import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { DocumentTemplate } from '../../../models/DocumentTemplate';
import {
  FETCH_DOCUMENT_TEMPLATES_COMMIT,
  FETCH_DOCUMENT_TEMPLATES_REQUEST,
  FETCH_DOCUMENT_TEMPLATES_ROLLBACK,
  DELETE_DOCUMENT_TEMPLATE,
} from '../actions/documentTemplates';

export interface DocumentTemplatesState {
  templates: DocumentTemplate[];
  meta: DocumentTemplatesMeta;
}

export interface DocumentTemplatesMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const templates: Reducer<DocumentTemplate[], any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TEMPLATES_COMMIT: {
      const { payload } = action;
      return payload as DocumentTemplate[];
    }

    case DELETE_DOCUMENT_TEMPLATE: {
      const { value } = action;
      return state.filter(
        (entry) => value.documentTemplateId !== entry.documentTemplateId
      );
    }

    // case DOCUMENT_TEMPLATE_RENAMED: {
    //   const { documentTemplateId, updatedName } = action;
    //   return state.map((driveItem) =>
    //     driveItem.id === documentTemplateId
    //       ? { ...driveItem, name: updatedName }
    //       : driveItem
    //   );
    // }
    // case DOCUMENT_TEMPLATE_DELETED: {
    //   const { documentTemplateId } = action;
    //   return state.filter((driveItem) => driveItem.id !== documentTemplateId);
    // }
    // case DOCUMENT_TEMPLATE_NEW_FOLDER_CREATED: {
    //   const { driveItem } = action;
    //   return ([...state, driveItem] as DocumentTemplate[]).sort((a, b) =>
    //     a.folder && b.file
    //       ? -1
    //       : b.folder && a.file
    //       ? 1
    //       : a.name.localeCompare(b.name)
    //   );
    // }

    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

const meta: Reducer<DocumentTemplatesMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_DOCUMENT_TEMPLATES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_DOCUMENT_TEMPLATES_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_DOCUMENT_TEMPLATES_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'documents:errorMessages.fetchDocumentTemplatesError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers({
  templates,
  meta,
});

export const getTemplates = (state: DocumentTemplatesState) => state.templates;
export const getMeta = (state: DocumentTemplatesState) => state.meta;
export const getIsFetching: (state: DocumentTemplatesState) => boolean = (
  state
) => state.meta.isFetching;
