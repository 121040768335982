import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { MailFolderId } from '../../../../models/Types';
import { FETCH_SPECIAL_MAIL_FOLDERS_ME_COMMIT } from '../../actions/me/specialMailFoldersMe';
import { SpecialMailFolders } from '../../actions/types';

export const specialMailFolder: Reducer<SpecialMailFolders, any> = (
  state = {},
  action
) => {
  switch (action.type) {
    case FETCH_SPECIAL_MAIL_FOLDERS_ME_COMMIT: {
      const { payload } = action;
      return payload;
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export default specialMailFolder;

export const getSpecialMailFolders: (
  state: SpecialMailFolders
) => SpecialMailFolders = (state) => state;
export const isDeletedFolder: (
  state: SpecialMailFolders,
  mailFolderId: MailFolderId
) => boolean = (state, mailFolderId) =>
  !!mailFolderId && state['deletedFolder']?.id === mailFolderId;

export const isSendFolder: (
  state: SpecialMailFolders,
  mailFolderId: MailFolderId
) => boolean = (state, mailFolderId) =>
  !!mailFolderId && state['sendFolder']?.id === mailFolderId;

export const isDraftFolder: (
  state: SpecialMailFolders,
  mailFolderId: MailFolderId
) => boolean = (state, mailFolderId) =>
  !!mailFolderId && state['draftFolder']?.id === mailFolderId;
