import { Moment } from 'moment-timezone';
import { ApiResult, apiUrl } from '../../../api';
import {
  AbsenceOverview,
  AbsenceProposal,
  AbsenceProposalRequest,
  AbsentWorkDaysResult,
  OfficeHoliday,
  SubstituteOnLeave,
  UpdateAbsenceProposalRequest,
} from '../../../models/AbsenceProposal';
import { RemainingAbsence } from '../../../models/RemainingAbsence';
import {
  AbsenceProposalId,
  ContactId,
  MedicalCertificateId,
  OfficeHolidayId,
  OfficeId,
} from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';
import {
  CreateMedicalCertificate,
  MedicalCertificateState,
  MedicalCertificate,
  UpdateMedicalCertificate,
} from '../../../models/MedicalCertificate';
import { PrioFile } from '../../../components/Upload/UploadField';

export const apiFetchAbsentWorkDays: (
  from: string,
  to: string,
  firstIsHalfDay: boolean,
  lastIsHalfDay: boolean,
  internalContactId?: ContactId,
  officeId?: OfficeId
) => Promise<ApiResult<AbsentWorkDaysResult>> = async (
  from,
  to,
  firstIsHalfDay,
  lastIsHalfDay,
  internalContactId,
  officeId
) => {
  const arr: string[] = [
    from ? `from=${from}` : undefined,
    to ? `to=${to}` : undefined,
    firstIsHalfDay ? `firstIsHalfDay=${firstIsHalfDay}` : undefined,
    lastIsHalfDay ? `lastIsHalfDay=${lastIsHalfDay}` : undefined,
  ];
  const arrString = arr.filter((item) => item !== undefined).join('&');
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/calculateOffDays/${
      officeId && internalContactId ? `Office/${officeId}/` : ''
    }${internalContactId ?? 'me'}${arrString !== '' ? `?${arrString}` : ''}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiRevokeAbsenceProposal: (
  absenceProposalId: AbsenceProposalId
) => Promise<ApiResult<void>> = async (absenceProposalId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/revoke/me`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiRevokeOfficeAbsenceProposal: (
  absenceProposalId: AbsenceProposalId,
  officeId: OfficeId
) => Promise<ApiResult<void>> = async (absenceProposalId, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/Office/${officeId}/revoke`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiDeleteOfficeAbsenceProposal: (
  absenceProposalId: AbsenceProposalId,
  officeId: OfficeId
) => Promise<ApiResult<void>> = async (absenceProposalId, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/Office/${officeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiDeleteAbsenceProposal: (
  absenceProposalId: AbsenceProposalId
) => Promise<ApiResult<void>> = async (absenceProposalId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/me/${absenceProposalId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiDeclineAbsenceProposal: (
  absenceProposalId: AbsenceProposalId
) => Promise<ApiResult<void>> = async (absenceProposalId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/decline`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiDeclineOfficeAbsenceProposal: (
  absenceProposalId: AbsenceProposalId,
  officeId: OfficeId
) => Promise<ApiResult<void>> = async (absenceProposalId, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/Office/${officeId}/decline`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiAcceptAbsenceProposal: (
  absenceProposalId: AbsenceProposalId
) => Promise<ApiResult<void>> = async (absenceProposalId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/accept`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiAcceptOfficeAbsenceProposal: (
  absenceProposalId: AbsenceProposalId,
  officeId: OfficeId
) => Promise<ApiResult<void>> = async (absenceProposalId, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/Office/${officeId}/accept`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  return { result };
};

export const apiCreateAbsenceProposal: (
  absenceProposalRequest: AbsenceProposalRequest
) => Promise<ApiResult<AbsenceProposal>> = async (absenceProposalRequest) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/proposal`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(absenceProposalRequest),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateAbsenceOfficeProposal: (
  absenceProposalRequest: AbsenceProposalRequest
) => Promise<ApiResult<AbsenceProposal>> = async (absenceProposalRequest) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/Office/${absenceProposalRequest.officeId}/proposal`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(absenceProposalRequest),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateAbsenceMeProposal: (
  absenceProposalRequest: AbsenceProposalRequest
) => Promise<ApiResult<AbsenceProposal>> = async (absenceProposalRequest) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/me/proposal`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(absenceProposalRequest),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchAbsencesMe: (
  signal?: AbortSignal
) => Promise<ApiResult<AbsenceOverview>> = async (signal) => {
  const result = await fetchWithRetry(`${apiUrl}/absence/absenceProposal/me`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    signal,
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  if (data) {
    return {
      result,
      data: {
        ...(data as AbsenceOverview),
        absenceProposals: (data as AbsenceOverview).absenceProposals.map(
          (proposal) => ({
            ...proposal,
            applicantId: proposal.applicantId.toLowerCase(),
            principalId: proposal.principalId.toLowerCase(),
            substituteId: proposal.substituteId.toLowerCase(),
            ...(proposal.notifyContactIds
              ? {
                  notifyContactIds: proposal.notifyContactIds.map((id) =>
                    id.toLowerCase()
                  ),
                }
              : {}),
          })
        ),
      },
    };
  }
  return {
    result,
    data,
  };
};

export const apiFetchAbsenceProposalOverview: (
  contactId: string,
  signal?: AbortSignal,
  officeId?: OfficeId
) => Promise<ApiResult<AbsenceOverview>> = async (
  contactId,
  signal,
  officeId
) => {
  var result;
  if (officeId !== undefined && officeId !== '') {
    result = await fetchWithRetry(
      `${apiUrl}/absence/absenceProposal/office/${officeId}/overview/${contactId}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );
  } else {
    result = await fetchWithRetry(
      `${apiUrl}/absence/absenceProposal/overview/${contactId}`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      }
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchAbsenceOverview: (
  officeId?: OfficeId,
  offices?: OfficeId[],
  year?: number,
  signal?: AbortSignal
) => Promise<ApiResult<RemainingAbsence[]>> = async (
  officeId,
  offices,
  year,
  signal
) => {
  var result;
  if (officeId === undefined || officeId === '') {
    var officesStr = '';
    offices.forEach((office) => {
      return (officesStr += `&offices=${office}`);
    });
    result = await fetch(
      `${apiUrl}/absence/AbsenceOverview${year ? `?year=${year}` : ''}${
        offices.length > 0 ? officesStr : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
        method: 'GET',
      }
    );
  } else {
    result = await fetch(
      `${apiUrl}/absence/AbsenceOverview/office/${officeId}${
        year ? `?year=${year}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
        method: 'GET',
      }
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchAbsenceProposals: (
  filterQuery?: { from: Moment; to: Moment },
  signal?: AbortSignal
) => Promise<ApiResult<AbsenceProposal[]>> = async (filterQuery, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/proposal${
      filterQuery
        ? `?${new URLSearchParams({
            from: filterQuery.from.toISOString(true).split('T')[0],
            to: filterQuery.to.toISOString(true).split('T')[0],
          }).toString()}`
        : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchOfficeAbsenceProposals: (
  officeId: OfficeId,
  filterQuery?: { from: Moment; to: Moment },
  signal?: AbortSignal
) => Promise<ApiResult<AbsenceProposal[]>> = async (
  officeId,
  filterQuery,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/Office/${officeId}/proposal${
      filterQuery
        ? `?${new URLSearchParams({
            from: filterQuery.from.toISOString(true).split('T')[0],
            to: filterQuery.to.toISOString(true).split('T')[0],
          }).toString()}`
        : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchPublicAbsenceProposals: (filterQuery: {
  from: Moment;
  to: Moment;
}) => Promise<ApiResult<AbsenceProposal[]>> = async (filterQuery) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/AbsenceProposal/proposal/public?${new URLSearchParams({
      from: filterQuery.from.toISOString(true).split('T')[0],
      to: filterQuery.to.toISOString(true).split('T')[0],
    }).toString()}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchFutureAbsenceProposalsWithUserInvolved: (filterQuery: {
  from: Moment;
  to: Moment;
}) => Promise<ApiResult<AbsenceProposal[]>> = async (filterQuery) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/AbsenceProposal/proposal/involved?${new URLSearchParams({
      from: filterQuery.from.toISOString(true).split('T')[0],
      to: filterQuery.to.toISOString(true).split('T')[0],
    }).toString()}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiChangeAbsenceProposal: (
  updateAbsenceProposalRequest: UpdateAbsenceProposalRequest,
  absenceProposalId: AbsenceProposalId
) => Promise<ApiResult<AbsenceProposal>> = async (
  updateAbsenceProposalRequest,
  absenceProposalId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/${absenceProposalId}/change`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateAbsenceProposalRequest),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateAbsenceProposal: (
  updateAbsenceProposalRequest: UpdateAbsenceProposalRequest,
  absenceProposalId: AbsenceProposalId,
  officeId?: OfficeId
) => Promise<ApiResult<AbsenceProposal>> = async (
  updateAbsenceProposalRequest,
  absenceProposalId,
  officeId
) => {
  const result = await fetchWithRetry(
    officeId
      ? `${apiUrl}/absence/office/${officeId}/absenceProposal/${absenceProposalId}`
      : `${apiUrl}/absence/absenceProposal/${absenceProposalId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateAbsenceProposalRequest),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchOfficeHolidays: (
  officeId?: OfficeId
) => Promise<ApiResult<OfficeHoliday[]>> = async (officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting${officeId ? `/office/${officeId}` : ''}/OfficeHoliday`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateOfficeHoliday: (
  officeHoliday: any,
  officeId?: OfficeId
) => Promise<ApiResult<OfficeHoliday>> = async (officeHoliday, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting${officeId ? `/office/${officeId}` : ''}/OfficeHoliday`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(officeHoliday),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCopyOfficeHolidayToNextYear: (
  officeHolidayId: OfficeHolidayId,
  year: string,
  officeId?: OfficeId
) => Promise<ApiResult<OfficeHoliday>> = async (
  officeHolidayId,
  year,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting${
      officeId ? `/office/${officeId}` : ''
    }/OfficeHoliday/${officeHolidayId}/${year}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateOfficeHoliday: (
  officeHoliday: any,
  officeHolidayId: OfficeHolidayId,
  officeId?: OfficeId
) => Promise<ApiResult<OfficeHoliday>> = async (
  officeHoliday,
  officeHolidayId,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting${
      officeId ? `/office/${officeId}` : ''
    }/OfficeHoliday/${officeHolidayId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/merge-patch+json',
      },
      method: 'PATCH',
      body: JSON.stringify(officeHoliday),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteHoliday: (
  officeHolidayId: OfficeHolidayId,
  officeId?: OfficeId
) => Promise<ApiResult<Response>> = async (officeHolidayId, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting${
      officeId ? `/office/${officeId}` : ''
    }/OfficeHoliday/${officeHolidayId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiFetchSubstituteOnLeave: (
  substituteId: ContactId,
  from?: string,
  to?: string
) => Promise<ApiResult<SubstituteOnLeave>> = async (substituteId, from, to) => {
  const arr: string[] = [
    from ? `from=${from}` : undefined,
    to ? `to=${to}` : undefined,
  ];
  const arrString = arr.filter((item) => item !== undefined).join('&');
  const result = await fetchWithRetry(
    `${apiUrl}/absence/absenceProposal/substitute/${substituteId}${
      arrString !== '' ? `?${arrString}` : ''
    }`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateMedicalCertificate: (
  medicalCertificate: CreateMedicalCertificate
) => Promise<ApiResult<MedicalCertificate>> = async (medicalCertificate) => {
  const result = await fetchWithRetry(`${apiUrl}/absence/MedicalCertificate`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(medicalCertificate),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMedicalCertificates: (
  absenceProposalId: AbsenceProposalId,
  additionalProps?: {
    officeId?: OfficeId;
    isUserMe?: boolean;
  }
) => Promise<ApiResult<MedicalCertificate[]>> = async (
  absenceProposalId,
  additionalProps
) => {
  let isUserMe = true;
  let officeId: string | undefined = undefined;

  if (additionalProps) {
    isUserMe = additionalProps.isUserMe ?? true;
    officeId = additionalProps.officeId;
  }

  const result = await fetchWithRetry(
    `${apiUrl}/absence/${
      officeId ? `office/${officeId}/` : ''
    }MedicalCertificate/${absenceProposalId}${
      isUserMe && !officeId ? '/me' : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  let data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateMedicalCertificate: (
  medicalCertificateId: MedicalCertificateId,
  request: UpdateMedicalCertificate,
  additionalProps?: {
    officeId?: OfficeId;
    isUserMe?: boolean;
  }
) => Promise<ApiResult<MedicalCertificate>> = async (
  medicalCertificateId,
  request,
  additionalProps
) => {
  let isUserMe = true;
  let officeId: string | undefined = undefined;

  if (additionalProps) {
    isUserMe = additionalProps.isUserMe ?? true;
    officeId = additionalProps.officeId;
  }

  const result = await fetchWithRetry(
    `${apiUrl}/absence/${
      officeId ? `office/${officeId}/` : ''
    }MedicalCertificate/${medicalCertificateId}${
      isUserMe && !officeId ? '/me' : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(request),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateMedicalCertificateState: (
  medicalCertificateId: MedicalCertificateId,
  request: {
    state: MedicalCertificateState;
  },
  officeId?: OfficeId
) => Promise<ApiResult<MedicalCertificate>> = async (
  medicalCertificateId,
  request,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/absence/${
      officeId ? `office/${officeId}/` : ''
    }MedicalCertificate/${medicalCertificateId}/state`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(request),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiArchiveMedicalCertificate: (
  medicalCertificateId: MedicalCertificateId,
  additionalProps?: {
    officeId?: OfficeId;
    isUserMe?: boolean;
  }
) => Promise<ApiResult<null>> = async (
  medicalCertificateId,
  additionalProps
) => {
  let isUserMe = true;
  let officeId: string | undefined = undefined;

  if (additionalProps) {
    isUserMe = additionalProps.isUserMe ?? true;
    officeId = additionalProps.officeId;
  }

  const result = await fetchWithRetry(
    `${apiUrl}/absence/${
      officeId ? `office/${officeId}/` : ''
    }MedicalCertificate/${medicalCertificateId}${
      isUserMe && !officeId ? '/me' : ''
    }/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );

  return {
    result,
    data: null,
  };
};

export const apiUploadAttachmentForMedicalCertificate: (
  medicalCertificateId: MedicalCertificateId,
  file: PrioFile
) => Promise<ApiResult<null>> = async (medicalCertificateId, file) => {
  let formData = new FormData();
  formData.append('medicalCertificate', file);

  const result = await fetchWithRetry(
    `${apiUrl}/absence/MedicalCertificate/${medicalCertificateId}/uploadFileAttachment`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'POST',
      body: formData,
    }
  );

  return {
    result,
    data: null,
  };
};
