import React, { useState, Reducer, useReducer, useEffect } from 'react';
import { Table, Typography, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';

import { GlobalProjectSettingId } from '../../../models/Types';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { tableTranslations } from '../../../util/table';
import {
  GlobalProjectSetting,
  CreateGlobalProjectSetting,
} from '../../../models/Configuration';
import HTMLTextEditor from '../../../components/HTMLTextEditor';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-row .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-thead > tr > th:first-child': {
      paddingLeft: theme.old.spacing.baseSpacing,
    },
    '& .ant-table-tbody > tr > td:first-child': {
      paddingLeft: theme.old.spacing.defaultPadding,
    },
    '& .ant-table-tbody > tr > td:last-child': {
      paddingRight: theme.old.spacing.defaultPadding,
    },
  },
  numberInput: {
    flex: 1,
  },
  currencyLabel: {
    padding: `0 ${theme.old.spacing.unit(1)}px`,
    color: theme.old.typography.colors.muted,
  },
  editor: {
    '& .fr-box.fr-basic': {
      maxHeight: 500,
    },
  },
  onHoverVisisbleIcon: {
    '&:hover': {
      visibility: 'hidden',
    },
  },
}));

interface GlobalProjectSettingsTableProps {
  settings: GlobalProjectSetting[];
  onIsDirtyChanged?: (
    isDirty: boolean,
    settings?: GlobalProjectSetting[]
  ) => void;
  onNewSetting?: (setting: CreateGlobalProjectSetting) => void;
  onDeleteSetting?: (settingId: GlobalProjectSettingId) => void;
  onSetDefaultSetting?: (settingId: GlobalProjectSettingId) => void;
  type: string;
}

interface TableEntry extends CreateGlobalProjectSetting {
  id: string;
  addSettingRow: boolean;
  isTemporary: boolean;
}

interface NewSetting extends CreateGlobalProjectSetting {
  isValid: boolean;
}

type UpdateSettingKey = 'displayName' | 'value';
type UpdateSettingValue = string;

interface UpdateNewSettingAction {
  displayName: UpdateSettingKey;
  value: UpdateSettingValue;
}

export const GlobalProjectSettingsTable: React.FC<
  GlobalProjectSettingsTableProps
> = (props) => {
  const classes = useStyles();
  const {
    settings: originalSettings,
    onIsDirtyChanged,
    onNewSetting,
    onDeleteSetting,
    onSetDefaultSetting,
    type,
  } = props;
  const [settings, setSettings] =
    useState<GlobalProjectSetting[]>(originalSettings);
  const { t } = useTranslation();
  const [newSettingRowActive, setNewSettingRowActive] = useState(false);

  useEffect(() => {
    setSettings(originalSettings);
    onIsDirtyChanged(false, originalSettings);
  }, [originalSettings, onIsDirtyChanged]);

  const validate: (state: NewSetting) => boolean = (state: NewSetting) =>
    state.displayName.trim().length > 0 && state.value.trim().length > 0;

  const emptyNewSetting: NewSetting = {
    displayName: '',
    value: '',
    isValid: false,
  };

  const [newSetting, updateNewSetting] = useReducer<
    Reducer<NewSetting, UpdateNewSettingAction | 'clear'>
  >((state: NewSetting, action: UpdateNewSettingAction | 'clear') => {
    if (action === 'clear') {
      return emptyNewSetting;
    }
    const updatedState = { ...state, [action.displayName]: action.value };
    return {
      ...updatedState,
      isValid: validate(updatedState),
    };
  }, emptyNewSetting);

  const updateSettingValue = (
    settingId: GlobalProjectSettingId,
    value: string
  ) => {
    const updatedSettings = settings.map((entry) =>
      entry.globalProjectSettingId === settingId
        ? { ...entry, objectJson: { value } }
        : entry
    );
    setSettings(updatedSettings);

    if (onIsDirtyChanged) {
      const isDirty = !equals(updatedSettings, originalSettings);
      onIsDirtyChanged(isDirty, updatedSettings);
    }
  };

  const saveNewSetting = () => {
    if (onNewSetting) {
      onNewSetting(newSetting);
      cancelNewSettingRow();
      updateNewSetting('clear');
    }
  };

  const deleteSetting = (settingId) => {
    if (onDeleteSetting) {
      onDeleteSetting(settingId);
      cancelNewSettingRow();
      updateNewSetting('clear');
    }
  };

  const isDefault = (id: string) =>
    originalSettings.some(
      (x) => x.globalProjectSettingId === id && x.isDefault
    );

  const setDefaultSetting = (settingId) => {
    onSetDefaultSetting?.(settingId);
  };

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('settings:projectSettingsTable.columnTitles.key'),
      dataIndex: 'displayName',
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      render: (value, record: TableEntry, index: number) =>
        record.addSettingRow ? (
          <Input
            value={record.addSettingRow ? newSetting.displayName : value}
            placeholder={t(
              'settings:projectSettingsTable.newEntry.placeHolderName'
            )}
            onChange={(e) =>
              updateNewSetting({
                displayName: 'displayName',
                value: e.currentTarget.value,
              })
            }
          />
        ) : (
          <Typography.Text>{value}</Typography.Text>
        ),
      width: 300,
    },
    {
      title: t('settings:projectSettingsTable.columnTitles.value'),
      dataIndex: 'value',
      render: (value, record) => (
        <Flex.Row>
          {type !== 'signature' ? (
            <Input
              value={record.addSettingRow ? newSetting.value : value}
              placeholder={t(
                'settings:projectSettingsTable.newEntry.placeHolderValue'
              )}
              onChange={(e) => {
                record.addSettingRow
                  ? updateNewSetting({
                      displayName: 'value',
                      value: e.currentTarget.value,
                    })
                  : updateSettingValue(record.id, e.currentTarget.value);
              }}
            />
          ) : (
            <HTMLTextEditor
              className={classes.editor}
              value={record.addSettingRow ? newSetting.value : value}
              onChange={(value) => {
                record.addSettingRow
                  ? updateNewSetting({
                      displayName: 'value',
                      value: value,
                    })
                  : updateSettingValue(record.id, value);
              }}
            />
          )}
        </Flex.Row>
      ),
    },
    {
      render: (_, record) =>
        !record.addSettingRow && (
          <Button
            type="default"
            className={
              !isDefault(record.id) &&
              'hourly-rate-suggestion-table-delete-button'
            }
            onClick={() => {
              setDefaultSetting(record.id);
            }}
            iconProp={[isDefault(record.id) ? 'fas' : 'fal', 'star']}
          ></Button>
        ),
      width: 80,
    },
    {
      render: (_, record) =>
        record.addSettingRow ? (
          <Button
            type="default"
            disabled={!newSetting.isValid}
            onClick={saveNewSetting}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            className="hourly-rate-suggestion-table-delete-button"
            onClick={() => {
              deleteSetting(record.id);
            }}
            iconProp={['fal', 'trash']}
            type="link"
          ></Button>
        ),
      width: 80,
    },
  ];

  const data: TableEntry[] = settings.map(
    (setting: GlobalProjectSetting, index: number) => ({
      id: setting.globalProjectSettingId,
      key: setting.globalProjectSettingId,
      displayName: setting.displayName,
      value: setting.objectJson.value,
      isTemporary: false, //config.isTemporary ?? false,
      addSettingRow: false,
    })
  );

  const enableNewSettingRow = () => setNewSettingRowActive(true);
  const cancelNewSettingRow = () => setNewSettingRowActive(false);

  const footer = () => (
    <div>
      <Button
        type="link"
        onClick={enableNewSettingRow}
        disabled={newSettingRowActive}
        iconProp={['fal', 'plus']}
      >
        {t('settings:projectSettingsTable.actions.add', {
          type: t(`settings:projectSettingsTable.type.${type}`),
        })}
      </Button>
    </div>
  );

  return (
    <Table
      className={classes.root}
      columns={columns}
      pagination={false}
      dataSource={
        newSettingRowActive
          ? [
              ...data,
              {
                id: 'addSetting',
                key: 'addSetting',
                displayName: '',
                value: '',
                addSettingRow: true,
              },
            ]
          : data
      }
      footer={footer}
      locale={tableTranslations(t)}
    />
  );
};

export default GlobalProjectSettingsTable;
