import React from 'react';
import {} from 'antd';
import { makePrioStyles } from '../../theme/utils';
import {
  DndDriveItemDto,
  DndEmailAttachmentDto,
  DndEmailDto,
  DndMailAddressDto,
  DND_TYPE_DRIVE_ITEM_FILE,
  DND_TYPE_EMAIL,
  DND_TYPE_EMAIL_ADDRESS,
  DND_TYPE_EMAIL_ATTACHMENT,
} from '../types';
import EmailAttachmentDragPreview from './EmailAttachmentDragPreview';
import DriveItemDragPreview from './DriveItemDragPreview';
import MailListItemDragPreview from './MailListItemDragPreview';
import MailAddressItemPreview from './MailAddressItemPreview';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    padding: 10,
  },
}));

interface DragPreviewProps {
  itemType: string | symbol;
  item:
    | DndDriveItemDto
    | DndEmailAttachmentDto
    | DndEmailDto
    | DndMailAddressDto;
}

export const DragPreview: React.FC<DragPreviewProps> = React.memo((props) => {
  //#region ------------------------------ Defaults
  const { itemType, item } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  switch (itemType) {
    case DND_TYPE_DRIVE_ITEM_FILE: {
      return (
        <DriveItemDragPreview
          className={classes.root}
          item={item as DndDriveItemDto}
        />
      );
    }
    case DND_TYPE_EMAIL_ATTACHMENT: {
      return (
        <EmailAttachmentDragPreview
          className={classes.root}
          item={item as DndEmailAttachmentDto}
        />
      );
    }
    case DND_TYPE_EMAIL: {
      return (
        <MailListItemDragPreview
          className={classes.root}
          item={item as DndEmailDto}
        />
      );
    }
    case DND_TYPE_EMAIL_ADDRESS: {
      return <MailAddressItemPreview item={item as DndMailAddressDto} />;
    }
    default: {
      return null;
    }
  }
});

export default DragPreview;
