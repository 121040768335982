import React, { useState } from 'react';
import { Dropdown, Menu, Tag, Typography } from 'antd';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';
import { PrioTheme } from '../theme/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    cursor: 'pointer',
  },
  customTag: {
    borderRadius: theme.old.components.tag.borderRadius,
    borderWidth: theme.old.components.tag.border,
    background: theme.old.components.tag.background,
    padding: 20,
    paddingTop: theme.old.spacing.baseSpacing,
    paddingBottom: theme.old.spacing.baseSpacing,
    '&.ant-tag:hover': {
      opacity: 1,
    },
  },
  tagText: {
    color: theme.old.components.tag.color,
  },
  disabled: {
    cursor: 'not-allowed',
    userSelect: 'none',
    color: theme.old.palette.chromaticPalette.lightGrey,
  },
  dropdownTagContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
  },
  dropdownChevron: {
    transition: '0.1s',
  },
  quickFilterDropDown: {
    '& .ant-dropdown-menu-root': {
      marginRight: '-8px',
    },
  },
}));

export interface CustomTagOption {
  name: string;
  value: any;
}

export interface CustomTagProps {
  className?: string;
  textClassName?: string;
  type: 'text' | 'options';
  text?: string;
  color?: string;
  classicStyle?: boolean;
  onClick?: (
    value: any,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  disabled?: boolean;
  value?: CustomTagOption;
  defaultValue?: CustomTagOption;
  options?: CustomTagOption[];
}

export const CustomTag: React.FC<CustomTagProps> = (props) => {
  const classes = useStyles();
  const {
    className,
    textClassName,
    text,
    color,
    children,
    classicStyle,
    disabled,
    value,
    options,
    defaultValue,
    type,
    onClick: onClickFromParent,
  } = props;

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState<CustomTagOption>(
    value ?? defaultValue
  );

  const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onClickFromParent?.(currentValue?.value, e);
  };

  const onOptionChange = (value: CustomTagOption) => {
    setCurrentValue(value);
    setDropDownOpen(false);
  };

  switch (type) {
    case 'options':
      return (
        <Tag
          onClick={!disabled ? onClick : undefined}
          className={classNames(
            classes.root,
            className,
            !classicStyle && classes.customTag,
            disabled && classes.disabled
          )}
          style={{ background: color }}
        >
          <div className={classes.dropdownTagContent}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick(e);
              }}
            >
              {currentValue?.name}
            </div>
            <Dropdown
              onVisibleChange={(visible) => setDropDownOpen(visible)}
              trigger={['click']}
              placement="bottomRight"
              overlayClassName={classes.quickFilterDropDown}
              overlay={
                <Menu defaultValue={currentValue?.name}>
                  {options.map((option) => (
                    <Menu.Item
                      key={option?.name}
                      onClick={(e) => {
                        e.domEvent.preventDefault();
                        e.domEvent.stopPropagation();
                        onOptionChange(option);
                      }}
                    >
                      {option?.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <FontAwesomeIcon
                  className={classes.dropdownChevron}
                  style={{
                    left: 28,
                    transform: `rotate(${dropDownOpen ? -180 : 0}deg)`,
                  }}
                  icon={['fal', 'chevron-down']}
                />
              </div>
            </Dropdown>
          </div>
        </Tag>
      );

    case 'text':
      return (
        <Tag
          onClick={!disabled ? onClick : undefined}
          className={classNames(
            classes.root,
            className,
            !classicStyle && classes.customTag,
            disabled && classes.disabled
          )}
          style={{ background: color }}
        >
          <Typography.Text
            className={
              !classicStyle && classNames(classes.tagText, textClassName)
            }
          >
            {value?.name}
          </Typography.Text>
        </Tag>
      );

    default:
      return (
        <Tag
          onClick={!disabled ? onClick : undefined}
          className={classNames(
            classes.root,
            className,
            !classicStyle && classes.customTag,
            disabled && classes.disabled
          )}
          style={{ background: color }}
        >
          {text ? (
            <Typography.Text
              className={
                !classicStyle && classNames(classes.tagText, textClassName)
              }
            >
              {text}
            </Typography.Text>
          ) : (
            children
          )}
        </Tag>
      );
  }
};

export default CustomTag;
