import { PRIO } from '../../../constants';
import { apiUrl } from '../../../api';
import { ProjectId, DriveUserRemoteItemId } from '../../../models/Types';
import {
  CreateDriveUserRemoteItem,
  DriveUserRemoteItem,
} from '../../../models/Document';

export const FETCH_REMOTE_FOLDERS_REQUEST =
  PRIO + 'FETCH_REMOTE_FOLDERS_REQUEST';
export const FETCH_REMOTE_FOLDERS_COMMIT = PRIO + 'FETCH_REMOTE_FOLDERS_COMMIT';
export const FETCH_REMOTE_FOLDERS_ROLLBACK =
  PRIO + 'FETCH_REMOTE_FOLDERS_ROLLBACK';

export const fetchRemoteFolders = () => ({
  type: FETCH_REMOTE_FOLDERS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveMe/remoteItem`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_REMOTE_FOLDERS_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_REMOTE_FOLDERS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'documents:errorMessages.fetchRemoteItemsError',
          timeout: 6,
        },
      },
    },
  },
});

export const DELETE_REMOTE_FOLDER_REQUEST =
  PRIO + 'DELETE_REMOTE_FOLDER_REQUEST';
export const DELETE_REMOTE_FOLDER_COMMIT = PRIO + 'DELETE_REMOTE_FOLDER_COMMIT';
export const DELETE_REMOTE_FOLDER_ROLLBACK =
  PRIO + 'DELETE_REMOTE_FOLDER_ROLLBACK';

export const deleteRemoteFolder = (
  driveUserRemoteItemId: DriveUserRemoteItemId,
  rollbackRemoteItem: DriveUserRemoteItem
) => ({
  type: DELETE_REMOTE_FOLDER_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveMe/remoteItem/${driveUserRemoteItemId}`,
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_REMOTE_FOLDER_COMMIT,
        meta: { driveUserRemoteItemId, rollbackRemoteItem },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_REMOTE_FOLDER_ROLLBACK,
        snackbarErrorMessage: {
          label: 'documents:errorMessages.deleteRemoteItemError',
          timeout: 6,
        },
        meta: { driveUserRemoteItemId, rollbackRemoteItem },
      },
    },
    driveUserRemoteItemId,
    rollbackRemoteItem,
  },
});

export const CREATE_REMOTE_FOLDER_REQUEST =
  PRIO + 'CREATE_REMOTE_FOLDER_REQUEST';
export const CREATE_REMOTE_FOLDER_COMMIT = PRIO + 'CREATE_REMOTE_FOLDER_COMMIT';
export const CREATE_REMOTE_FOLDER_ROLLBACK =
  PRIO + 'CREATE_REMOTE_FOLDER_ROLLBACK';

export const createRemoteFolder = (
  createRemoteFolderRequest: CreateDriveUserRemoteItem,
  projectId: ProjectId,
  temporaryId: string
) => ({
  type: CREATE_REMOTE_FOLDER_REQUEST,
  requiresAuth: true,
  payload: { ...createRemoteFolderRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/Drive/${projectId}/createRemoteItem`,
        json: createRemoteFolderRequest,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_REMOTE_FOLDER_COMMIT,
        meta: { temporaryId, projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_REMOTE_FOLDER_ROLLBACK,
        snackbarErrorMessage: {
          label: 'documents:errorMessages.createRemoteItemError',
          timeout: 6,
        },
        meta: { temporaryId, projectId },
      },
    },
    temporaryId,
    projectId,
  },
});

export const SET_CURRENT_REMOTE_ITEM =
  PRIO + 'SET_CURRENT_REMOTE_ITEM';

export const setCurrentRemoteItem = (
  driveUserRemoteItemId: DriveUserRemoteItemId,
) => ({
  type: SET_CURRENT_REMOTE_ITEM,
  driveUserRemoteItemId
});
