import { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Dropdown, Menu, notification } from 'antd';
import { Button, Modal } from '@prio365/prio365-react-library';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import DroppableElement from '../../../../dnd/components/DroppableElement';
import {
  DndDriveItemDto,
  DndEmailDto,
  DND_TYPE_DRIVE_ITEM_FILE,
  DND_TYPE_EMAIL,
} from '../../../../dnd/types';
import { makePrioStyles } from '../../../../theme/utils';
import { setSiderSetting } from '../../../userSettings/actions/themeSettings/sider';
import CreateSubprojectModal from '../Modals/CreateSubprojectModal';

import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import {
  addToProjectFavorites,
  fetchProjectById,
  leaveProjectMe,
  removeFromProjectFavorites,
  setActiveProjectTabView,
  updateInternalProjectContactMeNotification,
} from '../../actions';
import { DropTargetMonitor } from 'react-dnd';
import {
  MailFolderId,
  ProjectId,
  ProjectTabViews,
} from '../../../../models/Types';
import { Project } from '../../../../models/Project';
import {
  apiArchiveProject,
  apiFetchProjectMeNotification,
  apiLeaveProjectMe,
  apiUnarchiveProject,
  apiUpdateProjectMeNotification,
} from '../../api';
import {
  getActiveMailFolderId,
  getActiveProject,
  projectHasProjectNews,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { getFavoriteProjectsLength } from '../../selectors';
import { isDriveItemFolder } from '../../../documents/util';

const useStyles = makePrioStyles((theme) => ({
  flex1: {
    flex: 1,
    color: theme.old.typography.colors.base,
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  activeLink: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  badge: {
    '& .ant-badge-status-dot': {
      top: -3,
    },
  },
  headerRow: {
    lineHeight: '32px',
    '& > .prio-project-navigation-context-menu-button': {
      visibility: 'hidden',
      backgroundColor: 'transparent',
    },
    '&:hover > .prio-project-navigation-context-menu-button': {
      visibility: 'visible',
      backgroundColor: 'transparent',
      '&:hover > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
    '& > .prio-project-navigation-chevron-button': {
      backgroundColor: 'transparent',
      '&:hover > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
  collapseButton: {
    backgroundColor: 'transparent',
  },
  oneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > div': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  menuButtonWithoutCollapse: {
    marginRight: 32,
  },
  notReady: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  panelHeaderRoot: {
    flex: 1,
    transition: 'all 0.2s',
    paddingTop: theme.old.spacing.unit(0.5),
    paddingBottom: theme.old.spacing.unit(0.5),
    paddingRight: 5,
    paddingLeft: 0,
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  panelHeaderRootSubMenu: {
    padding: `${theme.old.spacing.unit(0.5)}px 5px  ${theme.old.spacing.unit(
      0.5
    )}px  ${theme.old.spacing.unit(1.5)}px`,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.sub,
    },
  },
  panelHeaderRootSubMenuActive: {
    backgroundColor: theme.old.palette.backgroundPalette.active.sub,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: 'calc(100% + 2px)',
      left: 0,
      top: -2,
      background: theme.old.palette.primaryColor,
    },
  },
  icon: {
    marginTop: theme.old.spacing.unit(1),
    marginRight: theme.old.spacing.unit(1.5),
  },
  notifications: {
    '& .ant-dropdown-menu-title-content': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  notificationLoadingCell: {
    width: 15,
    height: 15,
    marginLeft: theme.old.spacing.baseSpacing,
    display: 'flex',
  },
  noChevronVisible: {
    marginRight: '24px',
  },
  archivedProject: {
    fontStyle: 'italic',
  },
}));

interface NavigationItemPanelHeaderProjectProps {
  project: Project;
  rootPath: string;
  projectReady: boolean;
  isProjectMember: boolean;
  isSubMenu?: boolean;
  type?: ProjectTabViews;
  isActive: boolean;
  isParentProject?: boolean;
  collapsed?: boolean;
  setCollapsed?: (projectId: ProjectId, collapsed: boolean) => void;
  isAnySubProjectFavorited?: boolean;
  activeTab?: string;
  rerenderProjectList?: () => void;
}

export const NavigationItemPanelHeaderProject: React.FC<
  NavigationItemPanelHeaderProjectProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    project,
    rootPath,
    projectReady,
    isProjectMember,
    isSubMenu,
    type,
    isActive,
    isParentProject,
    collapsed,
    setCollapsed,
    isAnySubProjectFavorited,
    activeTab,
    rerenderProjectList,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeProjectId = useSelector(getActiveProject);
  const [isNotificationsFetching, setIsNotificationsFetching] =
    useState<boolean>(false);
  const [notificationEnabled, setNotificationEnabled] =
    useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const hasProjectNews = useSelector<RootReducerState, boolean>((state) =>
    projectHasProjectNews(state, project.projectId)
  );
  const [createSubprojectModalVisible, setCreateSubprojectModalVisible] =
    useState<boolean>(false);
  const [isProjectLeaveModalVisible, setIsProjectLeaveModalVisible] =
    useState<boolean>(false);

  const activeMailFolderId = useSelector<RootReducerState, MailFolderId>(
    (state) => getActiveMailFolderId(state, project?.projectId)
  );
  const favoriteProjectsLength = useSelector(getFavoriteProjectsLength);

  const chevronVisible =
    isParentProject &&
    (activeTab === 'favorites' ? isAnySubProjectFavorited : true);

  const [projectIsArchived, setProjectIsArchived] = useState<boolean>(
    project?.isArchived
  );

  const hasMailAccess = useMemo(() => {
    if (activeTab === 'all') {
      return project.sharedMailboxUserId;
    }
    return project?.projectExtensionMeDtos?.some(
      ({ projectExtensionType, extensionUserState }) =>
        projectExtensionType === 'sharedMailbox' &&
        extensionUserState === 'ready'
    );
  }, [activeTab, project]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchNotificationMe = useCallback(async () => {
    setIsNotificationsFetching(true);
    const { result, data } = await apiFetchProjectMeNotification(
      project.projectId
    );
    setIsNotificationsFetching(false);
    if (result.status >= 200 && result.status < 300) {
      setNotificationEnabled(data);
    }
  }, [project]);

  const updateNotificationMe = useCallback(async () => {
    setIsNotificationsFetching(true);
    const { data } = await apiUpdateProjectMeNotification(
      project.projectId,
      !notificationEnabled
    );
    setIsNotificationsFetching(false);
    if (data) {
      dispatch(
        updateInternalProjectContactMeNotification(project.projectId, data)
      );
      setNotificationEnabled(data.emailNotificationEnabled);
    } else {
      notification.open({
        message: t('common:error'),
        description: notificationEnabled
          ? t('projects:errorMessages.deactivateNotification')
          : t('projects:errorMessages.activateNotification'),
      });
    }
  }, [project, notificationEnabled, dispatch, t]);

  const onDriveItemIntoProjectDrop = (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    target: Project
  ) => {
    if (monitor.didDrop()) {
      return undefined;
    }
    const dndType = monitor.getItemType();
    switch (dndType) {
      case DND_TYPE_EMAIL: {
        const convertedItem = item as DndEmailDto;
        if (isProjectMember) {
          return {
            projectId: project.projectId,
            message: convertedItem.message,
            showModal: true,
          };
        }
        return undefined;
      }
      case DND_TYPE_DRIVE_ITEM_FILE: {
        // const convertedItem = item as DndDriveItemDto & {
        //   projectId: ProjectId;
        // };
        if (isProjectMember) {
          return {
            type: 'project',
            object: target,
          };
          // return {
          //   ...convertedItem,
          //   project: project,
          //   showDrawer: true,
          //   dropTargetType: 'project',
          //   destinationProject: project,
          //   destinationDriveItemId: `root-group-${project.groupId}`,
          //   destinationDriveItemIsRoot: true,
          // };
        }
        return undefined;
      }
      default: {
        return undefined;
      }
    }
  };

  const handleDisableOnDropOver: (
    item: DndEmailDto & DndDriveItemDto & { projectId: ProjectId },
    monitor: DropTargetMonitor<unknown, unknown>,
    object,
    isOverCurrent: boolean
  ) => boolean = (item, monitor) => {
    const dndType = monitor.getItemType();
    switch (dndType) {
      case DND_TYPE_EMAIL: {
        return !(
          isProjectMember &&
          (!item.message?.meetingMessageType ||
            item.message?.meetingMessageType === 'None')
        );
      }
      case DND_TYPE_DRIVE_ITEM_FILE: {
        return !(
          isProjectMember &&
          !(
            (item.driveItems ?? [item.driveItem]).find((dItem) =>
              isDriveItemFolder(dItem)
            ) && item.projectId !== project.projectId
          )
        );
      }
      default: {
        return !isProjectMember;
      }
    }
  };

  const handleProjectLeave = async () => {
    setIsProjectLeaveModalVisible(false);
    if (project?.projectId) {
      const { result, data } = await apiLeaveProjectMe(project.projectId);
      if (result.status >= 200 && result.status < 300) {
        dispatch(leaveProjectMe(project?.projectId));

        if (activeProjectId === project?.projectId)
          navigate('/module/prio/projects/me/dashboard/');
      } else {
        notification.open({
          message: t('common:error'),
          description: data?.TranslatedMessage,
        });
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setProjectIsArchived(project?.isArchived);
  }, [project]);
  //#endregion

  //#region ------------------------------ Component
  const menu = useMemo(() => {
    const handleProjectArchive = async () => {
      const result = projectIsArchived
        ? await apiUnarchiveProject(project.projectId)
        : await apiArchiveProject(project.projectId);
      const status = result.status;

      if (status >= 200 && status < 300) {
        setProjectIsArchived(!projectIsArchived);
        dispatch(fetchProjectById(project.projectId, isProjectMember));
        rerenderProjectList();
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            `projects:errorMessages.${
              projectIsArchived ? 'unarchive' : 'archive'
            }Project`
          ),
        });
      }
    };

    return (
      <Menu>
        {!projectIsArchived && (
          <Menu.Item
            onClick={(e) => {
              e.domEvent.stopPropagation();
              if (!project.favorite) {
                dispatch(setActiveProjectTabView(type));
                dispatch(addToProjectFavorites(project.projectId));
              } else {
                dispatch(removeFromProjectFavorites(project.projectId));
              }
            }}
            disabled={!project.favorite && (favoriteProjectsLength ?? 0) >= 15}
          >
            {t(
              `projects:navigation.dropDownMenu.${
                project.favorite ? 'removeFrom' : 'addTo'
              }Favorites`,
              {
                favoriteLength: favoriteProjectsLength ?? 0,
              }
            )}
          </Menu.Item>
        )}
        {!projectIsArchived && (
          <Menu.Item
            onClick={(e) => {
              e.domEvent.stopPropagation();
              updateNotificationMe();
            }}
            disabled={isNotificationsFetching}
            className={classes.notifications}
          >
            {t(
              `projects:navigation.dropDownMenu.${
                notificationEnabled
                  ? 'deactivateNotifications'
                  : 'activateNotifications'
              }`
            )}
            <div className={classes.notificationLoadingCell}>
              {isNotificationsFetching && <LoadingOutlined />}
            </div>
          </Menu.Item>
        )}
        {!project.parentProject && !projectIsArchived && (
          <Menu.Item
            onClick={(e) => {
              e.domEvent.stopPropagation();
              setCreateSubprojectModalVisible(true);
            }}
            disabled={project.isTemporary}
          >
            {t('projects:createSubprojectModal.menuTitle')}
          </Menu.Item>
        )}
        <Menu.Item
          onClick={(e) => {
            e.domEvent.stopPropagation();
            handleProjectArchive();
          }}
          disabled={project.isTemporary}
        >
          {t(
            `projects:navigation.dropDownMenu.${
              projectIsArchived ? 'unarchiveProject' : 'archiveProject'
            }`
          )}
        </Menu.Item>
        <Menu.Item
          onClick={(e) => {
            e.domEvent.stopPropagation();
            setIsProjectLeaveModalVisible(true);
          }}
          disabled={project.isTemporary}
        >
          {t(`projects:navigation.dropDownMenu.leaveProject`)}
        </Menu.Item>
      </Menu>
    );
  }, [
    project,
    isNotificationsFetching,
    notificationEnabled,
    type,
    classes,
    favoriteProjectsLength,
    projectIsArchived,
    isProjectMember,
    updateNotificationMe,
    rerenderProjectList,
    t,
    dispatch,
  ]);
  //#endregion

  return (
    <>
      <DroppableElement
        activeOverlay
        accept={[DND_TYPE_EMAIL, DND_TYPE_DRIVE_ITEM_FILE]}
        onDrop={onDriveItemIntoProjectDrop}
        object={project}
        disable={handleDisableOnDropOver}
      >
        <div
          className={classNames(classes.panelHeaderRoot, {
            [classes.panelHeaderRootSubMenu]: isSubMenu,
            [classes.panelHeaderRootSubMenuActive]: isSubMenu && isActive,
          })}
          onClick={() => {
            if (isSubMenu) {
              dispatch(setSiderSetting({ subMenuState: 'closed' }));
            }
          }}
        >
          <Flex.Row className={classes.headerRow}>
            {!!project.parentProject && (
              <FontAwesomeIcon
                size="sm"
                icon={['fal', 'horizontal-rule']}
                className={classes.icon}
              />
            )}
            <Link
              to={`${rootPath}${project.projectId}${
                isProjectMember && projectReady && hasMailAccess
                  ? `/mail${activeMailFolderId ? `/${activeMailFolderId}` : ''}`
                  : '/summary'
              }`}
              className={classNames(classes.flex1, classes.oneLine, {
                [classes.notReady]: !projectReady,
                [classes.activeLink]: isActive,
              })}
            >
              <div
                className={projectIsArchived ? classes.archivedProject : null}
              >{`${project.number} ${project.shortName ?? project.name}`}</div>
            </Link>
            {hasProjectNews ? (
              <Badge
                className={classes.badge}
                dot
                color={theme.old.palette.primaryColor}
              ></Badge>
            ) : null}
            {isProjectMember && (
              <Dropdown
                onVisibleChange={setDropdownVisible}
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  iconProp={['fal', 'ellipsis-v']}
                  className={classNames(
                    'prio-project-navigation-context-menu-button',
                    {
                      [classes.menuButtonWithoutCollapse]: !isParentProject,
                    }
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!dropdownVisible) {
                      fetchNotificationMe();
                    }
                  }}
                  type="default"
                />
              </Dropdown>
            )}
            {chevronVisible && (
              <Button
                className="prio-project-navigation-chevron-button"
                type="default"
                iconProp={['fal', collapsed ? 'angle-down' : 'angle-up']}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCollapsed(project.projectId, !collapsed);
                }}
              />
            )}
          </Flex.Row>
        </div>
        {!project.parentProject && createSubprojectModalVisible && (
          <CreateSubprojectModal
            visible={createSubprojectModalVisible}
            project={project}
            onClose={() => setCreateSubprojectModalVisible(false)}
          />
        )}
      </DroppableElement>
      {isProjectLeaveModalVisible && (
        <Modal
          title={t('projects:leaveProjectMeModal.title')}
          visible={isProjectLeaveModalVisible}
          onOk={handleProjectLeave}
          onClose={() => setIsProjectLeaveModalVisible(false)}
          okText={t('common:actions.confirm')}
          cancelText={t('common:actions.cancel')}
        >
          {t('projects:leaveProjectMeModal.content')}{' '}
        </Modal>
      )}
    </>
  );
};

export default NavigationItemPanelHeaderProject;
