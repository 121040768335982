import { QueryClientConfig } from '@tanstack/react-query';
import { defaultRetryOptions } from '../util/fetchWithRetry';

export class QueryError extends Error {
  result: Response;
  message: string;
  constructor(message: string, result: Response) {
    super(message);
    this.name = 'QueryError';
    this.result = result;
    this.message = message;
  }
}

const config: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: (count, error: QueryError) => {
        if (
          count >= defaultRetryOptions.length ||
          (error.result?.status >= 400 && error.result?.status < 500)
        ) {
          return false;
        }
        return true;
      },
      retryDelay: (attemptIndex) => defaultRetryOptions[attemptIndex],
    },
  },
};

export default config;
