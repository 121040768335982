import me, * as fromMe from './me';
import officeMe, * as fromOfficeMe from './officeMe';
import registrationState, * as fromRegistrationState from './registrationState';
import users, * as fromUsers from './users';
import addUserDrawer, * as fromAddUserDrawer from './addUserDrawer';
import { combineReducers } from 'redux';
import { User, UserRegistrationState } from '../../../models/User';
import { ContactId, GlobalRole, OfficeId } from '../../../models/Types';
import { InternalOffice } from '../../../models/Office';
import { OfficeMeByIdState } from './officeMe';

export interface UserReducerState {
  me: fromMe.PrioUserState;
  officeMe: fromOfficeMe.OfficeMeState;
  registrationState: UserRegistrationState;
  users: fromUsers.UsersState;
  addUserDrawer: fromAddUserDrawer.AddUserDrawerState;
}

export default combineReducers<UserReducerState>({
  me,
  officeMe,
  registrationState,
  users,
  addUserDrawer,
});

export const getUserMe: (state: UserReducerState) => User = (state) =>
  fromMe.getUserMe(state.me);
export const getUserMeContactId: (state: UserReducerState) => ContactId = (
  state
) => fromMe.getUserMeContactId(state.me);
export const getUserMeGlobalRoles: (state: UserReducerState) => GlobalRole[] = (
  state
) => fromMe.getGlobalRoles(state.me);
export const getUserMeIsFetching: (state: UserReducerState) => boolean = (
  state
) => fromMe.getIsFetching(state.me);
export const getUserMeHasError: (state: UserReducerState) => boolean = (
  state
) => fromMe.getHasError(state.me);
export const getUserMeErrorMessage: (state: UserReducerState) => string = (
  state
) => fromMe.getErrorMessage(state.me);

export const getOfficeMeByIdState: (
  state: UserReducerState
) => OfficeMeByIdState = (state) =>
  fromOfficeMe.getOfficeMeByIdState(state.officeMe);
export const getOfficeMeById: (
  state: UserReducerState,
  officeId: OfficeId
) => InternalOffice = (state, officeId) =>
  fromOfficeMe.getOfficeMeById(state.officeMe, officeId);
export const getOfficeMeIds: (state: UserReducerState) => OfficeId[] = (
  state
) => fromOfficeMe.getOfficeIds(state.officeMe);
export const getOfficeMeAllOffices: (
  state: UserReducerState
) => InternalOffice[] = (state) =>
  fromOfficeMe.getOfficeMeAllOffices(state.officeMe);

export const getOfficeMeIsFetching: (state: UserReducerState) => boolean = (
  state
) => fromOfficeMe.getIsFetching(state.officeMe);
export const getOfficeMeHasError: (state: UserReducerState) => boolean = (
  state
) => fromOfficeMe.getHasError(state.officeMe);
export const getOfficeMeErrorMessage: (state: UserReducerState) => string = (
  state
) => fromOfficeMe.getErrorMessage(state.officeMe);

export const getIsPrioUser: (state: UserReducerState) => boolean = (state) =>
  fromRegistrationState.getIsPrioUser(state.registrationState);
export const getHasRegistrationCompleted: (
  state: UserReducerState
) => boolean = (state) =>
  fromRegistrationState.getHasRegistrationCompleted(state.registrationState);

export const getPrioUsers: (state: UserReducerState) => User[] = (state) =>
  fromUsers.getPrioUsers(state.users);
export const getPrioUser: (
  state: UserReducerState,
  contactId: ContactId
) => User = (state, contactId) => fromUsers.getPrioUser(state.users, contactId);
export const getPrioUsersById: (
  state: UserReducerState
) => fromUsers.UsersByIdState = (state) =>
  fromUsers.getPrioUsersById(state.users);
export const getPrioUsersIds: (state: UserReducerState) => ContactId[] = (
  state
) => fromUsers.getPrioUsersIds(state.users);

export const getAddUserDrawerState: (
  state: UserReducerState
) => fromAddUserDrawer.AddUserDrawerState = (state) => state.addUserDrawer;

export const getUsersIsFetching: (state: UserReducerState) => boolean = (
  state
) => fromUsers.getIsFetching(state.users);
export const getUsersHasError: (state: UserReducerState) => boolean = (state) =>
  fromUsers.getHasError(state.users);
export const getUsersErrorMessage: (state: UserReducerState) => string = (
  state
) => fromUsers.getErrorMessage(state.users);
