import React from 'react';
import classNames from 'classnames';
// import MainDoodle from '../../doodles/components/MainDoodle';
import { makePrioStyles } from '../../../theme/utils';
import SvgIcon from '../../../components/SvgIcon';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
}));

interface MockProps {
  className?: string;
}

export const Mock: React.FC<MockProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      {/* <MainDoodle type="florian" /> */}
      <SvgIcon
        width={30}
        height={30}
        color="red"
        path={
          <>
            <circle
              cx="32.5"
              cy="32"
              r="31.5"
              fill="#E6EFFC"
              stroke="#0052CC"
            />
            <path d="M22.5 41.25H32.8125C33.375 42.1406 34.0781 42.8906 34.9219 43.5C34.7812 43.5 34.6406 43.5 34.5 43.5H22.5C20.8125 43.5 19.5 42.1875 19.5 40.5V22.5C19.5 20.8594 20.8125 19.5 22.5 19.5H30.2344C31.0312 19.5 31.7812 19.8281 32.3438 20.3906L36.6094 24.6562C37.1719 25.2188 37.5 25.9688 37.5 26.7656V28.8281C36.6562 29.0625 35.9062 29.3906 35.25 29.8594V27H31.5C30.6562 27 30 26.3438 30 25.5V21.75H22.5C22.0781 21.75 21.75 22.125 21.75 22.5V40.5C21.75 40.9219 22.0781 41.25 22.5 41.25ZM33 36.75C33 34.3594 34.2656 32.1562 36.375 30.9375C38.4375 29.7188 41.0156 29.7188 43.125 30.9375C45.1875 32.1562 46.5 34.3594 46.5 36.75C46.5 39.1875 45.1875 41.3906 43.125 42.6094C41.0156 43.8281 38.4375 43.8281 36.375 42.6094C34.2656 41.3906 33 39.1875 33 36.75ZM42.8906 34.7344C42.6094 34.4531 42.0938 34.4531 41.8125 34.7344L39 37.5938L37.6406 36.2344C37.3594 35.9531 36.8438 35.9531 36.5625 36.2344C36.2812 36.5156 36.2812 37.0312 36.5625 37.3125L38.4375 39.1875C38.7188 39.4688 39.2344 39.4688 39.5156 39.1875L42.8906 35.8125C43.1719 35.5312 43.1719 35.0156 42.8906 34.7344Z" />
          </>
        }
      ></SvgIcon>
    </div>
  );
};

export default Mock;
