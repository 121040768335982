import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { triggerSagaWatchResyncMessages } from '../modules/mail/sagas/watchResyncMessages';
import { Dispatch } from 'redux';
import { ProjectId } from '../models/Types';

export const updateFavicons = (isHidden: boolean) => {
  document
    .getElementById('prio-favicon-icon-16x16')
    .setAttribute(
      'href',
      `/favicons/${isHidden ? 'notifications/' : '/'}favicon-16x16.png`
    );
  document
    .getElementById('prio-favicon-icon-32x32')
    .setAttribute(
      'href',
      `/favicons/${isHidden ? 'notifications/' : '/'}avicon-32x32.png`
    );
  document
    .getElementById('prio-favicon-icon-96x96')
    .setAttribute(
      'href',
      `/favicons/${isHidden ? 'notifications/' : '/'}favicon-96x96.png`
    );
  document
    .getElementById('prio-favicon-android-192x192')
    .setAttribute(
      'href',
      `/favicons/${isHidden ? 'notifications/' : '/'}android-icon-192x192.png`
    );
};

const triggerMailResync = (dispatch: Dispatch<any>) => {
  const projectUrlMath = window.location.href.match(
    /\/module\/prio\/projects\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/mail/
  );

  const messageCentertUrlMath = window.location.href.match(
    /\/module\/prio\/projects\/me\/messageCenter\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/
  );

  const isMessageCenter = window.location.href.includes(
    'module/prio/projects/me/messageCenter/favorites/mail/inbox'
  );

  let projectId: ProjectId = null;
  if (projectUrlMath && projectUrlMath[1]) {
    projectId = projectUrlMath[1];
  } else if (messageCentertUrlMath && messageCentertUrlMath[1]) {
    projectId = messageCentertUrlMath[1];
  } else if (isMessageCenter) {
    projectId = 'favorites';
  }

  if (projectId) {
    dispatch(triggerSagaWatchResyncMessages(projectId));
  }
};

const useDocumentVisible = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const checkFocus = () => {
      updateFavicons(false);
      triggerMailResync(dispatch);
    };

    window.addEventListener('focus', checkFocus);
    return () => {
      window.removeEventListener('focus', checkFocus);
    };
  }, [dispatch]);
};

export default useDocumentVisible;
