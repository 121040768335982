import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {} from 'antd';
import { makePrioStyles } from '../../theme/utils';
import { DndDriveItemDto } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  checkIfOneNote,
  colorForIcon,
  iconForFile,
  isDriveItemFolder,
} from '../../modules/documents/util';
import Flex from '../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';
import { useKeyboardModifiers } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  iconSize: {
    fontSize: 18,
  },
}));

interface DriveItemDragPreviewProps {
  className?: string;
  item: DndDriveItemDto;
}

export const DriveItemDragPreview: React.FC<DriveItemDragPreviewProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, item } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isMultipleItems = item?.driveItems && item?.driveItems.length > 1;

  const isDraggedItemSelected = item.driveItems.some(
    (x) => x.id === item.driveItem.id
  );

  const { altIsPressed } = useKeyboardModifiers();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Components
  const renderIcon = () => {
    if (isMultipleItems && isDraggedItemSelected)
      return <FontAwesomeIcon icon={['fal', 'copy']} />;

    if (checkIfOneNote(item?.driveItem)) {
      return (
        <FontAwesomeIcon
          icon={['fas', iconForFile(item?.driveItem)]}
          color={theme.old.palette.chromaticPalette.purple}
        />
      );
    }
    if (isDriveItemFolder(item?.driveItem)) {
      return (
        <FontAwesomeIcon
          icon={['fas', 'folder']}
          color={theme.old.palette.chromaticPalette.yellow}
        />
      );
    }

    return (
      <FontAwesomeIcon
        color={colorForIcon(item?.driveItem?.file?.mimeType)}
        icon={['fas', iconForFile(item?.driveItem)]}
      />
    );
  };
  //#endregion

  return (
    <Flex.Row
      alignItems="center"
      childrenGap={theme.old.spacing.baseSpacing}
      className={classNames(classes.root, className)}
    >
      <div className={classes.iconSize}>{renderIcon()}</div>
      <div>
        {t(
          `common:drop.${altIsPressed ? 'copy' : 'move'}.${
            isMultipleItems && isDraggedItemSelected
              ? 'multipleDriveItems'
              : 'driveItem'
          }`,
          isMultipleItems && isDraggedItemSelected
            ? {
                amount: item?.driveItems?.length,
              }
            : {
                name: item?.driveItem?.name,
              }
        )}
      </div>
    </Flex.Row>
  );
};

export default DriveItemDragPreview;
