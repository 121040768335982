import { notification } from 'antd';
import {
  CommenErrorCode,
  DetailedErrorCode,
  GraphResponseError,
  commonGraphErrorCodes,
} from '../models/GraphError';
import { getResponseBody } from './api';
import i18n from '../i18n';

const createGraphErrorNotification: (
  response: Response
) => Promise<string> = async (response) => {
  try {
    const error = await getResponseBody<GraphResponseError>(response);
    if (error.error) {
      let { code, innerError } = error.error;
      let graphErrorCode: DetailedErrorCode | CommenErrorCode = code;
      if (innerError) {
        graphErrorCode = innerError.code;
        while (innerError.innerError) {
          innerError = innerError.innerError;
          if (innerError.code) {
            graphErrorCode = innerError.code;
          }
        }
      }
      if (graphErrorCode || code) {
        const isCommonErrorCode = commonGraphErrorCodes.includes(
          (graphErrorCode ?? code) as CommenErrorCode
        );
        const description = i18n.t(
          `common:graphError.${
            isCommonErrorCode ? 'commonCode' : 'detailedCode'
          }.${graphErrorCode ?? code}`
        );
        notification.error({
          message: i18n.t('common:error'),
          description,
        });
        return description;
      } else {
        throw new Error('Error in createGraphErrorNotification');
      }
    } else {
      throw new Error('Error in createGraphErrorNotification');
    }
  } catch (error) {
    console.error('Error in createGraphErrorNotification', error, response);
    notification.error({
      message: i18n.t('common:error'),
      description: i18n.t('common:graphError.commonCode.generalException'),
    });
    return i18n.t('common:graphError.commonCode.generalException');
  }
};

export default createGraphErrorNotification;
