import React, { useEffect, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { MENU_BUTTON_SIZE } from '../../../constants';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import ReleaseNotesDrawer from './ReleaseNoteDrawer';
import { Card, PrioSpinner } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Button } from '@prio365/prio365-react-library';
import { apiSupportGetReleases } from '../api';
import { PrioReleases } from '../../../models/Prio365Features';
import { useDispatch } from 'react-redux';
import { openZendeskDrawer } from '../actions/drawer';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    margin: `${theme.old.spacing.unit(3)}px`,
  },
  space: {
    display: 'flex',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  inputFieldContainer: {
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px 0`,
  },
  details: {
    float: 'right',
  },
  inputField: {
    marginBottom: theme.old.spacing.baseSpacing,
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  listContainer: {
    height: 'calc(100% - 40px)',
    overflowY: 'auto',
  },
  listBorder: {
    '& .ant-list-items': {
      borderBottom: theme.old.borders.content,
    },
  },
  listItem: {
    cursor: 'pointer',
    height: theme.old.spacing.unit(8),
    '-webkit-transition': 'all 0.3s ease-out',
    transition: 'all 0.3s ease-out',
    '&.ant-list-item': {
      padding: `0 ${theme.old.spacing.unit(3)}px`,
      borderTop: theme.old.borders.content,
      borderBottom: 0,
    },
    '& > button': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: theme.colors.application.background.default,
    },
    '&:hover > button': {
      visibility: 'visible',
    },
  },
  listItemMeta: {
    '& .ant-list-item-meta-title': {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      marginBottom: 0,
      paddingRight: theme.old.spacing.defaultPadding,
    },
  },
  userflowButton: {
    padding: '0!important',
    height: '100%',
    width: MENU_BUTTON_SIZE,
    backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
    '&:focus': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
    '&.ant-btn': {
      transition: 'none',
    },
  },
  inlineFloat: {
    display: 'flex',
    justifyContent: 'space-between',
    // p margin auto 0
    '& p': {
      margin: 'auto 0',
    },
  },
  prioNewsButton: {
    margin: '24px 24px 0px 24px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

interface ZendeskWidgetHelpFormProps {}

export const ZendeskWidgetHelpForm: React.FC<
  ZendeskWidgetHelpFormProps
> = () => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const [subDrawerVisible, setSubDrawerVisible] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState('');

  const [releases, setReleases] = useState<PrioReleases>();

  // use effect apiSupportGetReleases
  useEffect(() => {
    const fetchReleases = async () => {
      const { data } = await apiSupportGetReleases();
      if (data) {
        setReleases(data);
      }
    };

    fetchReleases();
  }, []);

  const dispatch = useDispatch();
  const closeDrawer = () => dispatch(openZendeskDrawer(false));

  const startUserFlow = (id: string) => {
    closeDrawer();

    setTimeout(() => window.open(`?userflow=${id}`, '_self'), 500);
  };

  const handleCardClick = (version: string) => {
    setSelectedVersion(version);
    setSubDrawerVisible(true);
  };

  return (
    <Flex.Column
      className={classes.root}
      childrenGap={theme.old.spacing.unit(2)}
    >
      {releases == null && (
        <div className={classes.center}>
          <PrioSpinner size="large" />
        </div>
      )}
      {releases?.prioReleases?.map((release) => (
        <Card
          secondaryCard={true}
          contentNode={
            <div className={classes.inlineFloat}>
              <p>{`${t('zendesk:version')} ${release.version}`}</p>
              {release.userFlowChecklist && (
                <div className={classes.inlineFloat}>
                  <p>{t('zendesk:updatetour')}</p>
                  <Button
                    className={classes.details}
                    type="link"
                    onClick={() => startUserFlow(release.userFlowChecklist)}
                    iconProp={['fal', 'play']}
                  ></Button>
                </div>
              )}
              <Button
                className={classes.details}
                type="link"
                onClick={() => handleCardClick(release.version)}
                iconProp={['fal', 'chevron-right']}
              ></Button>
            </div>
          }
        ></Card>
      ))}
      <ReleaseNotesDrawer
        visible={subDrawerVisible}
        onClose={() => setSubDrawerVisible(false)}
        selectedVersion={selectedVersion}
      />
    </Flex.Column>
  );
};

export default ZendeskWidgetHelpForm;
