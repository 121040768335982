import React, { useMemo, useState } from 'react';
import { Message } from '../../../models/Message';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Tabs,
  Typography,
} from 'antd';
import { MailFolderId, MessageId, ProjectId } from '../../../models/Types';
import { EventDetails } from '../../calendar/components/EventDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import { apiDeleteMessage } from '../api';
import MessageView from './MessageView';
import { CalendarEvent } from '../../../models/Calendar';
import { QuestionOutlined } from '@ant-design/icons';
import { deleteLocalMessage } from '../actions/actionControllers/messageActionController';
import classNames from 'classnames';
import {
  apiAcceptEvent,
  apiDeclineEvent,
  apiDeleteEvent,
  apiForwardEvent,
  apiTentativelyAcceptEvent,
} from '../../calendar/api';
import FilterEmailPicker from './FilterEmailPicker';
import { rowGutter } from '../../../util/forms';
import { parseLocation, readableMessageEventTime } from '../../calendar/util';
import PrioSpinner from '../../../components/PrioSpinner';
import {
  getMailSettings,
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  header: {
    padding: `${theme.old.spacing.defaultPadding}px ${theme.old.spacing.defaultPadding}px 0`,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    borderBottom: theme.old.borders.sub,
    overflow: 'hidden',
  },
  headerRow: {
    overflow: 'hidden',
  },
  headerRowColumn: {
    overflow: 'hidden',
  },
  headerRowColumnRow: {
    overflow: 'hidden',
  },
  tabs: {
    '& .ant-tabs-nav': {
      margin: 0,
    },
    '& .ant-tabs-nav::before': {
      border: 'none',
    },
    '& .ant-tabs-content-holder': {
      display: 'none',
    },
    '& .ant-tabs-tab': {
      flex: 'unset',
      maxWidth: 'unset',
      minWidth: 'unset',
    },
  },
  tabsCompressedView: {
    '& .ant-tabs-tab': {
      paddingBottom: '0px',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.old.spacing.baseSpacing,
  },
  titleLine: {
    overflow: 'hidden',
    '& h3.ant-typography, .ant-typography h3': {
      marginBottom: 0,
    },
  },
  eventOverview: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
  messagesContent: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  dropdown: {
    '&.ant-dropdown-trigger.ant-btn': {
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
  },
  messageView: {
    height: '100%',
    overflowY: 'auto',
  },
  button: {
    border: theme.old.borders.content,
  },
  info: {
    flex: 1,
  },
  icon: {
    marginTop: 4,
  },
  eventMessageViewHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.old.spacing.baseSpacing,
  },
  spinnerWrapper: {
    width: '30px',
    marginRight: '10px',
  },
}));

interface EventMessageViewProps {
  className?: string;
  projectId: ProjectId;
  message: Message;
  mailFolderId: MailFolderId;
  backButton?: boolean;
  prefix?: string;
}

export const EventMessageView: React.FC<EventMessageViewProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { className, projectId, message, backButton, prefix, mailFolderId } =
    props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [currentTab, setCurrentTab] = useState<string>('messages');
  const event = message?.event;
  const userMe = useSelector(getUserMe);
  const project = useSelector<RootReducerState, Project>((state) =>
    projectId !== 'me' ? getProject(state, projectId) : null
  );

  const mailAddress = useMemo(() => {
    if (projectId === 'me') {
      return userMe.mail;
    } else if (project) {
      return `${project.eMailPrefix}@${project.eMailSuffix}`;
    }
    return '';
  }, [userMe, project, projectId]);

  const mailSettings = useSelector(getMailSettings);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Components
  //#endregion

  //#region ------------------------------ Effects
  //#endregion
  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Column className={classes.header}>
        <Flex.Row className={classes.headerRow}>
          <Flex.Column
            className={classes.headerRowColumn}
            flex={1}
            childrenGap={theme.old.spacing.baseSpacing}
          >
            <Flex.Row
              className={classes.headerRowColumnRow}
              alignItems="center"
            >
              {backButton && (
                <Link to={prefix ?? mailFolderId ?? 'inbox'}>
                  <Button
                    type="link"
                    onClick={() => {
                      dispatch(setMailListNavigationState(null, projectId));
                    }}
                    icon={<FontAwesomeIcon icon={['fal', 'chevron-left']} />}
                  />
                </Link>
              )}
              <Flex.Row
                alignItems="center"
                childrenGap={theme.old.spacing.baseSpacing}
                className={classes.titleLine}
              >
                <Avatar
                  style={{
                    backgroundColor: theme.old.palette.primaryColor,
                    minWidth: 32,
                  }}
                  icon={<FontAwesomeIcon icon={['fal', 'calendar']} />}
                />
                <Typography.Title
                  level={3}
                  className={classes.title}
                  title={message?.subject}
                >
                  {message?.subject}
                </Typography.Title>
              </Flex.Row>
            </Flex.Row>
            <Flex.Item>
              <Flex.Row childrenGap={theme.old.spacing.baseSpacing}>
                <FontAwesomeIcon
                  icon={['fal', 'clock']}
                  color={theme.old.typography.colors.muted}
                  style={{ width: theme.old.spacing.unit(4) }}
                  className={classes.icon}
                />
                <Typography.Text className={classes.info}>
                  {readableMessageEventTime(event)}
                </Typography.Text>
              </Flex.Row>
              {(event?.location?.displayName ||
                event?.locations?.length > 0) && (
                <Flex.Row childrenGap={theme.old.spacing.baseSpacing}>
                  <FontAwesomeIcon
                    icon={['fal', 'map-marker-alt']}
                    color={theme.old.typography.colors.muted}
                    style={{ width: theme.old.spacing.unit(4) }}
                    className={classes.icon}
                  />
                  <Typography.Text className={classes.info}>
                    {parseLocation(event?.location, event?.locations)}
                  </Typography.Text>
                </Flex.Row>
              )}
              <Flex.Row childrenGap={theme.old.spacing.baseSpacing}>
                <FontAwesomeIcon
                  icon={['fal', 'user']}
                  color={theme.old.typography.colors.muted}
                  style={{ width: theme.old.spacing.unit(4) }}
                  className={classes.icon}
                />
                <Typography.Text className={classes.info}>
                  {event?.organizer?.emailAddress?.name ??
                    event?.organizer?.emailAddress?.address ??
                    '-'}
                </Typography.Text>
              </Flex.Row>
            </Flex.Item>
          </Flex.Column>
          {event?.organizer &&
            event?.organizer?.emailAddress?.address !== mailAddress && (
              <EventMessageViewHeader
                projectId={projectId}
                messageId={message?.id}
                event={event}
                mailFolderId={mailFolderId}
              />
            )}
        </Flex.Row>
        <Tabs
          className={classNames(classes.tabs, {
            [classes.tabsCompressedView]:
              mailSettings.mailListSpacing === 'tight',
          })}
          activeKey={currentTab}
          onChange={setCurrentTab}
        >
          <Tabs.TabPane
            tab={t('calendar:eventOverview.tabs.messages')}
            key="messages"
          />
          <Tabs.TabPane
            tab={t('calendar:eventOverview.tabs.details')}
            key="details"
          />
        </Tabs>
      </Flex.Column>
      <Flex.Item
        flex={1}
        className={classNames(classes.content, {
          [classes.messagesContent]: currentTab === 'messages',
        })}
      >
        {currentTab === 'messages'
          ? [message].map((m) => (
              <MessageView
                message={m}
                projectId={projectId}
                className={classes.messageView}
                isEvent
                showActions
              />
            ))
          : event && (
              <EventDetails className={classes.eventOverview} event={event} />
            )}
      </Flex.Item>
    </Flex.Column>
  );
};
export default EventMessageView;

interface EventMessageViewHeaderProps {
  projectId: ProjectId;
  messageId: MessageId;
  mailFolderId: MailFolderId;
  event: CalendarEvent;
  prefix?: string;
}

const EventMessageViewHeader: React.FC<EventMessageViewHeaderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { projectId, messageId, event, mailFolderId } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>(null);
  const [recipients, setRecipients] = useState<string[]>([]);

  const [eventDeleteInProgress, setEventDeleteInProgress] =
    useState<boolean>(false);
  const [showForwardModal, setShowForwardModal] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleAccept = async () => {
    setIsWaiting(true);
    const success = await apiAcceptEvent(projectId, messageId, event, answer);
    setIsWaiting(false);
    if (success) {
      dispatch(setMailListNavigationState(null, projectId));
      window.history.replaceState({}, '', `../../../${mailFolderId}`);
      dispatch(deleteLocalMessage(projectId, mailFolderId, messageId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('calendar:messages.error.acceptEventError'),
      });
    }
  };

  const handleDecline = async () => {
    setIsWaiting(true);
    const success = await apiDeclineEvent(projectId, messageId, event, answer);
    setIsWaiting(false);
    if (success) {
      dispatch(setMailListNavigationState(null, projectId));
      window.history.replaceState({}, '', `../../../${mailFolderId}`);
      dispatch(deleteLocalMessage(projectId, mailFolderId, messageId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('calendar:messages.error.declineEventError'),
      });
    }
  };

  const handleTentativelyAccept = async () => {
    setIsWaiting(true);
    const success = await apiTentativelyAcceptEvent(
      projectId,
      messageId,
      event,
      answer
    );
    setIsWaiting(false);
    if (success) {
      dispatch(setMailListNavigationState(null, projectId));
      window.history.replaceState({}, '', `../../../${mailFolderId}`);
      dispatch(deleteLocalMessage(projectId, mailFolderId, messageId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('calendar:messages.error.tentativelyAcceptedEventError'),
      });
    }
  };

  const handleForwardOk = async () => {
    setIsWaiting(true);
    const success = await apiForwardEvent(projectId, event, recipients, answer);
    setIsWaiting(false);
    if (!success) {
      notification.open({
        message: t('common:error'),
        description: t('calendar:messages.error.forwardEventError'),
      });
    }
    setShowForwardModal(false);
    setRecipients([]);
  };

  const handleEventDelete = async () => {
    setEventDeleteInProgress(true);
    const successDeleteEvent = await apiDeleteEvent(projectId, event);
    const successDeleteMessage = await apiDeleteMessage(projectId, messageId);
    if (successDeleteEvent && successDeleteMessage) {
      dispatch(deleteLocalMessage(projectId, mailFolderId, messageId));
    }
    setEventDeleteInProgress(false);
    navigate(`/module/prio/projects/${projectId}/mail/inbox/`);
  };

  const handleForwardCancel = () => {
    setShowForwardModal(false);
    setRecipients([]);
  };
  //#endregion

  //#region ------------------------------ Components
  const menu = () => (
    <Menu>
      <Menu.Item
        onClick={(e) => {
          e.domEvent.stopPropagation();
          setShowForwardModal(true);
        }}
      >
        {t('mail:eventMessage.actions.forward')}
      </Menu.Item>
    </Menu>
  );

  const renderEventDeleteButton = () => (
    <>
      <Button
        className={classes.button}
        disabled={!event?.id || isWaiting || eventDeleteInProgress}
        onClick={handleEventDelete}
      >
        <Row>
          {eventDeleteInProgress === false ? (
            <></>
          ) : (
            <div className={classes.spinnerWrapper}>
              <PrioSpinner size="small" alignSelf></PrioSpinner>
            </div>
          )}
          {t('mail:eventMessage.actions.deleteEvent')}
        </Row>
      </Button>
    </>
  );
  const renderEventHeader = () => (
    <>
      <Input
        onChange={(e) => setAnswer(e.target.value)}
        placeholder={t('mail:eventMessage.actions.answerPlaceholder')}
      />
      <Flex.Item>
        <Flex.Row
          childrenGap={theme.old.spacing.baseSpacing}
          justifyContent="flex-end"
        >
          <Button
            onClick={() => handleAccept()}
            className={classes.button}
            disabled={!event?.id || isWaiting}
          >
            <FontAwesomeIcon icon={['fal', 'check']} />
            {t('mail:eventMessage.actions.accept')}
          </Button>
          <Button
            onClick={() => handleTentativelyAccept()}
            icon={<QuestionOutlined />}
            className={classes.button}
            disabled={!event?.id || isWaiting}
          >
            {t('mail:eventMessage.actions.tenativelyAccepted')}
          </Button>
          <Button
            icon={<FontAwesomeIcon icon={['fal', 'times']} />}
            onClick={() => handleDecline()}
            className={classes.button}
            disabled={!event?.id || isWaiting}
          >
            {t('mail:eventMessage.actions.declined')}
          </Button>
          <Dropdown
            overlay={menu()}
            trigger={['click']}
            placement="bottomRight"
            className={classes.dropdown}
            disabled={!event?.id || isWaiting}
          >
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className={classes.button}
              icon={<FontAwesomeIcon icon={['fal', 'ellipsis-h']} />}
            />
          </Dropdown>
        </Flex.Row>
      </Flex.Item>
    </>
  );
  const renderEventActions = () => {
    if (event) {
      if (event.isCancelled) return renderEventDeleteButton();
      else return renderEventHeader();
    }
    return null;
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classes.eventMessageViewHeader}>
      {renderEventActions()}
      <Modal
        visible={showForwardModal}
        title={t('calendar:eventOverview.forwardModal.title')}
        okText={t('calendar:eventOverview.forwardModal.okText')}
        cancelText={t('calendar:eventOverview.forwardModal.cancelText')}
        onOk={() => handleForwardOk()}
        okButtonProps={{ disabled: !event?.id || isWaiting }}
        onCancel={() => handleForwardCancel()}
      >
        <Form layout="vertical">
          <Row gutter={rowGutter}>
            <Col span={24}>
              <Form.Item
                label={t('calendar:eventOverview.forwardModal.secondaryTitle')}
              >
                <FilterEmailPicker
                  projectId={projectId}
                  type="all"
                  onChange={setRecipients}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
