import React, { useEffect, useState } from 'react';

import { TextBlock } from '../../../models/TextBlock';
import Table, { ColumnProps } from 'antd/lib/table';
import { makePrioStyles } from '../../../theme/utils';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCompaniesByIdState } from '../../../apps/main/rootReducer';
import { tableTranslations } from '../../../util/table';
import { Input, Popover, Tag } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { TextBlockId } from '../../../models/Types';
import { FilterFilled } from '@ant-design/icons';
import Flex from '../../../components/Flex';
import PrioSpinner from '../../../components/PrioSpinner';
import { html2text } from '../../mail/util';
import ReactHtmlParser from 'react-html-parser';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  table: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-row .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(2) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(2) > button': {
      visibility: 'visible',
    },
  },
  dropDownFilter: {
    padding: theme.old.spacing.unit(2),
  },
  dropDownFilterButton: {
    width: '100%',
    fontSize: 14,
  },
  popoverTitle: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 500,
  },
  font: {
    fontSize: 14,
  },
  popover: {
    '& .ant-popover-inner-content': {
      padding: `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(3)}px`,
      maxWidth: 600,
      maxHeight: 300,
      overflowY: 'scroll',
    },
    '& .ant-popover-title': {
      padding: `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(3)}px`,
    },
  },
  trashIcon: {
    color: `${theme.old.palette.chromaticPalette.red}!important`,
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

interface TextBlocksTableProps {
  textBlocks: TextBlock[];
  onRowClick?: (entry: TextBlock) => void;
  onRowSelectionChange?: (selectedTextBlocks: TextBlock[]) => void;
  deleteTextBlock?: (id: TextBlockId) => void;
  loading?: boolean;
  showPopover?: boolean;
}

export const TextBlocksTable: React.FC<TextBlocksTableProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    textBlocks,
    onRowClick,
    onRowSelectionChange,
    deleteTextBlock,
    loading,
    showPopover,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    setClickedTextBlockId(null);
  }, [showPopover]);

  const rowSelection: TableRowSelection<TextBlock> = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (onRowSelectionChange) onRowSelectionChange(selectedRows);
    },
  };

  const companiesByIdState = useSelector(getCompaniesByIdState);

  const filterDropDown = (
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    dataIndex: string
  ) => (
    <Flex.Column
      className={classes.dropDownFilter}
      childrenGap={theme.old.spacing.unit(1)}
    >
      <Input
        placeholder={t(`settings:textBlock.table.${dataIndex}`)}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => {
          switch (dataIndex) {
            case 'name':
              setNameFilter(selectedKeys ?? []);
              break;
            case 'content':
              setContentFilter(selectedKeys ?? []);
              break;
            case 'company':
              setCompanyFilter(selectedKeys ?? []);
              break;
          }
          confirm();
        }}
      />
      <Flex.Row flex={1}>
        <Flex.Item flex={1}>
          <Button
            type="default"
            className={classes.danger}
            onClick={(event) => {
              switch (dataIndex) {
                case 'name':
                  setNameFilter([]);
                  break;
                case 'content':
                  setContentFilter([]);
                  break;
                case 'company':
                  setCompanyFilter([]);
                  break;
              }
              event.stopPropagation();
              clearFilters();
            }}
          >
            {t('settings:textBlock.table.filterReset')}
          </Button>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Button
            className={classes.dropDownFilterButton}
            type="primary"
            onClick={() => {
              switch (dataIndex) {
                case 'name':
                  setNameFilter(selectedKeys ?? []);
                  break;
                case 'content':
                  setContentFilter(selectedKeys ?? []);
                  break;
                case 'company':
                  setCompanyFilter(selectedKeys ?? []);
                  break;
              }
              confirm();
            }}
          >
            {t('settings:textBlock.table.filterSearch')}
          </Button>
        </Flex.Item>
      </Flex.Row>
    </Flex.Column>
  );

  const [nameFilter, setNameFilter] = useState<string[]>([]);
  const [contentFilter, setContentFilter] = useState<string[]>([]);
  const [companyFilter, setCompanyFilter] = useState<string[]>([]);

  const [clickedTextBlockId, setClickedTextBlockId] =
    useState<TextBlockId>(null);

  const columns: ColumnProps<TextBlock>[] = [
    {
      title: t('settings:textBlock.table.name'),
      dataIndex: 'name',
      key: 'name',
      filteredValue: nameFilter,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return filterDropDown(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          'name'
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filtered ? theme.old.palette.primaryColor : undefined,
          }}
        />
      ),
      onFilter: (value: string, record) => {
        return record.name.toLowerCase().includes(value?.toLowerCase());
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setClickedTextBlockId(null);
        }
      },
    },
    {
      title: t('settings:textBlock.table.content'),
      dataIndex: 'content',
      key: 'content',
      filteredValue: contentFilter,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return filterDropDown(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          'content'
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filtered ? theme.old.palette.primaryColor : undefined,
          }}
        />
      ),
      onFilter: (value: string, record) => {
        return record.content.toLowerCase().includes(value?.toLowerCase());
      },
      sorter: (a, b) => a.content.localeCompare(b.content),
      render: (value: string, record: TextBlock) => (
        <Popover
          overlayClassName={classes.popover}
          visible={record.textBlockId === clickedTextBlockId}
          title={
            <Flex.Row alignItems="center">
              <Flex.Item flex={1}>
                <div className={classes.popoverTitle}>{record.name}</div>
              </Flex.Item>
              <Button
                shape="circle"
                iconProp={['fal', 'times']}
                onClick={(event) => {
                  event.stopPropagation();
                  setClickedTextBlockId(null);
                }}
                type="link"
              />
            </Flex.Row>
          }
          content={
            <div onClick={(e) => e.stopPropagation()}>
              {ReactHtmlParser(value)}
            </div>
          }
          trigger="click"
        >
          {value.length > 35
            ? `${html2text(value).substring(0, 35)}...`
            : html2text(value)}
        </Popover>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setClickedTextBlockId(null);
        }
      },
    },
    {
      title: t('settings:textBlock.table.company'),
      dataIndex: 'companyId',
      key: 'companyId',
      filteredValue: companyFilter,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return filterDropDown(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          'company'
        );
      },
      filterIcon: (filtered) => (
        <FilterFilled
          style={{
            color: filtered ? theme.old.palette.primaryColor : undefined,
          }}
        />
      ),
      onFilter: (value: string, record) => {
        return companiesByIdState[record.companyId].fullName
          ?.toLowerCase()
          .includes(value?.toLowerCase());
      },
      sorter: (a, b) =>
        companiesByIdState[a.companyId]?.fullName.localeCompare(
          companiesByIdState[b.companyId]?.fullName
        ),
      render: (value) => companiesByIdState[value]?.fullName ?? '',
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setClickedTextBlockId(null);
        }
      },
    },
    ...(deleteTextBlock
      ? [
          {
            render: (_, record) => (
              <Button
                type="link"
                onClick={(event) => {
                  event.stopPropagation();
                  deleteTextBlock(record.textBlockId);
                }}
                className="hourly-rate-suggestion-table-delete-button"
                iconProp={['fal', 'trash']}
              ></Button>
            ),
            width: deleteTextBlock ? 80 : 0,
          },
        ]
      : []),
  ];

  const handleOnRowClick = (record: TextBlock) => {
    if (onRowClick) {
      onRowClick(record);
    }
    if (showPopover) {
      if (clickedTextBlockId && clickedTextBlockId === record.textBlockId) {
        setClickedTextBlockId(null);
      } else if (clickedTextBlockId !== record.textBlockId) {
        setClickedTextBlockId(record.textBlockId);
      }
    }
  };

  useEffect(() => {
    if (textBlocks || textBlocks?.length === 0) setClickedTextBlockId(null);
  }, [textBlocks]);

  return (
    <Flex.Column
      className={classes.root}
      childrenGap={theme.old.spacing.baseSpacing}
    >
      <Flex.Row flex={1} alignItems="center">
        <Flex.Item flex={1}>
          {nameFilter.length !== 0 && (
            <Tag
              className={classes.font}
              closable
              onClose={() => setNameFilter([])}
            >{`${t('settings:textBlock.table.name')}: ${nameFilter}`}</Tag>
          )}
          {contentFilter.length !== 0 && (
            <Tag
              className={classes.font}
              closable
              onClose={() => setContentFilter([])}
            >{`${t(
              'settings:textBlock.table.content'
            )}: ${contentFilter}`}</Tag>
          )}
          {companyFilter.length !== 0 && (
            <Tag
              className={classes.font}
              closable
              onClose={() => setCompanyFilter([])}
            >{`${t(
              'settings:textBlock.table.company'
            )}: ${companyFilter}`}</Tag>
          )}
        </Flex.Item>
        {(nameFilter.length !== 0 ||
          contentFilter.length !== 0 ||
          companyFilter.length !== 0) && (
          <Button
            type="link"
            className={classes.danger}
            onClick={() => {
              setNameFilter([]);
              setContentFilter([]);
              setCompanyFilter([]);
            }}
            iconProp={['fal', 'trash-alt']}
          >
            {t('settings:textBlock.table.deleteFilters')}
          </Button>
        )}
      </Flex.Row>
      <Table<TextBlock>
        className={classes.table}
        dataSource={textBlocks}
        columns={columns}
        scroll={{ x: '100%' }}
        rowKey={(record) => record.textBlockId}
        rowClassName={classes.row}
        onRow={(record: TextBlock) => {
          return {
            onClick: (event) => {
              event.stopPropagation();
              handleOnRowClick(record);
            },
          };
        }}
        onHeaderRow={() => {
          return {
            onClick: () => {
              setClickedTextBlockId(null);
            },
          };
        }}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        locale={tableTranslations(t)}
        loading={{
          spinning: loading,
          indicator: <PrioSpinner alignSelf />,
        }}
      />
    </Flex.Column>
  );
};

export default TextBlocksTable;
