import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DriveItem } from '../../../../models/Drive';
import { Project } from '../../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { calcTextWidth } from '../../../../util/calcTextWidth';
import classNames from 'classnames';
import { Breadcrumb, Radio } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  breadcrumbSeparator: {
    color: 'rgba(0,0,0,0.45)',
    margin: `0 ${theme.old.spacing.unit(0.5)}px`,
  },
  breadcrumb: {
    color: theme.old.palette.chromaticPalette.black,
    '& .ant-breadcrumb-separator': {
      color: 'rgba(0,0,0,0.45)',
      margin: `0 ${theme.old.spacing.unit(0.5)}px`,
    },
  },
  breadcrumbColor: {
    '& > span:!last-child': {
      color: theme.old.palette.chromaticPalette.black,
    },
    color: theme.old.typography.colors.muted,
  },
}));

interface PathSuggestionRadioButtonProps {
  className?: string;
  driveItem: DriveItem;
  pathItems: string[];
  project: Project;
}
const RADIO_OFFSET = 32;

export const PathSuggestionRadioButton = memo(
  ({
    className,
    driveItem,
    pathItems,
    project,
    ...props
  }: PathSuggestionRadioButtonProps) => {
    //#region ------------------------------ Defaults
    const theme = useTheme<PrioTheme>();
    const classes = useStyles(props);

    const tooltipItems = [project.number, ...pathItems, driveItem.name];
    const tooltipText = tooltipItems.join(' / ');

    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const refContainer = useRef<HTMLDivElement>(null);
    const refWidth = refContainer.current?.offsetWidth - RADIO_OFFSET ?? 0;

    const [itemsRemoved, setItemsRemoved] = useState<boolean>(false);
    const [breadcrumbItemsToDisplay, setBreadcrumbItemsToDisplay] =
      useState<string[]>(pathItems);
    const [containerWidth, setContainerWidth] = useState<number>(refWidth);

    const resizeObserver = useMemo(() => {
      return new ResizeObserver((entries) => {
        setContainerWidth(entries[0].contentRect.width - RADIO_OFFSET);
      });
    }, []);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const spreadBreadcrumbItems = useCallback(
      (
        pathItems: string[],
        rootItem: string,
        destinationItem: string,
        setBreadcrumbItemsToDisplay: (items: string[]) => void
      ) => {
        const calculateWidth = (text: string) =>
          calcTextWidth(text, theme.old.typography.fontSize.base);

        const separatorWidth = calculateWidth('/') + 8;
        const widthRootItem = calculateWidth(rootItem) + 2 * separatorWidth;
        const widthDestinationItem = calculateWidth(destinationItem);

        const itemsRemovedWidth = itemsRemoved ? calculateWidth('... / ') : 0;

        const itemWidths = pathItems.map(
          (item) => calculateWidth(item) + separatorWidth
        );

        let accumulationWidth = 0;
        const correlatedWidths = itemWidths.map((width) => {
          accumulationWidth += width;
          return accumulationWidth;
        });

        const availableWidth =
          containerWidth -
          widthRootItem -
          widthDestinationItem -
          itemsRemovedWidth;

        const newItems = correlatedWidths
          .filter((width) => width <= availableWidth)
          .map((width, index) => pathItems[index]);

        setItemsRemoved(newItems.length !== pathItems.length);
        setBreadcrumbItemsToDisplay([itemsRemoved && '...', ...newItems]);
      },
      [theme.old.typography.fontSize.base, containerWidth, itemsRemoved]
    );
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (refContainer.current) {
        resizeObserver.observe(refContainer.current);
      }
    }, [resizeObserver, refContainer]);

    useEffect(() => {
      spreadBreadcrumbItems(
        pathItems,
        project.number,
        driveItem.name,
        setBreadcrumbItemsToDisplay
      );
    }, [pathItems, project.number, driveItem.name, spreadBreadcrumbItems]);
    //#endregion

    return (
      <div
        className={classNames(classes.root, className)}
        title={tooltipText}
        ref={refContainer}
      >
        <Radio value={driveItem.id} key={driveItem.id}>
          <Breadcrumb
            className={classNames(classes.breadcrumb, classes.breadcrumbColor)}
          >
            <span className={classes.breadcrumbSeparator}>/</span>
            <Breadcrumb.Item>{project.number}</Breadcrumb.Item>
            {breadcrumbItemsToDisplay.map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
            <Breadcrumb.Item>{driveItem.name}</Breadcrumb.Item>
          </Breadcrumb>
        </Radio>
      </div>
    );
  }
);
