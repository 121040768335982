import React from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import { HROfficeModule } from './HROfficeModule';
import { HRTrainings } from './HRTrainings';
import { PersonnelFiles } from './personnelFiles/PersonnelFiles';
import useAccessRights from '../../users/hooks/useAccessRights';
import { makePrioStyles } from '../../../theme/utils';
import HRAbsence from '../../absences/components/HRAbsence';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
  },
}));

interface HRModuleProps {}

export const HRModule: React.FC<HRModuleProps> = (props) => {
  const classes = useStyles();

  const { showHrModule, showGlobalInHrModule } = useAccessRights([
    'showHrModule',
    'showGlobalInHrModule',
  ]);
  return (
    <div className={classes.root}>
      <Routes>
        {showHrModule && (
          <Route
            path="office/*"
            element={<HROfficeModule className={classes.fullHeight} />}
          />
        )}
        {showGlobalInHrModule && (
          <>
            <Route path="absence/">
              <Route path="" element={<Navigate to="overview" replace />} />
              <Route
                path=":selectedList/*"
                element={
                  <HRAbsence pathPrefix="../" className={classes.fullHeight} />
                }
              />
            </Route>
            <Route path="trainings">
              <Route path="" element={<Navigate to="trainings" replace />} />
              <Route
                path=":selectedList/*"
                element={
                  <HRTrainings
                    pathPrefix="../"
                    className={classes.fullHeight}
                  />
                }
              />
            </Route>
            <Route
              path="personnelFiles/*"
              element={<PersonnelFiles className={classes.fullHeight} />}
            />
          </>
        )}
      </Routes>
    </div>
  );
};

export default HRModule;
