import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, InputNumber, Select } from 'antd';
import { Button, Checkbox, Drawer } from '@prio365/prio365-react-library';
import moment, { Moment } from 'moment';
import {
  allFormOfEmploymentTypes,
  Employee,
  FormOfEmployment,
} from '../../../../models/Employee';
import { EmployeeId } from '../../../../models/Types';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { colon, rowGutter } from '../../../../util/forms';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import eqauls from 'deep-equal';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    fontSize: '14px',
    overflowY: 'auto',
  },
  form: {
    flex: 1,
    overflow: 'visible',
    '& .ant-input-number': {
      width: '100%',
    },
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  heading: {
    marginBottom: theme.spacing.regular,
    fontSize: theme.font.fontSize.regular,
    color: theme.colors.application.typography.muted,
  },
  dateOfEmployment: {
    '& .SingleDatePicker': {
      zIndex: 801,
    },
  },
  endOfContract: {
    '& .SingleDatePicker': {
      zIndex: 701,
    },
  },
  customDrawerContent: {
    paddingBottom: 0,
  },
  gridColumn: {
    marginTop: theme.spacing.regular,
    marginBottom: theme.spacing.large,
  },
  header: {
    marginBottom: theme.spacing.regular,
    fontWeight: theme.font.fontWeight.bold,
  },
  marginBottomLastChild: {
    '& > div:last-child': {
      marginBottom: theme.spacing.regular,
    },
  },
  labelHidden: {
    '& > div > label': {
      visibility: 'hidden',
    },
  },
}));

interface ContractDataFormModel {
  dateOfEmployment?: Moment;
  endOfContract?: Moment;
  hoursPerWeek?: number; //int
  formOfEmployment?: FormOfEmployment;
  probationDurationWeek?: number; //int
  workingDayMonday?: boolean;
  workingDayTuesday?: boolean;
  workingDayWednesday?: boolean;
  workingDayThursday?: boolean;
  workingDayFriday?: boolean;
  workingDaySaturday?: boolean;
  workingDaySunday?: boolean;
  nonTransferableOvertime?: number;
  nonTransferableOvertimeType?: 'absolute' | 'relativ';
  timeKeepingEnableDate?: Moment;
}

const contractDataFromEmployee: (
  employee: Employee
) => ContractDataFormModel = (employee) => ({
  dateOfEmployment: employee?.dateOfEmployment
    ? moment(employee?.dateOfEmployment)
    : null,
  probationDurationWeek: employee?.probationDurationWeek,
  endOfContract: employee?.endOfContract
    ? moment(employee?.endOfContract)
    : null,
  formOfEmployment: employee?.formOfEmployment,
  hoursPerWeek: employee?.hoursPerWeek,
  workingDayMonday: employee?.workingDayMonday,
  workingDayTuesday: employee?.workingDayTuesday,
  workingDayWednesday: employee?.workingDayWednesday,
  workingDayThursday: employee?.workingDayThursday,
  workingDayFriday: employee?.workingDayFriday,
  workingDaySaturday: employee?.workingDaySaturday,
  workingDaySunday: employee?.workingDaySunday,
  nonTransferableOvertime:
    employee?.nonTransferableOvertimeHoursPercent > 0
      ? employee.nonTransferableOvertimeHoursPercent
      : employee.nonTransferableOvertimeHours,
  nonTransferableOvertimeType:
    employee?.nonTransferableOvertimeHoursPercent > 0 ? 'relativ' : 'absolute',
  timeKeepingEnableDate: employee?.timeKeepingEnableDate
    ? moment(employee?.timeKeepingEnableDate)
    : null,
});

interface PersonnelFileContractDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  datePickerLocale: PickerLocale;
  patchEmployee: (patchData: Employee) => Promise<void>;
}

export const PersonnelFileContractDataForm: React.FC<
  PersonnelFileContractDataFormProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { employee, isSaving, patchEmployee } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion
  const formatter: (
    value: number,
    info: { userTyping: boolean; input: string }
  ) => string = (value, info) => {
    let _value = value;
    if (!info.userTyping) {
      _value = Math.round(value * 4) / 4;
    }
    return `${_value}`.replace('.', ',');
  };
  //#region ------------------------------ States / Attributes / Selectors
  const [contractDataForm] = Form.useForm<ContractDataFormModel>();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(true);

  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);

  const [isCalendarViewOpen, setIsCalendarViewOpen] = useState<boolean>(false);

  const contractData = contractDataFromEmployee(employee);

  const workingDaysProps = [
    'workingDayMonday',
    'workingDayTuesday',
    'workingDayWednesday',
    'workingDayThursday',
    'workingDayFriday',
    'workingDaySaturday',
    'workingDaySunday',
  ];

  const actualWorkingDays = workingDaysProps.reduce((acc, curr) => {
    if (contractData[curr] === true) {
      acc.push(curr);
    }
    return acc;
  }, []);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const cancelEditContractData = () => {
    contractDataForm.setFieldsValue(contractDataFromEmployee(employee));
    setEditDrawerVisible(false);
  };
  const submitContractDataForm = () => {
    contractDataForm.submit();
    setEditDrawerVisible(false);
  };

  const handleFinishContractData = async (
    contractData: ContractDataFormModel
  ) => {
    const {
      nonTransferableOvertime,
      nonTransferableOvertimeType,
      timeKeepingEnableDate,
      dateOfEmployment,
      endOfContract,
      ...rest
    } = contractData;
    const patchData: Employee = {
      ...rest,
      ...(nonTransferableOvertimeType === 'absolute'
        ? {
            nonTransferableOvertimeHoursPercent: 0,
            nonTransferableOvertimeHours: nonTransferableOvertime ?? 0,
          }
        : {
            nonTransferableOvertimeHours: 0,
            nonTransferableOvertimeHoursPercent: nonTransferableOvertime ?? 0,
          }),
      ...(timeKeepingEnableDate
        ? {
            timeKeepingEnableDate: timeKeepingEnableDate
              ?.toISOString(true)
              .split('T')[0],
          }
        : {}),
      dateOfEmployment:
        dateOfEmployment?.toISOString(true).split('T')[0] ?? null,
      endOfContract: endOfContract?.toISOString(true).split('T')[0] ?? null,
    };
    await patchEmployee(patchData).then(() => setEditDrawerVisible(false));
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (employee) {
      contractDataForm.setFieldsValue(contractDataFromEmployee(employee));
    }
  }, [employee, contractDataForm]);

  useEffect(() => {
    setDisabled(isSaving);
  }, [isSaving]);
  //#endregion

  //#region ------------------------------ Drawer components
  const editDrawerContent = () => {
    return (
      <Form<ContractDataFormModel>
        form={contractDataForm}
        onFinish={handleFinishContractData}
        layout="vertical"
        className={classes.form}
        onValuesChange={(_, values) => {
          const initialValues = contractDataFromEmployee(employee);

          if (!eqauls(values, initialValues)) {
            setIsSaveButtonDisabled(false);
          } else {
            setIsSaveButtonDisabled(true);
          }
        }}
      >
        <Flex.Row flex={1}>
          <Flex.Column flex={1}>
            <div className={classes.heading}>
              {t('hr:personnelFile.form.labels.general')}
            </div>
            <Flex.Row childrenGap={theme.spacing.small}>
              <Flex.Column flex={1}>
                <Form.Item
                  name="dateOfEmployment"
                  label={t('hr:personnelFile.form.labels.dateOfEmployment')}
                  colon={colon}
                  className={classes.dateOfEmployment}
                >
                  <CustomSingleDatePicker
                    id="edit_personnelfile_employmentdate_id"
                    anchorDirection={'ANCHOR_RIGHT'}
                    small={true}
                    regular={false}
                    twoMonths={false}
                    disabled={disabled}
                    withFullScreenPortal={false}
                    daySize={30}
                    hideKeyboardShortcutsPanel={true}
                    showDefaultInputIcon={true}
                    inputIconPosition={'after'}
                  ></CustomSingleDatePicker>
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="timeKeepingEnableDate"
                  label={t(
                    'hr:personnelFile.form.labels.timeKeepingEnableDate'
                  )}
                  colon={colon}
                >
                  <CustomSingleDatePicker
                    id="edit_personnelfile_timeKeepingEnableDate_id"
                    anchorDirection={'ANCHOR_RIGHT'}
                    small={true}
                    regular={false}
                    twoMonths={false}
                    disabled={disabled}
                    withFullScreenPortal={false}
                    daySize={30}
                    hideKeyboardShortcutsPanel={true}
                    showDefaultInputIcon={true}
                    inputIconPosition={'after'}
                  />
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row childrenGap={theme.spacing.small}>
              <Flex.Column flex={1}>
                <Form.Item
                  name="formOfEmployment"
                  label={t('hr:personnelFile.form.labels.formOfEmployment')}
                  colon={colon}
                >
                  <Select<FormOfEmployment>
                    disabled={disabled}
                    className={disabled ? classes.disabledPicker : ''}
                    options={allFormOfEmploymentTypes.map((type) => ({
                      value: type,
                      label: t(`hr:formOfEmploymentTypes.${type}`),
                    }))}
                  />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="probationDurationWeek"
                  label={t(
                    'hr:personnelFile.form.labels.probationDurationWeek'
                  )}
                  colon={colon}
                >
                  <InputNumber
                    disabled={disabled}
                    min={0}
                    step={1}
                    formatter={formatter}
                    decimalSeparator=","
                  />
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row childrenGap={theme.spacing.small}>
              <Flex.Column flex={1}>
                <Form.Item
                  name="hoursPerWeek"
                  label={t('hr:personnelFile.form.labels.hoursPerWeek')}
                  colon={colon}
                >
                  <InputNumber
                    disabled={disabled}
                    formatter={formatter}
                    decimalSeparator=","
                  />
                </Form.Item>
              </Flex.Column>

              <Flex.Column flex={1}>
                <Form.Item
                  label={t(
                    'hr:personnelFile.form.labels.nonTransferableOvertimeHours'
                  )}
                  style={{ marginBottom: 0 }}
                >
                  <Flex.Row childrenGap={theme.spacing.small}>
                    <Flex.Item flex={1}>
                      <Form.Item name="nonTransferableOvertime" colon={colon}>
                        <InputNumber
                          disabled={disabled}
                          min={0}
                          formatter={(value) => `${value}`.replace('.', ',')}
                          parser={(value) =>
                            parseFloat(value.replace(',', '.')) as 0
                          }
                        />
                      </Form.Item>
                    </Flex.Item>
                    <Flex.Item>
                      <Form.Item name="nonTransferableOvertimeType">
                        <Select
                          options={[
                            { value: 'absolute', label: 'h' },
                            { value: 'relativ', label: '%' },
                          ]}
                          defaultValue={'absolute'}
                          disabled={disabled}
                          className={disabled ? classes.disabledPicker : ''}
                          style={{
                            width: 60,
                          }}
                        ></Select>
                      </Form.Item>
                    </Flex.Item>
                  </Flex.Row>
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          <Flex.Column flex={1}>
            <div className={classes.heading} style={{ marginTop: '24px' }}>
              {t('hr:personnelFile.form.labels.workingDays')}
            </div>
            <Flex.Row childrenGap={theme.spacing.small}>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayMonday"
                  label={t('hr:personnelFile.form.labels.workingDayMonday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayTuesday"
                  label={t('hr:personnelFile.form.labels.workingDayTuesday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayWednesday"
                  label={t('hr:personnelFile.form.labels.workingDayWednesday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayThursday"
                  label={t('hr:personnelFile.form.labels.workingDayThursday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayFriday"
                  label={t('hr:personnelFile.form.labels.workingDayFriday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row gutter={rowGutter}>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDaySaturday"
                  label={t('hr:personnelFile.form.labels.workingDaySaturday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={4}>
                <Form.Item
                  name="workingDaySunday"
                  label={t('hr:personnelFile.form.labels.workingDaySunday')}
                  colon={colon}
                  valuePropName="checked"
                >
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row childrenGap={theme.spacing.small}>
          <Flex.Column flex={1}>
            <div
              className={classes.heading}
              style={{ marginTop: theme.spacing.regular }}
            >
              {t('hr:personnelFile.form.labels.offboarding')}
            </div>

            <Form.Item
              name="endOfContract"
              label={t('hr:personnelFile.form.labels.endOfContract')}
              className={classNames(classes.endOfContract, {
                [classes.labelHidden]: isCalendarViewOpen,
              })}
            >
              <CustomSingleDatePicker
                id="edit_personnelfile_endOfContract_id"
                onFocusChange={setIsCalendarViewOpen}
                focused={isCalendarViewOpen}
                openDirection={'up'}
                small={true}
                regular={false}
                twoMonths={false}
                disabled={disabled}
                withFullScreenPortal={false}
                daySize={30}
                hideKeyboardShortcutsPanel={true}
                showDefaultInputIcon={true}
                inputIconPosition={'after'}
              />
            </Form.Item>
          </Flex.Column>
          <Flex.Column flex={1} />
        </Flex.Row>
      </Form>
    );
  };
  const editDrawerFooter = () => {
    return (
      <Flex.Row justifyContent="flex-end" childrenGap={theme.spacing.small}>
        <Button
          type="link"
          onClick={cancelEditContractData}
          disabled={isSaving}
        >
          {t('common:actions.cancel')}
        </Button>
        <Button
          type={'primary'}
          onClick={submitContractDataForm}
          disabled={isSaving || isSaveButtonDisabled}
        >
          {t('common:save')}
        </Button>
      </Flex.Row>
    );
  };
  //#endregion

  return (
    <>
      <Flex.Column className={classes.root}>
        <Flex.Column flex={1}>
          <Flex.Row childrenGap={theme.spacing.large}>
            <Flex.Column
              flex={1}
              childrenGap={theme.spacing.small}
              className={classes.gridColumn}
            >
              <Flex.Column
                childrenGap={theme.spacing.small}
                className={classes.marginBottomLastChild}
              >
                <div className={classes.header}>
                  {t('hr:personnelFile.form.labels.general')}
                </div>
                {!!contractData?.dateOfEmployment && (
                  <div>{`${t(
                    'hr:personnelFile.form.labels.dateOfEmployment'
                  )}: ${moment(contractData?.dateOfEmployment).format(
                    'DD.MM.YYYY'
                  )}
              
            `}</div>
                )}
                {!!contractData?.timeKeepingEnableDate && (
                  <div>{`${t(
                    'hr:personnelFile.form.labels.timeKeepingEnableDate'
                  )}: ${moment(contractData?.timeKeepingEnableDate).format(
                    'DD.MM.YYYY'
                  )}
              
            `}</div>
                )}
              </Flex.Column>
              <Flex.Column
                childrenGap={theme.spacing.small}
                className={classes.marginBottomLastChild}
              >
                {!!contractData?.formOfEmployment && (
                  <div>{`${t(
                    'hr:personnelFile.form.labels.formOfEmployment'
                  )}: ${t(
                    `hr:formOfEmploymentTypes.${contractData?.formOfEmployment}`
                  )}
              
            `}</div>
                )}
                {!!contractData?.probationDurationWeek && (
                  <div>{`${t(
                    'hr:personnelFile.form.labels.probationDurationWeekWithoutUnit'
                  )}: ${contractData?.probationDurationWeek} ${t(
                    'hr:personnelFile.form.labels.weeks'
                  )}`}</div>
                )}
                {!!contractData?.hoursPerWeek && (
                  <div>{`${t(
                    'hr:personnelFile.form.labels.hoursPerWeekWithoutUnit'
                  )}: ${contractData?.hoursPerWeek} ${t(
                    'hr:personnelFile.form.labels.hours'
                  )}`}</div>
                )}
                {!!contractData?.nonTransferableOvertime && (
                  <div>{`${t(
                    'hr:personnelFile.form.labels.nonTransferableOvertimeHours'
                  )}: ${contractData?.nonTransferableOvertime
                    .toString()
                    .replace('.', ',')} ${
                    contractData?.nonTransferableOvertimeType === 'absolute'
                      ? t('hr:personnelFile.form.labels.hours')
                      : '%'
                  }`}</div>
                )}
              </Flex.Column>
            </Flex.Column>
            <Flex.Column
              flex={1}
              childrenGap={theme.spacing.small}
              className={classes.gridColumn}
            >
              <div className={classes.header}>
                {t('hr:personnelFile.form.labels.workingDays')}
              </div>
              {actualWorkingDays.map((day) => (
                <div key={day}>{t(`hr:personnelFile.form.labels.${day}`)}</div>
              ))}
            </Flex.Column>
          </Flex.Row>
          {!!contractData?.endOfContract && (
            <Flex.Row childrenGap={theme.spacing.large}>
              <Flex.Column
                flex={1}
                childrenGap={theme.spacing.small}
                className={classes.gridColumn}
              >
                <div className={classes.header}>
                  {t('hr:personnelFile.form.labels.offboarding')}
                </div>

                <div>{`${t(
                  'hr:personnelFile.form.labels.endOfContract'
                )}: ${moment(contractData?.endOfContract).format(
                  'DD.MM.YYYY'
                )}`}</div>
              </Flex.Column>
              <Flex.Column
                flex={1}
                childrenGap={theme.spacing.small}
                className={classes.gridColumn}
              ></Flex.Column>
            </Flex.Row>
          )}
        </Flex.Column>
        <Flex.Row justifyContent="flex-end">
          <Button
            type={'primary'}
            onClick={() => setEditDrawerVisible(true)}
            disabled={isSaving}
          >
            {t('common:edit')}
          </Button>
        </Flex.Row>
      </Flex.Column>
      <Drawer
        title={t('hr:personnelFile.editDrawer.contractData')}
        visible={editDrawerVisible}
        onClose={() => setEditDrawerVisible(false)}
        children={editDrawerContent()}
        footer={editDrawerFooter()}
        contentClassName={classes.customDrawerContent}
      />
    </>
  );
};

export default PersonnelFileContractDataForm;
