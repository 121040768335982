import React from 'react';
import { Skeleton } from 'antd';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  item: {
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(3)}px`,
  },
  checkbox: {
    width: '17px',
    height: '17px',
    marginLeft: '23px',
    marginRight: '16px',
    border: ' 1px solid rgb(0, 0, 0, 0.15)',
    borderRadius: '2px',
  },
  alignment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '32px',
    marginBottom: '48px',
  },
  input: {
    width: '90%',
    height: '16px',
    overflow: 'hidden',
  },
  avatar: {
    margin: '1px',
  },
}));

export const ContactListSkeleton: React.FC = (props) => {
  const classes = useStyles();
  return (
    <div>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <div key={index} className={classes.alignment}>
            <div className={classes.checkbox}></div>
            <div style={{ width: '60%' }}>
              <Skeleton.Input className={classes.input} />
              <Skeleton.Input className={classes.input} />
            </div>
            <div style={{ marginRight: '20px' }}>
              <Skeleton.Avatar className={classes.avatar} size={'small'} />
              <Skeleton.Avatar className={classes.avatar} size={'small'} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default ContactListSkeleton;
