import { PRIO } from '../../../../constants';
import { MailMenuNavigationState } from '../types';

export const UPDATE_MAIL_LIST_NAVIGATION_ME =
  PRIO + 'UPDATE_MAIL_LIST_NAVIGATION_ME';

export const setMailListNavigationStateMe = (activeMessageId: string) => ({
  type: UPDATE_MAIL_LIST_NAVIGATION_ME,
  activeMessageId,
});

export const UPDATE_MAIL_MENU_NAVIGATION_ME =
  PRIO + 'UPDATE_MAIL_MENU_NAVIGATION_ME';

export const setMailMenuNavigationStateMe = (
  menuState: MailMenuNavigationState
) => ({
  type: UPDATE_MAIL_MENU_NAVIGATION_ME,
  menuState,
});
