import React, { useEffect, useState } from 'react';
import { notification, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { Message } from '../../../models/Message';
import { makePrioStyles } from '../../../theme/utils';
import { apiDeleteMessage, apiFetchMessage } from '../../mail/api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EmailComposer from '../../mail/components/EmailComposer/EmailComposer';
import PrioSpinner from '../../../components/PrioSpinner';
import Flex from '../../../components/Flex';
import WidgetArea from '../../widgetArea/components/WidgetArea';
import {
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  content: {
    height: '100%',
    padding: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    overflow: 'auto',
  },
  mailComposer: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface ViewMailComposerProps {}

export declare type ModalType = 'None' | 'Delete' | 'Send' | 'SaveAndBack';

export const ViewMailComposer: React.FC<ViewMailComposerProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { projectId, messageId } = useParams();

  const { t } = useTranslation();

  const [message, setMessage] = useState<Message>(null);

  const currentProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const userMe = useSelector(getUserMe);

  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (!message && !isError) {
      const loadMessageDraft = async () => {
        const { data } = await apiFetchMessage(projectId, messageId);
        if (data) {
          setMessage(data);
        } else {
          setMessage(null);
          setIsError(true);
        }
      };
      loadMessageDraft();
    }
  }, [message, isError, messageId, projectId, t]);

  useEffect(() => {
    if (projectId) {
      document.title = `Prio365 – ${
        projectId !== 'me'
          ? `${currentProject?.number} ${
              currentProject?.shortName ?? currentProject?.name
            }`
          : `${userMe?.givenName ?? ''} ${userMe?.surname ?? ''}`
      } – ${t('projects:navigation.mail')}`;
    }
  }, [currentProject, projectId, userMe, t]);

  const deleteAndClose = async () => {
    if (!message) return;
    const { result } = await apiDeleteMessage(projectId, messageId);
    if (result.status >= 200 && result.status < 300) {
      setTimeout(() => {
        window.close();
      }, 2000);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.deleteDraftError'),
      });
    }
  };

  const handleSendAfter = (success: boolean) => {
    if (success) {
      setTimeout(() => {
        window.close();
      }, 2000);
    }
  };

  if (!message) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        {isError ? (
          <Typography.Text>
            <Trans i18nKey="mail:errorMessages.messages.loadError" />
          </Typography.Text>
        ) : (
          <PrioSpinner size="large" />
        )}
      </div>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Flex.Row className={classes.root}>
        <Flex.Column
          className={classes.content}
          childrenGap={theme.old.spacing.unit(1)}
          flex={2}
        >
          <EmailComposer
            className={classes.mailComposer}
            message={message}
            onSendAfter={handleSendAfter}
            onCancel={deleteAndClose}
            onBack={() =>
              setTimeout(() => {
                window.close();
              }, 1000)
            }
            showSubject
            projectId={projectId}
            openInNewWindow={false}
          />
        </Flex.Column>
        <WidgetArea selectedProjectId={projectId} disablePersonalMessages />
      </Flex.Row>
    </DndProvider>
  );
};

export default ViewMailComposer;
