import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import { Menu } from '@prio365/prio365-react-library';
import MenuItem from '@prio365/prio365-react-library/lib/Menu/components/MenuItem';
import { NewDocumentType } from '../../../models/Document';
import { apiFetchDocumentPrefix } from '../api';
import { useTranslation } from 'react-i18next';
import { ProjectId } from '../../../models/Types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    padding: 0,
  },
  menuItem: {
    margin: 0,
    fontSize: theme.font.fontSize.small,
    '& .prio-menu-suffixIcon': {
      display: 'flex',
    },
  },
  activeButton: {
    background: `${theme.colors.application.background.hover}!important`,
  },
  wordIcon: {
    '& > div > div > span > svg': {
      color: `${theme.colors.mimeType.word}!important`,
    },
  },
  powerPointIcon: {
    '& > div > div > span > svg': {
      color: theme.colors.mimeType.powerpoint,
    },
  },
  excelIcon: {
    '& > div > div > span > svg': {
      color: theme.colors.mimeType.excel,
    },
  },
}));

interface CreateNewDocumentDropdownOptionsProps {
  className?: string;
  projectId: ProjectId;
  setOpenNewDocDrawer?: (open: boolean) => void;
  setOpenNewDocFromTamplateDrawer?: (open: boolean) => void;
  setIsDropdownNewDocumentOpen?: (open: boolean) => void;
  onDocumentTypeSelect?: (id: NewDocumentType, prefix: string) => void;
}

export const CreateNewDocumentDropdownOptions: React.FC<
  CreateNewDocumentDropdownOptionsProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    projectId,
    setOpenNewDocFromTamplateDrawer,
    setOpenNewDocDrawer,
    setIsDropdownNewDocumentOpen,
    onDocumentTypeSelect,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [prefix, setPrefix] = useState<string>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const newDocument = () => {
    setOpenNewDocFromTamplateDrawer(true);
    if (setIsDropdownNewDocumentOpen) {
      setIsDropdownNewDocumentOpen(false);
    }
  };

  const addNewEmptyDocument = async (id: NewDocumentType | 'template') => {
    if (setIsDropdownNewDocumentOpen) {
      setIsDropdownNewDocumentOpen(false);
    }
    if (id === 'template') {
      newDocument();
      return;
    } else {
      setOpenNewDocDrawer(true);
      onDocumentTypeSelect(id, prefix);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchPrefix = async () => {
      const { data } = await apiFetchDocumentPrefix(projectId);
      if (data) {
        setPrefix(data.value);
      } else {
        setPrefix(null);
      }
    };
    fetchPrefix();
  }, [projectId]);
  //#endregion

  return (
    <Menu className={classNames(className, classes.root)} selectedItemId="">
      <MenuItem
        className={classNames(classes.menuItem, classes.wordIcon)}
        id="docx"
        iconProp={['fas', 'file-word']}
        label={t('documents:newDocumentMenu.word')}
        onClick={() => addNewEmptyDocument('docx')}
      />
      <MenuItem
        className={classNames(classes.menuItem, classes.powerPointIcon)}
        id="pptx"
        iconProp={['fas', 'file-powerpoint']}
        label={t('documents:newDocumentMenu.powerpoint')}
        onClick={() => addNewEmptyDocument('pptx')}
      />
      <MenuItem
        className={classNames(classes.menuItem, classes.excelIcon)}
        id="xlsx"
        iconProp={['fas', 'file-excel']}
        label={t('documents:newDocumentMenu.excel')}
        onClick={() => addNewEmptyDocument('xlsx')}
      />
      <MenuItem
        className={classNames(classes.menuItem)}
        id="template"
        label={t('documents:newDocumentMenu.template')}
        suffixIconProps={['fal', 'arrow-right']}
        onClick={() => addNewEmptyDocument('template')}
      />
    </Menu>
  );
};

export default CreateNewDocumentDropdownOptions;
