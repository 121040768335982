import {
  AuthenticationResult,
  PublicClientApplication,
} from '@azure/msal-browser';
import defaultEffect from '@redux-offline/redux-offline/lib/defaults/effect';
import { configuration } from '../modules/auth/authUtils';
import * as ConfigValues from '../util/configValues';
import i18n from '../i18n';

export default async function effect(
  { headers, body, ...params }: any,
  { requiresAuth, ...action }: any
) {
  headers = requiresAuth
    ? {
        ...headers,
        Authorization: `Bearer ${await getAccessToken()}`,
        'Accepted-Language': i18n.language,
      }
    : headers;

  const req = {
    ...params,
    headers,
    body,
  };
  return defaultEffect(req, action);
}

const msalInstance = new PublicClientApplication(configuration);

const TIMESPAN_TO_CHECK_EXPIRE = 900000;

export const getAccessToken: (
  checkExpire?: boolean,
  setDelayTimespan?: (time: number) => void
) => Promise<string> = async (checkExpire = false, setDelayTimespan) => {
  try {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API

    if (activeAccount) {
      const request = {
        scopes: [`${ConfigValues.SCOPES}`],
        account: activeAccount,
      };

      let authResult: AuthenticationResult = null;
      authResult = await msalInstance.acquireTokenSilent(request);

      if (checkExpire) {
        const now = new Date();
        if (
          authResult.expiresOn.getTime() - TIMESPAN_TO_CHECK_EXPIRE <=
          now.getTime()
        ) {
          authResult = await msalInstance.acquireTokenSilent({
            ...request,
            forceRefresh: true,
          });
        }
      }
      if (setDelayTimespan) {
        const now = new Date();
        setDelayTimespan(
          authResult.expiresOn.getTime() -
            TIMESPAN_TO_CHECK_EXPIRE -
            now.getTime()
        );
      }

      return authResult.accessToken;
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
};

export const logoutWithAccount: () => Promise<string> = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  if (activeAccount) {
    msalInstance.setActiveAccount(null);
    msalInstance.logoutRedirect();
    localStorage.removeItem('teamsLoginInProgress');
  }
  return '';
};
