import React from 'react';
import { Notification } from '../../../models/Notification';
import { makePrioStyles } from '../../../theme/utils';
import { TrainingRequest } from '../../../models/Training';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import ContactText from '../../contacts/components/ContactText';
import { ContactId } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.unit(2),
  },
  secondaryText: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
  },
}));

interface TrainingNotificationItemProps {
  notification: Notification;
  onClose?: VoidFunction;
}

export const TrainingNotificationItem: React.FC<
  TrainingNotificationItemProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const { notification, onClose } = props;

  const link = getLink(notification);

  const render = () => (
    <Flex.Row
      className={classes.root}
      alignItems="center"
      childrenGap={theme.old.spacing.unit(2)}
    >
      <FontAwesomeIcon
        icon={['fal', 'university']}
        color={theme.old.palette.primaryColor}
      />
      <Flex.Column>
        <ContactText
          contactId={getContactId(notification)}
          fallback={
            <Typography.Text>{t('common:unknownContact')}</Typography.Text>
          }
        />
        <Typography.Text className={classes.secondaryText}>
          {getSecondaryText(notification, t)}
        </Typography.Text>
      </Flex.Column>
      {getDetails(notification, t)}
    </Flex.Row>
  );

  if (link) {
    return (
      <Link to={link} onClick={onClose}>
        {render()}
      </Link>
    );
  }
  return render();
};

const getContactId: (notification: Notification) => ContactId = (
  notification
) => {
  const request = notification.data as TrainingRequest;
  return request.trainingRequestState === 'requested'
    ? request.participant.contactId
    : request.approvedBy.contactId;
};
const getSecondaryText: (
  notification: Notification,
  t: TFunction
) => ContactId = (notification, t) => {
  return t(`notifications:secondaryLabels.${notification.type}`);
};

const getDetails: (
  notification: Notification,
  t: TFunction
) => React.ReactNode = (notification, t) => {
  return (
    <>
      <Flex.Item flex={1}></Flex.Item>
      <Flex.Item flex={3}>
        <Typography.Text style={{ fontSize: 12 }}>
          Was soll hier stehen? Der TrainingRequest enthält keine sinnvollen
          Infos
        </Typography.Text>
      </Flex.Item>
    </>
  );
};
const getLink: (notification: Notification) => string = (notification) => {
  return '/module/prio/profile/trainings';
};

export default TrainingNotificationItem;
