import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { HRMenu } from './HRMenu';
import { useSelector } from 'react-redux';
import {
  getGlobalSiderOpen,
  getOfficeMeAllOffices,
  getUserMe,
} from '../../../apps/main/rootReducer';
import useAccessRights from '../../users/hooks/useAccessRights';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  overflow: {
    overflow: 'hidden',
  },
  menuContent: {
    overflow: 'hidden',
    height: '100%',
  },
}));

interface HRModuleNavigationProps {
  className?: string;
  isSubMenu?: boolean;
}

export const HRModuleNavigation: React.FC<HRModuleNavigationProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className, isSubMenu } = props;
  const { selectedList, officeId } = useParams();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const openSider = useSelector(getGlobalSiderOpen);
  const me = useSelector(getUserMe);
  const myOffices = useSelector(getOfficeMeAllOffices);

  const { showGlobalInHrModule } = useAccessRights(['showGlobalInHrModule']);

  const firstOfficeIdWhereController = myOffices.find(
    (office) => me.prioData.officeRoles[office.officeId]?.includes('officeHR')
  )?.officeId;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (!selectedList && !officeId) {
    if (showGlobalInHrModule) {
      return <Navigate to="absence/overview" />;
    } else if (firstOfficeIdWhereController) {
      return (
        <Navigate
          to={`office/${firstOfficeIdWhereController}/absence/overview`}
        />
      );
    }
  } else if (!selectedList && officeId) {
    return <Navigate to={`office/${officeId}/absence/overview`} />;
  }

  return (
    <Flex.Column flexGrow={1} className={classNames(classes.root, className)}>
      <Flex.Column
        childrenGap={theme.old.spacing.unit(1)}
        className={classes.overflow}
      >
        <Flex.Item className={classes.menuContent} flex={1}>
          <HRMenu
            selectedList={selectedList}
            urlPrefix="../"
            officeId={officeId}
            open={openSider}
            isSubMenu={isSubMenu}
          />
        </Flex.Item>
      </Flex.Column>
    </Flex.Column>
  );
};

export default HRModuleNavigation;
