import { InternalOffice } from '../../../models/Office';
import { combineReducers, Reducer } from 'redux';
import { distinct } from '../../../util';
import {
  FETCH_OFFICE_ME_COMMIT,
  FETCH_OFFICE_ME_REQUEST,
  FETCH_OFFICE_ME_ROLLBACK,
} from '../actions/officeMe';
import { OfficeId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface OfficeMeState {
  byId: OfficeMeByIdState;
  ids: OfficeId[];
  meta: OfficeMeMetaState;
}

export interface OfficeMeByIdState {
  [key: string]: InternalOffice;
}

interface OfficeMeMetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

interface OfficeMeAction {
  type: string;
  payload?: InternalOffice[];
}

const byId: Reducer<OfficeMeByIdState, OfficeMeAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case FETCH_OFFICE_ME_COMMIT: {
      const { payload } = action;
      return payload.reduce(function (map, item) {
        map[item.officeId] = item;
        return map;
      }, state);
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const ids: Reducer<Array<string>, OfficeMeAction> = (state = [], action) => {
  switch (action.type) {
    case FETCH_OFFICE_ME_COMMIT: {
      const { payload } = action;
      return distinct([...state, ...payload.map((item) => item.officeId)]);
    }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

const meta: Reducer<OfficeMeMetaState, OfficeMeAction> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_OFFICE_ME_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_OFFICE_ME_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_OFFICE_ME_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'contacts:errorMessages.fetchError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<OfficeMeState>({
  byId,
  ids,
  meta,
});

export const getOfficeMeByIdState: (
  state: OfficeMeState
) => OfficeMeByIdState = (state) => state.byId;
export const getOfficeMeById: (
  state: OfficeMeState,
  officeId: OfficeId
) => InternalOffice = (state, officeId) => state.byId[officeId];
export const getOfficeIds: (state: OfficeMeState) => OfficeId[] = (state) =>
  state.ids;
export const getOfficeMeAllOffices: (
  state: OfficeMeState
) => InternalOffice[] = (state) =>
  state.ids
    .map((id) => state.byId[id])
    .sort((a, b) => a.name.localeCompare(b.name));

export const getIsFetching: (state: OfficeMeState) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: OfficeMeState) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: OfficeMeState) => string = (state) =>
  state.meta.errorMessage;
