import { combineReducers, Reducer } from 'redux';
import {
  FETCH_KILOMETER_RATE_COMMIT,
  UPDATE_KILOMETER_RATE_REQUEST,
  FETCH_KILOMETER_RATE_REQUEST,
  FETCH_KILOMETER_RATE_ROLLBACK,
  UPDATE_KILOMETER_RATE_ROLLBACK,
  UPDATE_KILOMETER_RATE_COMMIT,
} from '../actions';
import { KilometerRate } from '../../../models/KilometerRate';
import { ProjectId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface KilometerRatesState {
  data: DataState;
  meta: KilometerRatesMeta;
}

export interface DataState {
  [projectId: string]: KilometerRate;
}

const data: Reducer<DataState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_KILOMETER_RATE_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: payload,
      };
    }

    /** CREATE */

    case UPDATE_KILOMETER_RATE_REQUEST: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...payload,
        },
      };
    }

    case UPDATE_KILOMETER_RATE_ROLLBACK: {
      const {
        meta: { projectId },
        rollbackKilometerRate,
      } = action;
      if (!rollbackKilometerRate) return state;
      return {
        ...state,
        [projectId]: {
          ...rollbackKilometerRate,
        },
      };
    }

    case UPDATE_KILOMETER_RATE_COMMIT: {
      const {
        meta: { projectId },
        payload: kilometerRate,
      } = action;
      return {
        ...state,
        [projectId]: kilometerRate,
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface KilometerRatesMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<KilometerRatesMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_KILOMETER_RATE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_KILOMETER_RATE_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_KILOMETER_RATE_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'projects:errorMessages.fetchKilometerRatesError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<KilometerRatesState>({
  data,
  meta,
});

export const getData: (state: any) => DataState = (state: any) => state.data;
export const getKilometerRate: (
  state: any,
  projectId: ProjectId
) => KilometerRate = (state: any, projectId: ProjectId) =>
  state.data[projectId];

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
