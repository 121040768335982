import { combineReducers } from 'redux';
import auth, * as fromAuth from '../../modules/auth/reducers';
import contacts, * as fromContacts from '../../modules/contacts/reducers';
import notifications, * as fromNotifications from '../../modules/notifications/reducers';
import companies, * as fromCompanies from '../../modules/companies/reducers';
import projects, * as fromProjects from '../../modules/projects/reducers';
import mail, * as fromMail from '../../modules/mail/reducers';
import timeRecords, * as fromTimeRecords from '../../modules/timeRecords/reducers';
import hr, * as fromHR from '../../modules/hr/reducers';
import controlling, * as fromControlling from '../../modules/controlling/reducers';
import users, * as fromUsers from '../../modules/users/reducers';
import settings, * as fromSettings from '../../modules/settings/reducers';
import profile, * as fromProfile from '../../modules/profile/reducers';
import documents, * as fromDocuments from '../../modules/documents/reducers';
import zendesk, * as fromZendesk from '../../modules/zendesk/reducers';
import widgetArea, * as fromWidgetArea from '../../modules/widgetArea/reducers';
import userSettings, * as fromUserSettings from '../../modules/userSettings/reducers/index';
import doodle, * as fromDoodle from '../../modules/doodles/reducers/index';
import prioVersion, * as fromPrioVersion from '../../version';
import theme, * as fromTheme from '../../theme/reducers';
import dashboard, * as fromDashboard from '../../modules/dashboard/reducers';
import timeKeeping, * as fromTimeKeeping from '../../modules/timeKeeping/reducers';
import timeAndLeaveManagement, * as fromTimeAndLeaveManagement from '../../modules/timeAndLeaveManagement/reducers';
import absences, * as fromAbsences from '../../modules/absences/reducers';
import filter, * as fromFilter from '../../components/Filter/reducers';
import { Company } from '../../models/Company';
import { ExternalOffice, InternalOffice, Office } from '../../models/Office';
import { Contact, ExternalContact } from '../../models/Contact';
import { MailFolder } from '../../models/MailFolder';
import { Message } from '../../models/Message';
import { Project, ProjectNews } from '../../models/Project';
import {
  ProjectId,
  ContactId,
  GlobalRole,
  ProjectRole,
  ProjectFileSystemStructureId,
  MailCategoryColorName,
  DriveItemId,
  TextBlockId,
  NotificationId,
  MessageId,
  MailFolderId,
  OfficeId,
  CompanyId,
  DriveUserRemoteItemId,
  SubMenuStateType,
  GroupId,
  DashboardItemId,
  DashboardId,
  AbsenceProposalId,
  DateTimeString,
  TimeRecordId,
  TimeKeepingDayId,
  DriveFavoriteId,
} from '../../models/Types';
import {
  InternalProjectContact,
  ExternalProjectContact,
} from '../../models/ProjectContacts';
import * as fromInternalProjectsContacts from '../../modules/projects/reducers/internalProjectContacts';
import * as fromExternalProjectsContacts from '../../modules/projects/reducers/externalProjectContacts';
import { Notification } from '../../models/Notification';
import { HourlyRate } from '../../models/HourlyRate';
import { KilometerRate } from '../../models/KilometerRate';
import { ContactsDrawerState } from '../../modules/contacts/reducers/drawer';
import { ContactsByIdState } from '../../modules/contacts/reducers/contacts';
import { TimeRecord } from '../../models/TimeRecord';
import { User } from '../../models/User';
import { ProjectByIdState } from '../../modules/projects/reducers/projects';
import {
  ContactClassification,
  CompanyClassification,
  ProjectFileSystemStructure,
} from '../../models/Settings';
import { AddUserDrawerState } from '../../modules/users/reducers/addUserDrawer';
import { TitleSuggestion } from '../../models/TitleSuggestion';
import { InternalJobTitleSuggestion } from '../../models/InternalJobTitleSuggestion';
import { ExternalJobTitleSuggestion } from '../../models/ExternalJobTitleSuggestion';
import { DocumentTemplate } from '../../models/DocumentTemplate';
import { CompaniesByIdState } from '../../modules/companies/reducers/companies';
import { TimeRecordsByIdState } from '../../modules/timeRecords/reducers/timeRecords';
import { UsersByIdState } from '../../modules/users/reducers/users';
import { TextBlock } from '../../models/TextBlock';
import { NotificationsDrawerState } from '../../modules/notifications/reducers/drawer';
import { NotificationsByIdState } from '../../modules/notifications/reducers/notifications';
import { ProjectDistributionList } from '../../models/ProjectDistributionList';
import { ProjectDistributionListDrawerState } from '../../modules/projects/reducers/projectDistributionList';
import {
  MessageIdsByMailFolderId,
  ByMessageId,
  SpecialMailFolders,
  MailListNavigationState,
  MailFolderByMailFolderId,
} from '../../modules/mail/actions/types';
import { OfficeMeByIdState } from '../../modules/users/reducers/officeMe';
import { ZendeskDrawerView } from '../../modules/zendesk/actions/drawer';
import { ZendeskSearchResult } from '../../models/Zendesk';
import { OfficesByIdState } from '../../modules/companies/reducers/offices';
import { AdvancedMailSearchDto } from '../../models/MailSearch';
import { MailSettings } from '../../models/UserSettings/MailSettings';
import {
  ByProjectId,
  NextLinkState,
} from '../../modules/mail/reducers/projects/messages';
import { SpecialMailFolderByProjectId } from '../../modules/mail/reducers/projects/specialMailFolder';
import { DoodleType } from '../../models/Doodle';
import { DocumentSettings } from '../../models/UserSettings/DocumentSettings';
import { PrioThemeReduxState } from '../../theme/types';
import { Dashboard, DashboardItem } from '../../models/Dashboard';
import { AbsenceProposal, OfficeHoliday } from '../../models/AbsenceProposal';
import {
  AbsenceOverviewState,
  AbsencesById,
} from '../../modules/absences/reducers/absences';
import { TimeAndLeaveDrawerState } from '../../modules/timeAndLeaveManagement/reducers/drawer';
import {
  MonthlyClose,
  MonthlyCloseAbsence,
  TimeKeepingDay,
} from '../../models/TimeKeeping';
import { TimeKeepingByIdState } from '../../modules/timeKeeping/reducers/timeKeepingDaysMe';
import { FilterBarData, SearchType } from '../../components/Filter/types';

export interface RootReducerState {
  auth: fromAuth.AuthState;
  absences: fromAbsences.AbsenceReducerState;
  contacts: fromContacts.ContactsReducerState;
  companies: fromCompanies.CompaniesReducerState;
  dashboard: fromDashboard.DashboardReducerState;
  projects: fromProjects.ProjectsReducerState;
  mail: fromMail.MailState;
  timeRecords: fromTimeRecords.TimeRecordsReducerState;
  timeKeeping: fromTimeKeeping.TimeKeepingState;
  timeAndLeaveManagement: fromTimeAndLeaveManagement.TimeAndLeaveManagementState;
  hr: fromHR.HRState;
  controlling: fromControlling.AccountingReducerState;
  users: fromUsers.UserReducerState;
  settings: fromSettings.SettingsReducerState;
  profile: fromProfile.ProfileReducerState;
  documents: fromDocuments.DocumentsReducerState;
  notifications: fromNotifications.NotificationsReducerState;
  zendesk: fromZendesk.ZendeskReducerState;
  widgetArea: fromWidgetArea.WidgetAreaReducerState;
  userSettings: fromUserSettings.UserSettingsState;
  doodle: fromDoodle.DoodleState;
  theme: PrioThemeReduxState;
  prioVersion: string;
  filter: fromFilter.FilterReducerState;
}

export default combineReducers<RootReducerState>({
  auth,
  absences,
  contacts,
  companies,
  dashboard,
  projects,
  mail,
  timeRecords,
  timeKeeping,
  timeAndLeaveManagement,
  hr,
  controlling,
  users,
  settings,
  profile,
  documents,
  notifications,
  zendesk,
  widgetArea,
  userSettings,
  doodle,
  theme,
  prioVersion,
  filter,
});

//#region ------------------------------ Auth
export const isLoggedIn = (state: RootReducerState) =>
  fromAuth.isLoggedIn(state.auth);

export const getIsInitialDataLoading = (state: RootReducerState) =>
  fromAuth.isInitialDataLoading(state.auth);
//#endregion

//#region ------------------------------ Absences
export const getAbsenceByIdState: (state: RootReducerState) => AbsencesById = ({
  absences,
}) => fromAbsences.getAbsenceByIdState(absences);

export const getAbsenceIds: (
  state: RootReducerState
) => AbsenceProposalId[] = ({ absences }) =>
  fromAbsences.getAbsenceIds(absences);

export const getAbsenceProposalsMe: (
  state: RootReducerState
) => AbsenceProposal[] = ({ absences }) =>
  fromAbsences.getAbsenceProposalsMe(absences);

export const getAbsenceProposalMe: (
  state: RootReducerState,
  absenceProposalId: AbsenceProposalId
) => AbsenceProposal = ({ absences }, absenceProposalId) =>
  fromAbsences.getAbsenceProposalMe(absences, absenceProposalId);

export const getAbsenceOverview: (
  state: RootReducerState
) => AbsenceOverviewState = ({ absences }) =>
  fromAbsences.getAbsenceOverview(absences);

export const getAbsencesMeIsFetching: (state: RootReducerState) => boolean = ({
  absences,
}) => fromAbsences.getAbsencesMeIsFetching(absences);

export const getOfficeHolidays: (
  state: RootReducerState
) => OfficeHoliday[] = ({ absences }) =>
  fromAbsences.getOfficeHolidays(absences);

export const getOfficeHolidaysOfMonth: (
  state: RootReducerState,
  dateTime: DateTimeString
) => OfficeHoliday[] = (state, dateTime) =>
  fromAbsences.getOfficeHolidaysOfMonth(state.absences, dateTime);

export const getOfficeHolidaysOfTimeRange: (
  state: RootReducerState,
  from: DateTimeString,
  to: DateTimeString
) => OfficeHoliday[] = (state, from, to) =>
  fromAbsences.getOfficeHolidaysOfTimeRange(state.absences, from, to);

export const getAbsenceTimelineSelectedEmployees: (
  state: RootReducerState,
  officeId: OfficeId | null
) => ContactId[] = (state, officeId) =>
  fromAbsences.getAbsenceTimelineSelectedEmployees(state.absences, officeId);
//#endregion

//#region ------------------------------ Company
export const getCompanyOffset: (state: RootReducerState) => string | null = (
  state
) => fromCompanies.getOffset(state.companies);

export const getCompanyLastSync: (state: RootReducerState) => string = (
  state
) => fromCompanies.getLastSync(state.companies);

export const getCompaniesByIdState: (
  state: RootReducerState
) => CompaniesByIdState = (state) =>
  fromCompanies.getCompaniesByIdState(state.companies);
export const getCompanyIds: (state: RootReducerState) => string[] = (state) =>
  fromCompanies.getCompanyIds(state.companies);
export const getAllCompanies: (state: RootReducerState) => Array<Company> = (
  state
) => fromCompanies.getAllCompanies(state.companies);
export const getCompany: (
  state: RootReducerState,
  companyId: string
) => Company = (state, companyId) =>
  fromCompanies.getCompany(state.companies, companyId);
export const getCompanies: (
  state: RootReducerState,
  companyIds: CompanyId[]
) => Company[] = (state, companyIds) =>
  companyIds.map((companyId) =>
    fromCompanies.getCompany(state.companies, companyId)
  );
export const getCompanyRedirect: (
  state: RootReducerState,
  temporaryId: string
) => string = (state, temporaryId) =>
  fromCompanies.getCompanyRedirect(state.companies, temporaryId);
export const getCompaniesIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromCompanies.getCompaniesIsFetching(state.companies);
export const getCompaniesHasError: (state: RootReducerState) => boolean = (
  state
) => fromCompanies.getCompaniesHasError(state.companies);
export const getCompaniesErrorMessage: (state: RootReducerState) => string = (
  state
) => fromCompanies.getCompaniesErrorMessage(state.companies);

//#region ------------------------------ All Offices
export const getOfficesByIdState: (
  state: RootReducerState
) => OfficesByIdState = (state) => ({
  ...fromCompanies.getInternalOfficesByIdState(state.companies),
  ...fromCompanies.getExternalOfficesByIdState(state.companies),
});

export const getAllOffices: (state: RootReducerState) => Office[] = (state) =>
  fromCompanies
    .getAllInternalOffices(state.companies)
    .concat(fromCompanies.getAllExternalOffices(state.companies));

export const getOffice: (
  state: RootReducerState,
  officeId: OfficeId
) => Office = (state, officeId) => {
  const internalOffice = fromCompanies.getInternalOffice(
    state.companies,
    officeId
  );
  const externalOffice = fromCompanies.getExternalOffice(
    state.companies,
    officeId
  );
  if (!(internalOffice || externalOffice)) {
    return undefined;
  }
  return internalOffice ?? externalOffice;
};

export const getOfficeIds: (state: RootReducerState) => string[] = (
  state: RootReducerState
) =>
  fromCompanies
    .getInternalOfficeIds(state.companies)
    .concat(fromCompanies.getExternalOfficeIds(state.companies));

export const getOfficesIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromCompanies.getOfficesIsFetching(state.companies);

export const getOfficesHasError: (state: RootReducerState) => boolean = (
  state
) => fromCompanies.getOfficesHasError(state.companies);

export const getOfficesErrorMessages: (state: RootReducerState) => string = (
  state
) => fromCompanies.getOfficesErrorMessages(state.companies);

//#region ------------------------------ Internal Office
export const getInternalOfficesByIdState: (
  state: RootReducerState
) => OfficesByIdState = (state) =>
  fromCompanies.getInternalOfficesByIdState(state.companies);

export const getInternalOfficeIds: (state: RootReducerState) => string[] = (
  state
) => fromCompanies.getInternalOfficeIds(state.companies);

export const getInternalOffice: (
  state: RootReducerState,
  officeId: OfficeId
) => InternalOffice = (state, officeId) =>
  fromCompanies.getInternalOffice(state.companies, officeId);

export const getAllInternalOffices: (
  state: RootReducerState
) => InternalOffice[] = (state) =>
  fromCompanies.getAllInternalOffices(state.companies);

export const getInternalOfficeIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromCompanies.getInternalOfficesIsFetching(state.companies);

export const getInternalOfficeHasError: (state: RootReducerState) => boolean = (
  state
) => fromCompanies.getInternalOfficesHasError(state.companies);

export const getInternalOfficeErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromCompanies.getInternalOfficesErrorMessage(state.companies);

//#region ------------------------------ External Office
export const getExternalOfficesByIdState: (
  state: RootReducerState
) => OfficesByIdState = (state) =>
  fromCompanies.getExternalOfficesByIdState(state.companies);

export const getExternalOfficeIds: (state: RootReducerState) => string[] = (
  state
) => fromCompanies.getExternalOfficeIds(state.companies);

export const getExternalOffice: (
  state: RootReducerState,
  officeId: OfficeId
) => ExternalOffice = (state, officeId) =>
  fromCompanies.getExternalOffice(state.companies, officeId);

export const getAllExternalOffices: (
  state: RootReducerState
) => InternalOffice[] = (state) =>
  fromCompanies.getAllExternalOffices(state.companies);

export const getExternalOfficeIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromCompanies.getExternalOfficesIsFetching(state.companies);

export const getExternalOfficeHasError: (state: RootReducerState) => boolean = (
  state
) => fromCompanies.getExternalOfficesHasError(state.companies);

export const getExternalOfficeErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromCompanies.getExternalOfficesErrorMessage(state.companies);

//#endregion

//#region ------------------------------ Contact
export const getContactOffset: (state: RootReducerState) => string | null = (
  state
) => fromContacts.getOffset(state.contacts);
export const getContactLastSync: (state: RootReducerState) => string = (
  state
) => fromContacts.getLastSync(state.contacts);
export const getAllContacts: (state: RootReducerState) => Array<Contact> = (
  state
) => fromContacts.getAllContacts(state.contacts);
export const getContact: (
  state: RootReducerState,
  contactId: string
) => Contact = (state, contactId) =>
  fromContacts.getContact(state.contacts, contactId);
export const getContacts: (
  state: RootReducerState,
  contactIds: ContactId[]
) => Contact[] = (state, contactIds) =>
  (contactIds ?? []).map((contactId) =>
    fromContacts.getContact(state.contacts, contactId)
  );
export const getContactRedirect: (
  state: RootReducerState,
  temporaryId: string
) => string = (state, temporaryId) =>
  fromContacts.getContactRedirect(state.contacts, temporaryId);
export const getContactsIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromContacts.getContactsIsFetching(state.contacts);
export const getContactsHasError: (state: RootReducerState) => boolean = (
  state
) => fromContacts.getContactsHasError(state.contacts);
export const getContactsErrorMessage: (state: RootReducerState) => string = (
  state
) => fromContacts.getContactsErrorMessage(state.contacts);
export const getContactsDrawerState: (
  state: RootReducerState
) => ContactsDrawerState = (state) =>
  fromContacts.getDrawerState(state.contacts);
export const getContactsDrawerOpen: (state: RootReducerState) => boolean = (
  state
) => fromContacts.getDrawerOpen(state.contacts);

export const getContactsByIdState: (
  state: RootReducerState
) => ContactsByIdState = (state) =>
  fromContacts.getContactsByIdState(state.contacts);

export const getContactIdsState: (state: RootReducerState) => ContactId[] = (
  state
) => fromContacts.getContactIdsState(state.contacts);

export const getContactsByCompanyId: (
  state: RootReducerState,
  companyId: string
) => Array<ExternalContact> = (state, companyId) =>
  fromContacts.getContactsByCompanyId(state.contacts, companyId);

export const getContactsByOfficeIds: (
  state: RootReducerState,
  officeIds: string[]
) => Array<Contact> = (state, officeIds) =>
  fromContacts.getContactsByOfficeIds(state.contacts, officeIds);

export const getLastContactsLocation: (state: RootReducerState) => string = (
  state
) => fromContacts.getLastContactsLocation(state.contacts);
//#endregion

//#region ------------------------------ Mail
export const getAllMailFolders: (
  state: RootReducerState,
  projectId: string
) => Array<MailFolder> = (state, projectId) =>
  fromMail.getAllMailFolders(state.mail, projectId);
export const getMailFoldersByIdState: (
  state: RootReducerState,
  projectId: string
) => MailFolderByMailFolderId = (state, projectId) =>
  fromMail.getMailFoldersByIdState(state.mail, projectId);
export const getMailFolder: (
  state: RootReducerState,
  id: string,
  projectId: string
) => MailFolder = (state, id, projectId) =>
  fromMail.getMailFolder(state.mail, id, projectId);
export const getMailFoldersIsFetching: (
  state: RootReducerState,
  projectId: string
) => boolean = (state, projectId) =>
  fromMail.getMailFoldersIsFetching(state.mail, projectId);
export const getMailFoldersHasError: (
  state: RootReducerState,
  projectId: string
) => boolean = (state, projectId) =>
  fromMail.getMailFoldersHasError(state.mail, projectId);
export const getMailFoldersErrorMessage: (
  state: RootReducerState,
  projectId: string
) => string = (state, projectId) =>
  fromMail.getMailFoldersErrorMessage(state.mail, projectId);

export const getAllMessages: (
  state: RootReducerState,
  projectId: string,
  mailFolderId: string
) => Array<Message> = (state, projectId, mailFolderId) =>
  fromMail.getAllMessages(state.mail, projectId, mailFolderId);

export const getMessageIdsByProjecId: (
  state: RootReducerState
) => ByProjectId = (state) => fromMail.getMessageIdsByProjecId(state.mail);

export const getMessage: (
  state: RootReducerState,
  projectId: string,
  id: string
) => Message = (state, projectId, id) =>
  fromMail.getMessage(state.mail, projectId, id);
export const getMessagesById: (
  state: any,
  projectId: string
) => { [messageId: string]: Message } = (state, projectId) =>
  fromMail.getMessagesById(state.mail, projectId);

export const getMessagesIsFetching: (
  state: RootReducerState,
  projectId: string,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromMail.getMessagesIsFetching(state.mail, projectId, mailFolderId);
export const getMessagesHasError: (
  state: RootReducerState,
  projectId: string
) => boolean = (state, projectId) =>
  fromMail.getMessagesHasError(state.mail, projectId);
export const getMessagesErrorMessage: (
  state: RootReducerState,
  projectId: string
) => string = (state, projectId) =>
  fromMail.getMessagesErrorMessage(state.mail, projectId);

export const getIsSingleMessageFetching: (
  state: RootReducerState,
  projectId: string
) => boolean = (state, projectId) =>
  fromMail.getIsSingleMessageFetching(state.mail, projectId);

export const getMessagesNextLink: (
  state: RootReducerState,
  projectId: string,
  mailFolderId: string
) => string = (state, projectId, mailFolderId) =>
  fromMail.getMessagesNextLink(state.mail, projectId, mailFolderId);

export const getProjectsNextLinkState: (
  state: RootReducerState
) => NextLinkState = (state) => fromMail.getProjectsNextLinkState(state.mail);

export const getActiveMailFolderId: (
  state: RootReducerState,
  projectId: string
) => string = (state, projectId) =>
  fromMail.getActiveMailFolderId(state.mail, projectId);

export const getOpenMailFolderKeys: (
  state: RootReducerState,
  projectId: ProjectId
) => MailFolderId[] = (state, projectId) =>
  fromMail.getOpenMailFolderKeys(state.mail, projectId);

export const getMessagesByIdState: (
  state: RootReducerState,
  projectId: string
) => ByMessageId = (state, projectId) =>
  fromMail.getMessagesByIdState(state.mail, projectId);

export const getMessagesIdsState: (
  state: RootReducerState,
  projectId: string
) => MessageIdsByMailFolderId = (state, projectId) =>
  fromMail.getMessagesIdsState(state.mail, projectId);

export const getDraftMessages: (
  state: RootReducerState,
  projectId: ProjectId
) => Message[] = (state, projectId) =>
  fromMail.getDraftMessages(state.mail, projectId);

export const getDraftMessage: (
  state: RootReducerState,
  projectId: ProjectId,
  messageId: MessageId
) => Message = (state, projectId, messageId) =>
  fromMail.getDraftMessage(state.mail, projectId, messageId);

export const getDraftMessageIsInitial: (
  state: RootReducerState,
  projectId: ProjectId,
  messageId: MessageId
) => boolean = (state, projectId, messageId) =>
  fromMail.getDraftMessageIsInitial(state.mail, projectId, messageId);

export const getActiveDraftMessageId: (
  state: RootReducerState,
  projectId: ProjectId
) => MessageId = (state, projectId) =>
  fromMail.getActiveDraftMessageId(state.mail, projectId);

export const getSendingMessageIdsProjects: (
  state: RootReducerState
) => MessageId[] = (state) => fromMail.getSendingMessageIdsProjects(state.mail);

export const getSendingMessageIdsMe: (
  state: RootReducerState
) => MessageId[] = (state) => fromMail.getSendingMessageIdsMe(state.mail);

export const getMessageCategoryColorMap: (
  state: RootReducerState,
  projectId: ProjectId
) => { [displayName: string]: MailCategoryColorName } = (state, projectId) =>
  fromMail.getMessageCategoryColorMap(state.mail, projectId);

export const getMailSearchIsFetching: (
  state: RootReducerState,
  projectId: ProjectId,
  mailFolderId?: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromMail.getMailSearchIsFetching(state.mail, projectId, mailFolderId);
export const getMailSearchHasError: (
  state: RootReducerState,
  projectId: ProjectId
) => boolean = (state, projectId) =>
  fromMail.getMailSearchHasError(state.mail, projectId);
export const getMailSearchErrorMessage: (
  state: RootReducerState,
  projectId: ProjectId
) => string = (state, projectId) =>
  fromMail.getMailSearchErrorMessage(state.mail, projectId);

export const getMailSearchByIdsState: (
  state: RootReducerState,
  projectId: ProjectId
) => ByMessageId = (state, projectId) =>
  fromMail.getSearchMessageById(state.mail, projectId);
export const getMailSearchIds: (
  state: RootReducerState,
  projectId: ProjectId
) => string[] = (state, projectId) =>
  fromMail.getSearchMessageIds(state.mail, projectId);
export const getMailSearchIdsState: (
  state: RootReducerState,
  projectId: ProjectId
) => { [projectId: ProjectId]: string[] } = (state, projectId) =>
  fromMail.getSearchMessageIdsState(state.mail, projectId);

export const getSearchMessage: (
  state: RootReducerState,
  projectId: ProjectId,
  id: MessageId
) => Message = (state, projectId, id) =>
  fromMail.getSearchMessage(state.mail, projectId, id);

export const getSearchMessages: (
  state: RootReducerState,
  projectId: ProjectId
) => Message[] = (state, projectId) =>
  fromMail.getSearchMessages(state.mail, projectId);

export const getMailSearchNextLink: (
  state: RootReducerState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => string = (state, projectId, mailFolderId) =>
  fromMail.getMailSearchNextLink(state.mail, projectId, mailFolderId);

export const getMailCountByProjectIdAndFolderId: (
  state: RootReducerState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => string = (state, projectId, mailFolderId) =>
  fromMail.getMailCountByProjectIdAndFolderId(
    state.mail,
    projectId,
    mailFolderId
  );

export const getlastSearchTermMail: (
  state: RootReducerState,
  projectId: ProjectId
) => string | AdvancedMailSearchDto = (state, projectId) =>
  fromMail.getlastSearchTermMail(state.mail, projectId);

export const getCurrentSearchTerm: (
  state: RootReducerState,
  projectId: ProjectId
) => string | AdvancedMailSearchDto = (state, projectId) =>
  fromMail.getCurrentSearchTerm(state.mail, projectId);

export const getCurrentSearchTermState = (state: RootReducerState) =>
  fromMail.getCurrentSearchTermState(state.mail);

export const getCurrentSearchMailFolderId: (
  state: RootReducerState,
  projectId: ProjectId
) => MailFolderId = (state, projectId) =>
  fromMail.getCurrentSearchMailFolderId(state.mail, projectId);

export const getCurrentSearchMailFolderIdState = (state: RootReducerState) =>
  fromMail.getCurrentSearchMailFolderIdState(state.mail);

export const getCurrentSearchKeywords: (
  state: RootReducerState,
  projectId: ProjectId
) => string = (state, projectId) =>
  fromMail.getCurrentSearchKeywords(state.mail, projectId);

export const getProjectsSpecialMailFoldersState: (
  state: RootReducerState
) => SpecialMailFolderByProjectId = (state) =>
  fromMail.getProjectsSpecialMailFoldersState(state.mail);

export const getSpecialMailFolders: (
  state: RootReducerState,
  projectId: ProjectId
) => SpecialMailFolders = (state, projectId) =>
  fromMail.getSpecialMailFolders(state.mail, projectId);

export const isMailDeletedFolder: (
  state: RootReducerState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromMail.isDeletedFolder(state.mail, projectId, mailFolderId);

export const isMailDraftFolder: (
  state: RootReducerState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromMail.isDraftFolder(state.mail, projectId, mailFolderId);

export const isMailSendFolder: (
  state: RootReducerState,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  fromMail.isSendFolder(state.mail, projectId, mailFolderId);

export const getMailListNavigationState: (
  state: RootReducerState,
  projectId: ProjectId
) => MailListNavigationState = (state, projectId) =>
  fromMail.getMailListNavigationState(state.mail, projectId);

export const isActiveMailEntry: (
  state: RootReducerState,
  projectId: ProjectId,
  messageId: MessageId
) => boolean = (state, projectId, messageId) =>
  fromMail.isActiveMailEntry(state.mail, projectId, messageId);

export const isActiveScrollableMailEntry: (
  state: RootReducerState,
  projectId: ProjectId,
  messageId: MessageId
) => boolean = (state, projectId, messageId) =>
  fromMail.isActiveScrollableMailEntry(state.mail, projectId, messageId);

export const getActiveMessageId: (
  state: RootReducerState,
  projectId: ProjectId
) => MessageId = (state, projectId) =>
  fromMail.getActiveMessageId(state.mail, projectId);

export const getMailMenuOpen: (
  state: RootReducerState,
  projectId: ProjectId
) => boolean = (state, projectId) =>
  fromMail.getMailMenuOpen(state.mail, projectId);

export const getActiveProjectInMessageCenter: (
  state: RootReducerState
) => ProjectId = (state) =>
  fromMail.getActiveProjectInMessageCenter(state.mail);

export const getMailToEmlIsCreating: (
  state: RootReducerState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMail.getMailToEmlIsCreating(state.mail, messageId);

export const getMailsToEmlAreCreating: (
  state: RootReducerState
) => MessageId[] = (state) => fromMail.getMailsToEmlAreCreating(state.mail);

export const getMessageIsMoving: (
  state: RootReducerState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMail.getMessageIsMoving(state.mail, messageId);

export const getMovingMessages: (state: RootReducerState) => MessageId[] = (
  state
) => fromMail.getMovingMessages(state.mail);

export const getCommentsByConversationId = (
  state: RootReducerState,
  conversationId: string
) => fromMail.getCommentsByConversationId(state.mail, conversationId);

export const getCommentById = (state: RootReducerState, commentId: string) =>
  fromMail.getCommentById(state.mail, commentId);

export const getIsFetchingComments = (state: RootReducerState) =>
  fromMail.getIsFetchingComments(state.mail);

export const getCommentsError = (state: RootReducerState) =>
  fromMail.getCommentsError(state.mail);
//#endregion

//#region ------------------------------ Projects
export const getAllProjects: (state: RootReducerState) => Array<Project> = (
  state
) => fromProjects.getAllProjects(state.projects);
export const getProject: (
  state: RootReducerState,
  projectId: string
) => Project = (state, projectId) =>
  fromProjects.getProject(state.projects, projectId);
export const getProjects: (
  state: RootReducerState,
  projectIds: ProjectId[]
) => Project[] = (state, projectIds) =>
  projectIds.map((projectId) =>
    fromProjects.getProject(state.projects, projectId)
  );
export const getProjectsAndParentProjects: (
  state: RootReducerState,
  projectIds: ProjectId[]
) => Project[] = (state, projectIds) => {
  let missingParentProjects: Project[] = [];
  const projects = projectIds.map((projectId, index, _projectIds) => {
    const project = fromProjects.getProject(state.projects, projectId);
    if (
      project?.parentProject &&
      !_projectIds.includes(project.parentProject) &&
      !missingParentProjects.some((p) => p.projectId === project.parentProject)
    ) {
      missingParentProjects.push(
        fromProjects.getProject(state.projects, project.parentProject)
      );
    }
    return project;
  });
  return projects.concat(missingParentProjects);
};
export const getProjectByIdState: (
  state: RootReducerState
) => ProjectByIdState = (state) =>
  fromProjects.getProjectByIdState(state.projects);
export const getProjectRedirect: (
  state: RootReducerState,
  temporaryId: string
) => string = (state, temporaryId) =>
  fromProjects.getProjectRedirect(state.projects, temporaryId);
export const getProjectsIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromProjects.getProjectsIsFetching(state.projects);
export const getProjectsHasError: (state: RootReducerState) => boolean = (
  state
) => fromProjects.getProjectsHasError(state.projects);
export const getProjectsErrorMessage: (state: RootReducerState) => string = (
  state
) => fromProjects.getProjectsErrorMessage(state.projects);

export const getInternalProjectContactsData: (
  state: RootReducerState
) => fromInternalProjectsContacts.DataState = (state) =>
  fromProjects.getInternalProjectContactsData(state.projects);

export const getMyProjectIds: (state: RootReducerState) => ProjectId[] = (
  state
) => fromProjects.getMyProjectIds(state.projects);

export const getAllProjectIds: (state: RootReducerState) => ProjectId[] = (
  state
) => fromProjects.getAllProjectIds(state.projects);

export const getInternalProjectRoles: (
  state: RootReducerState,
  projectId: ProjectId
) => ProjectRole[] = (state, projectId) =>
  fromProjects.getInternalProjectRoles(
    state.projects,
    projectId,
    fromUsers.getUserMeContactId(state.users)
  );

export const getAllInternalProjectContacts: (
  state: RootReducerState,
  projectId: ProjectId
) => InternalProjectContact[] = (
  state: RootReducerState,
  projectId: ProjectId
) => fromProjects.getAllInternalProjectContacts(state.projects, projectId);
export const getInternalProjectContactsIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromProjects.getInternalProjectContactsIsFetching(state.projects);
export const getInternalProjectContactsHasError: (
  state: RootReducerState
) => boolean = (state) =>
  fromProjects.getInternalProjectContactsHasError(state.projects);
export const getInternalProjectContactsErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromProjects.getInternalProjectContactsErrorMessage(state.projects);
export const isInternalProjectMember: (
  state: RootReducerState,
  projectId: ProjectId,
  contactId: ContactId
) => boolean = (state, projectId, contactId) =>
  fromProjects.isInternalProjectMember(state.projects, projectId, contactId);
export const userMeIsInternalProjectMember: (
  state: RootReducerState,
  projectId: ProjectId
) => boolean = (state, projectId) =>
  fromProjects.isInternalProjectMember(
    state.projects,
    projectId,
    getUserMeContactId(state)
  );

export const getExternalProjectContactsData: (
  state: RootReducerState
) => fromExternalProjectsContacts.DataState = (state) =>
  fromProjects.getExternalProjectContactsData(state.projects);

export const getAllExternalProjectContacts: (
  state: RootReducerState,
  projectId: ProjectId
) => ExternalProjectContact[] = (
  state: RootReducerState,
  projectId: ProjectId
) => fromProjects.getAllExternalProjectContacts(state.projects, projectId);
export const getExternalProjectContactsIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromProjects.getExternalProjectContactsIsFetching(state.projects);
export const getExternalProjectContactsHasError: (
  state: RootReducerState
) => boolean = (state) =>
  fromProjects.getExternalProjectContactsHasError(state.projects);
export const getExternalProjectContactsErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromProjects.getExternalProjectContactsErrorMessage(state.projects);

export const getAllHourlyRates: (
  state: RootReducerState,
  projectId: ProjectId
) => HourlyRate[] = (state: RootReducerState, projectId: ProjectId) =>
  fromProjects.getAllHourlyRates(state.projects, projectId);
export const getHourlyRatesIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromProjects.getHourlyRatesIsFetching(state.projects);
export const getHourlyRatesHasError: (state: RootReducerState) => boolean = (
  state
) => fromProjects.getHourlyRatesHasError(state.projects);
export const getHourlyRatesErrorMessage: (state: RootReducerState) => string = (
  state
) => fromProjects.getHourlyRatesErrorMessage(state.projects);

export const getKilometerRate: (
  state: RootReducerState,
  projectId: ProjectId
) => KilometerRate = (state: RootReducerState, projectId: ProjectId) =>
  fromProjects.getKilometerRate(state.projects, projectId);
export const getKilometerRateIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromProjects.getKilometerRateIsFetching(state.projects);
export const getKilometerRateHasError: (state: RootReducerState) => boolean = (
  state
) => fromProjects.getKilometerRateHasError(state.projects);
export const getKilometerRateErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromProjects.getKilometerRateErrorMessage(state.projects);

export const getActiveProject: (state: RootReducerState) => string = (state) =>
  fromProjects.getActiveProject(state.projects);

export const getActiveProjectWithoutMe: (state: RootReducerState) => string = (
  state
) => {
  const projectId = fromProjects.getActiveProject(state.projects);
  return projectId === 'me' ? null : projectId;
};
export const getActiveProjectProject: (state: RootReducerState) => Project = (
  state
) => fromProjects.getActiveProjectProject(state.projects);
export const getActiveProjectTabView: (state: RootReducerState) => string = (
  state
) => fromProjects.getActiveProjectTabView(state.projects);

export const getProjectsOverview: (
  state: RootReducerState
) => Array<Project> = (state) =>
  fromProjects.getProjectsOverview(state.projects);

export const getLastProjectLocation: (state: RootReducerState) => string = (
  state
) => fromProjects.getLastProjectLocation(state.projects);

export const getProjectDistributionLists: (
  state: RootReducerState,
  projectId: ProjectId
) => ProjectDistributionList[] = (state, projectId) =>
  fromProjects.getProjectDistributionLists(state.projects, projectId);
export const getProjectDistributionListIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromProjects.getProjectDistributionListIsFetching(state.projects);
export const getProjectDistributionListHasError: (
  state: RootReducerState
) => boolean = (state) =>
  fromProjects.getProjectDistributionListHasError(state.projects);
export const getProjectDistributionListErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromProjects.getProjectDistributionListErrorMessage(state.projects);

export const getProjectDistributionListDrawerState: (
  state: RootReducerState
) => ProjectDistributionListDrawerState = (state) =>
  fromProjects.getProjectDistributionListDrawerState(state.projects);

export const getProjectNewsByProjectId: (
  state: RootReducerState,
  projectId: string
) => ProjectNews[] = (state, projectId) =>
  fromProjects.getProjectNewsByProjectId(state.projects, projectId);
export const projectHasProjectNews: (
  state: RootReducerState,
  projectId: string
) => boolean = (state, projectId) =>
  fromProjects.projectHasProjectNews(state.projects, projectId);
//#endregion

//#region ------------------------------ TimeRecords
export const getTimeRecordsByIdState: (
  state: RootReducerState
) => TimeRecordsByIdState = (state: RootReducerState) =>
  fromTimeRecords.getTimeRecordsByIdState(state.timeRecords);

export const getTimeRecordIds: (
  state: RootReducerState
) => Array<TimeRecordId> = (state: RootReducerState) =>
  fromTimeRecords.getTimeRecordIds(state.timeRecords);

export const getTimeRecordsByProject: (
  state: RootReducerState,
  projectId: ProjectId
) => Array<TimeRecord> = (state: RootReducerState, projectId: ProjectId) =>
  fromTimeRecords.getTimeRecordsByProject(state.timeRecords, projectId);
export const getAllTimeRecords: (
  state: RootReducerState
) => Array<TimeRecord> = (state: RootReducerState) =>
  fromTimeRecords.getAllTimeRecords(state.timeRecords);

export const getTimeRecordsByContact: (
  state: RootReducerState,
  contactId: ContactId,
  disableSort?: boolean
) => Array<TimeRecord> = (
  state: RootReducerState,
  contactId: ContactId,
  disableSort?: boolean
) =>
  fromTimeRecords.getTimeRecordsByContact(
    state.timeRecords,
    contactId,
    disableSort
  );
export const getMyTimeRecords: (
  state: RootReducerState,
  disableSort?: boolean
) => Array<TimeRecord> = (state: RootReducerState, disableSort?: boolean) =>
  fromTimeRecords.getTimeRecordsByContact(
    state.timeRecords,
    getUserMeContactId(state),
    disableSort
  );
export const getTimeRecord: (
  state: RootReducerState,
  timeRecordId: string
) => TimeRecord = (state, timeRecordId) =>
  fromTimeRecords.getTimeRecord(state.timeRecords, timeRecordId);
export const getTimeRecordRedirect: (
  state: RootReducerState,
  temporaryId: string
) => string = (state, temporaryId) =>
  fromTimeRecords.getTimeRecordRedirect(state.timeRecords, temporaryId);
export const getTimeRecordsIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromTimeRecords.getTimeRecordsIsFetching(state.timeRecords);
export const getTimeRecordsHasError: (state: RootReducerState) => boolean = (
  state
) => fromTimeRecords.getTimeRecordsHasError(state.timeRecords);
export const getTimeRecordsErrorMessage: (state: RootReducerState) => string = (
  state
) => fromTimeRecords.getTimeRecordsErrorMessage(state.timeRecords);
//#endregion

//#region ------------------------------ TimeAndLeveManagement
export const getTimeAndLeaveManagementDrawerState: (
  state: RootReducerState
) => TimeAndLeaveDrawerState = ({ timeAndLeaveManagement }) =>
  fromTimeAndLeaveManagement.getTimeAndLeaveManagementDrawerState(
    timeAndLeaveManagement
  );
//#endregion

//#region ------------------------------ HR
export const getLastHRLocation: (state: RootReducerState) => string = (state) =>
  fromHR.getLastHRLocation(state.hr);
//#endregion

//#region ------------------------------ Accounting
export const getLastAccountingLocation: (state: RootReducerState) => string = (
  state
) => fromControlling.getLastAccountingLocation(state.controlling);
//#endregion

//#region ------------------------------ User
export const getUserMe: (state: RootReducerState) => User = (state) =>
  fromUsers.getUserMe(state.users);
export const getUserMeContactId: (state: RootReducerState) => ContactId = (
  state
) => fromUsers.getUserMeContactId(state.users);
export const getUserMeGlobalRoles: (state: RootReducerState) => GlobalRole[] = (
  state
) => fromUsers.getUserMeGlobalRoles(state.users);
export const getUserMeIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromUsers.getUserMeIsFetching(state.users);
export const getUserMeHasError: (state: RootReducerState) => boolean = (
  state
) => fromUsers.getUserMeHasError(state.users);
export const getUserMeErrorMessage: (state: RootReducerState) => string = (
  state
) => fromUsers.getUserMeErrorMessage(state.users);

export const getOfficeMeByIdState: (
  state: RootReducerState
) => OfficeMeByIdState = (state) => fromUsers.getOfficeMeByIdState(state.users);
export const getOfficeMeById: (
  state: RootReducerState,
  officeId: OfficeId
) => Office = (state, officeId) =>
  fromUsers.getOfficeMeById(state.users, officeId);
export const getOfficeMeIds: (state: RootReducerState) => OfficeId[] = (
  state
) => fromUsers.getOfficeMeIds(state.users);
export const getOfficeMeAllOffices: (state: RootReducerState) => Office[] = (
  state
) => fromUsers.getOfficeMeAllOffices(state.users);

export const getOfficeMeIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromUsers.getOfficeMeIsFetching(state.users);
export const getOfficeMeHasError: (state: RootReducerState) => boolean = (
  state
) => fromUsers.getOfficeMeHasError(state.users);
export const getOfficeMeErrorMessage: (state: RootReducerState) => string = (
  state
) => fromUsers.getOfficeMeErrorMessage(state.users);

export const getIsPrioUser: (state: RootReducerState) => boolean = (state) =>
  fromUsers.getIsPrioUser(state.users);
export const getHasRegistrationCompleted: (
  state: RootReducerState
) => boolean = (state) => fromUsers.getHasRegistrationCompleted(state.users);

export const getAddUserDrawerState: (
  state: RootReducerState
) => AddUserDrawerState = (state) =>
  fromUsers.getAddUserDrawerState(state.users);

export const getUsersIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromUsers.getUsersIsFetching(state.users);
export const getUsersHasError: (state: RootReducerState) => boolean = (state) =>
  fromUsers.getUsersHasError(state.users);
export const getUsersErrorMessage: (state: RootReducerState) => string = (
  state
) => fromUsers.getUsersErrorMessage(state.users);

export const getPrioUsers: (state: RootReducerState) => User[] = (state) =>
  fromUsers.getPrioUsers(state.users);
export const getPrioUser: (
  state: RootReducerState,
  contactId: ContactId
) => User = (state, contactId) => fromUsers.getPrioUser(state.users, contactId);
export const getPrioUsersById: (state: RootReducerState) => UsersByIdState = (
  state
) => fromUsers.getPrioUsersById(state.users);
export const getPrioUsersIds: (state: RootReducerState) => ContactId[] = (
  state
) => fromUsers.getPrioUsersIds(state.users);
//#endregion

//#region ------------------------------ Settings
export const getContactClassifications: (
  state: RootReducerState
) => ContactClassification[] = (state) =>
  fromSettings.getContactClassifications(state.settings);
export const getCompanyClassifications: (
  state: RootReducerState
) => CompanyClassification[] = (state) =>
  fromSettings.getCompanyClassifications(state.settings);
export const getProjectFileSystemStructures: (
  state: RootReducerState
) => ProjectFileSystemStructure[] = (state) =>
  fromSettings.getProjectFileSystemStructures(state.settings);
export const getProjectFileSystemStructureIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getProjectFileSystemStructureIsFetching(state.settings);
export const getProjectFileSystemStructureHasError: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getProjectFileSystemStructureHasError(state.settings);
export const getProjectFileSystemStructureHasUpdateError: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getProjectFileSystemStructureHasUpdateError(state.settings);
export const getProjectFileSystemStructureErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromSettings.getProjectFileSystemStructureErrorMessage(state.settings);
export const getProjectFileSystemStructure: (
  state: RootReducerState,
  projectFileSystemStructureId: ProjectFileSystemStructureId
) => ProjectFileSystemStructure = (state, projectFileSystemStructureId) =>
  fromSettings.getProjectFileSystemStructure(
    state.settings,
    projectFileSystemStructureId
  );
export const getTitleSuggestions: (
  state: RootReducerState
) => TitleSuggestion[] = (state) =>
  fromSettings.getTitleSuggestions(state.settings);
export const getTitleSuggestionsIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getTitleSuggestionsIsFetching(state.settings);
export const getTitleSuggestionsHasError: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getTitleSuggestionsHasError(state.settings);
export const getTitleSuggestionsErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromSettings.getTitleSuggestionsErrorMessage(state.settings);

export const getInternalJobTitles: (
  state: RootReducerState
) => InternalJobTitleSuggestion[] = (state) =>
  fromSettings.getInternalJobTitles(state.settings);
export const getInternalJobTitlesIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getInternalJobTitlesIsFetching(state.settings);
export const getInternalJobTitlesHasError: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getInternalJobTitlesHasError(state.settings);
export const getInternalJobTitlesErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromSettings.getInternalJobTitlesErrorMessage(state.settings);

export const getExternalJobTitles: (
  state: RootReducerState
) => ExternalJobTitleSuggestion[] = (state) =>
  fromSettings.getExternalJobTitles(state.settings);
export const getExternalJobTitlesIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getExternalJobTitlesIsFetching(state.settings);
export const getExternalJobTitlesHasError: (
  state: RootReducerState
) => boolean = (state) =>
  fromSettings.getExternalJobTitlesHasError(state.settings);
export const getExternalJobTitlesErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromSettings.getExternalJobTitlesErrorMessage(state.settings);

export const getTextBlockById: (
  state: RootReducerState,
  textBlockId: TextBlockId
) => TextBlock = (state, textBlockId) =>
  fromSettings.getTextBlockById(state.settings, textBlockId);
export const getTextBlocks: (state: RootReducerState) => TextBlock[] = (
  state
) => fromSettings.getTextBlocks(state.settings);

export const getTextBlocksIsFetching: (state: RootReducerState) => boolean = (
  state
) => fromSettings.getTextBlocksIsFetching(state.settings);
export const getTextBlocksHasError: (state: RootReducerState) => boolean = (
  state
) => fromSettings.getTextBlocksHasError(state.settings);
export const getTextBlocksErrorMessage: (state: RootReducerState) => string = (
  state
) => fromSettings.getTextBlocksErrorMessage(state.settings);

export const textBlockManagementGetSelectedEntry: (
  state: RootReducerState
) => TextBlock | null = (state) =>
  fromSettings.textBlockManagementGetSelectedEntry(state.settings);
export const textBlockManagementGetOpen = (state: RootReducerState) =>
  fromSettings.textBlockManagementGetOpen(state.settings);

export const getLastSettingsLocation: (state: RootReducerState) => string = (
  state
) => fromSettings.getLastSettingsLocation(state.settings);

export const getIsKeyEnabled: (
  state: RootReducerState,
  key: string
) => boolean = (state, key) =>
  fromSettings.getIsKeyEnabled(state.settings, key);
//#endregion

//#region ------------------------------ Profile
export const getLastProfileLocation: (state: RootReducerState) => string = (
  state
) => fromProfile.getLastProfileLocation(state.profile);
//#endregion

//#region ------------------------------ Documents
export const getCurrentFolderRedirect = (
  state: RootReducerState,
  driveItemId: DriveItemId
) => fromDocuments.getCurrentFolderRedirect(state.documents, driveItemId);
export const getCurrentFolderItem = (
  state: RootReducerState,
  driveItemId: DriveItemId
) => fromDocuments.getCurrentFolderItem(state.documents, driveItemId);
export const getCurrentFolderChildren = (
  state: RootReducerState,
  driveItemId: DriveItemId
) => fromDocuments.getCurrentFolderChildren(state.documents, driveItemId);

export const getRecursiveDriveItemIdPath = (
  state: RootReducerState,
  driveItemId: DriveItemId,
  groupId: GroupId
) =>
  fromDocuments.getRecursiveDriveItemIdPath(
    state.documents,
    driveItemId,
    groupId
  );

export const getPartialCurrentFolderState = (
  state: RootReducerState,
  driveItemIds: DriveItemId[]
) => fromDocuments.getPartialCurrentFolderState(state.documents, driveItemIds);

export const getCurrentFolderNextLink = (
  state: RootReducerState,
  driveItemId: DriveItemId
) => fromDocuments.getCurrentFolderNextLink(state.documents, driveItemId);
export const getCurrentFolderMeta = (state: RootReducerState) =>
  fromDocuments.getCurrentFolderMeta(state.documents);
export const getDriveItemIsFetching = (
  state: RootReducerState,
  driveItemId: string
) => fromDocuments.getDriveItemIsFetching(state.documents, driveItemId);
export const isDisabledDriveItem = (
  state: RootReducerState,
  driveItemId: DriveItemId
) => fromDocuments.isDisabledDriveItem(state.documents, driveItemId);
export const getDisabledItems = (state: RootReducerState) =>
  fromDocuments.getDisabledItems(state.documents);

export const getCurrentFolderMetaCeateNewDocumentOpen = (
  state: RootReducerState
) => fromDocuments.getCurrentFolderMetaCeateNewDocumentOpen(state.documents);
export const getDocumentsMetaCopyDocumentsDrawerState = (
  state: RootReducerState
) => fromDocuments.getMetaCopyDocumentsDrawerState(state.documents);
export const getDocumentsMetaAreMultipleSelected = (state: RootReducerState) =>
  fromDocuments.getMetaAreMultipleSelected(state.documents);
export const getDocumentsMetaState = (state: RootReducerState) =>
  fromDocuments.getMetaState(state.documents);

export const getErrorOfDriveItem = (
  state: RootReducerState,
  driveItemId: DriveItemId
) => fromDocuments.getErrorOfDriveItem(state.documents, driveItemId);

export const getCurrentDriveItem = (state: RootReducerState) =>
  fromDocuments.getCurrentDriveItem(state.documents);

export const documentTemplateManagementGetAction = (state: RootReducerState) =>
  fromDocuments.documentTemplateManagementGetAction(state.documents);
export const documentTemplateManagementGetSelectedEntry: (
  state: RootReducerState
) => DocumentTemplate | null = (state) =>
  fromDocuments.documentTemplateManagementGetSelectedEntry(state.documents);

export const documentTemplatesGetTemplates = (state: RootReducerState) =>
  fromDocuments.documentTemplatesGetTemplates(state.documents);

export const documentTemplatesGetIsFetching = (state: RootReducerState) =>
  fromDocuments.documentTemplatesGetIsFetching(state.documents);

export const getUploadListState = (state: RootReducerState, groupId: GroupId) =>
  fromDocuments.getUploadListState(state.documents, groupId);

export const getUploadDocumentById = (
  state: RootReducerState,
  groupId: GroupId,
  fileId: string
) => fromDocuments.getUploadDocumentById(state.documents, groupId, fileId);

export const getUploadList = (state: RootReducerState, groupId: GroupId) =>
  fromDocuments.getUploadList(state.documents, groupId);

export const getUploadProgressAllGroups = (state: RootReducerState) =>
  fromDocuments.getUploadProgressAllGroups(state.documents);

export const getRemoteFoldersMeta = (state: RootReducerState) =>
  fromDocuments.getRemoteFoldersMeta(state.documents);
export const getRemoteFoldersRedirect = (
  state: RootReducerState,
  temporaryId: string
) => fromDocuments.getRemoteFoldersRedirect(state.documents, temporaryId);
export const getRemoteFoldersProjectIds = (state: RootReducerState) =>
  fromDocuments.getRemoteFoldersProjectIds(state.documents);
export const getRemoteItemsByProjectId = (
  state: RootReducerState,
  projectId: ProjectId
) => fromDocuments.getRemoteItemsByProjectId(state.documents, projectId);
export const getCurrentRemoteItemId = (state: RootReducerState) =>
  fromDocuments.getCurrentRemoteItemId(state.documents);
export const getCurrentRemoteItem = (state: RootReducerState) =>
  fromDocuments.getCurrentRemoteItem(state.documents);
export const getRemoteItem = (
  state: RootReducerState,
  driveUserRemoteItemId: DriveUserRemoteItemId
) => fromDocuments.getRemoteItem(state.documents, driveUserRemoteItemId);
export const getOpenCurrentPreviewModal = (state: RootReducerState) =>
  fromDocuments.getOpenCurrentPreviewModal(state.documents);

export const getRemoteFolderIsFetching = (state: RootReducerState) =>
  fromDocuments.getRemoteFolderIsFetching(state.documents);

export const getDriveFavorites = (
  state: RootReducerState,
  projectId?: ProjectId
) => fromDocuments.getDriveFavorites(state.documents, projectId);

export const getDriveFavorite = (
  state: RootReducerState,
  driveFavoriteId: DriveFavoriteId
) => fromDocuments.getDriveFavorite(state.documents, driveFavoriteId);

export const getFavorisingProgresses = (state: RootReducerState) =>
  fromDocuments.getFavorisingProgresses(state.documents);

export const getLastOpenDriveItemFolder = (
  state: RootReducerState,
  projectId: ProjectId
) => fromDocuments.getLastOpenDriveItemFolder(state.documents, projectId);

export const getDocumentsTableColumnWidths = (state: RootReducerState) =>
  fromDocuments.getColumnWidths(state.documents);

export const getIsDocumentMetaDataSaving = (state: RootReducerState) =>
  fromDocuments.getIsDocumentMetaDataSaving(state.documents);

export const getIsGlobalDocumentsSearchModalOpen = (state: RootReducerState) =>
  fromDocuments.getIsGlobalDocumentsSearchModalOpen(state.documents);
//#endregion

//#region ------------------------------ Notifications
export const getAllNotifications: (
  state: RootReducerState
) => Array<Notification> = (state) =>
  fromNotifications.getAllNotifications(state.notifications);
export const getNotification: (
  state: RootReducerState,
  notificationId: NotificationId
) => Notification = (state, notificationId) =>
  fromNotifications.getNotification(state.notifications, notificationId);
export const getNotificationsIsFetching: (
  state: RootReducerState
) => boolean = (state) =>
  fromNotifications.getNotificationsIsFetching(state.notifications);
export const getNotificationsHasError: (state: RootReducerState) => boolean = (
  state
) => fromNotifications.getNotificationsHasError(state.notifications);
export const getNotificationsErrorMessage: (
  state: RootReducerState
) => string = (state) =>
  fromNotifications.getNotificationsErrorMessage(state.notifications);
export const getNotificationsDrawerState: (
  state: RootReducerState
) => NotificationsDrawerState = (state) =>
  fromNotifications.getDrawerState(state.notifications);
export const getNotificationsDrawerOpen: (
  state: RootReducerState
) => boolean = (state) => fromNotifications.getDrawerOpen(state.notifications);

export const getNotificationsByIdState: (
  state: RootReducerState
) => NotificationsByIdState = (state) =>
  fromNotifications.getNotificationsByIdState(state.notifications);
export const getNotificationsIds: (
  state: RootReducerState
) => NotificationId[] = (state) =>
  fromNotifications.getNotificationsIds(state.notifications);
//#endregion

//#region ------------------------------ Zendesk
export const getZendeskDrawerOpen: (state: RootReducerState) => boolean = (
  state
) => fromZendesk.getDrawerOpen(state.zendesk);

export const getZendeskDrawerView: (
  state: RootReducerState
) => ZendeskDrawerView = (state) => fromZendesk.getDrawerView(state.zendesk);

export const getZendeskDrawerSearchResult: (
  state: RootReducerState
) => ZendeskSearchResult = (state) =>
  fromZendesk.getDrawerSearchResult(state.zendesk);

export const getZendeskDrawerSearchTerm: (state: RootReducerState) => string = (
  state
) => fromZendesk.getDrawerSearchTerm(state.zendesk);

export const getWidgetAreaDisplayState: (
  state: RootReducerState
) => fromWidgetArea.WidgetAreaDisplayState = (state) =>
  fromWidgetArea.getDisplayState(state.widgetArea);

export const getWidgetAreaActiveProjectId: (
  state: RootReducerState
) => ProjectId = (state) => fromWidgetArea.getActiveProjectId(state.widgetArea);

export const getDocumentsWidgetActiveProjectId: (
  state: RootReducerState
) => ProjectId = (state) =>
  fromWidgetArea.getDocumentsWidgetActiveProjectId(state.widgetArea);

export const getBuddyIconVisibility: (state: RootReducerState) => boolean = (
  state
) => fromWidgetArea.getBuddyIconVisibility(state.widgetArea);

//#endregion

//#region ------------------------------ User Settings
export const getGlobalSiderOpen: (state: RootReducerState) => boolean = (
  state
) => fromUserSettings.getSiderOpen(state.userSettings);

export const getGlobalSiderSubMenuState: (
  state: RootReducerState
) => SubMenuStateType = (state) =>
  fromUserSettings.getSiderSubMenuState(state.userSettings);

export const getMailSettings: (state: RootReducerState) => MailSettings = (
  state
) => fromUserSettings.getMailSettings(state.userSettings);

export const getDocumentSettings: (
  state: RootReducerState
) => DocumentSettings = (state) =>
  fromUserSettings.getDocumentSettings(state.userSettings);

export const getEmailCachingEnabled: (state: RootReducerState) => boolean = (
  state
) => fromUserSettings.getEmailCachingEnabled(state.userSettings);

export const getDocumentsCachingEnabled: (
  state: RootReducerState
) => boolean = (state) =>
  fromUserSettings.getDocumentsCachingEnabled(state.userSettings);

//#endregion

//#region ------------------------------ Prio Version
export const getCurrentPrioVersion: (state: RootReducerState) => string = (
  state
) => fromPrioVersion.getCurrentPrioVersion(state.prioVersion);
//#endregion

//#region ------------------------------ Global Settings
export const getSiderOpen: (state: RootReducerState) => boolean = (state) =>
  fromUserSettings.getSiderOpen(state.userSettings);

export const getSiderSubMenuState: (
  state: RootReducerState
) => SubMenuStateType = (state) =>
  fromUserSettings.getSiderSubMenuState(state.userSettings);
//#endregion

//#region ------------------------------ Doodle
export const getDoodleState: (
  state: RootReducerState
) => fromDoodle.DoodleState = (state) =>
  fromDoodle.getDoodleState(state.doodle);

export const getDoodleTypeState: (
  state: RootReducerState,
  type: DoodleType
) => fromDoodle.DoodleTypeState = (state, type) =>
  fromDoodle.getDoodleTypeState(state.doodle, type);
//#endregion

//#region ------------------------------ Theme
export const getPrioTheme: (state: RootReducerState) => PrioThemeReduxState = (
  state
) => fromTheme.getPrioTheme(state.theme);
//#endregion

//#region ------------------------------ Dashboard
export const getDashboard: (
  state: RootReducerState,
  dashboardId: DashboardId
) => Dashboard = ({ dashboard }, dashboardId) =>
  fromDashboard.getDashboard(dashboard, dashboardId);

export const getDashboardItem: (
  state: RootReducerState,
  dashboardItemId: DashboardItemId
) => DashboardItem = ({ dashboard }, dashboardItemId) =>
  fromDashboard.getDashboardItem(dashboard, dashboardItemId);
//#endregion

//#region ------------------------------ TimeKeeping
export const getTimeKeepingDayByIdState: (
  state: RootReducerState
) => TimeKeepingByIdState = ({ timeKeeping }) =>
  fromTimeKeeping.getTimeKeepingDayByIdState(timeKeeping);

export const getTimeKeepingDayIdsState: (
  state: RootReducerState
) => TimeKeepingDayId[] = ({ timeKeeping }) =>
  fromTimeKeeping.getTimeKeepingDayIdsState(timeKeeping);

export const getAllTimeKeepingDaysMe: (
  state: RootReducerState
) => TimeKeepingDay[] = ({ timeKeeping }) =>
  fromTimeKeeping.getAllTimeKeepingDaysMe(timeKeeping);

export const getTimeKeepingDayMe: (
  state: RootReducerState,
  id: TimeKeepingDayId
) => TimeKeepingDay = ({ timeKeeping }, id) =>
  fromTimeKeeping.getTimeKeepingDayMe(timeKeeping, id);

export const getMonthlyCloseMeTimeKeepingDays: (
  state: RootReducerState,
  monthDateTime: DateTimeString
) => TimeKeepingDay[] = ({ timeKeeping }, monthDateTime) =>
  fromTimeKeeping.getMonthlyCloseMeTimeKeepingDays(timeKeeping, monthDateTime);

export const getMonthlyCloseMeTimeKeepingDayIds: (
  state: RootReducerState,
  monthDateTime: DateTimeString
) => TimeKeepingDayId[] = ({ timeKeeping }, monthDateTime) =>
  fromTimeKeeping.getMonthlyCloseMeTimeKeepingDayIds(
    timeKeeping,
    monthDateTime
  );

export const getMonthlyCloseAbsences: (
  state: RootReducerState,
  monthDateTime: DateTimeString
) => MonthlyCloseAbsence[] = ({ timeKeeping }, monthDateTime) =>
  fromTimeKeeping.getMonthlyCloseAbsences(timeKeeping, monthDateTime);

export const getMonthlyCloseMeByMonth: (
  state: RootReducerState,
  monthDateTime: DateTimeString
) => MonthlyClose = ({ timeKeeping }, monthDateTime) =>
  fromTimeKeeping.getMonthlyCloseMeByMonth(timeKeeping, monthDateTime);

export const getMonthlyClosesMeInRange: (
  state: RootReducerState,
  from: DateTimeString,
  to: DateTimeString
) => MonthlyClose[] = ({ timeKeeping }, from, to) =>
  fromTimeKeeping.getMonthlyClosesMeInRange(timeKeeping, { from, to });

export const getMonthlyCloseMeIsFetching: (
  state: RootReducerState
) => boolean = ({ timeKeeping }) =>
  fromTimeKeeping.getMonthlyCloseMeIsFetching(timeKeeping);
//#endregion

//#region ------------------------------ Filter
export const getSearchConfigBySearchType: (
  state: RootReducerState,
  searchType: SearchType
) => FilterBarData = ({ filter }, searchType) =>
  fromFilter.getSearchConfigBySearchType(filter, searchType);

export const getIsFetchingSearchConfig: (
  state: RootReducerState
) => boolean = ({ filter }) => fromFilter.getIsFetchingSearchConfig(filter);

export const getReduxSearchString: (
  state: RootReducerState,
  context: SearchType,
  officeId: string | null,
  projectId: string | null,
  path: string
) => string | null = (state, context, officeId, projectId, path) =>
  fromFilter.getReduxSearchString(
    state.filter,
    context,
    officeId,
    projectId,
    path
  );
//#endregion
