import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import PrioSpinner from '../../../components/PrioSpinner';
import { PublicClientApplication } from '@azure/msal-browser';

interface TeamsRedirectSignInProps {
  msalInstance: PublicClientApplication;
}
export const TeamsRedirectSignIn: React.FC<TeamsRedirectSignInProps> = (
  props
) => {
  const { msalInstance } = props;

  React.useEffect(() => {
    microsoftTeams.getContext(async function (context) {
      var inProgress = localStorage.getItem('teamsLoginInProgress');
      if (inProgress === null || inProgress === '') {
        localStorage.setItem('teamsLoginInProgress', 'true');
        msalInstance.loginRedirect();
      } else {
        setTimeout(() => {
          microsoftTeams.authentication.notifySuccess();
        }, 3000);
      }
    });
  });

  return <PrioSpinner />;
};

export default TeamsRedirectSignIn;
