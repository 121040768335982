import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { notification } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { TimeAndLeaveManagementTimelineItem } from '../selectors';
import TimeKeepingEditTimeRecordForm, {
  TimeRecordFormModel,
} from '../../timeKeeping/components/TimeKeepingEditTimeRecordForm';
import {
  MonthlyCloseId,
  TimeKeepingDayId,
  TimeRecordId,
} from '../../../models/Types';
import { isTemporaryId } from '../../../util';
import {
  CreateTimeRecordRequest,
  TimeRecord,
  UpdateTimeRecordRequest,
} from '../../../models/TimeRecord';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import {
  apiCreateTimeRecord,
  apiDeleteTimeRecords,
  apiUpdateTimeRecord,
} from '../../timeRecords/api';
import {
  createTimeRecords,
  deleteMyTimeRecord,
  updateTimeRecord,
} from '../../timeRecords/actions';
import { useTranslation } from 'react-i18next';
import TimeKeepingEditForm, {
  TimeKeepingFormModel,
} from '../../timeKeeping/components/TimeKeepingEditForm';
import {
  apiDeleteTimeKeepingDayMe,
  apiFetchTimeKeepingDaySuggestion,
  apiUpdateTimeKeeping,
} from '../../timeKeeping/api';
import { TimeKeepingDay } from '../../../models/TimeKeeping';
import {
  deleteTimeKeepingDay,
  updateTimeKeepingDay,
} from '../../timeKeeping/actions';
import moment from 'moment';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 8,
      backgroundColor: theme.old.palette.backgroundPalette.base,
    },
  },
  form: {
    overflow: 'auto',
    height: '100%',
    width: '100%',
    padding: theme.old.spacing.defaultPadding,
  },
}));

interface TimeAndLeaveTimelineFormManagerProps {
  className?: string;
  selectedItem: TimeAndLeaveManagementTimelineItem;
  onFinish: (
    id: string,
    item: TimeAndLeaveManagementTimelineItem,
    type: 'timeKeeping' | 'timeRecord'
  ) => void;
  onCancel: (
    item: TimeAndLeaveManagementTimelineItem,
    type: 'timeKeeping' | 'timeRecord'
  ) => void;
  onChange: (
    item: TimeAndLeaveManagementTimelineItem,
    type: 'timeKeeping' | 'timeRecord'
  ) => void;
  onDelete: (
    id: string,
    groupId: string,
    type: 'timeKeeping' | 'timeRecord'
  ) => void;
}

export const TimeAndLeaveTimelineFormManager: React.FC<
  TimeAndLeaveTimelineFormManagerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, selectedItem, onFinish, onCancel, onChange, onDelete } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);

  const [loading, setLoading] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnTimeRecordCreation = useCallback(
    async (value: TimeRecordFormModel, timeRecordId: TimeRecordId) => {
      if (isTemporaryId(timeRecordId)) {
        setLoading(true);
        const request: CreateTimeRecordRequest = {
          projectId: selectedItem.groupId,
          description: value.description,
          day: selectedItem.startDateTime.split('T')[0],
          contactId: userMe.id,
          kilometerDistance: value.kilometerDistance,
          title: value.title,
          timeRecordEntries: selectedItem.entries,
          projectPhaseId: value.projectPhaseId,
          isSeparateService: value.isSeparateService,
        };
        await apiCreateTimeRecord([request], request.projectId, true)
          .then(({ data }) => {
            const timeRecord = data[0];
            const sortedEntries = timeRecord.timeRecordEntries.sort((a, b) =>
              moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
            );
            dispatch(createTimeRecords(data));
            onFinish(
              timeRecordId,
              {
                id: timeRecord.timeRecordId,
                groupId: timeRecord.projectId,
                title: timeRecord.title,
                type: 'timeRecord',
                startDateTime: sortedEntries[0].startTime,
                endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
                entries: timeRecord.timeRecordEntries,
                value: timeRecord,
                state: 'ok',
              },
              'timeRecord'
            );
            apiFetchTimeKeepingDaySuggestion(
              timeRecord.timeRecordEntries[0].startTime,
              timeRecord.timeRecordEntries[
                timeRecord.timeRecordEntries.length - 1
              ].endTime
            );
          })
          .catch((error) => {
            notification.open({
              message: t('common:error'),
              description: t('timeRecords:errorMessages.createError'),
            });
          });

        setLoading(false);
      }
    },
    [selectedItem, userMe, dispatch, onFinish, t]
  );

  const handleOnTimeRecordChange = useCallback(
    async (
      value: TimeRecordFormModel,
      item: TimeAndLeaveManagementTimelineItem
    ) => {
      let newItem: TimeAndLeaveManagementTimelineItem = {
        ...item,
        value: {
          ...item.value,
          ...value,
        },
      };
      const _value: TimeRecord = newItem.value as TimeRecord;

      const request: UpdateTimeRecordRequest = {
        timeRecordId: _value.timeRecordId,
        projectId: _value.projectId,
        subProjectId: _value.subProjectId,
        description: _value.description,
        day: _value.day,
        contactId: _value.contactId,
        kilometerDistance: _value.kilometerDistance,
        title: _value.title,
        timeRecordEntries: _value.timeRecordEntries,
        rowVersion: _value.rowVersion,
        projectPhaseId: _value.projectPhaseId,
        isSeparateService: _value.isSeparateService,
      };

      const { data } = await apiUpdateTimeRecord(
        request,
        _value.timeRecordId,
        _value.rowVersion,
        'me'
      );
      if (data) {
        const sortedEntries = data.timeRecordEntries.sort((a, b) =>
          moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
        );
        dispatch(
          updateTimeRecord(data, data.timeRecordId, item.value as TimeRecord)
        );
        onChange(
          {
            id: data.timeRecordId,
            groupId: data.projectId,
            title: data.title,
            type: 'timeRecord',
            startDateTime: sortedEntries[0].startTime,
            endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
            entries: data.timeRecordEntries,
            value: data,
            state: 'ok',
          },
          'timeRecord'
        );
      } else {
        notification.open({
          message: t('common:error'),
          description: t('timeRecords:errorMessages.updateError'),
        });
      }
    },
    [onChange, dispatch, t]
  );

  const handleOnTimeRecordFormFinish = useCallback(
    (
      value: TimeRecordFormModel,
      timeRecord: TimeAndLeaveManagementTimelineItem
    ) => {
      if (isTemporaryId(timeRecord.id)) {
        handleOnTimeRecordCreation(value, timeRecord.id);
      } else {
        handleOnTimeRecordChange(value, timeRecord);
      }
    },
    [handleOnTimeRecordChange, handleOnTimeRecordCreation]
  );

  const handleOnTimeRecordFormCancel = useCallback(
    (item: TimeAndLeaveManagementTimelineItem) => {
      onCancel(item, 'timeRecord');
    },
    [onCancel]
  );

  const handleOnTimeRecordDelete = useCallback(
    async (timeRecordId: TimeRecordId) => {
      setLoading(true);
      const { result } = await apiDeleteTimeRecords(
        timeRecordId,
        selectedItem?.value as TimeRecord,
        'me'
      );
      if (result.status >= 200 && result.status < 300) {
        dispatch(
          deleteMyTimeRecord(
            timeRecordId,
            selectedItem?.value as TimeRecord,
            true
          )
        );
        onDelete(timeRecordId, selectedItem.groupId, 'timeRecord');
      }
      setLoading(false);
    },
    [onDelete, selectedItem, dispatch]
  );

  const handleOnTimeKeepingChange = useCallback(
    async (
      value: TimeKeepingFormModel,
      item: TimeAndLeaveManagementTimelineItem
    ) => {
      setLoading(true);
      const newItem: TimeAndLeaveManagementTimelineItem = {
        ...item,
        value: {
          ...item.value,
          ...value,
        },
      };
      const { data } = await apiUpdateTimeKeeping(item.id, {
        type: (newItem.value as TimeKeepingDay).type,
        notes: (newItem.value as TimeKeepingDay).notes,
        timeKeepingEntries: newItem.entries,
      });

      if (data) {
        const sortedEntries = data.timeKeepingEntries.sort((a, b) =>
          moment(a.startTime).isBefore(moment(b.startTime)) ? -1 : 1
        );
        dispatch(updateTimeKeepingDay(data, item.id));
        onChange(
          {
            id: data.timeKeepingDayId,
            groupId: item.groupId,
            title: '',
            type: 'timeKeeping',
            startDateTime: sortedEntries[0].startTime,
            endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
            entries: data.timeKeepingEntries,
            value: data,
            state: 'ok',
            notes: data.notes,
          } as TimeAndLeaveManagementTimelineItem,
          'timeKeeping'
        );
      } else {
        notification.open({
          message: t('common:error'),
          description: t('timeKeeping:messages.errorMessages.updateError'),
        });
      }
      setLoading(false);
    },
    [onChange, dispatch, t]
  );

  const handleOnTimeKeepingDelete = useCallback(
    async (
      monthlyCloseId: MonthlyCloseId,
      timeKeepingDayId: TimeKeepingDayId
    ) => {
      setLoading(true);
      const { result } = await apiDeleteTimeKeepingDayMe(timeKeepingDayId);

      if (result.status >= 200 && result.status < 300) {
        dispatch(deleteTimeKeepingDay(monthlyCloseId, timeKeepingDayId));
        onDelete(timeKeepingDayId, selectedItem.groupId, 'timeKeeping');
      }
      setLoading(false);
    },
    [selectedItem, onDelete, dispatch]
  );
  //#endregion

  //#region ------------------------------ Components
  const content = useMemo(() => {
    switch (selectedItem.type) {
      case 'timeKeeping': {
        return (
          <TimeKeepingEditForm
            className={classes.form}
            selectedItem={selectedItem}
            onChange={handleOnTimeKeepingChange}
            onDelete={handleOnTimeKeepingDelete}
            loading={loading}
          />
        );
      }
      case 'timeRecord': {
        return (
          <TimeKeepingEditTimeRecordForm
            className={classes.form}
            selectedItem={selectedItem}
            onCancel={handleOnTimeRecordFormCancel}
            onFinish={handleOnTimeRecordFormFinish}
            onDelete={handleOnTimeRecordDelete}
            loading={loading}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [
    loading,
    selectedItem,
    classes,
    handleOnTimeRecordFormCancel,
    handleOnTimeRecordFormFinish,
    handleOnTimeRecordDelete,
    handleOnTimeKeepingChange,
    handleOnTimeKeepingDelete,
  ]);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (selectedItem) {
      setLoading(false);
    }
  }, [selectedItem]);
  //#endregion

  return (
    <Flex.Item className={classNames(classes.root, className)}>
      {content}
    </Flex.Item>
  );
};

export default TimeAndLeaveTimelineFormManager;
