import { throttle, put, select, all, fork, spawn } from 'redux-saga/effects';
import {
  getContactOffset,
  getCompanyOffset,
  getContactLastSync,
  getCompanyLastSync,
} from '../apps/main/rootReducer';
import {
  SYNC_CONTACTS_ME,
  fetchContactsOffset,
} from '../modules/contacts/actions';
import { SYNC_COMPANIES, fetchCompanies } from '../modules/companies/actions';
import moment from 'moment';
import {
  fetchProjectsOverview,
  SYNC_GLOBAL_PROJECTS,
} from '../modules/projects/actions';
import { notification } from 'antd';
import i18n from '../i18n';
import { SYNC_ABSENCES_ME, fetchAbsencesMe } from '../modules/absences/actions';
import { ReduxAction } from '../models/Redux';
import { DateTimeString } from '../models/Types';
import {
  SYNC_TIMERECORDS_ME,
  fetchMyTimeRecords,
} from '../modules/timeRecords/actions';
import {
  SYNC_MONTHLY_CLOSE_ME,
  fetchMonthlyCloseMe,
} from '../modules/timeKeeping/actions';
import { dispatchSaga } from '../util/sagas';

function* syncContactsMe() {
  try {
    const offset = yield select(getContactOffset);
    const lastSync = yield select(getContactLastSync);
    const today = moment().toISOString().slice(0, 23);
    const refresh: boolean = moment(lastSync).diff(moment(today), 'days') > 3;
    yield put(fetchContactsOffset(refresh ? null : offset));
  } catch (error) {
    console.error('Error in syncContactsMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('contacts:errorMessages.fetchError'),
    });
  }
}
function* syncCompanies() {
  try {
    const offset = yield select(getCompanyOffset);
    const lastSync = yield select(getCompanyLastSync);
    const today = moment().toISOString().slice(0, 23);
    const refresh: boolean = moment(lastSync).diff(moment(today), 'days') > 3;
    yield put(fetchCompanies(refresh ? null : offset));
  } catch (error) {
    console.error('Error in syncCompanies', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('companies:errorMessages.fetchError'),
    });
  }
}
function* syncGlobalProjects() {
  try {
    yield put(fetchProjectsOverview());
  } catch (error) {
    console.error('Error in syncGlobalProjects', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('projects:errorMessages.fetchError'),
    });
  }
}
function* syncAbsencesMe(
  action: ReduxAction<{
    from?: DateTimeString;
    to?: DateTimeString;
  }>
) {
  try {
    const {
      meta: { from, to },
    } = action;
    yield spawn(dispatchSaga, fetchAbsencesMe(from, to));
  } catch (error) {
    console.error('Error in syncAbsencesMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('absences:errorMessages.fetchOverviewError'),
    });
  }
}
function* syncTimeRecordsMe(
  action: ReduxAction<{
    startDate?: DateTimeString;
    endDate?: DateTimeString;
  }>
) {
  try {
    const {
      meta: { startDate, endDate },
    } = action;
    yield spawn(dispatchSaga, fetchMyTimeRecords(startDate, endDate));
  } catch (error) {
    console.error('Error in syncTimeRecordsMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('timeRecords:errorMessages.fetchError'),
    });
  }
}
function* syncMonthlyCloseMe(
  action: ReduxAction<{
    from?: DateTimeString;
    to?: DateTimeString;
  }>
) {
  try {
    const {
      meta: { from, to },
    } = action;
    yield spawn(dispatchSaga, fetchMonthlyCloseMe(from, to));
  } catch (error) {
    console.error('Error in syncMonthlyCloseMe', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('timeKeeping:messages.errorMessages.fetchError'),
    });
  }
}

function* watchSyncContacts() {
  yield throttle(60000, SYNC_CONTACTS_ME, syncContactsMe);
}
function* watchSyncCompanies() {
  yield throttle(60000, SYNC_COMPANIES, syncCompanies);
}
function* watchSyncGlobalProjects() {
  yield throttle(300000, SYNC_GLOBAL_PROJECTS, syncGlobalProjects);
}
function* watchSyncAbsencesMe() {
  yield throttle(60000, SYNC_ABSENCES_ME, syncAbsencesMe);
}
function* watchSyncTimerecordsMe() {
  yield throttle(60000, SYNC_TIMERECORDS_ME, syncTimeRecordsMe);
}
function* watchSyncMonthlyCloseMe() {
  yield throttle(60000, SYNC_MONTHLY_CLOSE_ME, syncMonthlyCloseMe);
}

export default function* watchSyncActions() {
  yield all([
    fork(watchSyncContacts),
    fork(watchSyncCompanies),
    fork(watchSyncGlobalProjects),
    fork(watchSyncAbsencesMe),
    fork(watchSyncTimerecordsMe),
    fork(watchSyncMonthlyCloseMe),
  ]);
}
