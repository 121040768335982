import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tooltip } from 'antd';

import InternalCompanyForm from './InternalCompanyForm';
import InternalCompanyBankAccountForm from './InternalCompanyBankAccountForm';

import { InternalCompanyFormTabs } from '../actions/drawer';
import {
  CreateInternalCompanyRequest,
  InternalCompany,
} from '../../../models/Company';
import { CompanyId } from '../../../models/Types';
import { TableEntry } from './InternalCompanyTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: 'calc(100%)',
    marginTop: '8px',
    '& .ant-tabs-content-holder': {
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  iconBankAccount: {
    marginLeft: '6px',
    marginTop: '6px',
    '&:hover': {
      cursor: 'default',
    },
  },
}));

interface InternalCompanyWrapperFormProps {
  className?: string;
  activeKey?: InternalCompanyFormTabs;
  onChange?: (activeKey: InternalCompanyFormTabs) => void;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (
    value: CreateInternalCompanyRequest | InternalCompany,
    companyIdsToArchive?: CompanyId[]
  ) => void;
  onCancel?: () => void;
  initialValues?: InternalCompany | CreateInternalCompanyRequest;
  disableForm?: boolean;
  adaptedSelectedEntry: TableEntry;
  companyExists: boolean;
  isLoading?: boolean;
}

export const InternalCompanyWrapperForm: React.FC<
  InternalCompanyWrapperFormProps
> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    className,
    activeKey,
    onChange,
    actionLabel,
    cancelLabel,
    onFinish,
    onCancel,
    initialValues,
    adaptedSelectedEntry,
    companyExists,
    isLoading,
  } = props;

  const bankAccounts = useMemo(
    () => initialValues?.bankAccounts,
    [initialValues?.bankAccounts]
  );

  return (
    <Tabs
      animated={false}
      defaultActiveKey="general"
      activeKey={activeKey}
      onChange={onChange}
      className={classNames(classes.root, className)}
    >
      <TabPane
        tab={t('companies:internalCompanyForm.tabs.general')}
        key="general"
      >
        <InternalCompanyForm
          actionLabel={actionLabel}
          onFinish={onFinish}
          onCancel={onCancel}
          cancelLabel={cancelLabel}
          initialValues={initialValues}
          isLoading={isLoading}
        />
      </TabPane>
      {companyExists ? (
        <TabPane
          tab={t('companies:internalCompanyForm.tabs.bankAccounts')}
          key="bankAccounts"
        >
          <InternalCompanyBankAccountForm
            initialValues={initialValues}
            actionLabel={actionLabel}
            cancelLabel={cancelLabel}
            onCancel={onCancel}
            adaptedSelectedEntry={adaptedSelectedEntry}
            bankAccounts={bankAccounts}
          />
        </TabPane>
      ) : (
        <TabPane
          tab={
            <span>
              {t('companies:internalCompanyForm.tabs.bankAccounts')}
              <Tooltip
                title={t(
                  'companies:internalCompanyForm.tooltips.noCompanyForBankAccounts'
                )}
                overlayInnerStyle={{ textAlign: 'center' }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'info-circle']}
                  className={classes.iconBankAccount}
                />
              </Tooltip>
            </span>
          }
          key="bankAccounts"
          disabled={!companyExists}
          className={classes.iconBankAccount}
        >
          <InternalCompanyBankAccountForm
            initialValues={initialValues}
            actionLabel={actionLabel}
            cancelLabel={cancelLabel}
            onCancel={onCancel}
            adaptedSelectedEntry={adaptedSelectedEntry}
            bankAccounts={bankAccounts}
          />
        </TabPane>
      )}
    </Tabs>
  );
};

export default InternalCompanyWrapperForm;
