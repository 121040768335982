import React, { useState, useCallback, useEffect } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import {
  TitleSuggestion,
  CreateTitleSuggestion,
} from '../../../models/TitleSuggestion';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import equals from 'deep-equal';
import { TitleSuggestionsTable } from './TitleSuggestionsTable';
import { TitleSuggestionId } from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTitleSuggestions,
  updateTitleSuggestion,
  deleteTitleSuggestion,
  createTitleSuggestion,
} from '../actions/titleSuggestions';
import {
  getTitleSuggestions,
  getTitleSuggestionsIsFetching,
} from '../../../apps/main/rootReducer';
import { createTemporaryId } from '../../../util';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { t } from 'i18next';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  column: {},
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface TitleSuggestionsPageProps {
  className?: string;
}

export const TitleSuggestionsPage: React.FC<TitleSuggestionsPageProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTitleSuggestions());
  }, [dispatch]);

  const titleSuggestions: TitleSuggestion[] = useSelector(getTitleSuggestions);
  const isFetching = useSelector(getTitleSuggestionsIsFetching);

  const [updatedTitleSuggestions, setUpdatedTitleSuggestions] =
    useState<TitleSuggestion[]>(null);

  const onIsDirtyChangedTitleSuggestions = useCallback(
    (isDirty: boolean, hourlyRates: TitleSuggestion[]) => {
      if (isDirty) {
        setUpdatedTitleSuggestions(hourlyRates);
      } else {
        setUpdatedTitleSuggestions(null);
      }
    },
    [setUpdatedTitleSuggestions]
  );

  const onNewSuggestion = async (newSuggestion: CreateTitleSuggestion) => {
    const temporaryId = createTemporaryId();
    dispatch(createTitleSuggestion(newSuggestion, temporaryId));
  };

  const onDelete = async (titleSuggestionId: TitleSuggestionId) => {
    dispatch(deleteTitleSuggestion(titleSuggestionId));
  };

  const onSave = async () => {
    if (updatedTitleSuggestions !== null) {
      updatedTitleSuggestions.forEach((updatedTitleSuggestion) => {
        const originalTitleSuggestion = titleSuggestions.find(
          (ts) =>
            ts.titleSuggestionId === updatedTitleSuggestion.titleSuggestionId
        );
        if (
          originalTitleSuggestion &&
          !equals(updatedTitleSuggestion, originalTitleSuggestion)
        ) {
          dispatch(
            updateTitleSuggestion(
              updatedTitleSuggestion,
              originalTitleSuggestion
            )
          );
        }
      });
    }
  };

  if (
    isFetching &&
    (titleSuggestions === null || titleSuggestions.length === 0)
  ) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.column}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <TitleSuggestionsTable
          className={classes.shadow}
          titleSuggestions={titleSuggestions}
          onIsDirtyChanged={onIsDirtyChangedTitleSuggestions}
          onNewSuggestion={onNewSuggestion}
          onDelete={onDelete}
        />

        <Flex.Row justifyContent="flex-end">
          <Button disabled={!updatedTitleSuggestions} onClick={onSave}>
            {t('settings:titleSuggestionsTable.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default TitleSuggestionsPage;
