import React, { useEffect } from 'react';
import { Notification } from '../../../models/Notification';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Project } from '../../../models/Project';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { markReadNotifications } from '../actions';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.unit(2),
  },
  secondaryText: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
  },
  accept: {
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.green,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.green,
      },
    },
  },
}));

interface InternalProjectContactNotificationItemProps {
  notification: Notification;
  onClose?: VoidFunction;
}

export const InternalProjectContactNotificationItem: React.FC<
  InternalProjectContactNotificationItemProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const { notification, onClose } = props;

  const dispatch = useDispatch();
  const activeProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, notification.dataId)
  );

  useEffect(() => {
    if (!activeProject) {
      dispatch(
        markReadNotifications(notification.notificationId, {
          ...notification,
          isRead: true,
        })
      );
    }
  }, [activeProject, notification, dispatch]);

  if (!activeProject) {
    return null;
  }

  const link = getLink(activeProject);

  const handleOnLinkClick = () => {
    dispatch(
      markReadNotifications(notification.notificationId, {
        ...notification,
        isRead: true,
      })
    );
    onClose();
  };

  const render = () => (
    <Flex.Row
      className={classes.root}
      alignItems="center"
      childrenGap={theme.old.spacing.unit(2)}
    >
      <FontAwesomeIcon
        icon={['fal', 'grip-vertical']}
        color={theme.old.palette.primaryColor}
      />
      <Flex.Column>
        <Typography.Text ellipsis>
          {t(`notifications:titel.${notification.type}`, {
            number: activeProject.number,
          })}
        </Typography.Text>
        <Typography.Text className={classes.secondaryText}>
          {t(`notifications:secondaryLabels.${notification.type}`, {
            name: activeProject.name,
            number: activeProject.number,
          })}
        </Typography.Text>
      </Flex.Column>
      <Flex.Item flex={1}></Flex.Item>
      <Flex.Item flex={3}></Flex.Item>
      <Flex.Row>
        <Button
          type="link"
          className={classes.accept}
          iconProp={['fal', 'check']}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              markReadNotifications(notification.notificationId, {
                ...notification,
                isRead: true,
              })
            );
          }}
        />
      </Flex.Row>
    </Flex.Row>
  );

  if (link) {
    return (
      <Link to={link} onClick={handleOnLinkClick}>
        {render()}
      </Link>
    );
  }
  return render();
};

const getLink: (activeProject: Project) => string = (activeProject) => {
  return `/module/prio/projects/${activeProject.projectId}/mail/inbox`;
};
