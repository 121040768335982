import { combineReducers } from 'redux';
import drawer, * as fromDrawer from './drawer';

export interface TimeAndLeaveManagementState {
  drawer: fromDrawer.TimeAndLeaveDrawerState;
}

export default combineReducers<TimeAndLeaveManagementState>({
  drawer,
});

export const getTimeAndLeaveManagementDrawerState: (
  state: TimeAndLeaveManagementState
) => fromDrawer.TimeAndLeaveDrawerState = ({ drawer }) =>
  fromDrawer.getDrawerState(drawer);
