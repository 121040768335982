import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';
import equals from 'deep-equal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    width: '25vw',
    maxHeight: '25vh',
    boxShadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    overflow: 'hidden',
  },
  list: {
    height: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.old.spacing.unit(1.5)}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    cursor: 'pointer',
    '& a': {
      color: theme.old.typography.colors.base,
      '&:hover': {
        color: theme.old.palette.primaryColor,
      },
    },
    '&:hover': {
      color: theme.old.palette.primaryColor,
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  activeItem: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
    color: theme.old.palette.primaryColor,
    fontWeight: theme.old.typography.fontWeight.bold,
    '&::before': {
      content: '""',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: theme.old.palette.primaryColor,
      width: 2,
    },
    '& a': {
      color: theme.old.palette.primaryColor,
    },
  },
  itemContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
}));

interface VirtualMenuProps<T> {
  className?: string;
  items: Array<T>;
  itemRenderer: (value: T) => string | JSX.Element;
  overscanRowCount?: number;
  onActiveChanged?: (
    value: T,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  activeItem?: T;
  identifierPropName?: string;
}

function VirtualMenu<T>(props: VirtualMenuProps<T>) {
  //#region ------------------------------ Defaults
  const {
    className,
    items,
    overscanRowCount,
    activeItem,
    itemRenderer,
    onActiveChanged,
    identifierPropName,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const ref = useRef(null);

  const _identifierPropName = useMemo(
    () => identifierPropName ?? 'id',
    [identifierPropName]
  );

  const [_activeItem, setActiveItem] = useState<T>(activeItem);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClick = (
    value: T,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (onActiveChanged) {
      onActiveChanged(value, e);
      if (activeItem) {
        setActiveItem(value);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (
      !equals(
        _activeItem?.[_identifierPropName],
        activeItem?.[_identifierPropName]
      )
    ) {
      setActiveItem(activeItem);
    }
  }, [activeItem, _activeItem, ref, _identifierPropName]);

  useEffect(() => {
    if (_activeItem) {
      ref?.current?.forceUpdate();
    }
  }, [_activeItem, ref]);
  //#endregion

  return (
    <div
      className={classNames(classes.root, className)}
      style={{ height: items.length * 32 }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <List
            className={classes.list}
            ref={ref}
            rowCount={items.length}
            rowHeight={32}
            height={height}
            width={width}
            overscanRowCount={overscanRowCount}
            rowRenderer={({ index, style }: ListRowProps) => {
              const value = items[index];
              return (
                <div
                  key={index}
                  style={style}
                  className={classNames(classes.item, {
                    [classes.activeItem]: equals(
                      _activeItem?.[_identifierPropName],
                      value?.[_identifierPropName]
                    ),
                  })}
                  onClick={(e) => handleClick(value, e)}
                >
                  <div className={classes.itemContent}>
                    {itemRenderer(items[index])}
                  </div>
                </div>
              );
            }}
          />
        )}
      </AutoSizer>
    </div>
  );
}

export default React.memo(VirtualMenu) as typeof VirtualMenu;
