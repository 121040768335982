import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import {
  CreateInvoice,
  CurrencyCode,
  Invoice,
} from '../../../models/Accounting';
import {
  DEFAULT_VAT,
  DEFAULT_VAT_REDUCED,
  NULL_VAT,
  DEFAULT_VAT_16,
  DEFAULT_VAT_REDUCED_5,
} from '../../../constants';
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  CompanyId,
  InvoiceType,
  OfficeId,
  ProjectId,
} from '../../../models/Types';
import { colon, rowGutter } from '../../../util/forms';
import { useTranslation } from 'react-i18next';
import useDatePickerLocale from '../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrencyInput } from '../../../components/CurrencyInput';
import { useForm } from 'antd/lib/form/Form';
import { makePrioStyles } from '../../../theme/utils';
import CompanyPicker from '../../companies/components/CompanyPicker';
import ProjectPicker from '../../projects/components/ProjectPicker';
import Flex from '../../../components/Flex';
import { formatMoney } from '../../../util';
import { calcNetSum, calcSums, subtractMoney } from '../util';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { createSelector } from 'reselect';
import {
  RootReducerState,
  getOffice,
  getProject,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { Office } from '../../../models/Office';
import { useSelector } from 'react-redux';
import { debounceFunction } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  mainForm: {
    overflow: 'hidden auto',
  },
  fullWidth: {
    width: '100%',
  },
  submitButtonFormItem: {
    marginTop: theme.old.spacing.unit(3),
    marginBottom: 0,
    textAlign: 'right',
  },
  positionsContainer: {
    backgroundColor: theme.old.palette.backgroundPalette.main,
    padding: theme.old.spacing.defaultPadding,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  sumLabel: {
    display: 'inline-block',
    flex: 1,
    paddingRight: theme.old.spacing.unit(5),
  },
  grossSumRow: {
    marginTop: theme.old.spacing.unit(1),
  },
  bold: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
}));

interface InvoiceFormProps {
  initialValues?: Invoice;
  disableForm?: boolean;
  actionLabel: string;
  isoCode: CurrencyCode;
  onFinish: (invoice: CreateInvoice) => Promise<boolean>;
  projectId?: ProjectId;
  officeId?: OfficeId;
  invoiceType?: InvoiceType;
}

interface InvoiceFormModel {
  number: string;
  type: InvoiceType;
  invoiceDate: Moment;
  title: string;
  projectId?: ProjectId;
  recipientCompanyId?: CompanyId;
  invoicePositions: CreateInvoicePositionFormModel[];
  billerCompanyId?: CompanyId;
}

export interface CreateInvoicePositionFormModel {
  title: string;
  pricePerUnitValue: number;
  vat: number;
  amount: number;
}

const defaultInvoicePositions: CreateInvoicePositionFormModel[] = [
  {
    title: '',
    pricePerUnitValue: 0,
    vat: DEFAULT_VAT,
    amount: 1,
  },
];

const companyIdSelector = (projectId: ProjectId, officeId: OfficeId) =>
  createSelector<
    [(state: RootReducerState) => Project, (state: RootReducerState) => Office],
    CompanyId
  >(
    (state) => getProject(state, projectId),
    (state) => getOffice(state, officeId),
    (project, office) => {
      if (project) {
        return project.subsidiaryId;
      }
      if (office) {
        return office.companyId;
      }
      return null;
    }
  );
const debouncedSetPositions = debounceFunction(
  (
    value: CreateInvoicePositionFormModel[],
    setPositions: (value: CreateInvoicePositionFormModel[]) => void
  ) => {
    setPositions(value);
  },
  500
);

export const InvoiceForm: React.FC<InvoiceFormProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();

  const [form] = useForm<InvoiceFormModel>();

  const {
    initialValues,
    onFinish,
    disableForm,
    actionLabel,
    isoCode,
    projectId,
    officeId,
    invoiceType,
  } = props;
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const companyId = useSelector(companyIdSelector(projectId, officeId));

  const derivedInitialValues: InvoiceFormModel = useMemo(
    () =>
      initialValues
        ? {
            number: initialValues.number,
            type: initialValues.type,
            title: initialValues.title,
            projectId: initialValues.projectId,
            recipientCompanyId: initialValues.recipientCompanyId,
            invoicePositions: initialValues.invoicePositions.map(
              ({ title, pricePerUnit, vat, amount }) => ({
                title,
                vat,
                amount,
                pricePerUnitValue: pricePerUnit.value,
              })
            ),
            billerCompanyId: initialValues.billerCompanyId,
            invoiceDate: moment(initialValues.invoiceDate, moment.ISO_8601),
          }
        : {
            type: invoiceType ?? 'outgoingInvoice',
            number: '',
            invoiceDate: moment(),
            title: '',
            invoicePositions: defaultInvoicePositions,
            projectId,
            billerCompanyId: companyId,
          },
    [initialValues, projectId, companyId, invoiceType]
  );

  const derivedInitialInvoicePositions = derivedInitialValues.invoicePositions;

  const [isIncomingInvoice, setIsIncomingInvoice] = useState<boolean>(
    initialValues ? initialValues.type === 'incomingInvoice' : false
  );

  const [positions, setPositions] = useState<CreateInvoicePositionFormModel[]>(
    derivedInitialValues.invoicePositions
  );

  const { netSum, grossSum } = calcSums(positions, 'EUR');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const resetPositions = () => {
    setPositions(derivedInitialValues.invoicePositions);
  };

  const handleFinish = async (formData: InvoiceFormModel) => {
    const {
      number,
      type,
      title,
      projectId,
      recipientCompanyId,
      invoicePositions,
      billerCompanyId,
    } = formData;

    if (initialValues) {
      const { rowVersion } = initialValues;

      onFinish({
        invoiceDate: formData.invoiceDate.toISOString(true).split('T')[0],
        number,
        type,
        title,
        projectId,
        recipientCompanyId,
        invoicePositions: invoicePositions.map(
          ({ title, pricePerUnitValue, vat, amount }) => ({
            title,
            vat,
            amount,
            pricePerUnit: {
              value: pricePerUnitValue,
              isoCode,
            },
          })
        ),
        billerCompanyId,
        rowVersion,
      });
    } else {
      if (
        await onFinish({
          invoiceDate: formData.invoiceDate.toISOString(true).split('T')[0],
          number,
          type,
          title,
          projectId,
          recipientCompanyId,
          invoicePositions: invoicePositions.map(
            ({ title, pricePerUnitValue, vat, amount }) => ({
              title,
              vat,
              amount,
              pricePerUnit: {
                value: pricePerUnitValue,
                isoCode,
              },
            })
          ),
          billerCompanyId,
        })
      ) {
        form.resetFields();
        resetPositions();
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.resetFields();
    setPositions(derivedInitialInvoicePositions);
  }, [form, derivedInitialInvoicePositions]);
  //#endregion

  return (
    <Form<InvoiceFormModel>
      form={form}
      className={classes.root}
      initialValues={derivedInitialValues}
      onFinish={handleFinish}
      layout="vertical"
      onValuesChange={(
        changedValues: InvoiceFormModel,
        allValues: InvoiceFormModel
      ) => {
        if (changedValues.type) {
          setIsIncomingInvoice(changedValues.type === 'incomingInvoice');
          form.setFieldsValue({
            billerCompanyId: allValues.recipientCompanyId,
            recipientCompanyId: allValues.billerCompanyId,
          });
        } else if (changedValues.invoicePositions) {
          debouncedSetPositions(allValues.invoicePositions, setPositions);
        }
      }}
    >
      <Flex.Item flex={1} className={classes.mainForm}>
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="type">
              <Radio.Group disabled={disableForm || !!invoiceType}>
                <Radio value="incomingInvoice">
                  {t('accounting:invoiceForm.incomingInvoice')}
                </Radio>
                <Radio value="outgoingInvoice">
                  {t('accounting:invoiceForm.outgoingInvoice')}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="billerCompanyId"
              label={t('accounting:invoiceForm.labels.billerCompanyId')}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t(
                    'accounting:invoiceForm.validation.missing.billerCompanyId'
                  ),
                },
              ]}
            >
              <CompanyPicker
                companyType={isIncomingInvoice ? null : 'InternalCompany'}
                disabled={disableForm}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="recipientCompanyId"
              label={t('accounting:invoiceForm.labels.recipientCompanyId')}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t(
                    'accounting:invoiceForm.validation.missing.recipientCompanyId'
                  ),
                },
              ]}
            >
              <CompanyPicker
                companyType={isIncomingInvoice ? 'InternalCompany' : null}
                disabled={disableForm}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={t('accounting:invoiceForm.labels.title')}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t('accounting:invoiceForm.validation.missing.title'),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="projectId"
              label={t('accounting:invoiceForm.labels.projectId')}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t(
                    'accounting:invoiceForm.validation.missing.projectId'
                  ),
                },
              ]}
            >
              <ProjectPicker
                disabled={disableForm || !!projectId}
                contextType={'global'}
                officeId={officeId}
                fetch
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <Form.Item
              name="number"
              label={t('accounting:invoiceForm.labels.number')}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t(
                    'accounting:invoiceForm.validation.missing.number'
                  ),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="invoiceDate"
              label={t('accounting:invoiceForm.labels.date')}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t('accounting:invoiceForm.validation.missing.date'),
                },
              ]}
            >
              <DatePicker
                disabled={disableForm}
                locale={datePickerLocale}
                format="DD.MM.YYYY"
                suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                className={classes.fullWidth}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.List name="invoicePositions">
              {(fields, { add, remove }) => {
                return (
                  <div className={classes.positionsContainer}>
                    <Typography.Title level={3}>
                      {t(
                        'accounting:invoiceForm.labels.invoicePositions.headline'
                      )}
                    </Typography.Title>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <Row gutter={rowGutter / 2}>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'amount']}
                              label={t(
                                'accounting:invoiceForm.labels.invoicePositions.amount'
                              )}
                              colon={colon}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'accounting:invoiceForm.validation.missing.invoicePositions.amount'
                                  ),
                                },
                              ]}
                            >
                              <InputNumber
                                disabled={disableForm}
                                className={classes.fullWidth}
                                decimalSeparator=","
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'pricePerUnitValue']}
                              label={t(
                                'accounting:invoiceForm.labels.invoicePositions.pricePerUnit'
                              )}
                              colon={colon}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'accounting:invoiceForm.validation.missing.invoicePositions.pricePerUnit'
                                  ),
                                },
                              ]}
                            >
                              <CurrencyInput
                                disabled={disableForm}
                                isoCode="EUR"
                                allowNegative={true}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label=" ">
                              <CurrencyInput
                                isoCode="EUR"
                                value={calcNetSum(positions[index])}
                                disabled
                                updateOnValueChange
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={rowGutter / 2}>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'vat']}
                              label={t(
                                'accounting:invoiceForm.labels.invoicePositions.vat'
                              )}
                              colon={colon}
                            >
                              <Select<number> disabled={disableForm}>
                                <Select.Option value={DEFAULT_VAT}>
                                  {t(
                                    `accounting:invoiceForm.labels.invoicePositions.vatRegular`
                                  )}
                                </Select.Option>
                                <Select.Option value={DEFAULT_VAT_16}>
                                  {t(
                                    `accounting:invoiceForm.labels.invoicePositions.vatRegular16`
                                  )}
                                </Select.Option>
                                <Select.Option value={DEFAULT_VAT_REDUCED}>
                                  {t(
                                    `accounting:invoiceForm.labels.invoicePositions.vatReduced`
                                  )}
                                </Select.Option>
                                <Select.Option value={DEFAULT_VAT_REDUCED_5}>
                                  {t(
                                    `accounting:invoiceForm.labels.invoicePositions.vatReduced5`
                                  )}
                                </Select.Option>
                                <Select.Option value={NULL_VAT}>
                                  {t(
                                    `accounting:invoiceForm.labels.invoicePositions.vatNULL`
                                  )}
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...field}
                              name={[field.name, 'title']}
                              label={t(
                                'accounting:invoiceForm.labels.invoicePositions.title'
                              )}
                              colon={colon}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'accounting:invoiceForm.validation.missing.invoicePositions.title'
                                  ),
                                },
                              ]}
                            >
                              <Input disabled={disableForm} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item>
                          <Button
                            type="link"
                            onClick={() => {
                              remove(index);
                            }}
                            iconProp={['fal', 'minus']}
                          >
                            {t('accounting:invoiceForm.actions.removePosition')}
                          </Button>
                        </Form.Item>
                        <Divider />
                      </div>
                    ))}
                    <Flex.Row>
                      <Flex.Item flex={1}>
                        <Form.Item>
                          <Button
                            type="link"
                            onClick={() => {
                              add({
                                amount: 1,
                                pricePerUnitValue: 0,
                                vat: DEFAULT_VAT,
                                title: '',
                              });
                            }}
                            iconProp={['fal', 'plus']}
                          >
                            {t('accounting:invoiceForm.actions.addPosition')}
                          </Button>
                        </Form.Item>
                      </Flex.Item>
                      <Flex.Column>
                        <Flex.Row>
                          <Typography.Text className={classes.sumLabel}>
                            {t('accounting:invoiceForm.sums.netSum')}
                          </Typography.Text>
                          <Typography.Text className={classes.textAlignRight}>
                            {formatMoney(netSum)}
                          </Typography.Text>
                        </Flex.Row>
                        <Flex.Row>
                          <Typography.Text className={classes.sumLabel}>
                            {t('accounting:invoiceForm.sums.vatSum')}
                          </Typography.Text>
                          <Typography.Text className={classes.textAlignRight}>
                            {formatMoney(subtractMoney(grossSum, netSum))}
                          </Typography.Text>
                        </Flex.Row>
                        <Flex.Row className={classes.grossSumRow}>
                          <Typography.Text
                            className={classNames(
                              classes.sumLabel,
                              classes.bold
                            )}
                          >
                            {t('accounting:invoiceForm.sums.grossSum')}
                          </Typography.Text>
                          <Typography.Text
                            className={classNames(
                              classes.textAlignRight,
                              classes.bold
                            )}
                          >
                            {formatMoney(grossSum)}
                          </Typography.Text>
                        </Flex.Row>
                      </Flex.Column>
                    </Flex.Row>
                  </div>
                );
              }}
            </Form.List>
          </Col>
        </Row>
      </Flex.Item>
      <Row justify="end">
        <Col span={24}>
          <Form.Item className={classes.submitButtonFormItem}>
            <Button htmlType="submit" disabled={disableForm}>
              {actionLabel}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InvoiceForm;
