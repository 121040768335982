import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';

import { getContact, RootReducerState } from '../../../apps/main/rootReducer';
import { TextProps } from 'antd/lib/typography/Text';
import { truncateString } from '../../../util';
import { ContactId } from '../../../models/Types';
import { Contact } from '../../../models/Contact';
import { makePrioStyles } from '../../../theme/utils';

interface ContactTextProps extends TextProps {
  contactId: ContactId;
  addLineBreak?: boolean;
  maxChars?: number;
  plain?: boolean;
  fallback?: React.ReactElement<any>;
  hasTitle?: boolean;
}

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

export const ContactText: React.FC<ContactTextProps> = (props) => {
  const {
    contactId,
    addLineBreak,
    maxChars,
    plain,
    fallback,
    hasTitle,
    ...rest
  } = props;
  const classes = useStyles();

  const contact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, contactId)
  );

  if (!contact) {
    if (fallback) return fallback;
    return null;
  }

  const text = `${contact.firstName} ${contact.lastName}`;
  const finalText = maxChars ? truncateString(text, maxChars) : text;

  if (plain) {
    return (
      <>
        {finalText}
        {addLineBreak && <br />}
      </>
    );
  }

  return (
    <Typography.Text
      className={classes.root}
      title={hasTitle ? text : undefined}
      {...rest}
      ellipsis
    >
      {finalText}
      {addLineBreak && <br />}
    </Typography.Text>
  );
};

export default ContactText;
