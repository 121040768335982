import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { TimelineGroup, TimelineItem as TimelineItemModel } from './types';
import TimelineItem from './TimelineItem';
import { calculateItemsRowOffset, filterTimelineItemsInRange } from './utils';
import { useTimelineRowContext } from './TimelineRowProvider';

const CLASS_NAME = 'prio-timeline-row';

interface TimelineRowProps {
  className?: string;
  style?: CSSProperties;
  group: TimelineGroup;
  items: TimelineItemModel[];
  itemHeight?: number;
  rowHeight: number;
  pixelsPerMs: number;
  gridSnapInMilliseconds?: number;
  maxRowOffset: number;
  onItemClick?: (item: TimelineItemModel, group: TimelineGroup) => void;
}

export const TimelineRow: React.FC<TimelineRowProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    style,
    group,
    items,
    pixelsPerMs,
    itemHeight,
    rowHeight,
    onItemClick,
    gridSnapInMilliseconds,
    maxRowOffset,
  } = props;

  const {
    prefixClassName,
    visibleTimeStart,
    visibleTimeEnd,
    startTime,
    endTime,
    prefixWidth,
    suffixWidth,
    groupHeaderRenderer,
    groupSuffixRenderer,
  } = useTimelineRowContext();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const left = startTime.diff(visibleTimeStart, 'milliseconds') * pixelsPerMs;
  const right = visibleTimeEnd.diff(endTime, 'milliseconds') * pixelsPerMs;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(CLASS_NAME, className)} style={style}>
      <div
        className={classNames(`${CLASS_NAME}-groupHeader`, prefixClassName)}
        style={{ width: prefixWidth }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        {groupHeaderRenderer ? (
          groupHeaderRenderer(group, items)
        ) : (
          <div>{group?.title}</div>
        )}
      </div>
      <div className={`${CLASS_NAME}-rowItemOuterContent`}>
        <div className={`${CLASS_NAME}-rowItemContent`} style={{ left, right }}>
          <div
            className={classNames(
              'prio-timeline-item-row',
              `${CLASS_NAME}-rowItemContentInner`
            )}
            data-group-id={group.id}
            creation-disabled={`${
              group.disabled
                ? typeof group.disabled === 'boolean'
                  ? group.disabled
                  : group.disabled(
                      filterTimelineItemsInRange(items, startTime, endTime)
                    )
                : false
            }`}
          >
            {calculateItemsRowOffset(items, startTime, endTime).map(
              ({ item, rowOffset }) => (
                <TimelineItem
                  item={item}
                  group={group}
                  rowOffset={rowOffset}
                  pixelsPerMs={pixelsPerMs}
                  rowHeight={rowHeight}
                  itemHeight={itemHeight}
                  onClick={onItemClick}
                  gridSnapInMilliseconds={gridSnapInMilliseconds}
                  maxRowOffset={maxRowOffset}
                />
              )
            )}
          </div>
        </div>
      </div>
      {groupSuffixRenderer && (
        <div
          className={`${CLASS_NAME}-suffix`}
          style={{ width: suffixWidth - 1 }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {groupSuffixRenderer(
            group,
            items,
            visibleTimeStart,
            visibleTimeEnd,
            startTime,
            endTime
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineRow;
