import { DocumentTemplateManagementAction } from '../reducers/documentTemplateManagement';
import { DocumentTemplate } from '../../../models/DocumentTemplate';
import { PRIO } from '../../../constants';

export const DOCUMENT_TEMPLATE_MANAGEMENT_SET_SELECTED_ENTRY =
  PRIO + 'DOCUMENT_TEMPLATE_MANAGEMENT_SET_SELECTED_ENTRY';

export const documentTemplateManagementSetSelectedEntry = (
  selectedEntry: DocumentTemplate | null
) => ({
  type: DOCUMENT_TEMPLATE_MANAGEMENT_SET_SELECTED_ENTRY,
  selectedEntry,
});
export const DOCUMENT_TEMPLATE_MANAGEMENT_SET_ACTION =
  PRIO + 'DOCUMENT_TEMPLATE_MANAGEMENT_SET_ACTION';

export const documentTemplateManagementSetAction = (
  value: DocumentTemplateManagementAction
) => ({
  type: DOCUMENT_TEMPLATE_MANAGEMENT_SET_ACTION,
  value,
});
