import React, { useState, useEffect } from 'react';
import equals from 'deep-equal';
import { Typography } from 'antd';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { KilometerRateSuggestion } from '../../../models/KilometerRate';
import CurrencyInput from '../../../components/CurrencyInput';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  firstCol: {
    width: 300,
    minWidth: 300,
  },
  secondCol: {
    width: 250,
    minWidth: 250,
  },
  thirdCol: {
    width: 250,
    minWidth: 250,
  },
  fourthCol: {
    width: 80,
    minWidth: 80,
  },
  numberInput: {
    flex: 1,
  },
}));

interface KilometerRateSuggestionInputProps {
  className?: string;
  kilometerRateSuggestion: KilometerRateSuggestion;
  onIsDirtyChanged?: (
    isDirty: boolean,
    kilometerRateSuggestion?: KilometerRateSuggestion
  ) => void;
}

export const KilometerRateSuggestionInput: React.FC<
  KilometerRateSuggestionInputProps
> = (props) => {
  const classes = useStyles();
  const {
    className,
    kilometerRateSuggestion: originalKilometerRate,
    onIsDirtyChanged,
  } = props;
  const [kilometerRateSuggestion, setKilometerRate] =
    useState<KilometerRateSuggestion>(originalKilometerRate);
  const { t } = useTranslation();

  useEffect(() => {
    setKilometerRate(originalKilometerRate);
    onIsDirtyChanged(false, originalKilometerRate);
  }, [originalKilometerRate, onIsDirtyChanged]);

  const updateInternalKilometerRate = (value: number) => {
    const updatedKilometerRate: KilometerRateSuggestion = {
      ...kilometerRateSuggestion,
      internalValue: value,
    };
    setKilometerRate(updatedKilometerRate);

    if (onIsDirtyChanged) {
      const wasDirty = !equals(kilometerRateSuggestion, originalKilometerRate);
      const isDirty = !equals(updatedKilometerRate, originalKilometerRate);
      if (isDirty !== wasDirty) onIsDirtyChanged(isDirty, updatedKilometerRate);
    }
  };

  const updateExternalKilometerRate = (value: number) => {
    const updatedKilometerRate: KilometerRateSuggestion = {
      ...kilometerRateSuggestion,
      externalValue: value,
    };
    setKilometerRate(updatedKilometerRate);

    if (onIsDirtyChanged) {
      const isDirty = !equals(updatedKilometerRate, originalKilometerRate);
      onIsDirtyChanged(isDirty, updatedKilometerRate);
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      <div className="ant-table-wrapper">
        <div className="ant-table">
          <div className="ant-table-container">
            <div className="ant-table-content">
              <table style={{ tableLayout: 'auto' }}>
                <colgroup>
                  <col className={classes.firstCol} />
                  <col className={classes.secondCol} />
                  <col className={classes.thirdCol} />
                  <col className={classes.fourthCol} />
                </colgroup>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" />
                    <th className="ant-table-cell">
                      <Typography.Text>
                        {t('settings:kilometerRateTable.internalValue')}
                      </Typography.Text>
                    </th>
                    <th className="ant-table-cell">
                      <Typography.Text>
                        {t('settings:kilometerRateTable.externalValue')}
                      </Typography.Text>
                    </th>
                    <th className="ant-table-cell" />
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row ant-table-row-level-0">
                    <td className="ant-table-cell">
                      <Typography.Text>
                        {t('settings:kilometerRateTable.kilometerRate')}
                      </Typography.Text>
                    </td>
                    <td className="ant-table-cell">
                      <Flex.Row>
                        <CurrencyInput
                          isoCode="EUR"
                          value={kilometerRateSuggestion?.internalValue}
                          className={classes.numberInput}
                          onChange={updateInternalKilometerRate}
                        />
                      </Flex.Row>
                    </td>
                    <td className="ant-table-cell">
                      <Flex.Row>
                        <CurrencyInput
                          isoCode="EUR"
                          value={kilometerRateSuggestion?.externalValue}
                          className={classes.numberInput}
                          onChange={updateExternalKilometerRate}
                        />
                      </Flex.Row>
                    </td>
                    <td className="ant-table-cell">
                      <div></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KilometerRateSuggestionInput;
