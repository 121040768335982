import React, { useEffect, useState } from 'react';
import { Checkbox, Card, List, Skeleton, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompanyId } from '../../../models/Types';
import { ExternalCompany } from '../../../models/Company';
import { makePrioStyles } from '../../../theme/utils';
import useCollapse from 'react-collapsed';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  card: {
    '& .ant-card-head': {
      background: theme.old.palette.chromaticPalette.red,
    },

    '& .ant-card-body': {
      padding: '0px',
    },
  },
  cardTitle: {
    color: theme.old.typography.colors.inverted,
  },
  list: {
    minHeight: '77px',
    maxHeight: '311px',
    overflowY: 'auto',
  },

  listItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingTop: theme.old.spacing.unit(2),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  warningList: {
    height: '20vh',
    overflowY: 'auto',
  },
  alert: {
    marginTop: theme.old.spacing.unit(1),
  },
  checkbox: {},
}));

interface DuplicateCompanyWarningListProps {
  duplicateCompanys: ExternalCompany[];
  onArchiveCompany: (archivedCompanys: CompanyId[]) => void;
}

const DuplicateCompanyWarningList: React.FC<
  DuplicateCompanyWarningListProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const { duplicateCompanys, onArchiveCompany } = props;
  //#endregion

  //#region -------------------------------- State declaration
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { getCollapseProps } = useCollapse({ isExpanded });

  const [selectedDuplicateIds, setSelectedDuplicateIds] = useState<CompanyId[]>(
    []
  );
  //#endregion

  //#region -------------------------------- Methods
  const duplicateCheckboxChange = (
    selected: boolean,
    duplicateId: CompanyId
  ) => {
    selected
      ? setSelectedDuplicateIds([...selectedDuplicateIds, duplicateId])
      : setSelectedDuplicateIds(
          selectedDuplicateIds.filter((dId) => dId !== duplicateId)
        );
  };
  //#endregion

  //#region -------------------------------- Components
  const checkbox = (duplicateCompany: ExternalCompany) => {
    return (
      <Tooltip title={t('companies:newCompany.duplicateAlert.action')}>
        <Checkbox
          className={classes.checkbox}
          checked={selectedDuplicateIds.includes(duplicateCompany.companyId)}
          onChange={(e) =>
            duplicateCheckboxChange(
              e.target.checked,
              duplicateCompany.companyId
            )
          }
        />
      </Tooltip>
    );
  };

  const renderItem = (item: ExternalCompany) => {
    return (
      <List.Item className={classes.listItem} actions={[checkbox(item)]}>
        <Skeleton title={false} loading={false} active>
          <List.Item.Meta
            title={<Typography.Text>{item.fullName}</Typography.Text>}
            description={<Typography.Text>{item.shortName}</Typography.Text>}
          />
        </Skeleton>
      </List.Item>
    );
  };
  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    const handleCardOpenState = () => {
      if (duplicateCompanys.length >= 1) {
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
      }
    };
    handleCardOpenState();
  }, [duplicateCompanys]);

  useEffect(() => {
    onArchiveCompany(selectedDuplicateIds);
  }, [selectedDuplicateIds, onArchiveCompany]);
  //#endregion

  return (
    <section {...getCollapseProps()} className={classes.root}>
      {isExpanded && (
        <Card
          className={classes.card}
          title={
            <Typography.Text className={classes.cardTitle}>
              {t('companies:newCompany.duplicateAlert.message')}
            </Typography.Text>
          }
        >
          <List
            className={classes.list}
            itemLayout="horizontal"
            dataSource={duplicateCompanys}
            renderItem={renderItem}
          />
        </Card>
      )}
    </section>
  );
};

export default DuplicateCompanyWarningList;
