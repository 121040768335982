import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Typography, Tooltip } from 'antd';
import { getContact, RootReducerState } from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { Contact, InternalContact } from '../../../models/Contact';
import { Employee } from '../../../models/Employee';
import { EmployeeId, OfficeId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { InternalOffice } from '../../../models/Office';
import { useSelector } from 'react-redux';
import { apiFetchInternalOffice } from '../../contacts/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    borderRadius: '4px',
    padding: 10,
    marginLeft: theme.old.spacing.unit(1),
    marginRight: theme.old.spacing.unit(4),
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  companyDataBoxLeft: {
    paddingRight: theme.old.spacing.unit(3),
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
}));

interface CompanyDataFormModel {
  officeId?: string;
  managerId?: string;
  department?: string;
  position?: string;
  eMailPrefix?: string;
  eMailSuffix?: string;
  eMail2?: string;
  eMail3?: string;
  phone?: string;
  phone2?: string;
  phone3?: string;
  cellphone?: string;
  cellphone2?: string;
  cellphone3?: string;
  notes?: string;
  signaturePrefix?: string;
}

interface ProfilePersonnelCompanyDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  disabled: boolean;
  officeId?: OfficeId;
}

export const ProfilePersonnelCompanyDataForm: React.FC<
  ProfilePersonnelCompanyDataFormProps
> = (props) => {
  const { employeeId } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [companyDataForm] = Form.useForm<CompanyDataFormModel>();
  const [office, setOffice] = useState<InternalOffice>(null);

  const contact = useSelector<RootReducerState, Contact>(
    (state) => getContact(state, employeeId) as InternalContact
  );

  const manager = useSelector<RootReducerState, Contact>(
    (state) => getContact(state, contact.managerId) as InternalContact
  );

  const contactCast = contact as InternalContact;

  //#region ------------------------------ Methods / Handlers

  const companyDataFromEmployee: (
    contact: InternalContact
  ) => CompanyDataFormModel = (contact) => ({
    officeId: contact?.officeId,
    managerId: contact?.managerId,
    department: contact?.department,
    position: contact?.position,
    eMailPrefix: contact?.eMail.split('@')[0],
    eMailSuffix: contact?.eMail.split('@')[1],
    eMail2: contact?.eMail2,
    phone: contact?.phone,
    phone2: contact?.phone2,
    cellphone: contact?.cellphone,
    cellphone2: contact?.cellphone2,
    notes: contact?.notes,
    signaturePrefix: contact?.signaturePrefix,
  });

  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    const loadOffice = async () => {
      const { result, data } = await apiFetchInternalOffice(
        contactCast.officeId
      );

      if (result.status >= 200 && result.status < 300) {
        setOffice(data);
      }
    };

    if (contactCast && office === null) {
      loadOffice();
      companyDataForm.setFieldsValue(companyDataFromEmployee(contactCast));
    }
  }, [contactCast, companyDataForm, office]);

  //#endregion

  return (
    <Flex.Column
      className={classes.root}
      childrenGap={theme.old.spacing.unit(3)}
    >
      <Typography.Title style={{ fontSize: '18px', margin: 0 }}>
        {t('profile:profileNavigation.employeeData')}
      </Typography.Title>

      <Flex.Column flex={1} childrenGap={theme.old.spacing.unit(3)}>
        <Flex.Row>
          <Flex.Column flex={1} overflow={'hidden'}>
            <Typography.Title style={{ fontSize: 13 }}>E-Mail</Typography.Title>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '90%',
              }}
            >
              <Tooltip
                title={
                  companyDataFromEmployee(contactCast).eMailPrefix +
                  '@' +
                  companyDataFromEmployee(contactCast).eMailSuffix
                }
              >
                {`${
                  companyDataFromEmployee(contactCast).eMailPrefix +
                  '@' +
                  companyDataFromEmployee(contactCast).eMailSuffix
                }`}
              </Tooltip>
            </div>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '90%',
              }}
            >
              <Tooltip title={companyDataFromEmployee(contactCast).eMail2}>
                {`${
                  !companyDataFromEmployee(contactCast).eMail2
                    ? '  '
                    : companyDataFromEmployee(contactCast).eMail2
                }`}
              </Tooltip>
            </div>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '90%',
              }}
            >
              <Tooltip title={companyDataFromEmployee(contactCast).eMail3}>
                {`${
                  !companyDataFromEmployee(contactCast).eMail3
                    ? '  '
                    : companyDataFromEmployee(contactCast).eMail3
                }`}
              </Tooltip>
            </div>
          </Flex.Column>
          <Flex.Column flex={1}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('users:form.labels.signaturePrefix')}
            </Typography.Title>

            <Flex.Item>
              {`${
                !companyDataFromEmployee(contactCast).signaturePrefix
                  ? t('profile:personalData.noData')
                  : companyDataFromEmployee(contactCast).signaturePrefix
              }`}
            </Flex.Item>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          <Flex.Column flex={1}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('contacts:form.labels.phone')}
            </Typography.Title>
            <Flex.Item>
              {`${
                !companyDataFromEmployee(contactCast).phone
                  ? ` ${t('profile:personalData.noData')} `
                  : companyDataFromEmployee(contactCast).phone
              }`}
            </Flex.Item>
            <Flex.Item>
              {`${
                !companyDataFromEmployee(contactCast).phone2
                  ? '  '
                  : companyDataFromEmployee(contactCast).phone2
              }`}
            </Flex.Item>
            <Flex.Item>
              {`${
                !companyDataFromEmployee(contactCast).phone3
                  ? '  '
                  : companyDataFromEmployee(contactCast).phone3
              }`}
            </Flex.Item>
          </Flex.Column>
          <Flex.Column flex={1}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('contacts:form.labels.cellphone')}
            </Typography.Title>
            <Flex.Item>
              {`${
                !companyDataFromEmployee(contactCast).cellphone
                  ? ` ${t('profile:personalData.noData')} `
                  : companyDataFromEmployee(contactCast).cellphone
              }`}
            </Flex.Item>
            <Flex.Item>
              {`${
                !companyDataFromEmployee(contactCast).cellphone2
                  ? '  '
                  : companyDataFromEmployee(contactCast).cellphone2
              }`}
            </Flex.Item>
            <Flex.Item>
              {`${
                !companyDataFromEmployee(contactCast).cellphone3
                  ? '  '
                  : companyDataFromEmployee(contactCast).cellphone3
              }`}
            </Flex.Item>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          {office && (
            <Flex.Column flex={1} overflow={'hidden'}>
              <Typography.Title style={{ fontSize: 13 }}>
                {t('users:form.labels.officeId')}
              </Typography.Title>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '90%',
                }}
              >
                <Tooltip title={office === null ? ' - ' : office.name}>
                  {`${office === null ? ' - ' : office.name}`}
                </Tooltip>
              </div>
            </Flex.Column>
          )}
          {manager && (
            <Flex.Column flex={1}>
              <Typography.Title style={{ fontSize: 13 }}>
                {t('users:form.labels.managerId')}
              </Typography.Title>
              <Flex.Item>{`${
                manager.firstName + ' ' + manager.lastName
              }`}</Flex.Item>
            </Flex.Column>
          )}
        </Flex.Row>
        <Flex.Row>
          {companyDataFromEmployee(contactCast).department && (
            <Flex.Column flex={1} overflow={'hidden'}>
              <Typography.Title style={{ fontSize: 13 }}>
                {t('contacts:form.labels.department')}
              </Typography.Title>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '90%',
                }}
              >
                <Tooltip
                  title={companyDataFromEmployee(contactCast).department}
                >
                  {`${companyDataFromEmployee(contactCast).department}`}
                </Tooltip>
              </div>
            </Flex.Column>
          )}
          {companyDataFromEmployee(contactCast).position && (
            <Flex.Column flex={1} overflow={'hidden'}>
              <Typography.Title style={{ fontSize: 13 }}>
                {t('contacts:form.labels.position')}
              </Typography.Title>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '90%',
                }}
              >
                <Tooltip title={companyDataFromEmployee(contactCast).position}>
                  {`${companyDataFromEmployee(contactCast).position}`}
                </Tooltip>
              </div>
            </Flex.Column>
          )}
        </Flex.Row>
        <Flex.Row>
          {companyDataFromEmployee(contactCast).notes && (
            <Flex.Column flex={1} overflow={'hidden'}>
              <Typography.Title style={{ fontSize: 13 }}>
                {t('contacts:form.labels.notes')}
              </Typography.Title>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '90%',
                }}
              >
                <Tooltip title={companyDataFromEmployee(contactCast).notes}>
                  {`${companyDataFromEmployee(contactCast).notes}`}
                </Tooltip>
              </div>
            </Flex.Column>
          )}
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default ProfilePersonnelCompanyDataForm;
