import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { Drawer, Typography, notification } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { useTranslation } from 'react-i18next';
import UploadMedicalCertificateForm, {
  UploadMedicalCertificateFormProps,
} from './UploadMedicalCertificateForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MedicalCertificate } from '../../../../models/MedicalCertificate';
import { apiFetchMedicalCertificates } from '../../api';
import { PrioFile } from '../../../../components/Upload/UploadField';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-drawer-body': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    '& .prio-form': {
      flex: 1,
      overflow: 'hidden',
    },
  },
}));

export interface MedicalCertificateDrawerRef {
  setVisible: (visible: boolean) => void;
  setMedicalCertificate: (medicalCertificate: MedicalCertificate) => void;
  refetchMedicalCertificates: () => void;
}

type MedicalCertificateDrawerProps = Omit<
  UploadMedicalCertificateFormProps,
  'initialMedicalCertificate'
> & {
  onMedicalCertificatedFetch?: (
    medicalCertificates: MedicalCertificate[]
  ) => void;
};

export const MedicalCertificateDrawer: (
  props: MedicalCertificateDrawerProps & {
    ref: ForwardedRef<MedicalCertificateDrawerRef>;
  }
) => JSX.Element = forwardRef((props, ref) => {
  //#region ------------------------------ Defaults
  const {
    className,
    officeId,
    isUserMe,
    onMedicalCertificatedFetch,
    onClose,
    ...formProps
  } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const { absenceProposalId, isEditMode } = formProps;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  const [selectedMedicalCertificate, setSelectedMedicalCertificate] =
    useState<MedicalCertificate | null>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClose = (
    medicalCertificate?: MedicalCertificate,
    file?: PrioFile
  ) => {
    setDrawerVisible(false);
    setSelectedMedicalCertificate(null);

    if (isEditMode && medicalCertificate) {
      fetchMedicalCertificates();
    }
    if (onClose) {
      onClose(medicalCertificate, file);
    }
  };

  const fetchMedicalCertificates = useCallback(async () => {
    const { data } = await apiFetchMedicalCertificates(absenceProposalId, {
      isUserMe,
      officeId,
    });

    if (data) {
      if (onMedicalCertificatedFetch) {
        onMedicalCertificatedFetch(data);
      }
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.fetchMedicalCertificatesError'),
      });
      if (onMedicalCertificatedFetch) {
        onMedicalCertificatedFetch([]);
      }
    }
  }, [absenceProposalId, isUserMe, officeId, t, onMedicalCertificatedFetch]);
  //#endregion

  //#region ------------------------------ Ref
  useImperativeHandle(ref, () => {
    return {
      setVisible: (visible: boolean) => setDrawerVisible(visible),
      setMedicalCertificate: (medicalCertificate: MedicalCertificate) =>
        setSelectedMedicalCertificate(medicalCertificate),
      refetchMedicalCertificates: fetchMedicalCertificates,
    };
  });
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (absenceProposalId) {
      fetchMedicalCertificates();
    }
  }, [absenceProposalId, fetchMedicalCertificates]);
  //#endregion

  return (
    <Drawer
      className={classNames(classes.root, className)}
      placement="right"
      closable={true}
      visible={drawerVisible}
      onClose={() => handleOnClose()}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      width={600}
      destroyOnClose
    >
      <Typography.Title level={2}>
        {t('absences:medicalCertificate.abscenceProposalSubDrawerTitle')}
      </Typography.Title>
      <UploadMedicalCertificateForm
        {...formProps}
        initialMedicalCertificate={selectedMedicalCertificate}
        onClose={handleOnClose}
        isUserMe={isUserMe}
        officeId={officeId}
      />
    </Drawer>
  );
});

export default MedicalCertificateDrawer;
