import { combineReducers } from 'redux';
import timeRecords, * as fromTimeRecords from './timeRecords';
import { TimeRecord } from '../../../models/TimeRecord';
import { ProjectId, TimeRecordId, ContactId } from '../../../models/Types';

export interface TimeRecordsReducerState {
  timeRecords: fromTimeRecords.TimeRecordsState;
}

export default combineReducers<TimeRecordsReducerState>({
  timeRecords,
});

export const getAllTimeRecords: (state: any) => TimeRecord[] = (state: any) =>
  fromTimeRecords.getAllTimeRecords(
    state.timeRecords as fromTimeRecords.TimeRecordsState
  );

export const getTimeRecordsByProject: (
  state: any,
  projectId: ProjectId
) => TimeRecord[] = (state: any, projectId: ProjectId) =>
  fromTimeRecords.getTimeRecordsByProject(
    state.timeRecords as fromTimeRecords.TimeRecordsState,
    projectId
  );

export const getTimeRecordsByContact: (
  state: any,
  contactId: ContactId,
  disableSort?: boolean
) => TimeRecord[] = (state: any, contactId: ContactId, disableSort?: boolean) =>
  fromTimeRecords.getTimeRecordsByContact(
    state.timeRecords as fromTimeRecords.TimeRecordsState,
    contactId,
    disableSort
  );

export const getTimeRecord: (
  state: any,
  timeRecordId: TimeRecordId
) => TimeRecord = (state: any, timeRecordId: TimeRecordId) =>
  fromTimeRecords.getTimeRecord(
    state.timeRecords as fromTimeRecords.TimeRecordsState,
    timeRecordId
  );

export const getTimeRecordRedirect: (
  state: any,
  temporaryId: string
) => string = (state, temporaryId) =>
  fromTimeRecords.getRedirect(
    state.timeRecords as fromTimeRecords.TimeRecordsState,
    temporaryId
  );

export const getTimeRecordsIsFetching: (state: any) => boolean = (state) =>
  fromTimeRecords.getIsFetching(state.timeRecords);
export const getTimeRecordsHasError: (state: any) => boolean = (state) =>
  fromTimeRecords.getHasError(state.timeRecords);
export const getTimeRecordsErrorMessage: (state: any) => string = (state) =>
  fromTimeRecords.getErrorMessage(state.timeRecords);

export const getTimeRecordsByIdState: (
  state: TimeRecordsReducerState
) => fromTimeRecords.TimeRecordsByIdState = (state) =>
  fromTimeRecords.getTimeRecordsByIdState(state.timeRecords);

export const getTimeRecordIds: (
  state: TimeRecordsReducerState
) => TimeRecordId[] = (state) =>
  fromTimeRecords.getTimeRecordIds(state.timeRecords);
