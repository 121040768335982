import React, { useEffect, useState } from 'react';
import { Divider, Input, notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { TrainingAudience } from '../../../models/Training';
import { apiCreateAudience, apiFetchAudiences } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface AudiencePickerProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  multiple?: boolean;
}

export const AudiencePicker: React.FC<AudiencePickerProps> = (props) => {
  const classes = useStyles();
  const [newAudienceName, setNewAudienceName] = useState<string>('');
  const [audiences, setAudiences] = useState<TrainingAudience[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const { t } = useTranslation();

  const { value, onChange, disabled, multiple } = props;

  const onNewAudienceNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewAudienceName(event.target.value);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadTags = async () => {
      try {
        const { data } = await apiFetchAudiences(signal);
        if (data && Array.isArray(data)) {
          setAudiences(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        setFetching(false);
      } catch {}
    };
    loadTags();
    return () => {
      controller.abort();
    };
  }, [setAudiences]);

  const createAudience = async () => {
    const { result, data } = await apiCreateAudience(newAudienceName);
    if (result.status >= 200 && result.status < 300) {
      setAudiences(
        [...audiences, data].sort((a, b) => a.name.localeCompare(b.name))
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:audiencePicker.errorMessages.createAudienceError'),
      });
    }
  };

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      disabled={disabled}
      className={classes.root}
      mode={multiple ? 'multiple' : null}
      value={value}
      placeholder={t('hr:audiencePicker.placeholder')}
      notFoundContent={fetching ? <PrioSpinner size="small" /> : null}
      onChange={handleOnChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              value={newAudienceName}
              onChange={onNewAudienceNameChange}
            />
            <Button
              disabled={newAudienceName.trim().length === 0}
              onClick={createAudience}
              iconProp={['fal', 'plus']}
            >
              {t('hr:audiencePicker.addAction')}
            </Button>
          </div>
        </div>
      )}
    >
      {audiences.length === 0 ? (
        <Select.Option value="" disabled>
          {t('hr:audiencePicker.emptyAudienceMessage')}
        </Select.Option>
      ) : null}
      {audiences.map(({ trainingAudienceId, name }) => (
        <Select.Option key={trainingAudienceId} value={trainingAudienceId}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AudiencePicker;
