import * as React from 'react';
import { Typography } from 'antd';
import PrioSpinner from './PrioSpinner';

export interface OfficeLoadingScreenProps {
  logo: string;
  message: string;
  title: string;
}

export default class OfficeLoadingScreen extends React.Component<OfficeLoadingScreenProps> {
  render() {
    const { logo, message, title } = this.props;

    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <img width="90" height="90" src={logo} alt={title} title={title} />
        <Typography.Title>{title}</Typography.Title>
        <PrioSpinner size="large" tip={message} />
      </div>
    );
  }
}
