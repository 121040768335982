import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import ContactsModuleNavigation from '../contacts/components/ContactsModuleNavigation';

import ContactsModule from '../contacts/components/ContactsModule';
import { makePrioStyles } from '../../theme/utils';
import GlobalSider from '../../components/GlobalSider';
import { useTranslation } from 'react-i18next';
import Flex from '../../components/Flex';
import GlobalNavigationBar from '../../components/GlobalNavigationBar';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../theme/types';
import classNames from 'classnames';
import ContactSearchPage from '../contacts/components/ContactSearchPage';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  //@ts-ignore
  navigation: {
    ...theme.old.components.navigation,
  },

  content: {
    flex: 2,
    overflow: 'auto',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
}));

export const Contacts: React.FC<{}> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classNames('prio-deprecated-design', classes.root)}>
      <GlobalSider
        title={t('common:links.contacts')}
        selectedMainMenuItem={'contacts'}
      >
        <Routes>
          <Route
            path="*"
            element={
              <ContactsModuleNavigation className={classes.navigation} />
            }
          />
          <Route
            path=":selectedList/*"
            element={
              <ContactsModuleNavigation className={classes.navigation} />
            }
          />
        </Routes>{' '}
      </GlobalSider>
      <Flex.Column flex={1} className={classes.fullHeight}>
        <GlobalNavigationBar module="contacts" />
        <div className={classes.content}>
          <Routes>
            <Route path=":selectedList/*" element={<ContactsModule />} />
            <Route path="contactSearch/*" element={<ContactSearchPage />} />
          </Routes>
        </div>
      </Flex.Column>
    </div>
  );
};

export default Contacts;
