import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import {
  CurrencyCode,
  Invoice,
  InvoicePayment,
  Money,
} from '../../../models/Accounting';
import { TFunction } from 'i18next';
import CustomTag from '../../../components/CustomTag';
import { InvoiceStatus } from '../../../models/Types';
import { compactDateFormatString, formatMoney } from '../../../util';
import { MENU_BUTTON_SIZE } from '../../../constants';
import PrioSpinner from '../../../components/PrioSpinner';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  getCompaniesByIdState,
  getProjectByIdState,
} from '../../../apps/main/rootReducer';
import { Classes } from 'jss';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { VirtualListRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualList';
import Flex from '../../../components/Flex';
import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';
import { VirtualTableBodyRef } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTableBody';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    minHeight: '440px',
    height: '100%',
  },
  table: {
    width: '100%',
    whiteSpace: 'nowrap',
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  lastDays: {
    marginTop: `${theme.old.spacing.unit(4)}px !important`,
  },
  row: {
    cursor: 'pointer',
    flexDirection: 'row!important',
    '& .prio-vt-cell': {
      flexDirection: 'column',
    },
    '& .prio-vt-cell-content': {
      height: 'unset',
      alignItems: 'center',
      padding: `10px 10px 0`,
    },
  },
  menuColum: {
    padding: '0!important',
    width: MENU_BUTTON_SIZE,
  },
  menuButton: {
    padding: '0!important',
    height: MENU_BUTTON_SIZE,
    width: MENU_BUTTON_SIZE,
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
  },
  tableHeader: {
    color: theme.old.palette.primaryColor,
  },
  tag: {
    padding: `0 ${theme.old.spacing.baseSpacing}px`,
    height: 24,
  },
  tagText: {
    lineHeight: '24px',
  },
  stateGreen: {
    '&::before': {
      content: '""',
      display: 'block',
      height: '100%',
      backgroundColor: theme.old.palette.chromaticPalette.green,
      width: 5,
    },
  },
  stateRed: {
    '&::before': {
      content: '""',
      display: 'block',
      height: '100%',
      backgroundColor: theme.old.palette.chromaticPalette.red,
      width: 5,
    },
  },
  colorGreen: {
    color: theme.old.palette.chromaticPalette.green,
  },
  colorRed: {
    color: theme.old.palette.chromaticPalette.red,
  },
}));

export const parseData = (data: InvoiceTableEntry[]): Invoice[] => {
  return data.map((invoice) => {
    return {
      ...invoice,
      grossSum: {
        value: invoice.grossTotalSum,
        isoCode: invoice.currency as CurrencyCode,
      },
      netSum: {
        value: invoice.netTotalSum,
        isoCode: invoice.currency as CurrencyCode,
      },
      invoicePayments: invoice.invoicePayments.map((payment) => ({
        ...payment,
        debitSum: {
          value: payment.debitSum.value,
          isoCode: invoice.currency as CurrencyCode,
        },
      })),
    };
  });
};

export interface InvoiceTableEntry extends Invoice {
  currency: string;
  grossTotalSum: number;
  netTotalSum: number;
  outstandingBalance: number;
  paidBelance: number;
}
export interface InvoiceTableRef {
  getInvoices: () => Invoice[];
}
interface InvoicesTableProps {
  className?: string;
  type?: 'incomingInvoices' | 'outgoingInvoices';
  invoices?: Invoice[];
  onRowClick?: (entry: Invoice) => void;
  showPayments: boolean;
  loading?: boolean;
  projectId?: string;
}

export const InvoicesTable = forwardRef(
  (props: InvoicesTableProps, ref: React.Ref<InvoiceTableRef>) => {
    //#region ------------------------------ Defaults
    const {
      className,
      type = undefined,
      invoices: invoicesProps,
      onRowClick,
      loading: loadingProp,
      showPayments,
      projectId,
    } = props;
    const classes = useStyles();
    const theme = useTheme<PrioTheme>();
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const tableRef = useRef<VirtualTableBodyRef>(null);

    const invoices = useMemo(() => {
      const _invoices = invoicesProps ?? [];
      const filteredInvoices = _invoices.sort(
        (a, b) => moment(b.invoiceDate).unix() - moment(a.invoiceDate).unix()
      );
      return filteredInvoices;
    }, [invoicesProps]);
    const loading = loadingProp;

    const companiesById = useSelector(getCompaniesByIdState);
    const projectsById = useSelector(getProjectByIdState);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const calcRowHeight = useCallback(
      (value: VirtualListRowProps<Invoice>) => {
        const { item } = value;
        if (showPayments && item && item.invoicePayments.length > 0) {
          return 40 + item.invoicePayments.length * 35;
        }
        return 40;
      },
      [showPayments]
    );

    const handleOnRow = useCallback(
      (record: Invoice) => {
        return {
          onClick: onRowClick
            ? () => {
                onRowClick(record);
              }
            : null,
        };
      },
      [onRowClick]
    );

    const classNameTableRow = useCallback(
      (invoice) => {
        return classNames(classes.row, {
          [classes.stateGreen]: invoice.type === 'outgoingInvoice' && !type,
          [classes.stateRed]: invoice.type === 'incomingInvoice' && !type,
        });
      },
      [classes.row, classes.stateGreen, classes.stateRed, type]
    );
    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<Invoice>[] = useMemo(
      () => [
        {
          title: () => headerItem('invoiceDate', t, classes),
          accessor: 'invoiceDate',
          id: 'invoiceDate',
          Cell: ({ value, originalData }) => {
            return (
              value &&
              (showPayments ? (
                <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
                  <div>{compactDateFormatString(value)}</div>
                  {originalData.invoicePayments.map(
                    ({ invoicePaymentId, date }) => (
                      <div
                        key={invoicePaymentId}
                        className={
                          originalData.type === 'incomingInvoice'
                            ? classes.colorGreen
                            : classes.colorRed
                        }
                      >
                        {compactDateFormatString(date)}
                      </div>
                    )
                  )}
                </Flex.Column>
              ) : (
                compactDateFormatString(value)
              ))
            );
          },
          sortingFn: (a, b) =>
            moment(a.invoiceDate).unix() - moment(b.invoiceDate).unix(),
          width: 120,
        },
        {
          id: 'number',
          title: () => headerItem('number', t, classes),
          accessor: 'number',
          sortingFn: (a, b) => a.number.localeCompare(b.number),
          Cell: ({ value }) => value,
          width: 150,
        },
        {
          title: () => headerItem('recipientCompanyId', t, classes),
          accessor: 'recipientCompanyId',
          id: 'recipientCompanyId',
          Cell: ({ value }) => (
            <>
              <p
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  margin: 0,
                }}
                title={
                  companiesById?.[value]
                    ? companiesById?.[value].shortName +
                        ' - ' +
                        companiesById?.[value].fullName ??
                      companiesById?.[value].shortName
                    : ''
                }
              >
                {companiesById?.[value]
                  ? companiesById?.[value].shortName +
                      ' - ' +
                      companiesById?.[value].fullName ??
                    companiesById?.[value].shortName
                  : ''}
              </p>
            </>
          ),
          sortingFn: (a, b) =>
            companiesById?.[a.recipientCompanyId]?.fullName?.localeCompare(
              companiesById?.[b.recipientCompanyId]?.fullName
            ),

          width: 250,
        },
        {
          title: () => headerItem('title', t, classes),
          accessor: 'title',
          id: 'title',
          width: 180,
          sortingFn: (a, b) => a.title.localeCompare(b.title),
          Cell: ({ value }) => value,
        },
        {
          title: () => headerItem('projectId', t, classes),
          accessor: 'projectId',
          id: 'projectId',
          Cell: ({ value }) =>
            projectsById?.[value]?.name ?? projectsById?.[value]?.shortName,
          sortingFn: (a, b) => {
            const projectA = projectsById?.[a.projectId];
            const projectB = projectsById?.[b.projectId];
            return (projectA?.name ?? projectA?.shortName ?? '').localeCompare(
              projectB?.name ?? projectB?.shortName ?? ''
            );
          },
          width: 160,
        },
        {
          title: () => headerItem('grossSum', t, classes),
          accessor: 'grossSum',
          id: 'grossSum',
          Cell: (cellProps) => {
            const { value, originalData } = cellProps;
            return (
              value &&
              (showPayments ? (
                <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
                  <div>{formatMoney(value)}</div>
                  {originalData.invoicePayments.map(
                    ({ invoicePaymentId, debitSum, paymentType: type }) => (
                      <div
                        key={invoicePaymentId}
                        className={
                          type === 'payment'
                            ? classes.colorGreen
                            : classes.colorRed
                        }
                      >
                        {formatMoney(debitSum)}
                      </div>
                    )
                  )}
                </Flex.Column>
              ) : (
                formatMoney(value)
              ))
            );
          },
          sortingFn: (a, b) => a.grossSum.value - b.grossSum.value,
          width: 135,
        },
        {
          title: () => headerItem('remainingGross', t, classes),
          accessor: 'invoicePayments',
          id: 'invoicePayments1',
          Cell: (cellProps) => {
            const { value, originalData } = cellProps;
            return originalData.netSum !== null
              ? formatMoney(
                  value !== null
                    ? calcRemainingGross(originalData, value)
                    : originalData.netSum
                )
              : null;
          },
          sortingFn: (a, b) => {
            const remainingGrossA =
              a.netSum !== null
                ? a.invoicePayments !== null
                  ? calcRemainingGross(a, a.invoicePayments).value
                  : a.netSum.value
                : 0;
            const remainingGrossB =
              b.netSum !== null
                ? b.invoicePayments !== null
                  ? calcRemainingGross(b, b.invoicePayments).value
                  : b.netSum.value
                : 0;
            return remainingGrossA - remainingGrossB;
          },
          width: 135,
        },
        {
          title: () => headerItem('netSum', t, classes),
          accessor: 'netSum',
          id: 'netSum',
          Cell: ({ value }) => (value ? formatMoney(value) : 0),
          sortingFn: (a, b) => a.netSum.value - b.netSum.value,
          width: 135,
        },
        {
          title: () => headerItem('remainingNet', t, classes),
          accessor: 'invoicePayments',
          id: 'invoicePayments2',
          Cell: (cellProps) => {
            const { value, originalData } = cellProps;
            return originalData.netSum !== null
              ? formatMoney(
                  value !== null
                    ? calcRemainingNet(originalData, value)
                    : originalData.netSum
                )
              : null;
          },
          sortingFn: (a, b) => {
            const remainingNetA =
              a.netSum !== null
                ? a.invoicePayments !== null
                  ? calcRemainingNet(a, a.invoicePayments).value
                  : a.netSum.value
                : 0;
            const remainingNetB =
              b.netSum !== null
                ? b.invoicePayments !== null
                  ? calcRemainingNet(b, b.invoicePayments).value
                  : b.netSum.value
                : 0;
            return remainingNetA - remainingNetB;
          },
          width: 135,
        },
        {
          title: () => headerItem('status', t, classes),
          accessor: 'status',
          id: 'status',
          Cell: (cellProps) => {
            const { value, originalData } = cellProps;
            return (
              value &&
              (showPayments ? (
                <Flex.Column
                  childrenGap={theme.old.spacing.unit(2)}
                  alignItems="flex-start"
                >
                  <CustomTag
                    type="text"
                    className={classes.tag}
                    textClassName={classes.tagText}
                    color={tagBackground(value, theme)}
                    text={t(`accounting:table.tags.${value}`)}
                    value={{
                      name: t(`accounting:table.tags.${value}`),
                      value,
                    }}
                  />
                  {originalData.invoicePayments.map(
                    ({ invoicePaymentId, paymentType: type }) => (
                      <div
                        key={invoicePaymentId}
                        className={
                          type === 'payment'
                            ? classes.colorGreen
                            : classes.colorRed
                        }
                      >
                        {t(`accounting:invoicePaymentType.${type}`)}
                      </div>
                    )
                  )}
                </Flex.Column>
              ) : (
                <CustomTag
                  type="text"
                  className={classes.tag}
                  textClassName={classes.tagText}
                  color={tagBackground(value, theme)}
                  text={t(`accounting:table.tags.${value}`)}
                  value={{
                    name: t(`accounting:table.tags.${value}`),
                    value,
                  }}
                />
              ))
            );
          },
          sortingFn: (a, b) => a.status.localeCompare(b.status),
          width: 150,
        },
      ],
      [classes, companiesById, projectsById, showPayments, theme, t]
    );
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (tableRef.current) {
        tableRef.current?.forceUpdate();
        tableRef.current?.recomputeRowHeights();
      }
    }, [showPayments, tableRef]);

    useImperativeHandle(ref, () => ({
      getInvoices: () => invoices,
    }));
    //#endregion

    return (
      <div className={classNames(classes.root, className)}>
        <FilterContextVirtualTable<Invoice>
          id={`invoices-table${projectId ? `-${projectId}` : ''}`}
          ref={tableRef}
          className={classes.table}
          columns={columns}
          data={invoices}
          rowHeight={calcRowHeight}
          classNameTableRow={classNameTableRow}
          onRow={handleOnRow}
          onCheckEquality={(a, b) => a.invoiceId === b.invoiceId}
          loading={
            loading && {
              type: 'overlay',
              indicator: <PrioSpinner alignSelf />,
            }
          }
          resizable="absolute"
        />
      </div>
    );
  }
);

const headerItem = (itemKey: string, t: TFunction, classes: Classes) => (
  <div className={classes.tableHeader}>{t(`accounting:table.${itemKey}`)}</div>
);

function tagBackground(status: InvoiceStatus, theme: PrioTheme) {
  if (status === 'paid') return theme.old.palette.chromaticPalette.green;
  if (status === 'partlyPaid') return theme.old.palette.primaryColor;
  return theme.old.palette.chromaticPalette.red;
}

const calcRemainingGross: (
  invoice: Invoice,
  invoicePayments: InvoicePayment[]
) => Money = (invoice, invoicePayments) => {
  return {
    value:
      invoice.grossSum.value -
      invoicePayments.reduce((sum, value) => sum + value.debitSum.value, 0),
    isoCode: invoice.grossSum.isoCode,
  };
};

const calcRemainingNet: (
  invoice: Invoice,
  invoicePayments: InvoicePayment[]
) => Money = (invoice, invoicePayments) => {
  const remainingGross = calcRemainingGross(invoice, invoicePayments);
  const x =
    invoice.grossSum.value !== 0
      ? invoice.netSum.value / invoice.grossSum.value
      : 1;
  return {
    value: remainingGross.value * x,
    isoCode: invoice.netSum.isoCode,
  };
};

export default InvoicesTable;
