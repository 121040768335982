import { PRIO } from '../../../constants';
import { apiUrl } from '../../../api';
import { NotificationId } from '../../../models/Types';
import { Notification } from '../../../models/Notification';

/** Notification fetching */
export const FETCH_NOTIFICATIONS_REQUEST = PRIO + 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_COMMIT = PRIO + 'FETCH_NOTIFICATIONS_COMMIT';
export const FETCH_NOTIFICATIONS_ROLLBACK =
  PRIO + 'FETCH_NOTIFICATIONS_ROLLBACK';

export const fetchNotifications = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/notification/Notification/me?take=100`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_NOTIFICATIONS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_NOTIFICATIONS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'notifications:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
  },
});

export const READ_NOTIFICATIONS_REQUEST = PRIO + 'READ_NOTIFICATIONS_REQUEST';
export const READ_NOTIFICATIONS_COMMIT = PRIO + 'READ_NOTIFICATIONS_COMMIT';
export const READ_NOTIFICATIONS_ROLLBACK = PRIO + 'READ_NOTIFICATIONS_ROLLBACK';

export const markReadNotifications = (
  notificationId: NotificationId,
  notification: Notification,
) => ({
  type: READ_NOTIFICATIONS_REQUEST,
  requiresAuth: true,
  payload: { notification },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/notification/Notification/me/${notificationId}/read`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        json: { ...notification },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: READ_NOTIFICATIONS_COMMIT,
        meta: { notificationId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: READ_NOTIFICATIONS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'notifications:errorMessages.fetchError',
          timeout: 6,
        },
        meta: { notificationId },
      },
    },
    notificationId,
  },
});

export const markReadNotificationsLocal = (
  notificationId: NotificationId,
  notification: Notification,
) => ({
  type: READ_NOTIFICATIONS_REQUEST,
  payload: { notification },
  meta: {
    notificationId
  },
});