import { Reducer } from 'react';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { SET_LAST_SETTINGS_LOCATION, LastSettingsLocation } from '../actions/lastSettingsLocation';

const reducer: Reducer<string, LastSettingsLocation> = (
  state = null,
  action
) => {
  switch (action.type) {
    case SET_LAST_SETTINGS_LOCATION: {
      return action.settingsLocation;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

export const getLastSettingsLocation = (state: string) => state;

export default reducer;