import { ApiResult, apiUrl } from '../../../api';
import i18n from '../../../i18n';
import {
  CompensationPayment,
  CompensationPaymentFilter,
  CreateCompensationPayment,
  CreateTimeKeepingDay,
  EditableMonthlyClose,
  MonthlyClose,
  MonthlyCloseFilter,
  OvertimeHours,
  TimeKeepingDay,
  UpdateCompensationPayment,
} from '../../../models/TimeKeeping';
import {
  CompensationPaymentId,
  DateTimeString,
  EmployeeId,
  MonthlyCloseId,
  OfficeId,
  TimeKeepingDayId,
} from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import { createErrorNotification } from '../../../util/api';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiCreateTimeKeepingDay: (
  request: CreateTimeKeepingDay
) => Promise<ApiResult<TimeKeepingDay>> = async (request) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/TimeKeepingDayMe`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.createError')
    );
  }
  return {
    result,
    data,
  };
};

export const apiUpdateTimeKeeping: (
  timeKeepingDayId: TimeKeepingDayId,
  request: CreateTimeKeepingDay,
  signal?: AbortSignal
) => Promise<ApiResult<TimeKeepingDay>> = async (
  timeKeepingDayId,
  request,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/TimeKeepingDayMe/${timeKeepingDayId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      signal,
      body: JSON.stringify(request),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.updateError')
    );
  }
  return {
    result,
    data,
  };
};

export const apiDeleteTimeKeepingDayMe: (
  timeKeepingDayId: TimeKeepingDayId
) => Promise<ApiResult<null>> = async (timeKeepingDayId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/TimeKeepingDayMe/${timeKeepingDayId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.deleteError')
    );
  }
  return {
    result,
  };
};

export const apiFetchTimeKeepingDaySuggestion: (
  startDateTime: DateTimeString,
  endDateTime: DateTimeString
) => Promise<ApiResult<TimeKeepingDay>> = async (
  startDateTime,
  endDateTime
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/TimeKeepingDayMe/suggestion/${startDateTime}/${endDateTime}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiConfirmMonthlyCloseMe: (request: {
  monthlyCloseId: MonthlyCloseId;
  userTime: DateTimeString;
}) => Promise<ApiResult<MonthlyClose>> = async (request) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/MonthlyCloseMe/confirm`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.confirmMonthlyCloseError')
    );
  }
  return {
    result,
    data,
  };
};

export const apiFetchEmployeeOvertimeHours: () => Promise<
  ApiResult<OvertimeHours[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/MonthlyCloseMe/employeesOvertimeHours`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTimeKeepingDays: (
  officeId?: OfficeId,
  controller?: AbortController
) => Promise<ApiResult<TimeKeepingDay[]>> = async (officeId, controller) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }TimeKeepingDay`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
      signal: controller?.signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiApproveTimeKeepingDay: (
  timeKeepingDayId: TimeKeepingDayId,
  officeId?: OfficeId
) => Promise<ApiResult<TimeKeepingDay>> = async (
  timeKeepingDayId,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }TimeKeepingDay/approve/${timeKeepingDayId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.approveError')
    );
  }
  return {
    result,
    data,
  };
};

export const apiRejectTimeKeepingDay: (
  timeKeepingDayId: TimeKeepingDayId,
  officeId?: OfficeId
) => Promise<ApiResult<TimeKeepingDay>> = async (
  timeKeepingDayId,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }TimeKeepingDay/reject/${timeKeepingDayId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.rejectError')
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMonthlyClose: (
  filter: MonthlyCloseFilter,
  officeId?: OfficeId,
  controller?: AbortController
) => Promise<ApiResult<MonthlyClose[]>> = async (
  filter,
  officeId,
  controller
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }MonthlyClose`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(filter),
      signal: controller?.signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMonthlyCloseMeById: (
  monthlyCloseId: MonthlyCloseId
) => Promise<ApiResult<MonthlyClose>> = async (monthlyCloseId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/MonthlyCloseMe/${monthlyCloseId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiRecalculateMonthlyClose: (
  employeeId: EmployeeId,
  officeId?: OfficeId
) => Promise<ApiResult<MonthlyClose[]>> = async (employeeId, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }MonthlyClose/${employeeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.recalculateMonthlyCloseError')
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchCompenationPayments: (
  request: CompensationPaymentFilter,
  officeId?: OfficeId
) => Promise<ApiResult<CompensationPayment[]>> = async (request, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }CompensationPayment/overview`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateCompensationPayment: (
  createRequest: CreateCompensationPayment,
  officeId?: OfficeId
) => Promise<ApiResult<CompensationPayment>> = async (
  createRequest,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }CompensationPayment`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createRequest),
    }
  );
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t(
        'timeKeeping:messages.errorMessages.createCompensationPaymentError'
      )
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateCompensationPayment: (
  updateRequest: UpdateCompensationPayment,
  compensationPaymentId: CompensationPaymentId,
  officeId?: OfficeId
) => Promise<ApiResult<CompensationPayment>> = async (
  updateRequest,
  compensationPaymentId,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }CompensationPayment/${compensationPaymentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateRequest),
    }
  );
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t(
        'timeKeeping:messages.errorMessages.updateCompensationPaymentError'
      )
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiPayCompensationPayment: (
  compensationPaymentId: CompensationPaymentId,
  officeId?: OfficeId
) => Promise<ApiResult<CompensationPayment>> = async (
  compensationPaymentId,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }CompensationPayment/pay/${compensationPaymentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(compensationPaymentId),
    }
  );
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t('timeKeeping:messages.errorMessages.payOutError')
    );
  }
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteCompensationPayment: (
  compensationPaymentId: CompensationPaymentId,
  officeId?: OfficeId
) => Promise<ApiResult<CompensationPayment>> = async (
  compensationPaymentId,
  officeId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/${
      officeId ? `office/${officeId}/` : ''
    }CompensationPayment/${compensationPaymentId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  if (result.status < 200 || result.status >= 300) {
    createErrorNotification(
      result,
      i18n.t(
        'timeKeeping:messages.errorMessages.deleteCompensatoinPaymentError'
      )
    );
  }
  return {
    result,
  };
};

export const apiFetchEditableMonthlyClose: (
  employeeId: EmployeeId
) => Promise<ApiResult<EditableMonthlyClose>> = async (employeeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timekeeping/MonthlyClose/editable/${employeeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
