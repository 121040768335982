import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { Notification } from '../../../models/Notification';
import { NotificationId } from '../../../models/Types';
import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_ROLLBACK,
  FETCH_NOTIFICATIONS_COMMIT,
  READ_NOTIFICATIONS_REQUEST,
  READ_NOTIFICATIONS_ROLLBACK,
} from '../actions';
import {
  WS_NOTIFICATION_CREATED,
  WS_NOTIFICATION_UPDATED,
} from '../actions/ws';

export interface NotificationsState {
  byId: NotificationsByIdState;
  ids: NotificationId[];
  meta: NotificationsMeta;
}

export interface NotificationsByIdState {
  [notificationId: string]: Notification;
}

const byId: Reducer<NotificationsByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_COMMIT: {
      const { payload: values } = action;
      return (values as Array<Notification>).reduce(function (map, item) {
        map[item.notificationId] = item;
        return map;
      }, {});
    }
    case READ_NOTIFICATIONS_REQUEST: {
      const {
        meta: { notificationId },
      } = action;
      return {
        ...state,
        [notificationId]: {
          ...state[notificationId],
          isRead: true,
        },
      };
    }
    case READ_NOTIFICATIONS_ROLLBACK: {
      const {
        meta: { notificationId },
      } = action;
      return {
        ...state,
        [notificationId]: {
          ...state[notificationId],
          isRead: false,
        },
      };
    }
    case WS_NOTIFICATION_UPDATED:
    case WS_NOTIFICATION_CREATED: {
      const {
        payload: { notification },
      }: { payload: { notification: Notification } } = action;
      if (
        notification.notificationId !== '' &&
        notification.notificationId !== undefined
      ) {
        return {
          ...state,
          [notification?.notificationId]: notification,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const ids: Reducer<Array<string>, any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_COMMIT: {
      const { payload: values } = action;
      return (values as Array<Notification>).map((item) => item.notificationId);
    }

    case WS_NOTIFICATION_UPDATED:
    case WS_NOTIFICATION_CREATED: {
      const {
        payload: { notification },
      }: { payload: { notification: Notification } } = action;
      if (state.includes(notification?.notificationId)) return state;
      return [...state, notification?.notificationId];
    }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

interface NotificationsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<NotificationsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_NOTIFICATIONS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_NOTIFICATIONS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'notifications:errorMessages.fetchError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<NotificationsState>({
  byId,
  ids,
  meta,
});

export const getAllNotifications: (
  state: NotificationsState,
  includeArchived?: boolean
) => Array<Notification> = (state, includeArchived = false) =>
  (state.ids ?? [])
    .map((id) => state.byId[id])
    .filter(
      (notification: Notification) =>
        (notification?.isArchived ?? false) === includeArchived
    )
    .sort((a: Notification, b: Notification) => {
      return a.notificationDateTime.localeCompare(b.notificationDateTime);
    });
export const getNotification: (
  state: NotificationsState,
  notificationId: string
) => Notification = (state, notificationId) => state.byId[notificationId];

export const getNotificationsByIdState: (
  state: NotificationsState
) => NotificationsByIdState = (state) => state.byId;
export const getNotificationsIds: (
  state: NotificationsState
) => NotificationId[] = (state) => state.ids;

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
