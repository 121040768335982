import { all, fork } from 'redux-saga/effects';
import watchRollbacks from '../../sagas/watchRollbacks';
import watchRequests from '../../sagas/watchRequests';
import watchCommits from '../../sagas/watchCommits';
import watchLoggedIn from '../../sagas/watchLoggedIn';
import webSocketFlow from '../../sagas/webSocketFlow';
import watchSyncActions from '../../sagas/watchSyncActions';
import watchDriveItems from '../../sagas/watchDriveItems';
import watchMessages from '../../sagas/watchMessages';
import watchWebsocketReconnect from '../../sagas/watchWebsocketReconnect';
import watchLoggedOut from '../../sagas/watchLoggedOut';
import watchNotifications from '../../modules/notifications/saga/watchNotifications';
import watchDebouncedCalls from '../../sagas/watchDebouncedCalls';
import watchDebouncedAction from '../../sagas/watchDebouncedActions';

export default function* root(store: any) {
  yield all([
    fork(watchRollbacks),
    fork(watchRequests),
    fork(watchCommits),
    fork(watchLoggedIn),
    fork(watchLoggedOut),
    fork(watchDebouncedAction),
    fork(watchSyncActions),
    fork(watchDriveItems),
    fork(webSocketFlow, store),
    fork(watchMessages),
    fork(watchWebsocketReconnect, store),
    fork(watchNotifications),
    fork(watchDebouncedCalls),
  ]);
}
