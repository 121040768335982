import companies, * as fromCompanies from './companies';
import { Company } from '../../../models/Company';
import { ExternalOffice, InternalOffice, Office } from '../../../models/Office';
import { combineReducers } from 'redux';
import { TableEntry } from '../components/InternalCompanyTable';
import offices, * as fromOffices from './offices';
import { OfficeId } from '../../../models/Types';

export type CompanyManagementTableEntry = TableEntry;

export interface CompaniesReducerState {
  companies: fromCompanies.CompaniesState;
  offices: fromOffices.OfficesState;
}

export default combineReducers<CompaniesReducerState>({
  companies,
  offices,
});

export const getOffset: (state: CompaniesReducerState) => string | null = (
  state
) => fromCompanies.getOffset(state.companies);

export const getLastSync: (state: CompaniesReducerState) => string = (state) =>
  fromCompanies.getLastSync(state.companies);

export const getCompaniesByIdState: (
  state: CompaniesReducerState
) => fromCompanies.CompaniesByIdState = (state) =>
  fromCompanies.getCompaniesByIdState(state.companies);
export const getCompanyIds: (state: CompaniesReducerState) => string[] = (
  state
) => fromCompanies.getCompanyIds(state.companies);
export const getAllCompanies: (
  state: CompaniesReducerState
) => Array<Company> = (state) => fromCompanies.getAllCompanies(state.companies);
export const getCompany: (
  state: CompaniesReducerState,
  companyId: string
) => Company = (state, companyId) =>
  fromCompanies.getCompany(state.companies, companyId);
export const getCompanyRedirect: (
  state: CompaniesReducerState,
  temporaryId: string
) => string = (state, temporaryId) =>
  fromCompanies.getRedirect(state.companies, temporaryId);
export const getCompaniesIsFetching: (
  state: CompaniesReducerState
) => boolean = (state) => fromCompanies.getIsFetching(state.companies);
export const getCompaniesHasError: (state: CompaniesReducerState) => boolean = (
  state
) => fromCompanies.getHasError(state.companies);
export const getCompaniesErrorMessage: (
  state: CompaniesReducerState
) => string = (state) => fromCompanies.getErrorMessage(state.companies);

//#region ------------------------------ All Offices
export const getOfficesByIdState: (
  state: CompaniesReducerState
) => fromOffices.OfficesByIdState = (state) =>
  fromOffices.getOfficesByIdState(state.offices);

export const getAllOffices: (state: CompaniesReducerState) => Office[] = (
  state
) => fromOffices.getAllOffices(state.offices);

export const getOffice: (
  state: CompaniesReducerState,
  officeID: OfficeId
) => Office = (state, officeId) =>
  fromOffices.getOffice(state.offices, officeId);

export const getOfficeIds: (state: CompaniesReducerState) => string[] = (
  state
) => fromOffices.getOfficeIds(state.offices);

export const getOfficesIsFetching: (state: CompaniesReducerState) => boolean = (
  state
) => fromOffices.getOfficesIsFetching(state.offices);

export const getOfficesHasError: (state: CompaniesReducerState) => boolean = (
  state
) => fromOffices.getOfficesHasError(state.offices);

export const getOfficesErrorMessages: (
  state: CompaniesReducerState
) => string = (state) => fromOffices.getOfficesErrorMessages(state.offices);

//#region ------------------------------ Internal Office
export const getInternalOfficesByIdState: (
  state: CompaniesReducerState
) => fromOffices.OfficesByIdState = (state) =>
  fromOffices.getInternalOfficesByIdState(state.offices);

export const getInternalOfficeIds: (
  state: CompaniesReducerState
) => string[] = (state) => fromOffices.getInternalOfficeIds(state.offices);

export const getInternalOffice: (
  state: CompaniesReducerState,
  officeId: OfficeId
) => InternalOffice = (state, officeId) =>
  fromOffices.getInternalOffice(state.offices, officeId);

export const getAllInternalOffices: (
  state: CompaniesReducerState
) => InternalOffice[] = (state) =>
  fromOffices.getAllInternalOffices(state.offices);

export const getInternalOfficesIsFetching: (
  state: CompaniesReducerState
) => boolean = (state) =>
  fromOffices.getInternalOfficesIsFetching(state.offices);

export const getInternalOfficesHasError: (
  state: CompaniesReducerState
) => boolean = (state) => fromOffices.getInternalOfficesHasError(state.offices);

export const getInternalOfficesErrorMessage: (
  state: CompaniesReducerState
) => string = (state) =>
  fromOffices.getInternalOfficesErrorMessage(state.offices);

//#region ------------------------------ External Office
export const getExternalOfficesByIdState: (
  state: CompaniesReducerState
) => fromOffices.OfficesByIdState = (state) =>
  fromOffices.getExternalOfficesByIdState(state.offices);

export const getExternalOfficeIds: (
  state: CompaniesReducerState
) => string[] = (state) => fromOffices.getExternalOfficeIds(state.offices);

export const getExternalOffice: (
  state: CompaniesReducerState,
  officeId: OfficeId
) => ExternalOffice = (state, officeId) =>
  fromOffices.getExternalOffice(state.offices, officeId);

export const getAllExternalOffices: (
  state: CompaniesReducerState
) => InternalOffice[] = (state) =>
  fromOffices.getAllExternalOffices(state.offices);

export const getExternalOfficesIsFetching: (
  state: CompaniesReducerState
) => boolean = (state) =>
  fromOffices.getExternalOfficesIsFetching(state.offices);

export const getExternalOfficesHasError: (
  state: CompaniesReducerState
) => boolean = (state) => fromOffices.getExternalOfficesHasError(state.offices);

export const getExternalOfficesErrorMessage: (
  state: CompaniesReducerState
) => string = (state) =>
  fromOffices.getExternalOfficesErrorMessage(state.offices);
