import React from 'react';
import {} from 'antd';
import { makePrioStyles } from '../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../theme/types';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makePrioStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'left',
    color: theme.colors.application.typography.muted,
  },
  icon: {
    marginRight: theme.spacing.regular,
    fontSize: theme.font.fontSize.large,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: theme.font.fontSize.small,
  },
}));

interface InformationBoxProps {
  className?: string;
  description: string;
  marginBottom?: boolean;
  icon?: IconName;
}

export const InformationBox: React.FC<InformationBoxProps> = (props) => {
  //#region ------------------------------ Defaults
  const { description, marginBottom, icon } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classes.boxContainer}
      style={marginBottom ? { marginBottom: theme.spacing.large } : {}}
    >
      <span className={classes.icon}>
        <FontAwesomeIcon icon={['fal', icon ? icon : 'circle-info']} />
      </span>
      <span className={classes.text}>{description}</span>
    </div>
  );
};

export default InformationBox;
