import { CurrencyCode, Money } from '../../../models/Accounting';
import { CreateInvoicePositionFormModel } from '../components/InvoiceForm';

export type InvoiceSums = { netSum: Money; grossSum: Money };

export const calcSums: (
  invoicePositions: CreateInvoicePositionFormModel[],
  isoCode: CurrencyCode
) => InvoiceSums = (invoicePositions, isoCode) =>
  roundSums(
    invoicePositions.reduce(
      ({ netSum, grossSum }, position) => ({
        netSum: {
          value:
            netSum.value +
            (position?.pricePerUnitValue ?? 0) * (position?.amount ?? 0),
          isoCode: netSum.isoCode,
        },
        grossSum: {
          value:
            grossSum.value +
            (position?.pricePerUnitValue ?? 0) *
              (position?.amount ?? 0) *
              (1 + (position?.vat / 100 ?? 0)),
          isoCode: netSum.isoCode,
        },
      }),
      {
        netSum: {
          value: 0,
          isoCode,
        },
        grossSum: {
          value: 0,
          isoCode,
        },
      }
    )
  );

export const roundSums: (sums: InvoiceSums) => InvoiceSums = ({
  netSum,
  grossSum,
}) => ({
  netSum: {
    value: roundCurrencyValue(netSum.value),
    isoCode: netSum.isoCode,
  },
  grossSum: {
    value: roundCurrencyValue(grossSum.value),
    isoCode: grossSum.isoCode,
  },
});

export const roundCurrencyValue = (value: number) =>
  Math.round(value * 100) / 100;
export const calcNetSum = (p: CreateInvoicePositionFormModel) =>
  p ? roundCurrencyValue((p.amount ?? 0) * (p.pricePerUnitValue ?? 0)) : 0;

export const subtractMoney: (a: Money, b: Money) => Money = (a, b) => ({
  value: a.value - b.value,
  isoCode: a.isoCode,
});
