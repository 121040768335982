import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { MessageId } from '../../../../models/Types';
import {
  MailListNavigationState,
  MailMenuNavigationState,
  MailListNavigationAction,
  MailMenuNavigationAction,
} from '../../actions/types';
import {
  UPDATE_MAIL_LIST_NAVIGATION_PROJECTS,
  UPDATE_MAIL_MENU_NAVIGATION_PROJECTS,
} from '../../actions/projects/mailNavigation';
import { DELETE_LOCAL_MESSAGES } from '../../actions/actionControllers/messageActionController';

export interface MailNavigationState {
  listNavigation: MailListNavigationState;
  menuNavigation: MailMenuNavigationState;
}

const listNavigation: Reducer<
  MailListNavigationState,
  MailListNavigationAction
> = (state = { activeMessageId: null }, action) => {
  switch (action.type) {
    case UPDATE_MAIL_LIST_NAVIGATION_PROJECTS: {
      const { activeMessageId } = action;
      return { activeMessageId };
    }

    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return { activeMessageId: null };
    }

    default:
      return state;
  }
};

const menuNavigation: Reducer<
  MailMenuNavigationState,
  MailMenuNavigationAction
> = (state = { open: false }, action) => {
  switch (action.type) {
    case UPDATE_MAIL_MENU_NAVIGATION_PROJECTS: {
      const { menuState } = action;
      return menuState;
    }

    case CLEAR_PRIO_CACHE: {
      return { open: false };
    }
    default:
      return state;
  }
};

export default combineReducers<MailNavigationState>({
  listNavigation,
  menuNavigation,
});

export const getListNavigationStateProjects: (
  state: MailNavigationState
) => MailListNavigationState = (state) => state.listNavigation;

export const isActiveProjects: (
  state: MailNavigationState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  state.listNavigation.activeMessageId === messageId;

export const isActiveAndScrollProjects: (
  state: MailNavigationState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  state.listNavigation.activeMessageId === messageId;

export const getActiveMessageIdProjects: (
  state: MailNavigationState
) => MessageId = (state) => state.listNavigation.activeMessageId;

export const getMailMenuOpenProjects: (
  state: MailNavigationState
) => boolean = (state) => state.menuNavigation.open;
