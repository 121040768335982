import lastProfileLocation, * as fromLastProfileLocation from './lastProfileLocation';
import { combineReducers } from 'redux';

export interface ProfileReducerState {
  lastProfileLocation: string;
}

export default combineReducers<ProfileReducerState>({
  lastProfileLocation,
});

export const getLastProfileLocation: (state: any) => string = (state) =>
  fromLastProfileLocation.getLastProfileLocation(state.lastProfileLocation);