import React, { useCallback, useEffect, useState } from 'react';

import { ProjectId } from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveProjectDistributionList,
  fetchProjectDistribustionList,
} from '../actions';
import {
  getProjectDistributionLists,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { Form, Modal } from 'antd';
import { Button, Drawer } from '@prio365/prio365-react-library';
import ProjectDistributionListForm from './ProjectDistributionListForm';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import ProjectDistributionListTable from './ProjectDistributionListTable';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    flex: 1,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableCell: {
    display: 'contents',
    '& > div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginLeft: '10px',
    },
  },
  actionRow: {
    marginBottom: theme.old.spacing.unit(2),
  },
  fullWidth: {
    width: '100%',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  table: {
    borderRadius: '4px',
  },
  mailPopover: {
    maxHeight: '150px',
    overflowY: 'auto',
  },
  drawerContent: {
    paddingBottom: 0,
  },
  row: {
    cursor: 'pointer',
  },
}));

interface ProjectDistributionListPageProps {
  projectId: ProjectId;
}

export const ProjectDistributionListPage: React.FC<
  ProjectDistributionListPageProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId } = props;
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [selectedDistributionList, setSelectedDistributionList] =
    useState<ProjectDistributionList>(null);

  const [selectedTableItems, setSelectedTableItems] = useState<
    ProjectDistributionList[]
  >([]);

  const projectDistributionLists = useSelector<
    RootReducerState,
    ProjectDistributionList[]
  >((state) => getProjectDistributionLists(state, projectId));
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleCreateProjectDistributionList = () => {
    setIsDrawerOpen(true);
  };

  const handleClose = () => {
    setSelectedDistributionList(null);
    setIsDrawerOpen(false);
  };

  const handleOnDelete = (entries: ProjectDistributionList[]) => {
    Modal.confirm({
      icon: null,
      title: t('projects:projectDistributionList.modal.title'),
      content: t(
        `projects:projectDistributionList.modal.content${
          entries.length === 1 ? 'Singular' : 'Plural'
        }`,
        {
          name:
            entries.length > 1
              ? entries.map((entry) => entry.name).join(', ')
              : entries[0].name,
        }
      ),
      okText: t('common:actions.delete'),
      cancelText: t('common:actions.cancel'),
      onOk() {
        entries.forEach((entry) => {
          dispatch(archiveProjectDistributionList(entry));
        });
        setSelectedTableItems([]);
      },
      onCancel() {},
    });
  };

  const handleOnRow: (
    item: ProjectDistributionList,
    index: number,
    selectedItems: ProjectDistributionList[]
  ) => VirtualListItemOnRowProps<null, null> = useCallback(
    (item, index, selectedItems) => {
      return {
        onClick: (event) => {
          event.preventDefault();
          setIsDrawerOpen(true);
          setSelectedDistributionList(item);
        },
      };
    },
    []
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(fetchProjectDistribustionList(projectId));
  }, [dispatch, projectId]);
  //#endregion

  //#region ------------------------------ Drawer Components
  const [form] = Form.useForm();
  const drawerContent = () => {
    return (
      <ProjectDistributionListForm
        initialValues={selectedDistributionList}
        openedDistributionList={selectedDistributionList}
        projectId={projectId}
        form={form}
        closeDrawer={handleClose}
        setIsSaveButtonDisabled={setIsSaveButtonDisabled}
      />
    );
  };

  const drawerFooter = () => {
    return (
      <Flex.Row className={classes.fullWidth}>
        {selectedDistributionList && (
          <Button
            className={classes.danger}
            type="default"
            onClick={() => handleOnDelete}
          >
            {t('common:actions.delete')}
          </Button>
        )}
        <Flex.Row
          flex={1}
          justifyContent="flex-end"
          childrenGap={theme.old.spacing.unit(2)}
        >
          <Button type="default" onClick={handleClose}>
            {t('common:actions.cancel')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => form.submit()}
            disabled={isSaveButtonDisabled}
          >
            {t('common:actions.save')}
          </Button>
        </Flex.Row>
      </Flex.Row>
    );
  };
  //#endregion

  return (
    <Flex.Row className={classes.root}>
      <div className={classes.content}>
        <Flex.Row className={classes.actionRow}>
          <Button
            onClick={handleCreateProjectDistributionList}
            iconProp={['fal', 'plus']}
          >
            <span>
              {t('projects:navigationBar.createProjectDistributionList')}
            </span>
          </Button>
        </Flex.Row>

        <ProjectDistributionListTable
          distributionLists={projectDistributionLists}
          onRowClick={handleOnRow}
          onDelete={handleOnDelete}
          selectedTableItems={selectedTableItems}
          setSelectedTableItems={setSelectedTableItems}
        />
      </div>
      <Drawer
        title={'Verteilerliste bearbeiten'}
        visible={isDrawerOpen}
        footer={drawerFooter()}
        children={drawerContent()}
        onClose={handleClose}
        contentClassName={classes.drawerContent}
      />
    </Flex.Row>
  );
};

export default ProjectDistributionListPage;
