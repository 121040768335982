import React, { Dispatch, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import packageJson from '../../../package.json';
import versionMap from '../../version/version-map.json';
import {
  clearPrioCache,
  clearPrioContacts,
  setPrioVersion,
} from '../../actions';
import history from '../../apps/main/history';
import { getCurrentPrioVersion } from '../../apps/main/rootReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../components/Flex';
import { makePrioStyles } from '../../theme/utils';
import Countdown from 'antd/lib/statistic/Countdown';
import { Task } from '@redux-saga/types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
  icon: {
    color: `${theme.old.palette.chromaticPalette.yellow}!important`,
  },
}));

export const mapLastVersions: (
  lastVersion: string,
  newVersion: string
) => { [key: string]: true } | boolean = (
  lastVersion: string,
  newVersion: string
) => {
  if (lastVersion === newVersion) {
    return false;
  }
  const allVersions = Object.keys(versionMap);
  const lastVersionIndex = allVersions.findIndex(
    (value) => value === lastVersion
  );
  const newVersionIndex = allVersions.findIndex(
    (value) => value === newVersion
  );

  const lastIsFirst: boolean = lastVersionIndex <= newVersionIndex;

  const slicedVersions = allVersions.slice(
    lastIsFirst ? lastVersionIndex : newVersionIndex,
    (lastIsFirst ? newVersionIndex : lastVersionIndex) + 1
  );
  if (
    slicedVersions
      .map((version) => versionMap[version])
      .filter((item) => typeof item === 'boolean')
      .includes(true)
  ) {
    return true;
  }

  if (
    slicedVersions
      .map((version) => versionMap[version])
      .filter((item) => typeof item !== 'boolean').length > 0
  ) {
    const mappedOptions: { [key: string]: true } = slicedVersions.reduce(
      (map, currentVersion) => {
        if (typeof map === 'boolean') {
          return map;
        } else {
          const versionOption = versionMap[currentVersion];
          const keys = Object.keys(versionOption).filter(
            (key) => versionOption[key]
          );
          let newMap: { [key: string]: true } = map;
          keys.forEach((key) => {
            newMap[key] = true;
          });
          return newMap;
        }
      },
      {}
    );
    return mappedOptions;
  }
  return false;
};

const clearCache = (
  options: { [key: string]: true } | boolean,
  dispatch: Dispatch<any>
) => {
  if (options) {
    if (typeof options === 'boolean') {
      dispatch(clearPrioCache());
    } else {
      if (options.contacts) {
        dispatch(clearPrioContacts());
      }
    }
    dispatch(setPrioVersion(packageJson.version));
    setTimeout(() => {
      history.replace('/module');
      window.location.reload();
    }, 2500);
  } else {
    dispatch(setPrioVersion(packageJson.version));
  }
};

interface NewPrioVersionModalProps {
  hasRegistrationCompleted: boolean;
  task: Task;
}

export const NewPrioVersionModal: React.FC<NewPrioVersionModalProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { hasRegistrationCompleted, task } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const prioVersion = useSelector(getCurrentPrioVersion);

  const mappedOptions = useMemo(
    () => mapLastVersions(prioVersion, packageJson.version),
    [prioVersion]
  );

  const handleClearReload = async () => {
    clearCache(mappedOptions, dispatch);
  };

  useEffect(() => {
    console.log('Aktualisierungs Optionen:', mappedOptions);
  }, [mappedOptions]);

  useEffect(() => {
    console.log('Prio Version IndexedDB:', prioVersion);
    console.log('Prio Version package.json:', packageJson.version);
  }, [prioVersion]);

  useEffect(() => {
    if (
      hasRegistrationCompleted &&
      !(
        !prioVersion ||
        (prioVersion !== packageJson.version && !!mappedOptions)
      )
    ) {
      dispatch(setPrioVersion(packageJson.version));
    } else if (
      hasRegistrationCompleted &&
      (!prioVersion || (prioVersion !== packageJson.version && !!mappedOptions))
    ) {
      task.cancel();
    }
  }, [hasRegistrationCompleted, mappedOptions, prioVersion, dispatch, task]);

  return (
    <Modal
      className={classes.root}
      visible={
        hasRegistrationCompleted &&
        (!prioVersion ||
          (prioVersion !== packageJson.version && !!mappedOptions))
      }
      title={
        <Flex.Row childrenGap={theme.old.spacing.baseSpacing}>
          <Flex.Item>
            <FontAwesomeIcon
              className={classes.icon}
              icon={['fal', 'exclamation-circle']}
            />
          </Flex.Item>
          <Flex.Item>{t('common:newPrioVersionModal.title')}</Flex.Item>
        </Flex.Row>
      }
      maskClosable={false}
      keyboard={false}
      closable={false}
      footer={
        <Button
          id="prio-new-version-modal-refresh"
          onClick={() => handleClearReload()}
        >
          {t('common:newPrioVersionModal.confirm')}
        </Button>
      }
    >
      <Flex.Column
        id="prio-new-version-modal"
        childrenGap={theme.old.spacing.unit(2)}
      >
        <Flex.Item>{t('common:newPrioVersionModal.contentData')}</Flex.Item>
        <Flex.Item className={classes.fullWidth}>
          <Countdown
            value={Date.now() + 10000}
            onFinish={() => handleClearReload()}
          />
        </Flex.Item>
        <Flex.Item>{t('common:newPrioVersionModal.contentReset')}</Flex.Item>
      </Flex.Column>
    </Modal>
  );
};

export default NewPrioVersionModal;
