import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import {
  RemainingAbsence,
  RemainingAbsenceV2,
} from '../../../models/RemainingAbsence';
import useFilterContext, {
  useFilterContextDataConvertion,
} from '../../../components/Filter/hooks/useFilterContext';
import FilterResultNoItemsScreen from '../../../components/Filter/FilterResultNoItemsScreen';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import useOfficesContext from '../../companies/hooks/useOfficesContext';
import { makePrioStyles } from '../../../theme/utils';
import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';
import { sortContactsHelper } from '../../contacts/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  cell: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  cellHorizontallyCentered: {
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  marginRight: {
    marginRight: 10,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
}));

export interface HRRemainingAbsencePageTableRef {
  getSelectedRemainingAbsences: () => RemainingAbsenceV2[];
  fetchRemainingAbsences: () => void;
}

interface HRRemainingAbsencePageTableProps {
  className?: string;
  tableId: string;
  onRowClick: (row: RemainingAbsence) => void;
  onRowSelectionChange: (rows: RemainingAbsenceV2[]) => void;
  selectedRemainingAbsences: RemainingAbsenceV2[];
}

export const HRRemainingAbsencePageTable = forwardRef(
  (
    props: HRRemainingAbsencePageTableProps,
    ref: React.Ref<HRRemainingAbsencePageTableRef>
  ) => {
    //#region ------------------------------ Defaults
    const {
      tableId,
      selectedRemainingAbsences,
      onRowClick,
      onRowSelectionChange,
    } = props;
    const classes = useStyles();

    const { getContactById } = useContactsContext();
    const { getOfficeById } = useOfficesContext();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { data, isLoading, fetchSearch } =
      useFilterContext<RemainingAbsenceV2>();

    const remainingAbsences = useFilterContextDataConvertion(data, true).sort(
      (a, b) => a.year - b.year
    );

    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnRow: (item: RemainingAbsenceV2) => VirtualListItemOnRowProps =
      useCallback(
        (item) => {
          return {
            onClick: (e) => {
              if (onRowClick) {
                onRowClick(item);
              }
            },
          };
        },
        [onRowClick]
      );

    const onSelectionChange = (items: RemainingAbsenceV2[]) => {
      if (onRowSelectionChange) {
        onRowSelectionChange(items);
      }
    };
    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(ref, () => ({
      getSelectedRemainingAbsences: () => {
        return selectedRemainingAbsences;
      },
      fetchRemainingAbsences: () => {
        fetchSearch();
      },
    }));
    //#endregion

    //#region ------------------------------ Column
    const columns: Column<RemainingAbsenceV2>[] = [
      {
        sortingFn: (rowA, rowB) =>
          sortContactsHelper(
            getContactById(rowA.employeeId),
            getContactById(rowB.employeeId)
          ),
        title: t(
          'absences:remainingAbsenceManagement.table.columnTitle.applicantId'
        ),
        id: 'employeeId',
        accessor: 'employeeId',
        Cell: (cellProps) => (
          <div
            className={classNames(classes.cell)}
            title={`${getContactById(cellProps.value)
              ?.firstName} ${getContactById(cellProps.value)?.lastName}`}
          >
            <div className={classes.ellipsis}>
              {`${getContactById(cellProps.value)?.firstName} ${getContactById(
                cellProps.value
              )?.lastName}`}
            </div>
          </div>
        ),
        width: 32,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) =>
          getOfficeById(a.officeId)?.name?.localeCompare(
            getOfficeById(b.officeId)?.name
          ),
        title: t(
          'absences:remainingAbsenceManagement.table.columnTitle.office'
        ),
        id: 'officeId',
        accessor: 'officeId',
        Cell: ({ originalData: { officeId } }) => (
          <div className={classes.ellipsis}>
            {getOfficeById(officeId)?.name}
          </div>
        ),
        width: 20,
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => a.year - b.year,
        title: t('absences:remainingAbsenceManagement.table.columnTitle.year'),
        id: 'year',
        accessor: 'year',
        Cell: ({ originalData: { year } }) => year,
        width: 16,
        className: classNames(
          classes.cellHorizontallyCentered,
          classes.marginRight,
          classes.cell
        ),
        classNameHeaderCell: classes.cellHorizontallyCentered,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => a.holidayEntitlement - b.holidayEntitlement,
        title: t(
          'absences:remainingAbsenceManagement.table.columnTitle.holidayEntitlements'
        ),
        id: 'holidayEntitlement',
        accessor: 'holidayEntitlement',
        Cell: ({ originalData: { holidayEntitlement } }) => holidayEntitlement,
        width: 16,
        className: classNames(
          classes.cellHorizontallyCentered,
          classes.marginRight,
          classes.cell
        ),
        classNameHeaderCell: classes.cellHorizontallyCentered,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => a.remainingEntitlement - b.remainingEntitlement,
        title: t(
          'absences:remainingAbsenceManagement.table.columnTitle.remainingAbsenceDays'
        ),
        id: 'remainingEntitlement',
        accessor: 'remainingEntitlement',
        Cell: ({ originalData: { remainingEntitlement } }) =>
          remainingEntitlement,
        width: 16,
        className: classNames(
          classes.cellHorizontallyCentered,
          classes.marginRight,
          classes.cell
        ),
        classNameHeaderCell: classes.cellHorizontallyCentered,
        alignSelf: true,
      },
    ];
    //#endregion

    return (
      <FilterContextVirtualTable<RemainingAbsenceV2>
        id={tableId}
        resizable="relative"
        rowsAreSelectable
        className={classes.root}
        data={remainingAbsences}
        noItemsScreen={<FilterResultNoItemsScreen />}
        columns={columns}
        loading={
          isLoading && {
            type: 'noItems',
          }
        }
        onRow={handleOnRow}
        onSelectionChange={onSelectionChange}
        onCheckEquality={(a, b) =>
          a.employeeId === b.employeeId && a.year === b.year
        }
      />
    );
  }
);

export default HRRemainingAbsencePageTable;
