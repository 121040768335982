import { Classes, Styles } from 'jss';
import { createUseStyles } from 'react-jss';
import { PrioTheme } from '../types';

interface UseStylesProps<C extends string = string> {
  classes?: Classes<C>;
  [key: string]: any;
}

export type ClassNameMap<C extends string = string> = Classes<C>;

export function makePrioStyles<
  C extends string = string,
  Props = UseStylesProps,
  Theme = PrioTheme
>(
  styles:
    | Styles<C, Props, Theme>
    | ((theme: Theme) => Styles<C, Props, undefined>)
): (data?: Props & { theme?: Theme }) => Classes<C> {
  return createUseStyles(styles);
}
