import React, { useState } from 'react';
import classNames from 'classnames';
import { Table, Dropdown, Menu, notification, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { tableTranslations } from '../../../util/table';
import { TrainingRequest } from '../../../models/Training';
import { compactDateFormatString } from '../../../util';
import { TrainingId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../apps/main/rootReducer';
import { parseISO } from 'date-fns';
import { apiAcceptTrainingRequest, apiDeclineTrainingRequest } from '../api';
import { MENU_BUTTON_SIZE } from '../../../constants';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table': {
      backgroundColor: 'transparent',
    },
    '& .ant-table-thead > tr > th': {
      backgroundColor: 'transparent',
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-thead > tr > th:first-child': {
      width: 5,
    },
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(2) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(2) > button': {
      visibility: 'visible',
    },
  },
  menuColum: {
    padding: '0!important',
    width: MENU_BUTTON_SIZE,
  },
  menuButton: {
    backgroundColor: 'transparent',
    height: MENU_BUTTON_SIZE,
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
  secondaryColumn: theme.old.components.table.secondaryColumn,
  stateCell: {
    height: 64,
    padding: '2px 0!important',
  },
  state: {
    height: '100%',
    width: 5,
  },
  stateGreen: {
    backgroundColor: theme.old.palette.chromaticPalette.green,
  },
  stateYellow: {
    backgroundColor: theme.old.palette.chromaticPalette.yellow,
  },
  stateRed: {
    backgroundColor: theme.old.palette.chromaticPalette.red,
  },
  stateGrey: {
    backgroundColor: theme.old.palette.chromaticPalette.grey,
  },
  emailColumn: {
    ...theme.old.components.table.secondaryColumn,
    maxWidth: 100,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

interface TrainingRequestTableProps {
  className?: string;
  trainingId: TrainingId;
  onRowClick?: (entry: TrainingRequest) => void;
  trainingRequests: TrainingRequest[];
  triggerReload: VoidFunction;
}

export const TrainingRequestTable: React.FC<TrainingRequestTableProps> = (
  props
) => {
  const classes = useStyles();

  const {
    className,
    onRowClick,
    trainingRequests: absenceProposals,
    triggerReload,
  } = props;
  const { t } = useTranslation();

  const contactsById = useSelector(getContactsByIdState);

  const [menuSubmitting, setMenuSubmitting] = useState<boolean>(false);

  const onDecline = async (entry: TrainingRequest) => {
    Modal.confirm({
      icon: null,
      title: t('hr:trainings.declineTrainingRequest.confirmationDialog.title'),
      content: t(
        'hr:trainings.declineTrainingRequest.confirmationDialog.content',
        {
          contactName: `${entry.participant.firstName} ${entry.participant.lastName}`,
        }
      ),
      okText: t(
        'hr:trainings.declineTrainingRequest.confirmationDialog.okText'
      ),
      cancelText: t(
        'hr:trainings.declineTrainingRequest.confirmationDialog.cancelText'
      ),
      onOk() {
        const promise = new Promise<void>(async (resolve) => {
          await doDecline(entry);
          resolve();
        });
        return promise;
      },
      onCancel() {},
    });
  };

  const doDecline = async (entry: TrainingRequest) => {
    setMenuSubmitting(true);
    const { result } = await apiDeclineTrainingRequest(entry.trainingRequestId);
    if (result.status >= 200 && result.status < 300) {
      triggerReload();
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'hr:trainings.successMessages.declineTrainingRequestError'
        ),
      });
    }
    setMenuSubmitting(false);
  };

  const onAccept = async (entry: TrainingRequest) => {
    Modal.confirm({
      icon: null,
      title: t('hr:trainings.acceptTrainingRequest.confirmationDialog.title'),
      content: t(
        'hr:trainings.acceptTrainingRequest.confirmationDialog.content',
        {
          contactName: `${entry.participant.firstName} ${entry.participant.lastName}`,
        }
      ),
      okText: t('hr:trainings.acceptTrainingRequest.confirmationDialog.okText'),
      cancelText: t(
        'hr:trainings.acceptTrainingRequest.confirmationDialog.cancelText'
      ),
      onOk() {
        const promise = new Promise<void>(async (resolve) => {
          await doAccept(entry);
          resolve();
        });
        return promise;
      },
      onCancel() {},
    });
  };

  const doAccept = async (entry: TrainingRequest) => {
    setMenuSubmitting(true);
    const { result } = await apiAcceptTrainingRequest(entry.trainingRequestId);
    if (result.status >= 200 && result.status < 300) {
      triggerReload();
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'hr:trainings.successMessages.acceptTrainingRequestError'
        ),
      });
    }
    setMenuSubmitting(false);
  };

  const menu = (entry: TrainingRequest) => (
    <Menu>
      <Menu.Item
        disabled={
          menuSubmitting || !(entry.trainingRequestState === 'requested')
        }
        onClick={(e) => {
          e.domEvent.stopPropagation();
          onAccept(entry);
        }}
      >
        {t('hr:trainings.requestsTableMenu.accept')}
      </Menu.Item>
      <Menu.Item
        disabled={
          menuSubmitting || !(entry.trainingRequestState === 'requested')
        }
        onClick={(e) => {
          e.domEvent.stopPropagation();
          onDecline(entry);
        }}
      >
        {t('hr:trainings.requestsTableMenu.decline')}
      </Menu.Item>
    </Menu>
  );

  const columns: ColumnProps<TrainingRequest>[] = [
    {
      className: classes.stateCell,
      render: (_, record) => {
        let className = '';
        switch (record.trainingRequestState) {
          case 'accepted':
            className = classes.stateGreen;
            break;
          case 'declined':
            className = classes.stateRed;
            break;
          case 'requested':
            className = classes.stateYellow;
            break;
          case 'revokeAccepted':
            className = classes.stateGreen;
            break;
          case 'revokeDeclined':
            className = classes.stateRed;
            break;
          case 'revokeRequested':
            className = classes.stateYellow;
            break;
        }
        return <div className={classNames(className, classes.state)}></div>;
      },
    },
    {
      title: t('hr:trainings.requestsTable.columnTitle.participantId'),
      dataIndex: 'participantId',
      width: 150,
      sorter: (a, b) =>
        a.participant?.lastName?.localeCompare(b.participant?.lastName),
      render: (value) =>
        `${contactsById[value]?.firstName ?? '???'} ${
          contactsById[value]?.lastName ?? '???'
        }`,
    },
    {
      title: t('hr:trainings.requestsTable.columnTitle.registrationDate'),
      className: classes.secondaryColumn,
      dataIndex: 'registrationDate',
      width: 50,
      sorter: (a, b) =>
        parseISO(a.registrationDate).getDate() -
        parseISO(b.registrationDate).getDate(),
      render: (value) => compactDateFormatString(value),
    },
    {
      title: t('hr:trainings.requestsTable.columnTitle.email'),
      dataIndex: 'email',
      className: classes.emailColumn,
      sorter: (a, b) =>
        a.participant.eMail?.localeCompare(b.participant?.eMail),
      render: (_, record) => record.participant?.eMail,
    },
    {
      render: (_, record) => (
        <Dropdown
          overlay={menu(record)}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            iconProp={['fal', 'ellipsis-v']}
            className={classes.menuButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Dropdown>
      ),
      className: classes.menuColum,
    },
  ];

  return (
    <Table<TrainingRequest>
      className={classNames(classes.root, className)}
      dataSource={absenceProposals}
      columns={columns}
      scroll={{ x: '100%' }}
      rowKey={(record) => record.trainingRequestId}
      locale={tableTranslations(t)}
      rowClassName={classes.row}
      onRow={(record: TrainingRequest) => {
        return {
          onClick: onRowClick
            ? () => {
                onRowClick(record);
              }
            : null,
        };
      }}
    />
  );
};

export default TrainingRequestTable;
