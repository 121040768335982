import { TextBlock } from '../../../models/TextBlock';
import { Reducer } from 'react';
import { combineReducers } from 'redux';
import {
  TEXT_BLOCK_MANAGEMENT_SET_OPEN,
  TEXT_BLOCK_MANAGEMENT_SET_SELECTED_ENTRY,
} from '../actions/textBlockManagement';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface TextBlockManagementState {
  selectedEntry: TextBlock | null;
  drawerOpened: boolean;
}

const selectedEntry: Reducer<TextBlock | null, any> = (
  state = null,
  action
) => {
  switch (action.type) {
    case TEXT_BLOCK_MANAGEMENT_SET_SELECTED_ENTRY: {
      const { selectedEntry } = action;
      return selectedEntry;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};
const drawerOpened: Reducer<boolean, any> = (
  state = false,
  action
) => {
  switch (action.type) {
    case TEXT_BLOCK_MANAGEMENT_SET_OPEN: {
      const { value } = action;
      return value;
    }
    case CLEAR_PRIO_CACHE: {
      return false;
    }
    default:
      return state;
  }
};

export default combineReducers<TextBlockManagementState>({
  selectedEntry,
  drawerOpened,
});

export const textBlockManagementGetSelectedEntry: (
  state: TextBlockManagementState
) => TextBlock | null = (state) => state.selectedEntry;
export const textBlockManagementGetOpen: (
  state: TextBlockManagementState
) => boolean = (state) => state.drawerOpened;
