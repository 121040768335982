import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import {
  TimelineGroup,
  TimelineItem,
} from '../../../../components/Timeline/types';
import { Moment } from 'moment';
import Flex from '../../../../components/Flex';
import moment from 'moment';
import DebouncedInputNumber from '../../../../components/DebouncedInputField/DebouncedInputNumber';
import { createTemporaryId } from '../../../../util';
import { TimeAndLeaveManagementTimelineItem } from '../../../timeAndLeaveManagement/selectors';
import { TimelineRef } from '../../../../components/Timeline/Timeline';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '& .ant-input-number-disabled': {
      backgroundColor: 'transparent',
    },
  },
  cell: {
    height: '100%',
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '&:last-child': {
      borderLeft: theme.old.borders.content,
    },
    '& .ant-input-number-input': {
      padding: 0,
      textAlign: 'center',
    },
  },
  baseColor: {
    color: theme.old.typography.colors.base,
  },
}));

const snapHoursTo15Minutes = (value: number) => {
  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);
  const roundedMinutes = Math.round(minutes / 15) * 15;
  return hours + roundedMinutes / 60;
};

interface GroupSuffixProps {
  className?: string;
  group: TimelineGroup;
  items: TimeAndLeaveManagementTimelineItem[];
  visibleTimeStart: Moment;
  visibleTimeEnd: Moment;
  startTime: Moment;
  endTime: Moment;
  timelineRef: React.MutableRefObject<TimelineRef>;
  onItemChange: (
    groupId: string,
    item: TimeAndLeaveManagementTimelineItem
  ) => Promise<TimelineItem>;
  onItemCreate: (
    groupId: string,
    item: TimelineItem,
    forceUpdate: boolean
  ) => Promise<TimeAndLeaveManagementTimelineItem>;
  onBreakChange: (
    groupId: string,
    item: TimeAndLeaveManagementTimelineItem
  ) => void;
}

export const GroupSuffix: React.FC<GroupSuffixProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    group,
    items,
    startTime,
    timelineRef,
    onItemChange,
    onItemCreate,
    onBreakChange,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [previousValue, setPreviousValue] = React.useState<number | undefined>(
    undefined
  );
  const _items = items
    .filter(
      (item) =>
        !(item?.type === 'absence' || item?.type === 'timeTrackingSuggestion')
    )
    .sort(
      (a, b) =>
        new Date(a.startDateTime).getTime() -
        new Date(b.startDateTime).getTime()
    );

  const sum = _items
    .reduce(
      (currentArray, item) => [
        ...currentArray,
        ...(item?.entries ?? []).map(({ startTime, endTime }) => ({
          startTime,
          endTime,
        })),
      ],
      []
    )
    .map((item) => {
      const start = moment(item.startTime);
      const end = moment(item.endTime);
      const duration = moment.duration(end.diff(start));
      return duration.asHours();
    })
    .reduce((a, b) => a + b, 0);

  const breakSum =
    _items
      .map((item) => {
        const start = moment(item.startDateTime);
        const end = moment(item.endDateTime);
        const duration = moment.duration(end.diff(start));
        return duration.asHours();
      })
      .reduce((a, b) => a + b, 0) - sum;

  const endEntryTimeInHours = useMemo(() => {
    const item = _items[_items.length - 1];
    const endEntry = item?.entries?.[(item?.entries?.length ?? 0) - 1];
    if (endEntry) {
      const start = moment(endEntry.startTime);
      const end = moment(endEntry.endTime);
      const duration = moment.duration(end.diff(start));
      return duration.asHours();
    }
    return 0.5;
  }, [_items]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleValueChange = (
    currentValue: number,
    actionHandler: (value: number) => void
  ) => {
    if (currentValue !== previousValue) {
      actionHandler(currentValue);
      setPreviousValue(currentValue);
    }
  };
  const formatter: (
    value: number,
    info: { userTyping: boolean; input: string }
  ) => string = (value, info) => {
    let _value = value;
    if (!info.userTyping) {
      _value = Math.round(value * 4) / 4;
    }
    return `${_value}`.replace('.', ',');
  };

  const handleOnSumChange = async (value: number) => {
    if (value <= 0) {
      return;
    }
    if (timelineRef.current) {
      timelineRef.current.setIsFetching(true);
    }
    let diffInMinutes = 60 * snapHoursTo15Minutes(value);
    if (_items.length === 0) {
      const dateString = startTime.toISOString(true).split('T')[0];
      const _startTime = moment(`${dateString}T08:00`);

      if (group.id === 'timeKeeping') {
        if (diffInMinutes >= 360) {
          diffInMinutes += 30;
        }
        if (diffInMinutes >= 540) {
          diffInMinutes += 15;
        }
      }

      const endTime = _startTime.clone().add(diffInMinutes, 'minutes');

      const item: TimelineItem = {
        startDateTime: _startTime.toISOString(true).split('.')[0],
        endDateTime: endTime.toISOString(true).split('.')[0],
        groupId: group.id,
        id: createTemporaryId(),
        title: '',
      };
      await onItemCreate(group.id, item, true);
    } else {
      const item = _items[0] as TimeAndLeaveManagementTimelineItem;
      const newItem: TimeAndLeaveManagementTimelineItem = {
        ...item,
        endDateTime: moment(item.startDateTime)
          .add(diffInMinutes + breakSum * 60, 'minutes')
          .toISOString(true)
          .split('.')[0],
      };
      await onItemChange(group.id, newItem);
    }
    if (timelineRef.current) {
      timelineRef.current.setIsFetching(false);
      timelineRef.current.forceUpdate();
    }
  };

  const handleOnBreakChange = (value: number) => {
    if (value < 0 || sum === 0 || value > sum + breakSum - 0.5) {
      return;
    }
    const breakInMinutes = 60 * snapHoursTo15Minutes(value);
    if (_items.length === 1) {
      const { startDateTime, endDateTime, entries } = _items[0];
      const sortedEntries = entries.sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      );
      const hasAlreadyBreak = entries.length > 1;
      const diffStartEndDateTime = moment(endDateTime).diff(
        startDateTime,
        'minutes'
      );
      if (value === 0 && hasAlreadyBreak) {
        const newItem: TimeAndLeaveManagementTimelineItem = {
          ..._items[0],
          entries: [
            {
              startTime: startDateTime,
              endTime: endDateTime,
            },
          ],
        };
        onBreakChange(group.id, newItem);
      } else {
        const halfTimeStart = (diffStartEndDateTime - breakInMinutes) / 2;
        const snappedHalfTimeStart = Math.round(halfTimeStart / 15) * 15;
        let breakStartTime = hasAlreadyBreak
          ? sortedEntries[0].endTime
          : moment(startDateTime)
              .add(snappedHalfTimeStart, 'minutes')
              .toISOString(true)
              .split('.')[0];
        let breakEndTime = moment(breakStartTime)
          .add(breakInMinutes, 'minutes')
          .toISOString(true)
          .split('.')[0];

        if (
          moment(breakEndTime).isSameOrAfter(
            moment(endDateTime).subtract(15, 'minutes')
          )
        ) {
          breakEndTime = moment(endDateTime)
            .subtract(15, 'minutes')
            .toISOString(true)
            .split('.')[0];
          breakStartTime = moment(breakEndTime)
            .subtract(breakInMinutes, 'minutes')
            .toISOString(true)
            .split('.')[0];
        }

        const newItem: TimeAndLeaveManagementTimelineItem = {
          ..._items[0],
          entries: [
            {
              startTime: startDateTime,
              endTime: breakStartTime,
            },
            {
              startTime: breakEndTime,
              endTime: endDateTime,
            },
          ],
        };
        onBreakChange(group.id, newItem);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      className={classNames(classes.root, className)}
      alignItems="center"
    >
      <Flex.Item className={classes.cell}>
        {group.id !== 'addProject' && (
          <DebouncedInputNumber
            className={
              (_items.length !== 1 ||
                (_items.length === 1 &&
                  (_items[0]?.entries ?? []).length > 2) ||
                !!_items.find(({ disabled }) => disabled)) &&
              classes.baseColor
            }
            value={breakSum}
            bordered={false}
            controls={false}
            step={0.25}
            min={-0.001}
            max={sum === 0 ? 0 : sum + breakSum - 0.5}
            formatter={formatter}
            decimalSeparator=","
            disabled={
              _items.length !== 1 ||
              (_items.length === 1 && (_items[0]?.entries ?? []).length > 2) ||
              !!_items.find(({ disabled }) => disabled)
            }
            onBlur={(e) => {
              const currentValue = parseFloat(e.target.value.replace(',', '.'));
              handleValueChange(currentValue, handleOnBreakChange);
            }}
            onPressEnter={(e) => {
              const currentValue = parseFloat(
                (e.target as HTMLInputElement).value.replace(',', '.')
              );
              handleValueChange(currentValue, handleOnBreakChange);
            }}
          />
        )}
      </Flex.Item>
      <Flex.Item className={classes.cell}>
        {group.id !== 'addProject' && (
          <DebouncedInputNumber
            value={sum}
            className={
              (_items.length > 1 ||
                !!_items.find(({ disabled }) => disabled)) &&
              classes.baseColor
            }
            bordered={false}
            controls={false}
            step={0.25}
            min={sum - endEntryTimeInHours + 0.25}
            max={24}
            formatter={formatter}
            decimalSeparator=","
            disabled={
              _items.length > 1 || !!_items.find(({ disabled }) => disabled)
            }
            onBlur={(e) => {
              const currentValue = parseFloat(e.target.value.replace(',', '.'));
              handleValueChange(currentValue, handleOnSumChange);
            }}
            onPressEnter={(e) => {
              const currentValue = parseFloat(
                (e.target as HTMLInputElement).value.replace(',', '.')
              );
              handleValueChange(currentValue, handleOnSumChange);
            }}
          />
        )}
      </Flex.Item>
    </Flex.Row>
  );
};

export default GroupSuffix;
