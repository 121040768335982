import React from 'react';
import { Contact } from '../../../models/Contact';
import { ProjectId } from '../../../models/Types';
import { InternalProjectContact } from '../../../models/ProjectContacts';

export interface InternalProjectContactsContextProps {
  getInternalProjectContacts: (
    projectId: ProjectId
  ) => InternalProjectContact[];
  getInternalProjectContactsContacts: (projectId: ProjectId) => Contact[];
}

const INITIAL_INTERNAL_PROJECT_CONTACTS_CONTEXT_VALUE: InternalProjectContactsContextProps =
  {
    getInternalProjectContacts: () => [],
    getInternalProjectContactsContacts: () => [],
  };

export const InternalProjectContactsContext =
  React.createContext<InternalProjectContactsContextProps>(
    INITIAL_INTERNAL_PROJECT_CONTACTS_CONTEXT_VALUE
  );
