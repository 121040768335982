import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Modal, Row, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { setDocumentsMetaState } from '../../actions/meta';
import { DriveItem } from '../../../../models/Drive';
import Flex from '../../../../components/Flex';
import { CreateDriveUserRemoteItem } from '../../../../models/Document';
import { Project } from '../../../../models/Project';
import { createTemporaryId } from '../../../../util';
import { createRemoteFolder } from '../../actions/remoteFolders';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    maxHeight: '50vh',
    '& .ant-modal-content': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    '& .ant-modal-body': {
      flex: 1,
      overflow: 'scroll',
    },
  },
}));

interface CreateRemoteFolderModalProps {
  visible: boolean;
  driveItem: DriveItem;
  project: Project;
  onOk: VoidFunction;
  onCancel: VoidFunction;
}

export const CreateRemoteFolderModal: React.FC<CreateRemoteFolderModalProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { visible, driveItem, project, onOk, onCancel } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<CreateDriveUserRemoteItem>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const initialValues: CreateDriveUserRemoteItem = useMemo(
    () => ({
      name: project?.number + ' - ' + driveItem?.name,
      driveItemId: driveItem?.id,
      groupId: project?.groupId,
      isRootFolder: driveItem?.name === 'root',
    }),
    [driveItem, project]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onOkClick = async (value: CreateDriveUserRemoteItem) => {
    dispatch(
      setDocumentsMetaState({
        showCreateRemoteFolderModal: false,
      })
    );
    value.driveItemId = driveItem.id;
    value.groupId = project.groupId;
    value.isRootFolder = driveItem?.name === 'root';
    await dispatch(
      createRemoteFolder(value, project.projectId, createTemporaryId())
    );
    onOk();
  };

  const onCancelClick = () => {
    dispatch(
      setDocumentsMetaState({
        showCreateRemoteFolderModal: false,
      })
    );
    onCancel();
  };
  //#endregion

  //#region ------------------------------ useEffects

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);
  //#endregion

  return (
    <Modal
      title={t('documents:confirmation.createRemoteItem.title')}
      okText={t('documents:confirmation.createRemoteItem.okText')}
      cancelText={t('documents:confirmation.createRemoteItem.cancelText')}
      maskClosable={false}
      onCancel={onCancelClick}
      onOk={() => {
        form.submit();
      }}
      visible={visible}
      className={classes.root}
    >
      <Flex.Column>
        <Typography.Text>
          {t('documents:confirmation.createRemoteItem.content')}
        </Typography.Text>
        <Form<CreateDriveUserRemoteItem>
          initialValues={initialValues}
          form={form}
          layout="vertical"
          onFinish={onOkClick}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t('documents:confirmation.createRemoteItem.folderName')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Flex.Column>
    </Modal>
  );
};

export default CreateRemoteFolderModal;
