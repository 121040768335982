import React, { useEffect, useState } from 'react';

import { Input, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { fetchUsers, updateUserGlobalRoles } from '../actions/users';
import PrioUserRoleTable from './PrioUserRoleTable';
import useDebounce from '../../../hooks/useDebounce';
import useUserSearch from '../hooks/useUserSearch';
import Flex from '../../../components/Flex';
import { GlobalRole } from '../../../models/Types';
import { User } from '../../../models/User';
import equals from 'deep-equal';
import { getUsersIsFetching } from '../../../apps/main/rootReducer';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overvlow: 'hidden',
  },
  mainComponent: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
  },
  searchInput: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  saveButton: {
    alignSelf: 'flex-end',
  },
  module: {
    height: '100%',
    overflow: 'hidden',
  },
  table: {
    height: '100%',
    overflow: 'auto',
  },
  panel: {
    transition: 'width 375ms ease-in-out',
    overflow: 'hidden',
    position: 'relative',
  },
  details: {
    height: '100%',
    padding: `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(
      6
    )}px ${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(1)}px`,
  },
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
}));

interface PrioUserRoleManagementProps {
  mainComponentClassName?: string;
  searchInputClassName?: string;
}

export const PrioUserRoleManagement: React.FC<PrioUserRoleManagementProps> = (
  props
) => {
  const { mainComponentClassName, searchInputClassName } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = React.useState<string>('');
  const debouncedSearchInput = useDebounce(searchInput, 500);

  const originalUsers = useUserSearch({ searchTerm: debouncedSearchInput });

  const isFetching = useSelector(getUsersIsFetching);

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
  };

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const [users, setUsers] = useState<User[]>(originalUsers);

  const changed = !equals(users, originalUsers);

  useEffect(() => {
    setUsers(originalUsers);
  }, [originalUsers]);

  const updateRoles = (contactId: string, value: GlobalRole[]) => {
    const updatesUsers = users.map((user) =>
      user.id === contactId
        ? {
            ...user,
            prioData: {
              ...user.prioData,
              globalRoles: value,
            },
          }
        : user
    );
    setUsers(updatesUsers);
  };

  const saveUpdatedRoles = () => {
    let userCount = 0;
    users.forEach((user) => {
      const originalUser = originalUsers.find((oU) => oU.id === user.id);
      if (
        !equals(originalUser.prioData.globalRoles, user.prioData.globalRoles)
      ) {
        dispatch(updateUserGlobalRoles(user, originalUser));
        userCount = userCount + 1;
      }
    });
  };

  if (isFetching && originalUsers.length === 0) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <PrioSpinner size="large" />
      </div>
    );
  }

  return (
    <Flex.Row flex={1} className={classes.module}>
      <Flex.Column
        className={classNames(classes.mainComponent, mainComponentClassName)}
        flex={1}
      >
        <Flex.Row justifyContent={'space-between'} alignItems={'center'}>
          <Typography.Title>
            {t('users:usersTable.roles.globalRoles')}
          </Typography.Title>
          <Input.Search
            allowClear
            size="middle"
            placeholder={t('common:searchPlaceHolder')}
            onChange={onSearchInputChange}
            className={classNames(classes.searchInput, searchInputClassName)}
          />
        </Flex.Row>
        <Flex.Item className={classes.table}>
          <PrioUserRoleTable
            users={users}
            onUpdateRoles={updateRoles}
            loading={isFetching && users.length === 0}
          />
        </Flex.Item>
        <Button
          className={classes.saveButton}
          disabled={!changed}
          onClick={saveUpdatedRoles}
        >
          {t('common:save')}
        </Button>
      </Flex.Column>
    </Flex.Row>
  );
};

export default PrioUserRoleManagement;
