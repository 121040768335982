import React, { useMemo, useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import {
  OfficeRole,
  TimeRecordsModuleContextType,
} from '../../../models/Types';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import classNames from 'classnames';
import TimeRecordsPageTable, {
  TimeRecordsPageTableRef,
} from './TimeRecordsPageTable';
import { TimeRecord } from '../../../models/TimeRecord';
import { Drawer, Modal } from '@prio365/prio365-react-library';
import { useTheme } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, notification } from 'antd';
import EditTimeRecord from './EditTimeRecord/EditTimeRecord';
import { useTranslation } from 'react-i18next';
import { apiDeleteTimeRecords } from '../api';
import { ApiResult } from '../../../api';
import { deleteMyTimeRecord } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import { PrioTheme } from '../../../theme/types';
import { useParams } from 'react-router-dom';
import useOfficesContext from '../../companies/hooks/useOfficesContext';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: 24,
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  panelHeadline: {
    '&.ant-typography': {
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  form: {
    height: 'calc(100% - 33px)',
  },
  filterbar: { marginBottom: theme.spacing.regular },
  totalDisplay: {
    marginBottom: theme.spacing.regular,
    fontStyle: 'italic',
    fontSize: theme.font.fontSize.small,
  },
}));

interface TimeRecordsPageProps {
  className?: string;
  contextType?: TimeRecordsModuleContextType;
  officeRoles?: OfficeRole[];
  setSelectedTimeRecordsObjects?: (value: TimeRecord[]) => void;
  setTimeRecords?: (value: TimeRecord[]) => void;
  setReportDrawerVisible?: (value: boolean) => void;
  setSearchString?: (value: string) => void;
}

export const TimeRecordsPage: React.FC<TimeRecordsPageProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    contextType,
    officeRoles,
    setSelectedTimeRecordsObjects,
    setTimeRecords,
    setReportDrawerVisible,
    setSearchString,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);
  const dispatch = useDispatch();
  const { officeId, projectId } = useParams();

  const { getOfficeById } = useOfficesContext();

  const subsidaryId = useMemo(() => {
    const office = getOfficeById(officeId);
    return office?.companyId;
  }, [officeId, getOfficeById]);

  const tableRef = useRef<TimeRecordsPageTableRef>(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [clickedTimeRecord, setClickedTimeRecord] = useState<
    TimeRecord | undefined
  >(null);
  const [selectedTimeRecords, setSelectedTimeRecords] = useState<TimeRecord[]>(
    []
  );
  const [deleteSelectionModalVisible, setDeleteSelectionModalVisible] =
    useState<boolean>(false);

  const allTimeRecordsSearchResult = tableRef.current?.timeRecords ?? [];

  const customDefaultSearchParameters = useMemo(() => {
    switch (contextType) {
      case 'me':
        return [
          {
            parameterName: 'Data.ContactId',
            defaultValue: userMe?.id,
            defaultMethod: 'eq',
          },
        ];
      case 'project':
        return [
          {
            parameterName: 'Data.ProjectId',
            defaultValue: projectId,
            defaultMethod: 'eq',
          },
        ];
      case 'office':
        if (!subsidaryId) {
          return [];
        }
        return [
          {
            parameterName: 'Calculated.SubsidiaryId',
            defaultValue: subsidaryId,
            defaultMethod: 'eq',
          },
        ];
      default:
        return [];
    }
  }, [contextType, subsidaryId, projectId, userMe?.id]);

  //#region ------------------------------ Methods / Handlers
  const updateTimeRecordsTable = () => {
    tableRef.current?.fetchTimeRecords();
  };
  const onCloseEditRecordDrawer = () => {
    setIsEditDrawerOpen(false);
  };

  const handleRowClick = (timeRecord: TimeRecord) => {
    setClickedTimeRecord(timeRecord);
    setIsEditDrawerOpen(true);
  };

  const onSelectionChange = (items: TimeRecord[]) => {
    setSelectedTimeRecords(items);
    setSelectedTimeRecordsObjects?.(items);
  };

  const handleFinish = (value: TimeRecord) => {
    updateTimeRecordsTable();
    setIsEditDrawerOpen(false);
    setClickedTimeRecord(null);
  };

  const handleModalOk = () => {
    handleDelete(selectedTimeRecords);
    setDeleteSelectionModalVisible(false);
  };

  const deleteSingleTimeRecord = (editingElement: TimeRecord) => {
    setSelectedTimeRecords([editingElement]);
    setDeleteSelectionModalVisible(true);
    setIsEditDrawerOpen(false);
  };

  const onDelete = () => {
    setDeleteSelectionModalVisible(true);
  };

  const handleDelete = async (value: TimeRecord[]) => {
    await Promise.all(
      value.map((p) => apiDeleteTimeRecords(p.timeRecordId, p, contextType))
    )
      .then((values: ApiResult<undefined>[]) => {
        values.forEach(({ data }) => {
          if (data && data['contactId'] === userMe.id) {
            dispatch(
              deleteMyTimeRecord(
                data['timeRecordId'],
                data,
                data['contactId'] === userMe.id
              )
            );
          }
        });
      })
      .catch((error) => {
        notification.open({
          message: t('common:error'),
          description: t('timeRecords:errorMessages.deleteError'),
        });
        console.error(error.message);
      });
    setSelectedTimeRecords([]);
    setIsEditDrawerOpen(false);
    setClickedTimeRecord(null);
    updateTimeRecordsTable();
  };
  //#endregion

  //#region ------------------------------ Components
  function renderFilterBar(contextType: string): React.ReactNode {
    switch (contextType) {
      case 'me':
        return (
          <FilterBar
            className={classes.filterbar}
            hiddenPickers={['Data.ContactId', 'Transformed.IncludeSubProjects']}
          />
        );
      case 'project':
        return (
          <FilterBar
            className={classes.filterbar}
            hiddenPickers={['Data.ProjectId']}
            onChange={setSearchString}
          />
        );
      case 'office':
        return (
          <FilterBar
            className={classes.filterbar}
            hiddenPickers={['Transformed.IncludeSubProjects']}
            onChange={setSearchString}
          />
        );
      default: // 'global'
        return (
          <FilterBar
            hiddenPickers={['Transformed.IncludeSubProjects']}
            onChange={setSearchString}
          />
        );
    }
  }

  const renderTotal = () => {
    const isSelection = selectedTimeRecords.length > 0;
    const totalLabel = isSelection
      ? t('timeRecords:totalLabel.selected')
      : t('timeRecords:totalLabel.all');
    const selectedIds = selectedTimeRecords.map((tr) => tr.timeRecordId);
    const selectedTimeRecordsSearchResult = allTimeRecordsSearchResult.filter(
      (tr) => selectedIds.includes(tr.data.timeRecordId)
    );
    const totalValue = isSelection
      ? selectedTimeRecordsSearchResult.reduce(
          (acc, curr) => acc + curr.calculated.hours,
          0
        )
      : allTimeRecordsSearchResult.reduce(
          (acc, curr) => acc + curr.calculated.hours,
          0
        );
    return (
      <div className={classes.totalDisplay}>
        {`${totalLabel}: ${totalValue.toLocaleString('de-DE')} h`}
      </div>
    );
  };
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <FilterContextProvider
        searchType="timeRecords"
        customDefaultSearchParameters={customDefaultSearchParameters}
        transformedMap={{
          'Transformed.IncludeSubProjects': [],
        }}
      >
        {renderFilterBar(contextType)}
        {renderTotal()}
        <TimeRecordsPageTable
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          ref={tableRef}
          tableId="trp1"
          selectedTimeRecords={selectedTimeRecords}
          onRowClick={handleRowClick}
          onSelectionChange={onSelectionChange}
          onDelete={onDelete}
          setTimeRecords={setTimeRecords}
          setReportDrawerVisible={setReportDrawerVisible}
        />
        <Drawer
          title={t('timeRecords:navigationBar.editTimeRecord')}
          position="right"
          closable={true}
          onClose={onCloseEditRecordDrawer}
          visible={isEditDrawerOpen}
          customWidth={theme.old.components.drawerWidth}
        >
          <EditTimeRecord
            timeRecordId={clickedTimeRecord?.timeRecordId}
            timeRecord={clickedTimeRecord}
            titleComponent={(timeRecord: TimeRecord) => (
              <Typography.Title level={3} className={classes.panelHeadline}>
                <FontAwesomeIcon icon={['fal', 'user-clock']} />{' '}
                {timeRecord.title}
              </Typography.Title>
            )}
            onFinish={handleFinish}
            onCancel={onCloseEditRecordDrawer}
            onDelete={deleteSingleTimeRecord}
            contextType={contextType}
            officeRoles={officeRoles}
            onlyOffice={contextType !== 'global'}
            formClassName={classes.form}
            setOpen={setIsEditDrawerOpen}
          />
        </Drawer>
        <Modal
          visible={deleteSelectionModalVisible}
          onOk={() => handleModalOk()}
          onClose={() => setDeleteSelectionModalVisible(false)}
          title={t('timeRecords:modalDelete.title')}
          okText={t('timeRecords:modalDelete.confirm')}
          cancelText={t('timeRecords:modalDelete.cancel')}
        >
          {t(
            `accounting:modalDelete.content.${
              selectedTimeRecords?.length === 1 ? `single` : `multiple`
            }`,
            {
              amount: selectedTimeRecords?.length,
            }
          )}
        </Modal>
      </FilterContextProvider>
    </Flex.Column>
  );
};

export default TimeRecordsPage;
