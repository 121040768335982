import { TextBlock } from '../../../models/TextBlock';
import { PRIO } from '../../../constants';

export const TEXT_BLOCK_MANAGEMENT_SET_SELECTED_ENTRY =
  PRIO + 'TEXT_BLOCK_MANAGEMENT_SET_SELECTED_ENTRY';

export const textBlockManagementSetSelectedEntry = (
  selectedEntry: TextBlock | null
) => ({
  type: TEXT_BLOCK_MANAGEMENT_SET_SELECTED_ENTRY,
  selectedEntry,
});
export const TEXT_BLOCK_MANAGEMENT_SET_OPEN =
  PRIO + 'TEXT_BLOCK_MANAGEMENT_SET_OPEN';

export const textBlockManagementSetOpen = (
  value: boolean
) => ({
  type: TEXT_BLOCK_MANAGEMENT_SET_OPEN,
  value,
});
