import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { MailFolderId, ProjectId } from '../../../../models/Types';
import { FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_COMMIT } from '../../actions/projects/specialMailFolder';
import { SpecialMailFolders } from '../../actions/types';

export interface SpecialMailFolderByProjectId {
  [key: string]: SpecialMailFolders;
}

export const specialMailFolder: Reducer<SpecialMailFolderByProjectId, any> = (
  state = {},
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: payload,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export default specialMailFolder;

export const getSpecialMailFoldersState: (
  state: SpecialMailFolderByProjectId
) => SpecialMailFolderByProjectId = (state) => state ?? {};

export const getSpecialMailFolders: (
  state: SpecialMailFolderByProjectId,
  projectId: ProjectId
) => SpecialMailFolders = (state, projectId) => state[projectId];
export const isDeletedFolder: (
  state: SpecialMailFolderByProjectId,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  !!mailFolderId &&
  (state[projectId] ?? {})['deletedFolder']?.id === mailFolderId;

export const isSendFolder: (
  state: SpecialMailFolderByProjectId,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  !!mailFolderId && (state[projectId] ?? {})['sendFolder']?.id === mailFolderId;

export const isDraftFolder: (
  state: SpecialMailFolderByProjectId,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => boolean = (state, projectId, mailFolderId) =>
  !!mailFolderId &&
  (state[projectId] ?? {})['draftFolder']?.id === mailFolderId;
