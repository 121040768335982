import React, { useEffect, useState } from 'react';
import { Divider, Input, notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Department } from '../../../models/Department';
import { apiFetchDepartments, apiCreateDepartment } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface DepartmentPickerProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  multiple?: boolean;
}

export const DepartmentPicker: React.FC<DepartmentPickerProps> = (props) => {
  const classes = useStyles();
  const [newDepartmentName, setNewDepartmentName] = useState<string>('');
  const [departments, setDepartments] = useState<Department[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const { t } = useTranslation();

  const { value, onChange, disabled, multiple } = props;

  const onNewDepartmentNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewDepartmentName(event.target.value);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadTags = async () => {
      try {
        const { data } = await apiFetchDepartments(signal);
        if (data && Array.isArray(data)) {
          setDepartments(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        setFetching(false);
      } catch {}
    };
    loadTags();
    return () => {
      controller.abort();
    };
  }, [setDepartments]);

  const createDepartment = async () => {
    const { result, data } = await apiCreateDepartment(newDepartmentName);
    if (result.status >= 200 && result.status < 300) {
      setDepartments(
        [...departments, data].sort((a, b) => a.name.localeCompare(b.name))
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'hr:departmentPicker.errorMessages.createDepartmentError'
        ),
      });
    }
  };

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      disabled={disabled}
      className={classes.root}
      mode={multiple ? 'multiple' : null}
      value={value}
      placeholder={t('hr:departmentPicker.placeholder')}
      notFoundContent={fetching ? <PrioSpinner size="small" /> : null}
      onChange={handleOnChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              value={newDepartmentName}
              onChange={onNewDepartmentNameChange}
            />
            <Button
              disabled={newDepartmentName.trim().length === 0}
              onClick={createDepartment}
              iconProp={['fal', 'plus']}
            >
              {t('hr:departmentPicker.addAction')}
            </Button>
          </div>
        </div>
      )}
    >
      {departments.length === 0 ? (
        <Select.Option value="" disabled>
          {t('hr:departmentPicker.emptyDepartmentMessage')}
        </Select.Option>
      ) : null}
      {departments.map(({ name, departmentId }) => (
        <Select.Option key={departmentId} value={departmentId}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DepartmentPicker;
