import { PRIO } from '../../../constants';
import { CurrentFolderMetaState } from '../reducers/meta';

export interface UpdateCurrentFolderMetaStateAction {
  type: string;
  createNewFolderModalOpen?: boolean;
  createNewDocumentOpen?: boolean;
  enableMoveDocumentsButton?: boolean;
  copyDocumentsDrawerState?: 'none' | 'copy' | 'move';
}

export const UPDATE_CURRENT_FOLDER_META_STATE =
  PRIO + 'UPDATE_CURRENT_FOLDER_META_STATE';

export const setCurrentFolderMetaNewDocumentOpen = (
  createNewDocumentOpen: boolean
) => ({
  type: UPDATE_CURRENT_FOLDER_META_STATE,
  createNewDocumentOpen,
  createNewFolderModalOpen: false,
});

export const setDocumentsMetaState: (
  meta: CurrentFolderMetaState
) => UpdateCurrentFolderMetaStateAction = (meta: CurrentFolderMetaState) => ({
  type: UPDATE_CURRENT_FOLDER_META_STATE,
  ...meta,
});
