import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import MailSelectionList from '../MailSelectionList';
import {
  getActiveMailFolderId,
  getActiveMessageId,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { MailFolderId } from '../../../../models/Types';
import useDebounce from '../../../../hooks/useDebounce';
import { setMailListNavigationState } from '../../actions/actionControllers/mailNavigationActionController';
import { MailLayoutProps } from '../../util';
import MessagePage from '../MessagePage';
import MessageDetailsPage from '../MessageDetailsPage';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  mailMessageMenu: {
    overflow: 'hidden',
    flex: 1,
  },
  detailsPane: {
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  detailsPaneColumn: {
    overflow: 'hidden',
    borderTop: theme.old.borders.sub,
    flex: 1,
  },
  topContent: {
    flex: 1,
    overflow: 'hidden',
  },
  showAlwaysContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
}));

export const MailHiddenLayout: React.FC<MailLayoutProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId, selectedMessages, onSelectionChange, mailSettings } =
    props;
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const mailFolderId = useSelector<RootReducerState, MailFolderId>((state) =>
    getActiveMailFolderId(state, projectId)
  );
  const debouncedMailFolderId = useDebounce(mailFolderId, 100);

  const activeMessageId = useSelector<RootReducerState, string>((state) =>
    getActiveMessageId(state, projectId)
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (mailFolderId !== debouncedMailFolderId) {
      dispatch(setMailListNavigationState(null, projectId));
    }
  }, [mailFolderId, debouncedMailFolderId, projectId, dispatch]);
  //#endregion

  return (
    <Flex.Row flex={1} className={classes.content}>
      <MessagePage
        projectId={projectId}
        showAlwaysContent={
          <div className={classes.showAlwaysContent}>
            {activeMessageId && (
              <div className={classes.topContent}>
                <MessageDetailsPage
                  projectId={projectId}
                  backButton
                  mailFolderId={mailFolderId}
                />
              </div>
            )}
            <div
              style={{ display: !activeMessageId ? 'flex' : 'none' }}
              className={classes.detailsPaneColumn}
            >
              <Routes>
                <Route
                  path={`${
                    projectId === 'favorites' ? 'inbox' : ':mailFolderId'
                  }/*`}
                  element={
                    <MailSelectionList
                      projectId={projectId}
                      selectedMessages={selectedMessages}
                      onSelectionChange={onSelectionChange}
                      itemLayout="horizontal"
                      mailListSpacing={mailSettings.mailListSpacing}
                      mailListNavigationWidth={
                        mailSettings.mailNavigationSize.width
                      }
                      automaticRead={mailSettings.automaticRead}
                      listLayout="hidden"
                      mailSearchExpanded
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        }
        backButton
      />
    </Flex.Row>
  );
};

export default MailHiddenLayout;
