import React, { useEffect } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { MailSettings } from '../../../models/UserSettings/MailSettings';
import { Form, Row, Col, Radio, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { rowGutter } from '../../../util/forms';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCachingEnabled,
  updateMailSettings,
} from '../../userSettings/actions/mailSettings/mail';
import { getEmailCachingEnabled } from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  radioButton: {
    width: '100%',
    padding: `${theme.old.spacing.unit(0.5)}px 0`,
  },
  addressBarFirst: {
    marginBottom: theme.old.spacing.baseSpacing,
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '8px',
    width: '100%',
  },
  expandLastItem: {
    flex: 1,
  },
}));

interface MailSettingsProps {
  initialValues: MailSettings;
}

export const MailSettingsForm: React.FC<MailSettingsProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const { initialValues } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const cachingEnabled = useSelector(getEmailCachingEnabled);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleCachingEnabledChange = (checked: boolean) => {
    dispatch(updateCachingEnabled(checked));
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);
  //#endregion

  return (
    <Form<MailSettings>
      form={form}
      initialValues={initialValues}
      layout="vertical"
      className={classes.root}
      onValuesChange={(_, values) => {
        dispatch(updateMailSettings(values));
      }}
    >
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="messageViewLayout"
            label={t('mail:settingsForm.labels.messageViewLayout')}
          >
            <Radio.Group>
              <Radio value="right" className={classes.radioButton}>
                {t('mail:settingsForm.messageViewLayoutGroup.right')}
              </Radio>
              <Radio value="bottom" className={classes.radioButton}>
                {t('mail:settingsForm.messageViewLayoutGroup.bottom')}
              </Radio>
              <Radio value="hidden" className={classes.radioButton}>
                {t('mail:settingsForm.messageViewLayoutGroup.hidden')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="mailListSpacing"
            label={t('mail:settingsForm.labels.mailListSpacing')}
          >
            <Radio.Group>
              <Radio value="full" className={classes.radioButton}>
                {t('mail:settingsForm.mailListSpacingGroup.full')}
              </Radio>
              <Radio value="middle" className={classes.radioButton}>
                {t('mail:settingsForm.mailListSpacingGroup.middle')}
              </Radio>
              <Radio value="tight" className={classes.radioButton}>
                {t('mail:settingsForm.mailListSpacingGroup.tight')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name={'sendOnBehalfOfMe'}
            label={t('mail:settingsForm.sendOnBehalfOfMe')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name={['showCcBccSelection', 'from']}
            label={t('mail:settingsForm.addressBarGroup.from')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name={['showCcBccSelection', 'cc']}
            valuePropName="checked"
            label={t('mail:settingsForm.addressBarGroup.cc')}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name={['showCcBccSelection', 'bcc']}
            label={t('mail:settingsForm.addressBarGroup.bcc')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="automaticRead"
            label={t('mail:settingsForm.labels.automaticRead')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="deleteMovedMessageMe"
            label={t('mail:settingsForm.labels.deleteOrigin')}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            label={t(
              `mail:settingsForm.labels.${
                cachingEnabled ? 'disableCaching' : 'enableCaching'
              }`
            )}
          >
            <Flex.Column childrenGap={theme.old.spacing.baseSpacing}>
              <Flex.Item>
                <Switch
                  checked={cachingEnabled}
                  onChange={handleCachingEnabledChange}
                />
              </Flex.Item>
              <Flex.Row
                childrenGap={theme.old.spacing.unit(0.5)}
                alignItems={'baseline'}
              >
                <InfoCircleOutlined />
                <Flex.Item>
                  {t('mail:settingsForm.cachingEnabledInfo')}
                </Flex.Item>
              </Flex.Row>
            </Flex.Column>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter} className={classes.expandLastItem}>
        <Col span={24}>
          <Form.Item
            name="pushNotificationsEnabled"
            valuePropName="checked"
            label={t(
              `mail:settingsForm.labels.${
                initialValues.pushNotificationsEnabled
                  ? 'disablePushNotifications'
                  : 'enablePushNotifications'
              }`
            )}
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MailSettingsForm;
