import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { SiderOptions } from '../../../../models/UserSettings/ThemeSettings';
import { SubMenuStateType } from '../../../../models/Types';
import { UPDATE_THEME_SIDER_SETTING_REQUEST } from '../../actions/themeSettings/sider';
import { FETCH_USER_SETTINGS_COMMIT } from '../../actions/index';

export interface SiderOptionsState extends SiderOptions {}

const initialState: SiderOptionsState = {
  open: true,
  subMenuState: 'closed',
};

export const sider: Reducer<SiderOptionsState, any> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_USER_SETTINGS_COMMIT: {
      const { payload } = action;
      return {
        subMenuState: payload.themeSettings.siderOptions.subMenuState,
        open: payload.themeSettings.siderOptions.open,
      };
    }

    case UPDATE_THEME_SIDER_SETTING_REQUEST: {
      const {
        meta: { siderOpenSetting },
      } = action;
      return {
        ...state,
        ...siderOpenSetting,
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default sider;

export const getSiderOpen: (state: SiderOptionsState) => boolean = (state) => {
  return state.open;
};

export const getSubMenuState: (state: SiderOptionsState) => SubMenuStateType = (
  state
) => state.subMenuState;
