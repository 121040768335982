import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { Form, Layout } from 'antd';
import { Button, Input, useTheme } from '@prio365/prio365-react-library';
import { colon } from '../../../util/forms';
import { PrioTheme } from '../../../theme/types';
import { Content, Footer } from 'antd/lib/layout/layout';
import { getSvgIconPathByType } from '../../../util/icon';
import SvgIcon from '../../../components/SvgIcon';
import {
  JiraUserConfigurationData,
  UpdateProjectExtensionUserConfiguration,
  defaultJiraUserConfigurationData,
} from '../../../models/ProjectExtension';
import {
  apiDeleteProjectExtensionUserConfigurationById,
  apiGetProjectExtensionUserConfigurationById,
  apiUpdateProjectExtensionUserConfiguration,
} from '../../projects/api';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  rootLayout: {
    margin: 0,
  },
  contentFooter: {
    marginTop: `${theme.spacing.regular}px`,
    padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    backgroundColor: theme.colors.application.background.default,
    textAlign: 'right',
  },
  readOnlyInput: {
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
    '&:focus-visible': {
      border: 'none',
      boxShadow: 'none',
    },
  },
  editContent: {
    backgroundColor: theme.colors.application.background.default,
  },
  form: {
    padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    height: '100%',
    overflow: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  header: {
    marginLeft: '30px',
    marginBlock: theme.spacing.regular,
  },
}));

interface AddInItemConfigProps {
  className?: string;
  projectExtensionUserConfigurationChanged?: () => void;
}

export const JiraUserConfigurationForm: React.FC<AddInItemConfigProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { projectExtensionUserConfigurationChanged } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const { id } = useParams();
  const [dataForm] = Form.useForm<JiraUserConfigurationData>();
  const navigate = useNavigate();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  // issaving
  const [isSaving, setIsSaving] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers

  // handle save
  const handleFinishData = async (newData: JiraUserConfigurationData) => {
    const payload: UpdateProjectExtensionUserConfiguration = {
      isEnabled: true,
      objectJson: JSON.stringify(newData.objectJson),
    };
    setIsSaving(true);
    await apiUpdateProjectExtensionUserConfiguration(id, payload);
    projectExtensionUserConfigurationChanged();
    setIsSaving(false);
  };

  const handleDelete = async () => {
    await apiDeleteProjectExtensionUserConfigurationById(id);
    navigate(-1);
    projectExtensionUserConfigurationChanged();
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const getProjectExtensionConfigurationById = async (id: string) => {
      const { data } = await apiGetProjectExtensionUserConfigurationById(id);
      let newData: JiraUserConfigurationData = {
        isEnabled: data.isEnabled,
        objectJson: JSON.parse(data.objectJson),
      };
      dataForm.setFieldsValue(newData);
    };
    if (id && id !== '0') {
      getProjectExtensionConfigurationById(id);
    } else {
      dataForm.setFieldsValue(defaultJiraUserConfigurationData);
    }
  }, [id, dataForm]);
  //#endregion

  return (
    <Layout className={classes.rootLayout}>
      <Content className={classes.editContent}>
        <>
          <Flex.Row className={classes.header} flex={1}>
            <SvgIcon
              width={40}
              height={40}
              path={getSvgIconPathByType('jira')}
            ></SvgIcon>
            <h3 style={{ marginTop: '5px' }}>Jira</h3>
          </Flex.Row>
          <Form
            layout="vertical"
            form={dataForm}
            className={classes.form}
            onFinish={handleFinishData}
          >
            <Flex.Row flex={1}>
              <Flex.Column flex={2} childrenGap={theme.spacing.small}>
                <Flex.Row flex={1} alignItems="center">
                  <h4>{t('projects:addIn.jiraConfig.connection')}</h4>
                </Flex.Row>
                <Form.Item
                  name={['objectJson', 'displayName']}
                  label={t('projects:addIn.jiraConfig.displayName')}
                  colon={colon}
                  style={{ flex: 1 }}
                >
                  <Input disabled={false} full />
                </Form.Item>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  flex={1}
                  justifyContent="stretch"
                >
                  <Form.Item
                    name={['objectJson', 'userCredentials', 'mail']}
                    label={t('projects:addIn.jiraConfig.mail')}
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                  <Form.Item
                    name={['objectJson', 'userCredentials', 'apiKey']}
                    label={t('projects:addIn.jiraConfig.apiToken')}
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} type="password" full />
                  </Form.Item>
                </Flex.Row>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  flex={1}
                  justifyContent="flex-end"
                ></Flex.Row>
              </Flex.Column>
            </Flex.Row>
          </Form>
        </>
      </Content>
      <Footer className={classes.contentFooter}>
        <Button type="default" style={{ color: 'red' }} onClick={handleDelete}>
          {t('projects:addIn.jiraConfig.delete')}
        </Button>
        <Button
          disabled={isSaving}
          onClick={() => {
            dataForm.submit();
          }}
          htmlType="button"
        >
          {t('projects:addIn.jiraConfig.save')}
        </Button>
      </Footer>
    </Layout>
  );
};

export default JiraUserConfigurationForm;
