import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio, Select, Switch, Typography } from 'antd';
import { DocumentSettings } from '../../../../models/UserSettings/DocumentSettings';
import {
  updateDocumentSettings,
  updateDocumentsCachingEnabled,
} from '../../../userSettings/actions/documentSettings/documents';
import { useDispatch, useSelector } from 'react-redux';
import { makePrioStyles } from '../../../../theme/utils';
import { getDocumentsCachingEnabled } from '../../../../apps/main/rootReducer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingTop: '20px',
  },
  settingsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  radioButton: {
    width: '100%',
    padding: `${theme.old.spacing.unit(0.5)}px 0`,
  },
}));

interface DocumentSettingsFormProps {
  initialValues: DocumentSettings;
}

export const DocumentSettingsForm: React.FC<DocumentSettingsFormProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { initialValues } = props;
  const [form] = Form.useForm();
  //#endregion
  //#region -------------------------------- State declaration
  const cachingEnabled = useSelector(getDocumentsCachingEnabled);
  //#endregion
  //#region -------------------------------- Methods
  const handleCachingEnabledChange = (checked: boolean) => {
    dispatch(updateDocumentsCachingEnabled(checked));
  };
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks
  //#endregion

  return (
    <Form<DocumentSettings>
      form={form}
      layout="vertical"
      className={classes.root}
      initialValues={initialValues}
      onValuesChange={(_, values) => {
        dispatch(updateDocumentSettings(values));
      }}
    >
      <div className={classes.settingsSection}>
        <div>
          <Typography.Title level={4}>
            {t('documents:settings.pdf.title')}
          </Typography.Title>
          <Form.Item
            name={['doubleClickOpenBehaviour']}
            label={t('documents:settings.pdf.doubleClickOpenBehaviour.title')}
          >
            <Radio.Group
              defaultValue={initialValues.doubleClickOpenBehaviour}
              buttonStyle="solid"
            >
              <Radio.Button value="browser">
                {t('documents:settings.pdf.doubleClickOpenBehaviour.browser')}
              </Radio.Button>
              <Radio.Button value="local">
                {t('documents:settings.pdf.doubleClickOpenBehaviour.local')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name={['defaultPdfProgram']}
            label={t('documents:settings.pdf.defaultPdfProgram.title')}
          >
            <Select defaultValue={initialValues.defaultPdfProgram}>
              <Select.Option key={'pdfXchange'} value={'pdfXchange'}>
                {t('documents:settings.pdf.defaultPdfProgram.pdfXchange')}
              </Select.Option>
              <Select.Option key={'adobeAcrobat'} value={'adobeAcrobat'}>
                {t('documents:settings.pdf.defaultPdfProgram.adobeAcrobat')}
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div>
          <Typography.Title level={4}>
            {t('documents:settings.spacing.title')}
          </Typography.Title>

          <Form.Item
            name={['documentListSpacing']}
            label={t('documents:settings.spacing.documentListSpacing.title')}
          >
            <Radio.Group>
              <Radio value="full" className={classes.radioButton}>
                {t('documents:settings.spacing.documentListSpacing.full')}
              </Radio>
              <Radio value="middle" className={classes.radioButton}>
                {t('documents:settings.spacing.documentListSpacing.middle')}
              </Radio>
              <Radio value="tight" className={classes.radioButton}>
                {t('documents:settings.spacing.documentListSpacing.tight')}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div>
          <Typography.Title level={4}>
            {t('documents:settings.advancedSettings.title')}
          </Typography.Title>

          <Form.Item
            name={['enableDocumentCaching']}
            label={t(
              'documents:settings.advancedSettings.enableDocumentCaching'
            )}
          >
            <Switch
              checked={cachingEnabled}
              onChange={handleCachingEnabledChange}
            />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default DocumentSettingsForm;
