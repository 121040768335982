import React from 'react';

import { makePrioStyles } from '../../../theme/utils';
import { CreateGlobalProjectSetting } from '../../../models/Configuration';
import Flex from '../../../components/Flex';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { GlobalProjectSettingsTable } from './GlobalProjectSettingsTable';
import { useTranslation } from 'react-i18next';

import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useGlobalProjectSettings } from '../hooks/useGlobalProjectSettings';
import { GlobalProjectSettingId } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  actionRow: {
    padding: theme.old.spacing.defaultPadding,
    paddingTop: 0,
  },
  title: {
    padding: theme.old.spacing.defaultPadding,
    marginBottom: '0!important',
  },
}));

interface GlobalProjectSettingsSubjectPageProps {
  className?: string;
}

export const GlobalProjectSettingsSubjectPage: React.FC<
  GlobalProjectSettingsSubjectPageProps
> = (props) => {
  const classes = useStyles();
  const { className } = props;
  const { t } = useTranslation();

  const {
    settings,
    settingsSaving,
    areSettingsDirty,
    setDefaultSetting,
    createSetting,
    setIsDirtyChangedSettings,
    saveSettings,
    deleteSetting,
  } = useGlobalProjectSettings('emailSubject');

  const onIsDirtyChangedSettings = setIsDirtyChangedSettings;

  const onNewSetting = async (newSetting: CreateGlobalProjectSetting) => {
    await createSetting(newSetting);
  };

  const onSetDefaultSetting = async (settingId: GlobalProjectSettingId) => {
    await setDefaultSetting(settingId);
  };

  const onSave = async () => {
    await saveSettings();
  };

  const onDeleteSetting = async (settingId: GlobalProjectSettingId) => {
    await deleteSetting(settingId);
  };

  if (settings === null) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column>
        <Typography.Title className={classes.title}>
          {t('settings:projectSettingsTable.title.subject')}
        </Typography.Title>
        <GlobalProjectSettingsTable
          settings={settings}
          onIsDirtyChanged={onIsDirtyChangedSettings}
          onNewSetting={onNewSetting}
          onDeleteSetting={onDeleteSetting}
          onSetDefaultSetting={onSetDefaultSetting}
          type="subject"
        />

        <Flex.Row className={classes.actionRow} justifyContent="flex-end">
          <Button
            disabled={!areSettingsDirty || settingsSaving}
            onClick={onSave}
          >
            {t('common:actions.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default GlobalProjectSettingsSubjectPage;
