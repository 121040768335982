import React, { useEffect, useState } from 'react';
import { Table, Dropdown, Menu, Switch, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { tableTranslations } from '../../../util/table';
import { Training } from '../../../models/Training';
import { fullDateFormatFormatString } from '../../../util';
import { Company } from '../../../models/Company';
import { parseISO, startOfDay } from 'date-fns';
import { TableRowSelection } from 'antd/lib/table/interface';
import Flex from '../../../components/Flex';
import { QualificationsById } from './HRTrainings';
import { QualificationId, TrainingId } from '../../../models/Types';
import {
  apiActivateTraining,
  apiDeactivateTraining,
  apiArchiveTraining,
} from '../api';
import { MENU_BUTTON_SIZE } from '../../../constants';
import PrioSpinner from '../../../components/PrioSpinner';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(2) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(2) > button': {
      visibility: 'visible',
    },
  },
  menuColum: {
    padding: '0!important',
    width: MENU_BUTTON_SIZE,
  },
  menuButton: {
    backgroundColor: 'transparent',
    height: MENU_BUTTON_SIZE,
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
  secondaryColumn: theme.old.components.table.secondaryColumn,
}));

interface TrainingsTableProps {
  onRowClick?: (entry: Training) => void;
  trainings: Training[];
  companiesById: { [key: string]: Company };
  qualificationsById: QualificationsById;
  triggerReload: VoidFunction;
  loading?: boolean;
}

export const TrainingsTable: React.FC<TrainingsTableProps> = (props) => {
  const classes = useStyles();

  const {
    onRowClick,
    trainings,
    companiesById,
    qualificationsById,
    triggerReload,
    loading,
  } = props;
  const { t } = useTranslation();

  const [menuSubmitting, setMenuSubmitting] = useState<boolean>(false);
  const [optimisticActivationChange, setOptimisticActivationChange] = useState<{
    trainingId: TrainingId;
    isActive: boolean;
  }>();

  useEffect(() => {
    setOptimisticActivationChange(null);
  }, [trainings]);

  const rowSelection: TableRowSelection<Training> = {
    onChange: (selectedRowKeys, selectedRows) => {},
  };

  const menu = (entry: Training) => (
    <Menu>
      <Menu.Item
        disabled={menuSubmitting}
        onClick={async (e) => {
          e.domEvent.stopPropagation();
          setMenuSubmitting(true);
          await archiveTraining(entry.trainingId);
          setMenuSubmitting(false);
        }}
      >
        {t('hr:trainings.tableMenu.delete')}
      </Menu.Item>
    </Menu>
  );

  const handleActiveChange = async (
    trainingId: TrainingId,
    checked: boolean
  ) => {
    setOptimisticActivationChange({ trainingId, isActive: checked });
    try {
      const { result } = checked
        ? await apiActivateTraining(trainingId)
        : await apiDeactivateTraining(trainingId);
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t(
            checked
              ? 'hr:trainings.successMessages.trainingActivationSuccess'
              : 'hr:trainings.successMessages.trainingDeactivationSuccess'
          ),
        });
        triggerReload();
      } else {
        setOptimisticActivationChange(null);
        notification.open({
          message: t('common:error'),
          description: t(
            checked
              ? 'hr:trainings.errorMessages.trainingActivationError'
              : 'hr:trainings.errorMessages.trainingDeactivationError'
          ),
        });
      }
    } catch {
      setOptimisticActivationChange(null);
      notification.open({
        message: t('common:error'),
        description: t(
          checked
            ? 'hr:trainings.errorMessages.trainingActivationError'
            : 'hr:trainings.errorMessages.trainingDeactivationError'
        ),
      });
    }
  };
  const archiveTraining = async (trainingId: TrainingId) => {
    try {
      const { result } = await apiArchiveTraining(trainingId);
      if (result.status >= 200 && result.status < 300) {
        triggerReload();
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:trainings.errorMessages.archiveTrainingError'),
        });
      }
    } catch {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.archiveTrainingError'),
      });
    }
  };

  const columns: ColumnProps<Training>[] = [
    {
      sorter: (a, b) => a.title.localeCompare(b.title),
      title: t('hr:trainings.table.columnTitle.title'),
      dataIndex: 'title',
    },
    {
      render: (_, record) => (
        <Dropdown
          overlay={menu(record)}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button
            iconProp={['fal', 'ellipsis-v']}
            className={classes.menuButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Dropdown>
      ),
      className: classes.menuColum,
    },
    {
      sorter: (a, b) =>
        companiesById[a.trainingOrganizerId]?.fullName?.localeCompare(
          companiesById[b.trainingOrganizerId]?.fullName
        ),
      title: t('hr:trainings.table.columnTitle.trainingOrganizer'),
      dataIndex: 'trainingOrganizerId',
      className: classes.secondaryColumn,
      render: (value, record) => value && companiesById[value]?.fullName,
    },

    {
      dataIndex: 'trainingTargetQualificationIds',
      title: t('hr:trainings.table.columnTitle.trainingTargetQualificationIds'),
      className: classes.secondaryColumn,
      render: (value) =>
        (value as QualificationId[])
          .map((id) => qualificationsById[id]?.name)
          .filter((name) => !!name)
          .join(', '),
    },
    {
      sorter: (a, b) =>
        parseISO(a.trainingDays[0].startTime).getDate() -
        parseISO(b.trainingDays[0].startTime).getDate(),
      title: t('hr:trainings.table.columnTitle.period'),
      className: classes.secondaryColumn,
      render: (_, record) => (
        <Flex.Column>
          {record.trainingDays.map((trainingDay) => (
            <Flex.Item key={trainingDay.startTime}>
              {startOfDay(parseISO(trainingDay.startTime)).getDate() ===
              startOfDay(parseISO(trainingDay.endTime)).getDate() ? (
                fullDateFormatFormatString(trainingDay.startTime)
              ) : (
                <>
                  {fullDateFormatFormatString(trainingDay.startTime)}{' '}
                  {t('common:to')}{' '}
                  {fullDateFormatFormatString(trainingDay.endTime)}
                </>
              )}
            </Flex.Item>
          ))}
        </Flex.Column>
      ),
    },
    {
      sorter: (a, b) =>
        a.isActive && !b.isActive ? 1 : !a.isActive && b.isActive ? -1 : 0,
      title: t('hr:trainings.table.columnTitle.isActive'),
      className: classes.secondaryColumn,
      render: (value, record) => (
        <Switch
          checked={
            optimisticActivationChange?.trainingId === record.trainingId
              ? optimisticActivationChange.isActive
              : value.isActive
          }
          onChange={(checked, event) => {
            event.stopPropagation();
            handleActiveChange(record.trainingId, checked);
          }}
        />
      ),
    },
  ];

  return (
    <Table<Training>
      className={classes.root}
      dataSource={trainings}
      columns={columns}
      scroll={{ x: '100%' }}
      rowKey={(record) => record.trainingId}
      locale={tableTranslations(t)}
      rowClassName={classes.row}
      onRow={(record: Training) => {
        return {
          onClick: onRowClick
            ? () => {
                onRowClick(record);
              }
            : null,
        };
      }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      loading={{
        spinning: loading,
        indicator: <PrioSpinner alignSelf />,
      }}
    />
  );
};

export default TrainingsTable;
