import React, { useEffect, useState } from 'react';
import MailTaskItem from './MailTaskItem';
import { TaskDetail } from '../../../models/Task';
import {
  apiFetchTaskDdetails,
  apiFetchTasksForAMessage,
} from '../../tasks/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';

interface MailTaskItemListProps {
  projectId: string;
  messageId: string;
}

const MailTaskItemList: React.FC<MailTaskItemListProps> = ({
  projectId,
  messageId,
}) => {
  const [taskDetails, setTaskDetails] = useState<TaskDetail[]>([]);
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  useEffect(() => {
    let timeoutId;

    if (messageId) {
      timeoutId = setTimeout(() => {
        apiFetchTasksForAMessage(projectId, messageId)
          .then((result) => {
            if (result.data) {
              if (result.data.length > 0) {
                return apiFetchTaskDdetails(projectId, result.data);
              } else {
                setTaskDetails([]);
              }
            } else {
              return null;
            }
            return null;
          })
          .then((result) => {
            if (result?.data?.length > 0) {
              setTaskDetails(result.data);
            } else {
              setTaskDetails([]);
            }
          })
          .catch((error) => {
            console.error('Error fetching tasks or task details:', error);
          });
      }, 500);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [messageId, projectId]);

  if (taskDetails.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'center',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <p style={{ color: theme.colors.application.typography.default }}>
          {t('common:widgetArea.noTasks')}
        </p>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: '10px',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {taskDetails.map((taskDetail) => (
        <MailTaskItem key={taskDetail.taskId} taskDetail={taskDetail} />
      ))}
    </div>
  );
};

export default MailTaskItemList;
