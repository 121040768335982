import { MailComment } from '../../../../models/Message';
import {
  FETCH_CONVERSATION_COMMENTS_REQUEST,
  FETCH_CONVERSATION_COMMENTS_COMMIT,
  CREATE_COMMENT_COMMIT,
  UPDATE_COMMENT_COMMIT,
  DELETE_COMMENT_COMMIT,
  FETCH_CONVERSATION_COMMENTS_ROLLBACK,
  CREATE_COMMENT_ROLLBACK,
  UPDATE_COMMENT_ROLLBACK,
  DELETE_COMMENT_ROLLBACK,
  FETCH_COMMENT_BY_ID,
  UPDATE_COMMENT_BY_ID,
  DELETE_COMMENT_BY_ID,
} from '../../actions/projects/mailCommentActions';

export interface CommentsState {
  comments: MailComment[] | null;
  isFetching: boolean;
  error: string | null;
}

const initialState: CommentsState = {
  comments: null,
  isFetching: false,
  error: null,
};

const commentsReducer = (state = initialState, action): CommentsState => {
  switch (action.type) {
    case FETCH_CONVERSATION_COMMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_CONVERSATION_COMMENTS_COMMIT: {
      const comments = action.payload;
      return {
        ...state,
        isFetching: false,
        comments,
      };
    }

    case FETCH_COMMENT_BY_ID.COMMIT: {
      const comment = action.payload;
      return {
        ...state,
        isFetching: false,
        comments: [...state.comments, comment],
      };
    }

    case UPDATE_COMMENT_BY_ID.COMMIT: {
      const updatedComment = action.payload;
      return {
        ...state,
        isFetching: false,
        comments: state.comments?.map((comment) =>
          comment.emailCommentId === updatedComment.emailCommentId
            ? updatedComment
            : comment
        ),
      };
    }

    case DELETE_COMMENT_BY_ID.COMMIT: {
      const { commentId } = action.payload;
      return {
        ...state,
        isFetching: false,
        comments: state.comments?.filter(
          (comment) => comment.emailCommentId !== commentId
        ),
      };
    }

    case CREATE_COMMENT_COMMIT: {
      const newComment = action.payload.response;
      return {
        ...state,
        isFetching: false,
        comments: [...state.comments, newComment],
      };
    }

    case UPDATE_COMMENT_COMMIT: {
      const updatedComment = action.payload;
      return {
        ...state,
        isFetching: false,
        comments: state.comments?.map((comment) =>
          comment.emailCommentId === updatedComment.emailCommentId
            ? updatedComment
            : comment
        ),
      };
    }

    case DELETE_COMMENT_COMMIT: {
      const { commentId } = action.meta;
      return {
        ...state,
        isFetching: false,
        comments: state.comments?.filter(
          (comment) => comment.emailCommentId !== commentId
        ),
      };
    }

    case FETCH_CONVERSATION_COMMENTS_ROLLBACK:
    case FETCH_COMMENT_BY_ID.ROLLBACK:
    case CREATE_COMMENT_ROLLBACK:
    case UPDATE_COMMENT_ROLLBACK:
    case DELETE_COMMENT_ROLLBACK:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default commentsReducer;

export const getCommentsByConversationId = (state, conversationId) =>
  state.comments?.filter(
    (comment) => comment?.emailConversationId === conversationId
  );

export const getCommentById = (state, commentId) =>
  state.comments?.find((comment) => comment?.emailCommentId === commentId);

export const getIsFetchingComments = (state) => state.isFetching;

export const getCommentsError = (state) => state.error;
