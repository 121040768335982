import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../theme/types';
import { DriveItemVersion } from '../../../models/Document';
import moment from 'moment';
import { Button } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.application.background.light,
    padding: theme.spacing.extraSmall,
    '&:hover': {
      '& $downloadButton': {
        visibility: 'visible',
      },
    },
  },
  firstIcon: {
    paddingLeft: theme.spacing.small,
  },
  label: {
    width: '219px',
    fontSize: theme.font.fontSize.extraSmall,
    color: theme.colors.application.typography.muted,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  downloadButton: {
    padding: theme.spacing.small,
    marginRight: theme.spacing.extraSmall,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    visibility: 'hidden',
  },
}));

interface DocumentVersionItemProps {
  className?: string;
  item: DriveItemVersion;
}

export const DocumentVersionItem: React.FC<DocumentVersionItemProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const { className, item } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<PrioTheme>();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const subLabel = `${moment(item.lastModifiedDateTime).format(
      'DD.MM.YYYY HH:mm'
    )} | ${item.lastModifiedBy.user.displayName}`;
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const onLinkIconClick = () => {
      window.open(item?.downloadUrl, '_blank');
    };
    //#endregion

    //#region ------------------------------ Effects
    //#endregion

    return (
      <Flex.Row
        className={classNames(classes.root, className)}
        alignItems="center"
        childrenGap={theme.spacing.regular}
      >
        <FontAwesomeIcon
          icon={['fal', 'rectangle-history']}
          className={classes.firstIcon}
          color={theme.colors.base.primary.default}
        />
        <Flex.Column flex={1}>
          <div>{item.id}</div>
          <div className={classes.label} title={subLabel}>
            {subLabel}
          </div>
        </Flex.Column>
        <Button
          iconProp={['fal', 'arrow-down-to-line']}
          type="link"
          onClick={onLinkIconClick}
          className={classes.downloadButton}
          tooltip={t('documents:documentMetaData.form.download')}
        />
      </Flex.Row>
    );
  });

export default DocumentVersionItem;
