import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import CreateTimeRecord from './CreateTimeRecord/CreateTimeRecord';
import MyTimeRecordsOverview from './MyTimeRecordsOverview';
import { TimeRecordId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: 'calc(100%)',
    '& .ant-tabs-content-holder': {
      // overflowY: 'auto',
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-tabs-nav': {
      marginBottom: '4px',
    },
  },
}));

export declare type TimeRecordTabs = 'new' | 'overview';

interface TimeRecordsTabsProps {
  activeKey?: TimeRecordTabs;
  onChange?: (activeKey: TimeRecordTabs) => void;
  onTimeRecordClick?: (timeRecordId: TimeRecordId) => void;
}

export const TimeRecordsTabs: React.FC<TimeRecordsTabsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeKey, onChange, onTimeRecordClick } = props;

  return (
    <Tabs
      animated={false}
      defaultActiveKey="new"
      activeKey={activeKey}
      onChange={onChange}
      className={classes.root}
    >
      <TabPane tab={t('timeRecords:taskPane.tabs.new')} key="new">
        <CreateTimeRecord />
      </TabPane>
      <TabPane tab={t('timeRecords:taskPane.tabs.overview')} key="overview">
        <MyTimeRecordsOverview onTimeRecordClick={onTimeRecordClick} />
      </TabPane>
    </Tabs>
  );
};

export default TimeRecordsTabs;
