import { MessageId } from '../../../models/Types';
import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { DELETE_LOCAL_MESSAGES } from '../actions/actionControllers/messageActionController';
import {
  ADD_EML_TO_DRIVE_ITEM_COMMIT,
  ADD_EML_TO_DRIVE_ITEM_REQUEST,
  ADD_EML_TO_DRIVE_ITEM_ROLLBACK,
  IAddEmlToDriveFolderMeta,
} from '../../documents/actions';
import {
  COPY_MAIL_ME_TO_PROJECT_COMMIT,
  COPY_MAIL_ME_TO_PROJECT_REQUEST,
  COPY_MAIL_ME_TO_PROJECT_ROLLBACK,
  DELETE_LOCAL_MESSAGE_ME,
  IMoveMessageMeMeta,
  MOVE_MESSAGE_ME_REQUEST,
  WS_REVERT_MESSAGE_MOVE_ME,
  MOVE_MESSAGE_ME_COMMIT,
  MOVE_MESSAGE_ME_ROLLBACK,
} from '../actions/me/messagesMe';
import {
  COPY_MAIL_TO_PROJECT_PROJECT_COMMIT,
  COPY_MAIL_TO_PROJECT_PROJECT_REQUEST,
  COPY_MAIL_TO_PROJECT_PROJECT_ROLLBACK,
  DELETE_LOCAL_MESSAGE_PROJECT,
  IMoveMessageProjectMeta,
  MOVE_MESSAGE_PROJECT_REQUEST,
  WS_REVERT_MESSAGE_MOVE_PROJECT,
  MOVE_MESSAGE_PROJECT_COMMIT,
  MOVE_MESSAGE_PROJECT_ROLLBACK,
} from '../actions/projects/messages';
import { ReduxAction } from '../../../models/Redux';
import { Message } from '../../../models/Message';

export interface MailMetaState {
  mailsToEml: MessageId[];
  mailsToMove: MessageId[];
}

export const initialState: MailMetaState = {
  mailsToEml: [],
  mailsToMove: [],
};

const mailsToEml: Reducer<
  MessageId[],
  ReduxAction<IAddEmlToDriveFolderMeta, Message[]>
> = (state = initialState.mailsToEml, action) => {
  switch (action.type) {
    case ADD_EML_TO_DRIVE_ITEM_REQUEST: {
      const {
        meta: { messageId },
      } = action;
      return [...state, messageId];
    }

    case ADD_EML_TO_DRIVE_ITEM_ROLLBACK:
    case ADD_EML_TO_DRIVE_ITEM_COMMIT: {
      const {
        meta: { messageId },
      } = action;
      return state.filter((id) => id !== messageId);
    }

    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.mailsToEml;
    }
    default:
      return state;
  }
};

const mailsToMove: Reducer<
  MessageId[],
  ReduxAction<
    (IMoveMessageMeMeta | IMoveMessageProjectMeta) & { messages: Message[] } & {
      messageId: MessageId;
    },
    Message[] & { message: Message } & { messages: Message[] } & {
      messageIds: MessageId[];
    }
  >
> = (state = initialState.mailsToMove, action) => {
  switch (action.type) {
    case MOVE_MESSAGE_PROJECT_REQUEST:
    case MOVE_MESSAGE_ME_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      return [...state, ...messageIds];
    }

    case COPY_MAIL_TO_PROJECT_PROJECT_REQUEST:
    case COPY_MAIL_ME_TO_PROJECT_REQUEST: {
      const {
        meta: { messages },
      } = action;
      return [...state, ...messages.map((message) => message.id)];
    }

    case COPY_MAIL_TO_PROJECT_PROJECT_ROLLBACK:
    case COPY_MAIL_ME_TO_PROJECT_ROLLBACK:
    case COPY_MAIL_TO_PROJECT_PROJECT_COMMIT:
    case COPY_MAIL_ME_TO_PROJECT_COMMIT: {
      const {
        meta: { messages },
      } = action;
      const messageIds = messages.map((message) => message.id);
      return state.filter((id) => !messageIds.includes(id));
    }

    case MOVE_MESSAGE_PROJECT_ROLLBACK:
    case MOVE_MESSAGE_ME_ROLLBACK:
    case MOVE_MESSAGE_PROJECT_COMMIT:
    case MOVE_MESSAGE_ME_COMMIT: {
      const {
        meta: { messageIds },
      } = action;
      return state.filter((id) => !messageIds.includes(id));
    }

    case DELETE_LOCAL_MESSAGE_ME:
    case DELETE_LOCAL_MESSAGE_PROJECT: {
      const {
        meta: { messageId },
      } = action;
      return state.filter((id) => messageId !== id);
    }

    case WS_REVERT_MESSAGE_MOVE_ME:
    case WS_REVERT_MESSAGE_MOVE_PROJECT: {
      const { messageId } = action;
      return state.filter((id) => messageId !== id);
    }

    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.mailsToMove;
    }
    default:
      return state;
  }
};

export default combineReducers<MailMetaState>({
  mailsToEml,
  mailsToMove,
});

export const getMailToEmlIsCreating: (
  state: MailMetaState,
  messageId: MessageId
) => boolean = (state, messageId) => state.mailsToEml.includes(messageId);

export const getMailsToEmlAreCreating: (state: MailMetaState) => MessageId[] = (
  state
) => state.mailsToEml;

export const getMessageIsMoving: (
  state: MailMetaState,
  messageId: MessageId
) => boolean = (state, messageId) => state.mailsToMove.includes(messageId);

export const getMovingMessages: (state: MailMetaState) => MessageId[] = (
  state
) => state.mailsToMove;
