import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  CreateProjectFileSystemStructure,
  ProjectFileSystemStructure,
} from '../../../models/Settings';
import { ProjectFileSystemStructureId } from '../../../models/Types';

/** Contact classification fetching */
export const FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_REQUEST =
  PRIO + 'FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_REQUEST';
export const FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_COMMIT =
  PRIO + 'FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_COMMIT';
export const FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_ROLLBACK =
  PRIO + 'FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_ROLLBACK';

export const fetchProjectFileSystemStructures = () => ({
  type: FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ProjectFileSystemStructure`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchProjectFileSystemStructuresError',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST =
  PRIO + 'CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST';
export const CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT =
  PRIO + 'CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT';
export const CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK =
  PRIO + 'CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK';

export const createProjectFileSystemStructure = (
  projectFileSystemStructure: CreateProjectFileSystemStructure,
  temporaryId: string
) => ({
  type: CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST,
  requiresAuth: true,
  payload: projectFileSystemStructure,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ProjectFileSystemStructure`,
        method: 'POST',
        json: projectFileSystemStructure,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT,
        meta: {
          projectFileSystemStructureId: temporaryId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.createProjectFileSystemStructure',
          timeout: 6,
        },
        meta: {
          projectFileSystemStructureId: temporaryId,
        },
      },
    },
    projectFileSystemStructureId: temporaryId,
  },
});

export const UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST =
  PRIO + 'UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST';
export const UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT =
  PRIO + 'UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT';
export const UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK =
  PRIO + 'UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK';

export const updateProjectFileSystemStructure = (
  projectFileSystemStructure: ProjectFileSystemStructure,
  rollbackProjectFileSystemStructure: ProjectFileSystemStructure
) => ({
  type: UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST,
  requiresAuth: true,
  payload: projectFileSystemStructure,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ProjectFileSystemStructure/${projectFileSystemStructure.projectFileSystemStructureId}`,
        method: 'PUT',
        json: projectFileSystemStructure,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT,
        meta: {
          projectFileSystemStructureId:
            projectFileSystemStructure.projectFileSystemStructureId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK,
        meta: {
          projectFileSystemStructureId:
            projectFileSystemStructure.projectFileSystemStructureId,
        },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateProjectFileSystemStructure',
          timeout: 6,
        },
        rollbackProjectFileSystemStructure,
      },
    },
    projectFileSystemStructureId:
      projectFileSystemStructure.projectFileSystemStructureId,
  },
});

export const DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST =
  PRIO + 'DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST';
export const DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT =
  PRIO + 'DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT';
export const DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK =
  PRIO + 'DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK';

export const deleteProjectFileSystemStructure = (
  projectFileSystemStructureId: ProjectFileSystemStructureId,
  rollbackProjectFileSystemStructure: ProjectFileSystemStructure
) => ({
  type: DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ProjectFileSystemStructure/${projectFileSystemStructureId}/archive`,
        method: 'PUT',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT,
        meta: {
          projectFileSystemStructureId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK,
        meta: {
          projectFileSystemStructureId,
        },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.deleteProjectFileSystemStructure',
          timeout: 6,
        },
        rollbackProjectFileSystemStructure,
      },
    },
    projectFileSystemStructureId,
  },
});
