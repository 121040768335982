import { PRIO } from '../../../constants';
import { ReduxAction } from '../../../models/Redux';
import { ColumnWidthsState } from '../reducers/columnWidths';

export const UPDATE_DOCUMENTS_TABLE_WIDTHS =
  PRIO + 'UPDATE_DOCUMENTS_TABLE_WIDTHS';

export const updateDocumentsTableWidths: (
  widths: ColumnWidthsState
) => ReduxAction<{
  widths: ColumnWidthsState;
}> = (widths) => ({
  type: UPDATE_DOCUMENTS_TABLE_WIDTHS,
  meta: {
    widths,
  },
});
