import { useCallback, useEffect, useState } from 'react';
import { apiFetchDocumentTemplateTags } from '../api';
import { DocumentTemplateTag } from '../../../models/DocumentTemplate';
import DocumentTemplateTagContext from '../context/DocumentTemplateTagContext';

const DocumentTemplateTagContextProvider: React.FC = ({ children }) => {
  //#region ------------------------------ Defaults
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [data, setData] = useState<DocumentTemplateTag[]>([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchDocumentTemplateTags = useCallback(async () => {
    const { data } = await apiFetchDocumentTemplateTags();
    if (data && Array.isArray(data)) {
      setData(data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, []);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    fetchDocumentTemplateTags();
  }, [fetchDocumentTemplateTags]);
  //#endregion

  return (
    <DocumentTemplateTagContext.Provider
      value={{ data, fetchDocumentTemplateTags }}
    >
      {children}
    </DocumentTemplateTagContext.Provider>
  );
};

export default DocumentTemplateTagContextProvider;
