import { AbsenceState, AbsenceType } from '../../../models/Types';
import { PrioTheme } from '../../../theme/types';

export const mapAbsencesStateToRevokeState: (
  absenceState: AbsenceState
) => AbsenceState = (absenceState) => {
  switch (absenceState) {
    case 'accepted': {
      return 'revokeAccepted';
    }
    case 'declined': {
      return 'revokeDeclined';
    }
    case 'requested': {
      return 'revokeRequested';
    }
    default: {
      return absenceState;
    }
  }
};

export const mapRevokeAbsencesStateToAbsenceState: (
  absenceState: AbsenceState
) => AbsenceState = (absenceState) => {
  switch (absenceState) {
    case 'revokeAccepted': {
      return 'accepted';
    }
    case 'revokeDeclined': {
      return 'declined';
    }
    case 'revokeRequested': {
      return 'requested';
    }
    default: {
      return absenceState;
    }
  }
};

export const mapAbsenceStateToColor = (
  absenceState: AbsenceState,
  absenceType: AbsenceType,
  theme: PrioTheme
) => {
  switch (absenceType) {
    case 'annualLeavePlanning':
      return theme.old.palette.chromaticPalette.grey;
    default:
      switch (absenceState) {
        case 'accepted':
          return theme.old.palette.chromaticPalette.green;
        case 'declined':
          return theme.old.palette.chromaticPalette.red;
        case 'planned':
          return theme.old.palette.chromaticPalette.grey;
        case 'requested':
          return theme.old.palette.chromaticPalette.yellow;
        case 'revokeAccepted':
          return theme.old.palette.chromaticPalette.red;
        case 'revokeDeclined':
          return theme.old.palette.chromaticPalette.green;
        case 'revokeRequested':
          return theme.old.palette.chromaticPalette.yellow;
        default:
          return theme.old.palette.chromaticPalette.grey;
      }
  }
};
