import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makePrioStyles } from '../../../theme/utils';
import { Checkbox, Modal, Select, Typography } from 'antd';
import { createSelector } from 'reselect';
import { Project } from '../../../models/Project';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { MailFolder } from '../../../models/MailFolder';
import {
  getMailSettings,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { MailFolderId, ProjectId } from '../../../models/Types';
import { Message, MessageCenterMessage } from '../../../models/Message';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { apiFetchMailFolders, apiFetchSpecialMailFolders } from '../api';
import { getDisplayName } from '../util';
import { SpecialMailFolders } from '../actions/types';
import { updateMailSettings } from '../../userSettings/actions/mailSettings/mail';
import { copyMessageToProject } from '../actions/actionControllers/messageActionController';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  modalSelectTitle: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
    marginBottom: theme.old.spacing.unit(0.5),
  },
  modalSelect: {
    overflow: 'hidden',
  },
}));

const myProjectsSelector = createSelector<
  [
    (state: RootReducerState) => string[],
    (state: RootReducerState) => ProjectByIdState,
  ],
  Project[]
>(
  (state) => state.projects.projects.myIds,
  (state) => state.projects.projects.byId,
  (ids, byId) =>
    (ids ?? [])
      .map((id) => byId[id])
      .sort((a: Project, b: Project) => {
        return a.name.localeCompare(b.name);
      })
);

function useMailFolders(selectedProjectId: ProjectId): [MailFolder[], boolean] {
  const [mailFolders, setMailFolders] = useState<MailFolder[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        setIsFetching(true);
        const { data } = await apiFetchMailFolders(selectedProjectId);
        if (data) {
          setMailFolders(data);
        }
      } catch {}
      setIsFetching(false);
    };
    fetchFolders();
  }, [selectedProjectId]);
  return [mailFolders, isFetching];
}

interface MoveMessageModalProps {
  movedMessage: Message;
  selectedMessages: Message[];
  projectId: ProjectId;
  defaultSelected: ProjectId;
  mailFolderId?: MailFolderId;
  onOk: VoidFunction;
  onCancel: VoidFunction;
  onSelectionChange?: (selectedIds: Message[]) => void;
}

export const MoveMessageModal: React.FC<MoveMessageModalProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const {
    movedMessage,
    projectId,
    mailFolderId,
    selectedMessages,
    defaultSelected,
    onOk,
    onCancel,
    onSelectionChange,
  } = props;

  const dispatch = useDispatch();

  const settings = useSelector(getMailSettings);

  const myProjects = useSelector(myProjectsSelector);

  const [selectedProjectId, setSelectedProjectId] =
    useState<ProjectId>(defaultSelected);

  const [specialMailFolders, setSpecialMailFolders] =
    useState<SpecialMailFolders>({});

  useEffect(() => {
    const fetchSpecialFolders = async () => {
      try {
        const { data } = await apiFetchSpecialMailFolders(selectedProjectId);
        if (data) {
          setSpecialMailFolders(data);
        }
      } catch {}
    };
    fetchSpecialFolders();
  }, [selectedProjectId]);

  const [mailFolders, isFetching] = useMailFolders(selectedProjectId);

  const [selectedMailFolderId, setSelectedMailFolderId] =
    useState<MailFolderId>(null);

  useEffect(() => {
    if (mailFolders.find((folder) => folder.id === mailFolderId)) {
      setSelectedMailFolderId(
        mailFolderId && mailFolderId !== 'inbox'
          ? mailFolderId
          : mailFolders.find(
              (folder) =>
                specialMailFolders &&
                folder.id === specialMailFolders['inboxFolder']?.id
            )?.id ?? null
      );
    } else {
      setSelectedMailFolderId(
        specialMailFolders
          ? specialMailFolders['inboxFolder']?.id ?? null
          : null
      );
    }
  }, [mailFolders, mailFolderId, specialMailFolders]);

  useEffect(() => {
    setSelectedProjectId(defaultSelected);
  }, [defaultSelected]);

  const handleOnOk = async () => {
    try {
      const destinationMailFolderId =
        selectedMailFolderId === specialMailFolders['inboxFolder']?.id
          ? 'inbox'
          : selectedMailFolderId;
      const messagesToCopy = (
        selectedMessages?.find(
          (selectedMessage) => selectedMessage.id === movedMessage.id
        )
          ? selectedMessages
          : [movedMessage]
      ) as MessageCenterMessage[];
      const messagesByProjectId: { [projectId: ProjectId]: Message[] } =
        messagesToCopy.reduce(
          (map, message) => ({
            ...map,
            [message.projectId ?? projectId]: [
              ...(map[message.projectId ?? projectId] ?? []),
              message,
            ],
          }),
          {}
        );
      Object.keys(messagesByProjectId).forEach((messageProjectId) =>
        dispatch(
          copyMessageToProject(
            messagesByProjectId[messageProjectId],
            messageProjectId,
            mailFolderId ?? 'inbox',
            destinationMailFolderId,
            selectedProjectId,
            settings.deleteMovedMessageMe
          )
        )
      );
      if (settings.deleteMovedMessageMe) {
        const urlId = !!window.location.href.match(/message\/(.*)\/details?$/)
          ? window.location.href.match(/message\/(.*)\/details?$/)[1]
          : null;
        window.history.replaceState(
          {},
          '',
          urlId && messagesToCopy.find((message) => message.id === urlId)
            ? `../../../${mailFolderId}`
            : ''
        );
        if (messagesToCopy.find((message) => message.id === urlId)) {
          dispatch(setMailListNavigationState(null, projectId));
        }
      }
    } catch (e) {
      console.warn('some error occurred loading personal messages', e);
    }
    if (
      onSelectionChange &&
      selectedMessages?.find(
        (selectedMessage) => selectedMessage.id === movedMessage.id
      )
    ) {
      onSelectionChange([]);
    }
    onOk();
  };

  return (
    <Modal
      title={t('mail:widgetArea.modal.title')}
      okText={t('mail:widgetArea.modal.okText')}
      cancelText={t('mail:widgetArea.modal.cancelText')}
      onOk={handleOnOk}
      onCancel={onCancel}
      visible={!!movedMessage}
    >
      <Flex.Column childrenGap={theme.old.spacing.defaultPadding}>
        <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
          <Flex.Column className={classes.modalSelect} flex={1}>
            <Typography.Text className={classes.modalSelectTitle}>
              {t('mail:widgetArea.modal.project')}
            </Typography.Text>
            <Select
              value={selectedProjectId}
              onChange={(projectId) => setSelectedProjectId(projectId)}
            >
              {myProjects.map((project) => (
                <Select.Option
                  value={project.projectId}
                  key={project.projectId}
                >
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Flex.Column>
          <Flex.Column className={classes.modalSelect} flex={1}>
            <Typography.Text className={classes.modalSelectTitle}>
              {t('mail:widgetArea.modal.mailFolder')}
            </Typography.Text>
            <Select
              value={isFetching ? null : selectedMailFolderId}
              onChange={(mailFolderId) => setSelectedMailFolderId(mailFolderId)}
              loading={isFetching}
            >
              {specialMailFolders &&
                mailFolders.map((mailFolder) => (
                  <Select.Option value={mailFolder.id} key={mailFolder.id}>
                    {getDisplayName(mailFolder, specialMailFolders, t)}
                  </Select.Option>
                ))}
            </Select>
          </Flex.Column>
        </Flex.Row>
        <Checkbox
          checked={settings.deleteMovedMessageMe}
          onChange={(e) =>
            dispatch(
              updateMailSettings({
                deleteMovedMessageMe: e.target.checked,
              })
            )
          }
        >
          {t('mail:widgetArea.modal.deleteOrigin')}
        </Checkbox>
      </Flex.Column>
    </Modal>
  );
};

export default MoveMessageModal;
