import { PRIO } from '../../../constants';
import { apiUrl } from '../api/index';
import { MailSettings } from '../../../models/UserSettings/MailSettings';
import { UserSettings } from '../../../models/UserSettings/UserSettings';

export interface MailSettingsAction {
  type: string;
  mailSettings?: MailSettings;
}

/** Update Message View Layout Setting*/
export const FETCH_USER_SETTINGS_REQUEST = PRIO + 'FETCH_USER_SETTINGS_REQUEST';
export const FETCH_USER_SETTINGS_COMMIT = PRIO + 'FETCH_USER_SETTINGS_COMMIT';
export const FETCH_USER_SETTINGS_ROLLBACK =
  PRIO + 'FETCH_USER_SETTINGS_ROLLBACK';

export const fetchUserSettings = () => ({
  type: FETCH_USER_SETTINGS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/usersetting/usersetting/me`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_USER_SETTINGS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_USER_SETTINGS_ROLLBACK,
      },
    },
  },
});

/** Update Message View Layout Setting*/
export const UPDATE_USER_SETTINGS_REQUEST =
  PRIO + 'UPDATE_USER_SETTINGS_REQUEST';
export const UPDATE_USER_SETTINGS_COMMIT = PRIO + 'UPDATE_USER_SETTINGS_COMMIT';
export const UPDATE_USER_SETTINGS_ROLLBACK =
  PRIO + 'UPDATE_USER_SETTINGS_ROLLBACK';

export const setUserSettings = (userSettings: UserSettings) => ({
  type: UPDATE_USER_SETTINGS_REQUEST,
  userSettings,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        headers: {
          // Authorization: `Bearer ${await getAccessToken()}`,
          'Content-Type': 'application/merge-patch+json',
        },
        url: `${apiUrl}/usersetting/usersetting/me`,
        method: 'PATCH',
        json: userSettings,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_USER_SETTINGS_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_USER_SETTINGS_ROLLBACK,
      },
    },
  },
});
