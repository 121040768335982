import React, { useEffect, useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';

interface DebouncedInputNumberProps extends Omit<InputNumberProps, 'onChange'> {
  delay?: number;
  showSearchIcon?: boolean;
  onChange?: (value: number | string) => void;
}

export const DebouncedInputNumber: React.FC<DebouncedInputNumberProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const { value, delay, onChange, ...rest } = props;
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [currentInputValue, setCurrentInputValue] = useState<string | number>(
      null
    );
    const [timer, setTimer] = useState<NodeJS.Timeout>(null);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleChange = (value: string | number) => {
      setCurrentInputValue(value);
      if (timer) {
        clearTimeout(timer);
      }
      setTimer(
        setTimeout(() => {
          if (onChange) {
            onChange(value);
          }
        }, delay ?? 250)
      );
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      setCurrentInputValue(value);
    }, [value]);
    //#endregion

    return (
      <InputNumber
        value={currentInputValue}
        onChange={handleChange}
        {...rest}
      />
    );
  });

export default DebouncedInputNumber;
