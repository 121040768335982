import {
  FilterHistoryDrawer,
  HistoryItem,
} from '@prio365/prio365-react-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiFetchSearchHistory, apiPutSearchHistory } from './api';
import { useTranslation } from 'react-i18next';
import { Input, Modal } from 'antd';
import useContactsContext from '../../modules/contacts/hooks/useContactsProvider';
import useCompaniesContext from '../../modules/companies/hooks/useCompaniesContext';
import useProjectsContext from '../../modules/projects/hooks/useProjectsContext';
import useOfficesContext from '../../modules/companies/hooks/useOfficesContext';
import { generateFilterHistoryDrawerItems } from './utils';
import { FilterConfig } from './types';

interface FilterBarHistoryDrawerProps {
  searchType: string;
  visible: boolean;
  filters: FilterConfig[];
  setVisible: (value: boolean) => void;
  onFilterAgain?: (HistoryItem: any) => void;
}

export const FilterBarHistoryDrawer: React.FC<FilterBarHistoryDrawerProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const {
    searchType,
    visible,
    setVisible = () => {},
    filters,
    onFilterAgain = () => {},
  } = props;

  const { t } = useTranslation();

  //#endregion

  //#region -------------------------------- States
  const { getContactById } = useContactsContext();
  const { getCompanyById } = useCompaniesContext();
  const { getProjectById } = useProjectsContext();
  const { getOfficeById } = useOfficesContext();

  const [filterHistory, setFilterHistory] = useState<HistoryItem[]>(null);

  const [editDescriptionModalOpen, setEditDescriptionModalOpen] =
    useState<boolean>(false);
  const [historyItemInModal, setHistoryItemInModal] =
    useState<HistoryItem>(null);

  const historyItems = useMemo(() => {
    return generateFilterHistoryDrawerItems(
      filterHistory,
      filters,
      getContactById,
      getCompanyById,
      getProjectById,
      getOfficeById,
      t
    );
  }, [
    filterHistory,
    filters,
    getContactById,
    getCompanyById,
    getProjectById,
    getOfficeById,
    t,
  ]);
  //#endregion

  //#region -------------------------------- Methods / Handlers
  const getFilterHistory = useCallback(async () => {
    const { data } = await apiFetchSearchHistory(searchType);
    if (data) {
      setFilterHistory(data);
    } else {
      setVisible(false);
    }
  }, [searchType, setVisible]);

  const handleBookmark = useCallback(
    async (historyItem: HistoryItem, isBookmarked: boolean = false) => {
      setFilterHistory(
        (prev) =>
          prev?.map((item) =>
            item?.searchHistoryId === historyItem?.searchHistoryId
              ? {
                  ...item,
                  isBookmarked,
                }
              : item
          )
      );
      const { data } = await apiPutSearchHistory(historyItem?.searchHistoryId, {
        description: historyItem?.description,
        isBookmarked,
        sortKey: historyItem?.sortKey,
      });
      if (data) {
      } else {
        getFilterHistory();
      }
    },
    [setFilterHistory, getFilterHistory]
  );

  const handleDescription = useCallback(
    async (historyItem: HistoryItem) => {
      setFilterHistory(
        (prev) =>
          prev?.map((item) =>
            item?.searchHistoryId === historyItem?.searchHistoryId
              ? {
                  ...item,
                  description: historyItem?.description,
                }
              : item
          )
      );

      const { data } = await apiPutSearchHistory(historyItem?.searchHistoryId, {
        description: historyItem?.description,
        isBookmarked: historyItem?.isBookmarked,
        sortKey: historyItem?.sortKey,
      });
      if (data) {
      } else {
        getFilterHistory();
      }
    },
    [getFilterHistory]
  );

  async function handleEditDescriptionModalOk(historyItem: HistoryItem) {
    setEditDescriptionModalOpen(false);
    handleDescription(historyItem);
  }

  const handleOnCopyLink = (historyItem: HistoryItem) => {
    const url = new URL(window.location.href);
    url.searchParams.set('s', historyItem.searchString);
    url.searchParams.delete('pickedFilters');
    navigator.clipboard.writeText(url.toString());
  };
  //#endregion

  //#region -------------------------------- Effects
  useEffect(() => {
    if (visible) {
      getFilterHistory();
    } else {
      setFilterHistory(null);
    }
  }, [searchType, visible, getFilterHistory]);
  //#endregion

  return (
    <div>
      <FilterHistoryDrawer
        onFilterAgain={onFilterAgain}
        onBookmark={handleBookmark}
        onClose={() => setVisible(false)}
        historyItems={historyItems}
        onEditDescription={(historyItem) => {
          setHistoryItemInModal(historyItem);
          setEditDescriptionModalOpen(true);
        }}
        onCopyLink={handleOnCopyLink}
        visible={visible}
        title={t('components:filter.historyDrawer.title')}
        header={t('components:filter.historyDrawer.searchHistory')}
      />
      <Modal
        style={{ zIndex: 9999999 }}
        title={t('components:filter.historyDrawer.editName')}
        visible={editDescriptionModalOpen}
        onOk={() => handleEditDescriptionModalOk(historyItemInModal)}
        onCancel={() => {
          setEditDescriptionModalOpen(false);
        }}
        cancelText={t('common:actions.cancel')}
      >
        <Input
          type="text"
          value={historyItemInModal?.description}
          onChange={(e) =>
            setHistoryItemInModal((historyItemInModal) => ({
              ...historyItemInModal,
              description: e.target.value,
            }))
          }
        />
      </Modal>
    </div>
  );
};
