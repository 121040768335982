import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select } from 'antd';
import { Button, Divider, Drawer } from '@prio365/prio365-react-library';
import {
  ConfigurationKeys,
  EMailSuffix,
  EmployeeId,
  OfficeId,
} from '../../../../models/Types';
import { Employee } from '../../../../models/Employee';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { colon } from '../../../../util/forms';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import OfficePicker from '../../../companies/components/OfficePicker';
import ContactPicker from '../../../contacts/components/ContactPicker';
import {
  emailPattern,
  emailPrefixPattern,
} from '../../../../hooks/useEmailValidation';
import { apiValidateEmail } from '../../../users/api';
import {
  apiFetchConfigurations,
  apiFetchEmailSuffixes,
} from '../../../settings/api';
import { fetchTitleSuggestions } from '../../../settings/actions/titleSuggestions';
import { fetchContactClassifications } from '../../../settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { defaultPhonePattern, defaultPhonePlaceholder } from '../../../../util';
import { Configuration } from '../../../../models/Configuration';
import { InternalContact } from '../../../../models/Contact';
import { updateInternalContact } from '../../../contacts/actions';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getContactsByIdState,
  getOfficesByIdState,
} from '../../../../apps/main/rootReducer';
import TextArea from 'antd/lib/input/TextArea';
import eqauls from 'deep-equal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    fontSize: '14px',
    overflowY: 'auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  formContent: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  eMailSuffixSelect: {
    '&:before': {
      content: '"@"',
      display: 'inline-block',
      position: 'absolute',
      left: -22,
      top: 3,
      color: theme.colors.application.typography.muted,
    },
  },
  heading: {
    marginBottom: theme.spacing.regular,
    fontSize: theme.font.fontSize.regular,
    color: theme.colors.application.typography.muted,
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  placeholder: {
    '&.ant-input:placeholder-shown::placeholder': {
      color: 'rgb(0,0,0,0.1)',
    },
  },
  paddingTopOnLastRow: {
    paddingTop: theme?.old?.spacing?.baseSpacing * 2,
  },
  customDrawerContent: {
    paddingBottom: 0,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing.large,
  },
  gridColumn: {
    marginTop: theme.spacing.regular,
    marginBottom: theme.spacing.large,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing.large,
  },
  header: {
    marginBottom: theme.spacing.regular,
    fontWeight: theme.font.fontWeight.bold,
  },
  informationGroup: {
    marginBottom: theme.spacing.regular,
  },
  valueContactInformation: {
    marginLeft: theme.spacing.small,
  },
  spacing: {
    marginBottom: theme.spacing.large,
  },
  textAreaContainer: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
  },
  buttonTextArea: {
    position: 'absolute',
    top: '50%',
    padding: '8px',
    right: '12px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '& > svg': {
      display: 'flex',
    },
  },
  singleFormItem: {
    marginRight: 'calc(50% + 4px)',
  },
  divider: {
    marginTop: 0,
  },
  dataContainer: {
    overflowY: 'auto',
  },
}));

interface CompanyDataFormModel {
  officeId?: string;
  managerId?: string;
  department?: string;
  position?: string;
  eMailPrefix?: string;
  eMailSuffix?: string;
  eMail2?: string;
  eMail3?: string;
  phone?: string;
  phone2?: string;
  phone3?: string;
  cellphone?: string;
  cellphone2?: string;
  cellphone3?: string;
  notes?: string;
  signaturePrefix?: string;
  fax?: string;
}

interface PersonnelFileCompanyDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  datePickerLocale: PickerLocale;
  officeId?: OfficeId;
  patchEmployee: (patchData: Employee) => Promise<void>;
  contact: InternalContact;
}

export const PersonnelFileCompanyDataForm: React.FC<
  PersonnelFileCompanyDataFormProps
> = (props) => {
  const { employee, officeId, contact } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);
  const [parsedContactFormData, setParsedContactFormData] =
    useState<CompanyDataFormModel>(null);
  const officesById = useSelector(getOfficesByIdState);
  const contactsByIdState = useSelector(getContactsByIdState);

  const [companyDataForm] = Form.useForm<CompanyDataFormModel>();
  const [emailSuffixes, setEmailSuffixes] = useState<EMailSuffix[]>([]);
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  const [disabled] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(true);

  const [updateTextAreaString, setUpdateTextAreaString] =
    useState<string>(undefined);
  const [isTextAreaUpdated, setIsTextAreaUpdated] = useState<boolean>(false);

  const contactCast = contact as InternalContact;

  //#region ------------------------------ Methods / Handlers

  const companyDataFromEmployee: (
    contact: InternalContact
  ) => CompanyDataFormModel = (contact) => ({
    officeId: contact?.officeId,
    managerId: contact?.managerId,
    department: contact?.department,
    position: contact?.position,
    eMailPrefix: contact?.eMail.split('@')[0],
    eMailSuffix: contact?.eMail.split('@')[1],
    eMail2: contact?.eMail2,
    eMail3: contact?.eMail3,
    phone: contact?.phone,
    phone2: contact?.phone2,
    phone3: contact?.phone3,
    cellphone: contact?.cellphone,
    cellphone2: contact?.cellphone2,
    cellphone3: contact?.cellphone3,
    notes: contact?.notes,
    signaturePrefix: contact?.signaturePrefix,
    fax: contact?.fax,
  });

  const cancelEditCompanyData = () => {
    companyDataForm.setFieldsValue(companyDataFromEmployee(contactCast));
    setEditDrawerVisible(false);
    setIsSaveButtonDisabled(true);
  };

  const saveEditCompanyData = () => {
    companyDataForm.submit();
  };

  const handleFinishCompanyData: (value: CompanyDataFormModel) => void =
    useCallback(
      async (value: CompanyDataFormModel) => {
        const { eMailPrefix, eMailSuffix, ...rest } = value;
        let nullCheckedObject = Object.keys(rest).reduce((sum, currentKey) => {
          if (
            rest[currentKey] ||
            (rest[currentKey] === '' && contact[currentKey])
          ) {
            return {
              ...sum,
              [currentKey]: rest[currentKey],
            };
          }
          return sum;
        }, {});

        const editedUser = contact
          ? {
              ...nullCheckedObject,
              eMail: `${eMailPrefix}@${eMailSuffix}`,
            }
          : null;

        dispatch(
          updateInternalContact(
            editedUser,
            contact.contactId,
            contact.rowVersion,
            contact,
            officeId
          )
        );

        setEditDrawerVisible(false);
        setIsSaveButtonDisabled(true);
      },
      [contact, dispatch, officeId]
    );

  const validateEmail = async (email) => {
    const { data } = await apiValidateEmail(email);

    if (data?.validEmail) {
      return Promise.resolve('validate Email');
    } else {
      return Promise.reject(t('users:form.validation.invalidEmail'));
    }
  };

  const handleOnNoteChange = (value) => {
    const updateString = value.target.value;
    setIsTextAreaUpdated(updateString !== parsedContactFormData?.notes);
    setUpdateTextAreaString(updateString);
  };

  const handleSaveNotes = () => {
    setParsedContactFormData((prevState) => ({
      ...prevState,
      notes: updateTextAreaString,
    }));
    setIsTextAreaUpdated(false);
    setUpdateTextAreaString(undefined);
    handleFinishCompanyData({
      ...parsedContactFormData,
      notes: updateTextAreaString,
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setParsedContactFormData(companyDataFromEmployee(contactCast));
  }, [contactCast]);

  useEffect(() => {
    const loadEmailSuffixes = async () => {
      const { data } = await apiFetchEmailSuffixes();
      if (data) {
        setEmailSuffixes(data);
        if (data?.length > 0) {
          form.setFieldsValue({
            internalContact: { eMailSuffix: data[0] },
          });
        }
      }
    };
    dispatch(fetchTitleSuggestions());
    dispatch(fetchContactClassifications());
    loadEmailSuffixes();
  }, [dispatch, form]);

  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);

  const handleOnEditClick = () => {
    setEditDrawerVisible(true);
  };
  //#endregion

  //#region ------------------------------ Drawer Components
  const editDrawerContent = () => {
    return (
      <Form<CompanyDataFormModel>
        initialValues={companyDataFromEmployee(contactCast)}
        form={companyDataForm}
        layout="vertical"
        className={classes.form}
        onFinish={handleFinishCompanyData}
        onValuesChange={(_, values) => {
          const initialValues = companyDataFromEmployee(contactCast);
          const { notes: __, ...initialValuesWithoutNotes } = initialValues;

          if (!eqauls(values, initialValuesWithoutNotes)) {
            setIsSaveButtonDisabled(false);
          } else {
            setIsSaveButtonDisabled(true);
          }
        }}
      >
        <Flex.Column className={classes.formContent}>
          <Flex.Row flex={1}>
            <Flex.Column flex={1}>
              <div className={classes.heading}>
                <div>
                  {t('hr:personnelFile.form.labels.contactInformation')}
                </div>
              </div>
              <Flex.Row childrenGap={theme.spacing.small}>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'eMailPrefix'}
                    label={t('users:form.labels.eMailPrefix')}
                    style={{ marginRight: '22px' }}
                    colon={colon}
                    validateTrigger="onBlur"
                    rules={[
                      {
                        message: t('users:form.validation.invalidEmailPrefix'),
                        pattern: emailPrefixPattern,
                      },
                      ({ getFieldValue }) => ({
                        async validator(rule, value) {
                          if (value?.trim()?.length === 0)
                            return Promise.resolve();
                          const email = `${value}@${getFieldValue(
                            'eMailSuffix'
                          )}`;
                          if (!email.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          if (employee) {
                            return Promise.resolve();
                          }
                          return validateEmail(email);
                        },
                      }),
                      {
                        required: true,
                        message: t('users:form.validation.missingEmailPrefix'),
                      },
                    ]}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'eMailSuffix'}
                    label={t('users:form.labels.eMailSuffix')}
                    colon={colon}
                    validateTrigger="onChange"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'projects:form.validation.missingEmailSuffix'
                        ),
                      },
                      ({ getFieldValue }) => ({
                        async validator(rule, value) {
                          if (value?.trim()?.length === 0)
                            return Promise.resolve();
                          if (value == null) {
                            return Promise.reject(
                              t('projects:form.validation.missingEmailSuffix')
                            );
                          }
                          const email = `${getFieldValue(
                            'eMailPrefix'
                          )}@${value}`;
                          if (!email.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          if (employee) {
                            return Promise.resolve();
                          }
                          return validateEmail(email);
                        },
                      }),
                    ]}
                  >
                    <Select
                      className={classNames(classes.eMailSuffixSelect, {
                        [classes.disabledPicker]: disabled,
                      })}
                      disabled={disabled}
                    >
                      {emailSuffixes.map((emailSuffix) => (
                        <Select.Option value={emailSuffix} key={emailSuffix}>
                          {emailSuffix}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row childrenGap={theme.spacing.small}>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'eMail2'}
                    label={t('users:form.labels.eMail2')}
                    colon={colon}
                    validateTrigger="onChange"
                    rules={[
                      () => ({
                        async validator(rule, value) {
                          if (!value || value?.trim()?.length === 0) {
                            return Promise.resolve();
                          }
                          const email = value;
                          if (!email?.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'eMail3'}
                    label={t('users:form.labels.eMail3')}
                    colon={colon}
                    validateTrigger="onChange"
                    rules={[
                      () => ({
                        async validator(rule, value) {
                          if (!value || value?.trim()?.length === 0) {
                            return Promise.resolve();
                          }
                          const email = value;
                          if (!email?.match(emailPattern)) {
                            return Promise.reject(
                              t('users:form.validation.invalidEmail')
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row
                childrenGap={theme.spacing.small}
                className={classes.spacing}
              >
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'signaturePrefix'}
                    label={t('users:form.labels.signaturePrefix')}
                    colon={colon}
                    className={classes.singleFormItem}
                  >
                    <Input disabled={disabled} />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row childrenGap={theme.spacing.small}>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'phone'}
                    label={t('contacts:form.labels.phone')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_PHONE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'cellphone'}
                    label={t('contacts:form.labels.cellphone')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key ===
                                    ConfigurationKeys.REGEX_MOBILE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row childrenGap={theme.spacing.small}>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'phone2'}
                    label={t('contacts:form.labels.phone2')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_PHONE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'cellphone2'}
                    label={t('contacts:form.labels.cellphone2')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key ===
                                    ConfigurationKeys.REGEX_MOBILE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row
                className={classes.spacing}
                childrenGap={theme.spacing.small}
              >
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'phone3'}
                    label={t('contacts:form.labels.phone3')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_PHONE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'cellphone3'}
                    label={t('contacts:form.labels.cellphone3')}
                    colon={colon}
                    rules={[
                      {
                        message: t('users:form.validation.invalidPhoneNumber'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key ===
                                    ConfigurationKeys.REGEX_MOBILE
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row
                childrenGap={theme.spacing.small}
                className={classes.spacing}
              >
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'fax'}
                    label={t('contacts:form.labels.fax')}
                    colon={colon}
                    className={classes.singleFormItem}
                    rules={[
                      {
                        message: t('users:form.validation.invalidFax'),
                        pattern: configurations
                          ? configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_FAX
                            )
                            ? new RegExp(
                                configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_FAX
                                ).value
                              )
                            : defaultPhonePattern
                          : defaultPhonePattern,
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      className={disabled ? classes.placeholder : ''}
                      placeholder={
                        configurations?.find(
                          (config) =>
                            config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                        )?.value ?? defaultPhonePlaceholder
                      }
                    />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row
                childrenGap={theme.spacing.small}
                style={{
                  marginTop: theme.spacing.regular,
                }}
              >
                <Flex.Column flex={1} childrenGap={theme.spacing.small}>
                  <div className={classes.heading}>
                    <div>
                      {t('hr:personnelFile.form.labels.companyInformation')}
                    </div>
                  </div>
                  <Flex.Row childrenGap={theme.spacing.small} flex={1}>
                    <Flex.Column flex={1}>
                      <Form.Item
                        name={'officeId'}
                        label={t('hr:personnelFile.form.labels.office')}
                        rules={[
                          {
                            required: true,
                            message: t(
                              'hr:personnelFile.form.validation.missingOffice'
                            ),
                          },
                        ]}
                      >
                        <OfficePicker disabled={disabled} onlyInternal />
                      </Form.Item>
                      <Form.Item
                        name={'managerId'}
                        label={t('users:form.labels.managerId')}
                      >
                        <ContactPicker
                          contactType="InternalContact"
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Flex.Column>
                    <Flex.Column flex={1}>
                      <Form.Item
                        name={'department'}
                        label={t('contacts:form.labels.department')}
                        colon={colon}
                      >
                        <Input disabled={disabled} />
                      </Form.Item>
                      <Form.Item
                        name={'position'}
                        label={t('contacts:form.labels.position')}
                        colon={colon}
                      >
                        <Input disabled={disabled} />
                      </Form.Item>
                    </Flex.Column>
                  </Flex.Row>
                </Flex.Column>
              </Flex.Row>
            </Flex.Column>
          </Flex.Row>
        </Flex.Column>
      </Form>
    );
  };
  const editDrawerFooter = () => {
    return (
      <Flex.Row justifyContent="flex-end" childrenGap={theme.spacing.small}>
        <Button type="link" onClick={cancelEditCompanyData}>
          {t('common:actions.cancel')}
        </Button>

        <Button
          type="primary"
          onClick={saveEditCompanyData}
          disabled={isSaveButtonDisabled}
        >
          {t('common:save')}
        </Button>
      </Flex.Row>
    );
  };
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Flex.Item className={classes.dataContainer} flex={1}>
        <div className={classes.gridContainer}>
          <Flex.Column
            childrenGap={theme.spacing.small}
            className={classes.gridColumn}
          >
            <div className={classes.header}>
              {t('hr:personnelFile.form.labels.contactInformation')}
            </div>
            <Flex.Column
              childrenGap={theme.spacing.small}
              className={classes.informationGroup}
            >
              <Flex.Row alignItems="center">
                <FontAwesomeIcon
                  icon={['fal', 'envelope']}
                  width={20}
                  color={theme.colors.base.primary.default}
                />
                <span
                  className={classes.valueContactInformation}
                >{`${parsedContactFormData?.eMailPrefix}@${parsedContactFormData?.eMailSuffix}`}</span>
              </Flex.Row>
              {!!parsedContactFormData?.eMail2 && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'envelope']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.eMail2}
                  </span>
                </Flex.Row>
              )}
              {!!parsedContactFormData?.eMail3 && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'envelope']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.eMail3}
                  </span>
                </Flex.Row>
              )}
            </Flex.Column>
            <Flex.Column
              childrenGap={theme.spacing.small}
              className={classes.informationGroup}
            >
              {!!parsedContactFormData?.phone && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'phone']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.phone}
                  </span>
                </Flex.Row>
              )}
              {!!parsedContactFormData?.phone2 && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'phone']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.phone2}
                  </span>
                </Flex.Row>
              )}
              {!!parsedContactFormData?.phone3 && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'phone']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.phone3}
                  </span>
                </Flex.Row>
              )}
            </Flex.Column>
            <Flex.Column
              childrenGap={theme.spacing.small}
              className={classes.informationGroup}
            >
              {!!parsedContactFormData?.cellphone && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'mobile']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.cellphone}
                  </span>
                </Flex.Row>
              )}
              {!!parsedContactFormData?.cellphone2 && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'mobile']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.cellphone2}
                  </span>
                </Flex.Row>
              )}
              {!!parsedContactFormData?.cellphone3 && (
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'mobile']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.cellphone3}
                  </span>
                </Flex.Row>
              )}
            </Flex.Column>
            {!!parsedContactFormData?.fax && (
              <Flex.Column
                childrenGap={theme.spacing.small}
                className={classes.informationGroup}
              >
                <Flex.Row alignItems="center">
                  <FontAwesomeIcon
                    icon={['fal', 'fax']}
                    width={20}
                    color={theme.colors.base.primary.default}
                  />
                  <span className={classes.valueContactInformation}>
                    {parsedContactFormData?.fax}
                  </span>
                </Flex.Row>
              </Flex.Column>
            )}
          </Flex.Column>
          <Flex.Column
            childrenGap={theme.spacing.small}
            className={classes.gridColumn}
          >
            <div className={classes.header}>
              {t('hr:personnelFile.form.labels.companyInformation')}
            </div>
            <div>{`${t('hr:personnelFile.form.labels.office')}: ${
              !!parsedContactFormData?.officeId
                ? officesById?.[parsedContactFormData?.officeId]?.name
                : '-'
            }
              
          `}</div>
            {!!parsedContactFormData?.managerId && (
              <div>{`${t(
                'hr:personnelFile.form.labels.manager'
              )}: ${contactsByIdState?.[parsedContactFormData?.managerId]
                ?.firstName} ${contactsByIdState?.[
                parsedContactFormData?.managerId
              ]?.lastName}
          `}</div>
            )}
            {!!parsedContactFormData?.department && (
              <div>
                {`${t(
                  'hr:personnelFile.form.labels.department'
                )}: ${parsedContactFormData?.department}`}
              </div>
            )}
            {!!parsedContactFormData?.position && (
              <div>
                {`${t(
                  'hr:personnelFile.form.labels.position'
                )}: ${parsedContactFormData?.position}
            `}
              </div>
            )}
            {!!parsedContactFormData?.signaturePrefix && (
              <>
                <div
                  className={classes.header}
                  style={{ marginTop: `calc(${theme.spacing.small}px * 6)` }}
                >
                  {t('hr:personnelFile.form.labels.other')}
                </div>
                <div>{`${t(
                  'hr:personnelFile.form.labels.signaturePrefix'
                )}: ${parsedContactFormData?.signaturePrefix}
          `}</div>
              </>
            )}
          </Flex.Column>
        </div>
        <Divider className={classes.divider} />
        <Flex.Item flex={1}>
          <div>
            <div className={classes.header}>
              {t('hr:personnelFile.form.labels.notes')}
            </div>
            <div className={classes.textAreaContainer}>
              <TextArea
                autoSize={{ minRows: 2, maxRows: 5 }}
                style={{
                  width: '100%',
                  paddingRight: `${isTextAreaUpdated ? '38px' : '0px'}`,
                }}
                value={updateTextAreaString ?? parsedContactFormData?.notes}
                onChange={handleOnNoteChange}
              ></TextArea>
              {isTextAreaUpdated && (
                <Button
                  className={classes.buttonTextArea}
                  iconProp={['far', 'check']}
                  tooltip={t('hr:personnelFile.form.labels.saveNotes')}
                  onClick={handleSaveNotes}
                ></Button>
              )}
            </div>
          </div>
        </Flex.Item>
      </Flex.Item>

      <div className={classes.buttonRow}>
        <Button type="primary" onClick={handleOnEditClick}>
          {t('common:edit')}
        </Button>
      </div>
      <Drawer
        title={t('hr:personnelFile.editDrawer.companyData')}
        visible={editDrawerVisible}
        onClose={() => setEditDrawerVisible(false)}
        children={editDrawerContent()}
        footer={editDrawerFooter()}
        contentClassName={classes.customDrawerContent}
      />
    </Flex.Column>
  );
};

export default PersonnelFileCompanyDataForm;
