import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import { SettingKey } from '../../../models/Settings';

export const FETCH_AI_SETTING_REQUEST = PRIO + 'FETCH_AI_SETTING_REQUEST';
export const FETCH_AI_SETTING_COMMIT = PRIO + 'FETCH_AI_SETTING_COMMIT';
export const FETCH_AI_SETTING_ROLLBACK = PRIO + 'FETCH_AI_SETTING_ROLLBACK';

export const fetchAiSetting = (aiSettingKey: SettingKey[]) => ({
  type: FETCH_AI_SETTING_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/settings/Settings/GetSettings`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: aiSettingKey,
      },
      commit: { type: FETCH_AI_SETTING_COMMIT, meta: { aiSettingKey } },
      rollback: {
        type: FETCH_AI_SETTING_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchAiSettingError',
          timeout: 6,
        },
      },
    },
  },
});
