import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Typography } from 'antd';
import { closeAddUserDrawer } from '../actions/addUserDrawer';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RootReducerState,
  getAddUserDrawerState,
} from '../../../apps/main/rootReducer';
import { AddUserDrawerState } from '../reducers/addUserDrawer';
import ImportAadUsers from './ImportAadUsers';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

interface AddUserDrawerProps {}
const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  importUser: {
    height: '100%',
  },
  importAadUser: {
    flex: 1,
  },
  drawer: {
    zIndex: 999,
  },
}));

export const AddUserDrawer: React.FC<AddUserDrawerProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const drawerState = useSelector<RootReducerState, AddUserDrawerState>(
    getAddUserDrawerState
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open } = drawerState;

  const closeDrawer = () => dispatch(closeAddUserDrawer());

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={closeDrawer}
      visible={open}
      width={theme.old.components.drawerWidth}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      className={classes.drawer}
      destroyOnClose
    >
      <Flex.Column className={classes.importUser}>
        <div>
          <Typography.Title level={2}>
            {t('users:addUsersDrawer.importUser.title')}
          </Typography.Title>
        </div>
        <ImportAadUsers className={classes.importAadUser} />
      </Flex.Column>
    </Drawer>
  );
};

export default AddUserDrawer;
