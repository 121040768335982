import React from 'react';
import moment from 'moment';
import { NotificationTabs } from '../actions/drawer';
import { NotificationId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import {
  getNotificationsByIdState,
  getNotificationsIds,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { NotificationsByIdState } from '../reducers/notifications';
import { createSelector } from 'reselect';
import { groupByFunction } from '../../../util';
import { Notification } from '../../../models/Notification';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { NotificationItem } from './NotificationItem';
import { AbsenceNotificationItem } from './AbsenceNotificationItem';
import { TrainingNotificationItem } from './TrainingNotificationItem';
import { InternalProjectContactNotificationItem } from './InternalProjectContactNotificationItem';
import Flex from '../../../components/Flex';
import { SystemNotificationItem } from './SystemNotificationItem';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  dayLabel: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
  },
}));

type NotificationsByDate = {
  [dateString: string]: Notification[];
};

export const groupedNotificationsSelectorAll = createSelector<
  [
    (state: RootReducerState) => NotificationsByIdState,
    (state: RootReducerState) => NotificationId[],
  ],
  NotificationsByDate
>(
  (state) => getNotificationsByIdState(state),
  (state) => getNotificationsIds(state),
  (byId, ids) =>
    groupByFunction<Notification>(
      ids
        .map((id) => byId[id])
        .filter((n) => !!n && !n.isArchived && !n.isRead),
      (n) => n.notificationDateTime.substr(0, 100)
    )
);

export const groupedNotificationsSelectorAbsences = createSelector<
  [
    (state: RootReducerState) => NotificationsByIdState,
    (state: RootReducerState) => NotificationId[],
  ],
  NotificationsByDate
>(
  (state) => getNotificationsByIdState(state),
  (state) => getNotificationsIds(state),
  (byId, ids) =>
    groupByFunction<Notification>(
      ids
        .map((id) => byId[id])
        .filter(
          (n) =>
            !!n && !n.isArchived && !n.isRead && n.data && n.type === 'absence'
        ),
      (n) => n.notificationDateTime.substr(0, 100)
    )
);
export const groupedNotificationsSelectorTrainings = createSelector<
  [
    (state: RootReducerState) => NotificationsByIdState,
    (state: RootReducerState) => NotificationId[],
  ],
  NotificationsByDate
>(
  (state) => getNotificationsByIdState(state),
  (state) => getNotificationsIds(state),
  (byId, ids) =>
    groupByFunction<Notification>(
      ids
        .map((id) => byId[id])
        .filter(
          (n) =>
            !!n && !n.isArchived && !n.isRead && n.data && n.type === 'training'
        ),
      (n) => n.notificationDateTime.substr(0, 100)
    )
);

interface NotificationListProps {
  className?: string;
  type: NotificationTabs;
  onNotificationClick: (notificationId: NotificationId) => void;
  onClose?: VoidFunction;
}

export const NotificationList: React.FC<NotificationListProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { type, className, onClose } = props;

  const { t } = useTranslation();

  const notifications = useSelector<RootReducerState, NotificationsByDate>(
    (state) => {
      switch (type) {
        case 'all':
          return groupedNotificationsSelectorAll(state);
        case 'absences':
          return groupedNotificationsSelectorAbsences(state);
        case 'trainings':
          return groupedNotificationsSelectorTrainings(state);
      }
    }
  );

  const days = Object.keys(notifications).sort((a, b) => b.localeCompare(a));

  const userMe = useSelector(getUserMe);

  const mapToNotificationItem: (
    notification: Notification,
    index: number
  ) => React.ReactNode = (notification, index) => {
    switch (notification.type) {
      case 'absence': {
        if (!notification?.data) {
          return null;
        }
        return (
          <AbsenceNotificationItem
            key={notification.notificationId}
            notification={notification}
            onClose={onClose}
            userMe={userMe}
          />
        );
      }
      case 'training': {
        if (!notification?.data) {
          return null;
        }
        return (
          <TrainingNotificationItem
            key={notification.notificationId}
            notification={notification}
            onClose={onClose}
          />
        );
      }
      case 'internalProjectContact': {
        return (
          <InternalProjectContactNotificationItem
            key={notification.notificationId}
            notification={notification}
            onClose={onClose}
          />
        );
      }
      case 'system': {
        return (
          <SystemNotificationItem
            key={notification.notificationId}
            notification={notification}
            onClose={onClose}
          />
        );
      }
      default:
        return (
          <NotificationItem
            key={notification.notificationId}
            notification={notification}
            onClose={onClose}
          />
        );
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      {days.map((day) => {
        const dayMoment = moment(day);
        const isToday = dayMoment.isSame(moment(), 'day');
        return (
          <div key={day}>
            <Typography.Text className={classes.dayLabel}>
              {isToday ? t('common:today') : dayMoment.format('DD.MM.YYYY')}
            </Typography.Text>
            <Flex.Column childrenGap={theme.old.spacing.unit(1)}>
              {notifications[day].map(mapToNotificationItem)}
            </Flex.Column>
          </div>
        );
      })}
    </div>
  );
};

export default NotificationList;
