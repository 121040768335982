import React, {
  useState,
  useEffect,
  useRef,
  HTMLAttributes,
  CSSProperties,
  ReactNode,
  useReducer,
  Reducer,
} from 'react';
import classNames from 'classnames';
import {
  useTable,
  Column,
  CellProps,
  ColumnInstance,
  UseTableCellProps,
  Row,
  useSortBy,
} from 'react-table';
import { List, ListRowProps, ScrollParams } from 'react-virtualized';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { Paths } from '../../util/GenericHelper';
import { DragSourceMonitor, DropTargetMonitor } from 'react-dnd';
import { makePrioStyles } from '../../theme/utils';
import equals from 'deep-equal';
import VirtualTableBody from './VirtualTableBody';
import VirtualTableRow from './VirtualTableRow';
import VirtualTableCell from './VirtualTableCell';
import PrioSpinner from '../PrioSpinner';
import VirtualTableHeader from './VirtualTableHeader';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export type VColumn<DataType extends object> = Omit<
  Column<DataType>,
  'accessor' | 'width' | 'id'
> & {
  id: React.Key;
  title?: string | ((cellProps: ICellProps<DataType>) => JSX.Element);
  width: number;
  alignSelf?: boolean;
  accessor?: Paths<DataType>;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  className?: string;
  disableHover?: boolean;
  Cell?: (cellProps: ICellProps<DataType>) => ReactNode;
  cellTitle?: (value: DataType) => string;
};

export declare type GetComponentProps<DataType> = (
  data: DataType,
  index?: number
) => HTMLAttributes<HTMLElement>;

export interface IUseTableCellProps<DataType extends object>
  extends UseTableCellProps<DataType> {
  column: IColumnInstance<DataType>;
}

export interface IColumnInstance<DataType extends object>
  extends ColumnInstance<DataType> {
  cellTitle?: (value: DataType) => string;
  alignSelf?: boolean;
  className?: string;
  accessor?: Paths<DataType>;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
}

export interface ICellProps<DataType extends object>
  extends CellProps<DataType> {
  column: IColumnInstance<DataType>;
}

export interface LoadingProps {
  loadingType: 'table' | 'footer';
  indicator: JSX.Element;
  indicatorAsOverlay?: boolean;
}

interface OnRowDragProps {
  dragType: string;
  configureDraggedObject?: (...params: any) => object;
  onDragEnd?: (
    item: object,
    monitor: DragSourceMonitor<object, unknown>
  ) => void;
}

interface OnRowDropProps<DataType> {
  configureDroppableObject?: (...params: any) => object;
  including: (a: DataType) => boolean;
  disable?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    object: object
  ) => boolean;
  acceptType: string[];
  onDrop?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    targetItem: object
  ) => object;
  onOver?: (isOver, canDrop) => void;
}

export interface OnRowProps<DataType> {
  onRowDrag?: OnRowDragProps;
  onRowDrop?: OnRowDropProps<DataType>;
  triggerFunctions?: GetComponentProps<DataType>;
  onChange?: () => void;
  onMouseOverRow?: (e: MouseEvent) => void;
  onMouseLeaveRow?: (e: MouseEvent) => void;
}

export interface ColumnWidthsState {
  [id: string]: number;
}

export interface ColumnWidthsAction {
  type: 'UPDATE';
  update: ColumnWidthsState;
}

interface VTable2Props<
  DataType extends object,
  DataToForceRender extends object
> {
  id?: React.Key;
  className?: string;
  headerColumnClassname?: string;
  data: DataType[];
  selected?: (item: DataType) => boolean;
  disabled?: (item: DataType) => boolean;
  loading?: LoadingProps;
  noItemsScreen?: JSX.Element;
  columns: VColumn<DataType>[];
  rowsAreSelectable?: boolean;
  overscanRowCount?: number;
  columnsResizable?: boolean;
  dataToForceRender?: DataToForceRender;
  onDrop?: {};
  classNameTableRow?: string | ((item: DataType) => string);
  columnRowHeight?: number;
  rowHeight?: number;
  onRow?: OnRowProps<DataType>;
  onScroll?: (scrollParams: ScrollParams) => void;
  onChange?: (items: DataType[]) => void;
  noHeaders?: boolean;
}

const VirtualTable2 = <
  DataType extends object,
  DataToForceRender extends object
>({
  className,
  id: tableId,
  data,
  columns: initialColumnData,
  rowsAreSelectable,
  overscanRowCount,
  onRow,
  loading,
  noItemsScreen,
  columnsResizable,
  dataToForceRender: initialDataToForceRender,
  noHeaders,
  selected,
  disabled,
  onScroll: handleOnScrollFromParent,
  onChange,
  classNameTableRow,
  columnRowHeight: initialColumnRowHeight = 50,
  rowHeight = 50,
  headerColumnClassname,
  ...props
}: VTable2Props<DataType, DataToForceRender>) => {
  const classes = useStyles(props);

  //#region -------------------------------- Attributes
  //#endregion

  //#region -------------------------------- Selectors
  //#endregion

  //#region -------------------------------- Refs
  const vListRef = useRef<List>(null);
  //#endregion

  //#region -------------------------------- Reducers
  const [columnWidths, dispatchColumnWidths] = useReducer<
    Reducer<ColumnWidthsState, ColumnWidthsAction>
  >(
    (state: ColumnWidthsState, action: ColumnWidthsAction) => {
      const { update } = action;
      return {
        ...state,
        ...update,
      };
    },
    initialColumnData.reduce(
      (map, item) => ({
        ...map,
        [item.id]: item.width,
      }),
      {}
    )
  );
  //#endregion

  //#region -------------------------------- States
  const [dataToForceRenderColumns, setDataToForceRenderColumns] =
    useState<DataToForceRender>(null);

  const [columns, setColumns] =
    useState<VColumn<DataType>[]>(initialColumnData);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<DataType>(
      {
        //TODO:Ignoring Type mismatch Error because we ovveride the default behavour of the accessor property
        //@ts-ignore
        columns: columns ? columns : [],
        //defaultColumn,
        data,
      },
      useSortBy
    );

  const [selectedRows, setSelectedRows] = useState<Row<DataType>[]>([]);

  const [disabledRows, setDisabledRows] = useState<Row<DataType>[]>([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onSelectRow = (event: CheckboxChangeEvent, row: Row<DataType>) => {
    if (event.target.checked) {
      let _rows = [...selectedRows, row];
      if (disabled) {
        _rows = _rows.filter((x) => !disabled(x.original));
      }
      setSelectedRows(_rows);
      onChange(_rows.map((x) => x.original));
    } else {
      setSelectedRows([...selectedRows.filter((x) => x.id !== row.id)]);
      onChange(
        [...selectedRows.filter((x) => x.id !== row.id)].map((x) => x.original)
      );
    }
  };

  const handleSelectAll = (value: boolean) => {
    if (value) {
      let _rows = rows;
      if (disabled) {
        _rows = _rows.filter((x) => !disabled(x.original));
      }
      setSelectedRows(_rows);
      onChange(_rows.map((x) => x.original));
    } else {
      setSelectedRows([]);
      onChange([]);
    }
  };

  const calculateRowHeight: (value: { index: number }) => number = ({
    index,
  }) => {
    if (
      index === rows.length - 1 &&
      loading &&
      loading.loadingType === 'footer' &&
      loading.indicator
    ) {
      return 150;
    }
    return rowHeight;
  };

  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    if (vListRef.current) {
      vListRef.current.recomputeRowHeights();
      vListRef.current.forceUpdate();
      vListRef.current.forceUpdateGrid();
    }
  }, [rowHeight]);

  useEffect(() => {
    if (selected) {
      setSelectedRows(rows.filter((x) => selected(x.original)));
    }
  }, [selected, rows]);

  useEffect(() => {
    if (disabled) {
      setDisabledRows(rows.filter((x) => disabled(x.original)));
    }
  }, [disabled, rows]);

  useEffect(() => {
    if (!equals(dataToForceRenderColumns, initialDataToForceRender)) {
      setDataToForceRenderColumns(initialDataToForceRender);
      setColumns(initialColumnData);
    }
  }, [dataToForceRenderColumns, initialDataToForceRender, initialColumnData]);
  //#endregion

  return (
    <div
      {...getTableProps()}
      className={classNames(classes.root, className)}
      id={tableId as string}
    >
      {!noHeaders && (
        <VirtualTableHeader
          className={headerColumnClassname}
          columnRowHeight={initialColumnRowHeight}
          headerGroups={headerGroups}
          rowsAreSelectable={rowsAreSelectable}
          selectedRowsLength={selectedRows.length}
          itemsLength={
            disabled ? rows.length - disabledRows.length : rows.length
          }
          columnsResizable={columnsResizable}
          onSelectAll={handleSelectAll}
          tableId={tableId}
          columnWidths={columnWidths}
          setColumnWidths={dispatchColumnWidths}
        />
      )}
      <VirtualTableBody
        rows={rows}
        tableBodyProps={getTableBodyProps()}
        loading={loading}
        noItemsScreen={noItemsScreen}
        overscanRowCount={overscanRowCount}
        rowHeight={calculateRowHeight}
        onScroll={handleOnScrollFromParent}
        setVlistRef={(ref) => (vListRef.current = ref)}
        rowRenderer={(listRowProps: ListRowProps) => {
          const { index, style } = listRowProps;
          const row = rows[index];
          const addLoadingFooter =
            index === rows.length - 1 &&
            !loading?.indicatorAsOverlay &&
            loading?.loadingType === 'footer';
          return (
            <>
              <VirtualTableRow
                style={{
                  ...style,
                  ...(addLoadingFooter ? { height: rowHeight } : {}),
                }}
                className={classNameTableRow}
                row={row}
                onRow={onRow}
                isSelectable={rowsAreSelectable}
                selectedRows={selectedRows}
                disabledRows={disabledRows}
                onSelectRow={onSelectRow}
                prepareRow={prepareRow}
                renderCell={(row, cell) => (
                  <VirtualTableCell
                    className={cell.column.className}
                    tableId={tableId}
                    row={row}
                    cell={cell}
                    width={columnWidths[cell.column.id] ?? 0}
                  />
                )}
              />
              {addLoadingFooter ? (
                <div
                  style={{
                    position: 'absolute',
                    height: 100,
                    left: 0,
                    bottom: 0,
                    width: '100%',
                  }}
                >
                  {loading?.indicator ?? <PrioSpinner alignSelf />}
                </div>
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
};

export default VirtualTable2;
