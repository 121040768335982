import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Button } from '@prio365/prio365-react-library';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PrioSpinner from '../../../components/PrioSpinner';

import {
  getCompany,
  getCompanyRedirect,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import CompanyDetails from './CompanyDetails';
import { makePrioStyles } from '../../../theme/utils';
import { Company } from '../../../models/Company';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  companyDetails: {
    height: '100%',
  },
}));
interface CompanyDetailsPageProps {
  noPadding?: boolean;
  backButton?: boolean;
}

export const CompanyDetailsPage: React.FC<CompanyDetailsPageProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const company = useSelector<RootReducerState, Company>((state) =>
    getCompany(state, companyId)
  );
  const redirect = useSelector<RootReducerState, string>((state) =>
    getCompanyRedirect(state, companyId)
  );
  const navigate = useNavigate();

  const { noPadding, backButton } = props;

  if (redirect) {
    return <Navigate to={`../../${redirect}/details`} />;
  }

  const goBack = () => navigate(-1);

  if (company) {
    return (
      <>
        {backButton && (
          <Button
            onClick={goBack}
            type="link"
            iconProp={['fal', 'chevron-left']}
          >
            {t('common:back')}
          </Button>
        )}
        <CompanyDetails
          company={company}
          className={classNames(classes.companyDetails, {
            [classes.noPadding]: noPadding,
          })}
        />
      </>
    );
  }

  return (
    <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
      <PrioSpinner size="large" />
    </div>
  );
};

export default CompanyDetailsPage;
