import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import {
  CalendarEvent,
  TimeRange,
  TimeTrackingSuggestion,
} from '../../../models/Calendar';
import {
  ProjectId,
  CalendarEventId,
  MessageId,
  DateTimeString,
} from '../../../models/Types';
import fetchWithRetry from '../../../util/fetchWithRetry';

const eventControllerGenerator: (projectId: string) => string = (projectId) => {
  return projectId === 'me' ? 'EventMe' : `project/${projectId}/Event`;
};

export const apiFetchCalendarEventsByProjectId: (
  projectId: ProjectId,
  range?: TimeRange,
  signal?: AbortSignal
) => Promise<ApiResult<CalendarEvent[]>> = async (projectId, range, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/Calendar/${projectId}${
      range ? `?from=${range.from}&to=${range.to}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateCalendarEvent: (
  projectId: ProjectId,
  event: CalendarEvent
) => Promise<ApiResult<CalendarEvent>> = async (projectId, event) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/Calendar/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(event),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteCalendarEvent: (
  projectId: ProjectId,
  calendarEventId: CalendarEventId
) => Promise<ApiResult<void>> = async (projectId, calendarEventId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/Calendar/${projectId}/${calendarEventId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiGetEvent: (
  projectId: ProjectId,
  eventId: CalendarEventId
) => Promise<ApiResult<CalendarEvent>> = async (projectId, eventId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/${eventControllerGenerator(projectId)}/${eventId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiAcceptEvent: (
  projectId: ProjectId,
  messageId: MessageId,
  event: CalendarEvent,
  comment?: string
) => Promise<boolean> = async (projectId, messageId, event, comment) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/${eventControllerGenerator(projectId)}/${
      event.id
    }/accept`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        ...(comment ? { comment } : {}),
        sendResponse: event.responseRequested,
        messageIdSoftDelete: messageId,
      }),
    }
  );

  return result.status >= 200 && result.status < 300;
};

export const apiDeclineEvent: (
  projectId: ProjectId,
  messageId: MessageId,
  event: CalendarEvent,
  comment?: string
) => Promise<boolean> = async (projectId, messageId, event, comment) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/${eventControllerGenerator(projectId)}/${
      event.id
    }/decline`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        ...(comment ? { comment } : {}),
        sendResponse: event.responseRequested,
        messageIdSoftDelete: messageId,
      }),
    }
  );

  return result.status >= 200 && result.status < 300;
};

export const apiTentativelyAcceptEvent: (
  projectId: ProjectId,
  messageId: MessageId,
  event: CalendarEvent,
  comment?: string
) => Promise<boolean> = async (projectId, messageId, event, comment) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/${eventControllerGenerator(projectId)}/${
      event.id
    }/tentativelyAccept`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        ...(comment ? { comment } : {}),
        sendResponse: event.responseRequested,
        messageIdSoftDelete: messageId,
      }),
    }
  );

  return result.status >= 200 && result.status < 300;
};

export const apiForwardEvent: (
  projectId: ProjectId,
  event: CalendarEvent,
  recipientAddress: string[],
  comment?: string
) => Promise<boolean> = async (projectId, event, recipientAddress, comment) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/${eventControllerGenerator(projectId)}/${
      event.id
    }/forward`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        recipientAddress,
        ...(comment ? { comment } : {}),
      }),
    }
  );

  return result.status >= 200 && result.status < 300;
};

export const apiDeleteEvent: (
  projectId: ProjectId,
  event: CalendarEvent
) => Promise<boolean> = async (projectId, event) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/${eventControllerGenerator(projectId)}/${
      event.id
    }/delete`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({}),
    }
  );

  return result.status >= 200 && result.status < 300;
};

export const apiFetchTimeTrackingSuggestions: (
  from: DateTimeString,
  to: DateTimeString
) => Promise<ApiResult<TimeTrackingSuggestion[]>> = async (from, to) => {
  const result = await fetchWithRetry(
    `${apiUrl}/calendar/Calendar/timeTrackingSuggestions?from=${from}&to=${to}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
