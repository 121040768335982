import { PRIO } from '../../../../constants';
import { MailMenuNavigationState } from '../types';

export const UPDATE_MAIL_LIST_NAVIGATION_PROJECTS =
  PRIO + 'UPDATE_MAIL_LIST_NAVIGATION_PROJECTS';

export const setMailListNavigationStateProjects = (
  activeMessageId: string
) => ({
  type: UPDATE_MAIL_LIST_NAVIGATION_PROJECTS,
  activeMessageId,
});

export const UPDATE_MAIL_MENU_NAVIGATION_PROJECTS =
  PRIO + 'UPDATE_MAIL_MENU_NAVIGATION_PROJECTS';

export const setMailMenuNavigationStateProjects = (
  menuState: MailMenuNavigationState
) => ({
  type: UPDATE_MAIL_MENU_NAVIGATION_PROJECTS,
  menuState,
});
