import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Divider, List, Typography } from 'antd';
import { makePrioStyles } from '../../../../../theme/utils';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { apiFetchGlobalProjectSetting } from '../../../../settings/api';
import { GlobalProjectSetting } from '../../../../../models/Configuration';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import { useParams } from 'react-router-dom';
import { Project, ProjectMailSetting } from '../../../../../models/Project';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getContact,
  getProject,
  getUserMeContactId,
} from '../../../../../apps/main/rootReducer';
import { ContactId, GlobalProjectSettingId } from '../../../../../models/Types';
import { Contact } from '../../../../../models/Contact';
import { apiFetchProjectCompanyMailSignature } from '../../../api';
import OfficePicker from '../../../../companies/components/OfficePicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../../theme/types';
import { apiFetchProjectMailSetting } from '../../../../projects/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  signatureList: {
    overflow: 'auto',
  },
  signatureListContainer: {
    width: '30%',
  },
  signatureTypeTitle: {
    color: 'lightgray',
  },
  projectSignatureContainer: {
    userSelect: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  officePickerContainer: {
    marginTop: '5px',
    marginBottom: '25px',
  },
  signatureTitle: { marginLeft: '10px' },
  preview: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    borderRadius: theme.old.borders.radius,
    border: theme.old.borders.sub,
    overflow: 'auto',
  },
  divider: {
    marginRight: '30px',
    marginLeft: '30px',
    height: 'inherit',
  },
  listItem: {
    cursor: 'pointer',
    userSelect: 'none',
    paddingTop: '0px',
    paddingBottom: '0px',
    height: '40px',
    transition: '0.2s',

    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },

  noSignatureSeceltedBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  listItemContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
}));

interface SignaturePickerProps {
  className?: string;
  onSelect?: (value: string) => void;
}

export const SignaturePicker: React.FC<SignaturePickerProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, onSelect } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { projectId } = useParams();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const currentUserId = useSelector<RootReducerState, ContactId>(
    getUserMeContactId
  );
  const currentUser = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, currentUserId)
  );

  const [globalsProjectSettings, setGlobalsProjectSettings] = useState<
    GlobalProjectSetting[]
  >([]);
  const [globalProjectSettingsLoading, setGlobalProjectSettingsLoading] =
    useState<boolean>(false);

  const [signatureIsFormating, setSignatureIsFormating] =
    useState<boolean>(false);

  const [selectedGlobalsProjectSetting, setSelectedGlobalsProjectSetting] =
    useState<GlobalProjectSetting | ProjectMailSetting>();

  const [selectedGlobalsProjectSettingId, setSelectedGlobalsProjectSettingId] =
    useState<GlobalProjectSettingId>();

  const [projectMailSetting, setProjectMailSetting] =
    useState<ProjectMailSetting>();

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>(
    currentUser.officeId
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onSignatureSelect = (
    item: GlobalProjectSetting | ProjectMailSetting
  ) => {
    setSignatureIsFormating(true);
    setSelectedGlobalsProjectSettingId(
      item['useProjectSetting']
        ? item['projectSettingId']
        : item.globalProjectSettingId
    );
    Promise.resolve(
      apiFetchProjectCompanyMailSignature(
        projectId,
        selectedOfficeId,
        item.globalProjectSettingId,
        item['useProjectSetting'] ? true : false
      ).then((result) => {
        const { data } = result;
        setSelectedGlobalsProjectSetting({
          ...item,
          objectJson: {
            value: data,
          },
        });
        onSelect(data);
        setSignatureIsFormating(false);
      })
    );
  };

  const onOfficePick = (officeId: string) => {
    setSelectedOfficeId(officeId);

    if (selectedGlobalsProjectSetting) {
      setSignatureIsFormating(true);
      Promise.resolve(
        apiFetchProjectCompanyMailSignature(
          projectId,
          officeId,
          selectedGlobalsProjectSetting.globalProjectSettingId,
          selectedGlobalsProjectSetting['useProjectSetting'] ? true : false
        ).then((result) => {
          const { data } = result;
          setSelectedGlobalsProjectSetting({
            ...selectedGlobalsProjectSetting,
            objectJson: {
              value: data,
            },
          });
          onSelect(data);
          setSignatureIsFormating(false);
        })
      );
    }
  };
  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    const fetchGlobalProjectSettings = async () => {
      setGlobalProjectSettingsLoading(true);
      const { data } = await apiFetchGlobalProjectSetting('emailSignature');
      setGlobalsProjectSettings(data);

      const { data: projectMailSetting } = await apiFetchProjectMailSetting(
        projectId,
        'emailSignature'
      );
      setProjectMailSetting(projectMailSetting);

      setGlobalProjectSettingsLoading(false);
    };
    fetchGlobalProjectSettings();
  }, [projectId]);

  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.signatureListContainer}>
        <LoadingOverlay
          isLoading={globalProjectSettingsLoading}
          bodyStyles={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography.Text italic className={classes.signatureTypeTitle}>
            {t('mail:modals.insertSignature.officePicker')}
          </Typography.Text>
          <div className={classes.officePickerContainer}>
            <OfficePicker
              value={selectedOfficeId}
              onChange={onOfficePick}
              onlyInternal
            />
          </div>

          {projectId && (
            <>
              <Typography.Text italic className={classes.signatureTypeTitle}>
                {t('mail:modals.insertSignature.projectSignature')}
              </Typography.Text>

              {projectMailSetting && projectMailSetting.useProjectSetting ? (
                <div
                  className={classes.listItem}
                  style={{
                    backgroundColor:
                      selectedGlobalsProjectSettingId ===
                        projectMailSetting?.projectSettingId &&
                      theme.old.palette.backgroundPalette.active.main,
                  }}
                  onClick={() => {
                    onSignatureSelect(projectMailSetting);
                  }}
                >
                  <div className={classes.listItemContent}>
                    <span
                      className={classes.signatureTitle}
                      style={{
                        color:
                          selectedGlobalsProjectSettingId ===
                            projectMailSetting?.projectSettingId &&
                          theme.old.palette.primaryColor,
                      }}
                    >
                      {project.name}
                    </span>
                  </div>
                </div>
              ) : (
                <div className={classes.projectSignatureContainer}>
                  <span className={classes.signatureTitle}>
                    {t('mail:modals.insertSignature.noSignatureConfigured')}
                  </span>
                </div>
              )}
            </>
          )}

          <Typography.Text italic className={classes.signatureTypeTitle}>
            {t('mail:modals.insertSignature.globalSignature')}
          </Typography.Text>
          <List
            className={classes.signatureList}
            dataSource={globalsProjectSettings}
            renderItem={(item) => (
              <List.Item
                className={classes.listItem}
                style={{
                  borderLeft: 4,
                  backgroundColor:
                    selectedGlobalsProjectSettingId ===
                      item?.globalProjectSettingId &&
                    selectedGlobalsProjectSetting &&
                    !('projectSettingId' in selectedGlobalsProjectSetting) &&
                    theme.old.palette.backgroundPalette.active.main,
                }}
                onClick={() => {
                  onSignatureSelect(item);
                }}
              >
                <div className={classes.listItemContent}>
                  <span
                    className={classes.signatureTitle}
                    style={{
                      color:
                        selectedGlobalsProjectSettingId ===
                          item?.globalProjectSettingId &&
                        theme.old.palette.primaryColor,
                    }}
                  >
                    {item.displayName}
                  </span>
                </div>
              </List.Item>
            )}
          />
        </LoadingOverlay>
      </div>

      <Divider type="vertical" className={classes.divider} />
      {selectedGlobalsProjectSetting ? (
        <LoadingOverlay isLoading={signatureIsFormating}>
          <div className={classes.preview}>
            <FroalaEditorView
              model={selectedGlobalsProjectSetting.objectJson.value}
            />
          </div>
        </LoadingOverlay>
      ) : (
        <div className={classes.noSignatureSeceltedBox}>
          <Typography.Text>
            {t('mail:modals.insertSignature.selectSignature')}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default SignaturePicker;
