import { DriveItem, DriveItemDropTarget } from '../../models/Drive';
import { MailFolder } from '../../models/MailFolder';
import { Message, MessageAttachment } from '../../models/Message';
import { Project } from '../../models/Project';
import {
  DriveItemId,
  GroupId,
  MailFolderId,
  ProjectId,
} from '../../models/Types';
import { DraggableMessageAttachment } from '../../modules/mail/components/SelectableMessageAttachmentItem';

export const DND_TYPE_EMAIL = 'DND_TYPE_EMAIL';
export const DND_TYPE_EMAIL_FOLDER = 'DND_TYPE_EMAIL_FOLDER';
export const DND_TYPE_EMAIL_ATTACHMENT = 'DND_TYPE_EMAIL_ATTACHMENT';
export const DND_TYPE_DRIVE_ITEM_FILE = 'DND_TYPE_DRIVE_ITEM_FILE';
export const DND_TYPE_EMAIL_ADDRESS = 'DND_TYPE_EMAIL_ADDRESS';

export interface DndEmailDto {
  message: Message;
  selectedMessages: Message[];
  mailFolderId: MailFolderId;
  isPersonal?: boolean;
}

export interface DndMailAddressDto {
  value: string;
  label: React.ReactNode;
  closable?: boolean;
  selectedValues: string[];
}

export interface DndEmailDto {
  message: Message;
  selectedMessages: Message[];
  mailFolderId: MailFolderId;
  isPersonal?: boolean;
}

export interface DndEmailFolderDto {
  mailFolder: MailFolder;
}
export interface DndEmailAttachmentDto {
  draggableMessageAttachment?: DraggableMessageAttachment;
  selectedAttachments?: MessageAttachment[];
}
export interface DndDriveItemDto {
  driveItem: DriveItem;
  driveItems?: DriveItem[];
  groupId: GroupId;
  projectId: ProjectId;
  sourceFolderId: string;
  root: {
    sourceFolderRootId: string;

    sourceIsRoot: boolean;
  };
}

export interface DroppedAttachmentDto {
  attachment: DraggableMessageAttachment;
  targetDriveItem: DriveItem;
  groupId: GroupId;
  selectedAttachments?: MessageAttachment[];
}

export interface DropResult {
  dropEffect: 'copy' | 'move';
  [props: string]: any;
}

export interface DndDriveItemDtoDragResult extends DndDriveItemDto {
  destinationProject: Project;
  destinationDriveItemId: DriveItemId;
  destinationDriveItemIsRoot?: boolean;
  sourceProjectId: ProjectId;
  dropTargetType: 'project' | 'remoteItem' | 'driveItem';
}

export interface DndDriveItemDropResult extends DriveItemDropTarget {
  dropEffect: 'copy' | 'move';
}
