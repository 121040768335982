import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { DriveItemId } from '../../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useSelector } from 'react-redux';
import {
  getCurrentFolderChildren,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { DriveItem } from '../../../../models/Drive';
import BreadcrumbDriveItemMenu from './BreadcrumbDriveItemMenu';
import PrioSpinner from '../../../../components/PrioSpinner';
import { isDriveItemFolder } from '../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .anticon.anticon-down': {
      fontSize: 10,
      lineHeight: 1,
      marginLeft: theme.old.spacing.unit(0.5),
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  breadcrumbSeparator: {
    margin: '0px 4px',
    color: 'rgba(0,0,0,0.45)',
  },
}));

declare const Placements: [
  'topLeft',
  'topCenter',
  'topRight',
  'bottomLeft',
  'bottomCenter',
  'bottomRight',
];
declare type Placement = (typeof Placements)[number];

interface DriveItemBreadcrumbItemProps {
  className?: string;
  driveItemId: DriveItemId;
  clickable?: boolean;
  children: ReactNode;
  overlay?: ReactElement;
  placementOverlay?: Placement;
  onClick?: (driveItemId: DriveItemId) => void;
  key?: number | string;
  title?: string;
  copyDropDown?: (setOpenCopyMenu: (value: boolean) => void) => JSX.Element;
  pathPrefix?: string;
  activeDriveItemId?: DriveItemId;
  disableLinkNavigation?: boolean;
  onBreadcrumbClick?: (driveItemId: DriveItemId) => void;
  handleReloadDocuments?: () => void;
  isReloadingDocuments?: boolean;
  isNotLastBreadcrumbItem?: boolean;
}

export const DriveItemBreadcrumbItem: React.FC<DriveItemBreadcrumbItemProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    driveItemId,
    clickable,
    children,
    placementOverlay,
    onClick,
    key,
    title,
    copyDropDown,
    pathPrefix,
    activeDriveItemId,
    disableLinkNavigation,
    onBreadcrumbClick,
    handleReloadDocuments,
    isReloadingDocuments,
    isNotLastBreadcrumbItem,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const childFolders = useSelector<RootReducerState, DriveItem[]>(
    (state) =>
      getCurrentFolderChildren(state, driveItemId)?.filter((item) =>
        isDriveItemFolder(item)
      )
  );

  const [openFolderDropdown, setOpenFolderDropdown] = useState<boolean>(false);
  const [openLinkMenu, setOpenLinkMenu] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick(driveItemId);
    }
  };
  //#endregion

  //#region ------------------------------ Components
  const overlay = useMemo(
    () =>
      activeDriveItemId && (
        <BreadcrumbDriveItemMenu
          driveItemId={driveItemId}
          pathPrefix={pathPrefix}
          activeItemId={activeDriveItemId}
          disableLinkNavigation={disableLinkNavigation}
          onItemClick={(driveItemId) => {
            setOpenFolderDropdown(false);
            if (onBreadcrumbClick) {
              onBreadcrumbClick(driveItemId);
            }
          }}
        />
      ),
    [
      driveItemId,
      pathPrefix,
      activeDriveItemId,
      disableLinkNavigation,
      onBreadcrumbClick,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <span key={key} style={{ height: '25px', paddingTop: '1px' }}>
      {overlay && childFolders?.length > 1 ? (
        <Dropdown
          overlay={overlay}
          placement={placementOverlay ?? 'bottomRight'}
          trigger={['click']}
          overlayStyle={{ width: '25vw', minWidth: 'unset' }}
          onVisibleChange={setOpenFolderDropdown}
          visible={openFolderDropdown}
        >
          <span
            className={classNames(classes.root, className, {
              [classes.clickable]: clickable,
            })}
          >
            <span onClick={handleOnClick} title={title}>
              {children}
            </span>
            <DownOutlined />
          </span>
        </Dropdown>
      ) : (
        <span
          className={classNames(classes.root, className, {
            [classes.clickable]: clickable,
          })}
        >
          <span onClick={handleOnClick} title={title}>
            {children}
          </span>
        </span>
      )}
      {copyDropDown && (
        <Dropdown
          overlay={copyDropDown(setOpenLinkMenu)}
          placement={placementOverlay ?? 'bottomRight'}
          visible={openLinkMenu}
          onVisibleChange={setOpenLinkMenu}
          trigger={['click']}
        >
          <FontAwesomeIcon
            icon={['fal', 'link']}
            style={{
              marginLeft: theme.old.spacing.unit(0.5),
              cursor: 'pointer',
            }}
            color={theme.old.typography.colors.muted}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          />
        </Dropdown>
      )}
      {copyDropDown &&
        (!isReloadingDocuments ? (
          <FontAwesomeIcon
            icon={['fal', 'redo']}
            style={{
              marginLeft: theme.old.spacing.unit(0.5),
              cursor: 'pointer',
            }}
            color={theme.old.typography.colors.muted}
            onClick={handleReloadDocuments}
          />
        ) : (
          <div>
            <PrioSpinner size="small" alignSelf></PrioSpinner>
          </div>
        ))}
      {isNotLastBreadcrumbItem && (
        <span className={classes.breadcrumbSeparator}>/</span>
      )}
    </span>
  );
};

export default DriveItemBreadcrumbItem;
