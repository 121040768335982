import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
  },
  easterEgg: {
    position: 'relative',
    transformOrigin: 'bottom',
    animation: `$wiggle 2s forwards`,
  },
  egg: {
    position: 'relative',
    width: 160,
    height: 80,
    backgroundColor: 'rgba(209,66,125,1)',
    top: 90,
    borderRadius: '0% 100% 50% 50%/ 0% 0% 100% 100%',
    '&:before': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: 'transparent transparent rgba(230,51,56,1) transparent',
      borderWidth: '0 20px 20px 0',
      top: -20,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: 'transparent transparent rgba(230,51,56,1) transparent',
      borderWidth: '0 0 20px 20px',
      top: -20,
      left: 140,
    },
  },
  eggBottom: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '0% 100% 50% 50%/ 0% 0% 100% 100%',
    overflow: 'hidden',
  },
  shellStripe: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 20,
    backgroundColor: 'rgba(230,51,56,1)',
  },
  shellStripeTop: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
    backgroundColor: 'rgba(230,51,56,1)',
  },
  shellStripeTop2: {
    position: 'absolute',
    bottom: 19,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: 'rgba(232,70,47,1)',
  },
  shell: {
    position: 'absolute',
    '&:before': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: 'transparent transparent rgba(230,51,56,1) transparent',
      borderWidth: '0 20px 20px 20px',
      top: -19,
      left: 20,
      filter:
        'drop-shadow(40px 0 rgba(230,51,56,1)) drop-shadow(40px 0 rgba(230,51,56,1))',
    },
  },
  eggTop: {
    position: 'absolute',
    width: 160,
    height: 120,
    backgroundColor: 'rgba(243,147,37,1)',
    top: -44,
    left: 0,
    borderRadius: '50% 50% 70% 30%/ 100% 100% 0 0',
    transformOrigin: 'left',
    '&:before': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: 'rgba(230,51,56,1) transparent transparent transparent',
      borderWidth: '20px 20px 0 20px',
      top: 119,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'rgba(255,255,255,0.2)',
      width: 25,
      height: 60,
      borderRadius: '50%',
      transform: 'rotate(-27deg)',
      top: 30,
      left: 115,
    },
    animation: `$open 0.5s ease forwards 2s`,
  },
  eggTop2: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '50% 50% 70% 30%/ 100% 100% 0 0',
    overflow: 'hidden',
  },
  shellTop: {
    position: 'absolute',
    borderStyle: 'solid',
    borderColor: 'rgba(230,51,56,1) transparent transparent transparent',
    borderWidth: '20px 20px 0 20px',
    left: 40,
    top: 119,
    '&:before': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: 'rgba(230,51,56,1) transparent transparent transparent',
      borderWidth: '20px 20px 0 20px',
      top: -20,
      left: 20,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: 'rgba(230,51,56,1) transparent transparent transparent',
      borderWidth: '20px 20px 0 20px',
      top: -20,
      left: 60,
    },
  },
  shadow: {
    position: 'absolute',
    width: 220,
    height: 30,
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '50%',
    zIndex: -1,
    top: 147,
    left: -27,
  },
  yolk: {
    position: 'absolute',
    zIndex: -1,
    width: 90,
    height: 90,
    backgroundColor: 'rgba(243,147,37,1)',
    borderRadius: '50%',
    top: 0,
    left: 35,
    '&:before': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#333',
      width: 7,
      height: 10,
      borderRadius: '50%',
      top: 35,
      left: 30,
      boxShadow: '20px 0 #333',
      transformOrigin: '50%',
      animation: '$blink 2.5s infinite',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      backgroundColor: '#ffce99',
      width: 20,
      height: 10,
      borderRadius: '0 0 100px 100px',
      top: 50,
      left: 33,
    },
    animation: '$yolk_up 0.5s ease forwards 2s',
  },
  yolkBody: {
    position: 'absolute',
    width: 90,
    height: 90,
    backgroundColor: 'rgba(243,147,37,1)',
    borderRadius: '50%',
    top: 75,
  },
  text: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 15,
    animation: '$text_up 0.5s ease forwards 2s',
  },
  signature: {
    position: 'absolute',
    bottom: 'calc(50% - 192px)',
    width: '100%',
    textAlign: 'center',
    opacity: 0,
    whiteSpace: 'nowrap',
    animation: '$fade_in 0.5s ease forwards 2s',
  },
  '@keyframes blink': {
    '0%': {
      transform: 'scale(1,0.5)',
    },
    '5%': {
      transform: 'scale(1,1)',
    },
    '95%': {
      transform: 'scale(1,1)',
    },
    '100%': {
      transform: 'scale(1,0.5)',
    },
  },
  '@keyframes yolk_up': {
    '0%': {
      top: 0,
    },
    '100%': {
      top: -20,
    },
  },
  '@keyframes text_up': {
    '0%': {
      top: 0,
    },
    '100%': {
      top: -150,
    },
  },
  '@keyframes wiggle': {
    '0%': {
      transform: 'rotate(0)',
    },
    '20%': {
      transform: 'rotate(-20deg)',
    },
    '40%': {
      transform: 'rotate(20deg)',
    },
    '60%': {
      transform: 'rotate(-20deg)',
    },
    '80%': {
      transform: 'rotate(20deg)',
    },
    '85%': {
      transform: 'rotate(-20deg)',
    },
    '90%': {
      transform: 'rotate(20deg)',
    },
    '95%': {
      transform: 'rotate(-20deg)',
    },
    '99%': {
      transform: 'rotate(20deg)',
    },
    '100%': {
      transform: 'rotate(0)',
    },
  },
  '@keyframes open': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(-220deg)',
      top: 0,
      left: -15,
    },
  },
  '@keyframes fade_in': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0.5,
    },
  },
}));

interface HappyEasterProps {}

export const HappyEaster: React.FC<HappyEasterProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      <div className={classes.root}>
        <div className={classes.easterEgg}>
          <div className={classes.egg}>
            <div className={classes.eggBottom}>
              <div className={classes.shellStripe} />
            </div>
            <div className={classes.shell} />
          </div>
          <div className={classes.eggTop}>
            <div className={classes.eggTop2}>
              <div className={classes.shellStripeTop} />
              <div className={classes.shellStripeTop2} />
            </div>
            <div className={classes.shellTop} />
          </div>
          <div className={classes.yolk}>
            <div className={classes.yolkBody} />
          </div>
          <Typography.Title className={classes.text} style={{ fontSize: 41 }}>
            {t('doodle:happyEaster.titleHappy')}
            <br />
            {t('doodle:happyEaster.titleEaster')}
          </Typography.Title>
        </div>
        <div className={classes.shadow} />
      </div>
      <Typography.Text className={classes.signature}>
        {t('doodle:signature')}
      </Typography.Text>
    </>
  );
};

export default HappyEaster;
