import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Select, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { rowGutter } from '../../../util/forms';
import { useDispatch } from 'react-redux';
import { openZendeskDrawer } from '../actions/drawer';
import { apiCreateMessageDraft, apiUpdateMessageDraft } from '../../mail/api';
import * as ConfigValues from '../../../util/configValues';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px 0`,
  },
  openTicketButton: {
    width: '100%',
  },
}));

interface ZendeskWidgetDetailsProps {}

export const ZendeskWidgetReport: React.FC<ZendeskWidgetDetailsProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isCreating, setIsCreating] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOpenSupportTicket = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    doShowNewReportMessage();
  };

  const doShowNewReportMessage = async () => {
    setIsCreating(true);
    const { data: message } = await apiCreateMessageDraft('me');
    if (message) {
      message.toRecipients = [
        {
          emailAddress: {
            name: '',
            address: ConfigValues.SUPPORT_EMAIL,
          },
        },
      ];
      message.subject =
        t('zendesk:types.' + form.getFieldValue('type')) + ' - ';
      await apiUpdateMessageDraft('me', message.id, message);
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight * 0.6;
      window.open(
        `/view/me/composer/${message.id}`,
        '_blank',
        `width=${width},height=${height},top=${height / 4}`
      );

      dispatch(openZendeskDrawer(false));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }
    setIsCreating(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.resetFields();
  }, [form]);
  //#endregion

  return (
    <Form className={classes.root} layout="vertical" form={form}>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="type"
            label={t('zendesk:form.labels.type')}
            initialValue={'question'}
            rules={[
              {
                required: true,
                message: t('zendesk:form.validation.missingType'),
              },
            ]}
          >
            <Select>
              <Select.Option value={'question'} key={'question'}>
                {t('zendesk:types.question')}
              </Select.Option>
              <Select.Option value={'featurerequest'} key={'featurerequest'}>
                {t('zendesk:types.featurerequest')}
              </Select.Option>
              <Select.Option value={'incident'} key={'incident'}>
                {t('zendesk:types.incident')}
              </Select.Option>
              <Select.Option value={'enhancement'} key={'enhancement'}>
                {t('zendesk:types.enhancement')}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Button
            size={'large'}
            className={classes.openTicketButton}
            onClick={handleOpenSupportTicket}
            loading={isCreating}
            disabled={isCreating}
          >
            {t('zendesk:form.actions.send')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ZendeskWidgetReport;
