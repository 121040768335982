import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { AbsenceTabs } from '../actions/drawer';
import { NewAbsenceProposal } from './NewAbsenceProposal';
import {
  AbsenceOverview,
  AbsenceProposal,
} from '../../../models/AbsenceProposal';
import { MyAbsencesOverview } from './MyAbsencesOverview';
import { makePrioStyles } from '../../../theme/utils';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& .ant-tabs-content-holder': {
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  notScrollable: {
    overflow: 'hidden',
    height: '100%',
  },
}));

interface AbsenceTabsProps {
  activeKey?: AbsenceTabs;
  onChange?: (activeKey: AbsenceTabs) => void;
  onAbsenceClick?: (absenceProposal: AbsenceProposal) => void;
  absenceOverview: AbsenceOverview;
}

export const GlobalAbsenceDrawerTabs: React.FC<AbsenceTabsProps> = (props) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeKey, onChange, onAbsenceClick, absenceOverview } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onSuccessfulFormSubmit = () => {
    onChange('overview');
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Tabs
      animated={false}
      defaultActiveKey="new"
      activeKey={activeKey}
      onChange={onChange}
      className={classes.root}
    >
      <TabPane tab={t('absences:taskPane.tabs.new')} key="new">
        <div className={classes.notScrollable}>
          <NewAbsenceProposal
            absenceOverview={absenceOverview}
            onFinish={onSuccessfulFormSubmit}
          />
        </div>
      </TabPane>
      <TabPane tab={t('absences:taskPane.tabs.overview')} key="overview">
        <MyAbsencesOverview
          absenceOverview={absenceOverview}
          onAbsenceProposalSelect={onAbsenceClick}
        />
      </TabPane>
    </Tabs>
  );
};

export default GlobalAbsenceDrawerTabs;
