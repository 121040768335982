import { combineReducers } from 'redux';
import { ZendeskDrawerView } from '../actions/drawer';
import { ZendeskSearchResult } from '../../../models/Zendesk';
import drawer, * as fromDrawer from './drawer';

export interface ZendeskReducerState {
    drawer: fromDrawer.ZendeskDrawerState;
}

export default combineReducers<ZendeskReducerState>({
    drawer,
});

export const getDrawerOpen: (state: ZendeskReducerState) => boolean = (state) =>
    fromDrawer.getOpen(state.drawer);

export const getDrawerView: (state: ZendeskReducerState) => ZendeskDrawerView = (state) =>
    fromDrawer.getView(state.drawer);

export const getDrawerSearchResult: (state: ZendeskReducerState) => ZendeskSearchResult = (state) =>
    fromDrawer.getSearchResult(state.drawer);

export const getDrawerSearchTerm: (state: ZendeskReducerState) => string = (state) =>
    fromDrawer.getSearchTerm(state.drawer);