import React from 'react';
import { Notification } from '../../../models/Notification';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { markReadNotifications } from '../actions';
import { longDateFormatString } from '../../../util';
import i18n from '../../../i18n';
import moment from 'moment-timezone';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const tz = moment.tz.guess();

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.unit(2),
  },
  secondaryText: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
  },
  secondaryTextSmall: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.small,
  },
}));

interface SystemNotificationItemProps {
  notification: Notification;
  onClose?: VoidFunction;
}

export const SystemNotificationItem: React.FC<SystemNotificationItemProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const { notification } = props;

  const dispatch = useDispatch();

  const render = () => (
    <Flex.Row
      className={classes.root}
      alignItems="center"
      childrenGap={theme.old.spacing.unit(2)}
    >
      <FontAwesomeIcon
        icon={['fal', 'cog']}
        color={theme.old.palette.primaryColor}
      />
      <Flex.Column>
        <Typography.Text ellipsis>
          {t(
            `notifications:titel.${notification.type}.${notification.progressStatus}`
          )}
        </Typography.Text>
        <Typography.Text className={classes.secondaryTextSmall}>
          {t(`notifications:description.currentStatus`)}{' '}
          {t(`notifications:status.${notification.progressStatus}`)}
          {notification.lastProgressStatusChanged !== undefined ? (
            <>
              {' '}
              |{' '}
              {longDateFormatString(
                moment
                  .utc(notification.lastProgressStatusChanged)
                  .tz(tz)
                  .toISOString(),
                i18n.language
              )}
            </>
          ) : (
            <></>
          )}
        </Typography.Text>
        <Typography.Text className={classes.secondaryText}>
          {notification.additionalInformation}
        </Typography.Text>
      </Flex.Column>
      <Flex.Item flex={1}></Flex.Item>
      <Flex.Item flex={3}></Flex.Item>
      <Flex.Row>
        <Button
          type="link"
          iconProp={['fal', 'check']}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              markReadNotifications(notification.notificationId, {
                ...notification,
                isRead: true,
              })
            );
          }}
        />
      </Flex.Row>
    </Flex.Row>
  );

  return render();
};
