import React, { useEffect, useState } from 'react';
import { Input, InputProps } from 'antd';
import { debounceFunction } from '../../util';

const debouncedInputChange = debounceFunction(
  (value: string, onChange?: (value: string) => void) => {
    if (onChange) {
      onChange(value);
    }
  },
  250
);

interface DebouncedInputSearchProps extends Omit<InputProps, 'onChange'> {
  showSearchIcon?: boolean;
  onChange?: (value: string) => void;
}

export const DebouncedInputSearch: React.FC<DebouncedInputSearchProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const {
      value,
      allowClear,
      showSearchIcon,
      onChange,
      defaultValue,
      ...rest
    } = props;
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [currentInputValue, setCurrentInputValue] = useState<
      string | number | readonly string[]
    >(defaultValue ?? null);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = e.currentTarget.value;
      setCurrentInputValue(eventValue);
      debouncedInputChange(eventValue, onChange);
    };
    //#endregion

    //#region ------------------------------ Effects

    useEffect(() => {
      setCurrentInputValue(props.value ?? defaultValue);
    }, [props.value, defaultValue]);

    //#endregion

    return showSearchIcon ? (
      <Input.Search
        value={currentInputValue}
        onChange={handleChange}
        allowClear={allowClear}
        {...rest}
      />
    ) : (
      <Input
        value={currentInputValue}
        onChange={handleChange}
        allowClear={allowClear}
        {...rest}
      />
    );
  });

export default DebouncedInputSearch;
