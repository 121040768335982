import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Divider,
  Avatar,
  Alert,
  Typography,
  Checkbox,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import { makePrioStyles } from '../../../theme/utils';

import { emailPattern } from '../../../hooks/useEmailValidation';
import {
  emptyCreateInternalCompanyRequest,
  CreateInternalCompanyRequest,
  InternalCompany,
  ExternalCompany,
  CompanyDuplicate,
  Company,
} from '../../../models/Company';
import { CountryPicker } from '../../../components/CountryPicker';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultPhonePattern, defaultPhonePlaceholder } from '../../../util';
import { rowGutter, colon } from '../../../util/forms';
import { Configuration } from '../../../models/Configuration';
import { apiFetchConfigurations } from '../../settings/api';
import { CompanyId, ConfigurationKeys } from '../../../models/Types';
import useDebounce from '../../../hooks/useDebounce';
import { apiCheckCompanyDuplicate } from '../api';
import ContactPicker from '../../contacts/components/ContactPicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
    overflowY: 'scroll',
    '& > div': {
      height: '100%',
      overflow: 'hidden',
    },
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
  },
  checkbox: {
    marginTop: theme.old.spacing.unit(2),
  },
  alert: {
    marginTop: theme.old.spacing.unit(1),
  },
  scrollable: {
    height: 'calc(100% - 48px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  divider: {
    marginTop: '12px',
  },
}));

interface InternalCompanyFormProps {
  initialValues?: InternalCompany | CreateInternalCompanyRequest;
  editMode?: boolean;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (
    value: CreateInternalCompanyRequest | InternalCompany,
    companyIdsToArchive?: CompanyId[]
  ) => void;
  onCancel?: () => void;
  avatar?: boolean;
  isLoading?: boolean;
}

export const InternalCompanyForm: React.FC<InternalCompanyFormProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    initialValues,
    actionLabel,
    cancelLabel,
    onFinish,
    onCancel,
    editMode,
    disableActionButton,
    disableForm,
    avatar,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [foundDuplicates, setFoundDuplicates] = useState<ExternalCompany[]>([]);
  const [currentDuplicate, setCurrentDuplicate] = useState<CompanyDuplicate>({
    shortName: (initialValues ?? emptyCreateInternalCompanyRequest).shortName,
    fullName: (initialValues ?? emptyCreateInternalCompanyRequest).fullName,
  });
  const [selectedDuplicateCompanies, setSelectedDuplicateCompanies] = useState<
    CompanyId[]
  >([]);
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  const debouncedDuplicate = useDebounce(currentDuplicate, 500);

  useEffect(() => {
    const findDuplicates = async () => {
      const { shortName, fullName } = debouncedDuplicate;
      let companies: Company[] = [];
      if (shortName && fullName) {
        const { data } = await apiCheckCompanyDuplicate({
          shortName,
          fullName,
        });
        companies = data;
      } else if (shortName) {
        const { data } = await apiCheckCompanyDuplicate({
          shortName,
        });
        companies = data;
      } else if (fullName) {
        const { data } = await apiCheckCompanyDuplicate({
          fullName,
        });
        companies = data;
      }

      if (companies && companies.length > 0) {
        setFoundDuplicates(companies);
        setSelectedDuplicateCompanies((selectedDuplicateCompanies) =>
          selectedDuplicateCompanies.filter(
            (companyId) =>
              companies.findIndex((d) => d.companyId === companyId) > -1
          )
        );
      } else {
        setFoundDuplicates([]);
        setSelectedDuplicateCompanies([]);
      }
    };
    if (!initialValues) {
      findDuplicates();
    }
  }, [debouncedDuplicate, initialValues]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  const duplicateContactCheckboxChange = (
    selected: boolean,
    companyId: CompanyId
  ) => {
    selected
      ? setSelectedDuplicateCompanies([
          ...selectedDuplicateCompanies,
          companyId,
        ])
      : setSelectedDuplicateCompanies(
          selectedDuplicateCompanies.filter((cId) => cId !== companyId)
        );
  };

  const cancel = () => {
    form.resetFields();
    setSelectedDuplicateCompanies([]);
    setCurrentDuplicate({
      shortName: (initialValues ?? emptyCreateInternalCompanyRequest).shortName,
      fullName: (initialValues ?? emptyCreateInternalCompanyRequest).fullName,
    });
    onCancel();
  };

  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);

  const handleFinish: (
    value: CreateInternalCompanyRequest | InternalCompany
  ) => void = (value) => {
    onFinish(value, selectedDuplicateCompanies);
  };

  return (
    <Form
      className={classes.root}
      initialValues={initialValues ?? emptyCreateInternalCompanyRequest}
      form={form}
      onValuesChange={(
        changedValues: CreateInternalCompanyRequest,
        allValues: CreateInternalCompanyRequest
      ) => {
        if (changedValues.shortName?.startsWith(allValues.fullName ?? '')) {
          form.setFieldsValue({
            ...allValues,
            fullName: changedValues.shortName,
          });
        }
        if (
          allValues.shortName !== currentDuplicate.shortName ||
          allValues.fullName !== currentDuplicate.fullName
        ) {
          setCurrentDuplicate({
            shortName: allValues.shortName,
            fullName: allValues.fullName,
          });
        }
      }}
      onFinish={handleFinish}
      layout="vertical"
    >
      <Flex.Column>
        <div className={classes.scrollable}>
          <Flex.Row>
            {avatar && (
              <Avatar
                icon={<FontAwesomeIcon icon={['fal', 'building']} />}
                size={96}
                style={{
                  backgroundColor: theme.old.palette.primaryColor,
                  fontSize: 48,
                  fontWeight: theme.old.typography.fontWeight.bold,
                  minWidth: 96,
                  marginRight: theme.old.spacing.defaultPadding,
                }}
              />
            )}
            <Flex.Item flex={1}>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={12}>
                  <Form.Item
                    name="shortName"
                    colon={colon}
                    label={t('companies:form.labels.shortName')}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'companies:form.validation.missingShortName'
                        ),
                      },
                    ]}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="fullName"
                    colon={colon}
                    label={t('companies:form.labels.fullName')}
                    rules={[
                      {
                        required: true,
                        message: t('companies:form.validation.missingFullName'),
                      },
                    ]}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Col>
              </Row>
            </Flex.Item>
          </Flex.Row>
          <Form.List name="offices">
            {(fields) =>
              fields.map((field, index) => (
                <div key={field.key}>
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'street']}
                        label={t('companies:form.labels.street')}
                        colon={colon}
                      >
                        <Input disabled={disableForm} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={8}>
                      <Form.Item
                        name={[field.name, 'zip']}
                        label={t('companies:form.labels.zip')}
                        colon={colon}
                      >
                        <Input disabled={disableForm} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name={[field.name, 'city']}
                        label={t('companies:form.labels.city')}
                        colon={colon}
                      >
                        <Input disabled={disableForm} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, 'countryCode']}
                        label={t('companies:form.labels.countryCode')}
                      >
                        <CountryPicker
                          disabled={disableForm}
                          placeholder={t(
                            'companies:form.placeholder.countryCode'
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, 'federalStateCode']}
                        label={t('companies:form.labels.federalStateCode')}
                      >
                        <Input
                          disabled={disableForm}
                          placeholder={t(
                            'companies:form.placeholder.federalStateCode'
                          )}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={24}>
                      <Form.Item
                        name={[field.name, 'eMail']}
                        label={t('companies:form.labels.eMail')}
                        colon={colon}
                        validateTrigger="onBlur"
                        rules={[
                          {
                            required: true,
                            message: t(
                              'companies:form.validation.missingEmail'
                            ),
                          },
                          {
                            message: t(
                              'companies:form.validation.invalidEmail'
                            ),
                            pattern: emailPattern,
                          },
                        ]}
                      >
                        <Input
                          disabled={editMode || disableForm}
                          type="email"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={24}>
                      <Form.Item
                        name={[field.name, 'phone']}
                        label={t('companies:form.labels.phone')}
                        colon={colon}
                        rules={[
                          {
                            message: t(
                              'companies:form.validation.invalidPhoneNumber'
                            ),
                            pattern: configurations
                              ? configurations.find(
                                  (config) =>
                                    config.key === ConfigurationKeys.REGEX_PHONE
                                )
                                ? new RegExp(
                                    configurations.find(
                                      (config) =>
                                        config.key ===
                                        ConfigurationKeys.REGEX_PHONE
                                    ).value
                                  )
                                : defaultPhonePattern
                              : defaultPhonePattern,
                          },
                        ]}
                      >
                        <Input
                          disabled={editMode || disableForm}
                          placeholder={
                            configurations?.find(
                              (config) =>
                                config.key ===
                                ConfigurationKeys.PLACEHOLDER_PHONE
                            )?.value ?? defaultPhonePlaceholder
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider className={classes.divider} />
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={24}>
                      <Form.Item
                        name={[field.name, 'managerIds']}
                        label={t('companies:form.labels.managerIds')}
                      >
                        <ContactPicker
                          multiple
                          contactType="InternalContact"
                          disabled={disableForm}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))
            }
          </Form.List>
          <Divider className={classes.divider} />
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="website"
                label={t('companies:form.labels.website')}
                colon={colon}
              >
                <Input disabled={editMode || disableForm} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name="legalForm"
                label={t('companies:form.labels.legalForm')}
                colon={colon}
              >
                <Input disabled={editMode || disableForm} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vatNumber"
                label={t('companies:form.labels.vatNumber')}
                colon={colon}
              >
                <Input disabled={editMode || disableForm} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name="commercialRegisterNumber"
                label={t('companies:form.labels.commercialRegisterNumber')}
                colon={colon}
              >
                <Input disabled={editMode || disableForm} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="commercialRegisterCity"
                label={t('companies:form.labels.commercialRegisterCity')}
                colon={colon}
              >
                <Input disabled={editMode || disableForm} />
              </Form.Item>
            </Col>
          </Row>
          <Divider className={classes.divider} />
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="notes"
                label={t('companies:form.labels.notes')}
                colon={colon}
              >
                <Input.TextArea disabled={editMode || disableForm} />
              </Form.Item>
            </Col>
          </Row>
          {foundDuplicates.map((duplicateCompany) => (
            <Alert
              key={duplicateCompany.companyId}
              className={classes.alert}
              message={t('companies:newCompany.duplicateAlert.message')}
              description={
                <Flex.Column>
                  <Typography.Text>{duplicateCompany.fullName}</Typography.Text>
                  {duplicateCompany.companyType === 'InternalCompany' ? (
                    <div className={classes.checkbox}>
                      {t('companies:newCompany.duplicateAlert.noInternal')}
                    </div>
                  ) : (
                    <Checkbox
                      className={classes.checkbox}
                      checked={selectedDuplicateCompanies.includes(
                        duplicateCompany.companyId
                      )}
                      onChange={(e) =>
                        duplicateContactCheckboxChange(
                          e.target.checked,
                          duplicateCompany.companyId
                        )
                      }
                    >
                      {t('contacts:newContact.duplicateAlert.action')}
                    </Checkbox>
                  )}
                </Flex.Column>
              }
              type="error"
            />
          ))}
        </div>
        <Flex.Row justify="end" className={classes.actionButtonsRow} flex={1}>
          <Col span={24}>
            <Form.Item className={classes.submitButtonFormItem}>
              <Flex.Row
                alignItems={'center'}
                justifyContent={'flex-end'}
                childrenGap={theme.old.spacing.unit(2)}
              >
                {onCancel && (
                  <Button type="default" onClick={cancel}>
                    {cancelLabel}
                  </Button>
                )}
                <Button
                  htmlType="submit"
                  disabled={disableActionButton}
                  loading={isLoading}
                >
                  {actionLabel}
                </Button>
              </Flex.Row>
            </Form.Item>
          </Col>
        </Flex.Row>
      </Flex.Column>
    </Form>
  );
};

export default InternalCompanyForm;
