import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Cascader } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { DriveItemId } from '../../../../models/Types';
import equals from 'deep-equal';
import {
  getCurrentFolderItem,
  getPartialCurrentFolderState,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { FolderMap } from '../../reducers/currentFolder';
import { useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { isDriveItemFolder } from '../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  cascaderDropdownIcon: {
    fontSize: 10,
    lineHeight: 1,
    marginLeft: theme.old.spacing.unit(0.5),
    '& > svg': {
      marginBottom: 1,
    },
  },
  popup: {
    padding: 0,
    overflowX: 'scroll',
    maxWidth: '50vw',
    '& .ant-cascader-menus': {
      position: 'relative',
      '& > ul:not(:first-child)::before': {
        content: '""',
        position: 'absolute',
        width: 1,
        top: 0,
        bottom: 0,
        backgroundColor: theme.old.borders.colors.main,
        zIndex: 2,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: 2,
        top: 0,
        height: 32,
        left: 0,
        backgroundColor: theme.old.palette.primaryColor,
      },
    },
    '& .ant-cascader-menu': {
      height: '100%',
      maxHeight: '25vh',
      border: 'none',
    },
    '& .ant-cascader-menu-item-content': {
      display: 'flex',
      overflow: 'hidden',
      '& > span': {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '& .ant-cascader-menu-item-expand': {
      fontWeight: theme.old.typography.fontWeight.bold,
      backgroundColor: theme.old.palette.backgroundPalette.active.content,
      position: 'relative',
      '&.ant-cascader-menu-item': {
        color: theme.old.palette.primaryColor,
      },
      '&.ant-cascader-menu-item a': {
        color: theme.old.palette.primaryColor,
      },
      '&:hover': {
        backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      },
    },
    '& .ant-cascader-menu-item': {
      color: theme.old.typography.colors.base,
      position: 'relative',
      '&:has(.prio-documents-active-breadcrumb-item)': {
        color: theme.old.palette.primaryColor,
        fontWeight: theme.old.typography.fontWeight.bold,
        backgroundColor: theme.old.palette.backgroundPalette.active.content,
        '&:hover': {
          backgroundColor: theme.old.palette.backgroundPalette.hover.content,
        },
      },
      '&:has(.prio-documents-active-breadcrumb-item) a': {
        color: theme.old.palette.primaryColor,
        fontWeight: theme.old.typography.fontWeight.bold,
      },
      '&:hover': {
        color: theme.old.palette.primaryColor,
      },
    },
    '& .ant-cascader-menu-item a': {
      color: theme.old.typography.colors.base,
      '&:hover': {
        color: theme.old.palette.primaryColor,
      },
    },
  },
  breadcrumbSeparator: {
    margin: '0px 4px',
    color: 'rgba(0,0,0,0.45)',
  },
}));

interface Option {
  value: string | number;
  label: React.ReactNode;
  children?: Option[];
}

interface BreadcrumbItem {
  index: number;
  label: string;
  id?: string;
  isHidden?: boolean;
}

const renderLabel = (
  driveItemId: DriveItemId,
  label: string,
  pathPrefix?: string,
  disableLinkNavigation?: boolean,
  onClick?: (driveItemId: DriveItemId) => void,
  className?: string
) => {
  const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) {
      onClick(driveItemId);
    }
  };

  return (
    <span
      className={className}
      title={label === '...' ? undefined : label}
      onClick={handleOnClick}
    >
      {!disableLinkNavigation && driveItemId ? (
        <Link to={`${pathPrefix ?? ''}folder/${driveItemId}`}>{label}</Link>
      ) : (
        label
      )}
    </span>
  );
};

const useCascaderOptions: (
  breadcrumbItems: BreadcrumbItem[],
  pathPrefix?: string,
  disableLinkNavigation?: boolean,
  onOptionClick?: (driveItemId: DriveItemId) => void
) => Option[] = (
  breadcrumbItems,
  pathPrefix,
  disableLinkNavigation,
  onOptionClick
) => {
  const [driveItemIds, setDriveItemIds] = useState<DriveItemId[]>([]);

  const partialCurrentFolderState = useSelector<RootReducerState, FolderMap>(
    (state) => getPartialCurrentFolderState(state, driveItemIds)
  );

  useEffect(() => {
    const newIds = breadcrumbItems.map(({ id }) => id);
    if (!equals(newIds, driveItemIds)) {
      setDriveItemIds(newIds);
    }
  }, [breadcrumbItems, driveItemIds]);

  return useMemo(
    () =>
      [
        breadcrumbItems
          .filter(({ isHidden }) => isHidden)
          .map(({ id, label }) => ({
            value: id,
            label: renderLabel(
              id,
              label,
              pathPrefix,
              disableLinkNavigation,
              onOptionClick,
              'prio-documents-active-breadcrumb-item'
            ),
            children: (
              partialCurrentFolderState[id]?.value?.filter((item) =>
                isDriveItemFolder(item)
              ) ?? []
            )
              .sort((a, b) => {
                if (breadcrumbItems.find((i) => i.id === a.id)) {
                  return -1;
                }
                if (breadcrumbItems.find((i) => i.id === b.id)) {
                  return 1;
                }
                return a.name.localeCompare(b.name);
              })
              .map<Option>((item) => ({
                value: item.id,
                label: renderLabel(
                  item.id,
                  item.name,
                  pathPrefix,
                  disableLinkNavigation,
                  onOptionClick,
                  breadcrumbItems.find((i) => i.id === item.id)
                    ? 'prio-documents-active-breadcrumb-item'
                    : null
                ),
              })),
          }))
          .reduceRight<Option>((map, option) => {
            if (map) {
              const _children = [...option.children];
              const _index = _children.findIndex(
                (child) => child.value === map.value
              );
              _children.splice(_index, 1, map);
              if (_index > -1) {
                return {
                  ...option,
                  children: _children,
                };
              }
              return option;
            }
            return option;
          }, null),
      ].filter((option) => option),
    [
      breadcrumbItems,
      partialCurrentFolderState,
      pathPrefix,
      disableLinkNavigation,
      onOptionClick,
    ]
  );
};

declare type Placement = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';

interface CascaderBreadcrumbItemProps {
  className?: string;
  pathPrefix?: string;
  breadcrumbItems: BreadcrumbItem[];
  isFullPathAvailable: boolean;
  placementOverlay?: Placement;
  disableLinkNavigation?: boolean;
  onCascaderContentClick?: (driveItemId: DriveItemId) => void;
  onCascaderOptionClick?: (driveItemId: DriveItemId) => void;
}

export const CascaderBreadcrumbItem: React.FC<CascaderBreadcrumbItemProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    breadcrumbItems,
    isFullPathAvailable,
    pathPrefix,
    placementOverlay,
    disableLinkNavigation,
    onCascaderContentClick,
    onCascaderOptionClick,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [openFolderDropdown, setOpenFolderDropdown] = useState<boolean>(false);

  const options: Option[] = useCascaderOptions(
    breadcrumbItems,
    pathPrefix,
    disableLinkNavigation,
    (driveItemId) => {
      setOpenFolderDropdown(false);
      if (onCascaderOptionClick) {
        onCascaderOptionClick(driveItemId);
      }
    }
  );

  const firstItemParentId = useSelector<RootReducerState, DriveItemId>(
    (state) =>
      breadcrumbItems.filter(({ isHidden }) => !isHidden).length > 0
        ? getCurrentFolderItem(
            state,
            breadcrumbItems.filter(({ isHidden }) => !isHidden)[0]?.id
          )?.parentReference?.id
        : null
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (!isFullPathAvailable || options.length > 0) {
    return (
      <span className={classNames(classes.root, className)}>
        {options.length > 0 ? (
          <Cascader
            options={options}
            placement={placementOverlay ?? 'bottomRight'}
            dropdownClassName={classes.popup}
            onChange={(value: string[]) => {
              setOpenFolderDropdown(false);
              if (onCascaderOptionClick) {
                onCascaderOptionClick(value[value.length - 1]);
              }
            }}
            open={openFolderDropdown}
            onDropdownVisibleChange={setOpenFolderDropdown}
          >
            <span>
              {renderLabel(
                firstItemParentId,
                '...',
                pathPrefix,
                disableLinkNavigation,
                onCascaderContentClick
              )}
              <DownOutlined className={classes.cascaderDropdownIcon} />
            </span>
          </Cascader>
        ) : (
          <span>
            {renderLabel(
              firstItemParentId,
              '...',
              pathPrefix,
              disableLinkNavigation,
              onCascaderContentClick
            )}
          </span>
        )}
        <span className={classes.breadcrumbSeparator}>/</span>
      </span>
    );
  }

  return <span />;
};

export default CascaderBreadcrumbItem;
