import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Tooltip } from 'antd';
import Flex from '../../../components/Flex';
import { EmergencyContact } from '../../../models/Employee';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Divider } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {
    borderRadius: '4px',
    padding: 10,
    marginRight: theme.spacing.small,
    marginLeft: theme.spacing.large,
    marginBottom: theme.spacing.large,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  heading: {
    marginBottom: '24px !important',
    fontSize: '18px !important',
  },
  noData: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing.regular,
  },
}));

interface ProfilePersonnelEmergencyContactDataFormProps {
  emergencyContactList: EmergencyContact[];
}

export const ProfilePersonnelEmergencyContactDataForm: React.FC<
  ProfilePersonnelEmergencyContactDataFormProps
> = (props) => {
  const { emergencyContactList } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion
  //#region ------------------------------ States / Attributes / Selector
  //#region ------------------------------ Methods / Handlers
  //#endregion
  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Typography.Title className={classes.heading}>
        {t('profile:profileNavigation.emergencyContacts')}
      </Typography.Title>
      {!emergencyContactList.length ? (
        <div className={classes.noData}>{t('profile:noEmergencyContact')}</div>
      ) : (
        emergencyContactList.map((emergencyContact, index) => (
          <Flex.Column
            flex={1}
            childrenGap={theme.old.spacing.unit(3)}
            key={emergencyContact.emergencyContactId}
          >
            <Flex.Row>
              <Flex.Column flex={1} overflow={'hidden'}>
                <Typography.Title style={{ fontSize: 13 }}>
                  {t('profile:form.emergencyContactName')}
                </Typography.Title>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '90%',
                  }}
                >
                  <Tooltip title={emergencyContact.name}>
                    {`${
                      emergencyContact.name
                        ? emergencyContact.name
                        : ` ${t('profile:personalData.noData')} `
                    }`}
                  </Tooltip>
                </div>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Typography.Title style={{ fontSize: 13 }}>
                  {t('profile:form.emergencyContactMobile')}
                </Typography.Title>
                <Flex.Item>
                  {`${
                    emergencyContact.mobile
                      ? emergencyContact.mobile
                      : ` ${t('profile:personalData.noData')} `
                  }`}
                </Flex.Item>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row>
              <Flex.Column flex={1}>
                <Typography.Title style={{ fontSize: 13 }}>
                  {t('profile:form.emergencyContactRelationship')}
                </Typography.Title>
                <Flex.Item>
                  {`${
                    emergencyContact.relationship
                      ? emergencyContact.relationship
                      : ` ${t('profile:personalData.noData')} `
                  }`}
                </Flex.Item>
              </Flex.Column>
              <Flex.Column flex={1} overflow={'hidden'}>
                <Typography.Title style={{ fontSize: 13 }}>
                  {t('profile:form.emergencyContactPhone')}
                </Typography.Title>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '90%',
                  }}
                >
                  <Tooltip title={emergencyContact.phone}>
                    {`${
                      emergencyContact.phone
                        ? emergencyContact.phone
                        : ` ${t('profile:personalData.noData')} `
                    }`}
                  </Tooltip>
                </div>
              </Flex.Column>
            </Flex.Row>
            {index < emergencyContactList.length - 1 && <Divider></Divider>}
          </Flex.Column>
        ))
      )}
    </Flex.Column>
  );
};

export default ProfilePersonnelEmergencyContactDataForm;
