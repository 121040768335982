import {
  OPEN_ADD_USER_DRAWER,
  CLOSE_ADD_USER_DRAWER,
} from '../actions/addUserDrawer';
import { Reducer } from 'react';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface AddUserDrawerState {
  open: boolean;
}

const reducer: Reducer<AddUserDrawerState, { type: string }> = (
  state = { open: false },
  action
) => {
  switch (action.type) {
    case OPEN_ADD_USER_DRAWER: {
      return {
        ...state,
        open: true,
      };
    }
    case CLOSE_ADD_USER_DRAWER: {
      return {
        ...state,
        open: false,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { open: false };
    }
    default:
      return state;
  }
};

export default reducer;
