import { createContext } from 'react';
import { FilterContextProps } from '../types';

const defaultValue: FilterContextProps = {
  searchType: null,
  searchString: '',
  data: null,
  thresholdExceeded: null,
  fetchSearch: undefined,
  isLoading: false,
  isError: false,
  isSuccess: false,
  isFetching: false,
  getItemById: undefined,
  getSearchResultItem: undefined,
  clearSearch: undefined,
  optimisticWrite: undefined,
  tableRef: undefined,
  defaultSearchParameters: '',
  disableURLQuery: false,
  searchFilterConfig: null,
};

const FilterContext = createContext<FilterContextProps>(defaultValue);

export default FilterContext;
