import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dropdown, Menu, Tooltip } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { Message, MessageAttachment } from '../../../../models/Message';
import MessageAttachmentDropdownList from '../MessageAttachmentDropdownList';
import { ProjectId } from '../../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import QuickActionButton from './QuickActionButton';
import equals from 'deep-equal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
  },
  messageButtons: {
    cursor: 'pointer',
  },
  activeFlag: {
    display: 'block',
    color: theme.old.palette.chromaticPalette.red,
  },
  activeFlagCheck: {
    display: 'block',
    color: theme.old.palette.chromaticPalette.grey,
  },
}));

const useShrink = (listenerRef: React.MutableRefObject<HTMLDivElement>) => {
  const [element, setElement] = useState<HTMLDivElement>(listenerRef?.current);
  const [isShrinked, setIsShrinked] = useState<boolean>(false);

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setIsShrinked(entry.contentRect.width < 480);
        });
      }),
    []
  );

  useEffect(() => {
    if (element) {
      resizeObserver.observe(element);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [element, resizeObserver]);

  useEffect(() => {
    if (!equals(listenerRef.current, element)) {
      setElement(listenerRef.current);
    }
  }, [listenerRef, element]);

  return isShrinked;
};

interface QuickActionButtonRowProps {
  className?: string;
  projectId: ProjectId;
  listenerRef: React.MutableRefObject<any>;
  message: Message;
  messageAttachments: MessageAttachment[];
  selectedMessageAttachmentIds: string[];
  openInNewWindow: boolean;
  isProject?: boolean;
  onDownloadAttachment: (messageAttachment: MessageAttachment) => Promise<void>;
  onAttachmentSelectionChanged: (e: CheckboxChangeEvent) => void;
  onOpenInNewWindow?: VoidFunction;
  onDelete: (message: Message) => void;
  onReply: () => Promise<void>;
  onReplyAll: () => Promise<void>;
  onForward: () => Promise<void>;
  onMarkRead: () => Promise<void>;
  onPrintEmail?: () => Promise<void>;
  createTask: () => Promise<void>;
  handleFlag: () => Promise<void>;
  assignMessage: () => Promise<void>;
}

export const QuickActionButtonRow: React.FC<QuickActionButtonRowProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    projectId,
    message,
    listenerRef,
    selectedMessageAttachmentIds,
    messageAttachments,
    openInNewWindow,
    isProject,
    onDownloadAttachment,
    onAttachmentSelectionChanged,
    onOpenInNewWindow,
    onDelete,
    onReply,
    onReplyAll,
    onForward,
    onPrintEmail,
    onMarkRead,
    createTask,
    handleFlag,
    assignMessage,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isShrinked = useShrink(listenerRef);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const handleDelete = async () => {
    await onDelete(message);
  };

  const openNewWindow = async () => {
    const width = window.screen.availWidth / 2;
    const height = window.screen.availHeight * 0.6;
    window.open(
      `/view/${projectId}/message/${message.id}/details`,
      '_blank',
      `width=${width},height=${height},top=${height / 4}`
    );
    if (onOpenInNewWindow) {
      onOpenInNewWindow();
    }
  };

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (isShrinked) {
    return (
      <div className={classNames(classes.root, className)}>
        <Dropdown
          overlay={
            <Menu>
              {isProject && (
                <Menu.Item
                  key={'assign'}
                  onClick={assignMessage}
                  icon={<FontAwesomeIcon icon={['fal', 'user-plus']} />}
                >
                  {t('mail:messageDisplay.assignMessage')}
                </Menu.Item>
              )}
              <Menu.Item
                key={'tasks'}
                onClick={createTask}
                icon={<FontAwesomeIcon icon={['fal', 'tasks']} />}
              >
                {t('mail:messageDisplay.createTask')}
              </Menu.Item>
              <Menu.Item
                key={'print'}
                onClick={onPrintEmail}
                icon={<FontAwesomeIcon icon={['fal', 'print']} />}
                disabled={!onPrintEmail}
              >
                {t('mail:messageDisplay.printEmail')}
              </Menu.Item>
              <Menu.Item
                key={'isRead'}
                onClick={onMarkRead}
                icon={
                  <FontAwesomeIcon
                    icon={[
                      'fal',
                      message.isRead ? 'envelope' : 'envelope-open',
                    ]}
                  />
                }
              >
                {t(
                  `mail:messageDisplay.${
                    message.isRead ? 'markAsUnread' : 'markAsRead'
                  }`
                )}
              </Menu.Item>
              <Menu.Item
                key={'flagged'}
                onClick={handleFlag}
                icon={
                  <FontAwesomeIcon
                    color={
                      message.flag?.flagStatus === 'Flagged'
                        ? theme.old.palette.chromaticPalette.red
                        : theme.old.palette.chromaticPalette.grey
                    }
                    icon={
                      message.flag?.flagStatus === 'Complete'
                        ? ['fal', 'check']
                        : [
                            message.flag?.flagStatus === 'NotFlagged'
                              ? 'fal'
                              : 'fas',
                            'flag',
                          ]
                    }
                  />
                }
              >
                {t('mail:messageDisplay.flag')}
              </Menu.Item>
              <Menu.Item
                key={'delete'}
                onClick={handleDelete}
                icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
              >
                {t('mail:messageDisplay.delete')}
              </Menu.Item>
              <Menu.Item
                key={'reply'}
                onClick={onReply}
                icon={<FontAwesomeIcon icon={['fal', 'reply']} />}
              >
                {t('mail:messageDisplay.reply')}
              </Menu.Item>

              <Menu.Item
                key={'replyAll'}
                onClick={onReplyAll}
                icon={<FontAwesomeIcon icon={['fal', 'reply-all']} />}
              >
                {t('mail:messageDisplay.replyAll')}
              </Menu.Item>
              <Menu.Item
                key={'forward'}
                onClick={onForward}
                icon={<FontAwesomeIcon icon={['fal', 'arrow-right']} />}
              >
                {t('mail:messageDisplay.forward')}
              </Menu.Item>
              {openInNewWindow && (
                <Menu.Item
                  key={'newWindow'}
                  onClick={openNewWindow}
                  icon={<FontAwesomeIcon icon={['fal', 'external-link-alt']} />}
                >
                  {t('mail:messageDisplay.newWindow')}
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomRight"
        >
          <FontAwesomeIcon
            color={theme.old.palette.primaryColor}
            icon={['fal', 'plus']}
          />
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
        {messageAttachments.length > 0 && (
          <Dropdown
            overlay={
              <MessageAttachmentDropdownList
                selectedMessageAttachmentIds={selectedMessageAttachmentIds}
                messageAttachments={messageAttachments}
                onDownloadAttachment={onDownloadAttachment}
                onSelectionChange={onAttachmentSelectionChanged}
                messageId={message.id}
                projectId={projectId}
                receivedMessage={true}
              />
            }
            trigger={['click']}
            placement="bottomCenter"
          >
            <Tooltip title={t('mail:messageDisplay.showAttachments')}>
              <FontAwesomeIcon
                icon={['fal', 'paperclip']}
                color={theme.old.palette.primaryColor}
                className={classNames(classes.messageButtons)}
              />
            </Tooltip>
          </Dropdown>
        )}
        {isProject && (
          <QuickActionButton
            tooltipText={t('mail:messageDisplay.assignMessage')}
            icon={['fal', 'user-plus']}
            onClick={assignMessage}
          />
        )}
        <QuickActionButton
          tooltipText={t('mail:messageDisplay.createTask')}
          icon={['fal', 'tasks']}
          onClick={createTask}
        />
        <QuickActionButton
          tooltipText={t('mail:messageDisplay.printEmail')}
          icon={['fal', 'print']}
          onClick={onPrintEmail}
          disabled={!onPrintEmail}
        />
        <QuickActionButton
          tooltipText={t(
            `mail:messageDisplay.${
              message.isRead ? 'markAsUnread' : 'markAsRead'
            }`
          )}
          icon={['fal', message.isRead ? 'envelope' : 'envelope-open']}
          onClick={onMarkRead}
        />
        <QuickActionButton
          className={classNames({
            [classes.activeFlag]: message.flag?.flagStatus === 'Flagged',
            [classes.activeFlagCheck]: message.flag?.flagStatus === 'Complete',
          })}
          tooltipText={t('mail:messageDisplay.flag')}
          icon={
            message.flag?.flagStatus === 'Complete'
              ? ['fal', 'check']
              : [
                  message.flag?.flagStatus === 'NotFlagged' ? 'fal' : 'fas',
                  'flag',
                ]
          }
          color={
            message.flag?.flagStatus === 'Flagged'
              ? theme.old.palette.chromaticPalette.red
              : theme.old.palette.chromaticPalette.grey
          }
          onClick={handleFlag}
        />{' '}
        <QuickActionButton
          tooltipText={t('mail:messageDisplay.delete')}
          icon={['fal', 'trash']}
          onClick={handleDelete}
        />
        <QuickActionButton
          tooltipText={t('mail:messageDisplay.reply')}
          icon={['fal', 'reply']}
          onClick={onReply}
        />
        <QuickActionButton
          tooltipText={t('mail:messageDisplay.replyAll')}
          icon={['fal', 'reply-all']}
          onClick={onReplyAll}
        />
        <QuickActionButton
          tooltipText={t('mail:messageDisplay.forward')}
          icon={['fal', 'arrow-right']}
          onClick={onForward}
        />
        {openInNewWindow && (
          <QuickActionButton
            tooltipText={t('mail:messageDisplay.newWindow')}
            icon={['fal', 'external-link-alt']}
            onClick={openNewWindow}
          />
        )}
      </Flex.Row>
    </div>
  );
};

export default QuickActionButtonRow;
