import React, { useEffect, useRef, useState } from 'react';
import ContactsSelectionList, {
  ContactSelectionListRef,
} from './ContactSelectionList';
import Flex from '../../../components/Flex';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  useParams,
  useLocation,
} from 'react-router-dom';
import ContactDetailsPage from './ContactDetailsPage';
import CompanyDetailsPage from '../../companies/components/CompanyDetailsPage';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyId, ContactId, ContactSearchType } from '../../../models/Types';
import NavigationBar from '../../../components/NavigationBar';
import { useDispatch } from 'react-redux';
import { openContactsDrawer } from '../actions/drawer';
import {
  useExportContactsToExcelFile,
  useExportCompaniesToExcelFile,
} from '../export';
import useAccessRights from '../../users/hooks/useAccessRights';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../../theme/utils';
import { Contact } from '../../../models/Contact';
import { Company } from '../../../models/Company';
import { ContactListItem } from './ContactList';
import ContactTagsProvider from './ContactTagsProvider';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  moduleRoot: {
    height: '100%',
    overflow: 'hidden',
  },
  list: {
    borderRight: theme.old.borders.sub,
  },
}));

interface ContactsModuleProps {}

export const ContactsModule: React.FC<ContactsModuleProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { selectedList } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { exportContact: canExportContact, exportCompany: canExportCompany } =
    useAccessRights(['exportContact', 'exportCompany']);

  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);
  const [selectedContactIds, setSelectedContactIds] = useState<ContactId[]>([]);
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<CompanyId[]>([]);

  const contactIdMatch = /^.*\/contact\/(.*)\/details\/?$/.exec(
    location.pathname
  );
  const companyIdMatch = /^.*\/company\/(.*)\/details\/?$/.exec(
    location.pathname
  );

  const contactId = contactIdMatch ? contactIdMatch[1] : null;
  const companyId = companyIdMatch ? companyIdMatch[1] : null;

  const contactSelectionListRef = useRef<ContactSelectionListRef>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const showNewContactDrawer = () =>
    dispatch(openContactsDrawer({ view: 'contactNew' }));

  const exportContactsToExcelFile = useExportContactsToExcelFile();
  const exportCompaniesToExcelFile = useExportCompaniesToExcelFile();

  const exportToExcelFile = () => {
    const selectedContacts = contactSelectionListRef.current
      ?.getAllItems()
      ?.filter(
        (item: ContactListItem) =>
          item.type === 'contact' &&
          selectedContactIds.includes((item.value as Contact).contactId)
      )
      ?.map((item: ContactListItem) => item.value as Contact);
    const selectedCompanies = contactSelectionListRef.current
      ?.getAllItems()
      ?.filter(
        (item: ContactListItem) =>
          item.type === 'company' &&
          selectedCompanyIds.includes((item.value as Company).companyId)
      )
      ?.map((item: ContactListItem) => item.value as Company);

    switch (selectedList) {
      case 'all':
        if (selectedContacts?.length > 0)
          exportContactsToExcelFile(selectedContacts);
        if (selectedCompanies?.length > 0)
          exportCompaniesToExcelFile(selectedCompanies);
        break;
      case 'allContacts':
        if (selectedContacts?.length > 0)
          exportContactsToExcelFile(selectedContacts);
        break;
      case 'allCompanies':
        if (selectedCompanies?.length > 0)
          exportCompaniesToExcelFile(selectedCompanies);
        break;
    }
  };

  const handleOnExportClick = () => {
    exportToExcelFile();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setSelectedListIds([]);
  }, [selectedList]);

  useEffect(() => {
    setSelectedContactIds(
      selectedListIds
        ?.filter((id: string) => id.includes('contact-'))
        ?.map((id: string) => id.replace('contact-', ''))
    );
    setSelectedCompanyIds(
      selectedListIds
        ?.filter((id: string) => id.includes('company-'))
        ?.map((id: string) => id.replace('company-', ''))
    );
  }, [selectedListIds]);
  //#endregion

  return (
    <Flex.Column className={classes.root} id="prio-contacts-module">
      <NavigationBar>
        <Button onClick={showNewContactDrawer} iconProp={['fal', 'user-plus']}>
          <span>{t('contacts:navigationBar.createContact')}</span>
        </Button>
        <Button
          type="default"
          disabled={
            !(canExportContact && canExportCompany) || !selectedListIds?.length
          }
          onClick={handleOnExportClick}
          iconProp={['fal', 'file-excel']}
        >
          <span>{t('contacts:navigationBar.exportToExcelFile')}</span>
        </Button>
      </NavigationBar>
      <Flex.Row flex={1} className={classes.moduleRoot}>
        <ContactsSelectionList
          type={selectedList as ContactSearchType}
          activeId={contactId ?? companyId}
          setSelectedListIds={setSelectedListIds}
          selectedListIds={selectedListIds}
          className={classes.list}
          ref={contactSelectionListRef}
        />
        <Flex.Item flex={1}>
          <ContactTagsProvider>
            <Routes>
              <Route
                path="contact/:contactId/details"
                element={<ContactDetailsPage />}
              />
              <Route
                path="company/:companyId/details"
                element={<CompanyDetailsPage />}
              />
              <Route
                path="/"
                element={
                  <div className="prio-flex-center-center prio-flex-column">
                    <Typography.Text>
                      <Trans
                        i18nKey={'contacts:contactDetails.noContactSelected'}
                      />
                    </Typography.Text>
                  </div>
                }
              />
            </Routes>
          </ContactTagsProvider>
        </Flex.Item>
      </Flex.Row>
    </Flex.Column>
  );
};

export default ContactsModule;
