import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, notification } from 'antd';
import { Classes } from 'jss';
import { MailListItemClassNames } from './MailListItem';
import UserAvatar from '../../../../components/UserAvatar';
import { Message } from '../../../../models/Message';
import ContactPicker from '../../../contacts/components/ContactPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactId, MessageId, ProjectId } from '../../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getContact,
} from '../../../../apps/main/rootReducer';
import { Contact } from '../../../../models/Contact';
import { apiAssignMessage, apiUnassignMessage } from '../../api';
import { updateMessage } from '../../actions/actionControllers/messageActionController';
import classNames from 'classnames';

interface AssignMailButtonProps {
  classes: Classes<MailListItemClassNames>;
  projectId: ProjectId;
  message: Message;
  loading?: boolean;
}

export const AssignMailButton: React.FC<AssignMailButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { classes, projectId, message, loading } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const messageId = message.id;

  const [assignedToId, setAssignedToId] = useState<ContactId>(
    message.customSingleValueExtendedProperties?.messageAssignmentId
  );

  const assignedToContact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, assignedToId)
  );

  const [assignmentPopoverVisible, setAssignmentPopoverVisible] =
    useState(false);

  const [contactPickerDisabled, setContactPickerDisabled] =
    useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const assignMessage = async (
    projectId: ProjectId,
    messageId: MessageId,
    contactId?: ContactId
  ) => {
    if (projectId && messageId && contactId) {
      setContactPickerDisabled(true);
      const { data } = await apiAssignMessage(projectId, messageId, contactId);
      setContactPickerDisabled(false);
      if (data) {
        dispatch(updateMessage(projectId, messageId, data));
      } else {
        notification.open({
          message: t('common:error'),
          description: t('mail:errorMessages.messages.messageAssignmentError'),
        });
      }
    }
  };

  const unassignMessage = async (
    projectId: ProjectId,
    messageId: MessageId
  ) => {
    if (projectId && messageId) {
      setContactPickerDisabled(true);
      const { result } = await apiUnassignMessage(projectId, messageId);
      setContactPickerDisabled(false);
      if (result.status >= 200 && result.status < 300) {
        const {
          messageAssignmentId: _,
          ...customSingleValueExtendedProperties
        } = message.customSingleValueExtendedProperties;
        dispatch(
          updateMessage(projectId, message.id, {
            customSingleValueExtendedProperties: {
              ...customSingleValueExtendedProperties,
              messageAssignmentId: null,
            },
          })
        );
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:errorMessages.messages.messageUnassignmentError'
          ),
        });
      }
    }
  };

  const changeAssignedProjectMember = (value: string | string[]) => {
    let contactId: ContactId = null;
    Array.isArray(value) ? (contactId = value[0]) : (contactId = value);
    setAssignedToId(contactId);
    if (contactId) {
      assignMessage(projectId, messageId, contactId);
    } else {
      unassignMessage(projectId, messageId);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const newAssignedToId =
      message?.customSingleValueExtendedProperties?.messageAssignmentId;
    if (assignedToId !== newAssignedToId) {
      setAssignedToId(newAssignedToId);
    }
  }, [
    assignedToId,
    message?.customSingleValueExtendedProperties?.messageAssignmentId,
  ]);
  //#endregion

  if (loading) {
    return (
      <div
        style={{
          minWidth: '1.5715em',
          minHeight: '1.5715em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!assignedToId ? (
          <FontAwesomeIcon
            icon={['fal', 'user-plus']}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAssignmentPopoverVisible(!assignmentPopoverVisible);
            }}
            style={{ marginLeft: '3px' }}
            className={classNames(classes.assignmentIcon, {
              [classes.assignmentIconAssigned]: assignedToId,
            })}
          />
        ) : (
          <div
            style={{ display: 'block' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAssignmentPopoverVisible(!assignmentPopoverVisible);
            }}
            className={classNames(classes.assignmentIcon, {
              [classes.assignmentIconAssigned]: assignedToId,
            })}
          >
            <UserAvatar size={'tiny'} contact={assignedToContact} />
          </div>
        )}
      </div>
    );
  }

  return (
    <Popover
      placement="rightTop"
      trigger={'click'}
      visible={assignmentPopoverVisible}
      onVisibleChange={(visible) => {
        setAssignmentPopoverVisible(visible);
      }}
      title={t('mail:messageDisplay.assignMessage')}
      content={
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div
            className={classes.flexRow}
            style={{
              alignItems: 'center',
            }}
            // childrenGap={theme.old.spacing.unit(1)}
          >
            <ContactPicker
              style={{ flex: 1 }}
              value={assignedToContact?.contactId}
              projectId={projectId}
              onlyInternalProject
              disabled={contactPickerDisabled}
              multiple={false}
              onChange={(contact) => {
                changeAssignedProjectMember(contact);
                setAssignmentPopoverVisible(false);
              }}
            />
            <FontAwesomeIcon
              icon={['fal', 'xmark']}
              onClick={() => {
                changeAssignedProjectMember(undefined);
                setAssignmentPopoverVisible(false);
              }}
            />
          </div>
        </div>
      }
    >
      <div
        style={{
          minWidth: '1.5715em',
          minHeight: '1.5715em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!assignedToId ? (
          <FontAwesomeIcon
            icon={['fal', 'user-plus']}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAssignmentPopoverVisible(!assignmentPopoverVisible);
            }}
            style={{ marginLeft: '3px' }}
            className={classNames(classes.assignmentIcon, {
              [classes.assignmentIconAssigned]: assignedToId,
            })}
          />
        ) : (
          <div
            style={{ display: 'block' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setAssignmentPopoverVisible(!assignmentPopoverVisible);
            }}
            className={classNames(classes.assignmentIcon, {
              [classes.assignmentIconAssigned]: assignedToId,
            })}
          >
            <UserAvatar size={'tiny'} contact={assignedToContact} />
          </div>
        )}
      </div>
    </Popover>
  );
};

export default AssignMailButton;
