import React from 'react';

import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { User } from '../../../models/User';
import { GlobalRole } from '../../../models/Types';
import GlobalRolePicker from '../../../components/GlobalRolePicker';
import { paginationTranslations, tableTranslations } from '../../../util/table';
import PrioSpinner from '../../../components/PrioSpinner';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  dropDown: {
    width: '100%',
    '&.ant-select-single .ant-select-selector': {
      paddingLeft: 0,
    },
    '& .ant-select-selection-item': {
      color: theme.old.palette.primaryColor,
    },
  },
  link: {
    width: '100%',
    height: '100%',
  },
}));

interface PrioUserRoleTableProps {
  users: User[];
  onUpdateRoles?: (contactId: string, value: GlobalRole[]) => void;
  onClick?: (user: User) => void;
  loading?: boolean;
}

export const PrioUserRoleTable: React.FC<PrioUserRoleTableProps> = (props) => {
  const classes = useStyles();

  const { users, onUpdateRoles, onClick, loading } = props;
  const { t } = useTranslation();

  const columns: ColumnProps<User>[] = [
    {
      title: t('users:usersTable.columnsTitles.name'),
      dataIndex: 'displayName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a?.surname?.localeCompare(b?.surname) ?? 0,
    },
    {
      title: t('users:usersTable.columnsTitles.globalRoles'),
      dataIndex: ['prioData', 'globalRoles'],
      filters: [
        {
          text: t('users:usersTable.columns.globalAdmin'),
          value: 'globalAdmin',
        },
        {
          text: t('users:usersTable.columns.globalAssistance'),
          value: 'globalAssistance',
        },
        {
          text: t('users:usersTable.columns.globalEmployee'),
          value: 'globalEmployee',
        },
        {
          text: t('users:usersTable.columns.globalController'),
          value: 'globalController',
        },
        {
          text: t('users:usersTable.columns.globalHR'),
          value: 'globalHR',
        },
      ],
      onFilter: (value, record) =>
        record?.prioData?.globalRoles?.includes(value as GlobalRole) ?? false,
      sorter: (a, b) =>
        a?.prioData?.globalRoles
          ?.join(',')
          ?.localeCompare(b?.prioData?.globalRoles?.join(',')) ?? 0,
      render: (value, record) => (
        <GlobalRolePicker
          value={value}
          onChange={
            onUpdateRoles ? (value) => onUpdateRoles(record.id, value) : null
          }
          className={classes.dropDown}
        />
      ),
      width: '75%',
    },
  ];

  return (
    <Table
      className={classes.root}
      dataSource={users}
      columns={columns}
      rowKey={(record) => record.id}
      rowSelection={{
        type: 'checkbox',
      }}
      onRow={(record) => {
        return {
          onClick: onClick
            ? () => {
                if (record) onClick(record);
              }
            : null,
        };
      }}
      locale={tableTranslations(t)}
      loading={{
        spinning: loading,
        indicator: <PrioSpinner alignSelf />,
      }}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '50', '100', '500', '1000', '5000'],
        locale: paginationTranslations(t),
      }}
      sticky={true}
    />
  );
};

export default PrioUserRoleTable;
