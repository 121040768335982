import React, { useState, Reducer, useReducer, useEffect } from 'react';
import { Table, notification, Modal, Space } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';
import { useDispatch } from 'react-redux';

import { createHourlyRate } from '../actions';
import { ProjectId, HourlyRateId } from '../../../models/Types';
import {
  CreateHourlyRateRequest,
  HourlyRate,
} from '../../../models/HourlyRate';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { createTemporaryId } from '../../../util';
import { tableTranslations } from '../../../util/table';
import CurrencyInput from '../../../components/CurrencyInput';
import PrioSpinner from '../../../components/PrioSpinner';
import DebouncedInputSearch from '../../../components/DebouncedInputField/DebouncedInputSearch';
import classNames from 'classnames';
import {
  apiDeleteHourlyRate,
  apiGetGlobalHourlyRateSuggestions,
  apiGetProjectHourlyRate,
  apiPushHourlyRateForProject,
} from '../api';
import ProjectPicker from './ProjectPicker';
import { ApiResult } from '../../../api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-row .ant-table-cell .title-suggestion-table-delete-button': {
      visibility: 'hidden',
    },
    '& .ant-table-row:hover .ant-table-cell .title-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
  },
  shadowWidget: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    minHeight: theme.old.spacing.baseSpacing * 5,
  },
  centered: {
    margin: 'auto auto',
  },
  numberInput: {
    flex: 1,
  },
  currencyLabel: {
    padding: `0 ${theme.old.spacing.unit(1)}px`,
    color: theme.old.typography.colors.muted,
  },
}));

interface HourlyTableProps {
  className?: string;
  hourlyRates: HourlyRate[];
  projectId: ProjectId;
  onIsDirtyChanged?: (isDirty: boolean, hourlyRates?: HourlyRate[]) => void;
  loading?: boolean;
}

interface TableEntry extends HourlyRate {
  key: string;
  addHourlyRateRow: boolean;
  isTemporary: boolean;
}

interface NewHourlyRate extends HourlyRate {
  isValid: boolean;
}

type UpdateHourlyRateKey = 'name' | 'internalValue' | 'externalValue';

type UpdateHourlyRateValue = string | number;

interface UpdateNewHourlyRateAction {
  key: UpdateHourlyRateKey;
  value: UpdateHourlyRateValue;
}

export const HourlyTable: React.FC<HourlyTableProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    hourlyRates: originalHourlyRates,
    projectId,
    onIsDirtyChanged,
    loading,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [hourlyRates, setHourlyRates] =
    useState<HourlyRate[]>(originalHourlyRates);
  const [newHourlyRateRowActive, setNewHourlyRateRowActive] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const emptyNewHourlyRate: NewHourlyRate = {
    hourlyRateId: 'temporary',
    projectId,
    name: '',
    internalValue: 0,
    externalValue: 0,
    isValid: false,
    rowVersion: null,
  };

  const [isProccessing, setIsProccessing] = useState<boolean>(false);

  const [modalDeleteHourlyRateVisible, setModalDeleteHourlyRateVisible] =
    useState<boolean>(false);
  const [
    modalAddDefaultHourlyRateVisible,
    setModalAddDefaultHourlyRateVisible,
  ] = useState<boolean>(false);
  const [
    modalAddProjectHourlyRateVisible,
    setModalAddProjectHourlyRateVisible,
  ] = useState<boolean>(false);
  const [hourlyRateToDelete, setHourlyRateToDelete] = useState<string>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const validate: (state: NewHourlyRate) => boolean = (state: NewHourlyRate) =>
    state.name.trim().length > 0 &&
    state.internalValue > 0 &&
    state.externalValue > 0;

  const onProjectSelectionChange = (value: string) => {
    setSelectedProject(value);
  };

  const loadDefaultHourRateAndAddToProject = async () => {
    setIsProccessing(true);
    setModalAddDefaultHourlyRateVisible(false);
    const { data } = await apiGetGlobalHourlyRateSuggestions();
    if (data) {
      let update: CreateHourlyRateRequest[] = [];
      data.forEach((hourlyRate) => {
        update.push({
          externalValue: hourlyRate.externalValue,
          internalValue: hourlyRate.internalValue,
          name: hourlyRate.name,
          projectId: projectId,
        });
      });
      // TODO UPDATE REQUEST
      await apiPushHourlyRateForProject(projectId, update);

      const getNewHourlyRates: ApiResult<HourlyRate[]> =
        await apiGetProjectHourlyRate(projectId);
      setHourlyRates(getNewHourlyRates.data);
    }
    setIsProccessing(false);
  };

  const loadProjectHourRateAndAddToProject = async () => {
    setIsProccessing(true);
    setModalAddProjectHourlyRateVisible(false);
    if (selectedProject) {
      const { data } = await apiGetProjectHourlyRate(selectedProject);
      if (data) {
        let update: CreateHourlyRateRequest[] = [];
        data.forEach((hourlyRate) => {
          update.push({
            externalValue: hourlyRate.externalValue,
            internalValue: hourlyRate.internalValue,
            name: hourlyRate.name,
            projectId: projectId,
          });
        });
        // TODO UPDATE REQUEST
        await apiPushHourlyRateForProject(projectId, update);

        const getNewHourlyRates: ApiResult<HourlyRate[]> =
          await apiGetProjectHourlyRate(projectId);
        setHourlyRates(getNewHourlyRates.data);
      }
    }
    setIsProccessing(false);
  };

  const deleteHourlyRate = async (hourlyRateId: HourlyRateId) => {
    const { result } = await apiDeleteHourlyRate(projectId, hourlyRateId);
    if (result.status >= 200 && result.status < 300) {
      //nothing to do
      const removeHourlyRates = hourlyRates.filter(
        (entry) => entry.hourlyRateId !== hourlyRateId
      );
      setHourlyRates(removeHourlyRates);
      setModalDeleteHourlyRateVisible(false);
      setHourlyRateToDelete(null);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('projects:errorMessages.deleteHourlyRateError'),
      });
    }
  };

  const updateHourlyRate = (
    hourlyRateId: HourlyRateId,
    key: UpdateHourlyRateKey,
    value: UpdateHourlyRateValue
  ) => {
    const updatedHourlyRates = hourlyRates.map((entry) =>
      entry.hourlyRateId === hourlyRateId ? { ...entry, [key]: value } : entry
    );
    setHourlyRates(updatedHourlyRates);

    if (onIsDirtyChanged) {
      const isDirty = !equals(updatedHourlyRates, originalHourlyRates);
      onIsDirtyChanged(isDirty, updatedHourlyRates);
    }
  };

  const saveNewHourlyRate = () => {
    const { projectId, name, internalValue, externalValue } = newHourlyRate;
    const temporaryId = createTemporaryId();
    dispatch(
      createHourlyRate(
        {
          projectId,
          name,
          internalValue,
          externalValue,
        },
        temporaryId
      )
    );
    cancelNewHourlyRateRow();
    updateNewHourlyRate('clear');
  };

  const enableNewHourlyRateRow = () => setNewHourlyRateRowActive(true);
  const cancelNewHourlyRateRow = () => setNewHourlyRateRowActive(false);
  //#endregion

  //#region ------------------------------ Reducers
  const [newHourlyRate, updateNewHourlyRate] = useReducer<
    Reducer<NewHourlyRate, UpdateNewHourlyRateAction | 'clear'>
  >((state: NewHourlyRate, action: UpdateNewHourlyRateAction | 'clear') => {
    if (action === 'clear') {
      return emptyNewHourlyRate;
    }
    const updatedState = { ...state, [action.key]: action.value };
    return {
      ...updatedState,
      isValid: validate(updatedState),
    };
  }, emptyNewHourlyRate);
  //#endregion

  //#region ------------------------------ Table
  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('projects:hourlyRatesTable.columnTitles.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record: TableEntry, index: number) => (
        <DebouncedInputSearch
          value={record.addHourlyRateRow ? newHourlyRate.name : value}
          placeholder={t('projects:hourlyRatesTable.newEntry.placeHolderName')}
          onChange={(value) =>
            record.addHourlyRateRow
              ? updateNewHourlyRate({
                  key: 'name',
                  value: value,
                })
              : updateHourlyRate(record.hourlyRateId, 'name', value)
          }
        />
      ),
      width: 300,
    },
    {
      title: t('projects:hourlyRatesTable.columnTitles.internalValue'),
      dataIndex: 'internalValue',
      sorter: (a, b) => a.internalValue - b.internalValue,
      render: (value, record) => (
        <Flex.Row>
          <CurrencyInput
            isoCode="EUR"
            value={
              record.addHourlyRateRow ? newHourlyRate.internalValue : value
            }
            onChange={(value) =>
              record.addHourlyRateRow
                ? updateNewHourlyRate({ key: 'internalValue', value })
                : updateHourlyRate(record.hourlyRateId, 'internalValue', value)
            }
            className={classes.numberInput}
            disabled={record.isTemporary}
          />
        </Flex.Row>
      ),
      width: 250,
    },
    {
      title: t('projects:hourlyRatesTable.columnTitles.externalValue'),
      dataIndex: 'externalValue',
      sorter: (a, b) => a.externalValue - b.externalValue,
      render: (value, record) => (
        <Flex.Row>
          <CurrencyInput
            isoCode="EUR"
            value={
              record.addHourlyRateRow ? newHourlyRate.externalValue : value
            }
            onChange={(value) =>
              record.addHourlyRateRow
                ? updateNewHourlyRate({ key: 'externalValue', value })
                : updateHourlyRate(record.hourlyRateId, 'externalValue', value)
            }
            className={classes.numberInput}
            disabled={record.isTemporary}
          />
        </Flex.Row>
      ),
      width: 250,
    },
    {
      render: (_, record) =>
        record.addHourlyRateRow ? (
          <Button
            type="primary"
            disabled={!newHourlyRate.isValid}
            onClick={saveNewHourlyRate}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <>
            <Button
              onClick={() => {
                setHourlyRateToDelete(record.hourlyRateId);
                setModalDeleteHourlyRateVisible(true);
              }}
              className="title-suggestion-table-delete-button"
              disabled={record.isTemporary}
              iconProp={['fal', 'trash']}
              type="link"
            ></Button>
          </>
        ),
      width: 80,
    },
  ];

  const data: TableEntry[] = hourlyRates.map(
    (hourlyRate: HourlyRate, index: number) => ({
      key: hourlyRate.hourlyRateId,
      ...hourlyRate,
      isTemporary: hourlyRate.isTemporary ?? false,
      addHourlyRateRow: false,
    })
  );
  //#endregion

  //#region ------------------------------ Components
  const footer = (countOfDatas) => (
    <div>
      <Space direction="vertical">
        <Space wrap>
          <Button
            type="link"
            onClick={enableNewHourlyRateRow}
            disabled={newHourlyRateRowActive}
            iconProp={['fal', 'plus']}
          >
            {t('projects:hourlyRatesTable.actions.add')}
          </Button>
          {countOfDatas === 0 && (
            <>
              <Button
                type="link"
                onClick={() => {
                  setModalAddDefaultHourlyRateVisible(true);
                }}
                disabled={newHourlyRateRowActive}
                iconProp={['fal', 'plus']}
              >
                {t('projects:hourlyRatesTable.actions.addDefault')}
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setModalAddProjectHourlyRateVisible(true);
                }}
                disabled={newHourlyRateRowActive}
                iconProp={['fal', 'plus']}
              >
                {t('projects:hourlyRatesTable.actions.addProject')}
              </Button>
            </>
          )}
        </Space>
      </Space>
    </div>
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setHourlyRates(originalHourlyRates);
    onIsDirtyChanged(false, originalHourlyRates);
  }, [originalHourlyRates, onIsDirtyChanged]);
  //#endregion

  return (
    <>
      <Table
        className={classNames(classes.root, className)}
        columns={columns}
        pagination={false}
        dataSource={
          newHourlyRateRowActive
            ? [
                ...data,
                {
                  key: 'addHourlyRate',
                  name: '',
                  internalValue: 0,
                  externalValue: 0,
                  addHourlyRateRow: true,
                },
              ]
            : data
        }
        footer={(data) => footer(data.length)}
        locale={tableTranslations(t)}
        loading={{
          spinning: loading || isProccessing,
          indicator: <PrioSpinner alignSelf />,
        }}
      />
      <Modal
        visible={modalDeleteHourlyRateVisible}
        onOk={() => deleteHourlyRate(hourlyRateToDelete)}
        onCancel={() => {
          setModalDeleteHourlyRateVisible(false);
          setHourlyRateToDelete(null);
        }}
        title={t('settings:modalDeleteHourlyRate.title')}
        okText={t('settings:modalDeleteHourlyRate.actionButton')}
        cancelText={t('settings:modalDeleteHourlyRate.cancelButton')}
      >
        {t('settings:modalDeleteHourlyRate.content')}
      </Modal>

      <Modal
        visible={modalAddDefaultHourlyRateVisible}
        onOk={() => {
          loadDefaultHourRateAndAddToProject();
        }}
        onCancel={() => {
          setModalAddDefaultHourlyRateVisible(false);
        }}
        title={t('projects:modalDefaultHourlyRate.title')}
        okText={t('projects:modalDefaultHourlyRate.actionButton')}
        cancelText={t('projects:modalDefaultHourlyRate.cancelButton')}
      >
        {t('projects:modalDefaultHourlyRate.content')}
      </Modal>

      <Modal
        visible={modalAddProjectHourlyRateVisible}
        onOk={() => {
          loadProjectHourRateAndAddToProject();
        }}
        onCancel={() => {
          setModalAddProjectHourlyRateVisible(false);
        }}
        title={t('projects:modalProjectHourlyRate.title')}
        okText={t('projects:modalProjectHourlyRate.actionButton')}
        cancelText={t('projects:modalProjectHourlyRate.cancelButton')}
      >
        <Flex.Row>
          <Flex.Column flex={1}>
            {t('projects:modalProjectHourlyRate.content')}
          </Flex.Column>
          <Flex.Column flex={1}>
            <ProjectPicker onChange={onProjectSelectionChange}></ProjectPicker>
          </Flex.Column>
        </Flex.Row>
      </Modal>
    </>
  );
};

export default HourlyTable;
