import React, { useMemo } from 'react';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getZendeskDrawerSearchResult } from '../../../apps/main/rootReducer';
import ReactHtmlParser from 'react-html-parser';
import { openZendeskDrawer } from '../actions/drawer';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  tabs: {
    height: '100%',
  },
  tabsContent: {
    overflow: 'hidden',
  },
  title: {
    '&.ant-typography': {
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
    },
  },
  button: {
    marginBottom: theme.old.spacing.unit(2),
    display: 'flex',
    alignItems: 'center',
  },
  details: {
    flex: 1,
    overflow: 'scroll',
    height: '100%',
  },
}));

interface ZendeskWidgetDetailsProps {
  className?: string;
  closeDetails?: VoidFunction;
}

export const ZendeskWidgetDetails: React.FC<ZendeskWidgetDetailsProps> = (
  props
) => {
  const classes = useStyles();
  const { className, closeDetails } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchResult = useSelector(getZendeskDrawerSearchResult);

  const resultBody = useMemo(
    () => ReactHtmlParser(searchResult.body),
    [searchResult]
  );

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row>
        <Button
          type="link"
          className={classes.button}
          onClick={() => closeDetails()}
          iconProp={['fal', 'chevron-left']}
        >
          {t('common:back')}
        </Button>
      </Flex.Row>
      <Typography.Title className={classes.title} level={2}>
        {searchResult.title}
      </Typography.Title>
      <div
        className={classes.details}
        onClick={() => dispatch(openZendeskDrawer(false))}
      >
        {resultBody}
      </div>
    </Flex.Column>
  );
};

export default ZendeskWidgetDetails;
