import React from 'react';
import { Divider, Typography } from 'antd';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  label: {
    marginLeft: theme.old.spacing.unit(0.25),
  },
  divider: {
    marginTop: theme.old.spacing.unit(5),
    marginBottom: theme.old.spacing.unit(3),
  },
}));

interface FormTitleProps {
  title?: string;
}

const FormTitle: React.FC<FormTitleProps> = (props) => {
  const classes = useStyles();
  const { title } = props;
  return (
    <>
      {title && (
        <Typography.Text className={classes.label}>{title}</Typography.Text>
      )}
      <Divider className={classes.divider} />
    </>
  );
};

export default FormTitle;
