import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Auth } from '../../auth/authHook';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { Typography, Image } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

interface SignInPageProps {
  auth: Auth;
}

const useStyles = makePrioStyles((theme) => ({
  root: {},
  button: {
    width: '100%',
  },
}));

export const SignInPage: React.FC<SignInPageProps> = (props) => {
  const {
    auth: { error, onSignIn, onSignOut, accounts, account },
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  return (
    <div
      className={classNames(
        'prio-flex-center-center',
        'prio-flex-column prio-container-fullscreen-height',
        classes.root
      )}
    >
      <Flex.Column childrenGap={theme.old.spacing.unit(6)} alignItems="center">
        <Image src={'/logo/Prio_Logo_rgb_S.png'} preview={false} />
        {!account ? (
          [
            accounts.map((account, i) => {
              // Return the element. Also pass key
              return (
                <Button
                  key={account.username}
                  onClick={() => onSignIn(account)}
                  className={classes.button}
                >
                  {t('common:auth.signIn')} - {account.username}
                </Button>
              );
            }),

            <Button
              key={'empty'}
              onClick={() => onSignIn(null)}
              className={classes.button}
            >
              {t('common:auth.signIn')}
            </Button>,
          ]
        ) : (
          <>
            <Button onClick={() => onSignOut()} className={classes.button}>
              {t('common:auth.signOut')} - Account: {accounts}
            </Button>
          </>
        )}
        {error && (
          <Typography.Paragraph>
            {t('common:auth.error.label')}: {error}
          </Typography.Paragraph>
        )}
      </Flex.Column>
    </div>
  );
};

export default SignInPage;
