import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line no-control-regex */
export const emailPattern = /^[A-Za-z0-9][\w^.+-]*@([\w+.-]+\.+)+[\w-]{2,15}$/g;

/* eslint-disable-next-line no-control-regex */
export const emailPrefixPattern = /^[A-Za-z0-9][\w^.+-]*$/;
function useEmailValidation(): (value: string) => string {
  const { t } = useTranslation();

  return (value) =>
    value === '' || value.match(emailPattern)
      ? ''
      : t('common:validation.email.error');
}

export default useEmailValidation;
