import { NavigateFunction } from 'react-router-dom';
import { PRIO } from '../../../../constants';
import { CalendarEvent } from '../../../../models/Calendar';
import {
  Message,
  MessageAttachment,
  MessageUpdate,
} from '../../../../models/Message';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import { ReduxAction } from '../../../../models/Redux';

export interface FetchMessagesSagaAction {
  type: string;
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  isInitial?: boolean;
  ignoreUrl?: boolean;
  resync?: boolean;
}

export const SAGA_FETCH_MESSAGES = PRIO + 'SAGA_FETCH_MESSAGES';

export const fetchMessagesSagaAction: (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  isInitial?: boolean,
  ignoreUrl?: boolean,
  resync?: boolean
) => FetchMessagesSagaAction = (
  projectId,
  mailFolderId,
  isInitial,
  ignoreUrl,
  resync
) => ({
  type: SAGA_FETCH_MESSAGES,
  projectId,
  mailFolderId,
  isInitial,
  ignoreUrl,
  resync: resync ?? true,
});

export interface FetchMailFoldersSagaAction {
  type: string;
  projectId: ProjectId;
  refetch?: boolean;
  mailFolderId?: MailFolderId;
}

export const SAGA_FETCH_MAIL_FOLDERS = PRIO + 'SAGA_FETCH_MAIL_FOLDERS';

export const fetchMailFoldersSagaAction: (
  projectId: ProjectId,
  refetch?: boolean,
  mailFolderId?: MailFolderId
) => FetchMailFoldersSagaAction = (projectId, refetch, mailFolderId) => ({
  type: SAGA_FETCH_MAIL_FOLDERS,
  projectId,
  refetch,
  mailFolderId,
});

interface NavigateProps {
  navigate: NavigateFunction;
  prefix?: string;
}

export interface DeleteMessagesSagaAction {
  type: string;
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  messages: Message[];
  navigateProps?: NavigateProps;
  onAfter?: VoidFunction;
  hardDelete?: boolean;
}

export const SAGA_DELETE_MESSAGES = PRIO + 'SAGA_DELETE_MESSAGES';

export const deleteMessageSagaAction: (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  messages: Message[],
  navigateProps?: NavigateProps,
  onAfter?: VoidFunction,
  hardDelete?: boolean
) => DeleteMessagesSagaAction = (
  projectId,
  mailFolderId,
  messages,
  navigateProps,
  onAfter,
  hardDelete
) => ({
  type: SAGA_DELETE_MESSAGES,
  projectId,
  mailFolderId,
  messages,
  navigateProps,
  onAfter,
  hardDelete,
});

export interface UpdateDraftMessageSagaAction {
  projectId: ProjectId;
  messageId: MessageId;
  update: MessageUpdate;
  attachments: MessageAttachment[];
  setMessageAttachments: (value: MessageAttachment[]) => void;
  updateAndSend: boolean;
  event?: CalendarEvent;
  then?: (success?: boolean) => void;
}

export const SAGA_UPDATE_DRAFT_MESSAGE = PRIO + 'SAGA_UPDATE_DRAFT_MESSAGE';

export const updateDraftMessage: (
  projectId: ProjectId,
  messageId: MessageId,
  update: MessageUpdate,
  attachments: MessageAttachment[],
  setMessageAttachments: (value: MessageAttachment[]) => void,
  updateAndSend: boolean,
  then?: (success?: boolean) => void
) => UpdateDraftMessageSagaAction = (
  projectId,
  messageId,
  update,
  attachments,
  setMessageAttachments,
  updateAndSend,
  then
) => ({
  type: SAGA_UPDATE_DRAFT_MESSAGE,
  projectId,
  messageId,
  update,
  attachments,
  setMessageAttachments,
  updateAndSend,
  then,
});

export interface FetchSingleMessageSagaAction {
  projectId: ProjectId;
  messageId: MessageId;
  navigate: NavigateFunction;
}

export const SAGA_FETCH_SINGLE_MESSAGE = PRIO + 'SAGA_FETCH_SINGLE_MESSAGE';

export const fetchSingleMessageSaga: (
  projectId: ProjectId,
  messageId: MessageId,
  navigate: NavigateFunction
) => FetchSingleMessageSagaAction = (projectId, messageId, navigate) => ({
  type: SAGA_FETCH_SINGLE_MESSAGE,
  projectId,
  messageId,
  navigate,
});

export const SAGA_STOP_UPDATE_DRAFT_MESSAGE =
  PRIO + 'SAGA_STOP_UPDATE_DRAFT_MESSAGE';

export const stopUpdateDraftSagaAction: (
  messageId: MessageId
) => ReduxAction<{ messageId: MessageId }> = (messageId) => ({
  type: SAGA_STOP_UPDATE_DRAFT_MESSAGE,
  meta: {
    messageId,
  },
});
