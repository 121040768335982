import React, { useState } from 'react';
import { Invoice, InvoicePayment } from '../../../models/Accounting';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { InvoicePaymentDetails } from './InvoicePaymentDetails';
import { Divider, Modal, notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import InvoicePaymentForm from './InvoicePaymentForm';
import { makePrioStyles } from '../../../theme/utils';
import { apiCreateInvoicePayment, apiDeleteInvoice } from '../api/';
import { OfficeId, ProjectId } from '../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  newPaymentForm: {
    marginTop: theme.old.spacing.unit(3),
  },
  scrollable: {
    overflow: 'auto',
  },
  deleteInvoiceButton: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
    },
  },
}));

interface InvoiceDetailsPaymentsProps {
  invoice: Invoice;
  reloadInvoices: VoidFunction;
  officeId?: OfficeId;
  projectId?: ProjectId;
  handleDrawerClose: () => void;
}

export const InvoiceDetailsPayments: React.FC<InvoiceDetailsPaymentsProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { invoice, reloadInvoices, projectId, officeId, handleDrawerClose } =
    props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDeleteInvoiceModalVisible, setIsDeleteInvoiceModalVisible] =
    useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleNewInvoicePayment = async (payment: InvoicePayment) => {
    setIsSaving(true);
    const { result } = await apiCreateInvoicePayment(
      payment,
      officeId,
      projectId
    );
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      reloadInvoices();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('accounting:errorMessages.createInvoicePayment'),
      });
      return false;
    }
  };

  const deleteInvoice = async () => {
    const { result } = await apiDeleteInvoice(
      invoice.invoiceId,
      officeId,
      projectId
    );
    if (result.status >= 200 && result.status < 300) {
      reloadInvoices();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('accounting:errorMessages.deleteInvoice'),
      });
    }
  };
  const handleInvoiceDelete = () => {
    deleteInvoice();
    handleDrawerClose();
  };

  const showDeleteInvoiceModal = () => {
    setIsDeleteInvoiceModalVisible(true);
  };
  const deleteInvoiceModalOkAction = () => {
    setIsDeleteInvoiceModalVisible(false);
    handleInvoiceDelete();
  };
  const deleteInvoiceModalCancelAction = () => {
    setIsDeleteInvoiceModalVisible(false);
  };

  const getRemainingAmount = () => {
    if (invoice) {
      const { grossSum } = invoice;
      if (grossSum) {
        const { value } = grossSum;
        const paymentsSum = invoice.invoicePayments.reduce(
          (sum, payment) => sum + payment.debitSum.value,
          0
        );
        return value - paymentsSum;
      }
    }
    return 0;
  };
  //#endregion

  return (
    <div className={classes.root}>
      <Flex.Column
        childrenGap={theme.old.spacing.unit(2)}
        className={classes.fullHeight}
      >
        <div className={classes.scrollable}>
          {invoice.invoicePayments.length > 0 ? (
            invoice.invoicePayments.map((p) => (
              <InvoicePaymentDetails
                payment={p}
                key={p.invoicePaymentId}
                reloadInvoices={reloadInvoices}
                invoiceId={invoice?.invoiceId}
                projectId={projectId}
              />
            ))
          ) : (
            <Flex.Column>
              <Typography.Text>
                {t('accounting:invoiceDetails.noPayments')}
              </Typography.Text>
              <Divider />
            </Flex.Column>
          )}
        </div>
        <Flex.Item flex={1}>
          <Typography.Title level={2}>
            {t('accounting:invoiceDetails.newPayment')}
          </Typography.Title>
          <InvoicePaymentForm
            disableForm={isSaving}
            className={classes.newPaymentForm}
            onFinish={handleNewInvoicePayment}
            actionLabel={t('accounting:paymentForm.actions.create')}
            invoiceId={invoice?.invoiceId}
            isoCode={invoice?.grossSum?.isoCode}
            amount={getRemainingAmount()}
          />
        </Flex.Item>
        <Flex.Row>
          <Button
            onClick={showDeleteInvoiceModal}
            type="link"
            className={classes.deleteInvoiceButton}
          >
            {t('accounting:invoiceDetails.deleteInvoiceAction')}
          </Button>
        </Flex.Row>
      </Flex.Column>
      <Modal
        visible={isDeleteInvoiceModalVisible}
        onOk={() => deleteInvoiceModalOkAction()}
        onCancel={() => deleteInvoiceModalCancelAction()}
        title={t('accounting:invoiceDetails.deleteInvoiceModal.title')}
        okText={t('accounting:invoiceDetails.deleteInvoiceModal.okText')}
        cancelText={t(
          'accounting:invoiceDetails.deleteInvoiceModal.cancelText'
        )}
      >
        {[
          t('accounting:invoiceDetails.deleteInvoiceModal.description', {
            invoiceNumber: invoice?.number,
          }),
        ]}
      </Modal>
    </div>
  );
};

export default InvoiceDetailsPayments;
