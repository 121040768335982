import { combineReducers, Reducer } from 'redux';
import {
  FETCH_HOURLY_RATES_COMMIT,
  FETCH_HOURLY_RATES_REQUEST,
  FETCH_HOURLY_RATES_ROLLBACK,
  CREATE_HOURLY_RATE_REQUEST,
  CREATE_HOURLY_RATE_ROLLBACK,
  CREATE_HOURLY_RATE_COMMIT,
  UPDATE_HOURLY_RATE_REQUEST,
  UPDATE_HOURLY_RATE_ROLLBACK,
  UPDATE_HOURLY_RATE_COMMIT,
} from '../actions';
import { HourlyRate } from '../../../models/HourlyRate';
import { ProjectId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface HourlyRatesState {
  data: DataState;
  meta: HourlyRatesMeta;
}

export interface DataState {
  [projectId: string]: HourlyRate[];
}

export const initialState: HourlyRatesState = {
  data: {},
  meta: { isFetching: false, hasError: false },
};

const data: Reducer<DataState, any> = (state = initialState.data, action) => {
  switch (action.type) {
    case FETCH_HOURLY_RATES_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: payload,
      };
    }

    /** CREATE */

    case CREATE_HOURLY_RATE_REQUEST: {
      const {
        payload,
        meta: { projectId, temporaryId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []),
          {
            ...payload,
            hourlyRateId: temporaryId,
            isTemporary: true,
          },
        ],
      };
    }

    case CREATE_HOURLY_RATE_ROLLBACK: {
      const {
        meta: { projectId, temporaryId },
      } = action;
      const hourlyRates = state[projectId];
      if (!hourlyRates) return state;
      return {
        ...state,
        [projectId]: hourlyRates.filter(
          (hourlyRate: HourlyRate) => hourlyRate.hourlyRateId !== temporaryId
        ),
      };
    }

    case CREATE_HOURLY_RATE_COMMIT: {
      const {
        meta: { projectId },
        payload,
      } = action;
      const hourlyRates = state[projectId];
      if (!hourlyRates) {
        return state;
      }
      return {
        ...state,
        [projectId]: payload.map((hourlyRate: HourlyRate) => ({
          ...hourlyRate,
          isTemporary: false,
        })),
      };
    }

    /** UPDATE */

    case UPDATE_HOURLY_RATE_REQUEST: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (hourlyRate) =>
              (payload as HourlyRate[]).findIndex(
                (hr) => hr.hourlyRateId === hourlyRate.hourlyRateId
              ) === -1
          ),
          ...payload,
        ],
      };
    }

    case UPDATE_HOURLY_RATE_ROLLBACK: {
      const {
        meta: { projectId },
        rollbackHourlyRates,
      } = action;
      if (!rollbackHourlyRates) return state;
      return {
        ...state,
        [projectId]: rollbackHourlyRates,
      };
    }

    case UPDATE_HOURLY_RATE_COMMIT: {
      const {
        meta: { projectId },
        payload: updatedHourlyRates,
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (hourlyRate) =>
              (updatedHourlyRates as HourlyRate[]).findIndex(
                (v) => v.hourlyRateId === hourlyRate.hourlyRateId
              ) === -1
          ),
          ...updatedHourlyRates,
        ],
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialState.data;
    }

    default:
      return state;
  }
};

interface HourlyRatesMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<HourlyRatesMeta, any> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_HOURLY_RATES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_HOURLY_RATES_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_HOURLY_RATES_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'projects:errorMessages.fetchHourlyRatesError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default:
      return state;
  }
};

export default combineReducers<HourlyRatesState>({
  data,
  meta,
});

export const getData: (state: any) => DataState = (state: any) => state.data;
export const getAllHourlyRates: (
  state: any,
  projectId: ProjectId
) => HourlyRate[] = (state: any, projectId: ProjectId) =>
  state.data[projectId] ?? [];

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
