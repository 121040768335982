import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { useSelector } from 'react-redux';
import {
  getProject,
  getProjectRedirect,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { makePrioStyles } from '../../../theme/utils';
import PrioSpinner from '../../../components/PrioSpinner';
import { ProjectId } from '../../../models/Types';
import Dashboard from '../../dashboard/components/Dashboard';
import { DashboardItem } from '../../../models/Dashboard';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  body: {
    overflow: 'hidden',
  },
}));

interface ProjectSummaryProps {
  projectId: ProjectId;
  isProjectMember?: boolean;
}

export const ProjectSummary: React.FC<ProjectSummaryProps> = (props) => {
  const { projectId, isProjectMember } = props;
  const classes = useStyles();

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const [dashboardItems, setDashboardItems] = useState<DashboardItem[]>([]);
  const generateRandomString = (length: number): string => {
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  useEffect(() => {
    const dashboardItemsToSet: DashboardItem[] = isProjectMember
      ? [
          {
            dashboardItemId: generateRandomString(10),
            type: 'projectInfos',
            layout: {
              x: 0,
              y: 0,
              width: 3,
              height: 2,
            },
            data: project,
          },
        ]
      : [
          {
            dashboardItemId: generateRandomString(10),
            type: 'projectInfos',
            layout: {
              x: 0,
              y: 0,
              width: 3,
              height: 2,
            },
            data: project,
          },
        ];
    setDashboardItems(dashboardItemsToSet);
  }, [project, isProjectMember]);

  const redirect = useSelector<RootReducerState, string>((state) =>
    getProjectRedirect(state, projectId)
  );

  if (redirect) {
    return <Navigate to={`../../${redirect}/summary`} />;
  }

  if (!project) {
    return (
      <Flex.Column className={classes.root}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </Flex.Column>
    );
  }

  return (
    <Flex.Column className={classes.root}>
      {<Dashboard dashboardItems={dashboardItems} />}
    </Flex.Column>
  );
};

export default ProjectSummary;
