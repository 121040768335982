import { makePrioStyles } from '../../theme/utils';
import {
  BasisFilterPicker,
  DateRangePicker,
  MonthRangePicker,
  YearRangePicker,
  BooleanFilterPicker,
  LogikFilterPicker,
  getSingleLabelFromUTSN,
  LIB_TRANSLATION_NAMESPACE,
} from '@prio365/prio365-react-library';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import FilterPicker, { FilterPickerTypeStrings } from './FilterPicker';
import { FilterConfig, PickerStrings, SearchOperation } from './types';
import { useMemo } from 'react';
import { DatePickerLocales } from '@prio365/prio365-react-library/lib/Date/utility/utsn';
import { formatDateRangePickerValue } from './utils';

const useStyles = makePrioStyles((theme) => ({
  fitWidth: {
    width: 'fit-content',
  },
}));

const ALLOWED_METHODS_HIERACHY: SearchOperation[] = ['like', 'eq', 'in'];

export interface DynamicFilterProps {
  filter: FilterConfig;
  pickerStrings: PickerStrings[];
  setPickerStrings: (parameterName: string, value: PickerStrings[]) => void;
  deletePickerStrings: (parameterName: string) => void;
}

export const DynamicFilterContainer: React.FC<DynamicFilterProps> = (props) => {
  const { filter, deletePickerStrings } = props;

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        deletePickerStrings(filter?.parameterName);
      }}
    >
      <DynamicFilter {...props} />
    </div>
  );
};

export default DynamicFilterContainer;

//#region ------------------------------ DynamicFilter

const DynamicFilter: React.FC<DynamicFilterProps> = (props) => {
  const { filter, pickerStrings, setPickerStrings } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const locale = useMemo(() => moment.locale(), []);

  const getFilterMethod = (allowedMethods: string[]): string => {
    for (let i = 0; i < ALLOWED_METHODS_HIERACHY.length; i++) {
      if (allowedMethods.includes(ALLOWED_METHODS_HIERACHY[i])) {
        return ALLOWED_METHODS_HIERACHY[i];
      }
    }
    return allowedMethods[0];
  };

  if (FilterPickerTypeStrings?.includes(filter?.pickerType))
    return (
      <FilterPicker
        zIndex="50000"
        className={classes.fitWidth}
        type={filter?.pickerType}
        prefixOption={
          filter?.parameterNameTranslated || filter?.parameterName
            ? `${
                filter?.parameterNameTranslated !== ''
                  ? filter?.parameterNameTranslated
                  : filter?.parameterName
              }:`
            : undefined
        }
        value={pickerStrings?.[0]?.value?.split(',') || null}
        onChange={(value) => {
          setPickerStrings(filter?.parameterName, [
            {
              method: getFilterMethod(filter.allowedMethods),
              value: value?.length > 0 ? value.join(',') : null,
            },
          ]);
        }}
        roles={filter?.pickerRoles}
      />
    );

  if (filter?.selectOptions?.[0]) {
    const mappedSelectOptions = filter?.selectOptions?.map((option) => ({
      value: option?.selectValue,
      label: option?.selectValueTranslated,
      searchValue: `${option?.selectValueTranslated} ${option?.selectValue}`,
    }));
    return (
      <BasisFilterPicker
        zIndex="50000"
        onChange={(value) =>
          setPickerStrings(filter?.parameterName, [
            {
              method: getFilterMethod(filter.allowedMethods),
              value: value?.length > 0 ? value.join(',') : null,
            },
          ])
        }
        value={pickerStrings?.[0]?.value?.split(',') || null}
        prefixOption={
          filter?.parameterNameTranslated || filter?.parameterName
            ? `${
                filter?.parameterNameTranslated !== ''
                  ? filter?.parameterNameTranslated
                  : filter?.parameterName
              }:`
            : undefined
        }
        options={mappedSelectOptions}
        placeholder={t('components:filter.all')}
        mode="multiple"
      />
    );
  }

  switch (filter?.parameterType) {
    case 'date':
    case 'dateTime':
      // es-lint-disable-next-line

      const pickerStringsWithoutRel = pickerStrings?.filter(
        (S) => S.method !== 'rel'
      );

      const relPickerString = pickerStrings?.find((S) => S.method === 'rel');

      var [startISOString, endISOString] =
        pickerStringsWithoutRel
          ?.map((S) => S.value)
          .sort((a, b) => a.localeCompare(b)) || [];

      return (
        <DateRangePicker
          locale={locale as DatePickerLocales}
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          placeholder={t(`${LIB_TRANSLATION_NAMESPACE}:select.date.range.all`)}
          key={filter?.parameterName}
          onChange={({ dateValue, utsn }) => {
            if (utsn) {
              setPickerStrings(filter?.parameterName, [
                {
                  method: 'rel',
                  value: utsn?.utsn,
                },
              ]);
            } else {
              setPickerStrings(filter?.parameterName, [
                {
                  method: 'ge',
                  value:
                    dateValue?.[0]
                      ?.clone()
                      ?.toISOString(true)
                      ?.substring(0, 23) || null,
                },
                {
                  method: 'le',
                  value:
                    dateValue?.[1]
                      ?.clone()
                      ?.endOf('day')
                      ?.toISOString(true)
                      ?.substring(0, 23) || null,
                },
              ]);
            }
          }}
          value={formatDateRangePickerValue(
            [
              startISOString?.length > 5 ? moment(startISOString) : null,
              endISOString?.length > 5 ? moment(endISOString) : null,
            ],
            relPickerString?.value
              ? (getSingleLabelFromUTSN(
                  relPickerString?.value,
                  'range',
                  locale as any
                ) as any)
              : null
          )}
        />
      );
    case 'month':
      // es-lint-disable-next-line
      [startISOString, endISOString] =
        pickerStrings?.map((S) => S.value).sort((a, b) => a.localeCompare(b)) ||
        [];
      return (
        <MonthRangePicker
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          onChange={(value) =>
            setPickerStrings(filter?.parameterName, [
              {
                method: 'ge',
                value: value?.[0]?.utc(true)?.toISOString(true) || null,
              },
              {
                method: 'le',
                value: value?.[1]?.utc(true)?.toISOString(true) || null,
              },
            ])
          }
          value={[
            startISOString?.length > 5
              ? moment(startISOString)?.utc(true)
              : null,
            endISOString?.length > 5
              ? moment(endISOString)?.utc(true)?.subtract(2, 'hours')
              : null,
          ]}
          className={classes.fitWidth}
        />
      );
    case 'year':
      // es-lint-disable-next-line
      [startISOString, endISOString] =
        pickerStrings?.map((S) => S.value).sort((a, b) => a.localeCompare(b)) ||
        [];

      return (
        <YearRangePicker
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          onChange={(value) =>
            setPickerStrings(filter?.parameterName, [
              {
                method: 'ge',
                value: value?.[0]?.utc(true)?.toISOString(true) || null,
              },
              {
                method: 'le',
                value: value?.[1]?.utc(true)?.toISOString(true) || null,
              },
            ])
          }
          value={[
            startISOString?.length > 5
              ? moment(startISOString)?.utc(true)
              : null,
            endISOString?.length > 5
              ? moment(endISOString)?.utc(true)?.subtract(2, 'hours')
              : null,
          ]}
          className={classes.fitWidth}
        />
      );
    case 'boolean':
      return (
        <BooleanFilterPicker
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          className={classes.fitWidth}
          value={
            pickerStrings?.[0]?.value
              ? JSON.parse(pickerStrings?.[0]?.value || 'null')
              : null
          }
          onChange={(value) => {
            setPickerStrings(filter?.parameterName, [
              {
                method: getFilterMethod(filter.allowedMethods),
                value: JSON.stringify(value),
              },
            ]);
          }}
        />
      );
    default:
      return (
        <LogikFilterPicker
          key={filter?.parameterName}
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          parameterType={filter?.parameterType as any}
          className={classes.fitWidth}
          allowedMethods={filter?.allowedMethods || []}
          value={pickerStrings?.length > 0 ? (pickerStrings as any) : []}
          onChange={(value: any) => {
            setPickerStrings(filter?.parameterName, value);
          }}
        />
      );
  }
};
//#endregion
