import { notification } from 'antd';
import {
  actionChannel,
  call,
  cancelled,
  delay,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  getActiveMessageId,
  getActiveProject,
  getCurrentSearchMailFolderId,
  getCurrentSearchTerm,
  getMailSearchIsFetching,
  getMailSearchNextLink,
  getMessagesById,
  getMessagesIdsState,
  getMessagesIsFetching,
  getMessagesNextLink,
  getMovingMessages,
  getProject,
  isLoggedIn,
} from '../../../apps/main/rootReducer';
import i18n from '../../../i18n';
import { dispatchSaga, SAGA_REBUILD } from '../../../util/sagas';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import { fetchMessages } from '../actions/actionControllers/messageActionController';
import { fetchMailSearch } from '../actions/actionControllers/searchActionController';
import { FetchMessagesSagaAction, SAGA_FETCH_MESSAGES } from '../actions/sagas';
import { MessageId, ProjectId } from '../../../models/Types';
import { Message, MessageNextLinkPayload } from '../../../models/Message';
import {
  deleteLocalProjectMessagesBeforeInitialInboxCommit,
  setIsFetchingProjectMailFolder,
} from '../actions/projects/messages';
import equals from 'deep-equal';

function* handleCleanUpInboxInitial(
  data: MessageNextLinkPayload,
  projectId: ProjectId,
  setCommitEnabled: (value: boolean) => void
) {
  try {
    if (projectId !== 'me') {
      const byId: {
        [messageId: string]: Message;
      } = yield select((state) => getMessagesById(state, projectId));
      const messageIds: MessageId[] = yield select(
        (state) => getMessagesIdsState(state, projectId)?.inbox ?? []
      );
      const movingMessages: MessageId[] = yield select((state) =>
        getMovingMessages(state)
      );

      const currentProject = yield select((state) => getActiveProject(state));
      let disableCommit = true;

      for (let i = 0; disableCommit && i < data.messages.length; i++) {
        const fetchedMessage = data.messages[i];
        const storedMessage = byId[fetchedMessage.id];
        if (
          currentProject?.projectId !== projectId ||
          !storedMessage ||
          storedMessage.changeKey < fetchedMessage.changeKey ||
          storedMessage.isRead !== fetchedMessage.isRead ||
          !equals(storedMessage.flag, fetchedMessage.flag) ||
          storedMessage.parentFolderId !== fetchedMessage.parentFolderId ||
          !equals(
            storedMessage.customSingleValueExtendedProperties,
            fetchedMessage.customSingleValueExtendedProperties
          )
        ) {
          disableCommit = false;
        }
      }

      if (!disableCommit) {
        const activeMessageId = yield select((state) =>
          getActiveMessageId(state, projectId)
        );
        yield put(
          deleteLocalProjectMessagesBeforeInitialInboxCommit(
            projectId,
            messageIds.filter(
              (id) => !movingMessages.includes(id) && id !== activeMessageId
            )
          )
        );
      } else {
        yield put(setIsFetchingProjectMailFolder(projectId, 'inbox', false));
      }
      setCommitEnabled(!disableCommit);
    }
  } catch (error) {
    console.error(
      'Error in watchMessagesFetch - handleCleanUpInboxInitial:',
      data,
      projectId
    );
  }
}

function* handleMessages(action: FetchMessagesSagaAction) {
  const { projectId, mailFolderId, isInitial, ignoreUrl, resync } = action;
  try {
    const searchTerm = yield select((state) =>
      getCurrentSearchTerm(state, projectId)
    );
    const searchTermMailFolderId = yield select((state) =>
      getCurrentSearchMailFolderId(state, projectId)
    );

    if (
      !searchTerm &&
      !ignoreUrl &&
      !(
        window.location.href.includes(
          `module/prio/projects/${projectId}/mail/${mailFolderId}`
        ) ||
        window.location.href.includes(
          'module/prio/projects/me/messageCenter/favorites/mail/inbox'
        ) ||
        window.location.href.includes(
          `module/prio/projects/me/messageCenter/${projectId}/mail/inbox`
        )
      )
    ) {
      return;
    }

    const sharedMailboxFolderId = yield select(
      (state) => getProject(state, projectId)?.sharedMailboxUserId
    );

    const isFetching = yield select(
      !searchTerm
        ? (state) => getMessagesIsFetching(state, projectId, mailFolderId)
        : (state) =>
            getMailSearchIsFetching(state, projectId, searchTermMailFolderId)
    );

    const nextLink = yield select(
      !searchTerm
        ? (state) => getMessagesNextLink(state, projectId, mailFolderId) // get messages count by foler
        : (state) =>
            getMailSearchNextLink(state, projectId, searchTermMailFolderId)
    );

    const movingMessageIds = yield select((state) => getMovingMessages(state));

    if (!isFetching && (nextLink !== '' || isInitial)) {
      if (isInitial) {
        if (searchTerm) {
          yield call(
            dispatchSaga,
            fetchMailSearch(
              searchTerm,
              projectId,
              searchTermMailFolderId === 'allFolders'
                ? null
                : searchTermMailFolderId,
              undefined
            )
          );
        } else {
          if (mailFolderId === 'inbox') {
            if (projectId !== 'me') {
              yield call(
                dispatchSaga,
                fetchMessages(
                  projectId,
                  mailFolderId,
                  movingMessageIds,
                  [],
                  resync,
                  undefined,
                  sharedMailboxFolderId
                ),
                {
                  beforeCommit: function* (
                    data: MessageNextLinkPayload,
                    actionObject,
                    setCommitEnabled
                  ) {
                    return yield call(
                      handleCleanUpInboxInitial,
                      data,
                      projectId,
                      setCommitEnabled
                    );
                  },
                }
              );
            } else if (nextLink === null || nextLink === undefined) {
              yield call(
                dispatchSaga,
                fetchMessages(
                  projectId,
                  mailFolderId,
                  movingMessageIds,
                  [],
                  resync,
                  undefined,
                  sharedMailboxFolderId
                )
              );
            }
          } else {
            const messageIds: MessageId[] = yield select(
              (state) =>
                getMessagesIdsState(state, projectId)?.[mailFolderId] ?? []
            );
            const messageIdsToDelete = messageIds.filter(
              (messageId) => !movingMessageIds.includes(messageId)
            );
            yield call(
              dispatchSaga,
              fetchMessages(
                projectId,
                mailFolderId,
                movingMessageIds,
                messageIdsToDelete,
                resync,
                undefined,
                sharedMailboxFolderId
              )
            );
          }
        }
      } else {
        if (searchTerm) {
          yield spawn(
            dispatchSaga,
            fetchMailSearch(
              searchTerm,
              projectId,
              searchTermMailFolderId === 'allFolders'
                ? null
                : searchTermMailFolderId,
              nextLink
            )
          );
        } else {
          yield spawn(
            dispatchSaga,
            fetchMessages(
              projectId,
              mailFolderId,
              movingMessageIds,
              [],
              resync,
              nextLink,
              sharedMailboxFolderId
            )
          );
        }
        yield delay(10);
      }
    }
  } catch (error) {
    console.error(
      'Error in message saga fetching messages - Original Error',
      error
    );
    console.error(
      'Error in message saga fetching messages - Data',
      `projectId: ${projectId},`,
      `mailFolderId: ${mailFolderId}`
    );
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.messages.fetchError'),
    });
  }
}

function* actionPipeline() {
  let lastAction: FetchMessagesSagaAction = null;
  try {
    const channel = yield actionChannel(SAGA_FETCH_MESSAGES);
    while (true) {
      const payload = yield take(channel);
      try {
        lastAction = payload;
        yield call(handleMessages, payload);
        lastAction = null;
      } catch (error) {
        console.error('Error in watchMessagesFetch - actionpipeline', error);
        notification.open({
          message: i18n.t('common:error'),
          description: i18n.t('mail:errorMessages.messages.fetchError'),
        });
      }
    }
  } finally {
    if (yield cancelled() && lastAction !== null) {
      yield call(handleMessages, lastAction);
      lastAction = null;
    }
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(actionPipeline), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in watchMessagesFetch - mainTask', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.messages.fetchError'),
    });
    yield mainTask();
  }
}

export default function* watchMessagesFetch() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
