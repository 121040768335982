import { useContext, useMemo } from 'react';
import FilterContext from '../context';
import { FilterContextProps, GenericSearchResult } from '../types';

const useFilterContext = () => {
  const context = useContext(FilterContext);
  return context;
};

export const useFilterContextDataConvertion = <
  ResultData = unknown,
  CalculatedData = unknown,
>(
  data: GenericSearchResult<ResultData, CalculatedData>,
  includedCalculated?: boolean
) => {
  return useMemo(() => {
    return (data?.items ?? []).map(({ data, calculated: calculatedData }) => {
      if (includedCalculated) {
        return { ...data, ...calculatedData };
      }
      return data;
    });
  }, [data, includedCalculated]);
};

export default useFilterContext as <
  ResultData = unknown,
  CalculatedData = unknown,
>() => FilterContextProps<ResultData, CalculatedData>;
