import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popover, Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { MonthlyClose } from '../../../models/TimeKeeping';
import moment from 'moment';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  circularProgressbar: {
    marginLeft: -2,
    marginRight: 6,
    width: 60,
    height: 60,
    paddingTop: 6,
  },
  heading: {
    fontSize: 12,
    fontWeight: 600,
    opacity: 0.8,
    whiteSpace: 'nowrap',
  },
  popoverLarge: {
    width: 450,
  },
  popoverSmall: {
    width: 300,
  },

  infoIcon: {
    padding: '0px 8px',
    height: '14px',
    width: '14px',
    color: theme.old.palette.chromaticPalette.grey,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface WorkingHoursCircularProgressChartProps {
  className?: string;
  monthlyClose: MonthlyClose;
}

export const WorkingHoursCircularProgressChart: React.FC<
  WorkingHoursCircularProgressChartProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, monthlyClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const actualHoursLessThanExpected =
    monthlyClose?.actualWorkHours < monthlyClose?.expectedWorkHoursToDate;

  const isCurrentMonth: boolean = useMemo(() => {
    return monthlyClose?.month.substring(0, 7) === moment().format('YYYY-MM');
  }, [monthlyClose]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row className={classNames(classes.root, className)}>
      <CircularProgressbarWithChildren
        value={
          (((actualHoursLessThanExpected
            ? monthlyClose?.actualWorkHours
            : monthlyClose?.expectedWorkHoursToDate) ?? 1) /
            ((actualHoursLessThanExpected
              ? monthlyClose?.expectedWorkHoursToDate
              : monthlyClose?.actualWorkHours) ?? 1)) *
          100
        }
        circleRatio={0.75}
        strokeWidth={16}
        className={classes.circularProgressbar}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 8,
          strokeLinecap: 'butt',
          trailColor: actualHoursLessThanExpected
            ? theme.old.palette.chromaticPalette.lightGrey
            : theme.old.palette.chromaticPalette.lightBlue,
          pathColor: theme.old.palette.backgroundPalette.base,
        })}
      />
      <Flex.Column justifyContent="center" childrenGap={4}>
        <Flex.Row alignItems="center">
          <div className={classes.heading}>
            {t('timeAndLeaveManagement:calendar.toolbar.recordedTimeKeeping')}
          </div>
          <Popover
            trigger="click"
            content={
              <Typography.Paragraph
                className={
                  isCurrentMonth ? classes.popoverLarge : classes.popoverSmall
                }
                style={{ marginBottom: '0px' }}
              >
                {t(
                  `timeAndLeaveManagement:calendar.toolbar.popover1_${
                    isCurrentMonth ? 'current' : 'past'
                  }`,
                  {
                    expectedToDate: monthlyClose?.expectedWorkHoursToDate,
                    expected: monthlyClose?.expectedWorkHours,
                  }
                )}
              </Typography.Paragraph>
            }
          >
            <FontAwesomeIcon
              icon={['fal', 'info-circle']}
              className={classes.infoIcon}
            />
          </Popover>
        </Flex.Row>
        <div style={{ fontWeight: 600 }}>
          {`${monthlyClose?.actualWorkHours ?? 0} h von  ${
            isCurrentMonth
              ? monthlyClose?.expectedWorkHoursToDate ?? 0
              : monthlyClose?.expectedWorkHours ?? 0
          } h`}
        </div>
      </Flex.Column>
    </Flex.Row>
  );
};

export default WorkingHoursCircularProgressChart;
