import { useCallback, useEffect, useState } from 'react';
import { apiFetchDocumentTemplateCategories } from '../api';
import { DocumentTemplateCategory } from '../../../models/DocumentTemplate';
import DocumentTemplateCategoryContext from '../context/DocumentTemplateCategoryContext';

const DocumentTemplateCategoryContextProvider: React.FC = ({ children }) => {
  //#region ------------------------------ Defaults
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [data, setData] = useState<DocumentTemplateCategory[]>([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchDocumentTemplateCategories = useCallback(async () => {
    const { data } = await apiFetchDocumentTemplateCategories();
    if (data && Array.isArray(data)) {
      setData(data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, []);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    fetchDocumentTemplateCategories();
  }, [fetchDocumentTemplateCategories]);
  //#endregion

  return (
    <DocumentTemplateCategoryContext.Provider
      value={{ data, fetchDocumentTemplateCategories }}
    >
      {children}
    </DocumentTemplateCategoryContext.Provider>
  );
};

export default DocumentTemplateCategoryContextProvider;
