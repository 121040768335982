import { Col, Form, Input, Modal, Row, Switch } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import {
  CreateProjectDraftByProjectDto,
  emptyCreateProjectDraftByProjectDto,
} from '../../../../models/Project';
import { ConfigurationKeys } from '../../../../models/Types';
import { PrioTheme } from '../../../../theme/types';
import { defaultProjectNrPlaceholder } from '../../../../util';
import { colon, rowGutter } from '../../../../util/forms';
import { apiFetchConfigurations } from '../../../settings/api';
import { useProjectDraftValidate } from '../../hooks/useProjectDraftValidate';
import ProjectPicker from '../ProjectPicker';

interface CreateProjectFromProjectModalProps {
  createProjectFromDraftModelVisible: boolean;
  setCreateProjectFromDraftModelVisible: (visible: boolean) => void;
  handleFinished: (projectToCreate: CreateProjectDraftByProjectDto) => void;
}

export const CreateProjectFromProjectModal: React.FC<
  CreateProjectFromProjectModalProps
> = (props) => {
  const {
    createProjectFromDraftModelVisible,
    setCreateProjectFromDraftModelVisible,
    handleFinished,
  } = props;
  const theme = useTheme<PrioTheme>();
  const [projectNrPlaceholder, setProjectNumberPlaceholder] =
    useState<string>(null);
  const [createFromDraftForm] = Form.useForm();
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [dataDirty, setDataDirty] = useState<boolean>(true);

  const handleCreateProjectFromDraftModalOk = (
    values: CreateProjectDraftByProjectDto
  ) => {
    if (handleFinished) {
      handleFinished(values);
    }
    createFromDraftForm.resetFields();
    setCreateProjectFromDraftModelVisible(false);
  };

  var {
    validationResult,
    validateProjectCreationData,
    projectToCreateIsValid,
    projectToCreateValidating,
  } = useProjectDraftValidate(createFromDraftForm);

  useEffect(() => {
    setDisabled(true);
    if (validationResult) {
      if (
        !validationResult?.projectName?.isValid &&
        createFromDraftForm.isFieldTouched('name')
      ) {
        createFromDraftForm.setFields([
          {
            name: 'name',
            errors: [validationResult?.projectName?.description],
          },
        ]);
      } else {
        createFromDraftForm.setFields([
          {
            name: 'name',
            errors: [],
          },
        ]);
      }
      if (
        !validationResult?.projectNumber?.isValid &&
        createFromDraftForm.isFieldTouched('number')
      ) {
        createFromDraftForm.setFields([
          {
            name: 'number',
            errors: [validationResult?.projectNumber?.description],
          },
        ]);
      } else {
        createFromDraftForm.setFields([
          {
            name: 'number',
            errors: [],
          },
        ]);
      }
    }
    setDataDirty(false);
    setDisabled(false);
  }, [validationResult, createFromDraftForm, t]);

  useEffect(() => {
    if (!disabled) {
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) {
          const config = data.find(
            (configuration) =>
              configuration.key === ConfigurationKeys.PLACEHOLDER_PROJECTNR
          );
          setProjectNumberPlaceholder(config?.value ?? null);
        }
      };
      loadConfigs();
    }
  }, [disabled]);

  return (
    <Modal
      visible={createProjectFromDraftModelVisible}
      onOk={() => {
        createFromDraftForm.submit();
      }}
      okButtonProps={{
        disabled:
          projectToCreateValidating || !projectToCreateIsValid || dataDirty,
      }}
      onCancel={() => setCreateProjectFromDraftModelVisible(false)}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            createFromDraftForm.resetFields();
            setCreateProjectFromDraftModelVisible(false);
          }}
          type="link"
        >
          {t('projects:projectCreation.createFromDraftProject.cancel')}
        </Button>,
        <Button
          key="validate"
          onClick={() => {
            createFromDraftForm.validateFields();
          }}
          type="default"
          style={{ marginInline: theme.old.spacing.unit(1) }}
        >
          {t('projects:projectCreation.createFromDraftProject.validate')}
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={() => {
            createFromDraftForm.submit();
          }}
          disabled={
            dataDirty || projectToCreateValidating || !projectToCreateIsValid
          }
        >
          {t('projects:projectCreation.createFromDraftProject.confirm')}
        </Button>,
      ]}
      title={t('projects:projectCreation.createFromDraftProject.title')}
    >
      <Form
        form={createFromDraftForm}
        initialValues={emptyCreateProjectDraftByProjectDto}
        onFinish={handleCreateProjectFromDraftModalOk}
        layout="vertical"
      >
        <Row gutter={theme.old.spacing.unit(rowGutter)} justify="center">
          <Col span={24}>
            <Form.Item
              name="draftProjectId"
              label={t('projects:form.labels.draftProject')}
              required={true}
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.missingDraftProject'),
                },
              ]}
            >
              <ProjectPicker disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)} justify="center">
          <Col span={8}>
            <Form.Item
              name="number"
              label={t('projects:form.labels.number')}
              labelAlign="left"
              validateTrigger="onBlur"
              colon={colon}
              required={true}
              rules={[
                () => ({
                  async validator(rule, value) {
                    await validateProjectCreationData();
                  },
                }),
              ]}
            >
              <Input
                placeholder={
                  projectNrPlaceholder ?? defaultProjectNrPlaceholder
                }
                disabled={disabled}
                onChange={() => setDataDirty(true)}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="name"
              label={t('projects:form.labels.name')}
              validateTrigger="onBlur"
              colon={colon}
              required={true}
              rules={[
                () => ({
                  async validator(rule, value) {
                    await validateProjectCreationData();
                  },
                }),
              ]}
            >
              <Input disabled={disabled} onChange={() => setDataDirty(true)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)} justify="center">
          <Col span={12}>
            <Form.Item
              name="useHourlyRates"
              label={t('projects:form.labels.useHourlyRates')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="useKilometerRates"
              label={t('projects:form.labels.useKilometerRates')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)} justify="center">
          <Col span={12}>
            <Form.Item
              name="useInternalProjectContacts"
              label={t('projects:form.labels.useInternalProjectContacts')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="useExternalProjectContacts"
              label={t('projects:form.labels.useExternalProjectContacts')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)} justify="center">
          <Col span={12}>
            <Form.Item
              name="useProjectSettings"
              label={t('projects:form.labels.useProjectSettings')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="useDistributionLists"
              label={t('projects:form.labels.useDistributionLists')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
