import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../../actions';
import { Message } from '../../../../models/Message';
import { ReduxAction } from '../../../../models/Redux';
import { MailFolderId, MessageId } from '../../../../models/Types';
import {
  DELETE_LOCAL_MESSAGE_ME,
  DELETE_MESSAGES_ME_REQUEST,
  SOFTDELETE_MESSAGES_ME_REQUEST,
  UPDATE_MESSAGE_ME,
} from '../../actions/me/messagesMe';
import {
  ADD_DRAFT_MESSAGE_ME,
  ADD_SENDING_MESSAGE_ID_ME,
  CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_COMMIT,
  CREATE_NEW_MESSAGE_DRAFT_ME_COMMIT,
  REMOVE_DRAFT_MESSAGE_ME,
  REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_ME,
  REMOVE_SENDING_MESSAGE_ID_ME,
  SET_ACTIVE_DRAFT_MESSAGE_ID_ME,
} from '../../actions/me/draftsMe';
import { DraftMessagesState } from '../../actions/types';
import { DELETE_ALL_LOCAL_DRAFTS } from '../../actions/actionControllers/draftsActionController';
import { distinct } from '../../../../util';

export const initialState: DraftMessagesState = {
  openDrafts: [],
  initialMessageIds: [],
  activeDraftMessageId: null,
  sendingMessageIds: [],
};

const openDrafts: Reducer<
  Message[],
  ReduxAction<
    {
      mailFolderId: MailFolderId;
      messageId: MessageId;
      messageIds: MessageId[];
    },
    Message & { messageUpdate: Partial<Message> }
  >
> = (state = initialState.openDrafts, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_COMMIT:
    case CREATE_NEW_MESSAGE_DRAFT_ME_COMMIT: {
      const { payload: newDraft } = action;
      return [...state, newDraft as Message];
    }

    case UPDATE_MESSAGE_ME: {
      const {
        payload,
        meta: { messageId },
      } = action;
      const draftIndex = state.findIndex((draft) => draft.id === messageId);
      if (draftIndex > -1) {
        const newState = state;
        newState.splice(draftIndex, 1, {
          ...state[draftIndex],
          ...payload.messageUpdate,
        });
        return newState;
      }
      return state;
    }

    case SOFTDELETE_MESSAGES_ME_REQUEST:
    case DELETE_MESSAGES_ME_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      if (state.find((draftMessage) => messageIds.includes(draftMessage.id))) {
        return state.filter(
          (draftMessage) => !messageIds.includes(draftMessage.id)
        );
      }
      return state;
    }

    case DELETE_LOCAL_MESSAGE_ME: {
      const {
        meta: { messageId },
      } = action;
      if (state.find((draft) => draft.id === messageId)) {
        return state.filter((draft) => draft.id !== messageId);
      }
      return state;
    }

    case ADD_DRAFT_MESSAGE_ME: {
      const { message } = action;
      if (state.find((draft) => draft.id === message.id)) {
        return state;
      }
      return [...state, message];
    }

    case REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_ME:
    case REMOVE_DRAFT_MESSAGE_ME: {
      const { messageId } = action;
      if (state.find((draft) => draft.id === messageId)) {
        return state.filter((draft) => draft.id !== messageId);
      }
      return state;
    }

    case DELETE_ALL_LOCAL_DRAFTS:
    case CLEAR_PRIO_CACHE: {
      return initialState.openDrafts;
    }

    default:
      return state;
  }
};

const initialMessageIds: Reducer<
  MessageId[],
  ReduxAction<{ messageId: MessageId; messageIds: MessageId[] }, Message>
> = (state = initialState.initialMessageIds, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_COMMIT:
    case CREATE_NEW_MESSAGE_DRAFT_ME_COMMIT: {
      const { payload: newDraft } = action;
      return [...state, newDraft.id];
    }

    case DELETE_LOCAL_MESSAGE_ME:
    case UPDATE_MESSAGE_ME: {
      const {
        meta: { messageId },
      } = action;
      return state.filter((initialMessageId) => initialMessageId !== messageId);
    }

    case REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_ME:
    case REMOVE_DRAFT_MESSAGE_ME: {
      const { messageId } = action;
      return state.filter((initialMessageId) => initialMessageId !== messageId);
    }

    case ADD_DRAFT_MESSAGE_ME: {
      const { messageId, isInitial } = action;
      if (isInitial) {
        return [...state, messageId];
      }
      return state;
    }

    case SOFTDELETE_MESSAGES_ME_REQUEST:
    case DELETE_MESSAGES_ME_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      if (state.find((draftMessageId) => messageIds.includes(draftMessageId))) {
        return state.filter(
          (draftMessageId) => !messageIds.includes(draftMessageId)
        );
      }
      return state;
    }

    case DELETE_ALL_LOCAL_DRAFTS:
    case CLEAR_PRIO_CACHE: {
      return initialState.initialMessageIds;
    }

    default:
      return state;
  }
};

const activeDraftMessageId: Reducer<
  MessageId,
  ReduxAction<{ messageId: MessageId; messageIds: MessageId[] }, Message>
> = (state = initialState.activeDraftMessageId, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_COMMIT:
    case CREATE_NEW_MESSAGE_DRAFT_ME_COMMIT: {
      const { payload: newDraft } = action;
      return newDraft.id;
    }

    case SET_ACTIVE_DRAFT_MESSAGE_ID_ME: {
      return action.messageId;
    }

    case DELETE_LOCAL_MESSAGE_ME: {
      const {
        meta: { messageId },
      } = action;
      if (messageId !== state) {
        return state;
      }
      return initialState.activeDraftMessageId;
    }

    case ADD_DRAFT_MESSAGE_ME: {
      const { message } = action;
      return message.id;
    }

    case REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_ME:
    case REMOVE_DRAFT_MESSAGE_ME: {
      const { messageId } = action;
      if (messageId !== state) {
        return state;
      }
      return initialState.activeDraftMessageId;
    }

    case SOFTDELETE_MESSAGES_ME_REQUEST:
    case DELETE_MESSAGES_ME_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      if (messageIds.includes(state)) {
        return initialState.activeDraftMessageId;
      }
      return state;
    }

    case DELETE_ALL_LOCAL_DRAFTS:
    case CLEAR_PRIO_CACHE: {
      return initialState.activeDraftMessageId;
    }

    default:
      return state;
  }
};

const sendingMessageIds: Reducer<
  MessageId[],
  ReduxAction & { messageId: MessageId }
> = (state = initialState.sendingMessageIds, action) => {
  switch (action.type) {
    case ADD_SENDING_MESSAGE_ID_ME: {
      const { messageId } = action;
      return distinct([...state, messageId]);
    }
    case REMOVE_SENDING_MESSAGE_ID_ME: {
      const { messageId } = action;
      return state.filter((id) => id !== messageId);
    }
    case RESET_PRIO_CACHE:
    case DELETE_ALL_LOCAL_DRAFTS:
    case CLEAR_PRIO_CACHE: {
      return initialState.sendingMessageIds;
    }

    default:
      return state;
  }
};
export default combineReducers<DraftMessagesState>({
  openDrafts,
  initialMessageIds,
  activeDraftMessageId,
  sendingMessageIds,
});

export const getDraftMessages = (state: DraftMessagesState) =>
  state.openDrafts ?? initialState.openDrafts;

export const getDraftMessage = (
  state: DraftMessagesState,
  messageId: MessageId
) => state.openDrafts?.find((draft) => draft.id === messageId);

export const getDraftMessageIsInitial = (
  state: DraftMessagesState,
  messageId: MessageId
) => state.initialMessageIds?.includes(messageId);

export const getActiveDraftMessageId = (state: DraftMessagesState) =>
  state.activeDraftMessageId;

export const getSendingMessageIds = (state: DraftMessagesState) =>
  state.sendingMessageIds;
