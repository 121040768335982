import React, { useCallback, useEffect, useState } from 'react';
import {
  BasisPicker,
  BasisPickerSelectOptions,
} from '../../../../components/BasisPicker';
import { apiFetchTodoLists } from '../../api/index';
import { TodoList, TodoTaskFormModel } from '../../../../models/Todo';
import { FormInstance } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface TodoListPickerProps {
  form?: FormInstance<TodoTaskFormModel>;
  disabled?: boolean;
}

export const TodoListPicker: React.FC<TodoListPickerProps> = (props) => {
  const { form, disabled } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isFetching, setIsFetching] = useState<boolean>();
  const [todoListPickerOptions, setTodoListPickerOptions] = useState<
    BasisPickerSelectOptions[]
  >([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnSelect = (listId: string) => {
    form.setFieldsValue({
      todoListId: listId,
    });
  };
  const mapTodoListToBasisPickerOption = (todoList: TodoList) => {
    const basisPickerOption: BasisPickerSelectOptions = {
      label: todoList.displayName,
      value: todoList.id,
      key: todoList.id,
    };
    return basisPickerOption;
  };

  const fetchTodoLists = useCallback(() => {
    const loadTodoLists = async () => {
      try {
        const { data } = await apiFetchTodoLists();
        if (data) {
          setTodoListPickerOptions(
            Array.from(data, (todoList) =>
              mapTodoListToBasisPickerOption(todoList)
            )
          );

          // data.map((todoList) =>
          //   setTodoListPickerOptions([
          //     ...todoListPickerOptions,
          //     {
          //       title: todoList.displayName,
          //       value: todoList.displayName,
          //       key: todoList.id,
          //     },
          //   ])
          // );
        }
        setIsFetching(false);
      } catch {}
    };
    setIsFetching(true);
    loadTodoLists();
  }, [setTodoListPickerOptions]);
  //#endregion

  //#region ------------------------------ useEffects
  useEffect(() => {
    fetchTodoLists();
  }, [fetchTodoLists]);

  //#endregion

  return (
    <BasisPicker
      className={classes.root}
      disabled={disabled}
      loading={isFetching}
      items={todoListPickerOptions}
      onSelect={handleOnSelect}
    />
  );
};

export default TodoListPicker;
