import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  FormInstance,
  Row,
  Select,
  Slider,
  TimePicker,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';

import {
  TimeRecordEntryMoment,
  TimeRecordFormModel,
} from '../../../../../models/TimeRecord';
import { Form } from 'antd';
import { makePrioStyles } from '../../../../../theme/utils';
import { Moment } from 'moment';

import Flex from '../../../../../components/Flex';

import * as MomentObject from 'moment-timezone';

import TimeRecordPreview from './TimeRecordPreview';

import Checkbox from 'antd/lib/checkbox/Checkbox';
import useDatePickerLocale from '../../../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { extendMoment } from 'moment-range';
import CustomDateRangePicker from '../../../../../components/CustomDateRangePicker';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../../theme/types';

const moment = extendMoment(MomentObject);

const useStyles = makePrioStyles((theme) => ({
  root: {},
  datePicker: {
    width: '100%',
  },
  duration: {
    transition: 'border-color 0.3s',
  },
  time: {
    width: theme.old.spacing.unit(14),
    transition: 'border-color 0.3s',
  },
  timePicker: {
    width: `100%`,
    display: 'flex',
    marginRight: theme.old.spacing.unit(1),
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  slider: {
    marginLeft: '12px',
    marginRight: '12px',
  },
}));

interface TimeRecordEntryListProps {
  initialTimeRecordEntries: TimeRecordEntryMoment[];
  isFormDisabled: boolean;
  form: FormInstance<TimeRecordFormModel>;
  timeRecordForm: FormInstance<TimeRecordFormModel>;
  resetTimerecordformRef: React.MutableRefObject<VoidFunction>;
  onTimeRecordEntryAdd?: () => void;
  onTimeRecordEntryChange?: () => void;
}

export const TimeRecordEntryManagement: React.FC<TimeRecordEntryListProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    initialTimeRecordEntries,
    form,
    timeRecordForm,
    resetTimerecordformRef,
  } = props;
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  const initialDate = [
    initialTimeRecordEntries[0]?.startTime ?? moment(),
    initialTimeRecordEntries[(initialTimeRecordEntries?.length ?? 0) - 1]
      ?.endTime ?? moment(),
  ];

  //#endregion

  //#region -------------------------------- State declaration
  const [pickedWorkingDaysRange, setPickedWorkingDaysRange] =
    useState<Moment[]>(initialDate);

  const [timeRecords, setTimeRecords] = useState<TimeRecordEntryMoment[]>(
    initialTimeRecordEntries
  );
  const [timeRecordsSliderRange, setTimeRecordsSliderRange] = useState<
    [[number, number]]
  >([[8, 40]]);

  const [periods, setPeriods] = useState<[Moment, Moment][]>([
    [moment('8:00', 'h:mm a'), moment('16:00', 'h:mm a')],
  ]);

  const [currentBreakDuration, setCurrentBreakDuration] = useState<number>(0);
  const [isBreakOpen, setIsBreakOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [isToday, setIsToday] = useState<boolean>(true);

  //const [isFormDisabled, setIsFormDisabled] = useState<boolean>(isFormDisabled);

  //#endregion

  //#region -------------------------------- Methods

  //Converts Slider-Index into Timestring
  const calculateTime = (range: [number, number], startTime: number) => {
    let minStr = '';
    var _value = range[1] - range[0];
    const _x = (_value * 15) / 60;
    const hour = Math.trunc(_x) + startTime;
    const min = Math.round((_x - Math.trunc(_x)) * 60);
    if (min.toString().length === 1) {
      minStr = min.toString() + '0';
    } else {
      minStr = min.toString();
    }
    return `${hour}:${minStr}`;
  };

  //Calculates Difference between both Moments
  const calculateRange = (index: number) => {
    var _timeRanges = [];
    var _timeRecordsSliderRange =
      timeRecordsSliderRange[index] === undefined
        ? 8
        : timeRecordsSliderRange[index][0];

    for (var i = 0; i < 57 - _timeRecordsSliderRange; i++) {
      _timeRanges.push(i);
    }
    return _timeRanges;
  };

  //Converts Moment into Slider-Index
  const decalculateTime = (m1: Moment, m2: Moment) => {
    const hour1 = ((m1.hour() - 6) / 15) * 60;
    const min1 = m1.minute() / 15;

    const index1 = hour1 + min1;

    const hour2 = ((m2.hour() - 6) / 15) * 60;
    const min2 = m2.minute() / 15;

    const index2 = hour2 + min2;
    return [index1, index2];
  };

  //#endregion

  //#region -------------------------------- Handle methods

  const handleOnDateChange = (value: { startDate; endDate }) => {
    let startDate = value['startDate'] as Moment;
    let endDate = value['endDate'] as Moment;
    if (startDate && endDate) {
      setPickedWorkingDaysRange([
        value['startDate'] as Moment,
        value['endDate'] as Moment,
      ]);
    } else if (startDate && endDate === null) {
      setPickedWorkingDaysRange([
        value['startDate'] as Moment,
        value['startDate'] as Moment,
      ]);
    } else {
      setPickedWorkingDaysRange([
        moment().startOf('day'),
        moment().startOf('day'),
      ]);
      setIsToday(true);
    }
  };

  const handleAddTimeRecordEntry = () => {
    if (!isBreakOpen) {
      const _timeRecords = timeRecords;
      const _timeRecordItem: TimeRecordEntryMoment = {
        key: timeRecords.length,
        startTime: moment(periods[periods.length - 1][1].toISOString()),
        endTime: moment(periods[periods.length - 1][1].toISOString()).add(
          1,
          'hours'
        ),
      };
      _timeRecords.push(_timeRecordItem);
      setTimeRecords([..._timeRecords]);

      const _periods = periods;
      _periods.push([
        moment(periods[periods.length - 1][1].toISOString()),
        moment(periods[periods.length - 1][1].toISOString()).add(1, 'hours'),
      ]);

      const _timeRecordsSliderRange = timeRecordsSliderRange;
      _timeRecordsSliderRange.push([
        decalculateTime(
          moment(periods[periods.length - 1][0]),
          moment(periods[periods.length - 1][0]).add(1, 'hours')
        )[0],
        decalculateTime(
          moment(periods[periods.length - 1][0]),
          moment(periods[periods.length - 1][0]).add(1, 'hours')
        )[1],
      ]);

      setTimeRecordsSliderRange(_timeRecordsSliderRange);
      timeRecordForm.setFieldsValue({ periods: _periods });
    } else {
      const start = moment(periods[periods.length - 1][1].toISOString()).add(
        currentBreakDuration,
        'minutes'
      );
      const end = moment(start.toISOString());

      if (!start.isSame(periods[periods.length - 1][0])) {
        start.day(periods[periods.length - 1][0].day());
      }
      if (!end.isSame(periods[periods.length - 1][1])) {
        end.day(periods[periods.length - 1][1].day());
      }

      const _timeRecords = timeRecords;
      const _timeRecordItem: TimeRecordEntryMoment = {
        key: timeRecords.length,
        startTime: moment(start),
        endTime: moment(end).add(1, 'hours'),
      };
      _timeRecords.push(_timeRecordItem);
      setTimeRecords([..._timeRecords]);

      const _periods = periods;
      _periods.push([moment(start), moment(end).add(1, 'hours')]);
      const _timeRecordsSliderRange = timeRecordsSliderRange;
      _timeRecordsSliderRange.push([
        decalculateTime(moment(start), moment(end).add(1, 'hours'))[0],
        decalculateTime(moment(start), moment(end).add(1, 'hours'))[1],
      ]);

      setTimeRecordsSliderRange(_timeRecordsSliderRange);

      timeRecordForm.setFieldsValue({ periods: _periods });
    }
  };

  const handleOnTimeRecordChange = (index: number, value: [number, number]) => {
    const rowToUpdate = timeRecords[index];

    rowToUpdate.startTime = moment(calculateTime([0, value[0]], 6), 'h:mm a');

    rowToUpdate.endTime = moment(calculateTime([0, value[1]], 6), 'h:mm a');

    setTimeRecords([
      ...timeRecords.slice(0, index),
      rowToUpdate,
      ...timeRecords.slice(index + 1),
    ]);
  };

  const handleBreakDurationChange = (value: number) => {
    if (value === 0) {
      setIsBreakOpen(false);
    }
    setCurrentBreakDuration(value);
  };

  const handleOnDeleteTimeRecord = (index: number) => {
    let _r = JSON.parse(JSON.stringify(timeRecordsSliderRange));
    _r.splice(index, 1);
    setTimeRecordsSliderRange(_r);

    let _timeRecords = timeRecords;
    _timeRecords.splice(index, 1);
    setTimeRecords([..._timeRecords]);

    let _periods = periods;
    _periods.splice(index, 1);
    setPeriods(_periods);
  };

  const handleOnRangeChange = (
    value: [number, number],
    index: number,
    isSlider: boolean
  ) => {
    const _periods = periods;
    if (isSlider) {
      let _records = JSON.parse(JSON.stringify(timeRecordsSliderRange));

      _records[index] = value;
      setTimeRecordsSliderRange(_records);
      _periods[index] = [
        moment(calculateTime([0, value[0]], 6), 'h:mm a'),
        moment(calculateTime([0, value[1]], 6), 'h:mm a'),
      ];

      timeRecordForm.setFieldsValue({ periods: _periods });

      handleOnTimeRecordChange(index, value);
    } else {
      let _dis = value[1] - value[0];
      let _records = JSON.parse(JSON.stringify(timeRecordsSliderRange));

      _records[index] = [_records[index][0], _records[index][0] + _dis];

      setTimeRecordsSliderRange(_records);

      _periods[index] = [
        moment(calculateTime([0, _records[index][0]], 6), 'h:mm a'),
        moment(calculateTime([0, _records[index][0] + _dis], 6), 'h:mm a'),
      ];

      timeRecordForm.setFieldsValue({ periods: _periods });

      handleOnTimeRecordChange(index, _records[index]);
    }
  };

  const handleReset = useCallback(() => {
    setPeriods([[moment('8:00', 'h:mm a'), moment('16:00', 'h:mm a')]]);
    setTimeRecords(initialTimeRecordEntries);
    setTimeRecordsSliderRange([[8, 40]]);
    const dayRange: [Moment, Moment] =
      timeRecordForm.getFieldsValue(true).dayRange;
    setIsExpanded(!dayRange[0].isSame(dayRange[1], 'days'));
  }, [timeRecordForm, initialTimeRecordEntries]);

  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    resetTimerecordformRef.current = handleReset;
  }, [resetTimerecordformRef, handleReset]);

  useEffect(() => {
    if (pickedWorkingDaysRange[0] && pickedWorkingDaysRange[1]) {
      const range = moment.range(
        pickedWorkingDaysRange[0],
        pickedWorkingDaysRange[1]
      );
      var rangeBetweenWorkingdays = Array.from(range.by('days'));
      if (rangeBetweenWorkingdays.length > 1) {
        setIsExpanded(true);

        setIsToday(false);
      } else {
        setIsExpanded(false);
        setIsToday(true);
      }
    }
  }, [pickedWorkingDaysRange]);

  //#endregion

  return (
    <Form.Provider
      onFormFinish={(name: string) => {
        if (name === 'timeRecordsManagement') {
          setPeriods([[moment('8:00', 'h:mm a'), moment('16:00', 'h:mm a')]]);
          setTimeRecordsSliderRange([[8, 40]]);
        }
      }}
    >
      <Form
        className={classes.root}
        initialValues={{
          periods: [[moment('8:00', 'h:mm a'), moment('16:00', 'h:mm a')]],
        }}
        form={timeRecordForm}
        onValuesChange={(
          changedValues,
          allValues: {
            periods: [Moment, Moment][];
          }
        ) => {
          if (changedValues.periods) {
            const updatedPeriods = [...changedValues.periods];
            const itemCount = allValues.periods.length;
            const result = Array<[Moment, Moment]>(itemCount);
            const _timeRecordsSliderRange = Array<[number, number]>(itemCount);

            const _timeRecords = Array<TimeRecordEntryMoment>(itemCount);
            for (let i = 0; i < result.length; i++) {
              result[i] = updatedPeriods[i] || periods[i];
              _timeRecordsSliderRange[i] =
                updatedPeriods[i] !== undefined
                  ? [
                      decalculateTime(
                        updatedPeriods[i][0],
                        updatedPeriods[i][1]
                      )[0],
                      decalculateTime(
                        updatedPeriods[i][0],
                        updatedPeriods[i][1]
                      )[1],
                    ]
                  : [
                      decalculateTime(periods[i][0], periods[i][1])[0],
                      decalculateTime(periods[i][0], periods[i][1])[1],
                    ];

              _timeRecords[i] =
                updatedPeriods[i] !== undefined
                  ? {
                      startTime: updatedPeriods[i][0],
                      endTime: updatedPeriods[i][1],
                    }
                  : {
                      startTime: periods[i][0],
                      endTime: periods[i][1],
                    };
            }
            setTimeRecords([..._timeRecords]);
            setTimeRecordsSliderRange(
              _timeRecordsSliderRange as [[number, number]]
            );
            setPeriods(result);
          }
        }} /**/
      >
        <section>
          <Row gutter={theme.old.spacing.unit(theme.old.spacing.unit(2))}>
            <Col span={24}>
              <Form.Item
                name="dayRange"
                initialValue={initialDate}
                label={t('timeRecords:form.labels.day')}
                rules={[
                  {
                    required: true,
                    message: t('timeRecords:form.validation.missingDay'),
                  },
                ]}
              >
                <CustomDateRangePicker
                  disabled={props.isFormDisabled}
                  startDate={pickedWorkingDaysRange[0]}
                  startDateId="timeRecord_entryManagement_startDateId"
                  startDatePlaceholderText={moment()
                    .add('1', 'day')
                    .startOf('day')
                    .format('DD.MM.YYYY')}
                  endDate={pickedWorkingDaysRange[1]}
                  endDateId="timeRecord_entryManagement_endDateId"
                  endDatePlaceholderText={moment()
                    .add('1', 'day')
                    .startOf('day')
                    .format('DD.MM.YYYY')}
                  onDatesChange={handleOnDateChange}
                  anchorDirection={'ANCHOR_RIGHT'}
                  small={true}
                  regular={false}
                  withFullScreenPortal={false}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                  minimumNights={0}
                  showClearDates={isToday ? false : true}
                  isOutsideRange={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.List name="periods">
            {(fields) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={
                        index === 0
                          ? t('timeRecords:form.labels.timeRecordEntries')
                          : null
                      }
                      required={false}
                      key={field.key}
                    >
                      <Row>
                        <Col span={fields.length > 1 ? 22 : 24}>
                          <Slider
                            className={classes.slider}
                            range
                            defaultValue={[8, 40]}
                            disabled={false}
                            min={0.0}
                            max={56.0}
                            tipFormatter={(value) => (
                              <p>{calculateTime([0, value], 6)}</p>
                            )}
                            onChange={(e) => {
                              handleOnRangeChange(e, index, true);
                              timeRecordForm.validateFields([
                                ['periods', field.name],
                              ]);
                            }}
                            value={timeRecordsSliderRange[index]}
                          />
                          <Flex.Row>
                            <Flex.Item flexGrow={1}>
                              <div className={classes.duration}>
                                <Row>
                                  <Col
                                    span={8}
                                    style={{
                                      paddingRight: theme.old.spacing.unit(1),
                                    }}
                                  >
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      onSelect={(e) => {
                                        handleOnRangeChange(
                                          [0, parseInt(e)],
                                          index,
                                          false
                                        );

                                        timeRecordForm.validateFields([
                                          ['periods', field.name],
                                        ]);
                                      }}
                                      value={calculateTime(
                                        [
                                          decalculateTime(
                                            periods[index] === undefined
                                              ? moment('8:00', 'h:mm a')
                                              : periods[index][0],
                                            periods[index] === undefined
                                              ? moment('16:00', 'h:mm a')
                                              : periods[index][1]
                                          )[0],
                                          decalculateTime(
                                            periods[index] === undefined
                                              ? moment('8:00', 'h:mm a')
                                              : periods[index][0],
                                            periods[index] === undefined
                                              ? moment('8:00', 'h:mm a')
                                              : periods[index][1]
                                          )[1],
                                        ],
                                        0
                                      )}
                                    >
                                      {calculateRange(index).map(
                                        (quarterStep, i) => (
                                          <Select.Option
                                            key={i}
                                            value={quarterStep}
                                          >
                                            {calculateTime([0, quarterStep], 0)}
                                          </Select.Option>
                                        )
                                      )}
                                    </Select>
                                  </Col>
                                  <Col span={16}>
                                    <Form.Item
                                      {...field}
                                      rules={[
                                        {
                                          required: true,
                                          message: t(
                                            'timeRecords:form.validation.missingTimeRecordEntry'
                                          ),
                                        },
                                        () => ({
                                          async validator(rule, value) {
                                            if (value) {
                                              const start: Moment = value[0];
                                              const end: Moment = value[1];
                                              if (
                                                end.diff(start, 'minutes') < 0
                                              ) {
                                                return Promise.reject(
                                                  t(
                                                    'timeRecords:form.validation.invalidTimeRecordEntry'
                                                  )
                                                );
                                              }

                                              if (
                                                periods.filter((period, i) => {
                                                  return (
                                                    i !== index &&
                                                    period &&
                                                    !(
                                                      (period[0].diff(
                                                        start,
                                                        'minutes'
                                                      ) >= 0 &&
                                                        period[0].diff(
                                                          end,
                                                          'minutes'
                                                        ) >= 0) ||
                                                      (start.diff(
                                                        period[1],
                                                        'minutes'
                                                      ) >= 0 &&
                                                        end.diff(
                                                          period[1],
                                                          'minutes'
                                                        ) >= 0)
                                                    )
                                                  );
                                                }).length > 0
                                              ) {
                                                return Promise.reject(
                                                  t(
                                                    'timeRecords:form.validation.overlappingTimeRecordEntries'
                                                  )
                                                );
                                              }
                                              return Promise.resolve();
                                            }
                                            return Promise.reject();
                                          },
                                        }),
                                      ]}
                                      noStyle
                                    >
                                      <TimePicker.RangePicker
                                        disabled={false}
                                        locale={datePickerLocale}
                                        className={classes.timePicker}
                                        disabledHours={() => [
                                          0, 1, 2, 3, 4, 5, 21, 22, 23,
                                        ]}
                                        disabledMinutes={(
                                          selectedHour: number
                                        ) => {
                                          if (selectedHour === 20) {
                                            return [15, 30, 45];
                                          } else {
                                            return [];
                                          }
                                        }}
                                        //@ts-ignore
                                        picker="time"
                                        format="HH:mm"
                                        suffixIcon={null}
                                        minuteStep={15}
                                        allowClear={false}
                                        showNow={false}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Flex.Item>
                          </Flex.Row>
                        </Col>
                        <Col
                          span={fields.length > 1 ? 2 : 0}
                          style={{ marginTop: '32px' }}
                        >
                          <Flex.Item flex={1}>
                            <div className={classes.deleteButton}>
                              <Button
                                onClick={() => {
                                  handleOnDeleteTimeRecord(index);
                                }}
                                type="link"
                                iconProp={['fal', 'trash']}
                              ></Button>
                            </div>
                          </Flex.Item>
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Flex.Row>
                    <Flex.Item>
                      <Button
                        onClick={() => handleAddTimeRecordEntry()}
                        type="link"
                        iconProp={['fal', 'plus']}
                        style={{ marginRight: theme.old.spacing.unit(1) }}
                      >
                        {isBreakOpen
                          ? t(
                              'timeRecords:form.actions.addTimeRecordEntryWithBreak'
                            )
                          : t('timeRecords:form.actions.addTimeRecordEntry')}
                      </Button>
                    </Flex.Item>
                    <Flex.Item>
                      <Form.Item>
                        {isBreakOpen ? (
                          <Select
                            onSelect={handleBreakDurationChange}
                            defaultValue={30}
                          >
                            <Select.Option value={0}>
                              <Typography.Text>
                                {t(
                                  'timeRecords:form.actions.breakDuration.noBreak'
                                )}
                              </Typography.Text>
                            </Select.Option>
                            <Select.Option value={15}>
                              <Typography.Text>
                                {t(
                                  'timeRecords:form.actions.breakDuration.15Min'
                                )}
                              </Typography.Text>
                            </Select.Option>
                            <Select.Option value={30}>
                              <Typography.Text>
                                {t(
                                  'timeRecords:form.actions.breakDuration.30Min'
                                )}
                              </Typography.Text>
                            </Select.Option>
                            <Select.Option value={45}>
                              <Typography.Text>
                                {t(
                                  'timeRecords:form.actions.breakDuration.45Min'
                                )}
                              </Typography.Text>
                            </Select.Option>
                            <Select.Option value={60}>
                              <Typography.Text>
                                {t(
                                  'timeRecords:form.actions.breakDuration.60Min'
                                )}
                              </Typography.Text>
                            </Select.Option>
                          </Select>
                        ) : (
                          <Checkbox
                            onChange={(e) => {
                              setIsBreakOpen(e.target.checked);
                              e.target.checked
                                ? setCurrentBreakDuration(30)
                                : setCurrentBreakDuration(0);
                            }}
                          >
                            {t('timeRecords:form.actions.break')}
                          </Checkbox>
                        )}
                      </Form.Item>
                    </Flex.Item>
                  </Flex.Row>
                </div>
              );
            }}
          </Form.List>

          <TimeRecordPreview
            isExpanded={isExpanded}
            workingDaysRange={pickedWorkingDaysRange}
            pickedTimeRecords={timeRecords}
            form={form}
          />
        </section>
      </Form>
    </Form.Provider>
  );
};

export default TimeRecordEntryManagement;
