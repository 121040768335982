import React, { useMemo } from 'react';
import { Drawer } from 'antd';
import EditContact from './EditContact';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../theme/utils';
import { CompanyId, ContactId } from '../../../models/Types';
import ContactDetailsDrawer from './ContactDetailsDrawer';
import { Contact } from '../../../models/Contact';
import CreateContact from './CreateContact';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  flexColExpand: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },

  contactDetails: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export declare type SubDrawerState = 'none' | 'details' | 'edit' | 'create';

interface ContactSubDrawerProps {
  onClose: VoidFunction;
  subDrawerState: SubDrawerState;
  companyId: CompanyId;
  contactId?: ContactId;
  onNewContact: (contactId: ContactId) => void;
  onStartEditContact: (contactId: ContactId) => void;
  onFinishEditContact: (contactId: ContactId) => void;
  onRedirect: (contactId: ContactId) => void;
}

export const ContactSubDrawer: React.FC<ContactSubDrawerProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    subDrawerState,
    companyId,
    contactId,
    onClose,
    onNewContact,
    onStartEditContact,
    onFinishEditContact,
    onRedirect,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Components
  const content = useMemo(() => {
    switch (subDrawerState) {
      case 'details': {
        return (
          <ContactDetailsDrawer
            contactId={contactId}
            onEdit={onStartEditContact}
            onRedirect={onRedirect}
            isSubDrawer
          />
        );
      }
      case 'create': {
        const initialValuesContactForm: Contact = {
          contactId: '',
          title: '',
          firstName: '',
          lastName: '',
          department: '',
          position: '',
          eMail: '',
          eMail2: '',
          phone: '',
          phone2: '',
          cellphone: '',
          cellphone2: '',
          fax: '',
          notes: '',
          companyId: companyId,
        };
        return (
          <CreateContact
            className={classNames(classes.contactDetails, classes.noPadding)}
            onCancel={onClose}
            onFinish={onNewContact}
            initialValues={initialValuesContactForm}
            isSubDrawer
            noAvatar
          />
        );
      }
      case 'edit': {
        return (
          <EditContact
            className={classNames(classes.contactDetails, classes.noPadding)}
            formClassName={classes.flexColExpand}
            contactId={contactId}
            onFinish={onFinishEditContact}
            onCancel={onClose}
            isSubDrawer
            noAvatar
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [
    classes,
    subDrawerState,
    contactId,
    companyId,
    onClose,
    onNewContact,
    onStartEditContact,
    onFinishEditContact,
    onRedirect,
  ]);
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Drawer
      visible={subDrawerState !== 'none'}
      onClose={onClose}
      className={classes.root}
      width={600}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      destroyOnClose
    >
      {content}
    </Drawer>
  );
};

export default ContactSubDrawer;
