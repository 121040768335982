import React from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
  useParams,
} from 'react-router-dom';
import { PersonnelFiles } from './personnelFiles/PersonnelFiles';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import HRAbsence from '../../absences/components/HRAbsence';
import HRTimekeeping from './timeAndLeaveManagement/HRTimekeeping';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
  },
}));

interface HROfficeModuleProps {
  className?: string;
}

export const HROfficeModule: React.FC<HROfficeModuleProps> = (props) => {
  const classes = useStyles();
  const { className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <Routes>
        <Route path=":officeId/*" element={<RoutingWrapper />}></Route>
      </Routes>
    </div>
  );
};

export default HROfficeModule;

interface RoutingWrapperProps {}
const RoutingWrapper: React.FC<RoutingWrapperProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { officeId } = useParams();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Routes>
      <Route path="" element={<Navigate to="absence" replace />} />
      <Route path="absence">
        <Route path="" element={<Navigate to="overview" replace />} />
        <Route
          path=":selectedList/*"
          element={
            <HRAbsence pathPrefix="../" className={classes.fullHeight} />
          }
        />
      </Route>
      <Route path="timekeeping">
        <Route
          path=""
          element={
            <Navigate
              to={`timekeepingDays${
                officeId ? `?s=Calculated.OfficeId+eq+%27${officeId}%27` : ''
              }`}
              replace
            />
          }
        />
        <Route
          path=":selectedList/*"
          element={
            <HRTimekeeping pathPrefix="../" className={classes.fullHeight} />
          }
        />
      </Route>

      <Route
        path="personnelFiles/*"
        element={<PersonnelFiles className={classes.fullHeight} />}
      />
    </Routes>
  );
};
