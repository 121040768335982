import React, { useCallback, useEffect, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Typography, notification, Drawer, Switch, Card, Tabs } from 'antd';

import equals from 'deep-equal';
import { InvoicesTable } from './InvoicesTable';
import { InvoiceDetails } from './InvoiceDetails';
import { AccountingOverviewPage } from './AccountingOverviewPage';
import {
  emptyAccountingOverview,
  Invoice,
  CreateInvoice,
  AccountingFilter,
} from '../../../models/Accounting';
import { ProjectId, OfficeId } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import InvoiceForm from './InvoiceForm';
import { apiCreateInvoice, apiFetchInvoices } from '../api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import moment from 'moment';
import TimespanPeriodFilter, {
  TimespanFilter,
} from '../../common/TimespanPeriodFilter';
import AdvancedInvoiceFilter from './AdvancedInvoiceFilter';
import AccountingSearchbar from './AccountingSearchbar';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    overflowX: 'scroll',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  filterTabs: {
    '& .ant-tabs-tab': {
      paddingTop: 0,
    },
    overflow: 'visible',
  },
  cardWidget: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%',
  },
  drawerContent: {
    height: '100%',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
}));

interface InvoicesManagementProps {
  createInvoiceActive: boolean;
  onClose: VoidFunction;
  projectId?: ProjectId;
  officeId?: OfficeId;
}

export const InvoicesManagement: React.FC<InvoicesManagementProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    createInvoiceActive,
    onClose: onNewInvoiceClose,
    projectId,
    officeId,
  } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [open, setOpen] = useState<boolean>(false);

  const [showPayments, setShowPayments] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>();
  const [invoices, setInvoices] = useState<Invoice[] | null>(null);
  const [originalInvoices, setOriginalInvoices] = useState<Invoice[] | null>(
    null
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFilterReset, setIsFilterReset] = useState<boolean>(false);

  const period: TimespanFilter = {
    from: moment().startOf('W'),
    to: moment().endOf('W'),
  };

  const [currentFilter, setCurrentFilter] = useState<TimespanFilter>(null);
  const [accountingFilter, setAccountingFilter] =
    useState<AccountingFilter>(null);
  const [abortController, setAbortController] = useState<AbortController>(null);
  //#endregiong

  //#region ------------------------------ Methods / Handlers
  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedInvoice(null);
    onNewInvoiceClose();
  }, [onNewInvoiceClose]);

  const onInvoiceSelect = useCallback(
    (invoice: Invoice) => {
      onNewInvoiceClose();
      setSelectedInvoice(invoice);
      setOpen(true);
    },
    [onNewInvoiceClose]
  );

  const loadInvoices: (
    timeFilter: TimespanFilter,
    signal?: AbortSignal
  ) => Promise<Invoice[] | null> = useCallback(
    async (timeFilter, signal?: AbortSignal) => {
      if (!equals(timeFilter, {})) {
        try {
          setIsLoading(true);

          const { data } = await apiFetchInvoices(
            timeFilter,
            officeId,
            projectId,
            signal
          );
          if (data) {
            //setAccountingOverview(data);
            setInvoices(data);
            setOriginalInvoices(data);
            setIsLoading(false);
            return data;
          }
          setIsLoading(false);
        } catch {
          setIsLoading(false);
          console.warn('some error occurred loading invoices');
        }
      }
      return null;
    },
    [projectId, officeId]
  );

  const reloadInvoices = async () => {
    let controller = abortController;
    if (!controller) {
      controller = new AbortController();
      setAbortController(controller);
    }
    const resultData = await loadInvoices(currentFilter, controller.signal);
    if (selectedInvoice && resultData) {
      setSelectedInvoice(
        resultData.find(
          (invoice) => invoice.invoiceId === selectedInvoice.invoiceId
        )
      );
    }
  };

  const createInvoice: (
    invoiceUpdate: CreateInvoice
  ) => Promise<boolean> = async (invoice) => {
    setIsSaving(true);
    const { result } = await apiCreateInvoice(invoice, officeId, projectId);
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      onNewInvoiceClose();
      setOpen(false);
      reloadInvoices();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('accounting:errorMessages.createInvoice'),
      });
      return false;
    }
  };

  const handleShowPaymentsChange = (checked: boolean) => {
    setShowPayments(checked);
  };

  const onAccountingFilterChangeHandler = (
    accountingFilter: AccountingFilter
  ) => {
    setAccountingFilter(accountingFilter);
    setIsFilterReset(false);
  };

  const setSearchResult = (searchResult: Invoice[]) => {
    setOriginalInvoices(searchResult);
  };

  const resetAdvancedInvoiceFilter = () => {
    setIsFilterReset(true);
    setAccountingFilter(null);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (createInvoiceActive && !open) {
      setOpen(true);
    }
  }, [createInvoiceActive, open]);

  useEffect(() => {
    if (originalInvoices != null) {
      if (accountingFilter != null) {
        var filteredInvoices = originalInvoices;

        if (accountingFilter.companyIds?.length > 0) {
          filteredInvoices = filteredInvoices.filter(
            (invoice: Invoice) =>
              accountingFilter.companyIds.filter(
                (companyFilter) => companyFilter !== invoice.recipientCompanyId
              ).length ===
              accountingFilter.companyIds.length - 1
          );
        }

        if (accountingFilter.projectIds?.length > 0) {
          filteredInvoices = filteredInvoices.filter(
            (invoice: Invoice) =>
              accountingFilter.projectIds.filter(
                (projectFilter) => projectFilter !== invoice.projectId
              ).length ===
              accountingFilter.projectIds.length - 1
          );
        }

        if (accountingFilter.status?.length > 0) {
          filteredInvoices = filteredInvoices.filter(
            (invoice: Invoice) =>
              accountingFilter.status.filter(
                (statusFilter) => statusFilter !== invoice.status
              ).length ===
              accountingFilter.status.length - 1
          );
        }

        if (accountingFilter.types?.length > 0) {
          filteredInvoices = filteredInvoices.filter(
            (invoice: Invoice) =>
              accountingFilter.types.filter(
                (typesFilter) => typesFilter !== invoice.type
              ).length ===
              accountingFilter.types.length - 1
          );
        }
        setInvoices(filteredInvoices);
      } else {
        setInvoices(originalInvoices);
      }
    }
  }, [accountingFilter, originalInvoices]);

  useEffect(() => {
    setAbortController(new AbortController());
  }, []);

  useEffect(() => {
    if (officeId != null) {
      if (currentFilter) {
        loadInvoices(currentFilter);
      }
    }
  }, [officeId, loadInvoices, currentFilter]);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);
  //#endregion

  return (
    <Flex.Row className={classes.root}>
      <Flex.Column
        className={classes.content}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <Card className={classes.cardWidget}>
          <Tabs defaultActiveKey="1" className={classes.filterTabs}>
            <Tabs.TabPane tab={t('accounting:tabs.timespan')} key="1">
              <TimespanPeriodFilter
                initFilter={period}
                filterUpdated={(filter) => {
                  setCurrentFilter(filter);
                  loadInvoices(filter);
                }}
                resetAdvancedInvoiceFilter={resetAdvancedInvoiceFilter}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('accounting:tabs.search')} key="2">
              <AccountingSearchbar
                officeId={officeId}
                onSearchResult={setSearchResult}
                resetAdvancedInvoiceFilter={resetAdvancedInvoiceFilter}
              />
            </Tabs.TabPane>
          </Tabs>
          <AdvancedInvoiceFilter
            invoices={originalInvoices}
            // accountingFilter={accountingFilter}
            // onCompanyChange={handleCompanyChange}
            // onProjectChange={handleProjectChange}
            // onTypeChange={handleTypesChange}
            // onStatusChange={handleStatusChange}
            companyFilter={!officeId}
            projectFilter={!projectId}
            typeFilter
            statusFilter
            onFilterChange={onAccountingFilterChangeHandler}
            isFilterReset={isFilterReset}
          />
        </Card>
        <Card className={classes.cardWidget}>
          <Tabs
            defaultActiveKey="invoices"
            className={classes.filterTabs}
            tabBarExtraContent={
              <Flex.Row
                alignItems={'end'}
                childrenGap={theme.old.spacing.baseSpacing}
              >
                <Switch
                  checked={showPayments}
                  onChange={handleShowPaymentsChange}
                />
                <Flex.Item>{t('common:filter.label.showPayments')}</Flex.Item>
              </Flex.Row>
            }
          >
            <Tabs.TabPane tab={t('accounting:tabs.invoices')} key="invoices">
              <InvoicesTable
                invoices={invoices ?? []}
                onRowClick={onInvoiceSelect}
                showPayments={showPayments}
                loading={isLoading}
                projectId={projectId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('accounting:tabs.summary')} key="summary">
              <AccountingOverviewPage
                invoices={invoices ?? emptyAccountingOverview.invoices}
                projectId={projectId}
                officeId={officeId}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Flex.Column>
      <Drawer
        visible={open}
        width={panelWidth}
        onClose={handleClose}
        className={classes.drawer}
        destroyOnClose
      >
        {createInvoiceActive && (
          <Flex.Column className={classes.drawerContent}>
            <Typography.Title>
              {t('accounting:navigationBar.createInvoice')}
            </Typography.Title>
            <InvoiceForm
              actionLabel={t('accounting:invoiceForm.actions.update')}
              onFinish={createInvoice}
              isoCode="EUR"
              disableForm={isSaving}
              projectId={projectId}
              officeId={officeId}
            />
          </Flex.Column>
        )}
        {!createInvoiceActive && selectedInvoice && (
          <Flex.Column
            childrenGap={theme.old.spacing.unit(5)}
            className={classes.drawerContent}
          >
            <InvoiceDetails
              onSubmit={() => setOpen(false)}
              invoice={selectedInvoice}
              reloadInvoices={reloadInvoices}
              officeId={officeId}
              projectId={projectId}
              handleDrawerClose={handleClose}
            />
          </Flex.Column>
        )}
      </Drawer>
    </Flex.Row>
  );
};

export default InvoicesManagement;
