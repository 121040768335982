import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import commonDe from './translations/de.json';
import commonEn from './translations/en.json';
import commonFr from './translations/fr.json';
import commonEs from './translations/es.json';

import contactsDe from './modules/contacts/translations/de.json';
import contactsEn from './modules/contacts/translations/en.json';
import contactsFr from './modules/contacts/translations/fr.json';
import contactsEs from './modules/contacts/translations/es.json';

import companiesDe from './modules/companies/translations/de.json';
import companiesEn from './modules/companies/translations/en.json';
import companiesFr from './modules/companies/translations/fr.json';
import companiesEs from './modules/companies/translations/es.json';

import hrDe from './modules/hr/translations/de.json';
import hrEn from './modules/hr/translations/en.json';
import hrFr from './modules/hr/translations/fr.json';
import hrEs from './modules/hr/translations/es.json';

import projectsDe from './modules/projects/translations/de.json';
import projectsEn from './modules/projects/translations/en.json';
import projectsFr from './modules/projects/translations/fr.json';
import projectsEs from './modules/projects/translations/es.json';

import mailDe from './modules/mail/translations/de.json';
import mailEn from './modules/mail/translations/en.json';
import mailFr from './modules/mail/translations/fr.json';
import mailEs from './modules/mail/translations/es.json';

import timeRecordsDe from './modules/timeRecords/translations/de.json';
import timeRecordsEn from './modules/timeRecords/translations/en.json';
import timeRecordsFr from './modules/timeRecords/translations/fr.json';
import timeRecordsEs from './modules/timeRecords/translations/es.json';

import settingsDe from './modules/settings/translations/de.json';
import settingsEn from './modules/settings/translations/en.json';
import settingsFr from './modules/settings/translations/fr.json';
import settingsEs from './modules/settings/translations/es.json';

import usersDe from './modules/users/translations/de.json';
import usersEn from './modules/users/translations/en.json';
import usersFr from './modules/users/translations/fr.json';
import usersEs from './modules/users/translations/es.json';

import documentsDe from './modules/documents/translations/de.json';
import documentsEn from './modules/documents/translations/en.json';
import documentsFr from './modules/documents/translations/fr.json';
import documentsEs from './modules/documents/translations/es.json';

import profileDe from './modules/profile/translations/de.json';
import profileEn from './modules/profile/translations/en.json';
import profileFr from './modules/profile/translations/fr.json';
import profileEs from './modules/profile/translations/es.json';

import accountingDe from './modules/accounting/translations/de.json';
import accountingEn from './modules/accounting/translations/en.json';
import accountingFr from './modules/accounting/translations/fr.json';
import accountingEs from './modules/accounting/translations/es.json';

import calendarDe from './modules/calendar/translations/de.json';
import calendarEn from './modules/calendar/translations/en.json';
import calendarFr from './modules/calendar/translations/fr.json';
import calendarEs from './modules/calendar/translations/es.json';

import tasksDe from './modules/tasks/translations/de.json';
import tasksEn from './modules/tasks/translations/en.json';
import tasksFr from './modules/tasks/translations/fr.json';
import tasksEs from './modules/tasks/translations/es.json';

import notificationsDe from './modules/notifications/translations/de.json';
import notificationsEn from './modules/notifications/translations/en.json';
import notificationsFr from './modules/notifications/translations/fr.json';
import notificationsEs from './modules/notifications/translations/es.json';

import employeeDe from './modules/employee/translations/de.json';
import employeeEn from './modules/employee/translations/en.json';
import employeeFr from './modules/employee/translations/fr.json';
import employeeEs from './modules/employee/translations/es.json';

import zendeskDe from './modules/zendesk/translations/de.json';
import zendeskEn from './modules/zendesk/translations/en.json';
import zendeskFr from './modules/zendesk/translations/fr.json';
import zendeskEs from './modules/zendesk/translations/es.json';

import doodleDe from './modules/doodles/translations/de.json';
import doodleEn from './modules/doodles/translations/en.json';
import doodleFr from './modules/doodles/translations/fr.json';
import doodleEs from './modules/doodles/translations/es.json';

import userSettingsDe from './modules/userSettings/translations/de.json';
import userSettingsEn from './modules/userSettings/translations/en.json';
import userSettingsFr from './modules/userSettings/translations/fr.json';
import userSettingsEs from './modules/userSettings/translations/es.json';

import dashboardDe from './modules/dashboard/translations/de.json';
import dashboardEn from './modules/dashboard/translations/en.json';
import dashboardFr from './modules/dashboard/translations/fr.json';
import dashboardEs from './modules/dashboard/translations/es.json';

import absencesDe from './modules/absences/translations/de.json';
import absencesEn from './modules/absences/translations/en.json';
import absencesFr from './modules/absences/translations/fr.json';
import absencesEs from './modules/absences/translations/es.json';

import timeAndLeaveManagementDe from './modules/timeAndLeaveManagement/translations/de.json';
import timeAndLeaveManagementEn from './modules/timeAndLeaveManagement/translations/en.json';
import timeAndLeaveManagementFr from './modules/timeAndLeaveManagement/translations/fr.json';
import timeAndLeaveManagementEs from './modules/timeAndLeaveManagement/translations/es.json';

import controllingDe from './modules/controlling/translations/de.json';
import controllingEn from './modules/controlling/translations/en.json';
import controllingFr from './modules/controlling/translations/fr.json';
import controllingEs from './modules/controlling/translations/es.json';

import timeKeepingDe from './modules/timeKeeping/translations/de.json';
import timeKeepingEn from './modules/timeKeeping/translations/en.json';
import timeKeepingFr from './modules/timeKeeping/translations/fr.json';
import timeKeepingEs from './modules/timeKeeping/translations/es.json';

import trainingDe from './modules/training/translations/de.json';
import trainingEn from './modules/training/translations/en.json';
import trainingFr from './modules/training/translations/fr.json';
import trainingEs from './modules/training/translations/es.json';

import componentsDe from './components/translations/de.json';
import componentsEn from './components/translations/en.json';
import componentsFr from './components/translations/fr.json';
import componentsEs from './components/translations/es.json';

import {
  LIB_TRANSLATION_NAMESPACE,
  getLibTranslationFile,
} from '@prio365/prio365-react-library';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'de',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        ...getLibTranslationFile('en'),
        common: commonEn,
        contacts: contactsEn,
        companies: companiesEn,
        hr: hrEn,
        projects: projectsEn,
        mail: mailEn,
        timeRecords: timeRecordsEn,
        settings: settingsEn,
        users: usersEn,
        documents: documentsEn,
        profile: profileEn,
        accounting: accountingEn,
        calendar: calendarEn,
        tasks: tasksEn,
        notifications: notificationsEn,
        employee: employeeEn,
        zendesk: zendeskEn,
        doodle: doodleEn,
        userSettings: userSettingsEn,
        dashboard: dashboardEn,
        absences: absencesEn,
        timeAndLeaveManagement: timeAndLeaveManagementEn,
        controlling: controllingEn,
        timeKeeping: timeKeepingEn,
        training: trainingEn,
        components: componentsEn,
      },
      de: {
        ...getLibTranslationFile('de'),
        common: commonDe,
        contacts: contactsDe,
        companies: companiesDe,
        hr: hrDe,
        projects: projectsDe,
        mail: mailDe,
        timeRecords: timeRecordsDe,
        settings: settingsDe,
        users: usersDe,
        documents: documentsDe,
        profile: profileDe,
        accounting: accountingDe,
        calendar: calendarDe,
        tasks: tasksDe,
        notifications: notificationsDe,
        employee: employeeDe,
        zendesk: zendeskDe,
        doodle: doodleDe,
        userSettings: userSettingsDe,
        dashboard: dashboardDe,
        absences: absencesDe,
        timeAndLeaveManagement: timeAndLeaveManagementDe,
        controlling: controllingDe,
        timeKeeping: timeKeepingDe,
        training: trainingDe,
        components: componentsDe,
      },
      fr: {
        common: commonFr,
        contacts: contactsFr,
        companies: companiesFr,
        hr: hrFr,
        projects: projectsFr,
        mail: mailFr,
        timeRecords: timeRecordsFr,
        settings: settingsFr,
        users: usersFr,
        documents: documentsFr,
        profile: profileFr,
        accounting: accountingFr,
        calendar: calendarFr,
        tasks: tasksFr,
        notifications: notificationsFr,
        employee: employeeFr,
        zenFrsk: zendeskFr,
        doodle: doodleFr,
        userSettings: userSettingsFr,
        dashboard: dashboardFr,
        absences: absencesFr,
        timeAndLeaveManagement: timeAndLeaveManagementFr,
        controlling: controllingFr,
        timeKeeping: timeKeepingFr,
        training: trainingFr,
        components: componentsFr,
      },
      es: {
        common: commonEs,
        contacts: contactsEs,
        companies: companiesEs,
        hr: hrEs,
        projects: projectsEs,
        mail: mailEs,
        timeRecords: timeRecordsEs,
        settings: settingsEs,
        users: usersEs,
        documents: documentsEs,
        profile: profileEs,
        accounting: accountingEs,
        calendar: calendarEs,
        tasks: tasksEs,
        notifications: notificationsEs,
        employee: employeeEs,
        zenEssk: zendeskEs,
        doodle: doodleEs,
        userSettings: userSettingsEs,
        dashboard: dashboardEs,
        absences: absencesEs,
        timeAndLeaveManagement: timeAndLeaveManagementEs,
        controlling: controllingEs,
        timeKeeping: timeKeepingEs,
        training: trainingEs,
        components: componentsEs,
      },
    },
    ns: [
      'common',
      'contacts',
      'employee',
      'hr',
      'projects',
      'mail',
      'timeRecords',
      'settings',
      'users',
      'documents',
      'accounting',
      'calendar',
      'tasks',
      'zendesk',
      'doodle',
      'userSettings',
      'dashboard',
      'absences',
      'timeAndLeaveManagement',
      'controlling',
      'timeKeeping',
      'training',
      'components',
      LIB_TRANSLATION_NAMESPACE,
    ],
  });

export default i18n;
