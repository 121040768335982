import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import jsonMergePatch from 'json-merge-patch';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import ContactForm from './ContactForm';
import {
  Contact,
  UpdateContactRequest,
  ExternalContact,
} from '../../../models/Contact';
import { updateContact } from '../actions';
import { setContactsDrawerState, closeContactsDrawer } from '../actions/drawer';
import {
  getContactsDrawerState,
  getContact,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { ContactsDrawerState } from '../reducers/drawer';
import { apiFetchContact } from '../api';
import { useForm } from 'antd/lib/form/Form';
import { ContactId } from '../../../models/Types';
import PrioSpinner from '../../../components/PrioSpinner';
import useAccessRights from '../../users/hooks/useAccessRights';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px`,
  },
  form: {
    marginTop: theme.old.spacing.unit(4),
  },
  loadingOverlay: {
    background: '#FFFFFFBF',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
}));

interface EditContactProps {
  className?: string;
  formClassName?: string;
  noAvatar?: boolean;
  contactId?: ContactId;
  isSubDrawer?: boolean;
  onCancel?: VoidFunction;
  onFinish?: (contactId: ContactId) => void;
}

export const EditContact: React.FC<EditContactProps> = (props) => {
  //#region -------------------------------- Variables
  const {
    className,
    formClassName,
    contactId,
    isSubDrawer,
    onCancel,
    onFinish,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const { selectedContact: drawerContact, open } = useSelector<
    RootReducerState,
    ContactsDrawerState
  >(getContactsDrawerState);

  const selectedContact = useMemo(
    () => contactId ?? drawerContact,
    [contactId, drawerContact]
  );

  const contact: ExternalContact = useSelector<RootReducerState, Contact>(
    (state) => selectedContact && getContact(state, selectedContact)
  );

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { editInternalContact: canEditInternalContact } = useAccessRights([
    'editInternalContact',
  ]);

  const { editExternalContact: canEditExternalContact } = useAccessRights([
    'editExternalContact',
  ]);
  //#endregion

  //#region -------------------------------- State declaration
  const [editContact, setEditContact] = useState<ExternalContact>(null);
  //#endregion

  //#region -------------------------------- Methods

  const isContactFormEditable = () => {
    if (contact?.contactType === 'InternalContact') {
      return canEditInternalContact;
    }
    return canEditExternalContact;
  };
  const handleFinish = async (value: ExternalContact) => {
    const {
      contactId,
      rowVersion,
      isArchived,
      contactType,
      officeId,
      company: _,
      ...currentContact
    } = editContact;

    const { company: __, ...updatedContact } = value;

    dispatch(
      updateContact(
        jsonMergePatch.generate(
          currentContact,
          updatedContact
        ) as UpdateContactRequest,
        selectedContact,
        editContact.rowVersion,
        editContact
      )
    );
    if (isSubDrawer && onFinish) {
      onFinish(contactId);
    } else {
      dispatch(
        setContactsDrawerState({
          view: 'contactDetail',
        })
      );
    }
    dispatch(
      setContactsDrawerState({
        changedContact: {
          contact: {
            contactId,
            ...value,
          },
          state: 'edited',
        },
      })
    );
  };

  const handleCancel = () => {
    if (isSubDrawer && onCancel) {
      onCancel();
    } else {
      dispatch(closeContactsDrawer());
    }
  };
  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    const fetchOnlineContact = async () => {
      try {
        setIsFetching(true);
        const { data } = await apiFetchContact(selectedContact);
        setIsFetching(false);
        if (data) {
          setEditContact(data);
        }
      } catch {
        setIsFetching(false);
      }
    };
    if (selectedContact && !contact) {
      fetchOnlineContact();
    } else if (selectedContact && contact) {
      setEditContact(contact);
    }
  }, [contact, selectedContact]);
  //#endregion

  if (selectedContact) {
    return (
      <Flex.Column
        id="prio-global-contacts-drawer-form"
        className={classNames(classes.root, className)}
      >
        {isFetching && (
          <div className={classes.loadingOverlay}>
            <PrioSpinner size="large" />
          </div>
        )}
        <ContactForm
          mode="edit"
          actionLabel={t('contacts:newContact.actionLabel')}
          actionLabelIgnore={t('contacts:newContact.actionLabelIgnore')}
          actionLabelArchive={t('contacts:newContact.actionLabelArchive')}
          cancelLabel={t('common:actions.cancel')}
          className={classNames(classes.form, formClassName)}
          onFinish={handleFinish}
          onCancel={handleCancel}
          initialValues={editContact}
          disableForm={!open || !isContactFormEditable()}
          avatar
          form={form}
        />
      </Flex.Column>
    );
  }

  return null;
};

export default EditContact;
