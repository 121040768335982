import { Reducer, combineReducers } from 'redux';
import {
  LastOpenDriveItemFolderLocation,
  UPDATE_LAST_OPEN_DRIVE_FOLDER,
  UpdateLastOpenDriveItemFolderAction,
} from '../actions/lastOpenDriveItemFolder';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ProjectId } from '../../../models/Types';

export interface LastOpenDriveItemFolderState {
  byProjectId: ByProjectIdState;
}

interface ByProjectIdState {
  [projectId: string]: Partial<LastOpenDriveItemFolderLocation>;
}

const byProjectId: Reducer<
  ByProjectIdState,
  UpdateLastOpenDriveItemFolderAction
> = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_LAST_OPEN_DRIVE_FOLDER: {
      return {
        ...state,
        [action.projectId]: {
          ...state[action.projectId],
          ...action.lastOpenDriveItemFolderIds,
        },
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export default combineReducers({
  byProjectId,
});

export const getDriveItemPath = (
  state: LastOpenDriveItemFolderState,
  projectId: ProjectId
) => state.byProjectId[projectId];
