import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { Checkbox, Form, Layout } from 'antd';
import { Button, Input, useTheme } from '@prio365/prio365-react-library';
import { colon } from '../../../util/forms';
import { PrioTheme } from '../../../theme/types';
import { Content } from 'antd/lib/layout/layout';
import { getSvgIconPathByType } from '../../../util/icon';
import SvgIcon from '../../../components/SvgIcon';
import {
  JiraConfiguration,
  UpdateProjectExtensionConfiguration,
  defaultJiraConfiguration,
} from '../../../models/ProjectExtension';
import {
  apiDeleteProjectExtensionConfigurationById,
  apiGetProjectExtensionConfigurationById,
  apiUpdateProjectExtensionConfiguration,
} from '../../projects/api';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  rootLayout: {
    margin: 0,
  },
  editContent: {
    backgroundColor: theme.colors.application.background.default,
  },
  form: {
    padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  fullWidth: {
    width: '100%',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white + ' !important',
    },
  },
}));

interface AddInItemConfigProps {
  className?: string;
  projectExtensionConfigurationChanged: () => void;
}

export const JiraConfigurationForm: React.FC<AddInItemConfigProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { projectExtensionConfigurationChanged } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const { id } = useParams();
  const [dataForm] = Form.useForm<JiraConfiguration>();
  const navigate = useNavigate();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  // issaving
  const [isSaving, setIsSaving] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers

  // handle save
  const handleFinishData = async (newData: JiraConfiguration) => {
    const payload: UpdateProjectExtensionConfiguration = {
      objectJson: JSON.stringify(newData.objectJson),
      isEnabled: newData.isEnabled,
      rowVersion: newData.rowVersion,
    };

    setIsSaving(true);
    await apiUpdateProjectExtensionConfiguration(id, payload);
    projectExtensionConfigurationChanged();
    setIsSaving(false);
  };

  const handleDelete = async () => {
    await apiDeleteProjectExtensionConfigurationById(id);
    projectExtensionConfigurationChanged();
    navigate(-1);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const getProjectExtensionConfigurationById = async (id: string) => {
      const { data } = await apiGetProjectExtensionConfigurationById(id);
      let objectJsonParsed = JSON.parse(data.objectJson);
      dataForm.setFieldsValue({ objectJson: objectJsonParsed });
      dataForm.setFieldsValue({ rowVersion: data.rowVersion });
      dataForm.setFieldsValue({ isEnabled: data.isEnabled });
    };
    if (id && id !== '0') {
      getProjectExtensionConfigurationById(id);
    } else {
      dataForm.setFieldsValue(defaultJiraConfiguration);
    }
  }, [id, dataForm]);
  //#endregion

  return (
    <Layout className={classes.rootLayout}>
      <Content className={classes.editContent}>
        <>
          <Form
            layout="vertical"
            form={dataForm}
            className={classes.form}
            onFinish={handleFinishData}
          >
            <Flex.Row>
              <SvgIcon
                width={42}
                height={42}
                path={getSvgIconPathByType('jira')}
              ></SvgIcon>
              <h3 style={{ marginTop: '6px', marginBottom: '32px' }}>
                Jira {t('settings:jiraConfig.connection')}
              </h3>
            </Flex.Row>
            <Flex.Row flex={1}>
              <Flex.Column childrenGap={theme.spacing.small} flex={1}>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  justifyContent="stretch"
                >
                  <Form.Item
                    name={['objectJson', 'displayName']}
                    label={t('settings:jiraConfig.displayName')}
                    colon={colon}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                  <Form.Item
                    name={['objectJson', 'baseURL']}
                    label={t('projects:addIn.jiraConfig.jiraUrl')}
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                </Flex.Row>
                <Form.Item
                  name={['objectJson', 'description']}
                  label={t('settings:jiraConfig.description')}
                  colon={colon}
                >
                  <Input full />
                </Form.Item>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  justifyContent="stretch"
                >
                  <Form.Item
                    name={['objectJson', 'serviceUserCredentials', 'mail']}
                    label={t('projects:addIn.jiraConfig.mail')}
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                  <Form.Item
                    name={['objectJson', 'serviceUserCredentials', 'apiKey']}
                    label={t('projects:addIn.jiraConfig.apiToken')}
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} type="password" full />
                  </Form.Item>
                  <Form.Item
                    name={'rowVersion'}
                    hidden={true}
                    colon={colon}
                    style={{ flex: 1 }}
                  ></Form.Item>
                </Flex.Row>
                <Flex.Row childrenGap={theme.spacing.small} flex={1}>
                  <Form.Item
                    name="isEnabled"
                    colon={colon}
                    valuePropName="checked"
                  >
                    <Checkbox>{t('settings:jiraConfig.isEnabled')}</Checkbox>
                  </Form.Item>
                </Flex.Row>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  justifyContent="flex-end"
                >
                  <Button
                    type="default"
                    className={classes.danger}
                    onClick={handleDelete}
                  >
                    {t('settings:jiraConfig.delete')}
                  </Button>
                  <Button
                    disabled={isSaving}
                    onClick={() => {
                      dataForm.submit();
                    }}
                    htmlType="button"
                  >
                    {t('settings:jiraConfig.save')}
                  </Button>
                </Flex.Row>
              </Flex.Column>
            </Flex.Row>
          </Form>
        </>
      </Content>
    </Layout>
  );
};

export default JiraConfigurationForm;
