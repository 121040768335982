import { useSelector } from 'react-redux';
import { ProjectId } from '../../../models/Types';
import { Message } from '../../../models/Message';
import {
  getMessagesById,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { ByMessageId } from '../actions/types';
import { Dispatch, SetStateAction, useState } from 'react';

const selectedMessagesSelector = (
  projectId: ProjectId,
  selectedMessages: Message[]
) =>
  createSelector<[(state: RootReducerState) => ByMessageId], Message[]>(
    (state) => getMessagesById(state, projectId),
    (byId) =>
      selectedMessages
        .map((message) => ({ ...message, ...byId[message.id] }))
        .filter((m) => !!m)
  );

const useSelectedMessages: (
  projectId: ProjectId
) => [Message[], Dispatch<SetStateAction<Message[]>>] = (
  projectId: ProjectId
) => {
  //#region ------------------------------ States / Attributes / Selectors
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

  const _selectedMessages = useSelector(
    selectedMessagesSelector(projectId, selectedMessages)
  );
  //#endregion

  return [_selectedMessages, setSelectedMessages];
};

export default useSelectedMessages;
