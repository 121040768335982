import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Input } from 'antd';
import { makePrioStyles } from '../theme/utils';
import { debounceFunction } from '../util';

const debouncedInputChange = debounceFunction(
  (value: string, onChange?: (value: string) => void) => {
    if (onChange) {
      onChange(value);
    }
  },
  500
);

const useStyles = makePrioStyles({
  root: {},
});

interface DebouncedInputTextAreaProps {
  className?: string;
  onChange?: (value: string) => void;
  value?: string;
  autoSize: {
    minRows: number;
    maxRows: number;
  };
  disabled?: boolean;
  defaultValue?: string | number;
}

export const DebouncedInputTextArea: React.FC<DebouncedInputTextAreaProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, onChange, defaultValue, ...rest } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [currentInputValue, setCurrentInputValue] = useState<string | number>(
    defaultValue ?? null
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleChange = (value: string) => {
    setCurrentInputValue(value);
    debouncedInputChange(value, onChange);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setCurrentInputValue(props.value ?? defaultValue);
  }, [props.value, defaultValue]);

  //#endregion

  return (
    <Input.TextArea
      {...rest}
      className={classNames(classes.root, className)}
      value={currentInputValue}
      onChange={(e) => handleChange(e.currentTarget.value)}
    />
  );
};

export default DebouncedInputTextArea;
