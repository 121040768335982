import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import {
  MonthlyCloseId,
  TimeKeepingDayId,
  TimeKeepingDayType,
} from '../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getTimeKeepingDayMe,
} from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { useForm } from 'antd/lib/form/Form';
import { TimeAndLeaveManagementTimelineItem } from '../../timeAndLeaveManagement/selectors';
import { TimeKeepingDay } from '../../../models/TimeKeeping';
import equals from 'deep-equal';
import TextArea from 'antd/lib/input/TextArea';

const OPTIONS: TimeKeepingDayType[] = [
  'office',
  'homeOffice',
  'outOfOffice',
  'other',
];

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
    minWidth: 150,
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white + '!important',
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.white,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  textAreaContainer: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
  },
  buttonTextArea: {
    position: 'absolute',
    top: '50%',
    padding: '8px',
    right: '12px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '& > svg': {
      display: 'flex',
    },
  },
}));

export interface TimeKeepingFormModel {
  type: TimeKeepingDayType;
  notes?: string;
}

interface TimeKeepingEditFormProps {
  className?: string;
  selectedItem?: TimeAndLeaveManagementTimelineItem;
  onChange: (
    value: TimeKeepingFormModel,
    item?: TimeAndLeaveManagementTimelineItem
  ) => void;
  onDelete: (
    monthlyCloseId: MonthlyCloseId,
    timeKeepingDayId: TimeKeepingDayId
  ) => void;
  loading: boolean;
}

export const TimeKeepingEditForm: React.FC<TimeKeepingEditFormProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, selectedItem, loading, onChange, onDelete } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [form] = useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const timeKeepingDay = useSelector<RootReducerState, TimeKeepingDay>(
    (state) => getTimeKeepingDayMe(state, selectedItem?.id)
  );

  const initialValue: TimeKeepingFormModel = useMemo(
    () => ({
      type: timeKeepingDay ? timeKeepingDay.type : 'office',
      notes: timeKeepingDay ? timeKeepingDay.notes : '',
    }),
    [timeKeepingDay]
  );

  const [updateTextAreaString, setUpdateTextAreaString] =
    useState<string>(undefined);
  const [isTextAreaUpdated, setIsTextAreaUpdated] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onHandleValuesChange: (
    changedValues: TimeKeepingFormModel,
    allValues: TimeKeepingFormModel
  ) => void = (_, allValues) => {
    const allValuesButNotes: TimeKeepingFormModel = {
      ...allValues,
      notes: initialValue?.notes,
    };
    if (selectedItem) onChange(allValuesButNotes, selectedItem);
  };

  const handleOnDescriptionChange = (value) => {
    const updateString = value.target.value;
    setIsTextAreaUpdated(updateString !== initialValue?.notes);
    setUpdateTextAreaString(updateString);
  };

  const handleSaveDescription = () => {
    const currentValues = {
      type: form.getFieldsValue().type,
      notes: updateTextAreaString,
    };
    const asyncOnChange = async () => {
      await onChange(currentValues, selectedItem);
    };

    asyncOnChange().then(() => {
      setIsTextAreaUpdated(false);
      setUpdateTextAreaString(undefined);
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!equals(form.getFieldsValue(), initialValue)) {
      form.setFieldsValue(initialValue);
    }
  }, [form, initialValue]);
  //#endregion

  return (
    <Form<TimeKeepingFormModel>
      className={classNames(classes.root, className)}
      initialValues={initialValue}
      onValuesChange={(changedValues, allValues) => {
        if (changedValues.type) {
          onHandleValuesChange(changedValues, allValues);
        }
      }}
      form={form}
      layout="vertical"
    >
      <Flex.Column>
        <Flex.Row>
          <Flex.Item flex={1}>
            <Form.Item
              name="type"
              label={t('timeKeeping:form.labels.type')}
              style={{ width: '100%' }}
            >
              <Select
                options={OPTIONS.map((option) => ({
                  label: t(`timeKeeping:form.values.type.${option}`),
                  value: option,
                }))}
                disabled={loading}
              />
            </Form.Item>
          </Flex.Item>
        </Flex.Row>
        <Flex.Row flex={1}>
          <Flex.Item flex={2}>
            <Form.Item
              name="notes"
              label={t('timeKeeping:form.labels.description')}
            >
              <div className={classes.textAreaContainer}>
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  style={{
                    width: '100%',
                    paddingRight: `${isTextAreaUpdated ? '38px' : '0px'}`,
                  }}
                  value={updateTextAreaString ?? initialValue?.notes}
                  onChange={handleOnDescriptionChange}
                ></TextArea>
                {isTextAreaUpdated && (
                  <Button
                    loading={loading}
                    className={classes.buttonTextArea}
                    iconProp={['far', 'check']}
                    tooltip={t('timeKeeping:form.labels.tooltipSaveNotes')}
                    onClick={loading ? () => {} : handleSaveDescription}
                  ></Button>
                )}
              </div>
            </Form.Item>
          </Flex.Item>
        </Flex.Row>
        <Flex.Row>
          <Button
            type="default"
            className={classes.danger}
            disabled={loading}
            loading={loading}
            onClick={() => {
              if (timeKeepingDay) {
                onDelete(
                  timeKeepingDay?.monthlyCloseId,
                  timeKeepingDay?.timeKeepingDayId
                );
              }
            }}
          >
            {t('common:actions.delete')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </Form>
  );
};

export default TimeKeepingEditForm;
