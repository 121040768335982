import React from 'react';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import GlobalProjectSettingsSignaturePage from './GlobalProjectSettingsSignaturePage';
import GlobalProjectSettingsSubjectPage from './GlobalProjectSettingsSubjectPage';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface GlobalProjectMessageSettingsPageProps {
  className?: string;
}

export const GlobalProjectMessageSettingsPage: React.FC<
  GlobalProjectMessageSettingsPageProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
        <GlobalProjectSettingsSignaturePage className={classes.shadow} />
        <GlobalProjectSettingsSubjectPage className={classes.shadow} />
      </Flex.Column>
    </div>
  );
};

export default GlobalProjectMessageSettingsPage;
