import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Result } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import PrioSpinner from '../../../components/PrioSpinner';

import {
  getCompany,
  getCompanyRedirect,
  getContactsDrawerState,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import CompanyDetails from './CompanyDetails';
import { makePrioStyles } from '../../../theme/utils';
import {
  setContactsDrawerState,
  setContactsDrawerView,
  closeContactsDrawer,
} from '../../contacts/actions/drawer';
import { isTemporaryId } from '../../../util';
import { Company } from '../../../models/Company';
import { ContactId } from '../../../models/Types';
import ContactSubDrawer, {
  SubDrawerState,
} from '../../contacts/components/ContactSubDrawer';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
  },
  companyDetails: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    alignSelf: 'stretch',
    height: 'calc(100% - 32px)',
    overflow: 'hidden',
  },
  backButton: {
    marginBottom: theme.old.spacing.unit(2),
  },
}));

interface CompanyDetailsDrawerProps {}

export const CompanyDetailsDrawer: React.FC<CompanyDetailsDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const currentURLPath = window.location.pathname;
  const isContactSearchPage = currentURLPath.includes('contacts/contactSearch');

  const contactsDrawerState = useSelector(getContactsDrawerState); // Remove or fill with selector
  const { selectedCompany: companyId } = contactsDrawerState;
  const company = useSelector<RootReducerState, Company>((state) =>
    getCompany(state, companyId)
  );
  const redirect: string = useSelector<RootReducerState, string>((state) =>
    getCompanyRedirect(state, companyId)
  );

  const [subDrawerState, setSubDrawerState] = useState<SubDrawerState>('none');
  const [selectedContactId, setSelectedContactId] = useState<ContactId>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const goBack = () => dispatch(setContactsDrawerView('list'));
  const closeDrawer = () => dispatch(closeContactsDrawer());

  const handleContactClick = (contactId: ContactId) => {
    setSelectedContactId(contactId);
    setSubDrawerState('details');
  };

  const handleAddContactToCompany = () => {
    setSubDrawerState('create');
  };

  const handleSubDrawerClose = () => {
    setSubDrawerState('none');
    setSelectedContactId(null);
  };

  const handleOnNewContact = (contactId: ContactId) => {
    setSelectedContactId(null);
    setSubDrawerState('none');
  };

  const handleStartEditContact = (contactId: ContactId) => {
    setSelectedContactId(contactId);
    setSubDrawerState('edit');
  };

  const handleFinishEditContact = (contactId: ContactId) => {
    setSubDrawerState('none');
  };

  const handleRedirect = (contactId: ContactId) => {
    setSelectedContactId(contactId);
  };
  //#endregion

  //#region ------------------------------ Components
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (redirect) {
      dispatch(setContactsDrawerState({ selectedCompany: redirect }));
    }
  }, [redirect, dispatch]);
  //#endregion

  if (company) {
    return (
      <>
        {!isContactSearchPage && (
          <Button
            onClick={goBack}
            type="default"
            iconProp={['fal', 'chevron-left']}
            className={classes.backButton}
          >
            {t('common:back')}
          </Button>
        )}
        <CompanyDetails
          company={company}
          className={classNames(classes.companyDetails, classes.noPadding)}
          contentClassName={classes.noPadding}
          onNewContact={handleAddContactToCompany}
          onItemClick={handleContactClick}
          noAvatar
          isDrawer
        />
        <ContactSubDrawer
          onClose={handleSubDrawerClose}
          subDrawerState={subDrawerState}
          companyId={companyId}
          contactId={selectedContactId}
          onNewContact={handleOnNewContact}
          onStartEditContact={handleStartEditContact}
          onFinishEditContact={handleFinishEditContact}
          onRedirect={handleRedirect}
        />
      </>
    );
  }

  if (!companyId) {
    return null;
  }

  if (isTemporaryId(companyId)) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <Result
          status="500"
          title={t('common:weAreSorry')}
          subTitle={t('companies:errorMessages.createError')}
          extra={
            <Button type="default" onClick={closeDrawer}>
              {t('common:actions.close')}
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
      <PrioSpinner size="large" />
    </div>
  );
};

export default CompanyDetailsDrawer;
