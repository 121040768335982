import React, { useState, Reducer, useReducer, useEffect } from 'react';
import { Table, Typography, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';

import { ConfigurationId } from '../../../models/Types';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { tableTranslations } from '../../../util/table';
import {
  Configuration,
  CreateConfiguration,
} from '../../../models/Configuration';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-row .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  numberInput: {
    flex: 1,
  },
  currencyLabel: {
    padding: `0 ${theme.old.spacing.unit(1)}px`,
    color: theme.old.typography.colors.muted,
  },
}));

interface ConfigurationTableProps {
  className?: string;
  configurations: Configuration[];
  onIsDirtyChanged?: (
    isDirty: boolean,
    configurations?: Configuration[]
  ) => void;
  onNewConfiguration?: (config: CreateConfiguration) => void;
}

interface TableEntry extends Configuration {
  id: string;
  addConfigurationRow: boolean;
  isTemporary: boolean;
}

interface NewConfiguration extends CreateConfiguration {
  isValid: boolean;
}

type UpdateConfigurationKey = 'key' | 'value';
type UpdateConfigurationValue = string;

interface UpdateNewConfigurationAction {
  key: UpdateConfigurationKey;
  value: UpdateConfigurationValue;
}

export const ConfigurationTable: React.FC<ConfigurationTableProps> = (
  props
) => {
  const classes = useStyles();
  const {
    className,
    configurations: originalConfigurations,
    onIsDirtyChanged,
    onNewConfiguration,
  } = props;
  const [configurations, setConfigurations] = useState<Configuration[]>(
    originalConfigurations
  );
  const { t } = useTranslation();
  const [newConfigurationRowActive, setNewConfigurationRowActive] =
    useState(false);

  useEffect(() => {
    setConfigurations(originalConfigurations);
    onIsDirtyChanged(false, originalConfigurations);
  }, [originalConfigurations, onIsDirtyChanged]);

  const validate: (state: NewConfiguration) => boolean = (
    state: NewConfiguration
  ) => state.key.trim().length > 0 && state.value.trim().length > 0;

  const emptyNewConfiguration: NewConfiguration = {
    key: '',
    value: '',
    isValid: false,
  };

  const [newConfiguration, updateNewConfiguration] = useReducer<
    Reducer<NewConfiguration, UpdateNewConfigurationAction | 'clear'>
  >(
    (
      state: NewConfiguration,
      action: UpdateNewConfigurationAction | 'clear'
    ) => {
      if (action === 'clear') {
        return emptyNewConfiguration;
      }
      const updatedState = { ...state, [action.key]: action.value };
      return {
        ...updatedState,
        isValid: validate(updatedState),
      };
    },
    emptyNewConfiguration
  );

  const updateConfiguration = (
    configurationId: ConfigurationId,
    key: UpdateConfigurationKey,
    value: string
  ) => {
    const updatedConfigurations = configurations.map((entry) =>
      entry.configurationId === configurationId
        ? { ...entry, [key]: value }
        : entry
    );
    setConfigurations(updatedConfigurations);

    if (onIsDirtyChanged) {
      const isDirty = !equals(updatedConfigurations, originalConfigurations);
      onIsDirtyChanged(isDirty, updatedConfigurations);
    }
  };

  const saveNewConfiguration = () => {
    if (onNewConfiguration) {
      onNewConfiguration(newConfiguration);
      cancelNewConfigurationRow();
      updateNewConfiguration('clear');
    }
  };

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('settings:configurationTable.columnTitles.key'),
      dataIndex: 'key',
      sorter: (a, b) => a.key.localeCompare(b.key),
      render: (value, record: TableEntry, index: number) =>
        record.addConfigurationRow ? (
          <Input
            value={record.addConfigurationRow ? newConfiguration.key : value}
            placeholder={t(
              'settings:configurationTable.newEntry.placeHolderName'
            )}
            onChange={(e) =>
              updateNewConfiguration({
                key: 'key',
                value: e.currentTarget.value,
              })
            }
          />
        ) : (
          <Typography.Text>{value}</Typography.Text>
        ),
      width: 300,
    },
    {
      title: t('settings:configurationTable.columnTitles.value'),
      dataIndex: 'value',
      render: (value, record) => (
        <Flex.Row>
          <Input
            value={record.addConfigurationRow ? newConfiguration.value : value}
            placeholder={t(
              'settings:configurationTable.newEntry.placeHolderValue'
            )}
            onChange={(e) => {
              record.addConfigurationRow
                ? updateNewConfiguration({
                    key: 'value',
                    value: e.currentTarget.value,
                  })
                : updateConfiguration(
                    record.id,
                    'value',
                    e.currentTarget.value
                  );
            }}
          />
        </Flex.Row>
      ),
    },
    {
      render: (_, record) =>
        record.addConfigurationRow ? (
          <Button
            disabled={!newConfiguration.isValid}
            onClick={saveNewConfiguration}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            className="hourly-rate-suggestion-table-delete-button"
            iconProp={['fal', 'trash']}
            type="link"
          ></Button>
        ),
      width: 80,
    },
  ];

  const data: TableEntry[] = configurations.map(
    (config: Configuration, index: number) => ({
      id: config.configurationId,
      ...config,
      isTemporary: false, //config.isTemporary ?? false,
      addConfigurationRow: false,
    })
  );

  const enableNewConfigurationRow = () => setNewConfigurationRowActive(true);
  const cancelNewConfigurationRow = () => setNewConfigurationRowActive(false);

  const footer = () => (
    <div>
      <Button
        type="link"
        onClick={enableNewConfigurationRow}
        disabled={newConfigurationRowActive}
        iconProp={['fal', 'plus']}
      >
        {t('settings:configurationTable.actions.add')}
      </Button>
    </div>
  );

  return (
    <Table
      className={classNames(classes.root, className)}
      columns={columns}
      pagination={false}
      dataSource={
        newConfigurationRowActive
          ? [
              ...data,
              {
                id: 'addHourlyRate',
                name: '',
                key: '',
                value: '',
                addConfigurationRow: true,
              },
            ]
          : data
      }
      footer={footer}
      locale={tableTranslations(t)}
    />
  );
};

export default ConfigurationTable;
