import { Moment } from 'moment';
import { DateString, ProjectId, ProjectPhaseId } from './Types';

export interface ProjectPhase {
  projectPhaseId: ProjectPhaseId;
  projectId: ProjectId;
  name: string;
  description: string;
  from?: DateString | Moment;
  to?: DateString | Moment;
  budget?: number;
  isActive: boolean;
  isFavorite?: boolean;
  additionalInformation?: string;
  additionalInformation2?: string;
  additionalInformation3?: string;
  additionalInformation4?: string;
  isArchived?: boolean;
  rowVersion?: string;
}

export interface CreateProjectPhase {
  name: string;
  description: string;
  from: DateString | Moment;
  to: DateString | Moment;
  budget: number;
  isActive: boolean;
  isFavorite: boolean;
  additionalInformation: string;
  additionalInformation2: string;
  additionalInformation3: string;
  additionalInformation4: string;
}

export const DefaultProjectPhase: CreateProjectPhase = {
  name: 'Neue Phase',
  description: '',
  from: '',
  to: '',
  budget: 0,
  isActive: true,
  isFavorite: false,
  additionalInformation: '',
  additionalInformation2: '',
  additionalInformation3: '',
  additionalInformation4: '',
};

export interface UpdateProjectPhase {
  projectPhaseId: ProjectPhaseId;
  name: string;
  description: string;
  from: DateString | Moment;
  to: DateString | Moment;
  budget: number;
  isActive: boolean;
  isFavorite: boolean;
  additionalInformation: string;
  additionalInformation2: string;
  additionalInformation3: string;
  additionalInformation4: string;
}
