import { Reducer, combineReducers } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { DocumentSettings } from '../../../../models/UserSettings/DocumentSettings';
import { FETCH_USER_SETTINGS_COMMIT } from '../../actions';
import {
  UPDATE_DOCUMENTS_CACHING_ENABLED,
  UPDATE_DOCUMENT_SETTINGS_REQUEST,
} from '../../actions/documentSettings/documents';

export interface DocumentSettingsState {
  documentSettings: DocumentSettings;
  cachingDocumentsEnabled: boolean;
}

const initialState: DocumentSettings = {
  doubleClickOpenBehaviour: 'local',
  defaultPdfProgram: 'pdfXchange',
  documentListSpacing: 'middle',
};

export const documentSettings: Reducer<DocumentSettings, any> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_DOCUMENT_SETTINGS_REQUEST: {
      const {
        meta: { documentSettings },
      } = action;
      return {
        ...state,
        ...documentSettings,
      };
    }

    case FETCH_USER_SETTINGS_COMMIT: {
      const { payload } = action;
      return {
        doubleClickOpenBehaviour:
          payload.documentSettings.doubleClickOpenBehaviour,
        defaultPdfProgram: payload.documentSettings.defaultPdfProgram,
        documentListSpacing: payload.documentSettings.documentListSpacing,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export const cachingDocumentsEnabled: Reducer<boolean, any> = (
  state = false,
  action
) => {
  switch (action.type) {
    case UPDATE_DOCUMENTS_CACHING_ENABLED: {
      const { payload } = action;

      return payload;
    }

    case CLEAR_PRIO_CACHE: {
      return false;
    }
    default:
      return state;
  }
};

export default combineReducers<DocumentSettingsState>({
  documentSettings,
  cachingDocumentsEnabled,
});

export const getDocumentSettings: (
  state: DocumentSettingsState
) => DocumentSettings = (state) => state.documentSettings;

export const getDocumentsCachingEnabled: (
  state: DocumentSettingsState
) => boolean = (state) => state.cachingDocumentsEnabled;
