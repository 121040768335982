import React, { ChangeEvent, useState } from 'react';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import NavigationBar from '../../../../components/NavigationBar';
import { useTranslation } from 'react-i18next';
import ContactSearch from '../../../contacts/components/ContactSearch';
import { Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import useDebounce from '../../../../hooks/useDebounce';
import PersonnelFile from './PersonnelFile';
import {
  Route,
  Routes as ReactRouterDomRoutes,
  useParams,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddEmployeeDrawer } from '../AddEmployeeDrawer';
import { useNavigate } from 'react-router-dom';
import { OfficeId } from '../../../../models/Types';
import classNames from 'classnames';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../../theme/types';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
    maxHeight: '100%',
  },
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    height: '70%',
    overflow: 'hidden',
  },
  columnSearchContent: {
    borderRight: theme.old.borders.sub,
  },
  searchContainer: {
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
  },
  contactList: {
    flex: 1,
    borderTop: theme.old.borders.content,
  },
  contactListItem: {
    '& .ant-list-item-action': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      '& .ant-list-item-action': {
        visibility: 'visible',
      },
    },
    minHeight: 64,
  },
  selectAllCheckbox: {
    marginRight: theme.old.spacing.unit(2),
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
          background: 'transparent',
          padding: 0,
          width: 32,
          color: 'rgba(0, 0, 0, 0.6)',
          '&:hover': {
            background: 'transparent',
          },
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
  personnelFile: {
    flex: 1,
    padding: '24px',
  },
}));

interface PersonnelFilesProps {
  className?: string;
  officeId?: OfficeId;
  onlyShowTabs?: string[];
  hideNavBar?: boolean;
}

export const PersonnelFiles: React.FC<PersonnelFilesProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const { className, hideNavBar, onlyShowTabs } = props;
  const { officeId } = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [searchInput, setSearchInput] = React.useState<string>('');
  const debouncedSearchInput = useDebounce(searchInput, 1000);

  const [addEmployeeDrawerOpen, setAddEmployeeDrawerOpen] =
    useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.currentTarget.value);
  };

  const openAddEmployeeDrawer = () => setAddEmployeeDrawerOpen(true);
  const closeAddEmployeeDrawer = () => setAddEmployeeDrawerOpen(false);
  //#endregion

  return (
    <>
      <Flex.Column className={classNames(classes.root, className)}>
        {!hideNavBar && (
          <NavigationBar>
            <Button
              onClick={openAddEmployeeDrawer}
              iconProp={['fal', 'user-plus']}
            >
              <span>{t('hr:personnelFile.navigationBar.newEmployee')}</span>
            </Button>
          </NavigationBar>
        )}
        <Flex.Row className={classes.content} flex={1}>
          <Flex.Column className={classes.columnSearchContent}>
            <Flex.Row className={classes.searchContainer} alignItems="center">
              <Input.Search
                allowClear
                size="middle"
                placeholder={t(
                  'contacts:contactSelectionList.search.placeHolder'
                )}
                onChange={onSearchInputChange}
                className={classes.search}
                enterButton={
                  isOnline && <FontAwesomeIcon icon={['fal', 'globe']} />
                }
              />
            </Flex.Row>
            <ContactSearch
              type="allInternalContactsInOffice"
              searchTerm={debouncedSearchInput}
              onContactClick={(contactId) => navigate(contactId)}
              className={classes.contactList}
              officeId={officeId}
              searchTypeChanged={setIsOnline}
            />
          </Flex.Column>
          <Routes>
            <Route
              path=":employeeId"
              element={
                <PersonnelFile
                  className={classes.personnelFile}
                  officeId={officeId}
                  pathPrefix={'..'}
                  onlyShowTabs={onlyShowTabs}
                />
              }
            />
          </Routes>
        </Flex.Row>
      </Flex.Column>
      <AddEmployeeDrawer
        isDrawerOpen={addEmployeeDrawerOpen}
        onDrawerClose={closeAddEmployeeDrawer}
      />
    </>
  );
};

export default PersonnelFiles;
