import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import {
  Button,
  Empty,
  HistoryItem,
  HistoryItemPill,
  Modal,
  Pill,
  PrioSpinner,
  ResponsiveContainer,
  Tooltip,
} from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../../theme/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  apiDeleteSearchHistory,
  apiDeleteSearchHistoryByIds,
  apiFetchSearchHistory,
  apiPutSearchHistory,
} from '../../../../components/Filter/api';
import moment from 'moment';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import useCompaniesContext from '../../../companies/hooks/useCompaniesContext';
import useProjectsContext from '../../../projects/hooks/useProjectsContext';
import useOfficesContext from '../../../companies/hooks/useOfficesContext';
import { makePrioStyles } from '../../../../theme/utils';
import { generateFilterHistoryDrawerItems } from '../../../../components/Filter/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getSearchConfigBySearchType,
} from '../../../../apps/main/rootReducer';
import { fetchSearchConfigBySearchType } from '../../../../components/Filter/actions';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import { DriveItem } from '../../../../models/Drive';
import { notification } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
    height: '100%',
    overflow: 'hidden',
  },
  header: {
    fontSize: theme.font.fontSize.small,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.default,
    display: 'flex',
    gap: theme.spacing.small,
  },
  label: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
    whiteSpace: 'nowrap',
  },
  bookmarkRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.regular,
    padding: `${theme.spacing.small}px 16px`,
    '&:hover': {
      backgroundColor: theme.colors.application.background.hover,
    },
  },
  labelAndAction: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    gap: theme.spacing.small,
  },
  filterParameter: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: theme.spacing.small,
    flexShrink: 1,
    color: theme.colors.application.typography.default,
    fontSize: theme.font.fontSize.small,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionIcon: {
    cursor: 'pointer',
  },
  pillTitle: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pillLabel: {
    color: theme.colors.application.typography.default,
    fontSize: theme.font.fontSize.small,
  },
  spinnerRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  row: {
    borderBottom: `1px solid ${theme.colors.application.border}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  actionIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  emptyContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing.extraLarge,
  },
}));

interface GlobalDocumentsSearchBookmarksAndHistoryProps {
  className?: string;
  setHistoryItemInModal: (item: HistoryItem) => void;
  setLastCombinedSearchString: (searchString: string) => void;
}

const STEPSIZE = 10;

export const GlobalDocumentsSearchBookmarksAndHistory: React.FC<
  GlobalDocumentsSearchBookmarksAndHistoryProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, setHistoryItemInModal, setLastCombinedSearchString } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { fetchSearch } = useFilterContext<DriveItem, {}>();

  const handleFetchSearch = (searchString: string) => {
    fetchSearch(searchString);
    setLastCombinedSearchString(searchString);
  };

  const { getContactById } = useContactsContext();
  const { getCompanyById } = useCompaniesContext();
  const { getProjectById } = useProjectsContext();
  const { getOfficeById } = useOfficesContext();

  const { data, isLoading } = useQuery({
    queryKey: ['searchAPI', 'documents', 'historyItems'],
    queryFn: () => apiFetchSearchHistory('documents', false, 100),
  });

  const searchParameters = useSelector((state: RootReducerState) =>
    getSearchConfigBySearchType(state, 'documents')
  );

  const [numberOfItems, setNumberOfItems] = useState<number>(STEPSIZE);
  const [isTitleHovered, setIsTitleHovered] = useState(false);
  const [isDeleteAllModalVisible, setIsDeleteAllModalVisible] = useState(false);

  const bookmarkItems = data?.data

    ?.filter((item) => item.isBookmarked)

    ?.sort((a, b) => {
      const _a = a.description;
      const _b = b.description;
      if (_a && !_b) return -1;
      if (!_a && _b) return 1;
      if (_a && _b) return a.description.localeCompare(b.description);
      return 0;
    });
  const historyItems = data?.data
    ?.filter(
      (item) =>
        !bookmarkItems?.find((i) => i.searchHistoryId === item.searchHistoryId)
    )
    .sort((a, b) => moment(b.searchedAt).diff(moment(a.searchedAt)));
  const filters = searchParameters?.searchableParameters;

  const bookmarkItemsWithPills = useMemo<HistoryItem[] | null>(
    () =>
      generateFilterHistoryDrawerItems(
        bookmarkItems,
        filters,
        getContactById,
        getCompanyById,
        getProjectById,
        getOfficeById,
        t
      ),
    [
      bookmarkItems,
      filters,
      getContactById,
      getCompanyById,
      getProjectById,
      getOfficeById,
      t,
    ]
  );

  const historyItemsWithPills = useMemo<HistoryItem[] | null>(
    () =>
      generateFilterHistoryDrawerItems(
        historyItems,
        filters,
        getContactById,
        getCompanyById,
        getProjectById,
        getOfficeById,
        t
      ),
    [
      historyItems,
      filters,
      getContactById,
      getCompanyById,
      getProjectById,
      getOfficeById,
      t,
    ]
  );

  const isHistoryAvailable = useMemo(
    () => historyItemsWithPills?.length > 0,
    [historyItemsWithPills]
  );

  const items = useMemo(() => {
    return [
      ...(bookmarkItemsWithPills || []),
      ...(historyItemsWithPills || []),
    ];
  }, [historyItemsWithPills, bookmarkItemsWithPills]);

  const displayedItems = items.slice(0, numberOfItems);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const increaseNumberOfItems = () => {
    if (numberOfItems >= items.length) return;
    setNumberOfItems((prev) => prev + STEPSIZE);
  };

  const bookmarkItem = async (historyItem: HistoryItem) => {
    await apiPutSearchHistory(historyItem.searchHistoryId, {
      description: historyItem?.description,
      isBookmarked: !historyItem?.isBookmarked,
      sortKey: historyItem?.sortKey,
    });
  };

  const handleHistoryItemClicked = (historyItem: HistoryItem) => {
    handleFetchSearch(historyItem?.searchString);
  };

  const handleDeleteEntireSearchHistory = async () => {
    const { result } = await apiDeleteSearchHistory('documents');
    if (result.status >= 400) {
      notification.open({
        message: t('common:actions.error'),
        description: t(
          'components:filter.globalDocumentsSearch.errorMessages.deleteAllHistoryItemsError'
        ),
      });
    }
  };

  const deleteAllModalClicked = async () => {
    setIsDeleteAllModalVisible(false);
    await handleDeleteEntireSearchHistory();
    queryClient.invalidateQueries({
      queryKey: ['searchAPI', 'documents', 'historyItems'],
    });
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!searchParameters) {
      dispatch(fetchSearchConfigBySearchType('documents'));
    }
  }, [searchParameters, dispatch]);
  //#endregion

  //#region ------------------------------ Render
  const renderItems = () => {
    if (displayedItems.length === 0 && !isLoading)
      return (
        <div className={classes.emptyContainer}>
          <Empty
            customMessage={t(
              'components:filter.globalDocumentsSearch.noHistroy'
            )}
          />
        </div>
      );
    return (
      <div style={{ paddingBottom: theme.spacing.small }}>
        {displayedItems.map((item, i) => {
          const isBookmark = item.isBookmarked;
          return (
            <div className={classes.row} key={i}>
              {isBookmark ? (
                <Bookmark
                  filterParameter={item.pills}
                  item={item}
                  bookmarkItem={bookmarkItem}
                  setHistoryItemInModal={setHistoryItemInModal}
                  handleHistoryItemClicked={handleHistoryItemClicked}
                />
              ) : (
                <History
                  filterParameter={item.pills}
                  item={item}
                  bookmarkItem={bookmarkItem}
                  handleHistoryItemClicked={handleHistoryItemClicked}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  //#endregion
  return (
    <div className={classNames(classes.root, className)}>
      <div
        className={classes.header}
        onMouseEnter={() => setIsTitleHovered(true)}
        onMouseLeave={() => setIsTitleHovered(false)}
      >
        <div>
          {t('components:filter.globalDocumentsSearch.savedSearchesAndHistory')}
        </div>
        {isHistoryAvailable && (
          <Tooltip
            overlay={t(
              'components:filter.globalDocumentsSearch.actions.deleteAll'
            )}
            placement="top"
          >
            <div className={classes.actionIconWrapper}>
              <FontAwesomeIcon
                className={classes.actionIcon}
                style={{ visibility: isTitleHovered ? 'visible' : 'hidden' }}
                icon={['fal', 'trash']}
                color={theme.colors.base.red.default}
                onClick={() => setIsDeleteAllModalVisible(true)}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <div style={{ overflowY: 'auto', height: '100%' }}>
        {renderItems()}
        {isLoading && (
          <div className={classes.spinnerRow}>
            <PrioSpinner size="small" />
          </div>
        )}
        {numberOfItems < items.length && (
          <div className={classes.buttonRow}>
            <Button type="link" onClick={increaseNumberOfItems}>
              {t('components:filter.globalDocumentsSearch.showMore')}
            </Button>
          </div>
        )}
      </div>
      <Modal
        visible={isDeleteAllModalVisible}
        onClose={() => setIsDeleteAllModalVisible(false)}
        onOk={deleteAllModalClicked}
        cancelText={t(
          'components:filter.globalDocumentsSearch.deleteAllModal.cancelText'
        )}
        okText={t(
          'components:filter.globalDocumentsSearch.deleteAllModal.okText'
        )}
        title={t(
          'components:filter.globalDocumentsSearch.deleteAllModal.title'
        )}
      >
        {t('components:filter.globalDocumentsSearch.deleteAllModal.content')}
      </Modal>
    </div>
  );
};

export default GlobalDocumentsSearchBookmarksAndHistory;

interface BookmarkProps {
  filterParameter?: HistoryItemPill[];
  item: HistoryItem;
  bookmarkItem: (historyItem: HistoryItem) => void;
  setHistoryItemInModal: (item: HistoryItem) => void;
  handleHistoryItemClicked: (historyItem: HistoryItem) => void;
}

const Bookmark: React.FC<BookmarkProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    filterParameter = [],
    item,
    bookmarkItem,
    setHistoryItemInModal,
    handleHistoryItemClicked,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const queryClient = useQueryClient();
  const [isHovered, setIsHovered] = useState(false);
  const label = item.description;
  const dateString = moment
    .utc(item.searchedAt)
    .local()
    .format('DD.MM.YYYY - HH:mm');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleUnbookmark = async (e) => {
    e.stopPropagation();
    await bookmarkItem(item);
    queryClient.invalidateQueries({
      queryKey: ['searchAPI', 'documents'],
    });
  };

  const handleEditBookmark = (e, value: HistoryItem) => {
    e.stopPropagation();
    setHistoryItemInModal(value);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Render
  const renderPills = () => {
    if (filterParameter.length === 0)
      return (
        <div style={{ paddingRight: theme.spacing.small }}>
          {t('components:filter.globalDocumentsSearch.noFilterParameter')}
        </div>
      );
    return (
      <ResponsiveContainer>
        {filterParameter.map((pill, index) => {
          return (
            <Pill type="neutral" size={'small'} key={index}>
              <span className={classes.pillTitle}>{pill.title}: </span>
              <span className={classes.pillLabel}>{pill.text}</span>
            </Pill>
          );
        })}
      </ResponsiveContainer>
    );
  };
  //#endregion

  return (
    <div
      className={classes.bookmarkRow}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleHistoryItemClicked(item)}
    >
      <div className={classes.labelAndAction}>
        <FontAwesomeIcon
          icon={['far', 'bookmark']}
          color={theme.colors.base.yellow.default}
          style={{ width: 16, height: 16 }}
        />
        {label && <div className={classes.pillTitle}>{label}</div>}
        {!label && <div className={classes.pillTitle}>{dateString}</div>}
        <Tooltip overlay={t('common:actions.edit')} placement="top">
          <div className={classes.actionIconWrapper}>
            <FontAwesomeIcon
              className={classes.actionIcon}
              style={{ visibility: isHovered ? 'visible' : 'hidden' }}
              icon={['fal', 'pen']}
              color={theme.colors.base.primary.default}
              onClick={(e) => handleEditBookmark(e, item)}
            />
          </div>
        </Tooltip>
        <Tooltip
          overlay={t(
            'components:filter.globalDocumentsSearch.actions.unbookmark'
          )}
          placement="top"
        >
          <div className={classes.actionIconWrapper}>
            <FontAwesomeIcon
              className={classes.actionIcon}
              style={{ visibility: isHovered ? 'visible' : 'hidden' }}
              icon={['fal', 'bookmark-slash']}
              color={theme.colors.base.primary.default}
              onClick={handleUnbookmark}
            />
          </div>
        </Tooltip>
      </div>
      <div className={classes.filterParameter}>{renderPills()}</div>
    </div>
  );
};

interface HistoryProps {
  filterParameter?: HistoryItemPill[];
  item: HistoryItem;
  bookmarkItem: (historyItem: HistoryItem) => void;
  handleHistoryItemClicked: (historyItem: HistoryItem) => void;
}

const History: React.FC<HistoryProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    filterParameter = [],
    item,
    bookmarkItem,
    handleHistoryItemClicked,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isHovered, setIsHovered] = useState(false);
  const queryClient = useQueryClient();
  const dateString = moment
    .utc(item.searchedAt)
    .local()
    .format('DD.MM.YYYY - HH:mm');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const makeBookmark = async (e) => {
    e.stopPropagation();
    await bookmarkItem(item);
    queryClient.invalidateQueries({
      queryKey: ['searchAPI', 'documents'],
    });
  };

  const deleteHistroyItem = async (historyItemId: string) => {
    const { result } = await apiDeleteSearchHistoryByIds(historyItemId);
    if (result.status >= 400) {
      notification.open({
        message: t('common:actions.error'),
        description: t(
          'components:filter.globalDocumentsSearch.errorMessages.deleteHistoryItemError'
        ),
      });
    }
    if (result.status < 400 && result.status >= 200) {
      queryClient.invalidateQueries({
        queryKey: ['searchAPI', 'documents'],
      });
    }
  };

  const handleDeleteItem = (e) => {
    e.stopPropagation();
    deleteHistroyItem(item.searchHistoryId);
  };

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Render
  const renderPills = () => {
    if (filterParameter.length === 0)
      return (
        <div style={{ paddingRight: theme.spacing.small }}>
          {t('components:filter.globalDocumentsSearch.noFilterParameter')}
        </div>
      );
    return (
      <ResponsiveContainer>
        {filterParameter.map((pill, index) => {
          return (
            <Pill type="neutral" size={'small'} key={index}>
              <span className={classes.pillTitle}>{pill.title}: </span>
              <span className={classes.pillLabel}>{pill.text}</span>
            </Pill>
          );
        })}
      </ResponsiveContainer>
    );
  };
  //#endregion

  return (
    <div
      className={classes.bookmarkRow}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleHistoryItemClicked(item)}
    >
      <div className={classes.labelAndAction}>
        <FontAwesomeIcon
          icon={['fal', 'rotate-left']}
          color={theme.colors.application.typography.default}
          style={{ width: 16, height: 16 }}
        />
        <div className={classes.label}>{dateString}</div>
        <Tooltip overlay={t('common:actions.save')} placement="top">
          <div className={classes.actionIconWrapper}>
            <FontAwesomeIcon
              className={classes.actionIcon}
              style={{ visibility: isHovered ? 'visible' : 'hidden' }}
              icon={['fal', 'bookmark']}
              color={theme.colors.base.primary.default}
              onClick={makeBookmark}
            />
          </div>
        </Tooltip>
        <Tooltip overlay={t('common:actions.delete')} placement="top">
          <div className={classes.actionIconWrapper}>
            <FontAwesomeIcon
              className={classes.actionIcon}
              style={{ visibility: isHovered ? 'visible' : 'hidden' }}
              icon={['fal', 'trash']}
              color={theme.colors.base.red.default}
              onClick={handleDeleteItem}
            />
          </div>
        </Tooltip>
      </div>
      <div className={classes.filterParameter}>{renderPills()}</div>
    </div>
  );
};
