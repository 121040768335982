import { takeEvery, put, delay, select } from 'redux-saga/effects';
import { syncContactsMe } from '../modules/contacts/actions';
import { fetchProjects } from '../modules/projects/actions';
import { fetchUserMe } from '../modules/users/actions';
import {
  getActiveMailFolderId,
  getActiveProject,
  getAllProjects,
  getCompanyOffset,
  getContactOffset,
  getMailFoldersByIdState,
  getWidgetAreaDisplayState,
} from '../apps/main/rootReducer';
import { fetchNotifications } from '../modules/notifications/actions';
import { PRIO } from '../constants';
import { fetchMessagesSagaAction } from '../modules/mail/actions/sagas';
import { Project } from '../models/Project';
import { ProjectId } from '../models/Types';
import { syncCompanies } from '../modules/companies/actions';
import { SAGA_REBUILD } from '../util/sagas';
import { WidgetAreaDisplayState } from '../modules/widgetArea/reducers';
import { MailFolderByMailFolderId } from '../modules/mail/actions/types';

export const WS_RECONNECT = PRIO + 'WS_RECONNECT';

function* reconnect(store: any) {
  try {
    const activeProjectId: ProjectId = yield select(getActiveProject);
    const contactOffset = yield select(getContactOffset);
    const companyOffset = yield select(getCompanyOffset);
    const projects: Project[] = yield select(getAllProjects);
    const { open, selectedWidget }: WidgetAreaDisplayState = yield select(
      getWidgetAreaDisplayState
    );

    yield put(fetchUserMe());
    if (
      activeProjectId === 'me' &&
      window.location.href.includes('messageCenter')
    ) {
      if (window.location.href.includes('favorites')) {
        projects
          .filter((project) => project.favorite)
          .forEach((project) => {
            if (store.dispatch) {
              store.dispatch(
                fetchMessagesSagaAction(project.projectId, 'inbox', true, true)
              );
            }
          });
      } else if (
        window.location.href.match(
          /\/messageCenter\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/(mail\/inbox)/
        )
      ) {
        yield put(
          fetchMessagesSagaAction(
            window.location.href.match(
              /\/messageCenter\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/(mail\/inbox)/
            )[1],
            'inbox',
            true,
            true
          )
        );
      }
    } else {
      const activeMailFolderId = yield select((state) =>
        getActiveMailFolderId(state, activeProjectId)
      );
      const mailFolderById: MailFolderByMailFolderId = yield select((state) =>
        getMailFoldersByIdState(state, activeProjectId)
      );

      if (
        activeMailFolderId &&
        (mailFolderById[activeMailFolderId] || activeMailFolderId === 'inbox')
      ) {
        yield put(
          fetchMessagesSagaAction(
            activeProjectId,
            activeMailFolderId,
            true,
            true
          )
        );
      }
    }
    if (open && activeProjectId !== 'me' && selectedWidget === 'mail') {
      yield put(fetchMessagesSagaAction('me', 'inbox', true, true));
    }
    yield put(fetchProjects(true));
    yield put(fetchNotifications());

    if (contactOffset) {
      yield delay(4000);
    }
    yield put(syncContactsMe());
    if (companyOffset) {
      yield delay(3000);
      yield put(syncCompanies());
    }
  } catch (error) {
    console.error('Error in WS_RECONNECT', error);
  }
}

export default function* watchWebsocketReconnect(store: any) {
  yield takeEvery([WS_RECONNECT, SAGA_REBUILD], reconnect, store);
}
