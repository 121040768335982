import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useSelector } from 'react-redux';
import { getNotificationsIsFetching } from '../../../../apps/main/rootReducer';
import NotificationList from '../../../notifications/components/NotificationList';
import { Typography } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  notificationList: {
    flex: 1,
    overflow: 'auto',
  },
  spinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface DashboardAbsenceNotificationsItemProps {
  className?: string;
}

export const DashboardAbsenceNotificationsItem: React.FC<
  DashboardAbsenceNotificationsItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isFetching = useSelector(getNotificationsIsFetching);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      {isFetching ? (
        <Flex.Item flex={1} className={classes.spinner}>
          <PrioSpinner size="large" />
        </Flex.Item>
      ) : (
        <>
          <Typography.Title level={3} ellipsis style={{ marginBottom: 16 }}>
            Benachrichtigungen Urlaub & Abwesenheit
          </Typography.Title>
          <NotificationList
            type="absences"
            onNotificationClick={() => {}}
            className={classes.notificationList}
          />
        </>
      )}
    </Flex.Column>
  );
};

export default DashboardAbsenceNotificationsItem;
