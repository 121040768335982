import React, { useState } from 'react';
import { Typography } from 'antd';
import TimeRecordsTabs, { TimeRecordTabs } from './TimeRecordsTabs';
import { TimeRecordId } from '../../../models/Types';
import TimeRecordDetailsDrawer from './TimeRecordDetailsDrawer';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.old.spacing.defaultPadding,
  },
  tabs: {
    height: '100%',
  },
  tabsContent: {
    overflow: 'hidden',
  },
}));

declare type TimeRecordDrawerView = 'tabs' | 'edit';

interface TimeRecordsTaskPaneProps {}

export const TimeRecordsTaskPane: React.FC<TimeRecordsTaskPaneProps> = (
  props
) => {
  const classes = useStyles();
  const [view, setView] = useState<TimeRecordDrawerView>('tabs');
  const [tab, setTab] = useState<TimeRecordTabs>('new');
  const [selectedTimeRecordId, setSelectedTimeRecordId] = useState<
    string | null
  >();
  const { t } = useTranslation();

  const onTabChange = (activeKey: TimeRecordTabs) => {
    setTab(activeKey);
  };
  const onTimeRecordClick = (timeRecordId: TimeRecordId) => {
    setSelectedTimeRecordId(timeRecordId);
    setView('edit');
  };

  const goBack = () => {
    setView('tabs');
    setSelectedTimeRecordId(null);
  };

  const switchToOverview = () => {
    setTab('overview');
    goBack();
  };

  const redirectTimeRecordId = (timeRecordId: TimeRecordId) => {
    setSelectedTimeRecordId(timeRecordId);
  };

  const renderContent = () => {
    switch (view) {
      case 'tabs':
        return (
          <Flex.Column className={classes.tabs}>
            <Typography.Title level={2}>
              {t('timeRecords:title')}
            </Typography.Title>
            <Flex.Item flex={1} className={classes.tabsContent}>
              <TimeRecordsTabs
                activeKey={tab}
                onChange={onTabChange}
                onTimeRecordClick={onTimeRecordClick}
              />
            </Flex.Item>
          </Flex.Column>
        );
      case 'edit':
        return (
          <TimeRecordDetailsDrawer
            goBack={goBack}
            switchToOverview={switchToOverview}
            timeRecordId={selectedTimeRecordId}
            redirectTimeRecordId={redirectTimeRecordId}
          />
        );

      default:
        return <></>;
    }
  };

  return <div className={classes.root}>{renderContent()}</div>;
};

export default TimeRecordsTaskPane;
