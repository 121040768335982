import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { AccountingSearch, Invoice } from '../../../models/Accounting';
import { apiFetchInvoicesSearch } from '../api';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import ProjectPicker from '../../projects/components/ProjectPicker';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  trashIcon: {
    color: `${theme.old.palette.chromaticPalette.red}!important`,
  },
  resetButton: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.white,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

interface AccountingSearchbarProps {
  className?: string;
  officeId?: string;
  onSearchResult: (data: Invoice[]) => void;
  resetAdvancedInvoiceFilter?: () => void;
}

export const AccountingSearchbar: React.FC<AccountingSearchbarProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { onSearchResult, resetAdvancedInvoiceFilter, officeId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();
  const [form] = Form.useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnFinish = async (value: AccountingSearch) => {
    // call api to search
    const { data } = await apiFetchInvoicesSearch(value, officeId);
    if (data) {
      if (onSearchResult) {
        onSearchResult(data);
      }
    }
  };

  const resetFilters = () => {
    form.resetFields();
    form.submit();
    if (resetAdvancedInvoiceFilter) {
      resetAdvancedInvoiceFilter();
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Form
      className={classes.root}
      onFinish={handleOnFinish}
      initialValues={{}}
      form={form}
      layout="vertical"
    >
      <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
        <Flex.Column flex={1}>
          <Form.Item
            label={t('accounting:search.labels.invoiceIds')}
            name="invoiceIds"
          >
            <Select mode="tags" tokenSeparators={[',']} />
          </Form.Item>
        </Flex.Column>
        <Flex.Column flex={1}>
          <Form.Item
            label={t('accounting:search.labels.numbers')}
            name="numbers"
          >
            <Select mode="tags" tokenSeparators={[',']} />
          </Form.Item>
        </Flex.Column>
      </Flex.Row>
      <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
        <Flex.Column flex={1}>
          <Form.Item label={t('accounting:search.labels.titles')} name="titles">
            <Select mode="tags" tokenSeparators={[',']} />
          </Form.Item>
        </Flex.Column>
        <Flex.Column flex={1}>
          <Form.Item
            label={t('accounting:search.labels.projectIds')}
            name="projectIds"
          >
            <ProjectPicker
              officeId={officeId}
              label={t('common:filter.placeholder.projectId')}
              multiple
              contextType="office"
            />
          </Form.Item>
        </Flex.Column>
      </Flex.Row>
      <Flex.Row
        justifyContent="flex-end"
        childrenGap={theme.old.spacing.unit(2)}
      >
        <Button
          type="link"
          className={classes.resetButton}
          onClick={resetFilters}
          iconProp={['fal', 'trash']}
        >
          {t('common:filter.resetFilter')}
        </Button>
        <Button type="primary" htmlType="submit" iconProp={['fal', 'search']}>
          {t('accounting:search.actions.search')}
        </Button>
      </Flex.Row>
    </Form>
  );
};

export default AccountingSearchbar;
