import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { Drawer, Dropdown, useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../../../theme/types';
import ActionBar from '../../../components/ActionBar';
import ListMenuArea from '../../common/ListMenuArea';
import { apiGetAllProjectExtensionUserConfiguration } from '../../projects/api';
import { ProjectExtensionUserConfiguration } from '../../../models/ProjectExtension';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import { getUserMe } from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';
import JiraUserConfigurationCreate from './JiraUserConfigurationCreate';
import JiraUserConfigurationForm from './JiraUSerConfigurationForm';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  item: {
    color: theme.colors.application.typography.default,
    fontWeight: theme.font.fontSize.regular,
  },
  subItem: {
    color: theme.colors.application.typography.muted,
    fontWeight: theme.font.fontSize.extraSmall,
    float: 'left',
  },
  listItem: {
    padding: `${theme.spacing.regular * 0.5}px ${theme.spacing.regular}px`,
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& h2': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: `${theme.spacing.regular * 0.5}px`,
    },
  },
  icon: {
    minWidth: theme.spacing.regular * 1.5,
    fontSize: theme.font.fontSize.extraSmall,
  },
  overlay: {
    '& > ul': {
      paddingInlineStart: '0px',
      marginBlock: '0.5rem',
    },
  },
}));

interface ConfigAddInsProps {
  className?: string;
}

export const UserAddInsConfig: React.FC<ConfigAddInsProps> = (props) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const extensionTypes = useMemo(() => ['jira'], []);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [selectedExtensionType, setSelectedExtensionType] =
    useState<string>('');
  const me = useSelector(getUserMe);
  const contactId = me.id;
  const [
    projectExtensionUserConfiguration,
    setProjectExtensionUserConfiguration,
  ] = useState<ProjectExtensionUserConfiguration[]>([]);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [
    fetchProjectExtensionUserConfiguration,
    setfetchProjectExtensionUserConfiguration,
  ] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchProjectExtensionUserConfiguration = async () => {
      setfetchProjectExtensionUserConfiguration(true);
      const { data } = await apiGetAllProjectExtensionUserConfiguration();
      setProjectExtensionUserConfiguration(data);
      setfetchProjectExtensionUserConfiguration(false);
    };
    fetchProjectExtensionUserConfiguration();
  }, [setfetchProjectExtensionUserConfiguration]);

  const handleSelectExtensionType = (selectedOption) => {
    if (selectedOption) {
      const index = parseInt(selectedOption.value);
      setSelectedExtensionType(extensionTypes[index]);
      setOpenNew(true);
    }
  };

  const projectExtensionUserConfigurationChanged = async () => {
    setfetchProjectExtensionUserConfiguration(true);
    const { data } = await apiGetAllProjectExtensionUserConfiguration();
    setProjectExtensionUserConfiguration(data);
    setfetchProjectExtensionUserConfiguration(false);
    setOpenNew(false);
  };

  //#endregion

  const options = extensionTypes?.map((extType, index) => ({
    label: extType.charAt(0).toUpperCase() + extType.slice(1),
    value: index.toString(),
    disabled: projectExtensionUserConfiguration?.some(
      (ext) => ext.extensionType === extType
    ),
  }));

  const ProjectExtensionListItem: React.FC<{
    index: number;
    key: string;
    isScrolling: boolean;
    isVisible: boolean;
  }> = ({ index }) => {
    return (
      <div className={classes.listItem}>
        <Flex.Row
          className={classes.item}
          childrenGap={theme.spacing.extraSmall}
          alignItems="center"
        >
          <SvgIcon
            width={40}
            height={40}
            path={getSvgIconPathByType(
              projectExtensionUserConfiguration[index].extensionType
            )}
          ></SvgIcon>
          <div style={{ fontSize: '16px', fontWeight: '500' }}>
            {projectExtensionUserConfiguration[index]?.extensionType
              .charAt(0)
              .toUpperCase() +
              projectExtensionUserConfiguration[index]?.extensionType.slice(1)}
          </div>
        </Flex.Row>
      </div>
    );
  };

  return (
    <>
      <Flex.Column flex={1} className={classes.root}>
        <Flex.Row childrenGap={theme.spacing.regular} marginBottom={16}>
          <ActionBar>
            <Dropdown
              options={options}
              onSelect={handleSelectExtensionType}
              overlayClassName={classNames(classes.overlay, 'prio-dropdown')}
              buttonProps={{
                type: 'primary',
                suffixIconProp: ['fal', 'chevron-down'],
                disabled: options?.every((option) => option.disabled),
              }}
              defaultLabel={t('projects:addIn.addInsConfig.newConfig')}
              dropdownWidth="100"
              placement="bottomRight"
              overlayStyle={{ paddingLeft: '-40px' }}
            ></Dropdown>
          </ActionBar>
        </Flex.Row>
        <Flex.Row className={classes.root}>
          <ListMenuArea
            menuItems={projectExtensionUserConfiguration?.sort((a, b) =>
              a.extensionType.localeCompare(b.extensionType)
            )}
            menuItemIdKey="projectExtensionUserConfigurationId"
            menuItemEmptyString={t('projects:addIn.addInsConfig.emptyText')}
            listItem={ProjectExtensionListItem}
            pageNode={
              projectExtensionUserConfiguration &&
              projectExtensionUserConfiguration.map((config, index) => (
                <React.Fragment key={index}>
                  {config.extensionType === 'jira' && (
                    <JiraUserConfigurationForm
                      projectExtensionUserConfigurationChanged={
                        projectExtensionUserConfigurationChanged
                      }
                    />
                  )}
                </React.Fragment>
              ))
            }
            isLoading={fetchProjectExtensionUserConfiguration}
          />
        </Flex.Row>
      </Flex.Column>
      <Drawer
        title={t('projects:addIn.jiraConfig.newJiraConfig')}
        visible={openNew}
        closable={true}
        onClose={() => {
          setOpenNew(false);
        }}
      >
        {selectedExtensionType === 'jira' && (
          <JiraUserConfigurationCreate
            contactId={contactId}
            projectExtensionUserConfigurationChanged={
              projectExtensionUserConfigurationChanged
            }
          />
        )}
      </Drawer>
    </>
  );
};

export default UserAddInsConfig;
