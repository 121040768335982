import moment, { Moment } from 'moment';
import React from 'react';
import {
  DateTimeString,
  DateString,
  SubProjectId,
  TimeRecordId,
  ContactId,
  ProjectId,
  InvoiceId,
  CompanyId,
  ProjectPhaseId,
  OfficeId,
} from './Types';
import { GenericSearchResultItem } from '../components/Filter/types';

export interface TimeRecord {
  timeRecordId: TimeRecordId;
  companyId?: CompanyId;
  projectId: ProjectId;
  subProjectId?: SubProjectId;
  description?: string;
  day: DateString; //absolut
  contactId: ContactId;
  kilometerDistance: number;
  title: string;
  invoiceId?: InvoiceId;
  invoiceNumber?: string;
  timeRecordEntries: TimeRecordEntry[];
  durationInMinutes: number;
  projectPhaseId?: ProjectPhaseId;
  isSeparateService?: boolean;
  rowVersion?: string;
}

export interface TimeRecordCalculatedData {
  hours?: number;
  internalOfficeId?: OfficeId;
  externalOfficeId?: OfficeId;
  projectPhase?: string;
}

export type TimeRecordSearchResultItem = GenericSearchResultItem<
  TimeRecord,
  TimeRecordCalculatedData
>;

export interface TimeRecordEntry {
  key?: React.Key;
  startTime: DateTimeString; //absolut
  endTime: DateTimeString; //absolut
}

export interface CreateTimeRecordFormInitialState {
  timeRecords: TimeRecordEntryMoment;
  dayRange: [Moment, Moment];
}

export interface TimeRecordEntryMoment {
  key?: React.Key;
  dayRecordKey?: React.Key;
  startTime: Moment;
  endTime: Moment;
}

export interface DayRecordEntry {
  key: React.Key;
  date: Moment;
  kilometerDistance?: number;
  holidayName?: string;
  isWeekend?: boolean;
  isHoliday?: boolean;
  isVacation?: boolean;
  timeRecordEntries?: TimeRecordEntryMoment[];
}
export interface CreateTimeRecordRequest {
  timeRecordId?: TimeRecordId;
  projectId?: ProjectId;
  subProjectId?: SubProjectId;
  description?: string;
  day?: DateString | Moment; //absolut
  contactId?: ContactId;
  kilometerDistance?: number;
  title: string;
  timeRecordEntries: TimeRecordEntry[];
  projectPhaseId?: ProjectPhaseId;
  isSeparateService?: boolean;
}
export interface CreateTimeRecordsRequest {
  timeRecordId?: TimeRecordId;
  projectId?: ProjectId;
  contactId?: ContactId;
  timeRecords: CreateTimeRecordRequest[];
}

export interface TimeRecordFormModel {
  projectId?: ProjectId;
  subProjectId?: SubProjectId;
  description?: string;
  day?: Moment; //absolut
  dayRange?: Moment[];
  periods: [Moment, Moment][]; //absolut
  //periods: { [key: string]: [Moment, Moment] };
  contactId?: ContactId;
  kilometerDistance?: string;
  title: string;
  timeRecordEntries: TimeRecordEntry[];
  dayRecordEntries?: DayRecordEntry[];
  projectPhaseId?: ProjectPhaseId;
  isSeparateService?: boolean;
}

export const emptyCreateTimeRecordRequest: CreateTimeRecordRequest = {
  title: '',
  timeRecordEntries: [
    {
      startTime: moment('09:00', 'HH:mm').toISOString(),
      endTime: moment('12:00', 'HH:mm').toISOString(),
    },
  ],
  day: moment(),
};

export interface UpdateTimeRecordRequest {
  timeRecordId: TimeRecordId;
  projectId?: ProjectId;
  subProjectId?: SubProjectId;
  description?: string;
  day?: DateString; //absolut
  contactId?: ContactId;
  kilometerDistance?: number;
  title?: string;
  timeRecordEntries?: TimeRecordEntry[];
  projectPhaseId?: ProjectPhaseId;
  isSeparateService?: boolean;
  rowVersion?: string;
}

export interface TimeRecordsFilter {
  companyIds?: CompanyId[];
  projectIds?: ProjectId[];
  contactIds?: ContactId[];
  from?: Moment; //absolut
  to?: Moment; //absolut
}

export interface TimeRecordsFilterRequest {
  companyIds?: CompanyId[];
  projectIds?: ProjectId[];
  contactIds?: ContactId[];
  from?: string; //absolut
  to?: string; //absolut
}
