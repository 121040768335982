import React, { useEffect, useState } from 'react';
import { Divider, Input, notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { apiCreateQualification } from '../api';
import { Qualification } from '../../../models/Qualification';
import { apiFetchQualifications } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface QualificationPickerProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  multiple?: boolean;
}

export const QualificationPicker: React.FC<QualificationPickerProps> = (
  props
) => {
  const classes = useStyles();
  const [newQualificationName, setNewQualificationName] = useState<string>('');
  const [qualifications, setQualifications] = useState<Qualification[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const { t } = useTranslation();

  const { value, onChange, disabled, multiple } = props;

  const onNewQualificationNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewQualificationName(event.target.value);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadTags = async () => {
      try {
        const { data } = await apiFetchQualifications(signal);
        if (data && Array.isArray(data)) {
          setQualifications(data.sort((a, b) => a.name.localeCompare(b.name)));
        }
        setFetching(false);
      } catch {}
    };
    loadTags();
    return () => {
      controller.abort();
    };
  }, [setQualifications]);

  const createTag = async () => {
    const { result, data } = await apiCreateQualification(newQualificationName);
    if (result.status >= 200 && result.status < 300) {
      setQualifications(
        [...qualifications, data].sort((a, b) => a.name.localeCompare(b.name))
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'hr:qualificationPicker.errorMessages.createQualificationError'
        ),
      });
    }
  };

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      disabled={disabled}
      className={classes.root}
      mode={multiple ? 'multiple' : null}
      value={value}
      placeholder={t('hr:qualificationPicker.placeholder')}
      notFoundContent={fetching ? <PrioSpinner size="small" /> : null}
      onChange={handleOnChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              value={newQualificationName}
              onChange={onNewQualificationNameChange}
            />
            <Button
              disabled={newQualificationName.trim().length === 0}
              onClick={createTag}
              iconProp={['fal', 'plus']}
            >
              {t('hr:qualificationPicker.addAction')}
            </Button>
          </div>
        </div>
      )}
    >
      {qualifications.length === 0 ? (
        <Select.Option value="" disabled>
          {t('hr:qualificationPicker.emptyQualificationMessage')}
        </Select.Option>
      ) : null}
      {qualifications.map(({ name, qualificationId }) => (
        <Select.Option key={qualificationId} value={qualificationId}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default QualificationPicker;
