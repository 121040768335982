import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { useMemo, useState } from 'react';
import { PieChart } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { Bar } from '@prio365/prio365-react-library/lib/Charts/components/PieChart';

const useStyles = makePrioStyles((theme) => ({
  numbers: {
    fontWeight: theme.font.fontWeight.bold,
    paddingTop: theme.spacing.small,
  },
  subtext: {
    fontSize: theme.font.fontSize.small,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.base.primary.default,
    marginTop: theme.spacing.extraSmall * -1,
    textAlign: 'center',
    maxWidth: '100px',
    lineHeight: '1.15em',
  },
}));

interface AbsenceOverviewPieChartProps {
  absenceOverview: AbsenceOverview;
}

export const AbsenceOverviewPieChart: React.FC<AbsenceOverviewPieChartProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { absenceOverview } = props;

  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();

  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [hoveredOnBar, setHoveredOnBar] = useState<Bar | undefined>(undefined);

  const {
    bars,
    dividers,
    totalVacationDays,
    totalTakenVacationDays,
    remainingVacationDays,
  } = useMemo(() => {
    const {
      takenVacationDays,
      requestedVacationDays,
      acceptedVacationDays,
      remainingVacationDays,
      vacationDay,
      remainingDaysPreviousYear,
    } = absenceOverview ?? {};
    const bars = [];

    if (remainingDaysPreviousYear < 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.usedDaysPreviousYear'),
        fraction: Math.abs(remainingDaysPreviousYear),
        color: theme.colors.base.blue[90],
        hideEndGap: takenVacationDays > 0,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.blue[90],
              }}
            >
              {Math.abs(remainingDaysPreviousYear)}{' '}
            </span>
            <span>
              {remainingVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (takenVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.takenVacationDays'),
        fraction: takenVacationDays,
        color: theme.colors.base.blue[80],
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.blue[90],
              }}
            >
              {takenVacationDays}{' '}
            </span>
            <span>
              {takenVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (acceptedVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.acceptedVacationDays'),
        fraction: acceptedVacationDays,
        color: theme.colors.base.green.default,
        hideEndGap: requestedVacationDays > 0,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.green.default,
              }}
            >
              {acceptedVacationDays}{' '}
            </span>
            <span>
              {acceptedVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (requestedVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.requestedVacationDays'),
        fraction: requestedVacationDays,
        color: theme.colors.base.yellow.default,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.yellow.default,
              }}
            >
              {requestedVacationDays}{' '}
            </span>
            <span>
              {requestedVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (remainingVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.remainingVacationDays'),
        fraction: remainingVacationDays,
        color: theme.colors.base.primary.default,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.primary.default,
              }}
            >
              {remainingVacationDays}{' '}
            </span>
            <span>
              {remainingVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    const totalVacationDays =
      remainingDaysPreviousYear < 0
        ? vacationDay
        : vacationDay + remainingDaysPreviousYear;
    const totalTakenVacationDays =
      takenVacationDays +
      requestedVacationDays +
      acceptedVacationDays +
      (remainingDaysPreviousYear < 0 ? Math.abs(remainingDaysPreviousYear) : 0);
    const dividers = [];

    if (remainingVacationDays < 0)
      dividers.push({
        atFraction: totalVacationDays - Math.abs(remainingVacationDays),
        color: theme.colors.base.red.default,
      });
    return {
      bars,
      dividers,
      totalVacationDays,
      totalTakenVacationDays,
      remainingVacationDays,
    };
  }, [absenceOverview, theme, t]);

  const { subTextColor, subText } = useMemo(() => {
    let subTextColor = theme.colors.base.primary.default;
    let subText = howManyDaysLeft(remainingVacationDays, t);

    if (hoveredOnBar) {
      const _fraction = Math.abs(hoveredOnBar.fraction);
      subTextColor = hoveredOnBar.color;
      subText = `${_fraction} ${
        _fraction > 1 || _fraction === 0
          ? t('absences:absenceOverview.popOver.days')
          : t('absences:absenceOverview.popOver.day')
      } ${hoveredOnBar?.title?.split(' ')?.[0]?.toLowerCase() || ''}`;
    } else {
      if (remainingVacationDays === 0) {
        subTextColor = theme.colors.application.typography.default;
        subText = `${remainingVacationDays} ${t(
          'absences:absenceOverview.popOver.days'
        )} ${t(
          'absences:absenceOverview.popOver.remainingVacationDays'
        ).toLowerCase()}`;
      } else if (remainingVacationDays < 0) {
        subTextColor = theme.colors.base.red.default;
        const _remainingVacationDays = Math.abs(remainingVacationDays);
        subText = `${_remainingVacationDays} ${
          _remainingVacationDays > 1
            ? t('absences:absenceOverview.popOver.days')
            : t('absences:absenceOverview.popOver.day')
        } ${t('absences:absenceOverview.popOver.over').toLowerCase()}`;
      } else if (remainingVacationDays > 0) {
        subText = `${remainingVacationDays} ${
          remainingVacationDays > 1
            ? t('absences:absenceOverview.popOver.days')
            : t('absences:absenceOverview.popOver.day')
        } ${t(
          'absences:absenceOverview.popOver.remainingVacationDays'
        ).toLowerCase()}`;
      }
    }

    return {
      subTextColor,
      subText,
    };
  }, [remainingVacationDays, theme, t, hoveredOnBar]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <PieChart
      onHover={(bar) => setHoveredOnBar(bar)}
      bars={bars}
      dividers={dividers}
      opening
      miniLegend
      miniLegendAlignment="right"
      miniLegendContainerStyle={{
        justifyContent: 'center',
        height: '100%',
        display: 'flex',
        width: 'fit-content',
      }}
    >
      <h1 className={classes.numbers}>
        <span>{totalTakenVacationDays}</span>
        <span>/</span>
        <span>{totalVacationDays}</span>
      </h1>
      <p
        className={classes.subtext}
        style={{
          color: subTextColor,
        }}
      >
        {subText}
      </p>
    </PieChart>
  );

  function howManyDaysLeft(remainingVacationDays: number, t) {
    if (remainingVacationDays < 0) {
      remainingVacationDays = Math.abs(remainingVacationDays);
      return `${remainingVacationDays} ${
        remainingVacationDays > 1
          ? t('absences:absenceOverview.popOver.days')
          : t('absences:absenceOverview.popOver.day')
      } ${t('absences:absenceOverview.popOver.over').toLowerCase()}`;
    }

    return `${remainingVacationDays} ${
      remainingVacationDays > 1 || remainingVacationDays === 0
        ? t('absences:absenceOverview.popOver.days')
        : t('absences:absenceOverview.popOver.day')
    } ${t(
      'absences:absenceOverview.popOver.remainingVacationDays'
    ).toLowerCase()}`;
  }
};
