import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Typography } from 'antd';
import { Button, useKeyboardListener } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveProject,
  getOpenCurrentPreviewModal,
} from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../../theme/utils';
import {
  apiDownloadDocumentPreview,
  apiDownloadDriveItemThumbnails,
  apiDownloadProjectMailAttachmentPreview,
  apiGetDriveItemVersions,
} from '../../api';
import { Center } from '../../../../components/Center';
import { urltoFile } from '../../../../util';
import {
  checkIfOneNote,
  colorForAttachmentIcon,
  colorForIcon,
  iconForAttachment,
  iconForFile,
} from '../../util';
import { apiDownloadAttachmentToCache } from '../../../mail/api';
import { putMessageAttachmentToCache } from '../../../mail/actions/actionControllers/messageActionController';
import {
  DocumentsSearchResultItem,
  DriveItem,
  Preview,
} from '../../../../models/Drive';
import { MessageAttachment } from '../../../../models/Message';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useGetLocalUrlByDriveId } from '../../hooks/useGetLocalUrlByDriveId';
import classNames from 'classnames';
import PreviewModalMetaDataForm from './PreviewModalMetaDataForm';
import { setCurrentPreviewModalVisibility } from '../../actions/previewModal';
import { ListRowProps } from 'react-virtualized';
import { VirtualListRef } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualList';
import PreviewModalNavBar from './PreviewModalNavBar';
import PreviewModalPreviewComponent from './PreviewModalPreviewComponent';
import PreviewModalMetaDataFormMultiSelect from './PreviewModalMetaDataFormMultiSelect';
import ArrowKeyList from '@prio365/prio365-react-library/lib/VirtualList/components/ArrowKeyList';
import { DriveItemVersion } from '../../../../models/Document';
import { PreviewListItem } from '../../reducers/previewModal';
import { GroupId, ProjectId } from '../../../../models/Types';
import i18n from '../../../../i18n';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  preview: {
    backgroundColor: 'rgba(0,0,0,0.25)',
    left: 0,
    position: 'fixed',
    top: 0,
    paddingTop: '50px',
    height: '100%',
    width: '100%',
    zIndex: 1008,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
  },
  previewContainer: {
    backgroundColor: theme.colors.application.background.light,
    padding: theme.spacing.small,
    zIndex: 1009,
    height: '95%',
  },
  previewItemsPadding: {
    backgroundColor: theme.colors.application.background.default,
    padding: theme.spacing.regular,
  },
  buttonRight: {
    right: 0,
    position: 'fixed',
    top: 0,
    marginTop: '40px',
    height: '100%',
    width: '5%',
    zIndex: 1006,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    '& > div > button > svg': {
      color: theme.colors.application.typography.default,
    },
  },
  buttonLeft: {
    left: 0,
    position: 'fixed',
    top: 0,
    marginTop: '40px',
    height: '100%',
    width: '5%',
    zIndex: 1007,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'center',
    '& > div > button > svg': {
      color: theme.colors.application.typography.default,
    },
  },
  documentList: {
    overflow: 'hidden',
  },
  documentItem: {
    '&:hover': {
      cursor: 'pointer',
    },
    padding: '8px 0px',
    fontSize: 14,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  documentItemLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing.small,
    overflow: 'hidden',
  },
  documentItemLabel: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  list: {
    height: 'calc(100% - 31px)',
    '& .prio-virtual-list-item-checkBoxContainer': {
      width: '32px',
    },
  },
  fullHeight: {
    height: '100%',
  },
  secondRowHeight: {
    height: 'calc(100% - 56px)',
  },
}));

export type PreviewListItemTypeName =
  | 'DriveItem'
  | 'MessageAttachment'
  | 'DocumentsSearchResultItem';

export const getPreviewListItemTypeName = (
  item: PreviewListItem
): PreviewListItemTypeName => {
  if ('data' in (item ?? [])) {
    return 'DocumentsSearchResultItem';
  } else if ('listItemFields' in (item ?? [])) {
    return 'DriveItem';
  }
  return 'MessageAttachment';
};

//Document is Image
const fetchAndSetDocumentImage = async (
  document: DriveItem | DocumentsSearchResultItem,
  groupId: GroupId,
  setPreview: (value: Preview) => void
) => {
  const driveItem =
    getPreviewListItemTypeName(document) === 'DriveItem'
      ? (document as DriveItem)
      : (document as DocumentsSearchResultItem).data;
  const result = await apiDownloadDriveItemThumbnails(driveItem.id, groupId);
  if (result.data) {
    setPreview({
      type: 'image',
      item: document,
      src: result.data[0].large['url'],
      optional: {
        driveItemWebUrl: driveItem.webUrl,
        lastModifiedDateTime: driveItem.lastModifiedDateTime,
      },
      mimeType: driveItem.file.mimeType,
    });
  } else {
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('documents:errorMessages.loadPreviewError'),
    });
  }
};

//Document is no Image
const fetchAndSetDocumentPreview = async (
  document: DriveItem | DocumentsSearchResultItem,
  projectId: ProjectId,
  groupId: GroupId,
  setPreview: (value: Preview) => void,
  getLocalUrlByDriveItem: (
    groupId: GroupId,
    projectId: ProjectId,
    driveItem: DriveItem,
    setIsFetching?: (value: boolean) => void,
    openPdf?: boolean
  ) => Promise<string>
) => {
  const driveItem =
    getPreviewListItemTypeName(document) === 'DriveItem'
      ? (document as DriveItem)
      : (document as DocumentsSearchResultItem).data;
  const result = await apiDownloadDocumentPreview(driveItem.id, groupId);
  const localUrl = await getLocalUrlByDriveItem(
    groupId,
    projectId,
    driveItem,
    null,
    true
  );
  if (result.data) {
    setPreview({
      type: 'iframe',
      src: result.data['url'],
      item: document,
      optional: {
        localDriveItemUrl: localUrl,
        driveItemWebUrl: driveItem.webUrl,
        lastModifiedDateTime: driveItem.lastModifiedDateTime,
      },
      mimeType: driveItem?.file?.mimeType,
    });
  } else {
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('documents:errorMessages.loadPreviewError'),
    });
  }
};

interface PreviewModalProps {}

export const PreviewModal: React.FC<PreviewModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listRef = useRef<VirtualListRef>(null);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeProjectId = useSelector(getActiveProject);
  const previewModal = useSelector(getOpenCurrentPreviewModal);
  const groupId = previewModal.groupId;
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState<number>(null);
  const [preview, setPreview] = useState<Preview>(null);
  const { getLocalUrlByDriveItem } = useGetLocalUrlByDriveId();
  const [selectedDriveItems, setSelectedDriveItems] = useState<
    PreviewListItem[]
  >(previewModal.selectedDriveItems ?? undefined);
  const [waitedOneSecond, setWaitedOneSecond] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

  const isInitialMulti = useMemo(() => {
    return previewModal.selectedDriveItems?.length > 0;
  }, [previewModal.selectedDriveItems]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClosePreview = (
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    dispatch(
      setCurrentPreviewModalVisibility(
        false,
        '',
        '',
        null,
        [],
        null,
        '',
        '',
        false,
        [],
        ''
      )
    );
    setPreview(null);
    setPageNumber(null);
    setIsLoading(true);
  };

  const fetchAttachmentBytes = useCallback(async () => {
    //Attachment is Image
    const currentAttachment = previewModal.itemList[
      pageNumber
    ] as MessageAttachment;

    const fetchAndSetAttachmentImage = async () => {
      setIsLoading(true);
      let blob: Blob = null;
      if (!currentAttachment.contentBytes) {
        blob = await apiDownloadAttachmentToCache(
          previewModal.projectId,
          previewModal.messageId,
          currentAttachment
        );
      } else {
        blob = await urltoFile(
          `data:${currentAttachment.contentType};base64,${currentAttachment.contentBytes}`,
          currentAttachment.name,
          currentAttachment.contentType
        );
      }

      if (blob === null) {
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.loadPreviewError'),
        });
      }

      setPreview({
        type: 'image',
        src:
          blob !== null
            ? URL.createObjectURL(blob)
            : currentAttachment.contentBytes,
        item: {
          ...currentAttachment,
          contentBytes:
            blob !== null
              ? URL.createObjectURL(blob)
              : currentAttachment.contentBytes,
        },
        mimeType: currentAttachment.contentType,
      });
      dispatch(
        putMessageAttachmentToCache(
          previewModal.projectId,
          previewModal.messageId,
          (previewModal.itemList as MessageAttachment[]).map((_attachment) => {
            if (_attachment.id === currentAttachment.id) {
              return {
                ..._attachment,
                contentBytes:
                  blob !== null
                    ? URL.createObjectURL(blob)
                    : _attachment.contentBytes,
              };
            }
            return _attachment;
          })
        )
      );
      setIsLoading(false);
    };

    //Attachment is no Image
    const fetchAndSetAttachmentPreview = async () => {
      setIsLoading(true);
      const result = await apiDownloadProjectMailAttachmentPreview(
        previewModal.projectId,
        previewModal.messageId,
        currentAttachment.id
      );

      if (result.data) {
        setPreview({
          type: 'iframe',
          src: result.data['url'],
          item: { ...currentAttachment, contentBytes: result.data['url'] },
          mimeType: currentAttachment.contentType,
        });
      } else {
        setPreview({
          type: 'iframe',
          src: null,
          item: { ...currentAttachment, contentBytes: '' },
          mimeType: currentAttachment.contentType,
        });
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.loadPreviewError'),
        });
      }

      setIsLoading(false);
    };

    if (pageNumber !== null) {
      if (
        currentAttachment.contentType === 'image/png' ||
        currentAttachment.contentType === 'image/jpeg'
      ) {
        fetchAndSetAttachmentImage();
      } else {
        fetchAndSetAttachmentPreview();
      }
    }
  }, [
    previewModal.projectId,
    previewModal.messageId,
    previewModal.itemList,
    pageNumber,
    dispatch,
    t,
  ]);

  const fetchDocumentBytes = useCallback(async () => {
    const item = previewModal.itemList[pageNumber] as
      | DriveItem
      | DocumentsSearchResultItem;
    if (item) {
      const isDriveItem = getPreviewListItemTypeName(item) === 'DriveItem';
      const document: DriveItem = isDriveItem
        ? (item as DriveItem)
        : (item as DocumentsSearchResultItem).data;

      const groupId = isDriveItem
        ? previewModal.groupId
        : (item as DocumentsSearchResultItem).calculated.groupId;
      const projectId = isDriveItem
        ? activeProjectId
        : (item as DocumentsSearchResultItem).calculated.projectIds[0];

      setIsLoading(true);
      if (pageNumber !== null) {
        if (
          document?.file?.mimeType === 'image/png' ||
          document?.file?.mimeType === 'image/jpeg'
        ) {
          fetchAndSetDocumentImage(item, groupId, setPreview);
        } else {
          fetchAndSetDocumentPreview(
            item,
            projectId,
            groupId,
            setPreview,
            getLocalUrlByDriveItem
          );
        }
      }
      setIsLoading(false);
    }
  }, [
    activeProjectId,
    previewModal.itemList,
    previewModal.groupId,
    pageNumber,
    getLocalUrlByDriveItem,
  ]);

  const buttonLeftClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (pageNumber !== 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const buttonRightClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (pageNumber !== previewModal.itemList?.length - 1) {
      setPageNumber(pageNumber + 1);
    }
  };

  const onActiveChange = useCallback((index: number, previousIndex: number) => {
    if (index !== previousIndex) {
      setPageNumber(index);
    }
  }, []);

  const rowRendererDriveItemList = useCallback(
    ({ index }: ListRowProps) => {
      const item = previewModal.itemList[index];

      const getIcon = (item: PreviewListItem) => {
        switch (getPreviewListItemTypeName(item)) {
          case 'DocumentsSearchResultItem': {
            return iconForFile((item as DocumentsSearchResultItem).data);
          }
          case 'DriveItem': {
            return iconForFile(item as DriveItem);
          }
          case 'MessageAttachment': {
            return iconForAttachment(item as MessageAttachment);
          }
        }
      };

      const getIconColor = (item: PreviewListItem) => {
        switch (getPreviewListItemTypeName(item)) {
          case 'DocumentsSearchResultItem': {
            return colorForIcon(
              (item as DocumentsSearchResultItem).data.file.mimeType
            );
          }
          case 'DriveItem': {
            if (checkIfOneNote(item as DriveItem))
              return colorForIcon('application/oneNote');
            return colorForIcon((item as DriveItem).file.mimeType);
          }
          case 'MessageAttachment': {
            return colorForAttachmentIcon(
              (item as MessageAttachment).contentType
            );
          }
        }
      };

      const getName = (item: PreviewListItem) => {
        switch (getPreviewListItemTypeName(item)) {
          case 'DocumentsSearchResultItem': {
            return (item as DocumentsSearchResultItem).data.name;
          }
          case 'DriveItem': {
            return (item as DriveItem).name;
          }
          case 'MessageAttachment': {
            return (item as MessageAttachment).name;
          }
        }
      };

      return (
        <div
          onClick={() => setPageNumber(index)}
          className={classes.documentItem}
          style={{
            padding: !(previewModal.driveItem && previewModal.groupId)
              ? 8
              : undefined,
          }}
        >
          <div
            style={{ width: '20px', display: 'flex', justifyContent: 'center' }}
          >
            <FontAwesomeIcon
              icon={['fas', getIcon(item)]}
              color={getIconColor(item)}
            ></FontAwesomeIcon>
          </div>
          <div className={classes.documentItemLabelWrapper}>
            <div className={classes.documentItemLabel} title={getName(item)}>
              {getName(item)}
            </div>
          </div>
        </div>
      );
    },
    [
      previewModal.itemList,
      previewModal.driveItem,
      previewModal.groupId,
      classes,
    ]
  );

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setSelectedDriveItems(previewModal.selectedDriveItems ?? []);
  }, [previewModal.selectedDriveItems]);

  useEffect(() => {
    setPageNumber(previewModal.pageNumber);
  }, [previewModal.pageNumber]);

  useEffect(() => {
    if (previewModal.visibility) {
      if (
        previewModal.context === 'documents' ||
        previewModal.context === 'globalDocumentsSearch'
      ) {
        fetchDocumentBytes();
      } else if (previewModal.context === 'messages') {
        fetchAttachmentBytes();
      }
    }
  }, [
    previewModal.visibility,
    previewModal.context,
    pageNumber,
    fetchAttachmentBytes,
    fetchDocumentBytes,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setWaitedOneSecond(previewModal.visibility);
    }, 400);
  }, [previewModal.visibility]);

  useEffect(() => {
    if (pageNumber || previewModal.pageNumber) {
      listRef?.current?.setActiveIndex(pageNumber ?? previewModal.pageNumber);
      listRef?.current?.forceUpdate();
    }
  }, [previewModal.pageNumber, pageNumber]);

  useEffect(() => {
    if (!isInitialRender) {
      if (selectedDriveItems?.length === 0 && isInitialMulti) {
        listRef?.current?.setActiveIndex(0);
        listRef?.current?.forceUpdate();
        setPageNumber(0);
      }
    } else {
      setIsInitialRender(false);
    }
  }, [selectedDriveItems, isInitialRender, isInitialMulti]);

  const [driveItemVersions, setDriveItemVersions] = useState<
    DriveItemVersion[]
  >([]);

  const [currentDriveItemVersion, setCurrentDriveItemVersion] =
    useState<DriveItemVersion>();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadDriveItemVersions = async () => {
      try {
        const _groupId =
          getPreviewListItemTypeName(previewModal.itemList[pageNumber]) ===
          'DocumentsSearchResultItem'
            ? (previewModal.itemList[pageNumber] as DocumentsSearchResultItem)
                ?.calculated?.groupId
            : groupId;
        const { data } = await apiGetDriveItemVersions(
          _groupId,
          (previewModal.itemList[pageNumber] as DriveItem).id,
          signal
        );

        if (data && Array.isArray(data)) {
          setCurrentDriveItemVersion(data[0]);
          const versionsWithoutLastest = data.slice(1, data.length);
          setDriveItemVersions(versionsWithoutLastest);
        }
      } catch {}
    };
    loadDriveItemVersions();
    return () => {
      controller.abort();
    };
  }, [groupId, previewModal, pageNumber, t]);

  useKeyboardListener(
    previewModal.visibility
      ? {
          ArrowLeft: () => {
            if (pageNumber !== 0) {
              setPageNumber(pageNumber - 1);
            }
          },
          ArrowRight: () => {
            if (pageNumber !== previewModal.itemList?.length - 1) {
              setPageNumber(pageNumber + 1);
            }
          },
          Escape: () => {
            handleClosePreview();
          },
        }
      : {}
  );
  //#endregion

  return (
    <>
      {previewModal.visibility && waitedOneSecond && (
        <>
          <div
            className={classNames('prio-deprecated-design', classes.preview)}
            onClick={handleClosePreview}
            style={{ zIndex: 1060 }}
          >
            <Center>
              <div
                style={{ width: '90%' }}
                className={classes.previewContainer}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Flex.Column
                  childrenGap={theme.spacing.small}
                  className={classes.fullHeight}
                >
                  <PreviewModalNavBar
                    groupId={groupId}
                    preview={preview}
                    selectedDriveItems={selectedDriveItems}
                    currentDriveItemVersion={currentDriveItemVersion}
                    onClose={handleClosePreview}
                    itemList={previewModal.itemList}
                  />

                  <Flex.Row
                    childrenGap={theme.spacing.small}
                    style={{ zIndex: 1012 }}
                    className={classes.secondRowHeight}
                    flex={1}
                  >
                    {(previewModal.itemList.length > 1 ||
                      selectedDriveItems.length > 0) && (
                      <Flex.Column
                        className={classNames(
                          classes.previewItemsPadding,
                          classes.documentList
                        )}
                        flex={1}
                        childrenGap={theme.spacing.small}
                      >
                        <Typography.Title level={4}>
                          {t('documents:documentDetails.labels.moreDocuments')}
                        </Typography.Title>
                        <div className={classes.list}>
                          <ArrowKeyList<PreviewListItem>
                            id="previewModalList"
                            ref={listRef}
                            items={previewModal.itemList as DriveItem[]}
                            rowHeight={36}
                            rowRenderer={rowRendererDriveItemList}
                            registerChild={() => {
                              if (
                                pageNumber > -1 ||
                                previewModal.pageNumber > -1
                              ) {
                                listRef?.current?.setActiveIndex(
                                  pageNumber ?? previewModal.pageNumber
                                );
                                listRef?.current?.forceUpdate();
                              }
                            }}
                            rowsAreSelectable={
                              !!(
                                previewModal.driveItemId &&
                                previewModal.groupId &&
                                previewModal.context !== 'globalDocumentsSearch'
                              )
                            }
                            onSelectionChange={setSelectedDriveItems}
                            selectedItems={selectedDriveItems}
                            onActiveChange={onActiveChange}
                            enableContainer={false}
                          />
                        </div>
                      </Flex.Column>
                    )}
                    <Flex.Column
                      className={classes.previewItemsPadding}
                      flex={previewModal.context !== 'messages' ? 3 : 4}
                    >
                      <PreviewModalPreviewComponent
                        preview={preview}
                        selectedDriveItemsLength={selectedDriveItems.length}
                        isLoading={isLoading}
                      />
                    </Flex.Column>
                    {previewModal.context !== 'messages' &&
                      selectedDriveItems.length === 0 && (
                        <Flex.Column
                          className={classes.previewItemsPadding}
                          flex={1}
                        >
                          {preview?.item && (
                            <PreviewModalMetaDataForm
                              groupId={
                                getPreviewListItemTypeName(
                                  previewModal.itemList[pageNumber]
                                ) === 'DocumentsSearchResultItem'
                                  ? (
                                      previewModal.itemList[
                                        pageNumber
                                      ] as DocumentsSearchResultItem
                                    )?.calculated?.groupId
                                  : groupId
                              }
                              driveItem={
                                getPreviewListItemTypeName(
                                  previewModal.itemList[pageNumber]
                                ) === 'DocumentsSearchResultItem'
                                  ? (
                                      previewModal.itemList[
                                        pageNumber
                                      ] as DocumentsSearchResultItem
                                    )?.data
                                  : (previewModal.itemList[
                                      pageNumber
                                    ] as DriveItem)
                              }
                              activeProjectId={
                                getPreviewListItemTypeName(
                                  previewModal.itemList[pageNumber]
                                ) === 'DocumentsSearchResultItem'
                                  ? (
                                      previewModal.itemList[
                                        pageNumber
                                      ] as DocumentsSearchResultItem
                                    )?.calculated?.projectIds[0]
                                  : activeProjectId
                              }
                              driveItemVersions={driveItemVersions}
                            />
                          )}
                        </Flex.Column>
                      )}
                    {previewModal.context === 'documents' &&
                      selectedDriveItems.length > 0 && (
                        <Flex.Column
                          className={classes.previewItemsPadding}
                          flex={1}
                        >
                          <PreviewModalMetaDataFormMultiSelect
                            groupId={groupId}
                            selectedDriveItems={
                              selectedDriveItems as DriveItem[]
                            }
                            projectId={activeProjectId}
                          />
                        </Flex.Column>
                      )}
                  </Flex.Row>
                </Flex.Column>
              </div>
            </Center>

            {selectedDriveItems.length === 0 && (
              <div className={classes.buttonLeft}>
                <Center>
                  <Button
                    type="link"
                    shape="circle"
                    iconProp={['fas', 'chevron-left']}
                    onClick={buttonLeftClick}
                    style={{
                      backgroundColor: theme.colors.base.white.default,
                      alignItems: 'center',
                      fontSize: 15,
                      zIndex: 9999,
                    }}
                    disabled={pageNumber === 0}
                  />
                </Center>
              </div>
            )}
            {selectedDriveItems.length === 0 && (
              <div className={classes.buttonRight}>
                <Center>
                  <Button
                    type="link"
                    shape="circle"
                    iconProp={['fas', 'chevron-right']}
                    onClick={buttonRightClick}
                    style={{
                      backgroundColor: theme.colors.base.white.default,
                      alignItems: 'center',
                      fontSize: 15,
                      zIndex: 9999,
                    }}
                    disabled={pageNumber === previewModal.itemList.length - 1}
                  />
                </Center>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PreviewModal;
