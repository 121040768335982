import { UserRegistrationState } from '../../../models/User';
import { Reducer } from 'redux';
import {
  FETCH_USER_REGISTRATION_STATE_COMMIT,
  SET_REGISTRATION_COMPLETE,
} from '../actions/registrationState';
import { CLEAR_PRIO_CACHE } from '../../../actions';

interface RegistrationStateAction {
  type: 'PRIO_FETCH_USER_REGISTRATION_STATE_REQUEST';
  payload: UserRegistrationState;
}

const registrationState: Reducer<
  UserRegistrationState,
  RegistrationStateAction
> = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_REGISTRATION_STATE_COMMIT: {
      return action.payload;
    }
    case SET_REGISTRATION_COMPLETE: {
      return {
        ...state,
        registrationCompleted: true,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

export default registrationState;

export const getIsPrioUser: (state: UserRegistrationState) => boolean = (
  state
) => state?.prioUser;
export const getHasRegistrationCompleted: (
  state: UserRegistrationState
) => boolean = (state) => state?.registrationCompleted ?? false;
