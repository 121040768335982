import React, { useState, Reducer, useReducer, useEffect } from 'react';
import { Table, Typography, Input, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';

import { HourlyRateId, HourlyRateSuggestionId } from '../../../models/Types';
import { HourlyRateSuggestion } from '../../../models/HourlyRate';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { tableTranslations } from '../../../util/table';
import CurrencyInput from '../../../components/CurrencyInput';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-row .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .hourly-rate-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  numberInput: {
    flex: 1,
  },
  currencyLabel: {
    padding: `0 ${theme.old.spacing.unit(1)}px`,
    color: theme.old.typography.colors.muted,
  },
}));

interface HourlyRateSuggestionsTableProps {
  className?: string;
  hourlyRateSuggestions: HourlyRateSuggestion[];
  onIsDirtyChanged?: (
    isDirty: boolean,
    hourlyRates?: HourlyRateSuggestion[]
  ) => void;
  onNewSuggestion?: (hourlyRateSuggestion: HourlyRateSuggestion) => void;
  onDelete?: (hourlyRateSuggestionId: HourlyRateSuggestionId) => void;
}

interface TableEntry extends HourlyRateSuggestion {
  key: string;
  addHourlyRateRow: boolean;
  isTemporary: boolean;
}

interface NewHourlyRateSuggestion extends HourlyRateSuggestion {
  isValid: boolean;
}

type UpdateHourlyRateKey = 'name' | 'internalValue' | 'externalValue';

type UpdateHourlyRateValue = string | number;

interface UpdateNewHourlyRateAction {
  key: UpdateHourlyRateKey;
  value: UpdateHourlyRateValue;
}

export const HourlyRateSuggestionsTable: React.FC<
  HourlyRateSuggestionsTableProps
> = (props) => {
  const classes = useStyles();
  const {
    className,
    hourlyRateSuggestions: originalHourlyRateSuggestions,
    onIsDirtyChanged,
    onNewSuggestion,
    onDelete,
  } = props;
  const [hourlyRates, setHourlyRates] = useState<HourlyRateSuggestion[]>(
    originalHourlyRateSuggestions
  );
  const { t } = useTranslation();
  const [newHourlyRateRowActive, setNewHourlyRateRowActive] = useState(false);
  const [modalDeleteHourlyRateVisible, setModalDeleteHourlyRateVisible] =
    useState<boolean>(false);
  const [hourlyRateToDelete, setHourlyRateToDelete] = useState<string>(null);

  useEffect(() => {
    setHourlyRates(originalHourlyRateSuggestions);
    onIsDirtyChanged(false, originalHourlyRateSuggestions);
  }, [originalHourlyRateSuggestions, onIsDirtyChanged]);

  const validate: (state: NewHourlyRateSuggestion) => boolean = (
    state: NewHourlyRateSuggestion
  ) =>
    state.name.trim().length > 0 &&
    state.internalValue > 0 &&
    state.externalValue > 0;

  const emptyNewHourlyRate: NewHourlyRateSuggestion = {
    hourlyRateSuggestionId: 'temporary',
    name: '',
    internalValue: 0,
    externalValue: 0,
    isValid: false,
    rowVersion: null,
  };

  const [newHourlyRate, updateNewHourlyRate] = useReducer<
    Reducer<NewHourlyRateSuggestion, UpdateNewHourlyRateAction | 'clear'>
  >(
    (
      state: NewHourlyRateSuggestion,
      action: UpdateNewHourlyRateAction | 'clear'
    ) => {
      if (action === 'clear') {
        return emptyNewHourlyRate;
      }
      const updatedState = { ...state, [action.key]: action.value };
      return {
        ...updatedState,
        isValid: validate(updatedState),
      };
    },
    emptyNewHourlyRate
  );

  const updateHourlyRate = (
    hourlyRateSuggestionId: HourlyRateId,
    key: UpdateHourlyRateKey,
    value: UpdateHourlyRateValue
  ) => {
    const updatedHourlyRates = hourlyRates.map((entry) =>
      entry.hourlyRateSuggestionId === hourlyRateSuggestionId
        ? { ...entry, [key]: value }
        : entry
    );
    setHourlyRates(updatedHourlyRates);

    if (onIsDirtyChanged) {
      const isDirty = !equals(
        updatedHourlyRates,
        originalHourlyRateSuggestions
      );
      onIsDirtyChanged(isDirty, updatedHourlyRates);
    }
  };

  const saveNewHourlyRate = () => {
    if (onNewSuggestion) {
      onNewSuggestion(newHourlyRate);
      cancelNewHourlyRateRow();
      updateNewHourlyRate('clear');
    }
  };

  const deleteHourlyRate = (hourlyRateSuggestionId: HourlyRateSuggestionId) => {
    if (onDelete) {
      onDelete(hourlyRateSuggestionId);
      setModalDeleteHourlyRateVisible(false);
      setHourlyRateToDelete(null);
    }
  };

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('projects:hourlyRatesTable.columnTitles.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record: TableEntry, index: number) =>
        record.addHourlyRateRow ? (
          <Input
            value={record.addHourlyRateRow ? newHourlyRate.name : value}
            placeholder={t(
              'projects:hourlyRatesTable.newEntry.placeHolderName'
            )}
            onChange={(e) =>
              updateNewHourlyRate({ key: 'name', value: e.currentTarget.value })
            }
          />
        ) : (
          <Typography.Text>{value}</Typography.Text>
        ),
      width: 300,
    },
    {
      title: t('projects:hourlyRatesTable.columnTitles.internalValue'),
      dataIndex: 'internalValue',
      sorter: (a, b) => a.internalValue - b.internalValue,
      render: (value, record) => (
        <Flex.Row>
          <CurrencyInput
            isoCode="EUR"
            value={
              record.addHourlyRateRow ? newHourlyRate.internalValue : value
            }
            onChange={(value) =>
              record.addHourlyRateRow
                ? updateNewHourlyRate({ key: 'internalValue', value })
                : updateHourlyRate(
                    record.hourlyRateSuggestionId,
                    'internalValue',
                    value
                  )
            }
            className={classes.numberInput}
            disabled={record.isTemporary}
          />
        </Flex.Row>
      ),
      width: 250,
    },
    {
      title: t('projects:hourlyRatesTable.columnTitles.externalValue'),
      dataIndex: 'externalValue',
      sorter: (a, b) => a.externalValue - b.externalValue,
      render: (value, record) => (
        <Flex.Row>
          <CurrencyInput
            isoCode="EUR"
            value={
              record.addHourlyRateRow ? newHourlyRate.externalValue : value
            }
            onChange={(value) =>
              record.addHourlyRateRow
                ? updateNewHourlyRate({ key: 'externalValue', value })
                : updateHourlyRate(
                    record.hourlyRateSuggestionId,
                    'externalValue',
                    value
                  )
            }
            className={classes.numberInput}
            disabled={record.isTemporary}
          />
        </Flex.Row>
      ),
      width: 250,
    },

    {
      render: (_, record) =>
        record.addHourlyRateRow ? (
          <Button
            disabled={!newHourlyRate.isValid}
            onClick={saveNewHourlyRate}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            onClick={() => handleDelete(record.hourlyRateSuggestionId)}
            className="hourly-rate-suggestion-table-delete-button"
            iconProp={['fal', 'trash']}
            type="link"
          ></Button>
        ),
      width: 80,
    },
  ];

  const handleDelete = (value: string) => {
    setModalDeleteHourlyRateVisible(true);
    setHourlyRateToDelete(value);
  };

  const data: TableEntry[] = hourlyRates.map(
    (hourlyRate: HourlyRateSuggestion, index: number) => ({
      key: hourlyRate.hourlyRateSuggestionId,
      ...hourlyRate,
      isTemporary: hourlyRate.isTemporary ?? false,
      addHourlyRateRow: false,
    })
  );

  const enableNewHourlyRateRow = () => setNewHourlyRateRowActive(true);
  const cancelNewHourlyRateRow = () => setNewHourlyRateRowActive(false);

  const footer = () => (
    <div>
      <Button
        type="link"
        onClick={enableNewHourlyRateRow}
        disabled={newHourlyRateRowActive}
        iconProp={['fal', 'plus']}
      >
        {t('projects:hourlyRatesTable.actions.add')}
      </Button>
    </div>
  );

  return (
    <div>
      <Table
        className={classNames(classes.root, className)}
        columns={columns}
        pagination={false}
        dataSource={
          newHourlyRateRowActive
            ? [
                ...data,
                {
                  key: 'addHourlyRate',
                  name: '',
                  internalValue: 0,
                  externalValue: 0,
                  addHourlyRateRow: true,
                },
              ]
            : data
        }
        footer={footer}
        locale={tableTranslations(t)}
      />
      <Modal
        visible={modalDeleteHourlyRateVisible}
        onOk={() => deleteHourlyRate(hourlyRateToDelete)}
        onCancel={() => {
          setModalDeleteHourlyRateVisible(false);
          setHourlyRateToDelete(null);
        }}
        title={t('settings:modalDeleteHourlyRate.title')}
        okText={t('settings:modalDeleteHourlyRate.actionButton')}
        cancelText={t('settings:modalDeleteHourlyRate.cancelButton')}
      >
        {t('settings:modalDeleteHourlyRate.content')}
      </Modal>
    </div>
  );
};

export default HourlyRateSuggestionsTable;
