import React, { useEffect, useState } from 'react';
import { Drawer, Divider, Tag, Tabs } from 'antd';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';
import { apiSupportGetReleaseNotes } from '../api';
import { PrioFeature } from '../../../models/Prio365Features';
import i18n from '../../../i18n';
import { openZendeskDrawer } from '../actions/drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

interface Props {
  visible: boolean;
  onClose: () => void;
  selectedVersion: string;
}

const ReleaseNotesDrawer: React.FC<Props> = ({
  visible,
  onClose,
  selectedVersion,
}) => {
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const closeDrawer = () => dispatch(openZendeskDrawer(false));

  const startUserFlow = (id: string) => {
    closeDrawer();

    setTimeout(() => window.open(`?userflow=${id}`, '_self'), 500);
  };

  const [groupedFeatures, setGroupedFeatures] = useState<{
    [module: string]: PrioFeature[];
  }>({});
  useEffect(() => {
    const fetchReleases = async (selectedVersion) => {
      const { data } = await apiSupportGetReleaseNotes(selectedVersion);
      if (data) {
        const features = data.features;
        if (features) {
          const groupedFeatures = features?.reduce((acc, feature) => {
            if (!acc[feature.module]) {
              acc[feature.module] = [];
            }
            acc[feature.module].push(feature);
            return acc;
          }, {});
          setGroupedFeatures(groupedFeatures);
        }
      }
    };
    fetchReleases(selectedVersion);
  }, [selectedVersion]);

  return (
    <Drawer
      title={`Version ${selectedVersion}`}
      placement="right"
      onClose={onClose}
      width={theme.old.components.drawerWidth}
      visible={visible}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Features" key="1">
          <div>
            {Object.keys(groupedFeatures)?.map((module, index) => (
              <div key={module}>
                {module ? (
                  <h2>
                    {t('zendesk:module')}: {module}
                  </h2>
                ) : (
                  <h2> {t('zendesk:general')}</h2>
                )}
                {groupedFeatures[module].map((feature, featureIndex) => (
                  <div key={featureIndex}>
                    {feature.title && <h3>{feature.title}</h3>}

                    {feature.screencasturl && (
                      <Tag
                        color="blue"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          window.open(feature.screencasturl, '_blank');
                        }}
                      >
                        <FontAwesomeIcon icon={['fal', 'video']} />
                      </Tag>
                    )}
                    {feature.userflowurl && (
                      <Tag
                        color="yellow"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          startUserFlow(feature.userflowurl);
                        }}
                      >
                        <FontAwesomeIcon icon={['fal', 'play']} />
                      </Tag>
                    )}
                    <Tag color="purple">Feature</Tag>
                    <Tag color="green">{feature.release}</Tag>
                    {feature.submodule && (
                      <Tag color="cyan">{feature.submodule}</Tag>
                    )}
                    {i18n.language.includes('de') ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feature.descriptiongerman,
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feature.descriptionenglish,
                        }}
                      />
                    )}
                    <Divider />
                  </div>
                ))}
              </div>
            ))}
          </div>{' '}
        </TabPane>
        {/*
        <TabPane tab="Bugs" key="2">
          <div>
            BUGS
         
            {Object.keys(groupedBugs).map((module, index) => (
              <div key={module}>
                {module ? (
                  <h2>
                    {t('zendesk:module')}: {module}
                  </h2>
                ) : (
                  <h2> {t('zendesk:general')}</h2>
                )}
                {groupedBugs[module].map((bug, bugIndex) => (
                  <div key={bugIndex}>
                    {bug.PublicTitle && <h3>{bug.PublicTitle}</h3>}
                    <div dangerouslySetInnerHTML={{ __html: bug.Public }} />
                    <Tag color="red">{bug.Type}</Tag>
                    <Tag color="green">{bug.Version}</Tag>
                    {bug.SubModule && <Tag color="cyan">{bug.SubModule}</Tag>}

                    <Divider />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </TabPane>
          */}
      </Tabs>
    </Drawer>
  );
};

export default ReleaseNotesDrawer;
