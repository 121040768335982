import { notification } from 'antd';
import i18n from '../../../i18n';
import { WebSocketMessage } from '../../../models/WebSocket';
async function handleDriveFavoriteMessage(message: WebSocketMessage) {
  if (!message.type) {
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('documents:driveFavorites.ws.error'),
    });
    console.error('Error in websocket flow - handleDriveFavoriteMessage');
  }
}
export default handleDriveFavoriteMessage;
