import { combineReducers, Reducer } from 'redux';
import { TimeRecord } from '../../../models/TimeRecord';
import { distinct, groupBy } from '../../../util';
import {
  FETCH_MY_TIME_RECORDS_REQUEST,
  FETCH_MY_TIME_RECORDS_ROLLBACK,
  FETCH_MY_TIME_RECORDS_COMMIT,
  UPDATE_TIME_RECORD_COMMIT,
  DELETE_TIME_RECORD,
  FETCH_PROJECT_TIME_RECORDS_COMMIT,
  FETCH_PROJECT_TIME_RECORDS_REQUEST,
  FETCH_PROJECT_TIME_RECORDS_ROLLBACK,
  CREATE_TIME_RECORDS_COMMIT,
} from '../actions';

import { ProjectId, ContactId, TimeRecordId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';

export const initialState: TimeRecordsState = {
  byId: {},
  ids: [],
  idsByProject: {},
  idsByContact: {},
  redirects: {},
  meta: { isFetching: false, hasError: false },
};

export interface TimeRecordsState {
  byId: TimeRecordsByIdState;
  ids: string[];
  idsByProject: TimeRecordsIdsByProjectState;
  idsByContact: TimeRecordsIdsByContactState;
  redirects: TimeRecordsRedirectState;
  meta: TimeRecordsMeta;
}

export interface TimeRecordsByIdState {
  [timeRecordId: string]: TimeRecord;
}

interface TimeRecordsIdsByProjectState {
  [key: string]: Array<string>;
}

interface TimeRecordsIdsByContactState {
  [key: string]: Array<string>;
}

interface TimeRecordsRedirectState {
  [key: string]: string;
}

const byId: Reducer<TimeRecordsByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TIME_RECORDS_COMMIT:
    case FETCH_PROJECT_TIME_RECORDS_COMMIT:
    case FETCH_MY_TIME_RECORDS_COMMIT: {
      const { payload } = action;
      return {
        ...(payload as Array<TimeRecord>).reduce(
          function (map, item) {
            map[item.timeRecordId] = item;
            return map;
          },
          { ...state }
        ),
      };
    }

    case UPDATE_TIME_RECORD_COMMIT: {
      const {
        payload,
        meta: { timeRecordId },
      } = action;
      return {
        ...state,
        [timeRecordId]: payload,
      };
    }

    case DELETE_TIME_RECORD: {
      const {
        meta: { timeRecordId },
      } = action;
      const { [timeRecordId]: _, ...newState } = state;
      return newState;
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

const ids: Reducer<Array<string>, any> = (state = [], action) => {
  switch (action.type) {
    case CREATE_TIME_RECORDS_COMMIT:
    case FETCH_PROJECT_TIME_RECORDS_COMMIT:
    case FETCH_MY_TIME_RECORDS_COMMIT: {
      const { payload } = action;
      return distinct([
        ...(state ?? []),
        ...(payload as Array<TimeRecord>).map((item) => item.timeRecordId),
      ]);
    }

    case DELETE_TIME_RECORD: {
      const {
        meta: { timeRecordId },
      } = action;
      return state.filter((id) => id !== timeRecordId);
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return [];
    }

    default:
      return state;
  }
};

const idsByProject: Reducer<TimeRecordsIdsByProjectState, any> = (
  state = {},
  action
) => {
  switch (action.type) {
    case CREATE_TIME_RECORDS_COMMIT:
    case FETCH_PROJECT_TIME_RECORDS_COMMIT:
    case FETCH_MY_TIME_RECORDS_COMMIT: {
      const { payload } = action;
      const grouped = groupBy(payload as Array<TimeRecord>, 'projectId');
      const newState = { ...state };
      Object.entries(grouped).forEach(([projectId, timeRecords]) => {
        newState[projectId] = distinct([
          ...(state[projectId] ?? []),
          ...timeRecords.map((item) => item.timeRecordId),
        ]);
      });
      return newState;
    }

    case UPDATE_TIME_RECORD_COMMIT: {
      const {
        meta: { timeRecordId, timeRecord, originalTimeRecord },
      } = action;
      if (state[timeRecord?.projectId]?.includes(timeRecordId)) {
        return state;
      }
      return {
        ...state,
        [originalTimeRecord?.projectId]: [
          ...(state[originalTimeRecord?.projectId] ?? [])?.filter(
            (id) => id !== timeRecordId
          ),
        ],
        [timeRecord?.projectId]: [
          ...(state[timeRecord?.projectId] ?? []),
          timeRecordId,
        ],
      };
    }

    case DELETE_TIME_RECORD: {
      const {
        meta: { timeRecordId, projectId },
      } = action;
      if (!state[projectId]?.includes(timeRecordId)) return state;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId]?.filter((id) => id !== timeRecordId) ?? []),
        ],
      };
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

const idsByContact: Reducer<TimeRecordsIdsByContactState, any> = (
  state = {},
  action
) => {
  switch (action.type) {
    case CREATE_TIME_RECORDS_COMMIT:
    case FETCH_PROJECT_TIME_RECORDS_COMMIT:
    case FETCH_MY_TIME_RECORDS_COMMIT: {
      const { payload } = action;
      const grouped = groupBy(payload as Array<TimeRecord>, 'contactId');
      const newState = { ...state };
      Object.entries(grouped).forEach(([contactId, timeRecords]) => {
        newState[contactId] = distinct([
          ...(state[contactId] ?? []),
          ...timeRecords.map((item) => item.timeRecordId),
        ]);
      });
      return newState;
    }

    case UPDATE_TIME_RECORD_COMMIT: {
      const {
        meta: { timeRecordId, timeRecord, originalTimeRecord },
      } = action;
      if (
        state[(timeRecord as TimeRecord)?.contactId]?.includes(timeRecordId)
      ) {
        return state;
      }
      return {
        ...state,
        [(originalTimeRecord as TimeRecord)?.contactId]: [
          ...(
            state[(originalTimeRecord as TimeRecord)?.contactId] ?? []
          )?.filter((id) => id !== timeRecordId),
        ],
        [(timeRecord as TimeRecord)?.contactId]: [
          ...(state[(timeRecord as TimeRecord)?.contactId] ?? []),
          timeRecordId,
        ],
      };
    }

    case DELETE_TIME_RECORD: {
      const {
        meta: { timeRecordId, contactId },
      } = action;
      if (!state[contactId]?.includes(timeRecordId)) return state;
      return {
        ...state,
        [contactId]: [
          ...(state[contactId]?.filter((id) => id !== timeRecordId) ?? []),
        ],
      };
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const redirects: Reducer<TimeRecordsRedirectState, any> = (
  state = {},
  action
) => {
  switch (action.type) {
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface TimeRecordsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<TimeRecordsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_TIME_RECORDS_REQUEST:
    case FETCH_MY_TIME_RECORDS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROJECT_TIME_RECORDS_COMMIT:
    case FETCH_MY_TIME_RECORDS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_PROJECT_TIME_RECORDS_ROLLBACK:
    case FETCH_MY_TIME_RECORDS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'timeRecords:errorMessages.fetchError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<TimeRecordsState>({
  byId,
  ids,
  idsByProject,
  idsByContact,
  redirects,
  meta,
});

export const getTimeRecordIds: (
  state: TimeRecordsState
) => Array<TimeRecordId> = ({ ids }) => ids;

export const getTimeRecordsByProject: (
  state: TimeRecordsState,
  projectId: ProjectId
) => Array<TimeRecord> = (state: TimeRecordsState, projectId: ProjectId) =>
  (state.idsByProject[projectId] ?? [])
    .map((id) => state.byId[id])
    .sort((a: TimeRecord, b: TimeRecord) => a.day.localeCompare(b.day));

export const getTimeRecordsByContact: (
  state: TimeRecordsState,
  contactId: ContactId,
  disableSort?: boolean
) => Array<TimeRecord> = (
  state: TimeRecordsState,
  contactId: ContactId,
  disableSort?: boolean
) => {
  const timeRecords = (state.idsByContact[contactId] ?? []).map(
    (id) => state.byId[id]
  );
  if (disableSort) {
    return timeRecords;
  }
  return timeRecords.sort((a: TimeRecord, b: TimeRecord) =>
    a.day.localeCompare(b.day)
  );
};
export const getAllTimeRecords: (
  state: TimeRecordsState
) => Array<TimeRecord> = (state: TimeRecordsState) =>
  (state.ids ?? [])
    .map((id) => state.byId[id])
    .sort((a: TimeRecord, b: TimeRecord) => a.day.localeCompare(b.day));

export const getTimeRecord: (
  state: TimeRecordsState,
  timeRecordId: string
) => TimeRecord = (state, timeRecordId) => state.byId[timeRecordId];

export const getRedirect: (
  state: TimeRecordsState,
  temporaryId: string
) => string = (state, temporaryId) => state.redirects[temporaryId];

export const getTimeRecordsByIdState: (
  state: TimeRecordsState
) => TimeRecordsByIdState = (state: TimeRecordsState) => state.byId;

export const getIsFetching: (state: TimeRecordsState) => boolean = (
  state: TimeRecordsState
) => state.meta.isFetching;
export const getHasError: (state: TimeRecordsState) => boolean = (
  state: TimeRecordsState
) => state.meta.hasError;
export const getErrorMessage: (state: TimeRecordsState) => string = (
  state: TimeRecordsState
) => state.meta.errorMessage;
