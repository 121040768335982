import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  DateTimeString,
  MonthlyCloseId,
  TimeKeepingDayId,
} from '../../../models/Types';
import { DispatchAction, ReduxAction } from '../../../models/Redux';
import { MonthlyClose, TimeKeepingDay } from '../../../models/TimeKeeping';
import moment from 'moment';

export const DEBOUNCE_MONTHLY_CLOSE_ME = PRIO + 'DEBOUNCE_MONTHLY_CLOSE_ME';
export const debounceMonthlyCloseMe: (
  from?: DateTimeString,
  to?: DateTimeString,
  beforeCommit?: (any) => void
) => ReduxAction<{ from: DateTimeString; to: DateTimeString }> = (
  from,
  to,
  beforeCommit
) => ({
  type: DEBOUNCE_MONTHLY_CLOSE_ME,
  meta: {
    from,
    to,
  },
  beforeCommit,
});

export const SYNC_MONTHLY_CLOSE_ME = PRIO + 'SYNC_MONTHLY_CLOSE_ME';
export const syncMonthlyCloseMe: (
  from?: DateTimeString,
  to?: DateTimeString
) => ReduxAction<{ from: DateTimeString; to: DateTimeString }> = (
  from,
  to
) => ({
  type: SYNC_MONTHLY_CLOSE_ME,
  meta: {
    from,
    to,
  },
});

export const FETCH_MONTHLY_CLOSE_ME_REQUEST =
  PRIO + 'FETCH_MONTHLY_CLOSE_ME_REQUEST';
export const FETCH_MONTHLY_CLOSE_ME_COMMIT =
  PRIO + 'FETCH_MONTHLY_CLOSE_ME_COMMIT';
export const FETCH_MONTHLY_CLOSE_ME_ROLLBACK =
  PRIO + 'FETCH_MONTHLY_CLOSE_ME_ROLLBACK';

export const fetchMonthlyCloseMe: (
  from?: DateTimeString,
  to?: DateTimeString,
  isInitialFetch?: boolean
) => DispatchAction<
  {
    from: DateTimeString;
    to: DateTimeString;
    isInitialFetch: boolean;
  },
  MonthlyClose[]
> = (
  from = moment().startOf('month').toISOString(true).split('.')[0],
  to = moment().endOf('month').toISOString(true).split('.')[0],
  isInitialFetch = false
) => {
  return {
    type: FETCH_MONTHLY_CLOSE_ME_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/timekeeping/MonthlyCloseMe?${new URLSearchParams({
            from,
            to,
          }).toString()}`,
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: FETCH_MONTHLY_CLOSE_ME_COMMIT,
          meta: { from, to, isInitialFetch },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: FETCH_MONTHLY_CLOSE_ME_ROLLBACK,
          meta: { from, to, isInitialFetch },
          snackbarErrorMessage: {
            label: 'timeKeeping:messages.errorMessages.fetchError',
            timeout: 6,
          },
        },
      },
      from,
      to,
      isInitialFetch,
    },
  };
};

export const FETCH_SINGLE_MONTHLY_CLOSE_ME_REQUEST =
  PRIO + 'FETCH_SINGLE_MONTHLY_CLOSE_ME_REQUEST';
export const FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT =
  PRIO + 'FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT';
export const FETCH_SINGLE_MONTHLY_CLOSE_ME_ROLLBACK =
  PRIO + 'FETCH_SINGLE_MONTHLY_CLOSE_ME_ROLLBACK';

export const fetchSingleMonthlyCloseMe: (
  monthlyCloseId: MonthlyCloseId
) => DispatchAction<
  {
    monthlyCloseId: MonthlyCloseId;
  },
  MonthlyClose
> = (monthlyCloseId) => {
  return {
    type: FETCH_SINGLE_MONTHLY_CLOSE_ME_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/timekeeping/MonthlyCloseMe/${monthlyCloseId}`,
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT,
          meta: { monthlyCloseId },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: FETCH_SINGLE_MONTHLY_CLOSE_ME_ROLLBACK,
          meta: { monthlyCloseId },
          snackbarErrorMessage: {
            label: 'timeKeeping:messages.errorMessages.fetchError',
            timeout: 6,
          },
        },
      },
      monthlyCloseId,
    },
  };
};

export const CREATE_TIMEKEEPING_DAY = PRIO + 'CREATE_TIMEKEEPING_DAY';

export const createTimeKeepingDay: (
  monthDateTime: DateTimeString,
  timeKeepingDay: TimeKeepingDay
) => ReduxAction<{
  monthDateTime: DateTimeString;
}> = (monthDateTime, timeKeepingDay) => {
  return {
    type: CREATE_TIMEKEEPING_DAY,
    meta: {
      monthDateTime,
    },
    payload: timeKeepingDay,
  };
};

export const UPDATE_TIMEKEEPING_DAY = PRIO + 'UPDATE_TIMEKEEPING_DAY';

export const updateTimeKeepingDay: (
  updatedTimeKeepingDay: TimeKeepingDay,
  timeKeepingDayId: TimeKeepingDayId
) => ReduxAction<{ timeKeepingDayId: TimeKeepingDayId }, TimeKeepingDay> = (
  updatedTimeKeepingDay,
  timeKeepingDayId
) => ({
  type: UPDATE_TIMEKEEPING_DAY,
  payload: updatedTimeKeepingDay,
  meta: {
    timeKeepingDayId,
  },
});

export const DELETE_TIMEKEEPING_DAY = PRIO + 'DELETE_TIMEKEEPING_DAY';

export const deleteTimeKeepingDay: (
  monthlyCloseId: MonthlyCloseId,
  timeKeepingDayId: TimeKeepingDayId
) => ReduxAction<{
  monthlyCloseId: MonthlyCloseId;
  timeKeepingDayId: TimeKeepingDayId;
}> = (monthlyCloseId, timeKeepingDayId) => ({
  type: DELETE_TIMEKEEPING_DAY,
  meta: {
    monthlyCloseId,
    timeKeepingDayId,
  },
});
