import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Typography } from 'antd';
import {
  getNotificationsByIdState,
  getNotificationsDrawerState,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import NotificationsTabs from './NotificationsTabs';
import {
  closeNotificationsDrawer,
  NotificationTabs,
  setNotificationsDrawerTab,
  setNotificationsDrawerState,
} from '../actions/drawer';
import { NotificationId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSelector } from 'reselect';
import { NotificationsByIdState } from '../reducers/notifications';
import { apiUpdatedReadNotifications } from '../api';
import { ReadNotifications } from '../../../models/Notification';
import moment from 'moment';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

interface GlobalNotificationsDrawerProps {}
const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.old.spacing.defaultPadding,
  },
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },

  notificationDetails: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  notificationTabs: {
    flex: 1,
  },
  flexColExpand: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    zIndex: 999,
  },
}));

export const notificationsSelectorSystem = createSelector<
  [(state: RootReducerState) => NotificationsByIdState],
  NotificationId[]
>(
  (state) => getNotificationsByIdState(state),
  (byid) =>
    Object.values(byid)
      .filter(
        (n) =>
          (!!n &&
            !n.isArchived &&
            !n.isRead &&
            n.type === 'system' &&
            n.progressStatus === 'done' &&
            moment(n.notificationDateTime).add({ hours: 1 }) < moment.utc()) ||
          (n.type === 'absence' && n.data === null)
      )
      .map((n) => n.notificationId)
);

export const GlobalNotificationsDrawer: React.FC<
  GlobalNotificationsDrawerProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const notificationsDrawerState = useSelector(getNotificationsDrawerState); // Remove or fill with selector
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, view, tab } = notificationsDrawerState;

  const notifications = useSelector(notificationsSelectorSystem);

  const closeDrawer = () => {
    if (notifications.length > 0) {
      let readNotification: ReadNotifications = {
        notificationIds: notifications,
      };
      apiUpdatedReadNotifications(readNotification);
    }
    dispatch(closeNotificationsDrawer());
  };

  const onTabChange = (activeKey: NotificationTabs) => {
    dispatch(setNotificationsDrawerTab(activeKey));
  };
  const onNotificationClick = (notificationId: NotificationId) => {
    dispatch(
      setNotificationsDrawerState({
        selectedNotification: notificationId,
        view: 'notificationDetail',
      })
    );
  };

  const renderContent = () => {
    switch (view) {
      case 'list':
        return (
          <div className={classes.root}>
            <div>
              <Typography.Title level={2}>
                {t('notifications:title')}
              </Typography.Title>
            </div>
            <NotificationsTabs
              activeKey={tab}
              onChange={onTabChange}
              onNotificationClick={onNotificationClick}
              className={classes.notificationTabs}
              onClose={closeDrawer}
            />
          </div>
        );
      case 'notificationDetail':
        return <div>TODO: implement detail view</div>;

      default:
        return <></>;
    }
  };
  let bodyStyle: React.CSSProperties | null = null;

  switch (view) {
    case 'list':
      bodyStyle = { padding: 0 };
      break;
    case 'notificationDetail':
    default:
  }

  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={closeDrawer}
      visible={open}
      width={theme.old.components.drawerWidth}
      bodyStyle={bodyStyle}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      className={classes.drawer}
      destroyOnClose
    >
      {renderContent()}
    </Drawer>
  );
};

export default GlobalNotificationsDrawer;
