import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  List,
  Row,
  Select,
  Switch,
  notification,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import { InternalProjectContact } from '../../../../models/ProjectContacts';
import {
  ProjectExtension,
  ProjectExtensionAccess,
  ProjectExtensionAccessOption,
  UpdateUserExtensionAccess,
} from '../../../../models/ProjectExtension';
import { ProjectId } from '../../../../models/Types';
import { makePrioStyles } from '../../../../theme/utils';
import {
  apiFetchInternalProjectContacts,
  apiUpdateProjectExtensionAccesses,
  apiUpdateProjectExtensionDefaultAccess,
} from '../../api';
import UserAvatar from '../../../../components/UserAvatar';
import { Drawer, useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../../../../theme/types';
import InformationBox from '../../../../components/InformationBox';
import { debounceFunction } from '../../../../util';
//import SharedMailboxProjectExtensionAccessForm from './Forms/SharedMailboxProjectExtensionAccessForm';
//import ProjectExtensionAccessDrawer from './ProjectExtensionAccessDrawer';

const debouncedSearch = debounceFunction(
  (searchTerm: string, setSearchTerm: (value: string) => void) => {
    setSearchTerm(searchTerm);
  },
  500
);

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    position: 'relative',
  },
  spacing: {
    marginBottom: theme.old.spacing.defaultPadding,
  },
  list: {
    width: '100%',
  },
  avatarList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'left',
    '& > *': {
      marginRight: -theme.spacing.regular,
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    // margin right to separate the avatar from the text
    marginRight: theme.spacing.regular,
    // margin top 0 from avatar p
    '& p': {
      marginBottom: 0,
      marginRight: theme.spacing.regular,
    },
  },
  center: {
    marginBottom: 0,
    marginRight: theme.spacing.regular,
  },
  dangerButton: {
    marginLeft: theme.spacing.small,
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
    },
  },
}));

export interface ProjectExtensionAccessWithContact
  extends ProjectExtensionAccess {
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

export interface InternalProjectContactWithContact
  extends InternalProjectContact {
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

interface ExtensionAccessListProps {
  projectExtension: ProjectExtension;
  projectId: ProjectId;
  reloadProjectExtension: () => void;
}

export const ExtensionAccessList: React.FC<ExtensionAccessListProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { projectExtension, projectId, reloadProjectExtension } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const contactsByIdState = useSelector(getContactsByIdState);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const [
    projectExtensionAccessesWithContact,
    setProjectExtensionAccessesWithContact,
  ] = useState<ProjectExtensionAccessWithContact[]>(null);

  const [internalProjectContacts, setInternalProjectContacts] = useState<
    InternalProjectContact[]
  >([]);

  const contactWithNoAccess: InternalProjectContactWithContact[] =
    useMemo(() => {
      var noAccess = internalProjectContacts.filter((contact) => {
        if (
          !projectExtension?.accesses?.find(
            (access) =>
              access.contactId === contact.contactId && access.shouldHaveAccess
          )
        ) {
          return contact;
        } else {
          return null;
        }
      });
      var listToSave: InternalProjectContactWithContact[] = [];
      noAccess.forEach((element) => {
        var contact = contactsByIdState[element.contactId];
        if (contact) {
          listToSave.push({
            ...element,
            firstName: contact.firstName || '',
            lastName: contact.lastName || '',
            fullName: `${contact.firstName} ${contact.lastName}`,
          });
        }
      });
      return listToSave;
    }, [internalProjectContacts, projectExtension, contactsByIdState]);

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [hasDefaultAccess, setHasDefaultAccess] = useState<boolean>(
    projectExtension?.hasDefaultAccess
  );

  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = useMemo(() => {
    return projectExtensionAccessesWithContact?.filter(
      ({ shouldHaveAccess, firstName, lastName, fullName }) => {
        if (!shouldHaveAccess) {
          return false;
        }
        if (searchTerm) {
          return (
            fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            lastName?.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return true;
      }
    );
  }, [projectExtensionAccessesWithContact, searchTerm]);

  const [formAddUser] = Form.useForm();
  /*
  const [
    projectExtensionAccessDrawerVisible,
    setProjectExtensionAccessDrawerVisible,
  ] = useState<boolean>(false);


  const [selectedProjectExtensionAccess, setSelectedProjectExtensionAccess] =
    useState<ProjectExtensionAccess>(null);
  */
  // get project Extension Access list

  /*
  const openProjectExtensionAccess = (
    projectExtensionAccess: ProjectExtensionAccess
  ) => {
    setSelectedProjectExtensionAccess(projectExtensionAccess);
    setProjectExtensionAccessDrawerVisible(true);
  };
  */
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClick = (item: ProjectExtensionAccessWithContact) => {
    let newSelectedItems;
    if (selectedItems.includes(item.contactId)) {
      newSelectedItems = selectedItems.filter((i) => i !== item.contactId);
    } else {
      newSelectedItems = [...selectedItems, item.contactId];
    }
    setSelectedItems(newSelectedItems);
  };

  const handleSelectAll = (state: CheckboxChangeEvent) => {
    if (state.target.checked) {
      setSelectedItems(filteredData.map((row) => row.contactId));
    } else {
      setSelectedItems([]);
    }
  };

  const defaultAccessChanged = async (checked: boolean) => {
    setHasDefaultAccess(checked);
    setInProgress(true);
    try {
      await apiUpdateProjectExtensionDefaultAccess(
        projectId,
        projectExtension?.projectExtensionId,
        checked
      );
    } catch (error) {
      notification.error({
        message: t('common:error'),
        description: t('projects:defaultAccessChanged.error'),
      });
    }
    setInProgress(false);
    setSearchTerm('');
    if (reloadProjectExtension) {
      reloadProjectExtension();
    }
  };

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    debouncedSearch(event.target.value, setSearchTerm);
  };

  const addExtensionAccesses = async (formValues) => {
    setInProgress(true);
    var contactIds = formValues.addProjectContacts;
    var contactsToAdd = contactIds.map((contactId) =>
      internalProjectContacts.find((contact) => contact.contactId === contactId)
    );
    var accesses: ProjectExtensionAccess[] = contactsToAdd.map((contact) => {
      return {
        contactId: contact.contactId,
        shouldHaveAccess: true,
        projectExtensionAccessOptions: [],
        ProjectExtensionId: projectExtension?.projectExtensionId,
      };
    });
    // update api call
    const { data } = await apiUpdateProjectExtensionAccesses(
      projectId,
      accesses
    );
    if (data) {
      let contactsToAdd = filteredData;
      data.forEach((newAccess) => {
        var contact = contactsByIdState[newAccess.contactId];
        contactsToAdd.push({
          ...newAccess,
          firstName: contact.firstName,
          lastName: contact.lastName,
          fullName: `${contact.firstName} ${contact.lastName}`,
        });
      });
      setProjectExtensionAccessesWithContact(contactsToAdd);
      // update
      if (reloadProjectExtension) {
        reloadProjectExtension();
      }
    }
    formAddUser.resetFields();
    setInProgress(false);
  };

  const removeExtensionAccesses = async () => {
    setInProgress(true);
    var accessDeletions: UpdateUserExtensionAccess[] = selectedItems.map(
      (contactId) => {
        return {
          contactId: contactId,
          shouldHaveAccess: false,
          options: [],
          projectExtensionId: projectExtension?.projectExtensionId,
        };
      }
    );
    // update api call
    await apiUpdateProjectExtensionAccesses(projectId, accessDeletions);
    if (reloadProjectExtension) {
      reloadProjectExtension();
    }

    setSelectedItems([]);
    setInProgress(false);
  };
  //#endregion

  //#region ------------------------------ Components
  const getOptionsString: (
    options: ProjectExtensionAccessOption[]
  ) => React.ReactNode = (options: ProjectExtensionAccessOption[]) => {
    switch (projectExtension?.projectExtensionType) {
      /*
      case 'sharedMailbox':
        return (
          <SharedMailboxProjectExtensionAccessForm
            projectExtensionAccessOptions={options}
          />
        );
        */ //`${t(`projects:addIn.extensions.sharedMailbox.journal`)}: `;
      default:
        return '';
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    let accessList: ProjectExtensionAccessWithContact[] = [];
    projectExtension?.accesses?.forEach((access) => {
      var contact = contactsByIdState[access.contactId];
      if (contact) {
        accessList.push({
          ...access,
          firstName: contact?.firstName,
          lastName: contact?.lastName,
          fullName: `${contact?.firstName} ${contact?.lastName}`,
        });
      }
    });
    setProjectExtensionAccessesWithContact(accessList);
  }, [projectExtension, internalProjectContacts, contactsByIdState]);

  useEffect(() => {
    if (projectId) {
      const getInternalContacts = async (projectId) => {
        const { data } = await apiFetchInternalProjectContacts(projectId);
        if (data) {
          setInternalProjectContacts(data);
        }
      };
      getInternalContacts(projectId);
    }
  }, [projectId, contactsByIdState, projectExtension?.accesses]);
  //#endregion

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Switch
          disabled={inProgress}
          loading={inProgress}
          style={{ marginRight: theme.spacing.regular }}
          checked={hasDefaultAccess}
          onChange={(checked) => {
            defaultAccessChanged(checked);
          }}
        />
        <p>{t('projects:addIn.access.hasDefaultAccess')}</p>
      </div>
      <InformationBox
        description={t('projects:addIn.access.hasDefaultAccessDescription')}
        marginBottom={true}
      />
      {hasDefaultAccess ? (
        <>
          <div className={classes.avatarList}>
            {/*
           foreach projectExtentension contact create the user avatar
           */
            projectExtension?.accesses?.map((access) => {
              var contact = contactsByIdState[access.contactId];
              if (access.shouldHaveAccess && contact !== null) {
                return (
                  <UserAvatar
                    key={access.contactId}
                    size="large"
                    contact={contact ?? null}
                  />
                );
              }
              return <></>; // Add this line to return null for code paths that don't have a contact
            })}
          </div>
        </>
      ) : (
        <>
          <Row className={classes.spacing}>
            <Form
              form={formAddUser}
              className={classes.root}
              onFinish={(values) => {
                addExtensionAccesses(values);
              }}
            >
              <Flex.Column flex={12}>
                <Form.Item
                  name="addProjectContacts"
                  rules={[
                    {
                      required: true,
                      message: t(
                        'projects:form.validation.projectContactRequired'
                      ),
                    },
                  ]}
                >
                  <Select mode="tags">
                    {contactWithNoAccess.map((InternalProjectContact) => (
                      <Select.Option
                        id={InternalProjectContact.contactId}
                        key={InternalProjectContact.contactId}
                        value={InternalProjectContact.contactId}
                        title={InternalProjectContact.fullName}
                      >
                        {InternalProjectContact.fullName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Button htmlType="submit" type="primary" disabled={inProgress}>
                  {t(`projects:addIn.access.add`)}
                </Button>
              </Flex.Column>
            </Form>
          </Row>
          <Divider plain>{t(`projects:addIn.access.hasAccess`)}</Divider>
          <Flex.Row alignItems="center">
            <Flex.Column flex={1} justifyContent="center">
              <Checkbox
                checked={
                  selectedItems.length !== 0 &&
                  filteredData.length === selectedItems.length &&
                  filteredData
                    .map((map) => map.contactId)
                    .every((value, index) => value === selectedItems[index])
                }
                onChange={(state) => handleSelectAll(state)}
              ></Checkbox>
            </Flex.Column>
            <Flex.Column flex={selectedItems?.length > 0 ? 16 : 20}>
              <Input
                placeholder={t(`projects:addIn.access.search`)}
                onChange={handleSearch}
              />
            </Flex.Column>
            {selectedItems?.length > 0 && (
              <Flex.Column flex={4}>
                <Col>
                  <Button
                    onClick={() => {
                      removeExtensionAccesses();
                    }}
                    disabled={inProgress}
                    className={classes.dangerButton}
                  >
                    ({selectedItems.length}) {t(`projects:addIn.access.remove`)}
                  </Button>
                </Col>
              </Flex.Column>
            )}
          </Flex.Row>
          <Row>
            <List
              dataSource={filteredData}
              className={classes.list}
              renderItem={(item) => (
                <List.Item
                  key={item.projectExtensionAccessId}
                  actions={[
                    projectExtension?.projectExtensionType === 'jira' && (
                      /*
                  <Button onClick={() => openProjectExtensionAccess(item)}>
                    <FontAwesomeIcon
                      style={{ right: 28 }}
                      icon={['fal', 'chevron-right']}
                    />
                  </Button>
                  */ <></>
                    ),
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div className={classes.listItem}>
                        <div className={classes.center}>
                          <Checkbox
                            checked={selectedItems.includes(item.contactId)}
                            onChange={() => handleClick(item)}
                          ></Checkbox>
                        </div>

                        <div className={classes.center}>
                          <UserAvatar
                            key={item.contactId}
                            size="large"
                            contact={contactsByIdState[item.contactId] ?? null}
                          />
                        </div>
                        <p>{item.fullName}</p>
                      </div>
                    }
                    description={getOptionsString(
                      item.projectExtensionAccessOptions
                    )}
                  />
                </List.Item>
              )}
            />
          </Row>
        </>
      )}
      {/* <Row>
      <ProjectExtensionAccessDrawer
        projectExtensionAccessDrawerVisible={
          projectExtensionAccessDrawerVisible
        }
        setProjectExtensionAccessDrawerVisible={
          setProjectExtensionAccessDrawerVisible
        }
        projectExtensionAccess={selectedProjectExtensionAccess}
        projectExtension={projectExtension}
      />
      */}
      <Drawer title={t('projects:addIn.access.title')} />
    </>
  );
};

export default ExtensionAccessList;
