import { takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from '../i18n';

function showSuccessMessage(action: any) {
  try {
    const {
      snackbarCommitMessage: { label, timeout, title, placement },
    } = action;

    notification.open({
      message: title ? i18n.t(title) : i18n.t('common:requestMessageTitle'),
      description: label && i18n.t(label),
      duration: timeout,
      placement,
    });
  } catch (error) {}
}

export default function* watchCommits() {
  yield takeEvery<any>(
    (action) => /_COMMIT$/.test(action.type) && action.snackbarCommitMessage,
    showSuccessMessage
  );
}
