import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Input, Modal, notification, Typography } from 'antd';
import { DriveItem } from '../../../../models/Drive';
import { fileNameRegex, isDriveItemFolder } from '../../util';
import { apiUpdateDriveItem } from '../../api';
import { useDispatch } from 'react-redux';
import { driveItemRenamed } from '../../actions';
import { makePrioStyles } from '../../../../theme/utils';
import { DriveItemId, GroupId } from '../../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import Flex from '../../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.old.spacing.unit(1),
  },
}));

interface RenameModalProps {
  onClose: VoidFunction;
  renameDriveItem: DriveItem;
  folderDriveItemId: DriveItemId;
  groupId: GroupId;
}

export const RenameModal: React.FC<RenameModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const { groupId, renameDriveItem, folderDriveItemId, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [renameValue, setRenameValue] = useState<string>(null);
  const [fileEnding, setFileEnding] = useState<string>(null);
  const [renameValid, setRenameValid] = useState<boolean>(true);
  const [renamingActive, setRenamingActive] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onRenameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _renameValue = e.target.value;
    setRenameValue(_renameValue);
    setRenameValid(
      !!`${_renameValue.trim()}${fileEnding ? `.${fileEnding}` : ''}`.match(
        fileNameRegex
      )
    );
  };

  const onRenameOk = async () => {
    const _value = `${renameValue.trim()}${fileEnding ? `.${fileEnding}` : ''}`;
    if (_value === renameDriveItem.name) {
      closeModal();
      return;
    }
    setRenamingActive(true);
    const { result, data } = await apiUpdateDriveItem(
      groupId,
      renameDriveItem.id,
      { name: _value }
    );
    setRenamingActive(false);
    if (result.status >= 200 && result.status < 300) {
      dispatch(driveItemRenamed(data, folderDriveItemId, groupId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.renameDocumentError'),
      });
    }
    closeModal();
  };

  const closeModal = () => {
    setRenameValue(null);
    setFileEnding(null);
    onClose();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (renameDriveItem) {
      if (!isDriveItemFolder(renameDriveItem)) {
        const splittedNameArray = renameDriveItem.name.split('.');
        if (splittedNameArray.length > 1) {
          setRenameValue(splittedNameArray.slice(0, -1).join('.'));
          setFileEnding(splittedNameArray.splice(-1)[0]);
        } else {
          setRenameValue(splittedNameArray.join('.'));
          setFileEnding('');
        }
      } else {
        setRenameValue(renameDriveItem.name);
        setFileEnding(null);
      }
    }
  }, [renameDriveItem]);
  //#endregion

  return (
    <Modal
      title={t('documents:confirmation.rename.title')}
      visible={!!renameDriveItem}
      onOk={onRenameOk}
      onCancel={closeModal}
      confirmLoading={renamingActive}
      okButtonProps={{
        disabled: !renameValid,
      }}
      okText={t('documents:confirmation.rename.okText')}
      cancelText={t('documents:confirmation.rename.cancelText')}
      className={classes.root}
    >
      {renameDriveItem && (
        <div>
          <div>
            {t('documents:confirmation.rename.content', {
              fileName: renameDriveItem.name,
            })}
          </div>
          <Flex.Row
            alignItems="baseline"
            childrenGap={theme.old.spacing.unit(1)}
          >
            <Input
              value={renameValue}
              onChange={onRenameInputChange}
              className={classes.marginTop}
            />
            <Typography.Text>{`${
              fileEnding ? `.${fileEnding}` : ''
            }`}</Typography.Text>
          </Flex.Row>
          <Alert
            message={t('documents:confirmation.rename.invalidFileName')}
            type="error"
            style={{ visibility: renameValid ? 'hidden' : 'visible' }}
            className={classes.marginTop}
          />
        </div>
      )}
    </Modal>
  );
};

export default RenameModal;
