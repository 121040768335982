/**
 * @file Index.tsx the react container for the sidebar application
 * @author Jonathan Sommer <j.sommer@fc-gruppe.de>
 * @copyright (c) FC-Digitalisierung GmbH 2020
 * @license All rights reserved.
 */

import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import OfficeLoadingScreen from '../../components/OfficeLoadingScreen';
import configureStore from '../../store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import {
  BrowserRouter,
  Routes as ReactRouterDomRoutes,
  Route,
} from 'react-router-dom';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import MainApp from '../../modules/main/MainApp';
import 'react-dates/initialize';
import './App.less';
import './App.scss';
import PrioSpinner from '../../components/PrioSpinner';
import TeamsRedirectSignIn from '../../modules/auth/components/TeamsRedirectSignIn';
import OfficeRedirectSignIn from '../../modules/auth/components/OfficeRedirectSignIn';
import { configuration } from '../../modules/auth/authUtils';
import { PublicClientApplication } from '@azure/msal-browser';
import { Task } from '@redux-saga/types';
import PrioThemeProvider from '../../theme/components/PrioThemeProvider';
import icons from '../../icons';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import config from '../../queries/clientConfiguration';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

/**
 * The AppProps interface for the contact
 *
 * @export
 * @interface AppProps
 */
export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

const isMock =
  process.env.NODE_ENV === 'development' && process.env.REACT_APP_RUN_MOCK;

const { store, persistor } = configureStore(rootReducer, rootSaga);
const task = !isMock ? store.runSaga(rootSaga, store) : null;

icons.add();

const queryClient = new QueryClient(config);

/**
 * This Class is the entry point of the TaskPane for the Outlook plugin
 *
 * @class App
 * @extends {React.Component<AppProps>}
 */
class App extends React.Component<AppProps> {
  msalInstance = new PublicClientApplication(configuration);
  render() {
    const { title, isOfficeInitialized } = this.props;

    const loading = (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <PrioSpinner size="gigantic" />
      </div>
    );

    if (isMock) {
      return (
        <Suspense fallback={loading}>
          <PersistGate loading={loading} persistor={persistor}>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <PrioThemeProvider>
                  <BrowserRouter>
                    <Routes>
                      <Route
                        path="/*"
                        element={
                          <MainApp
                            msalInstance={this.msalInstance}
                            task={task as Task}
                            isMock
                          />
                        }
                      />
                    </Routes>
                  </BrowserRouter>
                </PrioThemeProvider>
              </QueryClientProvider>
            </Provider>
          </PersistGate>
        </Suspense>
      );
    }

    if (!isOfficeInitialized) {
      return (
        <OfficeLoadingScreen
          title={title}
          logo="/logo192.png"
          message="Loading..."
        />
      );
    }

    return (
      <Suspense fallback={loading}>
        <PersistGate loading={loading} persistor={persistor}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <PrioThemeProvider>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="teams/redirect"
                      element={
                        <TeamsRedirectSignIn msalInstance={this.msalInstance} />
                      }
                    ></Route>
                    <Route
                      path="office/redirect"
                      element={
                        <OfficeRedirectSignIn
                          msalInstance={this.msalInstance}
                        />
                      }
                    ></Route>
                    <Route
                      path="/*"
                      element={
                        <MainApp
                          msalInstance={this.msalInstance}
                          task={task as Task}
                        />
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </PrioThemeProvider>
            </QueryClientProvider>
          </Provider>
        </PersistGate>
      </Suspense>
    );
  }
}
export default App;
