import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dropdown, Menu, Upload, UploadProps } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import PrioSpinner from '../../../../components/PrioSpinner';
import MessageAttachmentDropdownList from '../MessageAttachmentDropdownList';
import { Importance, MessageAttachment } from '../../../../models/Message';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { MessageId, ProjectId } from '../../../../models/Types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch } from 'react-redux';
import { stopUpdateDraftSagaAction } from '../../actions/sagas';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.main,
    position: 'relative',
    maxHeight: 212,
  },
  actionsRow: {
    overflowX: 'scroll',
    padding: `${theme.old.spacing.unit(2)}px 0`,
    marginLeft: theme.old.spacing.unit(2),
    marginRight: theme.old.spacing.unit(2),
  },
  dropdown: {
    '&.ant-dropdown-trigger.ant-btn': {
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
  },
}));

export interface MailEditorActionButtowRowRefProps {
  setIsUpdating: (value: boolean) => void;
}

interface MailEditorActionButtowRowProps {
  className?: string;
  projectId: ProjectId;
  messageId: MessageId;
  messageAttachments: MessageAttachment[];
  selectedMessageAttachmentIds: string[];
  attachmentUploading: boolean;
  uploadProps: UploadProps<any>;
  cannotSend: boolean;
  setImportance: (value: Importance) => void;
  onImportanceChange: (
    value: Importance,
    setter: (value: Importance) => void
  ) => void;
  onDeleteAttachment?: (attachmentId: string) => Promise<void>;
  onAttachmentSelectionChanged: (e: CheckboxChangeEvent) => void;
  onDownloadAttachment: (messageAttachment: MessageAttachment) => Promise<void>;
  onSaveAndBack?: () => void;
  onCancel?: (messageId: MessageId) => Promise<void>;
  onSend: () => void;
}

export const MailEditorActionButtowRow = forwardRef<
  MailEditorActionButtowRowRefProps,
  MailEditorActionButtowRowProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const {
    className,
    projectId,
    messageId,
    messageAttachments,
    selectedMessageAttachmentIds,
    attachmentUploading,
    uploadProps,
    cannotSend,
    setImportance,
    onImportanceChange,
    onDeleteAttachment,
    onAttachmentSelectionChanged,
    onDownloadAttachment,
    onSaveAndBack,
    onCancel,
    onSend,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [mainButtonsDisabled, setMainButtonsDisabled] =
    useState<boolean>(false);

  const [isCanceling, setIsCanceling] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const isUpdatingRef = useRef<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleCancel = async () => {
    setMainButtonsDisabled(true);
    setIsCanceling(true);
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!isUpdatingRef.current) {
          clearInterval(interval);
          resolve(true);
        }
      }, 250);
    });
    dispatch(stopUpdateDraftSagaAction(messageId));
    await onCancel(messageId);
    setMainButtonsDisabled(false);
    setIsCanceling(false);
  };

  const handleOnSend = async () => {
    setMainButtonsDisabled(true);
    setIsSending(true);
    onSend();
    setTimeout(async () => {
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!isUpdatingRef.current) {
            clearInterval(interval);
            resolve(true);
          }
        }, 250);
      });
      setMainButtonsDisabled(false);
      setIsSending(false);
    }, 1000);
  };
  //#endregion

  //#region ------------------------------ Components
  const menu = (
    <Menu mode="vertical">
      <Menu.SubMenu
        key={'importance'}
        title={t('mail:composer.importance.title')}
      >
        <Menu.Item
          onClick={() => onImportanceChange('high', setImportance)}
          key={'high'}
        >
          {t('mail:composer.importance.high')}
        </Menu.Item>
        <Menu.Item
          onClick={() => onImportanceChange('normal', setImportance)}
          key={'normal'}
        >
          {t('mail:composer.importance.normal')}
        </Menu.Item>
        <Menu.Item
          onClick={() => onImportanceChange('low', setImportance)}
          key={'low'}
        >
          {t('mail:composer.importance.low')}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    setIsUpdating: (value: boolean) => {
      isUpdatingRef.current = value;
    },
  }));
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Row
        childrenGap={theme.old.spacing.unit(1)}
        className={classes.actionsRow}
      >
        <Button
          onClick={handleOnSend}
          disabled={mainButtonsDisabled || cannotSend}
          loading={isSending}
        >
          {t('mail:composer.send')}
        </Button>

        {onCancel && (
          <Button
            type="default"
            onClick={handleCancel}
            disabled={mainButtonsDisabled}
            loading={isCanceling}
          >
            {t('mail:composer.cancel')}
          </Button>
        )}
        {onSaveAndBack && (
          <Button
            type="default"
            onClick={onSaveAndBack}
            disabled={mainButtonsDisabled}
          >
            {t('mail:composer.back')}
          </Button>
        )}
        <div
          onDrop={(event: React.DragEvent<HTMLDivElement>) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          <Upload {...uploadProps}>
            {attachmentUploading ? (
              <Button type="default">
                <PrioSpinner size="small" />
              </Button>
            ) : (
              <Button
                type="default"
                style={{ color: 'black' }}
                iconProp={['fal', 'paperclip']}
              ></Button>
            )}
          </Upload>
        </div>
        {(messageAttachments ?? []).filter((message) => !message.isInline)
          .length > 0 && (
          <Dropdown
            overlay={
              <MessageAttachmentDropdownList
                selectedMessageAttachmentIds={selectedMessageAttachmentIds}
                messageAttachments={(messageAttachments ?? []).filter(
                  (message) => !message.isInline
                )}
                onDownloadAttachment={onDownloadAttachment}
                onSelectionChange={onAttachmentSelectionChanged}
                onDeleteAttachment={onDeleteAttachment}
                messageId={messageId}
                projectId={projectId}
                receivedMessage={false}
              />
            }
            trigger={['click']}
            placement="bottomCenter"
          >
            <Button
              type="default"
              style={{
                color: 'black',
                backgroundColor: theme.old.palette.backgroundPalette.content,
              }}
              iconProp={['fal', 'paperclip']}
            >
              {`(${
                (messageAttachments ?? []).filter(
                  (message) => !message.isInline
                ).length
              })`}
            </Button>
          </Dropdown>
        )}
        {
          <Dropdown
            overlay={menu}
            trigger={['click']}
            className={classes.dropdown}
            placement="topLeft"
          >
            <Button type="default" iconProp={['fal', 'ellipsis-h']}></Button>
          </Dropdown>
        }
      </Flex.Row>
    </div>
  );
});

export default MailEditorActionButtowRow;
