import { Reducer } from 'react';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { SET_LAST_HR_LOCATION, LastHRLocation } from '../actions';

const reducer: Reducer<string, LastHRLocation> = (
  state = null,
  action
) => {
  switch (action.type) {
    case SET_LAST_HR_LOCATION: {
      return action.hrLocation;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

export const getLastHRLocation = (state: string) => state;

export default reducer;