import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import {
  Button,
  Dropdown,
  Menu,
  Tooltip,
} from '@prio365/prio365-react-library';
import { MessageId, ProjectId } from '../../../../models/Types';
import {
  FlagStatus,
  flagStatuses,
  FollowupFlag,
  Message,
  MessageCenterMessage,
} from '../../../../models/Message';
import { useDispatch } from 'react-redux';
import MenuItem from '@prio365/prio365-react-library/lib/Menu/components/MenuItem';
import { flagMessage } from '../../actions/actionControllers/messageActionController';
import { distinct } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  menuItem: {
    margin: 0,
    fontSize: 14,
  },
  flagged: {
    '& svg': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

const getFlagMessage: (
  message: Message,
  flagStatus: FlagStatus
) => {
  messageId: MessageId;
  flag: FollowupFlag;
} = (message, flagStatus) => ({
  messageId: message.id,
  flag: {
    ...message.flag,
    flagStatus,
  },
});

interface MailFlagButtonProps {
  className?: string;
  projectId: ProjectId;
  selectedMessages: Message[];
  isFavoriteView?: boolean;
}

export const MailFlagButton: React.FC<MailFlagButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, projectId, selectedMessages, isFavoriteView } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeMenuItem = useMemo(() => {
    const flagStatuses = selectedMessages
      .map((message) => message.flag.flagStatus)
      .filter((flagStatus) => !!flagStatus);

    if (flagStatuses.length === 0) {
      return undefined;
    }

    if (flagStatuses.every((flagStatus) => flagStatus === 'Flagged')) {
      return 'Flagged';
    }

    if (flagStatuses.every((flagStatus) => flagStatus === 'Complete')) {
      return 'Complete';
    }

    if (flagStatuses.every((flagStatus) => flagStatus === 'NotFlagged')) {
      return 'NotFlagged';
    }
    return undefined;
  }, [selectedMessages]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = (flagStatus: FlagStatus) => {
    if (isFavoriteView) {
      const messages: MessageCenterMessage[] = selectedMessages;
      const projectIds = distinct(
        messages
          .map((message) => message.projectId)
          .filter((projectId) => !!projectId)
      );
      projectIds.forEach((projectId) => {
        dispatch(
          flagMessage(
            projectId,
            messages
              .filter((message) => message.projectId === projectId)
              .map((message) => getFlagMessage(message, flagStatus)),
            messages
              .filter((message) => message.projectId === projectId)
              .map((message) =>
                getFlagMessage(message, message.flag.flagStatus)
              )
          )
        );
      });
    } else {
      dispatch(
        flagMessage(
          projectId,
          selectedMessages.map((message) =>
            getFlagMessage(message, flagStatus)
          ),
          selectedMessages.map((message) =>
            getFlagMessage(message, message.flag.flagStatus)
          )
        )
      );
    }
  };
  //#endregion

  //#region ------------------------------ Components
  const wrapTooltip = (children: ReactElement, title: ReactNode) => {
    return title ? (
      <Tooltip overlay={title} placement="bottom">
        {children}
      </Tooltip>
    ) : (
      children
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Dropdown
      className={classNames(classes.root, className)}
      trigger={['click']}
      overlay={
        <Menu style={{ padding: 0 }} selectedItemId={activeMenuItem}>
          {flagStatuses.map((flagStatus) => (
            <MenuItem
              key={flagStatus}
              id={flagStatus}
              className={classNames(classes.menuItem, {
                [classes.flagged]: flagStatus === 'Flagged',
              })}
              iconProp={[
                flagStatus === 'Flagged' ? 'fas' : 'fal',
                flagStatus === 'Complete' ? 'check' : 'flag',
              ]}
              onClick={() => {
                handleOnClick(flagStatus);
              }}
              label={t(
                `mail:navigationBar.flagDropdown.${flagStatus.toLowerCase()}`
              )}
              disabled={selectedMessages.length > 200}
            />
          ))}
        </Menu>
      }
    >
      {wrapTooltip(
        <Button
          type="default"
          suffixIconProp={['fal', 'chevron-down']}
          disabled={selectedMessages.length > 200}
        >
          {t('mail:navigationBar.flag')}
        </Button>,
        selectedMessages.length > 200
          ? t('mail:navigationBar.toManySelected')
          : undefined
      )}
    </Dropdown>
  );
};

export default MailFlagButton;
