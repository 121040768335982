import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import { Row } from 'react-table';
import { IUseTableCellProps, OnRowProps } from './VirtualTable2';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DraggableElementWrapper from './DraggableElementWrapper';
import DroppableElementWrapper from './DroppableElementWrapper';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    borderTop: theme.old.borders.content,
    transition: 'background-color .3s',
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
  },
  checkBoxContainer: {
    width: '50px',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'flex-start',
    flex: 1,
    overflow: 'hidden',
  },
  cellContent: {
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  generalCellContent: {
    padding: 10,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    '& > span': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      flex: 1,
    },
  },
}));

interface VirtualTableRowProps<DataType extends object> {
  className?: string | ((item: DataType) => string);
  style: React.CSSProperties;
  row: Row<DataType>;
  onRow?: OnRowProps<DataType>;
  isSelectable?: boolean;
  selectedRows: Row<DataType>[];
  disabledRows: Row<DataType>[];
  prepareRow: (row: Row<DataType>) => void;
  onSelectRow: (
    event: CheckboxChangeEvent,
    row: Row<DataType>,
    selectAll?: boolean
  ) => void;
  renderCell: (
    row: Row<DataType>,
    cell: IUseTableCellProps<DataType>
  ) => JSX.Element;
}

export const VirtualTableRow = <DataType extends object>(
  props: VirtualTableRowProps<DataType>
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    style,
    row,
    onRow,
    isSelectable,
    disabledRows,
    selectedRows,
    prepareRow,
    onSelectRow,
    renderCell,
  } = props;
  const classes = useStyles();
  prepareRow(row);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isSelected = selectedRows.some((x) => x.id === row.id);
  const isDisabled = disabledRows.some((x) => x.id === row.id);
  const isDroppable =
    onRow?.onRowDrop?.acceptType && onRow?.onRowDrop.including(row.original);

  const isDraggable = !!onRow?.onRowDrag?.dragType;

  const triggerFunctions = useMemo(() => {
    let _triggerFunctions = onRow?.triggerFunctions(row.original);
    if (_triggerFunctions?.onClick) {
      _triggerFunctions.onClick = (e: React.MouseEvent<HTMLElement>) => {
        const regex = /((ant-dropdown)|(ant-popover))/;
        if (!regex.test((e.target as HTMLElement)?.className ?? '')) {
          onRow?.triggerFunctions(row.original)?.onClick(e);
        }
      };
    }
    return _triggerFunctions;
  }, [onRow, row.original]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getClassName = (item: DataType) => {
    if (className) {
      if (typeof className === 'string') {
        return className;
      } else {
        return className(item);
      }
    }
    return null;
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <DroppableElementWrapper
      row={row}
      onRow={onRow}
      style={style}
      selectedRows={selectedRows}
    >
      <DraggableElementWrapper
        row={row}
        onRow={onRow}
        style={style}
        selectedRows={selectedRows}
      >
        <div
          key={row.id}
          className={classNames(classes.root, getClassName(row.original), {
            [classes.clickable]: !!onRow?.triggerFunctions?.(row?.original)
              ?.onClick,
            [classes.selected]: isSelected,
          })}
          style={!isDraggable && !isDroppable ? style : null}
        >
          {isSelectable && (
            <div
              className={classNames(
                classes.checkBoxContainer,
                'prio-vTable-checkBoxContainer'
              )}
            >
              <Checkbox
                checked={isSelected}
                onChange={(e) => {
                  onSelectRow(e, row);
                }}
                disabled={isDisabled}
              ></Checkbox>
            </div>
          )}
          <div className={classes.dataRow} {...triggerFunctions}>
            {
              //@ts-ignore
              row.cells.map((cell: IUseTableCellProps<DataType>) =>
                renderCell(row, cell)
              )
            }
          </div>
        </div>
      </DraggableElementWrapper>
    </DroppableElementWrapper>
  );
};

export default VirtualTableRow;
