import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import Flex from '../../../components/Flex';
import { QRCodeSVG } from 'qrcode.react';
import * as ConfigValues from '../../../util/configValues';
import { PrioTheme } from '../../../theme/types';

const QR_CODE_VALUE = {
  tenantName: ConfigValues.FLUTTER_APP_TENANT_NAME,
  authority: ConfigValues.FLUTTER_APP_AUTHORITY,
  clientId: ConfigValues.FLUTTER_APP_CLIENT_ID,
  scopes: [
    `${
      ConfigValues.SCOPES.match(/^(api:\/\/.+)(\/\.default)$/)[1]
    }/access_as_user`,
  ],
  apiUrl: ConfigValues.REACT_APP_API_HOST_NAME,
};

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(3)}px`,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  card: {
    width: '100%',
    boxShadow: theme.old.palette.boxShadow.regular,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: theme.old.spacing.defaultPadding,
  },
  orderedList: {
    marginTop: theme.old.spacing.unit(2),
  },
}));

interface PrioAppPageProps {
  className?: string;
}

export const PrioAppPage: React.FC<PrioAppPageProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();

  console.log('QR_CODE_VALUE', QR_CODE_VALUE);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Typography.Title>
        {t('profile:profileNavigation.prioApp')}
      </Typography.Title>
      <Flex.Row
        className={classes.card}
        childrenGap={theme.old.spacing.defaultPadding}
      >
        <QRCodeSVG value={JSON.stringify(QR_CODE_VALUE)} size={200} />
        <Flex.Column>
          <Flex.Row>
            <div>{t('profile:prioAppPage.description')}</div>
          </Flex.Row>
          <Flex.Row>
            <div>{t('profile:prioAppPage.authenticate')}</div>
          </Flex.Row>
          <Flex.Row className={classes.orderedList}>
            <ol>
              <li>{t('profile:prioAppPage.steps.step1')}</li>
              <li>{t('profile:prioAppPage.steps.step2')}</li>
            </ol>
          </Flex.Row>
        </Flex.Column>
      </Flex.Row>
    </Flex.Column>
  );
};

export default PrioAppPage;
