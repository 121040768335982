import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Collapse,
  Menu,
  Button,
  Dropdown,
  Modal,
  Divider,
} from 'antd';

import {
  getProject,
  getProjectByIdState,
  getRemoteFoldersProjectIds,
  getRemoteItemsByProjectId,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createSelector } from 'reselect';
import { ProjectId, RemoteDriveItemId } from '../../../models/Types';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { DriveUserRemoteItem } from '../../../models/Document';
import classNames from 'classnames';
import {
  deleteRemoteFolder,
  setCurrentRemoteItem,
} from '../actions/remoteFolders';
import { replaceMyDriveItemPath } from '../util';
import { Project } from '../../../models/Project';
import { DndDriveItemDto, DND_TYPE_DRIVE_ITEM_FILE } from '../../../dnd/types';
import { useDrop } from 'react-dnd';
import { DriveItemDropTarget } from '../../../models/Drive';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import DocumentSettingsDrawer from './Drawers/DocumentSettingsDrawer';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.components.documentsTable.spacing * 2}px ${
      theme.old.components.documentsTable.padding
    }px`,
    margin: '0!important',
  },
  text: {
    lineHeight: '20px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
    color: theme.old.typography.colors.muted,
  },
  collapse: {
    backgroundColor: 'transparent',
    '& > .ant-collapse-item': {
      borderBottom: 'none',
    },
    '& > .ant-collapse-item-active > .ant-collapse-header': {
      fontWeight: theme.old.typography.fontWeight.bold,
    },
    '&.ant-collapse > .ant-collapse-item': {
      paddingTop: theme.old.components.documentsTable.spacing,
      paddingBottom: theme.old.components.documentsTable.spacing,
      paddingLeft: theme.old.components.documentsTable.padding,
      paddingRight: theme.old.components.documentsTable.padding,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingTop: theme.old.components.documentsTable.spacing,
      paddingBottom: theme.old.components.documentsTable.spacing,
      paddingLeft: 0,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
  },
  menu: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
    background: 'none',
    border: 'none',
    '&.ant-menu .ant-menu-item-selected:after': {
      display: 'none',
    },
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      paddingRight: 0,
    },
    '& .ant-menu-item:hover > span > a > div > button': {
      display: 'inline-block',
    },
    '& .ant-menu-item > span > a > div > button': {
      display: 'none',
    },
    '& .ant-menu-item-selected > span > a > div > button': {
      display: 'inline-block',
    },
  },
  collapseHeader: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  rowMenuButtonIcon: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
  },
  menuItem: {},
  innerMenuItem: {},
  menuItemContent: {
    overflow: 'hidden',
  },
  menuItemTitle: {
    lineHeight: '25px',
  },
  menuItemPath: {
    fontSize: theme.old.typography.fontSize.small,
    lineHeight: theme.old.typography.fontSize.base,
    color: theme.old.typography.colors.muted,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuButtonDefault: {
    padding: '0!important',
    height: 36,
    width: 40,
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
  },
  menuButtonSmall: {
    height: 40,
  },
  dropBackground: {
    border: theme.old.borders.dashedHighlighted,
    borderWidth: 2,
  },
  contentSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  settingsSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  settingsDivider: {
    margin: 0,
    marginLeft: '24px',
    width: 'calc(100% - 48px)',
    minWidth: 'calc(100% - 48px)',
    borderColor: theme.old.borders.colors.main,
  },
  settingsButton: {
    fontSize: theme.old.typography.fontSize.base,
    width: '100%',
    height: '64px',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.main,
    },
  },
  settingsButtonContent: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8px',
    gap: '10px',
    alignItems: 'center',
  },
  settingsIcon: {
    color: 'rgba(0,0,0,0.6)',
  },
}));

const projectsSelector = createSelector<
  [
    (state: RootReducerState) => ProjectId[],
    (state: RootReducerState) => ProjectByIdState,
  ],
  { projectId: ProjectId; number: string; shortName: string }[]
>(getRemoteFoldersProjectIds, getProjectByIdState, (ids, byId) =>
  (ids ?? [])
    .map((id) => ({
      number: byId[id]?.number ?? '',
      shortName: byId[id]?.shortName ?? '',
      projectId: id,
    }))
    .sort((a, b) => {
      const aString = `${a?.number ?? '...'} ${a?.shortName ?? ''}`;
      const bString = `${b?.number ?? '...'} ${b?.shortName ?? ''}`;
      return aString.localeCompare(bString);
    })
);

const remoteItemFromUrlSelector = (
  projectId: ProjectId,
  remoteItemId: RemoteDriveItemId,
  isRoot: boolean
) =>
  createSelector<
    [(state: RootReducerState) => DriveUserRemoteItem[]],
    DriveUserRemoteItem
  >(
    (state) => getRemoteItemsByProjectId(state, projectId),
    (items) =>
      items.find((item) => {
        if (isRoot) {
          return item.isRootFolder;
        }
        return item.remoteItemId === remoteItemId;
      })
  );

interface RemoteMenuProps {
  className?: string;
  pathPrefix?: string;
  isMeContext?: boolean;
  isRoot?: boolean;
}

export const RemoteMenu: React.FC<RemoteMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { className, pathPrefix, isRoot } = props;
  const { projectId, driveItemId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const projects = useSelector(projectsSelector);
  const remoteItemFromUrl = useSelector(
    remoteItemFromUrlSelector(projectId, driveItemId, isRoot)
  );

  const [documentSettingsDrawerOpen, setDocumentSettingsDrawerOpen] =
    useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(
      setCurrentRemoteItem(remoteItemFromUrl?.driveUserRemoteItemId ?? null)
    );
  }, [remoteItemFromUrl, dispatch]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const openDocumentSettingsDrawer = () => {
    setDocumentSettingsDrawerOpen(true);
  };
  const closeDocumentSettingsDrawer = () => {
    setDocumentSettingsDrawerOpen(false);
  };
  //#endregion

  return (
    <div
      className={classNames('prio-module-navigation', classes.root, className)}
    >
      <div className={classes.contentSection}>
        <Typography.Title className={classes.title}>
          {t('documents:titles.documents')}
        </Typography.Title>

        {projects.length === 0 && (
          <Typography.Text className={classes.text}>
            {t('documents:remoteItems.noItems')}
          </Typography.Text>
        )}
        <Collapse
          bordered={false}
          className={classes.collapse}
          defaultActiveKey={projectId ? [projectId] : undefined}
        >
          {projects.map((project) => (
            <Collapse.Panel
              className={classes.collapseHeader}
              key={project.projectId}
              header={`${project?.number ?? '...'} ${project?.shortName ?? ''}`}
            >
              <CollapseMenu
                projectId={project.projectId}
                pathPrefix={pathPrefix}
                isRoot={isRoot}
                activeKey={driveItemId}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
      <div className={classes.settingsSection}>
        <Divider className={classes.settingsDivider} />
        <Button
          type="text"
          className={classes.settingsButton}
          onClick={openDocumentSettingsDrawer}
        >
          <div className={classes.settingsButtonContent}>
            <FontAwesomeIcon
              className={classes.settingsIcon}
              icon={['fal', 'cog']}
            />
            {t('documents:settings.title')}
          </div>
        </Button>
      </div>
      <DocumentSettingsDrawer
        open={documentSettingsDrawerOpen}
        onClose={closeDocumentSettingsDrawer}
      />
    </div>
  );
};

export default RemoteMenu;

interface CollapseMenuProps {
  projectId: ProjectId;
  pathPrefix?: string;
  isRoot?: boolean;
  activeKey?: string;
}

const CollapseMenu: React.FC<CollapseMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId, pathPrefix, isRoot, activeKey } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const remoteItems = useSelector<RootReducerState, DriveUserRemoteItem[]>(
    (state) => getRemoteItemsByProjectId(state, projectId)
  );
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const menu = (item: DriveUserRemoteItem) => (
    <Menu inlineIndent={theme.old.components.menuInlineIndent}>
      <Menu.Item
        key={item.isRootFolder ? 'all-delete' : item.remoteItemId + 'delete'}
        onClick={(e) => {
          e.domEvent.stopPropagation();
          Modal.confirm({
            icon: null,
            title: t('documents:remoteItems.confirmation.delete.title'),
            content: (
              <Flex.Column>
                <Typography.Text>
                  {t('documents:remoteItems.confirmation.delete.content')}
                </Typography.Text>
                <Typography.Text>{item.name}</Typography.Text>
              </Flex.Column>
            ),
            okText: t('documents:remoteItems.confirmation.delete.okText'),
            cancelText: t(
              'documents:remoteItems.confirmation.delete.cancelText'
            ),
            onOk() {
              const promise = new Promise<void>(async (resolve) => {
                dispatch(deleteRemoteFolder(item.driveUserRemoteItemId, item));

                resolve();
              });
              return promise;
            },
            onCancel() {},
          });
        }}
      >
        {t('documents:remoteItems.actions.delete')}
      </Menu.Item>
    </Menu>
  );
  //#endregion

  return (
    <Menu
      className={classes.menu}
      mode="inline"
      selectedKeys={isRoot ? ['all'] : [activeKey]}
    >
      {remoteItems.map((item) => (
        <Menu.Item
          key={item.isRootFolder ? 'all' : item.remoteItemId}
          className={classNames({
            [classes.menuItem]: !!item.path,
          })}
          title={item.path ? replaceMyDriveItemPath(item, project) : undefined}
          style={{ height: theme.old.components.documentsTable.tableRowHeight }}
        >
          <RemoteItem
            project={project}
            remoteItem={item}
            menu={menu}
            pathPrefix={pathPrefix}
          />
        </Menu.Item>
      ))}
    </Menu>
  );
};

interface RemoteItemProps {
  project: Project;
  remoteItem: DriveUserRemoteItem;
  pathPrefix?: string;
  menu: (item: DriveUserRemoteItem) => JSX.Element;
}

const RemoteItem: React.FC<RemoteItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { remoteItem, pathPrefix, project, menu } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  const [{ isOver, canDrop }, dropRef] = useDrop<
    DndDriveItemDto & { projectId: ProjectId },
    any,
    any
  >({
    accept: [DND_TYPE_DRIVE_ITEM_FILE],
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return undefined;
      }
      const dndType = monitor.getItemType();
      switch (dndType) {
        case DND_TYPE_DRIVE_ITEM_FILE: {
          return {
            type: 'remoteDriveItem',
            object: remoteItem,
          } as DriveItemDropTarget;
        }
        default: {
          return undefined;
        }
      }
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item) => {
      const ids = (item.driveItems ?? [item.driveItem]).map(
        (driveItem) => driveItem.id
      );
      return !ids.includes(remoteItem.remoteItemId);
    },
  });
  //#endregion

  return (
    <div
      className={classNames(classes.innerMenuItem, {
        [classes.dropBackground]: isOver && canDrop,
      })}
    >
      <Link
        to={`${pathPrefix ?? ''}${
          remoteItem.isRootFolder
            ? `${project.projectId}/all`
            : `${project.projectId}/folder/${remoteItem.remoteItemId}`
        }`}
        ref={dropRef}
      >
        <Flex.Row alignItems="center">
          <Flex.Column flex={1} className={classes.menuItemContent}>
            <Flex.Item className={classes.menuItemTitle}>
              <FontAwesomeIcon
                fixedWidth
                icon={['fal', 'folder']}
                className={classes.icon}
              />
              {remoteItem.name}
            </Flex.Item>
          </Flex.Column>
          <Dropdown
            overlay={menu(remoteItem)}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button
              icon={
                <FontAwesomeIcon
                  className={classes.rowMenuButtonIcon}
                  icon={['fal', 'ellipsis-v']}
                />
              }
              className={classNames(classes.menuButtonDefault, {
                [classes.menuButtonSmall]: !remoteItem.path,
              })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Dropdown>
        </Flex.Row>
      </Link>
    </div>
  );
};
