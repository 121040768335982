import { PRIO } from '../../../constants';
import { Doodle, DoodleType } from '../../../models/Doodle';

export interface DoodleAction {
  type: string;
  doodleType: DoodleType;
  doodle: Doodle;
}

export const DISABLE_DOODLE = PRIO + 'DISABLE_DOODLE';

export const disableDoodle = (type: DoodleType, doodle: Doodle) => ({
  type: DISABLE_DOODLE,
  doodle,
  doodleType: type,
});

export const ENABLE_DOODLE = PRIO + 'ENABLE_DOODLE';

export const enableDoodle = (type: DoodleType, doodle: Doodle) => ({
  type: ENABLE_DOODLE,
  doodle,
  doodleType: type,
});
