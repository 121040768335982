export const commonGraphErrorCodes = [
  'accessDenied',
  'activityLimitReached',
  'extensionError',
  'generalException',
  'invalidRange',
  'invalidRequest',
  'itemNotFound',
  'malwareDetected',
  'nameAlreadyExists',
  'notAllowed',
  'notSupported',
  'resourceModified',
  'resyncRequired',
  'serviceNotAvailable',
  'syncStateNotFound',
  'quotaLimitReached',
  'unauthenticated',
] as const;

export declare type CommenErrorCode = typeof commonGraphErrorCodes[number];

export const detailedGraphErrorCodes = [
  'accessRestricted',
  'cannotSnapshotTree',
  'childItemCountExceeded',
  'entityTagDoesNotMatch',
  'fragmentLengthMismatch',
  'fragmentOutOfOrder',
  'fragmentOverlap',
  'invalidAcceptType',
  'invalidParameterFormat',
  'invalidQueryOption',
  'invalidStartIndex',
  'lockMismatch',
  'lockNotFoundOrAlreadyExpired',
  'lockOwnerMismatch',
  'malformedEntityTag',
  'maxDocumentCountExceeded',
  'maxFileSizeExceeded',
  'maxFolderCountExceeded',
  'maxFragmentLengthExceeded',
  'maxItemCountExceeded',
  'maxQueryLengthExceeded',
  'maxStreamSizeExceeded',
  'parameterIsTooLong',
  'parameterIsTooSmall',
  'pathIsTooLong',
  'pathTooDeep',
  'propertyNotUpdateable',
  'provisioningNotAllowed',
  'resourceBeingProvisioned',
  'resyncApplyDifferences',
  'resyncRequired',
  'resyncUploadDifferences',
  'serviceNotAvailable',
  'serviceReadOnly',
  'throttledRequest',
  'tooManyResultsRequested',
  'tooManyTermsInQuery',
  'totalAffectedItemCountExceeded',
  'truncationNotAllowed',
  'uploadSessionFailed',
  'uploadSessionIncomplete',
  'uploadSessionNotFound',
  'virusSuspicious',
  'zeroOrFewerResultsRequested',
] as const;

export declare type DetailedErrorCode = typeof detailedGraphErrorCodes[number];

export interface GraphError<CodeType> {
  code: CodeType;
  message: string;
  innerError?: Partial<GraphError<DetailedErrorCode>>;
}

export interface GraphResponseError {
  error: GraphError<CommenErrorCode>;
}
