import React from 'react';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesDe from 'i18n-iso-countries/langs/de.json';

countries.registerLocale(countriesEn);
countries.registerLocale(countriesDe);

interface CountryTextProps {
  countryCode: string;
  federalStateCode?: string;
}

export const CountryText: React.FC<CountryTextProps> = ({
  countryCode,
  federalStateCode,
}) => {
  const { i18n } = useTranslation();

  return (
    <span>
      {countries.getName(countryCode, i18n.language.substring(0, 2))}
      {federalStateCode != null ? ' (' + federalStateCode + ')' : ''}
    </span>
  );
};

export default CountryText;
