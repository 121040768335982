import React from 'react';
import { useState, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

const detectOverflowInDom: (
  nodes: Element[],
  maxDepth: number,
  maxBodyWidth: number
) => boolean = (nodes, maxDepth, maxBodyWidth) => {
  const result: boolean =
    nodes?.reduce((currentValue: boolean, node: Element) => {
      if (!node?.children) {
        return (
          currentValue || detectOverflow(node as HTMLElement, maxBodyWidth)
        );
      } else if (node?.children?.length > 0 && maxDepth - 1 > 0) {
        return (
          currentValue ||
          detectOverflow(node as HTMLElement, maxBodyWidth) ||
          detectOverflowInDom(
            Object.values(node?.children),
            maxDepth - 1,
            maxBodyWidth
          )
        );
      }
      return currentValue || detectOverflow(node as HTMLElement, maxBodyWidth);
    }, false) ?? false;
  return result;
};

const detectOverflow: (node: HTMLElement, maxBodyWidth: number) => boolean = (
  node,
  maxBodyWidth
) => {
  return !!(
    maxBodyWidth < node?.offsetWidth ||
    node?.parentElement?.offsetWidth < node?.offsetWidth
  );
};

const useOverflowDetection: (
  triggerCheck?: boolean
) => [
  boolean,
  React.MutableRefObject<Element>,
  (instance: any) => void,
  number
] = (triggerCheck) => {
  const { ref: resizeRef, width: bodyMaxWidth } =
    useResizeObserver<HTMLDivElement>();

  const ref = React.useRef<Element>();

  const [hasOverflow, setHasOverflow] = useState<boolean>();

  useEffect(() => {
    if (ref?.current && ref.current.children.length > 0) {
      setHasOverflow(
        detectOverflowInDom(
          Object.values(ref?.current?.children),
          3,
          bodyMaxWidth
        )
      );
    }
  }, [ref, bodyMaxWidth, triggerCheck]);

  return [hasOverflow, ref, resizeRef, bodyMaxWidth];
};

export default useOverflowDetection;
