import { combineReducers } from 'redux';
import { Dashboard, DashboardItem } from '../../../models/Dashboard';
import { DashboardId, DashboardItemId } from '../../../models/Types';
import dashboardMeState, * as fromDashboardMe from './me';

export interface DashboardReducerState {
  me: fromDashboardMe.DashboardMeState;
}

export default combineReducers<DashboardReducerState>({
  me: dashboardMeState,
});

export const getDashboard: (
  state: DashboardReducerState,
  dashboardId: DashboardId
) => Dashboard = ({ me }, dashboardId) =>
  fromDashboardMe.getDashboard(me, dashboardId);

export const getDashboardItem: (
  state: DashboardReducerState,
  dashboardItemId: DashboardItemId
) => DashboardItem = ({ me }, dashboardItemId) =>
  fromDashboardMe.getDashboardItem(me, dashboardItemId);
