import React, { useEffect, useState } from 'react';
import { ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import ProjectDocumentSettingsManagement from './ProjectDocumentSettingsManagement';
import { apiFetchDocumentProjectSettingPreview } from '../../mail/api';
import { DocumentPrefixProjectSettingPreview } from '../../../models/Project';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import ProjectDocumentTagsManagement from './ProjectDocumentTagsManagement';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface DocumentSettingsPageProps {
  projectId: ProjectId;
}

export const DocumentSettingsPage: React.FC<DocumentSettingsPageProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { projectId } = props;

  const [preview, setPreview] =
    useState<DocumentPrefixProjectSettingPreview>(null);

  useEffect(() => {
    const fetchPreviews = async () => {
      const { data } = await apiFetchDocumentProjectSettingPreview(projectId, [
        'projectDocumentPrefix',
      ]);
      if (data) {
        setPreview(data);
      }
    };
    fetchPreviews();
  }, [projectId]);

  return (
    <div className={classes.root}>
      <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
        <ProjectDocumentSettingsManagement
          className={classes.shadow}
          projectId={projectId}
          type="projectDocumentPrefix"
          initialPreview={preview ? preview['projectDocumentPrefix'] : null}
        />
        <ProjectDocumentTagsManagement
          className={classes.shadow}
          projectId={projectId}
        />
      </Flex.Column>
    </div>
  );
};

export default DocumentSettingsPage;
