import { useMemo } from 'react';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useCompaniesContext from '../../../modules/companies/hooks/useCompaniesContext';
import useAccessRights2 from '../../../modules/users/hooks/useAccessRights2';

interface CompanyFilterPickerConsumerProps extends FilterPickerConsumerProps {
  type?: 'all' | 'internal' | 'external';
}

const CompanyFilterPickerConsumer: React.FC<
  CompanyFilterPickerConsumerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children, roles, type } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { companies: _companies } = useCompaniesContext(true);

  const {
    checkOfficeRolesOfCompanyId,
    checkProjectRolesOfCompanyId,
    checkGlobalRoles,
  } = useAccessRights2();

  const companies = useMemo(() => {
    const companies = _companies.filter((company) => {
      const { companyType, fullName, fullName2, shortName } = company;
      if (!fullName && !fullName2 && !shortName) {
        return false;
      }
      switch (type) {
        case 'internal': {
          return companyType === 'InternalCompany';
        }
        case 'external': {
          return companyType === 'ExternalCompany';
        }
        default: {
          return true;
        }
      }
    });
    if (
      roles.globalRoles.length > 0 &&
      checkGlobalRoles(
        roles.globalRoles.filter((role) => role !== 'globalEmployee')
      )
    ) {
      return companies;
    }
    if (roles.officeRoles.length > 0 && roles.projectRoles.length > 0) {
      return companies.filter(
        ({ companyId, companyType }) =>
          companyType === 'ExternalCompany' ||
          checkOfficeRolesOfCompanyId(roles.officeRoles, companyId) ||
          checkProjectRolesOfCompanyId(roles.projectRoles, companyId)
      );
    }
    return companies;
  }, [
    type,
    roles,
    _companies,
    checkOfficeRolesOfCompanyId,
    checkProjectRolesOfCompanyId,
    checkGlobalRoles,
  ]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: companies.map(
          ({ companyId, fullName, fullName2, shortName }) => {
            return {
              value: companyId,
              label: fullName ?? fullName2 ?? shortName,
              searchValue: `${fullName} ${shortName} ${companyId}`,
            };
          }
        ),
      })}
    </>
  );
};

export default CompanyFilterPickerConsumer;
