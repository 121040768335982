import { Reducer, combineReducers } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ReduxAction } from '../../../models/Redux';
import { OfficeHoliday } from '../../../models/AbsenceProposal';
import {
  FETCH_OFFICE_HOLIDAY_COMMIT,
  FETCH_OFFICE_HOLIDAY_FROM_SEARCH_COMMIT,
  FETCH_OFFICE_HOLIDAY_FROM_SEARCH_REQUEST,
  FETCH_OFFICE_HOLIDAY_FROM_SEARCH_ROLLBACK,
  FETCH_OFFICE_HOLIDAY_REQUEST,
  FETCH_OFFICE_HOLIDAY_ROLLBACK,
} from '../actions';
import {
  DateTimeString,
  OfficeHolidayId,
  OfficeId,
} from '../../../models/Types';
import moment from 'moment';
import { distinct } from '../../../util';
import { GenericSearchResult } from '../../../components/Filter/types';

export interface OfficeHolidayState {
  byId: OfficeHolidayByIdState;
  ids: OfficeHolidayId[];
  meta: MetaState;
}

export const initialState: OfficeHolidayState = {
  byId: {},
  ids: [],
  meta: {
    isFetching: false,
    hasError: false,
  },
};

interface OfficeHolidayByIdState {
  [officeHolidayId: OfficeHolidayId]: OfficeHoliday;
}

type HolidayData = {
  isHalfDay: boolean;
  date: DateTimeString;
  name: string;
};

type HolidaysCalculatedData = {
  internalPublic: 'Public' | 'Internal';
  officeIds: OfficeId[];
  isoCodes: string[];
  officeIdOfficeHolidayIdDtos: Array<{
    officeId: OfficeId;
    officeHolidayId: OfficeHolidayId;
  }>;
};

const byId: Reducer<
  OfficeHolidayByIdState,
  ReduxAction<
    {
      officeId: OfficeId;
    },
    OfficeHoliday[] | GenericSearchResult<HolidayData, HolidaysCalculatedData>
  >
> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_OFFICE_HOLIDAY_COMMIT: {
      const { payload } = action;
      const _payload = payload as OfficeHoliday[];
      return _payload.reduce(
        (map, holiday) => ({
          ...map,
          [holiday.date]: holiday,
        }),
        {}
      );
    }
    case FETCH_OFFICE_HOLIDAY_FROM_SEARCH_COMMIT: {
      const {
        payload,
        meta: { officeId },
      } = action;
      const _payload = payload as GenericSearchResult<
        HolidayData,
        HolidaysCalculatedData
      >;
      return _payload.items.reduce((map, { data, calculated }) => {
        const officeHoliday: OfficeHoliday = {
          date: data.date,
          name: data.name,
          isHalfDay: data.isHalfDay,
          officeId: officeId.toLowerCase(),
          officeHolidayId: calculated.officeIdOfficeHolidayIdDtos
            .find(
              (officeHolidayId) =>
                officeHolidayId.officeId.toLowerCase() ===
                officeId.toLowerCase()
            )
            ?.officeHolidayId?.toLowerCase(),
          holidayType: calculated.internalPublic,
          isoCode: calculated.isoCodes[0],
        };

        return {
          ...map,
          [data.date]: officeHoliday,
        };
      }, state);
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default: {
      return state;
    }
  }
};

const ids: Reducer<
  OfficeHolidayId[],
  ReduxAction<
    unknown,
    OfficeHoliday[] | GenericSearchResult<HolidayData, HolidaysCalculatedData>
  >
> = (state = initialState.ids, action) => {
  switch (action.type) {
    case FETCH_OFFICE_HOLIDAY_COMMIT: {
      const { payload } = action;

      const _payload = payload as OfficeHoliday[];

      return _payload.map(({ date }) => date);
    }
    case FETCH_OFFICE_HOLIDAY_FROM_SEARCH_COMMIT: {
      const { payload } = action;
      const _payload = payload as GenericSearchResult<
        HolidayData,
        HolidaysCalculatedData
      >;

      return distinct(
        state.concat(_payload.items.map(({ data }) => data.date))
      );
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default: {
      return state;
    }
  }
};

interface MetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<MetaState, ReduxAction> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_OFFICE_HOLIDAY_FROM_SEARCH_REQUEST:
    case FETCH_OFFICE_HOLIDAY_REQUEST: {
      return {
        isFetching: true,
        hasError: false,
      };
    }
    case FETCH_OFFICE_HOLIDAY_FROM_SEARCH_COMMIT:
    case FETCH_OFFICE_HOLIDAY_COMMIT: {
      return {
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_OFFICE_HOLIDAY_FROM_SEARCH_ROLLBACK:
    case FETCH_OFFICE_HOLIDAY_ROLLBACK: {
      return {
        isFetching: false,
        hasError: true,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<OfficeHolidayState>({
  byId,
  ids,
  meta,
});

export const getOfficeHolidays: (
  state: OfficeHolidayState
) => OfficeHoliday[] = ({ ids, byId }) =>
  ids.map((id) => byId[id]).filter((holiday) => !!holiday);

export const getOfficeHolidaysOfMonth: (
  state: OfficeHolidayState,
  dateTime: DateTimeString
) => OfficeHoliday[] = ({ ids, byId }, dateTime) => {
  const holidaysThisMonth = ids
    .map((id) => byId[id])
    .filter(
      (holiday) =>
        holiday && moment(holiday.date).isSame(moment(dateTime), 'month')
    );
  return holidaysThisMonth ?? [];
};

export const getOfficeHolidaysOfTimeRange: (
  state: OfficeHolidayState,
  from: DateTimeString,
  to: DateTimeString
) => OfficeHoliday[] = ({ ids, byId }, from, to) => {
  const holidays = ids
    .map((id) => byId[id])
    .filter(
      (holiday) =>
        holiday &&
        moment(holiday.date).isSameOrAfter(moment(from), 'month') &&
        moment(holiday.date).isSameOrBefore(moment(to), 'month')
    );
  return holidays ?? [];
};
