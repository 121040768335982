import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@prio365/prio365-react-library';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  getContact,
  getContactRedirect,
  getContactsDrawerState,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import ContactDetails from './ContactDetails';
import { makePrioStyles } from '../../../theme/utils';
import {
  Contact,
  InternalContact,
  ExternalContact,
} from '../../../models/Contact';
import { apiFetchContact } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { setContactsDrawerState } from '../actions/drawer';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  contactDetails: {
    height: '100%',
    overflow: 'hidden',
  },
}));
interface ContactDetailsPageProps {
  noPadding?: boolean;
  backButton?: boolean;
}

export const ContactDetailsPage: React.FC<ContactDetailsPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const { contactId } = useParams();
  const navigate = useNavigate();
  const { noPadding, backButton } = props;

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const contact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, contactId)
  );
  const redirect = useSelector<RootReducerState, string>((state) =>
    getContactRedirect(state, contactId)
  );

  const { changedContact } = useSelector(getContactsDrawerState);
  const [onlineContact, setOnlineContact] = useState<Contact>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const goBack = () => navigate(-1);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!contact) {
      const fecthOnlineContact = async () => {
        const { result, data } = await apiFetchContact(contactId);
        if (result.status >= 200 && result.status < 300) {
          setOnlineContact(data);
        }
      };
      fecthOnlineContact();
    }
  }, [contact, contactId]);

  useEffect(() => {
    if (
      onlineContact &&
      onlineContact?.contactId === changedContact?.contact.contactId
    ) {
      const refetchOnlineContact = async () => {
        const { result, data } = await apiFetchContact(
          onlineContact?.contactId
        );
        if (result.status >= 200 && result.status < 300) {
          setOnlineContact(data);
        }
        dispatch(setContactsDrawerState({ changedContact: null }));
      };
      refetchOnlineContact();
    }
  }, [onlineContact, changedContact, dispatch]);

  useEffect(() => {
    if (contact && changedContact) {
      dispatch(setContactsDrawerState({ changedContact: null }));
    }
  }, [contact, changedContact, dispatch]);
  //#endregion

  if (redirect) {
    return <Navigate to={`../../${redirect}/details`} />;
  }

  if (contact || onlineContact) {
    return (
      <>
        {backButton && (
          <Button
            onClick={goBack}
            type="link"
            iconProp={['fal', 'chevron-left']}
          >
            {t('common:back')}
          </Button>
        )}
        <ContactDetails
          contact={
            (contact ?? onlineContact) as InternalContact & ExternalContact
          }
          className={classNames(classes.contactDetails, {
            [classes.noPadding]: noPadding,
          })}
        />
      </>
    );
  }

  return (
    <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
      <PrioSpinner size="large" />
    </div>
  );
};

export default ContactDetailsPage;
