import { useTranslation } from 'react-i18next';
import { DriveFavorite } from '../../../models/DriveFavorite';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useKeyboardModifiers } from '@prio365/prio365-react-library';
import { deleteDriveFavorite } from '../actions/driveFavorites';
import Flex from '../../../components/Flex';
import { Dropdown, Menu } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colorForIcon, iconForMimeType } from '../util';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  innerMenuItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
  },
  innerMenuItemSelected: {
    paddingLeft: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.active.main,
  },
  deleteIconRow: {
    display: 'flex',
    gap: '5px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuItemContent: {
    overflow: 'hidden',
  },
  menuItemTitle: {
    lineHeight: '25px',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuButtonDefault: {
    padding: '0!important',
    height: 36,
    width: 40,
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
    },
    '&:hover > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));
interface DriveFavoriteMenuItemProps {
  driveFavorite: DriveFavorite;
  onRename: (driveFavorite: DriveFavorite) => void;
  isSelected: boolean;
  onSelect: (driveFavorite: DriveFavorite, isSelected: boolean) => void;
  pathPrefix?: string;
}
export const DriveFavoriteMenuItem: React.FC<DriveFavoriteMenuItemProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { driveFavorite, pathPrefix, isSelected, onRename, onSelect } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { shiftIsPressed } = useKeyboardModifiers();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  //#endregion

  //#region ------------------------------ Methods / Handlers

  const onRenameDriveFavorite = () => {
    onRename(driveFavorite);
  };
  const onDeleteDriveFavorite = () => {
    dispatch(deleteDriveFavorite(driveFavorite));
  };
  const onDriveFavoriteClick = () => {
    if (shiftIsPressed) {
      onSelect(driveFavorite, isSelected);
    } else {
      if (!driveFavorite.mimeType) {
        navigate(
          `${pathPrefix}../../${driveFavorite.projectId}/documents/folder/${driveFavorite.driveItemId}`
        );
      }
    }
  };

  //#endregion

  //#region -------------------------------- Components
  const menu = () => (
    <Menu>
      <Menu.Item
        onClick={(info) => {
          info.domEvent.preventDefault();
          info.domEvent.stopPropagation();
          onDeleteDriveFavorite();
        }}
      >
        {t('documents:driveFavorites.actions.delete')}
      </Menu.Item>
      <Menu.Item
        onClick={(info) => {
          info.domEvent.preventDefault();
          info.domEvent.stopPropagation();
          onRenameDriveFavorite();
        }}
      >
        {t('documents:driveFavorites.actions.rename')}
      </Menu.Item>
    </Menu>
  );
  //#endregion
  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(
        classes.root,
        isSelected ? classes.innerMenuItemSelected : classes.innerMenuItem
      )}
      onClick={onDriveFavoriteClick}
    >
      <Flex.Row alignItems="center">
        <Flex.Column flex={1} className={classes.menuItemContent}>
          <Flex.Item className={classes.menuItemTitle}>
            <FontAwesomeIcon
              fixedWidth
              icon={
                !driveFavorite.mimeType
                  ? ['fas', 'folder']
                  : iconForMimeType(driveFavorite.mimeType)
              }
              style={{
                color: !driveFavorite.mimeType
                  ? theme.old.palette.chromaticPalette.yellow
                  : colorForIcon(driveFavorite.mimeType),
              }}
              className={classes.icon}
            />
            <span> {driveFavorite.displayName}</span>
          </Flex.Item>
        </Flex.Column>
        <Dropdown overlay={menu()} trigger={['click']} placement="bottomRight">
          <Button
            type="link"
            iconProp={['fal', 'ellipsis-v']}
            className={classNames(classes.menuButtonDefault)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Dropdown>
      </Flex.Row>
    </div>
  );
};

export default DriveFavoriteMenuItem;
