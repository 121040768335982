import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface GeneralMenuProps {
  selectedList?: string;
}

export const GeneralMenu: React.FC<GeneralMenuProps> = (props) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="mail">
        <Link to="../mail">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'envelope']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.mail')}
        </Link>
      </Menu.Item>
      <Menu.Item key="documents">
        <Link to="../documents">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'folder-tree']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.documents')}
        </Link>
      </Menu.Item>
      <Menu.Item key="addins">
        <Link to="../addins">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'puzzle-piece']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.addins')}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default GeneralMenu;
