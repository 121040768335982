import React from 'react';
import { useParams } from 'react-router-dom';

import { ProjectSettingsMenu } from './ProjectSettingsMenu';
import Flex from '../../../components/Flex';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    maxWidth: theme.old.components.menuMaxWidth,
    overflow: 'auto',
  },
}));

interface ProjectSettingsNavigationProps {
  projectId: string;
  className?: string;
}

export const ProjectSettingsNavigation: React.FC<
  ProjectSettingsNavigationProps
> = (props) => {
  const classes = useStyles();
  const { selectedList } = useParams();
  const { className, projectId } = props;

  return (
    <Flex.Item
      flex={1}
      className={classNames('prio-module-navigation', classes.root, className)}
    >
      <ProjectSettingsMenu projectId={projectId} selectedList={selectedList} />
    </Flex.Item>
  );
};

export default ProjectSettingsNavigation;
