import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getMailSearchByIdsState,
  getMailSearchIdsState,
  getMailSearchIsFetching,
  getMessagesByIdState,
  getMessagesIdsState,
  getMyProjectIds,
  RootReducerState,
  getProjectByIdState,
  getMessageIdsByProjecId,
  getCurrentSearchTerm,
  getCurrentSearchTermState,
  getCurrentSearchMailFolderId,
  getCurrentSearchMailFolderIdState,
  getMailSearchIds,
} from '../../../apps/main/rootReducer';
import { Message, MessageCenterMessage } from '../../../models/Message';
import { AdvancedMailSearchDto } from '../../../models/MailSearch';
import { MailFolderId, ProjectId } from '../../../models/Types';
import { deleteMailSearchResults } from '../actions/actionControllers/searchActionController';
import { ByMessageId, MessageIdsByMailFolderId } from '../actions/types';
import { ByProjectId } from '../reducers/projects/messages';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { Project } from '../../../models/Project';
import { getFavoriteProjects } from '../../projects/selectors';

const favoritesThreshold = 5;

const messagesSelector = (projectId: ProjectId, mailFolderId: MailFolderId) =>
  createSelector<
    [
      (state: RootReducerState) => ByMessageId,
      (state: RootReducerState) => MessageIdsByMailFolderId,
    ],
    Message[]
  >(
    (state) => getMessagesByIdState(state, projectId),
    (state) => getMessagesIdsState(state, projectId),
    (messageById, messageIds) =>
      (messageIds[mailFolderId] ?? [])
        .map((id) => messageById[id])
        .filter((message) => !!message)
        .sort((a: Message, b: Message) => {
          return (
            Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime)
          );
        })
  );

const searchMessagesInMessageCenterSelector = createSelector<
  [
    (state: RootReducerState) => ByMessageId,
    (state: RootReducerState) => { [projectId: ProjectId]: string[] },
    (state: RootReducerState) => { [projectId: ProjectId]: MailFolderId },
    (state: RootReducerState) => ProjectByIdState,
    (state: RootReducerState) => ProjectId[],
  ],
  MessageCenterMessage[]
>(
  (state) => getMailSearchByIdsState(state, 'favorites'),
  (state) => getMailSearchIdsState(state, 'favorites'),
  getCurrentSearchMailFolderIdState,
  getProjectByIdState,
  getMyProjectIds,
  (messageById, messageIds, mailFolderIdState, projectsById, projectIds) => {
    const projectFavoriteIds = projectIds.filter(
      (id) => projectsById[id]?.favorite
    );
    return projectFavoriteIds
      .reduce(
        (currentArray, projectId) => [
          ...currentArray,
          ...(messageIds[projectId] ?? []).map((messageId, index, array) =>
            !!messageById[messageId]
              ? {
                  ...messageById[messageId],
                  ...(!!messageById[messageId]
                    ? {
                        projectId,
                        projectName: `${projectsById[projectId].number} ${projectsById[projectId].name}`,
                        projectShortName: `${projectsById[projectId].number} ${projectsById[projectId].shortName}`,
                        shouldFetch:
                          index === array.length - favoritesThreshold,
                        mailFolderId: mailFolderIdState[projectId],
                      }
                    : {}),
                }
              : null
          ),
        ],
        []
      )
      .filter((message) => !!message)
      .sort((a: MessageCenterMessage, b: MessageCenterMessage) => {
        return Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime);
      });
  }
);

const searchMessagesSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: RootReducerState) => ByMessageId,
      (state: RootReducerState) => string[],
    ],
    Message[] | MessageCenterMessage[]
  >(
    (state) => getMailSearchByIdsState(state, projectId),
    (state) => getMailSearchIds(state, projectId),
    (searchMessageById, searchMessageIdsState) => {
      return (searchMessageIdsState ?? [])
        .map((id) => searchMessageById[id])
        .filter((message) => !!message)
        .sort((a: Message, b: Message) => {
          return (
            Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime)
          );
        });
    }
  );

const favoritesMessagesSelector = () =>
  createSelector<
    [
      (state: RootReducerState) => ProjectByIdState,
      (state: RootReducerState) => ProjectId[],
      (state: RootReducerState) => ByMessageId,
      (state: RootReducerState) => ByProjectId,
    ],
    MessageCenterMessage[]
  >(
    getProjectByIdState,
    getMyProjectIds,
    (state) => getMessagesByIdState(state, 'favorites'),
    getMessageIdsByProjecId,
    (projectsById, projectIds, byId, byProjectId) => {
      const projectFavoriteIds = projectIds.filter(
        (id) => projectsById[id]?.favorite
      );
      return projectFavoriteIds
        .reduce<MessageCenterMessage[]>(
          (currentArray, projectId) => [
            ...currentArray,
            ...(byProjectId[projectId]?.['inbox'] ?? []).map(
              (id, index, array) =>
                !!byId[id]
                  ? {
                      ...byId[id],
                      ...(!!byId[id]
                        ? {
                            projectId,
                            projectName: `${projectsById[projectId].number} ${projectsById[projectId].name}`,
                            projectShortName: `${projectsById[projectId].number} ${projectsById[projectId].shortName}`,
                            mailFolderId: 'inbox',
                            shouldFetch:
                              index === array.length - favoritesThreshold,
                          }
                        : {}),
                    }
                  : null
            ),
          ],
          []
        )
        .filter((message) => !!message)
        .sort((a: MessageCenterMessage, b: MessageCenterMessage) => {
          return (
            Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime)
          );
        });
    }
  );

export const combinedMessageSelector = (
  hasSearchTerm: boolean,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) =>
  createSelector<
    [
      (state: RootReducerState) => Message[],
      (state: RootReducerState) => Message[] | MessageCenterMessage[],
      (state: RootReducerState) => MessageCenterMessage[],
    ],
    Message[]
  >(
    messagesSelector(projectId, mailFolderId),
    projectId !== 'favorites'
      ? searchMessagesSelector(projectId)
      : searchMessagesInMessageCenterSelector,
    favoritesMessagesSelector(),
    (messages, search, favorites) => {
      if (hasSearchTerm) {
        return search;
      }
      return projectId !== 'favorites' ? messages : favorites;
    }
  );

const searchTermSelector = (projectId: ProjectId, projects: Project[]) =>
  createSelector<
    [
      (state: RootReducerState) => string | AdvancedMailSearchDto,
      (state: RootReducerState) => {
        [projectId: ProjectId]: string | AdvancedMailSearchDto;
      },
    ],
    string | AdvancedMailSearchDto
  >(
    (state) =>
      projectId !== 'favorites' ? getCurrentSearchTerm(state, projectId) : null,
    (state) =>
      projectId === 'favorites' ? getCurrentSearchTermState(state) : null,
    (searchTerm, searchTermState) => {
      if (projectId !== 'favorites') {
        return searchTerm;
      }
      return projects.length > 0
        ? Object.values(searchTermState).find((term) => !!term) ?? null
        : null;
    }
  );

const searchTermMailFolderIdSelector = (projectId: ProjectId) =>
  createSelector<[(state: RootReducerState) => MailFolderId], MailFolderId>(
    (state) =>
      projectId !== 'favorites'
        ? getCurrentSearchMailFolderId(state, projectId)
        : null,
    (mailFolderId) => {
      if (projectId !== 'favorites') {
        return mailFolderId;
      }
      return undefined;
    }
  );

interface MailSearchProps {
  projectId: string;
  mailFolderId: string;
}

const useMailSearch: (
  props: MailSearchProps
) => [
  messages: Message[],
  isFetchingSearchResults: boolean,
  searchTerm: AdvancedMailSearchDto | string,
  searchTermMailFolderIds: MailFolderId,
] = (props) => {
  //#region ------------------------------ Defaults
  const { projectId, mailFolderId } = props;
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const projectFavorites = useSelector(getFavoriteProjects);
  const searchTerm = useSelector(
    searchTermSelector(projectId, projectFavorites)
  );

  const searchTermMailFolderId = useSelector(
    searchTermMailFolderIdSelector(projectId)
  );

  const searchTermMailFolderIdsState = useSelector(
    getCurrentSearchMailFolderIdState
  );

  const favoriteSearchTermMailFolderIdsState = (projectFavorites ?? []).map(
    (project) => searchTermMailFolderIdsState[project.projectId]
  );

  const messages = useSelector(
    combinedMessageSelector(!!searchTerm, projectId, mailFolderId)
  );

  const isSearching = useSelector<RootReducerState, boolean>((state) =>
    getMailSearchIsFetching(state, projectId)
  );
  //#endregion

  useEffect(() => {
    if (!searchTerm) {
      dispatch(deleteMailSearchResults(projectId === 'me'));
    }
  }, [projectId, searchTerm, dispatch]);

  return [
    messages,
    !!searchTerm && isSearching,
    searchTerm,
    searchTermMailFolderId ??
      (favoriteSearchTermMailFolderIdsState.length > 0
        ? favoriteSearchTermMailFolderIdsState[0] === 'allFolders'
          ? 'allFolders'
          : 'inbox'
        : 'allFolders'),
  ];
};
export default useMailSearch;
