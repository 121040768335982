import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';

import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import { TextProps } from 'antd/lib/typography/Text';
import { truncateString } from '../../../util';
import { Project } from '../../../models/Project';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

interface ProjectTextProps extends TextProps {
  projectId: string;
  shortName?: boolean;
  showNumber?: boolean;
  addLineBreak?: boolean;
  maxChars?: number;
  style?: React.CSSProperties;
}

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

export const ProjectText: React.FC<ProjectTextProps> = (props) => {
  const {
    className,
    projectId,
    shortName,
    addLineBreak,
    showNumber,
    maxChars,
    ...rest
  } = props;
  const classes = useStyles();

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  if (!project) return null;

  const text = `${showNumber ? `${project.number} ` : ''}${
    shortName ? project.shortName ?? project.name : project.name
  }`;
  const finalText = maxChars ? truncateString(text, maxChars) : text;

  return (
    <Typography.Text
      className={classNames(classes.root, className)}
      {...rest}
      ellipsis
    >
      {finalText}
      {addLineBreak && <br />}
    </Typography.Text>
  );
};

export default ProjectText;
