import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';

/** Contact fetching */
export const FETCH_USER_REGISTRATION_STATE_REQUEST =
  PRIO + 'FETCH_USER_REGISTRATION_STATE_REQUEST';
export const FETCH_USER_REGISTRATION_STATE_COMMIT =
  PRIO + 'FETCH_USER_REGISTRATION_STATE_COMMIT';
export const FETCH_USER_REGISTRATION_STATE_ROLLBACK =
  PRIO + 'FETCH_USER_REGISTRATION_STATE_ROLLBACK';

export const fetchUserRegistrationState = () => ({
  type: FETCH_USER_REGISTRATION_STATE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/user/User/currentUser/getRegistrationState`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_USER_REGISTRATION_STATE_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_USER_REGISTRATION_STATE_ROLLBACK,
        snackbarErrorMessage: {
          label: 'common:errorMessages.fetchUserError',
          timeout: 6,
        },
      },
    },
  },
});

export const SET_REGISTRATION_COMPLETE = PRIO + 'SET_REGISTRATION_COMPLETE';

export const registrationComplete = () => ({
  type: SET_REGISTRATION_COMPLETE,
});
