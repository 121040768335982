import React from 'react';
import { Row } from 'react-table';
import { OnRowProps } from './VirtualTable2';
import DroppableElement from '../../dnd/components/DroppableElement';

interface DroppableElementWrapperProps<DataType extends object> {
  children: JSX.Element;
  row: Row<DataType>;
  onRow: OnRowProps<DataType>;
  style: React.CSSProperties;
  selectedRows: Row<DataType>[];
}

export const DroppableElementWrapper = <DataType extends object>(
  props: DroppableElementWrapperProps<DataType>
) => {
  //#region ------------------------------ Defaults
  const { children, row, onRow, style, selectedRows } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isDroppable =
    onRow?.onRowDrop?.acceptType && onRow?.onRowDrop.including(row.original);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (isDroppable) {
    return (
      <DroppableElement
        activeOverlay
        id={row.id}
        key={row.id}
        disable={onRow.onRowDrop.disable}
        style={style}
        object={
          onRow?.onRowDrop?.configureDroppableObject
            ? onRow.onRowDrop.configureDroppableObject(
              row.original,
              selectedRows.map((x) => x.original)
            )
            : row.original
        }
        accept={onRow.onRowDrop.acceptType}
        onDrop={onRow.onRowDrop.onDrop}
        onOver={onRow.onRowDrop.onOver}
      >
        {children}
      </DroppableElement>
    );
  }
  return children;
};

export default DroppableElementWrapper;
