import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Input, Row, Col, Checkbox, InputNumber, DatePicker } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import { makePrioStyles } from '../../../theme/utils';

import {
  CreateTrainingRequest,
  Training,
  TrainingFormModel,
} from '../../../models/Training';
import { rowGutter, colon } from '../../../util/forms';
import ContactPicker from '../../contacts/components/ContactPicker';
import useDatePickerLocale from '../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { QualificationPicker } from './QualificationPicker';
import { AudiencePicker } from './AudiencePicker';
import { DepartmentPicker } from './DepartmentPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompanyPicker from '../../companies/components/CompanyPicker';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TrainingId } from '../../../models/Types';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(3),
  },
  descriptionInput: {
    minHeight: '100px!important',
  },
  datePicker: {
    width: `calc(100% - ${16 + theme.old.spacing.unit(1)}px)`,
    marginRight: theme.old.spacing.unit(1),
  },
  applicationDeadlinePicker: {
    width: '100%',
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  scrollable: {
    height: 'calc(100% - 56px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

interface TrainingFormProps {
  initialValues?: Training;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  deleteLabel?: string;
  onFinish: (value: CreateTrainingRequest | Training) => Promise<boolean>;
  onCancel?: () => void;
  onDelete?: (trainingId: TrainingId) => void;
}

export const TrainingForm: React.FC<TrainingFormProps> = React.memo((props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    initialValues,
    actionLabel,
    cancelLabel,
    deleteLabel,
    onFinish,
    onCancel,
    onDelete,
    disableActionButton,
    disableForm,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [maxParticipantsEnabled, setMaxParticipantsEnabled] = useState<boolean>(
    initialValues.maxParticipants != null
  );
  const datePickerLocale: PickerLocale = useDatePickerLocale();

  const extraTrainingManagerIds = initialValues.trainingManagers.map(
    (manager) => manager.contactId
  );

  const transformedInitialValue: TrainingFormModel = {
    ...initialValues,
    trainingOrganizerId: initialValues?.trainingOrganizer?.companyId,
    trainingManagerIds: extraTrainingManagerIds,
    trainingDays: initialValues.trainingDays.map((trainingDay) => [
      moment(trainingDay.startTime, moment.ISO_8601),
      moment(trainingDay.endTime, moment.ISO_8601),
    ]),
    applicationDeadline: initialValues.applicationDeadline
      ? moment(initialValues.applicationDeadline, moment.ISO_8601)
      : null,
  };

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  const cancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = async (currentValue: TrainingFormModel) => {
    if (!onFinish) return;

    const { trainingDays, applicationDeadline, ...otherValues } = currentValue;
    const trainingDaysIso = trainingDays.map(([startTime, endTime]) => ({
      startTime: `${startTime.toISOString(true).slice(0, 23)}Z`,
      endTime: `${endTime.toISOString(true).slice(0, 23)}Z`,
    }));
    let success = false;
    if ((initialValues as Training).trainingId) {
      success = await onFinish({
        ...otherValues,
        trainingId: (initialValues as Training).trainingId,
        rowVersion: (initialValues as Training).rowVersion,
        trainingDays: trainingDaysIso,
        applicationDeadline: applicationDeadline
          .toISOString(true)
          .split('T')[0],
      });
    } else {
      success = await onFinish({
        ...otherValues,
        trainingDays: trainingDaysIso,
        applicationDeadline: applicationDeadline
          .toISOString(true)
          .split('T')[0],
      });
    }
    if (success) {
      form.resetFields();
    }
  };

  const handleMaxParticipantsCheckBoxChange = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;
    setMaxParticipantsEnabled(value);
    form.setFieldsValue({ maxParticipants: value ? 100 : null });
  };

  const handleDelete = () => {
    onDelete((initialValues as Training)?.trainingId);
  };

  return (
    <Form<TrainingFormModel>
      className={classes.root}
      initialValues={transformedInitialValue}
      form={form}
      onFinish={handleFinish}
      layout="vertical"
    >
      <div className={classes.scrollable}>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="title"
              colon={colon}
              label={t('hr:trainings.form.labels.title')}
              rules={[
                {
                  required: true,
                  message: t('hr:trainings.form.validation.missing.title'),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('hr:trainings.form.labels.description')}
            >
              <Input.TextArea
                disabled={disableForm}
                className={classes.descriptionInput}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="trainingManagerIds"
              label={t('hr:trainings.form.labels.trainingManagerIds')}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:trainings.form.validation.missing.trainingManagerIds'
                  ),
                },
              ]}
            >
              <ContactPicker
                multiple
                disabled={disableForm}
                includedContacts={initialValues?.trainingManagers ?? []}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              name="trainingTargetAudienceIds"
              label={t('hr:trainings.form.labels.trainingTargetAudienceIds')}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:trainings.form.validation.missing.trainingTargetAudienceIds'
                  ),
                },
              ]}
            >
              <AudiencePicker multiple disabled={disableForm} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="trainingTargetDepartmentIds"
              label={t('hr:trainings.form.labels.trainingTargetDepartmentIds')}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:trainings.form.validation.missing.trainingTargetDepartmentIds'
                  ),
                },
              ]}
            >
              <DepartmentPicker multiple disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="trainingTargetQualificationIds"
              label={t(
                'hr:trainings.form.labels.trainingTargetQualificationIds'
              )}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:trainings.form.validation.missing.trainingTargetQualificationIds'
                  ),
                },
              ]}
            >
              <QualificationPicker multiple disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.List name="trainingDays">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={
                          index === 0
                            ? t('hr:trainings.form.labels.trainingDays')
                            : null
                        }
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: true,
                              message: t(
                                'hr:trainings.form.validation.missing.trainingDay'
                              ),
                            },
                          ]}
                          noStyle
                        >
                          <DatePicker.RangePicker
                            disabled={disableForm}
                            locale={datePickerLocale}
                            className={classes.datePicker}
                            showTime={{ format: 'HH:mm' }}
                            format="DD.MM.YYYY HH:mm"
                            suffixIcon={null}
                            minuteStep={15}
                            allowClear={false}
                          />
                        </Form.Item>

                        {fields.length > 1 ? (
                          <FontAwesomeIcon
                            icon={['fal', 'minus-circle']}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ paddingLeft: 0 }}
                      >
                        <FontAwesomeIcon icon={['fal', 'plus']} />
                        {t('hr:trainings.form.actions.addTrainingDay')}
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="applicationDeadline"
              label={t('hr:trainings.form.labels.applicationDeadline')}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:trainings.form.validation.missing.applicationDeadline'
                  ),
                },
              ]}
            >
              <DatePicker
                disabled={disableForm}
                locale={datePickerLocale}
                format="DD.MM.YYYY"
                className={classes.applicationDeadlinePicker}
                suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="trainingOrganizerId"
              label={t('hr:trainings.form.labels.trainingOrganizerId')}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:trainings.form.validation.missing.trainingOrganizerId'
                  ),
                },
              ]}
            >
              <CompanyPicker disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="eventLocation"
              label={t('hr:trainings.form.labels.eventLocation')}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:trainings.form.validation.missing.eventLocation'
                  ),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="eventRoom"
              label={t('hr:trainings.form.labels.eventRoom')}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item name="isPrivateTraining" valuePropName="checked">
              <Checkbox disabled={disableForm}>
                {t('hr:trainings.form.labels.isPrivateTraining')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item name="isOnlineTraining" valuePropName="checked">
              <Checkbox disabled={disableForm}>
                {t('hr:trainings.form.labels.isOnlineTraining')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={20}>
            <Checkbox
              disabled={disableForm}
              checked={maxParticipantsEnabled}
              onChange={handleMaxParticipantsCheckBoxChange}
            >
              {t('hr:trainings.form.labels.maxParticipants')}
            </Checkbox>
          </Col>
          <Col span={4}>
            {maxParticipantsEnabled && (
              <Form.Item name="maxParticipants">
                <InputNumber
                  min={0}
                  max={100000}
                  precision={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
      </div>
      <Row justify="end" className={classes.actionButtonsRow}>
        <Col span={24}>
          <Form.Item className={classes.submitButtonFormItem}>
            <Flex.Row
              alignItems={'center'}
              justifyContent={'flex-end'}
              childrenGap={theme.old.spacing.unit(2)}
            >
              {onDelete && (
                <Flex.Item flex={1}>
                  <Button
                    type="default"
                    onClick={handleDelete}
                    disabled={disableActionButton || disableForm}
                    className={classes.danger}
                  >
                    {deleteLabel}
                  </Button>
                </Flex.Item>
              )}
              {onCancel && (
                <Button
                  type="default"
                  onClick={cancel}
                  disabled={disableActionButton || disableForm}
                  style={{ marginRight: theme.old.spacing.unit(1) }}
                >
                  {cancelLabel}
                </Button>
              )}
              <Button
                type="primary"
                htmlType="submit"
                disabled={disableActionButton || disableForm}
              >
                {actionLabel}
              </Button>
            </Flex.Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

export default TrainingForm;
