import React, { useEffect, useState } from 'react';
import { Dropdown, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { getGlobalSiderOpen } from '../apps/main/rootReducer';
import { MainMenu } from '../modules/main/MainMenu';
import useDebounce from '../hooks/useDebounce';
import PrioSpinner from './PrioSpinner';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../theme/utils';
import { PrioTheme } from '../theme/types';
import { useTheme } from 'react-jss';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    cursor: 'pointer',
    padding: `0 ${theme.old.spacing.defaultPadding}px`,
    height: theme.old.components.globalNavigationBar.height ?? 47,
    backgroundColor: theme.old.palette.backgroundPalette.base,
    marginBottom: theme.old.spacing.defaultPadding,
  },
  headLine: {
    lineHeight: '44px !important',
    marginBottom: '0px!important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&.ant-typography': {
      color: theme.old.typography.colors.inverted,
    },
  },
  dropDown: {
    cursor: 'pointer',
  },
  dropDownButton: {
    backgroundColor: theme.old.palette.backgroundPalette.base,
    marginBottom: theme.old.spacing.unit(1.5),
    height:
      theme.old.components.globalNavigationBar.height ??
      theme.old.spacing.defaultPadding * 2,
    borderRadius: 0,
    padding: '10.5px 21px',
    background: 'transparent',
    transition: 'all 0.2s',
    cursor: 'pointer',
    '&:hover': {
      background: theme.old.palette.backgroundPalette.hover.base,
    },
  },
  logoContainer: {
    transition: 'all 0.2s',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.old.typography.colors.inverted,
  },
  mainMenu: {
    margin: `0 ${theme.old.spacing.defaultPadding}px`,
  },
}));

interface GlobalDropdownProps {
  classNameCollapsed?: string;
  title?: string;
  onBarsClick?: VoidFunction;
  selectedMainMenuItem: string;
}

export const GlobalDropdown: React.FC<GlobalDropdownProps> = (props) => {
  const { title, onBarsClick, selectedMainMenuItem, classNameCollapsed } =
    props;

  //#region ---------- Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ---------- States / Attributes / Selectors
  const globalSiderOpen = useSelector(getGlobalSiderOpen);
  const debouncedGlobalSiderOpen = useDebounce(globalSiderOpen, 100);

  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  //#endregion

  //#region ---------- Methods / Handlers
  const closeDropDown = () => setDropDownOpen(false);
  //#endregion

  //#region ---------- useEffects
  useEffect(() => {
    setDropDownOpen(false);
  }, [globalSiderOpen]);
  //#endregion

  if (!debouncedGlobalSiderOpen) {
    return (
      <div
        className={classNames(classes.dropDownButton, classNameCollapsed)}
        onClick={() => {
          if (onBarsClick) {
            onBarsClick();
          }
        }}
        id="prio-global-navigation-button"
      >
        <PrioSpinner stand />
      </div>
    );
  }

  return (
    <Dropdown
      visible={dropDownOpen}
      overlay={
        <MainMenu
          className={classes.mainMenu}
          closeDropDown={closeDropDown}
          selectedEntry={selectedMainMenuItem}
        />
      }
      trigger={['click']}
      onVisibleChange={setDropDownOpen}
      className={classes.root}
    >
      <span
        className={classes.logoContainer}
        id="prio-global-navigation-button"
      >
        <div>
          <PrioSpinner stand />
        </div>
        {globalSiderOpen && (
          <Typography.Title className={classes.headLine} level={3}>
            {title ?? t('common:sider.mainMenu')}
          </Typography.Title>
        )}
        <FontAwesomeIcon
          icon={['fal', 'chevron-down']}
          size="xs"
          fixedWidth
          flip={dropDownOpen ? 'vertical' : null}
          style={{
            marginLeft: theme.old.spacing.unit(0.75),
            height: 20,
            fontSize: theme.old.components.globalDropDown.icon.fontSize,
          }}
        />
      </span>
    </Dropdown>
  );
};

export default GlobalDropdown;
