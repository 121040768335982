import React, { useEffect } from 'react';

import { Message } from '../../../../models/Message';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Flex from '../../../../components/Flex';
import MessageCenterNavigationMenu from './MessageCenterNavigationMenu';
import { Divider, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setActiveMailFolder } from '../../actions/actionControllers/mailFoldersController';
import { getFavoriteProjectsWithMail } from '../../../projects/selectors';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { PrioTheme } from '../../../../theme/types';
import {
  fetchMailFoldersSagaAction,
  fetchMessagesSagaAction,
} from '../../actions/sagas';
import {
  deleteMessagesOfMailbox,
  fetchMessage,
} from '../../actions/actionControllers/messageActionController';
import {
  RootReducerState,
  getActiveMessageId,
  getActiveProjectInMessageCenter,
} from '../../../../apps/main/rootReducer';
import { MessageId } from '../../../../models/Types';

const navigationWidth = 300;

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: navigationWidth,
    overflow: 'hidden',
  },
  content: {
    overflow: 'hidden',
    padding: theme.old.components.mailFolderItem.spacing * 3,
  },
  triggerButtonContent: {
    padding: theme.old.spacing.defaultPadding,
    paddingTop: 0,
  },
  menu: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    background: 'transparent',
  },
  button: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'background-color 0.2s',
    padding: `0 ${theme.old.spacing.unit(3)}px`,
    margin: `0 -${theme.old.spacing.defaultPadding}px -${theme.old.spacing.defaultPadding}px`,
    fontSize: theme.old.typography.fontSize.base,
    height: theme.old.spacing.unit(8),
    width: `calc(100% + ${theme.old.spacing.defaultPadding * 2}px)`,
    textAlign: 'start',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#000',
    justifyContent: 'center',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.sub,
    },
    '& > svg': {
      color: theme.old.typography.colors.muted,
    },
  },
  buttonText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    paddingLeft: theme.old.spacing.unit(1.5),
  },
  divider: {
    margin: 0,
    borderColor: theme.old.borders.colors.sub,
  },
}));

interface MessageCenterNavigationProps {
  projectId: string;
  pathPrefix?: string;
  onSearchResult?: (messages: Message[]) => void;
  handleOpenSettings: (value: boolean) => void;
  openSettings: boolean;
}

export const MessageCenterNavigation: React.FC<MessageCenterNavigationProps> =
  React.memo((props) => {
    const classes = useStyles();
    const { projectId, pathPrefix, handleOpenSettings, openSettings } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const projectFavorites = useSelector(getFavoriteProjectsWithMail);

    const activeMessageId = useSelector<RootReducerState, MessageId>((state) =>
      getActiveMessageId(state, projectId)
    );

    const activeProjectId = useSelector(getActiveProjectInMessageCenter);

    const handleMailFoldersRefetch = () => {
      projectFavorites.forEach((project) => {
        dispatch(deleteMessagesOfMailbox(project.projectId));
        dispatch(fetchMailFoldersSagaAction(project.projectId, true, 'inbox'));
        dispatch(fetchMessagesSagaAction(project.projectId, 'inbox', true));
      });
      dispatch(deleteMessagesOfMailbox('me'));
      dispatch(fetchMailFoldersSagaAction('me', true, 'inbox'));
      dispatch(fetchMessagesSagaAction('me', 'inbox', true));
      if (activeProjectId && activeMessageId) {
        dispatch(fetchMessage(activeProjectId, activeMessageId));
      }
    };

    useEffect(() => {
      dispatch(setActiveMailFolder(projectId, 'inbox'));
    }, [projectId, dispatch]);

    return (
      <Flex.Column
        className={classNames('prio-module-navigation', classes.root)}
      >
        <Flex.Column flex={1} className={classes.content}>
          <Flex.Row alignItems="baseline">
            <Typography.Title style={{ flex: 1 }} ellipsis>
              {t('mail:messageCenter.navigation.title')}
            </Typography.Title>
            <Button
              type="link"
              shape="circle"
              onClick={() => handleMailFoldersRefetch()}
              iconProp={['fal', 'sync']}
            />
          </Flex.Row>
          <MessageCenterNavigationMenu
            projectId={projectId}
            pathPrefix={pathPrefix}
            className={classes.menu}
          />
        </Flex.Column>
        <Flex.Item className={classes.triggerButtonContent}>
          <Divider className={classes.divider} />
          <div
            className={classes.button}
            onClick={() => handleOpenSettings(!openSettings)}
          >
            <FontAwesomeIcon icon={['fal', 'cog']} />
            <div className={classes.buttonText}>
              {t('mail:settingsForm.title')}
            </div>
          </div>
        </Flex.Item>
      </Flex.Column>
    );
  });
export default MessageCenterNavigation;
