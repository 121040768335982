import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getEmailCachingEnabled,
  getCurrentSearchTerm,
  RootReducerState,
  getCurrentSearchTermState,
  getCurrentSearchMailFolderId,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { MailFolderId, ProjectId } from '../../../models/Types';
import { getFavoriteProjectsWithMail } from '../../projects/selectors';
import { fetchMessagesSagaAction } from '../actions/sagas';
import { AdvancedMailSearchDto } from '../../../models/MailSearch';

const searchTermSelector = (projectId: ProjectId, projects: Project[]) =>
  createSelector<
    [
      (state: RootReducerState) => string | AdvancedMailSearchDto,
      (state: RootReducerState) => {
        [projectId: ProjectId]: string | AdvancedMailSearchDto;
      },
    ],
    string | AdvancedMailSearchDto
  >(
    (state) =>
      projectId !== 'favorites' ? getCurrentSearchTerm(state, projectId) : null,
    (state) =>
      projectId === 'favorites' ? getCurrentSearchTermState(state) : null,
    (searchTerm, searchTermState) => {
      if (projectId !== 'favorites') {
        return searchTerm;
      }
      return projects.length > 0
        ? searchTermState[projects[0].projectId] ?? null
        : null;
    }
  );

const searchTermMailFolderIdSelector = (
  projectId: ProjectId,
  projectFavorites: Project[]
) =>
  createSelector<
    [
      (state: RootReducerState) => MailFolderId,
      (state: RootReducerState) => MailFolderId,
    ],
    MailFolderId
  >(
    (state) =>
      projectId !== 'favorites'
        ? getCurrentSearchMailFolderId(state, projectId)
        : null,
    (state) =>
      projectFavorites?.length > 0
        ? getCurrentSearchMailFolderId(state, projectFavorites[0].projectId)
        : null,
    (mailFolderId, favoritesMailFolderId) => {
      if (projectId !== 'favorites') {
        return mailFolderId;
      }
      if (favoritesMailFolderId && favoritesMailFolderId !== 'allFolders') {
        return 'inbox';
      }
      return favoritesMailFolderId;
    }
  );

declare type FetchMessagesHook = (
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => void;

const useFetchMessages: FetchMessagesHook = (projectId, mailFolderId) => {
  //#region ------------------------------ Defaults
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const cachingEnabled = useSelector(getEmailCachingEnabled);

  const projectFavorites = useSelector(getFavoriteProjectsWithMail);

  const searchTerm = useSelector(
    searchTermSelector(projectId, projectFavorites)
  );

  const searchTermMailFolderId = useSelector(
    searchTermMailFolderIdSelector(projectId, projectFavorites ?? [])
  );
  //#endregion

  //#region ------------------------------ Effects
  //favorites
  useEffect(() => {
    const fetchFavoriteMessages = async () => {
      projectFavorites.forEach((project) => {
        dispatch(fetchMessagesSagaAction(project.projectId, 'inbox', true));
      });
    };
    if (projectId === 'favorites') {
      fetchFavoriteMessages();
    }
  }, [projectId, projectFavorites, cachingEnabled, dispatch]);
  //favorites search
  useEffect(() => {
    const fetchFavoriteMessages = async () => {
      projectFavorites.forEach((project) => {
        dispatch(fetchMessagesSagaAction(project.projectId, 'inbox', true));
      });
    };
    if (searchTerm && projectId === 'favorites') {
      fetchFavoriteMessages();
    }
  }, [
    projectId,
    projectFavorites,
    cachingEnabled,
    searchTermMailFolderId,
    searchTerm,
    dispatch,
  ]);

  //projects
  useEffect(() => {
    if (!(projectId === 'me' || projectId === 'favorites')) {
      dispatch(fetchMessagesSagaAction(projectId, mailFolderId, true));
    }
  }, [mailFolderId, projectId, cachingEnabled, dispatch]);
  //projects search
  useEffect(() => {
    if (searchTerm && !(projectId === 'me' || projectId === 'favorites')) {
      dispatch(fetchMessagesSagaAction(projectId, mailFolderId, true));
    }
  }, [
    mailFolderId,
    projectId,
    searchTermMailFolderId,
    searchTerm,
    cachingEnabled,
    dispatch,
  ]);

  //me
  useEffect(() => {
    if (projectId === 'me') {
      dispatch(fetchMessagesSagaAction(projectId, mailFolderId, true));
    }
  }, [mailFolderId, projectId, dispatch]);
  //me search
  useEffect(() => {
    if (searchTerm && projectId === 'me') {
      dispatch(fetchMessagesSagaAction(projectId, mailFolderId, true));
    }
  }, [mailFolderId, projectId, searchTermMailFolderId, searchTerm, dispatch]);
  //#endregion
};

export default useFetchMessages;
