import React, { forwardRef } from 'react';
import { Divider, Select, Typography } from 'antd';
import Flex from './Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { RefSelectProps } from 'rc-select';
import { FilterFunc } from 'rc-select/lib/interface/generator';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface/index';
import { RotateProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  optionIcon: {},
  optionText: {},
  option: {},
  dropDownMenuDivider: {
    marginTop: 2,
    marginBottom: 0,
  },
  visibleSelectParent: {
    height: '100%',
  },
  visibleSelect: {
    height: '100%',
  },
}));

interface BasisPickerProps {
  className?: string;
  items?: BasisPickerSelectOptions[];
  multipleSelect?: boolean;
  disabled?: boolean;
  placeHolder?: object;
  dropdownRenderMenu?: JSX.Element;
  supressMenu?: boolean;
  defaultValue?: string | string[];
  value?: string | string[];
  allowClear?: boolean;
  suffixIcon?: JSX.Element;
  suffixIconFontAwesome?: [IconPrefix, IconName];
  loading?: boolean;
  open?: boolean;
  showSearch?: boolean;
  onChange?: (value: string | number | string[] | number[]) => void;
  onClear?: () => void;
  onBlur?: (focusEvent: React.FocusEvent<HTMLInputElement>) => void;
  onSelect?: (item: string | number) => void;
  onClick?: (
    mouseEvent: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  onFocus?: (fousEvent: React.FocusEvent<HTMLInputElement>) => void;
  optionFilterProp?: string;
  filterOption?: boolean | FilterFunc<OptionData | OptionGroupData>;
  renderItemLabelOnly?: boolean;
}

const getLabel = (item: BasisPickerSelectOptions) => {
  if (item?.title) {
    return item.title;
  }
  if (item?.label && typeof item?.label === 'string') {
    return item.label;
  }
  return null;
};

export interface BasisPickerSelectOptions {
  title?: string;
  isItalic?: boolean;
  key?: React.Key;
  value: string | number;
  label: React.ReactNode;
  icon?: {
    name: IconName;
    prefix?: IconPrefix;
    color?: string;
    rotation?: RotateProp;
  };
  extension?: JSX.Element | string;
}

export const BasisPicker = forwardRef<RefSelectProps, BasisPickerProps>(
  (props, ref) => {
    //#region -------------------------------- Variables
    const classes = useStyles();
    const {
      className,
      items,
      multipleSelect,
      disabled,
      placeHolder,
      dropdownRenderMenu,
      supressMenu,
      defaultValue,
      value,
      allowClear,
      suffixIcon,
      suffixIconFontAwesome,
      loading,
      open,
      optionFilterProp,
      showSearch,
      onChange,
      onClear,
      onBlur,
      onSelect,
      onClick,
      onFocus,
      filterOption,
      renderItemLabelOnly,
    } = props;
    //#endregion
    //#region -------------------------------- State declaration
    //#endregion
    //#region -------------------------------- Methods
    //#endregion
    //#region -------------------------------- Handle methods
    //#endregion
    //#region -------------------------------- Hooks
    //#endregion

    return (
      <Select
        showSearch={showSearch}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp ?? 'label'}
        ref={ref}
        open={open}
        loading={loading}
        allowClear={allowClear}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeHolder}
        mode={multipleSelect ? 'multiple' : null}
        onChange={onChange}
        onClear={onClear}
        onSelect={onSelect}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        className={classNames(classes.root, className)}
        suffixIcon={
          suffixIcon ? (
            suffixIcon
          ) : suffixIconFontAwesome ? (
            <FontAwesomeIcon icon={suffixIconFontAwesome} />
          ) : null
        }
        dropdownRender={
          dropdownRenderMenu &&
          ((menu) => (
            <div>
              {supressMenu ? null : menu}
              <Divider className={classes.dropDownMenuDivider} />
              {dropdownRenderMenu}
            </div>
          ))
        }
      >
        {items &&
          items.map((item) => (
            <Select.Option
              title={item.title}
              value={item.value}
              key={item.key && item.key}
              label={getLabel(item)}
            >
              {renderItemLabelOnly ? (
                item.label
              ) : (
                <Flex.Row
                  justifyContent="space-between"
                  alignItems="baseline"
                  className={classes.visibleSelectParent}
                >
                  <Flex.Row
                    childrenGap={8}
                    alignItems="center"
                    className={classes.visibleSelect}
                  >
                    {item.icon && (
                      <FontAwesomeIcon
                        rotation={item.icon.rotation}
                        color={item.icon.color}
                        className={classes.optionIcon}
                        icon={[
                          item.icon.prefix ? item.icon.prefix : 'fal',
                          item.icon.name,
                        ]}
                      />
                    )}
                    <Typography.Text
                      className={classes.optionText}
                      italic={item.isItalic}
                    >
                      {item.label}
                    </Typography.Text>
                  </Flex.Row>

                  {item.extension}
                </Flex.Row>
              )}
            </Select.Option>
          ))}
      </Select>
    );
  }
);

export default BasisPicker;
