import { Button, List, Popover, Tag, Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getCompanies,
  getCompany,
  getContact,
  getContacts,
} from '../../../apps/main/rootReducer';
import Flex from '../../Flex';
import { CompanyId, ContactId, DateTimeString } from '../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  tag: {
    position: 'relative',
    display: 'flex',
    boxSizing: 'border-box',
    maxWidth: '100%',
    height: 19.6,
    marginTop: 2,
    marginBottom: 2,
    background: '#f5f5f5',
    border: '1px solid #f0f0f0',
    borderRadius: 2,
    cursor: 'default',
    transition: 'font-size 0.3s, line-height 0.3s, height 0.3s',
    userSelect: 'none',
    marginInlineEnd: 3.2,
    paddingInlineStart: 8,
    paddingInlineEnd: 4,
    alignItems: 'center',
  },

  listItem: {
    '& > button': {
      visibility: 'hidden',
    },
    '&:hover > button': {
      visibility: 'visible',
    },
  },
  listItemText: {
    flex: 1,
  },
}));

interface Period {
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
}

export declare type UltimateFilterTagTagPopoverContentType =
  | 'contacts'
  | 'contact'
  | 'companies'
  | 'company'
  | 'period'
  | 'text'
  | 'textArray'
  | 'custom'
  | 'customArray'
  | 'none';

declare type KeyOfCollection<T> = keyof T;
export interface CustomLabel<T> {
  renderLabel?: (elementValue: T) => string;
  renderLabelInArray?: (elementValue: T[KeyOfCollection<T>]) => string;
  renderLabelInObject?: (
    key: KeyOfCollection<T>,
    elementValue: T[KeyOfCollection<T>]
  ) => string;
}

interface UltimateFilterTagProps<T> {
  className?: string[];
  keyName: string;
  contentType?: {
    type: UltimateFilterTagTagPopoverContentType;
    customLabel: CustomLabel<T>;
    value?: T;
    customRender?: (value: any) => JSX.Element;
  };
  label?: string;
  popOverLabel?: string;

  onTagClose: (keyName: string) => void;
  onDeselectTagListItem?: (keyname: string, value: string) => void;
}

export function UltimateFilterTag<T>({
  className,
  keyName,
  contentType,
  popOverLabel,
  label,
  onTagClose: onTagCloseFromParent,
  onDeselectTagListItem,
}: UltimateFilterTagProps<T>) {
  const classes = useStyles();

  const onTagClose = (keyTagName: string) => {
    onTagCloseFromParent?.(keyTagName);
  };

  const renderContent = () => {
    if (!contentType) return null;
    var values = contentType?.value;

    switch (contentType.type) {
      case 'contacts':
        return (
          <PopoverContactsContent
            renderLabelInArray={contentType?.customLabel?.renderLabelInArray}
            contactIds={values as string[]}
            onDeselectContactTag={(value) =>
              onDeselectTagListItem(keyName, value)
            }
          />
        );

      case 'contact': {
        return (
          <PopoverContactContent
            renderLabel={contentType?.customLabel?.renderLabel}
            contactId={values as string}
          />
        );
      }

      case 'companies':
        return (
          <PopoverCompaniesContent
            renderLabelInArray={contentType?.customLabel?.renderLabelInArray}
            companyIds={values as string[]}
            onDeselectCompanyTag={(value) =>
              onDeselectTagListItem(keyName, value)
            }
          />
        );

      case 'company':
        return (
          <PopoverCompanyContent
            renderLabel={contentType?.customLabel?.renderLabel}
            companyId={values as string}
          />
        );

      case 'text':
        return (
          <PopoverTextContent
            renderLabel={contentType?.customLabel?.renderLabel}
            text={values as string}
          />
        );

      case 'textArray':
        return (
          <PopoverTextArrayContent
            renderLabelInArray={contentType?.customLabel?.renderLabelInArray}
            elements={values as string[]}
            onDeselectElementTag={(value) =>
              onDeselectTagListItem(keyName, value)
            }
          />
        );

      case 'period': {
        return (
          <PopoverPeriodContent
            renderLabelInObject={contentType?.customLabel?.renderLabelInObject}
            period={values as Period}
            onDeselectDateTag={(value) => onDeselectTagListItem(keyName, value)}
          />
        );
      }

      case 'custom':
        return (
          <PopoverCustomContent
            value={values}
            customRender={contentType?.customRender}
          />
        );

      case 'customArray':
        return (
          <PopoverCustomArrayContent
            elements={values as any[]}
            customRender={contentType?.customRender}
            onDeselectElementTag={(value) =>
              onDeselectTagListItem(keyName, value)
            }
          />
        );

      default:
        return null;
    }
  };

  const noPopOver = contentType.type === 'none';

  if (noPopOver) {
    return (
      <Tooltip key={keyName} overlay={popOverLabel}>
        <Tag
          key={keyName}
          className={classes.tag}
          closable
          onClose={() => onTagClose(keyName)}
        >
          {label ?? ''}
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Popover
      key={keyName}
      className={classnames(classes.root, className)}
      zIndex={1060}
      title={label ?? ''}
      placement="bottom"
      destroyTooltipOnHide
      content={renderContent()}
    >
      <Tag
        key={keyName}
        className={classes.tag}
        closable
        onClose={() => onTagClose(keyName)}
      >
        {label ?? ''}
      </Tag>
    </Popover>
  );
}

interface PopoverContactContentProps {
  contactId: ContactId;
  renderLabel?: CustomLabel<any>['renderLabel'];
}

const PopoverContactContent: React.FC<PopoverContactContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { contactId, renderLabel } = props;
  const classes = useStyles();
  const contact = useSelector((state: RootReducerState) =>
    getContact(state, contactId)
  );
  //#endregion

  return (
    <Flex.Row>
      <Typography.Text ellipsis className={classes.listItemText}>
        {renderLabel
          ? renderLabel(contact)
          : `${contact.firstName} ${contact.lastName}`}
      </Typography.Text>
    </Flex.Row>
  );
};

interface PopoverContactsContentProps {
  contactIds: ContactId[];
  renderLabelInArray?: CustomLabel<any[]>['renderLabelInArray'];
  onDeselectContactTag?: (value: ContactId) => void;
}

const PopoverContactsContent: React.FC<PopoverContactsContentProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { contactIds, onDeselectContactTag, renderLabelInArray } = props;
  const classes = useStyles();
  const contacts = useSelector((state: RootReducerState) =>
    getContacts(state, contactIds)
  );
  //#endregion

  return (
    <Flex.Column>
      <List
        dataSource={contacts}
        renderItem={(contact) => (
          <List.Item key={contact.contactId} className={classes.listItem}>
            <Typography.Text ellipsis className={classes.listItemText}>
              {renderLabelInArray
                ? renderLabelInArray(contact)
                : `${contact.firstName} ${contact.lastName}`}
            </Typography.Text>
            <Button
              shape="circle"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeselectContactTag(contact?.contactId);
              }}
              icon={<FontAwesomeIcon icon={['fal', 'times-circle']} />}
            />
          </List.Item>
        )}
      />
    </Flex.Column>
  );
};

interface PopoverCompanyContentProps {
  companyId: CompanyId;
  renderLabel?: CustomLabel<any>['renderLabel'];
}

const PopoverCompanyContent: React.FC<PopoverCompanyContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { companyId, renderLabel } = props;
  const classes = useStyles();
  const company = useSelector((state: RootReducerState) =>
    getCompany(state, companyId)
  );
  //#endregion

  return (
    <Flex.Row>
      <Typography.Text ellipsis className={classes.listItemText}>
        {renderLabel ? renderLabel(company) : `${company.fullName}`}
      </Typography.Text>
    </Flex.Row>
  );
};
interface PopoverCompaniesContentProps {
  companyIds: CompanyId[];
  renderLabelInArray?: CustomLabel<any[]>['renderLabelInArray'];
  onDeselectCompanyTag?: (value: CompanyId) => void;
}

const PopoverCompaniesContent: React.FC<PopoverCompaniesContentProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { companyIds, onDeselectCompanyTag, renderLabelInArray } = props;
  const classes = useStyles();
  const companies = useSelector((state: RootReducerState) =>
    getCompanies(state, companyIds)
  );
  //#endregion

  return (
    <Flex.Column>
      <List
        dataSource={companies}
        renderItem={(company) => (
          <List.Item key={company.companyId} className={classes.listItem}>
            <Typography.Text ellipsis className={classes.listItemText}>
              {renderLabelInArray
                ? renderLabelInArray(company)
                : `${company.fullName}`}
            </Typography.Text>
            <Button
              shape="circle"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeselectCompanyTag(company?.companyId);
              }}
              icon={<FontAwesomeIcon icon={['fal', 'times-circle']} />}
            />
          </List.Item>
        )}
      />
    </Flex.Column>
  );
};

interface PopoverPeriodContentProps {
  period: Period;
  onDeselectDateTag: (value: string) => void;
  renderLabelInObject?: CustomLabel<any>['renderLabelInObject'];
}

const PopoverPeriodContent: React.FC<PopoverPeriodContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { period, onDeselectDateTag, renderLabelInObject } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  //#endregion

  return (
    <Flex.Column>
      <List>
        {period?.dateFrom && (
          <List.Item key={'dateFrom'} className={classes.listItem}>
            <Typography.Text ellipsis className={classes.listItemText}>
              {renderLabelInObject
                ? renderLabelInObject('dateFrom', period?.dateFrom)
                : t(`mail:mailListFilter.period.dateFrom`, {
                    date: moment(period?.dateFrom).format('DD.MM.YYYY'),
                  })}
            </Typography.Text>
            <Button
              shape="circle"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeselectDateTag('dateFrom');
              }}
              icon={<FontAwesomeIcon icon={['fal', 'times-circle']} />}
            />
          </List.Item>
        )}
        {period?.dateTo && (
          <List.Item key={'dateTo'} className={classes.listItem}>
            <Typography.Text ellipsis className={classes.listItemText}>
              {renderLabelInObject
                ? renderLabelInObject('dateTo', period?.dateTo)
                : t(`mail:mailListFilter.period.dateTo`, {
                    date: moment(period?.dateTo).format('DD.MM.YYYY'),
                  })}
            </Typography.Text>
            <Button
              shape="circle"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeselectDateTag('dateTo');
              }}
              icon={<FontAwesomeIcon icon={['fal', 'times-circle']} />}
            />
          </List.Item>
        )}
      </List>
    </Flex.Column>
  );
};

interface PopoverTextContentProps {
  text: string;
  renderLabel?: CustomLabel<any>['renderLabel'];
}

const PopoverTextContent: React.FC<PopoverTextContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { text, renderLabel } = props;
  const classes = useStyles();

  //#endregion

  return (
    <Flex.Row>
      <Typography.Text ellipsis className={classes.listItemText}>
        {renderLabel ? renderLabel(text) : text}
      </Typography.Text>
    </Flex.Row>
  );
};

interface PopoverTextArrayContentProps {
  elements: string[];
  renderLabelInArray?: CustomLabel<any[]>['renderLabelInArray'];
  onDeselectElementTag?: (value: string) => void;
}

const PopoverTextArrayContent: React.FC<PopoverTextArrayContentProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { elements, onDeselectElementTag, renderLabelInArray } = props;
  const classes = useStyles();
  //#endregion

  return (
    <Flex.Column>
      <List
        dataSource={elements}
        renderItem={(element, index) => (
          <List.Item key={index} className={classes.listItem}>
            <Typography.Text ellipsis className={classes.listItemText}>
              {renderLabelInArray ? renderLabelInArray(element) : element}
            </Typography.Text>
            <Button
              shape="circle"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeselectElementTag(element);
              }}
              icon={<FontAwesomeIcon icon={['fal', 'times-circle']} />}
            />
          </List.Item>
        )}
      />
    </Flex.Column>
  );
};

interface PopoverCustomContentProps {
  value: any;
  customRender?: UltimateFilterTagProps<any>['contentType']['customRender'];
}

const PopoverCustomContent: React.FC<PopoverCustomContentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { value, customRender } = props;

  //#endregion

  return <Flex.Row>{customRender(value)}</Flex.Row>;
};

interface PopoverCustomArrayContentProps {
  elements: any[];
  customRender?: UltimateFilterTagProps<any>['contentType']['customRender'];
  onDeselectElementTag?: (value: string) => void;
}

const PopoverCustomArrayContent: React.FC<PopoverCustomArrayContentProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { elements, onDeselectElementTag, customRender } = props;
  const classes = useStyles();
  //#endregion

  return (
    <Flex.Column>
      <List
        dataSource={elements}
        renderItem={(element, index) => (
          <List.Item key={index} className={classes.listItem}>
            {customRender(element)}
            <Button
              shape="circle"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeselectElementTag(element);
              }}
              icon={<FontAwesomeIcon icon={['fal', 'times-circle']} />}
            />
          </List.Item>
        )}
      />
    </Flex.Column>
  );
};
