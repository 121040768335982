import React, { useCallback, useState } from 'react';
import { InvoicePayment } from '../../../models/Accounting';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { Divider, Modal, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import {
  apiCreateInvoicePayment,
  apiDeleteInvoice,
  apiFetchInvoice,
} from '../api';
import { OfficeId, ProjectId } from '../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import {
  InvoicesSearchResult,
  InvoicesSearchResultCalculatedData,
  SearchResultInvoicesPage,
} from './InvoicesTableNew';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import InvoicePaymentFormNew from './InvoicePaymentFormNew';
import InvoicePaymentDetailsNew from './InvoicePaymentDetailsNew';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  newPaymentForm: {
    marginTop: theme.old.spacing.unit(3),
  },
  scrollable: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  deleteInvoiceButton: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
    },
  },
}));

interface InvoiceDetailsPaymentsProps {
  invoice: SearchResultInvoicesPage;
  reloadInvoices: VoidFunction;
  officeId?: OfficeId;
  projectId?: ProjectId;
  handleDrawerClose: () => void;
}

export const InvoiceDetailsPayments: React.FC<InvoiceDetailsPaymentsProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { invoice, reloadInvoices, projectId, officeId, handleDrawerClose } =
    props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [invoicePayments, setInvoicePayments] = useState<InvoicePayment[]>(
    invoice.data.invoicePayments
  );

  const [isDeleteInvoiceModalVisible, setIsDeleteInvoiceModalVisible] =
    useState<boolean>(false);

  const { optimisticWrite } = useFilterContext<
    InvoicesSearchResult,
    InvoicesSearchResultCalculatedData
  >();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleNewInvoicePaymentOptimistic = (payment: InvoicePayment) => {
    optimisticWrite([
      {
        data: invoice.data,
        method: 'update',
        callback: async () => {
          const { result } = await apiCreateInvoicePayment(
            payment,
            officeId,
            projectId
          );
          if (result.ok) {
            const { result, data: currentInvoice } = await apiFetchInvoice(
              invoice.data.invoiceId,
              officeId,
              projectId
            );
            if (result.ok) {
              setInvoicePayments(currentInvoice.invoicePayments);
              return {
                result,
                data: {
                  data: currentInvoice,
                  calculated: {
                    ...invoice.calculated,
                    paidBalance: currentInvoice.invoicePayments.reduce(
                      (sum, payment) => sum + payment.debitSum.value,
                      0
                    ),
                  },
                },
              };
            }
          }
          return {
            result: new Response(
              JSON.stringify({
                error: true,
                message: t('accounting:errorMessages.createInvoicePayment'),
              })
            ),
            data: invoice,
          };
        },
      },
    ]);
  };

  const deleteInvoice = () => {
    optimisticWrite([
      {
        data: invoice.data,
        method: 'remove',
        callback: async () => {
          const { result } = await apiDeleteInvoice(
            invoice.data.invoiceId,
            officeId,
            projectId
          );
          return {
            result,
            data: null,
          };
        },
      },
    ]);
  };

  const handleInvoiceDelete = () => {
    deleteInvoice();
    handleDrawerClose();
  };

  const showDeleteInvoiceModal = () => {
    setIsDeleteInvoiceModalVisible(true);
  };
  const deleteInvoiceModalOkAction = () => {
    setIsDeleteInvoiceModalVisible(false);
    handleInvoiceDelete();
  };
  const deleteInvoiceModalCancelAction = () => {
    setIsDeleteInvoiceModalVisible(false);
  };

  const getRemainingAmount = useCallback(() => {
    if (invoice) {
      const { grossTotalSum } = invoice.calculated;
      if (grossTotalSum) {
        const paymentsSum = invoicePayments.reduce(
          (sum, payment) => sum + payment.debitSum.value,
          0
        );
        return grossTotalSum - paymentsSum;
      }
    }
    return 0;
  }, [invoice, invoicePayments]);
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classes.root}>
      <Flex.Column
        childrenGap={theme.old.spacing.unit(2)}
        className={classes.fullHeight}
      >
        <div className={classes.scrollable}>
          {invoicePayments.length > 0 ? (
            invoicePayments.map((p) => (
              <InvoicePaymentDetailsNew
                invoice={invoice}
                payment={p}
                key={p.invoicePaymentId}
                reloadInvoices={reloadInvoices}
                invoiceId={invoice?.data.invoiceId}
                projectId={projectId}
                officeId={officeId}
                setInvoicePayments={setInvoicePayments}
              />
            ))
          ) : (
            <Flex.Column>
              <Typography.Text>
                {t('accounting:invoiceDetails.noPayments')}
              </Typography.Text>
              <Divider />
            </Flex.Column>
          )}
        </div>
        <Flex.Item flex={1}>
          <Typography.Title level={2}>
            {t('accounting:invoiceDetails.newPayment')}
          </Typography.Title>
          <InvoicePaymentFormNew
            className={classes.newPaymentForm}
            onFinish={handleNewInvoicePaymentOptimistic}
            actionLabel={t('accounting:paymentForm.actions.create')}
            invoiceId={invoice?.data.invoiceId}
            isoCode={invoice?.calculated.currency}
            amount={getRemainingAmount()}
          />
        </Flex.Item>
        <Flex.Row>
          <Button
            onClick={showDeleteInvoiceModal}
            type="link"
            className={classes.deleteInvoiceButton}
          >
            {t('accounting:invoiceDetails.deleteInvoiceAction')}
          </Button>
        </Flex.Row>
      </Flex.Column>
      <Modal
        visible={isDeleteInvoiceModalVisible}
        onOk={() => deleteInvoiceModalOkAction()}
        onCancel={() => deleteInvoiceModalCancelAction()}
        title={t('accounting:invoiceDetails.deleteInvoiceModal.title')}
        okText={t('accounting:invoiceDetails.deleteInvoiceModal.okText')}
        cancelText={t(
          'accounting:invoiceDetails.deleteInvoiceModal.cancelText'
        )}
      >
        {[
          t('accounting:invoiceDetails.deleteInvoiceModal.description', {
            invoiceNumber: invoice?.data.number,
          }),
        ]}
      </Modal>
    </div>
  );
};

export default InvoiceDetailsPayments;
