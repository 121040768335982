import { put } from 'redux-saga/effects';
import { WebSocketMessage } from '../../../models/WebSocket';
import { wsArchiveUnzipped } from '../actions/ws';
import { DriveItem } from '../../../models/Drive';
import { Project } from '../../../models/Project';
import { notification } from 'antd';
import i18n from '../../../i18n';
import UnzipSuccessNotification from '../components/Notifications/UnzipSuccessNotification';

function* handleArchiveUnzipped(message: WebSocketMessage, project: Project) {
  if (message.object && message.projectId) {
    yield put(
      wsArchiveUnzipped(
        message.object,
        message.projectId,
        (message.object as DriveItem).id,
        project.groupId
      )
    );
    const key = `open${Date.now()}`;
    notification.open({
      message: i18n.t('common:archive:unzipSuccess'),
      description: (
        <UnzipSuccessNotification
          driveItem={message.object}
          notificationKey={key}
          project={project}
        />
      ),
      key,
      duration: 10,
    });
  } else {
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t(
        'documents:newDocumentForm.errorMessages.createDocumentError'
      ),
    });
  }
}

export default handleArchiveUnzipped;
