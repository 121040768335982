import { ApiResult, apiUrl } from '../../../api';
import { User } from '../../../models/User';
import { NewUserLicence, UserLicence } from '../../../models/UserLicence';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiValidateEmail: (
  email: string
) => Promise<ApiResult<any>> = async (email) => {
  const result = await fetchWithRetry(
    `${apiUrl}/user/User/validateNewUserEmail?email=${encodeURI(email)}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? null : await result.json();
  return {
    result,
    data,
  };
};

export const apiImportAadUsers: (
  azureADIds: string[]
) => Promise<ApiResult<void>> = async (azureADIds) => {
  const result = await fetchWithRetry(`${apiUrl}/user/User/importAADUser`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(
      azureADIds.map((azureADId) => ({
        azureADId,
        globalRoles: ['globalEmployee'],
      }))
    ),
  });
  return {
    result,
  };
};

export const apiFetchImportableUsers: (
  searchInput?: string
) => Promise<ApiResult<User[]>> = async (searchInput) => {
  const result = await fetchWithRetry(
    `${apiUrl}/user/User/getAADUser?excludeInternalContacts=true${
      searchInput ? `&searchString=${searchInput}` : ''
    }`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchUserLicences: (
  officeId: string | null
) => Promise<UserLicence[]> = async (officeId) => {
  const url = officeId
    ? `${apiUrl}/user/userlicence/office/${officeId}/licences`
    : `${apiUrl}/user/userlicence/licences`;

  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  return result.json();
};

export const apiCreateLicencesOfActiveUsers: () => Promise<
  UserLicence[]
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/user/userlicence/createLicencesOfActiveUsers`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  return result.json();
};

export const apiUpdateLicences: (
  officeId: string | null,
  licences: NewUserLicence[]
) => Promise<UserLicence[]> = async (officeId, licences) => {
  const url = officeId
    ? `${apiUrl}/user/userlicence/office/${officeId}`
    : `${apiUrl}/user/userlicence`;

  const result = await fetchWithRetry(url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(licences),
  });
  return result.json();
};
