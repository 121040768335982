import React from 'react';
import ProjectCardSkeleton from './ProjectCardSkeleton';
import Flex from '../../../components/Flex';

export const ProjectListSkeleton: React.FC = () => {
  return (
    <Flex.Row>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <ProjectCardSkeleton key={index} />
        ))}
    </Flex.Row>
  );
};

export default ProjectListSkeleton;
