import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@prio365/prio365-react-library';
import {
  MessageAttachment,
  MessageDriveItemMap,
} from '../../../models/Message';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ProjectId } from '../../../models/Types';
import Flex from '../../../components/Flex';
import classNames from 'classnames';
import SelectableMessageAttachmentItem from './SelectableMessageAttachmentItem';
import { apiDeleteAttachment, apiFetchSavedAttachmentMetadata } from '../api';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useSelector } from 'react-redux';
import { getMailSettings } from '../../../apps/main/rootReducer';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
  },
  attachmentListButton: {
    '&.ant-btn': {
      padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(
        1
      )}px`,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  attachmentsRoot: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  attachments: {
    overflowY: 'scroll',
    display: 'grid',
    gridTemplateColumns: 'repeat(3,minmax(0,1fr))',
    padding: `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(
      1
    )}px ${theme.old.spacing.unit(2)}px`,
    paddingTop: 0,
    paddingBottom: 0,
    '& > div': {
      overflow: 'hidden',
    },
  },
  messageAttachmentItem: {
    width: '100%',
    whiteSpace: 'nowrap',
  },
}));

interface MessageDriveItemMapByAttachmentId {
  [attachmentId: string]: MessageDriveItemMap[];
}

interface MessageAttachmentListProps {
  className?: string;
  selectedMessageAttachmentIds?: string[];
  setSelectedMessageAttachmentIds?: (values: string[]) => void;
  messageAttachments?: MessageAttachment[];
  onSelectionChange?: (e: CheckboxChangeEvent) => void;
  onDownloadAttachment: (messageAttachment: MessageAttachment) => Promise<void>;
  messageId?: string;
  onSelectionChangeAll?: (values: string[]) => void;
  openAttachmentDrawer?: VoidFunction;
  downloadAll?: VoidFunction;
  isMe?: boolean;
  projectId?: ProjectId;
  attachmentsCollapsed?: boolean;
  onDeleteAttachment?: (attachmentId: string) => Promise<void>;
  receivedMessage: boolean;
  onSetSelectedMessageAttachmentIds?: (values: string[]) => void;
  onSetMessageAttachments?: (values: MessageAttachment[]) => void;
  messageAttachmentsIncludingHiddenAttachments?: MessageAttachment[];
}

export const MessageAttachmentList: React.FC<MessageAttachmentListProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    selectedMessageAttachmentIds,
    setSelectedMessageAttachmentIds,
    messageAttachments,
    onSelectionChange,
    onDownloadAttachment,
    messageId,
    onSelectionChangeAll,
    openAttachmentDrawer,
    downloadAll,
    isMe,
    projectId,
    attachmentsCollapsed,
    onDeleteAttachment,
    receivedMessage,
    onSetSelectedMessageAttachmentIds,
    onSetMessageAttachments,
    messageAttachmentsIncludingHiddenAttachments,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const hiddenAttachmentsAvailable =
    messageAttachmentsIncludingHiddenAttachments?.length !==
    messageAttachments?.length;

  const [hiddenAttachmentsVisible, setHiddenAttachmentsVisible] =
    useState<boolean>(false);

  const [displayedMessageAttachments, setDisplayedMessageAttachments] =
    useState<MessageAttachment[]>([]);

  const allChecked =
    displayedMessageAttachments?.length > 0
      ? selectedMessageAttachmentIds?.length ===
        displayedMessageAttachments?.length
      : false;

  const selectedMessageAttachments = displayedMessageAttachments?.filter(
    (attachment) => selectedMessageAttachmentIds.includes(attachment.id)
  );

  const mailSettings = useSelector(getMailSettings);

  const [
    savedAttachmentsMetadataByAttachmentId,
    setSavedAttachmentsMetadataByAttachmentId,
  ] = useState<MessageDriveItemMapByAttachmentId>({});
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleCheckAll = () => {
    if (
      selectedMessageAttachmentIds?.length < displayedMessageAttachments?.length
    ) {
      onSelectionChangeAll(
        displayedMessageAttachments?.map((attachment) => attachment.id)
      );
    } else {
      onSelectionChangeAll([]);
    }
  };

  const deleteSelected = async (projectId, messageId) => {
    const deleteAttachmentPromise = async (
      element: string,
      previousSuccessedAttachmentIds: string[]
    ) => {
      const { result } = await apiDeleteAttachment(
        projectId,
        messageId,
        element
      );
      if (result.status >= 200 && result.status < 300) {
        return [...previousSuccessedAttachmentIds, element];
      }
      return previousSuccessedAttachmentIds;
    };

    const promiseChainFunction = () =>
      selectedMessageAttachmentIds.reduce(
        (currentPromiseChain, attachment) =>
          currentPromiseChain.then((resultArray) =>
            deleteAttachmentPromise(attachment, resultArray)
          ),
        Promise.resolve([] as string[])
      );
    const succededIds = await promiseChainFunction();

    onSetMessageAttachments(
      displayedMessageAttachments?.filter(
        (element) => !succededIds.includes(element.id)
      )
    );
    onSetSelectedMessageAttachmentIds([]);
  };

  const showHiddenAttachments = () => {
    setHiddenAttachmentsVisible(!hiddenAttachmentsVisible);
    setSelectedMessageAttachmentIds([]);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setDisplayedMessageAttachments(messageAttachments);
  }, [messageAttachments]);

  useEffect(() => {
    setDisplayedMessageAttachments(
      hiddenAttachmentsVisible
        ? messageAttachmentsIncludingHiddenAttachments
        : messageAttachments
    );
  }, [
    hiddenAttachmentsVisible,
    messageAttachmentsIncludingHiddenAttachments,
    messageAttachments,
  ]);

  useEffect(() => {
    const fetchSavedAttachmentMetadata = async () => {
      const { data } = await apiFetchSavedAttachmentMetadata([messageId]);
      if (data) {
        const byAttachmentId = data.reduce<MessageDriveItemMapByAttachmentId>(
          (acc, current) => {
            let item = { ...current };
            if (item.projectId === '') {
              return acc;
            }
            if (!acc[item.attachmentId]) {
              return {
                ...acc,
                [item.attachmentId]: [item],
              };
            }

            return {
              ...acc,
              [item.attachmentId]: [...acc[item.attachmentId], item],
            };
          },
          {}
        );
        setSavedAttachmentsMetadataByAttachmentId(byAttachmentId);
      }
    };
    fetchSavedAttachmentMetadata();
  }, [messageId]);
  //#endregion

  if (displayedMessageAttachments?.length > 0 || hiddenAttachmentsAvailable) {
    return (
      <>
        <div className={classes.root}>
          <Flex.Row
            padding={
              mailSettings.mailListSpacing === 'tight'
                ? `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(
                    2
                  )}px`
                : `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(
                    2.5
                  )}px`
            }
            paddingBottom={0}
            alignItems="baseline"
            childrenGap={theme.old.spacing.unit(1)}
          >
            <Checkbox
              indeterminate={
                selectedMessageAttachmentIds?.length > 0 &&
                selectedMessageAttachmentIds?.length <
                  displayedMessageAttachments?.length
              }
              checked={allChecked}
              onChange={handleCheckAll}
            />
            {isMe ? (
              <>
                <Button
                  type={'link'}
                  disabled={selectedMessageAttachmentIds?.length === 0}
                  className={classes.attachmentListButton}
                  onClick={downloadAll}
                >
                  {t('mail:messageDisplay.downloadSelectedAttachments', {
                    selected: selectedMessageAttachmentIds?.length ?? 0,
                    amount: displayedMessageAttachments?.length ?? 0,
                  })}
                </Button>
                <Button
                  type="link"
                  className={classes.attachmentListButton}
                  onClick={openAttachmentDrawer}
                  disabled={selectedMessageAttachmentIds?.length === 0}
                >
                  {t('mail:messageDisplay.saveSelectedAttachments', {
                    selected: selectedMessageAttachmentIds?.length ?? 0,
                    amount: displayedMessageAttachments?.length ?? 0,
                  })}
                </Button>
                {!receivedMessage && (
                  <Button
                    type="link"
                    className={classes.attachmentListButton}
                    onClick={() => deleteSelected(projectId, messageId)}
                    disabled={selectedMessageAttachmentIds?.length === 0}
                  >
                    {t('mail:messageDisplay.deleteSelectedAttachments', {
                      selected: selectedMessageAttachmentIds?.length ?? 0,
                      amount: displayedMessageAttachments?.length ?? 0,
                    })}
                  </Button>
                )}
                {receivedMessage && hiddenAttachmentsAvailable && (
                  <Button
                    type="link"
                    className={classes.attachmentListButton}
                    onClick={showHiddenAttachments}
                  >
                    {t(
                      `mail:messageDisplay.${
                        hiddenAttachmentsVisible ? 'hide' : 'show'
                      }HiddenAttachments`
                    )}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Button
                  className={classes.attachmentListButton}
                  disabled={selectedMessageAttachmentIds?.length === 0}
                  onClick={downloadAll}
                  type="link"
                >
                  {t('mail:messageDisplay.downloadSelectedAttachments', {
                    selected: selectedMessageAttachmentIds?.length ?? 0,
                    amount: displayedMessageAttachments?.length ?? 0,
                  })}
                </Button>
                <Button
                  type="link"
                  className={classes.attachmentListButton}
                  onClick={openAttachmentDrawer}
                  disabled={selectedMessageAttachmentIds?.length === 0}
                >
                  {t('mail:messageDisplay.saveSelectedAttachments', {
                    selected: selectedMessageAttachmentIds?.length ?? 0,
                    amount: displayedMessageAttachments?.length ?? 0,
                  })}
                </Button>
                {!receivedMessage && (
                  <Button
                    type="link"
                    className={classes.attachmentListButton}
                    onClick={() => deleteSelected(projectId, messageId)}
                    disabled={selectedMessageAttachmentIds?.length === 0}
                  >
                    {t('mail:messageDisplay.deleteSelectedAttachments', {
                      selected: selectedMessageAttachmentIds?.length ?? 0,
                      amount: displayedMessageAttachments?.length ?? 0,
                    })}
                  </Button>
                )}
                {receivedMessage && hiddenAttachmentsAvailable && (
                  <Button
                    type="link"
                    className={classes.attachmentListButton}
                    onClick={() => showHiddenAttachments()}
                  >
                    {t(
                      `mail:messageDisplay.${
                        hiddenAttachmentsVisible ? 'hide' : 'show'
                      }HiddenAttachments`
                    )}
                  </Button>
                )}
              </>
            )}
          </Flex.Row>
        </div>
        <div
          className={classNames(classes.attachmentsRoot)}
          style={attachmentsCollapsed ? { maxHeight: 48 } : null}
        >
          <Flex.Row className={classes.attachments} columnGap={8}>
            {displayedMessageAttachments?.map((messageAttachment, index) => (
              <Flex.Item flex={1} className={classes.messageAttachmentItem}>
                <SelectableMessageAttachmentItem
                  key={messageAttachment.id + index}
                  messageAttachment={messageAttachment}
                  messageAttachments={displayedMessageAttachments}
                  onSelectionChange={onSelectionChange}
                  onDownloadAttachment={onDownloadAttachment}
                  messageId={messageId}
                  selected={selectedMessageAttachmentIds.includes(
                    messageAttachment.id
                  )}
                  selectedAttachments={selectedMessageAttachments ?? []}
                  isDropdown={false}
                  projectId={projectId}
                  onDeleteAttachment={onDeleteAttachment}
                  receivedMessage={receivedMessage}
                  savedAttachmentsMetadata={
                    savedAttachmentsMetadataByAttachmentId[messageAttachment.id]
                  }
                />
              </Flex.Item>
            ))}
          </Flex.Row>
        </div>
      </>
    );
  }
  return null;
};

export default MessageAttachmentList;
