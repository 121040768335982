import { notification } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateGlobalProjectSetting,
  GlobalProjectSetting,
} from '../../../models/Configuration';
import {
  GlobalProjectSettingId,
  GlobalProjectSettingType,
} from '../../../models/Types';
import {
  apiArchiveGlobalProjectSetting,
  apiCreateGlobalProjectSetting,
  apiFetchGlobalProjectSetting,
  apiSetDefaultGlobalProjectSetting,
  apiUpdateGlobalProjectSetting,
} from '../api';
import { asyncForEach } from '../../../util';
import equals from 'deep-equal';

export const useGlobalProjectSettings = (type: GlobalProjectSettingType) => {
  const { t } = useTranslation();

  const [settings, setSettings] = useState<GlobalProjectSetting[] | null>(null);
  const [updatedSettings, setUpdatedSettings] =
    useState<GlobalProjectSetting[]>(null);
  const [settingsSaving, setSettingsSaving] = useState<boolean>(false);

  const createSetting = async (newSetting: CreateGlobalProjectSetting) => {
    const { data } = await apiCreateGlobalProjectSetting(type, {
      displayName: newSetting.displayName,
      objectJson: { value: newSetting.value },
    });
    if (data) {
      setSettings([...settings, data]);
      if (updatedSettings) setUpdatedSettings([...updatedSettings, data]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.createSettingsError'),
      });
    }
  };

  const saveSettings = async () => {
    setSettingsSaving(true);
    if (updatedSettings !== null) {
      await asyncForEach(updatedSettings, async (updatedSetting) => {
        const originalSetting: GlobalProjectSetting = settings.find(
          (setting) =>
            setting.globalProjectSettingId ===
            updatedSetting.globalProjectSettingId
        );
        if (!equals(updatedSetting, originalSetting)) {
          await saveUpdateSetting(updatedSetting);
        }
      });
    }
    setSettingsSaving(false);
  };

  const saveUpdateSetting = async (updatedSetting: GlobalProjectSetting) => {
    const { data } = await apiUpdateGlobalProjectSetting(type, updatedSetting);
    if (data) {
      setSettings([
        ...settings.map((setting) =>
          setting.globalProjectSettingId ===
          updatedSetting.globalProjectSettingId
            ? data
            : setting
        ),
      ]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.updateSettingsError'),
      });
    }
  };

  const deleteSetting = async (settingId: GlobalProjectSettingId) => {
    const { result } = await apiArchiveGlobalProjectSetting(
      'projectDocumentPrefix',
      settingId
    );
    if (result.status === 204) {
      var settingArray = [...settings]; // make a separate copy of the array
      var index = settingArray.findIndex(
        (value) => value.globalProjectSettingId === settingId
      );
      if (index !== -1) {
        settingArray.splice(index, 1);
        setSettings(settingArray);
      }
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.archiveSettingsError'),
      });
    }
  };

  const setDefaultSetting = async (settingId: GlobalProjectSettingId) => {
    const { result } = await apiSetDefaultGlobalProjectSetting(settingId);

    if (result.status === 204) {
      let settingsCopy = settings.map((x) => ({ ...x, isDefault: false }));

      const settingIndex = settingsCopy.findIndex(
        (setting) => setting.globalProjectSettingId === settingId
      );

      const settingFound = settingIndex !== -1;
      if (settingFound) {
        settingsCopy[settingIndex] = {
          ...settingsCopy[settingIndex],
          isDefault: true,
        };

        setSettings(settingsCopy);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('settings:errorMessages.setDefaultSettingError'),
        });
      }
    }
  };

  const setIsDirtyChangedSettings = useCallback(
    (isDirty: boolean, settings: GlobalProjectSetting[]) => {
      if (isDirty) {
        setUpdatedSettings(settings);
      } else {
        setUpdatedSettings(null);
      }
    },
    [setUpdatedSettings]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadSettings = async () => {
      const { data } = await apiFetchGlobalProjectSetting(type, signal);
      if (data) {
        setSettings(data);
      }
    };
    loadSettings();
    return () => {
      controller.abort();
    };
  }, [type]);

  return {
    areSettingsDirty: !!updatedSettings,
    settings,
    settingsSaving,
    createSetting,
    saveSettings,
    deleteSetting,
    setDefaultSetting,
    setIsDirtyChangedSettings,
  };
};
