import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { makePrioStyles } from '../../theme/utils';
import { useSelector } from 'react-redux';
import { getPrioTheme } from '../../apps/main/rootReducer';
import { PrioTheme, PrioThemeReduxState } from '../types';
import { useForm } from 'antd/lib/form/Form';
import { ChromePicker, Color, ColorChangeHandler } from 'react-color';
// import {
//   updatePrioThemeCICDPrimaryColor,
//   updatePrioThemePrimaryColor,
// } from '../actions';
import Flex from '../../components/Flex';
import { useTheme } from 'react-jss';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  picker: {},
}));

const hexToRgb = (value: string) => {
  let _r = '0';
  let _g = '0';
  let _b = '0';

  // 3 digits
  if (value.length === 4) {
    _r = '0x' + value[1] + value[1];
    _g = '0x' + value[2] + value[2];
    _b = '0x' + value[3] + value[3];

    // 6 digits
  } else if (value.length === 7) {
    _r = '0x' + value[1] + value[2];
    _g = '0x' + value[3] + value[4];
    _b = '0x' + value[5] + value[6];
  }
  let r = parseInt(_r);
  let g = parseInt(_g);
  let b = parseInt(_b);
  return [r, g, b];
};

const rgbToHsl = (
  r: number,
  g: number,
  b: number,
  index: number,
  isDarkMode?: boolean
) => {
  let _r = r;
  let _g = g;
  let _b = b;

  _r /= 255;
  _g /= 255;
  _b /= 255;

  let cmin = Math.min(_r, _g, _b),
    cmax = Math.max(_r, _g, _b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;
  if (delta === 0) {
    h = 0;
  } else if (cmax === _r) {
    h = ((_g - _b) / delta) % 6;
  } else if (cmax === _g) {
    h = (_b - _r) / delta + 2;
  } else {
    h = (_r - _g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  const addLightnessSteps = (lightness: number, index: number) => {
    const step = (100 - l) / 10;
    return lightness + step * index;
  };

  const subLightnessStepp = (lightness: number, index: number) => {
    const step = l / 10;
    return lightness - step * index;
  };

  return [
    isDarkMode ? h - index : h + index,
    s,
    isDarkMode ? subLightnessStepp(l, index) : addLightnessSteps(l, index),
  ];
};

const rgbToHex = (r: number, g: number, b: number) => {
  let _r = Math.round(r).toString(16);
  let _g = Math.round(g).toString(16);
  let _b = Math.round(b).toString(16);

  if (_r.length === 1) {
    _r = '0' + _r;
  }
  if (_g.length === 1) {
    _g = '0' + _g;
  }
  if (_b.length === 1) {
    _b = '0' + _b;
  }

  return '#' + _r + _g + _b;
};

const hslToHex = (h: number, s: number, l: number) => {
  let _s = s;
  let _l = l;
  _s /= 100;
  _l /= 100;

  let c = (1 - Math.abs(2 * _l - 1)) * _s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = _l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  return rgbToHex((r + m) * 255, (g + m) * 255, (b + m) * 255);
};

const addRecursivePalette = (current: number, max: number, index: number) => {
  if (index === 0) {
    return current;
  }
  const ratio = index < 4 ? 5 : index < 8 ? 4 : 3;
  return addRecursivePalette(current + (max - current) / ratio, max, index - 1);
};

const subRecursivePalette = (current: number, min: number, index: number) => {
  if (index === 0) {
    return current;
  }
  if (current <= min) {
    return min;
  }
  // const ratio = index < 4 ? 5 : index < 8 ? 5 : 4;
  return subRecursivePalette(current - current / 5, min, index - 1);
};

interface ThemeSettingsFormProps {}

export const ThemeSettingsForm: React.FC<ThemeSettingsFormProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [form] = useForm<PrioThemeReduxState>();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const prioTheme = useSelector(getPrioTheme);
  // const [timer, setTimer] = useState<NodeJS.Timeout>(null);
  const [color1, setColor1] = useState<string>('#2b3d4e');
  const [color2, setColor2] = useState<string>('#cdd5e3');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  // const handleValuesChange: (
  //   changedValues: any,
  //   values: PrioThemeReduxState
  // ) => void = (changedValues, values) => {
  //   if (timer) {
  //     clearTimeout(timer);
  //   }
  //   setTimer(
  //     setTimeout(() => {
  //       if (changedValues.palette?.primaryColor) {
  //         dispatch(
  //           updatePrioThemePrimaryColor(changedValues.palette?.primaryColor)
  //         );
  //       }
  //       if (changedValues.palette?.cicdPalette?.primaryColor) {
  //         dispatch(
  //           updatePrioThemeCICDPrimaryColor(
  //             changedValues.palette?.cicdPalette?.primaryColor
  //           )
  //         );
  //       }
  //     }, 250)
  //   );
  // };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Form<PrioThemeReduxState>
      form={form}
      initialValues={prioTheme}
      layout="vertical"
      className={classes.root}
      // onValuesChange={handleValuesChange}
    >
      {/* <Row gutter={theme.old.spacing.unit(theme.old.spacing.rowGutter)}>
        <Col span={12}>
          <Form.Item
            name={['palette', 'cicdPalette', 'primaryColor']}
            label="Header Hauptfarbe"
          >
            <ColorPicker />
          </Form.Item>

          <Flex.Row>
            {Object.keys(theme.old.palette.cicdPalette).map((key) => (
              <div
                style={{
                  flex: 1,
                  backgroundColor: theme.old.palette.cicdPalette[key],
                  height: 100,
                }}
              />
            ))}
          </Flex.Row>
        </Col>
        <Col span={12}>
          <Form.Item name={['palette', 'primaryColor']} label="Hauptfarbe">
            <ColorPicker />
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={theme.old.spacing.unit(theme.old.spacing.rowGutter)}>
        <Col span={12}>
          <ColorPicker value={color1} onChange={setColor1} />
        </Col>
        <Col span={12}>
          <ColorPicker value={color2} onChange={setColor2} />
        </Col>
      </Row>
      <Row
        gutter={theme.old.spacing.unit(theme.old.spacing.rowGutter)}
        style={{ height: 500 }}
      >
        <Col span={6} style={{ height: '100%' }}>
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {new Array(10).fill(color1).map((_, index) => {
              const [r, g, b] = hexToRgb(color1);
              const [h, s, l] = rgbToHsl(r, g, b, index + 1);
              return (
                <div
                  style={{
                    flex: 1,
                    backgroundColor: hslToHex(h, s, l),
                    height: 100,
                  }}
                />
              );
            })}
          </div>
        </Col>
        <Col span={6} style={{ height: '100%' }}>
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {new Array(10)
              .fill(color1)
              .map((_, index) => {
                const [r, g, b] = hexToRgb(color1);
                return rgbToHex(
                  addRecursivePalette(r, 255, index + 1),
                  addRecursivePalette(g, 255, index + 1),
                  addRecursivePalette(b, 255, index + 1)
                );
              })
              .map((backgroundColor) => (
                <div
                  style={{
                    flex: 1,
                    backgroundColor,
                    height: 100,
                  }}
                />
              ))}
          </div>
        </Col>
        <Col span={6} style={{ height: '100%' }}>
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {new Array(10)
              .fill(color2)
              .map((_, index) => {
                const [r, g, b] = hexToRgb(color2);
                const [h, s, l] = rgbToHsl(r, g, b, index + 1, true);
                return hslToHex(h, s, l);
              })
              .map((backgroundColor) => (
                <div
                  style={{
                    flex: 1,
                    backgroundColor,
                    height: 100,
                  }}
                />
              ))}
          </div>
        </Col>
        <Col span={6} style={{ height: '100%' }}>
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            {new Array(10)
              .fill(color2)
              .map((_, index) => {
                const [r, g, b] = hexToRgb(color2);
                return rgbToHex(
                  subRecursivePalette(r, 0, index + 1),
                  subRecursivePalette(g, 0, index + 1),
                  subRecursivePalette(b, 0, index + 1)
                );
              })
              .map((backgroundColor) => (
                <div
                  style={{
                    flex: 1,
                    backgroundColor,
                    height: 100,
                  }}
                />
              ))}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ThemeSettingsForm;

interface ColorPickerProps {
  value?: Color;
  onChange?: (value: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
  //#region ------------------------------ Defaults
  const { value, onChange } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleChange: ColorChangeHandler = (value) => {
    if (onChange) {
      onChange(value.hex);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column
      className={classes.picker}
      childrenGap={theme.old.spacing.defaultPadding}
    >
      <ChromePicker color={value} onChange={handleChange} />
    </Flex.Column>
  );
};
