import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { Drawer } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { TaskForm } from './TaskForm';
import { apiCreateJiraTask, apiCreateTask, apiCreateTodoTask } from '../api';
import { CreateJiraTask, CreateTaskRequest } from '../../../models/Task';
import TodoForm from './Todo/TodoForm';
import { CreateTodoTaskRequest, TodoTaskFormModel } from '../../../models/Todo';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Project } from '../../../models/Project';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import { MessageId } from '../../../models/Types';
import JiraTask from './JiraTask';

interface TasksDrawerProps {
  visible: boolean;
  preSelectedTask?: CreateTaskRequest;
  preSelectedTodoTask?: CreateTodoTaskRequest;
  sourceEmailUrl?: string;
  messageId?: MessageId;
  closeDrawer: VoidFunction;
}

export const TasksDrawer: React.FC<TasksDrawerProps> = (props) => {
  const { visible, preSelectedTask, closeDrawer, sourceEmailUrl, messageId } =
    props;

  var currentProject: Project;

  currentProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, preSelectedTask && preSelectedTask.projectId)
  );

  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleFinish = async (value: CreateTaskRequest) => {
    value.sourceEmailUrl = sourceEmailUrl;
    value.messageId = messageId;
    setIsSaving(true);
    const { result } = await apiCreateTask(value.projectId, value);
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      closeDrawer();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('tasks:form.errorMessages.createTask'),
      });
      return false;
    }
  };

  const handleFinishJiraTask = async (value: CreateJiraTask) => {
    value.messageLink = sourceEmailUrl;
    value.prioProjectId = currentProject.projectId;
    value.messageId = messageId;
    setIsSaving(true);
    const { result } = await apiCreateJiraTask(value);
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      closeDrawer();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('tasks:form.errorMessages.createTask'),
      });
      return false;
    }
  };

  const handleFinishTodoTask = async (value: TodoTaskFormModel) => {
    const { dueDate: dueDateTimeMoment, reminderDate: reminderDateTimeMoment } =
      value;
    const todoTask: CreateTodoTaskRequest = {
      todoTask: {
        title: value.title,
        status: value.status,
        importance: value.importance,
        isReminderOn: value.reminderDate ? true : false,
        dueDateTime: dueDateTimeMoment && {
          dateTime: dueDateTimeMoment?.toISOString(),
          timeZone: moment.tz.guess(),
        },
        reminderDateTime: reminderDateTimeMoment && {
          dateTime: reminderDateTimeMoment?.toISOString(),
          timeZone: moment.tz.guess(),
        },
        body: {
          content: value.description,
        },
      },
    };
    setIsSaving(true);
    const { result } = await apiCreateTodoTask(value.todoListId, todoTask);
    setIsSaving(true);
    if (result.status >= 200 && result.status < 300) {
      closeDrawer();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('tasks:todoForm.errorMessages.createTask'),
      });
      closeDrawer();
      return false;
    }
  };
  const handleCancel = () => {
    closeDrawer();
  };
  useEffect(() => {});

  return (
    <Drawer
      title={t('tasks:modal.title')}
      footer={null}
      visible={visible}
      onClose={handleCancel}
      defaultTab="0"
      tabs={[
        {
          label: 'ToDo',
          key: '0',
          content: (
            <TodoForm
              actionLabel={t('common:save')}
              cancelLabel={t('common:actions.cancel')}
              onFinish={handleFinishTodoTask}
              onCancel={handleCancel}
              initialValues={{
                todoListId: null,
                title: preSelectedTask && preSelectedTask.title,
                description: preSelectedTask && preSelectedTask.description,
                dueDate: preSelectedTask && moment(preSelectedTask.dueDateTime),
              }}
              isDrawer={visible}
              resetOnFinish
            />
          ),
        },
        ...(currentProject
          ? [
              ...(currentProject?.projectExtensionMeDtos?.find(
                (extension) => extension.projectExtensionType === 'planner'
              )
                ? [
                    {
                      label: 'Planner',
                      key: '1',
                      content: (
                        <TaskForm
                          actionLabel={t('tasks:form.actionLabel')}
                          cancelLabel={t('common:actions.cancel')}
                          onFinish={handleFinish}
                          onCancel={handleCancel}
                          disableForm={isSaving}
                          resetOnFinish
                          //@ts-ignore
                          initialValues={preSelectedTask}
                          isDrawer={visible}
                          disableActionButton={isSaving}
                        />
                      ),
                    },
                  ]
                : []),
              ...(currentProject.projectExtensionMeDtos?.find(
                (extension) => extension.projectExtensionType === 'jira'
              )
                ? [
                    {
                      label: 'Jira',
                      key: '2',
                      content: (
                        <JiraTask
                          actionLabel={t('common:save')}
                          cancelLabel={t('common:actions.cancel')}
                          onFinish={handleFinishJiraTask}
                          onCancel={handleCancel}
                          initialValues={{
                            summary: preSelectedTask && preSelectedTask.title,
                            description:
                              preSelectedTask && preSelectedTask.description,
                            jiraProjectId:
                              currentProject.projectExtensionMeDtos.filter(
                                (extension) =>
                                  extension.projectExtensionType === 'jira'
                              )[0]?.value,
                          }}
                          isDrawer={visible}
                          disableActionButton={isSaving}
                          resetOnFinish
                          projectExtensionsMe={currentProject.projectExtensionMeDtos.filter(
                            (extension) =>
                              extension.projectExtensionType === 'jira'
                          )}
                        />
                      ),
                    },
                  ]
                : []),
            ]
          : []),
      ]}
    ></Drawer>
  );
};

export default TasksDrawer;
