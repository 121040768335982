import React, { useEffect, useMemo, useState } from 'react';

import { Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import {
  fetchUsers,
  updateGlobalUserOfficeRoles,
  updateUserOfficeRoles,
} from '../actions/users';
import Flex from '../../../components/Flex';
import { ContactId, OfficeId, OfficeRole } from '../../../models/Types';
import { User } from '../../../models/User';
import {
  getPrioUsers,
  getUsersIsFetching,
} from '../../../apps/main/rootReducer';
import UserPicker from './UserPicker';
import { distinct } from '../../../util';
import { Button } from '@prio365/prio365-react-library';
import equals from 'deep-equal';
import { Office } from '../../../models/Office';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overvlow: 'hidden',
  },
  mainComponent: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
  },
  fullWidth: {
    width: '100%',
  },
  label: {
    fontSize: theme.old.typography.fontSize.label,
    color: theme.old.typography.colors.muted,
  },
}));

interface OfficeRolesForm {
  officeAdmin?: ContactId[];
  officeAssistance?: ContactId[];
  officeController?: ContactId[];
  officeHR?: ContactId[];
}

interface PrioOfficeRoleManagementProps {
  mainComponentClassName?: string;
  officeId: OfficeId;
  showGlobalRoleSettings: boolean;
  office?: Office;
}

export const PrioOfficeRoleManagement: React.FC<
  PrioOfficeRoleManagementProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mainComponentClassName, officeId, showGlobalRoleSettings, office } =
    props;

  const originalUsers = useSelector(getPrioUsers);
  const isFetching = useSelector(getUsersIsFetching);

  useEffect(() => {
    dispatch(fetchUsers(officeId));
  }, [dispatch, officeId]);

  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    setUsers(
      originalUsers.filter(
        (user) => user.prioData.officeRoles[officeId]?.length > 0
      )
    );
  }, [originalUsers, officeId]);

  const initialValues = useMemo<OfficeRolesForm>(
    () => ({
      officeController: users
        ?.filter(
          (user) =>
            user.prioData.officeRoles[officeId]?.includes('officeController')
        )
        ?.map((user) => user.id),
      officeHR: users
        ?.filter(
          (user) => user.prioData.officeRoles[officeId]?.includes('officeHR')
        )
        ?.map((user) => user.id),
      officeAssistance: users
        ?.filter(
          (user) =>
            user.prioData.officeRoles[officeId]?.includes('officeAssistance')
        )
        ?.map((user) => user.id),
      officeAdmin: users
        ?.filter(
          (user) => user.prioData.officeRoles[officeId]?.includes('officeAdmin')
        )
        ?.map((user) => user.id),
    }),
    [users, officeId]
  );

  const updateUsersByRole: (
    updatedIds: ContactId[],
    oldUsers: ContactId[],
    role: OfficeRole
  ) => User[] = (updatedIds, oldUsers, role) => {
    const newUsers = originalUsers
      .filter(
        (user) => updatedIds.includes(user.id) && !oldUsers.includes(user.id)
      )
      .map((user) => ({
        ...user,
        prioData: {
          ...user.prioData,
          officeRoles: {
            ...user.prioData.officeRoles,
            [officeId]: [...(user.prioData.officeRoles[officeId] ?? []), role],
          },
        },
      }));

    const removedUsers = originalUsers
      .filter(
        (user) => oldUsers.includes(user.id) && !updatedIds.includes(user.id)
      )
      .map((user) => {
        const { [officeId]: currentIdRoles, ...officeRoles } =
          user.prioData.officeRoles;
        const index = currentIdRoles.indexOf(role);
        if (index > -1) {
          currentIdRoles.splice(index, 1);
        }
        return {
          ...user,
          prioData: {
            ...user.prioData,
            officeRoles:
              currentIdRoles.length === 0
                ? officeRoles
                : {
                    ...officeRoles,
                    [officeId]: currentIdRoles,
                  },
          },
        };
      });
    return [...newUsers, ...removedUsers];
  };

  const saveUpdatedRoles = (value: OfficeRolesForm) => {
    const updatedAdmins = updateUsersByRole(
      value.officeAdmin,
      initialValues.officeAdmin,
      'officeAdmin'
    );
    const updatedAssistances = updateUsersByRole(
      value.officeAssistance,
      initialValues.officeAssistance,
      'officeAssistance'
    );
    const updatedControllers = updateUsersByRole(
      value.officeController,
      initialValues.officeController,
      'officeController'
    );
    const updatedHRs = updateUsersByRole(
      value.officeHR,
      initialValues.officeHR,
      'officeHR'
    );
    const concatUsers = [
      ...updatedAdmins,
      ...updatedAssistances,
      ...updatedControllers,
      ...updatedHRs,
    ];
    let mergedUsers: User[] = [];

    concatUsers.forEach((cUser, _, self) => {
      if (!mergedUsers.find((mUser) => mUser.id === cUser.id)) {
        const mergedRoles: OfficeRole[] = self
          .filter((user) => user.id === cUser.id)
          .map((user) => user.prioData.officeRoles[officeId])
          .filter((role) => role !== undefined)
          .reduce(
            (previous, current) => distinct([...previous, ...current]),
            []
          );
        const { [officeId]: empty_, ...removedOfficeRoles } =
          cUser.prioData.officeRoles;
        mergedUsers.push(
          !mergedRoles || mergedRoles?.length === 0
            ? {
                ...cUser,
                prioData: {
                  ...cUser.prioData,
                  officeRoles: {
                    ...removedOfficeRoles,
                  },
                },
              }
            : {
                ...cUser,
                prioData: {
                  ...cUser.prioData,
                  officeRoles: {
                    ...cUser.prioData.officeRoles,
                    [officeId]: mergedRoles,
                  },
                },
              }
        );
      }
    });

    mergedUsers.forEach((user) => {
      const originalUser = users.find((old) => old.id === user.id);
      if (showGlobalRoleSettings) {
        dispatch(updateGlobalUserOfficeRoles(user, originalUser, officeId));
      } else {
        dispatch(updateUserOfficeRoles(user, originalUser, officeId));
      }
    });

    setUsers(mergedUsers);
  };

  const [changedForm, setChangedForm] =
    useState<OfficeRolesForm>(initialValues);

  useEffect(() => {
    setChangedForm(initialValues);
  }, [initialValues]);

  const hasChanged = !equals(initialValues, changedForm);

  if (isFetching || originalUsers.length === 0) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <PrioSpinner size="large" />
      </div>
    );
  }

  return (
    <Flex.Row flex={1} className={classes.root}>
      <Flex.Column
        className={classNames(classes.mainComponent, mainComponentClassName)}
        flex={1}
        childrenGap={theme.old.spacing.unit(3)}
      >
        <Flex.Item>
          <Typography.Title>{office?.name ?? ''}</Typography.Title>
        </Flex.Item>
        <Flex.Column childrenGap={theme.old.spacing.unit(0.5)}>
          <Typography.Text className={classes.label}>
            {t('users:officeRolesForm.labels.officeAdmin')}
          </Typography.Text>
          <UserPicker
            className={classes.fullWidth}
            value={changedForm.officeAdmin}
            onChange={(ids: string[]) =>
              setChangedForm({
                ...changedForm,
                officeAdmin: ids,
              })
            }
            multiple
          />
        </Flex.Column>
        <Flex.Column childrenGap={theme.old.spacing.unit(0.5)}>
          <Typography.Text className={classes.label}>
            {t('users:officeRolesForm.labels.officeAssistance')}
          </Typography.Text>
          <UserPicker
            className={classes.fullWidth}
            value={changedForm.officeAssistance}
            onChange={(ids: string[]) =>
              setChangedForm({
                ...changedForm,
                officeAssistance: ids,
              })
            }
            multiple
          />
        </Flex.Column>
        <Flex.Column childrenGap={theme.old.spacing.unit(0.5)}>
          <Typography.Text className={classes.label}>
            {t('users:officeRolesForm.labels.officeController')}
          </Typography.Text>
          <UserPicker
            className={classes.fullWidth}
            value={changedForm.officeController}
            onChange={(ids: string[]) =>
              setChangedForm({
                ...changedForm,
                officeController: ids,
              })
            }
            multiple
          />
        </Flex.Column>
        <Flex.Column childrenGap={theme.old.spacing.unit(0.5)}>
          <Typography.Text className={classes.label}>
            {t('users:officeRolesForm.labels.officeHR')}
          </Typography.Text>
          <UserPicker
            className={classes.fullWidth}
            value={changedForm.officeHR}
            onChange={(ids: string[]) =>
              setChangedForm({
                ...changedForm,
                officeHR: ids,
              })
            }
            multiple
          />
        </Flex.Column>
        <Flex.Row justifyContent="flex-end">
          <Button
            disabled={!hasChanged}
            onClick={() => saveUpdatedRoles(changedForm)}
          >
            {t('common:actions.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </Flex.Row>
  );
};

export default PrioOfficeRoleManagement;
