import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popover } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import {
  checkIfLink,
  checkIfOneNote,
  colorForIcon,
  iconForFile,
  isDriveItemFolder,
} from '../util';
import { DriveItem } from '../../../models/Drive';
import { GroupId, ProjectId } from '../../../models/Types';
import { setCurrentPreviewModalVisibility } from '../actions/previewModal';
import { useDriveItemActions } from '../hooks/useDriveItemActions';
import { useDispatch } from 'react-redux';
import {
  DefaultDateTimeFormatString,
  formatHumanFileSize,
} from '../../../util';
import i18n from '../../../i18n';
import PrioSpinner from '../../../components/PrioSpinner';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from 'jss';

export const useDocumentsTableRowIconStyles = makePrioStyles(
  (theme: PrioTheme) => ({
    root: {},
    iconSize: {
      fontSize: theme.old.components.documentsTable.font.fontSize,
      lineHeight: theme.old.components.documentsTable.font.lineHeight,
      maxWidth: '1em',
      width: '100%',
      '& .svg-inline--fa': {
        maxWidth: '1em',
        width: '100%',
      },
    },
    previewButton: {
      '& .fa-layers': {
        maxWidth: '1em',
        width: '100%',
      },
      '& > span > svg:nth-child(1)': {
        visibility: 'visible',
      },
      '& > span > svg:nth-child(2)': {
        visibility: 'hidden',
      },
      '&:hover > span > svg:nth-child(1)': {
        visibility: 'hidden',
      },
      '&:hover > span > svg:nth-child(2)': {
        visibility: 'visible',
      },
    },
    folder: {
      color: theme.old.palette.chromaticPalette.yellow,
      fontSize: theme.old.components.documentsTable.font.fontSize,
      lineHeight: theme.old.components.documentsTable.font.lineHeight,
      '& .svg-inline--fa': {
        maxWidth: '1em',
        width: '100%',
      },
    },
    popover: {
      '& .ant-popover-title': {
        fontSize: theme.old.typography.fontSize.tiny,
        padding: theme.old.spacing.baseSpacing,
      },
      '& .ant-popover-inner-content': {
        fontSize: theme.old.typography.fontSize.tiny,
        padding: theme.old.spacing.baseSpacing,
      },
    },
    onHoverPreviewIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

interface DocumentsTableRowIconProps {
  className?: string;
  classes: Classes<
    | 'root'
    | 'iconSize'
    | 'previewButton'
    | 'folder'
    | 'popover'
    | 'onHoverPreviewIcon'
  >;
  groupId: GroupId;
  projectId: ProjectId;
  driveItem: DriveItem;
  driveItemList: DriveItem[];
  simplyfied?: boolean;
  selectedDriveItems?: DriveItem[];
}

export const DocumentsTableRowIcon: React.FC<DocumentsTableRowIconProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    classes,
    groupId,
    projectId,
    driveItem,
    driveItemList,
    simplyfied,
    selectedDriveItems,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const isOneNoteFile = checkIfOneNote(driveItem);
  const isLink = checkIfLink(driveItem?.name);
  const isFolder = isDriveItemFolder(driveItem);

  const { openInBrowser } = useDriveItemActions(groupId, projectId);

  const iconColor = isOneNoteFile
    ? theme.old.palette.chromaticPalette.purple
    : colorForIcon(driveItem?.file?.mimeType);
  const iconType = iconForFile(driveItem);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const showPreview = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      driveItem: DriveItem,
      sortedDriveItems: DriveItem[]
    ) => {
      if (!isDriveItemFolder(driveItem)) {
        e.preventDefault();
        e.stopPropagation();
        const index = sortedDriveItems
          .filter((item) => !isDriveItemFolder(item))
          .findIndex((item) => item.id === driveItem.id);
        dispatch(
          setCurrentPreviewModalVisibility(
            true,
            driveItem?.id,
            groupId,
            driveItem,
            sortedDriveItems.filter((item) => !isDriveItemFolder(item)),
            index,
            '',
            '',
            false,
            selectedDriveItems,
            'documents'
          )
        );
      }
    },
    [dispatch, groupId, selectedDriveItems]
  );

  const renderIcon = (
    icon: IconName,
    overlayIcon?: IconName,
    color?: string,
    overlayColor?: string
  ) => {
    return (
      <span className="fa-layers">
        <FontAwesomeIcon icon={['fas', icon]} color={color} />
        {overlayIcon && (
          <FontAwesomeIcon
            icon={['fal', overlayIcon]}
            color={overlayColor || 'rgba(0, 0, 0, 0.6)'}
            className={classes.onHoverPreviewIcon}
          />
        )}
      </span>
    );
  };

  const fileIcon = !isLink ? (
    <div
      className={classNames(classes.iconSize, {
        [classes.previewButton]: !isFolder,
      })}
      onClick={(e) => {
        showPreview(e, driveItem, driveItemList);
      }}
    >
      {isFolder
        ? renderIcon(iconType, null, iconColor)
        : renderIcon(iconType, 'eye', iconColor)}
    </div>
  ) : (
    <div
      onClick={() => {
        openInBrowser(driveItem);
      }}
    >
      {renderIcon(iconType, null, iconColor)}
    </div>
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (simplyfied) {
    return (
      <>
        {!driveItem?.id.includes('t-') ? (
          isDriveItemFolder(driveItem) ? (
            <div className={classes.folder}>
              <FontAwesomeIcon icon={['fas', 'folder']} />
            </div>
          ) : (
            fileIcon
          )
        ) : (
          <div>
            <PrioSpinner size="small" />
          </div>
        )}
      </>
    );
  }

  return (
    <Popover
      overlayClassName={classes.popover}
      title={driveItem?.name}
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {t('documents:table.popover.lastModifiedDateTime') +
              ': ' +
              DefaultDateTimeFormatString(
                driveItem.lastModifiedDateTime,
                i18n.language
              )}
          </div>
          <div style={{ marginBottom: '8px' }}>
            {t('documents:table.popover.size') +
              ': ' +
              formatHumanFileSize(driveItem.size)}
          </div>
          {driveItem.listItemFields.Prio365Description && (
            <div>
              {t('documents:table.popover.description') +
                ': ' +
                driveItem.listItemFields.Prio365Description}
            </div>
          )}
          {driveItem.listItemFields.Prio365AlternativeName && (
            <div>
              {t('documents:table.popover.alternativeName') +
                ': ' +
                driveItem.listItemFields.Prio365AlternativeName}
            </div>
          )}
        </div>
      }
      trigger="hover"
    >
      {!driveItem?.id.includes('t-') ? (
        isDriveItemFolder(driveItem) ? (
          <div className={classes.folder}>
            <FontAwesomeIcon icon={['fas', 'folder']} />
          </div>
        ) : (
          fileIcon
        )
      ) : (
        <div>
          <PrioSpinner size="small" />
        </div>
      )}
    </Popover>
  );
};

export default DocumentsTableRowIcon;
