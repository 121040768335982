import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popover, notification } from 'antd';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import Flex from '../../../components/Flex';
import { PrioTheme } from '../../../theme/types';
import AnnualSummaryItem, {
  AnnualSummaryItemUnit,
  AnnualSummarySingleItemProps,
} from './AnnualSummaryItem';
import { debounceMonthlyCloseMe } from '../../timeKeeping/actions';
import { useDispatch } from 'react-redux';
import { Moment } from 'moment';
import moment from 'moment';
import {
  MonthlyClose,
  MonthlyCloseAbsence,
  TimeKeepingDay,
} from '../../../models/TimeKeeping';
import { Button } from '@prio365/prio365-react-library';
import { EmployeeMe } from '../../../models/Employee';
import { apiFetchEmployeeMe } from '../../hr/api';
import {
  MonthlyCloseAbsenceType,
  TimeKeepingDayType,
} from '../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing.regular,
  },
  title: {
    fontSize: theme.font.fontSize.small,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.default,
    marginBottom: theme.spacing.small,
  },
  itemLabel: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
  },
  itemValue: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
    marginTop: '0px!important',
    marginBottom: '2px',
  },
  customDivider: {
    width: '30%',
    height: '1px',
    backgroundColor: theme.colors.application.border,
    marginTop: `${theme.spacing.regular}px!important`,
    marginBottom: `${theme.spacing.regular}px!important`,
  },
  containerDefault: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: 16,
  },

  container2columns: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  container1column: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  warning: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.small,
  },
  warningRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing.small,
  },
}));

export type AnnualSummaryItemTypes = 'absences' | 'workType' | 'overtimeHours';
interface AnnualSummaryProps {
  className?: string;
}

const calcAbsenceDays = (absenceProposals: MonthlyCloseAbsence[]): number => {
  return absenceProposals?.reduce((total, absence) => {
    return total + absence?.absentWorkDays ?? 0;
  }, 0);
};

const getNumbersOfAbsenceProposalsByType = (
  absenceProposals: MonthlyCloseAbsence[],
  type: MonthlyCloseAbsenceType
): number => {
  const filteredAbsenceProposals = absenceProposals?.filter(
    (absence) => absence.type === type
  );
  return calcAbsenceDays(filteredAbsenceProposals);
};

const getNumbersOfTimeKeepingDaysType = (
  timeKeepingDays: TimeKeepingDay[],
  type: TimeKeepingDayType
): number => {
  const filteredTimeKeepingDays = timeKeepingDays?.filter(
    (timeKeepingDay) => timeKeepingDay.type === type
  );
  return filteredTimeKeepingDays?.length ?? 0;
};

export const AnnualSummary: React.FC<AnnualSummaryProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const [employeeMe, setEmployeeMe] = useState<EmployeeMe>(undefined);
  const startTimeKeepingDate = moment(employeeMe?.timeKeepingEnableDate);

  const [selectedYear, setSelectedYear] = useState<Moment>(moment());
  const currentMonth = moment().month();

  const [monthlyClosesOfYear, setMonthlyClosesOfYear] =
    useState<MonthlyClose[]>();
  const [
    monthlyCloseLastMonthOfPreviousYear,
    setMonthlyCloseLastMonthOfPreviousYear,
  ] = useState<MonthlyClose>(undefined);
  const [monthlyClosesLoading, setMonthlyClosesLoading] = useState(false);

  const monthlyCloseAbsences: MonthlyCloseAbsence[] = useMemo(
    () =>
      monthlyClosesOfYear?.reduce((total, monthlyClose) => {
        return [...total, ...monthlyClose.monthlyCloseAbsences];
      }, []) ?? [],
    [monthlyClosesOfYear]
  );

  const getUnitType = (value: number): AnnualSummaryItemUnit => {
    if (value === 1) {
      return 'day' as AnnualSummaryItemUnit;
    } else {
      return 'days' as AnnualSummaryItemUnit;
    }
  };

  const absenceItems: AnnualSummarySingleItemProps[] = [
    {
      label: t(`timeAndLeaveManagement:annualSummary.absenceItems.annualLeave`),
      value: getNumbersOfAbsenceProposalsByType(
        monthlyCloseAbsences,
        'annualLeave'
      ),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(
          monthlyCloseAbsences,
          'annualLeave'
        ) ?? 0
      ),
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.absenceItems.dayOfIllness`
      ),
      value: getNumbersOfAbsenceProposalsByType(
        monthlyCloseAbsences,
        'dayOfIllness'
      ),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(monthlyCloseAbsences, 'dayOfIllness')
      ),
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.absenceItems.overtimeCompensation`
      ),
      value: getNumbersOfAbsenceProposalsByType(
        monthlyCloseAbsences,
        'overtimeCompensation'
      ),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(
          monthlyCloseAbsences,
          'overtimeCompensation'
        )
      ),
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.absenceItems.parentalLeave`
      ),
      value: getNumbersOfAbsenceProposalsByType(
        monthlyCloseAbsences,
        'parentalLeave'
      ),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(
          monthlyCloseAbsences,
          'parentalLeave'
        )
      ),
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.absenceItems.maternityLeave`
      ),
      value: getNumbersOfAbsenceProposalsByType(
        monthlyCloseAbsences,
        'maternityLeave'
      ),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(
          monthlyCloseAbsences,
          'maternityLeave'
        )
      ),
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.absenceItems.paidSpecialLeave`
      ),
      value: getNumbersOfAbsenceProposalsByType(
        monthlyCloseAbsences,
        'paidSpecialLeave'
      ),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(
          monthlyCloseAbsences,
          'paidSpecialLeave'
        )
      ),
    },
    {
      label: t(`timeAndLeaveManagement:annualSummary.absenceItems.training`),
      value: getNumbersOfAbsenceProposalsByType(
        monthlyCloseAbsences,
        'training'
      ),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(monthlyCloseAbsences, 'training')
      ),
    },
    {
      label: t(`timeAndLeaveManagement:annualSummary.absenceItems.school`),
      value: getNumbersOfAbsenceProposalsByType(monthlyCloseAbsences, 'school'),
      unit: getUnitType(
        getNumbersOfAbsenceProposalsByType(monthlyCloseAbsences, 'school')
      ),
    },
  ].filter((item) => item.value !== 0);

  const totalAbsenceDays = absenceItems.reduce(
    (total, item) => total + item.value,
    0
  );

  const absenceItemsTotal: AnnualSummarySingleItemProps[] = [
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.absenceItems.absenceItemTotal`
      ),
      value: totalAbsenceDays,
      unit: getUnitType(totalAbsenceDays),
    },
  ];

  const timeKeepingDaysOfYear: TimeKeepingDay[] = monthlyClosesOfYear?.reduce(
    (total, monthlyClose) => {
      return [...total, ...monthlyClose.timeKeepingDays];
    },
    []
  );

  const workTypeItems: AnnualSummarySingleItemProps[] = [
    {
      label: t(`timeAndLeaveManagement:annualSummary.workTypeItems.office`),
      value: getNumbersOfTimeKeepingDaysType(timeKeepingDaysOfYear, 'office'),
      unit: getUnitType(
        getNumbersOfTimeKeepingDaysType(timeKeepingDaysOfYear, 'office')
      ),
    },
    {
      label: t(`timeAndLeaveManagement:annualSummary.workTypeItems.homeOffice`),
      value: getNumbersOfTimeKeepingDaysType(
        timeKeepingDaysOfYear,
        'homeOffice'
      ),
      unit: getUnitType(
        getNumbersOfTimeKeepingDaysType(timeKeepingDaysOfYear, 'homeOffice')
      ),
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.workTypeItems.outOfOffice`
      ),
      value: getNumbersOfTimeKeepingDaysType(
        timeKeepingDaysOfYear,
        'outOfOffice'
      ),
      unit: getUnitType(
        getNumbersOfTimeKeepingDaysType(timeKeepingDaysOfYear, 'outOfOffice')
      ),
    },
    {
      label: t(`timeAndLeaveManagement:annualSummary.workTypeItems.other`),
      value: getNumbersOfTimeKeepingDaysType(timeKeepingDaysOfYear, 'other'),
      unit: getUnitType(
        getNumbersOfTimeKeepingDaysType(timeKeepingDaysOfYear, 'other')
      ),
    },
  ].filter((item) => item.value !== 0);

  const workTypeItemsTotal: AnnualSummarySingleItemProps[] = [
    {
      label: t(`timeAndLeaveManagement:annualSummary.workTypeItems.total`),
      value: workTypeItems.reduce((total, item) => total + item.value, 0),
      unit: getUnitType(
        workTypeItems.reduce((total, item) => total + item.value, 0)
      ),
    },
  ];

  const accumulatedOvertimeHoursBeginningFirstMonth =
    monthlyCloseLastMonthOfPreviousYear?.accumulatedOvertimeHours ?? 0;

  const accumulatedOvertimeHoursCurrentMonth =
    monthlyClosesOfYear?.[currentMonth]?.accumulatedOvertimeHours ?? 0;

  const overtimeHoursChangeOfCurrentYear =
    monthlyClosesOfYear?.reduce((total, monthlyClose) => {
      return (
        total +
          monthlyClose?.actualWorkHours -
          monthlyClose?.expectedWorkHoursToDate ?? 0
      );
    }, 0) ?? 0;

  const nonTransfarableOvertimeHoursOfCurrentYear =
    monthlyClosesOfYear?.reduce((total, monthlyClose) => {
      return total + monthlyClose?.nonTransferableOvertimeHours ?? 0;
    }, 0) ?? 0;

  const overtimeCompensationHoursOfCurrentYear =
    monthlyClosesOfYear?.reduce((total, monthlyClose) => {
      return total + monthlyClose?.overtimeCompensationHours ?? 0;
    }, 0) ?? 0;

  const compensationPaymentHoursOfCurrentYear =
    monthlyClosesOfYear?.reduce((total, monthlyClose) => {
      return total + monthlyClose?.compensationPaymentHours ?? 0;
    }, 0) ?? 0;

  const calculationPopoverItems: AnnualSummarySingleItemProps[] = [
    {
      label:
        overtimeHoursChangeOfCurrentYear >= 0
          ? t(
              `timeAndLeaveManagement:annualSummary.overtimeItems.positiveOvertimeHours`
            )
          : t(
              `timeAndLeaveManagement:annualSummary.overtimeItems.negativeOvertimeHours`
            ),
      value: overtimeHoursChangeOfCurrentYear,
      unit: 'hour' as AnnualSummaryItemUnit,
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.overtimeItems.nonTransferableOvertimeHours`
      ),
      value: nonTransfarableOvertimeHoursOfCurrentYear * -1,
      unit: 'hour' as AnnualSummaryItemUnit,
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.overtimeItems.overtimeCompensationHours`
      ),
      value: overtimeCompensationHoursOfCurrentYear * -1,
      unit: 'hour' as AnnualSummaryItemUnit,
    },
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.overtimeItems.compensationPaymentHours`
      ),
      value: compensationPaymentHoursOfCurrentYear * -1,
      unit: 'hour' as AnnualSummaryItemUnit,
    },
  ].filter((item) => item.value !== 0);

  const overTimeHoursItems: AnnualSummarySingleItemProps[] = [
    {
      label: t(
        `timeAndLeaveManagement:annualSummary.overtimeItems.beginningYear`,
        { year: selectedYear.format('YYYY') }
      ),
      value: accumulatedOvertimeHoursBeginningFirstMonth,
      unit: 'hour' as AnnualSummaryItemUnit,
    },
    {
      label: (
        <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
          <div>
            {t(`timeAndLeaveManagement:annualSummary.overtimeItems.change`, {
              year: selectedYear.format('YYYY'),
            })}
          </div>
          <Popover
            placement="bottom"
            content={
              <div style={{ width: '134px' }}>
                <div className={classes.title}>
                  {t(
                    `timeAndLeaveManagement:annualSummary.overtimeItems.calculation`
                  )}
                </div>
                {calculationPopoverItems?.map((item) => (
                  <>
                    <div className={classes.itemLabel}>{item.label}</div>
                    <div className={classes.itemValue}>{`${item.value
                      .toString()
                      .replace('.', ',')} ${t(
                      `timeAndLeaveManagement:annualSummary.unit.${item.unit}`
                    )}`}</div>
                  </>
                ))}
                <div className={classes.customDivider} />
                <>
                  <div className={classes.itemLabel}>
                    {t(
                      `timeAndLeaveManagement:annualSummary.overtimeItems.sum`
                    )}
                  </div>
                  <div className={classes.itemValue}>
                    {`${(
                      accumulatedOvertimeHoursCurrentMonth -
                      accumulatedOvertimeHoursBeginningFirstMonth
                    )
                      .toString()
                      .replace('.', ',')} ${t(
                      `timeAndLeaveManagement:annualSummary.unit.hour`
                    )}`}
                  </div>
                </>
              </div>
            }
          >
            <FontAwesomeIcon icon={['fal', 'info-circle']} />
          </Popover>
        </Flex.Row>
      ),
      value:
        accumulatedOvertimeHoursCurrentMonth -
        accumulatedOvertimeHoursBeginningFirstMonth,
      unit: 'hour' as AnnualSummaryItemUnit,
    },
  ];

  const overTimeHoursChangeItem: AnnualSummarySingleItemProps[] = [
    {
      label: t(`timeAndLeaveManagement:annualSummary.overtimeItems.current`),
      value: accumulatedOvertimeHoursCurrentMonth,
      unit: 'hour' as AnnualSummaryItemUnit,
    },
  ];
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onClickPrevYear = useCallback(() => {
    setSelectedYear(selectedYear.clone().subtract(1, 'year'));
  }, [selectedYear]);

  const onClickNextYear = useCallback(() => {
    setSelectedYear(selectedYear.clone().add(1, 'year'));
  }, [selectedYear]);

  const onClickToday = useCallback(() => {
    setSelectedYear(moment());
  }, []);

  const debounceFetchMonthlyClose = useCallback(
    (date: Moment) => {
      const start = date
        .clone()
        .startOf('year')
        .subtract(1, 'month')
        .toISOString(true)
        .split('T')[0];
      const end = date.clone().endOf('year').toISOString(true).split('T')[0];
      const getMonthlyClosesOfYear = (monthlyCloses: MonthlyClose[]) => {
        setMonthlyClosesOfYear(monthlyCloses.slice(1));
        setMonthlyCloseLastMonthOfPreviousYear(monthlyCloses[0]);
        setMonthlyClosesLoading(false);
      };

      setMonthlyClosesLoading(true);
      dispatch(debounceMonthlyCloseMe(start, end, getMonthlyClosesOfYear));
    },
    [dispatch]
  );
  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    const fetchEmployeeMe = async () => {
      const { data } = await apiFetchEmployeeMe();
      if (data) {
        setEmployeeMe(data);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('absences:errorMessages.fetchOverviewError'),
        });
      }
    };
    fetchEmployeeMe();
  }, [setEmployeeMe, t]);

  useEffect(() => {
    debounceFetchMonthlyClose(selectedYear);
  }, [debounceFetchMonthlyClose, selectedYear]);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (containerElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width } = entry.contentRect;
          setContainerWidth(width);
        }
      });

      resizeObserver.observe(containerElement);

      return () => {
        resizeObserver.unobserve(containerElement);
      };
    } else {
      return () => {};
    }
  }, [containerRef]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column childrenGap={theme.spacing.regular}>
        <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
          <Button
            type="default"
            iconProp={['fal', 'calendar-day']}
            onClick={onClickToday}
            disabled={selectedYear.isSame(startTimeKeepingDate, 'year')}
          />

          <Button
            type="default"
            iconProp={['fal', 'chevron-left']}
            onClick={onClickPrevYear}
            disabled={selectedYear.isBefore(moment(), 'year')}
          />
          <div>{selectedYear.format('YYYY')}</div>
          <Button
            type="default"
            iconProp={['fal', 'chevron-right']}
            onClick={onClickNextYear}
            disabled={selectedYear.isSame(moment(), 'year')}
          />
        </Flex.Row>
        <div
          className={classNames(
            classes.containerDefault,
            { [classes.container2columns]: containerWidth < 1024 },
            { [classes.container1column]: containerWidth < 670 }
          )}
          ref={containerRef}
        >
          <AnnualSummaryItem
            title={t(`timeAndLeaveManagement:annualSummary.absenceItems.title`)}
            items={absenceItems ?? []}
            totalItems={absenceItemsTotal ?? []}
            isDataLoading={monthlyClosesLoading}
          />
          <AnnualSummaryItem
            title={t(
              `timeAndLeaveManagement:annualSummary.workTypeItems.title`
            )}
            isDataLoading={monthlyClosesLoading}
            items={workTypeItems ?? []}
            totalItems={workTypeItemsTotal}
          />
          <AnnualSummaryItem
            title={t(
              `timeAndLeaveManagement:annualSummary.overtimeItems.title`
            )}
            isDataLoading={monthlyClosesLoading}
            items={overTimeHoursItems ?? []}
            totalItems={overTimeHoursChangeItem ?? []}
          />
        </div>
        {selectedYear.isSame(startTimeKeepingDate, 'year') && (
          <div className={classes.warningRow}>
            <FontAwesomeIcon
              icon={['fal', 'warning']}
              color={theme.colors.application.typography.muted}
            />
            <div className={classes.warning}>
              {t(`timeAndLeaveManagement:annualSummary.warning`, {
                date: startTimeKeepingDate.format('DD. MMM YYYY'),
              })}
            </div>
          </div>
        )}
      </Flex.Column>
    </div>
  );
};

export default AnnualSummary;
