import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../../actions';
import { Message } from '../../../../models/Message';
import { ReduxAction } from '../../../../models/Redux';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import { DELETE_ALL_LOCAL_DRAFTS } from '../../actions/actionControllers/draftsActionController';
import {
  ADD_DRAFT_MESSAGE_PROJECT,
  ADD_SENDING_MESSAGE_ID_PROJECT,
  CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT,
  CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT,
  REMOVE_DRAFT_MESSAGE_PROJECT,
  REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT,
  REMOVE_SENDING_MESSAGE_ID_PROJECT,
  SET_ACTIVE_DRAFT_MESSAGE_ID_PROJECT,
} from '../../actions/projects/drafts';
import {
  DELETE_LOCAL_MESSAGE_PROJECT,
  DELETE_MESSAGES_PROJECT_REQUEST,
  SOFTDELETE_MESSAGES_PROJECT_REQUEST,
  UPDATE_MESSAGE_PROJECT,
} from '../../actions/projects/messages';
import { DraftMessagesState } from '../../actions/types';
import { distinct } from '../../../../util';

export interface DraftsMessagesStateByProjectId {
  [projectId: ProjectId]: DraftMessagesState;
}

export const initialState: DraftsMessagesStateByProjectId = {};

export const projectInitialState: DraftMessagesState = {
  openDrafts: [],
  initialMessageIds: [],
  activeDraftMessageId: null,
  sendingMessageIds: [],
};

const byId: Reducer<
  DraftsMessagesStateByProjectId,
  ReduxAction<
    Partial<{
      mailFolderId: MailFolderId;
      messageId: MessageId;
      messageIds: MessageId[];
      projectId: ProjectId;
    }>,
    Partial<Message & { messageUpdate: Partial<Message> }>
  > &
    Partial<{ projectId: ProjectId; messageId: MessageId }>
> = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT:
    case CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT: {
      const {
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: {
          openDrafts: openDrafts(
            state[projectId]?.openDrafts ?? projectInitialState.openDrafts,
            action
          ),
          initialMessageIds: initialMessageIds(
            state[projectId]?.initialMessageIds ??
              projectInitialState.initialMessageIds,
            action
          ),
          activeDraftMessageId: activeDraftMessageId(
            state[projectId]?.activeDraftMessageId ??
              projectInitialState.activeDraftMessageId,
            action
          ),
          sendingMessageIds: sendingMessageIds(
            state[projectId]?.sendingMessageIds ??
              projectInitialState.sendingMessageIds,
            action
          ),
        },
      };
    }

    case SET_ACTIVE_DRAFT_MESSAGE_ID_PROJECT: {
      const { projectId } = action;
      if (!state[projectId]) {
        return state;
      }
      return {
        ...state,
        [projectId]: {
          openDrafts: openDrafts(state[projectId]?.openDrafts, action),
          initialMessageIds: initialMessageIds(
            state[projectId]?.initialMessageIds,
            action
          ),
          activeDraftMessageId: activeDraftMessageId(
            state[projectId]?.activeDraftMessageId,
            action
          ),
          sendingMessageIds: sendingMessageIds(
            state[projectId]?.sendingMessageIds ??
              projectInitialState.sendingMessageIds,
            action
          ),
        },
      };
    }

    case ADD_DRAFT_MESSAGE_PROJECT: {
      const { projectId } = action;
      return {
        ...state,
        [projectId]: {
          openDrafts: openDrafts(
            state[projectId]?.openDrafts ?? projectInitialState.openDrafts,
            action
          ),
          initialMessageIds: initialMessageIds(
            state[projectId]?.initialMessageIds ??
              projectInitialState.initialMessageIds,
            action
          ),
          activeDraftMessageId: activeDraftMessageId(
            state[projectId]?.activeDraftMessageId ??
              projectInitialState.activeDraftMessageId,
            action
          ),
          sendingMessageIds: sendingMessageIds(
            state[projectId]?.sendingMessageIds ??
              projectInitialState.sendingMessageIds,
            action
          ),
        },
      };
    }

    case REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT:
    case REMOVE_DRAFT_MESSAGE_PROJECT: {
      const { projectId } = action;
      if (!state[projectId]) {
        return state;
      }
      return {
        ...state,
        [projectId]: {
          openDrafts: openDrafts(state[projectId]?.openDrafts, action),
          initialMessageIds: initialMessageIds(
            state[projectId]?.initialMessageIds,
            action
          ),
          activeDraftMessageId: activeDraftMessageId(
            state[projectId]?.activeDraftMessageId,
            action
          ),
          sendingMessageIds: sendingMessageIds(
            state[projectId]?.sendingMessageIds ??
              projectInitialState.sendingMessageIds,
            action
          ),
        },
      };
    }

    case DELETE_MESSAGES_PROJECT_REQUEST:
    case SOFTDELETE_MESSAGES_PROJECT_REQUEST:
    case DELETE_LOCAL_MESSAGE_PROJECT:
    case UPDATE_MESSAGE_PROJECT: {
      const {
        meta: { projectId },
      } = action;
      if (!state[projectId]) {
        return state;
      }
      return {
        ...state,
        [projectId]: {
          openDrafts: openDrafts(state[projectId]?.openDrafts, action),
          initialMessageIds: initialMessageIds(
            state[projectId]?.initialMessageIds,
            action
          ),
          activeDraftMessageId: activeDraftMessageId(
            state[projectId]?.activeDraftMessageId,
            action
          ),
          sendingMessageIds: sendingMessageIds(
            state[projectId]?.sendingMessageIds ??
              projectInitialState.sendingMessageIds,
            action
          ),
        },
      };
    }
    case ADD_SENDING_MESSAGE_ID_PROJECT: {
      const { projectId } = action;
      return {
        ...state,
        [projectId]: {
          openDrafts: openDrafts(
            state[projectId]?.openDrafts ?? projectInitialState.openDrafts,
            action
          ),
          initialMessageIds: initialMessageIds(
            state[projectId]?.initialMessageIds ??
              projectInitialState.initialMessageIds,
            action
          ),
          activeDraftMessageId: activeDraftMessageId(
            state[projectId]?.activeDraftMessageId ??
              projectInitialState.activeDraftMessageId,
            action
          ),
          sendingMessageIds: sendingMessageIds(
            state[projectId]?.sendingMessageIds ??
              projectInitialState.sendingMessageIds,
            action
          ),
        },
      };
    }

    case REMOVE_SENDING_MESSAGE_ID_PROJECT: {
      const { projectId } = action;
      return {
        ...state,
        [projectId]: {
          openDrafts: openDrafts(
            state[projectId]?.openDrafts ?? projectInitialState.openDrafts,
            action
          ),
          initialMessageIds: initialMessageIds(
            state[projectId]?.initialMessageIds ??
              projectInitialState.initialMessageIds,
            action
          ),
          activeDraftMessageId: activeDraftMessageId(
            state[projectId]?.activeDraftMessageId ??
              projectInitialState.activeDraftMessageId,
            action
          ),
          sendingMessageIds: sendingMessageIds(
            state[projectId]?.sendingMessageIds ??
              projectInitialState.sendingMessageIds,
            action
          ),
        },
      };
    }

    case RESET_PRIO_CACHE: {
      return Object.keys(state).reduce((acc, projectId) => {
        const projectState = state[projectId];
        if (projectState.sendingMessageIds) {
          return {
            ...acc,
            [projectId]: {
              ...projectState,
              sendingMessageIds: sendingMessageIds(
                projectState.sendingMessageIds,
                action
              ),
            },
          };
        }
        return acc;
      }, {} as DraftsMessagesStateByProjectId);
    }

    case DELETE_ALL_LOCAL_DRAFTS:
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

const openDrafts: Reducer<
  Message[],
  ReduxAction<
    Partial<{
      mailFolderId: MailFolderId;
      messageId: MessageId;
      messageIds: MessageId[];
    }>,
    Partial<Message & { messageUpdate: Partial<Message> }>
  >
> = (state = projectInitialState.openDrafts, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT:
    case CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT: {
      const { payload: newDraft } = action;
      return [...state, newDraft as Message];
    }

    case UPDATE_MESSAGE_PROJECT: {
      const {
        payload,
        meta: { messageId },
      } = action;
      const draftIndex = state.findIndex((draft) => draft.id === messageId);
      if (draftIndex > -1) {
        const newState = state;
        newState.splice(draftIndex, 1, {
          ...state[draftIndex],
          ...payload.messageUpdate,
        });
        return newState;
      }
      return state;
    }

    case DELETE_LOCAL_MESSAGE_PROJECT: {
      const {
        meta: { messageId },
      } = action;
      if (state.find((draft) => draft.id === messageId)) {
        return state.filter((draft) => draft.id !== messageId);
      }
      return state;
    }

    case SOFTDELETE_MESSAGES_PROJECT_REQUEST:
    case DELETE_MESSAGES_PROJECT_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      if (state.find((draftMessage) => messageIds.includes(draftMessage.id))) {
        return state.filter(
          (draftMessage) => !messageIds.includes(draftMessage.id)
        );
      }
      return state;
    }

    case ADD_DRAFT_MESSAGE_PROJECT: {
      const { message } = action;
      if (state.find((draft) => draft.id === message.id)) {
        return state;
      }
      return [...state, message];
    }

    case REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT:
    case REMOVE_DRAFT_MESSAGE_PROJECT: {
      const { messageId } = action;
      if (state.find((draft) => draft.id === messageId)) {
        return state.filter((draft) => draft.id !== messageId);
      }
      return state;
    }

    case CLEAR_PRIO_CACHE: {
      return projectInitialState.openDrafts;
    }

    default:
      return state;
  }
};

const initialMessageIds: Reducer<
  MessageId[],
  ReduxAction<
    Partial<{
      mailFolderId: MailFolderId;
      messageId: MessageId;
      messageIds: MessageId[];
    }>,
    Partial<Message>
  >
> = (state = projectInitialState.initialMessageIds, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT:
    case CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT: {
      const { payload: newDraft } = action;
      return [...state, newDraft.id];
    }

    case DELETE_LOCAL_MESSAGE_PROJECT:
    case UPDATE_MESSAGE_PROJECT: {
      const {
        meta: { messageId },
      } = action;
      return state.filter((initialMessageId) => initialMessageId !== messageId);
    }

    case REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT:
    case REMOVE_DRAFT_MESSAGE_PROJECT: {
      const { messageId } = action;
      return state.filter((initialMessageId) => initialMessageId !== messageId);
    }

    case ADD_DRAFT_MESSAGE_PROJECT: {
      const { message, isInitial } = action;
      if (isInitial) {
        return [...state, message.id];
      }
      return state;
    }

    case SOFTDELETE_MESSAGES_PROJECT_REQUEST:
    case DELETE_MESSAGES_PROJECT_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      if (state.find((draftMessageId) => messageIds.includes(draftMessageId))) {
        return state.filter(
          (draftMessageId) => !messageIds.includes(draftMessageId)
        );
      }
      return state;
    }

    case CLEAR_PRIO_CACHE: {
      return projectInitialState.initialMessageIds;
    }

    default:
      return state;
  }
};

const activeDraftMessageId: Reducer<
  MessageId,
  ReduxAction<
    Partial<{
      mailFolderId: MailFolderId;
      messageId: MessageId;
      messageIds: MessageId[];
    }>,
    Partial<Message>
  >
> = (state = projectInitialState.activeDraftMessageId, action) => {
  switch (action.type) {
    case CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT:
    case CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT: {
      const { payload: newDraft } = action;
      return newDraft.id;
    }

    case SET_ACTIVE_DRAFT_MESSAGE_ID_PROJECT: {
      return action.messageId;
    }

    case DELETE_LOCAL_MESSAGE_PROJECT: {
      const {
        meta: { messageId },
      } = action;
      if (messageId !== state) {
        return state;
      }
      return projectInitialState.activeDraftMessageId;
    }

    case ADD_DRAFT_MESSAGE_PROJECT: {
      const { message } = action;
      return message.id;
    }

    case REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT:
    case REMOVE_DRAFT_MESSAGE_PROJECT: {
      const { messageId } = action;
      if (messageId !== state) {
        return state;
      }
      return projectInitialState.activeDraftMessageId;
    }

    case SOFTDELETE_MESSAGES_PROJECT_REQUEST:
    case DELETE_MESSAGES_PROJECT_REQUEST: {
      const {
        meta: { messageIds },
      } = action;
      if (messageIds.includes(state)) {
        return projectInitialState.activeDraftMessageId;
      }
      return state;
    }

    case CLEAR_PRIO_CACHE: {
      return projectInitialState.activeDraftMessageId;
    }

    default:
      return state;
  }
};

const sendingMessageIds: Reducer<
  MessageId[],
  ReduxAction & { messageId?: MessageId }
> = (state = projectInitialState.sendingMessageIds, action) => {
  switch (action.type) {
    case ADD_SENDING_MESSAGE_ID_PROJECT: {
      const { messageId } = action;
      return distinct([...state, messageId]);
    }
    case REMOVE_SENDING_MESSAGE_ID_PROJECT: {
      const { messageId } = action;
      return state.filter((id) => id !== messageId);
    }
    case RESET_PRIO_CACHE:
    case DELETE_ALL_LOCAL_DRAFTS:
    case CLEAR_PRIO_CACHE: {
      return projectInitialState.sendingMessageIds;
    }

    default:
      return state;
  }
};

export default byId;

export const getDraftMessages = (
  state: DraftsMessagesStateByProjectId,
  projectId: ProjectId
) => state[projectId]?.openDrafts ?? projectInitialState.openDrafts;

export const getDraftMessage = (
  state: DraftsMessagesStateByProjectId,
  projectId: ProjectId,
  messageId: MessageId
) => state[projectId]?.openDrafts?.find((draft) => draft.id === messageId);

export const getDraftMessageIsInitial = (
  state: DraftsMessagesStateByProjectId,
  projectId: ProjectId,
  messageId: MessageId
) => state[projectId]?.initialMessageIds?.includes(messageId);

export const getActiveDraftMessageId = (
  state: DraftsMessagesStateByProjectId,
  projectId: ProjectId
) => state[projectId]?.activeDraftMessageId;

export const getSendingMessageIds = (state: DraftsMessagesStateByProjectId) =>
  Object.keys(state).reduce((acc, projectId) => {
    const sendingMessageIds = state[projectId].sendingMessageIds;
    if (sendingMessageIds) {
      return [...acc, ...sendingMessageIds];
    }
    return acc;
  }, [] as MessageId[]);
