import React, { useCallback, useEffect, useState } from 'react';
import TrainingItem from './TrainingItem';
import Flex from '../../../components/Flex';
import { TrainingOverview } from '../../../models/Training';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DepartmentsById,
  QualificationsById,
} from '../../hr/components/HRTrainings';
import {
  apiFetchDepartments,
  apiFetchQualifications,
  apiFetchTrainingMe,
} from '../../hr/api';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  spin: {
    position: 'relative',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
}));

interface TrainingListProps {}

export const TrainingList: React.FC<TrainingListProps> = (props) => {
  const classes = useStyles();

  const [trainingOverviews, setTrainingOverviews] = useState<
    TrainingOverview[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [qualificationsById, setQualificationsById] =
    useState<QualificationsById>({});
  const [departmentsById, setDepartmentsById] = useState<DepartmentsById>({});
  const { t } = useTranslation();
  const fetchQualifications = useCallback(async () => {
    const { data } = await apiFetchQualifications();
    if (data && Array.isArray(data)) {
      const byId = data.reduce(function (map, item) {
        map[item.qualificationId] = item;
        return map;
      }, {});
      setQualificationsById(byId);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.fetchQualificationsError'),
      });
    }
  }, [t]);

  const fetchDepartments = useCallback(async () => {
    const { data } = await apiFetchDepartments();
    if (data && Array.isArray(data)) {
      const byId = data.reduce(function (map, item) {
        map[item.departmentId] = item;
        return map;
      }, {});
      setDepartmentsById(byId);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.fetchDepartmentsError'),
      });
    }
  }, [t]);

  const fetchTrainingOverviews = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await apiFetchTrainingMe();
      if (data && Array.isArray(data)) {
        setTrainingOverviews(data);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:trainings.errorMessages.fetchTrainingsOverviews'),
        });
      }
    } catch {
      console.error('error occured during fecth of training overview');
    }
    setIsLoading(false);
  }, [t]);

  useEffect(() => {
    fetchTrainingOverviews();
    fetchQualifications();
    fetchDepartments();
  }, [fetchTrainingOverviews, fetchQualifications, fetchDepartments]);

  if (isLoading) {
    return (
      <Flex.Column className={classes.spin} alignItems="center">
        <PrioSpinner size="large" />
      </Flex.Column>
    );
  }

  return (
    <Flex.Column className={classes.root}>
      {trainingOverviews.map((trainingOverview) => (
        <TrainingItem
          key={trainingOverview.trainingId}
          trainingOverview={trainingOverview}
          qualificationsById={qualificationsById}
          departmentsById={departmentsById}
        />
      ))}
    </Flex.Column>
  );
};

export default TrainingList;
