import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Typography } from 'antd';
import { useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { getDocumentSettings } from '../../../../apps/main/rootReducer';
import DocumentSettingsForm from '../Forms/DocumentSettingsForm';
import { makePrioStyles } from '../../../../theme/utils';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  drawerContent: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {},
}));

interface DocumentSettingsDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const DocumentSettingsDrawer: React.FC<DocumentSettingsDrawerProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { open, onClose } = props;
  //#endregion
  //#region -------------------------------- Selectors
  const documentSettings = useSelector(getDocumentSettings);
  //#endregion
  //#region -------------------------------- States
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks
  //#endregion

  return (
    <Drawer
      width={theme.old.components.drawerWidth}
      placement="right"
      visible={open}
      className={classes.root}
      destroyOnClose={true}
      onClose={onClose}
    >
      <div className={classes.drawerContent}>
        <Typography.Title level={2}>
          {t('documents:settings.title')}
        </Typography.Title>
        <DocumentSettingsForm initialValues={documentSettings} />
      </div>
    </Drawer>
  );
};

export default DocumentSettingsDrawer;
