import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { Trans, useTranslation } from 'react-i18next';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import TimeRecordsMenu from './TimeRecordsMenu';
import TimeRecordsPage from './TimeRecordsPage';
import { fetchMyTimeRecords } from '../actions';
import NavigationBar from '../../../components/NavigationBar';
import { ProjectId } from '../../../models/Types';
import classNames from 'classnames';
import { openTimeAndLeaveManagementDrawer } from '../../timeAndLeaveManagement/actions';
import * as Sentry from '@sentry/react';

import { PrioTheme } from '../../../theme/types';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  body: {
    overflow: 'hidden',
  },
  menu: {
    maxWidth: theme.old.components.menuMaxWidth,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  page: {
    height: '100%',
  },
  content: {
    overflow: 'hidden',
  },
}));

interface TimeRecordSubModuleProps {
  projectId: ProjectId;
}

export const TimeRecordSubModule: React.FC<TimeRecordSubModuleProps> = (
  props
) => {
  const { projectId } = props;
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchMyTimeRecords());
  }, [dispatch]);
  const { t } = useTranslation();

  const showTimeRecordDrawer = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeRecords' }));

  if (location.pathname.match(/^.*\/projects\/.*\/timeRecord\/?$/))
    return <Navigate to="my" />;

  return (
    <Flex.Column className={classes.root}>
      <NavigationBar>
        <Button onClick={showTimeRecordDrawer} iconProp={['fal', 'pencil']}>
          <span>{t('common:navigationBar.recordTime')}</span>
        </Button>
      </NavigationBar>
      <Flex.Row flex={1} className={classes.body}>
        <Routes>
          <Route
            path="/"
            element={
              <TimeRecordsMenu
                className={classNames(classes.menu, 'prio-module-navigation')}
                projectId={projectId}
              />
            }
          />
          <Route
            path="/:selectedList"
            element={
              <TimeRecordsMenu
                className={classNames(classes.menu, 'prio-module-navigation')}
                projectId={projectId}
                pathPrefix="../"
              />
            }
          />
        </Routes>
        <Flex.Item flex={1} className={classes.content}>
          <Routes>
            <Route
              path="my"
              element={
                <TimeRecordsPage className={classes.page} contextType="me" />
              }
            />
            <Route
              path="all"
              element={
                <TimeRecordsPage
                  className={classes.page}
                  contextType="project"
                />
              }
            />
            <Route
              //path=":mailFolderId"
              element={
                <div className="prio-flex-center-center prio-flex-column">
                  <Typography.Text>
                    <Trans i18nKey={'timeRecords:noMenuItemSelected'} />
                  </Typography.Text>
                </div>
              }
            />
          </Routes>
        </Flex.Item>
      </Flex.Row>
    </Flex.Column>
  );
};

export default TimeRecordSubModule;
