import React, { ReactNode } from 'react';
import Flex from './Flex';
import { makePrioStyles } from '../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: 64,
    minHeight: 64,
    overflow: 'hidden',
  },
  contextPart: {
    paddingLeft: theme.old.spacing.defaultPadding,
    height: 64,
    minHeight: 64,
    overflow: 'hidden',
  },
}));

interface NavigationBarProps {
  className?: string;
  children?: ReactNode;
}

export const NavigationBar: React.FC<NavigationBarProps> = (props) => {
  const classes = useStyles();
  const { children } = props;
  const theme = useTheme<PrioTheme>();

  return (
    <>
      <Flex.Row id="prio-module-navigation-bar" className={classes.root}>
        <Flex.Row
          className={classes.contextPart}
          flex={1}
          alignItems="center"
          childrenGap={theme.old.spacing.unit(1)}
        >
          {children}
        </Flex.Row>
      </Flex.Row>
    </>
  );
};

export default NavigationBar;
