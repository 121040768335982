import { notification } from 'antd';
import {
  actionChannel,
  call,
  spawn,
  race,
  select,
  take,
  delay,
  takeLatest,
  cancelled,
} from 'redux-saga/effects';
import {
  RootReducerState,
  getCurrentFolderNextLink,
  getDocumentsCachingEnabled,
  getDriveItemIsFetching,
  getLastOpenDriveItemFolder,
  getWidgetAreaActiveProjectId,
  getWidgetAreaDisplayState,
  isLoggedIn,
} from '../../../apps/main/rootReducer';
import i18n from '../../../i18n';
import { dispatchSaga, SAGA_REBUILD } from '../../../util/sagas';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import {
  FetchDriveItemsSagaAction,
  START_FETCH_DRIVE_ITEMS_SAGA,
  fetchDriveItems,
  fetchDriveItemsWithCaching,
} from '../actions';
import { DriveItemId, ProjectId } from '../../../models/Types';
import { WidgetAreaDisplayState } from '../../widgetArea/reducers';

const documentsPattern =
  /\/module\/prio\/projects\/([a-z\-\d]+)\/documents\/((all)|(folder\/([0-9A-Z]+)))$/;

function* shouldNotFetch(projectId: ProjectId, driveItemId: DriveItemId) {
  const url = window.location.href;
  if (documentsPattern.test(url)) {
    const match = url.match(documentsPattern);
    if (projectId === match[1]) {
      let folderId: DriveItemId = null;
      const [, , , , , _folderId] = match;
      if (!!_folderId) {
        folderId = _folderId;
      }

      if (folderId === driveItemId || (!folderId && !driveItemId)) {
        return true;
      }
    }
  }

  const widgetAreaDisplayState: WidgetAreaDisplayState = yield select(
    getWidgetAreaDisplayState
  );

  const widgetAreaProjectId: ProjectId = yield select(
    getWidgetAreaActiveProjectId
  );

  const widgetBarLastOpenDriveFolderId = yield select(
    (state: RootReducerState) =>
      getLastOpenDriveItemFolder(state, widgetAreaProjectId)?.documentsWidget
  );

  const { open, selectedWidget } = widgetAreaDisplayState;

  if (
    open &&
    selectedWidget === 'documents' &&
    widgetAreaProjectId === projectId &&
    (widgetBarLastOpenDriveFolderId === driveItemId ||
      (!driveItemId && !widgetBarLastOpenDriveFolderId))
  ) {
    return true;
  }

  return false;
}

function* handleDriveItemsFetch(action: FetchDriveItemsSagaAction) {
  const {
    driveItemFolderId,
    groupId,
    fetchWithNextLink,
    isRoot,
    size,
    projectId,
    ignoreShouldNotFetch,
    revertShouldNotFetchMechanism,
  } = action;

  try {
    const nextLink = yield select((state) =>
      getCurrentFolderNextLink(
        state,
        isRoot ? `root-group-${groupId}` : driveItemFolderId
      )
    );

    const { isFetching } = yield select((state) =>
      getDriveItemIsFetching(
        state,
        isRoot ? `root-group-${groupId}` : driveItemFolderId
      )
    );

    const isDocumentsCachingEnabled = yield select((state) =>
      getDocumentsCachingEnabled(state)
    );

    let _shouldNotFetch = yield call(
      shouldNotFetch,
      projectId,
      driveItemFolderId
    );

    if (revertShouldNotFetchMechanism !== undefined) {
      if (revertShouldNotFetchMechanism) {
        _shouldNotFetch = !_shouldNotFetch;
      }
    }
    if (!isFetching && (ignoreShouldNotFetch || !_shouldNotFetch)) {
      if (isDocumentsCachingEnabled) {
        yield spawn(
          dispatchSaga,
          fetchDriveItemsWithCaching(
            projectId,
            groupId,
            driveItemFolderId,
            isRoot
          )
        );
      } else {
        if (fetchWithNextLink) {
          if (nextLink) {
            yield spawn(
              dispatchSaga,
              fetchDriveItems(
                groupId,
                driveItemFolderId,
                nextLink,
                size,
                isRoot
              )
            );
          }
        } else {
          yield spawn(
            dispatchSaga,
            fetchDriveItems(groupId, driveItemFolderId, null, size, isRoot)
          );
        }
      }
      yield delay(10);
    }
  } catch (error) {
    console.error('Error in handleDriveItemsFetch', error, action);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('documents:errorMessages.fetchDriveItemsError'),
    });
  }
}

function* actionPipeline() {
  let lastAction: FetchDriveItemsSagaAction = null;
  try {
    const channel = yield actionChannel(START_FETCH_DRIVE_ITEMS_SAGA);

    while (true) {
      const payload = yield take(channel);
      lastAction = payload;
      yield call(handleDriveItemsFetch, payload);
      lastAction = null;
    }
  } finally {
    if (yield cancelled() && lastAction !== null) {
      yield call(handleDriveItemsFetch, lastAction);
      lastAction = null;
    }
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(actionPipeline), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in watchDriveItemsFetch - mainTask', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('documents:errorMessages.fetchDriveItemsError'),
    });
    yield mainTask();
  }
}

export default function* watchDriveItemsFetch() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
