import React, { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import { HeaderGroup } from 'react-table';
import Flex from '../Flex';
import { Checkbox, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { Paths } from '../../util/GenericHelper';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumberSize, Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import useResizeObserver from 'use-resize-observer';
import { ColumnWidthsAction, ColumnWidthsState } from './VirtualTable2';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  content: {
    marginRight: 6,
  },
  checkBoxContainer: {
    width: 50,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  columnRow: {
    position: 'relative',
    overflow: 'hidden',
  },
  columnCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 50,
  },
  columnCellContent: {
    color: theme.old.palette.primaryColor,
    whiteSpace: 'nowrap',
  },
  generalCellContent: {
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    '& > span': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      flex: 1,
    },
  },
  grayHover: {
    '&:hover': {
      backgroundColor: 'rgba(0 ,0, 0, 0.04)',
    },
  },
  resizeHandle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    '&::before': {
      content: '""',
      height: '50%',
      width: 1,
      backgroundColor: theme.old.borders.colors.content,
    },
  },
  resizeHandleResizable: {
    '&:hover::before': {
      width: 4,
    },
  },
}));

const getSorterToolTipName = <DataType extends object>(
  column: IHeaderGroup<DataType>
) => {
  if (column.isSortedDesc) {
    return 'Sortierung aufheben';
  }

  if (column.isSortedDesc === false) {
    return 'Absteigend sortieren';
  }

  if (column.isSortedDesc === undefined) {
    return 'Aufsteigend Sortieren';
  }
  return '';
};

export interface IHeaderGroup<DataType extends object>
  extends HeaderGroup<DataType> {
  title?: string;
  width?: number;
  accessor?: Paths<DataType>;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  disableHover: boolean;
}

declare type ResizeCallback<DataType extends object> = (
  headers: IHeaderGroup<DataType>[],
  column: IHeaderGroup<DataType>,
  columnIndex: number,
  direction: Direction,
  elementRef: HTMLElement,
  delta: NumberSize
) => void;

interface VirtualTableHeaderProps<DataType extends object> {
  className?: string;
  columnRowHeight: number;
  headerGroups: HeaderGroup<DataType>[];
  rowsAreSelectable?: boolean;
  selectedRowsLength: number;
  itemsLength: number;
  columnsResizable?: boolean;
  tableId: React.Key;
  onSelectAll?: (selectAll: boolean) => void;
  columnWidths: ColumnWidthsState;
  setColumnWidths: (value: ColumnWidthsAction) => void;
}

export const VirtualTableHeader = <DataType extends object>(
  props: VirtualTableHeaderProps<DataType>
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    columnRowHeight,
    headerGroups,
    rowsAreSelectable,
    selectedRowsLength,
    itemsLength,
    columnsResizable,
    onSelectAll,
    tableId,
    columnWidths,
    setColumnWidths,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { ref, width: tableWidth } = useResizeObserver<HTMLDivElement>();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSelectAll = (e: CheckboxChangeEvent) => {
    if (onSelectAll) {
      onSelectAll(e.target.checked);
    }
  };

  const handleOnResize: ResizeCallback<DataType> = (
    headers,
    column,
    columnIndex,
    direction,
    ref,
    delta
  ) => {
    const minWidth = column?.minWidth > 0 ? column?.minWidth : 50;
    const minWidthInPercent = (minWidth / tableWidth) * 100;
    const minWidthNextHeader =
      headers[columnIndex + 1]?.minWidth > 0
        ? headers[columnIndex + 1]?.minWidth
        : 50;
    const minWidthNextHeaderInPercent = (minWidthNextHeader / tableWidth) * 100;

    const list = document.getElementsByClassName(`${tableId}-${column.id}`);
    for (let i = 0; i < list.length; i++) {
      (list.item(i) as HTMLDivElement).style.width = ref.style.width;
    }

    const columnWidth = Math.max(minWidthInPercent, columnWidths[column.id]);
    const refWidth = Math.max(minWidthInPercent, parseFloat(ref.style.width));
    const nextColumnWidth = Math.max(
      minWidthNextHeaderInPercent,
      columnWidths[headers[columnIndex + 1].id]
    );

    const headerList = document.getElementsByClassName(
      `${tableId}-${column.id}-header`
    );
    for (let i = 0; i < headerList.length; i++) {
      (headerList.item(i) as HTMLDivElement).style.maxWidth = `${(
        columnWidth +
        nextColumnWidth -
        minWidthNextHeaderInPercent
      ).toFixed(5)}%`;
    }

    const headerList2 = document.getElementsByClassName(
      `${tableId}-${headers[columnIndex + 1].id}-header`
    );

    for (let i = 0; i < headerList2.length; i++) {
      (headerList2.item(i) as HTMLDivElement).style.width = `${(
        columnWidth -
        refWidth +
        nextColumnWidth
      ).toFixed(5)}%`;
      (headerList2.item(i) as HTMLDivElement).style.maxWidth = `${(
        columnWidth -
        refWidth +
        nextColumnWidth
      ).toFixed(5)}%`;
    }

    const list2 = document.getElementsByClassName(
      `${tableId}-${headers[columnIndex + 1].id}`
    );
    for (let i = 0; i < list2.length; i++) {
      (list2.item(i) as HTMLDivElement).style.width = `${(
        columnWidth -
        refWidth +
        nextColumnWidth
      ).toFixed(5)}%`;
    }
  };

  const handleOnResizeStop: ResizeCallback<DataType> = (
    headers,
    column,
    columnIndex,
    direction,
    ref,
    delta
  ) => {
    //persist
    const minWidth = column?.minWidth > 0 ? column?.minWidth : 50;
    const minWidthInPercent = (minWidth / tableWidth) * 100;
    const minWidthNextHeader =
      headers[columnIndex + 1]?.minWidth > 0
        ? headers[columnIndex + 1]?.minWidth
        : 50;
    const minWidthNextHeaderInPercent = (minWidthNextHeader / tableWidth) * 100;

    const columnWidth = Math.max(minWidthInPercent, columnWidths[column.id]);
    const refWidth = Math.max(minWidthInPercent, parseFloat(ref.style.width));
    const nextColumnWidth = Math.max(
      minWidthNextHeaderInPercent,
      columnWidths[headers[columnIndex + 1].id]
    );
    setColumnWidths({
      type: 'UPDATE',
      update: {
        [column.id]: parseFloat(ref.style.width),
        [headers[columnIndex + 1].id]: parseFloat(
          (columnWidth - refWidth + nextColumnWidth).toFixed(5)
        ),
      },
    });

    const headerList = document.getElementsByClassName(
      `${tableId}-${column.id}-header`
    );
    for (let i = 0; i < headerList.length; i++) {
      (headerList.item(i) as HTMLDivElement).style.maxWidth = `${(
        columnWidth +
        nextColumnWidth -
        minWidthNextHeaderInPercent
      ).toFixed(5)}%`;
    }
  };
  //#endregion

  //#region ------------------------------ Components
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      {headerGroups.map((headerGroup) => (
        <div {...headerGroup.getHeaderGroupProps()}>
          <Flex.Row className={classes.content}>
            {rowsAreSelectable && (
              <div
                className={classNames(
                  classes.checkBoxContainer,
                  'prio-vTable-checkBoxContainer'
                )}
              >
                <Checkbox
                  indeterminate={
                    selectedRowsLength >= 1 &&
                    selectedRowsLength !== itemsLength
                  }
                  checked={
                    selectedRowsLength === itemsLength && itemsLength !== 0
                  }
                  onChange={handleSelectAll}
                />
              </div>
            )}
            <Flex.Row
              justifyContent="start"
              className={classes.columnRow}
              flex={1}
              ref={ref}
            >
              {headerGroup.headers.map(
                (column: IHeaderGroup<DataType>, columnIndex) => {
                  const { title: _, ...headerProps } = column.getHeaderProps(
                    column.getSortByToggleProps()
                  ) as any;
                  if (
                    columnsResizable &&
                    columnIndex + 1 < headerGroup.headers.length
                  ) {
                    const minWidth =
                      column?.minWidth > 0 ? column?.minWidth : 50;
                    const minWidthInPercent = (minWidth / tableWidth) * 100;
                    const minWidthNextHeader =
                      headerGroup.headers[columnIndex + 1]?.minWidth > 0
                        ? headerGroup.headers[columnIndex + 1]?.minWidth
                        : 50;
                    const minWidthNextHeaderInPercent =
                      (minWidthNextHeader / tableWidth) * 100;

                    const columnWidth = Math.max(
                      minWidthInPercent,
                      columnWidths[column.id]
                    );
                    const nextColumnWidth = Math.max(
                      minWidthNextHeaderInPercent,
                      columnWidths[headerGroup.headers[columnIndex + 1].id]
                    );
                    return (
                      <Resizable
                        key={`${tableId}-${column.id}-header`}
                        className={classNames(
                          `${tableId}-${column.id}-header`,
                          !column.disableHover && classes.grayHover,
                          classes.columnCell
                        )}
                        enable={{
                          top: false,
                          right: true,
                          left: false,
                          bottom: false,
                          topRight: false,
                          bottomRight: false,
                          bottomLeft: false,
                          topLeft: false,
                        }}
                        minWidth={column?.minWidth > 0 ? column?.minWidth : 50}
                        maxWidth={`${(
                          columnWidth +
                          nextColumnWidth -
                          minWidthNextHeaderInPercent
                        ).toFixed(5)}%`}
                        size={{
                          height: columnRowHeight,
                          width: `${columnWidths[column.id]}%`,
                        }}
                        onResize={(e, d, r, delta) => {
                          handleOnResize(
                            headerGroup.headers as IHeaderGroup<DataType>[],
                            column,
                            columnIndex,
                            d,
                            r,
                            delta
                          );
                        }}
                        onResizeStop={(e, d, r, delta) => {
                          handleOnResizeStop(
                            headerGroup.headers as IHeaderGroup<DataType>[],
                            column,
                            columnIndex,
                            d,
                            r,
                            delta
                          );
                        }}
                        handleClasses={{
                          right: classNames(classes.resizeHandle, {
                            [classes.resizeHandleResizable]: columnsResizable,
                          }),
                        }}
                        handleStyles={
                          !columnsResizable && {
                            right: {
                              cursor: 'unset',
                            },
                          }
                        }
                      >
                        <TooltipWrapper
                          title={
                            column.sortType
                              ? getSorterToolTipName(column)
                              : undefined
                          }
                          mkey={`${tableId}-${column.id}-header`}
                        >
                          <TableHeaderCell
                            tableId={tableId}
                            column={column}
                            headerProps={headerProps}
                          />
                        </TooltipWrapper>
                      </Resizable>
                    );
                  }
                  return (
                    <TooltipWrapper
                      title={
                        column.sortType
                          ? getSorterToolTipName(column)
                          : undefined
                      }
                      mkey={`${tableId}-${column.id}-header`}
                    >
                      <div
                        className={classNames(
                          `${tableId}-${column.id}-header`,
                          classes.columnCell
                        )}
                        style={{
                          width: `${columnWidths[column.id]}%`,
                          minWidth:
                            column?.minWidth > 0 ? column?.minWidth : 50,
                          position: 'relative',
                        }}
                      >
                        <TableHeaderCell
                          tableId={tableId}
                          column={column}
                          headerProps={headerProps}
                        />
                        {columnIndex + 1 < headerGroup.headers.length && (
                          <div>
                            <div
                              className={classes.resizeHandle}
                              style={{
                                position: 'absolute',
                                width: 10,
                                height: '100%',
                                top: 0,
                                right: -5,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </TooltipWrapper>
                  );
                }
              )}
            </Flex.Row>
          </Flex.Row>
        </div>
      ))}
    </div>
  );
};

export default VirtualTableHeader;

interface TooltipWrapperProps {
  children?: JSX.Element;
  title?: string;
  mkey: React.Key;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = (props) => {
  const { children, title, mkey } = props;

  if (!!title) {
    return (
      <Tooltip key={mkey} title={title}>
        {children}
      </Tooltip>
    );
  }

  return children;
};

interface TableHeaderCellProps<DataType extends object> {
  className?: string;
  tableId: React.Key;
  column: IHeaderGroup<DataType>;
  headerProps: any;
  style?: CSSProperties;
}

const TableHeaderCell = <DataType extends object>(
  props: TableHeaderCellProps<DataType>
) => {
  const { className, column, headerProps, style, tableId } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const title = useMemo(() => {
    return column.render('title');
  }, [column]);

  return (
    <div
      key={`${tableId}-${column.id}-header`}
      className={classNames(className)}
      style={style}
      title={typeof title === 'string' ? title : undefined}
    >
      <div
        className={classNames(
          classes.columnCellContent,
          classes.generalCellContent
        )}
        {...headerProps}
      >
        <span>{title}</span>
        {column.isSortedDesc !== undefined && (
          <FontAwesomeIcon
            icon={[
              'fal',
              column.isSortedDesc ? 'long-arrow-up' : 'long-arrow-down',
            ]}
            color={theme.old.typography.colors.muted}
          />
        )}
      </div>
    </div>
  );
};
