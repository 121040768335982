import { combineReducers, Reducer } from 'redux';
import {
  FETCH_MAIL_FOLDERS_ME_REQUEST,
  FETCH_MAIL_FOLDERS_ME_ROLLBACK,
  FETCH_MAIL_FOLDERS_ME_COMMIT,
  UPDATE_UNREAD_ITEM_COUNT_ME,
  UPDATE_ACTIVE_MAILFOLDER_ME,
  SET_OPEN_MAILFOLDER_KEYS_ME,
  ADD_OPEN_MAILFOLDER_KEY_ME,
  RENAME_MAIL_FOLDER_ME_COMMIT,
  RENAME_MAIL_FOLDER_ME_ROLLBACK,
} from '../../actions/me/mailFoldersMe';
import { MailFolder } from '../../../../models/MailFolder';
import { MailFolderId } from '../../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import {
  MailFolderByMailFolderId,
  MailFolderIdEntry,
} from '../../actions/types';
import {
  COPY_MAIL_ME_TO_PROJECT_COMMIT,
  MARK_AS_READ_MESSAGE_ME_COMMIT,
  MOVE_MESSAGE_ME_COMMIT,
  WS_REVERT_MESSAGE_DELETE_ME,
  WS_REVERT_MESSAGE_MOVE_ME,
} from '../../actions/me/messagesMe';
import { SAGA_REBUILD } from '../../../../util/sagas';
import { ReduxAction } from '../../../../models/Redux';
import { WS_EMAIL_ME_CREATED } from '../../actions/ws';
import { DELETE_LOCAL_MESSAGES } from '../../actions/actionControllers/messageActionController';
import { Message } from '../../../../models/Message';

export interface MailFoldersState {
  byId: MailFolderByMailFolderId;
  ids: Array<MailFolderIdEntry>;
  meta: MailFoldersMeta;
  activeMailFolder: ActiveMailFolderState;
  openMailFolderKeys: MailFolderId[];
}

const reducerFunction = (
  mailFolders: Array<MailFolder>,
  state: MailFolderByMailFolderId
) =>
  mailFolders.reduce(
    (map, item) => ({
      ...(item.childFolder ? reducerFunction(item.childFolder, map) : map),
      [item.id]: {
        ...item,
        childFolder: undefined,
      },
    }),
    state
  );

const byId: Reducer<MailFolderByMailFolderId, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MAIL_FOLDERS_ME_COMMIT: {
      const { payload } = action;
      return reducerFunction(payload as Array<MailFolder>, { ...state });
    }
    case UPDATE_UNREAD_ITEM_COUNT_ME: {
      const { mailFolderId, itemCount, option } = action;
      const mailFolder = state[mailFolderId];
      return {
        ...state,
        [mailFolderId]: {
          ...mailFolder,
          unreadItemCount:
            option === 'add'
              ? (mailFolder?.unreadItemCount ?? 0) + itemCount
              : (mailFolder?.unreadItemCount ?? 0) > 0
              ? (mailFolder?.unreadItemCount ?? 0) - itemCount
              : 0,
        },
      };
    }

    case MOVE_MESSAGE_ME_COMMIT: {
      const {
        meta: { destinationId, originId, unreadItemCount, inboxFolderId },
      } = action;
      const originMailFolder =
        state[originId === 'inbox' ? inboxFolderId : originId];
      const destinationMailFolder =
        state[destinationId === 'inbox' ? inboxFolderId : destinationId];

      if (unreadItemCount === 0) {
        return state;
      }
      return {
        ...state,
        ...(originMailFolder
          ? {
              [originMailFolder.id]: {
                ...originMailFolder,
                unreadItemCount:
                  originMailFolder?.unreadItemCount > 0
                    ? originMailFolder?.unreadItemCount - unreadItemCount
                    : 0,
              },
            }
          : {}),
        ...(destinationMailFolder
          ? {
              [destinationMailFolder.id]: {
                ...destinationMailFolder,
                unreadItemCount:
                  (destinationMailFolder?.unreadItemCount ?? 0) +
                  unreadItemCount,
              },
            }
          : {}),
      };
    }

    case COPY_MAIL_ME_TO_PROJECT_COMMIT: {
      const {
        meta: { mailFolderId, messages },
      } = action;
      const originMailFolder =
        state[
          mailFolderId === 'inbox' ? messages[0]?.parentFolderId : mailFolderId
        ];

      const unreadItemCount = messages.filter(({ isRead }) => !isRead).length;
      if (unreadItemCount === 0) {
        return state;
      }
      return {
        ...state,
        ...(originMailFolder
          ? {
              [originMailFolder.id]: {
                ...originMailFolder,
                unreadItemCount:
                  originMailFolder?.unreadItemCount > 0
                    ? originMailFolder?.unreadItemCount - unreadItemCount
                    : 0,
              },
            }
          : {}),
      };
    }

    case WS_EMAIL_ME_CREATED: {
      const {
        payload: { message },
        meta: { wsMailFolderId, messageAlreadyExists },
      } = action;
      if (messageAlreadyExists) {
        return state;
      }
      const mailFolder = state[wsMailFolderId];
      return {
        ...state,
        ...(mailFolder
          ? {
              [mailFolder.id]: {
                ...mailFolder,
                unreadItemCount: !message.isRead
                  ? mailFolder?.unreadItemCount >= 0
                    ? mailFolder?.unreadItemCount + 1
                    : 0
                  : mailFolder?.unreadItemCount,
              },
            }
          : {}),
      };
    }

    case WS_REVERT_MESSAGE_MOVE_ME: {
      const { destinationId, message } = action;
      if (!(message as Message).isRead) {
        const sourceId = message.parentFolderId;

        const sourceFolder = state[sourceId];
        const destinationFolder = state[destinationId];
        return {
          ...state,
          ...(sourceFolder
            ? {
                [sourceId]: {
                  ...sourceFolder,
                  unreadItemCount: sourceFolder.unreadItemCount + 1,
                },
              }
            : {}),
          ...(destinationFolder
            ? {
                [destinationId]: {
                  ...destinationFolder,
                  unreadItemCount:
                    destinationFolder.unreadItemCount === 0
                      ? 0
                      : destinationFolder.unreadItemCount - 1,
                },
              }
            : {}),
        };
      }
      return state;
    }

    case WS_REVERT_MESSAGE_DELETE_ME: {
      const { message } = action;
      if (!(message as Message).isRead) {
        const sourceId = message.parentFolderId;
        const sourceFolder = state[sourceId];
        return {
          ...state,
          ...(sourceFolder
            ? {
                [sourceId]: {
                  ...sourceFolder,
                  unreadItemCount: sourceFolder.unreadItemCount + 1,
                },
              }
            : {}),
        };
      }
      return state;
    }

    case RENAME_MAIL_FOLDER_ME_COMMIT: {
      const {
        meta: { mailFolderId, newDisplayName },
      } = action;
      const mailFolder = state[mailFolderId];
      return {
        ...state,
        [mailFolderId]: {
          ...mailFolder,
          displayName: newDisplayName,
        },
      };
    }

    case RENAME_MAIL_FOLDER_ME_ROLLBACK: {
      const {
        meta: { mailFolderId, oldDisplayName },
      } = action;
      const mailFolder = state[mailFolderId];
      return {
        ...state,
        [mailFolderId]: {
          ...mailFolder,
          displayName: oldDisplayName,
        },
      };
    }

    case MARK_AS_READ_MESSAGE_ME_COMMIT: {
      const {
        meta: { mailFolderId, isRead, changedUnreadItemCount },
      } = action;

      const mailFolder = state[mailFolderId];

      if (mailFolder) {
        const unreadItemCount =
          mailFolder.unreadItemCount +
          changedUnreadItemCount * (isRead ? -1 : 1);
        return {
          ...state,
          [mailFolderId]: {
            ...mailFolder,
            unreadItemCount: unreadItemCount >= 0 ? unreadItemCount : 0,
          },
        };
      }

      return state;
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const mapMailFolderToIdEntry = (mailFolders: Array<MailFolder>) =>
  mailFolders.map((item) => ({
    id: item.id,
    childFolder: item.childFolder
      ? mapMailFolderToIdEntry(item.childFolder)
      : undefined,
  }));

const ids: Reducer<Array<MailFolderIdEntry>, any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_MAIL_FOLDERS_ME_COMMIT: {
      const { payload } = action;
      return mapMailFolderToIdEntry(payload as Array<MailFolder>);
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

interface MailFoldersMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<MailFoldersMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_MAIL_FOLDERS_ME_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_MAIL_FOLDERS_ME_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_MAIL_FOLDERS_ME_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'mail:errorMessages.mailFolders.fetchError',
      };
    }

    case DELETE_LOCAL_MESSAGES:
    case SAGA_REBUILD:
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

interface ActiveMailFolderState {
  mailFolderId: MailFolderId;
}

const activeMailFolder: Reducer<ActiveMailFolderState, any> = (
  state = { mailFolderId: null },
  action
) => {
  switch (action.type) {
    case UPDATE_ACTIVE_MAILFOLDER_ME: {
      const { mailFolderId } = action;
      return {
        mailFolderId,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { mailFolderId: null };
    }
    default:
      return state;
  }
};

const openMailFolderKeys: Reducer<MailFolderId[], ReduxAction> = (
  state = [],
  action
) => {
  switch (action.type) {
    case SET_OPEN_MAILFOLDER_KEYS_ME: {
      const { mailFolderIds } = action;
      return mailFolderIds;
    }
    case ADD_OPEN_MAILFOLDER_KEY_ME: {
      const { mailFolderId } = action;
      if (!mailFolderId || state.includes(mailFolderId)) {
        return state;
      }
      return [...state, mailFolderId];
    }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<MailFoldersState>({
  byId,
  ids,
  meta,
  activeMailFolder,
  openMailFolderKeys,
});

const expand = (ids: MailFolderIdEntry[], byId: MailFolderByMailFolderId) =>
  ids.map(({ id, childFolder }) => ({
    ...byId[id],
    ...(childFolder ? { childFolder: expand(childFolder, byId) } : {}),
  }));

export const getAllMailFolders: (state: any) => Array<MailFolder> = (state) =>
  expand(state.ids, state.byId);

export const getMailFoldersById: (
  state: MailFoldersState
) => MailFolderByMailFolderId = (state) => state.byId ?? {};

export const getMailFolder: (state: any, id: string) => MailFolder = (
  state,
  id
) => state.byId[id];

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;

export const getActiveMailFolderId: (state: any) => string = (state) =>
  state.activeMailFolder.mailFolderId;

export const getOpenMailFolderKeys: (
  state: MailFoldersState
) => MailFolderId[] = (state) => {
  return state.openMailFolderKeys;
};
