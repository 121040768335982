import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { FolderPermissionAccessRight } from '../../../models/Settings';
import { FolderPermissionValue } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface FolderPermissionSelectProps {
  field: FolderPermissionAccessRight;
  value: FolderPermissionValue;
  onChange: (value: FolderPermissionValue) => void;
  showTitle?: boolean;
}

export const FolderPermissionSelect: React.FC<FolderPermissionSelectProps> = (
  props
) => {
  const classes = useStyles();
  const { field, value, onChange, showTitle } = props;
  const { t } = useTranslation();

  return (
    <Flex.Row className={classes.root} alignItems="baseline">
      {showTitle && (
        <Flex.Item flex={1}>
          {t(
            `settings:projectFileSystemStructureEditor.panel.folderPermissions.${field}`
          )}
        </Flex.Item>
      )}
      <Flex.Item flex={1}>
        <Select<FolderPermissionValue> value={value} onChange={onChange}>
          <Select.Option value="none">
            {t(
              'settings:projectFileSystemStructureEditor.panel.folderPermissions.none'
            )}
          </Select.Option>
          <Select.Option value="read">
            {t(
              'settings:projectFileSystemStructureEditor.panel.folderPermissions.read'
            )}
          </Select.Option>
          <Select.Option value="write">
            {t(
              'settings:projectFileSystemStructureEditor.panel.folderPermissions.write'
            )}
          </Select.Option>
        </Select>
      </Flex.Item>
    </Flex.Row>
  );
};

export default FolderPermissionSelect;
