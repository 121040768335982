import { debounce, put } from 'redux-saga/effects';
import { fetchNotifications } from '../actions';
import { FETCH_NOTIFICATIONS_SAGA } from '../actions/saga';

function* handleFetchNotifications() {
  yield put(fetchNotifications());
}

export default function* watchFetchNotifications() {
  yield debounce(1000, FETCH_NOTIFICATIONS_SAGA, handleFetchNotifications);
}
