import React, { useState } from 'react';
import { Typography } from 'antd';
import { Button, Modal } from '@prio365/prio365-react-library'; // Import Modal
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import AddUsersDrawer from './AddUsersDrawer';
import NavigationBar from '../../../components/NavigationBar';
import { useParams } from 'react-router-dom';
import GlobalAndOfficeNavigation from '../../../components/GlobalAndOfficeNavigation';
import useAccessRights from '../hooks/useAccessRights';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import PrioUserLicenceManagement from './PrioUserLicenceManagement';
import { apiCreateLicencesOfActiveUsers } from '../api';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  module: {
    height: '100%',
    overflow: 'hidden',
  },
  navigation: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  menuItem: {
    '&.ant-menu-item': {
      paddingRight: 34,
    },
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  userManagement: {
    padding: theme.old.spacing.defaultPadding,
    overflow: 'hidden',
  },
  userManagementSearchInput: {
    alignSelf: 'flex-end',
    width: 'inherit',
  },
  prioUserLicenceManagement: {
    overflow: 'hidden',
  },
}));

interface UserLicencePageProps {
  pathPrefix?: string;
}

export const UserLicencePage: React.FC<UserLicencePageProps> = (props) => {
  const classes = useStyles();
  const { pathPrefix } = props;
  const { t } = useTranslation();
  const { officeId } = useParams();

  const { showGlobalLicenceSettings } = useAccessRights([
    'showGlobalLicenceSettings',
  ]);

  // State for modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleModalConfirm = async () => {
    try {
      const licences = await apiCreateLicencesOfActiveUsers();
      console.log('Lizenzen der aktiven Benutzer erstellt:', licences);
      // Perform any additional actions if needed
    } catch (error) {
      console.error(
        'Fehler beim Erstellen der Lizenzen der aktiven Benutzer:',
        error
      );
    } finally {
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <Flex.Column className={classes.root}>
        <NavigationBar>
          {!officeId && showGlobalLicenceSettings && (
            <Button onClick={openModal} iconProp={['fal', 'user-plus']}>
              <span>{t('users:licences.giveAllActiveUserLicences')}</span>
            </Button>
          )}
        </NavigationBar>
        <Flex.Row className={classes.module}>
          <Flex.Column
            className={classNames('prio-module-navigation', classes.navigation)}
          >
            <Typography.Title className={classes.title}>
              {t('settings:settingsNav.navigation.user-licences')}
            </Typography.Title>
            <GlobalAndOfficeNavigation
              urlPrefix={pathPrefix ?? ''}
              globalMenuItems={[
                {
                  itemKey: 'user-licences',
                  icon: 'key',
                  tString: 'users:licenceNavigation.globalLicenceSettings',
                  urlPrefix: officeId ? `${pathPrefix}../` : pathPrefix,
                },
              ]}
              officeMenuItems={[
                {
                  itemKey: 'user-licences',
                  icon: 'building',
                  tString: 'users:licenceNavigation.globalLicenceSettings',
                  urlPrefix: officeId
                    ? pathPrefix
                    : `${pathPrefix}user-licences/`,
                },
              ]}
              globalAccessMode={'showGlobalLicenceSettings'}
              officeAccessMode={'showOfficeLicenceSettings'}
              officeId={officeId}
              selectedList={officeId ?? 'user-licences'}
              showOpen
            />
          </Flex.Column>
          <Flex.Item flex={1} className={classes.prioUserLicenceManagement}>
            {!officeId && showGlobalLicenceSettings ? (
              <PrioUserLicenceManagement />
            ) : (
              officeId && <PrioUserLicenceManagement officeId={officeId} />
            )}
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>

      <AddUsersDrawer />

      <Modal
        title={t('users:licences.confirmModalActivateAll.title')}
        visible={isModalVisible}
        onOk={handleModalConfirm}
        onClose={handleModalCancel}
        okText={t('users:licences.confirmModalActivateAll.okText')}
        cancelText={t('users:licences.confirmModalActivateAll.cancelText')}
      >
        <p>{t('users:licences.confirmModalActivateAll.description')}</p>
      </Modal>
    </>
  );
};

export default UserLicencePage;
