import React, { ReactElement, ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@prio365/prio365-react-library';
import { ProjectId } from '../../../../models/Types';
import { useDispatch } from 'react-redux';

import { Message } from '../../../../models/Message';
import { deleteMessageSagaAction } from '../../actions/sagas';

interface DeleteMailsButtonProps {
  projectId: ProjectId;
  selectedMessages: Message[];
  onClearSelection: VoidFunction;
}

export const DeleteMailsButton: React.FC<DeleteMailsButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { selectedMessages, projectId, onClearSelection } = props;

  const { mailFolderId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const onDelete = async () => {
    if (!mailFolderId || !(selectedMessages?.length > 0)) return;

    dispatch(
      deleteMessageSagaAction(projectId, mailFolderId, selectedMessages, {
        navigate,
      })
    );

    onClearSelection();
  };
  //#endregion

  //#region ------------------------------ Components
  const wrapTooltip = (children: ReactElement, title: ReactNode) => {
    return title ? (
      <Tooltip overlay={title} placement="bottom">
        {children}
      </Tooltip>
    ) : (
      children
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return wrapTooltip(
    <Button
      type="default"
      onClick={onDelete}
      iconProp={['fal', 'trash']}
      disabled={selectedMessages.length > 200}
    >
      <span>{t('mail:navigationBar.deleteMessages')}</span>
    </Button>,
    selectedMessages.length > 200
      ? t('mail:navigationBar.toManySelected')
      : undefined
  );
};

export default DeleteMailsButton;
