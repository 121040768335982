import React from 'react';
import classNames from 'classnames';
import { Collapse, Divider } from 'antd';
import Flex from './Flex';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  label: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  content: {},
  contentBox: {
    padding: theme.old.spacing.defaultPadding,
    border: theme.old.borders.sub,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
}));

interface CollapseWithHeaderProps {
  className?: string;
  children: React.ReactNode;
  label: React.ReactNode;
  enableContentBox?: boolean;
  defaultCollapsed?: boolean;
}

export const CollapseWithHeader: React.FC<CollapseWithHeaderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    children,
    label,
    enableContentBox = true,
    defaultCollapsed,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Collapse
      defaultActiveKey={!defaultCollapsed ? 1 : undefined}
      ghost
      className={classNames(classes.root, className)}
    >
      <Collapse.Panel
        header={
          <Flex.Row flex={1}>
            <div className={classes.label}>{label}</div>

            <Divider className={classes.divider} />
          </Flex.Row>
        }
        key="1"
      >
        <div
          className={classNames(classes.content, {
            [classes.contentBox]: enableContentBox,
          })}
        >
          {children}
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseWithHeader;
