import { useRef } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import { Row, TableBodyProps } from 'react-table';
import { LoadingProps } from './VirtualTable2';
import {
  AutoSizer,
  List,
  ListRowRenderer,
  ScrollParams,
} from 'react-virtualized';

const useStyles = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    width: '100%',
    '& .ReactVirtualized__Grid.ReactVirtualized__List': {
      overflow: 'hidden scroll!important',
    },
  },
  transparentOverlayLoading: {
    position: 'fixed',
    width: '100%',
    height: '100%',

    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    transition: '1s',
    opacity: '0',
  },
  transparentOverlayLoadingIndicator: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 999,
  },
}));

interface VirtualTableBodyProps<DataType extends object> {
  className?: string;
  rows: Row<DataType>[];
  tableBodyProps: TableBodyProps;
  loading?: LoadingProps;
  noItemsScreen?: JSX.Element;
  overscanRowCount?: number;
  rowRenderer: ListRowRenderer;
  rowHeight: number | ((value: { index: number }) => number);
  onScroll?: (scrollParams: ScrollParams) => void;
  setVlistRef: (ref: List) => void;
}

export const VirtualTableBody = <DataType extends object>(
  props: VirtualTableBodyProps<DataType>
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    rows,
    loading,
    tableBodyProps,
    noItemsScreen,
    overscanRowCount,
    rowRenderer,
    rowHeight,
    onScroll,
    setVlistRef,
  } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const vListRef = useRef<List>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleScroll = (scrollParams: ScrollParams) => {
    if (onScroll) {
      onScroll(scrollParams);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion
  if (
    loading &&
    loading.loadingType === 'table' &&
    !loading.indicatorAsOverlay
  ) {
    return loading.indicator;
  }

  if (rows.length === 0) {
    return noItemsScreen ?? <div></div>;
  }

  return (
    <div {...tableBodyProps} className={classNames(classes.root, className)}>
      <AutoSizer>
        {({ height, width }) => (
          <>
            <div className={classes.transparentOverlayLoadingIndicator}>
              {loading && loading?.indicator && loading?.indicatorAsOverlay}
            </div>
            <div
              style={{
                opacity: loading?.indicatorAsOverlay ? '1' : '0',
                width: width,
                height: height,
              }}
              className={classes.transparentOverlayLoading}
            ></div>
            {rows.length === 0 && noItemsScreen ? (
              noItemsScreen
            ) : (
              <List
                overscanRowCount={overscanRowCount}
                onScroll={handleScroll}
                ref={(element) => {
                  vListRef.current = element;
                  setVlistRef(element);
                }}
                height={height}
                width={width}
                rowHeight={rowHeight}
                rowCount={rows.length}
                rowRenderer={rowRenderer}
              />
            )}
          </>
        )}
      </AutoSizer>
    </div>
  );
};

export default VirtualTableBody;
