import { PRIO } from '../../../../constants';
import { DocumentSettings } from '../../../../models/UserSettings/DocumentSettings';
import { apiUrl } from '../../api';

export const UPDATE_DOCUMENT_SETTINGS_REQUEST =
  PRIO + 'UPDATE_DOCUMENT_SETTINGS_REQUEST';

export const UPDATE_DOCUMENT_SETTINGS_COMMIT =
  PRIO + 'UPDATE_DOCUMENT_SETTINGS_COMMIT';

export const UPDATE_DOCUMENT_SETTINGS_ROLLBACK =
  PRIO + 'UPDATE_DOCUMENT_SETTINGS_ROLLBACK';

export const updateDocumentSettings = (documentSettings: DocumentSettings) => ({
  type: UPDATE_DOCUMENT_SETTINGS_REQUEST,
  payload: { documentSettings },
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/usersetting/usersetting/me`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: {
          documentSettings: {
            ...documentSettings,
          },
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: UPDATE_DOCUMENT_SETTINGS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_DOCUMENT_SETTINGS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'userSettings:errorMessages.updateDocumentSettings',
          timeout: 6,
        },
      },
    },
    documentSettings,
  },
});

export const UPDATE_DOCUMENTS_CACHING_ENABLED =
  PRIO + 'UPDATE_DOCUMENTS_CACHING_ENABLED';

export const updateDocumentsCachingEnabled = (value: boolean) => ({
  type: UPDATE_DOCUMENTS_CACHING_ENABLED,
  payload: value,
});
