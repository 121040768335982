import React, { useState } from 'react';
import { Input, Select, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContactsIsFetching,
  getExternalProjectContactsIsFetching,
  getInternalProjectContactsIsFetching,
  getProjectsIsFetching,
} from '../../../apps/main/rootReducer';
import { EmailString, ProjectId } from '../../../models/Types';
import {
  fetchExternalProjectContacts,
  fetchInternalProjectContacts,
} from '../../projects/actions';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import PrioSpinner from '../../../components/PrioSpinner';
import useAddressSearch from '../hooks/useAddressSearch';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    '&.ant-input-group.ant-input-group-compact': {
      display: 'flex',
    },
  },
  preselect: {
    minWidth: 100,
    height: 32,
    '&.ant-select-single .ant-select-selector': {
      borderRadius: 2,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      height: 32,
    },
  },
  select: {
    flex: 1,
    '&.ant-select-multiple .ant-select-selector': {
      borderRadius: 2,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      minHeight: 32,
    },
  },
  loadingOverlay: {
    background: '#FFFFFFBF',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },

  suggestionLabel: {
    fontSize: theme.old.typography.fontSize.tiny,
    color: theme.old.typography.colors.muted,
    padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
    borderBottom: theme.old.borders.content,
  },
}));

interface FilterEmailPickerProps {
  value?: EmailString[];
  onChange?: (value: EmailString[]) => void;
  type: 'all' | 'contact' | 'external' | 'project';
  projectId: ProjectId;
  disabled?: boolean;
  excludedMails?: EmailString[];
}

export const FilterEmailPicker: React.FC<FilterEmailPickerProps> = (props) => {
  const classes = useStyles();

  const { value, onChange, projectId, disabled, excludedMails } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchInternalProjectContacts(projectId));
    dispatch(fetchExternalProjectContacts(projectId));
  }, [dispatch, projectId]);

  const isContactsFetching = useSelector(getContactsIsFetching);
  const isInternalContactsFetching = useSelector(
    getInternalProjectContactsIsFetching
  );
  const isExternalContactsFetching = useSelector(
    getExternalProjectContactsIsFetching
  );
  const isAllProjectsFetching = useSelector(getProjectsIsFetching);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isOnline, setIsOnline] = useState<boolean>(false);

  const isSearching = searchTerm !== '';

  const { addresses, isSearching: isFetching } = useAddressSearch({
    searchTerm,
    isOnline,
    projectId,
  });

  const changeHandler = (value: EmailString[]) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Input.Group compact className={classes.root}>
      <Select<EmailString[]>
        className={classes.select}
        mode="tags"
        value={value}
        onChange={changeHandler}
        disabled={
          disabled ||
          (addresses.length === 0 &&
            (isContactsFetching ||
              isInternalContactsFetching ||
              isExternalContactsFetching ||
              isAllProjectsFetching))
        }
        loading={
          addresses.length === 0 &&
          (isContactsFetching ||
            isInternalContactsFetching ||
            isExternalContactsFetching ||
            isAllProjectsFetching)
        }
        onMouseDown={(e) => {
          e.preventDefault();
          return false;
        }}
        onSearch={(value: string) => {
          if (value === '') {
            setIsOnline(false);
          }
          setSearchTerm(value);
        }}
        dropdownRender={(menu) => (
          <Flex.Column>
            {isFetching && (
              <div className={classes.loadingOverlay}>
                <PrioSpinner />
              </div>
            )}
            {!isSearching && (
              <Typography.Text className={classes.suggestionLabel}>
                {t('contacts:actions.suggestions')}
              </Typography.Text>
            )}

            {menu}
            {!isOnline && searchTerm !== '' && (
              <Button
                type="link"
                onClick={() => {
                  setIsOnline(true);
                }}
                iconProp={['fal', 'plus']}
              >
                {t('contacts:actions.showMore')}
              </Button>
            )}
          </Flex.Column>
        )}
      >
        {addresses
          .filter((address) => !excludedMails?.find((e) => e === address.eMail))
          .map((address) => (
            <Select.Option
              id={address.id}
              key={address.id}
              value={address.eMail}
              data={address}
            >
              {address.eMail}
            </Select.Option>
          ))}
      </Select>
    </Input.Group>
  );
};

export default FilterEmailPicker;
