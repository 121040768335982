import React, { useCallback, useReducer } from 'react';

import {
  DateTimeString,
  MailCategoryColorName,
  MailFolderId,
  ProjectId,
} from '../../../../models/Types';

import moment from 'moment';
import {
  MailFolderByMailFolderId,
  MailFolderIdEntry,
  SpecialMailFolders,
} from '../../actions/types';
import {
  AdvancedMailSearchAction,
  AdvancedMailSearchDto,
} from '../../../../models/MailSearch';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  getlastSearchTermMail,
  getMessageCategoryColorMap,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { MailFolder } from '../../../../models/MailFolder';

import { saveLastSearchMail } from '../../actions/actionControllers/searchActionController';

interface Period {
  dateFrom: DateTimeString;
  dateTo: DateTimeString;
}

interface UseMailSearchProps {
  projectId: ProjectId;
  currentSearchMailFolderId: MailFolderId;
  specialMailFolders: SpecialMailFolders;
}

export const useMailSearch = ({
  projectId,
  currentSearchMailFolderId,
  specialMailFolders,
}: UseMailSearchProps) => {
  //#region -------------------------------- Helpers
  const dispatch = useDispatch();

  const getSpecialSorting = (
    mailFolderId: MailFolderId,
    specialMailFolders: SpecialMailFolders
  ) => {
    if (!specialMailFolders) return 0;
    if (!mailFolderId) return 0;
    switch (mailFolderId) {
      case specialMailFolders['inboxFolder']?.id:
        return 100;
      case specialMailFolders['sendFolder']?.id:
        return 99;
      case specialMailFolders['draftFolder']?.id:
        return 98;
      case specialMailFolders['deletedFolder']?.id:
        return 97;
      default:
        return 0;
    }
  };

  //#endregion

  //#region -------------------------------- Selectors

  const flattenMailFolderIdEntries = (
    mailFolderIdEntries: MailFolderIdEntry[]
  ): MailFolderIdEntry[] =>
    mailFolderIdEntries.reduce((acc, mailFolderIdEntry) => {
      acc.push(mailFolderIdEntry);
      if (
        mailFolderIdEntry.childFolder &&
        mailFolderIdEntry.childFolder.length > 0
      ) {
        acc.push(...flattenMailFolderIdEntries(mailFolderIdEntry.childFolder));
      }
      return acc;
    }, []);

  const mailFoldersSelector = (
    projectId: ProjectId,
    specialMailFolders: SpecialMailFolders
  ) =>
    createSelector<
      [
        (state: RootReducerState) => MailFolderIdEntry[],
        (state: RootReducerState) => MailFolderByMailFolderId,
      ],
      MailFolder[]
    >(
      (state) =>
        projectId === 'me'
          ? state.mail.me.mailFolders.ids
          : state.mail.projects.mailFolders.ids[projectId] ?? [],
      (state) =>
        projectId === 'me'
          ? state.mail.me.mailFolders.byId
          : state.mail.projects.mailFolders.byId,
      (ids, byId) => {
        return flattenMailFolderIdEntries(ids)
          .map(({ id }) => byId[id])
          .filter((mF) => !!mF)
          .sort((a, b) => {
            const aValue = getSpecialSorting(a.id, specialMailFolders);
            const bValue = getSpecialSorting(b.id, specialMailFolders);
            return bValue - aValue;
          });
      }
    );

  const mailFolders = useSelector(
    mailFoldersSelector(projectId, specialMailFolders)
  );
  const initialState: AdvancedMailSearchDto = {};

  const [advancedSearchTerm, dispatchAdvancedSearchTerm] = useReducer<
    React.Reducer<AdvancedMailSearchDto, AdvancedMailSearchAction>
  >((state: AdvancedMailSearchDto, action: AdvancedMailSearchAction) => {
    const filter = action.type;
    const value = action.value;

    switch (filter) {
      case 'period': {
        const { period, ...rest } = state;
        if (!value) {
          if (period?.dateTo) {
            return {
              ...rest,
              period: {
                dateTo: period?.dateTo ?? undefined,
              },
            };
          }

          if (period?.dateFrom) {
            return {
              ...rest,
              period: {
                dateFrom: period?.dateFrom ?? undefined,
              },
            };
          }

          return {
            ...rest,
          };
        }

        const convertedValue: Period = {
          dateFrom: moment((value as Period).dateFrom)
            .toISOString(true)
            .split('T')[0],
          dateTo: moment((value as Period).dateTo)
            .toISOString(true)
            .split('T')[0],
        };

        return {
          ...state,
          period: convertedValue,
        };
      }
      case 'dateFrom': {
        const { period, ...rest } = state;
        if (!value) {
          if (period?.dateTo) {
            return {
              ...rest,
              period: {
                dateTo: period?.dateTo ?? undefined,
              },
            };
          }

          return {
            ...rest,
          };
        }

        const convertedValue: Period = {
          dateTo: period?.dateTo ?? undefined,
          dateFrom: moment(value as string)
            .toISOString(true)
            .split('T')[0],
        };

        return {
          ...state,
          period: convertedValue,
        };
      }
      case 'dateTo': {
        const { period, ...rest } = state;
        if (!value) {
          if (period?.dateFrom) {
            return {
              ...rest,
              period: {
                dateFrom: period?.dateFrom ?? undefined,
              },
            };
          }

          return {
            ...rest,
          };
        }

        const convertedValue: Period = {
          dateFrom: period?.dateFrom ?? undefined,
          dateTo: moment(value as string)
            .toISOString(true)
            .split('T')[0],
        };

        return {
          ...state,
          period: convertedValue,
        };
      }

      case 'RESET': {
        return initialState;
      }

      default: {
        const newState = {
          ...state,
          [filter]: value,
        };

        return newState;
      }
    }
  }, {});

  const saveSearchTermToLastSearchTerm = useCallback(
    (searchtTerm: AdvancedMailSearchDto | string = advancedSearchTerm) => {
      dispatch(
        saveLastSearchMail(searchtTerm, projectId, currentSearchMailFolderId)
      );
    },
    [advancedSearchTerm, currentSearchMailFolderId, dispatch, projectId]
  );

  const colorMap = useSelector<
    RootReducerState,
    { [displayName: string]: MailCategoryColorName }
  >((state) => getMessageCategoryColorMap(state, projectId));

  const lastSearchTerm = useSelector<
    RootReducerState,
    AdvancedMailSearchDto | string
  >((state) => getlastSearchTermMail(state, projectId));

  //#endregion

  //#region -------------------------------- Values

  //#endregion

  return {
    advancedSearchTerm,
    colorMap,
    lastSearchTerm,
    mailFolders,
    saveSearchTermToLastSearchTerm,
    dispatchAdvancedSearchTerm,
  };
};
