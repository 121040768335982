import { PRIO } from '../../constants';
import { RecursivePartial } from '../../models/Types';
import { PrioTheme } from '../types';

export const UPDATE_PRIO_THEME = PRIO + 'UPDATE_PRIO_THEME';

export const updatePrioTheme = (value: RecursivePartial<PrioTheme>) => ({
  type: UPDATE_PRIO_THEME,
  value,
});

export const UPDATE_PRIO_THEME_PRIMARY_COLOR =
  PRIO + 'UPDATE_PRIO_THEME_PRIMARY_COLOR';

export const updatePrioThemePrimaryColor = (value: string) => ({
  type: UPDATE_PRIO_THEME_PRIMARY_COLOR,
  value,
});

export const UPDATE_PRIO_THEME_CICD_PRIMARY_COLOR =
  PRIO + 'UPDATE_PRIO_THEME_CICD_PRIMARY_COLOR';

export const updatePrioThemeCICDPrimaryColor = (value: string) => ({
  type: UPDATE_PRIO_THEME_CICD_PRIMARY_COLOR,
  value,
});
