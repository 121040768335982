import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Typography, Popover, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import countries from 'i18n-iso-countries';
import moment from 'moment';
import { Moment } from 'moment-timezone';
import Flex from '../../../components/Flex';
import { Employee } from '../../../models/Employee';
import { Gender, EmployeeId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import i18n from '../../../i18n';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import {
  faLock,
  faCakeCandles,
  faBuilding,
  faBriefcase,
} from '@fortawesome/pro-light-svg-icons';

const useStyles = makePrioStyles((theme) => ({
  root: {
    borderRadius: '4px',
    padding: 10,
    marginRight: theme.spacing.small,
    marginLeft: theme.spacing.large,
    marginBottom: theme.spacing.large,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  coreDataBoxLeft: {
    borderRight: theme.old.borders.content,
    paddingRight: theme.old.spacing.unit(3),
  },
  fullWidth: {
    width: '100%',
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
  },
  lockIcon: {},
  cakeCandlesIcon: {},
  buildingIcon: {},
  briefCaseIcon: {},
}));

interface CoreDataFormModel {
  phone?: string;
  eMail?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  federalStateCode?: string;
  gender?: Gender;
  nationality?: string;
  birthday?: Moment;
  allowShareBirthday?: boolean;
  allowShareBirthdayCompany?: boolean;
  birthplace?: string;
}

const coreDataFromEmployee: (employee: Employee) => CoreDataFormModel = (
  employee
) => ({
  phone: employee?.phone,
  eMail: employee?.eMail,
  street: employee?.street,
  zip: employee?.zip,
  city: employee?.city,
  countryCode: employee?.countryCode,
  federalStateCode: employee?.federalStateCode,
  gender: employee?.gender,
  nationality: employee?.nationality,
  birthday: employee?.birthday ? moment(employee?.birthday) : null,
  allowShareBirthday: employee?.allowShareBirthday,
  allowShareBirthdayCompany: employee?.allowShareBirthdayCompany,
  birthplace: employee?.birthplace,
});

interface ProfilePersonnelCoreDataFormProps {
  employeeId: EmployeeId;
  employee?: Employee;
  disabled: boolean;
  openEditPersonnelDataDrawer: () => void;
}

export const ProfilePersonnelCoreDataForm: React.FC<
  ProfilePersonnelCoreDataFormProps
> = (props) => {
  const { employee /*, openEditPersonnelDataDrawer*/ } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [coreDataForm] = Form.useForm<CoreDataFormModel>();
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    if (employee) {
      coreDataForm.setFieldsValue(coreDataFromEmployee(employee));
    }
  }, [employee, coreDataForm]);

  //#endregion

  return (
    <Flex.Column
      className={classes.root}
      childrenGap={theme.old.spacing.unit(3)}
    >
      <Typography.Title style={{ fontSize: '18px', margin: 0 }}>
        {t('profile:profileNavigation.personnelData')}
      </Typography.Title>
      <Flex.Column flex={1} childrenGap={theme.old.spacing.unit(3)}>
        <Flex.Row>
          <Flex.Column>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('hr:personnelFile.form.labels.address')}
            </Typography.Title>
            <Flex.Item>
              {`${
                !coreDataFromEmployee(employee).street
                  ? ` ${t('profile:personalData.noData')} `
                  : coreDataFromEmployee(employee).street
              }`}
            </Flex.Item>
            <Flex.Row>
              <Flex.Item>
                {`${
                  !coreDataFromEmployee(employee).zip
                    ? ` ${t('profile:personalData.noData')} `
                    : coreDataFromEmployee(employee).zip
                }`}
              </Flex.Item>
              <span>&nbsp;</span>
              <Flex.Item>
                {`${
                  !coreDataFromEmployee(employee).city
                    ? ` ${t('profile:personalData.noData')} `
                    : coreDataFromEmployee(employee).city
                }`}
              </Flex.Item>
            </Flex.Row>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          <Flex.Column flex={1} overflow={'hidden'}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('hr:personnelFile.form.labels.eMail')}
            </Typography.Title>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '90%',
              }}
            >
              <Tooltip title={coreDataFromEmployee(employee).eMail}>
                {`${
                  !coreDataFromEmployee(employee).eMail
                    ? ` ${t('profile:personalData.noData')} `
                    : coreDataFromEmployee(employee).eMail
                }`}
              </Tooltip>
            </div>
          </Flex.Column>
          <Flex.Column flex={1}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('hr:personnelFile.form.labels.phone')}
            </Typography.Title>
            <Flex.Item>
              {`${
                !coreDataFromEmployee(employee).phone
                  ? ` ${t('profile:personalData.noData')} `
                  : coreDataFromEmployee(employee).phone
              }`}
            </Flex.Item>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          <Flex.Column flex={1}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('hr:personnelFile.form.labels.gender')}
            </Typography.Title>
            <Flex.Item>
              {`${
                coreDataFromEmployee(employee).gender
                  ? t(
                      `contacts:form.gender.${
                        coreDataFromEmployee(employee).gender
                      }`
                    )
                  : ` ${t('profile:personalData.noData')} `
              }`}
            </Flex.Item>
          </Flex.Column>
          <Flex.Column flex={1} overflow={'hidden'}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('hr:personnelFile.form.labels.nationality')}
            </Typography.Title>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '90%',
              }}
            >
              <Tooltip title={coreDataFromEmployee(employee).nationality}>
                {`${
                  !coreDataFromEmployee(employee).nationality
                    ? ` ${t('profile:personalData.noData')} `
                    : countries.getName(
                        coreDataFromEmployee(employee).nationality,
                        i18n.language.substring(0, 2)
                      )
                }`}
              </Tooltip>
            </div>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          <Flex.Column flex={1}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('hr:personnelFile.form.labels.birthday')}
            </Typography.Title>
            <Flex.Row>
              <Flex.Item>
                {`${
                  !coreDataFromEmployee(employee).birthday
                    ? ` ${t('profile:personalData.noData')} `
                    : coreDataFromEmployee(employee)
                        .birthday.locale('de')
                        .format('L')
                }`}
              </Flex.Item>
              <span style={{ margin: '5px' }}>&nbsp;</span>
              {!coreDataFromEmployee(employee).allowShareBirthday && (
                <Popover
                  trigger="hover"
                  placement="topLeft"
                  arrowPointAtCenter
                  content={t(
                    'profile:personalData.popovers.birthdayNotSharedWithOffice'
                  )}
                >
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <FontAwesomeIcon
                      icon={faLock}
                      className={classes.lockIcon}
                      style={{ position: 'relative', fontSize: '120%' }}
                    />
                    <div
                      className="briefcaseIconWrapper"
                      style={{
                        position: 'absolute',
                        bottom: 4,
                        right: -6,
                        backgroundColor:
                          theme.old.palette.backgroundPalette.content,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        height: '1.2em',
                        width: '1.2em',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBriefcase}
                        className={classes.briefCaseIcon}
                        style={{ fontSize: '90%' }}
                      />
                    </div>
                  </div>
                </Popover>
              )}
              {coreDataFromEmployee(employee).allowShareBirthday && (
                <Popover
                  trigger="hover"
                  placement="topLeft"
                  arrowPointAtCenter
                  content={t(
                    'profile:personalData.popovers.birthdaySharedWithOffice'
                  )}
                >
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <FontAwesomeIcon
                      icon={faCakeCandles}
                      className={classes.cakeCandlesIcon}
                      style={{ position: 'relative', fontSize: '120%' }}
                    />
                    <div
                      className="briefcaseIconWrapper"
                      style={{
                        position: 'absolute',
                        bottom: 4,
                        right: -6,
                        backgroundColor:
                          theme.old.palette.backgroundPalette.content,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        height: '1.2em',
                        width: '1.2em',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBriefcase}
                        className={classes.briefCaseIcon}
                        style={{ fontSize: '90%' }}
                      />
                    </div>
                  </div>
                </Popover>
              )}
              <span style={{ margin: '5px' }}>&nbsp;</span>
              {!coreDataFromEmployee(employee).allowShareBirthdayCompany && (
                <Popover
                  trigger="hover"
                  placement="topLeft"
                  arrowPointAtCenter
                  content={t(
                    'profile:personalData.popovers.birthdayNotSharedWithCompany'
                  )}
                >
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <FontAwesomeIcon
                      icon={faLock}
                      className={classes.lockIcon}
                      style={{ position: 'relative', fontSize: '120%' }}
                    />
                    <div
                      className="buildingIconWrapper"
                      style={{
                        position: 'absolute',
                        bottom: 4,
                        right: -6,
                        backgroundColor:
                          theme.old.palette.backgroundPalette.content,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        height: '1.2em',
                        width: '1.2em',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBuilding}
                        className={classes.buildingIcon}
                        style={{ fontSize: '90%' }}
                      />
                    </div>
                  </div>
                </Popover>
              )}
              {coreDataFromEmployee(employee).allowShareBirthdayCompany && (
                <Popover
                  trigger="hover"
                  placement="topLeft"
                  arrowPointAtCenter
                  content={t(
                    'profile:personalData.popovers.birthdaySharedWithCompany'
                  )}
                >
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                  >
                    <FontAwesomeIcon
                      icon={faCakeCandles}
                      className={classes.cakeCandlesIcon}
                      style={{ position: 'relative', fontSize: '120%' }}
                    />
                    <div
                      className="buildingIconWrapper"
                      style={{
                        position: 'absolute',
                        bottom: 4,
                        right: -6,
                        backgroundColor:
                          theme.old.palette.backgroundPalette.content,
                        borderRadius: '50%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        height: '1.2em',
                        width: '1.05em',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBuilding}
                        className={classes.buildingIcon}
                        style={{ fontSize: '90%' }}
                      />
                    </div>
                  </div>
                </Popover>
              )}
            </Flex.Row>
          </Flex.Column>
          <Flex.Column flex={1} overflow={'hidden'}>
            <Typography.Title style={{ fontSize: 13 }}>
              {t('hr:personnelFile.form.labels.birthplace')}
            </Typography.Title>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '90%',
              }}
            >
              <Tooltip title={coreDataFromEmployee(employee).birthplace}>
                {`${
                  !coreDataFromEmployee(employee).birthplace
                    ? ` ${t('profile:personalData.noData')} `
                    : coreDataFromEmployee(employee).birthplace
                }`}
              </Tooltip>
            </div>
          </Flex.Column>
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default ProfilePersonnelCoreDataForm;
