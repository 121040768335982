import React, { useState } from 'react';
import {} from 'antd';
import {
  Resizable,
  ResizeCallback,
  ResizeDirection,
  NumberSize,
  Size,
} from 're-resizable';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    right: 0,
    '& > div > div': {
      zIndex: 900,
    },
  },
  borderLeft: {
    zIndex: 510,
    borderLeft: theme.old.borders.boldMain,
  },
}));

interface WidgetAreaResizableProps {
  size: Size;
  onResizeStop: ResizeCallback;
  minWidth: number;
  maxWidth: number;
  open: boolean;
  closedMinWidth: number;
}

export const WidgetAreaResizable: React.FC<WidgetAreaResizableProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { size, minWidth, maxWidth, closedMinWidth, open, onResizeStop } =
    props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isResizing, setIsResizing] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnResize: ResizeCallback = (
    event: MouseEvent | TouchEvent,
    direction: ResizeDirection,
    elementRef: HTMLElement,
    delta: NumberSize
  ) => {
    setIsResizing(false);
    onResizeStop(event, direction, elementRef, delta);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Resizable
      className={classNames(classes.root, {
        [classes.borderLeft]: isResizing,
      })}
      minWidth={open ? minWidth : closedMinWidth}
      maxWidth={maxWidth}
      size={!open ? { width: closedMinWidth, height: '100%' } : size}
      enable={{
        top: false,
        right: false,
        left: true,
        bottom: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      style={{ position: 'absolute' }}
      onResizeStart={() => setIsResizing(true)}
      onResizeStop={handleOnResize}
    />
  );
};

export default WidgetAreaResizable;
