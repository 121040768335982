import { Button } from '@prio365/prio365-react-library';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { Configuration } from '../../../models/Configuration';
import { ConfigurationKeys } from '../../../models/Types';
import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';
import { defaultPhonePattern, defaultPhonePlaceholder } from '../../../util';
import { colon, rowGutter } from '../../../util/forms';
import { apiFetchConfigurations } from '../../settings/api';
import { apiCreateEmergencyContact, apiUpdateEmergencyContact } from '../api';
import { EmergencyContact } from '../../../models/Employee';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  heading: {
    marginBottom: theme.spacing.regular,
  },
}));

interface EmergencyContactFormModel {
  name: string;
  mobile: string;
  phone: string;
  relationship: string;
  consentDataProcessing: boolean;
}

const coreDataFromEmergencyContact: (
  emergencyContact: EmergencyContact
) => EmergencyContactFormModel = (emergencyContact) => ({
  name: emergencyContact.name,
  mobile: emergencyContact.mobile,
  phone: emergencyContact.phone,
  relationship: emergencyContact.relationship,
  consentDataProcessing: false,
});

interface EditPersonnelFileDataFormProps {
  onClose?: any;
  emergencyContact: EmergencyContact;
  title: string;
  setIsEmergencyContactsUpdated?: () => void;
}

export const EditEmergencyContactForm: React.FC<
  EditPersonnelFileDataFormProps
> = (props) => {
  const { emergencyContact, onClose, title, setIsEmergencyContactsUpdated } =
    props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [coreDataForm] = Form.useForm<EmergencyContactFormModel>();
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  const [formValueHasChanged, setFormValueHasChanged] =
    useState<boolean>(false);
  const currentUser = useSelector(getUserMe);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const cancelEditCoreData = () => {
    onClose();
  };

  const handleFinishCoreData = async (allValues: EmergencyContactFormModel) => {
    // Add new Emergency Contact
    if (!emergencyContact) {
      const currentDate = new Date();
      const { consentDataProcessing, ...remainingCoreData } = allValues;
      const addedEmergencyContact: EmergencyContact = {
        ...remainingCoreData,
        sortKey: 0,
        consentGivenByContactId: currentUser.displayName,
        consentDateTime: new Date(
          currentDate.getTime() + currentDate.getTimezoneOffset() * -60000
        ).toISOString(),
      };
      const { result } = await apiCreateEmergencyContact(addedEmergencyContact);
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t('profile:notification.successMessages.addSuccess'),
        });
        setIsEmergencyContactsUpdated();
      } else {
        notification.open({
          message: t('common:error'),
          description: t('profile:notification.errorMessages.addFailed'),
        });
      }
    } else {
      // Update existing Emergency Contact
      if (formValueHasChanged) {
        const { consentDataProcessing, ...remainingCoreData } = allValues;
        const updatedEmergencyContact: EmergencyContact = {
          ...remainingCoreData,
          sortKey: 0,
        };
        const { result } = await apiUpdateEmergencyContact(
          emergencyContact.emergencyContactId,
          updatedEmergencyContact
        );
        if (result.status >= 200 && result.status < 300) {
          notification.open({
            message: t('common:success'),
            description: t(
              'profile:notification.successMessages.updateSuccess'
            ),
          });
          setIsEmergencyContactsUpdated();
        } else {
          notification.open({
            message: t('common:error'),
            description: t('profile:notification.errorMessages.updateFailed'),
          });
        }
      }
    }
    onClose();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (emergencyContact) {
      coreDataForm.setFieldsValue(
        coreDataFromEmergencyContact(emergencyContact)
      );
    }
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, [emergencyContact, coreDataForm]);
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Form<EmergencyContactFormModel>
        form={coreDataForm}
        onFinish={handleFinishCoreData}
        onValuesChange={() => {
          setFormValueHasChanged(true);
        }}
        layout="vertical"
        className={classes.form}
      >
        <Flex.Column flex={1}>
          <Typography.Text type="secondary" className={classes.heading}>
            {title}{' '}
          </Typography.Text>

          <Row gutter={rowGutter}>
            <Col span={12} style={{ paddingRight: theme.old.spacing.unit(1) }}>
              <Form.Item
                name="name"
                label={t('profile:form.emergencyContactName')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('profile:validation.errorMessages.missingName'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="relationship"
                label={t('profile:form.emergencyContactRelationship')}
                colon={colon}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} style={{ paddingRight: theme.old.spacing.unit(1) }}>
              <Form.Item
                name="mobile"
                label={t('profile:form.emergencyContactMobile')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_MOBILE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                    message: t(
                      'profile:validation.errorMessages.invalidMobile'
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={t('profile:form.emergencyContactPhone')}
                colon={colon}
                rules={[
                  {
                    required: false,
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                    message: t('profile:validation.errorMessages.invalidPhone'),
                  },
                ]}
              >
                <Input
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
            {!emergencyContact && (
              <Col span={8}>
                <Form.Item
                  name="consentDataProcessing"
                  label={t('profile:form.consentDataProcessing')}
                  colon={colon}
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              t(
                                'profile:validation.errorMessages.missingConsent'
                              )
                            ),
                    },
                  ]}
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Flex.Column>
        <Flex.Column>
          <Flex.Row
            alignSelf="flex-end"
            childrenGap={theme.old.spacing.unit(1)}
          >
            <Button type="link" onClick={cancelEditCoreData}>
              {t('common:actions.cancel')}
            </Button>

            <Button type={'primary'} htmlType="submit">
              {t('common:save')}
            </Button>
          </Flex.Row>
        </Flex.Column>
      </Form>
    </Flex.Column>
  );
};

export default EditEmergencyContactForm;
