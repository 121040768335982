import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { Col, Divider, Form, Input, Modal, Row } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UpdateBankAccountRequest,
  CreateInternalCompanyRequest,
  InternalCompany,
  BankAccount,
} from '../../../models/Company';
import { rowGutter } from '../../../util/forms';
import { makePrioStyles } from '../../../theme/utils';
import {
  createBankAccount,
  deleteBankAccount,
  updateBankAccount,
} from '../actions';
import { bicPattern, createTemporaryId, ibanPattern } from '../../../util';
import equals from 'deep-equal';
import { useDispatch } from 'react-redux';
import { TableEntry } from './InternalCompanyTable';
import { BankAccountId, CompanyId, ContactId } from '../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  hoverCursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconDisabled: {
    color: 'lightgrey',
  },
  fullHeight: {
    height: '100%',
    overflowY: 'scroll',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
  },
  reductionForActionButtons: {
    height: 'calc(100% - 48px)',
  },
  FormOverflowWithoutActionButtons: {
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}));

interface InternalCompanyBankAccountFormProps {
  initialValues?: InternalCompany | CreateInternalCompanyRequest;
  actionLabel: string;
  cancelLabel?: string;
  onCancel?: () => void;
  disableActionButton?: boolean;
  adaptedSelectedEntry: TableEntry;
  bankAccounts?: BankAccount[];
}

interface BankAccountsFormDto {
  bankAccounts: {
    bankAccountId?: BankAccountId;
    companyId?: CompanyId;
    creditInstitutionName?: string;
    bic?: string;
    iban?: string;
    rowVersion?: string;
    contactId?: ContactId;
  }[];
}

export const InternalCompanyBankAccountForm: React.FC<
  InternalCompanyBankAccountFormProps
> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const {
    initialValues,
    actionLabel,
    cancelLabel,
    onCancel,
    adaptedSelectedEntry,
    bankAccounts,
  } = props;

  const [form] = Form.useForm();
  const [addBankAccount, setAddBankAccount] = useState<boolean>(false);

  useEffect(() => {
    setAddBankAccount(false);
    form.resetFields();
  }, [form, bankAccounts]);

  const cancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = (value: BankAccountsFormDto) => {
    if (addBankAccount) {
      const temporaryId = createTemporaryId();
      dispatch(
        createBankAccount(
          {
            companyId: adaptedSelectedEntry.company.companyId,
            creditInstitutionName:
              value.bankAccounts[value.bankAccounts.length - 1]
                .creditInstitutionName,
            bic: value.bankAccounts[value.bankAccounts.length - 1].bic,
            iban: value.bankAccounts[value.bankAccounts.length - 1].iban,
          },
          temporaryId
        )
      );
      setAddBankAccount(false);
    } else {
      value.bankAccounts.forEach((bankAccount) => {
        const originalBankAccount = initialValues.bankAccounts.find(
          (originalBankAccount) =>
            originalBankAccount.bankAccountId === bankAccount.bankAccountId
        );
        if (!equals(originalBankAccount, bankAccount)) {
          const { contactId, ...rest } =
            bankAccount as UpdateBankAccountRequest;
          dispatch(
            updateBankAccount(
              { ...rest, contactId: contactId ?? undefined },
              adaptedSelectedEntry.company.companyId,
              bankAccount.rowVersion,
              originalBankAccount
            )
          );
        }
      });
    }
    setFormChanged(false);
  };

  const [modalDeleteBankAccountVisible, setModalDeleteBankAccountVisible] =
    useState<boolean>(false);
  const [modalNewBankAccountVisible, setModalNewBankAccountVisible] =
    useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [bankAccountIndex, setBankAccountIndex] = useState<number>();

  const handleDeleteExistingBankAccount = (index: number) => {
    const value = initialValues.bankAccounts[index];
    dispatch(deleteBankAccount(value.companyId, value.bankAccountId, value));
    setModalDeleteBankAccountVisible(false);
  };

  const handleDeleteBankAccount = (index: number) => {
    if (addBankAccount) {
      form.resetFields();
      setAddBankAccount(false);
      setFormChanged(false);
    } else {
      setBankAccountIndex(index);
      setModalDeleteBankAccountVisible(true);
    }
  };

  const openModalToDeleteExistingBankAccount = (index: number) => {
    setBankAccountIndex(index);
    setModalDeleteBankAccountVisible(true);
  };

  return (
    <div className={classes.fullHeight}>
      <Form<BankAccountsFormDto>
        layout="vertical"
        style={{ overflowX: 'hidden', height: '100%' }}
        initialValues={initialValues}
        form={form}
        onChange={() => setFormChanged(true)}
        onFinish={handleFinish}
      >
        <div
          style={{ height: 'calc(100% - 48px' }}
          className={classes.FormOverflowWithoutActionButtons}
        >
          <Form.List name="bankAccounts">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index, array) => (
                  <div key={field.key}>
                    <Form.Item required={false}>
                      <Col>
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={22}>
                            <Form.Item
                              {...field}
                              label={t(
                                'companies:internalCompanyForm.labels.creditInstitutionName'
                              )}
                              name={[field.name, 'creditInstitutionName']}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'companies:internalCompanyForm.validation.missingCreditInstitutionName'
                                  ),
                                },
                              ]}
                            >
                              <Input
                                disabled={
                                  addBankAccount && index < array.length - 1
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2} style={{ alignSelf: 'center' }}>
                            <FontAwesomeIcon
                              icon={['fal', 'minus-circle']}
                              onClick={() => {
                                !(addBankAccount && index < array.length - 1)
                                  ? handleDeleteBankAccount(index)
                                  : openModalToDeleteExistingBankAccount(index);
                              }}
                              className={
                                !(addBankAccount && index < array.length - 1)
                                  ? classes.hoverCursor
                                  : classes.iconDisabled
                              }
                            />
                          </Col>
                        </Row>
                        <Row gutter={theme.old.spacing.unit(rowGutter)}>
                          <Col span={12}>
                            <Form.Item
                              label={t(
                                'companies:internalCompanyForm.labels.iban'
                              )}
                              name={[field.name, 'iban']}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'companies:internalCompanyForm.validation.missingIban'
                                  ),
                                },
                                {
                                  message: t(
                                    'companies:internalCompanyForm.validation.invalidIban'
                                  ),
                                  pattern: ibanPattern,
                                },
                              ]}
                            >
                              <Input
                                disabled={
                                  addBankAccount && index < array.length - 1
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t(
                                'companies:internalCompanyForm.labels.bic'
                              )}
                              name={[field.name, 'bic']}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    'companies:internalCompanyForm.validation.missingBic'
                                  ),
                                },
                                {
                                  message: t(
                                    'companies:internalCompanyForm.validation.invalidBic'
                                  ),
                                  pattern: bicPattern,
                                },
                              ]}
                            >
                              <Input
                                disabled={
                                  addBankAccount && index < array.length - 1
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider style={{ margin: '0px' }} />
                      </Col>
                    </Form.Item>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="link"
                    onClick={() => {
                      formChanged ? setModalNewBankAccountVisible(true) : add();
                      setAddBankAccount(true);
                    }}
                    disabled={addBankAccount}
                    iconProp={['fal', 'plus']}
                  >
                    {t('companies:internalCompanyForm.actions.addBankAccount')}
                  </Button>
                </Form.Item>
                <Modal
                  visible={modalNewBankAccountVisible}
                  onOk={() => {
                    form.resetFields();
                    add();
                    setAddBankAccount(true);
                    setModalNewBankAccountVisible(false);
                  }}
                  onCancel={() => {
                    setModalNewBankAccountVisible(false);
                    setAddBankAccount(false);
                  }}
                  title={t(
                    'companies:internalCompanyForm.modalNewBankAccount.title'
                  )}
                  okText={t(
                    'companies:internalCompanyForm.modalNewBankAccount.actionButton'
                  )}
                  cancelText={t(
                    'companies:internalCompanyForm.modalNewBankAccount.cancelButton'
                  )}
                >
                  {t(
                    'companies:internalCompanyForm.modalNewBankAccount.message'
                  )}
                </Modal>
                <Modal
                  visible={
                    !addBankAccount ? modalDeleteBankAccountVisible : false
                  }
                  onOk={() => handleDeleteExistingBankAccount(bankAccountIndex)}
                  onCancel={() => setModalDeleteBankAccountVisible(false)}
                  title={t(
                    'companies:internalCompanyForm.modalDeleteBankAccount.title'
                  )}
                  okText={t(
                    'companies:internalCompanyForm.modalDeleteBankAccount.actionButton'
                  )}
                  cancelText={t(
                    'companies:internalCompanyForm.modalDeleteBankAccount.cancelButton'
                  )}
                >
                  {t(
                    'companies:internalCompanyForm.modalDeleteBankAccount.message'
                  )}
                </Modal>
              </div>
            )}
          </Form.List>
        </div>
        <Row justify="end" className={classes.actionButtonsRow}>
          <Col span={24}>
            <Form.Item className={classes.submitButtonFormItem}>
              <Flex.Row
                alignItems={'center'}
                justifyContent={'flex-end'}
                childrenGap={theme.old.spacing.unit(2)}
              >
                {onCancel && (
                  <Button type="default" onClick={cancel}>
                    {cancelLabel}
                  </Button>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!(addBankAccount || formChanged)}
                >
                  {actionLabel}
                </Button>
              </Flex.Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InternalCompanyBankAccountForm;
