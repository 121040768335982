import { PRIO } from '../../../../constants';
import { MailSettings } from '../../../../models/UserSettings/MailSettings';
import { apiUrl } from '../../api/index';

/** Update Message View Layout Setting*/
export const UPDATE_MAIL_SETTINGS_REQUEST =
  PRIO + 'UPDATE_MAIL_SETTINGS_REQUEST';
export const UPDATE_MAIL_SETTINGS_COMMIT = PRIO + 'UPDATE_MAIL_SETTINGS_COMMIT';
export const UPDATE_MAIL_SETTINGS_ROLLBACK =
  PRIO + 'UPDATE_MAIL_SETTINGS_ROLLBACK';

export const updateMailSettings = (mailSettings: MailSettings) => ({
  type: UPDATE_MAIL_SETTINGS_REQUEST,
  payload: { mailSettings },
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/usersetting/usersetting/me`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: {
          mailSettings: {
            ...mailSettings,
            ...(mailSettings?.mailNavigationSize
              ? {
                  mailNavigationSize: {
                    width: mailSettings?.mailNavigationSize.width,
                    height: mailSettings?.mailNavigationSize.height,
                  },
                }
              : {}),
          },
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: UPDATE_MAIL_SETTINGS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_MAIL_SETTINGS_ROLLBACK,
      },
    },
    mailSettings,
  },
});

export const UPDATE_CACHING_ENABLED = PRIO + 'UPDATE_CACHING_ENABLED';

export const updateCachingEnabled = (value: boolean) => ({
  type: UPDATE_CACHING_ENABLED,
  payload: value,
});