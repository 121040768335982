import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { timebarFormat as defaultTimebarFormat, TimebarFormat } from './const';
import { HeaderBarResolutions } from './types';
import { calculateSections } from './utils';
import Flex from '../Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, PillProps } from '@prio365/prio365-react-library';
import { useTheme } from 'theming';
import { PrioTheme } from '../../theme/types';
import { Pill } from '@prio365/prio365-react-library';

const CLASS_NAME = 'prio-timeline-header';

export declare type StepMode = 'jump' | 'stepDiff' | 'stepResolution';

interface TimelineHeaderProps {
  className?: string;
  prefixClassName?: string;
  suffixClassName?: string;
  visibleTimeStart: Moment;
  visibleTimeEnd: Moment;
  startTime: Moment;
  endTime: Moment;
  resolutions: HeaderBarResolutions;
  timebarFormat?: TimebarFormat;
  disableTopBar?: boolean;
  disableBottomBar?: boolean;
  enableStepper?: boolean;
  timelineWidth: number;
  prefixWidth: number;
  suffixWidth: number;
  prefixRenderer?: () => React.ReactNode;
  suffixRenderer?: () => React.ReactNode;
  sectionRenderer?: (
    date: Moment,
    sectionType: 'major' | 'minor'
  ) => React.ReactNode;
  onTimeRangeChange: (
    visibleTimeStart: Moment,
    visibleTimeEnd: Moment,
    startTime: Moment,
    endTime: Moment
  ) => void;
  onVisibleStartEndTimeChange?: (startTime: Moment, endTime: Moment) => void;
  stepperLabelRenderer?: (
    startTime: Moment,
    endTime: Moment
  ) => React.ReactNode;
  stepMode?: StepMode;
  pills?: PillProps[];
}

export const TimelineHeader: React.FC<TimelineHeaderProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    prefixClassName,
    suffixClassName,
    visibleTimeStart,
    visibleTimeEnd,
    startTime,
    endTime,
    resolutions,
    timebarFormat = defaultTimebarFormat,
    disableTopBar,
    disableBottomBar,
    enableStepper,
    timelineWidth,
    prefixWidth,
    suffixWidth,
    prefixRenderer,
    suffixRenderer,
    onTimeRangeChange,
    onVisibleStartEndTimeChange,
    sectionRenderer,
    stepperLabelRenderer,
    stepMode = 'jump',
    pills,
  } = props;

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [selectedDate, setSelectedDate] = useState(moment());
  const topSections = useMemo(
    () =>
      calculateSections(
        resolutions.top,
        visibleTimeStart,
        visibleTimeEnd,
        timelineWidth
      ),
    [resolutions.top, visibleTimeStart, visibleTimeEnd, timelineWidth]
  );

  const bottomSections = useMemo(
    () =>
      calculateSections(
        resolutions.bottom,
        visibleTimeStart,
        visibleTimeEnd,
        timelineWidth
      ),
    [resolutions.bottom, visibleTimeStart, visibleTimeEnd, timelineWidth]
  );

  const formatLength = useMemo(
    () => (timelineWidth > 720 ? 'long' : 'short'),
    [timelineWidth]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleStepToday = () => {
    const today = moment();
    setSelectedDate(today);
    let nextStartTime: Moment = startTime.clone();
    let nextEndTime: Moment = endTime.clone();

    const todayStartDiff = nextStartTime.diff(moment(), resolutions.top);
    const todayEndDiff = nextEndTime.diff(moment(), resolutions.top);
    const diff =
      todayStartDiff >= 0
        ? Math.max(todayStartDiff, todayEndDiff)
        : Math.min(todayStartDiff, todayEndDiff);

    switch (stepMode) {
      case 'jump': {
        nextStartTime = startTime.clone().subtract(diff, resolutions.top);
        nextEndTime = endTime.clone().subtract(diff, resolutions.top);
        break;
      }
      case 'stepDiff': {
        const diff = nextEndTime.diff(nextStartTime, resolutions.bottom);
        while (
          nextStartTime.isAfter(moment()) ||
          nextEndTime.isBefore(moment())
        ) {
          if (nextEndTime.isBefore(moment())) {
            nextStartTime = nextEndTime.clone();
            nextEndTime = nextStartTime.clone().add(diff, resolutions.bottom);
          } else {
            nextEndTime = nextStartTime.clone();
            nextStartTime = nextEndTime
              .clone()
              .subtract(diff, resolutions.bottom);
          }
        }
        break;
      }
      case 'stepResolution': {
        nextStartTime = moment().startOf(resolutions.top);
        nextEndTime = moment()
          .add(topSections.length - 1, resolutions.top)
          .endOf(resolutions.top);
        break;
      }
    }

    handelTimeRangeChange(nextStartTime, nextEndTime);
  };

  const handleStepBackward = () => {
    const prevDate = selectedDate.clone().subtract(1, 'day');
    setSelectedDate(prevDate);
    let nextStartTime: Moment = startTime.clone();
    let nextEndTime: Moment = endTime.clone();

    switch (stepMode) {
      case 'jump': {
        nextStartTime = startTime.clone().subtract(1, resolutions.top);
        nextEndTime = endTime.clone().subtract(1, resolutions.top);
        break;
      }
      case 'stepDiff': {
        const diff = endTime.diff(startTime, resolutions.bottom);
        nextStartTime = startTime.clone().subtract(diff, resolutions.bottom);
        nextEndTime = startTime.clone();
        break;
      }
      case 'stepResolution': {
        nextStartTime = startTime
          .clone()
          .subtract(1, resolutions.top)
          .startOf(resolutions.top);
        nextEndTime = endTime
          .clone()
          .subtract(1, resolutions.top)
          .endOf(resolutions.top);
        break;
      }
    }

    handelTimeRangeChange(nextStartTime, nextEndTime);
  };

  const handleStepForward = () => {
    const nextDate = selectedDate.clone().add(1, 'day');
    setSelectedDate(nextDate);
    let nextStartTime: Moment = startTime.clone();
    let nextEndTime: Moment = endTime.clone();
    switch (stepMode) {
      case 'jump': {
        nextStartTime = startTime.clone().add(1, resolutions.top);
        nextEndTime = endTime.clone().add(1, resolutions.top);
        break;
      }
      case 'stepDiff': {
        const diff = endTime.diff(startTime, resolutions.bottom);
        nextStartTime = endTime.clone();
        nextEndTime = endTime.clone().add(diff, resolutions.bottom);
        break;
      }
      case 'stepResolution': {
        nextStartTime = startTime
          .clone()
          .add(1, resolutions.top)
          .startOf(resolutions.top);
        nextEndTime = endTime
          .clone()
          .add(1, resolutions.top)
          .endOf(resolutions.top);
        break;
      }
    }
    handelTimeRangeChange(nextStartTime, nextEndTime);
  };

  const handelTimeRangeChange = (
    nextStartTime: Moment,
    nextEndTime: Moment
  ) => {
    const diffStartTimeNotVisible = visibleTimeStart.diff(
      startTime,
      resolutions.bottom
    );
    const diffEndTimeNotVisible = endTime.diff(
      visibleTimeEnd,
      resolutions.bottom
    );

    const nextVisualStart: Moment = nextStartTime
      .clone()
      .add(diffStartTimeNotVisible, resolutions.bottom);
    const nextVisualEnd: Moment = nextEndTime
      .clone()
      .subtract(diffEndTimeNotVisible, resolutions.bottom);

    onTimeRangeChange(
      nextVisualStart,
      nextVisualEnd,
      nextStartTime,
      nextEndTime
    );
  };

  const handleOnVisibleStartEndTimeChange = (direction: 'left' | 'right') => {
    if (onVisibleStartEndTimeChange) {
      if (direction === 'left') {
        onVisibleStartEndTimeChange(
          visibleTimeStart.clone().subtract(1, resolutions.bottom),
          visibleTimeEnd.clone().subtract(1, resolutions.bottom)
        );
      } else {
        onVisibleStartEndTimeChange(
          visibleTimeStart.clone().add(1, resolutions.bottom),
          visibleTimeEnd.clone().add(1, resolutions.bottom)
        );
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(CLASS_NAME, className)}>
      <Flex.Column>
        {enableStepper && (
          <Flex.Row
            className={`${CLASS_NAME}-stepper`}
            childrenGap={theme.old.spacing.unit(1)}
            margin-bottom={theme.old.spacing.unit(1)}
          >
            <Button
              type="link"
              iconProp={['fal', 'calendar']}
              onClick={handleStepToday}
            />
            <Button
              type="link"
              iconProp={['fal', 'chevron-left']}
              onClick={handleStepBackward}
            />

            <div className={`${CLASS_NAME}-currentSection`}>
              {stepperLabelRenderer
                ? stepperLabelRenderer(
                    topSections.at(0)?.date ?? moment(),
                    topSections.at(topSections.length - 1)?.date ?? moment()
                  )
                : topSections
                    .at(0)
                    ?.date?.format(
                      timebarFormat.majorLabels[resolutions.top].long
                    ) ?? ''}
            </div>

            <Button
              type="link"
              iconProp={['fal', 'chevron-right']}
              onClick={handleStepForward}
            />
            <Flex.Row
              className={`${CLASS_NAME}-pills`}
              childrenGap={theme.old.spacing.unit(1)}
            >
              {(pills ?? []).map((pillProps) => (
                <Pill
                  style={{ fontSize: '15px', height: '32px' }}
                  {...pillProps}
                />
              ))}
            </Flex.Row>
          </Flex.Row>
        )}
        <Flex.Row flex={1} className={`${CLASS_NAME}-header`}>
          <div
            className={classNames(
              `${CLASS_NAME}-headerBarPrefixCell`,
              prefixClassName
            )}
            style={{ width: prefixWidth }}
          >
            {prefixRenderer && prefixRenderer()}
          </div>
          <div className={`${CLASS_NAME}-barOuter`}>
            {!disableTopBar && (
              <div
                className={classNames(
                  `${CLASS_NAME}-bar`,
                  `${CLASS_NAME}-topBar`
                )}
              >
                {topSections.map(({ width, date }) => {
                  const isPreviousTimeSkip = date.isAfter(
                    date.clone().subtract(1, resolutions.top),
                    resolutions.top
                  );
                  return (
                    <div
                      className={classNames(
                        `${CLASS_NAME}-barItem`,
                        'timeline-header-bar-section',
                        isPreviousTimeSkip && `${CLASS_NAME}-barItemSkip`
                      )}
                      style={{
                        width,
                      }}
                    >
                      {sectionRenderer?.(date, 'major') ??
                        date.format(
                          timebarFormat.majorLabels[resolutions.top][
                            formatLength
                          ]
                        )}
                    </div>
                  );
                })}
              </div>
            )}
            {!disableBottomBar && (
              <div
                className={classNames(
                  `${CLASS_NAME}-bar`,
                  `${CLASS_NAME}-bottomBar`
                )}
              >
                {startTime.isBefore(visibleTimeStart, resolutions.bottom) && (
                  <div
                    className={`${CLASS_NAME}-visibleTimeRangeStepper`}
                    style={{
                      left: 0,
                      background: `linear-gradient(90deg, ${theme.old.palette.backgroundPalette.main}80 0%, rgba(0,0,0,0) 50%)`,
                    }}
                    onClick={() => handleOnVisibleStartEndTimeChange('left')}
                  >
                    <FontAwesomeIcon icon={['fal', 'chevron-left']} />
                  </div>
                )}
                {bottomSections.map(({ width, date }) => {
                  const isPreviousTimeSkip = date.isAfter(
                    date.clone().subtract(1, resolutions.bottom),
                    resolutions.top
                  );
                  return (
                    <div
                      className={classNames(
                        `${CLASS_NAME}-barItem`,
                        'timeline-header-bar-section',
                        isPreviousTimeSkip && `${CLASS_NAME}-barItemSkip`
                      )}
                      style={{
                        width,
                      }}
                    >
                      {sectionRenderer?.(date, 'minor') ??
                        date.format(
                          timebarFormat.minorLabels[resolutions.bottom][
                            formatLength
                          ]
                        )}
                    </div>
                  );
                })}
                {endTime.isAfter(visibleTimeEnd, resolutions.bottom) && (
                  <div
                    className={`${CLASS_NAME}-visibleTimeRangeStepper`}
                    style={{
                      right: 0,
                      background: `linear-gradient(270deg, ${theme.old.palette.backgroundPalette.main}80 0%, rgba(0,0,0,0) 50%)`,
                    }}
                    onClick={() => handleOnVisibleStartEndTimeChange('right')}
                  >
                    <FontAwesomeIcon icon={['fal', 'chevron-right']} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={classNames(
              `${CLASS_NAME}-headerBarSuffixCell`,
              suffixClassName
            )}
            style={{ width: suffixWidth }}
          >
            {suffixRenderer && suffixRenderer()}
          </div>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default TimelineHeader;
