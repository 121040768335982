import React, { useMemo } from 'react';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateTimeString } from '../../../models/Types';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {},
  tabs: {
    width: '100%',
    '& .ant-tabs-nav': {
      margin: 0,
    },
    '& .ant-tabs-nav-wrap': {
      justifyContent: 'flex-end',
    },
    '& .ant-tabs-tab': {
      margin: '0 0 0 32px',
    },
  },
  navigation: {
    width: '100%',
    height: 48,
  },
  label: {
    margin: 'auto',
    minWidth: 100,
    textAlign: 'center',
  },
}));

declare type View = 'day' | 'week' | 'month';

interface ToolbarProps {
  className?: string;
  toolbar?: any;
  onChange?: (dateTime: DateTimeString) => void;
  disabledViews?: View[];
  children?: React.ReactNode;
  isMonthClosed?: boolean;
}

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    toolbar,
    onChange,
    disabledViews,
    children,
    isMonthClosed,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const view = useMemo(() => toolbar.view, [toolbar.view]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnChange = (dateTime: DateTimeString) => {
    if (onChange) {
      onChange(dateTime);
    }
  };

  const goToBack = () => {
    const date = moment(toolbar.date);
    switch (view) {
      case 'day':
        date.subtract(1, 'day');
        break;
      case 'week':
        date.subtract(1, 'week');
        break;
      default:
        date.subtract(1, 'month');
        break;
    }
    const newDate = date.toISOString(true).split('T')[0];
    handleOnChange(newDate);
    toolbar.onNavigate('prev', newDate);
  };

  const goToNext = () => {
    const date = moment(toolbar.date);
    switch (view) {
      case 'day':
        date.add(1, 'day');
        break;
      case 'week':
        date.add(1, 'week');
        break;
      default:
        date.add(1, 'month');
        break;
    }
    const newDate = date.toISOString(true).split('T')[0];
    handleOnChange(newDate);
    toolbar.onNavigate('next', newDate);
  };

  const goToCurrent = () => {
    const today = moment().toISOString(true).split('T')[0];
    handleOnChange(today);
    toolbar.onNavigate('current', today);
  };

  const goToView = (view: string) => {
    toolbar.onView(view);
  };
  //#endregion

  //#region ------------------------------ Effects
  // #endregion;

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Tabs
        className={classes.tabs}
        activeKey={view}
        tabBarExtraContent={{
          left: (
            <Flex.Row
              className={classes.navigation}
              alignItems="center"
              childrenGap={theme.old.spacing.unit(1)}
            >
              <Button
                type="link"
                iconProp={['fal', 'calendar-day']}
                onClick={goToCurrent}
              />
              <Button
                type="link"
                iconProp={['fal', 'chevron-left']}
                onClick={goToBack}
              />
              <Typography.Text className={classes.label}>
                {view === 'day'
                  ? moment(toolbar.date).format('D. MMMM, dd.')
                  : toolbar.label}
              </Typography.Text>
              <Button
                type="link"
                iconProp={['fal', 'chevron-right']}
                onClick={goToNext}
              />
            </Flex.Row>
          ),
          right: isMonthClosed && (
            <Flex.Row
              title={t('calendar:toolbar.tooltip.closed')}
              childrenGap={theme.old.spacing.unit(1)}
              alignItems="center"
            >
              <FontAwesomeIcon icon={['fal', 'lock']} />
              <span>{t('calendar:toolbar.tooltip.closed')}</span>
            </Flex.Row>
          ),
        }}
        onChange={(key) => goToView(key)}
      >
        {!disabledViews?.includes('month') && (
          <TabPane key="month" tab={t('calendar:toolbar.views.month')} />
        )}
        {!disabledViews?.includes('week') && (
          <TabPane key="week" tab={t('calendar:toolbar.views.week')} />
        )}
        {!disabledViews?.includes('day') && (
          <TabPane key="day" tab={t('calendar:toolbar.views.day')} />
        )}
      </Tabs>
      {children}
    </Flex.Column>
  );
};

export default Toolbar;
