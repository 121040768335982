import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Result } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import {
  getTimeRecord,
  getTimeRecordRedirect,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import EditTimeRecord from './EditTimeRecord/EditTimeRecord';
import { makePrioStyles } from '../../../theme/utils';
import { isTemporaryId } from '../../../util';
import { TimeRecord } from '../../../models/TimeRecord';
import { EditTimeRecordContextType, TimeRecordId } from '../../../models/Types';
import PrioSpinner from '../../../components/PrioSpinner';
import useAccessRights from '../../users/hooks/useAccessRights';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    marginTop: '0px',
  },
  contactDetails: {
    height: `calc(100% - 30px)`,
    overflow: 'hidden',
  },
  buttonBack: {
    paddingLeft: '0px',
  },
  fullHeight: {
    height: '100%',
  },
}));
interface TimeRecordDetailsDrawerProps {
  goBack: VoidFunction;
  switchToOverview: VoidFunction;
  closeDrawer?: VoidFunction;
  timeRecordId: TimeRecordId;
  redirectTimeRecordId: (timeRecordId: TimeRecordId) => void;
}

export const TimeRecordDetailsDrawer: React.FC<TimeRecordDetailsDrawerProps> = (
  props
) => {
  const classes = useStyles();
  const {
    goBack,
    switchToOverview,
    closeDrawer,
    timeRecordId,
    redirectTimeRecordId,
  } = props;
  const { t } = useTranslation();

  const timeRecord = useSelector<RootReducerState, TimeRecord>((state) =>
    getTimeRecord(state, timeRecordId)
  );
  const redirect = useSelector<RootReducerState, string>((state) =>
    getTimeRecordRedirect(state, timeRecordId)
  );
  const userMe = useSelector(getUserMe);

  const accessRights = useAccessRights([
    'writeOtherUserTimeRecordByGlobalRole',
  ]);

  const contextType: EditTimeRecordContextType = useMemo(() => {
    if (timeRecord?.contactId === userMe?.id) {
      return 'me';
    }
    if (accessRights['writeOtherUserTimeRecordByGlobalRole']) {
      return 'global';
    }
    return 'office';
  }, [accessRights, timeRecord, userMe]);

  useEffect(() => {
    if (redirect) {
      redirectTimeRecordId(redirect);
    }
  }, [redirect, redirectTimeRecordId]);

  if (timeRecord) {
    return (
      <div className={classes.fullHeight}>
        <Button
          onClick={goBack}
          type="link"
          iconProp={['fal', 'chevron-left']}
          className={classes.buttonBack}
        >
          {t('common:back')}
        </Button>
        <EditTimeRecord
          className={classNames(classes.contactDetails, classes.noPadding)}
          timeRecordId={timeRecordId}
          timeRecord={timeRecord}
          onCancel={closeDrawer ?? goBack}
          onFinish={switchToOverview}
          onDelete={switchToOverview}
          switchToOverview={switchToOverview}
          contextType={contextType}
          officeRoles={['officeAdmin', 'officeAssistance', 'officeController']}
        />
      </div>
    );
  }

  if (isTemporaryId(timeRecordId)) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <Result
          status="500"
          title={t('common:weAreSorry')}
          subTitle={t('timeRecords:errorMessages.createError')}
          extra={
            <Button onClick={closeDrawer ?? goBack}>
              {t('common:actions.close')}
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
      <PrioSpinner size="large" />
    </div>
  );
};

export default TimeRecordDetailsDrawer;
