import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { Button, createForm } from '@prio365/prio365-react-library';
import CustomDateRangePicker from '../../../../components/CustomDateRangePicker';
import {
  CreateMedicalCertificate,
  MedicalCertificate,
  medicalCertificateType,
} from '../../../../models/MedicalCertificate';
import moment from 'moment';
import {
  AbsenceProposalId,
  DateTimeString,
  OfficeId,
} from '../../../../models/Types';
import { useTranslation } from 'react-i18next';
import { Select, notification } from 'antd';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  apiCreateMedicalCertificate,
  apiUpdateMedicalCertificate,
  apiUploadAttachmentForMedicalCertificate,
} from '../../api';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import UploadField, {
  PrioFile,
} from '../../../../components/Upload/UploadField';
import Flex from '../../../../components/Flex';
import { createTemporaryId } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  draggerItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  upload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
    border: `1px dashed ${theme.colors.application.border}`,
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
    backgroundColor: theme.colors.application.background.light,
    transition: 'all 0.3s',
    '&:hover': {
      borderColor: theme.old.palette.primaryColor,
    },
  },
}));

type FormProps = Omit<CreateMedicalCertificate, 'from' | 'to'> & {
  period: [DateTimeString, DateTimeString];
};

const Form = createForm<FormProps>();

export interface UploadMedicalCertificateFormProps {
  className?: string;
  from: DateTimeString;
  to: DateTimeString;
  absenceProposalId: AbsenceProposalId;
  initialMedicalCertificate?: MedicalCertificate;
  officeId?: OfficeId;
  isUserMe?: boolean;
  isEditMode: boolean;
  onClose: (medicalCertificate?: MedicalCertificate, file?: PrioFile) => void;
}

export const UploadMedicalCertificateForm: React.FC<
  UploadMedicalCertificateFormProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    from,
    to,
    absenceProposalId,
    initialMedicalCertificate,
    officeId,
    isUserMe,
    isEditMode: isAbsenceEditMode,
    onClose,
  } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const formRef = useRef<FormikProps<FormProps>>(null);

  const isEditMode = !!initialMedicalCertificate;

  const additionalSubmitProps = useMemo(
    () => ({
      officeId,
      isUserMe,
    }),
    [officeId, isUserMe]
  );

  const initialFormValues: FormProps = useMemo(() => {
    if (initialMedicalCertificate) {
      const { from, to, medicalCertificateType, absenceProposalId } =
        initialMedicalCertificate;
      return {
        medicalCertificateType: medicalCertificateType,
        period: [from, to],
        absenceProposalId: absenceProposalId,
      };
    }
    return {
      medicalCertificateType: 'initialMedicalCertificate',
      period: [from, to],
      ...(absenceProposalId ? { absenceProposalId } : {}),
    };
  }, [from, to, absenceProposalId, initialMedicalCertificate]);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [fileToUpload, setFileToUpload] = useState<PrioFile>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnSubmit = async (values: FormProps) => {
    if (!isAbsenceEditMode) {
      const tempRequest: MedicalCertificate = {
        medicalCertificateId:
          initialMedicalCertificate?.medicalCertificateId ??
          createTemporaryId(),
        medicalCertificateType: values.medicalCertificateType,
        from: values.period[0],
        to: values.period[1],
        attachmentUploaded: false,
        absenceProposalId:
          initialMedicalCertificate?.absenceProposalId ?? createTemporaryId(),
        lastModifiedAt: moment().toISOString(true),
        state: 'new',
      };
      onClose(tempRequest, fileToUpload);
    } else {
      setIsFetching(true);

      const { period, ...rest } = values;
      const request = {
        ...rest,
        from: period[0],
        to: period[1],
      };
      const result = isEditMode
        ? await apiUpdateMedicalCertificate(
            initialMedicalCertificate?.medicalCertificateId,
            {
              from: period[0],
              to: period[1],
              medicalCertificateType: rest.medicalCertificateType,
            },
            additionalSubmitProps
          )
        : await apiCreateMedicalCertificate(request);

      if (!result.data) {
        notification.error({
          message: t('common:error'),
          description: isEditMode
            ? t('absences:errorMessages.updateMedicalCertificateError')
            : t('absences:errorMessages.createMedicalCertificateError'),
        });
      } else if (fileToUpload) {
        const { result: uploadResult } =
          await apiUploadAttachmentForMedicalCertificate(
            result.data?.medicalCertificateId,
            fileToUpload
          );

        if (!uploadResult.ok) {
          notification.error({
            message: t('common:error'),
            description: t(
              'absences:errorMessages.uploadMedicalCertificateAttachmentError'
            ),
          });
        }
      }

      onClose(result.data, !!result.data ? fileToUpload : null);

      setFileToUpload(null);
      setIsFetching(false);
    }
  };

  const handleReset = () => {
    setFileToUpload(null);
    onClose();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues(initialFormValues);
      setFileToUpload(null);
    }
  }, [initialFormValues]);
  //#endregion

  return (
    <Form
      className={classNames(classes.root, className)}
      ref={formRef}
      initialValues={initialFormValues}
      onSubmit={handleOnSubmit}
      onReset={handleReset}
      actions={{
        submit: {
          htmlType: 'submit',
          children: t('common:actions.save'),
          type: 'primary',
          disabled: isFetching,
          loading: isFetching,
        },
        cancel: {
          htmlType: 'reset',
          children: t('common:actions.cancel'),
          type: 'default',
        },
      }}
    >
      <Form.Item
        name="period"
        label={t('absences:medicalCertificate.form.labels.timeRange')}
        validationSchema={Yup.tuple([
          Yup.string().required(),
          Yup.string().required(),
        ]).required()}
        fullWidth
      >
        {({ value, onChange }) => (
          <CustomDateRangePicker
            startDate={moment(value[0] as DateTimeString)} // momentPropTypes.momentObj or null,
            startDateId="medicalCertificate_startDateId" // PropTypes.string.isRequired,
            startDatePlaceholderText={moment()
              .add('1', 'day')
              .startOf('day')
              .format('DD.MM.YYYY')}
            endDate={moment(value[1] as DateTimeString)} // momentPropTypes.momentObj or null,
            endDateId="medicalCertificate_endDateId" // PropTypes.string.isRequired,
            endDatePlaceholderText={moment()
              .add('1', 'day')
              .startOf('day')
              .format('DD.MM.YYYY')}
            onDatesChange={({ startDate, endDate }) => {
              onChange([
                (startDate?.toISOString(true) ?? value[0]).split('T')[0],
                (endDate?.toISOString(true) ?? value[1]).split('T')[0],
              ]);
            }} // PropTypes.func.isRequired,
            anchorDirection={'ANCHOR_RIGHT'}
            small={true}
            regular={false}
            withFullScreenPortal={false}
            daySize={30}
            hideKeyboardShortcutsPanel={true}
            minimumNights={0}
            showClearDates={false}
            isOutsideRange={false}
            blockDatesBefore={moment(from)}
            blockDatesAfter={moment(to)}
          />
        )}
      </Form.Item>
      <Form.Item
        name="medicalCertificateType"
        label={t('absences:medicalCertificate.form.labels.type')}
        validationSchema={Yup.string().required()}
        fullWidth
      >
        {({ value, onChange }) => (
          <Select
            value={value}
            onChange={onChange}
            options={medicalCertificateType.map((type) => ({
              value: type,
              label: t(`absences:medicalCertificate.types.${type}`),
            }))}
          />
        )}
      </Form.Item>
      <div
        style={{
          width: '100%',
          height: 150,
        }}
      >
        <div className="prio-form-item-label">
          <span
            style={{
              marginRight: 8,
              fontSize: theme.font.fontSize.large,
              fontWeight: theme.font.fontWeight.bold,
            }}
          >
            {t('absences:medicalCertificate.form.labels.upload.primary')}
          </span>
          <span>
            {t('absences:medicalCertificate.form.labels.upload.secondary')}
          </span>
        </div>
        {!fileToUpload ? (
          <UploadField
            id="medicalCertificateUploader"
            multiple={false}
            onUploadRequest={async (file) => {
              setFileToUpload(file);
            }}
            clickable
            className={classes.upload}
          >
            <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
              <FontAwesomeIcon
                icon={['fal', 'upload']}
                size="3x"
                color={theme.colors.application.typography.default}
              />
              {initialMedicalCertificate?.attachmentUploaded && (
                <div
                  style={{
                    fontSize: 12,
                    color: theme.colors.application.typography.default,
                  }}
                >
                  {t(
                    'absences:medicalCertificate.form.labels.upload.alreadyUploaded'
                  )}
                </div>
              )}
            </Flex.Column>
          </UploadField>
        ) : (
          <Flex.Row alignItems="center">
            <Flex.Item
              flex={1}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {fileToUpload.name}
            </Flex.Item>
            <Button
              iconProp={['fal', 'trash']}
              iconColor={theme.old.palette.chromaticPalette.red}
              onClick={() => setFileToUpload(null)}
              type="default"
            />
          </Flex.Row>
        )}
      </div>
    </Form>
  );
};

export default UploadMedicalCertificateForm;
