import React, { useEffect, useState, useReducer, Reducer } from 'react';

import { Table, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import {
  InternalJobTitleSuggestion,
  CreateInternalJobTitleSuggestion,
} from '../../../models/InternalJobTitleSuggestion';
import { InternalJobTitleSuggestionId } from '../../../models/Types';
import equals from 'deep-equal';
import { isTemporaryId } from '../../../util';
import { tableTranslations } from '../../../util/table';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-row .ant-table-cell .internal-contact-job-title-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .internal-contact-job-title-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
}));

interface InternalJobTitlesTableProps {
  className?: string;
  internalJobTitles: InternalJobTitleSuggestion[];
  onIsDirtyChanged?: (
    isDirty: boolean,
    internalJobTitles?: InternalJobTitleSuggestion[]
  ) => void;
  onNewJobTitle?: (internalJobTitle: CreateInternalJobTitleSuggestion) => void;
  onDelete?: (
    internalJobTitleSuggestionId: InternalJobTitleSuggestionId
  ) => void;
}

interface TableEntry extends InternalJobTitleSuggestion {
  key: string;
  addInternalJobTitleRow: boolean;
  isTemporary: boolean;
}

interface UpdateNewInternalJobTitleAction {
  name: string;
}

interface NewInternalJobTitle extends CreateInternalJobTitleSuggestion {
  isValid: boolean;
}

export const InternalJobTitlesTable: React.FC<InternalJobTitlesTableProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    className,
    internalJobTitles: originalInternalJobTitles,
    onIsDirtyChanged,
    onNewJobTitle: onNewSuggestion,
    onDelete,
  } = props;

  const [internalJobTitles, setInternalJobTitles] = useState<
    InternalJobTitleSuggestion[]
  >(originalInternalJobTitles);

  const [newInternalJobTitleRowActive, setNewInternalJobTitleRowActive] =
    useState(false);

  useEffect(() => {
    setInternalJobTitles(originalInternalJobTitles);
    onIsDirtyChanged(false, originalInternalJobTitles);
  }, [originalInternalJobTitles, onIsDirtyChanged]);

  const validate: (state: NewInternalJobTitle) => boolean = (
    state: NewInternalJobTitle
  ) => state.name.trim().length > 0;

  const emptyNewInternalJobTitle: NewInternalJobTitle = {
    name: '',
    isValid: false,
  };

  const [newInternalJobTitle, updateNewInternalJobTitle] = useReducer<
    Reducer<NewInternalJobTitle, UpdateNewInternalJobTitleAction | 'clear'>
  >(
    (
      state: NewInternalJobTitle,
      action: UpdateNewInternalJobTitleAction | 'clear'
    ) => {
      if (action === 'clear') {
        return emptyNewInternalJobTitle;
      }
      const updatedState = { ...state, name: action.name };
      return {
        ...updatedState,
        isValid: validate(updatedState),
      };
    },
    emptyNewInternalJobTitle
  );

  const updateInternalJobTitle = (
    internalJobTitleSuggestionId: InternalJobTitleSuggestionId,
    name: string
  ) => {
    const updatedInternalJobTitles = internalJobTitles.map((entry) =>
      entry.internalJobTitleSuggestionId === internalJobTitleSuggestionId
        ? { ...entry, name }
        : entry
    );
    setInternalJobTitles(updatedInternalJobTitles);

    if (onIsDirtyChanged) {
      const isDirty = !equals(
        updatedInternalJobTitles,
        originalInternalJobTitles
      );
      onIsDirtyChanged(isDirty, updatedInternalJobTitles);
    }
  };

  const saveNewInternalJobTitle = () => {
    if (onNewSuggestion) {
      onNewSuggestion(newInternalJobTitle);
      cancelNewInternalJobTitleRow();
      updateNewInternalJobTitle('clear');
    }
  };

  const deleteInternalJobTitle = (
    internalJobTitleSuggestionId: InternalJobTitleSuggestionId
  ) => {
    if (onDelete) {
      onDelete(internalJobTitleSuggestionId);
    }
  };

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('settings:internalJobTitlesTable.columnTitles.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      render: (value, record) => (
        <Input
          value={
            record.addInternalJobTitleRow ? newInternalJobTitle.name : value
          }
          onChange={(e) =>
            record.addInternalJobTitleRow
              ? updateNewInternalJobTitle({
                  name: e.currentTarget.value,
                })
              : updateInternalJobTitle(
                  record.internalJobTitleSuggestionId,
                  e.currentTarget.value
                )
          }
          disabled={record.isTemporary}
        />
      ),
    },
    {
      render: (_, record) =>
        record.addInternalJobTitleRow ? (
          <Button
            disabled={!newInternalJobTitle.isValid}
            onClick={saveNewInternalJobTitle}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            onClick={() =>
              deleteInternalJobTitle(record.internalJobTitleSuggestionId)
            }
            className="internal-contact-job-title-table-delete-button"
            disabled={record.isTemporary}
            iconProp={['fal', 'trash']}
            type="link"
          ></Button>
        ),
      width: 80,
    },
  ];

  const data: TableEntry[] = internalJobTitles.map(
    (internalJobTitle: InternalJobTitleSuggestion, index: number) => ({
      key: internalJobTitle.internalJobTitleSuggestionId,
      ...internalJobTitle,
      isTemporary: isTemporaryId(internalJobTitle.internalJobTitleSuggestionId),
      addInternalJobTitleRow: false,
    })
  );

  const enableNewInternalJobTitleRow = () =>
    setNewInternalJobTitleRowActive(true);
  const cancelNewInternalJobTitleRow = () =>
    setNewInternalJobTitleRowActive(false);

  const footer = () => (
    <div>
      <Button
        type="link"
        onClick={enableNewInternalJobTitleRow}
        disabled={newInternalJobTitleRowActive}
        iconProp={['fal', 'plus']}
      >
        {t('settings:internalJobTitlesTable.actions.add')}
      </Button>
    </div>
  );

  return (
    <Table
      className={classNames(classes.root, className)}
      dataSource={
        newInternalJobTitleRowActive
          ? ([
              ...data,
              {
                key: 'addInternalJobTitle',
                name: '',
                addInternalJobTitleRow: true,
              },
            ] as TableEntry[])
          : data
      }
      columns={columns}
      pagination={false}
      rowSelection={{
        type: 'checkbox',
      }}
      footer={footer}
      locale={tableTranslations(t)}
    />
  );
};

export default InternalJobTitlesTable;
