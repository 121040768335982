import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { List, Typography, notification, Dropdown, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makePrioStyles } from '../../../../theme/utils';
import { Message } from '../../../../models/Message';
import Flex from '../../../../components/Flex';
import { shortDateFormatString } from '../../../../util';
import { DND_TYPE_EMAIL } from '../../../../dnd/types';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import {
  DriveItemId,
  GroupId,
  MessageId,
  ProjectId,
} from '../../../../models/Types';
import { apiAddEmlToDriveFolder } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import PrioSpinner from '../../../../components/PrioSpinner';
import { MailSettings } from '../../../../models/UserSettings/MailSettings';
import { copyMessageToProject } from '../../actions/actionControllers/messageActionController';
import { driveItemCreated } from '../../../documents/actions';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  getMessageIsMoving,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  listItem: {
    '& .mail-list-actions > div': {
      visibility: 'hidden',
    },
    borderBottom: theme.old.borders.content,
    padding: theme.old.components.mailListItem.spacing,
    borderLeft: '4px solid transparent',
    lineHeight: '1.3em',
    fontSize: theme.old.components.mailListItem.fontSize,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      '& .mail-list-actions > div': {
        visibility: 'visible',
      },
    },
    '& .svg-inline--fa': {
      fontSize:
        theme.old.components.mailListItem.fontSize > 13
          ? theme.old.components.mailListItem.fontSize
          : 13,
    },
    '& .ant-list-item-meta-description': {
      fontSize: theme.old.components.mailListItem.fontSize,
      lineHeight: '1.3em',
    },
  },
  flagged: {
    backgroundColor: theme.old.components.mailListItem.flaggedMessageBackground,
  },
  unreadItem: {
    borderLeft: `4px solid ${theme.old.palette.primaryColor}`,
  },
  listItemMeta: {
    alignItems: 'center',
    '& .ant-list-item-meta-title': {
      fontSize: theme.old.components.mailListItem.fontSize,
      marginBottom: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },
  attachmentIcon: {
    marginLeft: theme.old.spacing.unit(1),
    color: theme.old.typography.colors.muted,
  },
  wrapTitle: {
    overflowWrap: 'anywhere',
    '&.ant-typography.ant-typography-secondary': {
      color: theme.old.typography.colors.muted,
    },
  },
  wrapTitleUnread: {
    fontWeight: theme.old.typography.fontWeight.bold,
    '&.ant-typography.ant-typography-secondary': {
      color: theme.old.palette.primaryColor,
    },
  },
  wrapTitleContainer: {
    paddingRight: theme.old.components.mailListItem.spacing,
    '-webkit-line-clamp': 1,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
  },
  bodyPreview: {
    flex: 1,
    fontWeight: theme.old.typography.fontWeight.regular,
    '&.ant-typography.ant-typography-secondary': {
      marginBottom: theme.old.components.mailListItem.spacing,
    },
  },
  quickActions: {
    color: theme.old.typography.colors.muted,
    '& :hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  spinner: {
    position: 'absolute',
    width: '100%',
    backgroundColor: '#ffffff80',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  senderName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: theme.old.typography.fontWeight.regular,
  },
  unreadSenderName: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
}));

interface WidgetMailListItemProps {
  message: Message;
  projectId: ProjectId;
  moveToFolder: (
    message: Message,
    selectedMessages: Message[],
    projectId: ProjectId
  ) => void;
  createToDo: (message: Message) => void;
  settings?: MailSettings;
  endDrop: (messageId: MessageId) => void;
  style: React.CSSProperties;
  [anyProperty: string]: any;
}
export const WidgetMailListItem: React.FC<WidgetMailListItemProps> = (
  props
) => {
  const {
    message,
    projectId,
    moveToFolder,
    createToDo,
    settings,
    endDrop,
    style,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();

  const isMoving = useSelector<RootReducerState, boolean>((state) =>
    getMessageIsMoving(state, message.id)
  );
  const [isCreatingEml, setIsCreatingEml] = useState<boolean>(false);
  const dispatch = useDispatch();

  const uploadEMLToDriveFolder = async (
    mailboxProjectId: ProjectId,
    messageId: MessageId,
    driveItemId: DriveItemId,
    destinationGroupId: GroupId
  ) => {
    setIsCreatingEml(true);
    const { data } = await apiAddEmlToDriveFolder(
      mailboxProjectId,
      messageId,
      driveItemId,
      destinationGroupId
    );
    if (data) {
      dispatch(
        driveItemCreated(
          data,
          data.parentReference?.path === '/drive/root:'
            ? `root-group-${destinationGroupId}`
            : data.parentReference.id,
          destinationGroupId
        )
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.emlUploadError'),
      });
    }
    setIsCreatingEml(false);
  };

  const [{ opacity }, dragRef, preview] = useDrag({
    type: DND_TYPE_EMAIL,
    item: { message, selectedMessages: [message], isPersonal: true },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
    canDrag: () => !(isMoving || isCreatingEml),
    end: async (_, monitor) => {
      if (monitor.didDrop()) {
        const item: any = monitor.getDropResult();

        if (item.showModal) {
          moveToFolder(item.message, item.selectedMessages, item.projectId);
        } else if (item.droppedInDocumentsPage) {
          uploadEMLToDriveFolder(
            'me',
            message.id,
            item.driveItemId,
            item.groupId
          );
        } else if (item.message) {
          dispatch(
            copyMessageToProject(
              [message],
              'me',
              'inbox',
              item.destinationMailFolder,
              item.projectId,
              settings.deleteMovedMessageMe
            )
          );
        }
      }
    },
  });

  const handleMoveToFolderClick = () => {
    moveToFolder(message, [message], projectId);
  };

  const handleCreateTodoClick = () => {
    createToDo(message);
  };

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const menu = () => (
    <Menu>
      <Menu.Item key="1" onClick={handleCreateTodoClick}>
        <Flex.Row
          alignItems="center"
          childrenGap={theme.old.spacing.baseSpacing}
        >
          <div style={{ width: 15, display: 'flex', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={['fal', 'clipboard-check']} />
          </div>
          <Typography.Text>
            {t('mail:widgetArea.personalMessages.actions.createTodo')}
          </Typography.Text>
        </Flex.Row>
      </Menu.Item>
      <Menu.Item key="2" onClick={handleMoveToFolderClick}>
        <Flex.Row
          alignItems="center"
          childrenGap={theme.old.spacing.baseSpacing}
        >
          <div style={{ width: 15, display: 'flex', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={['fal', 'inbox-in']} />
          </div>
          <Typography.Text>
            {t('mail:widgetArea.personalMessages.actions.saveToProject')}
          </Typography.Text>
        </Flex.Row>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['contextMenu']}>
      <div ref={dragRef} style={style}>
        <div
          style={{
            opacity,
            cursor: !(isMoving || isCreatingEml) ? undefined : 'not-allowed',
            height: '100%',
          }}
        >
          {(isMoving || isCreatingEml) && (
            <div
              style={{
                height: '100%',
              }}
              className={classes.spinner}
            >
              <PrioSpinner />
            </div>
          )}
          <List.Item
            key={message.id}
            style={{ height: '100%' }}
            className={classNames(classes.listItem, {
              [classes.flagged]: message.flag?.flagStatus === 'Flagged',
              [classes.unreadItem]: !message.isRead,
            })}
            {...rest}
          >
            <List.Item.Meta
              className={classes.listItemMeta}
              title={
                <Flex.Row
                  alignItems="baseline"
                  childrenGap={theme.old.spacing.unit(1)}
                >
                  <Flex.Row
                    className={classes.fullWidth}
                    childrenGap={theme.old.spacing.unit(1)}
                  >
                    <Flex.Item
                      flex={1}
                      className={classNames(classes.senderName, {
                        [classes.unreadSenderName]: !message.isRead,
                      })}
                    >
                      {message.from?.emailAddress?.name}
                    </Flex.Item>
                    {message.hasAttachments ? (
                      <FontAwesomeIcon
                        icon={['fal', 'paperclip']}
                        size="sm"
                        className={classes.attachmentIcon}
                      />
                    ) : null}
                    <Flex.Row
                      childrenGap={theme.old.spacing.unit(1)}
                      className={'mail-list-actions'}
                    >
                      <div
                        className={classes.quickActions}
                        onClick={handleMoveToFolderClick}
                        title={t(
                          'mail:widgetArea.personalMessages.actions.saveToProject'
                        )}
                      >
                        <FontAwesomeIcon icon={['fal', 'inbox-in']} />
                      </div>
                      <div
                        className={classes.quickActions}
                        onClick={handleCreateTodoClick}
                        title={t(
                          'mail:widgetArea.personalMessages.actions.createTodo'
                        )}
                      >
                        <FontAwesomeIcon icon={['fal', 'clipboard-check']} />
                      </div>
                    </Flex.Row>
                  </Flex.Row>
                </Flex.Row>
              }
              description={
                <Flex.Column flex={1}>
                  <Flex.Row>
                    <Flex.Item flex={1} className={classes.wrapTitleContainer}>
                      <Typography.Text
                        type="secondary"
                        className={classNames(classes.wrapTitle, {
                          [classes.wrapTitleUnread]: !message.isRead,
                        })}
                      >
                        {message.subject}
                      </Typography.Text>
                    </Flex.Item>
                    <Typography.Text type="secondary">
                      {shortDateFormatString(message.receivedDateTime)}
                    </Typography.Text>
                  </Flex.Row>
                  <Flex.Row
                    childrenGap={theme.old.spacing.baseSpacing}
                    alignItems="center"
                  >
                    <Typography.Paragraph
                      type="secondary"
                      className={classes.bodyPreview}
                      ellipsis={{ rows: 1 }}
                    >
                      {message.bodyPreview}
                    </Typography.Paragraph>
                  </Flex.Row>
                </Flex.Column>
              }
            />
          </List.Item>
        </div>
      </div>
    </Dropdown>
  );
};

export default WidgetMailListItem;
