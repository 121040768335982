import React, { useState } from 'react';
import { Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    transition: 'color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    fontSize: 16,
  },
  day: {
    transition: 'color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    fontSize: 12,
  },
}));

interface HeaderComponentProps {
  value: any;
  view: string;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { value, view } = props;

  const [hoverColor, setHoverColor] = useState<string>('#000');

  return (
    <div
      className={classes.root}
      onMouseOver={() =>
        setHoverColor(view === 'week' ? theme.old.palette.primaryColor : '#000')
      }
      onMouseLeave={() => setHoverColor('#000')}
    >
      <Typography.Text className={classes.date} style={{ color: hoverColor }}>
        {value.label}
      </Typography.Text>
    </div>
  );
};

export default HeaderComponent;
