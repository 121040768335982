import React from 'react';
import { Project } from '../../../models/Project';
import { ProjectId } from '../../../models/Types';

export interface ProjectsContextProps {
  favorites: Project[];
  myProjects: Project[];
  allProjects: Project[];
  getProjectById: (id: ProjectId) => Project | undefined;
  getProjectByProperty: (
    property: keyof Project,
    value: string
  ) => Project | undefined;
}

const INITIAL_PROJECTS_CONTEXT_VALUE: ProjectsContextProps = {
  favorites: [],
  myProjects: [],
  allProjects: [],
  getProjectById: () => undefined,
  getProjectByProperty: () => undefined,
};

export const ProjectsContext = React.createContext<ProjectsContextProps>(
  INITIAL_PROJECTS_CONTEXT_VALUE
);
