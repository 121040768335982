import React, { useCallback, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../../components/NavigationBar';
import InvoicesManagement from './InvoicesManagement';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  fullHeight: {
    height: '100%',
  },
  excel: {
    marginLeft: theme.old.spacing.defaultPadding,
  },
  excelIcon: {
    marginLeft: theme.old.spacing.unit(1.5),
    marginRight: theme.old.spacing.unit(1.5),
  },
  warning: {
    background: theme.colors.base.yellow[50],
    color: theme.colors.application.typography.muted,
    padding: `${theme.spacing.regular}px 80px`,
    fontSize: theme.font.fontSize.small,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.regular,
  },
}));

interface InvoicesModuleProps {
  className?: string;
}

export const InvoicesModule: React.FC<InvoicesModuleProps> = (props) => {
  const classes = useStyles();
  const { className } = props;
  const { t } = useTranslation();

  const { officeId } = useParams();

  const [createInvoiceActive, setCreateInvoiceActive] =
    useState<boolean>(false);

  const toggleCreateInvoiceActive = () =>
    setCreateInvoiceActive(!createInvoiceActive);

  const onPanelClose = useCallback(() => setCreateInvoiceActive(false), []);

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <div className={classes.warning}>
        <FontAwesomeIcon icon={['fal', 'warning']} size="xl" />
        <div>{t('controlling:deprecatedWarning.invoices')}</div>
      </div>
      <Divider style={{ margin: 0 }} />
      <NavigationBar>
        <Button
          type="primary"
          onClick={toggleCreateInvoiceActive}
          iconProp={['fal', 'file-medical']}
        >
          <span>{t('accounting:navigationBar.createInvoice')}</span>
        </Button>
        {/*
        <div className={classes.excel}>
          <FontAwesomeIcon
            className={classes.excelIcon}
            icon={['fal', 'file-excel']}
          />
          <span>{t('accounting:navigationBar.exportExcel')}</span>
        </div>
        */}
      </NavigationBar>
      <InvoicesManagement
        createInvoiceActive={createInvoiceActive}
        onClose={onPanelClose}
        officeId={officeId}
      />
    </Flex.Column>
  );
};

export default InvoicesModule;
