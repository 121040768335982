import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { MedicalCertificate } from '../../../../models/MedicalCertificate';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { Button } from '@prio365/prio365-react-library';
import { OfficeId } from '../../../../models/Types';
import {
  apiArchiveMedicalCertificate,
  apiUpdateMedicalCertificateState,
} from '../../api';
import { Tooltip, notification } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.old.spacing.unit(2),
    borderRadius: theme.borderRadius.small,
    border: `1px solid ${theme.colors.application.border}`,
    backgroundColor: theme.colors.application.background.light,
    overflow: 'hidden',
    '& button': {
      display: 'none',
    },
    '&:hover button': {
      display: 'block',
    },
  },
  stateIndicator: {
    position: 'absolute',
    content: '""',
    top: 0,
    left: 0,
    bottom: 0,
    width: 4,
  },
  description: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
  },
}));

interface MeddicalCertificateListItemProps {
  className?: string;
  medicalCertificate: MedicalCertificate;
  enableStateEdit?: boolean;
  isUserMe?: boolean;
  officeId?: OfficeId;
  onEdit: (medicalCertificate: MedicalCertificate) => void;
  onArchive: (medicalCertificate: MedicalCertificate) => void;
  onReject?: (medicalCertificate: MedicalCertificate) => void;
  onApprove?: (medicalCertificate: MedicalCertificate) => void;
}

export const MeddicalCertificateListItem: React.FC<
  MeddicalCertificateListItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    medicalCertificate,
    enableStateEdit,
    isUserMe,
    officeId,
    onArchive,
    onApprove,
    onReject,
    onEdit,
  } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();

  const {
    from,
    to,
    medicalCertificateType,
    attachmentUploaded,
    state,
    attachmentSentToHrAt,
  } = medicalCertificate;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const iconName: IconName = useMemo(() => {
    switch (medicalCertificateType) {
      case 'initialMedicalCertificate':
        return 'file-medical';
      case 'followUpMedicalCertificate':
        return 'file-medical';
      case 'hospitalStay':
        return 'hospital';
      case 'childIllnessCertificate':
        return 'person-breastfeeding';
      case 'rehabilitationTherapyCertificate':
        return 'crutches';
      case 'reintegrationCertificate':
        return 'people-group';
    }
  }, [medicalCertificateType]);

  const iconColor = useMemo(() => {
    switch (state) {
      case 'new':
        return theme.old.palette.primaryColor;
      case 'pending':
        return theme.old.palette.chromaticPalette.yellow;
      case 'approved':
        return theme.old.palette.chromaticPalette.green;
      case 'rejected':
        return theme.old.palette.chromaticPalette.red;
    }
  }, [state, theme]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnEditClick = () => {
    onEdit(medicalCertificate);
  };

  const handleOnArchiveClick = async () => {
    const { result } = await apiArchiveMedicalCertificate(
      medicalCertificate.medicalCertificateId,
      {
        officeId,
        isUserMe,
      }
    );

    if (result.ok) {
      onArchive(medicalCertificate);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.archiveMedicalCertificateError'),
      });
    }
  };

  const handleOnApproveClick = async () => {
    const { data } = await apiUpdateMedicalCertificateState(
      medicalCertificate.medicalCertificateId,
      { state: 'approved' },
      officeId
    );

    if (data) {
      onApprove?.(medicalCertificate);
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'absences:errorMessages.updateStateMedicalCertificateError'
        ),
      });
    }
  };

  const handleOnRejectClick = async () => {
    const { data } = await apiUpdateMedicalCertificateState(
      medicalCertificate.medicalCertificateId,
      { state: 'rejected' },
      officeId
    );

    if (data) {
      onReject?.(medicalCertificate);
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'absences:errorMessages.updateStateMedicalCertificateError'
        ),
      });
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      className={classNames(classes.root, className)}
      childrenGap={theme.old.spacing.unit(2)}
      alignItems="center"
    >
      <div
        className={classes.stateIndicator}
        style={{
          backgroundColor: iconColor,
        }}
      />
      <FontAwesomeIcon
        icon={['fal', iconName]}
        color={iconColor}
        style={{ marginLeft: 0 }}
      />
      <Flex.Column flex={1}>
        <Flex.Item>
          {t('absences:medicalCertificate.listItem.fromTo', {
            type: t(
              `absences:medicalCertificate.types.${medicalCertificateType}`
            ),
            fromTo: moment(from).isSame(moment(to))
              ? moment(from).format('dd. DD.MM.YYYY')
              : `${moment(from).format('dd. DD.MM.YYYY')} - ${moment(to).format(
                  'dd. DD.MM.YYYY'
                )}`,
          })}
        </Flex.Item>
        <Flex.Row className={classes.description} alignItems="center">
          <span>
            {t('absences:medicalCertificate.listItem.description.state', {
              state: t(
                `absences:medicalCertificate.state.${state.toLowerCase()}`
              ),
            })}
          </span>
          {attachmentUploaded && (
            <span>
              {`; ${t(
                'absences:medicalCertificate.listItem.description.submittedAt',
                {
                  submittedAt: `${moment(`${attachmentSentToHrAt}Z`).format(
                    'DD.MM.YYYY, HH:mm'
                  )} ${t('common:moment.aClock')}`,
                }
              )}`}
            </span>
          )}
        </Flex.Row>
      </Flex.Column>
      <Flex.Row alignItems="center" childrenGap={theme.old.spacing.baseSpacing}>
        {enableStateEdit &&
          !(
            medicalCertificate.state === 'approved' ||
            medicalCertificate.state === 'rejected'
          ) && (
            <>
              <Tooltip
                title={t('absences:medicalCertificate.listItem.tooltip.reject')}
              >
                <Button
                  type="default"
                  iconProp={['fal', 'times']}
                  iconColor={theme.old.palette.chromaticPalette.red}
                  onClick={handleOnRejectClick}
                />
              </Tooltip>
              <Tooltip
                title={t('absences:medicalCertificate.listItem.tooltip.accept')}
              >
                <Button
                  type="default"
                  iconProp={['fal', 'check']}
                  iconColor={theme.old.palette.chromaticPalette.green}
                  onClick={handleOnApproveClick}
                />
              </Tooltip>
            </>
          )}
        <Tooltip
          title={t('absences:medicalCertificate.listItem.tooltip.delete')}
        >
          <Button
            type="default"
            iconProp={['fal', 'trash']}
            iconColor={theme.old.palette.chromaticPalette.red}
            onClick={handleOnArchiveClick}
          />
        </Tooltip>
        <Tooltip title={t('absences:medicalCertificate.listItem.tooltip.edit')}>
          <Button
            type="default"
            iconProp={['fal', 'pen']}
            onClick={handleOnEditClick}
          />
        </Tooltip>
      </Flex.Row>
    </Flex.Row>
  );
};

export default MeddicalCertificateListItem;
