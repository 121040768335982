import { Button, Divider, Drawer } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DriveItem } from '../../../../models/Drive';
import { DriveItemId, ProjectId } from '../../../../models/Types';
import {
  Col,
  Form,
  Input,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from 'antd';
import Flex from '../../../../components/Flex';
import DriveItemBreadcrumb from '../DriveItemBreadcrumb/DriveItemBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentFolderItem,
  getCurrentRemoteItem,
  getProject,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { Project } from '../../../../models/Project';
import { fileNameRegex } from '../../util';
import { fetchDriveItemsSagaAction } from '../../actions';
import FolderSelectionDrawer from './FolderSelectionDrawer';
import { NewDocumentType } from '../../../../models/Document';
import { apiCreateNewDocument } from '../../api';
import i18n from '../../../../i18n';
import DocumentSuccessNotification from '../Notifications/DocumentSuccessNotification';

interface NewDocumenDrawerProps {
  visible?: boolean;
  driveItem: DriveItem;
  projectId: string;
  groupId: string;
  parentFolderDriveItem: DriveItem;
  onCancel?: () => void;
  onSuccess?: (driveItem?: DriveItem, projectId?: ProjectId) => void;
  documentType?: NewDocumentType;
  prefix?: string;
  handleReloadDocuments: () => void;
}

export const NewDocumentDrawer: React.FC<NewDocumenDrawerProps> = (props) => {
  const {
    visible = false,
    onCancel = () => {},
    projectId,
    groupId,
    parentFolderDriveItem,
    documentType,
    prefix,
    handleReloadDocuments,
  } = props;
  //#region ------------------------------------------------- Defaults
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [isFormValid, setIsFormValid] = useState(false);
  const [documentName, setDocumentName] = useState<string>('');
  const [selectTargetFolderDrawerVisible, setSelectTargetFolderDrawerVisible] =
    useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] =
    useState<ProjectId>(projectId);
  const [saveFolderChoice, setSaveFolderChoice] = useState<
    'customFolder' | string
  >('customFolder');
  const [targetFolderId, setTargetFolderId] = useState<DriveItemId>(
    parentFolderDriveItem?.id
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const targetFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, targetFolderId)
  );
  const [saveFolderDriveItemId, setSaveFolderDriveItemId] =
    useState<DriveItemId>(targetFolderId);
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, selectedProjectId)
  );
  const currentRemoteItem = useSelector(getCurrentRemoteItem);
  //#endregion

  //#region ------------------------------ Handlers
  function handleClose() {
    setDocumentName('');
    form.resetFields();
    onCancel();
  }
  const handleSaveFolderChoice = (e: RadioChangeEvent) => {
    setSaveFolderChoice(e.target.value);
    if (e.target.value !== 'customFolder') {
      setSaveFolderDriveItemId(e.target.value);
    } else {
      setTargetFolderId(targetFolder !== null ? targetFolder?.id : null);
      setSaveFolderDriveItemId(targetFolder !== null ? targetFolder?.id : null);
    }
  };

  const openTargetFolderDrawer = () => {
    setSelectTargetFolderDrawerVisible(true);
  };

  const handleOnBreadcrumbItemClick = (driveItemId: DriveItemId) => {
    setTargetFolderId(driveItemId);
    setSaveFolderDriveItemId(driveItemId);
    if (!!project) {
      const { groupId, projectId } = project;
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          driveItemId,
          250,
          driveItemId === null,
          false
        )
      );
    }
  };

  const handleFormValuesChange = async () => {
    if (setIsFormValid) {
      try {
        await form.validateFields();
        setIsFormValid(true);
      } catch (error) {
        setIsFormValid(false);
      }
    }
  };

  const handleTargetFolderDrawerOk = (targetFolder: DriveItem | null) => {
    setSelectTargetFolderDrawerVisible(false);
    setTargetFolderId(targetFolder?.id);
    setSaveFolderDriveItemId(targetFolder?.id);
  };
  const handleTargetFolderDrawerCancel = () => {
    setSelectTargetFolderDrawerVisible(false);
  };

  const handleOnFinish = async () => {
    setIsSaving(true);
    try {
      const { data } = await apiCreateNewDocument({
        documentName: prefix ? prefix + documentName : documentName,
        documentType: documentType,
        saveFolderDriveItemId: saveFolderDriveItemId,
        groupId,
        projectId: selectedProjectId,
      });

      if (data) {
        const key = `open${Date.now()}`;
        notification.open({
          message: i18n.t('common:documents:success'),
          description: (
            <DocumentSuccessNotification
              driveItem={data}
              notificationKey={key}
              project={project}
            />
          ),
          key,
          duration: 10,
        });
        handleReloadDocuments();
        setIsSaving(false);
        handleClose();
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'documents:newDocumentForm.errorMessages.createDocumentError'
          ),
        });

        setIsSaving(false);
      }
    } catch (error) {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (parentFolderDriveItem?.id) {
      setTargetFolderId(parentFolderDriveItem.id);
      setSaveFolderDriveItemId(parentFolderDriveItem.id);
    }
  }, [parentFolderDriveItem]);

  //#endregion
  return (
    <Drawer
      zIndex={1000}
      title={t('documents:navigationBar.createDocument')}
      visible={visible}
      onClose={handleClose}
      customWidth={800}
      closable={true}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button type="default" onClick={onCancel} disabled={isSaving}>
            {t('common:actions.cancel')}
          </Button>
          <Button
            onClick={() => handleOnFinish()}
            disabled={!isFormValid || isSaving}
            style={{
              marginLeft: '12px',
            }}
          >
            {t('common:actions.save')}
          </Button>
        </div>
      }
    >
      {selectedProjectId && (
        <>
          <Typography.Title level={5}>
            {t('documents:documentTemplates.generalHeadline')}
          </Typography.Title>
          <Form
            form={form}
            onFinish={handleOnFinish}
            onValuesChange={handleFormValuesChange}
            layout="vertical"
            initialValues={{
              documentName: '',
              targetFolder: targetFolderId,
            }}
            id={'prio-project-new-documents-form'}
          >
            <Flex.Row>
              <Flex.Item flex={1}>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="documentName"
                        validateTrigger="onBlur"
                        label={t(
                          'documents:newDocumentForm.labels.documentName'
                        )}
                        rules={[
                          {
                            required: true,
                            message: t(
                              'documents:newDocumentForm.validation.missingDocumentName'
                            ),
                          },
                          {
                            message: t(
                              'documents:newDocumentForm.validation.invalidDocumentName'
                            ),
                            pattern: fileNameRegex,
                          },
                        ]}
                      >
                        <Input
                          prefix={`${prefix ? prefix + '-' : ''}`}
                          disabled={isSaving}
                          value={documentName}
                          onChange={(e) => setDocumentName(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider style={{ marginTop: '8px' }} />
                  <Typography.Title level={5}>
                    {t('documents:newDocumentForm.labels.saveFolderChoice')}
                  </Typography.Title>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <div>
                            {t(`documents:newDocumentForm.labels.targetFolder`)}
                            <Button
                              onClick={openTargetFolderDrawer}
                              iconProp={['fal', 'pen']}
                              type="link"
                            ></Button>
                          </div>
                        }
                      >
                        {
                          <Radio.Group
                            value={saveFolderChoice}
                            onChange={handleSaveFolderChoice}
                            disabled={isSaving}
                          >
                            <Space direction="vertical">
                              <Radio value="customFolder" key="customFolder">
                                <DriveItemBreadcrumb
                                  driveItem={
                                    targetFolder ?? parentFolderDriveItem
                                  }
                                  remoteItem={currentRemoteItem}
                                  project={project}
                                  onItemClick={handleOnBreadcrumbItemClick}
                                  disableLinkNavigation
                                />
                              </Radio>
                            </Space>
                          </Radio.Group>
                        }
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Flex.Item>
            </Flex.Row>
          </Form>
        </>
      )}
      {project && (
        <FolderSelectionDrawer
          visible={selectTargetFolderDrawerVisible}
          onOk={handleTargetFolderDrawerOk}
          onCancel={handleTargetFolderDrawerCancel}
          projectId={selectedProjectId}
          selectedFolder={targetFolder?.id ?? parentFolderDriveItem?.id}
          allowProjectChange
          setProjectId={setSelectedProjectId}
        />
      )}
    </Drawer>
  );
};
