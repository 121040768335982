import { LOGGED_IN, LOGGED_OUT, SET_IS_INITIAL_DATA_LOADING } from '../actions';
import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface AuthState {
  loggedIn: boolean;
  isInitialDataLoading: boolean;
}

const initialState: AuthState = {
  loggedIn: false,
  isInitialDataLoading: true,
};

const authReducer: Reducer<AuthState, any> = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN: {
      if (state.loggedIn) {
        return state;
      }
      return {
        ...state,
        loggedIn: true,
      };
    }
    case LOGGED_OUT: {
      if (!state.loggedIn) {
        return state;
      }
      return {
        ...state,
        loggedIn: false,
      };
    }
    case SET_IS_INITIAL_DATA_LOADING: {
      const { isInitialDataLoading } = action;
      return {
        ...state,
        isInitialDataLoading,
      };
    }
    case CLEAR_PRIO_CACHE:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;

export const isLoggedIn: (state: AuthState) => boolean = (state) =>
  state.loggedIn;

export const isInitialDataLoading: (state: AuthState) => boolean = (state) =>
  state.isInitialDataLoading;
