import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';
import useAccessRights from '../../users/hooks/useAccessRights';
import useProjectsContext from '../hooks/useProjectsContext';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface TimeRecordMenuProps {
  selectedList?: string;
}

export const TimeRecordMenu: React.FC<TimeRecordMenuProps> = (props) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { projectId } = useParams();

  const { getProjectById } = useProjectsContext();
  const project = getProjectById(projectId);

  const { showProjectSettingsProjectPhase } = useAccessRights(
    ['showProjectSettingsProjectPhase'],
    { projectId: projectId, officeId: project?.internalOfficeId }
  );

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="hourly-rates">
        <Link to="../hourly-rates">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'user-clock']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.hourlyRates')}
        </Link>
      </Menu.Item>
      {showProjectSettingsProjectPhase && (
        <Menu.Item key="projectPhase">
          <Link to="../projectPhase">
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', 'timeline-arrow']}
              className={classes.icon}
            />
            {t('projects:projectSettingsNav.projectPhase')}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default TimeRecordMenu;
