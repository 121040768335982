import { Button } from '@prio365/prio365-react-library';
import { List, Modal, Tooltip, notification } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties, useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { makePrioStyles } from '../../../theme/utils';
import { apiDeleteEmergencyContact } from '../api';
import EditEmergencyContactForm from './EditEmergencyContactForm';
import { EmergencyContact } from '../../../models/Employee';

const listItemHeight = 150;
const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  listItem: {
    paddingLeft: theme.spacing.large,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingTop: theme.old.spacing.unit(2),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
    '&:hover > ul > li  > svg': {
      visibility: 'visible',
    },
    '& > ul > li > svg': {
      visibility: 'hidden',
    },
    '&:hover > ul > li  > a': {
      visibility: 'visible',
    },
    '& > ul > li > a': {
      visibility: 'hidden',
    },
    '& .ant-list-item-action': {
      marginLeft: theme.old.spacing.baseSpacing,
    },
    height: listItemHeight,
  },
  listItemMeta: {
    alignItems: 'center',
    height: '100%',
    '& .ant-list-item-meta-title': {
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
    },
    '& .ant-list-item-meta-description': {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
    },
  },
  noData: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    borderBottom: theme.old.borders.content,
  },
  addButton: {
    position: 'fixed',
    bottom: theme.spacing.large,
    right: theme.spacing.large,
  },
  addAndEditForm: {
    padding: theme.spacing.large,
  },
}));

interface EmergencyContactListProps {
  items: EmergencyContact[];
  setIsEmergencyContactsUpdated?: () => void;
}

export const EmergencyContactList: React.FC<EmergencyContactListProps> =
  React.memo((props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { items, setIsEmergencyContactsUpdated } = props;

    const [openAddAndEditForm, setOpenAddAndEditForm] =
      useState<boolean>(false);
    const [selectedEmergencyContact, setSelectedEmergencyContact] =
      useState<EmergencyContact | null>(null);

    const onClose = () => {
      setOpenAddAndEditForm(false);
    };

    const onContactClick = useCallback((contact: EmergencyContact) => {
      setOpenAddAndEditForm(true);
      if (contact) {
        setSelectedEmergencyContact(contact);
      } else {
        setSelectedEmergencyContact(null);
      }
    }, []);

    const renderItem = useCallback(
      (item: EmergencyContact, style: CSSProperties) => {
        return (
          <EmergencyContactListItem
            onContactClick={onContactClick}
            emergencyContactListItem={item}
            style={style}
            setIsEmergencyContactsUpdated={setIsEmergencyContactsUpdated}
          />
        );
      },
      [onContactClick, setIsEmergencyContactsUpdated]
    );

    const renderAddButton = () => {
      return (
        <Button
          type={'primary'}
          className={classes.addButton}
          onClick={() => onContactClick(null)}
        >
          {t('profile:actions.add')}
        </Button>
      );
    };

    if (items.length === 0 && !openAddAndEditForm) {
      return (
        <div id="prio-contact-list-no-data" className={classes.root}>
          <div className={classes.noData}>
            {t('profile:noEmergencyContact')}
          </div>
          {renderAddButton()}
        </div>
      );
    }

    return (
      <span>
        {!openAddAndEditForm && (
          <div id="prio-contact-list" className={classNames(classes.root)}>
            <div style={{ height: '100%' }}>
              <AutoSizer disableWidth>
                {({ height }) => (
                  <FixedSizeList
                    height={height}
                    width="100%"
                    itemCount={items.length}
                    itemSize={listItemHeight}
                  >
                    {({ index, style }) => renderItem(items[index], style)}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </div>
            {renderAddButton()}
          </div>
        )}

        {openAddAndEditForm && (
          <div className={classNames(classes.addAndEditForm)}>
            <EditEmergencyContactForm
              title={t('profile:titles.editEmergencyContact')}
              onClose={onClose}
              emergencyContact={selectedEmergencyContact}
              setIsEmergencyContactsUpdated={setIsEmergencyContactsUpdated}
            />
          </div>
        )}
      </span>
    );
  });

const LinkableElement = ({ onClick, to, children }) =>
  to ? (
    <Link to={to}>{children}</Link>
  ) : (
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
      {children}
    </div>
  );

export default EmergencyContactList;

interface EmergencyContactListItemProps {
  listItemClassName?: string;
  emergencyContactListItem: EmergencyContact;
  style: CSSProperties;
  onContactClick?: (contact: EmergencyContact) => void;
  setIsEmergencyContactsUpdated?: () => void;
}

const EmergencyContactListItem: React.FC<EmergencyContactListItemProps> = (
  props
) => {
  const {
    listItemClassName,
    emergencyContactListItem,
    style,
    onContactClick,
    setIsEmergencyContactsUpdated,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const deleteEmergencyContact = async () => {
    const { result } = await apiDeleteEmergencyContact(
      emergencyContactListItem.emergencyContactId
    );
    if (result.status >= 200 && result.status < 300) {
      notification.open({
        message: t('common:success'),
        description: t('profile:notification.successMessages.deleteSuccess'),
      });
      setIsEmergencyContactsUpdated();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('profile:notification.errorMessages.deleteFailed'),
      });
    }
    setDeleteModalVisible(false);
  };

  const description = (
    <div>
      <div>
        <span>{`${t('profile:form.emergencyContactMobile')}: ${
          emergencyContactListItem.mobile
        }`}</span>
      </div>
      <div>
        <span>{`${t('profile:form.emergencyContactPhone')}: ${
          emergencyContactListItem.phone
        }`}</span>
      </div>
      {emergencyContactListItem.relationship && (
        <div>
          <span>{`${t('profile:form.emergencyContactRelationship')}: ${
            emergencyContactListItem.relationship
          }`}</span>
        </div>
      )}
    </div>
  );

  return (
    <div className={classes.item} style={style}>
      <LinkableElement
        to={null}
        onClick={() => onContactClick(emergencyContactListItem)}
      >
        <List.Item
          className={classNames(classes.listItem, listItemClassName)}
          actions={[
            <Tooltip title={t('profile:actions.call')}>
              <FontAwesomeIcon
                icon={['fal', 'phone']}
                onClick={(e: React.MouseEvent) => {
                  window.open(
                    emergencyContactListItem.mobile ||
                      emergencyContactListItem.phone
                      ? `msteams:/l/call/0/0?users=4:${
                          emergencyContactListItem.mobile?.replace('+', '00') ??
                          emergencyContactListItem.phone?.replace('+', '00')
                        }`
                      : undefined
                  );
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </Tooltip>,
            <Tooltip title={t('profile:actions.edit')}>
              <FontAwesomeIcon
                icon={['fal', 'edit']}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onContactClick(emergencyContactListItem);
                }}
              />
            </Tooltip>,

            <Tooltip title={t('profile:actions.delete')}>
              <FontAwesomeIcon
                icon={['fal', 'trash']}
                onClick={(e: React.MouseEvent) => {
                  setDeleteModalVisible(true);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </Tooltip>,
          ]}
        >
          <List.Item.Meta
            className={classes.listItemMeta}
            title={emergencyContactListItem.name}
            description={description}
          />
        </List.Item>
      </LinkableElement>
      <Modal
        visible={deleteModalVisible}
        onOk={() => deleteEmergencyContact()}
        onCancel={() => setDeleteModalVisible(false)}
        title={t('profile:deleteModal.title')}
        okText={t('profile:deleteModal.confirm')}
        cancelText={t('profile:deleteModal.cancel')}
      >
        {[t('profile:deleteModal.message')]}
      </Modal>
    </div>
  );
};
