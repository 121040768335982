import React, { useState } from 'react';
import { Divider, Input, Modal, notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { apiCreateGlobalDocumentTag, apiDeleteGlobalDocumentTag } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import Flex from '../../../components/Flex';
import { DocumentTag } from '../../../models/Document';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  hideCheckIcon: {
    '& .ant-select-item-option-state': {
      visibility: 'hidden',
      width: 0,
    },
  },
}));

interface DocumentTagPickerProps {
  className?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  allowCreate?: boolean;
  allowDelete?: boolean;
  projectId?: string;
  useAsProjectAdmin?: boolean;
  selectableDocumentTags?: DocumentTag[];
  isFetching?: boolean;
  setIsReloadNeeded?: (isNewDocumentTagCreated: (value) => boolean) => void;
}

export const DocumentTagPicker: React.FC<DocumentTagPickerProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    className,
    value,
    onChange,
    disabled,
    allowCreate,
    allowDelete,
    projectId,
    useAsProjectAdmin,
    selectableDocumentTags,
    isFetching,
    setIsReloadNeeded,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [newTagName, setNewTagName] = useState<string>('');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onNewTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(event.target.value);
  };

  const createTag = async () => {
    const { data } =
      projectId && useAsProjectAdmin
        ? await apiCreateGlobalDocumentTag(newTagName, projectId)
        : await apiCreateGlobalDocumentTag(newTagName);
    if (data) {
      setIsReloadNeeded((prev) => !prev);
      setNewTagName('');
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'documents:documentTagPicker.errorMessages.createTagError'
        ),
      });
    }
  };

  const deleteTag = async (documentTagId: string) => {
    try {
      const { result } =
        projectId && useAsProjectAdmin
          ? await apiDeleteGlobalDocumentTag(documentTagId, projectId)
          : await apiDeleteGlobalDocumentTag(documentTagId);
      if (result.status >= 400) {
        notification.open({
          message: t('common:error'),
          description: t(
            'documents:documentTagPicker.errorMessages.deleteTagError'
          ),
        });
      } else {
        setIsReloadNeeded((prev) => !prev);
      }
    } catch (error) {}
  };

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
  };

  const onClickTagDelete = (e, documentTag: DocumentTag) => {
    e.preventDefault();
    e.stopPropagation();
    Modal.confirm({
      icon: null,
      title: t('documents:documentTagPicker.deleteModal.title'),
      content: (
        <div>
          <div>
            {t('documents:documentTagPicker.deleteModal.content1', {
              tagName: documentTag.name,
            })}
          </div>
          <div>{t('documents:documentTagPicker.deleteModal.content2')}</div>
        </div>
      ),
      okText: t('documents:documentTagPicker.deleteModal.okText'),
      cancelText: t('documents:documentTagPicker.deleteModal.cancelText'),
      onOk() {
        deleteTag(documentTag.documentTagId);
      },
      onCancel() {},
    });
  };

  const renderOption = (tag: DocumentTag) => {
    const showIcon =
      (allowDelete && !tag.isInUse) || value.find((item) => item === tag.name);
    const isDeleteScenario = allowDelete && !tag.isInUse;
    return (
      <Flex.Row alignItems="center">
        <Flex.Item flex={1}>{tag.name}</Flex.Item>
        {showIcon && (
          <div
            title={
              isDeleteScenario
                ? t('documents:documentTagPicker.deleteAction')
                : ''
            }
            onClick={(e) => isDeleteScenario && onClickTagDelete(e, tag)}
          >
            <FontAwesomeIcon
              icon={['fal', isDeleteScenario ? 'trash' : 'check']}
            ></FontAwesomeIcon>
          </div>
        )}
      </Flex.Row>
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Select
      disabled={disabled}
      className={classNames(classes.root, className)}
      mode="multiple"
      value={value?.sort((a, b) => a.localeCompare(b))}
      placeholder={t('documents:documentTagPicker.placeholder')}
      notFoundContent={isFetching ? <PrioSpinner size="small" /> : null}
      onChange={handleOnChange}
      optionLabelProp="title"
      dropdownRender={(menu) => (
        <div>
          {menu}
          {allowCreate && (
            <>
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input
                  style={{ flex: 'auto', marginRight: '8px' }}
                  value={newTagName}
                  onChange={onNewTagNameChange}
                />
                <Button
                  disabled={newTagName.trim().length === 0}
                  onClick={createTag}
                  iconProp={['fal', 'plus']}
                >
                  {t('documents:documentTagPicker.addAction')}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      options={
        selectableDocumentTags.length > 0
          ? selectableDocumentTags?.map((tag) => ({
              value: tag.name,
              label: renderOption(tag),
              className: classes.hideCheckIcon,
            }))
          : [
              {
                value: '',
                label: t('documents:documentTagPicker.noTagsMessage'),
                disabled: true,
              },
            ]
      }
    ></Select>
  );
};

export default DocumentTagPicker;
