import { combineReducers } from 'redux';

import sider, * as fromSider from './themeSettings/sider';
import mailSettings, * as fromMailSettings from './mailSettings/mail';
import documentSettings, * as fromDocumentSettings from './documentSettings/documents';
import { SubMenuStateType } from '../../../models/Types';
import { MailSettings } from '../../../models/UserSettings/MailSettings';
import { DocumentSettings } from '../../../models/UserSettings/DocumentSettings';

export interface UserSettingsState {
  sider: fromSider.SiderOptionsState;
  mailSettings: fromMailSettings.MailSettingsState;
  documentSettings: fromDocumentSettings.DocumentSettingsState;
}

export default combineReducers<UserSettingsState>({
  sider,
  mailSettings,
  documentSettings,
});

/** Sider settings*/
export const getSiderOpen: (state: UserSettingsState) => boolean = (state) =>
  fromSider.getSiderOpen(state.sider);

export const getSiderSubMenuState: (
  state: UserSettingsState
) => SubMenuStateType = (state) => fromSider.getSubMenuState(state.sider);

/** Mail settings*/
export const getMailSettings: (state: UserSettingsState) => MailSettings = (
  state
) => fromMailSettings.getMailSettings(state.mailSettings);

export const getDocumentSettings: (
  state: UserSettingsState
) => DocumentSettings = (state) =>
  fromDocumentSettings.getDocumentSettings(state.documentSettings);

export const getEmailCachingEnabled: (state: UserSettingsState) => boolean = (
  state
) => fromMailSettings.getEmailCachingEnabled(state.mailSettings);

export const getDocumentsCachingEnabled: (
  state: UserSettingsState
) => boolean = (state) =>
  fromDocumentSettings.getDocumentsCachingEnabled(state.documentSettings);
