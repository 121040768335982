import React, { useRef, useState } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import classNames from 'classnames';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import HRRemainingAbsencePageTable, {
  HRRemainingAbsencePageTableRef,
} from './HRRemainingAbsencePageTable';
import { RemainingAbsenceV2 } from '../../../models/RemainingAbsence';
import { DefaultSearchParameterItem } from '../../../components/Filter/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface HRRestAbsencePageProps {
  className?: string;
  officeId?: string;
}

export const HRRemainingAbsencePage: React.FC<HRRestAbsencePageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, officeId } = props;
  const classes = useStyles(props);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef = useRef<HRRemainingAbsencePageTableRef>(null);
  const [selectedRemainingAbsences, setSelectedRemainingAbsences] = useState<
    RemainingAbsenceV2[]
  >([]);

  const customDefaultSearchParameters: DefaultSearchParameterItem[] = [
    ...(officeId
      ? [
          {
            parameterName: 'Calculated.OfficeId',
            defaultValue: officeId,
            defaultMethod: 'eq',
          },
        ]
      : []),
  ];
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnRowSelectionChange = (
    remainingAbsences: RemainingAbsenceV2[]
  ) => {
    setSelectedRemainingAbsences(remainingAbsences);
  };

  const onRemainingAbsenceSelect = (remainingAbsence: RemainingAbsenceV2) => {};
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <FilterContextProvider
        searchType="holidayEntitlements"
        customDefaultSearchParameters={customDefaultSearchParameters}
        transformedMap={{
          'Transformed.Year': ['Data.Year'],
        }}
      >
        <FilterBar />
        <HRRemainingAbsencePageTable
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          ref={tableRef}
          selectedRemainingAbsences={selectedRemainingAbsences}
          tableId={'rap1'}
          onRowClick={onRemainingAbsenceSelect}
          onRowSelectionChange={handleOnRowSelectionChange}
        />
      </FilterContextProvider>
    </div>
  );
};

export default HRRemainingAbsencePage;
