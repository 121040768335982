import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Form, Input, Row, Col, Divider, InputNumber, Checkbox } from 'antd';
import { Button, Select } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';

import ProjectPicker from '../../../projects/components/ProjectPicker';
import {
  TimeRecord,
  TimeRecordFormModel,
  TimeRecordEntry,
  CreateTimeRecordsRequest,
  TimeRecordEntryMoment,
} from '../../../../models/TimeRecord';
import {
  ContactId,
  DateTimeString,
  EditTimeRecordContextType,
  ProjectId,
} from '../../../../models/Types';
import ContactPicker from '../../../contacts/components/ContactPicker';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchInternalProjectContacts,
  syncGlobalProjects,
} from '../../../projects/actions';
import {
  getActiveProjectWithoutMe,
  getUserMeContactId,
  RootReducerState,
  getUserMeIsFetching,
  getAllProjects,
  getInternalProjectContactsIsFetching,
  getProject,
} from '../../../../apps/main/rootReducer';
import { useAccessRights } from '../../../users/hooks/useAccessRights';
import { rowGutter, colon } from '../../../../util/forms';
import { createSelector } from 'reselect';
import { Project } from '../../../../models/Project';
import TimeRecordEntryManagement from './TimeRecordEntryManagement/TimeRecordEntryManagement';
import { v4 as uuid } from 'uuid';
import { sumUpDurationInMinutes } from '../../util';
import { internalProjectContactContactIdSelector } from '../../../projects/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Moment } from 'moment-timezone';
import * as MomentObject from 'moment-timezone';
import { extendMoment } from 'moment-range';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import Flex from '../../../../components/Flex';
import { ProjectPhase } from '../../../../models/ProjectPhase';
import { apiGetProjectPhases } from '../../../projects/api';

const moment = extendMoment(MomentObject);

const MIN_WIDTH_FORM_ITEM = 480;

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
    '&.ant-form': {
      marginTop: '8px',
    },
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  fullWidth: {
    width: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(3),
  },
  datePicker: {
    width: '100%',
  },
  timePicker: {
    width: `calc(75% - ${16 + theme.old.spacing.unit(1)}px)`,
    marginRight: theme.old.spacing.unit(1),
  },
  duration: {
    marginRight: theme.old.spacing.unit(2),
    transition: 'border-color 0.3s',
  },
  space: {
    display: 'flex',
  },
  periodSum: {
    textAlign: 'right',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  deleteButton: {
    float: 'left',
    backgroundColor: 'transparent',
    padding: 0,
  },
  warning: {
    color: theme.old.palette.chromaticPalette.red,
  },
  scrollable: {
    height: 'calc(100% - 56px - 4px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    flex: 1,
  },
  noMargin: {
    marginBottom: '0px',
  },
  divider: {
    margin: '8px 0px 24px 0px',
  },
  divider2: {
    margin: '0px 0px 24px 0px',
  },
}));

const isMyProjectSelector = (projectId: ProjectId) =>
  createSelector<[(state: RootReducerState) => Project[]], boolean>(
    (state) => getAllProjects(state),
    (projects) =>
      (projects ?? []).map((project) => project.projectId).includes(projectId)
  );

const myProjectIdsSelector = createSelector<
  [(state: RootReducerState) => Project[]],
  ProjectId[]
>(
  (state) => getAllProjects(state),
  (projects) => (projects ?? []).map((project) => project.projectId)
);

interface CreateTimeRecordFormProps {
  className?: string;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (
    value: CreateTimeRecordsRequest | TimeRecord,
    isMe: boolean
  ) => void;
  onCancel?: () => void;
  resetOnFinish?: boolean;
  starTime?: DateTimeString;
  endTime?: DateTimeString;
}

export const CreateTimeRecordForm: React.FC<CreateTimeRecordFormProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    actionLabel,
    cancelLabel,
    onFinish,
    onCancel,
    disableActionButton,
    disableForm,
    resetOnFinish,
    starTime,
    endTime,
  } = props;

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [timeRecordForm] = Form.useForm();

  const [projectPhases, setProjectPhases] = useState<ProjectPhase[]>([]);

  const resetTimerecordformRef = useRef<VoidFunction>(null);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [descriptionIsRequired, setDescriptionIsRequired] = useState(false);

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMeContactId = useSelector(getUserMeContactId);
  const activeProject = useSelector(getActiveProjectWithoutMe);
  const inintialTimeRecordEntries: TimeRecordEntryMoment[] = [
    {
      startTime: (starTime ? moment(starTime) : moment())
        .startOf('day')
        .add(8, 'hours'),
      endTime: (endTime ? moment(endTime) : moment())
        .startOf('day')
        .add(16, 'hours'),
    },
  ];
  const isFetchingMe = useSelector(getUserMeIsFetching);

  const [currentProjectId, setCurrentProject] =
    useState<ProjectId>(activeProject);

  const currentProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, currentProjectId)
  );

  const projectContactIds: ContactId[] = useSelector<
    RootReducerState,
    ContactId[]
  >((state) =>
    internalProjectContactContactIdSelector(currentProjectId)(state)
  );

  const isMyProject = useSelector(isMyProjectSelector(currentProjectId));
  const myProjectIds = useSelector(myProjectIdsSelector);

  const isInternalFetching = useSelector(getInternalProjectContactsIsFetching);

  const accessRights = useAccessRights(
    [
      'writeOtherUserTimeRecord',
      'writeOtherUserTimeRecordByProjectRole',
      'writeOtherUserTimeRecordByGlobalRole',
    ],
    { projectId: currentProjectId, companyId: currentProject?.subsidiaryId }
  );
  const canSelectContact = accessRights['writeOtherUserTimeRecord'];

  const [contact, setContact] = useState<ContactId>(
    isMyProject ? userMeContactId ?? undefined : undefined
  );

  const contextType: EditTimeRecordContextType = useMemo(() => {
    if (accessRights['writeOtherUserTimeRecordByGlobalRole']) {
      return 'global';
    }
    return 'office';
  }, [accessRights]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const cancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish: (value: TimeRecordFormModel) => Promise<void> = async (
    value: TimeRecordFormModel
  ) => {
    const timeRecords: TimeRecord[] = [];
    await timeRecordForm
      .validateFields()
      .then(async (timeRecordResult: [Moment, Moment][]) => {
        await form
          .validateFields()
          .then(async (result: TimeRecordFormModel) => {
            result.dayRecordEntries.forEach((dayRecord, index) => {
              const timeRecordEntriesStrings: TimeRecordEntry[] = [];
              if (result.dayRecordEntries.length > 1) {
                dayRecord.timeRecordEntries.forEach((timeRecord) => {
                  timeRecordEntriesStrings.push({
                    startTime: `${
                      dayRecord.date.toISOString(true).split('T')[0]
                    }T${timeRecord.startTime
                      .toISOString(true)
                      .split('T')[1]
                      .slice(0, 12)}Z`,

                    endTime: `${
                      dayRecord.date.toISOString(true).split('T')[0]
                    }T${timeRecord.endTime
                      .toISOString(true)
                      .split('T')[1]
                      .slice(0, 12)}Z`,
                  });
                });
              } else {
                timeRecordResult['periods'].forEach((timeRecord) => {
                  timeRecordEntriesStrings.push({
                    startTime: `${
                      dayRecord.date.toISOString(true).split('T')[0]
                    }T${timeRecord[0]
                      .toISOString(true)
                      .split('T')[1]
                      .slice(0, 12)}Z`,

                    endTime: `${
                      dayRecord.date.toISOString(true).split('T')[0]
                    }T${timeRecord[1]
                      .toISOString(true)
                      .split('T')[1]
                      .slice(0, 12)}Z`,
                  });
                });
              }

              const guid = uuid();

              const _kilometerDistance =
                value.dayRecordEntries.length > 1
                  ? dayRecord?.kilometerDistance?.toString() ??
                    value.kilometerDistance
                  : value.kilometerDistance;
              const kilometerDistance = !_kilometerDistance
                ? 0
                : parseInt(_kilometerDistance);

              timeRecords.push({
                timeRecordId: guid,
                title: value.title,
                projectId: value.projectId,
                day: dayRecord.date.toISOString(true).split('T')[0],
                kilometerDistance: kilometerDistance,
                durationInMinutes: sumUpDurationInMinutes(
                  timeRecordEntriesStrings
                ),
                contactId: value.contactId,
                description: value.description,
                timeRecordEntries: timeRecordEntriesStrings,
                projectPhaseId: value.projectPhaseId,
                isSeparateService: value.isSeparateService,
              });
            });
          });
      });
    const request: CreateTimeRecordsRequest = {
      contactId: value.contactId,
      projectId: value.projectId,
      timeRecords: timeRecords,
    };

    onFinish(request, request.contactId === userMeContactId);
    if (resetOnFinish) {
      const dayRange: [Moment, Moment] =
        timeRecordForm.getFieldsValue(true).dayRange;
      const startDate = dayRange[0];
      const endDate = dayRange[1];
      timeRecordForm.setFieldsValue({
        dayRange: [startDate, endDate],
        periods: [[moment('8:00', 'h:mm a'), moment('16:00', 'h:mm a')]],
      });
      resetTimerecordformRef.current();

      form.setFieldsValue({
        projectId: currentProjectId,
        contactId: value.contactId,
        title: '',
        kilometerDistance: undefined,
        description: '',
        isSeparateService: false,
        projectPhaseId: null,
      });
      setContact(value.contactId);
    }
  };
  //#endregion

  const getProjectPhases: (projectId: ProjectId) => void = async (
    projectId: ProjectId
  ) => {
    const { data } = await apiGetProjectPhases(projectId);
    if (data) {
      setProjectPhases(
        data.filter((phase) => phase.isActive && !phase.isArchived)
      );
    } else {
      setProjectPhases([]);
    }
  };

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchProjectPhases = async () => {
      // get projectPhases
      await getProjectPhases(activeProject);
    };
    if (activeProject) {
      fetchProjectPhases();
      dispatch(fetchInternalProjectContacts(activeProject));
    }
  }, [activeProject, dispatch, form]);

  useEffect(() => {
    dispatch(syncGlobalProjects());
  }, [dispatch]);

  useEffect(() => {
    if (contact && userMeContactId && projectContactIds) {
      if (contact !== userMeContactId) {
        if (!isInternalFetching && !projectContactIds.includes(contact)) {
          if (projectContactIds.includes(userMeContactId)) {
            form.setFieldsValue({ contactId: userMeContactId });
            setContact(userMeContactId);
          } else {
            form.setFieldsValue({ contactId: null });
            setContact(null);
          }
        }
      }
    }
  }, [projectContactIds, contact, form, userMeContactId, isInternalFetching]);

  //#endregion

  return (
    <Form
      className={classNames(classes.root, className)}
      form={form}
      onValuesChange={(
        changedValues: TimeRecordFormModel,
        allValues: TimeRecordFormModel
      ) => {
        if (changedValues.projectId) {
          dispatch(fetchInternalProjectContacts(changedValues.projectId));
          setCurrentProject(changedValues.projectId);
          getProjectPhases(changedValues.projectId);
          if (allValues.contactId) {
            if (contact === userMeContactId) {
              if (!myProjectIds.includes(changedValues.projectId)) {
                form.setFieldsValue({
                  contactId: null,
                });
                setContact(null);
              }
            }
          } else if (myProjectIds.includes(changedValues.projectId)) {
            form.setFieldsValue({
              contactId: userMeContactId,
            });
            setContact(userMeContactId);
          }
        }
        if (changedValues.contactId) {
          setContact(changedValues.contactId);
        }
        setDescriptionIsRequired(allValues.isSeparateService);
        if (
          allValues.title &&
          allValues.projectId &&
          form.getFieldValue('contactId')
        ) {
          if (allValues.isSeparateService && !allValues.description)
            setSaveButtonEnabled(false);
          else {
            setSaveButtonEnabled(true);
          }
        } else {
          setSaveButtonEnabled(false);
        }
        form.validateFields(['description']);
      }}
      onFinish={handleFinish}
      layout="vertical"
    >
      <div className={classes.scrollable}>
        <Flex.Row flexWrap="wrap" columnGap={theme.old.spacing.baseSpacing}>
          <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM}>
            <Form.Item
              initialValue={currentProjectId}
              name="projectId"
              label={t('timeRecords:form.labels.projectId')}
              rules={[
                {
                  required: true,
                  message: t('timeRecords:form.validation.missingProjectId'),
                },
              ]}
            >
              <ProjectPicker
                disabled={disableForm}
                contextType={contextType}
                officeRoles={[
                  'officeAdmin',
                  'officeAssistance',
                  'officeController',
                ]}
              />
            </Form.Item>
          </Flex.Item>
          <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM}>
            <Form.Item
              initialValue={contact}
              name="contactId"
              label={t('timeRecords:form.labels.contactId')}
              rules={[
                {
                  required: true,
                  message: t('timeRecords:form.validation.missingContactId'),
                },
              ]}
            >
              <ContactPicker
                disabled={!canSelectContact || disableForm || !currentProjectId}
                onlyInternalProject
                projectId={currentProjectId}
                loading={isFetchingMe && !!userMeContactId}
              />
            </Form.Item>
          </Flex.Item>
        </Flex.Row>
        <Row>
          <Col span={24}>
            <Divider className={classes.divider} />
          </Col>
        </Row>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item name="dayRecordEntries" style={{ marginBottom: '0px' }}>
              <TimeRecordEntryManagement
                form={form}
                timeRecordForm={timeRecordForm}
                initialTimeRecordEntries={inintialTimeRecordEntries}
                isFormDisabled={disableForm}
                resetTimerecordformRef={resetTimerecordformRef}
              />
            </Form.Item>
            <Divider className={classes.divider2} />
          </Col>
        </Row>
        <Flex.Row flexWrap="wrap" columnGap={theme.old.spacing.baseSpacing}>
          <Flex.Item flex={2} minWidth={MIN_WIDTH_FORM_ITEM * 1.5}>
            <Form.Item
              name="title"
              colon={colon}
              label={t('timeRecords:form.labels.title')}
              rules={[
                {
                  required: true,
                  message: t('timeRecords:form.validation.missingTitle'),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Flex.Item>
          <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM * 0.5}>
            <Form.Item
              name="kilometerDistance"
              colon={colon}
              label={t('timeRecords:form.labels.kilometerDistance')}
              rules={[
                {
                  message: t(
                    'timeRecords:form.validation.invalidKilometerDistance'
                  ),
                  pattern: new RegExp('[0-9]+'),
                },
                () => ({
                  async validator(rule, value) {
                    if (value < 0) {
                      return Promise.reject(
                        t(
                          'timeRecords:form.validation.invalidKilometerDistance'
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                className={classes.fullWidth}
                min={0}
                disabled={disableForm}
                defaultValue={0}
                addonAfter={
                  <FontAwesomeIcon
                    icon={['fal', 'road']}
                    className={classes.mutedColor}
                  />
                }
              />
            </Form.Item>
          </Flex.Item>
        </Flex.Row>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              name="isSeparateService"
              valuePropName="checked"
              label={t('timeRecords:form.labels.isSeparateService')}
            >
              <Checkbox />
            </Form.Item>
          </Col>
          {projectPhases.length > 0 && (
            <Col span={12}>
              <Form.Item
                name="projectPhaseId"
                label={t('timeRecords:form.labels.projectPhase')}
              >
                <Select<string>
                  showSearch
                  size="small"
                  value={projectPhases[0].projectPhaseId}
                >
                  {projectPhases.map((phase) => (
                    <Select.Option value={phase.projectPhaseId}>
                      {phase.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('timeRecords:form.labels.description')}
              rules={[
                {
                  required: descriptionIsRequired,
                  message: t('timeRecords:form.validation.missingDescription'),
                },
              ]}
              colon={colon}
              className={classes.noMargin}
            >
              <Input.TextArea
                disabled={disableForm}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Row justify="end" className={classes.actionButtonsRow}>
        <Col span={24}>
          <Form.Item className={classes.submitButtonFormItem}>
            {onCancel && (
              <Button
                type="default"
                onClick={cancel}
                style={{ marginRight: '8px' }}
              >
                {cancelLabel}
              </Button>
            )}
            <Button
              htmlType="submit"
              disabled={disableActionButton || !saveButtonEnabled}
            >
              {actionLabel}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateTimeRecordForm;
