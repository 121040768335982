import React from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import Module from '../module/Module';
import TaskPane from '../taskpane/TaskPane';
import useAuth from '../auth/authHook';
import SignInPage from '../auth/components/SignInPage';
import { AuthContext } from '../auth/authContext';
import { useSelector } from 'react-redux';
import {
  isLoggedIn,
  getIsPrioUser,
  getHasRegistrationCompleted,
} from '../../apps/main/rootReducer';
import { ConfigProvider, Empty } from 'antd';
import { officeContext, teamsContext } from '../../util/officeJs';
import i18n from '../../i18n';
import NoPrioUser from './NoPrioUser';
import WelcomeWizard from './WelcomeWizard';
import View from '../module/View';
import userflow from 'userflow.js';
import NewPrioVersionModal from './NewPrioVersionModal';
import PrioSpinner from '../../components/PrioSpinner';
import { PublicClientApplication } from '@azure/msal-browser';
import { Task } from '@redux-saga/types';
import Mock from '../mock/components/Mock';
import * as Sentry from '@sentry/react';
import MainProvider from './MainProvider';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import 'moment/locale/es';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const loading = (
  <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
    <PrioSpinner size="gigantic" />
  </div>
);

const customizedRenderEmpty = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={i18n.t('common:noData')}
  />
);

interface MainAppProps {
  msalInstance: PublicClientApplication;
  task: Task;
  isMock?: boolean;
}

export const MainApp: React.FC<MainAppProps> = (props) => {
  const { msalInstance, task, isMock } = props;
  const auth = useAuth(officeContext, teamsContext, msalInstance);
  const loggedIn: boolean = useSelector(isLoggedIn);
  const isPrioUser: boolean = useSelector(getIsPrioUser);

  const { i18n } = useTranslation();

  moment.locale(i18n.language);
  const hasRegistrationCompleted: boolean = useSelector(
    getHasRegistrationCompleted
  );

  if (!auth.account) {
    return <SignInPage auth={auth} />;
  }

  if (!loggedIn || isPrioUser === null || typeof isPrioUser === 'undefined') {
    return loading;
  }
  if (!isPrioUser) {
    return <NoPrioUser />;
  }

  if (isMock) {
    return (
      <AuthContext.Provider value={auth}>
        <ConfigProvider renderEmpty={customizedRenderEmpty}>
          <MainProvider>
            <Routes>
              <Route path="*" element={<Navigate to="mock" />} />
              <Route path="mock/*" element={<Mock />} />
            </Routes>
          </MainProvider>
        </ConfigProvider>
      </AuthContext.Provider>
    );
  }

  // init userflow
  try {
    userflow.init('ct_sozua5i4lbbc3oioa7n3lcq34q');
    userflow.identify(auth.account.homeAccountId);
  } catch {}

  return (
    <AuthContext.Provider value={auth}>
      <ConfigProvider renderEmpty={customizedRenderEmpty}>
        <Routes>
          <Route path="*" element={<Navigate to="module" />} />
          <Route path="module/*" element={<Module />} />
          <Route path="taskpane/*" element={<TaskPane />} />
          <Route path="view/*" element={<View />} />
        </Routes>
        {!hasRegistrationCompleted ? (
          <div
            tabIndex={-1}
            style={{
              position: 'fixed',
              zIndex: 1000,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: '100%',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                backdropFilter: 'blur(5px)',
                height: '100%',
                opacity: 1,
                pointerEvents: 'auto',
              }}
            />
            <div
              style={{
                position: 'relative',
                height: '100%',
              }}
            >
              <WelcomeWizard />
            </div>
          </div>
        ) : null}
        {hasRegistrationCompleted && (
          <NewPrioVersionModal
            hasRegistrationCompleted={hasRegistrationCompleted}
            task={task}
          />
        )}
      </ConfigProvider>
    </AuthContext.Provider>
  );
};

export default MainApp;
