import { Reducer } from 'redux';
import { ReduxAction } from '../../../models/Redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';
import { UPDATE_IS_DOCUMENT_META_DATA_SAVING } from '../actions/isDocumentMetaDataSaving';

export type DocumentMetaDataSavingState = boolean;

export const initialState: DocumentMetaDataSavingState = false;

const isDocumentMetaDataSaving: Reducer<
  DocumentMetaDataSavingState,
  ReduxAction<{
    isSaving: boolean;
  }>
> = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_IS_DOCUMENT_META_DATA_SAVING: {
      const {
        meta: { isSaving },
      } = action;
      return isSaving;
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default isDocumentMetaDataSaving;

export const getIsDocumentMetaDataSaving = (
  state: DocumentMetaDataSavingState
) => state;
