import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, notification, Typography } from 'antd';
import { apiDeleteDriveItems } from '../../api';
import { DriveItemId, GroupId } from '../../../../models/Types';
import { useDispatch } from 'react-redux';
import { driveItemsDeleted } from '../../actions';
import { setDocumentsMetaState } from '../../actions/meta';
import { DriveItem } from '../../../../models/Drive';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    maxHeight: '50vh',
    '& .ant-modal-content': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    '& .ant-modal-body': {
      flex: 1,
      overflow: 'scroll',
    },
  },
}));

interface DeleteSelectionModalProps {
  visible: boolean;
  selectedDriveItemIds: DriveItemId[];
  driveItems: DriveItem[];
  parentDriveItemId: DriveItemId;
  groupId: GroupId;
  onOk: VoidFunction;
  onCancel: VoidFunction;
}

export const DeleteSelectionModal: React.FC<DeleteSelectionModalProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    visible,
    selectedDriveItemIds,
    groupId,
    parentDriveItemId,
    driveItems,
    onOk,
    onCancel,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const selectedItems = driveItems.filter((item) =>
    selectedDriveItemIds.includes(item.id)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onOkClick = async () => {
    const { result } = await apiDeleteDriveItems(groupId, selectedDriveItemIds);
    if (result.status >= 200 && result.status < 300) {
      dispatch(
        driveItemsDeleted(selectedDriveItemIds, parentDriveItemId, groupId)
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.deleteDocumentError'),
      });
    }
    dispatch(
      setDocumentsMetaState({
        showDeleteDocumentsModal: false,
      })
    );
    onOk();
  };

  const onCancelClick = () => {
    dispatch(
      setDocumentsMetaState({
        showDeleteDocumentsModal: false,
      })
    );
    onCancel();
  };
  //#endregion

  //#region ------------------------------ useEffects
  //#endregion

  return (
    <Modal
      title={t('documents:confirmation.delete.title')}
      okText={t('documents:confirmation.delete.okText')}
      cancelText={t('documents:confirmation.delete.cancelText')}
      onOk={onOkClick}
      onCancel={onCancelClick}
      visible={visible}
      className={classes.root}
    >
      <Flex.Column>
        <Typography.Text>
          {t('documents:confirmation.delete.content')}
        </Typography.Text>
        <ul>
          {selectedItems.map((item) => (
            <li>
              <Typography.Text>{item.name}</Typography.Text>
            </li>
          ))}
        </ul>
      </Flex.Column>
    </Modal>
  );
};

export default DeleteSelectionModal;
