import React from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectMembership } from '../../../models/Contact';
import { useSelector } from 'react-redux';
import { RootReducerState, getCompanies } from '../../../apps/main/rootReducer';
import { Company } from '../../../models/Company';
import VirtualTable2, {
  VColumn,
} from '../../../components/VirtualTable/VirtualTable2';
import PrioSpinner from '../../../components/PrioSpinner';
import { useTranslation } from 'react-i18next';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  tableCell: {
    userSelect: 'none',
  },
}));

interface ProjectMembershipTableProps {
  isFetching: boolean;
  projectMembership: ProjectMembership;
}

interface ProjectMembershipMapToCompany {
  name: string;
  shortName: string;
  number: string;
  projectClientCompany: Company;
  contractorCompany: Company;
}

export const ProjectMembershipTable: React.FC<ProjectMembershipTableProps> = (
  props
) => {
  const classes = useStyles();
  const { projectMembership, isFetching } = props;
  const { t } = useTranslation();
  //#region ------------------------------ Defaults
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const projectClients = useSelector<RootReducerState, Company[]>((state) =>
    getCompanies(
      state,
      projectMembership?.projectInfos.map((x) => x.projectClientCompanyId) ?? []
    )
  );

  const contractors = useSelector<RootReducerState, Company[]>((state) =>
    getCompanies(
      state,
      projectMembership?.projectInfos.map((x) => x.contractorCompanyId) ?? []
    )
  );

  const columns: VColumn<ProjectMembershipMapToCompany>[] = [
    {
      id: 'number',
      alignSelf: true,
      title: t('contacts:contactDetails.projectMember.name'),
      width: 34,
      accessor: 'number',
      className: classes.tableCell,
      Cell: (cellprops) => (
        <span>{`${cellprops?.row?.original?.number ?? ''} ${
          cellprops?.row?.original?.shortName ?? ''
        }`}</span>
      ),
      sortType: (a, b) => {
        const numberCompare = a?.original?.number.localeCompare(
          b?.original?.number
        );
        if (numberCompare !== 0) {
          return numberCompare;
        }
        return a?.original?.shortName.localeCompare(b?.original?.shortName);
      },
    },
    {
      id: 'projectClientCompany',
      alignSelf: true,
      title: t('contacts:contactDetails.projectMember.projectClientCompany'),
      width: 33,
      accessor: 'projectClientCompany',
      className: classes.tableCell,
      Cell: (cellprops) => (
        <span>{cellprops?.row?.original?.projectClientCompany?.fullName}</span>
      ),
      sortType: (a, b) => {
        return a?.original?.projectClientCompany?.fullName.localeCompare(
          b?.original?.projectClientCompany?.fullName
        );
      },
    },
    {
      id: 'contractorCompany',
      alignSelf: true,
      title: t('contacts:contactDetails.projectMember.contractorCompany'),
      width: 33,
      accessor: 'contractorCompany',
      className: classes.tableCell,
      Cell: (cellprops) => (
        <span>{cellprops?.row?.original?.contractorCompany?.fullName}</span>
      ),
      sortType: (a, b) => {
        return a?.original?.contractorCompany?.fullName.localeCompare(
          b?.original?.contractorCompany?.fullName
        );
      },
    },
  ];

  const data: ProjectMembershipMapToCompany[] =
    projectMembership?.projectInfos
      .map((x, index) => ({
        name: x?.name,
        shortName: x?.shortName,
        number: x?.number,
        projectClientCompany: projectClients[index],
        contractorCompany: contractors[index],
      }))
      ?.sort((a, b) => {
        const numberCompare = a?.number.localeCompare(b?.number);
        if (numberCompare !== 0) {
          return numberCompare;
        }
        return a?.shortName.localeCompare(b?.shortName);
      }) ?? [];

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region -------------------------------- Effects

  //#endregion

  return (
    <div className={classes.root}>
      <VirtualTable2
        columns={columns}
        data={data}
        columnsResizable
        loading={
          isFetching
            ? {
                loadingType: 'table',
                indicator: <PrioSpinner alignSelf />,
              }
            : null
        }
        overscanRowCount={10}
        classNameTableRow={classes.root}
      />
    </div>
  );
};
