import React from 'react';

import HourlyRatesPage from '../HourlyRatesPage';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
  },
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    marginBottom: `${theme.old.spacing.unit(2)}px`,
    overflow: 'hidden',
    minHeight: `${theme.old.spacing.unit(8)}px`,
  },
  wrapContainerWhiteMain: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    overflow: 'hidden',
    height: '100%',
  },
  contentContainerFloat: {
    gap: `${theme.old.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    '& .ant-form-item': {
      width: '100%',
    },
    '& .ant-row': {
      width: '100%',
    },
  },
  contentContainer: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    gap: `${theme.old.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  spacing: {
    marginLeft: theme.old.spacing.unit(2),
  },
  floatRight: {
    float: 'right',
  },
}));

interface CreateProjectControllingFormProps {
  className?: string;
  projectId: string;
  nextStep?: (projectId: string, step: string) => void;
}

export const CreateProjectControllingForm: React.FC<
  CreateProjectControllingFormProps
> = (props) => {
  const { nextStep, projectId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.wrapContainerWhiteMain}>
          <HourlyRatesPage projectId={projectId} />
        </div>
        <div className={classes.wrapContainerWhite}>
          <Button
            className={classes.floatRight}
            onClick={() => {
              if (nextStep) nextStep(projectId, 'member');
            }}
          >
            {t('projects:createProject.buttons.next')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateProjectControllingForm;
