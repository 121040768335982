import {
  fetchMailFoldersProject,
  setUnreadItemCountProject,
  setActiveMailFolderInProjects,
  setOpenMailFolderProjects,
  addOpenMailFolderProjects,
} from '../projects/mailFolders';
import {
  fetchMailFoldersMe,
  setUnreadItemCountMe,
  setActiveMailFolderMe,
  setOpenMailFolderMe,
  addOpenMailFolderMe,
} from '../me/mailFoldersMe';
import { MailFolderId, ProjectId } from '../../../../models/Types';

export const fetchMailFolders = (projectId: ProjectId) => {
  if (projectId === 'me') {
    return fetchMailFoldersMe();
  }
  return fetchMailFoldersProject(projectId);
};

export const setUnreadItemCount = (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  itemCount: number,
  option: 'add' | 'remove'
) => {
  if (projectId === 'me') {
    return setUnreadItemCountMe(mailFolderId, itemCount, option);
  }
  return setUnreadItemCountProject(mailFolderId, itemCount, option);
};

export const setActiveMailFolder = (
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => {
  if (projectId === 'me') {
    return setActiveMailFolderMe(mailFolderId);
  }
  return setActiveMailFolderInProjects(projectId, mailFolderId);
};

export const setOpenMailFolders = (
  projectId: ProjectId,
  mailFolderIds: MailFolderId[]
) => {
  if (projectId === 'me') {
    return setOpenMailFolderMe(mailFolderIds);
  }
  return setOpenMailFolderProjects(projectId, mailFolderIds);
};

export const addOpenMailFolder = (
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => {
  if (projectId === 'me') {
    return addOpenMailFolderMe(mailFolderId);
  }
  return addOpenMailFolderProjects(projectId, mailFolderId);
};
