import React, { useState, useEffect } from 'react';
import { notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import {
  Message,
  MessageAttachment,
  MessageCenterMessage,
} from '../../../models/Message';
import { convertToOfficeRecipient } from '../officeJsUtil';
import { MessageDisplay } from './MessageDisplay/MessageDisplay';
import { apiFetchDraftMessages, apiCreateMessageReply } from '../api';
import { ProjectId } from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import * as ConfigValues from '../../../util/configValues';
import { debounceFunction } from '../../../util';
import { markAsRead } from '../actions/actionControllers/messageActionController';
import {
  getActiveMailFolderId,
  getCurrentSearchKeywords,
  getIsSingleMessageFetching,
  getMailSettings,
  getProjectByIdState,
  getSpecialMailFolders,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import printJS from 'print-js';
import { SpecialMailFolders } from '../actions/types';
import { Link, useNavigate } from 'react-router-dom';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import classNames from 'classnames';
import { addDraftMessage } from '../actions/actionControllers/draftsActionController';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { deleteMessageSagaAction } from '../actions/sagas';
import ReactDOMServer from 'react-dom/server';
import MessagePrintTemplate from './Print/MessagePrintTemplate';

const TIMEOUT = 1000;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  mailComposerContentWithoutDraft: {
    minHeight: '100%',
  },
  message: {
    flex: 1,
  },
  messageDisplay: {
    overflow: 'hidden scroll',
    marginTop: '0px',
    flex: 1,
  },
  backButtonAndHeaderWrapper: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '24px',
    alignItems: 'center',
  },
  backButtonAndHeaderWrapperViewCompressed: {
    paddingTop: '8px !important',
    paddingLeft: '0px !important',
    height: '32px',
  },

  title: {
    padding: theme.old.spacing.defaultPadding,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.ant-typography': {
      margin: '0px',
    },
  },
  titleNoSubject: {
    padding: theme.old.spacing.defaultPadding,
    paddingBottom: 0,
    paddingRight: theme.old.spacing.unit(1),
  },
  titleNoReadingArea: {
    paddingTop: 0,
    paddingLeft: '8px',
  },
}));

const debouncedFetchDraftMessages = debounceFunction(
  async (
    projectId: ProjectId,
    conversationId: string,
    setDraftMessages: (value: Message[]) => void
  ) => {
    if (projectId && conversationId) {
      const { data } = await apiFetchDraftMessages(projectId, conversationId);

      if (Array.isArray(data)) {
        setDraftMessages(
          (data as Message[]).sort(
            (a, b) =>
              Date.parse(b.lastModifiedDateTime) -
              Date.parse(a.lastModifiedDateTime)
          )
        );
      } else {
        setDraftMessages([]);
      }
    }
  },
  TIMEOUT
);

interface MessageViewProps {
  className?: string;
  message: Message;
  projectId: ProjectId;
  showTitle?: boolean;
  showActions?: boolean;
  backButton?: boolean;
  prefix?: string;
  openInNewWindow?: boolean;
  isEvent?: boolean;
}

export const MessageView: React.FC<MessageViewProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    message,
    projectId,
    showActions,
    backButton,
    prefix,
    openInNewWindow,
    isEvent,
  } = props;
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [draftMessages, setDraftMessages] = useState<Message[]>([]);

  const [messageId, setMessageId] = useState<string>(message.id);

  const [parsedMessageAttachments, setParsedMessageAttachments] = useState<
    MessageAttachment[]
  >([]);

  const [isDraft, setIsDraft] = useState<boolean>(message.isDraft);

  const [conversationId, setConversationId] = useState<string>(
    message.conversationId
  );

  const classes = useStyles({
    props,
  });

  const isMessageRefetching = useSelector<RootReducerState, boolean>((state) =>
    getIsSingleMessageFetching(state, projectId)
  );

  const searchKeywords = useSelector<RootReducerState, string>((state) =>
    getCurrentSearchKeywords(state, projectId)
  );

  const mailFolderId = useSelector<RootReducerState, string>((state) =>
    getActiveMailFolderId(state, projectId)
  );

  const specialMailFolders = useSelector<RootReducerState, SpecialMailFolders>(
    (state) => getSpecialMailFolders(state, projectId)
  );

  const mailSettings = useSelector(getMailSettings);

  const userMe = useSelector(getUserMe);

  const projectsById = useSelector(getProjectByIdState);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const deleteMessage = (message: Message) => {
    const messageProjectId =
      projectId === 'favorites'
        ? (message as MessageCenterMessage).projectId
        : projectId;
    dispatch(
      deleteMessageSagaAction(
        messageProjectId,
        mailFolderId,
        [message],
        { navigate, prefix: 'mail' },
        () => {
          setTimeout(() => {
            window.close();
          }, 500);
        }
      )
    );
  };

  const onDelete = (message: Message) => {
    deleteMessage(message);
  };

  const reply = async () => {
    await createMessageReplyAllForward('Reply');
  };

  const createMessageReplyAllForward = async (
    type: 'Reply' | 'ReplyAll' | 'Forward'
  ) => {
    const { data } = await apiCreateMessageReply(projectId, messageId, type);
    if (data) {
      setDraftMessages([data, ...draftMessages]);
      dispatch(addDraftMessage(projectId, data));
      return data;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
      return null;
    }
  };

  const replyAll = async () => {
    if (
      ConfigValues.REACT_APP_ENVIRONMENT === 'office' &&
      Office.context.mailbox
    ) {
      Office.context.mailbox.displayNewMessageForm({
        toRecipients: [message.sender, ...message.ccRecipients].map(
          convertToOfficeRecipient
        ),
        subject: t('mail:replyToPrefix') + message.subject,
        htmlBody: message.body.content,
      });
    } else {
      await createMessageReplyAllForward('ReplyAll');
    }
  };

  const forward = async () => {
    if (
      ConfigValues.REACT_APP_ENVIRONMENT === 'office' &&
      Office.context.mailbox
    ) {
      Office.context.mailbox.displayNewMessageForm({
        subject: t('mail:forwardPrefix') + message.subject,
        htmlBody: message.body.content,
      });
    } else {
      await createMessageReplyAllForward('Forward');
    }
  };

  const markRead = async () => {
    dispatch(
      markAsRead(
        projectId,
        [message.id],
        !message.isRead,
        mailFolderId === 'inbox' && specialMailFolders
          ? specialMailFolders['inboxFolder']?.id
          : mailFolderId,
        1
      )
    );
  };

  const printEmail = async () => {
    const printable = ReactDOMServer.renderToStaticMarkup(
      <MessagePrintTemplate
        message={message}
        messageAttachments={parsedMessageAttachments}
      />
    );

    const messageProjectId =
      projectId === 'favorites'
        ? (message as MessageCenterMessage).projectId
        : projectId;

    printJS({
      printable: printable,
      type: 'raw-html',
      documentTitle: `Prio365 - ${
        messageProjectId === 'me'
          ? userMe?.displayName
          : projectsById[messageProjectId]?.name
      } - ${t('mail:mailNav.title')}`,
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (messageId !== message.id) {
      setMessageId(message.id);
    }
  }, [message, messageId]);

  useEffect(() => {
    if (conversationId !== message.conversationId) {
      setConversationId(message.conversationId);
    }
  }, [message, conversationId]);

  useEffect(() => {
    if (isDraft !== message.isDraft) {
      setIsDraft(message.isDraft);
    }
  }, [message, isDraft]);

  useEffect(() => {
    if (message.attachments) {
      setParsedMessageAttachments(message.attachments);
    } else if (message.messageAttachments) {
      setParsedMessageAttachments(message.messageAttachments);
    }
  }, [message.attachments, message.messageAttachments]);

  useEffect(() => {
    if (!isDraft) {
      debouncedFetchDraftMessages(projectId, conversationId, setDraftMessages);
    }
  }, [projectId, conversationId, isDraft]);
  //#endregion

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      style={{ marginBottom: '24px' }}
    >
      {backButton && (
        <Flex.Row
          className={classNames(classes.backButtonAndHeaderWrapper, {
            [classes.backButtonAndHeaderWrapperViewCompressed]:
              mailSettings.mailListSpacing === 'tight',
          })}
        >
          <Link to={prefix ?? mailFolderId ?? 'inbox'}>
            <Button
              type="link"
              onClick={() => {
                dispatch(setMailListNavigationState(null, projectId));
              }}
              iconProp={['fal', 'chevron-left']}
            ></Button>
          </Link>
          <Typography.Title
            level={3}
            className={classNames(
              message.subject === '' ? classes.titleNoSubject : classes.title,
              backButton ? classes.titleNoReadingArea : null
            )}
            title={`${
              message.importance === 'high'
                ? `${t('mail:messageDisplay.highImportance')}! `
                : ''
            }${
              message.subject === ''
                ? `${t('mail:messageDisplay.noSubject')}`
                : message.subject
            }`}
            style={{ margin: 0 }}
          >
            {message.importance === 'high' && (
              <span style={{ color: theme.old.palette.chromaticPalette.red }}>
                {`${t('mail:messageDisplay.highImportance')}! `}
              </span>
            )}
            <span>
              {!message.subject
                ? `${t('mail:messageDisplay.noSubject')}`
                : message.subject}
            </span>
          </Typography.Title>
        </Flex.Row>
      )}
      <Flex.Column
        className={classNames(classes.messageDisplay)}
        childrenGap={theme.old.spacing.baseSpacing}
      >
        <Flex.Column
          className={classes.mailComposerContentWithoutDraft}
          childrenGap={theme.old.spacing.baseSpacing}
        >
          <MessageDisplay
            className={classes.message}
            message={message}
            onDelete={onDelete}
            onReply={reply}
            onReplyAll={replyAll}
            onForward={forward}
            onMarkRead={markRead}
            onPrintEmail={printEmail}
            messageAttachments={parsedMessageAttachments}
            projectId={projectId}
            showActions={showActions}
            searchKeywords={searchKeywords?.toLocaleLowerCase()}
            openInNewWindow={openInNewWindow}
            isFetching={isMessageRefetching}
            disableTitleBar={isEvent || backButton}
            messageAttachmentsLoaded={true}
            draftMessages={draftMessages}
            isEvent={isEvent}
          />
        </Flex.Column>
      </Flex.Column>
    </Flex.Column>
  );
};

export default MessageView;
