import React, { ReactNode, useCallback, useMemo } from 'react';
import { CompaniesContext } from '../context';
import { useSelector } from 'react-redux';
import {
  getCompaniesByIdState,
  getCompanyIds,
} from '../../../apps/main/rootReducer';
import { Company } from '../../../models/Company';

interface CompaniesProviderProps {
  children?: ReactNode;
}

export const CompaniesProvider: React.FC<CompaniesProviderProps> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const byId = useSelector(getCompaniesByIdState);

  const companyIds = useSelector(getCompanyIds);

  const companies: Company[] = useMemo(() => {
    return companyIds
      .map((id) => byId[id])
      .filter((company) => !!company)
      .sort((a: Company, b: Company) => {
        const shortNameCompare = a.shortName?.localeCompare(b.shortName);
        if (shortNameCompare !== 0) {
          return shortNameCompare;
        }
        return a.fullName?.localeCompare(b.fullName);
      });
  }, [companyIds, byId]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getCompanyById = useCallback(
    (id: string) => {
      return byId[id];
    },
    [byId]
  );

  const getCompanyByProperty = useCallback(
    (property: keyof Company, value: any) => {
      return companies.find((project) => project[property] === value);
    },
    [companies]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <CompaniesContext.Provider
      value={{
        companies,
        getCompanyById,
        getCompanyByProperty,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

export default CompaniesProvider;
