import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import {
  AddEmployee,
  CreateRegistrationEmployee,
} from '../../../models/Employee';
import { ContactId, EmployeeId, OfficeId } from '../../../models/Types';
import fetchWithRetry from '../../../util/fetchWithRetry';
import { Birthday } from '../../../models/Employee';
import { Moment } from 'moment-timezone';

export const apiCreateRegistrationEmployee: (
  employeeData: CreateRegistrationEmployee
) => Promise<boolean> = async (employeeData) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/Employee/register/me`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(employeeData),
    }
  );

  return result.status >= 200 && result.status < 300;
};
export const apiSubmitEmployeeOnboarding: (
  addEmployee: AddEmployee,
  officeId?: OfficeId
) => Promise<ApiResult<boolean>> = async (addEmployee, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/onboarding/Office/${officeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(addEmployee),
    }
  );

  const data = result.status >= 200 && result.status < 300;
  return {
    result,
    data,
  };
};
export const apiOffBoardEmployee: (
  employeeId: EmployeeId,
  formData: FormData
) => Promise<ApiResult<any>> = async (employeeId, formData) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/Employee/${employeeId}/offboarding`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'POST',
      body: formData,
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchPrincipalsMe: () => Promise<
  ApiResult<ContactId[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/Employee/principal/me`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  let data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchPrincipals: (
  contactId: ContactId
) => Promise<ApiResult<ContactId[]>> = async (contactId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/Employee/contact/${contactId}/principal`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  let data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchCompanyBirthdays: (
  filterQuery?: { from: Moment; to?: Moment },
  signal?: AbortSignal
) => Promise<ApiResult<Birthday[]>> = async (filterQuery, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/Employee/CompanyBirthdays${
      filterQuery
        ? `?${new URLSearchParams({
            from: filterQuery.from.toISOString(),
            to: filterQuery.to.toISOString(),
          }).toString()}`
        : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  let data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchOfficeBirthdays: (
  filterQuery?: { from: Moment; to?: Moment },
  signal?: AbortSignal
) => Promise<ApiResult<Birthday[]>> = async (filterQuery, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/Employee/OfficeBirthdays${
      filterQuery
        ? `?${new URLSearchParams({
            from: filterQuery.from.toISOString(),
            to: filterQuery.to.toISOString(),
          }).toString()}`
        : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  let data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};
