import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import PrioSpinner from '../../../../components/PrioSpinner';
import { Typography } from 'antd';
import { Project } from '../../../../models/Project';
import { useTranslation } from 'react-i18next';
import CompanyText from '../../../companies/components/CompanyText';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  notificationList: {
    flex: 1,
    overflow: 'auto',
  },
  spinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface DashboardProjectInfosItemItemProps {
  className?: string;
  project: Project;
}

export const DashboardProjectInfosItemItem: React.FC<
  DashboardProjectInfosItemItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, project } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isFetching = false;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      {isFetching ? (
        <Flex.Item flex={1} className={classes.spinner}>
          <PrioSpinner size="large" />
        </Flex.Item>
      ) : project ? (
        <>
          <Typography.Title level={3} ellipsis style={{ marginBottom: 16 }}>
            {project.number} - {project.shortName ?? project.name}
          </Typography.Title>
          <Typography.Title level={4} style={{ marginBottom: 2 }}>
            {t('projects:summary.email')}
          </Typography.Title>
          <Typography.Text>
            {`${project.eMailPrefix}@${project.eMailSuffix}`}
          </Typography.Text>
          <Typography.Title level={4} style={{ marginBottom: 2 }}>
            {t('projects:summary.customer')}
          </Typography.Title>
          <Typography.Text>
            {<CompanyText companyId={project.companyId} />}
          </Typography.Text>
          <Typography.Title level={4} style={{ marginBottom: 2 }}>
            {t('projects:summary.subsidiary')}
          </Typography.Title>
          <Typography.Text>
            {<CompanyText companyId={project.subsidiaryId} />}
          </Typography.Text>
        </>
      ) : (
        <>no data</>
      )}
    </Flex.Column>
  );
};

export default DashboardProjectInfosItemItem;
