import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {} from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { Message } from '../../../../models/Message';
import { longDateFormat } from '../../../../util';
import i18n from '../../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useDispatch } from 'react-redux';
import { addDraftMessage } from '../../actions/actionControllers/draftsActionController';
import { ProjectId } from '../../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    '& $listItem:not(:first-child)': {
      borderTop: theme.old.borders.content,
    },
  },
  listItem: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.old.spacing.defaultPadding,
    '& .prio-message-draft-listItem-edit': {
      display: 'none',
      marginLeft: theme.old.spacing.baseSpacing,
    },
    '&:hover .prio-message-draft-listItem-edit': {
      display: 'block',
    },
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  listItemTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  listItemTitle: {
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  listItemDescriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  listItemDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  listItemTitleDate: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.small,
  },
}));

interface DraftListContainerProps {
  className?: string;
  projectId: ProjectId;
  draftMessages: Message[];
}

export const DraftListContainer: React.FC<DraftListContainerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, projectId, draftMessages } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const openDraft = (draftMessage: Message) => {
    dispatch(addDraftMessage(projectId, draftMessage));
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      {draftMessages.map((draftMessage) => (
        <div className={classes.listItem}>
          <div className={classes.listItemContainer}>
            <div className={classes.listItemTitleContainer}>
              <div className={classes.listItemTitle}>
                {draftMessage.subject ?? t('mail:messageDisplay.noSubject')}
              </div>
              <div className={classes.listItemTitleDate}>
                {longDateFormat(
                  new Date(Date.parse(draftMessage.lastModifiedDateTime)),
                  i18n.language
                )}
              </div>
            </div>
            <div className={classes.listItemDescriptionContainer}>
              <div className={classes.listItemDescription}>
                {draftMessage.bodyPreview}
              </div>
              <div className={'prio-message-draft-listItem-edit'}>
                <FontAwesomeIcon
                  color={theme.old.palette.primaryColor}
                  style={{ cursor: 'pointer' }}
                  icon={['fal', 'edit']}
                  onClick={() => openDraft(draftMessage)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DraftListContainer;
