import React, { useState, useEffect } from 'react';
import equals from 'deep-equal';
import { Typography } from 'antd';

import { ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { KilometerRate } from '../../../models/KilometerRate';
import CurrencyInput from '../../../components/CurrencyInput';
import PrioSpinner from '../../../components/PrioSpinner';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  firstCol: {
    width: 300,
    minWidth: 300,
  },
  secondCol: {
    width: 250,
    minWidth: 250,
  },
  thirdCol: {
    width: 250,
    minWidth: 250,
  },
  fourthCol: {
    width: 80,
    minWidth: 80,
  },
  numberInput: {
    flex: 1,
  },
  spin: {
    height: 64,
    justifyContent: 'center',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
}));

interface KilometerRateInputProps {
  className?: string;
  projectId: ProjectId;
  kilometerRate: KilometerRate;
  onIsDirtyChanged?: (isDirty: boolean, kilometerRate?: KilometerRate) => void;
  loading?: boolean;
}

export const KilometerRateInput: React.FC<KilometerRateInputProps> = (
  props
) => {
  const classes = useStyles();
  const {
    className,
    kilometerRate: originalKilometerRate,
    onIsDirtyChanged,
    loading,
  } = props;
  const { t } = useTranslation();
  const [kilometerRate, setKilometerRate] = useState<KilometerRate>(
    originalKilometerRate
  );

  useEffect(() => {
    setKilometerRate(originalKilometerRate);
    onIsDirtyChanged(false, originalKilometerRate);
  }, [originalKilometerRate, onIsDirtyChanged]);

  const updateInternalKilometerRate = (value: number) => {
    const updatedKilometerRate: KilometerRate = {
      ...kilometerRate,
      internalValue: value,
    };
    setKilometerRate(updatedKilometerRate);

    if (onIsDirtyChanged) {
      const wasDirty = !equals(kilometerRate, originalKilometerRate);
      const isDirty = !equals(updatedKilometerRate, originalKilometerRate);
      if (isDirty !== wasDirty) onIsDirtyChanged(isDirty, updatedKilometerRate);
    }
  };

  const updateExternalKilometerRate = (value: number) => {
    const updatedKilometerRate: KilometerRate = {
      ...kilometerRate,
      externalValue: value,
    };
    setKilometerRate(updatedKilometerRate);

    if (onIsDirtyChanged) {
      const isDirty = !equals(updatedKilometerRate, originalKilometerRate);
      onIsDirtyChanged(isDirty, updatedKilometerRate);
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      <div className="ant-table-wrapper">
        <div className="ant-table">
          <div className="ant-table-container">
            <div className="ant-table-content">
              {loading ? (
                <Flex.Column className={classes.spin}>
                  <PrioSpinner />
                </Flex.Column>
              ) : (
                <table style={{ tableLayout: 'auto' }}>
                  <colgroup>
                    <col className={classes.firstCol} />
                    <col className={classes.secondCol} />
                    <col className={classes.thirdCol} />
                    <col className={classes.fourthCol} />
                  </colgroup>
                  <tbody className="ant-table-tbody">
                    <tr className="ant-table-row ant-table-row-level-0">
                      <td className="ant-table-cell">
                        <Typography.Text>
                          {t('projects:kilometerRateTable.kilometerRate')}
                        </Typography.Text>
                      </td>
                      <td className="ant-table-cell">
                        <Flex.Row>
                          <CurrencyInput
                            isoCode="EUR"
                            value={kilometerRate.internalValue}
                            className={classes.numberInput}
                            onChange={updateInternalKilometerRate}
                          />
                        </Flex.Row>
                      </td>
                      <td className="ant-table-cell">
                        <Flex.Row>
                          <CurrencyInput
                            isoCode="EUR"
                            value={kilometerRate.externalValue}
                            className={classes.numberInput}
                            onChange={updateExternalKilometerRate}
                          />
                        </Flex.Row>
                      </td>
                      <td className="ant-table-cell">
                        <div></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KilometerRateInput;
