import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Menu } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { MailFolder } from '../../../../models/MailFolder';
import { MailFolderId, ProjectId } from '../../../../models/Types';
import { SpecialMailFolders } from '../../actions/types';
import { Message } from '../../../../models/Message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import MailFolderMenuItem from './MailFolderMenuItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMailSettings,
  getOpenMailFolderKeys,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { setOpenMailFolders } from '../../actions/actionControllers/mailFoldersController';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menu: {
    background: 'none',
    border: 'none',
    '&.ant-menu .ant-menu-item-selected:after': {
      display: 'none',
    },
    padding: `0 ${theme.old.components.mailFolderItem.spacing * 3}px`,
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'all 0.2s',
    '&.ant-menu-inline .ant-menu-item': {
      height: theme.old.components.mailFolderItem.itemHeight,
      lineHeight: `${theme.old.components.mailFolderItem.itemHeight}px`,
      paddingRight: 28,
    },
    '&.ant-menu-inline .ant-menu-submenu-title': {
      height: theme.old.components.mailFolderItem.itemHeight,
      lineHeight: `${theme.old.components.mailFolderItem.itemHeight}px`,
      paddingRight: 28,
    },
    '& .ant-btn': {
      height: theme.old.components.mailFolderItem.itemHeight,
      width: theme.old.components.mailFolderItem.itemHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .ant-btn-icon-only > *': {
      fontSize: theme.old.components.mailFolderItem.fontSize,
    },
  },
  collapsedMenu: {
    padding: `0 ${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: '0 15px',
    },
  },
  menuItem: {
    '&.ant-menu-item': {},
    '&.ant-menu-item-selected': {
      color: '#000',
    },
    borderRadius: 2,
  },
  collapsedMenuItem: {
    width: 48,
  },
  addFolderMenuItem: {
    color: theme.old.palette.primaryColor,
    fontSize: theme.old.components.mailFolderItem.fontSize,
  },
  collapsedAddFolderMenuItem: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.old.typography.fontSize.label,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  IconCompressed: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 16,
  },
}));

const getIconName: (
  mailFolderId: MailFolderId,
  specialMailFolders: SpecialMailFolders
) => IconName = (mailFolderId, specialMailFolders) => {
  if (!mailFolderId || !specialMailFolders) return 'folder';
  switch (mailFolderId) {
    case specialMailFolders['inboxFolder']?.id:
      return 'inbox';
    case specialMailFolders['sendFolder']?.id:
      return 'paper-plane';
    case specialMailFolders['draftFolder']?.id:
      return 'pen';
    case specialMailFolders['deletedFolder']?.id:
      return 'trash';
    default:
      return 'folder';
  }
};

interface MailFolderMenuProps {
  selectedMailFolderId?: string;
  mailFolders: MailFolder[];
  projectId: ProjectId;
  showNewFolderModal: (parentFolderId?: MailFolderId) => void;
  onDelete: (mailFolderId: MailFolderId, mailFolderName: string) => void;
  specialMailFolders: SpecialMailFolders;
  pathPrefix?: string;
  collapsed?: boolean;
  clearSelectedMessages?: (messageIds: Message[]) => void;
}

const MailFolderMenu: React.FC<MailFolderMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    selectedMailFolderId,
    mailFolders,
    projectId,
    showNewFolderModal,
    onDelete,
    specialMailFolders,
    pathPrefix,
    collapsed,
    clearSelectedMessages,
  } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const openKeys = useSelector<RootReducerState, MailFolderId[]>((state) =>
    getOpenMailFolderKeys(state, projectId)
  );

  const mailSettings = useSelector(getMailSettings);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnOpenChange = (openKeys: string[]) => {
    if (!collapsed) {
      dispatch(setOpenMailFolders(projectId, openKeys));
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Menu
      className={classNames(
        classes.menu,
        collapsed ? classes.collapsedMenu : ''
      )}
      selectedKeys={[selectedMailFolderId]}
      mode={collapsed ? 'vertical' : 'inline'}
      inlineIndent={!collapsed ? 12 : 24}
      openKeys={!collapsed ? openKeys : []}
      onOpenChange={handleOnOpenChange}
    >
      {mailFolders.map(
        (mailFolder) =>
          (!collapsed ||
            getIconName(mailFolder.id, specialMailFolders) !== 'folder') && (
            <MailFolderMenuItem
              key={mailFolder.id}
              mailFolder={mailFolder}
              projectId={projectId}
              showNewFolderModal={showNewFolderModal}
              onDelete={onDelete}
              pathPrefix={pathPrefix}
              specialMailFolders={specialMailFolders}
              collapsed={collapsed}
              selectedMailFolderId={selectedMailFolderId}
              clearSelectedMessages={clearSelectedMessages}
            />
          )
      )}
      <Menu.Item
        className={classNames(classes.menuItem, classes.addFolderMenuItem, {
          [classes.collapsedMenuItem]: collapsed,
          [classes.collapsedAddFolderMenuItem]: collapsed,
        })}
        key="newFolder"
        onClick={() => showNewFolderModal()}
        title={
          <>
            <FontAwesomeIcon icon={['fal', 'plus']} className={classes.icon} />{' '}
            {t('mail:mailNav.newFolder')}
          </>
        }
      >
        <FontAwesomeIcon
          icon={['fal', 'plus']}
          className={classNames(
            {
              [classes.icon]:
                !collapsed && mailSettings.mailListSpacing === 'full',
            },
            {
              [classes.IconCompressed]:
                !collapsed && mailSettings.mailListSpacing !== 'full',
            }
          )}
        />
        {!collapsed && t('mail:mailNav.newFolder')}
      </Menu.Item>
    </Menu>
  );
};

export default MailFolderMenu;
