// ExternalContactForm.tsx
import React, { useEffect, useState } from 'react';
import { Button, Drawer } from '@prio365/prio365-react-library';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ContactPicker from './ContactPicker';
import { ExternalProjectContactItem } from '../../../models/ProjectContacts';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import JobTitleSelect from './JobTitleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../../apps/main/rootReducer';
import { externalProjectContactsSelector } from '../../projects/selectors';
import { fetchExternalProjectContacts } from '../../projects/actions';

interface AddExternalContactDrawerProps {
  visible: boolean;
  onClose: () => void;
  onFinish: (values: any) => void;
  loading: boolean;
  form: any;
  projectId: string;
}

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

const AddExternalContactDrawer: React.FC<AddExternalContactDrawerProps> = (
  props
) => {
  const { visible, onClose, onFinish, loading, form, projectId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [hasChanges, setHasChanges] = useState(false);
  const dispatch = useDispatch();

  const contactItems: ExternalProjectContactItem[] = useSelector<
    RootReducerState,
    ExternalProjectContactItem[]
  >((state) => externalProjectContactsSelector(projectId)(state));
  const onFormChange = (_, allValues) => {
    const hasAnyChanges = allValues.contacts.every(
      (contact) => contact?.contactId !== '' && contact?.jobTitle !== ''
    );
    setHasChanges(hasAnyChanges);
  };
  useEffect(() => {
    if (visible) {
      dispatch(fetchExternalProjectContacts(projectId));
    }
  }, [visible, dispatch, projectId]);

  useEffect(() => {
    if (!visible) {
      setHasChanges(false);
    }
  }, [visible]);

  return (
    <Drawer
      title={t('contacts:projectContactsPage.addExternalContactDrawer.title')}
      visible={visible}
      closable
      maskClosable
      onClose={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          contacts: [{ contactId: '', notes: '', jobTitle: '' }],
        }}
        style={{
          overflow: 'auto',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onFinish={onFinish}
        onValuesChange={onFormChange}
      >
        <div>
          <Form.List name="contacts">
            {(fields, { add, remove }) => {
              const contacts = form.getFieldValue('contacts');
              const selectedContactIds =
                fields.length > 1
                  ? contacts
                      ?.filter((contact) => contact && contact.contactId)
                      .map((contact) => contact.contactId)
                  : [];
              return (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={key} style={{ marginBottom: '16px' }}>
                      <div style={{ display: 'flex', gap: '16px' }}>
                        <Form.Item
                          {...restField}
                          label={t(
                            'contacts:projectContactsPage.addExternalContactDrawer.searchContact'
                          )}
                          required
                          name={[name, 'contactId']}
                          fieldKey={[fieldKey, 'contactId']}
                          style={{ width: '100%' }}
                        >
                          <ContactPicker
                            contactType="ExternalContact"
                            excludedContactIds={[
                              ...contactItems.map((i) => i.contact.contactId),
                              ...selectedContactIds?.filter(
                                (id) => id !== undefined
                              ),
                            ]}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t(
                            'contacts:projectContactsPage.addExternalContactDrawer.jobTitle'
                          )}
                          required
                          name={[name, 'jobTitle']}
                          fieldKey={[fieldKey, 'jobTitle']}
                          style={{ width: '100%' }}
                        >
                          <JobTitleSelect />
                        </Form.Item>
                      </div>
                      <Form.Item
                        {...restField}
                        label={t(
                          'contacts:projectContactsPage.addExternalContactDrawer.notes'
                        )}
                        name={[name, 'notes']}
                        fieldKey={[fieldKey, 'notes']}
                      >
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 5 }}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                      {fields.length > 1 && (
                        <Button
                          className={classes.danger}
                          type="link"
                          onClick={() => remove(name)}
                          style={{ width: '100%' }}
                        >
                          {t(
                            'contacts:projectContactsPage.addExternalContactDrawer.deleteContact'
                          )}
                        </Button>
                      )}
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="default"
                      onClick={() =>
                        add({ contactId: '', notes: '', jobTitle: '' })
                      }
                      style={{ width: '100%' }}
                    >
                      {t(
                        'contacts:projectContactsPage.addExternalContactDrawer.anotherContact'
                      )}
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Form.Item style={{ marginBottom: '0px' }}>
            <Button
              type="default"
              onClick={() => {
                onClose();
                form.resetFields();
              }}
            >
              {t('common:cancel')}
            </Button>

            <Button
              htmlType="submit"
              style={{ marginInline: '8px' }}
              loading={loading}
              disabled={loading || !hasChanges}
            >
              {t(
                'contacts:projectContactsPage.addExternalContactDrawer.submit'
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default AddExternalContactDrawer;
