import { combineReducers } from 'redux';
import contacts, * as fromContacts from './contacts';
import drawer, * as fromDrawer from './drawer';
import { Contact, ExternalContact } from '../../../models/Contact';
import lastContactsLocation, * as fromLastContactsLocation from './lastContactsLocation';
import { ContactId } from '../../../models/Types';

export interface ContactsReducerState {
  contacts: fromContacts.ContactsState;
  drawer: fromDrawer.ContactsDrawerState;
  lastContactsLocation: string;
}

export default combineReducers<ContactsReducerState>({
  contacts,
  drawer,
  lastContactsLocation,
});

export const getOffset: (state: any) => string | null = (state) =>
  fromContacts.getOffset(state.contacts);

export const getLastSync: (state: ContactsReducerState) => string = (state) =>
  fromContacts.getLastSync(state.contacts);

export const getAllContacts: (state: any) => Array<Contact> = (state) =>
  fromContacts.getAllContacts(state.contacts);
export const getContact: (state: any, contactId: string) => Contact = (
  state,
  contactId
) => fromContacts.getContact(state.contacts, contactId);
export const getContactRedirect: (state: any, temporaryId: string) => string = (
  state,
  temporaryId
) => fromContacts.getRedirect(state.contacts, temporaryId);
export const getContactsIsFetching: (state: any) => boolean = (state) =>
  fromContacts.getIsFetching(state.contacts);
export const getContactsHasError: (state: any) => boolean = (state) =>
  fromContacts.getHasError(state.contacts);
export const getContactsErrorMessage: (state: any) => string = (state) =>
  fromContacts.getErrorMessage(state.contacts);

export const getContactsByCompanyId: (
  state: any,
  companyId: string
) => Array<ExternalContact> = (state, companyId) =>
  fromContacts.getContactsByCompanyId(state.contacts, companyId);

export const getContactsByOfficeIds: (
  state: any,
  officeIds: string[]
) => Array<Contact> = (state, officeIds) =>
  fromContacts.getContactsByOfficeIds(state.contacts, officeIds);

export const getContactsByIdState: (
  state: any
) => fromContacts.ContactsByIdState = (state) =>
  fromContacts.getContactsByIdState(state.contacts);

export const getContactIdsState: (
  state: ContactsReducerState
) => ContactId[] = (state) => fromContacts.getContactIdsState(state.contacts);

export const getDrawerState: (state: any) => fromDrawer.ContactsDrawerState = (
  state: any
) => state.drawer;
export const getDrawerOpen: (state: any) => boolean = (state: any) =>
  fromDrawer.getOpen(state.drawer);

export const getLastContactsLocation: (state: any) => string = (state: any) =>
  fromLastContactsLocation.getLastContactsLocation(state.lastContactsLocation);
