import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';

import { useSelector } from 'react-redux';
import { getAbsenceOverview } from '../../../../apps/main/rootReducer';
import { AbsenceOverviewBarChart } from '../overview/AbsenceOverviewBarChart';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.old.spacing.unit(2),
    width: '100%',
  },
  mainContent: {
    paddingLeft: 15,
  },
}));

interface AbsenceInsightsChartProps {
  className?: string;
}
export const AbsenceInsightsChart: React.FC<AbsenceInsightsChartProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  //#endregion

  const absenceOverview = useSelector(getAbsenceOverview);

  //#region ------------------------------ States / Attributes / Selectors

  const ref = useRef<HTMLDivElement>(null);

  const [resizeObserver, setResizeObserver] = useState<ResizeObserver>(null);

  const [showFullText, setShowFullText] = useState<boolean>(true);
  const classes = useStyles({ showFullText });

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!resizeObserver) {
      const _resizeObserver = new ResizeObserver((entries) => {
        if (entries.length === 1) {
          const visibleWidth = entries[0].contentRect.width;
          setShowFullText(visibleWidth > 648);
        }
      });
      setResizeObserver(_resizeObserver);
    }
  }, [resizeObserver]);

  useEffect(() => {
    if (ref.current && resizeObserver) {
      resizeObserver.observe(ref.current);
    }
  }, [ref, resizeObserver]);

  useEffect(() => {
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [resizeObserver]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <AbsenceOverviewBarChart
        absenceOverview={absenceOverview as AbsenceOverview}
      />
    </div>
  );
};

export default AbsenceInsightsChart;
