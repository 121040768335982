import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import EditTimeRecordForm from './../EditTimeRecord/EditTimeRecordForm';
import { TimeRecord, TimeRecordsFilter } from '../../../../models/TimeRecord';
import { updateTimeRecord } from '../../actions';
import { Result, Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  getTimeRecord,
  getUserMe,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import {
  TimeRecordId,
  OfficeRole,
  EditTimeRecordContextType,
} from '../../../../models/Types';
import { syncGlobalProjects } from '../../../projects/actions';
import classNames from 'classnames';
import { apiUpdateTimeRecord } from '../../api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  form: {
    marginTop: theme.old.spacing.unit(4),
  },
}));

interface EditTimeRecordProps {
  className?: string;
  formClassName?: string;
  noAvatar?: boolean;
  timeRecordId: TimeRecordId;
  timeRecord?: TimeRecord;
  titleComponent?: (timeRecord: TimeRecord) => ReactNode;
  onCancel?: VoidFunction;
  onFinish?: (timeRecord: TimeRecord) => void;
  onDelete?: (timeRecord: TimeRecord) => void;
  contextType: EditTimeRecordContextType;
  officeRoles?: OfficeRole[];
  onlyOffice?: boolean;
  currentFilter?: TimeRecordsFilter;
  setOpen?: (boolean) => void;
  setSelectedTimeRecordId?: (TimeRecordId) => void;
  fetchRecords?: (filter: TimeRecordsFilter) => void;
  switchToOverview?: () => void;
}

export const EditTimeRecord: React.FC<EditTimeRecordProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    className,
    formClassName,
    timeRecordId,
    timeRecord: givenTimeRecord,
    titleComponent,
    onCancel,
    onDelete,
    onFinish,
    contextType,
    officeRoles,
    onlyOffice,
  } = props;

  const timeRecordMe: TimeRecord = useSelector<RootReducerState, TimeRecord>(
    (state) =>
      timeRecordId && contextType === 'me' && getTimeRecord(state, timeRecordId)
  );

  const [timeRecord, setTimeRecord] = useState<TimeRecord>(null);

  React.useEffect(() => {
    if (timeRecordMe) {
      setTimeRecord(timeRecordMe);
    }
  }, [timeRecordMe]);

  React.useEffect(() => {
    if (givenTimeRecord) {
      setTimeRecord(givenTimeRecord);
    }
  }, [givenTimeRecord]);

  React.useEffect(() => {
    if (contextType !== 'me') {
      dispatch(syncGlobalProjects());
    }
  }, [dispatch, contextType]);

  const userMe = useSelector(getUserMe);

  const handleFinish = async (value: TimeRecord) => {
    const { rowVersion } = timeRecord;

    await apiUpdateTimeRecord(
      { ...value, timeRecordId },
      timeRecordId,
      rowVersion,
      value.contactId === userMe.id ? 'me' : contextType,
      contextType === 'project' || contextType === 'office'
        ? timeRecord.projectId
        : undefined
    )
      .then(({ data }) => {
        dispatch(updateTimeRecord(data, timeRecordId, timeRecord));
      })
      .catch(() => {
        notification.open({
          message: t('common:error'),
          description: t('timeRecords:errorMessages.updateError'),
        });
      });
    if (onFinish) {
      onFinish({ timeRecordId, ...value });
    }
  };

  if (timeRecord) {
    return (
      <Flex.Column className={classNames(classes.root, className)}>
        {titleComponent ? titleComponent(timeRecord) : null}
        {timeRecord.invoiceId ? (
          <Typography.Text>
            {t('timeRecords:editTimeRecord.alreadyInvoicedLabel')}:{' '}
            {timeRecord.invoiceNumber}
          </Typography.Text>
        ) : null}
        <EditTimeRecordForm
          actionLabel={t('timeRecords:newTimeRecord.actionLabel')}
          cancelLabel={t('common:actions.cancel')}
          className={classNames(classes.form, formClassName)}
          onFinish={handleFinish}
          onCancel={onCancel}
          onDelete={onDelete}
          initialValues={timeRecord}
          disableForm={!!timeRecord.invoiceId}
          contextType={contextType}
          officeRoles={officeRoles}
          onlyOffice={onlyOffice}
        />
      </Flex.Column>
    );
  }
  return (
    <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
      <Result
        status="500"
        title={t('common:weAreSorry')}
        extra={<Button onClick={onCancel}>{t('common:actions.close')}</Button>}
      />
    </div>
  );
};

export default EditTimeRecord;
