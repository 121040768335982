import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';
import { Progress, Tooltip } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import { getUploadProgressAllGroups } from '../../../../apps/main/rootReducer';
import { deleteLocalUploadListProgress } from '../../actions/uploadLists';
import useDebounce from '../../../../hooks/useDebounce';

const useStyles = makePrioStyles((theme) => ({
  progressBar: {
    '& .ant-progress-text': {
      height: '100%',
    },
  },
}));

interface WidgetBarUploadManagerNavigationButtonProps {
  className?: string;
  isActive: boolean;
  disabled?: boolean;
  widgetBarIsOpen?: boolean;
  onClick: () => void;
}

export const WidgetBarUploadManagerNavigationButton: React.FC<
  WidgetBarUploadManagerNavigationButtonProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, isActive, disabled, widgetBarIsOpen, onClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { uploadedSize, size } = useSelector(getUploadProgressAllGroups);

  const progress =
    uploadedSize === size ? 100 : ((uploadedSize ?? 1) / (size ?? 1)) * 100;

  const debouncedSize = useDebounce(size, 1500);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = () => {
    if (!isActive && progress === 100) {
      dispatch(deleteLocalUploadListProgress());
    }
    onClick();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (progress === 100 && isActive && widgetBarIsOpen) {
      setTimeout(() => {
        dispatch(deleteLocalUploadListProgress());
      }, 1500);
    }
  }, [progress, isActive, widgetBarIsOpen, dispatch]);
  //#endregion

  if (debouncedSize === 0) {
    return (
      <Tooltip
        title={t('documents:widget.uploadManager.title')}
        placement="left"
      >
        <Button
          onClick={handleOnClick}
          size="large"
          iconProp={['fal', 'arrow-up-to-line']}
          disabled={disabled}
          type="link"
          className={className}
          style={{ marginTop: '8px', width: '44px' }}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={t('documents:widget.uploadManager.title')} placement="left">
      <Progress
        className={classes.progressBar}
        percent={progress}
        type="circle"
        width={40}
        style={{ marginTop: '4px', height: 36 }}
        format={() => (
          <Button
            onClick={handleOnClick}
            shape="circle"
            iconProp={['fal', 'arrow-up-to-line']}
            disabled={disabled}
            type="link"
            className={className}
            style={{ marginTop: '4px' }}
          />
        )}
      />
    </Tooltip>
  );
};

export default WidgetBarUploadManagerNavigationButton;
