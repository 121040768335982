import { TFunction } from 'i18next';
import { MailFolder } from '../../../models/MailFolder';
import { MailSettings } from '../../../models/UserSettings/MailSettings';
import { Message } from '../../../models/Message';
import { MailCategoryColorName } from '../../../models/Types';
import { SpecialMailFolders } from '../actions/types';
import { Moment } from 'moment-timezone';
import moment from 'moment';
import i18n from '../../../i18n';
import { PrioTheme } from '../../../theme/types';

export const colorFromPreset = (
  mailCategoryColorName: MailCategoryColorName,
  theme: PrioTheme
) => {
  if (!mailCategoryColorName) return theme.old.palette.chromaticPalette.grey;
  switch (mailCategoryColorName) {
    case 'Preset0':
      return '#e74856'; // Red
    case 'Preset1':
      return '#ff8c00'; // Orange
    case 'Preset2':
      return '#ffab45'; // Brown
    case 'Preset3':
      return '#fff100'; // Yellow
    case 'Preset4':
      return '#47d041'; // Green
    case 'Preset5':
      return '#30c6cc'; // Teal
    case 'Preset6':
      return '#73aa24'; // Olive
    case 'Preset7':
      return '#00bcf2'; // Blau
    case 'Preset8':
      return '#8764b8'; // Purple
    case 'Preset9':
      return '#f495bf'; // Cranberry
    case 'Preset10':
      return '#a0aeb2'; // Steel
    case 'Preset11':
      return '#004b60'; // DarkSteel
    case 'Preset12':
      return '#b1adab'; // Gray
    case 'Preset13':
      return '#5d5a58'; // DarkGrey
    case 'Preset14':
      return '#000000'; // Black
    case 'Preset15':
      return '#750b1c'; // DarkRed
    case 'Preset16':
      return '#ca5010'; // DarkOrange
    case 'Preset17':
      return '#ab620d'; // DarkBrown
    case 'Preset18':
      return '#c19c00'; // DarkYellow
    case 'Preset19':
      return '#002050'; // DarkGreen
    case 'Preset20':
      return '#004b50'; // DarkTeal
    case 'Preset21':
      return '#0b6a0b'; // DarkOlive
    case 'Preset22':
      return '#002050'; // DarkBlue
    case 'Preset23':
      return '#32145a'; // DarkPurple
    case 'Preset24':
      return '#5c005c'; // DarkCranberry
    default:
      return theme.old.palette.chromaticPalette.grey;
  }
};

export const getDisplayName: (
  mailFolder: MailFolder,
  specialMailFolders: SpecialMailFolders,
  t: TFunction
) => string = (mailFolder, specialMailFolders, t) => {
  if (!mailFolder || !specialMailFolders) return mailFolder.displayName;
  switch (mailFolder.id) {
    case specialMailFolders['inboxFolder']?.id:
      return t('mail:mailNav.folderNames.inbox');
    case specialMailFolders['sendFolder']?.id:
      return t('mail:mailNav.folderNames.sentItems');
    case specialMailFolders['draftFolder']?.id:
      return t('mail:mailNav.folderNames.drafts');
    case specialMailFolders['deletedFolder']?.id:
      return t('mail:mailNav.folderNames.deletedItems');
    default:
      return mailFolder.displayName;
  }
};

export interface MailLayoutProps {
  projectId: string;
  pathPrefix?: string;
  selectedMessages: Message[];
  onSelectionChange: (selectedIds: Message[]) => void;
  mailSettings: MailSettings;
}

export const html2text: (html: string) => string = (html) => {
  //remove code brakes and tabs
  let htmlContent = html.replace(/\n/g, '');
  htmlContent = htmlContent.replace(/\t/g, '');
  htmlContent = htmlContent.replace(/<!--(.|\s)*?-->/g, '');
  htmlContent = htmlContent.replace(/<head(.|\s)*?head>/g, '');
  //keep html brakes and tabs
  htmlContent = htmlContent.replace(/<\/td>/g, '\t');
  htmlContent = htmlContent.replace(/<\/table>/g, '\n');
  htmlContent = htmlContent.replace(/<\/tr>/g, '\n');
  htmlContent = htmlContent.replace(/<\/p>/g, '\n');
  htmlContent = htmlContent.replace(/<\/div>/g, '\n');
  htmlContent = htmlContent.replace(/<\/h(\D)?>/g, '\n');
  htmlContent = htmlContent.replace(/<br>/g, '\n');
  htmlContent = htmlContent.replace(/<br( )*\/>/g, '\n');

  //parse html into text

  var tag = document.createElement('div');
  tag.innerHTML = htmlContent;
  return tag.innerText;
};

export const hexToHSL = (
  hexString: string,
  options?: { hFixed?: number; sFixed?: number; lFixed?: number }
) => {
  // Convert hex to RGB first
  let r = '',
    g = '',
    b = '';
  if (hexString.length === 4) {
    r = '0x' + hexString[1] + hexString[1];
    g = '0x' + hexString[2] + hexString[2];
    b = '0x' + hexString[3] + hexString[3];
  } else if (hexString.length === 7) {
    r = '0x' + hexString[1] + hexString[2];
    g = '0x' + hexString[3] + hexString[4];
    b = '0x' + hexString[5] + hexString[6];
  }
  // Then to HSL
  const rNumber = Number(r) / 255;
  const gNumber = Number(g) / 255;
  const bNumber = Number(b) / 255;
  let cmin = Math.min(rNumber, gNumber, bNumber),
    cmax = Math.max(rNumber, gNumber, bNumber),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === rNumber) {
    h = ((gNumber - bNumber) / delta) % 6;
  } else if (cmax === gNumber) {
    h = (bNumber - rNumber) / delta + 2;
  } else {
    h = (rNumber - gNumber) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return (
    'hsl(' +
    (options?.hFixed ?? h) +
    ',' +
    (options?.sFixed ?? s) +
    '%,' +
    (options?.lFixed ?? l) +
    '%)'
  );
};

/**
 * Adds separators to the mail list based on the current, next date and today's date.
 * @param currentDateTime The current date time. Should be null if it is the first item in the list.
 * @param nextDateTime The next date time.
 * @returns The separator string or null if no separator should be added.
 */
export const addMailListSeparators = (
  currentDateTime: Moment | undefined,
  nextDateTime: Moment
) => {
  const today = moment();

  if (nextDateTime.isSame(today, 'day')) {
    if (!currentDateTime) {
      return i18n.t('mail:mailSelectionList.separators.today');
    }
    return null;
  } else if (nextDateTime.isSame(today.clone().subtract(1, 'days'), 'day')) {
    if (currentDateTime) {
      if (!nextDateTime.isSame(currentDateTime, 'day')) {
        return i18n.t('mail:mailSelectionList.separators.yesterday');
      }
      return null;
    }
    return i18n.t('mail:mailSelectionList.separators.yesterday');
  } else if (nextDateTime.isSame(today, 'week')) {
    if (currentDateTime) {
      if (currentDateTime.isAfter(nextDateTime, 'days')) {
        return nextDateTime.format('dddd, DD. MMMM');
      }
      return null;
    }
    return nextDateTime.format('dddd, DD. MMMM');
  } else if (nextDateTime.isSame(today.clone().subtract(1, 'weeks'), 'week')) {
    if (currentDateTime) {
      if (
        currentDateTime.isAfter(
          today.clone().subtract(1, 'weeks').endOf('week'),
          'days'
        ) ||
        currentDateTime.isSame(today.clone().subtract(1, 'days'), 'day')
      ) {
        return i18n.t('mail:mailSelectionList.separators.lastWeek');
      }
      return null;
    }
    return i18n.t('mail:mailSelectionList.separators.lastWeek');
  } else if (nextDateTime.isSame(today, 'month')) {
    if (currentDateTime) {
      if (
        currentDateTime.isAfter(
          today.clone().subtract(2, 'weeks').endOf('week'),
          'days'
        )
      ) {
        return i18n.t('mail:mailSelectionList.separators.thisMonth');
      }
      return null;
    }
    return i18n.t('mail:mailSelectionList.separators.thisMonth');
  } else if (
    nextDateTime.isSame(today.clone().subtract(1, 'months'), 'month')
  ) {
    if (currentDateTime) {
      if (
        currentDateTime.isAfter(
          today.clone().subtract(2, 'weeks').endOf('week'),
          'days'
        )
      ) {
        return i18n.t('mail:mailSelectionList.separators.lastMonth');
      }
      return null;
    }
    return i18n.t('mail:mailSelectionList.separators.lastMonth');
  } else if (nextDateTime.isSame(today, 'year')) {
    if (currentDateTime) {
      if (currentDateTime.isAfter(nextDateTime.endOf('month'), 'days')) {
        return nextDateTime.format('MMMM');
      }
      return null;
    }
    return nextDateTime.format('MMMM');
  }

  if (currentDateTime) {
    if (currentDateTime.isAfter(nextDateTime.endOf('year'), 'days')) {
      return nextDateTime.format('YYYY');
    }
    return null;
  }
  return nextDateTime.format('YYYY');
};
