import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import { Company } from '../../../models/Company';
import {
  getCompaniesByIdState,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { CompanyId, DriveItemId } from '../../../models/Types';
import classNames from 'classnames';
import { PrioSpinner } from '@prio365/prio365-react-library';
import { createSelector } from 'reselect';
import { CompaniesByIdState } from '../reducers/companies';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-select-selection-item': {
      minHeight: 50,
    },
    '&.ant-select-single > .ant-select-selector > .ant-select-selection-item': {
      display: 'flex',
      flexDirection: 'row',
    },
    '&.ant-select-single $shortNameText': {
      marginLeft: theme.old.spacing.baseSpacing,
    },
  },
  shortNameText: {
    fontSize: 14,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    color: theme.old.typography.colors.muted,
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  noneStateLabel: {
    fontWeight: 400,
  },
  completeStateLabel: {
    fontWeight: 500,
  },
  partialStateLabel: {
    color: 'grey',
  },
  noneStateOption: {
    backgroundColor: '#ffffff!important',
  },
  partialStateOption: {
    backgroundColor: '#106ebe08!important',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: '#106ebe40',
    },
  },
  completeStateOption: {
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  hideCheckIcon: {
    '& .ant-select-item-option-state': {
      visibility: 'hidden',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#f5f5f5!important',
    },
  },
  textOverflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const getCompanyState = (
  parsedCompanies: { companyId: CompanyId; numberOfUses: number }[],
  company: Company,
  numberOfSelectedDriveItems: number
) => {
  const numberOfUses = parsedCompanies.find(
    (parsedCompany) => parsedCompany.companyId === company.companyId
  )?.numberOfUses;

  if (numberOfUses === 0) return 'none';
  if (numberOfUses === numberOfSelectedDriveItems) return 'complete';
  if (numberOfUses > 0 && numberOfUses < numberOfSelectedDriveItems)
    return 'partial';
  return 'none';
};

export const companiesSelector = createSelector<
  [
    (state: RootReducerState) => CompaniesByIdState,
    (state: RootReducerState) => CompanyId[],
  ],
  Company[]
>(
  getCompaniesByIdState,
  (state) => state.companies.companies.ids,
  (byId, ids) =>
    (ids ?? [])
      .map((id) => byId[id])
      .filter((company: Company) => !company.isArchived)
      .sort((a: Company, b: Company) => {
        const shortNameCompare = a.shortName?.localeCompare(b.shortName);
        if (shortNameCompare !== 0) return shortNameCompare;
        return a.fullName?.localeCompare(b.fullName);
      })
);

interface ById {
  [key: string]: string[];
}

interface CompanyPickerProps {
  className?: string;
  parsedValues: ById;
  setParsedValues: (value: ById) => void;
  selectDriveItemIds: DriveItemId[];
  disabled?: boolean;
  fetching?: boolean;
  placeholderLabel?: string;
}

export const CompanyPicker: React.FC<CompanyPickerProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const {
    className,
    parsedValues,
    setParsedValues,
    selectDriveItemIds,
    disabled,
    fetching,
    placeholderLabel,
  } = props;

  const companiesById = useSelector(getCompaniesByIdState);

  const companies: Company[] = useSelector(companiesSelector);
  const selectedValues: CompanyId[] = useMemo(
    () =>
      Object.keys(parsedValues).sort(
        (a, b) =>
          companiesById[a]?.fullName.localeCompare(companiesById[b]?.fullName)
      ),
    [parsedValues, companiesById]
  );

  const parsedCompanies: { companyId: CompanyId; numberOfUses: number }[] =
    useMemo(
      () =>
        Object.entries(parsedValues).reduce((acc, [key, value]) => {
          return [
            ...acc,
            {
              companyId: key,
              numberOfUses: (value as string[]).length,
            },
          ];
        }, []),
      [parsedValues]
    );
  //#endregion

  //#region -------------------------------- State declaration
  //#endregion

  //#region -------------------------------- Methods
  const renderOption = useCallback(
    (item: {
      labelFullName: string;
      labelShortName: string;
      companyId: CompanyId;
      state: 'none' | 'partial' | 'complete';
    }) => {
      return (
        <div
          className={classNames({
            [classes.noneStateLabel]: item.state === 'none',
            [classes.partialStateLabel]: item.state === 'partial',
            [classes.completeStateLabel]: item.state === 'complete',
          })}
          title={item.labelFullName}
        >
          <div className={classes.textOverflowEllipsis}>
            {item.labelFullName}
          </div>
          <div className={classes.shortNameText}>{item.labelShortName}</div>
        </div>
      );
    },
    [classes]
  );

  //#endregion

  //#region -------------------------------- Handle methods
  const handleOnChange = (value: string[]) => {
    const initialCompanies = Object.keys(parsedValues);
    const newCompanies = value.filter(
      (company) => !initialCompanies.includes(company)
    );

    if (newCompanies.length > 0) {
      const newParsedValues = newCompanies.reduce((acc, company) => {
        return {
          ...acc,
          [company]: selectDriveItemIds,
        };
      }, parsedValues);
      setParsedValues(newParsedValues);
    }
    const removedCompanies = initialCompanies.filter(
      (company) => !value.includes(company)
    );

    if (removedCompanies.length > 0) {
      const newParsedValues = removedCompanies.reduce((acc, company) => {
        const { [company]: removed, ...rest } = acc;
        return rest;
      }, parsedValues);
      setParsedValues(newParsedValues);
    }
  };
  //#endregion

  //#region -------------------------------- Hooks
  //#endregion

  return (
    <Select
      className={classNames(classes.root, className, {
        [classes.disabledPicker]: disabled,
      })}
      mode={'multiple'}
      value={selectedValues}
      showSearch
      placeholder={placeholderLabel}
      notFoundContent={fetching ? <PrioSpinner size="small" /> : null}
      onChange={handleOnChange}
      filterOption={(input, option) =>
        option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      options={companies.map((company) => {
        const state = getCompanyState(
          parsedCompanies,
          company,
          selectDriveItemIds.length
        );
        return {
          value: company?.companyId,
          label: renderOption({
            labelFullName: company?.fullName,
            labelShortName: company?.shortName,
            companyId: company?.companyId,
            state: state,
          }),
          className: classNames(
            {
              [classes.noneStateOption]: state === 'none',
              [classes.partialStateOption]: state === 'partial',
              [classes.completeStateOption]: state === 'complete',
            },
            classes.hideCheckIcon,
            classes.hover
          ),
        };
      })}
    />
  );
};

export default CompanyPicker;
