import React from 'react';
import { Office } from '../../../models/Office';
import { OfficeId } from '../../../models/Types';

export interface OfficesContextProps {
  myOffices: Office[];
  internalOffices: Office[];
  externalOffices: Office[];
  allOffices: Office[];
  getMyOfficeById: (id: OfficeId) => Office | undefined;
  getMyOfficeByProperty: (
    property: keyof Office,
    value: string
  ) => Office | undefined;
  getInternalOfficeById: (id: OfficeId) => Office | undefined;
  getInternalOfficeByProperty: (
    property: keyof Office,
    value: string
  ) => Office | undefined;
  getExternalOfficeById: (id: OfficeId) => Office | undefined;
  getExternalOfficeByProperty: (
    property: keyof Office,
    value: string
  ) => Office | undefined;
  getOfficeById: (id: OfficeId) => Office | undefined;
  getOfficeByProperty: (
    property: keyof Office,
    value: string
  ) => Office | undefined;
}

const INITIAL_CONTACTS_CONTEXT_VALUE: OfficesContextProps = {
  myOffices: [],
  internalOffices: [],
  externalOffices: [],
  allOffices: [],
  getMyOfficeById: () => undefined,
  getMyOfficeByProperty: () => undefined,
  getInternalOfficeById: () => undefined,
  getInternalOfficeByProperty: () => undefined,
  getExternalOfficeById: () => undefined,
  getExternalOfficeByProperty: () => undefined,
  getOfficeById: () => undefined,
  getOfficeByProperty: () => undefined,
};

export const OfficesContext = React.createContext<OfficesContextProps>(
  INITIAL_CONTACTS_CONTEXT_VALUE
);
