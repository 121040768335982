import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {} from 'antd';
import { makePrioStyles } from '../../theme/utils';
import { DndEmailAttachmentDto } from '../types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';
import Flex from '../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  colorForMimeType,
  iconForMimeType,
} from '../../modules/documents/util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    padding: 10,
  },
  iconSize: {
    fontSize: 18,
  },
}));

interface EmailAttachmentDragPreviewProps {
  className?: string;
  item: DndEmailAttachmentDto;
}

export const EmailAttachmentDragPreview: React.FC<
  EmailAttachmentDragPreviewProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, item } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      alignItems="center"
      childrenGap={theme.old.spacing.baseSpacing}
      className={classNames(classes.root, className)}
    >
      {item?.selectedAttachments && item?.selectedAttachments?.length > 1 ? (
        <FontAwesomeIcon icon={['fal', 'copy']} />
      ) : (
        <div className={classes.iconSize}>
          <FontAwesomeIcon
            icon={[
              'fas',
              iconForMimeType(
                item?.draggableMessageAttachment?.attachment?.contentType
              ),
            ]}
            color={colorForMimeType(
              item?.draggableMessageAttachment?.attachment?.contentType
            )}
          />
        </div>
      )}
      <Flex.Item>
        {item?.selectedAttachments && item?.selectedAttachments?.length > 1
          ? t('common:drop.copy.multipleDriveItems', {
              amount: item?.selectedAttachments.length,
            })
          : t('common:drop.copy.driveItem', {
              name: item?.draggableMessageAttachment?.attachment?.name,
            })}
      </Flex.Item>
    </Flex.Row>
  );
};

export default EmailAttachmentDragPreview;
