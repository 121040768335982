import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, PrioSpinner } from '@prio365/prio365-react-library';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import { OfficeId } from '../../../../models/Types';
import { apiFetchPendingActions } from '../../api';
import { useNavigate } from 'react-router-dom';
import useOfficesContext from '../../../companies/hooks/useOfficesContext';
import moment from 'moment';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import { useQuery } from '@tanstack/react-query';
import { Popover } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    fontSize: theme.font.fontSize.large,
    fontWeight: theme.font.fontWeight.bold,
  },
  pendingActionItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minWidth: '101px',
    maxWidth: '101px',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    border: `1px solid ${theme.colors.base.primary.default}`,
    background: theme.colors.base.blue[20],
  },
  iconWrapperHeight2: {
    width: 64,
    height: 64,
  },
  number: {
    fontSize: 24,
    height: 38,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  type: {
    fontSize: 16,
    hyphens: 'auto',
    width: '100%',
    textAlign: 'center',
    overflow: 'hidden',
    height: '51px',
  },
  numberTypeGroup: {
    width: '100%',
  },
  rowH2: {
    marginTop: '24px',
    marginBottom: theme.spacing.small,
  },
  rowNoItems: {
    justifyContent: 'center',
  },
  pendingActionItemsRow: {
    display: 'flex',
    flex: 1,
    gap: theme.spacing.small,
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  actionButtonH1: {
    fontSize: theme.font.fontSize.small,
  },
  noPendingActionsIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.small,
    borderRadius: '100%',
    border: `1px solid ${theme.colors.base.green.default}`,
    background: theme.colors.base.green[20],
    height: 64,
    width: 64,
  },
  centerInRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  actionItemSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
    width: 140,
    marginTop: theme.spacing.regular,
  },
  iconNumber: {
    color: theme.colors.base.primary.default,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  officeLabel: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

type TimekeepingOverviewItemType =
  | 'overtimeHours'
  | 'deficitHours'
  | 'totalOvertimeHours'
  | 'monthlyClosesNotClosed';
interface PendingAction {
  icon: ReactNode;
  numberOfActions: number;
  type: TimekeepingOverviewItemType;
  linkToAction: string;
  actionLabel: string;
  iconLabel: string;
}

interface DashboardHRTimekeepingItemProps {
  className?: string;
  height?: number;
}

const generateSearchString = (
  type: TimekeepingOverviewItemType,
  officeIdsWhereOfficeHR: OfficeId[]
) => {
  const parsedOfficeIds = officeIdsWhereOfficeHR
    .map((officeId) => `'${officeId}'`)
    .join(',')
    .toString();

  const officeIdValue =
    parsedOfficeIds === '' ? '' : `Calculated.OfficeId eq ${parsedOfficeIds}`;

  let stateValue = '';
  let monthFromValue = `Data.Month ge '${moment()
    .startOf('month')
    .subtract(1, 'day')
    .format('YYYY-MM-DD')}T23:00:00Z'`;
  let monthToValue = `Data.Month le '${moment()
    .endOf('month')
    .format('YYYY-MM-DD')}T21:59:59Z'`;

  if (type === 'deficitHours') {
    stateValue = `Data.AccumulatedOvertimeHours lt '-20'`;
  }

  if (type === 'overtimeHours') {
    stateValue = `Data.AccumulatedOvertimeHours gt '50'`;
  }

  if (type === 'monthlyClosesNotClosed') {
    stateValue = `Data.EmployeeConfirmation eq 'false'`;
    monthFromValue = '';
    monthToValue = '';
  }

  return `
  ${officeIdValue} 
  ${stateValue ? `%26 ${stateValue}` : ''}
  ${monthFromValue ? `%26 ${monthFromValue}` : ''}
  ${
    monthToValue ? `%26 ${monthToValue}` : ''
  }%26 Calculated.EmployeeIsArchived eq 'false'
  `;
};

const generateLinkToActions = (
  type: TimekeepingOverviewItemType,
  userOfficeId: OfficeId,
  officeIdsWhereOfficeHR: OfficeId[]
) => {
  const officeId = officeIdsWhereOfficeHR.find(
    (officeId) => officeId === userOfficeId
  )
    ? userOfficeId
    : officeIdsWhereOfficeHR[0];

  const path = `/module/prio/hr/office/${officeId}/timekeeping/monthlyClose`;
  let query = `?s=${generateSearchString(type, officeIdsWhereOfficeHR)}`;
  let pickedFilters = '';

  if (type === 'overtimeHours' || type === 'deficitHours') {
    pickedFilters = `&pickedFilters=Data.AccumulatedOvertimeHours`;
  }

  return `${path}${query}${pickedFilters}`;
};

const useHRDetails = (
  searchType,
  searchString: string,
  useSum,
  shortLoading
) => {
  const threshold = shortLoading ? 10 : 1000;
  const forceResults = shortLoading ? false : true;
  const { data, isLoading } = useQuery(
    [searchType, searchString, threshold, forceResults],
    () =>
      apiFetchPendingActions(
        searchType ?? '',
        searchString ?? '',
        threshold,
        forceResults
      ),
    {
      staleTime: 1000 * 60 * 60 * 20, // 20 hours
    }
  );
  const numberOfEntities = data?.data?.totalItems;
  const totalOvertimeHours = data?.data?.items?.reduce(
    (acc: number, item: any) => {
      return acc + item?.data['accumulatedOvertimeHours'];
    },
    0
  );
  const _totalOvertimeHoursRounded = Math.floor(totalOvertimeHours as number);
  const totalOvertimeHoursRounded: number | undefined = isLoading
    ? undefined
    : _totalOvertimeHoursRounded;

  const value = useSum ? totalOvertimeHoursRounded : numberOfEntities;

  return { value };
};

export const DashboardHRTimekeepingItem: React.FC<
  DashboardHRTimekeepingItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, height = 2 } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const { getOfficeById } = useOfficesContext();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);

  const employeeId = useMemo(() => {
    return userMe?.id;
  }, [userMe?.id]);

  const { getContactById } = useContactsContext();
  const officeId = getContactById(employeeId)?.officeId;

  const officeIdsWhereOfficeHR: OfficeId[] = useMemo(
    () =>
      Object.entries(userMe?.prioData?.officeRoles)
        .filter(([_, roles]) => {
          return roles.includes('officeHR');
        })
        .map(([officeId, _]) => officeId),
    [userMe?.prioData?.officeRoles]
  );

  const offices: OfficeId[] = useMemo(
    () =>
      officeIdsWhereOfficeHR.map((officeId) => getOfficeById(officeId)?.name),
    [officeIdsWhereOfficeHR, getOfficeById]
  );

  const { value: numberOfPeopleWithOvertimeHours } = useHRDetails(
    'monthlyCloses',
    generateSearchString('overtimeHours', officeIdsWhereOfficeHR),
    false,
    true
  );

  const { value: numberOfPeopleWithDeficitHours } = useHRDetails(
    'monthlyCloses',
    generateSearchString('deficitHours', officeIdsWhereOfficeHR),
    false,
    true
  );

  const { value: totalOvertimeHours } = useHRDetails(
    'monthlyCloses',
    generateSearchString('totalOvertimeHours', officeIdsWhereOfficeHR),
    true,
    false
  );

  const { value: numberOfMonthlyClosesNotClosed } = useHRDetails(
    'monthlyCloses',
    generateSearchString('monthlyClosesNotClosed', officeIdsWhereOfficeHR),
    false,
    true
  );

  const pendingActions: PendingAction[] = useMemo(
    () => [
      {
        actionLabel: t(`dashboard:hrTimekeepingOverview.showDetails`),
        iconLabel: t(`dashboard:hrTimekeepingOverview.employees`),
        icon: <div className={classes.iconNumber}>{'< -20h'}</div>,
        numberOfActions: numberOfPeopleWithDeficitHours,
        type: 'deficitHours',
        linkToAction: generateLinkToActions(
          'deficitHours',
          officeId,
          officeIdsWhereOfficeHR
        ),
      } as PendingAction,
      {
        actionLabel: t(`dashboard:hrTimekeepingOverview.showDetails`),
        iconLabel: t(`dashboard:hrTimekeepingOverview.employees`),
        icon: <div className={classes.iconNumber}>{'> 50h'}</div>,
        numberOfActions: numberOfPeopleWithOvertimeHours,
        type: 'overtimeHours',
        linkToAction: generateLinkToActions(
          'overtimeHours',
          officeId,
          officeIdsWhereOfficeHR
        ),
      } as PendingAction,
      {
        actionLabel: t(`dashboard:hrTimekeepingOverview.showDetails`),
        iconLabel: t(`dashboard:hrTimekeepingOverview.totalOvertimeHours`),
        icon: (
          <FontAwesomeIcon
            icon={['fal', 'business-time']}
            size={'xl'}
            color={theme.colors.base.primary.default}
          />
        ),
        numberOfActions: totalOvertimeHours,
        type: 'totalOvertimeHours',
        linkToAction: generateLinkToActions(
          'totalOvertimeHours',
          officeId,
          officeIdsWhereOfficeHR
        ),
      } as PendingAction,
      {
        actionLabel: t(`dashboard:hrTimekeepingOverview.showDetails`),
        iconLabel: t(`dashboard:hrTimekeepingOverview.monthlyClosesNotClosed`),
        icon: (
          <FontAwesomeIcon
            icon={['fal', 'calendar-clock']}
            size={'xl'}
            color={theme.colors.base.primary.default}
          />
        ),
        numberOfActions: numberOfMonthlyClosesNotClosed,
        type: 'monthlyClosesNotClosed',
        linkToAction: generateLinkToActions(
          'monthlyClosesNotClosed',
          officeId,
          officeIdsWhereOfficeHR
        ),
      } as PendingAction,
    ],
    [
      t,
      theme,
      classes,
      numberOfMonthlyClosesNotClosed,
      totalOvertimeHours,
      numberOfPeopleWithDeficitHours,
      numberOfPeopleWithOvertimeHours,
      officeIdsWhereOfficeHR,
      officeId,
    ]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Components
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row
        childrenGap={theme.spacing.small}
        marginBottom={8}
        alignItems="center"
      >
        <Flex.Item>
          <div className={classes.header}>
            {t('dashboard:hrTimekeepingOverview.title')}
          </div>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Popover
            trigger="click"
            align={{ offset: [91, 510] }}
            title={t('dashboard:hrTimekeepingOverview.infoTitle')}
            content={
              <div style={{ width: 527 }}>
                <ol>
                  <li>
                    {t('dashboard:hrTimekeepingOverview.flexkontoLess20')}
                  </li>
                  <li>
                    {t('dashboard:hrTimekeepingOverview.flexkontoMore50')}
                  </li>
                  <li>{t('dashboard:hrTimekeepingOverview.overtime')}</li>
                  <li>
                    {t(
                      'dashboard:hrTimekeepingOverview.notClosedMonthlyCloses'
                    )}
                  </li>
                </ol>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('dashboard:hrTimekeepingOverview.action', {
                      color: theme.colors.base.primary.default,
                    }),
                  }}
                ></div>
                <span style={{ color: 'red' }}></span>
              </div>
            }
          >
            <FontAwesomeIcon
              icon={['fal', 'circle-info']}
              color={theme.colors.application.typography.muted}
              className={classes.pointer}
            />
          </Popover>
        </Flex.Item>
        <Flex.Item className={classes.overflowHidden}>
          <div
            title={offices.join(' - ')}
            className={classNames(classes.ellipsis, classes.officeLabel)}
          >
            {offices.join(' - ')}
          </div>
        </Flex.Item>
      </Flex.Row>
      <PendingActionItemsRow height={height} pendingActions={pendingActions} />
    </Flex.Column>
  );
};

export default DashboardHRTimekeepingItem;

//#region ------------------------------ PendingActionItems
interface PendingActionItemProps extends PendingAction {
  className?: string;
}

const PendingActionItemH2: React.FC<PendingActionItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    icon,
    numberOfActions,
    linkToAction,
    actionLabel,
    iconLabel,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onButtonClick = () => {
    navigate(linkToAction);
  };
  //#endregion

  return (
    <div
      className={classNames(classes.pendingActionItem, className)}
      style={{
        gap: theme.spacing.regular,
      }}
    >
      <div
        className={classNames(classes.iconWrapper, {
          [classes.iconWrapperHeight2]: true,
        })}
      >
        {icon}
      </div>
      <Flex.Column alignItems="center" className={classes.numberTypeGroup}>
        <div id="number" className={classNames(classes.number)}>
          {numberOfActions ?? <PrioSpinner size="small" />}
        </div>
        <div className={classes.type} title={iconLabel}>
          {iconLabel}
        </div>
      </Flex.Column>
      <Button type="link" onClick={onButtonClick}>
        {actionLabel}
      </Button>
    </div>
  );
};

const PendingActionItemH1: React.FC<PendingActionItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, numberOfActions, linkToAction, actionLabel, iconLabel } =
    props;
  const classes = useStyles();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onButtonClick = () => {
    navigate(linkToAction);
  };
  //#endregion

  return (
    <div className={classNames(classes.pendingActionItem, className)}>
      <Flex.Column
        alignItems="center"
        className={classes.numberTypeGroup}
        childrenGap={4}
      >
        <div style={{ fontSize: 18 }}>{numberOfActions}</div>
        <div
          className={classes.type}
          style={{ fontSize: 15 }}
          title={iconLabel}
        >
          {iconLabel}
        </div>
      </Flex.Column>
      <Button
        className={classes.actionButtonH1}
        type="link"
        onClick={onButtonClick}
      >
        {actionLabel}
      </Button>
    </div>
  );
};

//#endregion

//#region ------------------------------ PendingActionItemsRow
interface PendingActionItemsRowProps {
  className?: string;
  height: number;
  pendingActions: PendingAction[];
}

const PendingActionItemsRow: React.FC<PendingActionItemsRowProps> = (props) => {
  //#region ------------------------------ Defaults
  const { height, pendingActions } = props;
  const classes = useStyles();
  //#endregion

  return (
    <div
      className={classNames(
        { [classes.rowH2]: height === 2 },
        { [classes.rowNoItems]: pendingActions.length === 0 },
        classes.pendingActionItemsRow
      )}
    >
      {pendingActions.map((pendingAction, index) => {
        if (height === 1) {
          return (
            <PendingActionItemH1
              key={index}
              icon={pendingAction.icon}
              numberOfActions={pendingAction.numberOfActions}
              type={pendingAction.type}
              linkToAction={pendingAction.linkToAction}
              actionLabel={pendingAction.actionLabel}
              iconLabel={pendingAction.iconLabel}
            />
          );
        }
        return (
          <PendingActionItemH2
            key={index}
            icon={pendingAction.icon}
            numberOfActions={pendingAction.numberOfActions}
            type={pendingAction.type}
            linkToAction={pendingAction.linkToAction}
            actionLabel={pendingAction.actionLabel}
            iconLabel={pendingAction.iconLabel}
          />
        );
      })}
    </div>
  );
};
//#endregion
