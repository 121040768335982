import {
  setMailMenuNavigationStateProjects,
  setMailListNavigationStateProjects,
} from '../projects/mailNavigation';
import {
  setMailMenuNavigationStateMe,
  setMailListNavigationStateMe,
} from '../me/mailNavigationMe';
import { MailMenuNavigationState } from '../types';

export const setMailListNavigationState = (
  activeMessageId: string,
  projectId: string
) => {
  if (projectId === 'me') {
    return setMailListNavigationStateMe(activeMessageId);
  }
  return setMailListNavigationStateProjects(activeMessageId);
};

export const setMailMenuNavigationState = (
  menuState: MailMenuNavigationState,
  projectId: string
) => {
  if (projectId === 'me') {
    return setMailMenuNavigationStateMe(menuState);
  }
  return setMailMenuNavigationStateProjects(menuState);
};
