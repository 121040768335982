import { combineReducers } from 'redux';
import notifications, * as fromNotifications from './notifications';
import drawer, * as fromDrawer from './drawer';
import { Notification } from '../../../models/Notification';
import { NotificationId } from '../../../models/Types';

export interface NotificationsReducerState {
  notifications: fromNotifications.NotificationsState;
  drawer: fromDrawer.NotificationsDrawerState;
}

export default combineReducers<NotificationsReducerState>({
  notifications,
  drawer,
});

export const getAllNotifications: (
  state: NotificationsReducerState
) => Array<Notification> = (state) =>
    fromNotifications.getAllNotifications(state.notifications);
export const getNotification: (
  state: NotificationsReducerState,
  notificationId: NotificationId
) => Notification = (state, notificationId) =>
    fromNotifications.getNotification(state.notifications, notificationId);
export const getNotificationsIsFetching: (
  state: NotificationsReducerState
) => boolean = (state) => fromNotifications.getIsFetching(state.notifications);
export const getNotificationsHasError: (
  state: NotificationsReducerState
) => boolean = (state) => fromNotifications.getHasError(state.notifications);
export const getNotificationsErrorMessage: (
  state: NotificationsReducerState
) => string = (state) => fromNotifications.getErrorMessage(state.notifications);

export const getNotificationsByIdState: (
  state: NotificationsReducerState
) => fromNotifications.NotificationsByIdState = (state) =>
    fromNotifications.getNotificationsByIdState(state.notifications);
export const getNotificationsIds: (
  state: NotificationsReducerState
) => NotificationId[] = (state) =>
    fromNotifications.getNotificationsIds(state.notifications);

export const getDrawerState: (
  state: NotificationsReducerState
) => fromDrawer.NotificationsDrawerState = (state: NotificationsReducerState) =>
    state.drawer;
export const getDrawerOpen: (state: NotificationsReducerState) => boolean = (
  state: NotificationsReducerState
) => fromDrawer.getOpen(state.drawer);
