import React from 'react';
import { Message, MessageAttachment } from '../../../../models/Message';
import { sanitizeHTML } from '../../../../util';
import ReactHtmlParser from 'react-html-parser';

import MessageOverview from './MessageOverview';
import EventOverview from './EventOverview';
import '../../../../apps/main/App.less';

const inlineImageSrcRegex = /src="cid(:|%3Ad).*?"/g;

interface MessagePrintTemplateProps {
  message: Message;
  messageAttachments: MessageAttachment[];
}

export const MessagePrintTemplate: React.FC<MessagePrintTemplateProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { message, messageAttachments } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const _messageAttachments = messageAttachments.filter(
    (attachment) => !attachment.isInline
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      style={{
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        fontSize: '15px',
        fontWeight: 400,
      }}
    >
      <h3
        style={{
          fontSize: 18,
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          color: 'rgba(0, 0, 0, 0.85)',
          lineHeight: '1.35',
          fontWeight: 600,
        }}
      >
        <span>{message.subject}</span>
      </h3>
      <div style={{ margin: '16px 0', borderTop: '1px solid #dde0e2' }} />
      {!message.event ? (
        <MessageOverview
          message={message}
          messageAttachments={_messageAttachments}
        />
      ) : (
        <EventOverview
          message={message}
          messageAttachments={_messageAttachments}
        />
      )}
      <div style={{ margin: '16px 0', borderTop: '1px solid #dde0e2' }} />
      <div>
        {ReactHtmlParser(
          sanitizeHTML(message.body.content.replace(inlineImageSrcRegex, ''))
        )}
      </div>
    </div>
  );
};

export default MessagePrintTemplate;
