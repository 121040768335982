import React from 'react';
import { Skeleton } from 'antd';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  item: {
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(3)}px`,
  },
  widthAndMargin: {
    width: 'calc(100% - 26px)',
    margin: '2px',
    overflow: 'hidden',
    height: '20px',
  },

  widthAndMarginElement1: {
    width: '70%',
    margin: '2px',
    overflow: 'hidden',
    height: '20px',
  },

  widthAndMarginElement2: {
    width: 'calc(30% - 8px)',
    marginLeft: '4px',
    overflow: 'hidden',
    height: '20px',
  },

  widthAndMarginAvatar: {
    width: 'calc(100% - 32px)',
    margin: '2px',
    overflow: 'hidden',
    height: '36px',
    marginTop: '4px',
  },
  checkbox: {
    border: '1px solid rgb(0, 0, 0, 0.2)',
    borderRadius: '2px',

    width: '15px',
    height: '15px',
    marginTop: '74px',
    marginRight: '18px',
    marginLeft: '23px',

    float: 'left',
  },
}));

export const MailListSkeleton: React.FC = (props) => {
  const classes = useStyles();
  return (
    <div>
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <div style={{ height: '100px' }} key={index}>
            <span className={classes.checkbox}></span>
            <span
              style={{
                float: 'left',
                marginTop: '20px',
                width: 'calc(100% - 56px)',
              }}
            >
              <Skeleton.Input
                className={classes.widthAndMargin}
                style={{ width: 'calc(100%-56px-40px)', height: '16px' }}
              />
              <div className={classes.widthAndMargin}>
                <Skeleton.Input
                  className={classes.widthAndMarginElement1}
                  style={{
                    width: '100%',
                    height: '16px',
                    marginLeft: '0px',
                  }}
                />
                <Skeleton.Input
                  className={classes.widthAndMarginElement2}
                  style={{ width: '100%', height: '20px' }}
                />
              </div>
              <Skeleton.Input
                className={classes.widthAndMargin}
                style={{
                  height: '16px',
                  // marginRight: '16px',
                  width: 'calc(100% )',
                }}
              />
              <div style={{ height: '50px', marginBottom: '5px' }}>
                <Skeleton.Avatar
                  className={classes.widthAndMarginAvatar}
                  style={{ height: '24px' }}
                  size={'small'}
                />
              </div>
            </span>
            <br />
          </div>
        ))}
    </div>
  );
};

export default MailListSkeleton;
