import { Reducer, combineReducers } from 'redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';
import {
  DateTimeString,
  DriveItemId,
  GroupId,
  PrioFileUploadStatus,
} from '../../../models/Types';
import { ReduxAction } from '../../../models/Redux';
import {
  ADD_PRIO_FILES_TO_UPLOAD_LIST,
  DELETE_LOCAL_UPLOAD_LIST_PROGRESS,
  PURGE_PRIO_FILE_UPLOAD_LIST,
  UPDATE_PRIO_FILE_UPLOAD_PROGRESS,
} from '../actions/uploadLists';
import moment from 'moment';
import { distinct } from '../../../util';
import i18n from '../../../i18n';

export interface UploadListState {
  byGroupId: ByGroupIdState;
  progressBySessionId: ProgressBySessionIdState;
}

export const initialUploadListState: UploadListState = {
  byGroupId: {},
  progressBySessionId: {},
};

export interface PrioFileList {
  sessionId: string;
  fileId: string;
  isFolder: boolean;
  name: string;
  path: string;
  size: number;
  driveItemId: DriveItemId;
  parentDriveItemId: DriveItemId;
  mimeType: string;
}

declare type UploadAction = ReduxAction & {
  groupId: GroupId;
  sessionId: string;
  fileList: PrioFileList[];
  fileId: string;
  name: string;
  path: string;
  size: number;
  uploadedSize: number;
  status: PrioFileUploadStatus;
  isFolder: boolean;
  errorString?: string;
  parentDriveItemId: DriveItemId;
  driveItemId: DriveItemId;
  mimeType: string;
};

export interface ByGroupIdState {
  [groupId: GroupId]: GroupIdUploadListState;
}

const initialByGroupIdState: ByGroupIdState = {};

export interface GroupIdUploadListState {
  byId: ByIdState;
  ids: string[];
}

const initialGroupIdUploadListState: GroupIdUploadListState = {
  byId: {},
  ids: [],
};

const byGroupId: Reducer<ByGroupIdState, UploadAction> = (
  state = initialByGroupIdState,
  action
) => {
  switch (action.type) {
    case UPDATE_PRIO_FILE_UPLOAD_PROGRESS:
    case ADD_PRIO_FILES_TO_UPLOAD_LIST: {
      return {
        ...state,
        [action.groupId]: {
          byId: byId(
            state[action.groupId]?.byId ?? initialGroupIdUploadListState.byId,
            action
          ),
          ids: ids(
            state[action.groupId]?.ids ?? initialGroupIdUploadListState.ids,
            action
          ),
        },
      };
    }
    case RESET_PRIO_CACHE: {
      return Object.keys(state).reduce((map, groupId) => {
        if (map[groupId]) {
          map[groupId] = {
            byId: byId(
              state[groupId]?.byId ?? initialGroupIdUploadListState.byId,
              action
            ),
            ids: ids(
              state[groupId]?.ids ?? initialGroupIdUploadListState.ids,
              action
            ),
          };
        }
        return map;
      }, state);
    }
    case PURGE_PRIO_FILE_UPLOAD_LIST: {
      const groupIds = Object.keys(state);
      return groupIds.reduce((map, groupId) => {
        if (map[groupId]) {
          const { byId, ids } = map[groupId];
          const _oldIds = ids.filter((id) => {
            const { lastTimeModified } = byId[id];
            return moment(lastTimeModified).isAfter(
              moment().subtract(8, 'day')
            );
          });
          map[groupId] = {
            byId: _oldIds.reduce((byIdMap, id) => {
              byIdMap[id] = byId[id];
              return byIdMap;
            }, {}),
            ids: _oldIds,
          };
        }
        return map;
      }, state);
    }
    case CLEAR_PRIO_CACHE: {
      return initialByGroupIdState;
    }
    default: {
      return state;
    }
  }
};

export interface UploadFileState {
  sessionId: string;
  fileId: string;
  name: string;
  path: string;
  size: number;
  uploadedSize: number;
  status: PrioFileUploadStatus;
  isFolder: boolean;
  parentDriveItemId: DriveItemId;
  driveItemId: DriveItemId;
  errorString?: string;
  lastTimeModified: DateTimeString;
  mimeType: string;
}

interface ByIdState {
  [path: string]: UploadFileState;
}

const byId: Reducer<ByIdState, UploadAction> = (
  state = initialGroupIdUploadListState.byId,
  action
) => {
  const { type, ...restAction } = action;
  switch (type) {
    case ADD_PRIO_FILES_TO_UPLOAD_LIST: {
      const { fileList } = restAction;
      return fileList.reduce((map, file) => {
        const {
          fileId,
          name,
          path,
          size,
          isFolder,
          parentDriveItemId,
          mimeType,
          sessionId,
          driveItemId,
        } = file;
        map[fileId] = {
          sessionId,
          fileId,
          name,
          path,
          size,
          uploadedSize: 0,
          status: 'pending',
          isFolder,
          parentDriveItemId,
          driveItemId,
          lastTimeModified: moment().toISOString(),
          mimeType,
          errorString: undefined,
        };
        return map;
      }, state ?? initialGroupIdUploadListState.byId);
    }
    case UPDATE_PRIO_FILE_UPLOAD_PROGRESS: {
      const {
        fileId,
        sessionId,
        name,
        path,
        size,
        uploadedSize,
        status,
        errorString,
        isFolder,
        parentDriveItemId,
        driveItemId,
        mimeType,
      } = restAction;
      return {
        ...state,
        [fileId]: {
          sessionId,
          fileId,
          name,
          path,
          size,
          uploadedSize,
          status,
          isFolder,
          errorString,
          parentDriveItemId,
          driveItemId,
          lastTimeModified: moment().toISOString(),
          mimeType,
        },
      };
    }
    case RESET_PRIO_CACHE: {
      return Object.keys(state).reduce((map, fileId) => {
        const file = state[fileId];
        if (
          file?.status === 'pending' ||
          (!file?.isFolder && file?.uploadedSize < 100)
        ) {
          map[fileId] = {
            ...file,
            status: 'error',
            uploadedSize: 100,
            errorString: i18n.t('common:graphError.closeBrowserDuringUpload'),
          };
        }
        return map;
      }, state);
    }
    case CLEAR_PRIO_CACHE: {
      return initialGroupIdUploadListState.byId;
    }
    default: {
      return state;
    }
  }
};

const ids: Reducer<string[], UploadAction> = (
  state = initialGroupIdUploadListState.ids,
  action
) => {
  switch (action.type) {
    case ADD_PRIO_FILES_TO_UPLOAD_LIST: {
      const { fileList } = action;
      return distinct([...(state ?? []), ...fileList.map((f) => f.fileId)]);
    }
    case UPDATE_PRIO_FILE_UPLOAD_PROGRESS: {
      const { fileId } = action;
      if (state.includes(fileId)) {
        return state;
      }
      return distinct([...(state ?? []), fileId]);
    }
    case CLEAR_PRIO_CACHE: {
      return initialGroupIdUploadListState.ids;
    }
    default: {
      return state;
    }
  }
};

export interface ProgressBySessionIdState {
  [sessionId: string]: ProgressByFileIdState;
}

export interface ProgressByFileIdState {
  [fileId: string]: {
    size: number;
    uploadedSize: number;
  };
}

const progressBySessionId: Reducer<ProgressBySessionIdState, UploadAction> = (
  state = initialUploadListState.progressBySessionId,
  action
) => {
  switch (action.type) {
    case UPDATE_PRIO_FILE_UPLOAD_PROGRESS: {
      const { sessionId, fileId, uploadedSize, size, isFolder } = action;
      if (isFolder) {
        return state;
      }
      return {
        ...state,
        [sessionId]: {
          ...(state[sessionId] ?? {}),
          [fileId]: {
            size,
            uploadedSize,
          },
        },
      };
    }

    case ADD_PRIO_FILES_TO_UPLOAD_LIST: {
      let _initialState = state;
      const isPending = Object.keys(state).some((sessionId) =>
        Object.keys(state[sessionId]).some((fileId) => {
          const { uploadedSize, size } = state[sessionId][fileId];
          return uploadedSize < size;
        })
      );
      if (!isPending) {
        _initialState = { ...initialUploadListState.progressBySessionId };
      }
      const { fileList } = action;
      return fileList.reduce((map, file) => {
        const { sessionId, fileId, size } = file;
        map[sessionId] = {
          ...(map[sessionId] ?? {}),
          [fileId]: {
            size,
            uploadedSize: 0,
          },
        };
        return map;
      }, _initialState);
    }

    case DELETE_LOCAL_UPLOAD_LIST_PROGRESS:
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialUploadListState.progressBySessionId;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<UploadListState>({
  byGroupId,
  progressBySessionId,
});

export const getUploadListState: (
  state: UploadListState,
  groupId: GroupId
) => GroupIdUploadListState = ({ byGroupId }, groupId) =>
  byGroupId[groupId] ?? initialGroupIdUploadListState;

export const getUploadDocumentById: (
  state: UploadListState,
  groupId: GroupId,
  fileId: string
) => UploadFileState = (state, groupId, fileId) => {
  const { byId } = getUploadListState(state, groupId);
  return byId[fileId];
};

export const getUploadList: (
  state: UploadListState,
  groupId: GroupId
) => UploadFileState[] = (state, groupId) => {
  const { byId, ids } = getUploadListState(state, groupId);
  return ids.map((id) => byId[id]);
};

export const getUploadProgressAllGroups: (state: UploadListState) => {
  uploadedSize: number;
  size: number;
} = ({ progressBySessionId }) => {
  const sessionIds = Object.keys(progressBySessionId);
  if (sessionIds.length === 0) {
    return {
      uploadedSize: 0,
      size: 0,
    };
  }
  const sizeSum = sessionIds.reduce((sum, sessionId) => {
    const progressByFileId = progressBySessionId[sessionId];
    const fileIds = Object.keys(progressByFileId);
    if (fileIds.length === 0) {
      return sum;
    }
    const sizeSum = fileIds.reduce((sum, fileId) => {
      const { size } = progressByFileId[fileId];
      return sum + size;
    }, 0);
    return sum + sizeSum;
  }, 0);

  const uploadedSizeSum = sessionIds.reduce((sum, sessionId) => {
    const progressByFileId = progressBySessionId[sessionId];
    const fileIds = Object.keys(progressByFileId);
    if (fileIds.length === 0) {
      return sum;
    }
    const uploadedSizeSum = fileIds.reduce((sum, fileId) => {
      const { uploadedSize } = progressByFileId[fileId];
      return sum + uploadedSize;
    }, 0);
    return sum + uploadedSizeSum;
  }, 0);
  return {
    uploadedSize: uploadedSizeSum,
    size: sizeSum,
  };
};
