import {
  CalendarEvent,
  DaysOfWeekType,
  Location,
} from '../../../models/Calendar';
import moment from 'moment-timezone';
import i18n from '../../../i18n';

const mapRecurrencePatternIndex = (
  index: 'First' | 'Second' | 'Third' | 'Fourth' | 'Last'
) => {
  switch (index) {
    case 'First': {
      return i18n.t('calendar:util.eventMessageReadableTime.index.first');
    }
    case 'Second': {
      return i18n.t('calendar:util.eventMessageReadableTime.index.second');
    }
    case 'Third': {
      return i18n.t('calendar:util.eventMessageReadableTime.index.third');
    }
    case 'Fourth': {
      return i18n.t('calendar:util.eventMessageReadableTime.index.fourth');
    }
    case 'Last': {
      return i18n.t('calendar:util.eventMessageReadableTime.index.last');
    }
  }
};

const parseDayOfWeekToNumber = (dayOfWeek: DaysOfWeekType) => {
  switch (dayOfWeek) {
    case 'Monday':
      return 1;
    case 'Tuesday':
      return 2;
    case 'Wednesday':
      return 3;
    case 'Thursday':
      return 4;
    case 'Friday':
      return 5;
    case 'Saturday':
      return 6;
    case 'Sunday':
      return 0;
  }
};

export const readableMessageEventTime: (event: CalendarEvent) => string = (
  event
) => {
  const start = event?.start;
  const end = event?.end;
  const isAllDay = event?.isAllDay;
  const recurrence = event?.recurrence;
  if (!event?.id) {
    return '-';
  }

  const tz = moment.tz.guess();
  const startTime = moment.utc(start.dateTime).tz(tz);
  const endTime = moment.utc(end.dateTime).tz(tz);
  if (recurrence) {
    const timeRange = i18n.t(
      `calendar:util.eventMessageReadableTime.timeRangeRecurrence.${
        !startTime.isSame(endTime, 'day') ? 'long' : 'short'
      }`,
      {
        startTime: startTime.format('HH:mm'),
        ...(startTime.isSame(endTime, 'day')
          ? { endTime: endTime.format('HH:mm') }
          : {}),
      }
    );
    const startDate = moment
      .utc(recurrence.range.startDate)
      .tz(tz)
      .format('dd, DD.MM.YYYY');
    const endDate =
      '0001-01-01' === recurrence.range.endDate
        ? ''
        : i18n.t('calendar:util.eventMessageReadableTime.endDate', {
            endDate: moment
              .utc(recurrence.range.endDate)
              .tz(tz)
              .format('dd, DD.MM.YYYY'),
          });
    const daysOfWeek = (recurrence.pattern.daysOfWeek ?? [])
      .map((date) =>
        moment().isoWeekday(parseDayOfWeekToNumber(date)).format('dddd')
      )
      .join(', ');
    const interval = recurrence.pattern.interval;
    const isLargeInterval = recurrence.pattern.interval > 1;
    const dayOfMonth = recurrence.pattern.dayOfMonth;
    const month = moment().month(recurrence.pattern.month).format('MMMM');
    const index = mapRecurrencePatternIndex(recurrence.pattern.index);
    const type = recurrence.pattern.type.toLowerCase();
    return i18n.t(
      `calendar:util.eventMessageReadableTime.${type}.${
        isLargeInterval ? 'largeInterval' : 'smallInterval'
      }`,
      {
        timeRange,
        startDate,
        endDate,
        ...(type === 'weekly' ||
        type === 'relativemonthly' ||
        type === 'relativeyearly'
          ? { daysOfWeek }
          : {}),
        ...(type === 'absolutemonthly' || type === 'absoluteyearly'
          ? { dayOfMonth }
          : {}),
        ...(type === 'relativemonthly' || type === 'relativeyearly'
          ? { index }
          : {}),
        ...(type === 'absoluteyearly' || type === 'relativeyearly'
          ? { month }
          : {}),
        ...(isLargeInterval ? { interval } : {}),
      }
    );
  }

  if (startTime.isSame(endTime, 'day')) {
    return `${startTime.format('dd, DD.MM.YYYY HH:mm')} - ${endTime.format(
      'HH:mm'
    )}`;
  }
  if (isAllDay && endTime.diff(startTime) === 86400000) {
    return `${startTime.format('dd, DD.MM.YYYY')} - ${i18n.t(
      'calendar:form.labels.allDay'
    )}`;
  }
  if (isAllDay && endTime.diff(startTime) > 86400000) {
    return `${startTime.format('dd, DD.MM.YYYY')} ${i18n.t(
      'common:to'
    )} ${endTime.subtract(1, 'days').format('dd, DD.MM.YYYY')} - ${i18n.t(
      'calendar:form.labels.allDay'
    )}`;
  }
  return `${startTime.format('dd, DD.MM.YYYY HH:mm')} ${i18n.t(
    'common:to'
  )} ${endTime.format('dd, DD.MM.YYYY HH:mm')}`;
};

export const parseLocation: (
  location: Location,
  locations?: Location[]
) => string = (location, locations) => {
  if (location.displayName) {
    return location.displayName;
  } else if (locations) {
    if (locations.length < 1) {
      return '-';
    }
    return locations.map((item) => item.displayName).join(', ');
  }
  return '-';
};
