import React, { useState } from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import ContactsTabs from './ContactsTabs';
import ContactDetailsPage from './ContactDetailsPage';
import CompanyDetailsPage from '../../companies/components/CompanyDetailsPage';
import { ContactTabs } from '../actions/drawer';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../../theme/utils';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

interface ContactsTaskPaneProps {}

const useStyles = makePrioStyles((theme) => ({
  contactTabs: {
    height: '100vh',
  },
}));
export const ContactsTaskPane: React.FC<ContactsTaskPaneProps> = (props) => {
  const classes = useStyles();
  const [activeKey, setActiveKey] = useState<ContactTabs>('all');

  const [searchInput, setSearchInput] = React.useState<string>('');

  const onTabChange = (activeKey: ContactTabs) => {
    setActiveKey(activeKey);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ContactsTabs
            activeKey={activeKey}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            onChange={onTabChange}
            className={classes.contactTabs}
          />
        }
      />
      <Route
        path="contact/:contactId/details"
        element={<ContactDetailsPage noPadding backButton />}
      />
      <Route
        path="company/:companyId/details"
        element={<CompanyDetailsPage noPadding backButton />}
      />
    </Routes>
  );
};

export default ContactsTaskPane;
