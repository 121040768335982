import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import { useDispatch, useSelector } from 'react-redux';
import { getAbsenceOverview } from '../../../../apps/main/rootReducer';
import { debounceAbsencesMe } from '../../../absences/actions';
import { Link } from 'react-router-dom';
import { AbsenceOverviewPieChart } from '../../../absences/components/overview/AbsenceOverviewPieChart';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  table: {
    marginTop: theme.old.spacing.unit(3),
    flex: 1,
    overflow: 'hidden',
    '& .ant-table': {
      fontSize: theme.old.typography.fontSize.small,
    },
    '& .ant-table-tbody > tr > td': {
      padding: theme.old.spacing.baseSpacing,
    },
  },
  overtimePanel: {
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    padding: '3px 6px',
    margin: '0px 6px 3px 6px',
    height: '58px',
    '& .ant-form-item-label': {
      padding: '0px',
    },
  },
  content: {
    justifyContent: 'space-between',
    height: '100%',
  },
  chart: {
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    width: '100%',
    border: `1px solid ${theme.colors.application.border}`,
    borderRadius: theme.borderRadius.regular,
    height: 'fit-content',
    marginLeft: theme.spacing.large,
    maxWidth: 204,
  },
  boxDivider: {
    borderTop: `1px solid ${theme.colors.application.border}`,
    width: '100%',
  },
  noData: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.font.fontSize.regular,
    color: theme.colors.application.typography.muted,
  },
}));

interface DashboardAbsenceOverviewItemProps {
  className?: string;
}

export const DashboardAbsenceOverviewItem: React.FC<
  DashboardAbsenceOverviewItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const absenceOverview = useSelector(getAbsenceOverview);
  const loaded = useMemo(() => {
    return (
      absenceOverview.vacationDay !== 0 ||
      absenceOverview.takenVacationDays !== 0
    );
  }, [absenceOverview]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(debounceAbsencesMe());
  }, [dispatch]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row
        childrenGap={theme.old.spacing.baseSpacing}
        marginBottom={theme.spacing.small}
      >
        <Flex.Item flex={1}>
          <Typography.Title level={3} ellipsis style={{ marginBottom: 0 }}>
            {t('dashboard:holidayEntitlement.title')}
          </Typography.Title>
        </Flex.Item>
        <Link to={'../timeAndLeaveManagement/absences'}>
          <Button
            iconProp={['fal', 'arrow-right-to-bracket']}
            type="default"
            style={{ fontSize: 16 }}
          />
        </Link>
      </Flex.Row>
      {!loaded ? (
        <PrioSpinner size="large" alignSelf />
      ) : (
        <>
          <Flex.Row className={classNames(classes.content)}>
            <div className={classNames(classes.chart)}>
              {absenceOverview ? (
                <AbsenceOverviewPieChart
                  absenceOverview={absenceOverview as AbsenceOverview}
                />
              ) : (
                <div className={classes.noData}>
                  <div>{t('dashboard:holidayEntitlement.error')}</div>
                </div>
              )}
            </div>
          </Flex.Row>
        </>
      )}
    </Flex.Column>
  );
};

export default DashboardAbsenceOverviewItem;
