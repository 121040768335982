import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import ProfileModuleNavigation from '../profile/components/ProfileModuleNavigation';
import ProfileModule from '../profile/components/ProfileModule';

import { makePrioStyles } from '../../theme/utils';
import GlobalSider from '../../components/GlobalSider';
import { useTranslation } from 'react-i18next';
import Flex from '../../components/Flex';
import GlobalNavigationBar from '../../components/GlobalNavigationBar';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../theme/types';
import classNames from 'classnames';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  //@ts-ignore
  navigation: {
    ...theme.old.components.navigation,
  },
  content: {
    flex: 2,
    overflow: 'auto',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
}));

export const Profile: React.FC<{}> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classNames('prio-deprecated-design', classes.root)}>
        <GlobalSider
          title={t('common:links.profile')}
          selectedMainMenuItem={'profile'}
        >
          <Routes>
            <Route
              path="*"
              element={
                <ProfileModuleNavigation className={classes.navigation} />
              }
            />
            <Route
              path=":selectedList/*"
              element={
                <ProfileModuleNavigation className={classes.navigation} />
              }
            />
          </Routes>
        </GlobalSider>
        <Flex.Column flex={1} className={classes.fullHeight}>
          <GlobalNavigationBar module="profile" />
          <div className={classes.content}>
            <ProfileModule />
          </div>
        </Flex.Column>
      </div>
    </DndProvider>
  );
};

export default Profile;
