import React from 'react';
import { Skeleton } from 'antd';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  item: {
    paddingRight: theme.old.spacing.defaultPadding,
  },
  heading: {
    overflow: 'hidden',
    width: '88.5%',
    height: '28px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  menuLine: {
    overflow: 'hidden',
    width: '115%',
    height: '30px',
    marginTop: '12px',
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  input: {
    width: '60%',
    height: '20px',
    overflow: 'hidden',
  },
  avatar: {
    marginLeft: '16px',
    marginRight: '4px',
  },
}));

export const ProjectsNavigationSkeleton: React.FC = (props) => {
  const classes = useStyles();
  return (
    <div id="prio-projects-navigation-skeleton">
      {/* unfolded project */}
      <div>
        <div style={{ marginBottom: '20px' }}>
          <Skeleton.Input className={classes.heading} />
          {Array(9)
            .fill(null)
            .map((_, index) => (
              <div className={classes.menuLine} key={index}>
                <Skeleton.Avatar size={'small'} className={classes.avatar} />
                <Skeleton.Input className={classes.input} />
              </div>
            ))}
        </div>
      </div>
      {/* folded projects */}
      <div>
        <Skeleton.Input className={classes.heading} />
      </div>
      <div>
        <Skeleton.Input className={classes.heading} />
      </div>
    </div>
  );
};

export default ProjectsNavigationSkeleton;
