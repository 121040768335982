import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: theme.old.spacing.unit(0.5),
    position: 'relative',
  },
  dot: {
    position: 'absolute',
    width: theme.old.spacing.unit(0.5),
    height: theme.old.spacing.unit(0.5),
    borderRadius: theme.old.spacing.unit(0.5),
    backgroundColor: theme.old.palette.primaryColor,
    animation: '$dotFloating 3s infinite cubic-bezier(0.15, 1.1, 1, 0.25)',
    left: 'calc(-50% - 5px)',
  },
  dot0: {
    animationDelay: 250,
  },
  dot1: {
    animationDelay: 400,
  },
  dot2: {
    animationDelay: 550,
  },
  dot3: {
    animationDelay: 700,
  },
  dot4: {
    animationDelay: 850,
  },
  '@keyframes dotFloating': {
    '0%': {
      left: 'calc(-50% - 5px)',
    },
    '75%': {
      left: 'calc(100% + 5px)',
    },
    '100%': {
      left: 'calc(100% + 5px)',
    },
  },
}));

interface ProgressDotsProps {
  className?: string;
}

export const ProgressDots: React.FC<ProgressDotsProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classNames(classes.dot, classes.dot0)} />
      <div className={classNames(classes.dot, classes.dot1)} />
      <div className={classNames(classes.dot, classes.dot2)} />
      <div className={classNames(classes.dot, classes.dot3)} />
      <div className={classNames(classes.dot, classes.dot4)} />
    </div>
  );
};

export default ProgressDots;
