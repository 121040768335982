import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';

/** Contact fetching */
export const FETCH_PROJECT_MAIL_CATEGORIES_REQUEST =
  PRIO + 'FETCH_PROJECT_MAIL_CATEGORIES_REQUEST';
export const FETCH_PROJECT_MAIL_CATEGORIES_COMMIT =
  PRIO + 'FETCH_MAIL_CATEGORIES_COMMIT';
export const FETCH_PROJECT_MAIL_CATEGORIES_ROLLBACK =
  PRIO + 'FETCH_PROJECT_MAIL_CATEGORIES_ROLLBACK';

export const fetchProjectMailCategories = (
  projectId: string,
  reloadCache?: boolean
) => ({
  type: FETCH_PROJECT_MAIL_CATEGORIES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Outlook/${projectId}/masterCategories${
          reloadCache ? '?reload=true' : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_PROJECT_MAIL_CATEGORIES_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_MAIL_CATEGORIES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.categories.fetchError',
          timeout: 3,
        },
        meta: { projectId },
      },
    },
    projectId,
  },
});
