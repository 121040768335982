import { Dispatch } from 'redux';
import { getAccessToken } from '../store/authEffect';
import fetchWithRetry from './fetchWithRetry';

interface DispatchAction {
  type: string;
  requiresAuth: boolean;
  payload?: any;
  meta: {
    offline: {
      effect: {
        url: string;
        method: string;
      };
      commit: {
        type: string;
        meta?: any;
      };
      rollback: {
        type: string;
        meta?: any;
        snackbarErrorMessage?: {
          label: string;
          timeout: number;
        };
      };
    };
    [anyProperty: string]: any;
  };
}

export const abortableDispatch: (
  value: DispatchAction,
  dispatch: Dispatch<any>,
  abortController?: AbortController,
  deleteController?: boolean,
  setAbortController?: (value: AbortController) => void,
  onStart?: VoidFunction,
  onEnd?: VoidFunction
) => Promise<void> = async (
  value,
  dispatch,
  abortController,
  deleteController,
  setAbortController,
  onStart,
  onEnd
) => {
  const { type, payload, meta } = value;
  const { offline, ...rest } = meta;
  if (onStart) {
    onStart();
  }
  dispatch({
    type,
    payload,
    meta: {
      ...rest,
    },
  });
  const result = await fetchWithRetry(value.meta.offline.effect.url, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: value.meta.offline.effect.method,
    body: value.payload,
    signal: abortController?.signal,
  });
  if (result.status >= 200 && result.status < 300) {
    const data = await result.json();
    dispatch({
      payload: data,
      ...value.meta.offline.commit,
    });
    if (deleteController) {
      setAbortController(null);
    }
    if (onEnd) {
      onEnd();
    }
    return Promise.resolve();
  }
  dispatch({
    ...value.meta.offline.rollback,
  });
  if (deleteController) {
    setAbortController(null);
  }
  if (onEnd) {
    onEnd();
  }
  return Promise.reject();
};
