import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getGlobalSiderOpen } from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import UserAvatar from '../../../../components/UserAvatar';
import { User } from '../../../../models/User';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import { setSiderSetting } from '../../../userSettings/actions/themeSettings/sider';

const useStyles = makePrioStyles((theme) => ({
  flex1: {
    color: theme.old.typography.colors.base,
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  activeLink: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  headerRow: {
    lineHeight: '32px',
    paddingLeft: '5px',
    '& > .prio-project-navigation-context-menu-button': {
      visibility: 'hidden',
    },
    '&:hover > .prio-project-navigation-context-menu-button': {
      visibility: 'visible',
    },
  },
  panelHeaderRoot: {
    display: 'flex',
    alignItems: 'center',
    transition: 'all 0.2s',
  },
  panelHeaderRootSubMenu: {
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.sub,
    },
  },
  panelHeaderRootSubMenuActive: {
    backgroundColor: theme.old.palette.backgroundPalette.active.sub,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.sub,
    },
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: '100%',
      left: 0,
      top: 0,
      background: theme.old.palette.primaryColor,
    },
  },
  avatar: {
    '& .ant-avatar-circle': {
      transform: 'scale(1)',
    },
  },
}));

interface NavigationItemPanelHeaderMeProps {
  rootPath: string;
  isSubMenu?: boolean;
  user: User;
  isActive: boolean;
}

const NavigationItemPanelHeaderMe: React.FC<
  NavigationItemPanelHeaderMeProps
> = (props) => {
  const classes = useStyles();
  const { rootPath, isSubMenu, user, isActive } = props;

  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();

  const globalSiderOpen = useSelector(getGlobalSiderOpen);

  return (
    <>
      <Link
        className={classNames(classes.flex1, {
          [classes.activeLink]: isActive,
        })}
        to={`${rootPath}me/dashboard`}
      >
        <div
          className={classNames(classes.panelHeaderRoot, {
            [classes.panelHeaderRootSubMenu]: isSubMenu,
            [classes.panelHeaderRootSubMenuActive]: isSubMenu && isActive,
          })}
          onClick={() => {
            if (isSubMenu) {
              dispatch(setSiderSetting({ subMenuState: 'closed' }));
            }
          }}
          style={{
            height: 40,
            padding: `0 ${
              globalSiderOpen
                ? theme.old.spacing.defaultPadding
                : theme.old.spacing.unit(1.5)
            }px`,
          }}
        >
          <Flex.Row
            className={classes.headerRow}
            padding={`0 ${theme.old.spacing.defaultPadding}`}
          >
            <Flex.Row
              alignItems="center"
              childrenGap={theme.old.spacing.baseSpacing}
            >
              <div className={classes.avatar}>
                <UserAvatar size="medium" />
              </div>
              <Flex.Item>{`${user?.givenName ?? ''} ${
                user?.surname ?? ''
              }`}</Flex.Item>
            </Flex.Row>
          </Flex.Row>
        </div>
      </Link>
    </>
  );
};

export default NavigationItemPanelHeaderMe;
