import { notification } from 'antd';
import { call, put, select, spawn } from 'redux-saga/effects';
import { WebSocketMessage } from '../../../models/WebSocket';
import i18n from 'i18next';

import {
  getCurrentFolderRedirect,
  getProject,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { wsFetchDocumentCopiedOrMoved } from '../actions/ws';
import { DriveItemId, GroupId, ProjectId } from '../../../models/Types';
import { apiFetchDocument } from '../api';
import { fetchDriveItemsSagaAction } from '../actions';
import { DriveItem } from '../../../models/Drive';
import handleDocumentCacheDeltaUpdated from './handleDocumentCacheDeltaUpdated';
import handleDocumentCreated from './handleDocumentCreated';
import handleArchiveUnzipped from './handleArchiveUnzipped';
import { isDriveItemFolder } from '../util';
import handleDocumentFromTemplateCreated from './handleDocumentFromTemplateCreated';
import { SavedDocumentFromTemplateDto } from '../../../models/Document';

export declare type DocumentMessageTypes =
  | 'reportCreated'
  | 'documentCreated'
  | 'documentCopiedOrMoved'
  | 'archiveUnzipped'
  | 'archiveUnzipping'
  | 'documentCacheDeltaUpdated';

export declare type DriveFavoriteMessageTypes =
  | 'driveFavoriteCreated'
  | 'driveFavoriteUpdated'
  | 'driveFavoriteDeleted';

export function* handleDocumentMessage(message: WebSocketMessage) {
  try {
    const project: Project = yield select<(state: RootReducerState) => Project>(
      (state) => getProject(state, message.projectId)
    );
    switch (message.type) {
      case 'documentCacheDeltaUpdated': {
        if (message.object && message.projectId) {
          yield spawn(
            handleDocumentCacheDeltaUpdated,
            message.object,
            message.projectId
          );
        }
        break;
      }

      case 'documentCopiedOrMoved': {
        if (message.object) {
          yield spawn(
            handleCopiedOrMovedDocuments,
            message.object.destinationDriveItemId,
            message.object.destinationGroupId,
            message.projectId,
            message.object.sourceDriveItemId,
            message.object.sourceGroupId
          );
        }
        break;
      }
      case 'archiveUnzipping': {
        notification.open({
          message: i18n.t('common:archive:unzippingStarted'),
          description: i18n.t((message.object as DriveItem)?.name),
        });
        break;
      }
      case 'archiveUnzipped': {
        yield spawn(handleArchiveUnzipped, message, project);
        break;
      }
      case 'documentCreated':
      case 'reportCreated':
        yield spawn(handleDocumentCreated, message, project);
        break;
    }
  } catch (error) {
    console.error('Error in websocket flow - handleDocumentMessage', error);
  }
}

export function* handleDocumentFromTemplateCreatedMessage(
  message: WebSocketMessage
) {
  try {
    const savedDFT = message.type as any as SavedDocumentFromTemplateDto;
    const project: Project = yield select<(state: RootReducerState) => Project>(
      (state) => getProject(state, savedDFT.projectId)
    );
    yield spawn(
      handleDocumentFromTemplateCreated,
      message.type as any,
      project
    );
  } catch (error) {
    console.error(
      'Error in websocket flow - handleDocumentFromTemplateCreated',
      error
    );
  }
}

function* handleCopiedOrMovedDocuments(
  destinationDriveItemId: DriveItemId,
  destinationGroupId: GroupId,
  destinationProjectId: ProjectId,
  sourceDriveItemId: DriveItemId,
  sourceGroupId: GroupId
) {
  const temporaryId: string = yield select<(state: RootReducerState) => string>(
    (state) => getCurrentFolderRedirect(state, sourceDriveItemId)
  );

  const { data } = yield call(
    apiFetchDocument,
    destinationGroupId,
    destinationDriveItemId
  );
  if (data) {
    if (isDriveItemFolder(data)) {
      yield put(
        fetchDriveItemsSagaAction(
          destinationProjectId,
          destinationGroupId,
          destinationDriveItemId,
          250,
          false,
          false
        )
      );
    }
    yield put(
      wsFetchDocumentCopiedOrMoved(
        data,
        destinationDriveItemId,
        destinationGroupId,
        sourceDriveItemId,
        sourceGroupId,
        temporaryId
      )
    );
  }
}
