import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  ExternalJobTitleSuggestion,
  CreateExternalJobTitleSuggestion,
} from '../../../models/ExternalJobTitleSuggestion';
import { ExternalJobTitleSuggestionId } from '../../../models/Types';

/** Contact classification fetching */

export interface FetchExternalJobTitlesCommitAction {
  type: 'PRIO_FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT';
  payload: ExternalJobTitleSuggestion[];
}

export const FETCH_EXTERNAL_CONTACT_JOB_TITLES_REQUEST =
  PRIO + 'FETCH_EXTERNAL_CONTACT_JOB_TITLES_REQUEST';
export const FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT =
  PRIO + 'FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT';
export const FETCH_EXTERNAL_CONTACT_JOB_TITLES_ROLLBACK =
  PRIO + 'FETCH_EXTERNAL_CONTACT_JOB_TITLES_ROLLBACK';

export const fetchExternalJobTitles = () => ({
  type: FETCH_EXTERNAL_CONTACT_JOB_TITLES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ExternalJobTitleSuggestion`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_EXTERNAL_CONTACT_JOB_TITLES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchExternalJobTitlesError',
          timeout: 6,
        },
      },
    },
  },
});

export interface ExternalJobTitleAction {
  type: string;
  payload?: ExternalJobTitleSuggestion;
  rollbackExternalJobTitle?: ExternalJobTitleSuggestion;
  meta?: {
    externalJobTitleSuggestionId?: ExternalJobTitleSuggestionId;
    temporaryId?: ExternalJobTitleSuggestionId;
  };
}

export const UPDATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST =
  PRIO + 'UPDATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST';
export const UPDATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT =
  PRIO + 'UPDATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT';
export const UPDATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK =
  PRIO + 'UPDATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK';

export const updateExternalJobTitle = (
  updatedExternalJobTitle: ExternalJobTitleSuggestion,
  rollbackExternalJobTitle: ExternalJobTitleSuggestion
) => ({
  type: UPDATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST,
  requiresAuth: true,
  payload: updatedExternalJobTitle,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ExternalJobTitleSuggestion/${updatedExternalJobTitle.externalJobTitleSuggestionId}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/merge-patch+json',
        },
        json: updatedExternalJobTitle,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
        rollbackExternalJobTitle,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateExternalJobTitleError',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST =
  PRIO + 'CREATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST';
export const CREATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT =
  PRIO + 'CREATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT';
export const CREATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK =
  PRIO + 'CREATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK';

export const createExternalJobTitle = (
  titleSuggestion: CreateExternalJobTitleSuggestion,
  temporaryId: ExternalJobTitleSuggestionId
) => ({
  type: CREATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST,
  requiresAuth: true,
  payload: titleSuggestion,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ExternalJobTitleSuggestion`,
        method: 'POST',
        json: titleSuggestion,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT,
        meta: {
          temporaryId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
        meta: {
          temporaryId,
        },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.createExternalJobTitleError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

export const DELETE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST =
  PRIO + 'DELETE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST';
export const DELETE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT =
  PRIO + 'DELETE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT';
export const DELETE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK =
  PRIO + 'DELETE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK';

export const deleteExternalJobTitle = (
  externalJobTitleSuggestionId: ExternalJobTitleSuggestionId
) => ({
  type: DELETE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/ExternalJobTitleSuggestion/${externalJobTitleSuggestionId}`,
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
        meta: { externalJobTitleSuggestionId },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.deleteExternalJobTitleError',
          timeout: 6,
        },
      },
    },
    externalJobTitleSuggestionId,
  },
});
