import { useContext } from 'react';
import { OfficesContext, OfficesContextProps } from '../context/OfficesContext';

export const useOfficesContext: () => OfficesContextProps = () => {
  const context = useContext(OfficesContext);

  return context;
};

export default useOfficesContext;
