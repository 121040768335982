import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import SettingsModuleNavigation from '../settings/components/SettingsModuleNavigation';

import SettingsModule from '../settings/components/SettingsModule';
import { makePrioStyles } from '../../theme/utils';
import GlobalSider from '../../components/GlobalSider';
import { useTranslation } from 'react-i18next';
import Flex from '../../components/Flex';
import GlobalNavigationBar from '../../components/GlobalNavigationBar';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../theme/types';
import classNames from 'classnames';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  //@ts-ignore
  navigation: {
    ...theme.old.components.navigation,
  },
  content: {
    flex: 2,
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
}));

export const Settings: React.FC<{}> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classNames('prio-deprecated-design', classes.root)}>
      <GlobalSider
        title={t('common:links.settings')}
        selectedMainMenuItem={'settings'}
      >
        <Routes>
          <Route
            path="*"
            element={
              <SettingsModuleNavigation className={classes.navigation} />
            }
          />
          <Route
            path=":selectedList/*"
            element={
              <SettingsModuleNavigation className={classes.navigation} />
            }
          />
        </Routes>
      </GlobalSider>
      <Flex.Column flex={1} className={classes.fullHeight}>
        <GlobalNavigationBar module="settings" />
        <div className={classes.content}>
          <SettingsModule />
        </div>
      </Flex.Column>
    </div>
  );
};

export default Settings;
