import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ExternalProjectContactsTable from './ExternalProjectContactsTable';
import { ExternalProjectContactItem } from '../../../models/ProjectContacts';

import { externalProjectContactsSelector } from '../selectors';
import { ProjectId } from '../../../models/Types';
import { fetchExternalProjectContacts, fetchHourlyRates } from '../actions';
import {
  getExternalProjectContactsIsFetching,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  table: {},
}));

interface ExternalProjectContactsTablePageProps {
  projectId: ProjectId;
}

export const ExternalProjectContactsTablePage: React.FC<
  ExternalProjectContactsTablePageProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId } = props;

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  let contactItems: ExternalProjectContactItem[] = useSelector<
    RootReducerState,
    ExternalProjectContactItem[]
  >((state) => externalProjectContactsSelector(projectId)(state));

  const isFetching = useSelector(getExternalProjectContactsIsFetching);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(fetchHourlyRates(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(fetchExternalProjectContacts(projectId));
  }, [dispatch, projectId]);
  //#endregion

  return (
    <ExternalProjectContactsTable
      className={classes.table}
      contactItems={contactItems}
      projectId={projectId}
      loading={isFetching && contactItems.length === 0}
    />
  );
};

export default ExternalProjectContactsTablePage;
