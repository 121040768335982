import { PrioFile } from './UploadField';
import { v4 as uuid } from 'uuid';

interface InternalDataTransferItem extends DataTransferItem {
  isFile: boolean;
  file: (
    cd: (file: PrioFile & { webkitRelativePath?: string }) => void
  ) => void;
  createReader: () => FileSystemDirectoryReader;
  fullPath: string;
  isDirectory: boolean;
  name: string;
  path: string;
}

const loopFiles = (
  item: InternalDataTransferItem,
  callback: (items: InternalDataTransferItem[]) => Promise<void>
) => {
  return new Promise<void>((resolve) => {
    const dirReader = item.createReader();
    let fileList = [];

    function sequence(resolve: (value: void | PromiseLike<void>) => void) {
      dirReader.readEntries(async (entries: FileSystemEntry[]) => {
        const entryList = Array.prototype.slice.apply(entries);
        fileList = fileList.concat(entryList);

        // Check if all the file has been viewed
        const isFinished = !entryList.length;

        if (isFinished) {
          await callback(fileList);
          resolve();
        } else {
          sequence(resolve);
        }
      });
    }

    sequence(resolve);
  });
};

const traverseFileTree = async (
  items: InternalDataTransferItem[],
  isAccepted: (file: PrioFile) => boolean,
  sessionId: string,
  path = ''
) => {
  const prioFiles: PrioFile[] = [];

  const _traverseFileTree = async (
    item: InternalDataTransferItem,
    isAccepted: (file: PrioFile) => boolean,
    sessionId: string,
    path = '',
    parentResolve: (value: void | PromiseLike<void>) => void = () => {}
  ) => {
    // eslint-disable-next-line no-param-reassign
    item.path = '';
    if (item.isFile) {
      const promise = new Promise<PrioFile>((resolve) => {
        item.file((file) => {
          if (isAccepted(file)) {
            // https://github.com/ant-design/ant-design/issues/16426
            if (item.fullPath && !file.webkitRelativePath) {
              Object.defineProperties(file, {
                webkitRelativePath: {
                  writable: true,
                },
              });
              // eslint-disable-next-line no-param-reassign
              (file as any).webkitRelativePath = item.fullPath.replace(
                /^\//,
                ''
              );
              Object.defineProperties(file, {
                webkitRelativePath: {
                  writable: false,
                },
              });
            }
            file.sessionId = sessionId;
            file.fileId = uuid();
            resolve(file);
          }
          resolve(null);
        });
      });
      const file = await promise;
      if (file) {
        prioFiles.push(file);
      }
    } else if (item.isDirectory) {
      await loopFiles(item, async (entries: InternalDataTransferItem[]) => {
        for (const entry of entries) {
          await _traverseFileTree(
            entry,
            isAccepted,
            sessionId,
            `${path}${item.name}/`
          );
        }
      });
    }
    parentResolve();
  };

  const promises: Promise<void>[] = [];
  for (const entry of items) {
    const promise = new Promise<void>((resolve) => {
      _traverseFileTree(
        entry.webkitGetAsEntry() as any,
        isAccepted,
        sessionId,
        path,
        resolve
      );
    });
    promises.push(promise);
  }
  await Promise.all(promises);
  return prioFiles;
};

export default traverseFileTree;
