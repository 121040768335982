import { put, select } from 'redux-saga/effects';
import {
  getActiveProject,
  getMailSettings,
  getMessage,
  getSpecialMailFolders,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { WebSocketMessage } from '../../../models/WebSocket';
import { wsEmailDeleted, wsEmailCreated, wsEmailUpdated } from '../actions/ws';
import { Message } from '../../../models/Message';
import { SpecialMailFolders } from '../actions/types';
import { updateFavicons } from '../../../hooks/useDocumentVisible';
import i18n from '../../../i18n';
import { notification } from 'antd';
import { MailSettings } from '../../../models/UserSettings/MailSettings';
import equals from 'deep-equal';

export declare type EmailMessageTypes =
  | 'emailCreated'
  | 'emailUpdated'
  | 'emailDeleted';

export function* handleEmailMessage(message: WebSocketMessage) {
  try {
    const activeProject = yield select(getActiveProject);
    const specialMailFolders = yield select<
      (state: RootReducerState) => SpecialMailFolders
    >((state) => getSpecialMailFolders(state, message?.projectId));

    if (specialMailFolders) {
      const mailFolderId: string =
        message?.object?.parentFolderId ===
        specialMailFolders['inboxFolder']?.id
          ? 'inbox'
          : message?.object?.parentFolderId;

      switch (message.type) {
        case 'emailCreated':
          const messageInRedux: Message = yield select<
            (state: RootReducerState) => Message
          >((state) =>
            getMessage(state, message?.projectId, message?.object?.id)
          );

          if (
            message &&
            messageInRedux?.changeKey >= message.object.changeKey &&
            messageInRedux?.isRead === message.object.isRead &&
            equals(messageInRedux?.flag, message.object.flag) &&
            messageInRedux?.parentFolderId === message.object.parentFolderId &&
            equals(
              messageInRedux?.customSingleValueExtendedProperties,
              message.object.customSingleValueExtendedProperties
            )
          ) {
            return;
          }
          if (!!activeProject && activeProject === message.projectId) {
            if (
              !messageInRedux &&
              mailFolderId === 'inbox' &&
              (document.visibilityState === 'hidden' || !document.hasFocus())
            ) {
              updateFavicons(true);
            }
          }
          yield put(
            wsEmailCreated(
              message?.object,
              message?.projectId,
              mailFolderId,
              !!messageInRedux,
              message?.object?.parentFolderId
            )
          );
          break;
        case 'emailUpdated':
          const messageToUpdate: Message = yield select<
            (state: RootReducerState) => Message
          >((state) =>
            getMessage(state, message?.projectId, message?.object?.id)
          );

          if (
            (message &&
              messageToUpdate?.changeKey < message.object.changeKey) ||
            messageToUpdate?.isRead !== message.object.isRead ||
            !equals(messageToUpdate?.flag, message.object.flag) ||
            messageToUpdate?.parentFolderId !== message.object.parentFolderId ||
            !equals(
              messageToUpdate?.customSingleValueExtendedProperties,
              message.object.customSingleValueExtendedProperties
            )
          ) {
            yield put(
              wsEmailUpdated(message.object, message.projectId, mailFolderId)
            );
          }
          break;
        case 'emailDeleted':
          const messageId = message.object;
          const messageToDelete: Message = yield select<
            (state: RootReducerState) => Message
          >((state) => getMessage(state, message.projectId, messageId));
          const messageToDeleteMailFolderId =
            messageToDelete?.parentFolderId ===
            specialMailFolders['inboxFolder']?.id
              ? 'inbox'
              : message?.object?.parentFolderId;
          if (messageToDelete || messageToDeleteMailFolderId) {
            yield put(
              wsEmailDeleted(
                messageId,
                messageToDeleteMailFolderId,
                message.projectId
              )
            );
          }
          break;
        default:
          console.warn('Received unhandled email web socket message', message);
      }
    }
  } catch (error) {
    console.error('Error in websocket flow  - handleEmailMessage', error);
  }
}

export function* handleEmailMeMessage(message: WebSocketMessage) {
  try {
    const styleObj = {
      fontSize: 13,
    };
    const activeProject = yield select(getActiveProject);

    const specialMailFolders = yield select<
      (state: RootReducerState) => SpecialMailFolders
    >((state) => getSpecialMailFolders(state, 'me'));

    if (specialMailFolders) {
      const mailFolderId: string =
        message?.object?.parentFolderId ===
        specialMailFolders['inboxFolder']?.id
          ? 'inbox'
          : message?.object?.parentFolderId;

      switch (message.type) {
        case 'emailCreated':
          const messageInRedux: Message = yield select<
            (state: RootReducerState) => Message
          >((state) => getMessage(state, 'me', message?.object?.id));

          const mailSettings: MailSettings = yield select<
            (state: RootReducerState) => MailSettings
          >(getMailSettings);

          const _message: Message = message?.object;

          if (!!activeProject && activeProject === 'me') {
            if (
              !messageInRedux &&
              (document.visibilityState === 'hidden' || !document.hasFocus()) &&
              message?.object?.parentFolderId ===
                specialMailFolders['inboxFolder']?.id
            ) {
              updateFavicons(true);
              if (mailSettings.pushNotificationsEnabled) {
                const notification = new Notification(
                  _message?.from?.emailAddress?.name &&
                  _message?.from?.emailAddress?.address
                    ? `${_message?.from?.emailAddress?.name} <${_message?.from?.emailAddress?.address}>`
                    : _message?.from?.emailAddress?.address ??
                      i18n.t('mail:newMessage'),
                  {
                    body:
                      _message?.subject ??
                      i18n.t('mail:messageDisplay.noSubject'),
                    icon: '/favicon.ico',
                    tag: message?.object?.id,
                  }
                );
                notification.onclick = () => {
                  const width = window.screen.availWidth / 2;
                  const height = window.screen.availHeight * 0.6;
                  window.open(
                    `/view/me/message/${message?.object?.id}/details`,
                    '_blank',
                    `width=${width},height=${height},top=${height / 4}`
                  );
                };
              }
            }
          }

          if (
            !messageInRedux &&
            !window.location.href.match(
              /module\/prio\/projects\/me\/mail\/inbox/
            )
          ) {
            if (
              mailSettings.pushNotificationsEnabled &&
              message?.object?.parentFolderId ===
                specialMailFolders['inboxFolder']?.id
            ) {
              const key = `open${Date.now()}`;
              notification.open({
                key: key,
                message: i18n.t('mail:notification.newPersonalMessage'),
                description: (
                  <>
                    <div style={styleObj}>
                      <b>{i18n.t('mail:notification.newMessageSubject')}:</b>{' '}
                      {_message?.subject}
                      <br />
                      <b>{i18n.t('mail:notification.newMessageFrom')}</b>:{' '}
                      {_message?.from?.emailAddress?.name &&
                      _message?.from?.emailAddress?.address
                        ? `${_message?.from?.emailAddress?.name} <${_message?.from?.emailAddress?.address}>`
                        : _message?.from?.emailAddress?.address}
                    </div>
                  </>
                ),
                onClick() {
                  const width = window.screen.availWidth / 2;
                  const height = window.screen.availHeight * 0.6;
                  window.open(
                    `/view/me/message/${message?.object?.id}/details`,
                    '_blank',
                    `width=${width},height=${height},top=${height / 4}`
                  );
                  notification.close(key);
                },
                className: 'prio-new-message-notification',
                duration: 5,
              });
            }
          }
          yield put(
            wsEmailCreated(
              message?.object,
              'me',
              mailFolderId,
              !!messageInRedux,
              message?.object?.parentFolderId
            )
          );
          break;
        case 'emailUpdated':
          const messageToUpdate: Message = yield select<
            (state: RootReducerState) => Message
          >((state) => getMessage(state, 'me', message?.object?.id));

          if (
            (message &&
              messageToUpdate?.changeKey < message.object.changeKey) ||
            messageToUpdate?.isRead !== message.object?.isRead ||
            !equals(messageToUpdate?.flag, message.object?.flag) ||
            messageToUpdate?.parentFolderId !==
              message.object?.parentFolderId ||
            !equals(
              messageToUpdate?.customSingleValueExtendedProperties,
              message.object?.customSingleValueExtendedProperties
            )
          ) {
            yield put(wsEmailUpdated(message.object, 'me', mailFolderId));
          }

          break;
        case 'emailDeleted':
          const messageId = message.object;
          const messageToDelete: Message = yield select<
            (state: RootReducerState) => Message
          >((state) => getMessage(state, 'me', messageId));
          const messageToDeleteMailFolderId =
            messageToDelete?.parentFolderId ===
            specialMailFolders['inboxFolder']?.id
              ? 'inbox'
              : message?.object?.parentFolderId;

          if (messageToDelete || messageToDeleteMailFolderId) {
            yield put(
              wsEmailDeleted(messageId, messageToDeleteMailFolderId, 'me')
            );
          }
          break;
        default:
          console.warn('Received unhandled email web socket message', message);
      }
    }
  } catch (error) {
    console.error('Error in websocket flow  - handleEmailMeMessage', error);
  }
}
