import { notification } from 'antd';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  getAllProjects,
  getCompanyOffset,
  getContactOffset,
  getCurrentPrioVersion,
  getDoodleState,
  getOfficeMeAllOffices,
  getUserMe,
} from '../apps/main/rootReducer';
import i18n from '../i18n';
import { LOGGED_IN, setIsInitialDataLoading } from '../modules/auth/actions';
import { mapLastVersions } from '../modules/main/NewPrioVersionModal';
import packageJson from '../../package.json';
import {
  FETCH_USER_ME_COMMIT,
  FETCH_USER_ME_ROLLBACK,
} from '../modules/users/actions/me';
import {
  FETCH_USER_SETTINGS_COMMIT,
  FETCH_USER_SETTINGS_ROLLBACK,
} from '../modules/userSettings/actions';
import {
  FETCH_PROJECTS_COMMIT,
  FETCH_PROJECTS_ROLLBACK,
} from '../modules/projects/actions';
import {
  FETCH_OFFICE_ME_COMMIT,
  FETCH_OFFICE_ME_ROLLBACK,
} from '../modules/users/actions/officeMe';
import {
  FETCH_CONTACTS_ME_COMMIT,
  FETCH_CONTACTS_ME_ROLLBACK,
} from '../modules/contacts/actions';
import {
  FETCH_COMPANIES_COMMIT,
  FETCH_COMPANIES_ROLLBACK,
} from '../modules/companies/actions';
import {
  FETCH_USER_REGISTRATION_STATE_COMMIT,
  FETCH_USER_REGISTRATION_STATE_REQUEST,
  FETCH_USER_REGISTRATION_STATE_ROLLBACK,
} from '../modules/users/actions/registrationState';
import { Doodle, DoodleType } from '../models/Doodle';
import doodleJson from '../modules/doodles/doodle.json';
import moment from 'moment';

function* waitForUserMe(showDoodle: boolean) {
  try {
    const userMe = yield select(getUserMe);
    if (!userMe || showDoodle) {
      yield take([FETCH_USER_ME_COMMIT, FETCH_USER_ME_ROLLBACK]);
    }
  } catch (error) {
    console.error('Error in initialLoadingProcess - waitForUserMe', error);
  }
}

function* waitForProjectsMe(showDoodle: boolean) {
  try {
    const projectsMe = yield select(getAllProjects);
    if (projectsMe.length === 0 || showDoodle) {
      yield take([FETCH_PROJECTS_COMMIT, FETCH_PROJECTS_ROLLBACK]);
    }
  } catch (error) {
    console.error('Error in initialLoadingProcess - waitForProjectsMe', error);
  }
}

function* waitForOfficesMe(showDoodle: boolean) {
  try {
    const officesMe = yield select(getOfficeMeAllOffices);
    if (officesMe.length === 0 || showDoodle) {
      yield take([FETCH_OFFICE_ME_COMMIT, FETCH_OFFICE_ME_ROLLBACK]);
    }
  } catch (error) {
    console.error('Error in initialLoadingProcess - waitForOfficesMe', error);
  }
}

function* waitForContactsMe(showDoodle: boolean) {
  try {
    const contactOffset = yield select(getContactOffset);
    if (!contactOffset || showDoodle) {
      yield take([FETCH_CONTACTS_ME_COMMIT, FETCH_CONTACTS_ME_ROLLBACK]);
    }
  } catch (error) {
    console.error('Error in initialLoadingProcess - waitForContactsMe', error);
  }
}

function* waitForCompanies(showDoodle: boolean) {
  try {
    const companyOffset = yield select(getCompanyOffset);
    if (!companyOffset || showDoodle) {
      yield take([FETCH_COMPANIES_COMMIT, FETCH_COMPANIES_ROLLBACK]);
    }
  } catch (error) {
    console.error('Error in initialLoadingProcess - waitForCompanies', error);
  }
}

function* initialLoadingProcess() {
  try {
    const prioVersion = yield select(getCurrentPrioVersion);
    const shouldFetch = !(
      prioVersion !== packageJson.version &&
      !!mapLastVersions(prioVersion, packageJson.version)
    );
    const doodleState = yield select(getDoodleState);
    const showDoodle = !!((Object.keys(doodleJson) ?? []) as DoodleType[]).find(
      (typeFromJson) =>
        (!doodleState[typeFromJson] || !doodleState[typeFromJson]?.disabled) &&
        moment().isBetween(
          (doodleJson[typeFromJson] as Doodle).start,
          (doodleJson[typeFromJson] as Doodle).end
        )
    );
    if (shouldFetch) {
      yield all([
        call(waitForUserMe, showDoodle),
        take([FETCH_USER_SETTINGS_COMMIT, FETCH_USER_SETTINGS_ROLLBACK]),
        call(waitForProjectsMe, showDoodle),
        call(waitForOfficesMe, showDoodle),
        call(waitForContactsMe, showDoodle),
        call(waitForCompanies, showDoodle),
      ]);
    }
    yield put(setIsInitialDataLoading(false));
  } catch (error) {
    try {
      yield put(setIsInitialDataLoading(false));
    } catch (e) {}
    console.error(
      'Error in initial data load - spinner routine, initialLoadingProcess',
      error
    );
  }
}

function* initialDataLoad() {
  try {
    const { error, registrationAndLogginCompleted } = yield race({
      registrationAndLogginCompleted: all([
        take(FETCH_USER_REGISTRATION_STATE_COMMIT),
        take(LOGGED_IN),
      ]),
      error: take([FETCH_USER_REGISTRATION_STATE_ROLLBACK]),
    });

    if (registrationAndLogginCompleted && !error) {
      const { timeout } = yield race({
        process: call(initialLoadingProcess),
        timeout: delay(300000),
      });
      if (timeout) {
        throw new Error('Timeout on initial load');
      }
    }
  } catch (error) {
    console.error('Error in initial data load - spinner routine', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('common:errorMessages.initialLoadError'),
    });
  }
}

export default function* watchInitialDataLoading() {
  yield takeEvery(FETCH_USER_REGISTRATION_STATE_REQUEST, initialDataLoad);
}
