import React from 'react';
import {} from 'antd';
import { MessageAttachment } from '../../../models/Message';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ProjectId } from '../../../models/Types';
import SelectableMessageAttachmentItem from './SelectableMessageAttachmentItem';
import classNames from 'classnames';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  attachmentsRoot: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  attachmentsDropdown: {
    padding: theme.old.spacing.unit(1),
    backgroundColor: 'white',
    maxHeight: '50vh',
    maxWidth: '25vw',
    overflowY: 'auto',
  },
}));

interface MessageAttachmentDropdownListProps {
  selectedMessageAttachmentIds?: string[];
  messageAttachments?: MessageAttachment[];
  onSelectionChange?: (e: CheckboxChangeEvent) => void;
  onDownloadAttachment: (messageAttachment: MessageAttachment) => Promise<void>;
  onDeleteAttachment?: (attachmentId: string) => Promise<void>;
  messageId: string;
  projectId: ProjectId;
  receivedMessage: boolean;
}

export const MessageAttachmentDropdownList: React.FC<
  MessageAttachmentDropdownListProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    selectedMessageAttachmentIds,
    messageAttachments,
    onSelectionChange,
    onDownloadAttachment,
    onDeleteAttachment,
    messageId,
    projectId,
    receivedMessage,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const selectedMessageAttachments = messageAttachments.filter((attachment) =>
    selectedMessageAttachmentIds.includes(attachment.id)
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (messageAttachments.length > 0) {
    return (
      <div style={{ boxShadow: theme.old.palette.boxShadow.large }}>
        <div className={classNames(classes.attachmentsRoot)}>
          <Flex.Column className={classes.attachmentsDropdown}>
            {messageAttachments.map((messageAttachment, key) => (
              <Flex.Item style={{ width: '200px' }} key={key}>
                <SelectableMessageAttachmentItem
                  key={messageAttachment.id}
                  messageAttachment={messageAttachment}
                  messageAttachments={messageAttachments}
                  onSelectionChange={onSelectionChange}
                  onDownloadAttachment={onDownloadAttachment}
                  messageId={messageId}
                  selected={selectedMessageAttachmentIds.includes(
                    messageAttachment.id
                  )}
                  selectedAttachments={selectedMessageAttachments ?? []}
                  isDropdown={true}
                  projectId={projectId}
                  receivedMessage={receivedMessage}
                  onDeleteAttachment={onDeleteAttachment}
                />
              </Flex.Item>
            ))}
          </Flex.Column>
        </div>
      </div>
    );
  }
  return null;
};

export default MessageAttachmentDropdownList;
