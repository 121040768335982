import React, { useEffect, useMemo } from 'react';
import moment, { Moment } from 'moment';
import {
  CurrencyCode,
  InvoicePayment,
  Money,
} from '../../../models/Accounting';
import { Col, Form, Input, Row, Select, DatePicker } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  allInvoicePaymentTypes,
  InvoiceId,
  InvoicePaymentType,
} from '../../../models/Types';
import { colon, rowGutter } from '../../../util/forms';
import { useTranslation } from 'react-i18next';
import useDatePickerLocale from '../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CurrencyInput } from '../../../components/CurrencyInput';
import { useForm } from 'antd/lib/form/Form';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
  button: {
    marginBottom: '0px',
  },
}));

interface InvoicePaymentFormProps {
  className?: string;
  initialValues?: InvoicePayment;
  disableForm?: boolean;
  actionLabel: string;
  onFinish: (payment: InvoicePayment) => void;
  invoiceId: InvoiceId;
  isoCode: CurrencyCode;
  amount?: number;
}

interface InvoicePaymentFormModel {
  type: InvoicePaymentType;
  date: Moment;
  debitSumValue: number;
  title: string;
}

export const InvoicePaymentForm: React.FC<InvoicePaymentFormProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    initialValues,
    onFinish,
    disableForm,
    actionLabel,
    invoiceId,
    isoCode,
    amount,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const [form] = useForm<InvoicePaymentFormModel>();
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const derivedInitialValues: InvoicePaymentFormModel = useMemo(
    () =>
      initialValues
        ? {
            type: initialValues.paymentType,
            date: moment(initialValues.date, moment.ISO_8601),
            debitSumValue: initialValues.debitSum.value,
            title: initialValues.title,
          }
        : {
            type: 'payment',
            date: moment(),
            debitSumValue: amount ?? 0,
            title: '',
          },
    [initialValues, amount]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = async (formData: InvoicePaymentFormModel) => {
    const { debitSumValue, title, type } = formData;
    const debitSum: Money = {
      value: debitSumValue,
      isoCode,
    };

    const invoicePaymentId = initialValues?.invoicePaymentId ?? undefined;

    onFinish({
      invoiceId,
      invoicePaymentId,
      date: formData.date.toISOString(true).split('T')[0],
      debitSum,
      originalSum: debitSum,
      paymentType: type,
      title,
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.setFieldsValue(derivedInitialValues);
  }, [derivedInitialValues, form]);
  //#endregion

  return (
    <Form<InvoicePaymentFormModel>
      form={form}
      className={classNames(classes.root, className)}
      initialValues={derivedInitialValues}
      onFinish={handleFinish}
      layout="vertical"
    >
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="date"
            label={t('accounting:paymentForm.labels.date')}
            colon={colon}
            rules={[
              {
                required: true,
                message: t('accounting:paymentForm.validation.missing.date'),
              },
            ]}
          >
            <DatePicker
              disabled={disableForm}
              locale={datePickerLocale}
              format="DD.MM.YYYY"
              suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
              className={classes.fullWidth}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <Form.Item
            name="type"
            label={t('accounting:paymentForm.labels.type')}
            colon={colon}
          >
            <Select<InvoicePaymentType> disabled={disableForm}>
              {allInvoicePaymentTypes.map((type) => (
                <Select.Option value={type} key={type}>
                  {t(`accounting:invoicePaymentType.${type}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['debitSumValue']}
            label={t('accounting:paymentForm.labels.debitSum')}
            colon={colon}
          >
            <CurrencyInput
              isoCode={isoCode}
              disabled={disableForm}
              allowNegative={true}
              updateOnValueChange
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="title"
            label={t('accounting:paymentForm.labels.title')}
            colon={colon}
          >
            <Input.TextArea disabled={disableForm} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col span={24}>
          <Form.Item className={classes.button}>
            <Button type="primary" htmlType="submit" disabled={disableForm}>
              {actionLabel}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default InvoicePaymentForm;
