import { combineReducers } from 'redux';
import lastHRLocation, * as fromLastHRLocation from './lastHRLocation';

export interface HRState {
  lastHRLocation: string;
}

export default combineReducers<HRState>({
  lastHRLocation,
});

export const getLastHRLocation: (state: HRState) => string = (state) =>
  fromLastHRLocation.getLastHRLocation(state.lastHRLocation);
