import { combineReducers, Reducer } from 'redux';
import { ProjectId } from '../../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { DELETE_LOCAL_MESSAGES } from '../../actions/actionControllers/messageActionController';
import { SET_ACTIVE_PROJECT_IN_MESSAGE_CENTER } from '../../actions/favorites/meta';
import { UPDATE_CACHING_ENABLED } from '../../../userSettings/actions/mailSettings/mail';

export interface MetaState {
  activeProject: ProjectId;
}

const activeProject: Reducer<ProjectId, any> = (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_PROJECT_IN_MESSAGE_CENTER: {
      const { projectId } = action;
      return projectId;
    }

    case UPDATE_CACHING_ENABLED:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

export default combineReducers<MetaState>({
  activeProject,
});

export const getActiveProjectInMessageCenter: (
  state: MetaState
) => ProjectId = (state) => state.activeProject;
