import React, { useState } from 'react';
import classNames from 'classnames';
import { InvoicePayment } from '../../../models/Accounting';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../theme/utils';
import { Divider, Modal, notification, Typography } from 'antd';
import { compactDateFormatString, formatMoney } from '../../../util';
import { InvoicePaymentForm } from './InvoicePaymentForm';
import { InvoiceId, OfficeId, ProjectId } from '../../../models/Types';
import { apiDeleteInvoicePayment, apiUpdateInvoicePayment } from '../api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  colorGreen: {
    color: theme.old.palette.chromaticPalette.green,
  },
  colorRed: {
    color: theme.old.palette.chromaticPalette.red,
  },
  date: {
    width: 100,
  },
  paymentType: {
    display: 'inline-block',
    flex: 1,
  },
  form: {
    marginTop: theme.old.spacing.unit(4),
  },
  deleteIcon: {
    padding: `0px, ${theme.old.spacing.unit(2)}px`,
    visibility: 'hidden',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paymentElement: {
    padding: `${theme.old.spacing.unit(1)}px 0px`,
    '&:hover > svg': {
      visibility: 'visible',
    },
  },
  divider: {
    marginTop: theme.old.spacing.unit(2),
  },
}));

interface InvoicePaymentDetailsProps {
  payment: InvoicePayment;
  invoiceId: InvoiceId;
  reloadInvoices: VoidFunction;
  officeId?: OfficeId;
  projectId: ProjectId;
}

export const InvoicePaymentDetails: React.FC<InvoicePaymentDetailsProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { payment, reloadInvoices, invoiceId, projectId, officeId } = props;

  //#region ------------------------------ States / Attributes / Selectors
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [
    isDeleteInvoicePaymentModalVisible,
    setIsDeleteInvoicePaymentModalVisible,
  ] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const toggleExpanded = () => setExpanded(!expanded);

  const handleSave = async (payment: InvoicePayment) => {
    setIsSaving(true);
    const { result } = await apiUpdateInvoicePayment(
      payment,
      officeId,
      projectId
    );
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      reloadInvoices();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('accounting:errorMessages.updateInvoicePayment'),
      });
      return false;
    }
  };

  const deleteInvoicePayment = async () => {
    const { result } = await apiDeleteInvoicePayment(
      payment.invoicePaymentId,
      officeId,
      projectId
    );
    if (result.status >= 200 && result.status < 300) {
      reloadInvoices();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('accounting:errorMessages.deleteInvoicePayment'),
      });
    }
  };

  const handleDeleteInvoicePayment = () => {
    deleteInvoicePayment();
  };

  const showDeleteInvoicePaymentModal = () => {
    setIsDeleteInvoicePaymentModalVisible(true);
  };
  const deleteInvoicePaymentModalOkAction = () => {
    setIsDeleteInvoicePaymentModalVisible(false);
    handleDeleteInvoicePayment();
  };
  const deleteInvoicePaymentModalCancelAction = () => {
    setIsDeleteInvoicePaymentModalVisible(false);
  };
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <Flex.Row
        childrenGap={theme.old.spacing.unit(2)}
        alignItems="center"
        className={classes.paymentElement}
      >
        <FontAwesomeIcon
          icon={['fal', expanded ? 'minus-square' : 'plus-square']}
          onClick={toggleExpanded}
        />
        <Typography.Text className={classes.date}>
          {compactDateFormatString(payment.date)}
        </Typography.Text>
        <Typography.Text
          className={classNames(
            classes.paymentType,
            payment.paymentType === 'payment'
              ? classes.colorGreen
              : classes.colorRed
          )}
        >
          {t(`accounting:invoicePaymentType.${payment.paymentType}`)}
        </Typography.Text>
        <Typography.Text
          className={
            payment.paymentType === 'payment'
              ? classes.colorGreen
              : classes.colorRed
          }
        >
          {formatMoney(payment.debitSum)}
        </Typography.Text>
        <FontAwesomeIcon
          className={classes.deleteIcon}
          icon={['fal', 'trash']}
          onClick={() => showDeleteInvoicePaymentModal()}
        />
      </Flex.Row>
      {expanded && (
        <Flex.Item className={classes.form}>
          <InvoicePaymentForm
            initialValues={payment}
            onFinish={handleSave}
            actionLabel={t('accounting:paymentForm.actions.update')}
            disableForm={isSaving}
            invoiceId={invoiceId}
            isoCode={payment.debitSum.isoCode}
          />
        </Flex.Item>
      )}
      <Divider className={classes.divider} />
      <Modal
        visible={isDeleteInvoicePaymentModalVisible}
        onOk={() => deleteInvoicePaymentModalOkAction()}
        onCancel={() => deleteInvoicePaymentModalCancelAction()}
        title={t('accounting:invoiceDetails.deleteInvoicePaymentModal.title')}
        okText={t('accounting:invoiceDetails.deleteInvoicePaymentModal.okText')}
        cancelText={t(
          'accounting:invoiceDetails.deleteInvoicePaymentModal.cancelText'
        )}
      >
        {t('accounting:invoiceDetails.deleteInvoicePaymentModal.description')}
      </Modal>
    </Flex.Column>
  );
};

export default InvoicePaymentDetails;
