import React, { useCallback, useEffect, useState } from 'react';
import ContactTagsContext from '../context/contactTagsContext';
import { apiFetchContactTags } from '../api';
import { ContactTag } from '../../../models/Contact';

interface ContactTagsProviderProps {
  children: React.ReactNode;
}

export const ContactTagsProvider: React.FC<ContactTagsProviderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [selectableTags, setSelectableTags] = useState<ContactTag[]>([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchTags = useCallback(async () => {
    const res = await apiFetchContactTags();
    if (res.data) {
      setSelectableTags(res.data.filter((tag) => tag.isArchived === false));
    }
  }, []);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    fetchTags();
  }, [fetchTags]);
  //#endregion

  return (
    <ContactTagsContext.Provider value={{ selectableTags, fetchTags }}>
      {children}
    </ContactTagsContext.Provider>
  );
};

export default ContactTagsProvider;
