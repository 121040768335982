import { notification } from 'antd';
import i18n from '../i18n';
import { parseErrorObjectToString } from '../models/ValidationResults';

const tryParseJSON = (json: string) => {
  if (!json) {
    return null;
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    console.error('Failed to parse unexpected JSON response: '.concat(json), e);
  }
};

export const getResponseBody: <T>(response: Response) => Promise<T> = async (
  response: Response
) => {
  const contentType = response.headers.get('content-type') || false;

  const text = await response.text();
  if (contentType && contentType.indexOf('json') >= 0) {
    return await tryParseJSON(text);
  }

  return text;
};

export const createErrorNotification = async (
  result: Response,
  defaultErrorString: string
) => {
  let validationError = null;
  try {
    validationError = await result?.json();
  } catch (e) {
    // nothing to do
  }

  var translatedError = '';
  if (validationError !== null && validationError.errors !== null) {
    translatedError = parseErrorObjectToString(validationError.errors);
  }
  if (translatedError !== null && translatedError.length > 0) {
    defaultErrorString = translatedError;
  }
  notification.error({
    message: i18n.t('common:error'),
    description: defaultErrorString,
  });
};

export const showErrorNotificationIfErrorResult = async (
  result: Response,
  defaultErrorString: string
) => {
  if (!result.ok) {
    const obj = await result.json();
    const description = obj?.TranslatedMessage ?? i18n.t(defaultErrorString);

    notification.error({
      message: i18n.t('common:error'),
      description,
    });
  }
};

export const buildQueryString = (params: object) => {
  const queryParams = [];

  for (let [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      queryParams.push(`${key}=${encodeURIComponent(value)}`);
    }
  }

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
};
