import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import { FilterBar } from '../../../../components/Filter/FilterBar';
import AbsenceTimeline, { AbsenceTimelineRef } from './AbsenceTimeline';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { OfficeId } from '../../../../models/Types';
import AbsenceProposalDetailsDrawer from '../AbsenceProposalDetailsDrawer';
import { useSelector } from 'react-redux';
import { getOfficesByIdState } from '../../../../apps/main/rootReducer';
import { DefaultSearchParameterItem } from '../../../../components/Filter/types';

const SCREEN_WIDTH = 1080;

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: 24,
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const calcDefaultSearchParameters = (
  factor: number,
  isHrContext: boolean,
  officeId?: OfficeId
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchString = searchParams.get('s');
  const currentDateString = searchString
    ?.split('&')
    ?.find((str) =>
      str.includes(`${isHrContext ? 'Transformed.Timespan' : 'Data.From'} ge`)
    )
    ?.trim()
    ?.split(' ')?.[2]
    ?.replace("'", '')
    ?.substring(0, 7);
  const currentDate = currentDateString
    ? moment(`${currentDateString}-15`).add(1, 'month')
    : moment(moment().toISOString(true).substring(0, 7) + '-15');
  const _factor = factor === 0 ? 0 : factor - 1;

  let parameters: DefaultSearchParameterItem[] = [];
  if (isHrContext) {
    parameters = [
      {
        parameterName: 'Transformed.Timespan',
        defaultValue: currentDate
          .clone()
          .subtract(1, 'year')
          .startOf('month')
          .toISOString(true)
          .split('T')[0],
        defaultMethod: 'ge',
      },
      {
        parameterName: 'Transformed.Timespan',
        defaultValue: currentDate
          .clone()
          .add(_factor, 'months')
          .add(1, 'year')
          .endOf('month')
          .toISOString(true)
          .split('T')[0],
        defaultMethod: 'le',
      },
      {
        parameterName: 'Data.AbsenceState',
        defaultValue: ['planned', 'requested', 'accepted'],
        defaultMethod: 'eq',
      },
    ];
  } else {
    parameters = [
      {
        parameterName: 'Data.From',
        defaultValue: currentDate
          .clone()
          .subtract(1, 'year')
          .startOf('month')
          .toISOString(true)
          .split('T')[0],
        defaultMethod: 'ge',
      },
      {
        parameterName: 'Data.From',
        defaultValue: currentDate
          .clone()
          .add(_factor, 'months')
          .endOf('month')
          .toISOString(true)
          .split('T')[0],
        defaultMethod: 'le',
      },
      {
        parameterName: 'Data.To',
        defaultValue: currentDate
          .clone()
          .startOf('month')
          .toISOString(true)
          .split('T')[0],
        defaultMethod: 'ge',
      },
      {
        parameterName: 'Data.To',
        defaultValue: currentDate
          .clone()
          .add(_factor, 'months')
          .add(1, 'year')
          .endOf('month')
          .toISOString(true)
          .split('T')[0],
        defaultMethod: 'le',
      },
    ];
  }
  if (officeId) {
    parameters.push({
      parameterName: 'Data.OfficeId',
      defaultValue: officeId,
      defaultMethod: 'eq',
    });
  }

  return parameters;
};

interface AbsenceTimelinePageProps {
  className?: string;
  officeId?: OfficeId;
  isHrContext?: boolean;
}

export const AbsenceTimelinePage: React.FC<AbsenceTimelinePageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, officeId, isHrContext } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const rootRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<AbsenceTimelineRef>(null);
  const lastScreenWidthRoundedFactor = useRef<number>(null);

  const [customDefaultSearchParameters, setCustomDefaultSearchParameters] =
    useState<DefaultSearchParameterItem[]>(
      calcDefaultSearchParameters(0, isHrContext, officeId)
    );

  const [absenceProposalDrawerOpen, setAbsenceProposalDrawerOpen] =
    useState<boolean>(false);

  const [clickedAbsenceProposal, setClickedAbsenceProposal] =
    useState<AbsenceProposal | null>(null);

  const officesById = useSelector(getOfficesByIdState);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSetDefaultValues = () => {
    const startDate = moment(
      customDefaultSearchParameters[isHrContext ? 0 : 2].defaultValue
    );
    const endDate = moment(customDefaultSearchParameters[1].defaultValue);

    if (isHrContext) {
      startDate.add(1, 'year').startOf('month');
      endDate.subtract(1, 'year').subtract(1, 'month').endOf('month');
    }

    setTimeout(() => {
      timelineRef.current?.setTimeRange(startDate, endDate, startDate, endDate);
    }, 10);
  };

  const handleOnAbsenceProposalClick = (absenceProposal: AbsenceProposal) => {
    setClickedAbsenceProposal(absenceProposal);
    setAbsenceProposalDrawerOpen(true);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const observedElement = rootRef.current;

    if (!observedElement) {
      return null;
    }

    const handleResize = (entry?: ResizeObserverEntry) => {
      const element = (entry?.target ?? observedElement) as HTMLDivElement;
      if (element) {
        const containerWidth = element.offsetWidth;
        const screenWidthRoundedFactor = Math.floor(
          containerWidth / SCREEN_WIDTH
        );
        const lastRoundedFactor = lastScreenWidthRoundedFactor.current;
        if (
          lastRoundedFactor === null ||
          lastRoundedFactor !== screenWidthRoundedFactor
        ) {
          lastScreenWidthRoundedFactor.current = screenWidthRoundedFactor;
          setCustomDefaultSearchParameters(
            calcDefaultSearchParameters(
              screenWidthRoundedFactor,
              isHrContext,
              officeId
            )
          );

          const _factor =
            screenWidthRoundedFactor === 0 ? 0 : screenWidthRoundedFactor - 1;

          const searchParams = new URLSearchParams(window.location.search);
          const searchString = searchParams.get('s');
          const currentDateString = searchString
            ?.split('&')
            ?.find((str) =>
              str.includes(
                `${isHrContext ? 'Transformed.Timespan' : 'Data.From'} ge`
              )
            )
            ?.trim()
            ?.split(' ')?.[2]
            ?.replace("'", '')
            ?.substring(0, 7);
          const currentDate = currentDateString
            ? moment(`${currentDateString}-15`).add(1, 'year')
            : moment(moment().toISOString(true).substring(0, 7) + '-15');
          timelineRef.current?.setTimeRange(
            currentDate.clone().startOf('month'),
            currentDate.clone().add(_factor, 'months').endOf('month'),
            currentDate.clone().startOf('month'),
            currentDate.clone().add(_factor, 'months').endOf('month')
          );
        }
      }
    };

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        handleResize(entry);
      }
    });

    observer.observe(observedElement);

    handleResize();

    return () => observer.disconnect();
  }, [isHrContext, officeId]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)} ref={rootRef}>
      <FilterContextProvider<AbsenceProposal>
        searchType={isHrContext ? 'absenceProposals' : 'publicAbsenceProposals'}
        equalityFunction={(a, b) =>
          a.data.absenceProposalId === b.data.absenceProposalId
        }
        options={{
          enabled: false,
          staleTime: 1000 * 60 * 60,
        }}
        customDefaultSearchParameters={customDefaultSearchParameters}
        transformedMap={{
          'Transformed.Timespan': ['Data.From', 'Data.To'],
        }}
        forceResult
      >
        <FilterBar
          hiddenPickers={(isHrContext
            ? ['Transformed.Timespan']
            : ['Data.From', 'Data.To']
          ).concat(['Data.AbsenceState'])}
          onSetDefaultValues={handleSetDefaultValues}
          allowUserToClearSearch={false}
        />
        <AbsenceTimeline
          ref={timelineRef}
          isHrContext={isHrContext}
          customDefaultSearchParameters={customDefaultSearchParameters}
          onAbsenceProposalClick={handleOnAbsenceProposalClick}
        />
        <AbsenceProposalDetailsDrawer
          absenceProposalDrawerOpen={absenceProposalDrawerOpen}
          setAbsenceProposalDrawerOpen={setAbsenceProposalDrawerOpen}
          absenceProposal={clickedAbsenceProposal}
          officesById={officesById}
          setClickedAbsenceProposal={setClickedAbsenceProposal}
          officeId={officeId}
        />
      </FilterContextProvider>
    </div>
  );
};

export default AbsenceTimelinePage;
