import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';

import useAccessRights from '../../users/hooks/useAccessRights';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from 'jss';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  menu: {
    background: 'none',
    border: 'none',
  },
  menuCollapsed: {
    transition: 'padding 0.2s',
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: `0 ${theme.old.spacing.unit(1.875)}px`,
      borderRadius: 2,
    },
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  navItemText: {
    '&.ant-typography': {
      color: 'unset',
    },
  },
}));

interface SettingsMenuProps {
  selectedList?: string;
  urlPrefix?: string;
  collapsed?: boolean;
}

export const SettingsMenu: React.FC<SettingsMenuProps> = (props) => {
  const { selectedList, urlPrefix, collapsed } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const {
    showAllPrioSettings,
    showUserRoleSettings,
    showTemplateSettings,
    showUserCompanyData,
    showAddInsSettings,
    showProjectAdminSettings,
  } = useAccessRights([
    'showAllPrioSettings',
    'showUserRoleSettings',
    'showTemplateSettings',
    'showUserCompanyData',
    'showAddInsSettings',
    'showProjectAdminSettings',
  ]);

  return (
    <Menu
      className={classNames(classes.menu, {
        [classes.menuCollapsed]: collapsed,
      })}
      selectedKeys={[selectedList]}
      mode="inline"
      inlineIndent={theme.old.components.menuInlineIndent}
    >
      {showAllPrioSettings &&
        navItem('generic', 'cog', t, classes, urlPrefix, undefined, collapsed)}
      {showUserRoleSettings &&
        navItem(
          'user-roles',
          'user-gear',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {showUserRoleSettings &&
        navItem(
          'user-licences',
          'key',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {showUserCompanyData &&
        navItem('users', 'users', t, classes, urlPrefix, undefined, collapsed)}
      {showTemplateSettings &&
        navItem(
          'templates',
          'file-upload',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {showAllPrioSettings &&
        navItem(
          'subsidiaries',
          'building',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {showAddInsSettings &&
        navItem(
          'addins',
          'puzzle-piece',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {showProjectAdminSettings &&
        navItem(
          'projectSettings',
          'cogs',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {showProjectAdminSettings &&
        navItem(
          'projectExtensionSettings',
          'cogs',
          t,
          classes,
          urlPrefix,
          undefined,
          collapsed
        )}
      {/* {showAllPrioSettings &&
        navItem('theme', 'magic', t, classes, urlPrefix, undefined, collapsed)} */}
    </Menu>
  );
};

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string,
  collapsed?: boolean
) => (
  <Menu.Item
    key={itemKey}
    title={
      <>
        {icon != null ? (
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', icon]}
            className={classes.icon}
          />
        ) : (
          <span className={classes.settingsIcon} />
        )}
        <Typography.Text className={classes.navItemText}>
          {t(`settings:settingsNav.navigation.${itemKey}`)}
        </Typography.Text>
      </>
    }
  >
    <Link to={customUrl ?? `${urlPrefix ?? ''}${itemKey}`}>
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {!collapsed && (
        <Typography.Text className={classes.navItemText}>
          {t(`settings:settingsNav.navigation.${itemKey}`)}
        </Typography.Text>
      )}
    </Link>
  </Menu.Item>
);
