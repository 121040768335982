import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { Affix } from 'antd';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import { Message } from '../../../../models/Message';
import { debounceFunction, sanitizeHTML } from '../../../../util';
import ReactHtmlParser from 'react-html-parser';
import { DomElement } from 'htmlparser2';
import useOverflowDetection from '../../../../hooks/useOverflowDetection';
import { useSelector } from 'react-redux';
import { getMailSettings } from '../../../../apps/main/rootReducer';
import { PrioTheme } from '../../../../theme/types';

const linkRegex = /^((?:[a-z]+:)?\/\/|mailto:|file:)/;

const useStyles = makePrioStyles((theme) => ({
  root: {},
  messageBody: {
    overflowX: 'auto',
    padding: theme.old.spacing.defaultPadding,
    scrollbarWidth: 'none' /* Firefox */,
    '-ms-overflow-style': 'none' /* Internet Explorer and Edge */,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  messageBodyCompressedView: {
    padding: 16,
  },
  compressAffix: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& .ant-affix': {
      marginTop: theme.old.spacing.defaultPadding,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  compressButton: {
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.old.typography.colors.muted,
    borderRadius: 2,
    transition: 'background-color 0s',
    boxShadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.85)',
      '& svg': {
        color: theme.old.palette.backgroundPalette.content,
      },
    },
  },
  compressIcon: {
    transition: 'color 0.2s',
  },
}));

const cssWidthHeightToNumber: (
  value: string,
  type?: 'width' | 'height'
) => number | undefined = (value: string, type = 'width') => {
  if (!value) {
    return undefined;
  }
  const slicedValue = value.slice(type === 'width' ? 6 : 7, value.length);
  const numberValue = parseFloat(slicedValue.slice(0, slicedValue.length - 2));
  const unitString = slicedValue.slice(slicedValue.length - 2);
  let currentNumber: number = 0;
  switch (unitString) {
    case 'cm': {
      currentNumber = numberValue * 37.795;
      break;
    }
    case 'mm': {
      currentNumber = numberValue * 3.779;
      break;
    }
    case 'in': {
      currentNumber = numberValue * 96;
      break;
    }
    case 'pt': {
      currentNumber = numberValue * (4 / 3);
      break;
    }
    case 'pc': {
      currentNumber = numberValue * 16;
      break;
    }
    default: {
      currentNumber = numberValue;
    }
  }
  return currentNumber;
};

const findBodyTagChildren: (nodes: DomElement[]) => DomElement[] = (
  nodes: DomElement[]
) => {
  let result: DomElement[];
  for (const node of nodes) {
    if (node.type === 'tag' && node.name === 'body') {
      result = node.children;
      break;
    }
    if (node.children) {
      const bodyTagChildren = findBodyTagChildren(node.children);
      if (bodyTagChildren) {
        result = bodyTagChildren;
        break;
      }
    }
  }
  return result;
};
const manipulateDom: (
  nodes: DomElement[],
  maxWidth: number | undefined,
  compress?: boolean
) => DomElement[] = (nodes, maxWidth, compress) => {
  return nodes?.map((node) => {
    if (node.type === 'style' && node.name === 'style') {
      return {
        ...node,
        type: 'comment',
        ...(node.children
          ? {
              children: manipulateDom(node.children, maxWidth, compress),
            }
          : {}),
      };
    } else if (compress && node.type === 'tag' && node.name === 'img') {
      const originalWidthMatch = node.attribs?.style
        ?.replace(/max-width:.+;/, '')
        ?.match(/width:[0-9]*(\.)?[0-9]+\w\w?/);
      const originalHeightMatch = ((node.attribs?.style as string) ?? '')
        .replace(/max-height:[0-9]*(\.)?[0-9]+\w\w?/, '')
        .match(/height:[0-9]*(\.)?[0-9]+\w\w?/);
      const originalWidth = cssWidthHeightToNumber(
        originalWidthMatch ? originalWidthMatch[0] : null
      );
      const originalHeight = cssWidthHeightToNumber(
        originalHeightMatch ? originalHeightMatch[0] : null,
        'height'
      );
      if (originalWidth >= maxWidth) {
        const newHeight = (originalHeight / originalWidth) * maxWidth;
        const newStyle =
          (node.attribs?.style ?? '')
            .replace(/max-width:.+;/, '')
            .replace(/body:.+;/, '') +
          `;width:${maxWidth ? `${maxWidth}px` : 'none'};${
            originalHeight
              ? `height:${maxWidth ? `${newHeight}px` : 'none'}`
              : ''
          }`;
        return {
          ...node,
          attribs: {
            ...(node.attribs ?? {}),
            style: newStyle,
          },
          ...(node.children
            ? {
                children: manipulateDom(node.children, maxWidth, compress),
              }
            : {}),
        };
      }
      return {
        ...node,
        ...(node.children
          ? {
              children: manipulateDom(node.children, maxWidth, compress),
            }
          : {}),
      };
    } else if (node.type === 'tag' && node.name === 'a') {
      return {
        ...node,
        attribs: {
          ...(node.attribs ?? {}),
          rel: node.attribs?.hinternalRef?.match(linkRegex)
            ? 'noopener nointernalReferrer'
            : undefined,
          target: node.attribs?.hinternalRef?.match(linkRegex)
            ? '_blank'
            : undefined,
          hinternalRef: node.attribs?.hinternalRef?.match(linkRegex)
            ? node.attribs?.hinternalRef ?? undefined
            : undefined,
          style: compress
            ? ((node.attribs?.style as string) ?? '')
                .replace(/white-space:[^;]+(;)?/, 'white-space:normal;')
                .replace(/(word-break|max-width):[^;]+(;)?/, '')
                .concat(
                  `word-break:break-all;max-width:${maxWidth}px;white-space:normal;`
                )
            : node.attribs?.style,
        },
        ...(node.children
          ? {
              children: manipulateDom(node.children, maxWidth, compress),
            }
          : {}),
      };
    } else {
      return {
        ...node,
        ...(node.attribs
          ? {
              attribs: {
                ...node.attribs,
                style: compress
                  ? ((node.attribs?.style as string) ?? '')
                      .replace(/white-space:[^;]+(;)?/, 'white-space:normal;')
                      .replace(/(word-break|max-width):[^;]+(;)?/, '')
                      .concat(
                        `word-break:break-all;max-width:${maxWidth}px;white-space:normal;`
                      )
                  : node.attribs?.style,
              },
            }
          : {}),
        ...(node.children
          ? {
              children: manipulateDom(node.children, maxWidth, compress),
            }
          : {}),
      };
    }
  });
};

const escapeRegex = (string: string) =>
  string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const applySearch: (nodes: DomElement[], searchTerm: string) => DomElement[] = (
  nodes: DomElement[],
  searchTerm
) => {
  const resultingNodes: DomElement[] = [];
  nodes?.forEach((node) => {
    if (
      node.type === 'text' &&
      typeof node.data === 'string' &&
      node.data.toLocaleLowerCase().includes(searchTerm)
    ) {
      const highlightedNodes: DomElement[] = highlightNode(node, searchTerm);

      resultingNodes.push(...highlightedNodes);
    } else {
      resultingNodes.push({
        ...node,
        ...(node.children
          ? { children: applySearch(node.children, searchTerm) }
          : {}),
      });
    }
  });
  return resultingNodes;
};

const highlightNode: (node: DomElement, searchTerm: string) => DomElement[] = (
  node,
  searchTerm
) => {
  if (node.data.toLocaleLowerCase() === searchTerm) {
    const highlightedNode = {
      type: 'tag',
      name: 'span',
      attribs: {
        style: `background-color: #fff100;`,
      },
      parent: node.parent,
      next: node.next,
      prev: node.prev,
      children: [
        {
          ...node,
          ...(node.children
            ? { children: applySearch(node.children, searchTerm) }
            : {}),
          next: null,
          prev: null,
        },
      ],
    };
    highlightedNode.children[0].parent = highlightedNode;
    return [highlightedNode];
  } else {
    let splitNodes: DomElement[] = (node.data as string)
      .split(RegExp('(' + escapeRegex(searchTerm) + ')', 'i'))
      .filter((text) => text !== '')
      .map((text) => ({
        type: 'text',
        data: text,
        parent: node.parent,
        next: null,
        prev: null,
      }));
    splitNodes.forEach((element, index) => {
      if (index === 0) {
        element.prev = node.prev;
      } else {
        element.prev = splitNodes[index - 1];
      }
      if (index < splitNodes.length - 1) {
        element.next = splitNodes[index + 1];
      } else {
        element.next = node.next;
      }
    });
    const result = splitNodes.flatMap((element) =>
      element.data.toLocaleLowerCase().includes(searchTerm)
        ? highlightNode(element, searchTerm)
        : [element]
    );
    return result;
  }
};

const debouncedScrollWidthChange = debounceFunction(
  (width: number, onScrollWidthChange?: (width: number) => void) => {
    if (onScrollWidthChange) {
      onScrollWidthChange(width);
    }
  },
  250
);

export interface HtmlTextContentRef {
  getScrollWidth: () => number;
  setScrollPosition: (value: { scrollLeft: number; scrollTop: number }) => void;
}

interface HtmlTextContentProps {
  className?: string;
  message: Message;
  searchKeywords?: string | null;
  scrollableRef: HTMLDivElement | null;
  onScrollWidthChange?: (scrollWidth: number) => void;
  onScroll?: (value: { scrollLeft: number; scrollTop: number }) => void;
}

export const HtmlTextContent = forwardRef<
  HtmlTextContentRef,
  HtmlTextContentProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const {
    className,
    message,
    searchKeywords,
    scrollableRef,
    onScrollWidthChange,
    onScroll,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [triggerCheck, setTriggerCheck] = useState<boolean>(false);

  const [compress, setCompress] = useState<boolean>(false);

  const [hasOverflow, internalRef, bodyRef, bodyMaxWidth] =
    useOverflowDetection(triggerCheck);

  const mailSettings = useSelector(getMailSettings);

  const handleOnScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (onScroll) {
      onScroll({
        scrollLeft: e.currentTarget.scrollLeft,
        scrollTop: e.currentTarget.scrollTop,
      });
    }
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const toggleCompress = () => {
    setTriggerCheck(!compress);
    setCompress(!compress);
  };
  //#endregion

  //#region ------------------------------ Ref
  useImperativeHandle(ref, () => ({
    getScrollWidth: () => {
      return internalRef.current?.scrollWidth ?? 0;
    },
    setScrollPosition: (value: { scrollLeft: number; scrollTop: number }) => {
      if (internalRef.current) {
        internalRef.current.scrollLeft = value.scrollLeft;
        internalRef.current.scrollTop = value.scrollTop;
      }
    },
  }));
  //#endregion

  //#region ------------------------------ Effects
  const messageBody = useMemo(() => {
    if (
      message?.body &&
      (message.body.contentType === 'html' ||
        message.body.contentType === 'Html' ||
        message.body.contentType === 1)
    ) {
      const sanitizedBody = sanitizeHTML(message.body.content);
      return ReactHtmlParser(sanitizedBody, {
        preprocessNodes(nodes) {
          nodes = manipulateDom(
            findBodyTagChildren(nodes) ?? nodes,
            bodyMaxWidth,
            compress
          );
          const highlightedNodes = searchKeywords
            ? applySearch(nodes, searchKeywords)
            : nodes;
          return highlightedNodes;
        },
      });
    } else {
      let messageText = message?.body?.content ?? '';
      messageText = messageText.replace(/\n/g, '<br />');
      const sanitizedBody = sanitizeHTML(messageText);
      return ReactHtmlParser(sanitizedBody, {
        preprocessNodes(nodes) {
          nodes = manipulateDom(
            findBodyTagChildren(nodes) ?? nodes,
            bodyMaxWidth,
            compress
          );
          const highlightedNodes = searchKeywords
            ? applySearch(nodes, searchKeywords)
            : nodes;
          return highlightedNodes;
        },
      });
    }
  }, [message, searchKeywords, bodyMaxWidth, compress]);

  useEffect(() => {
    document?.querySelectorAll('#email-message-container a')?.forEach((el) => {
      el?.setAttribute('target', '_blank');
    });
  }, [message, searchKeywords, bodyMaxWidth, compress]);
  //#endregion

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      id="email-message-container"
    >
      {(hasOverflow || compress) && (
        <Affix
          target={() => scrollableRef}
          className={classes.compressAffix}
          style={{
            marginTop: theme.old.spacing.unit(2),
            marginRight: theme.old.spacing.unit(2),
          }}
        >
          <div onClick={toggleCompress} className={classes.compressButton}>
            <FontAwesomeIcon
              icon={['fal', compress ? 'expand' : 'compress']}
              className={classes.compressIcon}
            />
          </div>
        </Affix>
      )}
      <Flex.Item
        ref={(element) => {
          bodyRef(element);
          internalRef.current = element;
          if (element) {
            debouncedScrollWidthChange(
              element.scrollWidth,
              onScrollWidthChange
            );
          }
        }}
        className={classNames(classes.messageBody, {
          [classes.messageBodyCompressedView]:
            mailSettings.mailListSpacing === 'tight',
        })}
        furtherProps={{
          onScroll: handleOnScroll,
        }}
      >
        {messageBody}
      </Flex.Item>
    </Flex.Column>
  );
});

export default HtmlTextContent;
