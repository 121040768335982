import moment, { Moment, unitOfTime } from 'moment';
import { ResolutionType, RowResolutionType, TimelineItem } from './types';

export const guessTimelineHeaderResolution: (
  start: Moment,
  end: Moment
) => { top: ResolutionType; bottom: ResolutionType } = (start, end) => {
  const durationMilliSecs = end.diff(start);
  /// 1ms -> 1s
  if (durationMilliSecs <= 1000) {
    return { top: 'second', bottom: 'millisecond' };
  }
  // 1s  -> 2m
  else if (durationMilliSecs <= 60 * 2 * 1000) {
    return { top: 'minute', bottom: 'second' };
  }
  // 2m -> 2h
  else if (durationMilliSecs <= 60 * 60 * 2 * 1000) {
    return { top: 'hour', bottom: 'minute' };
  }
  // 2h -> 3d
  else if (durationMilliSecs <= 24 * 60 * 60 * 3 * 1000) {
    return { top: 'day', bottom: 'hour' };
  }
  // 1d -> 30d
  else if (durationMilliSecs <= 30 * 24 * 60 * 60 * 1000) {
    return { top: 'month', bottom: 'day' };
  }
  //30d -> 1y
  else {
    //if (durationMilliSecs <= 365 * 24 * 60 * 60 * 1000)
    return { top: 'year', bottom: 'month' };
  }
  // 1y ->
  // else {
  //   return { top: 'year', bottom: 'year' };
  // }
};

export const mapHeaderBarResolutionToGridResolution: (
  resolution: ResolutionType
) => RowResolutionType = (resolution) => {
  switch (resolution) {
    case 'hour': {
      return 'quarterOfAnHour';
    }
    case 'day': {
      return 'halfDay';
    }
    default: {
      return resolution;
    }
  }
};

export const mapResolutionToMillisecondSnap: (
  resolution: RowResolutionType | ResolutionType,
  startDate: Moment,
  endDate: Moment
) => number = (resolution, startDate, endDate) => {
  if (resolution === 'quarterOfAnHour') {
    return 1000 * 60 * 15;
  }
  if (resolution === 'halfDay') {
    return 1000 * 60 * 60 * 12;
  }
  let result: number = 1;
  switch (resolution) {
    // eslint-disable-next-line
    case 'year': {
      result = result * 12;
    }
    // eslint-disable-next-line
    case 'month': {
      result = result * endDate.diff(startDate, 'days');
    }
    // eslint-disable-next-line
    case 'day': {
      result = result * 24;
    }
    // eslint-disable-next-line
    case 'hour': {
      result = result * 60;
    }
    // eslint-disable-next-line
    case 'minute': {
      result = result * 60;
    }
    // eslint-disable-next-line
    case 'second': {
      result = result * 1000;
    }
    // eslint-disable-next-line
    default: {
      return result;
    }
  }
};

export interface TimelineBodySection {
  width: number;
  date: Moment;
  isStartOfHigherResolution: boolean;
}

const getPixelIncrement: (
  date: Moment,
  resolutionType: ResolutionType | RowResolutionType,
  offset: number,
  startDate: Moment,
  endDate: Moment,
  width: number
) => number = (date, resolutionType, offset = 0, startDate, endDate, width) => {
  const start_end_ms = endDate.diff(startDate, 'milliseconds');
  const pixels_per_ms = width / start_end_ms;
  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }
  const daysInYear = isLeapYear(date.year()) ? 366 : 365;
  let inc = width;
  switch (resolutionType) {
    case 'year': {
      inc = pixels_per_ms * 1000 * 60 * 60 * 24 * (daysInYear - offset);
      break;
    }
    case 'month': {
      inc = pixels_per_ms * 1000 * 60 * 60 * 24 * (date.daysInMonth() - offset);
      break;
    }
    case 'day': {
      inc = pixels_per_ms * 1000 * 60 * 60 * (24 - offset);
      break;
    }
    case 'halfDay': {
      inc = pixels_per_ms * 1000 * 60 * 60 * (12 - offset);
      break;
    }
    case 'hour': {
      inc = pixels_per_ms * 1000 * 60 * (60 - offset);
      break;
    }
    case 'quarterOfAnHour': {
      inc = pixels_per_ms * 1000 * 60 * (15 - offset);
      break;
    }
    case 'minute': {
      inc = pixels_per_ms * 1000 * 60 - offset;
      break;
    }
    case 'second': {
      inc = pixels_per_ms * 1000 - offset;
      break;
    }
    case 'millisecond': {
      inc = pixels_per_ms - offset;
      break;
    }
    default: {
      break;
    }
  }
  return Math.min(inc, width);
};

export const calculateSections: (
  resolutionType: ResolutionType | RowResolutionType,
  startDate: Moment,
  endDate: Moment,
  timelineWidth: number
) => TimelineBodySection[] = (
  resolutionType,
  startDate,
  endDate,
  timelineWidth
) => {
  let currentDate = startDate.clone();
  let sectionSizes: TimelineBodySection[] = [];
  const width = timelineWidth;
  let pixelsLeft = width;

  const addTimeIncrement = (
    initialOffset: moment.Duration,
    offsetType: unitOfTime.Base,
    higherResolutionOffsetType: unitOfTime.Base,
    stepFunc: (currentDt: Moment, offst: moment.Duration) => void
  ) => {
    let offset: moment.Duration = null;
    while (currentDate.isBefore(endDate) && pixelsLeft > 0) {
      // if this is the first 'block' it may be cut off at the start
      if (pixelsLeft === width) {
        offset = initialOffset;
      } else {
        offset = moment.duration(0);
      }
      let pixelIncrements = Math.min(
        getPixelIncrement(
          currentDate,
          resolutionType,
          offset.as(offsetType),
          startDate,
          endDate,
          width
        ),
        pixelsLeft
      );
      sectionSizes.push({
        date: currentDate.clone(),
        width: pixelIncrements,
        isStartOfHigherResolution:
          currentDate
            .clone()
            .isSame(currentDate.clone().startOf(higherResolutionOffsetType)) ||
          currentDate
            .clone()
            .add(currentDate.utcOffset(), 'minutes')
            .isSame(
              currentDate
                .clone()
                .add(currentDate.utcOffset(), 'minutes')
                .startOf(higherResolutionOffsetType)
            ),
      });
      stepFunc(currentDate, offset);
      pixelsLeft -= pixelIncrements;
    }
  };

  if (resolutionType === 'year') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('year'))
    );
    addTimeIncrement(offset, 'months', 'year', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'year')
    );
  } else if (resolutionType === 'month') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('month'))
    );
    addTimeIncrement(offset, 'days', 'month', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'month')
    );
  } else if (resolutionType === 'day') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('day'))
    );
    addTimeIncrement(offset, 'hours', 'day', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'days')
    );
  } else if (resolutionType === 'halfDay') {
    const offset = moment.duration(
      currentDate.diff(
        currentDate
          .clone()
          .startOf('day')
          .subtract(currentDate.clone().minute() % 12, 'hours')
      )
    );
    addTimeIncrement(offset, 'minutes', 'day', (currentDt, offst) =>
      currentDt.subtract(offst).add(12, 'hours')
    );
  } else if (resolutionType === 'hour') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('hour'))
    );
    addTimeIncrement(offset, 'minutes', 'hour', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'hours')
    );
  } else if (resolutionType === 'quarterOfAnHour') {
    const offset = moment.duration(
      currentDate.diff(
        currentDate
          .clone()
          .startOf('minute')
          .subtract(currentDate.clone().minute() % 15, 'minutes')
      )
    );
    addTimeIncrement(offset, 'minutes', 'hour', (currentDt, offst) =>
      currentDt.subtract(offst).add(15, 'minutes')
    );
  } else if (resolutionType === 'minute') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('minute'))
    );
    addTimeIncrement(offset, 'minutes', 'minute', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'minutes')
    );
  } else if (resolutionType === 'second') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('second'))
    );
    addTimeIncrement(offset, 'second', 'second', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'seconds')
    );
  } else if (resolutionType === 'millisecond') {
    addTimeIncrement(
      moment.duration(0),
      'millisecond',
      'millisecond',
      (currentDt, offst) => currentDt.add(1, 'milliseconds')
    );
  }
  return sectionSizes;
};

interface FilterAndSortItemsResult {
  rowOffset: number;
  item: TimelineItem;
}

export const calculateItemsRowOffset: (
  items: TimelineItem[],
  visibleTimeStart: Moment,
  visibleTimeEnd: Moment
) => FilterAndSortItemsResult[] = (items, visibleTimeStart, visibleTimeEnd) => {
  const filteredItems = filterTimelineItemsInRange(
    items,
    visibleTimeStart,
    visibleTimeEnd
  ).sort((a, b) => {
    const aDate = moment(a.startDateTime);
    const bDate = moment(b.startDateTime);
    return aDate.isBefore(bDate) ? -1 : 1;
  });

  let displayItems = [];
  let rowOffset = 0;
  let filteredItemsCopy = [...filteredItems];
  while (displayItems.length < filteredItems.length) {
    let lastEnd: Moment = null;
    let _filteredItemsCopy = [...filteredItemsCopy];
    for (let i = 0; i < _filteredItemsCopy.length; i++) {
      const item = _filteredItemsCopy[i];
      const { startDateTime, endDateTime, id } = item;
      if (!lastEnd || moment(startDateTime).isSameOrAfter(lastEnd)) {
        let result: FilterAndSortItemsResult = {
          item,
          rowOffset,
        };
        displayItems.push(result);
        lastEnd = moment(endDateTime);
        const index = filteredItemsCopy.findIndex(({ id: _id }) => id === _id);
        if (index > -1) {
          filteredItemsCopy.splice(index, 1);
        }
      }
    }
    rowOffset++;
  }
  return displayItems;
};

export const getSnapPixelFromDelta = (
  delta: number,
  pixelsPerMillisecond: number,
  snapMilliseconds = 0
) => {
  const pixelsPerSnapSegment = pixelsPerMillisecond * snapMilliseconds;
  return Math.round(delta / pixelsPerSnapSegment) * pixelsPerSnapSegment;
};

export const filterTimelineItemsInRange = (
  items: TimelineItem[],
  visibleTimeStart: Moment,
  visibleTimeEnd: Moment
) => {
  return items.filter(({ startDateTime, endDateTime }) => {
    const startDate = moment(startDateTime);
    const endDate = moment(endDateTime);
    return !(
      endDate.isBefore(visibleTimeStart) || startDate.isAfter(visibleTimeEnd)
    );
  });
};
