import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, MessageAttachment } from '../../../../models/Message';
import { readableMessageEventTime } from '../../../calendar/util';
import i18n from '../../../../i18n';

const firstTdStyle: CSSProperties = {
  width: '30%',
  fontWeight: 600,
  verticalAlign: 'top',
};

const secondTdStyle: CSSProperties = {
  width: '70%',
  verticalAlign: 'top',
};

const parseEmailAddress = (emailAddress: {
  name?: string;
  address?: string;
}) => {
  return emailAddress.name
    ? `${emailAddress.name}${
        emailAddress.address ? ` <${emailAddress.address}>` : ''
      }`
    : emailAddress.address ?? '';
};

const mapShowAsStatus = (
  showAs: 'Free' | 'Tentative' | 'Busy' | 'Oof' | 'WorkingElsewhere' | 'Unknown'
) => {
  switch (showAs) {
    case 'Free':
      return i18n.t('mail:print.event.showAs.free');
    case 'Tentative':
      return i18n.t('mail:print.event.showAs.tentative');
    case 'Busy':
      return i18n.t('mail:print.event.showAs.busy');
    case 'Oof':
      return i18n.t('mail:print.event.showAs.oof');
    case 'WorkingElsewhere':
      return i18n.t('mail:print.event.showAs.workingElsewhere');
    case 'Unknown':
      return i18n.t('mail:print.event.showAs.unknown');
    default:
      return '';
  }
};

const mapResponseStatus = (
  responseStatus:
    | 'None'
    | 'Organizer'
    | 'TentativelyAccepted'
    | 'Accepted'
    | 'Declined'
    | 'NotResponded'
) => {
  switch (responseStatus) {
    case 'None':
      return i18n.t('mail:print.event.response.none');
    case 'Organizer':
      return i18n.t('mail:print.event.response.organizer');
    case 'TentativelyAccepted':
      return i18n.t('mail:print.event.response.tentativelyAccepted');
    case 'Accepted':
      return i18n.t('mail:print.event.response.accepted');
    case 'Declined':
      return i18n.t('mail:print.event.response.declined');
    case 'NotResponded':
      return i18n.t('mail:print.event.response.notResponded');
    default:
      return '';
  }
};

interface EventOverviewProps {
  message: Message;
  messageAttachments: MessageAttachment[];
}

export const EventOverview: React.FC<EventOverviewProps> = (props) => {
  //#region ------------------------------ Defaults
  const { message } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const event = message.event;

  const requiredAttendees =
    event?.attendees?.filter(
      (attendee) =>
        attendee.type === 'Required' &&
        attendee.emailAddress.address !==
          event?.organizer?.emailAddress?.address
    ) ?? [];
  const optionalAttendees =
    event?.attendees?.filter(
      (attendee) =>
        attendee.type === 'Optional' &&
        attendee.emailAddress.address !==
          event?.organizer?.emailAddress?.address
    ) ?? [];
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <table
      style={{
        width: '100%',
      }}
    >
      <tbody>
        {event.location && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.event.location')}:`}</td>
            <td style={secondTdStyle}>{event.location.displayName}</td>
          </tr>
        )}
        <tr>
          <td
            style={{
              width: '30%',
            }}
          >{`${t('mail:print.event.time')}:`}</td>
          <td
            style={{
              width: '70%',
            }}
          >
            {readableMessageEventTime(event)}
          </td>
        </tr>
        {event.showAs && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.event.showAs')}:`}</td>
            <td style={secondTdStyle}>{mapShowAsStatus(event.showAs)}</td>
          </tr>
        )}
        {event.responseStatus?.response && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.event.response')}:`}</td>
            <td style={secondTdStyle}>
              {event.responseStatus.response &&
                mapResponseStatus(event.responseStatus.response)}
            </td>
          </tr>
        )}
        {event.organizer && (
          <tr>
            <td style={firstTdStyle}>{`${t(
              'mail:print.event.organizer'
            )}:`}</td>
            <td style={secondTdStyle}>
              {parseEmailAddress(event.organizer.emailAddress)}
            </td>
          </tr>
        )}
        {requiredAttendees.length > 0 && (
          <tr>
            <td style={firstTdStyle}>{`${t(
              'mail:print.event.requiredAttendees'
            )}:`}</td>
            <td style={secondTdStyle}>
              {requiredAttendees
                .map(({ emailAddress }) => parseEmailAddress(emailAddress))
                .join(', ')}
            </td>
          </tr>
        )}
        {optionalAttendees.length > 0 && (
          <tr>
            <td style={firstTdStyle}>{`${t(
              'mail:print.event.optionalAttendees'
            )}:`}</td>
            <td style={secondTdStyle}>
              {optionalAttendees
                .map(({ emailAddress }) => parseEmailAddress(emailAddress))
                .join(', ')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default EventOverview;
