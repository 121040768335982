import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { useDispatch } from 'react-redux';

import { NotificationId } from '../../../models/Types';
import { fetchNotifications } from '../actions';
import { NotificationTabs } from '../actions/drawer';
import { NotificationList } from './NotificationList';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  tabPane: {
    height: '100%',
    overflowY: 'scroll',
  },
}));

interface NotificationsTabsProps {
  className?: string;
  activeKey?: NotificationTabs;
  onChange?: (activeKey: string) => void;
  onNotificationClick?: (notificationId: NotificationId) => void;
  onClose?: VoidFunction;
}

export const NotificationsTabs: React.FC<NotificationsTabsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { className, activeKey, onChange, onNotificationClick, onClose } =
    props;

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchNotifications());
  }, [dispatch]);

  return (
    <Tabs
      className={classNames(classes.root, className)}
      animated={false}
      defaultActiveKey="all"
      activeKey={activeKey}
      onChange={onChange}
    >
      <TabPane
        tab={t('notifications:drawer.tabs.all')}
        key="all"
        className={classes.tabPane}
      >
        <NotificationList
          type="all"
          onNotificationClick={onNotificationClick}
          onClose={onClose}
        />
      </TabPane>
      <TabPane
        tab={t('notifications:drawer.tabs.absences')}
        key="absences"
        className={classes.tabPane}
      >
        <NotificationList
          type="absences"
          onNotificationClick={onNotificationClick}
          onClose={onClose}
        />
      </TabPane>
      <TabPane
        tab={t('notifications:drawer.tabs.trainings')}
        key="trainings"
        className={classes.tabPane}
      >
        <NotificationList
          type="trainings"
          onNotificationClick={onNotificationClick}
          onClose={onClose}
        />
      </TabPane>
    </Tabs>
  );
};

export default NotificationsTabs;
