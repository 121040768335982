import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  TextBlock,
} from '../../../models/TextBlock';
import { TextBlockId } from '../../../models/Types';
import {
  FetchTextBlocksAction,
  TextBlockAction,
  FETCH_TEXT_BLOCKS_COMMIT,
  UPDATE_TEXT_BLOCK_COMMIT,
  UPDATE_TEXT_BLOCK_REQUEST,
  UPDATE_TEXT_BLOCK_ROLLBACK,
  CREATE_TEXT_BLOCK_COMMIT,
  CREATE_TEXT_BLOCK_REQUEST,
  CREATE_TEXT_BLOCK_ROLLBACK,
  ARCHIVE_TEXT_BLOCK_REQUEST,
  ARCHIVE_TEXT_BLOCK_ROLLBACK,
  FETCH_TEXT_BLOCKS_ROLLBACK,
  FETCH_TEXT_BLOCKS_REQUEST,
} from '../actions/textBlocks';

export interface TextBlocksState {
  byId: TextBlocksByIdState;
  ids: string[];
  meta?: TextBlocksMeta;
}
export interface TextBlocksByIdState {
  [textBlockId: string]: TextBlock;
}

const byId: Reducer<
  TextBlocksByIdState,
  FetchTextBlocksAction & TextBlockAction
> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TEXT_BLOCKS_COMMIT: {
      const { payload } = action;
      return (payload as TextBlock[]).reduce(function (map, item) {
        map[item.textBlockId] = item;
        return map;
      }, {});
    }

    case CREATE_TEXT_BLOCK_REQUEST: {
      const {
        meta: { temporaryId },
        payload,
      } = action;
      return {
        ...state,
        [temporaryId]: {
          textBlockId: temporaryId,
          rowVersion: null,
          ...payload,
        },
      };
    }
    case CREATE_TEXT_BLOCK_COMMIT: {
      const {
        payload,
        payload: { textBlockId },
      } = action;
      if (state[textBlockId]) return state;
      return {
        ...state,
        [textBlockId]: payload,
      };
    }
    case UPDATE_TEXT_BLOCK_COMMIT:
    case UPDATE_TEXT_BLOCK_REQUEST: {
      const { payload } = action;
      const updatedTextBlock = payload as TextBlock;
      return {
        ...state,
        [updatedTextBlock.textBlockId]: updatedTextBlock,
      };
    }
    case UPDATE_TEXT_BLOCK_ROLLBACK: {
      const { rollbackTextBlock } = action;
      return {
        ...state,
        [rollbackTextBlock.textBlockId]: rollbackTextBlock,
      };
    }

    case ARCHIVE_TEXT_BLOCK_REQUEST: {
      const {
        meta: { textBlockId },
      } = action;
      const currentTextBlock = state[textBlockId];
      if (!currentTextBlock) return state;
      return {
        ...state,
        [textBlockId]: {
          ...currentTextBlock,
          isArchived: true,
        },
      };
    }
    case ARCHIVE_TEXT_BLOCK_ROLLBACK: {
      const {
        meta: { textBlockId },
      } = action;
      const currentTextBlock = state[textBlockId];
      if (!currentTextBlock) return state;
      return {
        ...state,
        [textBlockId]: {
          ...currentTextBlock,
          isArchived: false,
        },
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
}

const ids: Reducer<
  Array<string>,
  FetchTextBlocksAction & TextBlockAction
> = (state = [], action) => {
  switch (action.type) {
    case FETCH_TEXT_BLOCKS_COMMIT: {
      const { payload } = action;
      return payload.map((item) => item.textBlockId);
    }
    case CREATE_TEXT_BLOCK_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }
    case CREATE_TEXT_BLOCK_COMMIT: {
      const {
        meta: { temporaryId },
        payload: { textBlockId },
      } = action;
      if (state.includes(textBlockId)) return state;
      return [...state.filter((id) => id !== temporaryId), textBlockId];
    }
    case CREATE_TEXT_BLOCK_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return state.filter((id) => id !== temporaryId);
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

interface TextBlocksMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<TextBlocksMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_TEXT_BLOCKS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_TEXT_BLOCKS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_TEXT_BLOCKS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.fetchError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<TextBlocksState>({
  byId,
  ids,
  meta,
});

export const getTextBlockById: (
  state: TextBlocksState,
  textBlockId: TextBlockId,
) => TextBlock = (state, textBlockId) => state.byId[textBlockId];

export const getTextBlocks: (
  state: TextBlocksState
) => TextBlock[] = (state) => state.ids
  .filter((id) => !state.byId[id].isArchived)
  .map((id) => state.byId[id]);

export const getIsFetching: (state: TextBlocksState) => boolean = (state) => state.meta.isFetching;
export const getHasError: (state: TextBlocksState) => boolean = (state) => state.meta.hasError;
export const getErrorMessage: (state: TextBlocksState) => string = (state) => state.meta.errorMessage;