import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Table, Typography } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteProjects } from '../../../projects/selectors';
import { fetchMailFoldersSagaAction } from '../../../mail/actions/sagas';
import { Project } from '../../../../models/Project';
import { ColumnProps } from 'antd/lib/table';
import { ProjectId } from '../../../../models/Types';
import {
  getMailFolder,
  getMailFoldersIsFetching,
  getSpecialMailFolders,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { SpecialMailFolders } from '../../../mail/actions/types';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useNavigate } from 'react-router-dom';
import Flex from '../../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    '& .ant-table-wrapper': {
      flex: 1,
      overflow: 'hidden',
    },
    '& .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td':
      {
        padding: theme.old.spacing.baseSpacing,
      },
    '& .ant-spin-nested-loading': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-spin-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& ant-table-content': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& table': {
      height: '100%',
      overflow: 'auto',
    },
  },
}));

interface DashboardInboxOverviewItemProps {
  className?: string;
}

export const DashboardInboxOverviewItem: React.FC<
  DashboardInboxOverviewItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const projectFavorites = useSelector(getFavoriteProjects);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Columns
  const columns: ColumnProps<Project>[] = [
    {
      title: 'Projekt',
      dataIndex: 'projectNumber',
      render: (_, record) => {
        return `${record.number} ${record.shortName ?? record.name}`;
      },
      width: '70%',
      ellipsis: true,
    },
    {
      title: 'Ungelesen',
      dataIndex: 'projectId',
      width: '30%',
      render: (projectId) => <UnreadCount projectId={projectId} />,
    },
  ];
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setTimeout(() => {
      projectFavorites.forEach((project) => {
        dispatch(fetchMailFoldersSagaAction(project.projectId, false, 'inbox'));
      });
    }, 500);
  }, [projectFavorites, dispatch]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Typography.Title level={3} ellipsis style={{ marginBottom: 16 }}>
        Neue Nachrichten
      </Typography.Title>
      <Table<Project>
        columns={columns}
        dataSource={projectFavorites}
        onRow={(project: Project) => {
          return {
            onClick: (event) => {
              event.stopPropagation();
              event.preventDefault();
              navigate(`../../${project.projectId}/mail/inbox`);
            },
          };
        }}
        pagination={false}
        scroll={{ y: 'calc(100% - 37px)' }}
      />
    </Flex.Column>
  );
};

export default DashboardInboxOverviewItem;

interface UnreadCountProps {
  projectId: ProjectId;
}

const UnreadCount: React.FC<UnreadCountProps> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const specialMailFolders = useSelector<RootReducerState, SpecialMailFolders>(
    (state) => getSpecialMailFolders(state, projectId)
  );
  const isFetching = useSelector<RootReducerState, boolean>((state) =>
    getMailFoldersIsFetching(state, projectId)
  );
  const count = useSelector<RootReducerState, number>((state) =>
    specialMailFolders
      ? getMailFolder(state, specialMailFolders['inboxFolder']?.id, projectId)
          ?.unreadItemCount
      : 0
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return isFetching ? <PrioSpinner size="small" /> : <>{count}</>;
};
