import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';

/** current User Office fetching */
export const FETCH_OFFICE_ME_REQUEST = PRIO + 'FETCH_OFFICE_ME_REQUEST';
export const FETCH_OFFICE_ME_COMMIT = PRIO + 'FETCH_OFFICE_ME_COMMIT';
export const FETCH_OFFICE_ME_ROLLBACK = PRIO + 'FETCH_OFFICE_ME_ROLLBACK';

export const fetchOfficeMe = () => ({
  type: FETCH_OFFICE_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: { url: `${apiUrl}/user/User/currentUser/Office`, method: 'GET' },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_OFFICE_ME_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_OFFICE_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'users:errorMessages.fetchOfficeMeError',
          timeout: 6,
        },
      },
    },
  },
});
