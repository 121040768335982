import { PRIO } from '../../../constants';
import { Contact } from '../../../models/Contact';
import { ContactId, CompanyId } from '../../../models/Types';

export const OPEN_CONTACTS_DRAWER = PRIO + 'OPEN_CONTACTS_DRAWER';
export const CLOSE_CONTACTS_DRAWER = PRIO + 'CLOSE_CONTACTS_DRAWER';
export const SET_CONTACTS_DRAWER_TAB = PRIO + 'SET_CONTACTS_DRAWER_TAB';
export const SET_CONTACTS_DRAWER_VIEW = PRIO + 'SET_CONTACTS_DRAWER_VIEW';
export const SET_CONTACTS_DRAWER_STATE = PRIO + 'SET_CONTACTS_DRAWER_STATE';

export type ContactTabs = 'all' | 'companies' | 'contacts';
export type ContactDrawerView =
  | 'list'
  | 'contactDetail'
  | 'contactEdit'
  | 'contactNew'
  | 'companyDetail'
  | 'companyEdit'
  | 'companyNew';

interface PartialContactsDrawerState {
  tab?: ContactTabs;
  view?: ContactDrawerView;
  selectedContact?: ContactId;
  selectedCompany?: CompanyId;
  changedContact?: {
    contact: Contact;
    state: 'edited' | 'created' | 'archived' | 'unarchived';
  };
}
export interface OpenContactsDrawerAction {
  type: string;
  predefinedState?: PartialContactsDrawerState;
}
export interface SetContactsDrawerTabAction {
  type: string;
  tab: ContactTabs;
}
export interface SetContactsDrawerViewAction {
  type: string;
  view: ContactDrawerView;
}
export interface SetContactsDrawerStateAction {
  type: string;
  partialState: PartialContactsDrawerState;
}

export const openContactsDrawer = (
  predefinedState?: PartialContactsDrawerState
) => ({
  type: OPEN_CONTACTS_DRAWER,
  predefinedState,
});
export const closeContactsDrawer = () => ({
  type: CLOSE_CONTACTS_DRAWER,
});
export const setContactsDrawerState: (
  partialState: PartialContactsDrawerState
) => SetContactsDrawerStateAction = (
  partialState: PartialContactsDrawerState
) => ({
  type: SET_CONTACTS_DRAWER_STATE,
  partialState,
});
export const setContactsDrawerTab: (
  tab: ContactTabs
) => SetContactsDrawerTabAction = (tab: ContactTabs) => ({
  type: SET_CONTACTS_DRAWER_TAB,
  tab,
});
export const setContactsDrawerView: (
  tab: ContactDrawerView
) => SetContactsDrawerViewAction = (view: ContactDrawerView) => ({
  type: SET_CONTACTS_DRAWER_VIEW,
  view,
});
