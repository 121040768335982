import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Divider, Dropdown, Input, Menu, Tabs } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import {
  getActiveProject,
  getCompaniesByIdState,
  getGlobalSiderOpen,
  getProjectByIdState,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { setActiveProject } from '../actions';
import { isTemporaryId } from '../../../util';
import useDebounce from '../../../hooks/useDebounce';
import ProjectDropDownNavigation, {
  ProjectDropDownNavigationRefProps,
} from './ProjectNavigation/ProjectDropDownNavigation';
import { getFavoriteProjectsLength } from '../selectors';
import { getActiveProjectTabView } from '../../../apps/main/rootReducer';
import { ProjectId, ProjectTabViews } from '../../../models/Types';
import classNames from 'classnames';
import ProjectMainMenuCollapsed from './ProjectMainMenuCollapsed';
import {
  syncGlobalProjects,
  setActiveProjectTabView,
  fetchProjectNews,
} from '../../projects/actions';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';
import NavigationItemPanelHeaderMe from './ProjectNavigation/NavigationItemPanelHeaderMe';
import ProjectProfileMenu from './ProjectProfileMenu';
import XLSX from 'xlsx';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  rootSubMenu: {
    padding: theme.old.spacing.unit(1.5),
  },
  collapse: {
    flex: 1,
    overflow: 'hidden',
    paddingBottom: theme.old.spacing.unit(2),
  },
  search: {
    overflow: 'hidden',
    transition: 'all 0.2s',
    minHeight: 32,
    '& .ant-input-search-button': {
      background: 'transparent',
    },
    '& .ant-input': {
      border: ({ isSubMenu }) =>
        `1px solid ${
          isSubMenu
            ? theme.old.borders.colors.content
            : theme.old.borders.colors.main
        }`,
    },
    '& .ant-input-affix-wrapper': {
      border: ({ isSubMenu }) =>
        `1px solid ${
          isSubMenu
            ? theme.old.borders.colors.content
            : theme.old.borders.colors.main
        }`,
    },
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: ({ isSubMenu }) =>
          `1px solid ${
            isSubMenu
              ? theme.old.borders.colors.content
              : theme.old.borders.colors.main
          }`,
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: '#dcf4ff',
        },
      },
    '& .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused': {
      boxShadow: 'none',
    },
  },
  tabs: {
    minHeight: 63,
    '& .ant-tabs-nav::before': {
      borderColor: ({ isSubMenu }) =>
        isSubMenu
          ? theme.old.borders.colors.content
          : theme.old.borders.colors.main,
    },
  },
  menu: {
    background: 'none',
    border: 'none',
    transition: 'padding 0.2s',
  },
  divider: {
    marginBottom: theme.old.spacing.unit(1),
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  exportIcon: {
    padding: theme.old.spacing.unit(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdownIcon: {
    fontSize: 15,
  },
  blackIcon: {
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
}));

interface ProjectsNavigationProps {
  className?: string;
  isSubMenu?: boolean;
  pathPrefix?: string;
}

export const ProjectsNavigation: React.FC<ProjectsNavigationProps> = (
  props
) => {
  const { isSubMenu, pathPrefix, className } = props;
  const { projectId, subModule } = useParams();
  return (
    <MemoizedProjectsNavigation
      className={className}
      isSubMenu={isSubMenu}
      pathPrefix={pathPrefix}
      projectId={projectId}
      subModule={subModule}
    />
  );
};

interface MemoizedProjectsNavigationProps {
  className?: string;
  isSubMenu?: boolean;
  pathPrefix?: string;
  projectId: ProjectId;
  subModule?: string;
}

const MemoizedProjectsNavigation: React.FC<MemoizedProjectsNavigationProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isSubMenu, pathPrefix, className, projectId, subModule } = props;
    const classes = useStyles({ isSubMenu });
    const theme = useTheme<PrioTheme>();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const ref = useRef<ProjectDropDownNavigationRefProps>(null);

    const activeProjectId = useSelector(getActiveProject);

    const globalSiderOpen = useSelector(getGlobalSiderOpen);

    const getProjects = useSelector(getProjectByIdState);
    const getCompanies = useSelector(getCompaniesByIdState);

    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce(
      searchInput.toLocaleLowerCase(),
      1000
    );
    const favoritesLength: number = useSelector(getFavoriteProjectsLength);
    const activeTab = useSelector(getActiveProjectTabView);

    const userMe = useSelector(getUserMe);
    const [archivedProjectsHidden, setArchivedProjectsHidden] =
      useState<boolean>(true);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const onSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.type === 'click') {
        setIsSearching(false);
        setSearchInput('');
      } else {
        if (!isSearching) {
          setIsSearching(true);
        }
        setSearchInput(e.currentTarget.value);
      }
    };
    const exportProjectList = () => {
      const projects = ref.current.getCurrentProjects();
      const projectsToExport = projects.map((project) => {
        return {
          [t('projects:exportProjectList.projectId')]: project.projectId,
          [t('projects:exportProjectList.number')]: project.number,
          [t('projects:exportProjectList.name')]: project.name,
          [t('projects:exportProjectList.shortName')]: project.shortName,
          [t('projects:exportProjectList.projectStatus')]:
            project.projectStatus,
          [t('projects:exportProjectList.isArchived')]:
            project.isArchived === true
              ? t('projects:exportProjectList.isArchivedTrue')
              : t('projects:exportProjectList.isArchivedFalse'),
          [t('projects:exportProjectList.subsidary.name')]:
            getCompanies[project.subsidiaryId]?.fullName,
          [t('projects:exportProjectList.subsidary.shortName')]:
            getCompanies[project.subsidiaryId]?.shortName,
          [t('projects:exportProjectList.company.name')]:
            getCompanies[project.companyId]?.fullName,
          [t('projects:exportProjectList.company.shortName')]:
            getCompanies[project.companyId]?.shortName,
          [t('projects:exportProjectList.parentProject.number')]:
            getProjects[project.parentProject]?.number ?? '-',
          [t('projects:exportProjectList.parentProject.shortName')]:
            getProjects[project.parentProject]?.shortName ?? '-',
        };
      });

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(projectsToExport);

      XLSX.utils.book_append_sheet(
        wb,
        ws,
        t('projects:exportProjectList.title')
      );
      XLSX.writeFile(wb, `${t('projects:exportProjectList.title')}.xlsx`);
    };

    const displayOrHideArchivedProjects = () => {
      setArchivedProjectsHidden(!archivedProjectsHidden);
    };
    //endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (
        projectId &&
        projectId !== 'create' &&
        !isTemporaryId(projectId) &&
        projectId !== activeProjectId
      ) {
        dispatch(setActiveProject(projectId));
      }
    }, [activeProjectId, projectId, dispatch]);

    useEffect(() => {
      if (activeTab === null && favoritesLength !== 0) {
        dispatch(setActiveProjectTabView('favorites'));
      } else if (activeTab !== null && favoritesLength === 0) {
        if (activeTab === 'favorites') {
          dispatch(setActiveProjectTabView('myProjects'));
        } else {
          dispatch(setActiveProjectTabView(activeTab as ProjectTabViews));
        }
      }
    }, [favoritesLength, activeTab, dispatch]);

    useEffect(() => {
      if (activeTab === 'all' || isSearching) {
        dispatch(syncGlobalProjects());
      }
      dispatch(fetchProjectNews());
    }, [activeTab, isSearching, dispatch]);
    //#endregion

    //#region ------------------------------ Components
    const menuProjectList = (
      <Menu>
        <Menu.Item
          key={'exportProjects'}
          onClick={exportProjectList}
          icon={
            <FontAwesomeIcon
              className={classes.dropdownIcon}
              icon={['fal', 'file-export']}
            />
          }
        >
          {t('projects:navigation.dropDownMenu.exportProjectList')}
        </Menu.Item>
        <Menu.Item
          key={'showArchivedProjects'}
          onClick={displayOrHideArchivedProjects}
          icon={
            <FontAwesomeIcon
              className={classes.dropdownIcon}
              icon={['fal', 'box-archive']}
            />
          }
        >
          {archivedProjectsHidden
            ? t('projects:navigation.dropDownMenu.showArchivedProjects')
            : t('projects:navigation.dropDownMenu.hideArchivedProjects')}
        </Menu.Item>
      </Menu>
    );
    //endregion
    return (
      <Flex.Column
        flexGrow={1}
        className={classNames(classes.root, className, {
          [classes.rootSubMenu]: isSubMenu,
        })}
        childrenGap={theme.old.spacing.unit(1)}
      >
        {(isSubMenu || globalSiderOpen) && (
          <div>
            <NavigationItemPanelHeaderMe
              rootPath={pathPrefix}
              user={userMe}
              isSubMenu={isSubMenu}
              isActive={activeProjectId === 'me'}
            />
            {!isSubMenu && activeProjectId === 'me' && (
              <Flex.Item
                flex={1}
                padding={`0 ${theme.old.spacing.defaultPadding}px`}
              >
                <ProjectProfileMenu
                  urlPrefix={`${pathPrefix}me/`}
                  selectedList={subModule}
                  className={classes.menu}
                  hideTrainings
                  hidePersonalFile
                />
              </Flex.Item>
            )}
          </div>
        )}

        {isSearching && (isSubMenu || globalSiderOpen) && (
          <>
            <Divider className={classes.divider} />
            <Input.Search
              size="middle"
              placeholder={t('projects:navigation.searchPlaceHolder')}
              value={searchInput}
              onChange={onSearchInputChange}
              className={classes.search}
              style={{
                padding: globalSiderOpen
                  ? `0 ${theme.old.spacing.defaultPadding}px`
                  : undefined,
              }}
              autoFocus={isSearching}
              suffix={
                isSearching && (
                  <FontAwesomeIcon
                    icon={['fas', 'times-circle']}
                    onClick={() => {
                      setSearchInput('');
                      setIsSearching(false);
                    }}
                    color={theme.old.typography.colors.muted}
                    className={classes.hover}
                  />
                )
              }
            />
          </>
        )}

        <Flex.Column
          className={classes.collapse}
          padding={
            globalSiderOpen
              ? `0 ${theme.old.spacing.defaultPadding}px ${theme.old.spacing.defaultPadding}px`
              : !isSubMenu
              ? `0 ${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
                  1.5
                )}px`
              : undefined
          }
        >
          {!isSearching && (isSubMenu || globalSiderOpen) && (
            <Tabs
              className={classes.tabs}
              style={{
                margin: 0,
              }}
              animated={false}
              activeKey={activeTab ?? 'myProjects'}
              onTabClick={(activeKey) => {
                dispatch(setActiveProjectTabView(activeKey as ProjectTabViews));
              }}
              tabBarExtraContent={{
                right: (
                  <Flex.Row
                    childrenGap={theme.old.spacing.unit(0.5)}
                    alignItems="center"
                  >
                    <Button
                      onClick={() => {
                        if (!isSearching) {
                          setIsSearching(true);
                        }
                      }}
                      iconProp={['fal', 'search']}
                      className={classes.blackIcon}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                    />
                    <Dropdown
                      overlay={menuProjectList}
                      trigger={['click']}
                      placement="bottomRight"
                    >
                      <FontAwesomeIcon
                        icon={['fal', 'ellipsis-v']}
                        className={classes.exportIcon}
                      ></FontAwesomeIcon>
                    </Dropdown>
                  </Flex.Row>
                ),
              }}
            >
              <Tabs.TabPane
                tab={t('projects:navigation.favorites')}
                key="favorites"
                disabled={favoritesLength === 0}
              />
              <Tabs.TabPane
                tab={t('projects:navigation.myProjects')}
                key="myProjects"
              />
              <Tabs.TabPane tab={t('projects:navigation.all')} key="all" />
            </Tabs>
          )}
          {!isSubMenu && !globalSiderOpen ? (
            <ProjectMainMenuCollapsed
              activeProjectId={activeProjectId}
              selectedSubModule={subModule}
              pathPrefix={pathPrefix}
              activeTab={activeTab}
            />
          ) : (
            <ProjectDropDownNavigation
              ref={ref}
              activeProjectId={activeProjectId}
              selectedSubModule={subModule}
              searchString={debouncedSearchInput}
              type={
                globalSiderOpen && isSearching
                  ? 'all'
                  : (activeTab as ProjectTabViews)
              }
              isSubMenu={isSubMenu}
              pathPrefix={pathPrefix}
              isSearching={isSearching}
              activeTab={activeTab}
              archivedProjectsHidden={archivedProjectsHidden}
            />
          )}
        </Flex.Column>
      </Flex.Column>
    );
  });

export default ProjectsNavigation;
