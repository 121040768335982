import { Reducer, combineReducers } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';

import { ContactId, OfficeId } from '../../../models/Types';
import { User } from '../../../models/User';
import {
  FETCH_USERS_COMMIT,
  FETCH_USERS_REQUEST,
  FETCH_USERS_ROLLBACK,
  UPDATE_USER_REQUEST,
  UPDATE_USER_ROLLBACK,
  UPDATE_USER_COMMIT,
  UPDATE_USER_GLOBAL_ROLES_REQUEST,
  UPDATE_USER_GLOBAL_ROLES_ROLLBACK,
  UPDATE_USER_OFFICE_ROLES_REQUEST,
  UPDATE_USER_OFFICE_ROLES_ROLLBACK,
  UPDATE_GLOBAL_USER_OFFICE_ROLES_ROLLBACK,
  UPDATE_GLOBAL_USER_OFFICE_ROLES_REQUEST,
} from '../actions/users';

interface UsersAction {
  type: string;
  payload: User[] & User;
  meta: {
    contactId?: ContactId;
    officeId?: OfficeId;
  };
  rollbackUser?: User;
}

export interface UsersState {
  byId: UsersByIdState;
  ids: Array<string>;
}

export interface UsersByIdState {
  [contactId: string]: User;
}

const byId: Reducer<UsersByIdState, UsersAction> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERS_COMMIT: {
      const { payload } = action;
      return payload.reduce(function (map, item) {
        map[item.id] = item;
        return map;
      }, {});
    }
    // case CREATE_USER_REQUEST: {
    //   const {
    //     payload,
    //     meta: { contactId: temporaryId },
    //   } = action;
    //   return {
    //     ...state,
    //     [temporaryId]: {
    //       contactId: temporaryId,
    //       ...payload,
    //     },
    //   };
    // }
    // case CREATE_USER_ROLLBACK: {
    //   const {
    //     meta: { contactId: temporaryId },
    //   } = action;
    //   if (!state[temporaryId]) return state;
    //   const { [temporaryId]: _, ...newState } = state;
    //   return newState;
    // }
    // case CREATE_USER_COMMIT: {
    //   const {
    //     payload,
    //     payload: { contactId },
    //     meta: { contactId: temporaryId },
    //   } = action;
    //   const { [temporaryId]: _, ...newState } = state;
    //   return {
    //     ...newState,
    //     [contactId]: payload,
    //   };
    // }
    case UPDATE_USER_REQUEST: {
      const {
        payload,
        meta: { contactId },
      } = action;
      return {
        ...state,
        [contactId]: payload,
      };
    }
    case UPDATE_USER_ROLLBACK: {
      const {
        meta: { contactId },
        rollbackUser,
      } = action;
      if (!state[contactId]) return state;
      return {
        ...state,
        [contactId]: rollbackUser,
      };
    }
    case UPDATE_USER_COMMIT: {
      const {
        payload,
        payload: { id: contactId },
      } = action;
      return {
        ...state,
        [contactId]: payload,
      };
    }
    case UPDATE_USER_GLOBAL_ROLES_REQUEST: {
      const {
        payload,
        meta: { contactId },
      } = action;
      if (!state[contactId]) return state;
      return {
        ...state,
        [contactId]: {
          ...state[contactId],
          prioData: {
            ...(state[contactId]?.prioData ?? {}),
            globalRoles: payload,
          },
        },
      };
    }
    case UPDATE_USER_GLOBAL_ROLES_ROLLBACK: {
      const {
        meta: { contactId },
        rollbackUser,
      } = action;
      if (!state[contactId]) return state;
      return {
        ...state,
        [contactId]: rollbackUser,
      };
    }
    case UPDATE_GLOBAL_USER_OFFICE_ROLES_REQUEST:
    case UPDATE_USER_OFFICE_ROLES_REQUEST: {
      const {
        payload,
        meta: { contactId, officeId },
      } = action;
      if (!state[contactId]) return state;
      const { [officeId]: empty_, ...restState} = state[contactId]?.prioData.officeRoles;
      return !payload || payload.length === 0 ? 
      {
        ...state,
        [contactId]: {
          ...state[contactId],
          prioData: {
            ...(state[contactId]?.prioData ?? {}),
            officeRoles: {
              ...restState,
            },
          },
        },
      }
      : {
        ...state,
        [contactId]: {
          ...state[contactId],
          prioData: {
            ...(state[contactId]?.prioData ?? {}),
            officeRoles: {
              ...(state[contactId]?.prioData.officeRoles ?? {}),
              [officeId]: payload,
            },
          },
        },
      };
    }
    case UPDATE_GLOBAL_USER_OFFICE_ROLES_ROLLBACK:
    case UPDATE_USER_OFFICE_ROLES_ROLLBACK: {
      const {
        meta: { contactId },
        rollbackUser,
      } = action;
      if (!state[contactId]) return state;
      return {
        ...state,
        [contactId]: rollbackUser,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { };
    }
    default:
      return state;
  }
};

const ids: Reducer<Array<string>, UsersAction> = (state = [], action) => {
  switch (action.type) {
    case FETCH_USERS_COMMIT: {
      const { payload } = action;
      return payload.map((item) => item.id);
    }

    // case CREATE_USER_REQUEST: {
    //   const {
    //     meta: { contactId },
    //   } = action;
    //   if (state.includes(contactId)) return state;
    //   return [...state, contactId];
    // }
    // case CREATE_USER_COMMIT: {
    //   const {
    //     payload: { contactId },
    //     meta: { contactId: temporaryId },
    //   } = action;
    //   if (state.includes(contactId)) return state;
    //   return [
    //     ...state.filter((id) => id !== temporaryId),
    //     contactId,
    //   ];
    // }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

interface PrioUsersMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<PrioUsersMeta, any> = (state = { isFetching: false, hasError: false }, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_USERS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_USERS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'users:errorMessages.fetchUsersError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  ids,
  meta,
});

export const getPrioUsers: (state: UsersState) => User[] = (state) =>
  state.ids.map((id) => state.byId[id]).filter((entry) => !!entry);

export const getPrioUser: (state: UsersState, contactId: ContactId) => User = (
  state,
  contactId
) => state.byId[contactId];

export const getPrioUsersById: (state: UsersState) => UsersByIdState = (
  state
) => state.byId;
export const getPrioUsersIds: (state: UsersState) => ContactId[] = (state) =>
  state.ids;

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
