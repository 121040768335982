import React, { useState } from 'react';
import { Select } from 'antd';
import { makePrioStyles } from '../theme/utils';
import classnames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

interface PrioAutoCompleteProps {
  className?: string;
  options: PrioAutoCompleteOption[];
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

export interface PrioAutoCompleteOption {
  value: string;
  label: string;
}

export const PrioAutoComplete: React.FC<PrioAutoCompleteProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    options: initialOptions,
    value,
    onChange: handleChangeFromParent,
    disabled,
  } = props;
  const classes = useStyles();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [options, setOptions] = useState<PrioAutoCompleteOption[]>(
    initialOptions.sort((a, b) => a.label.localeCompare(b.label)) ?? []
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onChange = (option: PrioAutoCompleteOption) => {
    if (option.value === 'temporary-id') {
      if (options.some((x) => x.value === 'temporary-id')) {
        const newOptions = options.map((x) => {
          if (x.value === 'temporary-id') {
            return {
              ...x,
              label: option.label,
            };
          }
          return x;
        });
        setOptions(newOptions);
      } else {
        setOptions([
          ...options,
          {
            value: 'temporary-id',
            label: option.label,
          },
        ]);
      }
    }
    handleChangeFromParent(option.label);
  };
  //#endregion

  //#region ------------------------------ Effects

  //#endregion

  return (
    <Select
      disabled={disabled}
      className={classnames(classes.root, className)}
      suffixIcon={null}
      showSearch
      options={options}
      value={value}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          onChange({
            value: 'temporary-id',
            label: e.currentTarget.value,
          });
        }
      }}
      filterOption={(input, option) =>
        ((option?.label ?? '') as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) => {
        return (optionA.label as string).localeCompare(optionB.label as string);
      }}
      onChange={(v, o: PrioAutoCompleteOption) => {
        onChange(o);
      }}
    />
  );
};

export default PrioAutoComplete;
