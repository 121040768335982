import { ProjectFileSystemStructure } from '../../../models/Settings';
import { Reducer, combineReducers } from 'redux';
import {
  FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_COMMIT,
  CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT,
  CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST,
  CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK,
  UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT,
  UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST,
  UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK,
  FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_REQUEST,
  FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_ROLLBACK,
  DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK,
  DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT,
  DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST,
} from '../actions/projectFileSystemStructure';
import { ProjectFileSystemStructureId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE, RESET_META } from '../../../actions';

interface ProjectFileSystemStructureAction {
  type: string;
  payload: ProjectFileSystemStructure[] & ProjectFileSystemStructure;
  meta: {
    projectFileSystemStructureId?: ProjectFileSystemStructureId;
  };
  rollbackProjectFileSystemStructure?: ProjectFileSystemStructure;
}

export interface ProjectFileSystemStructureState {
  byId: ProjectFileSystemStructureByIdState;
  ids: Array<string>;
  meta?: ProjectFileSystemStructureMeta;
}

export interface ProjectFileSystemStructureByIdState {
  [projectFileSystemStructureId: string]: ProjectFileSystemStructure;
}

const byId: Reducer<
  ProjectFileSystemStructureByIdState,
  ProjectFileSystemStructureAction
> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_COMMIT: {
      const { payload } = action;
      return payload.reduce(function (map, item) {
        map[item.projectFileSystemStructureId] = item;
        return map;
      }, {});
    }
    case CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST: {
      const {
        payload,
        meta: { projectFileSystemStructureId: temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: {
          projectFileSystemStructureId: temporaryId,
          ...payload,
        },
      };
    }
    case CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK: {
      const {
        meta: { projectFileSystemStructureId: temporaryId },
      } = action;
      if (!state[temporaryId]) return state;
      const { [temporaryId]: _, ...newState } = state;
      return newState;
    }
    case CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT: {
      const {
        payload,
        payload: { projectFileSystemStructureId },
        meta: { projectFileSystemStructureId: temporaryId },
      } = action;
      const { [temporaryId]: _, ...newState } = state;
      return {
        ...newState,
        [projectFileSystemStructureId]: payload,
      };
    }
    case UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST: {
      const {
        payload,
        meta: { projectFileSystemStructureId },
      } = action;
      return {
        ...state,
        [projectFileSystemStructureId]: payload,
      };
    }
    case DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK:
    case UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK: {
      const {
        meta: { projectFileSystemStructureId },
        rollbackProjectFileSystemStructure,
      } = action;
      if (!state[projectFileSystemStructureId]) return state;
      return {
        ...state,
        [projectFileSystemStructureId]: rollbackProjectFileSystemStructure,
      };
    }
    case UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT: {
      const {
        payload,
        payload: { projectFileSystemStructureId },
      } = action;
      return {
        ...state,
        [projectFileSystemStructureId]: payload,
      };
    }

    case DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT: {
      const {
        meta: { projectFileSystemStructureId },
      } = action;
      const {
        [projectFileSystemStructureId]: stateToRemove,
        ...remainingState
      } = state;
      return remainingState;
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const ids: Reducer<Array<string>, ProjectFileSystemStructureAction> = (
  state = [],
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_COMMIT: {
      const { payload } = action;
      return payload.map((item) => item.projectFileSystemStructureId);
    }

    case CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST: {
      const {
        meta: { projectFileSystemStructureId },
      } = action;
      if (state.includes(projectFileSystemStructureId)) return state;
      return [...state, projectFileSystemStructureId];
    }
    case CREATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT: {
      const {
        payload: { projectFileSystemStructureId },
        meta: { projectFileSystemStructureId: temporaryId },
      } = action;
      if (state.includes(projectFileSystemStructureId)) return state;
      return [
        ...state.filter((id) => id !== temporaryId),
        projectFileSystemStructureId,
      ];
    }
    case DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_REQUEST: {
      const {
        meta: { projectFileSystemStructureId },
      } = action;
      if (!state.includes(projectFileSystemStructureId)) return state;
      return state.filter((id) => id !== projectFileSystemStructureId);
    }
    case DELETE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK: {
      const {
        meta: { projectFileSystemStructureId },
      } = action;
      if (state.includes(projectFileSystemStructureId)) return state;
      return [...state, projectFileSystemStructureId];
    }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

export interface ProjectFileSystemStructureMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
  hasUpdateError: boolean;
}

const meta: Reducer<ProjectFileSystemStructureMeta, any> = (
  state = { isFetching: false, hasError: false, hasUpdateError: false },
  action
) => {
  switch (action.type) {
    case RESET_META: {
      return { isFetching: false, hasError: false, hasUpdateError: false };
    }
    case FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_PROJECT_FILE_SYSTEM_STRUCTURES_ROLLBACK: {
      return {
        ...state,
        hasError: true,
        isFetching: false,
        errorMessage:
          'settings:errorMessages.fetchProjectFileSystemStructuresError',
      };
    }
    case UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_ROLLBACK: {
      return {
        ...state,
        hasUpdateError: true,
      };
    }
    case UPDATE_PROJECT_FILE_SYSTEM_STRUCTURE_COMMIT: {
      return {
        ...state,
        hasUpdateError: false,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false, hasUpdateError: false };
    }
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  ids,
  meta,
});

export const getProjectFileSystemStructures: (
  state: ProjectFileSystemStructureState
) => ProjectFileSystemStructure[] = (state) =>
  state.ids.map((id) => state.byId[id]).filter((entry) => !!entry);

export const getProjectFileSystemStructure: (
  state: ProjectFileSystemStructureState,
  projectFileSystemStructureId: ProjectFileSystemStructureId
) => ProjectFileSystemStructure = (state, projectFileSystemStructureId) =>
  state.byId[projectFileSystemStructureId];

export const getIsFetching: (
  state: ProjectFileSystemStructureState
) => boolean = (state) => state.meta.isFetching;
export const getHasError: (
  state: ProjectFileSystemStructureState
) => boolean = (state) => state.meta.hasError;
export const getHasUpdateError: (
  state: ProjectFileSystemStructureState
) => boolean = (state) => state.meta.hasUpdateError;
export const getErrorMessage: (
  state: ProjectFileSystemStructureState
) => string = (state) => state.meta.errorMessage;
