import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import {
  ZendeskHelpCenterSearchRequest,
  ZendeskRequest,
} from '../../../models/Zendesk';
import fetchWithRetry from '../../../util/fetchWithRetry';
import {
  PrioReleaseNotes,
  PrioReleases,
} from '../../../models/Prio365Features';

export const apiSearchZendeskResults: (
  searchString: string,
  locale?: string
) => Promise<ApiResult<ZendeskHelpCenterSearchRequest>> = async (
  searchString,
  locale
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/support/support/prioHelp?query=${searchString}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiZendeskRequest: (
  request: ZendeskRequest
) => Promise<boolean> = async (request) => {
  const result = await fetchWithRetry(`${apiUrl}/support/Support/me/request`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(request),
  });

  return result.status >= 200 && result.status < 300;
};

export const apiZendeskAttachmentUploadUrl: () => string = () =>
  `${apiUrl}/support/Support/attachment`;

export const apiZendeskDeleteAttachment: (
  attachmentToken: string
) => Promise<boolean> = async (attachmentToken) => {
  const result = await fetchWithRetry(
    `${apiUrl}/support/Support/attachment/${attachmentToken}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );

  return result.status >= 200 && result.status < 300;
};

///api/support/Support/releases
/*
returns 
public class PrioReleasesDto
{
    public List<string> Releases { get; set; }
}
*/

export const apiSupportGetReleases: () => Promise<
  ApiResult<PrioReleases>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/support/Support/releases`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSupportGetReleaseNotes: (
  release: string
) => Promise<ApiResult<PrioReleaseNotes>> = async (release) => {
  const result = await fetchWithRetry(
    `${apiUrl}/support/Support/releases/${release}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
