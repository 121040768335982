import { PRIO } from '../../../constants';
import { ReduxAction } from '../../../models/Redux';
import {
  DriveItemId,
  GroupId,
  PrioFileUploadStatus,
} from '../../../models/Types';
import { PrioFileList } from '../reducers/uploadLists';

export const ADD_PRIO_FILES_TO_UPLOAD_LIST =
  PRIO + 'ADD_PRIO_FILES_TO_UPLOAD_LIST';

export const addPrioFilesToUploadList: (
  groupId: GroupId,
  fileList: PrioFileList[]
) => ReduxAction = (groupId, fileList) => ({
  type: ADD_PRIO_FILES_TO_UPLOAD_LIST,
  groupId,
  fileList,
});

export const UPDATE_PRIO_FILE_UPLOAD_PROGRESS =
  PRIO + 'UPDATE_PRIO_FILE_UPLOAD_PROGRESS';

export const updatePrioFileUploadProgress: (
  groupId: GroupId,
  sessionId: string,
  parentDriveItemId: DriveItemId,
  driveItemId: DriveItemId,
  fileId: string,
  name: string,
  mimeType: string,
  path: string,
  size: number,
  uploadedSize: number,
  status: PrioFileUploadStatus,
  isFolder: boolean,
  errorString?: string
) => ReduxAction = (
  groupId,
  sessionId,
  parentDriveItemId,
  driveItemId,
  fileId,
  name,
  mimeType,
  path,
  size,
  uploadedSize,
  status,
  isFolder,
  errorString
) => ({
  type: UPDATE_PRIO_FILE_UPLOAD_PROGRESS,
  groupId,
  sessionId,
  parentDriveItemId,
  driveItemId,
  fileId,
  name,
  mimeType,
  path,
  size,
  uploadedSize,
  status,
  isFolder,
  errorString,
});

export const PURGE_PRIO_FILE_UPLOAD_LIST = PRIO + 'PURGE_PRIO_FILE_UPLOAD_LIST';

export const purgePrioFileUploadList: () => ReduxAction = () => ({
  type: PURGE_PRIO_FILE_UPLOAD_LIST,
});

export const DELETE_LOCAL_UPLOAD_LIST_PROGRESS =
  PRIO + 'DELETE_LOCAL_UPLOAD_LIST_PROGRESS';

export const deleteLocalUploadListProgress: () => ReduxAction = () => ({
  type: DELETE_LOCAL_UPLOAD_LIST_PROGRESS,
});
