import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import { makePrioStyles } from '../../theme/utils';

import ViewMailComposer from './../view/components/ViewMailComposer';
import ViewMessageView from './../view/components/ViewMessageView';
import CustomDragLayer from '../../dnd/components/CustomDragLayer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useBeforeUnloadBrowser from '../../hooks/useBeforeUnloadBrowser';
import * as Sentry from '@sentry/react';
import PreviewModal from '../documents/components/Modals/PreviewModal';
import MainProvider from '../main/MainProvider';
import classNames from 'classnames';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  mailComposer: {
    padding: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    overflow: 'auto',
  },
}));

export const View: React.FC<{}> = () => {
  const classes = useStyles();

  useBeforeUnloadBrowser();

  return (
    <MainProvider>
      <DndProvider backend={HTML5Backend}>
        <div className={classNames('prio-deprecated-design', classes.root)}>
          <CustomDragLayer />
          <PreviewModal />
          <Routes>
            <Route
              path=":projectId/composer/:messageId"
              element={<ViewMailComposer />}
            />
            <Route
              path=":projectId/message/:messageId/details"
              element={<ViewMessageView />}
            />
          </Routes>
        </div>
      </DndProvider>
    </MainProvider>
  );
};

export default View;
