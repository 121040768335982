import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface ProjectInformationMenuProps {
  selectedList?: string;
}

export const ProjectInformationMenu: React.FC<ProjectInformationMenuProps> = (
  props
) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="general">
        <Link to="../general">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'gauge']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.general')}
        </Link>
      </Menu.Item>
      <Menu.Item key="information">
        <Link to="../information">
          <FontAwesomeIcon
            icon={['fal', 'info-circle']}
            fixedWidth
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.info')}
        </Link>
      </Menu.Item>
      <Menu.Item key="description">
        <Link to="../description">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'pen-clip']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.description')}
        </Link>
      </Menu.Item>
      <Menu.Item key="times" disabled>
        <Link to="../times">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'calendar']}
            className={classes.icon}
            style={{ color: 'lightgrey' }}
          />
          {t('projects:projectSettingsNav.times')}
        </Link>
      </Menu.Item>
      <Menu.Item key="additional ">
        <Link to="../additional ">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'database']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.additionalInformation')}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default ProjectInformationMenu;
