import { PRIO } from '../../../constants';

export interface LastProfileLocation {
  type: string;
  profileLocation: string;
}

export const SET_LAST_PROFILE_LOCATION =
  PRIO + 'SET_LAST_PROFILE_LOCATION';

export const setLastProfileLocation: (profileLocation: string) => LastProfileLocation = (
  profileLocation: string
) => ({
  type: SET_LAST_PROFILE_LOCATION,
  profileLocation,
});