import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Bucket } from '../../../models/Planner';
import { BucketId, ProjectId } from '../../../models/Types';
import { apiFetchBucketsByProjectId } from '../api';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface BucketsSelectorProps {
  projectId: ProjectId;
  value?: BucketId;
  onChange?: (value: BucketId) => void;
  disabled?: boolean;
}

export const BucketsSelector: React.FC<BucketsSelectorProps> = (props) => {
  const classes = useStyles();
  const { value, onChange, projectId, disabled } = props;

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [buckets, setBuckets] = useState<Bucket[]>([]);

  useEffect(() => {
    const fetchBuckets = async () => {
      setIsFetching(true);
      const { data } = await apiFetchBucketsByProjectId(projectId);
      if (data) {
        setBuckets(data);
      }
      setIsFetching(false);
    };
    fetchBuckets();
  }, [projectId]);

  const handleOnChange = (value: BucketId) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Select
      className={classes.root}
      value={value}
      onChange={handleOnChange}
      loading={isFetching}
      disabled={disabled || isFetching}
    >
      {buckets.map((bucket) => (
        <Select.Option
          value={bucket.id}
          key={bucket.id}
          id={bucket.id}
        >{`${bucket.name}`}</Select.Option>
      ))}
    </Select>
  );
};

export default BucketsSelector;
