import React, { useState, useMemo, useEffect } from 'react';
import jsonMergePatch from 'json-merge-patch';

import {
  Typography,
  Select,
  Form,
  Row,
  Col,
  Divider,
  notification,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import InternalCompanyTable, { TableEntry } from './InternalCompanyTable';
import OfficeForm from './OfficeForm';
import Flex from '../../../components/Flex';
import { CompanyId } from '../../../models/Types';
import {
  InternalCompany,
  Company,
  UpdateInternalCompanyRequest,
  CreateInternalCompanyRequest,
  UpdateBankAccountRequest,
} from '../../../models/Company';
import {
  CreateInternalOfficeRequest,
  UpdateInternalOfficeRequest,
} from '../../../models/Office';
import {
  getAllCompanies,
  getCompany,
  getCompanyRedirect,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import equals from 'deep-equal';
import {
  updateInternalOffice,
  createInternalCompany,
  createInternalOffice,
  archiveCompany,
  createBankAccount,
  updateBankAccount,
} from '../actions';
import { createTemporaryId, isTemporaryId } from '../../../util';
import NavigationBar from '../../../components/NavigationBar';
import { rowGutter } from '../../../util/forms';
import PrioSpinner from '../../../components/PrioSpinner';
import InternalCompanyWrapperForm from './InternalCompanyWrapperForm';
import { apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import classNames from 'classnames';
import fetchWithRetry from '../../../util/fetchWithRetry';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  rowContent: {
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    overflow: 'hidden',
    position: 'relative',
  },
  panelChild: {
    width: panelWidth,
    overflow: 'hidden',
    background: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    borderLeft: theme.old.borders.sub,
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
  form: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  panelChildColumn: {
    height: '100%',
    overflow: 'hidden',
  },
  divider: {
    margin: '0px',
  },
  divider2: {
    marginTop: '6px',
    marginBottom: '12px',
  },
}));

const internalCompaniesSelector = createSelector<
  [(state: RootReducerState) => Company[]],
  Company[]
>(
  (state) => getAllCompanies(state),
  (companies) =>
    companies.filter((company) => company.companyType === 'InternalCompany')
);

const selectedEntryCompaniesSelector = (selectedEntry: TableEntry) =>
  createSelector<[(state: RootReducerState) => InternalCompany], TableEntry>(
    (state) =>
      selectedEntry?.company?.companyId
        ? getCompany(state, selectedEntry?.company?.companyId)
        : null,
    (company) => {
      return selectedEntry && selectedEntry.company
        ? {
            ...selectedEntry,
            company: {
              ...company,
              offices: isTemporaryId(selectedEntry.company.companyId)
                ? []
                : company.offices.filter((o) => o.isMainOffice),
            },
          }
        : selectedEntry;
    }
  );

const buildChildren: (
  companies: InternalCompany[],
  parentCompanyId: CompanyId
) => TableEntry[] = (
  companies: InternalCompany[],
  parentCompanyId: CompanyId
) =>
  companies
    .filter((c) => c.parentCompanyId === parentCompanyId)
    .map((company) => ({
      company,
      children: [
        ...buildChildren(companies, company.companyId),
        ...company.offices
          .filter((office) => !office.isMainOffice)
          .map((office) => ({ office })),
      ],
    }))
    //remove empty children
    .map((entry) => ({
      ...entry,
      children: entry.children?.length > 0 ? entry.children : null,
    }));

export type CompanyManagementAction = 'AddSubsidiary' | 'AddOffice' | null;

interface InternalCompanyManagementProps {
  className?: string;
}

export const InternalCompanyManagement: React.FC<
  InternalCompanyManagementProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [selectedEntry, setSelectedEntry] = useState<TableEntry>(null);
  const [companyExists, setCompanyExists] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(selectedEntry != null);
  const [companyAction, setCompanyAction] =
    useState<CompanyManagementAction>(null);

  const adaptedSelectedEntry = useSelector(
    selectedEntryCompaniesSelector(selectedEntry)
  );

  const internalCompanies = useSelector(internalCompaniesSelector);

  const rootCompany: InternalCompany = useMemo(
    () => internalCompanies.find((c) => c.parentCompanyId == null),
    [internalCompanies]
  );

  const rootEntry: TableEntry = useMemo(
    () =>
      rootCompany
        ? {
            company: rootCompany,
            children: [
              ...buildChildren(internalCompanies, rootCompany.companyId),
            ],
          }
        : null,
    [internalCompanies, rootCompany]
  );

  const width = open ? panelWidth : 0;
  const opacity = open ? '100%' : '0%';

  const redirect = useSelector<RootReducerState, string>((state) =>
    getCompanyRedirect(state, selectedEntry?.company?.companyId)
  );

  const selectedEntryParent = adaptedSelectedEntry
    ? adaptedSelectedEntry?.company?.parentCompanyId
      ? internalCompanies.find(
          (c) => c.companyId === adaptedSelectedEntry.company.parentCompanyId
        )
      : internalCompanies.find(
          (c) => c.companyId === adaptedSelectedEntry.office?.companyId
        )
    : null;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClose = () => {
    setOpen(false);
    setSelectedEntry(null);
  };

  const onRowClick = (entry: TableEntry) => {
    setCompanyAction(null);
    setSelectedEntry(entry);
    setOpen(true);
    setCompanyExists(true);
  };

  const onCreateOffice = (
    value: CreateInternalOfficeRequest,
    companyIdsToArchive?: CompanyId[]
  ) => {
    if (!selectedEntry?.company?.companyId) {
      console.warn('onCreateOffice called without company selected');
    }
    const temporaryId = createTemporaryId();
    dispatch(
      createInternalOffice(
        {
          ...value,
          companyId: selectedEntry.company.companyId,
          isMainOffice: false,
          officeType: 'internalOffice',
        },
        selectedEntry.company.companyId,
        temporaryId
      )
    );
    if (companyIdsToArchive) {
      companyIdsToArchive.forEach((companyId) =>
        dispatch(archiveCompany(companyId))
      );
    }
    handleClose();
  };
  const onCreateSubsidiary = (
    value: CreateInternalCompanyRequest,
    companyIdsToArchive?: CompanyId[]
  ) => {
    if (!selectedEntry?.company?.companyId) {
      console.warn('onCreateSubsidiary called without company selected');
    }
    const temporaryId = createTemporaryId();
    dispatch(
      createInternalCompany(
        {
          ...value,
          parentCompanyId: selectedEntry.company.companyId,
          offices: value.offices.map((o) => ({ ...o, isMainOffice: true })),
        },
        temporaryId
      )
    );
    if (companyIdsToArchive) {
      companyIdsToArchive.forEach((companyId) =>
        dispatch(archiveCompany(companyId))
      );
    }
    setCompanyExists(true);
    onRowClick({
      company: {
        ...value,
        companyId: temporaryId,
        parentCompanyId: selectedEntry.company.companyId,
        offices: value.offices.map((o) => ({
          ...o,
          isMainOffice: true,
          isArchived: false,
          rowVersion: null,
        })),
        companyType: 'InternalCompany',
        company: {
          offices: value.offices.map((o) => ({
            ...o,
            isMainOffice: true,
            isArchived: false,
            rowVersion: null,
          })),
        },
      },
    });
  };
  const onUpdateInternalCompany = async (value: InternalCompany) => {
    let successfulCompanyChange = false;
    let errorCompanyChange = false;
    let officeRowVersion = '';

    if (!adaptedSelectedEntry.company) return;
    const company = adaptedSelectedEntry.company;
    const {
      companyId,
      rowVersion,
      offices: currentOffices,
      bankAccounts: currentBankAccounts,
      parentCompanyId,
      fullName2,
      companyType,
      vatNumber,
      isArchived,
      ...currentCompany
    } = company;
    const {
      offices: updatedOffices,
      bankAccounts: updatedBankAccounts,
      ...updatedCompany
    } = value;
    if (!equals(currentBankAccounts, updatedBankAccounts)) {
      updatedBankAccounts
        ?.filter((bankAccounts) => bankAccounts.bankAccountId === undefined)
        .forEach((bankAccounts) => {
          const temporaryId = createTemporaryId();
          dispatch(
            createBankAccount(
              { ...bankAccounts, companyId: company.companyId },
              temporaryId
            )
          );
        });
    }
    if (!equals(currentBankAccounts, updatedBankAccounts)) {
      updatedBankAccounts
        ?.filter((bankAccounts) => bankAccounts.bankAccountId !== undefined)
        .forEach((bankAccounts) => {
          const currentBankAccount = currentBankAccounts.find(
            (currentBankAccount) =>
              currentBankAccount.bankAccountId === bankAccounts.bankAccountId
          );
          if (currentBankAccount && !equals(currentBankAccount, bankAccounts)) {
            dispatch(
              updateBankAccount(
                jsonMergePatch.generate(
                  currentBankAccount,
                  bankAccounts
                ) as UpdateBankAccountRequest,
                companyId,
                bankAccounts.rowVersion,
                bankAccounts
              )
            );
          }
        });
    }

    officeRowVersion = '';
    successfulCompanyChange = false;
    errorCompanyChange = false;

    if (!equals(currentCompany, updatedCompany)) {
      dispatch({
        type: 'PRIO_UPDATE_COMPANY_REQUEST',
        payload: {
          updateRequest: jsonMergePatch.generate(
            currentCompany,
            updatedCompany
          ) as UpdateInternalCompanyRequest,
        },
        meta: { companyId: company.companyId },
      });

      setIsLoading(true);
      const result = await fetchWithRetry(
        `${apiUrl}/contact/Company/internalCompany/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'Content-Type': 'application/merge-patch+json',
          },
          method: 'PATCH',
          body: JSON.stringify({
            ...(jsonMergePatch.generate(
              currentCompany,
              updatedCompany
            ) as UpdateInternalCompanyRequest),
            rowVersion: company.rowVersion,
          } as any),
        }
      );
      setIsLoading(false);

      if (result.status >= 200 && result.status < 300) {
        successfulCompanyChange = true;

        const data = await result.json();
        officeRowVersion = data.offices[0].rowVersion;
        dispatch({
          type: 'PRIO_UPDATE_COMPANY_COMMIT',
          meta: { companyId },
          payload: data,
        });
      } else {
        errorCompanyChange = true;
        dispatch({
          type: 'PRIO_UPDATE_COMPANY_ROLLBACK',
          meta: { companyId },
          rollbackCompany: company,
        });
      }
    }

    updatedOffices?.forEach(async (updatedOffice) => {
      const currentOffice = currentOffices.find(
        (o) => o.officeId === updatedOffice.officeId
      );

      if (!equals(currentOffice, updatedOffice) && officeRowVersion !== '') {
        dispatch(
          updateInternalOffice(
            {
              ...currentOffice,
              ...updatedOffice,
              officeType: 'internalOffice',
            },
            company.companyId,
            currentOffice.officeId,
            officeRowVersion ? officeRowVersion : currentOffice.rowVersion,
            currentOffice
          )
        );
      }
    });

    if (errorCompanyChange) {
      notification.open({
        message: t('common:error'),
        description: t('companies:errorMessages.updateError'),
      });
    } else if (successfulCompanyChange) {
      setOpen(false);
    }

    successfulCompanyChange = false;
    errorCompanyChange = false;
    officeRowVersion = '';
  };

  const onUpdateOffice = (value: UpdateInternalOfficeRequest) => {
    if (!adaptedSelectedEntry.office) return;
    const office = adaptedSelectedEntry.office;
    const {
      officeId,
      companyId,
      isArchived,
      rowVersion,
      isMainOffice,
      ...currentOffice
    } = office;
    const { companyId: _, ...updatedOffice } = value;

    if (!equals(currentOffice, updatedOffice)) {
      dispatch(
        updateInternalOffice(
          { ...office, ...updatedOffice, officeType: 'internalOffice' },
          companyId,
          officeId,
          rowVersion,
          office
        )
      );
    }
    handleClose();
  };

  const clickAddSubsidiary = () => {
    setCompanyExists(false);
    setCompanyAction('AddSubsidiary');
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (redirect) {
      setSelectedEntry({
        ...selectedEntry,
        company: { ...selectedEntry?.company, companyId: redirect },
      });
    }
  }, [redirect, selectedEntry]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>
        <Button
          disabled={!selectedEntry?.company}
          onClick={clickAddSubsidiary}
          type="default"
          iconProp={['fal', 'building']}
        >
          <span>{t('companies:addSubsidiary')}</span>
        </Button>
        <Button
          disabled={!selectedEntry?.company}
          onClick={() => setCompanyAction('AddOffice')}
          type="default"
          iconProp={['fal', 'map-marker-alt']}
        >
          <span>{t('companies:addOffice')}</span>
        </Button>
      </NavigationBar>
      <Flex.Row flex={1} className={classes.rowContent}>
        <div className={classes.content}>
          {rootEntry ? (
            <InternalCompanyTable
              rootEntry={rootEntry}
              onRowClick={onRowClick}
              selectedEntry={selectedEntry}
            />
          ) : (
            <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
              <PrioSpinner size="large" />
            </div>
          )}
        </div>
        <div className={classes.panel} style={{ width: width, opacity }}>
          <div className={classes.panelChild}>
            {companyAction === 'AddSubsidiary' && (
              <Flex.Column
                childrenGap={theme.old.spacing.unit(2)}
                className={classes.panelChildColumn}
              >
                <Typography.Title level={2}>
                  {t('companies:titles.newSubsidiary')}
                </Typography.Title>
                <Form layout="vertical" style={{ marginBottom: '0px' }}>
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={12}>
                      <Form.Item label={t('companies:parentCompany')}>
                        <Select
                          value={selectedEntry?.company?.fullName}
                          disabled
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t('companies:type')}>
                        <Select
                          value={t('companies:subsidiary')}
                          disabled
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Divider style={{ margin: '0px' }} />
                {/* create new internal company */}
                <InternalCompanyWrapperForm
                  className={classes.form}
                  actionLabel={t('common:actions.save')}
                  cancelLabel={t('common:actions.cancel')}
                  onFinish={onCreateSubsidiary}
                  onCancel={handleClose}
                  adaptedSelectedEntry={adaptedSelectedEntry}
                  companyExists={companyExists}
                />
              </Flex.Column>
            )}
            {companyAction === 'AddOffice' && (
              <Flex.Column
                childrenGap={theme.old.spacing.unit(2)}
                className={classes.panelChildColumn}
              >
                <Typography.Title level={2}>
                  {t('companies:titles.newOffice')}
                </Typography.Title>
                <Form layout="vertical">
                  <Row gutter={theme.old.spacing.unit(rowGutter)}>
                    <Col span={12}>
                      <Form.Item label={t('companies:parentCompany')}>
                        <Select
                          value={selectedEntry?.company?.fullName}
                          disabled
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t('companies:type')}>
                        <Select
                          value={t('companies:subsidiary')}
                          disabled
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Divider />
                <OfficeForm
                  className={classes.form}
                  actionLabel={t('common:actions.save')}
                  cancelLabel={t('common:actions.cancel')}
                  onFinish={onCreateOffice}
                  onCancel={handleClose}
                />
              </Flex.Column>
            )}
            {adaptedSelectedEntry && !companyAction ? (
              adaptedSelectedEntry.company ? (
                <Flex.Column
                  childrenGap={theme.old.spacing.unit(2)}
                  className={classes.panelChildColumn}
                >
                  <Typography.Title level={2}>
                    {adaptedSelectedEntry.company.fullName}
                  </Typography.Title>
                  <Form layout="vertical">
                    <Row gutter={theme.old.spacing.unit(rowGutter)}>
                      <Col span={12}>
                        <Form.Item label={t('companies:parentCompany')}>
                          <Select
                            value={selectedEntryParent?.fullName}
                            disabled
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={t('companies:type')}>
                          <Select
                            value={
                              selectedEntryParent
                                ? t('companies:subsidiary')
                                : t('companies:parentCompany')
                            }
                            disabled
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <Divider style={{ margin: '0px' }} />
                  <InternalCompanyWrapperForm
                    className={classes.form}
                    actionLabel={t('common:actions.save')}
                    cancelLabel={t('common:actions.cancel')}
                    onFinish={onUpdateInternalCompany}
                    onCancel={handleClose}
                    initialValues={adaptedSelectedEntry.company}
                    disableForm={isTemporaryId(
                      adaptedSelectedEntry.company.companyId
                    )}
                    adaptedSelectedEntry={adaptedSelectedEntry}
                    companyExists={true}
                    isLoading={isLoading}
                  />
                </Flex.Column>
              ) : (
                <Flex.Column
                  childrenGap={theme.old.spacing.unit(2)}
                  className={classes.panelChildColumn}
                >
                  <Typography.Title level={2}>
                    {adaptedSelectedEntry.office.name}
                  </Typography.Title>
                  <Form layout="vertical">
                    <Row gutter={theme.old.spacing.unit(rowGutter)}>
                      <Col span={12}>
                        <Form.Item label={t('companies:parentCompany')}>
                          <Select
                            value={selectedEntryParent?.fullName}
                            disabled
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={t('companies:type')}>
                          <Select
                            value={t('companies:office')}
                            disabled
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                  <Divider className={classes.divider2} />
                  <OfficeForm
                    className={classes.form}
                    initialValues={adaptedSelectedEntry.office}
                    disableForm={isTemporaryId(
                      adaptedSelectedEntry.office.officeId
                    )}
                    actionLabel={t('common:actions.save')}
                    cancelLabel={t('common:actions.cancel')}
                    onFinish={onUpdateOffice}
                    onCancel={handleClose}
                  />
                </Flex.Column>
              )
            ) : null}
          </div>
          <Button
            onClick={handleClose}
            shape="circle"
            iconProp={['fal', 'times']}
            className={classes.closeButton}
            type="link"
          />
        </div>
      </Flex.Row>
    </Flex.Column>
  );
};

export default InternalCompanyManagement;
