import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  InfiniteLoader,
  AutoSizer,
  List,
  ListRowProps,
  Index,
  IndexRange,
  ArrowKeyStepper,
  ScrollIndices,
} from 'react-virtualized';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
}));

const handleScrollToChange = async <T extends object>(
  nextIndex: number,
  currentIndex: number,
  list: List,
  items: T[],
  setScrollToRow: (index: number) => void,
  onActiveChange?: (index: number, previousIndex: number) => void
) => {
  setScrollToRow(nextIndex);
  if (list) {
    list.scrollToRow(nextIndex);
  }
  if (items.length > 0 && onActiveChange) {
    onActiveChange(nextIndex, currentIndex);
  }
};

interface VirtualListProps<T extends object> {
  className?: string;
  items: Array<T>;
  rowRenderer: (
    props: ListRowProps,
    activeIndex: number,
    onActiveChanged?: (index: number) => void
  ) => React.ReactNode;
  rowHeight: number | ((value: { index: number }) => number);
  overscanRowCount?: number;
  isRowLoaded?: (params: Index) => boolean;
  loadMoreRows?: (params: IndexRange) => Promise<any>;
  totalItemCount?: number;
  threshold?: number;
  registerList?: (element: any) => void;
  onActiveChange?: (index: number, previousIndex: number) => void;
}

function VirtualList<T extends object>(props: VirtualListProps<T>) {
  //#region ------------------------------ Defaults
  const {
    className,
    items,
    rowRenderer,
    rowHeight,
    overscanRowCount,
    isRowLoaded,
    loadMoreRows,
    totalItemCount,
    threshold,
    registerList,
    onActiveChange,
  } = props;
  const classes = useStyles();

  const ref = useRef<List>(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [scrollToRow, setScrollToRow] = useState<number>(-1);

  const rowCount = useMemo(
    () => totalItemCount ?? items.length + (threshold ?? 0),
    [totalItemCount, items, threshold]
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onScrollToChange = ({ scrollToRow: _scrollToRow }: ScrollIndices) => {
    handleScrollToChange(
      _scrollToRow,
      scrollToRow,
      ref?.current,
      items,
      setScrollToRow,
      onActiveChange
    );
  };

  const onActiveChanged = useCallback(
    (index: number) => {
      if (index !== scrollToRow) {
        setScrollToRow(index);
      }
    },
    [scrollToRow]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <ArrowKeyStepper
      className={classNames(classes.root, className)}
      rowCount={rowCount}
      columnCount={1}
      scrollToColumn={0}
      scrollToRow={scrollToRow}
      onScrollToChange={onScrollToChange}
      mode="cells"
      isControlled
    >
      {({ onSectionRendered }) => {
        return (
          <InfiniteLoader
            isRowLoaded={
              isRowLoaded ??
              (({ index }) => index < (totalItemCount ?? items.length))
            }
            loadMoreRows={
              loadMoreRows ?? (async ({ startIndex, stopIndex }) => {})
            }
            rowCount={rowCount}
            threshold={threshold}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    rowCount={items.length}
                    rowHeight={rowHeight}
                    height={height}
                    width={width}
                    overscanRowCount={overscanRowCount}
                    onRowsRendered={onRowsRendered}
                    ref={(element) => {
                      if (registerList) {
                        registerList(element);
                      }
                      registerChild(element);
                      ref.current = element;
                    }}
                    rowRenderer={(props: ListRowProps) =>
                      rowRenderer(props, scrollToRow, onActiveChanged)
                    }
                    onSectionRendered={onSectionRendered}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        );
      }}
    </ArrowKeyStepper>
  );
}

export default React.memo(VirtualList) as typeof VirtualList;
