import React from 'react';
import { Company } from '../../../models/Company';
import { CompanyId } from '../../../models/Types';

export interface CompaniesContextProps {
  companies: Company[];
  getCompanyById: (id: CompanyId) => Company | undefined;
  getCompanyByProperty: (
    property: keyof Company,
    value: string
  ) => Company | undefined;
}

const INITIAL_COMPANIES_CONTEXT_VALUE: CompaniesContextProps = {
  companies: [],
  getCompanyById: () => undefined,
  getCompanyByProperty: () => undefined,
};

export const CompaniesContext = React.createContext<CompaniesContextProps>(
  INITIAL_COMPANIES_CONTEXT_VALUE
);
