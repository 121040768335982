import { PRIO } from '../../../constants';
import { ReduxAction } from '../../../models/Redux';

export const UPDATE_IS_DOCUMENT_META_DATA_SAVING =
  PRIO + 'UPDATE_IS_DOCUMENT_META_DATA_SAVING';

export const updateIsDocumentMetaDataSaving: (
  isSaving: boolean
) => ReduxAction<{
  isSaving: boolean;
}> = (isSaving) => ({
  type: UPDATE_IS_DOCUMENT_META_DATA_SAVING,
  meta: {
    isSaving,
  },
});
