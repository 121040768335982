import { PRIO } from '../../../constants';
import {
  CreateExternalCompanyRequest,
  UpdateExternalCompanyRequest,
  Company,
  UpdateInternalCompanyRequest,
  CreateInternalCompanyRequest,
  CreateaBankAccountRequest,
  UpdateBankAccountRequest,
  BankAccount,
} from '../../../models/Company';
import { apiUrl } from '../../../api';
import { BankAccountId, CompanyId, OfficeId } from '../../../models/Types';
import {
  CreateExternalOfficeRequest,
  CreateInternalOfficeRequest,
  ExternalOffice,
  InternalOffice,
  OfficeFetchType,
  UpdateExternalOfficeRequest,
} from '../../../models/Office';

/** Sync Action for Saga */
export const SYNC_COMPANIES = PRIO + 'SYNC_COMPANIES';

export const syncCompanies = () => ({ type: SYNC_COMPANIES });

/** Company fetching */
export const FETCH_COMPANIES_REQUEST = PRIO + 'FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_COMMIT = PRIO + 'FETCH_COMPANIES_COMMIT';
export const FETCH_COMPANIES_ROLLBACK = PRIO + 'FETCH_COMPANIES_ROLLBACK';

export const fetchCompanies = (offset?: string) => ({
  type: FETCH_COMPANIES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/company?includeArchived=true${
          offset ? `&offset=${offset}` : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_COMPANIES_COMMIT,
        meta: {
          offset,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_COMPANIES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
    offset,
  },
});

/** Company creation */
export const CREATE_COMPANY_REQUEST = PRIO + 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_COMMIT = PRIO + 'CREATE_COMPANY_COMMIT';
export const CREATE_COMPANY_ROLLBACK = PRIO + 'CREATE_COMPANY_ROLLBACK';

export const createExternalCompany = (
  company: CreateExternalCompanyRequest,
  temporaryId
) => ({
  type: CREATE_COMPANY_REQUEST,
  requiresAuth: true,
  payload: { company: { ...company, companyType: 'ExternalCompany' } },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Company/externalCompany/`,
        method: 'POST',
        json: company,
      },
      // action to dispatch when effect succeeds:
      commit: { type: CREATE_COMPANY_COMMIT, meta: { temporaryId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_COMPANY_ROLLBACK,
        meta: { temporaryId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.createError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

export const createInternalCompany = (
  company: CreateInternalCompanyRequest,
  temporaryId
) => ({
  type: CREATE_COMPANY_REQUEST,
  requiresAuth: true,
  payload: { company: { ...company, companyType: 'InternalCompany' } },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Company/internalCompany/`,
        method: 'POST',
        json: company,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_COMPANY_COMMIT,
        meta: { temporaryId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_COMPANY_ROLLBACK,
        meta: { temporaryId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.createError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

/** Company update */
export const UPDATE_COMPANY_REQUEST = PRIO + 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_COMMIT = PRIO + 'UPDATE_COMPANY_COMMIT';
export const UPDATE_COMPANY_ROLLBACK = PRIO + 'UPDATE_COMPANY_ROLLBACK';

export const updateExternalCompany = (
  updateRequest: UpdateExternalCompanyRequest,
  companyId: CompanyId,
  rowVersion: string,
  rollbackCompany: Company
) => ({
  type: UPDATE_COMPANY_REQUEST,
  requiresAuth: true,
  payload: { updateRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Company/externalCompany/${companyId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: { ...updateRequest, rowVersion },
      },
      // action to dispatch when effect succeeds:
      commit: { type: UPDATE_COMPANY_COMMIT, meta: { companyId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_COMPANY_ROLLBACK,
        meta: { companyId },
        rollbackCompany,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
    companyId,
  },
});

export const REPORT_COMPANY_REQUEST = PRIO + 'REPORT_COMPANY_REQUEST';
export const REPORT_COMPANY_COMMIT = PRIO + 'REPORT_COMPANY_COMMIT';
export const REPORT_COMPANY_ROLLBACK = PRIO + 'REPORT_COMPANY_ROLLBACK';

export const reportCompany = (companyId: CompanyId) => ({
  type: REPORT_COMPANY_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Company/report/${companyId}`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: REPORT_COMPANY_COMMIT,
        meta: { companyId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: REPORT_COMPANY_ROLLBACK,
        meta: { companyId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.reportError',
          timeout: 6,
        },
      },
    },
    companyId,
  },
});

export const updateInternalCompany = (
  updateRequest: UpdateInternalCompanyRequest,
  companyId: CompanyId,
  rowVersion: string,
  rollbackCompany: Company
) => ({
  type: UPDATE_COMPANY_REQUEST,
  requiresAuth: true,
  payload: { updateRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Company/internalCompany/${companyId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: { ...updateRequest, rowVersion },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_COMPANY_COMMIT,
        meta: { companyId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_COMPANY_ROLLBACK,
        meta: { companyId },
        rollbackCompany,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
    companyId,
  },
});

/** archive company */
export const ARCHIVE_COMPANY_REQUEST = PRIO + 'ARCHIVE_COMPANY_REQUEST';
export const ARCHIVE_COMPANY_COMMIT = PRIO + 'ARCHIVE_COMPANY_COMMIT';
export const ARCHIVE_COMPANY_ROLLBACK = PRIO + 'ARCHIVE_COMPANY_ROLLBACK';

export const archiveCompany = (companyId: CompanyId) => ({
  type: ARCHIVE_COMPANY_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Company/${companyId}/archive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_COMPANY_COMMIT,
        meta: { companyId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_COMPANY_ROLLBACK,
        meta: { companyId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.archiveError',
          timeout: 6,
        },
      },
    },
    companyId,
  },
});

/** unarchive company */
export const UNARCHIVE_COMPANY_REQUEST = PRIO + 'UNARCHIVE_COMPANY_REQUEST';
export const UNARCHIVE_COMPANY_COMMIT = PRIO + 'UNARCHIVE_COMPANY_COMMIT';
export const UNARCHIVE_COMPANY_ROLLBACK = PRIO + 'UNARCHIVE_COMPANY_ROLLBACK';

export const unarchiveCompany = (companyId: CompanyId) => ({
  type: UNARCHIVE_COMPANY_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Company/${companyId}/unarchive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UNARCHIVE_COMPANY_COMMIT,
        meta: { companyId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UNARCHIVE_COMPANY_ROLLBACK,
        meta: { companyId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.unarchiveError',
          timeout: 6,
        },
      },
    },
    companyId,
  },
});

/** Office creation */
export const CREATE_INTERNAL_OFFICE_REQUEST =
  PRIO + 'CREATE_INTERNAL_OFFICE_REQUEST';
export const CREATE_INTERNAL_OFFICE_COMMIT =
  PRIO + 'CREATE_INTERNAL_OFFICE_COMMIT';
export const CREATE_INTERNAL_OFFICE_ROLLBACK =
  PRIO + 'CREATE_INTERNAL_OFFICE_ROLLBACK';

export const createInternalOffice = (
  office: CreateInternalOfficeRequest,
  companyId: CompanyId,
  temporaryId: OfficeId
) => ({
  type: CREATE_INTERNAL_OFFICE_REQUEST,
  requiresAuth: true,
  payload: { office },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/InternalOffice`,
        method: 'POST',
        json: office,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_INTERNAL_OFFICE_COMMIT,
        meta: { companyId, temporaryId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_INTERNAL_OFFICE_ROLLBACK,
        meta: { companyId, temporaryId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.createOfficeError',
          timeout: 6,
        },
      },
    },
    companyId,
    temporaryId,
  },
});

/** Internal Office creation */
export const CREATE_EXTERNAL_OFFICE_REQUEST =
  PRIO + 'CREATE_EXTERNAL_OFFICE_REQUEST';
export const CREATE_EXTERNAL_OFFICE_COMMIT =
  PRIO + 'CREATE_EXTERNAL_OFFICE_COMMIT';
export const CREATE_EXTERNAL_OFFICE_ROLLBACK =
  PRIO + 'CREATE_EXTERNAL_OFFICE_ROLLBACK';

export const createExternalOffice = (
  office: CreateExternalOfficeRequest,
  companyId: CompanyId,
  temporaryId: OfficeId
) => ({
  type: CREATE_EXTERNAL_OFFICE_REQUEST,
  requiresAuth: true,
  payload: { office },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/ExternalOffice`,
        method: 'POST',
        json: office,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_EXTERNAL_OFFICE_COMMIT,
        meta: { companyId, temporaryId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_EXTERNAL_OFFICE_ROLLBACK,
        meta: { companyId, temporaryId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.createOfficeError',
          timeout: 6,
        },
      },
    },
    companyId,
    temporaryId,
  },
});

/** External Office update */
export const UPDATE_EXTERNAL_OFFICE_REQUEST =
  PRIO + 'UPDATE_EXTERNAL_OFFICE_REQUEST';
export const UPDATE_EXTERNAL_OFFICE_COMMIT =
  PRIO + 'UPDATE_EXTERNAL_OFFICE_COMMIT';
export const UPDATE_EXTERNAL_OFFICE_ROLLBACK =
  PRIO + 'UPDATE_EXTERNAL_OFFICE_ROLLBACK';

export const updateExternalOffice = (
  updateRequest: UpdateExternalOfficeRequest,
  companyId: CompanyId,
  officeId: OfficeId,
  rowVersion: string,
  rollbackOffice: ExternalOffice
) => ({
  type: UPDATE_EXTERNAL_OFFICE_REQUEST,
  requiresAuth: true,
  payload: { updateRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/ExternalOffice/${officeId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        json: { ...updateRequest, rowVersion },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_EXTERNAL_OFFICE_COMMIT,
        meta: { companyId, officeId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_EXTERNAL_OFFICE_ROLLBACK,
        meta: { companyId, officeId },
        rollbackOffice,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
    companyId,
    officeId,
  },
});

/** Internal Office update */
export const UPDATE_INTERNAL_OFFICE_REQUEST =
  PRIO + 'UPDATE_INTERNAL_OFFICE_REQUEST';
export const UPDATE_INTERNAL_OFFICE_COMMIT =
  PRIO + 'UPDATE_INTERNAL_OFFICE_COMMIT';
export const UPDATE_INTERNAL_OFFICE_ROLLBACK =
  PRIO + 'UPDATE_INTERNAL_OFFICE_ROLLBACK';

export const updateInternalOffice = (
  updateRequest: UpdateExternalOfficeRequest,
  companyId: CompanyId,
  officeId: OfficeId,
  rowVersion: string,
  rollbackOffice: InternalOffice
) => ({
  type: UPDATE_INTERNAL_OFFICE_REQUEST,
  requiresAuth: true,
  payload: { updateRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/InternalOffice/${officeId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        json: { ...updateRequest, rowVersion },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_INTERNAL_OFFICE_COMMIT,
        meta: { companyId, officeId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_INTERNAL_OFFICE_ROLLBACK,
        meta: { companyId, officeId },
        rollbackOffice,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
    companyId,
    officeId,
  },
});

/** Archive External Office */
export const ARCHIVE_EXTERNAL_OFFICE_REQUEST =
  PRIO + 'ARCHIVE_EXTERNAL_OFFICE_REQUEST';
export const ARCHIVE_EXTERNAL_OFFICE_COMMIT =
  PRIO + 'ARCHIVE_EXTERNAL_OFFICE_COMMIT';
export const ARCHIVE_EXTERNAL_OFFICE_ROLLBACK =
  PRIO + 'ARCHIVE_EXTERNAL_OFFICE_ROLLBACK';

export const archiveExternalOffice = (
  companyId: CompanyId,
  officeId: OfficeId,
  rollbackOffice: ExternalOffice
) => ({
  type: ARCHIVE_EXTERNAL_OFFICE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/ExternalOffice/${officeId}/archive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_EXTERNAL_OFFICE_COMMIT,
        meta: { companyId, officeId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_EXTERNAL_OFFICE_ROLLBACK,
        meta: { companyId, officeId },
        rollbackOffice,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.archiveOfficeError',
          timeout: 6,
        },
      },
    },
    companyId,
    officeId,
  },
});

/** Archive Internal Office */
export const ARCHIVE_INTERNAL_OFFICE_REQUEST =
  PRIO + 'ARCHIVE_INTERNAL_OFFICE_REQUEST';
export const ARCHIVE_INTERNAL_OFFICE_COMMIT =
  PRIO + 'ARCHIVE_INTERNAL_OFFICE_COMMIT';
export const ARCHIVE_INTERNAL_OFFICE_ROLLBACK =
  PRIO + 'ARCHIVE_INTERNAL_OFFICE_ROLLBACK';

export const archiveInternalOffice = (
  companyId: CompanyId,
  officeId: OfficeId,
  rollbackOffice: ExternalOffice
) => ({
  type: ARCHIVE_INTERNAL_OFFICE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/InternalOffice/${officeId}/archive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_INTERNAL_OFFICE_COMMIT,
        meta: { companyId, officeId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_INTERNAL_OFFICE_ROLLBACK,
        meta: { companyId, officeId },
        rollbackOffice,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.archiveOfficeError',
          timeout: 6,
        },
      },
    },
    companyId,
    officeId,
  },
});

/** External Offices fetching */
export const FETCH_EXTERNAL_OFFICES_REQUEST =
  PRIO + 'FETCH_EXTERNAL_OFFICES_REQUEST';
export const FETCH_EXTERNAL_OFFICES_COMMIT =
  PRIO + 'FETCH_EXTERNAL_OFFICES_COMMIT';
export const FETCH_EXTERNAL_OFFICES_ROLLBACK =
  PRIO + 'FETCH_EXTERNAL_OFFICES_ROLLBACK';

export const fetchExternalOffices = (officeFetchType?: OfficeFetchType) => ({
  type: FETCH_EXTERNAL_OFFICES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/ExternalOffice${
          officeFetchType ? `?${officeFetchType}=true` : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_EXTERNAL_OFFICES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_EXTERNAL_OFFICES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.fetchOfficesError',
          timeout: 6,
        },
      },
    },
  },
});

/** External Offices fetching */
export const FETCH_INTERNAL_OFFICES_REQUEST =
  PRIO + 'FETCH_INTERNAL_OFFICES_REQUEST';
export const FETCH_INTERNAL_OFFICES_COMMIT =
  PRIO + 'FETCH_INTERNAL_OFFICES_COMMIT';
export const FETCH_INTERNAL_OFFICES_ROLLBACK =
  PRIO + 'FETCH_INTERNAL_OFFICES_ROLLBACK';

export const fetchInternalOffices = (officeFetchType?: OfficeFetchType) => ({
  type: FETCH_INTERNAL_OFFICES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/InternalOffice${
          officeFetchType ? `?${officeFetchType}=true` : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_INTERNAL_OFFICES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_INTERNAL_OFFICES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.fetchOfficesError',
          timeout: 6,
        },
      },
    },
  },
});

/** External Office fetching */
export const FETCH_EXTERNAL_OFFICE_REQUEST =
  PRIO + 'FETCH_EXTERNAL_OFFICE_REQUEST';
export const FETCH_EXTERNAL_OFFICE_COMMIT =
  PRIO + 'FETCH_EXTERNAL_OFFICE_COMMIT';
export const FETCH_EXTERNAL_OFFICE_ROLLBACK =
  PRIO + 'FETCH_EXTERNAL_OFFICE_ROLLBACK';

export const fetchOffice = (officeId: OfficeId) => ({
  type: FETCH_EXTERNAL_OFFICES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/ExternalOffice/${officeId}`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_EXTERNAL_OFFICES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_EXTERNAL_OFFICES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.fetchOfficesError',
          timeout: 6,
        },
      },
    },
  },
});

//Post BankAccounts BankAccount Objekt mitgeben
export const CREATE_BANKACCOUNT_REQUEST = PRIO + 'CREATE_BANKACCOUNT_REQUEST';
export const CREATE_BANKACCOUNT_COMMIT = PRIO + 'CREATE_BANKACCOUNT_COMMIT';
export const CREATE_BANKACCOUNT_ROLLBACK = PRIO + 'CREATE_BANKACCOUNT_ROLLBACK';

export const createBankAccount = (
  bankAccount: CreateaBankAccountRequest,
  temporaryId
) => ({
  type: CREATE_BANKACCOUNT_REQUEST,
  requiresAuth: true,
  payload: { bankAccount: { ...bankAccount } },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/BankAccount`,
        method: 'POST',
        json: bankAccount,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_BANKACCOUNT_COMMIT,
        meta: { temporaryId, companyId: bankAccount.companyId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_BANKACCOUNT_ROLLBACK,
        meta: { temporaryId, companyId: bankAccount.companyId },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.createBankAccountError',
          timeout: 6,
        },
      },
    },
    temporaryId,
    companyId: bankAccount.companyId,
  },
});

/** Bank Account update */
export const UPDATE_BANKACCOUNT_REQUEST = PRIO + 'UPDATE_BANKACCOUNT_REQUEST';
export const UPDATE_BANKACCOUNT_COMMIT = PRIO + 'UPDATE_BANKACCOUNT_COMMIT';
export const UPDATE_BANKACCOUNT_ROLLBACK = PRIO + 'UPDATE_BANKACCOUNT_ROLLBACK';

export const updateBankAccount = (
  updateRequest: UpdateBankAccountRequest,
  companyId: CompanyId,
  rowVersion: string,
  rollbackBankAccount: BankAccount
) => ({
  type: UPDATE_BANKACCOUNT_REQUEST,
  requiresAuth: true,
  payload: { updateRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/BankAccount/${rollbackBankAccount.bankAccountId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: { ...updateRequest, rowVersion },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_BANKACCOUNT_COMMIT,
        meta: { companyId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_BANKACCOUNT_ROLLBACK,
        meta: { companyId, rollbackBankAccount },
        snackbarErrorMessage: {
          label: 'companies:errorMessages.updateBankAccountError',
          timeout: 6,
        },
      },
    },
    companyId,
  },
});

/** delete bank account */
export const DELETE_BANKACCOUNT_REQUEST = PRIO + 'DELETE_BANKACCOUNT_REQUEST';
export const DELETE_BANKACCOUNT_COMMIT = PRIO + 'DELETE_BANKACCOUNT_COMMIT';
export const DELETE_BANKACCOUNT_ROLLBACK = PRIO + 'DELETE_BANKACCOUNT_ROLLBACK';

export const deleteBankAccount = (
  companyId: CompanyId,
  bankAccountId: BankAccountId,
  rollbackBankAccount: BankAccount
) => ({
  type: DELETE_BANKACCOUNT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/BankAccount/${bankAccountId}`,
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_BANKACCOUNT_COMMIT,
        meta: { companyId, bankAccountId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_BANKACCOUNT_ROLLBACK,
        meta: { companyId, bankAccountId },
        rollbackBankAccount,
        snackbarErrorMessage: {
          label: 'companies:errorMessages.deleteBankAccountError',
          timeout: 6,
        },
      },
    },
    companyId,
    bankAccountId,
  },
});

export const WS_UPDATE_OR_ADD_COMPANY = PRIO + 'WS_UPDATE_OR_ADD_COMPANY';

export const wsUpdateOrAddCompany: (company: Company) => {
  type: string;
  company: Company;
} = (company) => ({
  type: WS_UPDATE_OR_ADD_COMPANY,
  company,
});
