import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';

/** Contact fetching */
export const FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_REQUEST =
  PRIO + 'FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_REQUEST';
export const FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_COMMIT =
  PRIO + 'FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_COMMIT';
export const FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_ROLLBACK =
  PRIO + 'FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_ROLLBACK';

export const fetchSpecialMailFoldersProject = (projectId: string) => ({
  type: FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/MailFolder/${projectId}/specialMailFolder`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_SPECIAL_MAIL_FOLDERS_ROLLBACK,
        meta: { projectId },
      },
    },
    projectId,
  },
});
