import useDocumentTemplateCategoryContext from '../../../modules/documents/hooks/useDocumentTemplateCategoryContext';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';

const DocumentTemplateCategoryFilterPickerConsumer: React.FC<
  FilterPickerConsumerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { data } = useDocumentTemplateCategoryContext();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: data.map(({ documentTemplateCategoryId, name }) => {
          return {
            value: documentTemplateCategoryId,
            label: name,
            searchValue: name,
          };
        }),
      })}
    </>
  );
};

export default DocumentTemplateCategoryFilterPickerConsumer;
