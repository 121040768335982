import { PRIO } from '../../../constants';

export interface LastSettingsLocation {
  type: string;
  settingsLocation: string;
}

export const SET_LAST_SETTINGS_LOCATION =
  PRIO + 'SET_LAST_SETTINGS_LOCATION';

export const setLastSettingsLocation: (settingLocation: string) => LastSettingsLocation = (
  settingsLocation: string
) => ({
  type: SET_LAST_SETTINGS_LOCATION,
  settingsLocation,
});