import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import {
  FlagMessagePayload,
  Message,
  MessageAttachment,
  MessageCategorization,
} from '../../../../models/Message';
import { DispatchAction } from '../../../../models/Redux';

export const FETCH_MESSAGES_PROJECT_REQUEST =
  PRIO + 'FETCH_MESSAGES_PROJECT_REQUEST';
export const FETCH_MESSAGES_PROJECT_COMMIT =
  PRIO + 'FETCH_MESSAGES_PROJECT_COMMIT';
export const FETCH_MESSAGES_PROJECT_ROLLBACK =
  PRIO + 'FETCH_MESSAGES_PROJECT_ROLLBACK';

export const fetchMessagesProject = (
  projectId: string,
  mailFolderId: string,
  sharedMailboxFolderId: string,
  movingMessageIds: MessageId[] = [],
  messageIdsToDelete: MessageId[] = [],
  resync: boolean = true,
  nextLink?: string
) => {
  var urlToFetch = `${apiUrl}/email/mailExtension/${projectId}/userId/${sharedMailboxFolderId}/folder/${mailFolderId}/message${
    !!nextLink ? `?skip=${nextLink}` : ''
  }`;

  return {
    type: FETCH_MESSAGES_PROJECT_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: urlToFetch,
          method: 'GET',
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: FETCH_MESSAGES_PROJECT_COMMIT,
          meta: {
            projectId,
            mailFolderId,
            nextLink,
            movingMessageIds,
            messageIdsToDelete,
            resync,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: FETCH_MESSAGES_PROJECT_ROLLBACK,
          snackbarErrorMessage: {
            label: 'mail:errorMessages.messages.fetchError',
            timeout: 6,
          },
          meta: {
            projectId,
            mailFolderId,
            nextLink,
            movingMessageIds,
            messageIdsToDelete,
            resync,
          },
        },
      },
      projectId,
      mailFolderId,
      nextLink,
      movingMessageIds,
      messageIdsToDelete,
      resync,
    },
  };
};

export const FETCH_MESSAGE_PROJECT_REQUEST =
  PRIO + 'FETCH_MESSAGE_PROJECT_REQUEST';
export const FETCH_MESSAGE_PROJECT_COMMIT =
  PRIO + 'FETCH_MESSAGE_PROJECT_COMMIT';
export const FETCH_MESSAGE_PROJECT_ROLLBACK =
  PRIO + 'FETCH_MESSAGE_PROJECT_ROLLBACK';

export const fetchMessageProject: (
  projectId: ProjectId,
  messageId: MessageId
) => DispatchAction<{ projectId: ProjectId; messageId: MessageId }, Message> = (
  projectId,
  messageId
) => ({
  type: FETCH_MESSAGE_PROJECT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/message/${messageId}`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_MESSAGE_PROJECT_COMMIT,
        meta: { projectId, messageId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MESSAGE_PROJECT_ROLLBACK,
        meta: { projectId, messageId },
      },
    },
    projectId,
    messageId,
  },
});

export const DELETE_MESSAGES_PROJECT_REQUEST =
  PRIO + 'DELETE_MESSAGES_PROJECT_REQUEST';
export const DELETE_MESSAGES_PROJECT_COMMIT =
  PRIO + 'DELETE_MESSAGES_PROJECT_COMMIT';
export const DELETE_MESSAGES_PROJECT_ROLLBACK =
  PRIO + 'DELETE_MESSAGES_PROJECT_ROLLBACK';

export const deleteMessagesProject = (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  messageIds: MessageId[]
) => ({
  type: DELETE_MESSAGES_PROJECT_REQUEST,
  requiresAuth: true,
  payload: { messageIds },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/message/delete`,
        method: 'DELETE',
        json: messageIds,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_MESSAGES_PROJECT_COMMIT,
        meta: { projectId, mailFolderId, messageIds },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_MESSAGES_PROJECT_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.deleteMessagesError',
          timeout: 6,
        },
        meta: { projectId, mailFolderId, messageIds },
      },
    },
    projectId,
    mailFolderId,
    messageIds,
  },
});

export const SOFTDELETE_MESSAGES_PROJECT_REQUEST =
  PRIO + 'SOFTDELETE_MESSAGES_PROJECT_REQUEST';
export const SOFTDELETE_MESSAGES_PROJECT_COMMIT =
  PRIO + 'SOFTDELETE_MESSAGES_PROJECT_COMMIT';
export const SOFTDELETE_MESSAGES_PROJECT_ROLLBACK =
  PRIO + 'SOFTDELETE_MESSAGES_PROJECT_ROLLBACK';

export const softdeleteMessagesProject = (
  projectId: ProjectId,
  messageIds: MessageId[],
  rollbackMessages: Message[],
  mailFolderId?: MailFolderId
) => ({
  type: SOFTDELETE_MESSAGES_PROJECT_REQUEST,
  requiresAuth: true,
  payload: { messageIds },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/message/softdelete`,
        method: 'POST',
        json: messageIds,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: SOFTDELETE_MESSAGES_PROJECT_COMMIT,
        meta: { projectId, mailFolderId, rollbackMessages, messageIds },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: SOFTDELETE_MESSAGES_PROJECT_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.deleteMessagesError',
          timeout: 6,
        },
        meta: { projectId, mailFolderId, rollbackMessages, messageIds },
      },
    },
    projectId,
    mailFolderId,
    rollbackMessages,
    messageIds,
  },
});

export const MOVED_MESSAGE_PROJECT = PRIO + 'MOVED_MESSAGE_PROJECT';

export const movedMessageProject = (
  projectId: ProjectId,
  messageIds: MessageId[],
  destinationId: MailFolderId,
  originId: MailFolderId
) => ({
  type: MOVED_MESSAGE_PROJECT,
  payload: {
    messageIds,
    destinationId,
  },
  meta: {
    projectId,
    originId,
  },
});

export const UPDATE_MESSAGE_PROJECT = PRIO + 'UPDATE_MESSAGE_PROJECT';

export const updateMessageProject = (
  projectId: ProjectId,
  messageId: MessageId,
  messageUpdate: Partial<Message>
) => ({
  type: UPDATE_MESSAGE_PROJECT,
  payload: {
    messageUpdate,
  },
  meta: {
    projectId,
    messageId,
  },
});

export const UPDATE_MESSAGES_PROJECT = PRIO + 'UPDATE_MESSAGES_PROJECT';

export const updateMessagesProject = (
  projectId: ProjectId,
  messageIds: MessageId[],
  messageUpdates: { messageId: MessageId; messageUpdate: Partial<Message> }[]
) => ({
  type: UPDATE_MESSAGES_PROJECT,
  payload: {
    messageUpdates,
  },
  meta: {
    projectId,
    messageIds,
  },
});

export const CATEGORIZED_MESSAGE_PROJECT = PRIO + 'CATEGORIZED_MESSAGE_PROJECT';

export const categorizedMessageProject = (
  messages: MessageCategorization,
  isDelete: boolean
) => ({
  type: CATEGORIZED_MESSAGE_PROJECT,
  payload: {
    messages,
    isDelete,
  },
});

export const MARK_AS_READ_REQUEST = PRIO + 'MARK_AS_READ_REQUEST';
export const MARK_AS_READ_COMMIT = PRIO + 'MARK_AS_READ_COMMIT';
export const MARK_AS_READ_ROLLBACK = PRIO + 'MARK_AS_READ_ROLLBACK';

export const markAsReadProject = (
  projectId: ProjectId,
  messageIds: MessageId[],
  isRead: boolean,
  mailFolderId: MailFolderId,
  changedUnreadItemCount: number
) => {
  const payload = messageIds.map((messageId) => ({ messageId, isRead }));
  return {
    type: MARK_AS_READ_REQUEST,
    requiresAuth: true,
    payload,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/email/Email/${projectId}/message/read`,
          method: 'PUT',
          json: { messages: payload },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: MARK_AS_READ_COMMIT,
          meta: {
            projectId,
            payload,
            mailFolderId,
            isRead,
            changedUnreadItemCount,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: MARK_AS_READ_ROLLBACK,
          snackbarErrorMessage: {
            label: 'mail:errorMessages.messages.markAsReadError',
            timeout: 6,
          },
          meta: { projectId, payload },
        },
      },
      projectId,
    },
  };
};

export const FLAG_MESSAGE_PROJECT_REQUEST =
  PRIO + 'FLAG_MESSAGE_PROJECT_REQUEST';
export const FLAG_MESSAGE_PROJECT_COMMIT = PRIO + 'FLAG_MESSAGE_PROJECT_COMMIT';
export const FLAG_MESSAGE_PROJECT_ROLLBACK =
  PRIO + 'FLAG_MESSAGE_PROJECT_ROLLBACK';

export const flagMessageProject = (
  projectId: ProjectId,
  payload: FlagMessagePayload,
  originalPayload: FlagMessagePayload
) => ({
  type: FLAG_MESSAGE_PROJECT_REQUEST,
  requiresAuth: true,
  payload,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/message/flag`,
        method: 'PUT',
        json: { messages: payload },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FLAG_MESSAGE_PROJECT_COMMIT,
        meta: { projectId, payload },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FLAG_MESSAGE_PROJECT_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.flagMessageError',
          timeout: 6,
        },
        meta: { projectId, originalPayload },
      },
    },
    projectId,
  },
});

export const DELETE_LOCAL_PROJECT_MESSAGES_BEFORE_INITIAL_INBOX_COMMIT_PROJECT =
  PRIO + 'DELETE_LOCAL_PROJECT_MESSAGES_BEFORE_INITIAL_INBOX_COMMIT_PROJECT';

export const deleteLocalProjectMessagesBeforeInitialInboxCommit = (
  projectId: ProjectId,
  messageIds: MessageId[]
) => ({
  type: DELETE_LOCAL_PROJECT_MESSAGES_BEFORE_INITIAL_INBOX_COMMIT_PROJECT,
  meta: {
    projectId,
    messageIds,
  },
});

export const SET_IS_FETCHING_PROJECT_MAILFOLDER =
  PRIO + 'SET_IS_FETCHING_PROJECT_MAILFOLDER';

export const setIsFetchingProjectMailFolder = (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  isFetching: boolean
) => ({
  type: SET_IS_FETCHING_PROJECT_MAILFOLDER,
  meta: {
    projectId,
    mailFolderId,
    isFetching,
  },
});

export const DELETE_LOCAL_MESSAGE_PROJECT =
  PRIO + 'DELETE_LOCAL_MESSAGE_PROJECT';

export const deleteLocalMessageProject = (
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  messageId: MessageId
) => ({
  type: DELETE_LOCAL_MESSAGE_PROJECT,
  meta: {
    projectId,
    mailFolderId,
    messageId,
  },
});

export const FETCH_MESSAGE_PROJECT_INLINE_IMAGE_REQUEST =
  PRIO + 'FETCH_MESSAGE_PROJECT_INLINE_IMAGE_REQUEST';
export const FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT =
  PRIO + 'FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT';
export const FETCH_MESSAGE_PROJECT_INLINE_IMAGE_ROLLBACK =
  PRIO + 'FETCH_MESSAGE_PROJECT_INLINE_IMAGE_ROLLBACK';

export const fetchMessageWithInlineImageProject = (
  projectId: string,
  messageId: string
) => ({
  type: FETCH_MESSAGE_PROJECT_INLINE_IMAGE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/message/${messageId}/InlineImages`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_MESSAGE_PROJECT_INLINE_IMAGE_COMMIT,
        meta: { projectId, messageId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MESSAGE_PROJECT_INLINE_IMAGE_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.fetchInlineImagesError',
          timeout: 6,
        },
        meta: { projectId, messageId },
      },
    },
    projectId,
    messageId,
  },
});

export const FETCH_MESSAGE_PROJECT_DECODE_MIME_COMMIT =
  PRIO + 'FETCH_MESSAGE_PROJECT_DECODE_MIME_COMMIT';

export const commitMessageWithMimeContentProject = (
  messageId: string,
  message: Message
) => ({
  type: FETCH_MESSAGE_PROJECT_DECODE_MIME_COMMIT,
  payload: message,
  meta: {
    messageId,
  },
});

export const PUT_MESSAGE_PROJECT_ATTACHMENT_TO_CACHE =
  PRIO + 'PUT_MESSAGE_PROJECT_ATTACHMENT_TO_CACHE';

export const putMessageProjectAttachmentToCache = (
  messageId: string,
  attachment: MessageAttachment[]
) => ({
  type: PUT_MESSAGE_PROJECT_ATTACHMENT_TO_CACHE,
  payload: attachment,
  meta: {
    messageId,
  },
});

export const COPY_MAIL_TO_PROJECT_PROJECT_REQUEST =
  PRIO + 'COPY_MAIL_TO_PROJECT_PROJECT_REQUEST';
export const COPY_MAIL_TO_PROJECT_PROJECT_COMMIT =
  PRIO + 'COPY_MAIL_TO_PROJECT_PROJECT_COMMIT';
export const COPY_MAIL_TO_PROJECT_PROJECT_ROLLBACK =
  PRIO + 'COPY_MAIL_TO_PROJECT_PROJECT_ROLLBACK';

export const copyMessageToProjectProject = (
  messages: Message[],
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  destinationMailFolderId: MailFolderId,
  targetProjectId: ProjectId,
  deleteMail: boolean
) => ({
  type: COPY_MAIL_TO_PROJECT_PROJECT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/messages/copyToProject/${targetProjectId}`,
        method: 'POST',
        json: {
          destinationMailFolderId,
          copyMessageDtos: messages.map((message) => ({
            messageId: message.id,
            deleteMail: deleteMail ?? false,
            copyAttachments: true,
          })),
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: COPY_MAIL_TO_PROJECT_PROJECT_COMMIT,
        meta: {
          deleteMail,
          mailFolderId,
          projectId,
          destinationMailFolderId,
          messages,
          targetProjectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: COPY_MAIL_TO_PROJECT_PROJECT_ROLLBACK,
        meta: {
          deleteMail,
          mailFolderId,
          projectId,
          destinationMailFolderId,
          messages,
          targetProjectId,
        },
        snackbarErrorMessage: {
          label: `mail:errorMessages.messages.${
            messages.length === 1
              ? 'copyMessageToProjectError'
              : 'copyMessagesToProjectError'
          }`,
          timeout: 6,
        },
      },
    },
    deleteMail,
    mailFolderId,
    projectId,
    destinationMailFolderId,
    messages,
    targetProjectId,
  },
});

export interface IMoveMessageProjectMeta {
  projectId: ProjectId;
  messageIds: MessageId[];
  destinationId: MailFolderId;
  originId: MailFolderId;
  unreadItemCount: number;
  inboxFolderId: MailFolderId;
}

export const MOVE_MESSAGE_PROJECT_REQUEST =
  PRIO + 'MOVE_MESSAGE_PROJECT_REQUEST';
export const MOVE_MESSAGE_PROJECT_COMMIT = PRIO + 'MOVE_MESSAGE_PROJECT_COMMIT';
export const MOVE_MESSAGE_PROJECT_ROLLBACK =
  PRIO + 'MOVE_MESSAGE_PROJECT_ROLLBACK';

export const moveMessageProject: (
  projectId: ProjectId,
  messages: Message[],
  destinationId: MailFolderId,
  originId: MailFolderId,
  inboxFolderId: MailFolderId
) => DispatchAction<IMoveMessageProjectMeta, Message[]> = (
  projectId,
  messages,
  destinationId,
  originId,
  inboxFolderId
) => {
  const messageIds = messages.map((message) => message.id);
  const unreadItemCount = messages.filter((message) => !message.isRead).length;
  return {
    type: MOVE_MESSAGE_PROJECT_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/email/Email/${projectId}/message/moveAsync`,
          method: 'POST',
          json: { moveMailDto: { destinationId }, messageIds },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: MOVE_MESSAGE_PROJECT_COMMIT,
          meta: {
            projectId,
            messageIds,
            destinationId,
            originId,
            unreadItemCount,
            inboxFolderId,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: MOVE_MESSAGE_PROJECT_ROLLBACK,
          meta: {
            projectId,
            messageIds,
            destinationId,
            originId,
            unreadItemCount,
            inboxFolderId,
          },
          snackbarErrorMessage: {
            label: `mail:errorMessages.messages.${
              messageIds.length === 1 ? 'moveMessageError' : 'moveMessagesError'
            }`,
            timeout: 6,
          },
        },
      },
      projectId,
      messageIds,
      destinationId,
      originId,
      unreadItemCount,
      inboxFolderId,
    },
  };
};

export const WS_REVERT_MESSAGE_MOVE_PROJECT =
  PRIO + 'WS_REVERT_MESSAGE_MOVE_PROJECT';

export const wsRevertMessageMoveProject = (
  projectId: ProjectId,
  destinationId: MailFolderId,
  messageId: MessageId,
  message: Message,
  inboxFolderId: MailFolderId
) => ({
  type: WS_REVERT_MESSAGE_MOVE_PROJECT,
  projectId,
  destinationId,
  messageId,
  message,
  inboxFolderId,
});

export const WS_REVERT_MESSAGE_DELETE_PROJECT =
  PRIO + 'WS_REVERT_MESSAGE_DELETE_PROJECT';

export const wsRevertMessageDeleteProject = (
  projectId: ProjectId,
  sourceId: MailFolderId,
  messageId: MessageId,
  message: Message
) => ({
  type: WS_REVERT_MESSAGE_DELETE_PROJECT,
  projectId,
  sourceId,
  messageId,
  message,
});

export const ADD_PROJECT_MESSAGE_TO_CACHE =
  PRIO + 'ADD_PROJECT_MESSAGE_TO_CACHE';
export const addProjectMessageToCache = (message: Message) => ({
  type: ADD_PROJECT_MESSAGE_TO_CACHE,
  message,
});
