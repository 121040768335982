import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import useFilterContext from './hooks/useFilterContext';
import { Empty } from 'antd';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing.regular,
    fontSize: theme.font.fontSize.small,
  },
  title: {
    fontWeight: theme.font.fontWeight.bold,
    fontSize: 16,
  },
  tryAnyway: {
    marginTop: theme.spacing.small,
    color: theme.colors.base.primary.default,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: theme.font.fontSize.regular,
  },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: theme.spacing.regular,
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: theme.font.fontSize.small,
  },
}));

interface FilterResultNoItemsScreenProps {
  className?: string;
}

export const FilterResultNoItemsScreen: React.FC<
  FilterResultNoItemsScreenProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { thresholdExceeded, fetchSearch } = useFilterContext();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnTryAnywayClick = () => {
    if (true || thresholdExceeded) {
      fetchSearch(undefined, thresholdExceeded);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (!thresholdExceeded) {
    return (
      <div className={classNames(classes.noItemsScreen, className)}>
        <div className={classes.noItemsIconContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className={classes.noItemsText}>
                {t('components:filter.noItems')}
              </span>
            }
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        {t('components:filter.thresholdScreen.title', {
          amount: thresholdExceeded.toLocaleString() ?? 0,
        })}
      </div>
      <div>{t('components:filter.thresholdScreen.content1')}</div>
      <div>{t('components:filter.thresholdScreen.content2')}</div>
      <div className={classes.tryAnyway} onClick={handleOnTryAnywayClick}>
        {t('components:filter.thresholdScreen.tryAnyway')}
      </div>
    </div>
  );
};

export default FilterResultNoItemsScreen;
