import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { Button, Tooltip } from '@prio365/prio365-react-library';
import { ProjectId } from '../../../../models/Types';
import { Message, MessageCenterMessage } from '../../../../models/Message';
import { useDispatch, useSelector } from 'react-redux';
import { markAsRead } from '../../actions/actionControllers/messageActionController';
import { distinct } from '../../../../util';
import {
  getProjectsSpecialMailFoldersState,
  getSpecialMailFolders,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { useParams } from 'react-router-dom';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface MailFlagButtonProps {
  className?: string;
  projectId: ProjectId;
  selectedMessages: Message[];
  isFavoriteView?: boolean;
}

export const MailMarkReadButton: React.FC<MailFlagButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, projectId, selectedMessages, isFavoriteView } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { mailFolderId } = useParams();

  const specialMailFoldersById = useSelector(
    getProjectsSpecialMailFoldersState
  );

  const specialMailFolders = useSelector((state: RootReducerState) =>
    getSpecialMailFolders(state, projectId)
  );

  const allAreRead = useMemo(() => {
    return selectedMessages.every(({ isRead }) => isRead);
  }, [selectedMessages]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = () => {
    if (isFavoriteView) {
      const messages: MessageCenterMessage[] = selectedMessages;
      const projectIds = distinct(
        messages
          .map((message) => message.projectId)
          .filter((projectId) => !!projectId)
      );

      projectIds.forEach((projectId) => {
        dispatch(
          markAsRead(
            projectId,
            messages
              .filter(({ projectId: _projectId }) => _projectId === projectId)
              .map(({ id }) => id),
            !allAreRead,
            mailFolderId === 'inbox' && specialMailFoldersById[projectId]
              ? specialMailFoldersById[projectId]['inboxFolder']?.id
              : mailFolderId,
            messages.filter(({ isRead }) => isRead !== !allAreRead).length
          )
        );
      });
    } else {
      dispatch(
        markAsRead(
          projectId,
          selectedMessages.map(({ id }) => id),
          !allAreRead,
          mailFolderId === 'inbox' && specialMailFolders
            ? specialMailFolders['inboxFolder']?.id
            : mailFolderId,
          selectedMessages.filter(({ isRead }) => isRead !== !allAreRead).length
        )
      );
    }
  };
  //#endregion

  //#region ------------------------------ Components
  const wrapTooltip = (children: ReactElement, title: ReactNode) => {
    return title ? (
      <Tooltip overlay={title} placement="bottom">
        {children}
      </Tooltip>
    ) : (
      children
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return wrapTooltip(
    <Button
      type="default"
      iconProp={['fal', allAreRead ? 'envelope' : 'envelope-open']}
      onClick={handleOnClick}
      className={classNames(classes.root, className)}
      disabled={selectedMessages.length > 200}
    >
      {t(`mail:navigationBar.${allAreRead ? 'markUnread' : 'markRead'}`)}
    </Button>,
    selectedMessages.length > 200
      ? t('mail:navigationBar.toManySelected')
      : undefined
  );
};

export default MailMarkReadButton;
