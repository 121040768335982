import enUS from 'antd/es/date-picker/locale/en_US';
import deDE from 'antd/es/date-picker/locale/de_DE';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { useTranslation } from 'react-i18next';

const locales = { 'en-US': enUS, 'de-DE': deDE };

export default function useDatePickerLocale(): PickerLocale {
  const { i18n } = useTranslation();

  return locales[i18n.language];
}
