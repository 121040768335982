import { PRIO } from '../../../constants';

export const OPEN_GLOBAL_DOCUMENTS_SEARCH_MODAL =
  PRIO + 'OPEN_GLOBAL_DOCUMENTS_SEARCH_MODAL';
export const CLOSE_GLOBAL_DOCUMENTS_SEARCH_MODAL =
  PRIO + 'CLOSE_GLOBAL_DOCUMENTS_SEARCH_MODAL';

interface PartialGlobalDocumentsSearchModalState {
  open?: boolean;
}

export interface OpenGlobalSearchModalAction {
  type: string;
  predefinedState?: PartialGlobalDocumentsSearchModalState;
}

export const openGlobalDocumentsSearchModal = (
  predefinedState?: PartialGlobalDocumentsSearchModalState
) => ({
  type: OPEN_GLOBAL_DOCUMENTS_SEARCH_MODAL,
  predefinedState,
});
export const closeGlobalDocumentsSearchModal = () => ({
  type: CLOSE_GLOBAL_DOCUMENTS_SEARCH_MODAL,
});
