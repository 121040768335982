import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { notification } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { TimeAndLeaveManagementTimelineItem } from '../../../timeAndLeaveManagement/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimelineGroup } from '../../../../components/Timeline/types';
import Flex from '../../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import { CreateTimeRecordRequest } from '../../../../models/TimeRecord';
import { apiCreateTimeRecord } from '../../../timeRecords/api';
import { createTimeRecords } from '../../../timeRecords/actions';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { CreateTimeKeepingDay } from '../../../../models/TimeKeeping';
import { apiCreateTimeKeepingDay } from '../../api';
import { createTimeKeepingDay } from '../../actions';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface TimeKeepingTimlinePopoverProps {
  className?: string;
  group: TimelineGroup;
  item: TimeAndLeaveManagementTimelineItem;
  onTimeTrackingSuggestionCreate?: (
    groupId: string,
    item: TimeAndLeaveManagementTimelineItem
  ) => void;
  onClickExecuted?: (isClicked: boolean) => void;
}

export const TimeKeepingTimlinePopover: React.FC<
  TimeKeepingTimlinePopoverProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, item, group, onClickExecuted } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const userMe = useSelector(getUserMe);

  const suggestionTitle = useMemo(() => {
    const _title = item.title.match(`^(${group.title})(.*)`);
    if (_title && _title[2]) {
      return _title[2].trim();
    } else {
      return item.title;
    }
  }, [group, item]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnAddClick: React.MouseEventHandler<HTMLDivElement> = async (
    event
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setIsCreating(true);
    if (onClickExecuted) {
      onClickExecuted(true); // Informiere das Parent-Component, dass der Klick ausgeführt wurde
    }
    if (item.type === 'timeKeepingSuggestion') {
      const request: CreateTimeKeepingDay = {
        type: 'office',
        timeKeepingEntries: item.entries,
      };
      await apiCreateTimeKeepingDay(request)
        .then(({ data }) => {
          const timeKeeping = data;
          dispatch(
            createTimeKeepingDay(
              timeKeeping.timeKeepingEntries[0].startTime,
              data
            )
          );
        })
        .catch((error) => {
          notification.open({
            message: t('common:error'),
            description: t('timeRecords:errorMessages.createError'),
          });
        });
      setIsCreating(false);
    }
    if (item.type === 'timeTrackingSuggestion') {
      const request: CreateTimeRecordRequest = {
        projectId: item.groupId,
        description: '',
        day: item.entries[0].startTime.split('T')[0],
        contactId: userMe.id,
        title: suggestionTitle,
        timeRecordEntries: item.entries,
      };
      await apiCreateTimeRecord([request], request.projectId, true)
        .then(({ data }) => {
          dispatch(createTimeRecords(data));
        })
        .catch((error) => {
          notification.open({
            message: t('common:error'),
            description: t('timeRecords:errorMessages.createError'),
          });
        });
      setIsCreating(false);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (item.type === 'absence') {
    return null;
  }

  if (
    item.type === 'timeTrackingSuggestion' ||
    item.type === 'timeKeepingSuggestion'
  ) {
    return (
      <Flex.Row
        className={classNames(classes.root, className)}
        alignItems="center"
        onClick={!isCreating ? handleOnAddClick : undefined}
        childrenGap={theme.old.spacing.baseSpacing}
        padding={`0 ${theme.old.spacing.baseSpacing}px`}
      >
        <FontAwesomeIcon
          icon={['fal', isCreating ? 'spinner-third' : 'calendar-circle-plus']}
          className={isCreating ? 'fa-spin' : undefined}
        />
        <Flex.Item
          flex={1}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {t('timeKeeping:timeline.popover.addTimeTrackingSuggestion', {
            title: suggestionTitle,
          })}
        </Flex.Item>
      </Flex.Row>
    );
  }

  return (
    <div
      className={classNames(classes.root, className)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <FontAwesomeIcon icon={['fal', 'coffee']} />
    </div>
  );
};

export default TimeKeepingTimlinePopover;
