export const exportToCsv: (
  headers: string[],
  dataRows: string[][],
  fileName: string
) => void = (headers, dataRows, fileName) => {
  const csvHeader: string = headers.map((c) => `"${c}"`).join(';');
  const csvData: string = dataRows
    .map((columns) =>
      columns
        .map((c) => `"${c?.replace(/"/g, "'").replace(/\n/g, '\\n') ?? ''}"`)
        .join(';')
    )
    .join('\n');
  const csvContent = csvHeader + '\n' + csvData;
  const a = document.createElement('a');
  a.setAttribute('download', fileName);
  a.href = URL.createObjectURL(
    new Blob([csvContent], {
      type: 'text/csv',
    })
  );
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
