import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { makePrioStyles } from '../../../../../theme/utils';
import { SignaturePicker } from './SignaturePicker';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface SignatureInsertModalProps {
  visible: boolean;
  insertSignatureInsert: (value: string) => void;
  onCancelSignatureInsert: () => void;
}

export const SignatureInsertModal: React.FC<SignatureInsertModalProps> = (
  props
) => {
  const classes = useStyles();
  const { visible, onCancelSignatureInsert, insertSignatureInsert } = props;
  const { t } = useTranslation();

  const [selectedSignature, setSelectedSignature] = useState<string>();

  const onSelectSignature = (value: string) => {
    setSelectedSignature(value);
  };

  const onOk = () => {
    insertSignatureInsert(selectedSignature);
  };

  return (
    <Modal
      visible={visible}
      title={t('mail:modals.insertSignature.title')}
      okText={t('mail:modals.insertSignature.okText')}
      cancelText={t('mail:modals.insertSignature.cancelText')}
      onOk={onOk}
      onCancel={onCancelSignatureInsert}
      width={'90%'}
      bodyStyle={{ height: '70vh' }}
      className={classes.root}
    >
      <SignaturePicker onSelect={onSelectSignature} />
    </Modal>
  );
};

export default SignatureInsertModal;
