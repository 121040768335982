import { PRIO } from '../../../constants';

/* Set last project location */
export interface LastHRLocation {
  type: string;
  hrLocation: string;
}

export const SET_LAST_HR_LOCATION =
  PRIO + 'SET_LAST_HR_LOCATION';

export const setLastHRLocation: (hrLocation: string) => LastHRLocation = (
  hrLocation: string
) => ({
  type: SET_LAST_HR_LOCATION,
  hrLocation,
});