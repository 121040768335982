import React, { ReactNode, useEffect, useState } from 'react';
import { Layout, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGlobalSiderOpen,
  getGlobalSiderSubMenuState,
} from '../apps/main/rootReducer';
import { setSiderSetting } from '../modules/userSettings/actions/themeSettings/sider';
import Flex from './Flex';
import { MainMenu } from '../modules/main/MainMenu';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import GlobalSiderTrigger from './GlobalSiderTrigger';
import PrioSpinner from './PrioSpinner';
import { AdditionalTriggerButtonProps } from './GlobalSiderTrigger';
import GlobalDropdown from './GlobalDropdown';
import { SubMenuStateType } from '../models/Types';
import { makePrioStyles } from '../theme/utils';
import { PrioTheme } from '../theme/types';
import { useTheme } from 'react-jss';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  //@ts-ignore
  sider: {
    '&.ant-layout-sider': {
      transition: 'all 0.2s',
    },
    '& .ant-layout-trigger': {
      transition: 'all 0.2s',
    },
    '& .ant-layout-sider-trigger': {
      background: 'transparent',
      height: 'auto',
      textAlign: 'start',
      position: 'unset',
    },
    '& .ant-layout-sider-children': {
      overflow: 'hidden',
    },
    '&.ant-layout-sider-has-trigger': {
      padding: 0,
    },
    ...theme.old.components.globalSider?.css,
  },
  content: {
    transition: 'padding 0.2s',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top:
        theme.old.components.globalNavigationBar.height ??
        2 * theme.old.spacing.defaultPadding,
      right: 0,
      bottom: 0,
      width: 12,
      pointerEvents: 'none',
      boxShadow: 'inset rgba(0, 0, 0, .25) -8px 0px 8px -8px',
    },
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  contentSubMenuOpen: {
    '&::before': {
      top: 0,
    },
  },
  children: {
    flex: 1,
    overflow: 'hidden',
  },
  subMenuContainer: {
    width: 0,
    position: 'relative',
    overflow: 'visible',
    top: 0,
    bottom: 0,
    zIndex: 100,
  },
  subMenuMask: {
    transition: 'background-color 0.2s, width 0s',
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    width: 0,
  },
  subMenuMaskCollapsed: {
    transition: 'background-color 0.2s, width 0s  ease 0.2s',
  },
  subMenu: {
    transition: 'all 0.2s',
    width: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow: 'rgba(0, 0, 0, .45) 8px 0px 8px -8px',
  },
  subMenuOpen: {
    width: theme.old.components.menuMaxWidth,
  },
  dropDownMenu: {
    overflow: 'hidden',
  },
  //@ts-ignore
  headLine: {
    ...theme.old.components.globalSiderNavigationHeadline,
  },
  mainMenuSubMenu: {
    padding: theme.old.spacing.unit(1.5),
  },
  logoContainer: {
    height: 52,
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  subMenuHeader: {
    position: 'relative',
    margin: `${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px 0`,
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  prio: {
    marginBottom: theme.old.spacing.baseSpacing,
    display: 'flex',
  },
  defaultSubMenuButton: {
    margin: `0 ${theme.old.spacing.unit(1.5)}px`,
    width: theme.old.spacing.unit(6),
    height: theme.old.spacing.unit(5),
    backgroundColor: theme.old.palette.backgroundPalette.main,
    '&.ant-btn:not(.ant-btn-primary) > .svg-inline--fa, .ant-table-cell > .svg-inline--fa':
      {
        color: theme.old.palette.primaryColor,
      },
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.main,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
  defaultSubMenuButtonOpenSubMenu: {
    backgroundColor: `${theme.old.palette.backgroundPalette.active.main}!important`,
  },
  subMenuContent: {
    height: '100%',
    overflow: 'hidden',
  },
  subMenuGenericContent: {
    flex: 1,
    overflow: 'hidden',
  },
  dropDownButton: {
    background: `${theme.old.palette.backgroundPalette.main}!important`,
    '&:hover': {
      background: `${theme.old.palette.backgroundPalette.hover.main}!important`,
    },
  },
  dropDownButtonMainMenu: {
    background: `${theme.old.palette.backgroundPalette.active.main}!important`,
  },
}));

interface GlobalSiderProps {
  title: string;
  children?: ReactNode;
  subMenu?: ReactNode;
  subMenuIcon?: ReactNode;
  onOpenChange?: (value: boolean) => void;
  additionalTriggerBarButton?: AdditionalTriggerButtonProps;
  selectedMainMenuItem: string;
}

export const GlobalSider: React.FC<GlobalSiderProps> = (props) => {
  const {
    children,
    onOpenChange,
    title,
    subMenu,
    subMenuIcon,
    additionalTriggerBarButton,
    selectedMainMenuItem,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const open = useSelector(getGlobalSiderOpen);
  const subMenuState = useSelector(getGlobalSiderSubMenuState);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const setOpen = (value: boolean) => {
    if (onOpenChange) {
      onOpenChange(value);
    }
    dispatch(setSiderSetting({ open: value, subMenuState: 'closed' }));
  };

  const closeDropDownSubMenu = () => {
    dispatch(
      setSiderSetting({
        subMenuState: 'closed',
      })
    );
  };
  //#endregion

  return (
    <div id="prio-global-sider" className={classes.root}>
      <Layout.Sider
        className={classes.sider}
        width={theme.old.components.globalSider.openWidth}
        trigger={
          <GlobalSiderTrigger
            open={open}
            setOpen={setOpen}
            additionalTrigger={additionalTriggerBarButton}
          />
        }
        collapsedWidth={theme.old.components.globalSider.collapsedWidth}
        collapsed={!open}
        reverseArrow={open}
        onCollapse={(collapsed: boolean) => {
          setOpen(!collapsed);
        }}
        collapsible
      >
        <div
          className={classNames(classes.content, {
            [classes.contentSubMenuOpen]:
              subMenuState && subMenuState !== 'closed',
          })}
        >
          <GlobalDropdown
            classNameCollapsed={classNames({
              [classes.dropDownButton]:
                subMenuState && subMenuState === 'subMenu',
              [classes.dropDownButtonMainMenu]:
                subMenuState && subMenuState === 'dropDownMenu',
            })}
            onBarsClick={() => {
              if (!open) {
                dispatch(
                  setSiderSetting({
                    subMenuState:
                      subMenuState && subMenuState === 'dropDownMenu'
                        ? 'closed'
                        : 'dropDownMenu',
                  })
                );
              }
            }}
            selectedMainMenuItem={selectedMainMenuItem}
          />
          {subMenu &&
            !open &&
            (subMenuIcon ?? (
              <Button
                type="link"
                className={classNames(classes.defaultSubMenuButton, {
                  [classes.defaultSubMenuButtonOpenSubMenu]:
                    subMenuState === 'subMenu',
                })}
                onClick={() =>
                  dispatch(
                    setSiderSetting({
                      subMenuState:
                        subMenuState && subMenuState === 'subMenu'
                          ? 'closed'
                          : 'subMenu',
                    })
                  )
                }
                iconProp={['fal', 'grip-vertical']}
              />
            ))}
          <div className={classes.children}>{children}</div>
        </div>
      </Layout.Sider>
      <SubMenu
        subMenu={subMenu}
        subMenuState={subMenuState}
        closeDropDownSubMenu={closeDropDownSubMenu}
        selectedMainMenuItem={selectedMainMenuItem}
        openSider={open}
        title={title}
      />
    </div>
  );
};

export default GlobalSider;

interface SubMenuProps {
  subMenu?: ReactNode;
  subMenuState: SubMenuStateType;
  closeDropDownSubMenu: VoidFunction;
  selectedMainMenuItem: string;
  openSider: boolean;
  title: string;
}

const SubMenu: React.FC<SubMenuProps> = (props) => {
  const {
    subMenu,
    subMenuState,
    closeDropDownSubMenu,
    selectedMainMenuItem,
    openSider,
    title,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [lastSubMenuState, setLastSubMenuState] =
    useState<SubMenuStateType>('dropDownMenu');
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (subMenuState !== 'closed') {
      setLastSubMenuState(subMenuState);
    }
  }, [subMenuState]);
  //#endregion

  return (
    <div
      id={'prio-global-sider-submenu'}
      className={classNames(classes.subMenuContainer)}
    >
      <div
        className={classNames(classes.subMenu, {
          [classes.subMenuOpen]: subMenuState !== 'closed',
        })}
      >
        {lastSubMenuState === 'dropDownMenu' ? (
          <Flex.Column className={classes.mainMenuSubMenu}>
            <Flex.Row className={classes.logoContainer} alignItems="center">
              <div className={classes.prio}>
                <PrioSpinner size="large" stand />
              </div>
              <Typography.Title className={classes.headLine}>
                {t('common:sider.mainMenu')}
              </Typography.Title>

              <Button
                className={classes.closeButton}
                shape="circle"
                type="link"
                iconProp={['fal', 'times']}
                onClick={closeDropDownSubMenu}
              />
            </Flex.Row>
            <MainMenu
              className={classes.dropDownMenu}
              closeDropDown={() => {
                if (!openSider) {
                  closeDropDownSubMenu();
                }
              }}
              selectedEntry={selectedMainMenuItem}
              isSubMenu
            />
          </Flex.Column>
        ) : (
          <Flex.Column className={classes.subMenuContent}>
            <Flex.Row className={classes.subMenuHeader}>
              <Typography.Title className={classes.headLine}>
                {title}
              </Typography.Title>
              <Button
                className={classes.closeButton}
                shape="circle"
                type="link"
                iconProp={['fal', 'times']}
                onClick={closeDropDownSubMenu}
              />
            </Flex.Row>
            <div className={classes.subMenuGenericContent}>{subMenu}</div>
          </Flex.Column>
        )}
      </div>
    </div>
  );
};
