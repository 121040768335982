import { all, fork } from 'redux-saga/effects';
import watchDeleteMessages from '../modules/mail/sagas/watchDeleteMessages';
import watchMailFolders from '../modules/mail/sagas/watchMailFolders';
import watchMessagesFetch from '../modules/mail/sagas/watchMessagesFetch';
import watchUpdateDraftMessage from '../modules/mail/sagas/watchUpdateDraftMessage';
import watchResyncMessages from '../modules/mail/sagas/watchResyncMessages';

export default function* watchMessages() {
  yield all([
    fork(watchMessagesFetch),
    fork(watchMailFolders),
    fork(watchDeleteMessages),
    fork(watchUpdateDraftMessage),
    fork(watchResyncMessages),
  ]);
}
