import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useContactTags from '../../../modules/contacts/hooks/useContactTags';

const ContactTagFilterPickerConsumer: React.FC<FilterPickerConsumerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { selectableTags } = useContactTags();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: selectableTags.map(({ name, contactTagId }) => {
          return {
            value: contactTagId,
            label: name,
            searchValue: name,
          };
        }),
      })}
    </>
  );
};

export default ContactTagFilterPickerConsumer;
