import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Button, Drawer } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { colon } from '../../../../util/forms';
import { makePrioStyles } from '../../../../theme/utils';
import { Employee } from '../../../../models/Employee';
import { EmployeeId } from '../../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { bicPattern, ibanPattern } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    fontSize: theme.font.fontSize.small,
    paddingTop: theme.spacing.regular,
  },
  form: {
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  bankDataBoxLeft: {
    paddingRight: theme.spacing.small,
  },
  customDrawerContent: {
    paddingBottom: 0,
  },
  noInformationLabel: {
    color: theme.colors.application.typography.muted,
  },
  header: {
    marginBottom: theme.spacing.regular,
    fontWeight: theme.font.fontWeight.bold,
  },
}));

interface BankDataFormModel {
  iban?: string;
  bic?: string;
}

interface PersonnelFileBankDataProps {
  employeeId: EmployeeId;
  employee?: Employee;
  isSaving: boolean;
  patchEmployee: (patchData: Employee) => Promise<void>;
}

export const PersonnelFileBankData: React.FC<PersonnelFileBankDataProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { employee, isSaving, patchEmployee } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [bankDataForm] = Form.useForm<BankDataFormModel>();

  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const editBankData = () => {
    setEditDrawerVisible(true);
  };
  const cancelEditBankData = () => {
    bankDataForm.setFieldsValue({
      iban: employee.iban,
      bic: employee.bic,
    });
    setEditDrawerVisible(false);
  };
  const submitBankDataForm = () => {
    bankDataForm.submit();
  };

  const handleFinishBankData = async (bankData: BankDataFormModel) => {
    const patchData: Employee = {
      ...bankData,
    };
    await patchEmployee(patchData).then(() => setEditDrawerVisible(false));
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (employee) {
      bankDataForm.setFieldsValue({
        iban: employee.iban,
        bic: employee.bic,
      });
    }
  }, [employee, bankDataForm]);
  //#endregion

  //#region ------------------------------ Effects
  const editDrawerContent = () => {
    return (
      <Form<BankDataFormModel>
        form={bankDataForm}
        onFinish={handleFinishBankData}
        layout="vertical"
        className={classes.form}
      >
        <Flex.Row flex={1}>
          <Flex.Column flex={2} childrenGap={theme.spacing.small}>
            <Flex.Row childrenGap={theme.spacing.small}>
              <Flex.Column flex={1}>
                <Form.Item
                  name="iban"
                  label={t('hr:personnelFile.form.labels.iban')}
                  colon={colon}
                  rules={[
                    {
                      message: t(
                        'hr:personnelFile.form.validation.invalidIban'
                      ),
                      pattern: ibanPattern,
                    },
                  ]}
                >
                  <Input disabled={isSaving} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="bic"
                  label={t('hr:personnelFile.form.labels.bic')}
                  colon={colon}
                  rules={[
                    {
                      message: t('hr:personnelFile.form.validation.invalidBic'),
                      pattern: bicPattern,
                    },
                  ]}
                >
                  <Input disabled={isSaving} />
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
          </Flex.Column>
        </Flex.Row>
      </Form>
    );
  };

  const editDrawerFooter = () => {
    return (
      <Flex.Column>
        <Flex.Item flex={1} />
        <Flex.Row alignSelf="flex-end" childrenGap={theme.spacing.small}>
          <Button
            type="default"
            onClick={cancelEditBankData}
            disabled={isSaving}
            style={{ marginRight: theme.spacing.small }}
          >
            {t('common:actions.cancel')}
          </Button>
          <Button
            type={'primary'}
            onClick={submitBankDataForm}
            disabled={isSaving}
          >
            {t('common:save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    );
  };
  //#endregion

  return (
    <>
      <Flex.Column className={classes.root}>
        <Flex.Column flex={1}>
          <div className={classes.header}>
            {t('hr:personnelFile.form.labels.bankAccountData')}
          </div>
          {employee?.iban && (
            <div style={{ marginTop: theme.spacing.small }}>{`${t(
              'hr:personnelFile.form.labels.iban'
            )}: ${employee.iban}`}</div>
          )}
          {employee?.bic && (
            <div>{`${t('hr:personnelFile.form.labels.bic')}: ${
              employee.bic
            }`}</div>
          )}
          {!(employee?.iban || employee?.bic) && (
            <div className={classes.noInformationLabel}>
              {t('hr:personnelFile.form.labels.noInformation')}
            </div>
          )}
        </Flex.Column>
        <Flex.Row alignSelf="flex-end" childrenGap={theme.spacing.small}>
          <Button type={'primary'} onClick={editBankData} disabled={isSaving}>
            {t('common:edit')}
          </Button>
        </Flex.Row>
      </Flex.Column>
      <Drawer
        title={t('hr:personnelFile.editDrawer.bankData')}
        visible={editDrawerVisible}
        onClose={() => setEditDrawerVisible(false)}
        children={editDrawerContent()}
        footer={editDrawerFooter()}
        contentClassName={classes.customDrawerContent}
      />
    </>
  );
};

export default PersonnelFileBankData;
