import { TableLocale } from 'antd/lib/table/interface';
import { TFunction } from 'i18next';

export const tableTranslations: (t: TFunction) => TableLocale = (t) => ({
  filterTitle: t('common:table.filterTitle'),
  filterConfirm: t('common:table.filterConfirm'),
  filterReset: t('common:table.filterReset'),
  emptyText: t('common:table.emptyText'),
  selectAll: t('common:table.selectAll'),
  selectInvert: t('common:table.selectInvert'),
  selectionAll: t('common:table.selectionAll'),
  sortTitle: t('common:table.sortTitle'),
  expand: t('common:table.expand'),
  collapse: t('common:table.collapse'),
  triggerDesc: t('common:table.triggerDesc'),
  triggerAsc: t('common:table.triggerAsc'),
  cancelSort: t('common:table.cancelSort'),
});

interface PaginationLocale {
  page: string;
  jump_to: string;
  jump_to_confirm: string;
  items_per_page: string;
  prev_page: string;
  next_page: string;
  prev_3: string;
  prev_5: string;
  next_3: string;
  next_5: string;
}

export const paginationTranslations: (t: TFunction) => PaginationLocale = (t) => ({
  page: t('common:table.pagination.page'),
  jump_to: t('common:table.pagination.jumpTo'),
  jump_to_confirm: t('common:table.pagination.jumpToConfirm'),
  items_per_page: t('common:table.pagination.itemsPerPage'),
  prev_page: t('common:table.pagination.prevPage'),
  next_page: t('common:table.pagination.nextPage'),
  prev_3: t('common:table.pagination.prev3'),
  prev_5: t('common:table.pagination.prev5'),
  next_3: t('common:table.pagination.next3'),
  next_5: t('common:table.pagination.next5'),
});