import { PRIO } from '../../../constants';
import { AbsenceProposal } from '../../../models/AbsenceProposal';

export const OPEN_ABSENCE_DRAWER = PRIO + 'OPEN_ABSENCE_DRAWER';
export const CLOSE_ABSENCE_DRAWER = PRIO + 'CLOSE_ABSENCE_DRAWER';
export const SET_ABSENCE_DRAWER_TAB = PRIO + 'SET_ABSENCE_DRAWER_TAB';
export const SET_ABSENCE_DRAWER_VIEW = PRIO + 'SET_ABSENCE_DRAWER_VIEW';
export const SET_ABSENCE_DRAWER_STATE = PRIO + 'SET_ABSENCE_DRAWER_STATE';

export type AbsenceTabs = 'new' | 'overview';
export type AbsenceDrawerView = 'tabs' | 'edit';

interface PartialAbsenceDrawerState {
  tab?: AbsenceTabs;
  view?: AbsenceDrawerView;
  selectedAbsenceProposal?: AbsenceProposal;
  isTimeAndLeaveManagement?: boolean;
}
export interface OpenAbsenceDrawerAction {
  type: string;
  predefinedState?: PartialAbsenceDrawerState;
}
export interface SetAbsenceDrawerTabAction {
  type: string;
  tab: AbsenceTabs;
}
export interface SetAbsenceDrawerViewAction {
  type: string;
  view: AbsenceDrawerView;
}
export interface SetAbsenceDrawerStateAction {
  type: string;
  partialState: PartialAbsenceDrawerState;
}

export const openAbsenceDrawer = (
  predefinedState?: PartialAbsenceDrawerState
) => ({
  type: OPEN_ABSENCE_DRAWER,
  predefinedState,
});
export const closeAbsenceDrawer = () => ({
  type: CLOSE_ABSENCE_DRAWER,
});
export const setAbsenceDrawerState: (
  partialState: PartialAbsenceDrawerState
) => SetAbsenceDrawerStateAction = (
  partialState: PartialAbsenceDrawerState
) => ({
  type: SET_ABSENCE_DRAWER_STATE,
  partialState,
});
export const setAbsenceDrawerTab: (
  tab: AbsenceTabs
) => SetAbsenceDrawerTabAction = (tab: AbsenceTabs) => ({
  type: SET_ABSENCE_DRAWER_TAB,
  tab,
});
export const setAbsenceDrawerView: (
  tab: AbsenceDrawerView
) => SetAbsenceDrawerViewAction = (view: AbsenceDrawerView) => ({
  type: SET_ABSENCE_DRAWER_VIEW,
  view,
});
