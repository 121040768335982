import { ContactClassification } from '../../../models/Settings';
import { Reducer } from 'redux';
import { FETCH_CONTACT_CLASSIFICATIONS_COMMIT } from '../actions/contactClassification';
import { CLEAR_PRIO_CACHE } from '../../../actions';

interface ContactClassificationAction {
  type: 'PRIO_FETCH_CONTACT_CLASSIFICATIONS_COMMIT';
  payload: ContactClassification[];
}

const contactClassification: Reducer<
  ContactClassification[],
  ContactClassificationAction
> = (state = [], action) => {
  switch (action.type) {
    case FETCH_CONTACT_CLASSIFICATIONS_COMMIT: {
      return action.payload;
    }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

export default contactClassification;

export const getContactClassifications: (
  state: ContactClassification[]
) => ContactClassification[] = (state) => state;
