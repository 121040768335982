import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { useMemo } from 'react';
import useOfficesContext from '../../../companies/hooks/useOfficesContext';
import { AbsenceTimelineGroup } from './AbsenceTimeline';

interface AbsenceTimelineGroupHeaderProps {
  group: AbsenceTimelineGroup;
}

const AbsenceTimelineGroupHeader: React.FC<AbsenceTimelineGroupHeaderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { group } = props;

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getOfficeById } = useOfficesContext();

  const officeName = useMemo(() => {
    return getOfficeById(group.contact.officeId)?.name;
  }, [getOfficeById, group.contact.officeId]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0 16px',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: theme.font.fontSize.small,
        }}
      >
        {group.title}
      </div>
      {officeName !== undefined && (
        <div
          style={{
            fontSize: theme.font.fontSize.extraSmall,
            color: theme.colors.application.typography.muted,
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {officeName}
        </div>
      )}
    </div>
  );
};

export default AbsenceTimelineGroupHeader;
