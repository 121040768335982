import { put, select } from 'redux-saga/effects';
import {
  getActiveMessageId,
  getActiveProject,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { WebSocketMessage } from '../../../models/WebSocket';
import {
  deleteCommentById,
  fetchCommentById,
  updateCommentById,
} from '../actions/projects/mailCommentActions';

export interface WSEmailCommentMessage extends WebSocketMessage {
  commentId: string;
  conversationId: string;
  messageId: string;
  userAzureId: string;
}

export declare type EmailCommentMessageTypes =
  | 'emailCommentCreated'
  | 'emailCommentUpdated'
  | 'emailCommentDeleted';

export function* handleEmailCommentMessage(wsMessage: WebSocketMessage) {
  try {
    const { id: userMeId } = yield select(getUserMe);
    const { object, type } = wsMessage;
    const { commentId, messageId, userAzureId } =
      object as WSEmailCommentMessage;

    const activeProjectId = yield select(getActiveProject);
    const currentMessageId = yield select((state: RootReducerState) =>
      getActiveMessageId(state, activeProjectId)
    );

    if (userAzureId !== userMeId && currentMessageId === messageId) {
      switch (type) {
        case 'emailCommentCreated': {
          yield put(fetchCommentById(commentId));
          break;
        }
        case 'emailCommentUpdated': {
          yield put(updateCommentById(commentId));
          break;
        }
        case 'emailCommentDeleted': {
          yield put(deleteCommentById(commentId));
          break;
        }
      }
    }
  } catch (error) {
    console.error('Error in websocket flow handleEmailCommentMessage', error);
  }
}
