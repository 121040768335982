import React, { useState, useEffect, useMemo } from 'react';
import { Form, Input, Row, Col, Divider, DatePicker } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { makePrioStyles } from '../../../theme/utils';
import useDatePickerLocale from '../../../hooks/useDatePickerLocale';

import ProjectPicker from '../../projects/components/ProjectPicker';
import {
  Task,
  CreateTaskRequest,
  emptyCreateTaskRequest,
  TaskFormModel,
} from '../../../models/Task';
import { ProjectId } from '../../../models/Types';
import ContactPicker from '../../contacts/components/ContactPicker';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInternalProjectContacts } from '../../projects/actions';
import { getActiveProject } from '../../../apps/main/rootReducer';
import { rowGutter, colon } from '../../../util/forms';
import { Project } from '../../../models/Project';
import BucketsSelector from './BucketsSelector';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: theme.old.spacing.unit(2),
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
    marginBottom: theme.old.spacing.unit(2),
  },
  datePicker: {
    width: '100%',
  },
  timePicker: {
    width: `calc(75% - ${16 + theme.old.spacing.unit(1)}px)`,
    marginRight: theme.old.spacing.unit(1),
  },
  duration: {
    width: `calc(25% - ${theme.old.spacing.unit(1)}px)`,
    marginRight: theme.old.spacing.unit(1),
  },
  space: {
    display: 'flex',
  },
  periodSum: {
    textAlign: 'right',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  deleteButton: {
    float: 'left',
  },
  scrollableFormModal: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 48px)',
  },
  scrollableFormWidget: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100% - 48px - 32px)',
  },
}));

const { TextArea } = Input;
interface TaskFormProps {
  className?: string;
  initialValues?: Task;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (value: CreateTaskRequest | Task) => void;
  onCancel?: () => void;
  resetOnFinish?: boolean;
  isDrawer?: boolean;
}

const convertToModel: (
  initialValue: Task | CreateTaskRequest
) => TaskFormModel = (initialValue: Task | CreateTaskRequest) => ({
  ...initialValue,
  dueDateTime:
    initialValue.dueDateTime &&
    moment(initialValue.dueDateTime, moment.ISO_8601),
  startDateTime:
    initialValue.dueDateTime &&
    moment(initialValue.dueDateTime, moment.ISO_8601),
});

const projectFilter: (project: Project) => boolean = (project) =>
  !!project.masterPlanId;

export const TaskForm: React.FC<TaskFormProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    initialValues,
    actionLabel,
    cancelLabel,
    onFinish,
    onCancel,
    disableActionButton,
    disableForm,
    resetOnFinish,
    isDrawer,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const activeProject = useSelector(getActiveProject);
  const [currentProject, setCurrentProject] = useState<ProjectId>(
    initialValues?.projectId ?? activeProject
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (currentProject) dispatch(fetchInternalProjectContacts(currentProject));
  }, [currentProject, dispatch]);

  useEffect(() => {
    if (activeProject && !initialValues) {
      form.setFieldsValue({ projectId: activeProject, contactId: null });
      setCurrentProject(activeProject);
    }
  }, [activeProject, form, initialValues]);

  const convertedInitialValue = useMemo(
    () =>
      convertToModel(
        initialValues ?? {
          ...emptyCreateTaskRequest,
          projectId: currentProject ?? activeProject,
        }
      ),
    [initialValues, currentProject, activeProject]
  );

  const datePickerLocale: PickerLocale = useDatePickerLocale();

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  const cancel = () => {
    form.resetFields();
    setCurrentProject(activeProject);
    if (onCancel) {
      onCancel();
    }
  };

  const handleFinish: (value: TaskFormModel) => void = (
    value: TaskFormModel
  ) => {
    const {
      startDateTime: startDateTimeMoment,
      dueDateTime: dueDateTimeMoment,
      ...otherValues
    } = value;
    const startDateTime = startDateTimeMoment?.toISOString();
    const dueDateTime = dueDateTimeMoment?.toISOString();
    onFinish({
      ...otherValues,
      startDateTime,
      dueDateTime,
    });
    if (resetOnFinish) {
      form.resetFields();
      form.setFieldsValue({
        projectId: currentProject,
      });
    }
  };

  const now = moment();

  return (
    <Form
      className={classNames(classes.root, className)}
      initialValues={convertedInitialValue}
      form={form}
      onValuesChange={(changedValues: TaskFormModel) => {
        if (changedValues.projectId) {
          setCurrentProject(changedValues.projectId);
          form.setFieldsValue({ internalProjectContactId: null });
        }
      }}
      onFinish={handleFinish}
      layout="vertical"
    >
      <div
        className={
          isDrawer ? classes.scrollableFormModal : classes.scrollableFormWidget
        }
      >
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="projectId"
              label={t('tasks:form.labels.projectId')}
              rules={[
                {
                  required: true,
                  message: t('tasks:form.validation.missing.projectId'),
                },
              ]}
            >
              <ProjectPicker disabled={disableForm} filter={projectFilter} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="title"
              colon={colon}
              label={t('tasks:form.labels.title')}
              rules={[
                {
                  required: true,
                  message: t('tasks:form.validation.missing.title'),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('tasks:form.labels.description')}
              colon={colon}
            >
              <TextArea disabled={disableForm} rows={8} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="internalProjectContactId"
              label={t('tasks:form.labels.internalProjectContactId')}
            >
              <ContactPicker
                onlyInternalProject
                projectId={currentProject}
                disabled={disableForm || !currentProject}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item name="bucketId" label={t('tasks:form.labels.bucket')}>
              <BucketsSelector
                projectId={currentProject}
                disabled={disableForm || !currentProject}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              name="startDateTime"
              label={t('tasks:form.labels.startDateTime')}
              colon={colon}
            >
              <DatePicker
                disabled={disableForm}
                locale={datePickerLocale}
                format="DD.MM.YYYY"
                className={classes.datePicker}
                suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="dueDateTime"
              label={t('tasks:form.labels.dueDateTime')}
              colon={colon}
            >
              <DatePicker
                disabled={disableForm}
                locale={datePickerLocale}
                format="DD.MM.YYYY"
                className={classes.datePicker}
                suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                disabledDate={(date) => date.isBefore(now)}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Row justify="end" className={classes.actionButtonsRow}>
        <Col span={24}>
          <Form.Item className={classes.submitButtonFormItem}>
            <Button type="default" onClick={cancel}>
              {cancelLabel}
            </Button>

            <Button
              htmlType="submit"
              disabled={disableActionButton}
              style={{ marginInline: '8px' }}
            >
              {actionLabel}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TaskForm;
