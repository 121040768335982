import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getPrioUsersById,
  getPrioUsersIds,
  getContactsByIdState,
} from '../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { User } from '../../../models/User';
import { useMemo } from 'react';
import { UsersByIdState } from '../reducers/users';
import { ContactId, OfficeId } from '../../../models/Types';

interface ContactSearchProps {
  searchTerm: string;
  officeId?: OfficeId;
}

const allUsersSelector = createSelector<
  [
    (state: RootReducerState) => UsersByIdState,
    (state: RootReducerState) => ContactId[]
  ],
  User[]
>(
  (state) => getPrioUsersById(state),
  (state) => getPrioUsersIds(state),
  (byId, ids) => ids.map((id) => byId[id]).filter((entry) => !!entry)
);

function useUserSearch(props: ContactSearchProps): User[] {
  const { searchTerm, officeId } = props;

  const allUsers: User[] = useSelector<RootReducerState, User[]>(
    allUsersSelector
  );

  const contactsByIdState = useSelector(getContactsByIdState);

  const matchingUsers = useMemo(() => {
    const matchingUsers: User[] = allUsers
      .filter((user) =>
        officeId
          ? user.displayName
              ?.toLocaleLowerCase()
              ?.match(searchTerm.toLocaleLowerCase()) &&
            contactsByIdState[user.id].officeId === officeId
          : user.displayName
              ?.toLocaleLowerCase()
              ?.match(searchTerm.toLocaleLowerCase())
      )
      .sort((a, b) => a.surname.localeCompare(b.surname));
    return matchingUsers;
  }, [searchTerm, officeId, allUsers, contactsByIdState]);

  return matchingUsers;
}

export default useUserSearch;
