import { Reducer, combineReducers } from 'redux';
import {
  DriveFavorite,
  FavorisingProgress,
} from '../../../models/DriveFavorite';
import { DriveFavoriteId, DriveItemId } from '../../../models/Types';
import {
  CREATE_DRIVE_FAVORITE_COMMIT,
  CREATE_DRIVE_FAVORITE_REQUEST,
  CREATE_DRIVE_FAVORITE_ROLLBACK,
  DELETE_DRIVE_FAVORITES_COMMIT,
  DELETE_DRIVE_FAVORITES_REQUEST,
  DELETE_DRIVE_FAVORITES_ROLLBACK,
  DELETE_DRIVE_FAVORITE_COMMIT,
  DELETE_DRIVE_FAVORITE_REQUEST,
  DELETE_DRIVE_FAVORITE_ROLLBACK,
  FETCH_DRIVE_FAVORITES_COMMIT,
  FETCH_DRIVE_FAVORITES_REQUEST,
  FETCH_DRIVE_FAVORITE_COMMIT,
  FETCH_DRIVE_FAVORITE_ROLLBACK,
  UPDATE_DRIVE_FAVORITE_COMMIT,
  UPDATE_DRIVE_FAVORITE_REQUEST,
  UPDATE_DRIVE_FAVORITE_ROLLBACK,
} from '../actions/driveFavorites';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ReduxAction } from '../../../models/Redux';
import { distinct } from '../../../util';

export interface DriveFavoriteState {
  byId: ByIdState;
  ids: string[];
  meta: DriveFavoriteMeta;
  favorisingProgressById: DriveItemFavorisingProgressMeta;
  favorisingProgressIds: string[];
}

interface ByIdState {
  [key: DriveFavoriteId]: DriveFavorite;
}

interface DriveFavoriteMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

interface DriveItemFavorisingProgressMeta {
  [key: DriveFavoriteId]: FavorisingProgress;
}

const byId: Reducer<ByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    // Fetch Drive Favorites
    case FETCH_DRIVE_FAVORITES_COMMIT: {
      const { payload } = action;
      return (payload as Array<DriveFavorite>).reduce((map, item) => {
        map[item.driveFavoriteId] = item;
        return map;
      }, {});
    }

    // Fetch Drive Favorite
    case FETCH_DRIVE_FAVORITE_COMMIT: {
      const { payload } = action;

      return {
        ...state,
        [(payload as DriveFavorite).driveFavoriteId]: {
          ...payload,
        },
      };
    }

    // Create Drive Favorite
    case CREATE_DRIVE_FAVORITE_COMMIT: {
      const { payload } = action;
      return {
        ...state,
        [(payload as DriveFavorite).driveFavoriteId]: {
          ...payload,
        },
      };
    }

    // Update Drive Favorite
    case UPDATE_DRIVE_FAVORITE_REQUEST: {
      const {
        payload,
        meta: { driveFavorite },
      } = action;

      const driveFavoriteId = (driveFavorite as DriveFavorite).driveFavoriteId;

      return {
        ...state,
        [driveFavoriteId]: {
          ...state[driveFavoriteId],
          ...payload,
        },
      };
    }

    case UPDATE_DRIVE_FAVORITE_COMMIT: {
      const {
        payload,
        meta: { driveFavorite },
      } = action;
      const driveFavoriteId = (driveFavorite as DriveFavorite).driveFavoriteId;
      return {
        ...state,
        [driveFavoriteId]: {
          ...payload,
        },
      };
    }

    case UPDATE_DRIVE_FAVORITE_ROLLBACK: {
      const {
        meta: { driveFavorite },
      } = action;

      const driveFavoriteId = (driveFavorite as DriveFavorite).driveFavoriteId;

      return {
        ...state,
        [driveFavoriteId]: {
          ...state[driveFavoriteId],
          ...driveFavorite,
        },
      };
    }

    // Delete Drive Favorite
    case DELETE_DRIVE_FAVORITE_REQUEST: {
      const {
        payload: { driveFavoriteId },
      } = action;
      const newState = { ...state };
      delete newState[driveFavoriteId];
      return newState;
    }

    case DELETE_DRIVE_FAVORITE_ROLLBACK: {
      const {
        meta: { driveFavorite },
      } = action;
      return {
        ...state,
        [(driveFavorite as DriveFavorite).driveFavoriteId]: driveFavorite,
      };
    }

    // Delete Drive Favorites
    case DELETE_DRIVE_FAVORITES_REQUEST: {
      const { payload } = action;
      const driveFavoriteIds = (payload as DriveFavorite[]).map(
        (favorite) => favorite.driveFavoriteId
      );

      const obj = driveFavoriteIds.reduce(
        (acc, driveFavoriteId) => {
          if (acc.hasOwnProperty(driveFavoriteId)) {
            const { [driveFavoriteId]: _, ...restState } = acc;
            return restState;
          }
          return acc;
        },
        { ...state }
      );

      return obj;
    }

    case DELETE_DRIVE_FAVORITES_ROLLBACK: {
      const {
        meta: { driveFavorites },
      } = action;

      const obj = { ...state };
      for (const favorite of driveFavorites) {
        obj[favorite.driveFavoriteId] = { ...favorite };
      }

      return obj;
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

const ids: Reducer<string[], any> = (state = [], action) => {
  switch (action.type) {
    // Fetch Drive Favorites
    case FETCH_DRIVE_FAVORITES_COMMIT: {
      const { payload } = action;
      return ((payload as Array<DriveFavorite>) ?? []).map(
        (item) => item.driveFavoriteId
      );
    }

    // Fetch Drive Favorite
    case FETCH_DRIVE_FAVORITE_COMMIT: {
      const { payload } = action;
      return distinct([...state, (payload as DriveFavorite).driveFavoriteId]);
    }

    // Create Drive Favorite
    case CREATE_DRIVE_FAVORITE_COMMIT: {
      const { payload } = action;
      return distinct([...state, (payload as DriveFavorite).driveFavoriteId]);
    }

    // Delete Drive Favorite
    case DELETE_DRIVE_FAVORITE_REQUEST: {
      const { payload } = action;
      return state.filter(
        (x) => x !== (payload as DriveFavorite).driveFavoriteId
      );
    }

    case DELETE_DRIVE_FAVORITE_ROLLBACK: {
      const {
        meta: { driveFavorite },
      } = action;

      return distinct([
        ...state,
        (driveFavorite as DriveFavorite).driveFavoriteId,
      ]);
    }

    // Delete Drive Favorites
    case DELETE_DRIVE_FAVORITES_REQUEST: {
      const { payload } = action;
      const driveFavoriteIds = (payload as DriveFavorite[]).map(
        (favorite) => favorite.driveFavoriteId
      );
      return state.filter(
        (favoriteId) => !driveFavoriteIds.includes(favoriteId)
      );
    }

    case DELETE_DRIVE_FAVORITES_ROLLBACK: {
      const { meta: driveFavorites } = action;
      return distinct([
        ...state,
        ...(driveFavorites as DriveFavorite[]).map((x) => x.driveFavoriteId),
      ]);
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }

    default:
      return state;
  }
};

const meta: Reducer<DriveFavoriteMeta, ReduxAction> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_DRIVE_FAVORITES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_DRIVE_FAVORITE_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_DRIVE_FAVORITE_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage:
          'documents:driveFavorites.errorMessages.fetchDriveFavorite',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

const favorisingProgressById: Reducer<
  DriveItemFavorisingProgressMeta,
  ReduxAction
> = (state = {}, action) => {
  switch (action.type) {
    //#region -------------------------------- Add Progress
    case CREATE_DRIVE_FAVORITE_REQUEST: {
      const {
        payload: { driveItemId },
      } = action;

      return {
        ...state,
        [driveItemId]: {
          progress: 'creating',
          driveItemId: driveItemId,
        },
      };
    }

    case UPDATE_DRIVE_FAVORITE_REQUEST: {
      const {
        meta: { driveFavorite },
      } = action;

      return {
        ...state,
        [(driveFavorite as DriveFavorite).driveItemId]: {
          progress: 'updating',
          driveItemId: (driveFavorite as DriveFavorite).driveItemId,
        },
      };
    }

    case DELETE_DRIVE_FAVORITE_REQUEST: {
      const {
        payload: { driveItemId },
      } = action;

      return {
        ...state,
        [driveItemId]: {
          progress: 'deleting',
          driveItemId: driveItemId,
        },
      };
    }

    case DELETE_DRIVE_FAVORITES_REQUEST: {
      const { payload } = action;

      const obj = (payload as DriveFavorite[]).reduce(
        (acc, { driveItemId }) => {
          acc[driveItemId] = {
            progress: 'deleting',
            driveItemId: driveItemId,
          };
          return acc;
        },
        state
      );

      return obj;
    }

    //#endregion

    //#region -------------------------------- Remove Progress
    case UPDATE_DRIVE_FAVORITE_COMMIT:
    case CREATE_DRIVE_FAVORITE_COMMIT: {
      const {
        payload: { driveItemId },
      } = action;

      const newState = { ...state };
      delete newState[driveItemId];
      return newState;
    }

    case DELETE_DRIVE_FAVORITE_COMMIT: {
      const {
        meta: {
          driveFavorite: { driveItemId },
        },
      } = action;

      const { [driveItemId]: item, ...rest } = state;
      if (item) {
        return rest;
      }
      return state;
    }

    case DELETE_DRIVE_FAVORITES_COMMIT: {
      const {
        meta: { driveFavorites },
      } = action;

      const obj = (driveFavorites as DriveFavorite[]).reduce(
        (acc, { driveItemId }) => {
          if (acc.hasOwnProperty(driveItemId)) {
            const { [driveItemId]: _, ...restState } = acc;
            return restState;
          }
          return acc;
        },
        { ...state }
      );
      return obj;
    }

    case UPDATE_DRIVE_FAVORITE_ROLLBACK:
    case CREATE_DRIVE_FAVORITE_ROLLBACK:
    case DELETE_DRIVE_FAVORITE_ROLLBACK: {
      const {
        meta: {
          driveFavorite: { driveItemId },
        },
      } = action;

      const newState = { ...state };
      delete newState[driveItemId];
      return newState;
    }

    case DELETE_DRIVE_FAVORITES_ROLLBACK: {
      const {
        meta: { driveFavorites },
      } = action;

      const obj = (driveFavorites as DriveFavorite[]).reduce(
        (acc, { driveItemId }) => {
          if (acc.hasOwnProperty(driveItemId)) {
            const { [driveItemId]: _, ...restState } = acc;
            return restState;
          }
          return acc;
        },
        { ...state }
      );
      return obj;
    }
    //#endregion

    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

const favorisingProgressIds: Reducer<DriveItemId[], any> = (
  state = [],
  action
) => {
  switch (action.type) {
    //#region -------------------------------- Add Progress
    case DELETE_DRIVE_FAVORITE_REQUEST:
    case CREATE_DRIVE_FAVORITE_REQUEST: {
      const {
        payload: { driveItemId },
      } = action;

      return distinct([...state, driveItemId]);
    }

    case UPDATE_DRIVE_FAVORITE_REQUEST: {
      const {
        meta: { driveFavorite },
      } = action;

      return distinct([...state, (driveFavorite as DriveFavorite).driveItemId]);
    }

    case DELETE_DRIVE_FAVORITES_REQUEST: {
      const { payload } = action;

      return distinct([
        ...state,
        ...(payload as DriveFavorite[]).map((x) => x.driveItemId),
      ]);
    }
    //#endregion

    //#region -------------------------------- Remove Progress
    case CREATE_DRIVE_FAVORITE_COMMIT: {
      const {
        payload: { driveItemId },
      } = action;

      return state.filter((x) => x !== driveItemId);
    }

    case UPDATE_DRIVE_FAVORITE_COMMIT: {
      const {
        payload: { driveItemId },
      } = action;
      return state.filter((x) => x !== driveItemId);
    }

    case DELETE_DRIVE_FAVORITE_COMMIT: {
      const {
        meta: {
          driveFavorite: { driveItemId },
        },
      } = action;
      return state.filter((x) => x !== driveItemId);
    }

    case DELETE_DRIVE_FAVORITES_COMMIT: {
      const {
        meta: { driveFavorites },
      } = action;
      return state.filter(
        (x) =>
          !(driveFavorites as DriveFavorite[]).some((y) => y.driveItemId === x)
      );
    }

    case UPDATE_DRIVE_FAVORITE_ROLLBACK:
    case CREATE_DRIVE_FAVORITE_ROLLBACK:
    case DELETE_DRIVE_FAVORITE_ROLLBACK: {
      const {
        meta: {
          driveFavorite: { driveItemId },
        },
      } = action;
      return state.filter((x) => x !== driveItemId);
    }

    case DELETE_DRIVE_FAVORITES_ROLLBACK: {
      const {
        meta: { driveFavorites },
      } = action;
      return state.filter(
        (x) =>
          !(driveFavorites as DriveFavorite[]).some((y) => y.driveItemId === x)
      );
    }
    //#endregion

    case CLEAR_PRIO_CACHE: {
      return [];
    }

    default:
      return state;
  }
};
export default combineReducers({
  byId,
  ids,
  meta,
  favorisingProgressById,
  favorisingProgressIds,
});

export const getDriveFavorites: (
  state: DriveFavoriteState,
  projectId?: string
) => DriveFavorite[] = (state, projectId) => {
  return (state?.ids ?? [])
    .map((id) => state.byId[id])
    .filter((x) => (projectId ? x?.projectId === projectId : true))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export const getDriveFavorite: (
  state: DriveFavoriteState,
  driveFavoriteId: string
) => DriveFavorite = (state, driveFavoriteId) => state.byId[driveFavoriteId];

export const getFavorisingProgresses: (
  state: DriveFavoriteState
) => FavorisingProgress[] = (state) => {
  return (state?.favorisingProgressIds ?? []).map(
    (id) => state.favorisingProgressById[id]
  );
};
