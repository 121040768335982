import React from 'react';
import { Routes as ReactRouterDomRoutes, Route, Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import ProjectList from './ProjectList';
import ProjectDetailsPage from './ProjectDetailsPage';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

interface ProjectsProps {}

export const Projects: React.FC<ProjectsProps> = () => (
  <>
    <Flex.Row>
      <Link to="create">
        <Button>
          <Trans i18nKey="projects:links.create">Kontakt anlegen </Trans>
        </Button>
      </Link>
      <Link to="search">
        <Button type="link">
          <Trans i18nKey="projects:links.search">Kontakt suchen </Trans>
        </Button>
      </Link>
    </Flex.Row>
    <Routes>
      <Route path="/" element={<ProjectList />} />
      <Route path=":projectId/details" element={<ProjectDetailsPage />} />
      <Route path="search">
        <h1>Kontaktsuche</h1>
      </Route>
    </Routes>
  </>
);

export default Projects;
