import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { DriveItemId, GroupId, ProjectId } from '../../../../models/Types';
import ProjectPicker from '../../../projects/components/ProjectPicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getProject,
  getUploadList,
} from '../../../../apps/main/rootReducer';
import { Project } from '../../../../models/Project';
import { UploadFileState } from '../../reducers/uploadLists';
import Flex from '../../../../components/Flex';
import { AutoSizer, List } from 'react-virtualized';
import { createSelector } from 'reselect';
import moment, { Moment } from 'moment';
import UploadListItem from './UploadListItem';
import i18n from '../../../../i18n';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { selectWidget } from '../../../widgetArea/actions';
import { useTranslation } from 'react-i18next';
import { FolderMap } from '../../reducers/currentFolder';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    '& > div:first-child:not(:last-child)': {
      borderBottom: `1px solid ${theme.old.borders.colors.content}`,
    },
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.old.borders.colors.content}`,
    },
  },
  separator: {
    height: theme.old.spacing.unit(3),
    borderBottom: theme.old.borders.content,
    paddingLeft: theme.old.spacing.unit(2),
    fontSize: theme.old.typography.fontSize.label,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    fontWeight: 500,
  },
  timeSeparator: {
    color: theme.old.typography.colors.muted,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  projectPicker: {
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: 'none',
    },
  },
}));

const uploadListSelector = (groupId: GroupId) =>
  createSelector<
    [(state: RootReducerState) => UploadFileState[]],
    UploadFileState[]
  >(
    (state) => getUploadList(state, groupId),
    (uploadList) => {
      const lastModifiedTimeBySessionId = uploadList.reduce(
        (acc, { sessionId, lastTimeModified }) => {
          if (!acc[sessionId]) {
            acc[sessionId] = lastTimeModified;
          } else {
            acc[sessionId] = moment(acc[sessionId]).isBefore(
              moment(lastTimeModified)
            )
              ? lastTimeModified
              : acc[sessionId];
          }
          return acc;
        },
        {} as { [sessionId: string]: string }
      );
      return uploadList.sort(
        (
          { path: a, sessionId: sessionIdA },
          { path: b, sessionId: sessionIdB }
        ) => {
          if (sessionIdA !== sessionIdB) {
            return moment(lastModifiedTimeBySessionId[sessionIdA]).isBefore(
              moment(lastModifiedTimeBySessionId[sessionIdB])
            )
              ? 1
              : -1;
          }
          return a.localeCompare(b);
        }
      );
    }
  );

const droppedDriveItemBySessionIdSelector = (groupId: GroupId) =>
  createSelector<
    [
      (state: RootReducerState) => UploadFileState[],
      (state: RootReducerState) => FolderMap,
    ],
    {
      [sessionId: string]: {
        name: string;
        driveItemId: DriveItemId;
      };
    }
  >(
    (state) => getUploadList(state, groupId),
    (state) => state.documents.currentFolder.folders,
    (uploadList, folderMap) => {
      return uploadList.reduce(
        (acc, { sessionId, parentDriveItemId, path }) => {
          if (path.split('/').length === 1) {
            acc[sessionId] = {
              name: folderMap[parentDriveItemId]?.item?.name,
              driveItemId: folderMap[parentDriveItemId]?.item?.id,
            };
          }
          return acc;
        },
        {} as {
          [sessionId: string]: {
            name: string;
            driveItemId: DriveItemId;
          };
        }
      );
    }
  );

const generateDateSeperators = (
  currentDateTime: Moment,
  nextDateTime: Moment
) => {
  const today = moment();
  if (nextDateTime.isSame(today, 'day')) {
    if (!currentDateTime) {
      return i18n.t('documents:widget.uploadManager.separators.today');
    }
    return null;
  } else if (nextDateTime.isSame(today.clone().subtract(1, 'days'), 'day')) {
    if (currentDateTime) {
      if (!nextDateTime.isSame(currentDateTime, 'day')) {
        return i18n.t('documents:widget.uploadManager.separators.yesterday');
      }
      return null;
    }
    return i18n.t('documents:widget.uploadManager.separators.yesterday');
  }
  if (currentDateTime) {
    if (!currentDateTime.isSame(nextDateTime.clone(), 'days')) {
      return nextDateTime.format('dddd, DD. MMMM');
    }
    return null;
  }
  return nextDateTime.format('dddd, DD. MMMM');
};

interface UploadManagerProps {
  className?: string;
  projectId: ProjectId;
  onProjectIdChange?: (projectId: ProjectId) => void;
  onParentDriveItemClick?: (parentDriveItemId: DriveItemId) => void;
}

export const UploadManager: React.FC<UploadManagerProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, projectId, onProjectIdChange, onParentDriveItemClick } =
    props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const listRef = useRef<List>(null);
  const [selectedProjectId, setSelectedProjectId] =
    useState<ProjectId>(projectId);

  const selectedProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, selectedProjectId)
  );

  const groupId = useMemo(
    () => selectedProject?.groupId,
    [selectedProject?.groupId]
  );

  const uploadList = useSelector(uploadListSelector(groupId));
  const droppedDriveItemBySessionId = useSelector(
    droppedDriveItemBySessionIdSelector(groupId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnProjectIdChange = (value: string) => {
    setSelectedProjectId(value);
    if (onProjectIdChange) {
      onProjectIdChange(value);
    }
  };

  const handleOnClickParentItem = (parentDriveItemId: DriveItemId) => {
    dispatch(selectWidget('documents'));
    if (onParentDriveItemClick) {
      onParentDriveItemClick(parentDriveItemId);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (projectId !== selectedProjectId) {
      setSelectedProjectId(projectId);
    }
  }, [projectId, selectedProjectId]);

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.recomputeRowHeights();
      listRef.current.forceUpdate();
    }
  }, [droppedDriveItemBySessionId, listRef]);
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <ProjectPicker
        value={selectedProjectId}
        onChange={handleOnProjectIdChange}
        className={classes.projectPicker}
      />
      <Flex.Item flex={1}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={listRef}
              width={width}
              height={height}
              rowCount={uploadList.length}
              rowHeight={({ index }) => {
                const item = uploadList[index];
                const previousItem = index === 0 ? null : uploadList[index - 1];
                const separator = generateDateSeperators(
                  !!previousItem
                    ? moment(previousItem?.lastTimeModified)
                    : null,
                  moment(item?.lastTimeModified)
                );
                let itemHeightWithoutSeparator = 48;
                if (separator) {
                  itemHeightWithoutSeparator += theme.old.spacing.unit(3);
                }
                if (item.sessionId !== previousItem?.sessionId) {
                  itemHeightWithoutSeparator += theme.old.spacing.unit(3);
                }
                return itemHeightWithoutSeparator;
              }}
              rowRenderer={({ index, key, style }) => {
                const item = uploadList[index];
                const previousItem = index === 0 ? null : uploadList[index - 1];
                const separator = generateDateSeperators(
                  !!previousItem
                    ? moment(previousItem?.lastTimeModified)
                    : null,
                  moment(item?.lastTimeModified)
                );
                let timeSepartor = null;
                if (item.sessionId !== previousItem?.sessionId) {
                  timeSepartor = `${moment(item.lastTimeModified).format(
                    'HH:mm'
                  )} ${t('common:moment.aClock')}${
                    droppedDriveItemBySessionId[item.sessionId]
                      ? ` - ${droppedDriveItemBySessionId[item.sessionId]
                          ?.name}`
                      : ''
                  }`;
                }
                return (
                  <div key={key} style={style} className={classes.listItem}>
                    {separator && (
                      <div className={classes.separator}>{separator}</div>
                    )}
                    {timeSepartor && (
                      <div
                        className={classNames(
                          classes.separator,
                          classes.timeSeparator
                        )}
                        onClick={() => {
                          if (
                            droppedDriveItemBySessionId[item.sessionId]
                              ?.driveItemId
                          ) {
                            handleOnClickParentItem(
                              droppedDriveItemBySessionId[item.sessionId]
                                .driveItemId
                            );
                          }
                        }}
                      >
                        {timeSepartor}
                      </div>
                    )}
                    <UploadListItem
                      item={item}
                      onClickParentItem={handleOnClickParentItem}
                    />
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </Flex.Item>
    </Flex.Column>
  );
};

export default UploadManager;
