import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import { DndEmailDto } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';
import Flex from '../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  iconSize: {
    fontSize: 18,
  },
}));

interface MailListItemDragPreviewProps {
  className?: string;
  item: DndEmailDto;
}

export const MailListItemDragPreview: React.FC<MailListItemDragPreviewProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, item } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isMultipleItems = item?.selectedMessages?.length > 1;

  const isDraggedItemSelected = item?.selectedMessages?.some(
    (x) => x.id === item.message.id
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      alignItems="center"
      childrenGap={theme.old.spacing.unit(
        isMultipleItems && isDraggedItemSelected ? 2 : 1
      )}
      className={classNames(classes.root, className)}
    >
      <div className={classes.iconSize}>
        {isMultipleItems && isDraggedItemSelected ? (
          <span className="fa-layers">
            <FontAwesomeIcon icon={['fal', 'rectangle-landscape']} />
            <FontAwesomeIcon
              icon={['fal', 'envelope']}
              style={{
                position: 'absolute',
                top: -12,
                right: -8,
                backgroundColor: theme.old.palette.backgroundPalette.content,
                outline: `1px solid ${theme.old.palette.backgroundPalette.content}`,
              }}
            />
          </span>
        ) : (
          <FontAwesomeIcon icon={['fal', 'envelope']} />
        )}
      </div>
      <div>
        {t(
          `common:drop.move.${
            isMultipleItems && isDraggedItemSelected
              ? 'multipleMessages'
              : 'message'
          }`,
          isMultipleItems && isDraggedItemSelected
            ? {
                amount: item?.selectedMessages?.length,
              }
            : {
                name:
                  item?.message?.subject ?? t('mail:messageDisplay.noSubject'),
              }
        )}
      </div>
    </Flex.Row>
  );
};

export default MailListItemDragPreview;
