import { useEffect, useState } from 'react';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { distinct } from '../../../util';

const presetKeys = [
  'pdf',
  'word',
  'excel',
  'powerpoint',
  'image',
  'video',
  'audio',
  'mail',
  'zip',
] as const;

type PresetKey = (typeof presetKeys)[number];

const presets: Record<PresetKey, string[]> = {
  pdf: ['pdf'],
  word: ['docx', 'doc', 'dotx', 'dotm', 'docm', 'dot'],
  excel: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt'],
  powerpoint: [
    'pptx',
    'pptm',
    'ppsx',
    'ppsm',
    'potx',
    'potm',
    'ppt',
    'pps',
    'pot',
  ],
  image: [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'tif',
    'webp',
    'svg',
    'raw',
    'cr2',
    'nef',
    'arw',
    'dng',
    'heif',
    'heic',
    'psd',
    'ai',
    'epsp',
  ],
  video: ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv'],
  audio: ['mp3', 'wav', 'wma', 'aac', 'flac', 'm4a', 'ogg'],
  mail: ['eml'],
  zip: ['zip', 'rar'],
};

const FileExtensionPickerConsumer: React.FC<FilterPickerConsumerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children, value, onChange } = props;

  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [searchValue, setSearchValue] = useState<string>('');

  const [customValues, setCustomValues] = useState<string[]>([]);

  const searchValueIsCustomValue = customValues.includes(
    searchValue.toLowerCase() || ''
  );
  const searchValueIsPresetValue = Object.values(presets)
    .flat()
    .includes(searchValue.toLowerCase() || '');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnPresetClick = (presetKey: string) => {
    const presetValuesalreadyExist = presets[presetKey].every((v) =>
      value.includes(v)
    );
    if (presetValuesalreadyExist) {
      const valueWithoutPresetValues = value.filter(
        (v) => !presets[presetKey].includes(v)
      );
      onChange(valueWithoutPresetValues, {
        value: [],
        label: '',
        searchValue: '',
      });
    } else {
      const presetValues = presets[presetKey];
      const valueWithoutPresetValues = value.filter(
        (v) => !presetValues.includes(v)
      );
      onChange([...valueWithoutPresetValues, ...presetValues], {
        value: presetValues,
        label: presetKey,
        searchValue: presetValues.join('') + presetKey,
      });
    }
  };

  const handleOnSelect = (value: string) => {
    const isPresetValue = Object.values(presets)
      .flat()
      .includes(value.toLowerCase());
    const isCustomValue = customValues.includes(value.toLowerCase());
    if (!isPresetValue && !isCustomValue) {
      setCustomValues((currentValue) => currentValue.concat(value));
      setSearchValue('');
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const valueWithoutPresetValues = value.filter(
      (v) => !Object.values(presets).flat().includes(v)
    );

    if (valueWithoutPresetValues.length > 0) {
      setCustomValues((currentValue) =>
        distinct(currentValue.concat(valueWithoutPresetValues))
      );
    }
  }, [value]);
  //#endregion

  return (
    <>
      {children({
        options: (searchValue &&
        !searchValueIsCustomValue &&
        !searchValueIsPresetValue
          ? [
              {
                value: searchValue,
                label: searchValue,
                searchValue: searchValue,
              },
            ]
          : []
        ).concat(
          Object.entries(presets)
            .map(([key, value]) => {
              return value.map((v) => {
                return {
                  value: v,
                  label: v,
                  searchValue: v + key,
                };
              });
            })
            .flat()
            .concat(
              customValues.map((v) => {
                return {
                  value: v,
                  label: v,
                  searchValue: v,
                };
              })
            )
            .sort((a, b) => a.value.localeCompare(b.value))
        ),
        dropdownRender: (menu) => {
          return (
            <div style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 8,
                  padding: 8,
                }}
              >
                {Object.keys(presets).map((key) => {
                  return (
                    <div
                      key={key}
                      className={
                        'prio-pill prio-pill-default prio-pill-small-size'
                      }
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        gap: 4,
                      }}
                      onClick={() => {
                        handleOnPresetClick(key);
                      }}
                    >
                      {t(`documents:fileExtensionsForSelectSearch.${key}`)}
                      {presets[key].every((v: string) => value.includes(v)) && (
                        <FontAwesomeIcon icon={['fal', 'check']} />
                      )}
                    </div>
                  );
                })}
              </div>
              {menu}
            </div>
          );
        },
        onSearch: setSearchValue,
        //@ts-ignore
        onSelect: handleOnSelect,
      })}
    </>
  );
};

export default FileExtensionPickerConsumer;
