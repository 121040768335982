import React, { useEffect, useState } from 'react';
import {
  AbsenceProposal,
  AbsenceProposalFormModel,
  AbsentWorkDaysResult,
  UpdateAbsenceProposalRequest,
} from '../../../models/AbsenceProposal';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import {
  Alert,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  notification,
  Row,
  Select,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  formatNumber,
  compactDateWithWeekDayFormatString,
} from '../../../util';
import { rowGutter, colon } from '../../../util/forms';
import { AbsenceType, ContactId, OfficeId } from '../../../models/Types';
import ContactPicker from '../../contacts/components/ContactPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDatePickerLocale from '../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import {
  getUserMe,
  getContactsByIdState,
} from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ContactsByIdState } from '../../contacts/reducers/contacts';
import { apiFetchAbsentWorkDays } from '../api';
import { User } from '../../../models/User';
import { Moment } from 'moment-timezone';
import { apiFetchPrincipals, apiFetchPrincipalsMe } from '../../employee/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%' },
  value: {
    textAlign: 'right',
    flex: 1,
  },
  datePicker: {
    width: `calc(80% - ${16 + theme.old.spacing.unit(1)}px)`,
    marginRight: theme.old.spacing.unit(1),
  },
  datePickerFullWidth: {
    width: '100%',
    paddingRight: '4px',
  },
  absentWorkDays: {
    textAlign: 'center',
  },
  halfDayCheckboxRow: {
    paddingTop: '15px',
  },
  halfDayCheckboxFirst: {
    marginBottom: '15px',
  },
  halfDayCheckboxFirstFullWidth: {
    marginBottom: '15px',
  },
  noMarginBottom: {
    marginBottom: '0px',
    marginLeft: '2px',
  },
  publicHolidays: {
    maxHeight: 69,
    overflowY: 'auto',
  },
  publicHolidaysCount: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  publicHolidayDate: {
    width: 125,
  },
  divider: {
    margin: '4px 0px 28px 0px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  warningIcon: {
    marginRight: theme.old.spacing.unit(2),
  },
  alert: {
    marginTop: '24px',
  },
}));

interface AbsenceProposalDetailsEditProps {
  onFinish?: (value: UpdateAbsenceProposalRequest) => void;
  absenceProposal: AbsenceProposal;
  disableForm?: boolean;
  updateAbsenceProposal?: (value: UpdateAbsenceProposalRequest) => void;
  actionsDisabled?: boolean;
  setSubDrawerOpen?: (open: boolean) => void;
  canSelectApplicant: boolean;
  officeId?: OfficeId;
}

export const missingPrincipalSelect = (
  principalIds: string[],
  contactsByIdState: ContactsByIdState,
  form: FormInstance<AbsenceProposalFormModel>
) => {
  const principalId = form.getFieldsValue(['principalId'])?.principalId;
  if (principalIds.includes(principalId)) {
    return null;
  }
  return (
    <Select.Option
      value={principalId}
      key={principalId}
      label={
        contactsByIdState[principalId]?.firstName &&
        contactsByIdState[principalId]?.lastName
          ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
          : ''
      }
    >
      {contactsByIdState[principalId]?.firstName &&
      contactsByIdState[principalId]?.lastName
        ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
        : ''}
    </Select.Option>
  );
};

const fetchAbsentWorkDays = async (
  form: FormInstance<any>,
  setAbsentWorkDays: (data: AbsentWorkDaysResult) => void,
  t: TFunction,
  userMe: User,
  period?: Moment[],
  officeId?: OfficeId
) => {
  period = period ?? form.getFieldValue('period');
  const from = period[0].format('YYYY-MM-DD');
  const to = period[1].format('YYYY-MM-DD');
  const internalContactId = form.getFieldValue('applicantId');
  const firstIsHalfDay = form.getFieldValue('firstIsHalfDay');
  const lastIsHalfDay = form.getFieldValue('lastIsHalfDay');
  const { result, data } = await apiFetchAbsentWorkDays(
    from,
    to,
    firstIsHalfDay,
    lastIsHalfDay,
    userMe.id !== internalContactId ? internalContactId : undefined,
    officeId
  );

  if (result.status >= 200 && result.status < 300) {
    if (typeof data === 'object') {
      setAbsentWorkDays(data);
    }
  } else {
    notification.open({
      message: t('common:error'),
      description: t('absences:errorMessages.fetchAbsentWorkdaysError'),
    });
  }

  form.setFieldsValue({
    absentWorkdays: data.absentWorkdays,
  });
};

export const AbsenceProposalDetailsEdit: React.FC<
  AbsenceProposalDetailsEditProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const datePickerLocale: PickerLocale = useDatePickerLocale();

  const {
    absenceProposal,
    disableForm,
    updateAbsenceProposal,
    actionsDisabled,
    setSubDrawerOpen,
    canSelectApplicant,
    officeId,
  } = props;

  const transformedAbsenceProposal: AbsenceProposalFormModel = {
    ...absenceProposal,
    period: [
      absenceProposal.from
        ? moment(absenceProposal.from, moment.ISO_8601)
        : moment().add('1', 'day').startOf('day'),
      absenceProposal.to
        ? moment(absenceProposal.to, moment.ISO_8601)
        : moment().add('1', 'day').startOf('day'),
    ],
  };

  const contactsByIdState = useSelector(getContactsByIdState);
  const userMe = useSelector(getUserMe);

  const [absenceTypeOther, setAbsenceTypeOther] = useState<boolean>(
    absenceProposal?.absenceType === 'other'
  );

  const [absenceTypeAnnualLeave, setAbsenceTypeAnnualLeave] = useState<boolean>(
    absenceProposal?.absenceType === 'annualLeave'
  );

  const [numberOfAbsentWorkDays, setNumberOfAbsentWorkDays] =
    useState<AbsentWorkDaysResult>({
      absentWorkdays: (absenceProposal as AbsenceProposal)?.absentWorkdays ?? 1,
      publicHolidays: [],
    });

  const [isSingleDay, setIsSingleDay] = useState<boolean>(
    !!absenceProposal && absenceProposal.from === absenceProposal.to
  );

  const [principalIds, setPrincipalIds] = useState<ContactId[]>([]);

  const [currentApplicantId, setCurrentApplicantId] = useState<ContactId>(
    transformedAbsenceProposal?.applicantId ?? null
  );

  const [tooManyDaysChosen, setTooManyDaysChosen] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
  }, [absenceProposal, form]);

  const onFinish = (currentValues) => {
    const { period, ...rest } = currentValues;
    const result: UpdateAbsenceProposalRequest = {
      ...rest,
      from: period[0].toISOString(true).split('T')[0],
      to: period[1].toISOString(true).split('T')[0],
      rowVersion: absenceProposal.rowVersion,
      absenceProposalId: absenceProposal.absenceProposalId,
    };
    updateAbsenceProposal(result);
    setSubDrawerOpen(false);
  };

  const onCancel = () => {
    setSubDrawerOpen(false);
  };

  useEffect(() => {
    const fetchPrincipals = async () => {
      const promise = new Promise<void>(async (resolve) => {
        const { data } = canSelectApplicant
          ? await apiFetchPrincipals(currentApplicantId)
          : await apiFetchPrincipalsMe();
        if (data) {
          setPrincipalIds(data);
        }
        resolve();
      });
      return promise.then(async () => {
        if (!absenceProposal) {
          form.setFieldsValue({
            principalId: contactsByIdState[currentApplicantId].managerId,
          });
        }
      });
    };
    fetchPrincipals();
  }, [
    currentApplicantId,
    canSelectApplicant,
    contactsByIdState,
    form,
    absenceProposal,
  ]);

  return (
    <Flex.Column
      childrenGap={theme.old.spacing.unit(2)}
      className={classes.root}
    >
      <Typography.Title level={2}>
        {t('absences:details.titleEditMode')}
      </Typography.Title>
      <Form
        className={classes.form}
        initialValues={transformedAbsenceProposal}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onValuesChange={(changedValues: AbsenceProposalFormModel) => {
          if (changedValues.absenceType) {
            setAbsenceTypeOther(changedValues.absenceType === 'other');
            setAbsenceTypeAnnualLeave(
              changedValues.absenceType === 'annualLeave'
            );
          }
          if (changedValues.period) {
            setIsSingleDay(
              changedValues.period[0].isSame(changedValues.period[1])
            );
            fetchAbsentWorkDays(
              form,
              setNumberOfAbsentWorkDays,
              t,
              userMe,
              changedValues.period,
              officeId
            );
            setTooManyDaysChosen(false);
          }
          if (
            changedValues.firstIsHalfDay !== undefined ||
            changedValues.lastIsHalfDay !== undefined
          ) {
            fetchAbsentWorkDays(
              form,
              setNumberOfAbsentWorkDays,
              t,
              userMe,
              undefined,
              officeId
            );
            setTooManyDaysChosen(false);
          }
          if (changedValues.applicantId) {
            setCurrentApplicantId(changedValues.applicantId);
          }
          if (changedValues.absentWorkdays) {
            if (
              changedValues.absentWorkdays >
              numberOfAbsentWorkDays?.absentWorkdays
            ) {
              setTooManyDaysChosen(true);
            } else {
              setTooManyDaysChosen(false);
            }
          }
        }}
      >
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="absenceType"
              colon={colon}
              label={t('absences:form.labels.absenceType')}
            >
              <Select<AbsenceType> disabled={true}>
                <Select.Option value="annualLeave">
                  {t('absences:form.absenceTypes.annualLeave')}
                </Select.Option>
                <Select.Option value="overtimeCompensation">
                  {t('absences:form.absenceTypes.overtimeCompensation')}
                </Select.Option>
                <Select.Option value="paidSpecialLeave">
                  {t('absences:form.absenceTypes.paidSpecialLeave')}
                </Select.Option>
                <Select.Option value="dayOfIllness">
                  {t('absences:form.absenceTypes.dayOfIllness')}
                </Select.Option>
                <Select.Option value="parentalLeave">
                  {t('absences:form.absenceTypes.parentalLeave')}
                </Select.Option>
                <Select.Option value="maternityLeave">
                  {t('absences:form.absenceTypes.maternityLeave')}
                </Select.Option>
                <Select.Option value="school">
                  {t('absences:form.absenceTypes.school')}
                </Select.Option>
                <Select.Option value="training">
                  {t('absences:form.absenceTypes.training')}
                </Select.Option>
                <Select.Option value="annualLeavePlanning">
                  {t('absences:form.absenceTypes.annualLeavePlanning')}
                </Select.Option>
                {/*
                <Select.Option value="UnpaidLeave">
                  {t('absences:form.absenceTypes.unpaidLeave')}
                </Select.Option>
                
                <Select.Option value="Other">
                  {t('absences:form.absenceTypes.other')}
                </Select.Option>
                */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {absenceTypeOther && (
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="reason"
                colon={colon}
                label={t('absences:form.labels.absenceType')}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="applicantId"
              label={t('absences:form.labels.applicantId')}
              rules={[
                {
                  required: true,
                  message: t('absences:form.validation.missingApplicantId'),
                },
              ]}
            >
              <ContactPicker contactType="InternalContact" disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Divider className={classes.divider} />
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={18} style={{ paddingRight: '4px' }}>
            <Form.Item
              name="period"
              label={t('absences:form.labels.period')}
              colon={colon}
              noStyle
            >
              <DatePicker.RangePicker
                format="DD.MM.YYYY"
                className={
                  absenceProposal.absenceType === 'annualLeave'
                    ? classes.datePickerFullWidth
                    : classes.datePickerFullWidth
                }
                disabled={disableForm}
                locale={datePickerLocale}
                allowClear={false}
                suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
              />
            </Form.Item>
          </Col>
          <Col span={6} style={{ paddingLeft: '4px' }}>
            {absenceTypeAnnualLeave && (
              <Form.Item
                name="absentWorkdays"
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('absences:form.warning.pleaseInsertValue'),
                  },
                  {
                    pattern: /^((0|[1-9][0-9]*)((\.|,)[1-9][0-9]*)?)$/,
                    message: t('absences:form.warning.invalidValue'),
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  defaultValue={formatNumber(
                    numberOfAbsentWorkDays?.absentWorkdays
                  )}
                  className={classes.absentWorkDays}
                />
              </Form.Item>
            )}
          </Col>
        </Row>
        {tooManyDaysChosen ? (
          <Alert
            type="warning"
            message={
              <Flex.Row alignItems="center">
                <FontAwesomeIcon
                  icon={['fal', 'exclamation-triangle']}
                  className={classes.warningIcon}
                />
                <Flex.Item flex={1}>
                  {t('absences:form.warning.tooManyDays')}
                </Flex.Item>
              </Flex.Row>
            }
            className={classes.alert}
          ></Alert>
        ) : null}
        <Row
          gutter={theme.old.spacing.unit(rowGutter)}
          className={classes.halfDayCheckboxRow}
        >
          <Col span={9}>
            <Flex.Row>
              <Form.Item
                name="firstIsHalfDay"
                colon={colon}
                valuePropName="checked"
                className={
                  absenceProposal.absenceType === 'annualLeave'
                    ? classes.halfDayCheckboxFirst
                    : classes.halfDayCheckboxFirstFullWidth
                }
              >
                <Checkbox disabled={disableForm}>
                  {t(
                    isSingleDay
                      ? 'absences:form.labels.am'
                      : 'absences:form.labels.firstIsHalfDay'
                  )}
                </Checkbox>
              </Form.Item>
            </Flex.Row>
          </Col>
          <Col span={15}>
            <Form.Item
              name="lastIsHalfDay"
              colon={colon}
              className={classes.noMarginBottom}
              valuePropName="checked"
            >
              <Checkbox disabled={disableForm}>
                {t(
                  isSingleDay
                    ? 'absences:form.labels.pm'
                    : 'absences:form.labels.lastIsHalfDay'
                )}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {numberOfAbsentWorkDays?.publicHolidays.length > 0 && (
          <>
            <div className={classes.publicHolidaysCount}>
              {t('absences:form.publicHolidays', {
                count: numberOfAbsentWorkDays?.publicHolidays.length,
              })}
            </div>
            <Flex.Column className={classes.publicHolidays}>
              {numberOfAbsentWorkDays.publicHolidays
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                .map((publicHoliday) => (
                  <Flex.Row key={publicHoliday.date}>
                    <Flex.Item className={classes.publicHolidayDate}>
                      {compactDateWithWeekDayFormatString(publicHoliday.date)}
                    </Flex.Item>
                    <Flex.Item flex={1}>{publicHoliday.name}</Flex.Item>
                  </Flex.Row>
                ))}
            </Flex.Column>
          </>
        )}
        <Divider />
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="principalId"
              label={t('absences:form.labels.principalId')}
              rules={[
                {
                  required: true,
                  message: t('absences:form.validation.missingPrincipalId'),
                },
              ]}
            >
              <Select<ContactId>
                disabled={disableForm}
                showSearch
                optionFilterProp="label"
              >
                {missingPrincipalSelect(principalIds, contactsByIdState, form)}
                {principalIds.map((principalId) => {
                  if (
                    !contactsByIdState[principalId]?.firstName ||
                    !contactsByIdState[principalId]?.lastName
                  )
                    return '';
                  return (
                    <Select.Option
                      value={principalId}
                      key={principalId}
                      label={
                        contactsByIdState[principalId]?.firstName &&
                        contactsByIdState[principalId]?.lastName
                          ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
                          : ''
                      }
                    >
                      {contactsByIdState[principalId]?.firstName &&
                      contactsByIdState[principalId]?.lastName
                        ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
                        : ''}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="notifyContactIds"
              label={t('absences:form.labels.notifyContactIds')}
            >
              <ContactPicker
                multiple
                contactType="InternalContact"
                disabled={disableForm}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="substituteId"
              label={t('absences:form.labels.substituteId')}
              rules={[
                {
                  required: true,
                  message: t('absences:form.validation.missingSubstituteId'),
                },
              ]}
            >
              <ContactPicker
                contactType="InternalContact"
                disabled={disableForm}
                excludedContactIds={[currentApplicantId]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex.Row gutter={theme.old.spacing.unit(rowGutter)} flex={1}>
          <Col span={24}>
            <Form.Item
              name="note"
              label={t('absences:form.labels.notes')}
              colon={colon}
            >
              <Input.TextArea
                disabled={disableForm}
                style={{ height: '75px' }}
              />
            </Form.Item>
          </Col>
        </Flex.Row>
        <Flex.Row>
          <Flex.Item flex={1}>
            <Button
              type="default"
              onClick={onCancel}
              disabled={actionsDisabled || disableForm}
              style={{ marginRight: theme.old.spacing.unit(1) }}
            >
              {t('common:actions.cancel')}
            </Button>
          </Flex.Item>
          <Flex.Item>
            <Button
              htmlType={'submit'}
              disabled={actionsDisabled || disableForm}
            >
              {t('common:actions.save')}
            </Button>
          </Flex.Item>
        </Flex.Row>{' '}
      </Form>
    </Flex.Column>
  );
};

export default AbsenceProposalDetailsEdit;
