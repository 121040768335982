import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { Drawer, Dropdown, useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../../../theme/types';
import ActionBar from '../../../components/ActionBar';
import ListMenuArea from '../../common/ListMenuArea';
import JiraConfigurationForm from './JiraConfigurationForm';
import { apiGetAllProjectExtensionConfiguration } from '../../projects/api';
import { ProjectExtensionConfiguration } from '../../../models/ProjectExtension';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import JiraConfigurationCreate from './JiraConfigurationCreate';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  item: {
    color: theme.colors.application.typography.default,
    fontWeight: theme.font.fontSize.regular,
  },
  subItem: {
    color: theme.colors.application.typography.muted,
    fontWeight: theme.font.fontSize.extraSmall,
    float: 'left',
  },
  listItem: {
    padding: `${theme.spacing.regular * 0.5}px ${theme.spacing.regular}px`,
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& h2': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: `${theme.spacing.regular * 0.5}px`,
    },
  },
  icon: {
    minWidth: theme.spacing.regular * 1.5,
    fontSize: theme.font.fontSize.extraSmall,
  },
  overlay: {
    '& > ul': {
      paddingInlineStart: '0px',
      marginBlock: '0.5rem',
    },
  },
}));

interface ConfigAddInsProps {
  className?: string;
}

export const ConfigAddIns: React.FC<ConfigAddInsProps> = (props) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const extensionTypes = useMemo(() => ['jira'], []);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [projectExtensionConfiguration, setProjectExtensionConfiguration] =
    useState<ProjectExtensionConfiguration[]>([]);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [
    fetchProjectExtensionConfiguration,
    setfetchProjectExtensionConfiguration,
  ] = useState<boolean>(false);

  const [selectedExtensionType, setSelectedExtensionType] =
    useState<string>('');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchProjectExtensionConfiguration = async () => {
      setfetchProjectExtensionConfiguration(true);
      const { data } = await apiGetAllProjectExtensionConfiguration();
      setProjectExtensionConfiguration(data);
      setfetchProjectExtensionConfiguration(false);
    };
    fetchProjectExtensionConfiguration();
  }, [setfetchProjectExtensionConfiguration]);

  const projectExtensionConfigurationChanged = async () => {
    setfetchProjectExtensionConfiguration(true);
    const { data } = await apiGetAllProjectExtensionConfiguration();
    setProjectExtensionConfiguration(data);
    setfetchProjectExtensionConfiguration(false);
  };

  const handleSelectExtensionType = (selectedOption) => {
    if (selectedOption) {
      const index = parseInt(selectedOption.value);
      setSelectedExtensionType(extensionTypes[index]);
      setOpenNew(true);
    }
  };
  //#endregion

  const options = extensionTypes?.map((extType, index) => ({
    label: extType.charAt(0).toUpperCase() + extType.slice(1),
    value: index.toString(),
    disabled: projectExtensionConfiguration?.some(
      (ext) => ext.extensionType === extType
    ),
  }));

  const ProjectExtensionListItem: React.FC<{
    index: number;
    key: string;
    isScrolling: boolean;
    isVisible: boolean;
  }> = ({ index }) => {
    return (
      <div className={classes.listItem}>
        <Flex.Row
          className={classes.item}
          childrenGap={theme.spacing.extraSmall}
          alignItems="center"
        >
          <SvgIcon
            width={42}
            height={42}
            path={getSvgIconPathByType(
              projectExtensionConfiguration[index]?.extensionType
            )}
          ></SvgIcon>
          <Flex.Column>
            <div style={{ fontSize: '16px', fontWeight: '500' }}>
              {projectExtensionConfiguration[index]?.extensionType
                .charAt(0)
                .toUpperCase() +
                projectExtensionConfiguration[index]?.extensionType.slice(1)}
            </div>
            <div style={{ fontWeight: '400' }}>
              {projectExtensionConfiguration[index]?.isEnabled
                ? t('settings:jiraConfig.isEnabled')
                : t('settings:jiraConfig.isDisabled')}
            </div>
          </Flex.Column>
        </Flex.Row>
      </div>
    );
  };

  return (
    <>
      <Flex.Column flex={1} className={classes.root}>
        <Flex.Row childrenGap={theme.spacing.regular} marginBottom={16}>
          <ActionBar>
            <Dropdown
              options={options}
              onSelect={handleSelectExtensionType}
              overlayClassName={classNames(classes.overlay, 'prio-dropdown')}
              buttonProps={{
                type: 'primary',
                suffixIconProp: ['fal', 'chevron-down'],
                disabled: options?.every((option) => option.disabled),
              }}
              defaultLabel={t('settings:addInsConfig.newConfig')}
              placement="bottomRight"
            ></Dropdown>
          </ActionBar>
        </Flex.Row>
        <Flex.Row className={classes.root}>
          <ListMenuArea
            // sort by name
            menuItems={projectExtensionConfiguration?.sort((a, b) =>
              a.extensionType.localeCompare(b.extensionType)
            )}
            menuItemIdKey="projectExtensionConfigurationId"
            menuItemEmptyString={t('settings:addInsConfig.emptyText')}
            listItem={ProjectExtensionListItem}
            pageNode={
              <JiraConfigurationForm
                projectExtensionConfigurationChanged={
                  projectExtensionConfigurationChanged
                }
              />
            }
            isLoading={fetchProjectExtensionConfiguration}
          />
        </Flex.Row>
      </Flex.Column>
      <Drawer
        title={t('settings:jiraConfig.newJiraConfig')}
        visible={openNew}
        closable={true}
        onClose={() => {
          setOpenNew(false);
        }}
        footer={<></>}
      >
        {selectedExtensionType === 'jira' && (
          <JiraConfigurationCreate
            projectExtensionConfigurationChanged={
              projectExtensionConfigurationChanged
            }
            closeDrawer={() => {
              setOpenNew(false);
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default ConfigAddIns;
