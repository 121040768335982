import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';

/** Company classification fetching */
export const FETCH_COMPANY_CLASSIFICATIONS_REQUEST =
  PRIO + 'FETCH_COMPANY_CLASSIFICATIONS_REQUEST';
export const FETCH_COMPANY_CLASSIFICATIONS_COMMIT =
  PRIO + 'FETCH_COMPANY_CLASSIFICATIONS_COMMIT';
export const FETCH_COMPANY_CLASSIFICATIONS_ROLLBACK =
  PRIO + 'FETCH_COMPANY_CLASSIFICATIONS_ROLLBACK';

export const fetchCompanyClassifications = () => ({
  type: FETCH_COMPANY_CLASSIFICATIONS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/CompanyClassification`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_COMPANY_CLASSIFICATIONS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_COMPANY_CLASSIFICATIONS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchCompanyClassificationError',
          timeout: 6,
        },
      },
    },
  },
});
