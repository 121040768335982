import i18n from '../i18n';

export const defaultRetryOptions = [0, 2000, 5000, 8000, 15000, 20000];

const wait = (value: number) => {
  return new Promise((resolve) => setTimeout(resolve, value));
};

const fetchWithRetry: (
  input: RequestInfo,
  init?: RequestInit,
  retryWithErrorResponse?: boolean,
  retryOptions?: number[]
) => Promise<Response> = async (
  input,
  init,
  retryWithErrorResponse = false,
  retryOptions = defaultRetryOptions
) => {
  if (init !== null && init !== undefined) {
    init.headers['Accepted-Language'] = i18n.language;
  }
  const [delay, ...rest] = retryOptions;
  try {
    await wait(delay);
    const result = await fetch(input, init);
    if (
      (result.status >= 200 && result.status < 300) ||
      (!retryWithErrorResponse && result.status >= 400 && result.status < 500)
    ) {
      return result;
    }
    throw result;
  } catch (error) {
    if (rest.length > 0) {
      return fetchWithRetry(input, init, retryWithErrorResponse, rest);
    } else if (!('status' in error)) {
      return Response.error();
    } else {
      return error;
    }
  }
};

export default fetchWithRetry;
