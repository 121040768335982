import { Reducer } from 'redux';
import { ReduxAction } from '../../../models/Redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { UPDATE_DOCUMENTS_TABLE_WIDTHS } from '../actions/columnWidths';

export interface ColumnWidthsState {
  [key: string]: number;
}

export const initialState: ColumnWidthsState = {};

const columnWidths: Reducer<
  ColumnWidthsState,
  ReduxAction<{
    widths: ColumnWidthsState;
  }>
> = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DOCUMENTS_TABLE_WIDTHS: {
      const {
        meta: { widths },
      } = action;
      return widths;
    }

    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default columnWidths;

export const getColumnWidths = (state: ColumnWidthsState) => state;
