import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { makePrioStyles } from '../../../theme/utils';
import MailMenu from './MailMenu/MailMenu';
import { Message } from '../../../models/Message';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMailMenuOpen,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { setMailMenuNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import { useDrop } from 'react-dnd';
import { DND_TYPE_EMAIL } from '../../../dnd/types';
import { setActiveMailFolder } from '../actions/actionControllers/mailFoldersController';
import { useTranslation } from 'react-i18next';
import GlobalSiderTrigger from '../../../components/GlobalSiderTrigger';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { MailFolderId } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-layout-sider-trigger': {
      background: 'transparent',
      height: 'auto',
      textAlign: 'start',
      position: 'unset',
      '& > div > div > div': {
        width: '48px',
      },
      '& > div > div > div > div': {
        paddingLeft: '8px',
      },
      '& .ant-btn-icon-only': {
        width: '48px',
      },
    },
    '& .ant-layout-sider-children': {
      overflow: 'hidden',
      paddingBottom: theme.old.spacing.unit(1.5),
    },
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
    height: '100%',
    overflow: 'hidden',
  },
  rootSettingsOpen: {
    '& .ant-layout-sider-trigger': {
      '& > div > div > div': {
        // width: '241px',
        paddingRight: '5px',
      },
      '& .ant-btn-icon-only': {
        width: '60x',
        paddingRight: '34px',
        paddingLeft: '22px',
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
  },
}));

interface MailNavigationProps {
  projectId: string;

  pathPrefix?: string;
  onSearchResult?: (messages: Message[]) => void;
  handleOpenSettings: (value: boolean) => void;
  openSettings: boolean;
  clearSelectedMessages?: (messageIds: Message[]) => void;
}

export const MailNavigation: React.FC<MailNavigationProps> = React.memo(
  (props) => {
    const { mailFolderId } = useParams();
    return <MemoizedMailNavigation {...props} mailFolderId={mailFolderId} />;
  }
);

interface MemoizedMailNavigationProps extends MailNavigationProps {
  mailFolderId: MailFolderId;
}

const MemoizedMailNavigation: React.FC<MemoizedMailNavigationProps> =
  React.memo((props) => {
    const classes = useStyles();
    const theme = useTheme<PrioTheme>();
    const {
      projectId,
      mailFolderId,
      pathPrefix,
      handleOpenSettings,
      openSettings,
      clearSelectedMessages,
    } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(setActiveMailFolder(projectId, mailFolderId));
    }, [projectId, mailFolderId, dispatch]);

    const open = useSelector<RootReducerState, boolean>((state) =>
      getMailMenuOpen(state, projectId)
    );

    const setOpen = useCallback(
      (value: boolean) => {
        dispatch(setMailMenuNavigationState({ open: value }, projectId));
      },
      [projectId, dispatch]
    );

    const [, dropRef] = useDrop({
      accept: [DND_TYPE_EMAIL],
      drop: (item, monitor) => {
        if (!monitor.didDrop()) {
          if ((item as any).isPersonal) {
            return {
              destinationMailFolder: mailFolderId,
              message: (item as any).message as Message,
              projectId: projectId,
              showModal: true,
            };
          }
          return undefined;
        }
        return undefined;
      },
    });

    const menu = useMemo(
      () => (
        <MailMenu
          projectId={projectId}
          mailFolderId={mailFolderId}
          pathPrefix={pathPrefix}
          collapsed={!open}
          clearSelectedMessages={clearSelectedMessages}
        />
      ),
      [projectId, mailFolderId, pathPrefix, open, clearSelectedMessages]
    );

    return (
      <Layout.Sider
        ref={dropRef}
        trigger={
          <GlobalSiderTrigger
            open={open}
            setOpen={setOpen}
            additionalTrigger={{
              iconName: 'cog',
              type: 'default',
              text: t('mail:settingsForm.title'),
              onClick: () => handleOpenSettings(!openSettings),
            }}
            disableSiderCollapseOnAdditionalButtonClick
          />
        }
        width={theme.old.components.mailMenuMaxWidth}
        collapsedWidth={theme.old.components.sider.collapsedWidth}
        collapsible
        className={classNames(classes.root, 'prio-module-navigation', {
          [classes.rootSettingsOpen]: open,
        })}
        collapsed={!open}
      >
        {menu}
      </Layout.Sider>
    );
  });
export default MailNavigation;
