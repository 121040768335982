import { call } from 'redux-saga/effects';
import { WebSocketMessage } from '../../models/WebSocket';
import { handleContactChangedMessage } from '../../modules/contacts/ws';
import { handleCompanyChangedMessage } from '../../modules/companies/ws';

export function* handleContactCompanyChangedMessage(message: WebSocketMessage) {
  if (message.source === 'ContactCompanyChangedMessage') {
    if ((message.type as any)?.isContact) {
      yield call(handleContactChangedMessage, message.type as any);
    } else if ((message.type as any)?.isCompany) {
      yield call(handleCompanyChangedMessage, message.type as any);
    }
  }
}
