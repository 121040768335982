import React, { useState } from 'react';
import {} from 'antd';
import {
  Resizable,
  ResizeCallback,
  ResizeDirection,
  NumberSize,
} from 're-resizable';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import {
  maxMailNavigationHeight,
  maxMailNavigationWidth,
  minMailNavigationHeight,
  minMailNavigationWidth,
} from '../../userSettings/reducers/mailSettings/mail';

const minWidth = minMailNavigationWidth;
const maxWidth = maxMailNavigationWidth;
const minHeight = minMailNavigationHeight;
const maxHeight = maxMailNavigationHeight;

const useStyles = makePrioStyles((theme) => ({
  root: {},
  borderRight: {
    zIndex: 30,
    borderRight: theme.old.borders.boldMain,
  },
  borderBottom: {
    zIndex: 30,
    borderBottom: theme.old.borders.boldMain,
  },
}));

interface MailListResizableProps {
  size: {
    width?: string;
    height?: string;
  };
  onResizeStop: ResizeCallback;
  listLayout: 'vertical' | 'horizontal';
}

export const MailListResizable: React.FC<MailListResizableProps> = (props) => {
  //#region ------------------------------ Defaults
  const { size, listLayout, onResizeStop } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const adaptedSize =
    listLayout === 'horizontal'
      ? {
          height: '100%',
          width: parseInt(size.width ?? '320'),
        }
      : {
          width: '100%',
          height: parseInt(size.height ?? '320'),
        };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnResize: ResizeCallback = (
    event: MouseEvent | TouchEvent,
    direction: ResizeDirection,
    elementRef: HTMLElement,
    delta: NumberSize
  ) => {
    setIsResizing(false);
    onResizeStop(event, direction, elementRef, delta);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Resizable
      className={classNames(classes.root, {
        [classes.borderRight]: listLayout === 'horizontal' && isResizing,
        [classes.borderBottom]: listLayout === 'vertical' && isResizing,
      })}
      minWidth={listLayout === 'horizontal' ? minWidth : undefined}
      maxWidth={listLayout === 'horizontal' ? maxWidth : undefined}
      minHeight={listLayout === 'vertical' ? minHeight : undefined}
      maxHeight={listLayout === 'vertical' ? maxHeight : undefined}
      size={adaptedSize}
      enable={{
        top: false,
        right: listLayout === 'horizontal',
        left: false,
        bottom: listLayout === 'vertical',
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      style={{ position: 'absolute' }}
      onResizeStart={() => setIsResizing(true)}
      onResizeStop={handleOnResize}
    />
  );
};

export default MailListResizable;
