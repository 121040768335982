export enum InitialsColor {
  lightBlue = 0,
  blue = 1,
  darkBlue = 2,
  teal = 3,
  lightGreen = 4,
  green = 5,
  darkGreen = 6,
  lightPink = 7,
  pink = 8,
  magenta = 9,
  purple = 10,
  /**
   * Black can result in offensive persona coins with some initials combinations, so it can only be set with overrides.
   * @deprecated will be removed in a future major release.
   */
  black = 11,
  orange = 12,
  /**
   * Red often has a special meaning, so it is considered a reserved color and can only be set with overrides.
   * @deprecated will be removed in a future major release.
   */
  red = 13,
  darkRed = 14,
  /**
   * Transparent is not intended to be used with typical initials due to accessibility issues.
   * Its primary use is for overflow buttons, so it is considered a reserved color and can only be set with overrides.
   */
  transparent = 15,
  violet = 16,
  lightRed = 17,
  gold = 18,
  burgundy = 19,
  warmGray = 20,
  coolGray = 21,
  /**
   * Gray can result in offensive persona coins with some initials combinations, so it can only be set with overrides.
   */
  gray = 22,
  cyan = 23,
  rust = 24,
}

const COLOR_SWATCHES_LOOKUP = [
  InitialsColor.lightBlue,
  InitialsColor.blue,
  InitialsColor.darkBlue,
  InitialsColor.teal,
  InitialsColor.green,
  InitialsColor.darkGreen,
  InitialsColor.lightPink,
  InitialsColor.pink,
  InitialsColor.magenta,
  InitialsColor.purple,
  InitialsColor.orange,
  InitialsColor.lightRed,
  InitialsColor.darkRed,
  InitialsColor.violet,
  InitialsColor.gold,
  InitialsColor.burgundy,
  InitialsColor.warmGray,
  InitialsColor.cyan,
  InitialsColor.rust,
  InitialsColor.coolGray,
];
var COLOR_SWATCHES_NUM_ENTRIES = COLOR_SWATCHES_LOOKUP.length;
export function getInitialsColorFromName(displayName: string): InitialsColor {
  var color = InitialsColor.blue;
  if (!displayName) {
    return color;
  }
  var hashCode = 0;
  for (var iLen = displayName.length - 1; iLen >= 0; iLen--) {
    var ch = displayName.charCodeAt(iLen);
    var shift = iLen % 8;
    // tslint:disable-next-line:no-bitwise
    hashCode ^= (ch << shift) + (ch >> (8 - shift));
  }
  color = COLOR_SWATCHES_LOOKUP[hashCode % COLOR_SWATCHES_NUM_ENTRIES];
  return color;
}
function InitialsColorToHexCode(
  initialsColor: InitialsColor
): string | undefined {
  switch (initialsColor) {
    case InitialsColor.lightBlue:
      return '#4F6BED';
    case InitialsColor.blue:
      return '#0078D4';
    case InitialsColor.darkBlue:
      return '#004E8C';
    case InitialsColor.teal:
      return '#038387';
    case InitialsColor.lightGreen:
    case InitialsColor.green:
      return '#498205';
    case InitialsColor.darkGreen:
      return '#0B6A0B';
    case InitialsColor.lightPink:
      return '#C239B3';
    case InitialsColor.pink:
      return '#E3008C';
    case InitialsColor.magenta:
      return '#881798';
    case InitialsColor.purple:
      return '#5C2E91';
    case InitialsColor.orange:
      return '#CA5010';
    // tslint:disable-next-line:deprecation
    case InitialsColor.red:
      return '#EE1111';
    case InitialsColor.lightRed:
      return '#D13438';
    case InitialsColor.darkRed:
      return '#A4262C';
    case InitialsColor.transparent:
      return 'transparent';
    case InitialsColor.violet:
      return '#8764B8';
    case InitialsColor.gold:
      return '#986F0B';
    case InitialsColor.burgundy:
      return '#750B1C';
    case InitialsColor.warmGray:
      return '#7A7574';
    case InitialsColor.cyan:
      return '#005B70';
    case InitialsColor.rust:
      return '#8E562E';
    case InitialsColor.coolGray:
      return '#69797E';
    // tslint:disable-next-line:deprecation
    case InitialsColor.black:
      return '#1D1D1D';
    case InitialsColor.gray:
      return '#393939';
  }
  return undefined;
}
export const getInitialsColorHexFromName: (displayName: string) => string = (
  displayName: string
) => InitialsColorToHexCode(getInitialsColorFromName(displayName));
