import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import {
  getActiveMailFolderId,
  getMailFolder,
  getMailSettings,
  getMessagesIsFetching,
  getSpecialMailFolders,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { MailListSkeleton } from './MailListSkeleton';
import { WidgetMailList } from './MailWidget/WidgetMailList';
import { Typography } from 'antd';
import { Message } from '../../../models/Message';
import MoveMessageModal from './MoveMessageModal';
import { MailFolderId, MessageId, ProjectId } from '../../../models/Types';
import useMailSearch from '../hooks/useMailSearch';
import { MailFolder } from '../../../models/MailFolder';
import { createSelector } from 'reselect';
import { SpecialMailFolders } from '../actions/types';
import { deleteLocalMessageMe } from '../actions/me/messagesMe';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import {
  fetchMailFoldersSagaAction,
  fetchMessagesSagaAction,
} from '../actions/sagas';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  titleContainer: {
    padding: 4 + theme.old.components.mailListItem.spacing,
    borderBottom: theme.old.borders.content,
  },
  title: {
    '&.ant-typography': {
      marginBottom: 0,
    },
  },
  modalSelectTitle: {
    color: theme.old.typography.colors.muted,
    fontSize: 14,
    marginBottom: theme.old.spacing.unit(0.5),
  },
  modalSelect: {
    overflow: 'hidden',
  },
}));

const mailFolderSelector = (projectId: ProjectId, mailFolderId: MailFolderId) =>
  createSelector<
    [
      (state: RootReducerState) => MailFolder,
      (state: RootReducerState) => SpecialMailFolders,
    ],
    MailFolder
  >(
    (state) => getMailFolder(state, mailFolderId, projectId),
    (state) => getSpecialMailFolders(state, projectId),
    (mailFolder, specialMailFolders) => {
      if (!mailFolder && specialMailFolders && mailFolderId === 'inbox') {
        return specialMailFolders['inboxFolder'];
      }
      return mailFolder;
    }
  );

interface WidgetMailSelectionListProps {
  className?: string;
  projectId: string;
}

export const WidgetMailSelectionList: React.FC<WidgetMailSelectionListProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const { t } = useTranslation();
    const { className, projectId } = props;
    const mailFolderId = 'inbox';

    const settings = useSelector(getMailSettings);

    const classes = useStyles({
      ...props,
      lessSpacing:
        settings.mailListSpacing === 'full'
          ? 1
          : settings.mailListSpacing === 'middle'
          ? 2
          : 4,
    });

    const dispatch = useDispatch();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [items] = useMailSearch({
      projectId: 'me',
      mailFolderId: mailFolderId,
    });

    const isFetching = useSelector<RootReducerState, boolean>((state) =>
      getMessagesIsFetching(state, 'me', 'inbox')
    );

    const [droppedItem, setDroppedItem] = useState<{
      message: Message;
      selectedMessages: Message[];
      projectId: ProjectId;
    }>({ message: null, selectedMessages: [], projectId });

    const activeMailFolderId = useSelector<RootReducerState, string>((state) =>
      getActiveMailFolderId(state, projectId)
    );

    const mailFolder = useSelector(mailFolderSelector('me', mailFolderId));
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleEndDrop = (messageId: MessageId) => {
      dispatch(deleteLocalMessageMe(mailFolderId, messageId));
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      setTimeout(() => {
        dispatch(fetchMessagesSagaAction('me', mailFolderId, true, true));
        dispatch(fetchMailFoldersSagaAction('me', false, 'inbox'));
      }, 500);
    }, [dispatch]);
    //#endregion

    return (
      <Flex.Column className={classNames(classes.root, className)}>
        <Flex.Item className={classes.titleContainer}>
          <Typography.Title level={3} className={classes.title}>
            {t('mail:widgetArea.personalMessages.inbox')}
          </Typography.Title>
        </Flex.Item>
        {isFetching && items.length === 0 ? (
          <MailListSkeleton />
        ) : (
          <WidgetMailList
            items={items}
            projectId={projectId}
            moveToFolder={(message, selectedMessages, projectId) => {
              setDroppedItem({
                message,
                selectedMessages,
                projectId,
              });
            }}
            settings={settings}
            endDrop={handleEndDrop}
            mailFolder={mailFolder}
          />
        )}
        {droppedItem.message && (
          <MoveMessageModal
            movedMessage={droppedItem.message}
            selectedMessages={droppedItem.selectedMessages}
            projectId={'me'}
            defaultSelected={droppedItem.projectId}
            onOk={() => {
              setDroppedItem({
                message: null,
                selectedMessages: [],
                projectId: projectId,
              });
            }}
            onCancel={() => {
              setDroppedItem({
                message: null,
                selectedMessages: [],
                projectId: projectId,
              });
            }}
            mailFolderId={activeMailFolderId}
          />
        )}
      </Flex.Column>
    );
  });

export default WidgetMailSelectionList;
