import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { DateTimeString } from '../../../models/Types';
import {
  CLOSE_TIME_AND_LEAVE_MANAGEMENT_DRAWER,
  OPEN_TIME_AND_LEAVE_MANAGEMENT_DRAWER,
} from '../actions';
import { ReduxAction } from '../../../models/Redux';
import {
  CREATE_TIME_RECORDS_COMMIT,
  DELETE_TIME_RECORD,
  UPDATE_TIME_RECORD_COMMIT,
} from '../../timeRecords/actions';
import {
  CHANGE_ABSENCE_PROPOSAL_COMMIT,
  CREATE_ABSENCE_PROPOSAL_COMMIT,
  DELETE_ABSENCE_PROPOSAL_COMMIT,
} from '../../absences/actions';

export declare type TimeAndLeaveDrawerTab =
  | 'timeRecords'
  | 'timeKeeping'
  | 'absence';

export declare type CreatedObjectType = 'timeRecord' | 'absence' | 'none';

export interface TimeAndLeaveTimeRange {
  startDateTime: DateTimeString;
  endDateTime: DateTimeString;
}

export interface TimeAndLeaveDrawerState {
  isOpen: boolean;
  tab: TimeAndLeaveDrawerTab;
  selectedTimeRange: TimeAndLeaveTimeRange;
  createdObjectType: CreatedObjectType;
}

export const initialState: TimeAndLeaveDrawerState = {
  isOpen: false,
  tab: 'timeKeeping',
  selectedTimeRange: {
    startDateTime: null,
    endDateTime: null,
  },
  createdObjectType: 'none',
};

const reducer: Reducer<
  TimeAndLeaveDrawerState,
  ReduxAction<TimeAndLeaveDrawerState & CreatedObjectType>
> = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_TIME_AND_LEAVE_MANAGEMENT_DRAWER: {
      const { meta } = action as ReduxAction<TimeAndLeaveDrawerState>;
      return {
        ...state,
        ...meta,
        isOpen: true,
      };
    }
    case DELETE_ABSENCE_PROPOSAL_COMMIT:
    case CHANGE_ABSENCE_PROPOSAL_COMMIT:
    case CREATE_ABSENCE_PROPOSAL_COMMIT: {
      return {
        ...state,
        createdObjectType: 'absence',
      };
    }
    case DELETE_TIME_RECORD:
    case UPDATE_TIME_RECORD_COMMIT:
    case CREATE_TIME_RECORDS_COMMIT: {
      return {
        ...state,
        createdObjectType: 'timeRecord',
      };
    }
    case CLOSE_TIME_AND_LEAVE_MANAGEMENT_DRAWER: {
      return {
        ...state,
        isOpen: false,
        selectedId: null,
        selectedTimeRange: {
          startDateTime: null,
          endDateTime: null,
        },
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;

export const getDrawerState: (
  state: TimeAndLeaveDrawerState
) => TimeAndLeaveDrawerState = (state) => state;
