export const parseFalsyValuesToUndefined = (obj: any) => {
  const parsedObj = { ...obj };
  Object.keys(parsedObj).forEach((key) => {
    const value = parsedObj[key];
    if (value === null || value === undefined || value === '') {
      parsedObj[key] = undefined;
    }
  });
  return parsedObj;
};

export const removeFalsyValuesFromObject = (obj: any) => {
  const parsedObj = { ...obj };
  Object.keys(parsedObj).reduce((acc, key) => {
    const value = parsedObj[key];
    if (value === null || value === undefined || value === '') {
      delete parsedObj[key];
    }
    return parsedObj;
  }, {});
  return parsedObj;
};
