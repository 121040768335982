import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { Trans, useTranslation } from 'react-i18next';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import RemoteMenu from './RemoteMenu';
import DocumentsPage from './DocumentsPage';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import RemoteFolderNavigationBar from './RemoteFolderNavigationBar';
import NavigationBar from '../../../components/NavigationBar';
import { setCurrentRemoteItem } from '../actions/remoteFolders';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../theme/types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const queryClient = new QueryClient();

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
  },
  menu: {
    maxWidth: theme.old.components.documentsTable.documentsMenu.width,
  },
  page: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    '& .ant-upload': {
      width: '100%',
      height: '100%',
    },
  },
  content: {
    overflow: 'hidden',
    height: '100%',
  },
  table: {
    overflow: 'auto hidden',
  },
  newDocument: {
    width: '100%',
  },
}));

interface RemoteFoldersSubModuleProps {}

export const RemoteFoldersSubModule: React.FC<RemoteFoldersSubModuleProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    return () => {
      dispatch(setCurrentRemoteItem(null));
    };
  }, [dispatch]);
  //#endregion

  return (
    <QueryClientProvider client={queryClient}>
      <Flex.Column
        className={classes.root}
        id={'prio-project-documents-submodule'}
      >
        <NavigationBar>
          <Routes>
            <Route path=":projectId/*">
              <Route
                path="new"
                element={
                  <Button iconProp={['fal', 'file']} disabled>
                    <span>{t('documents:navigationBar.createDocument')}</span>
                  </Button>
                }
              />
              <Route
                path="all"
                element={<RemoteFolderNavigationBar pathPrefix="../../" />}
              />
              <Route
                path="folder/:driveItemId/*"
                element={<RemoteFolderNavigationBar pathPrefix="../../" />}
              />
            </Route>
          </Routes>
        </NavigationBar>
        <Flex.Row flex={1} className={classes.content}>
          <Routes>
            <Route path="*" element={<RemoteMenu className={classes.menu} />} />
            <Route path=":projectId/*">
              <Route
                path="all"
                element={
                  <RemoteMenu
                    className={classes.menu}
                    pathPrefix="../../"
                    isRoot
                  />
                }
              />
              <Route
                path="folder/:driveItemId/*"
                element={
                  <RemoteMenu className={classes.menu} pathPrefix="../../" />
                }
              />
            </Route>
          </Routes>
          <Flex.Item flex={1} className={classes.table}>
            <Routes>
              <Route path=":projectId/*">
                <Route
                  path="all"
                  element={<DocumentsPageWrapper pathPrefix="../" />}
                />
                <Route
                  path="folder/:driveItemId/*"
                  element={<DocumentsPageWrapper pathPrefix="../" />}
                />
              </Route>
              <Route
                element={
                  <div className="prio-flex-center-center prio-flex-column">
                    <Typography.Text>
                      <Trans i18nKey={'documents:noMenuItemSelected'} />
                    </Typography.Text>
                  </div>
                }
              />
            </Routes>
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>
    </QueryClientProvider>
  );
};

export default RemoteFoldersSubModule;

interface DocumentsPageWrapperProps {
  pathPrefix: string;
}

const DocumentsPageWrapper: React.FC<DocumentsPageWrapperProps> = (props) => {
  //#region ------------------------------ Defaults
  const { pathPrefix } = props;
  const classes = useStyles();
  const { projectId } = useParams();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const groupId = useSelector<RootReducerState, string>(
    (state) => getProject(state, projectId)?.groupId
  );
  //#endregion

  return (
    <DocumentsPage
      className={classes.page}
      projectId={projectId}
      groupId={groupId}
      pathPrefix={pathPrefix}
    />
  );
};
