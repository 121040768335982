import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import { Row } from 'react-table';
import { IUseTableCellProps } from './VirtualTable2';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    minWidth: 50,
  },
  cellContent: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  generalCellContent: {
    padding: 10,
  },
}));

interface VirtualTableCellProps<DataType extends object> {
  className?: string;
  tableId: React.Key;
  row: Row<DataType>;
  cell: IUseTableCellProps<DataType>;
  width: number;
}

export const VirtualTableCell = <DataType extends object>(
  props: VirtualTableCellProps<DataType>
) => {
  //#region ------------------------------ Defaults
  const { className, tableId, row, cell, width } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const content = useMemo(() => {
    if (cell?.render) {
      return cell.render('Cell');
    }
    return null;
  }, [cell]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      id={`${tableId}-${cell.column.id}-${row.id}`}
      key={`${tableId}-${cell.column.id}-${row.id}`}
      className={classNames(classes.root, `${tableId}-${cell.column.id}`)}
      style={{
        width: `${width}%`,
        minWidth: cell.column.minWidth > 0 ? cell.column.minWidth : 50,
        flexShrink:
          row.allCells.findIndex((c) => c?.column?.id === cell?.column?.id) <
          row.allCells.length - 1
            ? 0
            : undefined,
      }}
      title={cell?.column?.cellTitle?.(row?.original)}
    >
      <div
        style={cell.column.innerStyle}
        className={classNames(
          classes.cellContent,
          {
            [classes.generalCellContent]: cell.column.alignSelf,
          },
          className
        )}
      >
        {content}
      </div>
    </div>
  );
};

export default VirtualTableCell;
