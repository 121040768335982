import React from 'react';
import { List } from 'antd';

import { makePrioStyles } from '../../../theme/utils';
import {
  Contact,
  ExternalContact,
  InternalContact,
} from '../../../models/Contact';
import { useDispatch } from 'react-redux';
import Flex from '../../../components/Flex';
import { ContactListItem } from '../../contacts/components/ContactList';
//import { setDraftMessageProjectId, setMailMetaNewMessageOpen, } from '../../mail/actions/meta';
import { openContactsDrawer } from '../../contacts/actions/drawer';
import { ContactId } from '../../../models/Types';

const listItemHeight = 64;
const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  list: {
    overflowY: 'scroll',
  },
  listItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingTop: theme.old.spacing.unit(2),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      cursor: 'pointer',
    },
    '& .ant-list-vertical .ant-list-item-meta': {
      marginBottom: 0,
    },
    height: listItemHeight,
    overflow: 'hidden',
  },
  listItemMeta: {
    marginTop: theme.old.spacing.unit(2),
    '& .ant-list-item-meta-title': {
      marginBottom: 0,
      fontSize: theme.old.typography.fontSize.base,
    },
    '& .ant-list-item-meta': {
      marginBottom: 0,
    },
    '& .ant-list-item-meta-content': {
      marginBottom: 0,
    },
  },
  icons: {
    color: theme.old.typography.colors.muted,
    height: '100%',
  },
  row: {
    height: '100%',
  },
}));

interface ContactListProps {
  items: ContactListItem[];
  isDrawer?: boolean;
  onItemClick?: (contactId: ContactId) => void;
}

export const ContactList: React.FC<ContactListProps> = (props) => {
  const { items, isDrawer, onItemClick } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleOnListItemClick = (item: InternalContact & ExternalContact) => {
    if (isDrawer && onItemClick) {
      onItemClick(item.contactId);
    } else {
      dispatch(
        openContactsDrawer({
          selectedContact: item.contactId,
          view: 'contactDetail',
        })
      );
    }
  };

  return (
    <Flex.Column className={classes.root}>
      <List
        className={classes.list}
        itemLayout="vertical"
        size="large"
        dataSource={items.sort((a, b) => {
          const aValue = a.value as Contact;
          const bValue = b.value as Contact;
          const lastNameCompare = aValue.lastName?.localeCompare(
            bValue.lastName
          );
          if (lastNameCompare !== 0) return lastNameCompare;
          return aValue.firstName?.localeCompare(bValue.firstName);
        })}
        renderItem={(item) => {
          const c: InternalContact & ExternalContact =
            item.value as InternalContact & ExternalContact;
          return (
            <List.Item
              key={c.contactId}
              className={classes.listItem}
              onClick={() =>
                handleOnListItemClick(
                  item.value as InternalContact & ExternalContact
                )
              }
            >
              <Flex.Row className={classes.row} alignItems={'center'}>
                <List.Item.Meta
                  className={classes.listItemMeta}
                  title={`${c.lastName}, ${c.firstName}`}
                  description={`${c.title ? `${c.title}, ` : ''}
                  ${c.eMail ? `${c.eMail}, ` : ''}
                  ${c.phone ? `${c.phone}, ` : ''}
                  ${c.cellphone ? `${c.cellphone}, ` : ''}
                  `}
                />
              </Flex.Row>
            </List.Item>
          );
        }}
      ></List>
    </Flex.Column>
  );
};

export default ContactList;
