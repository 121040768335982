import { apiUrl } from '../../../../api';
import { PRIO } from '../../../../constants';
import { Message } from '../../../../models/Message';
import { DispatchAction, ReduxAction } from '../../../../models/Redux';
import { MessageId } from '../../../../models/Types';

export const SET_ACTIVE_DRAFT_MESSAGE_ID_ME =
  PRIO + 'SET_ACTIVE_DRAFT_MESSAGE_ID_ME';

export const setActiveDraftMessageIdMe: (
  messageId: MessageId
) => ReduxAction = (messageId) => ({
  type: SET_ACTIVE_DRAFT_MESSAGE_ID_ME,
  messageId,
});

export const CREATE_NEW_MESSAGE_DRAFT_ME_REQUEST =
  PRIO + 'CREATE_NEW_MESSAGE_DRAFT_ME_REQUEST';
export const CREATE_NEW_MESSAGE_DRAFT_ME_COMMIT =
  PRIO + 'CREATE_NEW_MESSAGE_DRAFT_ME_COMMIT';
export const CREATE_NEW_MESSAGE_DRAFT_ME_ROLLBACK =
  PRIO + 'CREATE_NEW_MESSAGE_DRAFT_ME_ROLLBACK';

export const createNewDraftMessageMe: () => DispatchAction<
  unknown,
  Message
> = () => ({
  type: CREATE_NEW_MESSAGE_DRAFT_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: {
          body: {
            contentType: 1,
            content: '<p></p><p></p><p></p>',
          },
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_NEW_MESSAGE_DRAFT_ME_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_NEW_MESSAGE_DRAFT_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.createDraftError',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_REQUEST =
  PRIO + 'CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_REQUEST';
export const CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_COMMIT =
  PRIO + 'CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_COMMIT';
export const CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_ROLLBACK =
  PRIO + 'CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_ROLLBACK';

export const createMessageReplyAllForwardMe: (
  messageId: string,
  type: 'Reply' | 'ReplyAll' | 'Forward'
) => DispatchAction<unknown, Message> = (messageId, type) => ({
  type: CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message/${messageId}/create${type}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: {
          body: {
            contentType: 1,
            content: '<p></p><p></p><p></p>',
          },
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_MESSAGE_REPLY_ALL_FORWARD_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.createDraftError',
          timeout: 6,
        },
      },
    },
  },
});

export const REMOVE_DRAFT_MESSAGE_ME = PRIO + 'REMOVE_DRAFT_MESSAGE_ME';

export const removeDraftMessageMe: (messageId: MessageId) => ReduxAction = (
  messageId
) => ({
  type: REMOVE_DRAFT_MESSAGE_ME,
  messageId,
});

export const REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_ME =
  PRIO + 'REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_ME';

export const removeDraftMessageWithoutPipelineStopMe: (
  messageId: MessageId
) => ReduxAction = (messageId) => ({
  type: REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_ME,
  messageId,
});

export const ADD_DRAFT_MESSAGE_ME = PRIO + 'ADD_DRAFT_MESSAGE_ME';

export const addDraftMessageMe: (
  message: Message,
  isInitial?: boolean
) => ReduxAction = (message, isInitial) => ({
  type: ADD_DRAFT_MESSAGE_ME,
  message,
  isInitial,
});

export const ADD_SENDING_MESSAGE_ID_ME = PRIO + 'ADD_SENDING_MESSAGE_ID_ME';

export const addSendingMessageIdMe: (messageId: MessageId) => ReduxAction = (
  messageId
) => ({
  type: ADD_SENDING_MESSAGE_ID_ME,
  messageId,
});

export const REMOVE_SENDING_MESSAGE_ID_ME =
  PRIO + 'REMOVE_SENDING_MESSAGE_ID_ME';

export const removeSendingMessageIdMe: (messageId: MessageId) => ReduxAction = (
  messageId
) => ({
  type: REMOVE_SENDING_MESSAGE_ID_ME,
  messageId,
});
