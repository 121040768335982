import { useSelector } from 'react-redux';
import { getContactsByIdState } from '../../../apps/main/rootReducer';
import { exportToCsv } from '../../../util/export';
import { ContactsByIdState } from '../../contacts/reducers/contacts';
import { AbsenceProposal } from '../../../models/AbsenceProposal';

export const exportAbsenceProposalsToCsv: (
  absenceProposals: AbsenceProposal[],
  contactsById: ContactsByIdState
) => void = (absenceProposals, contactsById) => {
  const dataRows: string[][] = absenceProposals.map((absenceProposal) => {
    if (absenceProposal) {
      const applicant = absenceProposal.applicantId
        ? contactsById[absenceProposal.applicantId]
        : null;
      const principal = absenceProposal.principalId
        ? contactsById[absenceProposal.principalId]
        : null;
      const substitute = absenceProposal.substituteId
        ? contactsById[absenceProposal.substituteId]
        : null;
      return [
        applicant?.firstName,
        applicant?.lastName,
        principal?.firstName,
        principal?.lastName,
        substitute?.firstName,
        substitute?.lastName,
        absenceProposal.absenceType,
        absenceProposal.reason,
        absenceProposal.from,
        absenceProposal.to,
        absenceProposal.absentWorkdays.toFixed(2),
        absenceProposal.firstIsHalfDay ? 'x' : '',
        absenceProposal.lastIsHalfDay ? 'x' : '',
        absenceProposal.note,
      ];
    }
    return [];
  });

  const headers = [
    'applicantFirstName',
    'applicantLastName',
    'principalFirstName',
    'principalLastName',
    'substituteFirstName',
    'substituteLastName',
    'absenceType',
    'reason',
    'from',
    'to',
    'absentWorkdays',
    'firstIsHalfDay',
    'lastIsHalfDay',
    'note',
  ];
  exportToCsv(headers, dataRows, 'AbsenceProposals.csv');
};

export function useExportAbsenceProposalsToCsv(): (
  absenceProposals: AbsenceProposal[]
) => void {
  const contactsById = useSelector(getContactsByIdState);

  return (absenceProposals: AbsenceProposal[]) =>
    exportAbsenceProposalsToCsv(absenceProposals, contactsById);
}
