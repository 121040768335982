import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, notification, Input, Alert, Modal } from 'antd';

import {
  getActiveMessageId,
  getMailFoldersIsFetching,
  getMailMenuOpen,
  getMailSettings,
  getSpecialMailFolders,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import Flex from '../../../../components/Flex';
import { MailFolder } from '../../../../models/MailFolder';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import { Message } from '../../../../models/Message';
import { fetchMailFolders } from '../../actions/actionControllers/mailFoldersController';
import { folderNameRegex } from '../../../documents/util';
import { apiCreateMailFolder, apiDeleteMailFolder } from '../../api';
import MailMenuSkeleton from '../MailMenuSkeleton';
import { createSelector } from 'reselect';
import {
  MailFolderByMailFolderId,
  MailFolderIdEntry,
  SpecialMailFolders,
} from '../../actions/types';
import { makePrioStyles } from '../../../../theme/utils';
import {
  fetchMailFoldersSagaAction,
  fetchMessagesSagaAction,
} from '../../actions/sagas';
import { PrioTheme } from '../../../../theme/types';
import MailFolderMenu from './MailFolderMenu';
import {
  deleteMessagesOfMailbox,
  fetchMessage,
} from '../../actions/actionControllers/messageActionController';
import { MailSettings } from '../../../../models/UserSettings/MailSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  titleContainer: {
    overflow: 'hidden',
    textOverflow: ' ellipsis',
    width: '100%',
    maxHeight: 65,
    marginBottom: 11,
    transition: 'all 0.2s',
    borderColor: theme.old.borders.colors.content,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    '& button, button:hover, button:active': {
      marginRight: theme.old.spacing.unit(1.5),
    },
  },
  collapsedTitleContainer: {
    margin: `0 ${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
    height: 0,
  },
  titleContainerCompressedView: {
    marginBottom: 0,
  },

  title: {
    flex: 1,
    lineHeight: '44px!important',
    padding: `${theme.old.components.mailFolderItem.spacing * 2}px ${
      theme.old.components.mailFolderItem.spacing * 3
    }px`,
    margin: '0!important',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all 0.2s',
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  marginTop: {
    marginTop: theme.old.components.mailFolderItem.spacing,
  },
  refetchButton: {
    cursor: 'pointer',
    padding: `0 ${theme.old.components.mailFolderItem.spacing * 3}px`,
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
}));

interface MailMenuProps {
  projectId: string;
  mailFolderId: string;
  pathPrefix?: string;
  collapsed?: boolean;
  clearSelectedMessages?: (messageIds: Message[]) => void;
}

const getSpecialSorting = (
  mailFolderId: MailFolderId,
  specialMailFolders: SpecialMailFolders
) => {
  if (!specialMailFolders) return 0;
  if (!mailFolderId) return 0;
  switch (mailFolderId) {
    case specialMailFolders['inboxFolder']?.id:
      return 100;
    case specialMailFolders['sendFolder']?.id:
      return 99;
    case specialMailFolders['draftFolder']?.id:
      return 98;
    case specialMailFolders['deletedFolder']?.id:
      return 97;
    default:
      return 0;
  }
};

const mailFoldersSelector = (
  projectId: ProjectId,
  specialMailFolders: SpecialMailFolders
) =>
  createSelector<
    [
      (state: RootReducerState) => MailFolderIdEntry[],
      (state: RootReducerState) => MailFolderByMailFolderId,
    ],
    MailFolder[]
  >(
    (state) =>
      projectId === 'me'
        ? state.mail.me.mailFolders.ids
        : state.mail.projects.mailFolders.ids[projectId] ?? [],
    (state) =>
      projectId === 'me'
        ? state.mail.me.mailFolders.byId
        : state.mail.projects.mailFolders.byId,
    (ids, byId) =>
      expand(ids, byId)
        .filter((mF) => !!mF)
        .sort((a, b) => {
          const aValue = getSpecialSorting(a.id, specialMailFolders);
          const bValue = getSpecialSorting(b.id, specialMailFolders);
          return bValue - aValue;
        })
  );

const expand = (ids: MailFolderIdEntry[], byId: MailFolderByMailFolderId) => {
  return ids.map(({ id, childFolder }) => ({
    ...byId[id],
    ...(childFolder ? { childFolder: expand(childFolder, byId) } : {}),
  }));
};

const mailFolderIdSelector = (
  projectId: ProjectId,
  mailFolderId: MailFolderId
) =>
  createSelector<
    [(state: RootReducerState) => SpecialMailFolders],
    MailFolderId
  >(
    (state) => getSpecialMailFolders(state, projectId),
    (specialMailFolders) => {
      if (specialMailFolders && mailFolderId === 'inbox') {
        return specialMailFolders['inboxFolder']?.id;
      }
      return mailFolderId;
    }
  );

export const MailMenu: React.FC<MailMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    mailFolderId,
    projectId,
    pathPrefix,
    collapsed,
    clearSelectedMessages,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const specialMailFolders = useSelector<RootReducerState, SpecialMailFolders>(
    (state) => getSpecialMailFolders(state, projectId)
  );

  const mailFolders = useSelector(
    mailFoldersSelector(projectId, specialMailFolders)
  ); // Remove or fill with selector

  const isFetching = useSelector<RootReducerState, boolean>((state) =>
    getMailFoldersIsFetching(state, projectId)
  );

  const [newFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false);
  const [newFolderParentFolderId, setNewFolderParentFolderId] =
    useState<string>();
  const [newFolderName, setNewFolderName] = useState<string>('');
  const [newFolderNameValid, setNewFolderNameValid] = useState<boolean>(true);
  const [newFolderCreating, setNewFolderCreating] = useState<boolean>(false);

  const selectedMailFolderId = useSelector(
    mailFolderIdSelector(projectId, mailFolderId)
  );

  const mailSettings = useSelector(getMailSettings);

  const open = useSelector<RootReducerState, boolean>((state) =>
    getMailMenuOpen(state, projectId)
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const closeNewFolderModal = () => {
    setNewFolderModalOpen(false);
    setNewFolderName('');
    setNewFolderParentFolderId(null);
  };

  const onNewFolderOk = async () => {
    const displayName = newFolderName.trim();
    if (displayName === '') {
      closeNewFolderModal();
      return;
    }
    setNewFolderCreating(true);
    const { result } = await apiCreateMailFolder(
      displayName,
      projectId,
      newFolderParentFolderId
    );

    setNewFolderCreating(false);
    if (result.status >= 200 && result.status < 300) {
      dispatch(fetchMailFolders(projectId));
      closeNewFolderModal();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.mailFolders.createFolderError'),
      });
    }
  };
  const onNewFolderCancel = () => {
    closeNewFolderModal();
  };
  const onNewFolderInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFolderName(e.target.value);
    setNewFolderNameValid(!!e.target.value.match(folderNameRegex));
  };

  const showNewFolderModal = (parentFolderId: MailFolderId) => {
    setNewFolderParentFolderId(parentFolderId);
    setNewFolderModalOpen(true);
  };

  const onDelete = (mailFolderId: MailFolderId, mailFolderName: string) => {
    Modal.confirm({
      icon: null,
      title: t('mail:modals.deleteFolder.title'),
      content: t('mail:modals.deleteFolder.confirmation', {
        mailFolderName,
      }),
      okText: t('mail:modals.deleteFolder.okText'),
      cancelText: t('mail:modals.deleteFolder.cancelText'),
      onOk() {
        const promise = new Promise<void>(async (resolve) => {
          const { result } = await apiDeleteMailFolder(projectId, mailFolderId);
          if (result.status >= 200 && result.status < 300) {
            dispatch(fetchMailFolders(projectId));
          } else {
            notification.open({
              message: t('common:error'),
              description: t('mail:errorMessages.mailFolders.deleteError'),
            });
          }

          resolve();
        });
        return promise;
      },
      onCancel() {},
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      <Flex.Column flex={1} className={classes.root}>
        <div
          className={classNames(classes.titleContainer, {
            [classes.collapsedTitleContainer]: collapsed,
            [classes.titleContainerCompressedView]:
              mailSettings.mailListSpacing === 'tight' && open,
          })}
        >
          <Typography.Title className={classes.title}>
            {t('mail:mailNav.title')}
          </Typography.Title>
          <RefetchButton
            projectId={projectId}
            mailFolderId={mailFolderId}
            mailSettings={mailSettings}
          />
        </div>
        {mailFolders.length === 0 && isFetching ? (
          <MailMenuSkeleton />
        ) : (
          <MailFolderMenu
            selectedMailFolderId={selectedMailFolderId}
            mailFolders={mailFolders}
            projectId={projectId}
            showNewFolderModal={showNewFolderModal}
            onDelete={onDelete}
            pathPrefix={pathPrefix}
            specialMailFolders={specialMailFolders}
            collapsed={collapsed}
            clearSelectedMessages={clearSelectedMessages}
          />
        )}
      </Flex.Column>
      <Modal
        title={t('mail:modals.newFolder.title')}
        visible={newFolderModalOpen}
        onOk={onNewFolderOk}
        onCancel={onNewFolderCancel}
        confirmLoading={newFolderCreating}
        okButtonProps={{
          disabled: !newFolderNameValid,
        }}
        okText={t('mail:modals.newFolder.okText')}
        cancelText={t('mail:modals.newFolder.cancelText')}
      >
        <div>
          <Input
            value={newFolderName}
            onChange={onNewFolderInputChange}
            className={classes.marginTop}
          />
          <Alert
            message={t('documents:confirmation.rename.invalidFileName')}
            type="error"
            style={{ visibility: newFolderNameValid ? 'hidden' : 'visible' }}
            className={classes.marginTop}
          />
        </div>
      </Modal>
    </>
  );
};

export default MailMenu;

interface RefetchButtonProps {
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  mailSettings: MailSettings;
}

const RefetchButton: React.FC<RefetchButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId, mailFolderId } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeMessageId = useSelector<RootReducerState, MessageId>((state) =>
    getActiveMessageId(state, projectId)
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleMailFolderRefetch = () => {
    dispatch(deleteMessagesOfMailbox(projectId));
    dispatch(fetchMailFoldersSagaAction(projectId, true));
    dispatch(fetchMessagesSagaAction(projectId, mailFolderId, true));
    if (activeMessageId) {
      dispatch(fetchMessage(projectId, activeMessageId));
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classes.refetchButton}
      onClick={() => handleMailFolderRefetch()}
    >
      <FontAwesomeIcon icon={['fal', 'sync']} />
    </div>
  );
};
