import React from 'react';
import classNames from 'classnames';
import { Divider } from 'antd';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import Flex from '../../../components/Flex';
import { PrioTheme } from '../../../theme/types';
import { PrioSpinner } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 310,
    minHeight: 195,
    maxHeight: 320,
    border: `1px solid ${theme.colors.application.border}`,
    borderRadius: theme.borderRadius.regular,
    padding: theme.spacing.small,
  },
  title: {
    fontSize: theme.font.fontSize.small,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.default,
  },
  itemLabel: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
  },
  itemValue: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
    marginTop: '0px!important',
    marginBottom: '2px',
  },
  marginTop: {
    marginTop: `${theme.spacing.regular}px!important`,
  },
  prioSpinnerWrapper: {
    marginTop: `${theme.spacing.large}px!important`,
  },
  customDivider: {
    width: '20%',
    height: '1px',
    backgroundColor: theme.colors.application.border,
    marginTop: `${theme.spacing.regular}px!important`,
  },
}));

export type AnnualSummaryItemUnit = 'day' | 'days' | 'hour';
export interface AnnualSummarySingleItemProps {
  label: string | React.ReactNode;
  value: number;
  unit: AnnualSummaryItemUnit;
}

interface AnnualSummaryItemProps {
  className?: string;
  title: string;
  items?: AnnualSummarySingleItemProps[];
  totalItems?: AnnualSummarySingleItemProps[];
  isDataLoading?: boolean;
}

export const AnnualSummaryItem: React.FC<AnnualSummaryItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, title, items, totalItems, isDataLoading } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const itemsLength = items?.length;

  const itemsCol1 = items?.slice(0, Math.ceil(itemsLength / 2));
  const itemsCol2 = items?.slice(Math.ceil(itemsLength / 2), itemsLength);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Components
  const renderItem = (label: string | React.ReactNode, value: string) => {
    return (
      <>
        <div className={classes.itemLabel}>{label}</div>
        <div className={classes.itemValue}>{value}</div>
      </>
    );
  };
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column childrenGap={theme.spacing.extraSmall}>
        <div className={classes.title}>{title}</div>
        <Divider style={{ marginBottom: 0 }} />
        {isDataLoading ? (
          <Flex.Row
            flex={1}
            justifyContent="center"
            className={classes.prioSpinnerWrapper}
          >
            <PrioSpinner />
          </Flex.Row>
        ) : (
          <>
            <Flex.Row childrenGap={theme.spacing.extraSmall}>
              <Flex.Column childrenGap={theme.spacing.extraSmall} flex={1}>
                {itemsCol1?.map((item) =>
                  renderItem(
                    item.label,
                    `${item.value.toString().replace('.', ',')} ${t(
                      `timeAndLeaveManagement:annualSummary.unit.${item.unit}`
                    )}`
                  )
                )}
              </Flex.Column>
              <Flex.Column childrenGap={theme.spacing.extraSmall} flex={1}>
                {itemsCol2?.map((item) =>
                  renderItem(
                    item.label,
                    `${item.value.toString().replace('.', ',')} ${t(
                      `timeAndLeaveManagement:annualSummary.unit.${item.unit}`
                    )}`
                  )
                )}
              </Flex.Column>
            </Flex.Row>
            {totalItems && <div className={classes.customDivider} />}
            {totalItems && (
              <Flex.Row
                childrenGap={theme.spacing.extraSmall}
                className={classes.marginTop}
              >
                {totalItems?.map((item) => (
                  <Flex.Item flex={1}>
                    {renderItem(
                      item.label,
                      `${item.value.toString().replace('.', ',')} ${t(
                        `timeAndLeaveManagement:annualSummary.unit.${item.unit}`
                      )}`
                    )}
                  </Flex.Item>
                ))}
              </Flex.Row>
            )}
          </>
        )}
      </Flex.Column>
    </div>
  );
};

export default AnnualSummaryItem;
