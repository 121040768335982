import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Popover, Progress, Tooltip, Typography } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { UploadFileState } from '../../reducers/uploadLists';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { colorForMimeType, iconForMimeType } from '../../util';
import {
  DefaultDateTimeFormatString,
  formatHumanFileSize,
} from '../../../../util';
import i18n from '../../../../i18n';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    overflow: 'hidden',
    padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(1)}px`,
    background: theme.old.palette.backgroundPalette.content,
    transition: 'background 0.2s ease-in-out',
    '&:hover': {
      background: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  progressBar: {
    '& .ant-progress-text': {
      height: '100%',
    },
    '&.ant-progress-circle .ant-progress-text': {
      fontSize: 15,
    },
  },
  iconContainer: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
  },
  linkButton: {
    cursor: 'pointer',
    '& > svg:nth-child(1)': {
      visibility: 'visible',
    },
    '& > svg:nth-child(2)': {
      visibility: 'hidden',
    },
    '&:hover > svg:nth-child(1)': {
      visibility: 'hidden',
    },
    '&:hover > svg:nth-child(2)': {
      visibility: 'visible',
    },
  },
  popover: {
    '& .ant-popover-title': {
      fontSize: theme.old.typography.fontSize.tiny,
      padding: theme.old.spacing.baseSpacing,
    },
    '& .ant-popover-inner-content': {
      fontSize: theme.old.typography.fontSize.tiny,
      padding: theme.old.spacing.baseSpacing,
    },
  },
}));

interface UploadListItemProps {
  className?: string;
  item: UploadFileState;
  onClickParentItem?: (parentDriveItemId: string) => void;
}

export const UploadListItem: React.FC<UploadListItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, item, onClickParentItem } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    name,
    parentDriveItemId,
    uploadedSize,
    errorString,
    mimeType,
    isFolder,
    lastTimeModified,
    size,
    status,
    path,
  } = item;
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const progress =
    uploadedSize === size
      ? 100
      : Math.floor(((uploadedSize ?? 1) / (size ?? 1)) * 100);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Row alignItems="center" childrenGap={theme.old.spacing.baseSpacing}>
        {!isFolder && status === 'pending' && (
          <Progress
            className={classes.progressBar}
            percent={progress}
            type="circle"
            width={40}
            format={() => (
              <div className={classNames(classes.iconContainer)}>
                <FontAwesomeIcon
                  icon={[
                    'fas',
                    isFolder ? 'folder' : iconForMimeType(mimeType),
                  ]}
                  color={
                    isFolder
                      ? theme.old.palette.chromaticPalette.yellow
                      : colorForMimeType(mimeType)
                  }
                />
              </div>
            )}
          />
        )}
        {status === 'error' && (
          <Tooltip
            title={
              errorString ?? t('common:graphError.commonCode.generalException')
            }
          >
            <div className={classes.iconContainer}>
              <FontAwesomeIcon
                icon={['fal', 'times']}
                color={theme.old.palette.chromaticPalette.red}
              />
            </div>
          </Tooltip>
        )}
        {((isFolder && status === 'pending') || status === 'done') && (
          <Popover
            overlayClassName={classes.popover}
            title={name}
            content={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  {t('documents:table.popover.lastModifiedDateTime') +
                    ': ' +
                    DefaultDateTimeFormatString(
                      lastTimeModified,
                      i18n.language
                    )}
                </div>
                <div>{t('documents:table.popover.path') + ': ../' + path}</div>
                {!isFolder && (
                  <div>
                    {t('documents:table.popover.size') +
                      ': ' +
                      formatHumanFileSize(size)}
                  </div>
                )}
              </div>
            }
            trigger="hover"
          >
            <div
              className={classNames(
                classes.iconContainer,
                classes.linkButton,
                'fa-layers'
              )}
            >
              <FontAwesomeIcon
                icon={['fas', isFolder ? 'folder' : iconForMimeType(mimeType)]}
                color={
                  isFolder
                    ? theme.old.palette.chromaticPalette.yellow
                    : colorForMimeType(mimeType)
                }
              />
              <FontAwesomeIcon
                icon={['fal', 'arrow-right-to-bracket']}
                color={'rgba(0, 0, 0, 0.6)'}
                onClick={() => onClickParentItem?.(parentDriveItemId)}
              />
            </div>
          </Popover>
        )}
        <Flex.Item flex={1} overflow="hidden">
          <Typography.Text title={name} ellipsis>
            {name}
          </Typography.Text>
        </Flex.Item>
        {!isFolder && status === 'pending' && (
          <Flex.Row justifyContent="flex-end" marginLeft={8}>
            <Typography.Text type="secondary">
              {`${formatHumanFileSize(uploadedSize)} / ${formatHumanFileSize(
                size
              )}`}
            </Typography.Text>
          </Flex.Row>
        )}
      </Flex.Row>
    </div>
  );
};

export default UploadListItem;
