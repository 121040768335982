import React, { useMemo } from 'react';
import { makePrioStyles } from '../theme/utils';
import { Divider, Tooltip, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import useDebounce from '../hooks/useDebounce';
import { ButtonType } from 'antd/lib/button';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import Flex from './Flex';
import { useDispatch } from 'react-redux';
import { setSiderSetting } from '../modules/userSettings/actions/themeSettings/sider';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';

const useStylesTrigger = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  trigger: {
    height: theme.old.spacing.unit(8),
    width: theme.old.spacing.unit(8) - 1,
    alignSelf: 'flex-end',
    fontSize: theme.old.typography.fontSize.base,
    borderRadius: 0,
    '& > .svg-inline--fa': {
      color: `${theme.old.palette.primaryColor}!important`,
    },
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.main,
    },
  },
  triggerClosed: {
    width: '100%',
    height: theme.old.spacing.unit(6),
    borderRadius: 2,
  },
  triggerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 1px)',
    padding: 0,
  },
  triggerContainerCollapsed: {
    flexDirection: 'column',
    padding: theme.old.spacing.unit(1.5),
  },
  buttonLink: {
    width: `calc(100% - ${theme.old.spacing.unit(8) - 1}px)`,
  },
  buttonLinkCollapsed: {
    width: `calc(100% - ${theme.old.spacing.unit(8) - 1}px)`,
  },
  button: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'background-color 0.2s',
    padding: `0 ${theme.old.spacing.unit(3)}px`,
    fontSize: theme.old.typography.fontSize.base,
    height: theme.old.spacing.unit(8),
    width: 'calc(100% - 1px)',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: '#000',
    justifyContent: 'center',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.main,
    },
    '& > svg': {
      color: theme.old.typography.colors.muted,
    },
  },
  buttonCollapsed: {
    flex: 'unset',
    height: theme.old.spacing.unit(5),
  },
  buttonText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    paddingLeft: theme.old.spacing.unit(1.5),
  },
  dividerTrigger: {
    margin: `0 ${theme.old.spacing.unit(1.5)}px`,
    width: `calc(100% - ${theme.old.spacing.unit(3)}px)`,
    minWidth: `calc(100% - ${theme.old.spacing.unit(3)}px)`,
    transition: 'all 0.2s',
    borderColor: theme.old.borders.colors.main,
  },
  dividerTriggerOpen: {
    margin: `0 ${theme.old.spacing.unit(3)}px`,
    width: `calc(100% - ${theme.old.spacing.unit(6)}px)`,
    minWidth: `calc(100% - ${theme.old.spacing.unit(6)}px)`,
  },
}));

export interface AdditionalTriggerButtonProps {
  type: ButtonType;
  link?: string;
  text: string;
  iconName?: IconName;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

interface GlobalSiderTriggerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  additionalTrigger?: AdditionalTriggerButtonProps;
  disableSiderCollapseOnAdditionalButtonClick?: boolean;
}

const GlobalSiderTrigger: React.FC<GlobalSiderTriggerProps> = (props) => {
  const {
    open,
    setOpen,
    additionalTrigger,
    disableSiderCollapseOnAdditionalButtonClick,
  } = props;
  const classes = useStylesTrigger(props);
  const theme = useTheme<PrioTheme>();

  const debouncedOpen = useDebounce(open, 100);
  const dispatch = useDispatch();

  const additionalButton = useMemo(
    () => (
      <div
        style={{ height: additionalTrigger ? undefined : 0 }}
        className={classNames(classes.button, {
          [classes.buttonCollapsed]: !open,
        })}
        onClick={(event) => {
          if (!disableSiderCollapseOnAdditionalButtonClick) {
            dispatch(setSiderSetting({ subMenuState: 'closed' }));
          }
          if (additionalTrigger?.onClick) {
            additionalTrigger?.onClick(event);
          }
        }}
      >
        {additionalTrigger && (
          <FontAwesomeIcon icon={['fal', additionalTrigger.iconName]} />
        )}
        {debouncedOpen && (
          <div className={classes.buttonText}>
            {additionalTrigger?.text ?? ''}
          </div>
        )}
      </div>
    ),
    [
      additionalTrigger,
      classes,
      open,
      debouncedOpen,
      disableSiderCollapseOnAdditionalButtonClick,
      dispatch,
    ]
  );

  const additionalButtonWithTooltip = open ? (
    additionalButton
  ) : (
    <Tooltip
      title={
        <Flex.Row
          justifyContent="center"
          alignItems="center"
          childrenGap={theme.old.spacing.baseSpacing}
        >
          {additionalTrigger?.iconName && (
            <FontAwesomeIcon icon={['fal', additionalTrigger?.iconName]} />
          )}
          <Typography.Text style={{ color: 'unset' }}>
            {additionalTrigger?.text ?? ''}
          </Typography.Text>
        </Flex.Row>
      }
      placement="right"
    >
      {additionalButton}
    </Tooltip>
  );

  return (
    <div
      className={classes.root}
      onClick={(event) => {
        event.stopPropagation();
      }}
      tabIndex={-1}
    >
      {additionalTrigger && (
        <Divider
          className={classNames(classes.dividerTrigger, {
            [classes.dividerTriggerOpen]: open,
          })}
        />
      )}
      <div
        className={classNames(classes.triggerContainer, {
          [classes.triggerContainerCollapsed]: !debouncedOpen,
        })}
      >
        {additionalTrigger?.link ? (
          <Link
            to={additionalTrigger?.link}
            className={classNames(classes.buttonLink, {
              [classes.buttonLinkCollapsed]: !open,
            })}
          >
            {additionalButtonWithTooltip}
          </Link>
        ) : (
          additionalButtonWithTooltip
        )}
        <Button
          style={{
            marginTop:
              !open && additionalTrigger ? theme.old.spacing.baseSpacing : 0,
            backgroundColor: 'transparent',
            transform: !open && 'rotate(180deg)',
            width: !open && 'rotate(180deg)',
          }}
          className={classNames(classes.trigger, {
            [classes.triggerClosed]: !open,
          })}
          onClick={() => {
            setOpen(!open);
          }}
          type="link"
          iconProp={['fal', 'chevron-double-left']}
        />
      </div>
    </div>
  );
};
export default GlobalSiderTrigger;
