import {
  ContactId,
  CompanyId,
  Gender,
  ContactType,
  OfficeId,
  CompanyType,
} from './Types';
import {
  Company,
  CreateExternalCompanyRequest,
  ExternalCompany,
} from './Company';

import { CreateExternalOfficeRequest } from './Office';
import { ProjectInfo } from './Project';

export interface Contact {
  contactId: ContactId;
  gender?: Gender;
  title?: string;
  firstName: string;
  lastName: string;
  department?: string;
  position?: string;
  eMail?: string;
  eMail2?: string;
  eMail3?: string;
  phone?: string;
  phone2?: string;
  phone3?: string;
  cellphone?: string;
  cellphone2?: string;
  cellphone3?: string;
  fax?: string;
  notes?: string;
  archiveContactId?: string;
  rowVersion?: string;
  contactType?: ContactType;
  officeId?: OfficeId;
  companyId?: CompanyId;
  isArchived?: boolean;
  managerId?: ContactId;
  signaturePrefix?: string;
  street?: string;
  zip?: string;
  city?: string;
  website?: string;
  contactTagDtos?: ContactTag[];
}

export interface ExternalProjectContactMetaData {
  projectId: string;
  jobTitle: string;
}

export interface InternalContact extends Contact {
  registrationCompleted: boolean;
}

export interface ExternalContact extends Contact {
  contactClassificationName?: string;
  contactClassificationDescription?: string;
  companyId?: CompanyId;
  company?: Company;
}
export interface IExternalContactBundle {
  externalContact: ExternalContact;
  externalCompany: ExternalCompany;
}

export interface ICreateExternalContactBundle {
  externalContact: ExternalContact;
  externalCompany: ExternalCompany;
}

export interface CreateContactRequest {
  title?: string;
  gender?: Gender;
  firstName: string;
  lastName: string;
  department?: string;
  position?: string;
  eMail?: string;
  eMail2?: string;
  phone?: string;
  phone2?: string;
  cellphone?: string;
  cellphone2?: string;
  fax?: string;
  notes?: string;
  companyId: string;
  officeId: string;
  contactClassificationName?: string;
  projectList?: ExternalProjectContactMetaData[];
}

export interface CreateContactFormsResult {
  Contact: {
    title?: string;
    gender?: Gender;
    firstName: string;
    lastName: string;
    department?: string;
    position?: string;
    eMail?: string;
    eMail2?: string;
    phone?: string;
    phone2?: string;
    cellphone?: string;
    cellphone2?: string;
    fax?: string;
    notes?: string;
    companyId: string;
    officeId: string;
    contactClassificationName?: string;
  };
}

export interface CreateExternalContactBundle {
  contactDto: CreateContactRequest;
  companyDto: CreateExternalCompanyRequest;
  officeDto: CreateExternalOfficeRequest;
  projectIds: string[];
}

export const emptyCreateContactRequest: CreateContactRequest = {
  firstName: '',
  lastName: '',
  companyId: '',
  officeId: '',
};

export interface UpdateContactRequest {
  gender?: Gender;
  title?: string;
  firstName?: string;
  lastName?: string;
  department?: string;
  position?: string;
  eMail?: string;
  eMail2?: string;
  phone?: string;
  phone2?: string;
  cellphone?: string;
  cellphone2?: string;
  fax?: string;
  notes?: string;
  companyId?: string;
  contactClassificationName?: string;
  managerId?: ContactId;
  officeId?: OfficeId;
}

export interface ContactDuplicate {
  lastName?: string;
  firstName?: string;
  email?: string;
}

export interface ProjectMembership {
  contactId: string;
  projectInfos: ProjectInfo[];
}

export interface ContactTag {
  contactTagId: string;
  name: string;
  isArchived: boolean;
}

export interface ContactCompanysCalculatedData {
  name: string;
  internalExternal: 'Internal' | 'External';
  companyContact: 'Company' | 'Contact';
  email: string;
  phone: string;
  address: string;
  commercialRegisterNumber: string;
  contactTagIds: string[];
}

export interface ContactCompanyData {
  company: {
    companyId: CompanyId;
    shortName: string;
    fullName: string;
    fullName2: string;
    website: string;
    notes: string;
    commercialRegisterNumber: string;
    commercialRegisterCity: string;
    legalForm: string;
    companyType: CompanyType;
    parentCompanyId: string;
    signatureAdditionalInformation: string;
    signatureAdditionalInformation2: string;
    signatureAdditionalInformation3: string;
    isImported: boolean;
    vatNumber: string;
    isArchived: boolean;
  };
  contact: {
    officeId: OfficeId;
    contactId: ContactId;
    firstName: string;
    lastName: string;
    contactType: ContactType;
    isArchived: boolean;
    companyId: CompanyId;
    gender: 'male' | 'female' | 'diverse' | 'unknown';
    salutation: string;
    department: string;
    position: string;
    eMail: string;
    eMail2: string;
    eMail3: string;
    phone: string;
    phone2: string;
    phone3: string;
    cellphone: string;
    cellphone2: string;
    cellphone3: string;
    street: string;
    zip: string;
    city: string;
    website: string;
    fax: string;
    notes: string;
    title: string;
    signaturePrefix: string;
    isImported: boolean;
    rowVersion: string;
  };
}

export interface ContactSearchResultItem {
  data: ContactCompanyData;
  calculated: ContactCompanysCalculatedData;
}
