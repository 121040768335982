import { Typography } from 'antd';
import classNames from 'classnames';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import Flex from '../../../components/Flex';
import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';

const START_DELAY = 500;
const BALL_MOVEMENT = 3500;
const FUSE = 1500;
const BURN_DOWN = 3000;
const FLAME_DELAY = 50;
const BLEW_UP = 100;
const BURST_DELAY = 50;

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.base,
    overflow: 'hidden',
  },
  christmasBomble: {
    position: 'relative',
    left: '100%',
    top: 'calc(50% - 150px)',
    width: 150,
    height: 150,
    animation: `$bounce 750ms ease-in forwards ${START_DELAY}ms, $horizontalMovement ${BALL_MOVEMENT}ms ease-out forwards ${START_DELAY}ms, $spin ${BALL_MOVEMENT}ms forwards ${START_DELAY}ms, $disappear 1ms forwards ${
      BALL_MOVEMENT + BURN_DOWN + BURST_DELAY * 2
    }ms`,
  },
  christmasBombleShadow: {
    position: 'absolute',
    height: 12,
    width: 200,
    left: '100%',
    top: 'calc(65% - 4px)',
    backgroundColor: 'rgba(0, 0, 0, .25)',
    borderRadius: '50%',
    animation: `$horizontalMovementShadow ${BALL_MOVEMENT}ms ease-out forwards ${START_DELAY}ms, $disappear 1ms forwards ${
      BALL_MOVEMENT + BURN_DOWN + BURST_DELAY * 2
    }ms`,
  },
  ball: {
    position: 'relative',
    top: 0,
    left: 0,
    width: 150,
    height: 150,
    borderRadius: 75,
    overflow: 'hidden',
    backgroundColor: theme.old.palette.backgroundPalette.base,
    animation: `$ballBlewUp 100ms forwards ${
      BALL_MOVEMENT + BURN_DOWN
    }ms, $ballVanish 50ms forwards ${
      BALL_MOVEMENT + BURN_DOWN + 2 * BURST_DELAY
    }ms`,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: 'inset rgba(0,0,0, .25) 8px -8px',
      borderRadius: 75,
      animation: `$counterSpin ${BALL_MOVEMENT}ms forwards ${START_DELAY}ms`,
    },
  },
  ballTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 30,
    backgroundColor: theme.old.palette.chromaticPalette.red,
  },
  ballBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
    backgroundColor: theme.old.palette.chromaticPalette.red,
  },
  ballCenter: {
    position: 'relative',
    top: 30,
    bottom: 30,
    left: 0,
    right: 0,
    height: 90,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  ballCenterCenter: {
    position: 'absolute',
    top: 14,
    bottom: 14,
    left: 0,
    right: 0,
    backgroundColor: theme.old.palette.chromaticPalette.blue,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  triangleTop: {
    position: 'absolute',
    top: 2,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `10px solid ${theme.old.palette.chromaticPalette.blue}`,
  },
  triangleBottom: {
    position: 'absolute',
    bottom: 2,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: `10px solid ${theme.old.palette.chromaticPalette.blue}`,
  },
  fuseContainer: {
    position: 'relative',
    left: 'calc(50% - 10px)',
    top: 0,
    animation: `$popOutFuseContainer ${FUSE - 500}ms ease-in-out forwards ${
      BALL_MOVEMENT - 2000
    }ms`,
  },
  fuseEnd: {
    position: 'absolute',
    height: 30,
    width: 20,
    background: theme.old.palette.chromaticPalette.yellow,
    borderRadius: 5,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      boxShadow: 'inset rgba(0, 0, 0, 0.25) 4px -5px',
      borderRadius: '25%',
    },
  },
  rope: {
    position: 'absolute',
    height: 75,
    width: 100,
    borderRadius: '100% 0% 91% 9% / 100% 11% 89% 0%',
    top: -74,
    left: -19,
    animation: `$popOutRope ${FUSE}ms ease-in-out forwards ${
      BALL_MOVEMENT - 1800
    }ms, $ropeBurnDown ${BURN_DOWN}ms forwards ${BALL_MOVEMENT}ms`,
    transformOrigin: 'bottom right',
    transform: 'rotate(-90deg)',
    background:
      'repeating-linear-gradient(42deg, #9b6007, #9b6007 10px, #443825 12px, #443825 13px)',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 5,
      left: 5,
      right: -1,
      bottom: -1,
      borderRadius: '100% 0% 91% 9% / 100% 11% 89% 0%',
      backgroundColor: theme.old.palette.backgroundPalette.base,
    },
  },
  fire: {
    position: 'relative',
    width: 0,
    height: 0,
    animation: `$fireMovement ${BURN_DOWN}ms forwards ${BALL_MOVEMENT}ms`,
  },
  fireWhite: {
    top: -125,
    left: -25,
    transform: 'scale(0)',
    animation: `$burnWhite 100ms infinite ${BALL_MOVEMENT + 2 * FLAME_DELAY}ms`,
  },
  fireYellow: {
    top: -75,
    left: -25,
    transform: 'scale(0)',
    animation: `$burnYellow 100ms infinite ${BALL_MOVEMENT + FLAME_DELAY}ms`,
  },
  fireRed: {
    top: -25,
    left: -25,
    transform: 'scale(0)',
    animation: `$burnRed 100ms infinite ${BALL_MOVEMENT + FLAME_DELAY}ms`,
  },
  burstContainer: {
    position: 'relative',
    height: '100vh',
    width: '150vh',
    top: -150,
    left: 'calc((100vw - 150vh) / 2)',
    animation: '$disappear 1ms forwards 8050ms',
    '& > div:nth-child(1)': {
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      transform: 'scale(0)',
      animation: `$burst 500ms forwards ${
        BALL_MOVEMENT + BURN_DOWN + BLEW_UP / 2
      }ms`,
      '& > div': {
        backgroundColor: theme.old.palette.chromaticPalette.red,
      },
    },
    '& > div:nth-child(2)': {
      height: '76%',
      width: '76%',
      top: '-88%',
      left: '12%',
      transform: 'scale(0)',
      animation: `$burst 500ms forwards ${
        BALL_MOVEMENT + BURN_DOWN + BLEW_UP / 2 + BURST_DELAY
      }ms`,
      '& > div': {
        backgroundColor: theme.old.palette.chromaticPalette.yellow,
      },
    },
    '& > div:nth-child(3)': {
      height: '50%',
      width: '50%',
      top: '-150%',
      left: '25%',
      transform: 'scale(0)',
      animation: `$burst 500ms forwards ${
        BALL_MOVEMENT + BURN_DOWN + BLEW_UP / 2 + 2 * BURST_DELAY
      }ms`,
      '& > div': {
        backgroundColor: theme.old.palette.chromaticPalette.white,
      },
    },
  },
  burst: {
    position: 'relative',
    '& > div': {
      position: 'absolute',
      width: '76%',
      height: '76%',
      backgroundColor: '#fff',
      top: '12%',
      left: '12%',
    },
    '& > div:nth-last-child(-n+2)': {
      width: '50%',
      height: '50%',
      top: '25%',
      left: '25%',
    },
    '& > div:nth-child(1)': {
      transform: 'rotate(337deg) skew(55deg, 10deg)',
    },
    '& > div:nth-child(2)': {
      transform: 'rotate(337deg) skew(13deg, 38deg)',
    },
    '& > div:nth-child(3)': {
      transform: 'rotate(132deg) skew(55deg, 10deg)',
    },
    '& > div:nth-child(4)': {
      transform: 'rotate(19deg) skew(18deg, 41deg)',
    },
    '& > div:nth-child(5)': {
      transform: 'rotate(251deg) skew(18deg, 41deg)',
    },
  },
  merryChristmas: {
    position: 'relative',
    top: 'calc(50% - 250px)',
  },
  present: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '125%',
      height: 12,
      right: 0,
      bottom: -8,
      backgroundColor: 'rgba(0,0,0, .25)',
      borderRadius: '50%',
    },
  },
  presentTop: {
    position: 'relative',
    backgroundColor: '#fff',
    width: 175,
    height: 75,
    borderRadius: 10,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      borderRadius: 10,
      boxShadow: 'inset rgba(0,0,0, .25) 8px -4px',
    },
  },
  ribbon: {
    position: 'absolute',
    border: `15px solid ${theme.old.palette.chromaticPalette.red}`,
    borderRadius: '50%',
    borderBottomRightRadius: 0,
    width: 75,
    height: 75,
    top: -75,
    left: 15,
    '&::before': {
      content: '""',
      position: 'absolute',
      border: `15px solid ${theme.old.palette.chromaticPalette.red}`,
      borderRadius: '50%',
      borderBottomLeftRadius: 0,
      width: 75,
      height: 75,
      top: -15,
      left: '100%',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      boxShadow: 'inset rgba(0,0,0, .25) 8px -4px',
      width: 75,
      height: 75,
      top: -15,
      left: -15,
    },
  },
  presentContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    width: 150,
    height: 100,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      borderRadius: 10,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: 'inset rgba(0,0,0, .25) 8px -4px',
    },
  },
  presentContainerRibbon: {
    position: 'absolute',
    backgroundColor: theme.old.palette.chromaticPalette.red,
    top: 0,
    bottom: 0,
    height: '100%',
    width: '50%',
    left: '25%',
  },
  text1: {
    opacity: 0,
    animation: '$fadeIn 1s ease forwards 1s',
  },
  text2: {
    opacity: 0,
    animation: '$fadeIn 1s ease forwards 2.5s',
  },
  '@keyframes horizontalMovement': {
    '0%': {
      left: '100%',
    },
    '50%': {
      left: 'calc(50% - 75px)',
    },
    '100%': {
      left: 'calc(50% - 75px)',
    },
  },
  '@keyframes horizontalMovementShadow': {
    '0%': {
      left: '100%',
    },
    '50%': {
      left: 'calc(50% - 125px)',
    },
    '100%': {
      left: 'calc(50% - 125px)',
    },
  },
  '@keyframes bounce': {
    '0%': {
      top: 'calc(50% - 150px)',
    },
    '15%': {
      top: 'calc(65% - 150px)',
    },
    '30%': {
      top: 'calc(60% - 150px)',
    },
    '45%': {
      top: 'calc(65% - 150px)',
    },
    '60%': {
      top: 'calc(63% - 150px)',
    },
    '75%': {
      top: 'calc(65% - 150px)',
    },
    '90%': {
      top: 'calc(64% - 150px)',
    },
    '100%': {
      top: 'calc(65% - 150px)',
    },
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(-350deg)',
    },
    '100%': {
      transform: 'rotate(-350deg)',
    },
  },
  '@keyframes counterSpin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '50%': {
      transform: 'rotate(350deg)',
    },
    '100%': {
      transform: 'rotate(350deg)',
    },
  },
  '@keyframes popOutRope': {
    '0%': {
      left: -19,
      transform: 'rotate(-90deg)',
    },
    '100%': {
      left: 7,
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes popOutFuseContainer': {
    '0%': {
      top: 0,
    },
    '100%': {
      top: -25,
    },
  },
  '@keyframes ropeBurnDown': {
    '0%': {
      height: 75,
      width: 100,
      top: -74,
    },
    '100%': {
      height: 0,
      width: 0,
      top: 0,
    },
  },
  '@keyframes burnWhite': {
    '0%': {
      transform: 'scale(0) rotate(0deg)',
    },
    '100%': {
      transform: 'scale(0.25) rotate(360deg)',
    },
  },
  '@keyframes burnYellow': {
    '0%': {
      transform: 'scale(0) rotate(0deg)',
    },
    '100%': {
      transform: 'scale(0.5) rotate(720deg)',
    },
  },
  '@keyframes burnRed': {
    '0%': {
      transform: 'scale(0) rotate(0deg)',
    },
    '100%': {
      transform: 'scale(1) rotate(480deg)',
    },
  },
  '@keyframes fireMovement': {
    '0%': {
      top: -75,
      left: 107,
    },
    '100%': {
      top: 0,
      left: 10,
    },
  },
  '@keyframes ballBlewUp': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(1.15)',
    },
  },
  '@keyframes burst': {
    '0%': {
      transform: 'scale(0)',
      opacity: 1,
    },
    '25%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.5)',
      opacity: 0,
    },
  },
  '@keyframes ballVanish': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@keyframes disappear': {
    '0%': {
      visibility: 'visible',
    },
    '100%': {
      visibility: 'hidden',
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

interface MerryChristmasProps {}

export const MerryChristmas: React.FC<MerryChristmasProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [showText, setShowText] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setTimeout(() => setShowText(true), BALL_MOVEMENT + BURN_DOWN + 500);
  }, []);
  //#endregion

  return (
    <div className={classes.root}>
      {!showText ? (
        <>
          <div className={classes.christmasBombleShadow} />
          <div className={classes.christmasBomble}>
            <div className={classes.fuseContainer}>
              <div className={classes.rope} />
              <div className={classes.fire}>
                <Star
                  className={classes.fireRed}
                  color={theme.old.palette.chromaticPalette.red}
                />
                <Star
                  className={classes.fireYellow}
                  color={theme.old.palette.chromaticPalette.yellow}
                />
                <Star className={classes.fireWhite} />
              </div>
              <div className={classes.fuseEnd} />
            </div>
            <div className={classes.ball}>
              <div className={classes.ballTop} />
              <div className={classes.ballCenter}>
                {new Array(9).fill(0).map((_, index) => (
                  <div
                    style={{
                      left: `${(index + 1) * 14 - 1}px`,
                      ...(index % 2 === 0
                        ? {
                            borderBottomColor:
                              theme.old.palette.chromaticPalette.red,
                          }
                        : {}),
                    }}
                    className={classes.triangleTop}
                  />
                ))}
                <div className={classes.ballCenterCenter}>
                  <DoubleStar />
                  <DoubleStar />
                  <DoubleStar />
                </div>
                {new Array(9).fill(0).map((_, index) => (
                  <div
                    style={{
                      left: `${(index + 1) * 14 - 1}px`,
                      ...(index % 2 === 0
                        ? {
                            borderTopColor:
                              theme.old.palette.chromaticPalette.red,
                          }
                        : {}),
                    }}
                    className={classes.triangleBottom}
                  />
                ))}
              </div>
              <div className={classes.ballBottom} />
            </div>
          </div>
          <div className={classes.burstContainer}>
            <div className={classes.burst}>
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>

            <div className={classes.burst}>
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>

            <div className={classes.burst}>
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </>
      ) : (
        <>
          {new Array(45).fill(null).map(() => (
            <Snow size="small" />
          ))}
          <Flex.Column
            className={classes.merryChristmas}
            childrenGap={100}
            alignItems={'center'}
          >
            <Flex.Column alignItems={'center'}>
              <Typography.Title
                className={classes.text1}
                style={{
                  fontSize: 41,
                  margin: 0,
                  marginBottom: 24,
                  color: '#fff',
                }}
              >
                {t('doodle:merryChristmas.merryChristmas')}
              </Typography.Title>
              <Typography.Title
                className={classes.text2}
                style={{ fontSize: 41, margin: 0, color: '#fff' }}
              >
                {t('doodle:merryChristmas.andHappyNewYear')}
              </Typography.Title>
            </Flex.Column>
            <div className={classes.present}>
              <div className={classes.presentTop}>
                <div className={classes.ribbon} />
                <div className={classes.presentContainerRibbon} />
              </div>
              <div className={classes.presentContainer}>
                <div className={classes.presentContainerRibbon} />
              </div>
            </div>
          </Flex.Column>
          {new Array(25).fill(null).map(() => (
            <Snow size="big" />
          ))}
        </>
      )}
    </div>
  );
};

export default MerryChristmas;

const useStarStyles = makePrioStyles((theme) => ({
  diamondVertical: {
    width: 0,
    height: 0,
    border: '5px solid transparent',
    borderBottom: ({ color }) => `20px solid ${color ?? '#fff'}`,
    position: 'relative',
    top: 0,
    left: '20px',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '-5px',
      top: '20px',
      width: 0,
      height: 0,
      border: '5px solid transparent',
      borderTop: ({ color }) => `20px solid ${color ?? '#fff'}`,
    },
  },
  diamondHorizontal: {
    width: 0,
    height: 0,
    border: '5px solid transparent',
    borderLeft: ({ color }) => `20px solid ${color ?? '#fff'}`,
    position: 'relative',
    top: 15,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: -45,
      top: -5,
      width: 0,
      height: 0,
      border: '5px solid transparent',
      borderRight: ({ color }) => `20px solid ${color ?? '#fff'}`,
    },
  },
}));

interface StarProps {
  className?: string;
  style?: CSSProperties;
  color?: string;
}

const Star: React.FC<StarProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, style, color } = props;
  const classes = useStarStyles({ color });
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={className}
      style={{ ...style, position: 'relative', width: 50, height: 50 }}
    >
      <div className={classes.diamondVertical}>
        <div className={classes.diamondHorizontal} />
      </div>
    </div>
  );
};

interface DoubleStarProps {
  className?: string;
  color?: string;
}

const DoubleStar: React.FC<DoubleStarProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, color } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={className}
      style={{ position: 'relative', width: 50, height: 50 }}
    >
      <Star
        color={color}
        style={{
          transform: 'rotate(45deg) scale(0.75)',
          opacity: 0.5,
        }}
      />
      <Star color={color} style={{ top: -50 }} />
    </div>
  );
};

const useSnowStyles = makePrioStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: '50%',
    animation: ({ delay, speed }) =>
      `$fall ${speed}ms linear infinite ${delay}ms`,
    top: -100,
  },
  '@keyframes fall': {
    '0%': {
      top: -100,
    },
    '100%': {
      top: 'calc(100% + 100px)',
    },
  },
}));

interface SnowProps {
  className?: string;
  size: 'big' | 'small';
}

const Snow: React.FC<SnowProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, size } = props;
  const classes = useSnowStyles({
    delay: Math.round(Math.abs(Math.random()) * 10000),
    speed: Math.round(Math.abs(Math.random()) * 5000) + 10000,
  });
  //#endregion

  const dimensions =
    size === 'big' ? 25 + Math.round(Math.abs(Math.random()) * 10) : 5;

  return (
    <div
      className={classNames(classes.root, className)}
      style={{
        width: dimensions,
        height: dimensions,
        left: `${Math.round(Math.abs(Math.random()) * 95)}%`,
        ...(size === 'big' ? { opacity: 0.5 } : {}),
      }}
    />
  );
};
