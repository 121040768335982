import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  AutoComplete,
  Avatar,
  FormInstance,
  Collapse,
  Divider,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import * as ConfigValues from '../../../util/configValues';
import { makePrioStyles } from '../../../theme/utils';

import CompanyPicker from '../../companies/components/CompanyPicker';
import {
  Contact,
  ContactDuplicate,
  CreateContactRequest,
  emptyCreateContactRequest,
  ExternalContact,
} from '../../../models/Contact';
import { ConfigurationKeys } from '../../../models/Types';
import { emailPattern } from '../../../hooks/useEmailValidation';
import { useSelector, useDispatch } from 'react-redux';
import {
  getContactClassifications,
  getTitleSuggestions,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import {
  debounceFunction,
  defaultPhonePattern,
  defaultPhonePlaceholder,
} from '../../../util';
import { ContactId } from '../../../models/Types';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchContactClassifications } from '../../settings/actions';
import { fetchTitleSuggestions } from '../../settings/actions/titleSuggestions';
import { TitleSuggestion } from '../../../models/TitleSuggestion';
import { rowGutter, colon } from '../../../util/forms';
import { Configuration } from '../../../models/Configuration';
import { apiFetchConfigurations } from '../../settings/api';
import { apiCheckContactDuplicate } from '../api';
import { Company } from '../../../models/Company';
import DuplicateContactWarningList from './DuplicateContactWarningList';
import ContactCompanyPreview from './ContactCompanyPreview';
import { ProjectAssignment } from '../../../models/Project';
import AssignProject from '../../projects/components/AssignProject';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
    overflow: 'hidden',
  },
  formContent: {
    height: '100%',
    overflow: 'hidden',
  },
  formEditableContent: {
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(3),
  },
  checkbox: {
    marginTop: theme.old.spacing.unit(2),
  },
  alert: {
    marginTop: theme.old.spacing.unit(1),
  },

  tabs: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  tabsPane: {
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  newCompanyPlaceholder: {
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  collapse: {
    '&.ant-collapse-borderless': {
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
    '&.ant-collapse-borderless > .ant-collapse-item': {
      border: 'none',
    },
    '&.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header': {
      padding: 0,
      paddingBottom: theme.old.spacing.unit(1.5),
    },
    '& .ant-collapse-content > .ant-collapse-content-box': {
      padding: 0,
    },
  },
}));

type FormMode = 'create' | 'edit';
interface ContactFormProps {
  className?: string;
  initialCompany?: Company;
  initialValues?: Contact;
  mode: FormMode;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  actionLabelIgnore: string;
  actionLabelArchive: string;
  cancelLabel?: string;
  onFinish?: (
    value: CreateContactRequest | Contact,
    contactIdsToArchive?: ContactId[]
  ) => void;
  onCancel?: () => void;
  avatar?: boolean;
  openCompanyDrawer?: VoidFunction;
  form?: FormInstance;
  onProjectAssignmentChange?: (
    currentProjectAssignments: ProjectAssignment[]
  ) => void;
}

export const fallBackTitleOptions = [
  { value: 'Dr.' },
  { value: 'Prof. Dr.' },
  { value: 'Prof. Dr. Dr.' },
];
const { Panel } = Collapse;
export const ContactForm: React.FC<ContactFormProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    actionLabel,
    actionLabelIgnore,
    actionLabelArchive,
    cancelLabel,
    onFinish,
    onCancel,
    mode,
    disableActionButton,
    disableForm,
    avatar,
    openCompanyDrawer,
    form,
    initialCompany,
    initialValues,
    onProjectAssignmentChange,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contactClassifications = useSelector(getContactClassifications);
  const titleSuggestions: TitleSuggestion[] = useSelector<
    RootReducerState,
    TitleSuggestion[]
  >(getTitleSuggestions);

  const titleOptions = titleSuggestions.map((suggestion) => ({
    value: suggestion.value,
  }));
  //#endregion

  //#region -------------------------------- State declaration
  const [selectedCompany, setSelectedCompany] =
    useState<Company>(initialCompany);
  const [foundDuplicates, setFoundDuplicates] = useState<ExternalContact[]>([]);

  const [selectedDuplicateContacts, setSelectedDuplicateContacts] = useState<
    ContactId[]
  >([]);

  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  //#endregion

  //#region -------------------------------- Methods

  const cancel = () => {
    form.resetFields();
    setSelectedDuplicateContacts([]);
    onCancel();
  };

  var searchDuplicateContacts = debounceFunction(
    async (contactDuplicate: ContactDuplicate) => {
      const { firstName, lastName, email } = contactDuplicate;
      let contacts: Contact[] = [];
      if (firstName && lastName && email) {
        const { data } = await apiCheckContactDuplicate({
          firstName,
          lastName,
          email,
        });
        contacts = data;
      } else if (firstName && lastName) {
        const { data } = await apiCheckContactDuplicate({
          firstName,
          lastName,
        });
        contacts = data;
      } else if (email) {
        const { data } = await apiCheckContactDuplicate({
          email,
        });
        contacts = data;
      }
      if (contacts && contacts.length > 0) {
        const duplicates = contacts.filter(
          (c) => c.contactId !== initialValues?.contactId
        );
        if (duplicates.length > 0) {
          setFoundDuplicates(duplicates);
          setSelectedDuplicateContacts((selectedDuplicateContacts) =>
            selectedDuplicateContacts.filter(
              (contactId) =>
                duplicates.findIndex((d) => d.contactId === contactId) > -1
            )
          );
        }
      } else {
        setFoundDuplicates([]);
        setSelectedDuplicateContacts([]);
      }
    },
    500
  );
  //#endregion

  //#region -------------------------------- Handle methods
  const handleFinish: (value: CreateContactRequest | Contact) => void = (
    value: CreateContactRequest | Contact
  ) => {
    onFinish(value, selectedDuplicateContacts);
  };

  const handleOnCompanyCreate = () => {
    openCompanyDrawer();
  };
  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    dispatch(fetchTitleSuggestions());
    dispatch(fetchContactClassifications());
  }, [dispatch]);

  useEffect(() => {
    form.resetFields();
    if (initialValues?.companyId) {
      setSelectedCompany(initialCompany);
    }
  }, [initialValues, form, initialCompany]);

  useEffect(() => {
    if (!disableForm) {
      const controller = new AbortController();
      const signal = controller.signal;
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations(signal);
        if (data) setConfigurations(data);
      };
      loadConfigs();
      return () => {
        controller.abort();
      };
    }
    return undefined;
  }, [disableForm]);

  //#endregion

  return (
    <Form
      className={classNames(classes.root, className)}
      initialValues={initialValues ?? emptyCreateContactRequest}
      form={form}
      onValuesChange={(
        changedValues: CreateContactRequest,
        allValues: CreateContactRequest
      ) => {
        if (
          changedValues.firstName ||
          changedValues.lastName ||
          changedValues.eMail
        ) {
          searchDuplicateContacts({
            firstName: allValues.firstName,
            lastName: allValues.lastName,
            email: allValues.eMail,
          });
        }
      }}
      onFinish={handleFinish}
      layout="vertical"
    >
      <Flex.Column className={classes.formContent}>
        <Flex.Row>
          {avatar && (
            <Avatar
              icon={<FontAwesomeIcon icon={['fal', 'user']} />}
              size={96}
              style={{
                backgroundColor: theme.old.palette.primaryColor,
                fontSize: 48,
                fontWeight: theme.old.typography.fontWeight.bold,
                minWidth: 96,
                marginRight: theme.old.spacing.defaultPadding,
              }}
            />
          )}
          <Flex.Item flex={1}>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={8}>
                <Form.Item
                  name={['gender']}
                  colon={colon}
                  rules={[
                    {
                      required: true,
                      message: t('contacts:form.validation.missingGender'),
                    },
                  ]}
                >
                  <Select
                    disabled={disableForm}
                    placeholder={t('contacts:form.labelsRequired.gender')}
                  >
                    <Select.Option value="male">
                      {t('contacts:form.gender.male')}
                    </Select.Option>
                    <Select.Option value="female">
                      {t('contacts:form.gender.female')}
                    </Select.Option>
                    <Select.Option value="diverse">
                      {t('contacts:form.gender.diverse')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={['title']} colon={colon}>
                  <AutoComplete
                    options={titleOptions || fallBackTitleOptions}
                    placeholder={t('contacts:form.labels.title')}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={['contactClassificationName']}
                  colon={colon}
                  rules={[
                    {
                      required: false,
                      message: t(
                        'contacts:form.validation.missingContactClassification'
                      ),
                    },
                  ]}
                >
                  <Select
                    disabled={disableForm}
                    placeholder={t(
                      'contacts:form.labels.contactClassificationName'
                    )}
                  >
                    {contactClassifications.map((classification) => (
                      <Select.Option
                        key={classification.name}
                        value={classification.name}
                      >
                        {classification.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={12}>
                <Form.Item
                  name={['firstName']}
                  colon={colon}
                  rules={[
                    {
                      required: true,
                      message: t('contacts:form.validation.missingFirstName'),
                    },
                  ]}
                >
                  <Input
                    disabled={disableForm}
                    placeholder={t('contacts:form.labelsRequired.firstName')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['lastName']}
                  colon={colon}
                  rules={[
                    {
                      required: true,
                      message: t('contacts:form.validation.missingLastName'),
                    },
                  ]}
                >
                  <Input
                    disabled={disableForm}
                    placeholder={t('contacts:form.labelsRequired.lastName')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Flex.Item>
        </Flex.Row>
        <Divider />
        <Flex.Item flex={1} className={classes.formEditableContent}>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name={['companyId']}
                label={t('contacts:form.labels.companyId')}
                rules={[
                  {
                    required: true,
                    message: t('contacts:form.validation.missingCompanyId'),
                  },
                ]}
              >
                <CompanyPicker
                  buttonLabel={
                    mode === 'create'
                      ? t('companies:pickerActions.addCompany')
                      : undefined
                  }
                  companyType="ExternalCompany"
                  disabled={
                    disableForm ||
                    !!initialCompany ||
                    (mode === 'edit' &&
                      ConfigValues.REACT_APP_CONTACT_OFFICE_CHANGE === null) ||
                    (mode === 'edit' &&
                      ConfigValues.REACT_APP_CONTACT_OFFICE_CHANGE === 'false')
                  }
                  onButtonClick={handleOnCompanyCreate}
                  onCompanySelect={setSelectedCompany}
                  disableTemporaryIdFilter
                />
              </Form.Item>
              <ContactCompanyPreview
                companyData={selectedCompany}
              ></ContactCompanyPreview>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name={['department']}
                label={t('contacts:form.labels.department')}
                colon={colon}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['position']}
                label={t('contacts:form.labels.position')}
                colon={colon}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name={['eMail']}
                label={t('contacts:form.labels.eMail')}
                colon={colon}
                validateTrigger="onBlur"
                rules={[
                  {
                    message: t('contacts:form.validation.invalidEmail'),
                    pattern: emailPattern,
                  },
                  {
                    required: true,
                    message: t('contacts:form.validation.missingEmail'),
                  },
                ]}
              >
                <Input disabled={disableForm} type="email" />
              </Form.Item>
            </Col>
          </Row>
          {mode === 'create' && (
            <Flex.Item>
              <AssignProject
                onProjectAssignmentChange={onProjectAssignmentChange}
              />
            </Flex.Item>
          )}

          <Collapse bordered={false} className={classes.collapse}>
            <Panel
              header={
                <Typography.Title level={4}>
                  {t('contacts:form.labels.personalContactData')}
                </Typography.Title>
              }
              key="1"
            >
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={24}>
                  <Form.Item
                    name={['street']}
                    label={t('contacts:form.labels.street')}
                    colon={colon}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={theme.old.spacing.unit(rowGutter)}>
                <Col span={8}>
                  <Form.Item
                    name={['zip']}
                    label={t('contacts:form.labels.zip')}
                    colon={colon}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name={['city']}
                    label={t('companies:form.labels.city')}
                    colon={colon}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Divider style={{ marginTop: 0 }} />
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name={['eMail2']}
                label={t('contacts:form.labels.eMail2')}
                colon={colon}
                validateTrigger="onBlur"
                rules={[
                  {
                    message: t('contacts:form.validation.invalidEmail'),
                    pattern: emailPattern,
                  },
                ]}
              >
                <Input disabled={disableForm} type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name={['eMail3']}
                label={t('contacts:form.labels.eMail3')}
                colon={colon}
                validateTrigger="onBlur"
                rules={[
                  {
                    message: t('contacts:form.validation.invalidEmail'),
                    pattern: emailPattern,
                  },
                ]}
              >
                <Input disabled={disableForm} type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name={['phone']}
                label={t('contacts:form.labels.phone')}
                colon={colon}
                rules={[
                  {
                    message: t('contacts:form.validation.invalidPhoneNumber'),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['cellphone']}
                label={t('contacts:form.labels.cellphone')}
                colon={colon}
                rules={[
                  {
                    message: t('contacts:form.validation.invalidPhoneNumber'),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_MOBILE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name={['phone2']}
                label={t('contacts:form.labels.phone2')}
                colon={colon}
                rules={[
                  {
                    message: t('contacts:form.validation.invalidPhoneNumber'),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['cellphone2']}
                label={t('contacts:form.labels.cellphone2')}
                colon={colon}
                rules={[
                  {
                    message: t('contacts:form.validation.invalidPhoneNumber'),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_MOBILE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name={['phone3']}
                label={t('contacts:form.labels.phone3')}
                colon={colon}
                rules={[
                  {
                    message: t('contacts:form.validation.invalidPhoneNumber'),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['cellphone3']}
                label={t('contacts:form.labels.cellphone3')}
                colon={colon}
                rules={[
                  {
                    message: t('contacts:form.validation.invalidPhoneNumber'),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_MOBILE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_MOBILE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={
                    configurations?.find(
                      (config) =>
                        config.key === ConfigurationKeys.PLACEHOLDER_MOBILE
                    )?.value ?? defaultPhonePlaceholder
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name={['notes']}
                label={t('contacts:form.labels.notes')}
                colon={colon}
                style={{ marginBottom: '0px' }}
              >
                <Input.TextArea disabled={disableForm} rows={5} />
              </Form.Item>
            </Col>
          </Row>
          <DuplicateContactWarningList
            duplicateContacts={foundDuplicates}
            onArchiveContact={setSelectedDuplicateContacts}
          />
        </Flex.Item>
        <Row justify="end" className={classes.actionButtonsRow}>
          <Col span={24}>
            <Form.Item className={classes.submitButtonFormItem}>
              {onCancel && (
                <Button
                  type="default"
                  onClick={cancel}
                  style={{ marginRight: '8px' }}
                >
                  {cancelLabel}
                </Button>
              )}
              <Button htmlType="submit" disabled={disableActionButton}>
                {foundDuplicates.length > 0
                  ? selectedDuplicateContacts.length > 0
                    ? actionLabelArchive
                    : actionLabelIgnore
                  : actionLabel}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Flex.Column>
    </Form>
  );
};

export default ContactForm;
