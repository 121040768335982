import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'antd';
import { CurrencyCode } from '../models/Accounting';
import { currencySymbol } from '../util';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface CurrencyInputProps {
  className?: string;
  value?: number;
  isoCode: CurrencyCode;
  onChange?: (value: number) => void;
  placeholder?: string;
  disabled?: boolean;
  updateOnValueChange?: boolean;
  allowNegative?: boolean;
}

const numberReg = /^-?\d*(,\d{0,2})?$/;
const positiveNumberReg = /^\d*(,\d{0,2})?$/;

export const CurrencyInput: React.FC<CurrencyInputProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    value,
    isoCode,
    onChange,
    placeholder,
    disabled,
    updateOnValueChange,
    allowNegative,
  } = props;

  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [stringValue, setStringValue] = useState<string>(
    value?.toFixed(2)?.replace('.', ',')
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onBlur = () => {
    setStringValue(value?.toFixed(2)?.replace('.', ','));
  };

  const onStringChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newStringValue = event.target.value.replace(/\./g, '');
    if ((allowNegative ? numberReg : positiveNumberReg).test(newStringValue)) {
      const newValue = parseFloat(newStringValue.replace(/,/g, '.'));
      setStringValue(newStringValue);
      if (onChange) {
        onChange(newValue);
      } else {
        event.preventDefault();
      }
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (updateOnValueChange)
      setStringValue(value?.toFixed(2)?.replace('.', ','));
  }, [value, updateOnValueChange]);
  //#endregion

  return (
    <Input
      className={classNames(classes.root, className)}
      onChange={onStringChanged}
      placeholder={placeholder}
      maxLength={30}
      suffix={currencySymbol(isoCode)}
      value={stringValue}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
};

export default CurrencyInput;
