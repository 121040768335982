import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import CreateTimeRecord from './CreateTimeRecord/CreateTimeRecord';
import { DateTimeString } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface TimeRecordsDrawerContentProps {
  className?: string;
  startDateTime?: DateTimeString;
  endDateTime?: DateTimeString;
}

export const TimeRecordsDrawerContent: React.FC<
  TimeRecordsDrawerContentProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, startDateTime, endDateTime } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <CreateTimeRecord startTime={startDateTime} endTime={endDateTime} />
    </div>
  );
};

export default TimeRecordsDrawerContent;
