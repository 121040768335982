import React, { ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getInternalProjectContactsData } from '../../../apps/main/rootReducer';
import { ProjectId } from '../../../models/Types';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import { Contact } from '../../../models/Contact';
import { sortContactsHelper } from '../../contacts/utils';
import { InternalProjectContactsContext } from '../context/InternalProjectContactsContext';
import { InternalProjectContact } from '../../../models/ProjectContacts';

interface InternalProjectContactsProviderProps {
  children?: ReactNode;
}

export const InternalProjectContactsProvider: React.FC<
  InternalProjectContactsProviderProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getContactById } = useContactsContext(true);

  const internalProjectContactDataState = useSelector(
    getInternalProjectContactsData
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getInternalProjectContacts = useCallback(
    (projectId: ProjectId) => {
      return (
        (internalProjectContactDataState[projectId] ??
          []) as InternalProjectContact[]
      ).sort((a, b) => {
        const _a = getContactById(a.contactId);
        const _b = getContactById(b.contactId);
        const lastNameCompare = (_a?.lastName ?? '').localeCompare(
          _b?.lastName ?? ''
        );
        if (lastNameCompare !== 0) {
          return lastNameCompare;
        }
        return (_a?.firstName ?? '').localeCompare(_b?.firstName ?? '');
      });
    },
    [internalProjectContactDataState, getContactById]
  );

  const getInternalProjectContactsContacts = useCallback(
    (projectId: ProjectId) => {
      const internalProjectContacts =
        internalProjectContactDataState[projectId] ?? [];
      return internalProjectContacts
        .reduce((acc, { contactId }) => {
          const contact = getContactById(contactId);
          if (contact) {
            acc.push(contact);
          }
          return acc;
        }, [] as Contact[])
        .sort(sortContactsHelper);
    },
    [internalProjectContactDataState, getContactById]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <InternalProjectContactsContext.Provider
      value={{
        getInternalProjectContacts,
        getInternalProjectContactsContacts,
      }}
    >
      {children}
    </InternalProjectContactsContext.Provider>
  );
};

export default InternalProjectContactsProvider;
