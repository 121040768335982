import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { MailCategoryColorName, ProjectId } from '../../../../models/Types';
import { FETCH_PROJECT_MAIL_CATEGORIES_COMMIT } from '../../actions/projects/categories';
import { MailCategoryAction } from '../../actions/types';

export interface CategoryState {
  [projectId: string]: {
    [displayName: string]: MailCategoryColorName;
  };
}

const categories: Reducer<CategoryState, MailCategoryAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_MAIL_CATEGORIES_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: {
          ...(state[projectId] || {}),
          ...payload.reduce(function (map, item) {
            map[item.displayName] = item.color;
            return map;
          }, {}),
        },
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export default categories;

export const getColorForMessageCategory = (
  state: CategoryState,
  projectId: ProjectId,
  displayName: string
) => state[projectId] && state[projectId][displayName];

export const getMessageCategoryColorMapForProject = (
  state: CategoryState,
  projectId: ProjectId
) => state[projectId];
