import { PRIO } from '../../../constants';
import { Notification } from '../../../models/Notification';

export const WS_NOTIFICATION_CREATED = PRIO + 'WS_NOTIFICATION_CREATED';

export const wsNotificationCreated = (notification: Notification) => ({
  type: WS_NOTIFICATION_CREATED,
  payload: {
    notification,
  },
});

export const WS_NOTIFICATION_UPDATED = PRIO + 'WS_NOTIFICATION_UPDATED';

export const wsNotificationUpdated = (notification: Notification) => ({
  type: WS_NOTIFICATION_UPDATED,
  payload: {
    notification,
  },
});
