import { PRIO } from '../../../constants';
import { ContactId, OfficeId } from '../../../models/Types';

export interface AbsenceTimelineAction {
  type: string;
  contactId?: ContactId;
  officeId?: OfficeId;
}

export const ABSENCE_OVERVIEW_ADD_EMPLOYEE =
  PRIO + 'ABSENCE_OVERVIEW_ADD_EMPLOYEE';

export const addEmployee: (
  contactId: ContactId,
  officeId: OfficeId | null
) => AbsenceTimelineAction = (contactId, officeId) => ({
  type: ABSENCE_OVERVIEW_ADD_EMPLOYEE,
  contactId,
  officeId,
});

export const ABSENCE_OVERVIEW_REMOVE_EMPLOYEE =
  PRIO + 'ABSENCE_OVERVIEW_REMOVE_EMPLOYEE';

export const removeEmployee: (
  contactId: ContactId,
  officeId: OfficeId | null
) => AbsenceTimelineAction = (contactId, officeId) => ({
  type: ABSENCE_OVERVIEW_REMOVE_EMPLOYEE,
  contactId,
  officeId,
});
