import { useEffect, useState } from 'react';
import { ProjectMembership } from '../../../models/Contact';
import { apiFetchProjectMemberships } from '../../projects/api';

export interface UsePrijectMembership {
  projectMembership: ProjectMembership;
  isFetchingProjectMembers: boolean;
}
export const useProjectMembership = (contactId: string) => {
  const [projectMembership, setProjectMembership] =
    useState<ProjectMembership>();

  const [isFetchingProjectMember, setIsFetchingProjectMember] =
    useState<boolean>();

  useEffect(() => {
    setProjectMembership(null);
    const fetchProjectMembership = async () => {
      setIsFetchingProjectMember(true);

      const { data } = await apiFetchProjectMemberships(contactId);
      setProjectMembership(data);
      setIsFetchingProjectMember(false);
    };

    fetchProjectMembership();
  }, [contactId]);

  return {
    projectMembership,
    isFetchingProjectMember,
  };
};
