import {
  actionChannel,
  all,
  call,
  cancelled,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  getActiveMailFolderId,
  getAllMailFolders,
  getAllMessages,
  getMailFoldersIsFetching,
  getMessageCategoryColorMap,
  getSpecialMailFolders,
  isLoggedIn,
} from '../../../apps/main/rootReducer';
import { MailCategoryColorName } from '../../../models/Types';
import { MailFolder } from '../../../models/MailFolder';
import { SpecialMailFolders } from '../actions/types';
import equals from 'deep-equal';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import { dispatchSaga, SAGA_REBUILD } from '../../../util/sagas';
import { fetchMailFolders } from '../actions/actionControllers/mailFoldersController';
import { fetchMailCategories } from '../actions/actionControllers/categoriesActionController';
import { fetchSpecialMailFolders } from '../actions/actionControllers/specialMailFoldersActionController';
import {
  FetchMailFoldersSagaAction,
  SAGA_FETCH_MAIL_FOLDERS,
} from '../actions/sagas';
import { notification } from 'antd';
import i18n from '../../../i18n';

const flattenAllMailFolders = (mailFolders: Array<MailFolder>) => {
  const allMailFolders: Array<MailFolder> = [];
  (mailFolders ?? []).forEach((mailFolder) => {
    allMailFolders.push(mailFolder);
    if (mailFolder.childFolder) {
      allMailFolders.push(...flattenAllMailFolders(mailFolder.childFolder));
    }
  });
  return allMailFolders;
};

function* handleMailFolders(action: FetchMailFoldersSagaAction) {
  const { projectId, refetch, mailFolderId } = action;
  try {
    const isFetching = yield select((state) =>
      getMailFoldersIsFetching(state, projectId)
    );

    const mailFolders: Array<MailFolder> = yield select((state) =>
      flattenAllMailFolders(getAllMailFolders(state, projectId))
    );

    const specialMailFolders: SpecialMailFolders = yield select((state) =>
      getSpecialMailFolders(state, projectId)
    );

    const activeMailFolderId = yield select((state) =>
      getActiveMailFolderId(state, projectId)
    );

    const isInitial: boolean = yield select(
      (state) =>
        getAllMessages(
          state,
          projectId,
          mailFolderId ?? activeMailFolderId ?? 'inbox'
        )?.length === 0
    );

    const categories: { [displayName: string]: MailCategoryColorName } =
      yield select((state) => getMessageCategoryColorMap(state, projectId));

    if (!isFetching) {
      yield all({
        ...(isInitial || mailFolders.length === 0 || refetch
          ? { mailFolders: dispatchSaga(fetchMailFolders(projectId)) }
          : {}),
        ...(isInitial ||
        categories === undefined ||
        equals(categories, {}) ||
        refetch
          ? { categories: dispatchSaga(fetchMailCategories(projectId)) }
          : {}),
        ...(isInitial ||
        specialMailFolders === undefined ||
        equals(categories, {}) ||
        refetch
          ? {
              specialMailFolders: dispatchSaga(
                fetchSpecialMailFolders(projectId)
              ),
            }
          : {}),
      });
    }
  } catch (error) {
    console.error(
      'Error in message saga fetching mailFolders - Original Error',
      error
    );
    console.error(
      'Error in message saga fetching mailFolders - Data',
      `projectId: ${projectId}`
    );
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.mailFolders.fetchError'),
    });
  }
}

function* actionPipeline() {
  let lastAction: FetchMailFoldersSagaAction = null;
  try {
    const channel = yield actionChannel(SAGA_FETCH_MAIL_FOLDERS);
    while (true) {
      const payload = yield take(channel);
      try {
        lastAction = payload;
        yield call(handleMailFolders, payload);
        lastAction = null;
      } catch (error) {
        console.error('Error in watchMailFolders - actionpipeline', error);
        notification.open({
          message: i18n.t('common:error'),
          description: i18n.t('mail:errorMessages.mailFolders.fetchError'),
        });
      }
    }
  } finally {
    if (yield cancelled() && lastAction !== null) {
      yield call(handleMailFolders, lastAction);
      lastAction = null;
    }
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(actionPipeline), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in watchMailFolders - mainTask', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.mailFolders.fetchError'),
    });
    yield mainTask();
  }
}

export default function* watchMailFolders() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
