import React, { useEffect } from 'react';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import WidgetArea from '../../widgetArea/components/WidgetArea';
import PrioSpinner from '../../../components/PrioSpinner';
import { useTranslation } from 'react-i18next';
import {
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Project } from '../../../models/Project';
import MessagePage from '../../mail/components/MessagePage';
import { setMailListNavigationState } from '../../mail/actions/actionControllers/mailNavigationActionController';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { fetchMailFoldersSagaAction } from '../../mail/actions/sagas';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  content: {
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    overflow: 'hidden',
    position: 'relative',
  },
  messageView: {
    overflow: 'auto',
  },
}));

interface ViewMessageViewProps {}

export const ViewMessageView: React.FC<ViewMessageViewProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { projectId, messageId } = useParams();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const currentProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const userMe = useSelector(getUserMe);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(fetchMailFoldersSagaAction(projectId, true));
  }, [projectId, dispatch]);

  useEffect(() => {
    dispatch(setMailListNavigationState(messageId, projectId));
  }, [projectId, messageId, dispatch]);

  useEffect(() => {
    if (projectId) {
      document.title = `Prio365 – ${
        projectId !== 'me'
          ? `${currentProject?.number} ${
              currentProject?.shortName ?? currentProject?.name
            }`
          : `${userMe?.givenName ?? ''} ${userMe?.surname ?? ''}`
      } – ${t('projects:navigation.mail')}`;
    }
  }, [currentProject, projectId, userMe, t]);
  //#endregion

  return (
    <DndProvider backend={HTML5Backend}>
      <Flex.Row className={classes.root}>
        <Flex.Column
          className={classes.content}
          childrenGap={theme.old.spacing.unit(1)}
          flex={2}
        >
          {projectId ? (
            <MessagePage projectId={projectId} />
          ) : (
            <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
              <PrioSpinner size="large" />
            </div>
          )}
        </Flex.Column>
        <WidgetArea selectedProjectId={projectId} disablePersonalMessages />
      </Flex.Row>
    </DndProvider>
  );
};

export default ViewMessageView;
