import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';

import { getCompany, RootReducerState } from '../../../apps/main/rootReducer';
import { TextProps } from 'antd/lib/typography/Text';
import { Company } from '../../../models/Company';

interface CompanyTextProps extends Omit<TextProps, 'styles'> {
  companyId: string;
  shortName?: boolean;
  addLineBreak?: boolean;
  style?: React.CSSProperties & { [key: string]: any };
}

export const CompanyText: React.FC<CompanyTextProps> = (props) => {
  const { companyId, shortName, addLineBreak, ...rest } = props;

  const company = useSelector<RootReducerState, Company>((state) =>
    getCompany(state, companyId)
  );

  if (!company) return null;

  return (
    <Typography.Text {...rest}>
      {shortName ? company.shortName : company.fullName}
      {addLineBreak && <br />}
    </Typography.Text>
  );
};

export default CompanyText;
