import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { MailSettings } from '../../../../models/UserSettings/MailSettings';
import { FETCH_USER_SETTINGS_COMMIT } from '../../actions';
import {
  UPDATE_CACHING_ENABLED,
  UPDATE_MAIL_SETTINGS_REQUEST,
} from '../../actions/mailSettings/mail';

export interface MailSettingsState {
  mailSettings: MailSettings;
  cachingEnabled: boolean;
}

export const maxMailNavigationWidth = 480;
export const minMailNavigationWidth = 240;
export const maxMailNavigationHeight = 600;
export const minMailNavigationHeight = 240;

const initialState: MailSettings = {
  messageViewLayout: 'right',
  mailListSpacing: 'full',
  automaticRead: true,
  deleteMovedMessageMe: true,
  mailNavigationSize: {
    width: 320,
    height: 320,
  },
  showCcBccSelection: {
    cc: false,
    bcc: false,
  },
};

export const mailSettings: Reducer<MailSettings, any> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_USER_SETTINGS_COMMIT: {
      const { payload } = action;
      const { width, height } = payload?.mailSettings?.mailNavigationSize;
      return {
        ...payload.mailSettings,
        mailNavigationSize: {
          width:
            width < minMailNavigationWidth ||
            width > maxMailNavigationWidth ||
            typeof width === 'string'
              ? 320
              : width,
          height:
            height < minMailNavigationHeight ||
            height > maxMailNavigationHeight ||
            typeof height === 'string'
              ? 320
              : height,
        },
      };
    }

    case UPDATE_MAIL_SETTINGS_REQUEST: {
      const {
        meta: { mailSettings },
      } = action;
      return {
        ...state,
        ...mailSettings,
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export const cachingEnabled: Reducer<boolean, any> = (
  state = false,
  action
) => {
  switch (action.type) {
    case UPDATE_CACHING_ENABLED: {
      const { payload } = action;

      return payload;
    }

    case CLEAR_PRIO_CACHE: {
      return false;
    }
    default:
      return state;
  }
};

export default combineReducers<MailSettingsState>({
  mailSettings,
  cachingEnabled,
});

export const getMailSettings: (state: MailSettingsState) => MailSettings = (
  state
) => state.mailSettings;

export const getEmailCachingEnabled: (state: MailSettingsState) => boolean = (
  state
) => state.cachingEnabled;
