import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment';
import { makePrioStyles } from '../../../theme/utils';
import { useSelector } from 'react-redux';
import { Modal, VirtualTable } from '@prio365/prio365-react-library';
import { apiFetchUserLicences, apiUpdateLicences } from '../api';
import { Contact } from '../../../models/Contact';
import {
  getAllInternalOffices,
  getContactsByOfficeIds,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserLicence } from '../../../models/UserLicence';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%' },
}));

interface PrioUserLicenceManagementProps {
  className?: string;
  officeId?: string;
}

interface UserWithLicence {
  contact: Contact;
  hasLicence: boolean;
  activationDateTime?: string;
  deactivationDateTime?: string;
  type?: string;
}

export const PrioUserLicenceManagement: React.FC<
  PrioUserLicenceManagementProps
> = ({ className, officeId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [usersWithLicence, setUsersWithLicence] = useState<UserWithLicence[]>(
    []
  );
  const [selectedItems, setSelectedItems] = useState<UserWithLicence[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalActionType, setModalActionType] = useState<
    'activate' | 'deactivate' | null
  >(null);

  const allInternalOfficeIds = useSelector(getAllInternalOffices).map(
    (office) => office.officeId
  );

  const allOfficesEmployees = useSelector<RootReducerState, Contact[]>(
    (state) => getContactsByOfficeIds(state, allInternalOfficeIds)
  );

  const processUserLicences = (licences: UserLicence[]) => {
    // Filter all employees based on the office ID
    let employees = [];
    if (!officeId) {
      employees = allOfficesEmployees;
    } else {
      employees = allOfficesEmployees.filter(
        (contact) => contact.officeId === officeId
      );
    }

    // Filter non-archived contacts
    const activeContacts = employees.filter(
      (contact) => contact.isArchived === false
    );

    // Map contacts to licenses
    const usersData = activeContacts.map((contact) => {
      const matchedLicence = licences.find(
        (licence) => licence.contactId === contact.contactId
      );

      return {
        contact,
        hasLicence: !!matchedLicence, // true if a license exists, false otherwise
        activationDateTime: matchedLicence?.activationDateTime || '',
        deactivationDateTime: matchedLicence?.deactivationDateTime || '',
        type: matchedLicence?.type || '',
      };
    });

    setUsersWithLicence(usersData);
  };

  const showModal = (actionType: 'activate' | 'deactivate') => {
    setModalActionType(actionType);
    setIsModalVisible(true);
  };

  const handleModalConfirm = async () => {
    if (modalActionType === 'activate') {
      await activateLicences(selectedItems); // Call activation function
    } else if (modalActionType === 'deactivate') {
      await deactivateLicences(selectedItems); // Call deactivation function
    }
    setSelectedItems([]); // Clear selected items
    const licences = await apiFetchUserLicences(officeId);
    processUserLicences(licences);
    setIsModalVisible(false); // Close the modal after confirming
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Close the modal without taking action
  };

  useEffect(() => {
    const fetchUserLicences = async () => {
      try {
        const licences = await apiFetchUserLicences(officeId);
        processUserLicences(licences);
      } catch (error) {
        console.error('Error fetching user licenses:', error);
      }
    };

    fetchUserLicences();
    // eslint-disable-next-line
  }, [officeId]);

  const handleRowClick = useCallback((user: UserWithLicence) => {
    return {
      onClick: () => {
        console.log('User selected:', user);
      },
    };
  }, []);

  const onSelectionChange = (items: UserWithLicence[]) => {
    setSelectedItems(items);
  };

  const activateLicences = async (selectedUsers: UserWithLicence[]) => {
    const licencesToUpdate = selectedUsers.map((user) => ({
      contactId: user.contact.contactId,
      activationDateTime: user.activationDateTime
        ? user.activationDateTime
        : moment.utc().startOf('month').toISOString(), // First day of the current month in UTC
      deactivationDateTime: '2099-12-31T23:59:59Z', // Set to far future
      type: user.type || 'standard', // Adjust the type if needed
    }));

    try {
      await apiUpdateLicences(officeId, licencesToUpdate);
      console.log('Licences activated');
      // Optionally, refetch or update the UI
    } catch (error) {
      console.error('Error activating licences:', error);
    }
  };

  const deactivateLicences = async (selectedUsers: UserWithLicence[]) => {
    const licencesToUpdate = selectedUsers.map((user) => {
      const firstDayNextMonth = moment.utc().add(1, 'months').startOf('month');
      const firstDayLastMonth = moment
        .utc()
        .subtract(1, 'months')
        .startOf('month');

      return {
        contactId: user.contact.contactId,
        activationDateTime: user.activationDateTime
          ? user.activationDateTime
          : firstDayLastMonth.toISOString(), // Set to last month if not already activated
        deactivationDateTime: firstDayNextMonth.toISOString(), // Deactivate at the start of next month
        type: user.type || 'default', // Adjust the type if needed
      };
    });

    try {
      await apiUpdateLicences(officeId, licencesToUpdate);
      console.log('Licences deactivated');
      // Optionally, refetch or update the UI
    } catch (error) {
      console.error('Error deactivating licences:', error);
    }
  };

  //#region ------------------------------ Table Setup
  const columns: Column<UserWithLicence>[] = [
    {
      id: 'name',
      alignSelf: true,
      width: 200,
      title: t('users:userLicenceTable.name'),
      accessor: 'contact',
      Cell: ({ value: contact }) => `${contact.firstName} ${contact.lastName}`,
      sortingFn: (a, b) =>
        `${a.contact.firstName} ${a.contact.lastName}`.localeCompare(
          `${b.contact.firstName} ${b.contact.lastName}`
        ),
    },
    {
      id: 'eMail',
      alignSelf: true,
      width: 200,
      title: t('users:userLicenceTable.eMail'),
      accessor: 'contact',
      Cell: ({ value: contact }) => `${contact.eMail}`,
      sortingFn: (a, b) =>
        `${a.contact.firstName}`.localeCompare(`${b.contact.eMail}`),
    },
    {
      id: 'hasLicence',
      alignSelf: true,
      width: 80,
      title: t('users:userLicenceTable.hasLicence'),
      accessor: 'hasLicence',
      Cell: (cellProps) =>
        cellProps.value
          ? t('users:userLicenceTable.hasLicenceYes')
          : t('users:userLicenceTable.hasLicenceNo'),
      sortingFn: (a, b) =>
        a.hasLicence === b.hasLicence ? 0 : a.hasLicence ? -1 : 1,
      isDefaultSortingFn: true,
    },
    {
      id: 'type',
      alignSelf: true,
      width: 100,
      title: t('users:userLicenceTable.type'),
      accessor: 'type',
      Cell: (cellProps) =>
        cellProps?.value === undefined || cellProps?.value === ''
          ? '-'
          : cellProps?.value,
    },
    {
      id: 'activationDateTime',
      alignSelf: true,
      width: 100,
      title: t('users:userLicenceTable.activationDateTime'),
      accessor: 'activationDateTime',
      Cell: (cellProps) =>
        cellProps.value
          ? moment(cellProps.value).format('MM/YYYY') // Using moment to format date
          : '-',
    },
    {
      id: 'deactivationDateTime',
      alignSelf: true,
      width: 100,
      title: t('users:userLicenceTable.deactivationDateTime'),
      accessor: 'deactivationDateTime',
      Cell: (cellProps) =>
        cellProps.value ? (
          moment(cellProps.value).year() === 2099 ? ( // Check for the year 2099
            <FontAwesomeIcon icon={['fal', 'infinity']} />
          ) : (
            moment(cellProps.value).format('MM/YYYY') // Using moment to format date
          )
        ) : (
          '-'
        ),
    },
  ];
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <VirtualTable<UserWithLicence>
        columns={columns}
        data={usersWithLicence}
        id="user-licence-table"
        resizable="absolute"
        rowsAreSelectable
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
        onRow={handleRowClick}
        actionBarButtons={[
          {
            children: t('users:userLicenceTable.activateLicenses'),
            iconProp: ['fal', 'check'],
            onClick: () => {
              showModal('activate'); // Show modal for activation
            },
          },
          {
            children: t('users:userLicenceTable.deactivateLicenses'),
            iconProp: ['fal', 'ban'],
            onClick: () => {
              showModal('deactivate'); // Show modal for deactivation
            },
          },
        ]}
      />
      <Modal
        title={
          modalActionType === 'activate'
            ? t('users:licences.confirmModal.activateTitle')
            : t('users:licences.confirmModal.deactivateTitle')
        }
        visible={isModalVisible}
        onOk={handleModalConfirm}
        onClose={handleModalCancel}
        okText={t('users:licences.confirmModal.okText')}
        cancelText={t('users:licences.confirmModal.cancelText')}
      >
        <p>
          {modalActionType === 'activate'
            ? t('users:licences.confirmModal.activateDescription')
            : t('users:licences.confirmModal.deactivateDescription')}
        </p>
      </Modal>
    </div>
  );
};

export default PrioUserLicenceManagement;
