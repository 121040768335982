import { notification } from 'antd';
import {
  actionChannel,
  call,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import i18n from '../../../i18n';
import {
  MessageAttachment,
  MessageRecipient,
  MessageUpdate,
} from '../../../models/Message';
import { MessageId, ProjectId } from '../../../models/Types';
import {
  SAGA_DELETE_MESSAGES,
  SAGA_STOP_UPDATE_DRAFT_MESSAGE,
  SAGA_UPDATE_DRAFT_MESSAGE,
  UpdateDraftMessageSagaAction,
} from '../actions/sagas';
import {
  apiDeleteAttachment,
  apiFetchAttachments,
  apiSaveAndSendMessage,
  apiUpdateMessageDraft,
  apiUploadInlineImage,
} from '../api';
import equals from 'deep-equal';
import {
  getProject,
  getSpecialMailFolders,
  isLoggedIn,
} from '../../../apps/main/rootReducer';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import {
  deleteLocalMessage,
  updateMessage,
} from '../actions/actionControllers/messageActionController';
import { SpecialMailFolders } from '../actions/types';
import { REMOVE_DRAFT_MESSAGE_ME } from '../actions/me/draftsMe';
import { REMOVE_DRAFT_MESSAGE_PROJECT } from '../actions/projects/drafts';
import { DELETE_LOCAL_MESSAGE_ME } from '../actions/me/messagesMe';
import { DELETE_LOCAL_MESSAGE_PROJECT } from '../actions/projects/messages';
import {
  removeDraftMessage,
  removeDraftMessageWithoutPipelineStop,
} from '../actions/actionControllers/draftsActionController';
import { formatHumanFileSize } from '../../../util';
import { SAGA_REBUILD } from '../../../util/sagas';
import mime from 'mime-types';
import fetchWithRetry from '../../../util/fetchWithRetry';
import * as imageConversion from 'image-conversion';

const parseDraftBodyToAttachment = async (
  messageBody: string,
  attachments: MessageAttachment[],
  messageId: MessageId,
  projectId: ProjectId,
  onReloadAttachments: () => Promise<void>
) => {
  let shouldReload = false;
  let parsedBody = messageBody;
  // eslint-disable-next-line
  var ptrn = /<img([\w\W]+?)>/g;

  let imageTags = messageBody.match(ptrn);
  var inlineAttachments = attachments.filter((attachment) => {
    return attachment.isInline;
  });

  if (imageTags === null && inlineAttachments.length > 0) {
    for (const attachment of inlineAttachments) {
      if (attachment.isInline) {
        if (!shouldReload) {
          shouldReload = true;
        }
        await apiDeleteAttachment(projectId, messageId, attachment.id);
      }
    }
  } else {
    let inlineAttachmentsToDelete = inlineAttachments.filter(
      (attachmentInline) => {
        return (
          imageTags.filter((imgTags) => {
            return imgTags.includes(attachmentInline.contentId);
          }).length === 0
        );
      }
    );
    for (const attachment of inlineAttachmentsToDelete) {
      if (attachment.isInline) {
        if (!shouldReload) {
          shouldReload = true;
        }
        await apiDeleteAttachment(projectId, messageId, attachment.id);
      }
    }
  }

  if (imageTags !== null) {
    for (let index = 0; index < imageTags.length; index++) {
      const imageTag = imageTags[index];
      // eslint-disable-next-line
      let imageBase64Regex = /blob:([\w\W]+?)"/.exec(imageTag);
      if (imageBase64Regex) {
        // eslint-disable-next-line
        let idRegex = /id="([\w\W\/]+?)"/.exec(imageTag);
        if (idRegex?.length > 0) {
          let id = idRegex[1];
          parsedBody = parsedBody.replace(imageBase64Regex[0], `cid:${id}"`);

          //image was deleted --> need to be uploaded again
          const attachment = attachments.find(
            ({ contentId }) => contentId === id
          );
          if (!attachment) {
            if (!shouldReload) {
              shouldReload = true;
            }
            try {
              const blobUrl = imageBase64Regex[0].replace('"', '');
              await fetchWithRetry(blobUrl)
                .then((res) => res.blob())
                .then(async (blob) => {
                  const fileToUpload = new File(
                    [blob],
                    'inline-img-' + id + '.' + mime.extension(blob.type)
                  );
                  let _file = fileToUpload;
                  if (fileToUpload.size > 3000) {
                    let res = await imageConversion.compressAccurately(
                      fileToUpload,
                      700
                    );
                    _file = new File([res], fileToUpload.name);
                  }
                  await apiUploadInlineImage(projectId, messageId, _file, id);
                });
            } catch (error) {
              console.error(
                'Error in parseDraftBodyToAttachment - upload',
                error
              );
            }
          }
        } else {
          console.error('CANNOT GET ID', idRegex, imageBase64Regex);
        }
      }
    }
  }

  if (shouldReload) {
    await onReloadAttachments();
  }
  return parsedBody;
};

function* handleUpdateDraft(action: UpdateDraftMessageSagaAction) {
  const {
    projectId,
    messageId,
    update,
    attachments,
    setMessageAttachments,
    updateAndSend,
    then,
  } = action;
  try {
    const onReloadAttachments = async () => {
      const { data } = await apiFetchAttachments(projectId, messageId);
      if (Array.isArray(data) && !equals(data, attachments)) {
        setMessageAttachments(data);
      }
    };
    const project = yield select((state) => getProject(state, projectId));

    const projectMail: MessageRecipient =
      !project || projectId === 'me'
        ? null
        : {
            emailAddress: {
              name: project.name ?? project.shortName ?? '',
              address: `${project.eMailPrefix}@${project.eMailSuffix}`,
            },
          };

    let parsedBody: string = null;
    if (update.body) {
      parsedBody = yield call(
        parseDraftBodyToAttachment,
        update.body.content,
        attachments,
        messageId,
        projectId,
        onReloadAttachments
      );
    }

    const message: MessageUpdate = {
      ...update,
      ...(parsedBody
        ? {
            body: {
              content: parsedBody,
              contentType: 1,
            },
          }
        : {}),
      ...(update.from ? { from: update.from } : {}),
      ...(!update.from
        ? {}
        : !equals(
            projectMail?.emailAddress?.address,
            update.from?.emailAddress.address
          )
        ? {
            replyTo: [
              {
                emailAddress: {
                  address: projectMail?.emailAddress?.address,
                },
              },
            ],
          }
        : {}),
    };

    let success = false;

    if (updateAndSend) {
      try {
        yield put(updateMessage(projectId, messageId, message));
        yield put(removeDraftMessageWithoutPipelineStop(projectId, messageId));

        const { result } = yield call(
          apiSaveAndSendMessage,
          projectId,
          messageId,
          message,
          true
        );

        if (result.status >= 200 && result.status < 300) {
          success = true;
          const specialMailFolders: SpecialMailFolders = yield select((state) =>
            getSpecialMailFolders(state, projectId)
          );
          if (specialMailFolders?.draftFolder?.id) {
            yield put(
              deleteLocalMessage(
                projectId,
                specialMailFolders?.draftFolder?.id,
                messageId
              )
            );
          } else {
            yield put(removeDraftMessage(projectId, messageId));
          }
        } else {
          console.error(
            'Error in message saga updating - failed to update before send'
          );
          notification.warning({
            message: i18n.t('common:error'),
            description: i18n.t(
              'mail:errorMessages.messages.messageSendingError',
              { subject: message.subject }
            ),
            duration: 0,
          });
        }
      } catch (error) {
        console.error(
          'Error in message saga sending - Data:',
          `Original Message:`,
          error,
          `projectId: ${projectId},`,
          `messageId: ${messageId},`,
          `attachments: ${formatHumanFileSize(
            attachments.reduce((sum, { size }) => sum + (size ?? 0), 0)
          )} ${attachments.length} ${attachments}`
        );
        notification.warning({
          message: i18n.t('common:error'),
          description: i18n.t(
            'mail:errorMessages.messages.messageSendingError',
            { subject: message.subject }
          ),
          duration: 0,
        });
      }
    } else {
      const { data } = yield call(
        apiUpdateMessageDraft,
        projectId,
        messageId,
        message,
        updateAndSend
      );

      if (data) {
        yield put(updateMessage(projectId, messageId, message));
        success = true;
      } else {
        notification.open({
          message: i18n.t('common:error'),
          description: i18n.t('mail:errorMessages.messages.updateDraftError'),
        });
      }
    }
    if (then) {
      then(success);
    }
  } catch (error) {
    console.error('Error in message saga updating - Original Error:', error);
    console.error(
      'Error in message saga updating - Data:',
      `projectId: ${projectId},`,
      `messageId: ${messageId},`,
      `attachments: ${formatHumanFileSize(
        attachments.reduce((sum, { size }) => sum + (size ?? 0), 0)
      )} ${attachments.length} ${attachments}`
    );
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.messages.updateDraftError'),
    });
  }
}

function* takeLatestAction(channel, messageId: MessageId) {
  let action: UpdateDraftMessageSagaAction = null;
  while (action?.messageId !== messageId) {
    action = yield take(channel);
  }
  return action;
}

function* actionPipeline(initialAction: UpdateDraftMessageSagaAction) {
  let lastAction: UpdateDraftMessageSagaAction = null;
  try {
    const channel = yield actionChannel(SAGA_UPDATE_DRAFT_MESSAGE);

    yield put({ type: SAGA_UPDATE_DRAFT_MESSAGE, ...initialAction });

    while (true) {
      let action: UpdateDraftMessageSagaAction = yield call(
        takeLatestAction,
        channel,
        initialAction.messageId
      );
      lastAction = action;

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(1000),
          latestAction: call(
            takeLatestAction,
            channel,
            initialAction.messageId
          ),
        });
        if (debounced) {
          yield call(handleUpdateDraft, action);
          lastAction = null;
          break;
        }

        if (latestAction) {
          const newAction: UpdateDraftMessageSagaAction = {
            ...latestAction,
            update: {
              ...action.update,
              ...(latestAction as UpdateDraftMessageSagaAction).update,
            },
            updateAndSend: action.updateAndSend || latestAction.updateAndSend,
          };
          action = newAction;
          lastAction = action;
        }
      }
    }
  } catch (error) {
    console.error(
      'Error in draftMessageTask - actionPipeline',
      error,
      lastAction
    );
  } finally {
    if (yield cancelled() && lastAction !== null) {
      yield call(handleUpdateDraft, lastAction);
      lastAction = null;
    }
  }
}

function* stopPipeline(messageId: MessageId) {
  const channel = yield actionChannel([
    DELETE_LOCAL_MESSAGE_ME,
    DELETE_LOCAL_MESSAGE_PROJECT,
    REMOVE_DRAFT_MESSAGE_ME,
    REMOVE_DRAFT_MESSAGE_PROJECT,
    SAGA_STOP_UPDATE_DRAFT_MESSAGE,
    SAGA_DELETE_MESSAGES,
  ]);

  const isSameMessageId = (action) => {
    switch (action.type) {
      case SAGA_STOP_UPDATE_DRAFT_MESSAGE:
      case DELETE_LOCAL_MESSAGE_ME:
      case DELETE_LOCAL_MESSAGE_PROJECT: {
        return messageId === action.meta.messageId;
      }
      case REMOVE_DRAFT_MESSAGE_ME:
      case REMOVE_DRAFT_MESSAGE_PROJECT: {
        return messageId === action.messageId;
      }
      case SAGA_DELETE_MESSAGES: {
        return action.messages.some(({ id }) => id === messageId);
      }
      default: {
        return false;
      }
    }
  };

  while (true) {
    let action = yield take(channel);
    if (isSameMessageId(action)) {
      if (action.type !== SAGA_STOP_UPDATE_DRAFT_MESSAGE) {
        yield delay(1000);
      }
      break;
    }
  }
  return true;
}

function* draftMessageTask(
  action: UpdateDraftMessageSagaAction,
  removeMessageId: (messageId: MessageId) => void
) {
  let _stop = null;
  try {
    const { stop } = yield race({
      actionPipeline: call(actionPipeline, action),
      stop: call(stopPipeline, action.messageId),
    });
    if (stop) {
      _stop = stop;
    }
  } catch (error) {
    console.error('Error in draftMessageTask', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.messages.updateDraftError'),
    });
  } finally {
    if (_stop) {
      removeMessageId(action.messageId);
    }
  }
}

function* draftMessageManager() {
  let draftMessageIds: MessageId[] = [];
  const channel = yield actionChannel(SAGA_UPDATE_DRAFT_MESSAGE);

  function removeMessageId(messageId: MessageId) {
    draftMessageIds = draftMessageIds.filter((id) => id !== messageId);
  }

  while (true) {
    let action: UpdateDraftMessageSagaAction = yield take(channel);
    if (!draftMessageIds.includes(action.messageId)) {
      draftMessageIds = [...draftMessageIds, action.messageId];
      yield fork(draftMessageTask, action, removeMessageId);
    }
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(draftMessageManager), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in mainTask', error);
    yield mainTask();
  }
}

export default function* watchUpdateDraftMessage() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
