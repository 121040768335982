import { Reducer, combineReducers } from 'redux';
import {
  AbsenceOverview,
  AbsenceProposal,
  AbsenceProposalRequest,
  UpdateAbsenceProposalRequest,
} from '../../../models/AbsenceProposal';
import { AbsenceProposalId, DateTimeString } from '../../../models/Types';
import { ReduxAction } from '../../../models/Redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';
import {
  CHANGE_ABSENCE_PROPOSAL_COMMIT,
  CREATE_ABSENCE_PROPOSAL_COMMIT,
  CREATE_ABSENCE_PROPOSAL_REQUEST,
  CREATE_ABSENCE_PROPOSAL_ROLLBACK,
  DELETE_ABSENCE_PROPOSAL_REQUEST,
  DELETE_ABSENCE_PROPOSAL_ROLLBACK,
  FETCH_ABSENCES_ME_COMMIT,
  FETCH_ABSENCES_ME_REQUEST,
  FETCH_ABSENCES_ME_ROLLBACK,
  REVOKE_ABSENCE_PROPOSAL_ME_REQUEST,
  REVOKE_ABSENCE_PROPOSAL_ME_ROLLBACK,
  UPDATE_LOCAL_ABSENCE_PROPOSAL,
} from '../actions';
import {
  mapAbsencesStateToRevokeState,
  mapRevokeAbsencesStateToAbsenceState,
} from '../utils';
import { distinct } from '../../../util';

export interface AbsencesMeState {
  byId: AbsencesById;
  ids: AbsenceProposalId[];
  meta: MetaState;
  overview: AbsenceOverviewState;
}

export const initialState: AbsencesMeState = {
  byId: {},
  ids: [],
  meta: {
    isFetching: false,
    hasError: false,
  },
  overview: {
    vacationDay: 0,
    remainingVacationDays: 0,
    takenVacationDays: 0,
    requestedVacationDays: 0,
    acceptedVacationDays: 0,
    remainingDaysPreviousYear: 0,
    previousYearDeadline: null,
  },
};

export interface AbsencesById {
  [absenceId: AbsenceProposalId]: AbsenceProposal;
}

type ActionMeta = Partial<{
  from: DateTimeString;
  to: DateTimeString;
  absenceProposalId: AbsenceProposalId;
  updateAbsenceProposalRequest: UpdateAbsenceProposalRequest;
  absenceProposalRequest: AbsenceProposalRequest;
  rollbackAbsenceProposal: AbsenceProposal;
  isMe: boolean;
}>;

const byId: Reducer<
  AbsencesById,
  ReduxAction<ActionMeta, AbsenceOverview & AbsenceProposal>
> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_ABSENCES_ME_COMMIT: {
      const {
        meta: { from },
        payload: { absenceProposals },
      } = action;
      const initialValue: AbsencesById = from ? state : {};
      return absenceProposals.reduce(
        (map, proposal) => ({
          ...map,
          [proposal.absenceProposalId.toLowerCase()]: {
            ...proposal,
            applicantId: proposal.applicantId.toLowerCase(),
            principalId: proposal.principalId.toLowerCase(),
            substituteId: proposal.substituteId.toLowerCase(),
            ...(proposal.notifyContactIds
              ? {
                  notifyContactIds: proposal.notifyContactIds.map((id) =>
                    id.toLowerCase()
                  ),
                }
              : {}),
          } as AbsenceProposal,
        }),
        initialValue
      );
    }
    case CREATE_ABSENCE_PROPOSAL_REQUEST: {
      const {
        meta: { absenceProposalRequest: proposal },
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;

      return {
        ...state,
        [proposal.absenceProposalId.toLowerCase()]: {
          ...proposal,
          absenceState: 'requested',
        },
      } as AbsencesById;
    }
    case CREATE_ABSENCE_PROPOSAL_COMMIT: {
      const {
        meta: { absenceProposalRequest },
        payload: proposal,
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;
      const {
        [absenceProposalRequest.absenceProposalId]: temporaryProposal,
        ...rest
      } = state;

      if (temporaryProposal) {
        return {
          ...rest,
          [proposal.absenceProposalId.toLowerCase()]: {
            ...proposal,
            applicantId: proposal.applicantId.toLowerCase(),
            principalId: proposal.principalId.toLowerCase(),
            substituteId: proposal.substituteId.toLowerCase(),
            ...(proposal.notifyContactIds
              ? {
                  notifyContactIds: proposal.notifyContactIds.map((id) =>
                    id.toLowerCase()
                  ),
                }
              : {}),
          },
        } as AbsencesById;
      }
      return state;
    }
    case CREATE_ABSENCE_PROPOSAL_ROLLBACK: {
      const {
        meta: { absenceProposalRequest },
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;
      const {
        [absenceProposalRequest.absenceProposalId]: temporaryProposal,
        ...rest
      } = state;
      if (temporaryProposal) {
        return rest as AbsencesById;
      }
      return state;
    }
    case DELETE_ABSENCE_PROPOSAL_REQUEST: {
      const {
        meta: { absenceProposalId },
      } = action;
      const { [absenceProposalId]: absenceProposal, ...rest } = state;
      if (absenceProposal) {
        return rest;
      }
      return state;
    }
    case DELETE_ABSENCE_PROPOSAL_ROLLBACK: {
      const {
        meta: { absenceProposalId, rollbackAbsenceProposal },
      } = action;
      return {
        ...state,
        [absenceProposalId]: rollbackAbsenceProposal,
      };
    }
    case REVOKE_ABSENCE_PROPOSAL_ME_REQUEST: {
      const {
        meta: { absenceProposalId },
      } = action;
      if (state[absenceProposalId]) {
        return {
          ...state,
          [absenceProposalId]: {
            ...state[absenceProposalId],
            absenceState: mapAbsencesStateToRevokeState(
              state[absenceProposalId].absenceState
            ),
          },
        };
      }
      return state;
    }
    case REVOKE_ABSENCE_PROPOSAL_ME_ROLLBACK: {
      const {
        meta: { absenceProposalId },
      } = action;
      if (state[absenceProposalId]) {
        return {
          ...state,
          [absenceProposalId]: {
            ...state[absenceProposalId],
            absenceState: mapRevokeAbsencesStateToAbsenceState(
              state[absenceProposalId].absenceState
            ),
          },
        };
      }
      return state;
    }

    case UPDATE_LOCAL_ABSENCE_PROPOSAL: {
      const { payload: proposal } = action as ReduxAction<
        ActionMeta,
        AbsenceProposal
      >;
      return {
        ...state,
        [proposal.absenceProposalId.toLowerCase()]: {
          ...proposal,
          applicantId: proposal.applicantId.toLowerCase(),
          principalId: proposal.principalId.toLowerCase(),
          substituteId: proposal.substituteId.toLowerCase(),
          ...(proposal.notifyContactIds
            ? {
                notifyContactIds: proposal.notifyContactIds.map((id) =>
                  id.toLowerCase()
                ),
              }
            : {}),
        },
      };
    }

    case CHANGE_ABSENCE_PROPOSAL_COMMIT: {
      const {
        payload: proposal,
        meta: { isMe },
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;
      if (!isMe) {
        return state;
      }
      return {
        ...state,
        [proposal.absenceProposalId.toLowerCase()]: {
          ...proposal,
          applicantId: proposal.applicantId.toLowerCase(),
          principalId: proposal.principalId.toLowerCase(),
          substituteId: proposal.substituteId.toLowerCase(),
          ...(proposal.notifyContactIds
            ? {
                notifyContactIds: proposal.notifyContactIds.map((id) =>
                  id.toLowerCase()
                ),
              }
            : {}),
        },
      };
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default: {
      return state;
    }
  }
};

const ids: Reducer<
  AbsenceProposalId[],
  ReduxAction<ActionMeta, AbsenceOverview & AbsenceProposal>
> = (state = initialState.ids, action) => {
  switch (action.type) {
    case FETCH_ABSENCES_ME_COMMIT: {
      const {
        meta: { from },
        payload: { absenceProposals },
      } = action;
      return distinct([
        ...(from ? state : []),
        ...absenceProposals.map(({ absenceProposalId }) =>
          absenceProposalId.toLowerCase()
        ),
      ]);
    }
    case CREATE_ABSENCE_PROPOSAL_REQUEST: {
      const {
        meta: { absenceProposalRequest },
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;
      return [...state, absenceProposalRequest.absenceProposalId];
    }
    case CREATE_ABSENCE_PROPOSAL_COMMIT: {
      const {
        meta: { absenceProposalRequest },
        payload: { absenceProposalId },
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;
      return [
        ...state.filter(
          (id) => id !== absenceProposalRequest.absenceProposalId
        ),
        absenceProposalId.toLowerCase(),
      ];
    }
    case CREATE_ABSENCE_PROPOSAL_ROLLBACK: {
      const {
        meta: { absenceProposalRequest },
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;
      return state.filter(
        (id) => id !== absenceProposalRequest.absenceProposalId
      );
    }
    case DELETE_ABSENCE_PROPOSAL_REQUEST: {
      const {
        meta: { absenceProposalId },
      } = action;
      return state.filter((id) => id !== absenceProposalId);
    }
    case DELETE_ABSENCE_PROPOSAL_ROLLBACK: {
      const {
        meta: { absenceProposalId },
      } = action;
      return [...state, absenceProposalId];
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default: {
      return state;
    }
  }
};

export type AbsenceOverviewState = {
  [P in Exclude<keyof AbsenceOverview, 'absenceProposals'>]: AbsenceOverview[P];
};

const overview: Reducer<
  AbsenceOverviewState,
  ReduxAction<ActionMeta, AbsenceOverview & AbsenceProposal>
> = (state = initialState.overview, action) => {
  switch (action.type) {
    case FETCH_ABSENCES_ME_COMMIT: {
      const {
        meta: { from },
        payload: { absenceProposals: _, ...rest },
      } = action;
      if (from) {
        return state;
      }
      return rest;
    }
    case CREATE_ABSENCE_PROPOSAL_COMMIT: {
      const {
        payload: { absentWorkdays },
      } = action as ReduxAction<ActionMeta, AbsenceProposal>;
      const diff = absentWorkdays;
      return {
        ...state,
        requestedVacationDays: state.requestedVacationDays + diff,
        remainingVacationDays: state.remainingVacationDays - diff,
      };
    }
    case DELETE_ABSENCE_PROPOSAL_REQUEST: {
      const {
        meta: { rollbackAbsenceProposal },
      } = action;
      if (rollbackAbsenceProposal.absenceState === 'requested') {
        const diff = rollbackAbsenceProposal.absentWorkdays;
        return {
          ...state,
          requestedVacationDays: state.requestedVacationDays - diff,
          remainingVacationDays: state.remainingVacationDays + diff,
        };
      }
      return state;
    }
    case DELETE_ABSENCE_PROPOSAL_ROLLBACK: {
      const {
        meta: { rollbackAbsenceProposal },
      } = action;
      if (rollbackAbsenceProposal.absenceState === 'requested') {
        const diff = rollbackAbsenceProposal.absentWorkdays;
        return {
          ...state,
          requestedVacationDays: state.requestedVacationDays + diff,
          remainingVacationDays: state.remainingVacationDays - diff,
        };
      }
      return state;
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.overview;
    }
    default: {
      return state;
    }
  }
};

interface MetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<MetaState, ReduxAction> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_ABSENCES_ME_REQUEST: {
      return {
        isFetching: true,
        hasError: false,
      };
    }
    case FETCH_ABSENCES_ME_COMMIT: {
      return {
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_ABSENCES_ME_ROLLBACK: {
      return {
        isFetching: false,
        hasError: true,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<AbsencesMeState>({
  byId,
  ids,
  meta,
  overview,
});

export const getAbsenceByIdState: (state: AbsencesMeState) => AbsencesById = ({
  byId,
}) => byId;

export const getAbsenceIds: (state: AbsencesMeState) => AbsenceProposalId[] = ({
  ids,
}) => ids;

export const getAbsenceProposalsMe: (
  state: AbsencesMeState
) => AbsenceProposal[] = ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((proposal) => !!proposal);

export const getAbsenceProposalMe: (
  state: AbsencesMeState,
  absenceProposalId: AbsenceProposalId
) => AbsenceProposal = ({ byId }, absenceProposalId) => byId[absenceProposalId];

export const getAbsenceOverview: (
  state: AbsencesMeState
) => AbsenceOverviewState = ({ overview }) => overview;

export const getAbsencesMeIsFetching: (state: AbsencesMeState) => boolean = ({
  meta,
}) => meta.isFetching;
