import React from 'react';
import { useSelector } from 'react-redux';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import ProjectProfileMenu from './ProjectProfileMenu';
import ProjectMenu from './ProjectMenu';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
    transition: 'padding 0.2s',
  },
}));

interface ProjectMainMenuCollapsedProps {
  activeProjectId: string;
  selectedSubModule: string;
  pathPrefix: string;
  activeTab: string;
}

export const ProjectMainMenuCollapsed: React.FC<
  ProjectMainMenuCollapsedProps
> = (props) => {
  const { activeProjectId, selectedSubModule, pathPrefix, activeTab } = props;
  const classes = useStyles();

  //#region ------------------------------ States / Attributes / Selectors
  const activeProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, activeProjectId)
  );
  //#endregion

  if (activeProjectId === 'me') {
    return (
      <ProjectProfileMenu
        collapsed
        urlPrefix={`${pathPrefix}me/`}
        selectedList={selectedSubModule}
        className={classes.menu}
        hideTrainings
        hidePersonalFile
      />
    );
  }

  if (!activeProject || activeProject?.isTemporary) {
    return null;
  }

  return (
    <ProjectMenu
      projectId={activeProject.projectId}
      selectedProjectId={activeProjectId}
      selectedSubModule={selectedSubModule}
      masterPlanId={activeProject.masterPlanId}
      groupId={activeProject.groupId}
      emailSuffix={activeProject.eMailSuffix}
      emailPrefix={activeProject.eMailPrefix}
      pathPrefix={pathPrefix}
      activeTab={activeTab}
      collapsed
    />
  );
};

export default ProjectMainMenuCollapsed;
