import { combineReducers } from 'redux';
import { DateTimeString, TimeKeepingDayId } from '../../../models/Types';
import {
  MonthlyClose,
  MonthlyCloseAbsence,
  TimeKeepingDay,
} from '../../../models/TimeKeeping';
import timeKeepingDaysMe, * as fromTimeKeepingDaysMe from './timeKeepingDaysMe';
import monthlyCloseMe, * as fromMonthlyCloseMe from './monthlyCloseMe';

export interface TimeKeepingState {
  timeKeepingDaysMe: fromTimeKeepingDaysMe.TimeKeepingDaysMeReducerState;
  monthlyCloseMe: fromMonthlyCloseMe.MonthlyCloseMeReducerState;
}

export default combineReducers<TimeKeepingState>({
  timeKeepingDaysMe,
  monthlyCloseMe,
});

export const getTimeKeepingDayByIdState: (
  state: TimeKeepingState
) => fromTimeKeepingDaysMe.TimeKeepingByIdState = ({ timeKeepingDaysMe }) =>
  fromTimeKeepingDaysMe.getTimeKeepingByIdState(timeKeepingDaysMe);

export const getTimeKeepingDayIdsState: (
  state: TimeKeepingState
) => TimeKeepingDayId[] = ({ timeKeepingDaysMe }) =>
  fromTimeKeepingDaysMe.getTimeKeepingIdsState(timeKeepingDaysMe);

export const getAllTimeKeepingDaysMe: (
  state: TimeKeepingState
) => TimeKeepingDay[] = ({ timeKeepingDaysMe }) =>
  fromTimeKeepingDaysMe.getAllTimeKeepingDaysMe(timeKeepingDaysMe);

export const getTimeKeepingDayMe: (
  state: TimeKeepingState,
  id: TimeKeepingDayId
) => TimeKeepingDay = ({ timeKeepingDaysMe }, id) =>
  fromTimeKeepingDaysMe.getTimeKeepingDayMe(timeKeepingDaysMe, id);

export const getMonthlyCloseMeTimeKeepingDays: (
  state: TimeKeepingState,
  monthDateTime: DateTimeString
) => TimeKeepingDay[] = (
  { monthlyCloseMe, timeKeepingDaysMe },
  monthDateTime
) => {
  const byId = fromTimeKeepingDaysMe.getTimeKeepingByIdState(timeKeepingDaysMe);
  return fromMonthlyCloseMe
    .getMonthlyCloseMeTimeKeepingDayIds(monthlyCloseMe, monthDateTime)
    .reduce((currentArray, id) => {
      const timeKeepingDay = byId[id];
      if (timeKeepingDay) {
        currentArray.push(timeKeepingDay);
      }
      return currentArray;
    }, [] as TimeKeepingDay[]);
};

export const getMonthlyCloseMeTimeKeepingDayIds: (
  state: TimeKeepingState,
  monthDateTime: DateTimeString
) => TimeKeepingDayId[] = ({ monthlyCloseMe }, monthDateTime) =>
  fromMonthlyCloseMe.getMonthlyCloseMeTimeKeepingDayIds(
    monthlyCloseMe,
    monthDateTime
  );

export const getMonthlyCloseAbsences: (
  state: TimeKeepingState,
  monthDateTime: DateTimeString
) => MonthlyCloseAbsence[] = ({ monthlyCloseMe }, monthDateTime) =>
  fromMonthlyCloseMe.getMonthlyCloseAbsences(monthlyCloseMe, monthDateTime);

export const getMonthlyCloseMeByMonth: (
  state: TimeKeepingState,
  monthDateTime: DateTimeString
) => MonthlyClose = ({ monthlyCloseMe, timeKeepingDaysMe }, monthDateTime) => {
  const monthlyClose = fromMonthlyCloseMe.getMonthlyCloseMeByMonth(
    monthlyCloseMe,
    monthDateTime
  );
  if (monthlyClose) {
    const timeKeepingDays = monthlyClose.timeKeepingDays.map((id) =>
      fromTimeKeepingDaysMe.getTimeKeepingDayMe(timeKeepingDaysMe, id)
    );
    return {
      ...monthlyClose,
      timeKeepingDays,
    };
  }
  return undefined;
};

export const getMonthlyClosesMeInRange: (
  state: TimeKeepingState,
  range: { from: DateTimeString; to: DateTimeString }
) => MonthlyClose[] = ({ monthlyCloseMe, timeKeepingDaysMe }, range) => {
  const monthlyCloses = fromMonthlyCloseMe.getMonthlyClosesMeInRange(
    monthlyCloseMe,
    range
  );
  return monthlyCloses.map((monthlyClose) => {
    const timeKeepingDays = monthlyClose.timeKeepingDays.map((id) =>
      fromTimeKeepingDaysMe.getTimeKeepingDayMe(timeKeepingDaysMe, id)
    );
    return {
      ...monthlyClose,
      timeKeepingDays,
    };
  });
};

export const getMonthlyCloseMeIsFetching: (
  state: TimeKeepingState
) => boolean = ({ monthlyCloseMe }) =>
  fromMonthlyCloseMe.getMonthlyCloseMeIsFetching(monthlyCloseMe);
