import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  CreateDriveFavorite,
  DriveFavorite,
  DrivePlatform,
  UpdateDriveFavorite,
} from '../../../models/DriveFavorite';

import { buildQueryString } from '../../../util/api';
export const FETCH_DRIVE_FAVORITE_REQUEST =
  PRIO + 'FETCH_DRIVE_FAVORITE_REQUEST';
export const FETCH_DRIVE_FAVORITE_COMMIT = PRIO + 'FETCH_DRIVE_FAVORITE_COMMIT';
export const FETCH_DRIVE_FAVORITE_ROLLBACK =
  PRIO + 'FETCH_DRIVE_FAVORITE_ROLLBACK';

export const fetchDriveFavorite = (driveFavoriteId: string) => ({
  type: FETCH_DRIVE_FAVORITE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveFavorite/${driveFavoriteId}`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_DRIVE_FAVORITE_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_DRIVE_FAVORITE_ROLLBACK,
        snackbarErrorMessage: {
          label: 'documents:driveFavorites.errorMessages.fetchDriveFavorite',
          timeout: 6,
        },
      },
    },
  },
});

export const FETCH_DRIVE_FAVORITES_REQUEST =
  PRIO + 'FETCH_DRIVE_FAVORITES_REQUEST';
export const FETCH_DRIVE_FAVORITES_COMMIT =
  PRIO + 'FETCH_DRIVE_FAVORITES_COMMIT';
export const FETCH_DRIVE_FAVORITES_ROLLBACK =
  PRIO + 'FETCH_DRIVE_FAVORITES_ROLLBACK';

export const fetchDriveFavorites = (
  projectId?: string,
  drivePlatform?: DrivePlatform
) => ({
  type: FETCH_DRIVE_FAVORITES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveFavorite${buildQueryString({
          projectId: projectId,
          drivePlatform: drivePlatform,
        })}`,

        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_DRIVE_FAVORITES_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_DRIVE_FAVORITES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'documents:driveFavorites.errorMessages.fetchDriveFavorites',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_DRIVE_FAVORITE_REQUEST =
  PRIO + 'CREATE_DRIVE_FAVORITE_REQUEST';
export const CREATE_DRIVE_FAVORITE_COMMIT =
  PRIO + 'CREATE_DRIVE_FAVORITE_COMMIT';
export const CREATE_DRIVE_FAVORITE_ROLLBACK =
  PRIO + 'CREATE_DRIVE_FAVORITE_ROLLBACK';

export const createDriveFavorite = (
  createDriveFavoriteRequest: CreateDriveFavorite
) => ({
  type: CREATE_DRIVE_FAVORITE_REQUEST,
  requiresAuth: true,
  payload: { ...createDriveFavoriteRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveFavorite`,
        json: createDriveFavoriteRequest,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_DRIVE_FAVORITE_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_DRIVE_FAVORITE_ROLLBACK,
        meta: { driveFavorite: createDriveFavoriteRequest },
        snackbarErrorMessage: {
          label: 'documents:driveFavorites.errorMessages.createDriveFavorite',
          timeout: 6,
        },
      },
    },
  },
});

export const UPDATE_DRIVE_FAVORITE_REQUEST =
  PRIO + 'UPDATE_DRIVE_FAVORITE_REQUEST';
export const UPDATE_DRIVE_FAVORITE_COMMIT =
  PRIO + 'UPDATE_DRIVE_FAVORITE_COMMIT';
export const UPDATE_DRIVE_FAVORITE_ROLLBACK =
  PRIO + 'UPDATE_DRIVE_FAVORITE_ROLLBACK';

export const updateDriveFavorite = (
  updateDriveFavoriteRequest: UpdateDriveFavorite,
  driveFavoriteToUpdate: DriveFavorite
) => ({
  type: UPDATE_DRIVE_FAVORITE_REQUEST,
  payload: updateDriveFavoriteRequest,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveFavorite/${driveFavoriteToUpdate.driveFavoriteId}`,
        json: updateDriveFavoriteRequest,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_DRIVE_FAVORITE_COMMIT,
        meta: { driveFavorite: driveFavoriteToUpdate },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_DRIVE_FAVORITE_ROLLBACK,
        meta: { driveFavorite: driveFavoriteToUpdate },
        snackbarErrorMessage: {
          label: 'documents:driveFavorites.errorMessages.updateDriveFavorite',
          timeout: 6,
        },
      },
    },
    driveFavorite: driveFavoriteToUpdate,
  },
});

export const DELETE_DRIVE_FAVORITE_REQUEST =
  PRIO + 'DELETE_DRIVE_FAVORITE_REQUEST';
export const DELETE_DRIVE_FAVORITE_COMMIT =
  PRIO + 'DELETE_DRIVE_FAVORITE_COMMIT';
export const DELETE_DRIVE_FAVORITE_ROLLBACK =
  PRIO + 'DELETE_DRIVE_FAVORITE_ROLLBACK';

export const deleteDriveFavorite = (driveFavorite: DriveFavorite) => ({
  type: DELETE_DRIVE_FAVORITE_REQUEST,
  requiresAuth: true,
  payload: driveFavorite,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveFavorite/${driveFavorite.driveFavoriteId}`,
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_DRIVE_FAVORITE_COMMIT,
        meta: { driveFavorite },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_DRIVE_FAVORITE_ROLLBACK,
        meta: { driveFavorite },
        snackbarErrorMessage: {
          label: 'documents:driveFavorites.errorMessages.deleteDriveFavorite',
          timeout: 6,
        },
      },
    },
  },
});

export const DELETE_DRIVE_FAVORITES_REQUEST =
  PRIO + 'DELETE_DRIVE_FAVORITES_REQUEST';
export const DELETE_DRIVE_FAVORITES_COMMIT =
  PRIO + 'DELETE_DRIVE_FAVORITES_COMMIT';
export const DELETE_DRIVE_FAVORITES_ROLLBACK =
  PRIO + 'DELETE_DRIVE_FAVORITES_ROLLBACK';

export const deleteDriveFavorites = (driveFavorites: DriveFavorite[]) => ({
  type: DELETE_DRIVE_FAVORITES_REQUEST,
  requiresAuth: true,
  payload: driveFavorites,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/document/DriveFavorite`,
        json: driveFavorites.map((x) => x.driveFavoriteId),
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_DRIVE_FAVORITES_COMMIT,
        meta: { driveFavorites },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_DRIVE_FAVORITES_ROLLBACK,
        meta: { driveFavorites },
        snackbarErrorMessage: {
          label: 'documents:driveFavorites.errorMessages.deleteDriveFavorites',
          timeout: 6,
        },
      },
    },
  },
});
