import React from 'react';
import { ProjectCard } from './ProjectCard';
import { Project } from '../../../models/Project';

interface ProjectDetailsProps {
  project: Project;
}

export const ProjectDetails: React.FC<ProjectDetailsProps> = ({ project }) => {
  return <ProjectCard project={project} />;
};

export default ProjectDetails;
