import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { MessageAttachment } from '../../../models/Message';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, FormInstance, Input, Row, Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { formatHumanFileSize } from '../../../util';
import { rowGutter } from '../../../util/forms';
import { colorForMimeType, iconForMimeType } from '../../documents/util';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
  },
  data: {
    width: '100%',
  },
  inputField: {
    flex: 1,
  },
  label: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.small,
  },
  prefixLabel: {
    whiteSpace: 'nowrap',
  },
  labelData: {
    fontSize: theme.old.typography.fontSize.small,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

interface MessageAttachmentDrawerItemProps {
  attachment: MessageAttachment;
  useDocumentsPrefix: boolean;
  prefix: string;
  form: FormInstance<any>;
}

export const MessageAttachmentDrawerItem: React.FC<
  MessageAttachmentDrawerItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { attachment, useDocumentsPrefix, prefix, form } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const fileEnding = useMemo(() => {
    const splittedNameArray = attachment.name.split('.');
    if (splittedNameArray.length > 1) {
      return splittedNameArray.splice(-1)[0];
    }
    return '';
  }, [attachment]);
  const type = fileEnding.length === 3 ? fileEnding?.toUpperCase() : null;

  const fileName = useMemo(() => {
    const splittedNameArray = attachment.name.split('.');
    if (splittedNameArray.length > 1) {
      return splittedNameArray.slice(0, -1).join('.');
    }
    return splittedNameArray.join('.');
  }, [attachment]);

  const attachmentId = useMemo(() => attachment.id, [attachment]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    form.setFieldsValue({
      [attachmentId]: `${
        useDocumentsPrefix ? prefix : ''
      }${value}.${fileEnding}`,
    });
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.setFieldsValue({
      [attachmentId]: `${useDocumentsPrefix ? prefix : ''}${attachment.name}`,
    });
  }, [form, attachmentId, useDocumentsPrefix, prefix, attachment.name]);
  //#endregion

  return (
    <Flex.Column
      className={classes.root}
      childrenGap={theme.old.spacing.unit(1)}
    >
      <Flex.Row alignItems="baseline" childrenGap={theme.old.spacing.unit(1)}>
        <FontAwesomeIcon
          icon={['fas', iconForMimeType(attachment.contentType)]}
          color={colorForMimeType(attachment.contentType)}
        />
        {useDocumentsPrefix && (
          <Typography.Text className={classes.prefixLabel}>
            {prefix}
          </Typography.Text>
        )}
        <Input
          defaultValue={fileName}
          onChange={handleOnNameChange}
          className={classes.inputField}
        />
        <Typography.Text>{`${
          fileEnding ? `.${fileEnding}` : ''
        }`}</Typography.Text>
      </Flex.Row>
      <Row gutter={rowGutter} className={classes.data}>
        <Col span={2}>
          <Typography.Text className={classes.label}>
            {t('mail:attachmentDrawerItem.labels.original')}
          </Typography.Text>
        </Col>
        <Col span={22}>
          <Typography.Text className={classes.labelData}>
            {attachment.name}
          </Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col span={2}>
          <Typography.Text className={classes.label}>
            {t('mail:attachmentDrawerItem.labels.type')}
          </Typography.Text>
        </Col>
        <Col span={10} className={classes.labelData}>
          <Typography.Text>
            {type ?? t('mail:attachmentDrawerItem.unknownType')}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text className={classes.label}>
            {t('mail:attachmentDrawerItem.labels.size')}
          </Typography.Text>
        </Col>
        <Col span={10} className={classes.labelData}>
          <Typography.Text>
            {formatHumanFileSize(attachment.size)}
          </Typography.Text>
        </Col>
      </Row>
    </Flex.Column>
  );
};

export default MessageAttachmentDrawerItem;
