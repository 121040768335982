import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BasisPicker,
  BasisPickerSelectOptions,
} from '../../../../components/BasisPicker';
import { makePrioStyles } from '../../../../theme/utils';
import { FormInstance } from 'antd';
import { TaskImportance, TodoTaskFormModel } from '../../../../models/Todo';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface ImportancePickerProps {
  form?: FormInstance<TodoTaskFormModel>;
  disabled?: boolean;
}

export const ImportancePicker: React.FC<ImportancePickerProps> = (props) => {
  const { form, disabled } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#region ------------------------------ Defaults
  const { t } = useTranslation();

  const selectOptions: BasisPickerSelectOptions[] = [
    {
      label: t('tasks:todoForm.importancePicker.options.low'),
      value: 'low',
      icon: {
        name: 'arrow-down',
        color: theme.old.palette.chromaticPalette.blue,
      },
    },
    {
      label: t('tasks:todoForm.importancePicker.options.normal'),
      value: 'normal',
      icon: {
        name: 'dot-circle',
        color: theme.old.palette.chromaticPalette.green,
      },
    },
    {
      label: t('tasks:todoForm.importancePicker.options.high'),
      value: 'high',
      icon: {
        name: 'exclamation',
        color: theme.old.palette.chromaticPalette.red,
      },
    },
  ];
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnSelect = (importance: TaskImportance) => {
    form.setFieldsValue({
      importance: importance,
    });
  };
  //#endregion

  //#region ------------------------------ useEffects
  //#endregion

  return (
    <BasisPicker
      className={classes.root}
      disabled={disabled}
      onSelect={handleOnSelect}
      suffixIconFontAwesome={['fal', 'exclamation']}
      allowClear
      items={selectOptions}
    />
  );
};

export default ImportancePicker;
