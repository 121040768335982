import React from 'react';
import { Moment } from 'moment';
import { TimelineGroup, TimelineItem } from './types';
import moment from 'moment';

export interface TimelineItemContextProps {
  visibleTimeStart: Moment;
  visibleTimeEnd: Moment;
  startTime: Moment;
  endTime: Moment;
  disableResize?: boolean;
  itemStyle: (group: TimelineGroup, item: TimelineItem) => React.CSSProperties;
  itemRenderer?: (
    group: TimelineGroup,
    item: TimelineItem,
    pixelsPerMs: number,
    gridSnapInMilliseconds: number
  ) => React.ReactNode;
  popoverRenderer?: (
    item: TimelineItem,
    group: TimelineGroup
  ) => React.ReactNode;
  onPopoverClick?: (
    item: TimelineItem,
    group: TimelineGroup,
    popoverPositionInMS: number
  ) => void;
}

const TIMELINE_ITEM_CONTEXT_INITIAL_STATE: TimelineItemContextProps = {
  visibleTimeStart: moment().startOf('day'),
  visibleTimeEnd: moment().endOf('day'),
  startTime: moment().startOf('day'),
  endTime: moment().endOf('day'),
  disableResize: undefined,
  itemStyle: () => ({}),
  itemRenderer: undefined,
  popoverRenderer: undefined,
  onPopoverClick: undefined,
};

export const TimelineItemContext =
  React.createContext<TimelineItemContextProps>(
    TIMELINE_ITEM_CONTEXT_INITIAL_STATE
  );

export const useTimelineItemContext = () =>
  React.useContext(TimelineItemContext);

interface TimelineItemProviderProps {
  value: Partial<TimelineItemContextProps>;
  children: React.ReactNode;
}

export const TimelineItemProvider: React.FC<TimelineItemProviderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { value, children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <TimelineItemContext.Provider
      value={{
        ...TIMELINE_ITEM_CONTEXT_INITIAL_STATE,
        ...Object.keys(value).reduce((acc, key) => {
          if (value[key] !== undefined) {
            acc[key] = value[key];
          }
          return acc;
        }, {}),
      }}
    >
      {children}
    </TimelineItemContext.Provider>
  );
};

export default TimelineItemProvider;
