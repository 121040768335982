import {
  actionChannel,
  call,
  cancelled,
  delay,
  fork,
  put,
  race,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { PRIO } from '../constants';
import { DispatchAction, MetaOffline } from '../models/Redux';
import { LOGGED_IN, LOGGED_OUT } from '../modules/auth/actions';
import { distinct } from '../util';
import { SAGA_REBUILD, apiCall } from '../util/sagas';

interface DebounceDispatchAction {
  type: string;
  action: DispatchAction<unknown>;
  attributesToConcat?: string[];
}

export const RUN_DEBOUNCE_SAGA = PRIO + 'RUN_DEBOUNCE_SAGA';
export const putRunDebounceSaga: (
  action: DispatchAction<unknown>,
  attributesToConcat?: string[]
) => DebounceDispatchAction = (action, attributesToConcat) => ({
  type: RUN_DEBOUNCE_SAGA,
  action,
  attributesToConcat,
});

interface HandleDebounceSagaAction extends MetaOffline {
  type: string;
}

const HANDLE_DEBOUNCE_SAGA = PRIO + 'HANDLE_DEBOUNCE_SAGA';
const putHandleDebounceSaga: (
  offline: MetaOffline
) => HandleDebounceSagaAction = (offline) => ({
  type: HANDLE_DEBOUNCE_SAGA,
  ...offline,
});

const combineAttributes = (current: any, latest: any) => {
  return latest && current
    ? Array.isArray(current)
      ? distinct(current.concat(latest))
      : Object.keys(current).reduce(
          (map, key) => ({
            ...map,
            [key]:
              typeof current[key] === 'string' ||
              typeof current[key] === 'number' ||
              typeof current[key] === 'boolean'
                ? current[key]
                : Array.isArray(current[key])
                ? distinct(current[key].concat(latest[key]))
                : {
                    ...(current[key] ?? {}),
                    ...(latest[key] ?? {}),
                  },
          }),
          {}
        )
    : undefined;
};

function* handleAction(offline: MetaOffline) {
  try {
    const { effect, rollback, commit } = offline;
    const { task, cancel } = yield race({
      task: call(
        apiCall,
        effect.url,
        effect.method,
        effect.json,
        effect.headers?.['Content-Type'] ??
          effect.headers?.['content-type'] ??
          'application/json'
      ),
      cancel: take(LOGGED_OUT),
    });
    if (cancel || !(task?.result.status >= 200 && task?.result.status < 300)) {
      yield put({
        ...rollback,
      });
    } else {
      yield put({
        ...(task?.data ? { payload: task?.data } : {}),
        ...commit,
      });
    }
  } catch (error) {
    console.error('Error in watchDebouncedCalls - handleAction', error);
  }
}

function* handleDebounce(action: DispatchAction<unknown>, request: string) {
  let lastAction: MetaOffline = null;
  try {
    const channel = yield actionChannel(
      (action: HandleDebounceSagaAction) =>
        action.type === HANDLE_DEBOUNCE_SAGA &&
        action?.effect?.url &&
        request === action?.effect?.url
    );

    yield put(putHandleDebounceSaga(action.meta.offline));

    let _action: MetaOffline = action.meta.offline;
    lastAction = _action;
    let callFinished = false;

    while (!callFinished) {
      const {
        debounced,
        latestAction,
      }: { debounced: any; latestAction: HandleDebounceSagaAction } =
        yield race({
          debounced: delay(1000),
          latestAction: take(channel),
        });
      if (debounced) {
        yield spawn(handleAction, _action);
        lastAction = null;
        callFinished = true;
      }
      if (latestAction) {
        const metaCommit = combineAttributes(
          _action.commit.meta,
          latestAction.commit.meta
        );
        const metaRollback = combineAttributes(
          _action.rollback.meta,
          latestAction.rollback.meta
        );
        const json = combineAttributes(
          _action.effect.json,
          latestAction.effect.json
        );
        _action = {
          effect: {
            ..._action.effect,
            ...(json
              ? {
                  json: json,
                }
              : {}),
          },
          commit: {
            ..._action.commit,
            meta: metaCommit,
          },
          rollback: {
            ..._action.rollback,
            meta: metaRollback,
          },
        } as MetaOffline;
        lastAction = _action;
      }
    }
  } catch (error) {
    console.error('Error in watchDebouncedCalls - handleDebounce', error);
  } finally {
    if (yield cancelled() && lastAction !== null) {
      yield spawn(handleAction, lastAction);
      lastAction = null;
    }
  }
}

function* requestTask(
  action: DebounceDispatchAction,
  removeRequest: (request: string) => void
) {
  const request = action?.action?.meta?.offline?.effect?.url;
  try {
    yield race({
      action: call(handleDebounce, action.action, request),
      stop: take(action?.action?.meta?.offline?.rollback?.type),
    });
  } catch (error) {
    console.error('Error in watchDebouncedCalls - requestTask', error);
  } finally {
    removeRequest(request);
  }
}

function* mainTask() {
  const channel = yield actionChannel(RUN_DEBOUNCE_SAGA);

  let requests: string[] = [];

  const removeRequest = (request: string) => {
    requests = requests.filter((r) => r !== request);
  };

  while (true) {
    const action: DebounceDispatchAction = yield take(channel);
    const request = action?.action?.meta?.offline?.effect?.url;

    if (request && action?.action?.meta?.offline) {
      try {
        const { offline, ...rest } = action?.action?.meta;
        if (!requests.includes(request)) {
          requests = [...requests, request];
          yield fork(requestTask, action, removeRequest);
        } else {
          yield put(putHandleDebounceSaga(offline));
        }
        yield put({
          type: action.action.type,
          ...(action.action.payload ? { payload: action.action.payload } : {}),
          ...(rest ? { meta: rest } : {}),
        });
      } catch (error) {
        console.error('Error in watchDebouncedCalls - mainTask', error);
      }
    }
  }
}

export default function* watchDebouncedCalls() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
