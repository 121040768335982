import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Menu } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import useAccessRights, {
  AccessItem,
} from '../modules/users/hooks/useAccessRights';
import {
  getOfficeMeIsFetching,
  getUserMe,
  getOfficeMeAllOffices,
  RootReducerState,
  getOfficesIsFetching,
  getAllInternalOffices,
} from '../apps/main/rootReducer';
import { Office } from '../models/Office';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GlobalRole, OfficeRole } from '../models/Types';
import { useTranslation } from 'react-i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import Flex from './Flex';
import { fetchInternalOffices } from '../modules/companies/actions';
import classNames from 'classnames';
import PrioSpinner from './PrioSpinner';
import { setSiderSetting } from '../modules/userSettings/actions/themeSettings/sider';
import { makePrioStyles } from '../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-menu-submenu-selected': {
      color: '#000',
    },
    '&.ant-menu .ant-menu-item-selected:after': {
      display: 'none',
    },
    height: '100%',
    overflow: 'hidden',
  },
  rootSubMenu: {},
  globalMenu: {
    background: 'none',
    border: 'none',
    padding: `0 ${theme.old.spacing.defaultPadding}px ${theme.old.spacing.defaultPadding}px`,
  },
  globalMenuSubMenu: {
    background: 'none',
    border: 'none',
    padding: `0 ${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
  },
  officeMenu: {
    background: 'none',
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    border: 'none',
    padding: theme.old.spacing.defaultPadding,
    paddingTop: 0,
  },
  officeMenuSubMenu: {
    background: 'none',
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    border: 'none',
    padding: theme.old.spacing.unit(1.5),
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  subMenuOpen: {
    fontWeight: theme.old.typography.fontWeight.bold,
    '& .ant-menu-sub.ant-menu-inline': {
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      marginBottom: theme.old.spacing.defaultPadding,
      borderColor: theme.old.borders.colors.sub,
    },
  },
  subMenu: {},
  subMenuTitle: {
    display: 'flex',
    zIndex: 1,
    marginRight: -34,
    marginLeft: -24,
    paddingLeft: 24,
    color: '#000',
  },
  menuItem: {},
  menuItemSubMenu: {},
}));

export interface NavItem {
  itemKey: string;
  icon?: IconName;
  tString?: string;
  urlPrefix?: string;
  customUrl?: string;
  urlSuffix?: string;
  showWarning?: boolean;
}

interface GlobalAndOfficeNavigationProps {
  selectedEntry?: string;
  className?: string;
  urlPrefix?: string;
  customUrl?: string;
  globalMenuItems: NavItem[];
  officeMenuItems: NavItem[];
  officeAccessMode: AccessItem;
  globalAccessMode: AccessItem;
  officeId?: string;
  selectedList?: string;
  onSelect?: (office: Office) => void;
  showOpen?: boolean;
  isSubMenu?: boolean;
  globalSubMenuItem?: NavItem;
}

export const hasOfficeAccess = (
  item: AccessItem,
  globalRoles: GlobalRole[],
  officeRoles: OfficeRole[]
) => {
  switch (item) {
    case 'showOfficeRoleSettings':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalHR') ||
        officeRoles.includes('officeAdmin') ||
        officeRoles.includes('officeHR')
      );
    case 'showOfficeTemplateSettings':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalAssistance') ||
        officeRoles.includes('officeAdmin') ||
        officeRoles.includes('officeAssistance')
      );
    case 'showOfficesInHrModule':
      return (
        globalRoles.includes('globalHR') || officeRoles.includes('officeHR')
      );
    case 'showOfficesInControllingModule':
      return (
        globalRoles.includes('globalController') ||
        officeRoles.includes('officeController')
      );
    default:
      return false;
  }
};

export const GlobalAndOfficeNavigation: React.FC<
  GlobalAndOfficeNavigationProps
> = (props) => {
  const {
    urlPrefix,
    customUrl,
    globalMenuItems,
    officeMenuItems,
    officeAccessMode,
    globalAccessMode,
    selectedList,
    officeId,
    onSelect,
    showOpen,
    isSubMenu,
    globalSubMenuItem,
    className,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();

  const { [officeAccessMode]: officeAccess, [globalAccessMode]: globalAccess } =
    useAccessRights([officeAccessMode, globalAccessMode]);

  React.useEffect(() => {
    if (globalAccess) {
      dispatch(fetchInternalOffices());
    }
  }, [globalAccess, dispatch]);

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames(classes.root, className, {
        [classes.rootSubMenu]: isSubMenu,
      })}
    >
      {globalAccess && (
        <Menu
          mode="inline"
          className={classNames(classes.globalMenu, {
            [classes.globalMenuSubMenu]: isSubMenu,
          })}
          selectedKeys={
            officeId
              ? null
              : [isSubMenu ? globalSubMenuItem.itemKey : selectedList]
          }
          inlineIndent={theme.old.components.menuInlineIndent}
        >
          {(isSubMenu ? [globalSubMenuItem] : globalMenuItems)?.map((item) => (
            <Menu.Item
              className={classNames(classes.menuItem, {
                [classes.menuItemSubMenu]: isSubMenu,
              })}
              key={item.itemKey}
              onClick={() => {
                if (isSubMenu) {
                  dispatch(
                    setSiderSetting({
                      subMenuState: 'closed',
                    })
                  );
                }
              }}
            >
              <Link
                to={
                  customUrl ??
                  item.customUrl ??
                  `${item.urlPrefix ?? urlPrefix ?? ''}${item.itemKey}${
                    item.urlSuffix ? `/${item.urlSuffix}` : ''
                  }`
                }
              >
                {item.icon && (
                  <FontAwesomeIcon
                    fixedWidth
                    icon={['fal', item.icon]}
                    className={classes.icon}
                  />
                )}
                {t(item.tString)}
                {item.showWarning && (
                  <FontAwesomeIcon
                    fixedWidth
                    icon={['fal', 'warning']}
                    className={classes.icon}
                    style={{ marginLeft: theme.spacing.small }}
                  />
                )}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      )}
      {globalAccess && officeMenuItems.length > 0 && (
        <Divider className={classes.divider} />
      )}
      <OfficeNavigation
        officeMenuItems={officeMenuItems}
        officeAccess={officeAccess}
        globalAccess={globalAccess}
        officeId={officeId}
        selectedList={selectedList}
        onSelect={onSelect}
        showOpen={showOpen}
        officeAccessMode={officeAccessMode}
        urlPrefix={urlPrefix}
        customUrl={customUrl}
        isSubMenu={isSubMenu}
      />
    </Flex.Column>
  );
};

export default GlobalAndOfficeNavigation;

interface OfficeNavigationProps {
  officeId: string;
  selectedList: string;
  officeMenuItems: NavItem[];
  globalAccess: boolean;
  officeAccess: boolean;
  customUrl?: string;
  urlPrefix?: string;
  showOpen?: boolean;
  officeAccessMode?: AccessItem;
  onSelect?: (office: Office) => void;
  isSubMenu?: boolean;
}

const OfficeNavigation: React.FC<OfficeNavigationProps> = (props) => {
  const {
    officeMenuItems,
    globalAccess,
    officeAccess,
    officeId,
    selectedList,
    customUrl,
    urlPrefix,
    showOpen,
    officeAccessMode,
    onSelect,
    isSubMenu,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const hasSubMenus = officeMenuItems.length > 1;
  const isOfficeMeFetching = useSelector(getOfficeMeIsFetching);
  const isGlobalOfficesFetching = useSelector(getOfficesIsFetching);
  const [openKey, setOpenKey] = useState<string>(officeId ?? null);

  const userMe = useSelector(getUserMe);

  const myOffices = useSelector(getOfficeMeAllOffices);

  const globalOffices = useSelector<RootReducerState, Office[]>((state) =>
    getAllInternalOffices(state)
  );

  const offices: Office[] = useMemo(
    () =>
      globalAccess
        ? globalOffices
        : myOffices.filter((office) =>
            hasOfficeAccess(
              officeAccessMode,
              userMe?.prioData?.globalRoles ?? [],
              userMe?.prioData?.officeRoles[office.officeId] ?? []
            )
          ),
    [globalAccess, globalOffices, myOffices, officeAccessMode, userMe]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (onSelect) {
      if (!officeId) {
        onSelect(null);
      } else {
        onSelect(offices.find((office) => office.officeId === officeId));
      }
    }
  }, [officeId, onSelect, offices]);

  useEffect(() => {
    setOpenKey(showOpen ? officeId : null);
  }, [showOpen, officeId]);
  //#endregion

  if (
    (isOfficeMeFetching && myOffices.length === 0) ||
    (isGlobalOfficesFetching && globalOffices.length === 0)
  ) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <PrioSpinner />
      </div>
    );
  }

  return (
    <Menu
      mode="inline"
      className={classNames(classes.officeMenu, {
        [classes.officeMenuSubMenu]: isSubMenu,
      })}
      openKeys={openKey ? [openKey] : []}
      selectedKeys={officeId ? [officeId, `${officeId}/${selectedList}`] : null}
      inlineIndent={
        !hasSubMenus || isSubMenu ? theme.old.components.menuInlineIndent : 0
      }
    >
      {(globalAccess || officeAccess) && officeMenuItems.length > 0
        ? offices.map((office) =>
            !hasSubMenus || isSubMenu ? (
              <Menu.Item
                className={classNames(classes.menuItem, {
                  [classes.menuItemSubMenu]: isSubMenu,
                })}
                key={office.officeId}
                onClick={() => {
                  if (isSubMenu) {
                    dispatch(
                      setSiderSetting({
                        subMenuState: 'closed',
                      })
                    );
                  }
                }}
              >
                <Link
                  to={
                    customUrl ??
                    officeMenuItems[0]?.customUrl ??
                    `${
                      officeMenuItems[0]?.urlPrefix ?? urlPrefix ?? ''
                    }office/${office.officeId}${
                      officeMenuItems[0]?.itemKey
                        ? `/${officeMenuItems[0]?.itemKey}`
                        : ''
                    }`
                  }
                >
                  <FontAwesomeIcon
                    fixedWidth
                    icon={['fal', 'building']}
                    className={classes.icon}
                  />
                  {office.name}
                </Link>
              </Menu.Item>
            ) : (
              <Menu.SubMenu
                key={office.officeId}
                className={classNames({
                  [classes.subMenuOpen]: officeId === office.officeId,
                })}
                onTitleClick={() =>
                  navigate(
                    customUrl ??
                      officeMenuItems[0]?.customUrl ??
                      `${
                        officeMenuItems[0]?.urlPrefix ?? urlPrefix ?? ''
                      }office/${office.officeId}/${
                        officeMenuItems[0]?.itemKey ?? ''
                      }`
                  )
                }
                title={
                  <Link
                    className={classes.subMenuTitle}
                    to={
                      customUrl ??
                      officeMenuItems[0]?.customUrl ??
                      `${
                        officeMenuItems[0]?.urlPrefix ?? urlPrefix ?? ''
                      }office/${office.officeId}/${
                        officeMenuItems[0]?.itemKey ?? ''
                      }`
                    }
                  >
                    {office.name}
                  </Link>
                }
              >
                {officeMenuItems.map((item) => (
                  <Menu.Item
                    className={classNames(classes.menuItem, {
                      [classes.menuItemSubMenu]: isSubMenu,
                    })}
                    key={`${office.officeId}/${item.itemKey ?? ''}`}
                    style={{
                      paddingLeft: theme.old.components.menuInlineIndent,
                    }}
                  >
                    <Link
                      to={
                        customUrl ??
                        item.customUrl ??
                        `${item.urlPrefix ?? urlPrefix ?? ''}${
                          officeId
                            ? `office/${officeId}/${item.itemKey}`
                            : item.itemKey
                        }${item.urlSuffix ? `/${item.urlSuffix}` : ''}`
                      }
                    >
                      {item.icon && (
                        <FontAwesomeIcon
                          fixedWidth
                          icon={['fal', item.icon]}
                          className={classes.icon}
                        />
                      )}
                      {t(item.tString)}
                      {item.showWarning && (
                        <FontAwesomeIcon
                          fixedWidth
                          icon={['fal', 'warning']}
                          className={classes.icon}
                          style={{ marginLeft: theme.spacing.small }}
                        />
                      )}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )
          )
        : null}
    </Menu>
  );
};
