import { ApiResult, apiUrl } from '../../../api';
import { EmergencyContact } from '../../../models/Employee';
import { EmployeeId, OfficeId } from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiFetchEmergencyContactsMe: () => Promise<
  ApiResult<EmergencyContact[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/EmergencyContact/me`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchEmergencyContacts: (
  employeeId: EmployeeId,
  officeId?: OfficeId
) => Promise<ApiResult<EmergencyContact[]>> = async (employeeId, officeId) => {
  const result = await fetchWithRetry(
    officeId
      ? `${apiUrl}/employee/office/${officeId}/EmergencyContact/${employeeId}` // officeHr
      : `${apiUrl}/employee/EmergencyContact/${employeeId}`, // globalHr
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateEmergencyContact: (
  emergencyContact: EmergencyContact
) => Promise<ApiResult<EmergencyContact>> = async (
  emergencyContact: EmergencyContact
) => {
  const result = await fetchWithRetry(`${apiUrl}/employee/EmergencyContact`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(emergencyContact),
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateEmergencyContact: (
  emergencyContactId: string,
  emergencyContact: EmergencyContact
) => Promise<ApiResult<void>> = async (
  emergencyContactId,
  emergencyContact
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/EmergencyContact/${emergencyContactId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(emergencyContact),
    }
  );
  return { result };
};

export const apiDeleteEmergencyContact: (
  emergencyContactId: string
) => Promise<ApiResult<void>> = async (emergencyContactId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/employee/EmergencyContact/${emergencyContactId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};
