import React from 'react';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import { useTranslation } from 'react-i18next';

const useStyles = makePrioStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: theme.old.spacing.defaultPadding,
    paddingTop: '0px',
    paddingBottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  headerPreview: {
    marginTop: '12px',
    marginLeft: '24px',
  },
  thumbnailImageContainer: {
    margin: '24px',
    marginTop: '8px',
    marginBottom: '24px',
    flex: 3,
  },
  noPreviewAvailableText: {
    color: theme.old.typography.colors.muted,
  },
  previewContainer: {
    padding: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    width: '100%',
    maxWidth: '80vh',
    height: '100%',
  },
  documentPreviewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
}));

interface DocumentTemplatePreviewProps {
  thumbnailURL: String[];
  selectedTemplate: any;
}

export const DocumentTemplatePreview: React.FC<DocumentTemplatePreviewProps> = (
  props
) => {
  const { thumbnailURL, selectedTemplate } = props;
  const theme = useTheme<PrioTheme>();
  //#region ------------------------------------------------- Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion
  return (
    <div className={classes.container}>
      {thumbnailURL.length > 0 && selectedTemplate?.name ? (
        <Flex.Item flex={3} className={classes.previewContainer}>
          <div className={classes.documentPreviewWrapper}>
            <Typography.Title level={2} className={classes.headerPreview}>
              {selectedTemplate?.name}
            </Typography.Title>
            <iframe
              title={selectedTemplate?.name}
              src={thumbnailURL[0] as string}
              frameBorder="0"
              className={classes.thumbnailImageContainer}
            />
          </div>
        </Flex.Item>
      ) : (
        <div className={classes.previewContainer}>
          <Flex.Column
            className={'prio-flex-center-center'}
            childrenGap={theme.old.spacing.unit(2)}
          >
            <FontAwesomeIcon
              icon={['fal', 'file-image']}
              className={classes.noPreviewAvailableText}
              size="3x"
            />
            <Typography.Text className={classes.noPreviewAvailableText}>
              {t('documents:newDocumentForm.noPreviewAvailable')}
            </Typography.Text>
          </Flex.Column>
        </div>
      )}
    </div>
  );
};
