import { PRIO } from '../../../constants';

export const OPEN_ADD_USER_DRAWER = PRIO + 'OPEN_ADD_USER_DRAWER';
export const CLOSE_ADD_USER_DRAWER = PRIO + 'CLOSE_ADD_USER_DRAWER';

export const openAddUserDrawer = () => ({
  type: OPEN_ADD_USER_DRAWER,
});

export const closeAddUserDrawer = () => ({
  type: CLOSE_ADD_USER_DRAWER,
});
