import { FluentValidationError } from '../models/ValidationResults';
import * as ConfigValues from '../util/configValues';

export const legacyApiUrl = 'https://prio-backend.azurewebsites.net';
export const apiUrl = `${ConfigValues.REACT_APP_API_HOST_NAME ?? ''}/api`;
export const zendeskApiUrl = `${
  ConfigValues.REACT_APP_ZENDESK_API_HOST_NAME ?? ''
}/api`;

export interface ApiResult<T> {
  result: Response;
  data?: T;
  validationError?: FluentValidationError;
}
