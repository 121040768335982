import { END, EventChannel, eventChannel } from 'redux-saga';
import {
  call,
  cancelled,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { LOGGED_IN, LOGGED_OUT } from '../modules/auth/actions';
import { WebSocketMessage, WebSocketMessageType } from '../models/WebSocket';
import {
  // HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { getAccessToken } from '../store/authEffect';
import { handleContactMessage } from '../modules/contacts/ws';
import { handleEmailMeMessage, handleEmailMessage } from '../modules/mail/ws';
import { handleNotificationMessage } from '../modules/notifications/ws';
import * as ConfigValues from '../util/configValues';
import {
  handleDocumentFromTemplateCreatedMessage,
  handleDocumentMessage,
} from '../modules/documents/ws';
import {
  handleInternalProjectContactMessage,
  handleProjectExtensionAccessUpdateMessage,
} from '../modules/projects/ws';
import moment from 'moment';
import { PRIO } from '../constants';
import { handleToastMessages } from './handleToastMessages';
import { WS_RECONNECT } from './watchWebsocketReconnect';
import { defaultRetryOptions } from '../util/fetchWithRetry';
import { isLoggedIn } from '../apps/main/rootReducer';
import { SAGA_REBUILD } from '../util/sagas';
import handleDriveFavoriteMessage from '../modules/documents/ws/handleDriveFavoriteMessage';
import { handleContactCompanyChangedMessage } from './ws';
import { handleEmailCommentMessage } from '../modules/mail/ws/commentMessage';

const WS_RENEW = PRIO + 'WS_RENEW';
const SET_DELAY = PRIO + 'SET_DELAY';

const RETRY_DELAY = 30000;

const wsRenew = (retried: boolean, timeStamp?: number) => ({
  type: WS_RENEW,
  retried,
  timeStamp,
});

function* connectWebSocket(store: any, action) {
  const loggedIn = yield select(isLoggedIn);
  if (loggedIn) {
    let reconnectingTimestamp = moment();
    let connectionLost = false;

    if (ConfigValues.REACT_APP_WS_DISABLED === 'true') {
      return;
    }

    const connection = new HubConnectionBuilder()
      .withUrl(`${ConfigValues.REACT_APP_API_WS_URL}`, {
        accessTokenFactory: () =>
          getAccessToken(true, (delay) => {
            store.dispatch({
              type: SET_DELAY,
              delay,
            });
          }),
        // skipNegotiation: true,
        // transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([...defaultRetryOptions, null])
      .build();

    const socketChannel: EventChannel<WebSocketMessage> = yield call(
      watchMessages,
      connection
    );

    try {
      connection
        .start()
        .then(() => {
          if (action.retried) {
            store.dispatch({
              type: WS_RECONNECT,
            });
          } else if (action.timeStamp) {
            const now = new Date().getTime();
            if (action.timeStamp - now >= 100) {
              store.dispatch({
                type: WS_RECONNECT,
              });
            }
          }
        })
        .catch((error) => {
          setTimeout(() => {
            store.dispatch(wsRenew(true));
          }, RETRY_DELAY);
        });

      connection.onclose((error) => {
        if (error || connectionLost) {
          setTimeout(() => {
            store.dispatch(wsRenew(true));
          }, RETRY_DELAY);
        }
      });

      connection.onreconnected((error) => {
        if (
          store.dispatch &&
          moment().diff(reconnectingTimestamp, 'seconds') > 1
        ) {
          connectionLost = true;
          store.dispatch({
            type: WS_RECONNECT,
          });
        }
      });

      connection.onreconnecting((error) => {
        reconnectingTimestamp = moment();
      });

      const { cancel, reconnect } = yield race({
        task: call(backgroundTask, socketChannel),
        cancel: take([LOGGED_OUT, WS_RENEW]),
        reconnect: call(checkReconnect),
      });

      if (cancel) {
        socketChannel.close();
      }
      if (reconnect) {
        yield put(wsRenew(false, new Date().getTime()));
        socketChannel.close();
      }
    } catch (error) {
      console.error('Error in connectWebSocket', error);
      yield put(wsRenew(true));
    } finally {
      socketChannel.close();
      if (yield cancelled()) {
        yield put({
          type: WS_RECONNECT,
        });
      }
    }
  }
  return;
}

function* checkReconnect() {
  const { delay: _delay } = yield take(SET_DELAY);
  if (_delay > 0) {
    yield delay(_delay + 1000);
  }
  return true;
}

function* backgroundTask(socketChannel: EventChannel<WebSocketMessage>) {
  while (true) {
    const data = yield take(socketChannel);
    try {
      switch (data.source) {
        case 'ProjectExtensionAccessStateUpdated': {
          yield handleProjectExtensionAccessUpdateMessage(data);
          break;
        }
        case 'ContactMessage':
          yield handleContactMessage(data);
          break;
        case 'ContactCompanyChangedMessage': {
          yield handleContactCompanyChangedMessage(data);
          break;
        }
        case 'NotificationMessage':
          yield handleNotificationMessage(data);
          break;
        case 'EmailMeMessage':
          yield handleEmailMeMessage(data);
          break;
        case 'EmailMessage':
          yield handleEmailMessage(data);
          break;
        case 'EmailComment':
          yield handleEmailCommentMessage(data);
          break;
        case 'DocumentMessage':
          yield handleDocumentMessage(data);
          break;
        case 'DocumentFromTemplateCreatedMessage':
          yield handleDocumentFromTemplateCreatedMessage(data);
          break;
        case 'InternalProjectContactMessage':
          yield handleInternalProjectContactMessage(data);
          break;
        case 'ToastMessage':
          yield handleToastMessages(data);
          break;
        case 'DriveFavoriteMessage':
          yield call(handleDriveFavoriteMessage, data);
          break;
        default:
          console.warn('Received unhandled web socket message', data);
          break;
      }
    } catch (error) {
      console.error('Error in websocket flow', error, data);
    }
  }
}

function watchMessages(
  connection: HubConnection
): EventChannel<WebSocketMessage> {
  return eventChannel<WebSocketMessage>((emit) => {
    connection.on(
      'ProjectExtensionAccessStateUpdated',
      createListener('ProjectExtensionAccessStateUpdated', emit)
    );
    connection.on(
      'ContactCompanyChangedMessage',
      createListener('ContactCompanyChangedMessage', emit)
    );
    connection.on(
      'NotificationMessage',
      createListener('NotificationMessage', emit)
    );
    connection.on('EmailMeMessage', createListener('EmailMeMessage', emit));
    connection.on('EmailMessage', createListener('EmailMessage', emit));
    connection.on('EmailComment', createListener('EmailComment', emit));
    connection.on('DocumentMessage', createListener('DocumentMessage', emit));
    connection.on(
      'DocumentFromTemplateCreatedMessage',
      createListener('DocumentFromTemplateCreatedMessage', emit)
    );
    connection.on(
      'DriveFavoriteMessage',
      createListener('DriveFavoriteMessage', emit)
    );
    connection.on('ToastMessage', createListener('ToastMessage', emit));
    connection.on(
      'InternalProjectContactMessage',
      createListener('InternalProjectContactMessage', emit)
    );

    return () => {
      connection.stop();
    };
  });
}

const createListener: (
  source: WebSocketMessageType,
  emit: (input: WebSocketMessage | END) => void
) => (...args: any[]) => void =
  (source, emit) => (type, objectType, object, projectId) => {
    const wsMessage: WebSocketMessage = {
      source,
      type,
      objectType,
      object,
      projectId,
    };
    emit(wsMessage);
  };

export default function* webSocketFlow(store: any) {
  yield takeLatest(
    [LOGGED_IN, WS_RENEW, SAGA_REBUILD],
    connectWebSocket,
    store
  );
}
