import React from 'react';
import {} from 'antd';

import BasisPicker, {
  BasisPickerSelectOptions,
} from '../../../../components/BasisPicker';
import { useSelector } from 'react-redux';
import {
  getAllProjects,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { ProjectId } from '../../../../models/Types';
import { Project } from '../../../../models/Project';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

interface ProjectAssignmentProjectPickerProps {
  className?: string;
  onChange?: (projectId: string) => void;
  value?: ProjectId | ProjectId[];
}

export const ProjectAssignmentProjectPicker: React.FC<
  ProjectAssignmentProjectPickerProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { className, value, onChange } = props;

  var projects = useSelector<RootReducerState, Project[]>((state) =>
    getAllProjects(state)
  );

  var selectOptions: BasisPickerSelectOptions[] = [];
  if (projects) {
    selectOptions = projects.map((project, index) => {
      return {
        label: project && project.number && `${project.number}-${project.name}`,
        title: project && project.number && `${project.number}-${project.name}`,
        value: project.projectId,
        key: project.projectId,
      };
    });
  }
  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks
  //#endregion

  return (
    <>
      <BasisPicker
        showSearch
        value={value}
        className={classNames(classes.root, className)}
        items={selectOptions}
        onChange={onChange}
      />
    </>
  );
};

export default ProjectAssignmentProjectPicker;
