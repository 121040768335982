import React from 'react';
import { useSelector } from 'react-redux';
import {
  getProject,
  getProjectRedirect,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { useParams, Navigate } from 'react-router-dom';
import ProjectDetails from './ProjectDetails';
import ProjectCardSkeleton from './ProjectCardSkeleton';
import { Project } from '../../../models/Project';

interface ProjectDetailsPageProps {}

export const ProjectDetailsPage: React.FC<ProjectDetailsPageProps> = () => {
  const { projectId } = useParams();
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const redirect = useSelector<RootReducerState, string>((state) =>
    getProjectRedirect(state, projectId)
  );

  if (redirect) {
    return <Navigate to={`../../${redirect}/details`} />;
  }

  if (project) {
    return <ProjectDetails project={project} />;
  }

  return <ProjectCardSkeleton />;
};

export default ProjectDetailsPage;
