import { Reducer } from 'react';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ContactId, OfficeId } from '../../../models/Types';
import {
  AbsenceTimelineAction,
  ABSENCE_OVERVIEW_ADD_EMPLOYEE,
  ABSENCE_OVERVIEW_REMOVE_EMPLOYEE,
} from '../actions/absenceTimeline';

export const noOfficeConstant = 'root';

export interface AbsenceTimelineState {
  [officeId: string]: {
    selectedEmployees: ContactId[];
  };
}

const reducer: Reducer<AbsenceTimelineState, AbsenceTimelineAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case ABSENCE_OVERVIEW_ADD_EMPLOYEE: {
      const { contactId, officeId } = action;
      if (
        (state[officeId ?? noOfficeConstant]?.selectedEmployees ?? []).includes(
          contactId
        )
      )
        return state;
      return {
        ...state,
        [officeId ?? noOfficeConstant]: {
          selectedEmployees: [
            ...(state[officeId ?? noOfficeConstant]?.selectedEmployees ?? []),
            contactId,
          ],
        },
      };
    }
    case ABSENCE_OVERVIEW_REMOVE_EMPLOYEE: {
      const { contactId, officeId } = action;
      if (
        !(
          state[officeId ?? noOfficeConstant]?.selectedEmployees ?? []
        ).includes(contactId)
      )
        return state;
      return {
        ...state,
        [officeId ?? noOfficeConstant]: {
          selectedEmployees: (
            state[officeId ?? noOfficeConstant]?.selectedEmployees ?? []
          ).filter((cId) => cId !== contactId),
        },
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export const getSelectedEmployees = (
  state: AbsenceTimelineState,
  officeId: OfficeId | null
) => state[officeId ?? noOfficeConstant]?.selectedEmployees ?? [];

export default reducer;
