import React from 'react';
import { useDragLayer, DragLayerMonitor } from 'react-dnd';
import DragPreview from './DragPreview';
import { makePrioStyles } from '../../theme/utils';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 10000,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  dragLayer: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999999,
    pointerEvents: 'none',
  },
}));

const getPreview = (monitor: DragLayerMonitor) => {
  const item = monitor.getItem();
  const initialOffset = monitor.getInitialSourceClientOffset();
  const currentOffset = monitor.getClientOffset();
  const isDragging = monitor.isDragging();
  const itemType = monitor.getItemType();
  if (!isDragging || !initialOffset || !currentOffset) {
    return null;
  }

  let { x, y } = currentOffset;
  return {
    transform: `translate(${Math.round(x)}px, ${Math.round(y)}px)`,
    itemType: itemType,
    item: item,
  };
};

interface CustomDragLayerProps {}

const CustomDragLayer: React.FC<CustomDragLayerProps> = (props) => {
  const classes = useStyles();

  const previewDragLayer = useDragLayer(getPreview);

  if (!previewDragLayer) {
    return null;
  }

  return (
    <div className={classNames('prio-deprecated-design', classes.dragLayer)}>
      <div
        style={{
          transform: previewDragLayer.transform,
          WebkitTransform: previewDragLayer.transform,
        }}
      >
        <DragPreview
          item={previewDragLayer.item}
          itemType={previewDragLayer.itemType}
        />
      </div>
    </div>
  );
};

export default CustomDragLayer;
