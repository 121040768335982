import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAllProjects,
  getProjectsIsFetching,
} from '../../../apps/main/rootReducer';
import { ProjectCard } from './ProjectCard';
import { Project } from '../../../models/Project';
import ProjectListSkeleton from './ProjectListSkeleton';
import { fetchProjects } from '../actions';
import { useDispatch } from 'react-redux';
import Flex from '../../../components/Flex';

interface ProjectsListProps {}

export const ProjectsList: React.FC<ProjectsListProps> = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProjects(true));
  }, [dispatch]);

  const projects = useSelector(getAllProjects);
  const isFetching = useSelector(getProjectsIsFetching);

  const renderSkeleton = projects.length === 0 && isFetching;

  if (renderSkeleton) {
    return <ProjectListSkeleton />;
  }

  return (
    <Flex.Column>
      {projects.map((project: Project) => (
        <ProjectCard key={project.projectId} project={project} />
      ))}
    </Flex.Column>
  );
};

export default ProjectsList;
