import { combineReducers } from 'redux';
import absences, * as fromAbsences from './absences';
import absenceTimeline, * as fromAbsenceTimeline from './absenceTimeline';
import officeHolidays, * as fromOfficeHolidays from './officeHolidays';
import {
  AbsenceProposal,
  OfficeHoliday,
} from '../../../models/AbsenceProposal';
import {
  AbsenceProposalId,
  DateTimeString,
  OfficeId,
} from '../../../models/Types';
import { AbsenceOverviewState } from './absences';

export interface AbsenceReducerState {
  absences: fromAbsences.AbsencesMeState;
  officeHolidays: fromOfficeHolidays.OfficeHolidayState;
  absenceTimeline: fromAbsenceTimeline.AbsenceTimelineState;
}

export default combineReducers<AbsenceReducerState>({
  absences,
  officeHolidays,
  absenceTimeline,
});

export const getAbsenceByIdState: (
  state: AbsenceReducerState
) => fromAbsences.AbsencesById = ({ absences }) =>
  fromAbsences.getAbsenceByIdState(absences);

export const getAbsenceIds: (
  state: AbsenceReducerState
) => AbsenceProposalId[] = ({ absences }) =>
  fromAbsences.getAbsenceIds(absences);

export const getAbsenceProposalsMe: (
  state: AbsenceReducerState
) => AbsenceProposal[] = ({ absences }) =>
  fromAbsences.getAbsenceProposalsMe(absences);

export const getAbsenceProposalMe: (
  state: AbsenceReducerState,
  absenceProposalId: AbsenceProposalId
) => AbsenceProposal = ({ absences }, absenceProposalId) =>
  fromAbsences.getAbsenceProposalMe(absences, absenceProposalId);

export const getAbsenceOverview: (
  state: AbsenceReducerState
) => AbsenceOverviewState = ({ absences }) =>
  fromAbsences.getAbsenceOverview(absences);

export const getAbsencesMeIsFetching: (
  state: AbsenceReducerState
) => boolean = ({ absences }) => fromAbsences.getAbsencesMeIsFetching(absences);

export const getOfficeHolidays: (
  state: AbsenceReducerState
) => OfficeHoliday[] = (state) =>
  fromOfficeHolidays.getOfficeHolidays(state.officeHolidays);

export const getOfficeHolidaysOfMonth: (
  state: AbsenceReducerState,
  dateTime: DateTimeString
) => OfficeHoliday[] = (state, dateTime) =>
  fromOfficeHolidays.getOfficeHolidaysOfMonth(state.officeHolidays, dateTime);

export const getOfficeHolidaysOfTimeRange: (
  state: AbsenceReducerState,
  from: DateTimeString,
  to: DateTimeString
) => OfficeHoliday[] = (state, from, to) =>
  fromOfficeHolidays.getOfficeHolidaysOfTimeRange(
    state.officeHolidays,
    from,
    to
  );

export const getAbsenceTimelineSelectedEmployees = (
  state: AbsenceReducerState,
  officeId: OfficeId | null
) => fromAbsenceTimeline.getSelectedEmployees(state.absenceTimeline, officeId);
