import React, { useEffect, useState, useReducer, Reducer } from 'react';

import { Table, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import {
  ExternalJobTitleSuggestion,
  CreateExternalJobTitleSuggestion,
} from '../../../models/ExternalJobTitleSuggestion';
import { ExternalJobTitleSuggestionId } from '../../../models/Types';
import equals from 'deep-equal';
import { isTemporaryId } from '../../../util';
import { tableTranslations } from '../../../util/table';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-row .ant-table-cell .external-contact-job-title-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .external-contact-job-title-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
}));

interface ExternalJobTitlesTableProps {
  className?: string;
  externalJobTitles: ExternalJobTitleSuggestion[];
  onIsDirtyChanged?: (
    isDirty: boolean,
    externalJobTitles?: ExternalJobTitleSuggestion[]
  ) => void;
  onNewJobTitle?: (externalJobTitle: CreateExternalJobTitleSuggestion) => void;
  onDelete?: (
    externalJobTitleSuggestionId: ExternalJobTitleSuggestionId
  ) => void;
}

interface TableEntry extends ExternalJobTitleSuggestion {
  key: string;
  addExternalJobTitleRow: boolean;
  isTemporary: boolean;
}

interface UpdateNewExternalJobTitleAction {
  name: string;
}

interface NewExternalJobTitle extends CreateExternalJobTitleSuggestion {
  isValid: boolean;
}

export const ExternalJobTitlesTable: React.FC<ExternalJobTitlesTableProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    className,
    externalJobTitles: originalExternalJobTitles,
    onIsDirtyChanged,
    onNewJobTitle: onNewSuggestion,
    onDelete,
  } = props;

  const [externalJobTitles, setExternalJobTitles] = useState<
    ExternalJobTitleSuggestion[]
  >(originalExternalJobTitles);

  const [newExternalJobTitleRowActive, setNewExternalJobTitleRowActive] =
    useState(false);

  useEffect(() => {
    setExternalJobTitles(originalExternalJobTitles);
    onIsDirtyChanged(false, originalExternalJobTitles);
  }, [originalExternalJobTitles, onIsDirtyChanged]);

  const validate: (state: NewExternalJobTitle) => boolean = (
    state: NewExternalJobTitle
  ) => state.name.trim().length > 0;

  const emptyNewExternalJobTitle: NewExternalJobTitle = {
    name: '',
    isValid: false,
  };

  const [newExternalJobTitle, updateNewExternalJobTitle] = useReducer<
    Reducer<NewExternalJobTitle, UpdateNewExternalJobTitleAction | 'clear'>
  >(
    (
      state: NewExternalJobTitle,
      action: UpdateNewExternalJobTitleAction | 'clear'
    ) => {
      if (action === 'clear') {
        return emptyNewExternalJobTitle;
      }
      const updatedState = { ...state, name: action.name };
      return {
        ...updatedState,
        isValid: validate(updatedState),
      };
    },
    emptyNewExternalJobTitle
  );

  const updateExternalJobTitle = (
    externalJobTitleSuggestionId: ExternalJobTitleSuggestionId,
    name: string
  ) => {
    const updatedExternalJobTitles = externalJobTitles.map((entry) =>
      entry.externalJobTitleSuggestionId === externalJobTitleSuggestionId
        ? { ...entry, name }
        : entry
    );
    setExternalJobTitles(updatedExternalJobTitles);

    if (onIsDirtyChanged) {
      const isDirty = !equals(
        updatedExternalJobTitles,
        originalExternalJobTitles
      );
      onIsDirtyChanged(isDirty, updatedExternalJobTitles);
    }
  };

  const saveNewExternalJobTitle = () => {
    if (onNewSuggestion) {
      onNewSuggestion(newExternalJobTitle);
      cancelNewExternalJobTitleRow();
      updateNewExternalJobTitle('clear');
    }
  };

  const deleteExternalJobTitle = (
    externalJobTitleSuggestionId: ExternalJobTitleSuggestionId
  ) => {
    if (onDelete) {
      onDelete(externalJobTitleSuggestionId);
    }
  };

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('settings:externalJobTitlesTable.columnTitles.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      render: (value, record) => (
        <Input
          value={
            record.addExternalJobTitleRow ? newExternalJobTitle.name : value
          }
          onChange={(e) =>
            record.addExternalJobTitleRow
              ? updateNewExternalJobTitle({
                  name: e.currentTarget.value,
                })
              : updateExternalJobTitle(
                  record.externalJobTitleSuggestionId,
                  e.currentTarget.value
                )
          }
          disabled={record.isTemporary}
        />
      ),
    },
    {
      render: (_, record) =>
        record.addExternalJobTitleRow ? (
          <Button
            disabled={!newExternalJobTitle.isValid}
            onClick={saveNewExternalJobTitle}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            onClick={() =>
              deleteExternalJobTitle(record.externalJobTitleSuggestionId)
            }
            className="external-contact-job-title-table-delete-button"
            disabled={record.isTemporary}
            iconProp={['fal', 'trash']}
            type="link"
          ></Button>
        ),
      width: 80,
    },
  ];

  const data: TableEntry[] = externalJobTitles.map(
    (externalJobTitle: ExternalJobTitleSuggestion, index: number) => ({
      key: externalJobTitle.externalJobTitleSuggestionId,
      ...externalJobTitle,
      isTemporary: isTemporaryId(externalJobTitle.externalJobTitleSuggestionId),
      addExternalJobTitleRow: false,
    })
  );

  const enableNewExternalJobTitleRow = () =>
    setNewExternalJobTitleRowActive(true);
  const cancelNewExternalJobTitleRow = () =>
    setNewExternalJobTitleRowActive(false);

  const footer = () => (
    <div>
      <Button
        type="link"
        onClick={enableNewExternalJobTitleRow}
        disabled={newExternalJobTitleRowActive}
        iconProp={['fal', 'plus']}
      >
        {t('settings:externalJobTitlesTable.actions.add')}
      </Button>
    </div>
  );

  return (
    <Table
      className={classNames(classes.root, className)}
      dataSource={
        newExternalJobTitleRowActive
          ? ([
              ...data,
              {
                key: 'addExternalJobTitle',
                name: '',
                addExternalJobTitleRow: true,
              },
            ] as TableEntry[])
          : data
      }
      columns={columns}
      pagination={false}
      rowSelection={{
        type: 'checkbox',
      }}
      footer={footer}
      locale={tableTranslations(t)}
    />
  );
};

export default ExternalJobTitlesTable;
