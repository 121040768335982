import { Reducer } from 'redux';
import {
  UPDATE_PRIO_THEME,
  UPDATE_PRIO_THEME_CICD_PRIMARY_COLOR,
  UPDATE_PRIO_THEME_PRIMARY_COLOR,
} from '../actions';
import { PrioThemeReduxState } from '../types';
import { CLEAR_PRIO_CACHE } from '../../actions';
import { PRIO_DEFAULT_BORDERS, PRIO_DEFAULT_PALETTE } from '../types/default';

const hexToRgb = (value: string) => {
  let _r = '0';
  let _g = '0';
  let _b = '0';

  // 3 digits
  if (value.length === 4) {
    _r = '0x' + value[1] + value[1];
    _g = '0x' + value[2] + value[2];
    _b = '0x' + value[3] + value[3];

    // 6 digits
  } else if (value.length === 7) {
    _r = '0x' + value[1] + value[2];
    _g = '0x' + value[3] + value[4];
    _b = '0x' + value[5] + value[6];
  }
  let r = parseInt(_r);
  let g = parseInt(_g);
  let b = parseInt(_b);
  return [r, g, b];
};

const rgbToHex = (r: number, g: number, b: number) => {
  let _r = Math.round(r).toString(16);
  let _g = Math.round(g).toString(16);
  let _b = Math.round(b).toString(16);

  if (_r.length === 1) {
    _r = '0' + r;
  }
  if (_g.length === 1) {
    _g = '0' + g;
  }
  if (_b.length === 1) {
    _b = '0' + b;
  }

  return '#' + _r + _g + _b;
};

const rgbToHsl = (r: number, g: number, b: number, index: number) => {
  let _r = r;
  let _g = g;
  let _b = b;

  _r /= 255;
  _g /= 255;
  _b /= 255;

  let cmin = Math.min(_r, _g, _b),
    cmax = Math.max(_r, _g, _b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;
  if (delta === 0) {
    h = 0;
  } else if (cmax === _r) {
    h = ((_g - _b) / delta) % 6;
  } else if (cmax === _g) {
    h = (_b - _r) / delta + 2;
  } else {
    h = (_r - _g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  const addLightnessSteps = (lightness: number, index: number) => {
    const step = (100 - l) / 10;
    return lightness + step * index;
  };

  return [h + index, s, addLightnessSteps(l, index)];
};

const hslToHex = (h: number, s: number, l: number) => {
  let _s = s;
  let _l = l;
  _s /= 100;
  _l /= 100;

  let c = (1 - Math.abs(2 * _l - 1)) * _s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = _l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  return rgbToHex((r + m) * 255, (g + m) * 255, (b + m) * 255);
};

const addRecursivePalette = (current: number, max: number, index: number) => {
  if (index === 0) {
    return current;
  }
  const ratio = index < 4 ? 5 : index < 8 ? 4 : 3;
  return addRecursivePalette(current + (max - current) / ratio, max, index - 1);
};

const initialState: PrioThemeReduxState = {
  baseSpacing: 8,
  rowGutter: 4,
  palette: PRIO_DEFAULT_PALETTE,
  borders: PRIO_DEFAULT_BORDERS,
};

const reducer: Reducer<PrioThemeReduxState, any> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_PRIO_THEME_PRIMARY_COLOR: {
      const primaryColor = action.value;
      return {
        ...state,
        palette: {
          ...state.palette,
          primaryColor: primaryColor ?? state.palette.primaryColor,
        },
      };
    }
    case UPDATE_PRIO_THEME_CICD_PRIMARY_COLOR: {
      const cicdPrimaryColor = action.value;
      const [r, g, b] = hexToRgb(cicdPrimaryColor);
      return {
        ...state,
        palette: {
          ...state.palette,
          cicdPalette: {
            primaryColor: cicdPrimaryColor,
            ...new Array(10).fill(cicdPrimaryColor).reduce(
              (map, _, currentIndex) => ({
                ...map,
                [`secondaryColor_${
                  currentIndex + 1 < 10
                    ? `0${currentIndex + 1}`
                    : currentIndex + 1
                }`]: rgbToHex(
                  addRecursivePalette(r, 255, currentIndex + 1),
                  addRecursivePalette(g, 255, currentIndex + 1),
                  addRecursivePalette(b, 255, currentIndex + 1)
                ),
              }),
              {}
            ),
            ...new Array(9)
              .fill(cicdPrimaryColor)
              .reduce((map, _, currentIndex) => {
                const [h, s, l] = rgbToHsl(r, g, b, currentIndex + 1);
                return {
                  ...map,
                  [`primaryColor_${
                    currentIndex + 1 < 10
                      ? `0${currentIndex + 1}`
                      : currentIndex + 1
                  }`]: hslToHex(h, s, l),
                };
              }, {}),
          },
        },
      };
    }
    case UPDATE_PRIO_THEME: {
      // const { cicdPalette, primaryColor } = action.value.palette;
      // return {
      //   ...state,
      //   palette: {
      //     ...state.palette,
      //     primaryColor: primaryColor ?? state.palette.primaryColor,
      //     ...(cicdPalette
      //       ? { cicdPalette }
      //       : { cicdPalette: state.palette.cicdPalette }),
      //   },
      // };
      return state;
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;

export const getPrioTheme: (
  state: PrioThemeReduxState
) => PrioThemeReduxState = (state) => state;
