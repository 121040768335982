import { Reducer } from 'react';
import { SET_PRIO_VERSION } from '../actions';
import packageJson from '../../package.json';

const reducer: Reducer<
  string,
  {
    type: string;
    prioVersion: string;
  }
> = (state = packageJson.version, action) => {
  switch (action.type) {
    case SET_PRIO_VERSION: {
      return action.prioVersion;
    }
    default:
      return state;
  }
};

export const getCurrentPrioVersion = (state: string) => state;

export default reducer;
