import { MutableRefObject, createContext, useRef } from 'react';
import { UltimateFilterSearchRef } from '../../../../components/UltimateFilter/createUltimateFilter';
import { MailSearchFormModel } from '../../../../models/MailSearch';

interface MailFilterContextProviderProps {
  children: React.ReactNode;
}

export const MailFilterContext =
  createContext<MutableRefObject<UltimateFilterSearchRef<MailSearchFormModel>>>(
    null
  );

export const MailFilterContextProvider: React.FC<
  MailFilterContextProviderProps
> = ({ children }) => {
  const sharedRef = useRef<UltimateFilterSearchRef<MailSearchFormModel> | null>(
    null
  );
  return (
    <MailFilterContext.Provider value={sharedRef}>
      {children}
    </MailFilterContext.Provider>
  );
};
