import {
  createNewDraftMessageProject,
  createMessageReplyAllForwardProject,
  setActiveDraftMessageIdProject,
  removeDraftMessageProject,
  addDraftMessageProject,
  removeDraftMessageWithoutPipelineStopProject,
  addSendingMessageIdProject,
  removeSendingMessageIdProject,
} from '../projects/drafts';
import {
  createNewDraftMessageMe,
  createMessageReplyAllForwardMe,
  setActiveDraftMessageIdMe,
  removeDraftMessageMe,
  addDraftMessageMe,
  removeDraftMessageWithoutPipelineStopMe,
  addSendingMessageIdMe,
  removeSendingMessageIdMe,
} from '../me/draftsMe';
import { MessageId, ProjectId } from '../../../../models/Types';
import { Message } from '../../../../models/Message';
import { PRIO } from '../../../../constants';

export const setActiveDraftMessageId = (
  projectId: ProjectId,
  messageId: MessageId
) => {
  if (projectId === 'me') {
    return setActiveDraftMessageIdMe(messageId);
  }
  return setActiveDraftMessageIdProject(projectId, messageId);
};

export const createNewDraftMessage = (projectId: ProjectId) => {
  if (projectId === 'me') {
    return createNewDraftMessageMe();
  }
  return createNewDraftMessageProject(projectId);
};

export const createMessageReplyAllForward = (
  projectId: ProjectId,
  messageId: string,
  type: 'Reply' | 'ReplyAll' | 'Forward'
) => {
  if (projectId === 'me') {
    return createMessageReplyAllForwardMe(messageId, type);
  }
  return createMessageReplyAllForwardProject(projectId, messageId, type);
};

export const removeDraftMessage = (
  projectId: ProjectId,
  messageId: MessageId
) => {
  if (projectId === 'me') {
    return removeDraftMessageMe(messageId);
  }
  return removeDraftMessageProject(projectId, messageId);
};

export const removeDraftMessageWithoutPipelineStop = (
  projectId: ProjectId,
  messageId: MessageId
) => {
  if (projectId === 'me') {
    return removeDraftMessageWithoutPipelineStopMe(messageId);
  }
  return removeDraftMessageWithoutPipelineStopProject(projectId, messageId);
};

export const addDraftMessage = (
  projectId: ProjectId,
  message: Message,
  isInitial?: boolean
) => {
  if (projectId === 'me') {
    return addDraftMessageMe(message, isInitial);
  }
  return addDraftMessageProject(projectId, message, isInitial);
};

export const DELETE_ALL_LOCAL_DRAFTS = PRIO + 'DELETE_ALL_LOCAL_DRAFTS';

export const deleteAllLocalDrafts = () => ({
  type: DELETE_ALL_LOCAL_DRAFTS,
});

export const addSendingMessageId = (
  projectId: ProjectId,
  messageId: MessageId
) => {
  if (projectId === 'me') {
    return addSendingMessageIdMe(messageId);
  }
  return addSendingMessageIdProject(projectId, messageId);
};

export const removeSendingMessageId = (
  projectId: ProjectId,
  messageId: MessageId
) => {
  if (projectId === 'me') {
    return removeSendingMessageIdMe(messageId);
  }
  return removeSendingMessageIdProject(projectId, messageId);
};
