import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Cascader, Dropdown } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { useSelector } from 'react-redux';
import {
  getAllProjects,
  getCurrentFolderChildren,
  getDriveFavorites,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { DriveItem } from '../../../../models/Drive';
import { DriveItemId } from '../../../../models/Types';
import { Link } from 'react-router-dom';
import { DriveUserRemoteItem } from '../../../../models/Document';
import { Project } from '../../../../models/Project';
import BreadcrumbDriveItemMenu from './BreadcrumbDriveItemMenu';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { DriveFavorite } from '../../../../models/DriveFavorite';
import PrioSpinner from '../../../../components/PrioSpinner';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  clickable: {
    cursor: 'pointer',
  },
  cascaderDropdownIcon: {
    fontSize: 10,
    lineHeight: 1,
    marginLeft: theme.old.spacing.unit(0.5),
    '& > svg': {
      marginBottom: 1,
    },
  },
  popup: {
    padding: 0,
    '& .ant-cascader-menus': {
      position: 'relative',
      '& > ul:not(:first-child)::before': {
        content: '""',
        position: 'absolute',
        width: 1,
        top: 0,
        bottom: 0,
        backgroundColor: theme.old.borders.colors.main,
        zIndex: 2,
      },
    },
    '& .ant-cascader-menu': {
      height: '100%',
      maxHeight: '25vh',
      border: 'none',
    },
    '& .ant-cascader-menu-item-expand': {
      fontWeight: theme.old.typography.fontWeight.bold,
      backgroundColor: theme.old.palette.backgroundPalette.active.content,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: 2,
        top: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.old.palette.primaryColor,
      },
      '&.ant-cascader-menu-item': {
        color: theme.old.palette.primaryColor,
      },
      '&.ant-cascader-menu-item a': {
        color: theme.old.palette.primaryColor,
      },
      '&:hover': {
        backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      },
    },
    '& .ant-cascader-menu-item': {
      color: theme.old.typography.colors.base,
      position: 'relative',
      '&:has(.prio-documents-active-breadcrumb-item)': {
        color: theme.old.palette.primaryColor,
        fontWeight: theme.old.typography.fontWeight.bold,
        backgroundColor: theme.old.palette.backgroundPalette.active.content,
        '&:hover': {
          backgroundColor: theme.old.palette.backgroundPalette.hover.content,
        },
      },
      '&:hover': {
        color: theme.old.palette.primaryColor,
      },
    },
    '& .ant-cascader-menu-item a': {
      color: theme.old.typography.colors.base,
      '&:hover': {
        color: theme.old.palette.primaryColor,
      },
    },
  },
  breadcrumbSeparator: {
    margin: '0px 4px',
    color: 'rgba(0,0,0,0.45)',
  },
}));

declare type Placement = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';

interface Option {
  value?: string | number;
  label?: React.ReactNode;
  children?: Option[];
}

export interface DataNode {
  label: React.ReactNode;
  title?: string;
  value: string | number;
  disabled?: boolean;
  children?: DataNode[];
  isLeaf?: boolean;
}

interface FirstBreadcrumbItemProps {
  className?: string;
  addProjectList: boolean;
  onClick?: (driveItemId: DriveItemId) => void;
  onRootChange?: (project: Project) => void;
  pathPrefix?: string;
  remoteItem?: DriveUserRemoteItem;
  project: Project;
  activeDriveItemId: DriveItemId;
  placementOverlay?: Placement;
  disableLinkNavigation?: boolean;
  copyDropDown?: (setOpenCopyMenu: (value: boolean) => void) => JSX.Element;
  handleReloadDocuments?: () => void;
  isReloadingDocuments?: boolean;
}

export const FirstBreadcrumbItem: React.FC<FirstBreadcrumbItemProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    addProjectList,
    pathPrefix,
    remoteItem,
    project,
    activeDriveItemId,
    placementOverlay,
    disableLinkNavigation,
    copyDropDown,
    onClick,
    onRootChange,
    handleReloadDocuments,
    isReloadingDocuments,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const groupId = project?.groupId;

  const driveItems = useSelector<RootReducerState, DriveItem[]>(
    (state) =>
      getCurrentFolderChildren(state, `root-group-${groupId}`)?.filter(
        ({ folder }) => folder
      )
  );

  const [openFolderDropdown, setOpenFolderDropdown] = useState<boolean>(false);
  const [openLinkMenu, setOpenLinkMenu] = useState<boolean>(false);

  const projects = useSelector(getAllProjects);

  const driveFavorites = useSelector<RootReducerState, DriveFavorite[]>(
    (state) => getDriveFavorites(state, project?.projectId)
  );

  const options: DataNode[] = useMemo(() => {
    const sortedProjects = (projects ?? []).sort((a, b) => {
      if (a.projectId === project?.projectId) {
        return -1;
      }
      if (b.projectId === project?.projectId) {
        return 1;
      }
      const numberCompare = a.number.localeCompare(b.number);
      if (numberCompare !== 0) return numberCompare;
      return a.name.localeCompare(b.name);
    });

    const projectOptionChildren = (_project: Project): Option[] => {
      const options =
        _project?.projectId === project?.projectId
          ? (driveItems ?? [])
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(
                (item) =>
                  ({
                    value: item.id,
                    label: (
                      <span
                        className={
                          activeDriveItemId === item.id &&
                          'prio-documents-active-breadcrumb-item'
                        }
                      >
                        {item.name}
                      </span>
                    ),
                  }) as DataNode
              )
          : undefined;

      if (driveFavorites.length > 0) {
        options?.unshift({
          value: 'driveFavorites',
          label: <span>{t('documents:menu.documentFavorites')}</span>,
          children: driveFavorites.map((x) => ({
            value: x.driveItemId,
            label: x.displayName,
          })),
        });
      }

      return options;
    };

    const projectOptions: DataNode[] = sortedProjects.map(
      (_project) =>
        ({
          value: _project?.projectId,
          label: _project?.number,
          children: projectOptionChildren(_project),
        }) as DataNode
    );

    return projectOptions;
  }, [
    projects,
    project?.projectId,
    driveItems,
    driveFavorites,
    activeDriveItemId,
    t,
  ]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      if (onClick) {
        onClick(`root-group-${groupId}`);
      }
    },
    [onClick, groupId]
  );

  const handleOnCascaderSelect = (value: string[]) => {
    const id = value[value.length - 1];
    if (value.length === 2 || value[1] === 'driveFavorites') {
      if (onClick) {
        onClick(id);
      }
    } else if (value.length === 1) {
      if (onRootChange) {
        const project = projects.find(({ projectId }) => projectId === id);
        onRootChange(project);
      }
    }
  };
  //#endregion

  //#region ------------------------------ Components
  const labelContent = useMemo(
    () => (
      <span className={classes.clickable}>
        <span
          onClick={handleOnClick}
          title={
            project
              ? `${project?.number} ${project?.number}`
              : remoteItem?.name ?? t('documents:files')
          }
        >
          {!addProjectList && !disableLinkNavigation ? (
            <Link to={`${pathPrefix ?? ''}all`}>
              {project?.number ?? t('documents:files')}
            </Link>
          ) : (
            project?.number ?? remoteItem?.name ?? t('documents:files')
          )}
        </span>
        {!copyDropDown && (
          <DownOutlined className={classes.cascaderDropdownIcon} />
        )}
        {copyDropDown && (
          <Dropdown
            overlay={copyDropDown(setOpenLinkMenu)}
            placement={placementOverlay ?? 'bottomRight'}
            visible={openLinkMenu}
            onVisibleChange={setOpenLinkMenu}
          >
            <FontAwesomeIcon
              icon={['fal', 'link']}
              style={{
                marginLeft: theme.old.spacing.unit(0.5),
                cursor: 'pointer',
              }}
              color={theme.old.typography.colors.muted}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          </Dropdown>
        )}
      </span>
    ),
    [
      classes,
      pathPrefix,
      project,
      remoteItem,
      addProjectList,
      placementOverlay,
      copyDropDown,
      theme,
      openLinkMenu,
      disableLinkNavigation,
      handleOnClick,
      t,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <span
      className={classNames(classes.root, className)}
      style={{
        height: '25px',
        paddingTop: '1px',
      }}
    >
      <span className={classes.breadcrumbSeparator}>/</span>
      {addProjectList ? (
        <Cascader
          placement={placementOverlay ?? 'bottomRight'}
          options={options}
          dropdownClassName={classes.popup}
          onChange={handleOnCascaderSelect}
        >
          {labelContent}
        </Cascader>
      ) : driveItems?.length > 1 ? (
        <Dropdown
          overlay={
            <BreadcrumbDriveItemMenu
              driveItemId={`root-group-${groupId}`}
              activeItemId={activeDriveItemId}
              onItemClick={(driveItemId) => {
                setOpenFolderDropdown(false);
                if (onClick) {
                  onClick(driveItemId);
                }
              }}
              disableLinkNavigation={disableLinkNavigation}
              pathPrefix={pathPrefix}
            />
          }
          placement={placementOverlay ?? 'bottomRight'}
          trigger={['click']}
          overlayStyle={{ width: '25vw', minWidth: 'unset' }}
          visible={openFolderDropdown}
          onVisibleChange={setOpenFolderDropdown}
        >
          {labelContent}
        </Dropdown>
      ) : (
        labelContent
      )}
      {copyDropDown &&
        (!isReloadingDocuments ? (
          <FontAwesomeIcon
            icon={['fal', 'redo']}
            style={{
              marginLeft: theme.old.spacing.unit(0.5),
              cursor: 'pointer',
            }}
            color={theme.old.typography.colors.muted}
            onClick={handleReloadDocuments}
          />
        ) : (
          <div>
            <PrioSpinner size="small" alignSelf></PrioSpinner>
          </div>
        ))}

      <span className={classes.breadcrumbSeparator}>/</span>
    </span>
  );
};

export default FirstBreadcrumbItem;
