import { ApiResult, apiUrl } from '../../../api';
import {
  CreateTimeRecordRequest,
  TimeRecord,
  TimeRecordsFilterRequest,
  UpdateTimeRecordRequest,
} from '../../../models/TimeRecord';
import {
  EditTimeRecordContextType,
  OfficeId,
  ProjectId,
  TimeRecordId,
} from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiFetchTimeRecords: (
  filter: TimeRecordsFilterRequest,
  signal?: AbortSignal,
  projectId?: ProjectId,
  includeSubProjects?: boolean
) => Promise<ApiResult<TimeRecord[]>> = async (
  filter,
  signal,
  projectId,
  includeSubProjects
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timeTracking/${
      projectId ? `project/${projectId}/` : ''
    }TimeRecord/report?includeSubProjects=${includeSubProjects ?? false}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(filter),
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchOfficeTimeRecords: (
  filter: TimeRecordsFilterRequest,
  signal?: AbortSignal,
  officeId?: OfficeId
) => Promise<ApiResult<TimeRecord[]>> = async (filter, signal, officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timeTracking/${
      officeId ? `office/${officeId}/` : ''
    }TimeRecord/report`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(filter),
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchMeTimeRecords: (
  filter: TimeRecordsFilterRequest,
  signal?: AbortSignal
) => Promise<ApiResult<TimeRecord[]>> = async (filter, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timetracking/TimeRecord/me/overview`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(filter),
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteTimeRecords: (
  timeRecordId: TimeRecordId,
  rollbackTimeRecord: TimeRecord,
  contextType: EditTimeRecordContextType
) => Promise<ApiResult<TimeRecord>> = async (
  timeRecordId,
  rollbackTimeRecord,
  contextType
) => {
  const result = await fetchWithRetry(
    `
    ${apiUrl}/timetracking${
      contextType === 'project' || contextType === 'office'
        ? `/project/${rollbackTimeRecord.projectId}`
        : ''
    }/TimeRecord${contextType === 'me' ? '/me' : ''}/${timeRecordId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? rollbackTimeRecord : null;
  return {
    result,
    data,
  };
};

export const apiCreateTimeRecord: (
  value: CreateTimeRecordRequest[],
  projectId: ProjectId,
  isMe?: boolean
) => Promise<ApiResult<TimeRecord[]>> = async (value, projectId, isMe) => {
  const result = await fetchWithRetry(
    `
    ${apiUrl}/timetracking/TimeRecord/project/${projectId}${isMe ? '/me' : ''}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(value),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateTimeRecord: (
  updateRequest: UpdateTimeRecordRequest,
  timeRecordId: TimeRecordId,
  rowVersion: string,
  context: EditTimeRecordContextType,
  projectId?: ProjectId,
  signal?: AbortSignal
) => Promise<ApiResult<TimeRecord>> = async (
  updateRequest,
  timeRecordId,
  rowVersion,
  context,
  projectId,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/timetracking/${
      projectId && (context === 'project' || context === 'office')
        ? `project/${projectId}/`
        : ''
    }TimeRecord/${context === 'me' ? 'me/' : ''}${timeRecordId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      signal,
      body: JSON.stringify({
        ...updateRequest,
        rowVersion,
      }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
