import React from 'react';
import { AllMemberMenu } from './ContactsMenu';

import Flex from '../../../components/Flex';
import { useParams, Navigate } from 'react-router-dom';
import { getGlobalSiderOpen } from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    transition: 'padding 0.2s',
    padding: `0 ${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
  },
  rootOpen: {
    padding: `0 ${theme.old.spacing.defaultPadding}px ${theme.old.spacing.defaultPadding}px`,
  },
  menu: {},
  mainContent: {},
}));

interface ContactsModuleNavigationProps {
  className?: string;
}

export const ContactsModuleNavigation: React.FC<
  ContactsModuleNavigationProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { selectedList } = useParams();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const siderOpen = useSelector(getGlobalSiderOpen);
  //#endregion

  if (!selectedList) return <Navigate to="all" />;

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames(classes.root, className, {
        [classes.rootOpen]: siderOpen,
      })}
    >
      <Flex.Column
        childrenGap={theme.old.spacing.unit(1)}
        className={classes.mainContent}
      >
        <AllMemberMenu
          selectedList={selectedList}
          urlPrefix="../"
          className={classes.menu}
          collapsed={!siderOpen}
        />
      </Flex.Column>
    </Flex.Column>
  );
};

export default ContactsModuleNavigation;
