import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { spawn } from 'redux-saga/effects';
import { OfficeId } from '../models/Types';
import { WebSocketMessage } from '../models/WebSocket';
import {
  handleToastMessageMessageDelete,
  handleToastMessageMessageMove,
} from '../modules/mail/ws/handleToastMessageMessages';

export declare type WsTypesToast = 'info' | 'success' | 'failure' | 'warning';
declare type WSToastMessageType = 'default' | 'messageMove' | 'messageDelete';

interface WebSocketToastMessage extends WebSocketMessage {
  title: string;
  description: string;
  duration?: number;
  message: any;
  officeId: OfficeId;
  wsToastMessageType: WSToastMessageType;
}

export function* handleToastMessages(wsMessage: WebSocketMessage) {
  const { object, type } = wsMessage;
  const {
    wsToastMessageType,
    duration,
    title,
    description,
    message,
    projectId,
  } = object as WebSocketToastMessage;

  switch (wsToastMessageType) {
    case 'messageMove': {
      yield spawn(handleToastMessageMessageMove, message, projectId);
      break;
    }
    case 'messageDelete': {
      yield spawn(handleToastMessageMessageDelete, message, projectId);
      break;
    }
  }

  const toastNotification: ArgsProps = {
    message: title,
    description: description,
    duration: duration ?? 10,
  };

  switch (type) {
    case 'warning': {
      notification.warning(toastNotification);
      break;
    }
    case 'success': {
      notification.success(toastNotification);
      break;
    }
    case 'failure': {
      notification.error(toastNotification);
      break;
    }
    case 'info': {
      notification.info(toastNotification);
      break;
    }
    default: {
      notification.open(toastNotification);
      break;
    }
  }
  yield;
}
