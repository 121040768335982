import { takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from '../i18n';

function showRollbackError(action: any) {
  try {
    const translatedErrorMessage = action?.payload?.response?.TranslatedMessage;

    const {
      snackbarErrorMessage: { label, timeout, title, placement, parameters },
    } = action;

    notification.open({
      message: title ? i18n.t(title) : i18n.t('common:error'),
      description:
        translatedErrorMessage ?? (label && i18n.t(label, parameters)),
      duration: timeout,
      placement,
    });
  } catch (error) {}
}

export default function* watchRollbacks() {
  yield takeEvery<any>(
    (action) => /_ROLLBACK$/.test(action.type) && action.snackbarErrorMessage,
    showRollbackError
  );
}
