import React, { useEffect, useState } from 'react';
import { GlobalProjectSettingType, ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import {
  Typography,
  Radio,
  Select,
  Input,
  Collapse,
  Divider,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { ProjectDocumentSetting } from '../../../models/Project';
import {
  apiCreateProjectDocumentSetting,
  apiFetchProjectDocumentSetting,
  apiUpdateProjectDocumentSetting,
} from '../api';
import { apiFetchGlobalProjectSetting } from '../../settings/api';
import { GlobalProjectSetting } from '../../../models/Configuration';
import useDebounce from '../../../hooks/useDebounce';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/de.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { apiFetchDocumentProjectSettingPreview } from '../../mail/api';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: theme.old.spacing.defaultPadding,
  },
  content: {
    marginBottom: theme.old.spacing.unit(1.5),
  },
  label: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
    paddingBottom: theme.old.spacing.unit(0.5),
  },
  creatButton: {
    color: '#000',
    padding: 0,
    '&.ant-btn-link:hover': {
      color: '#000',
    },
    '&.ant-btn-link:focus': {
      color: '#000',
    },
    '&.ant-btn-link:active': {
      color: '#000',
    },
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    '& > label:nth-child(n+2)': {
      marginTop: theme.old.spacing.baseSpacing,
    },
  },
  radioButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 0,
    '& > span:nth-child(2)': {
      flex: 1,
      paddingRight: 0,
    },
    '& .ant-radio': {
      top: 0,
    },
  },
  radioLabelCol: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  preview: {
    padding: theme.old.spacing.defaultPadding,
    border: theme.old.borders.sub,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
}));

const previewFunc = (type: GlobalProjectSettingType, value: string) => {
  if (!value) {
    return null;
  }
  switch (type) {
    case 'emailSubject': {
      return <div>{value}</div>;
    }
    case 'emailSignature': {
      return <FroalaEditorView model={value} />;
    }
    case 'projectNumberRegex': {
      return <div>{value}</div>;
    }
    case 'projectDocumentPrefix': {
      return <div>{value}</div>;
    }
  }
};

interface ProjectDocumentSettingsManagementProps {
  className?: string;
  projectId: ProjectId;
  type: GlobalProjectSettingType;
  initialPreview: string;
}

export const ProjectDocumentSettingsManagement: React.FC<
  ProjectDocumentSettingsManagementProps
> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { className, projectId, type, initialPreview } = props;

  const [projectDocumentSetting, setProjectDocumentSetting] =
    useState<ProjectDocumentSetting>(null);

  const [originalProjectDocumentSetting, setOriginalProjectDocumentSetting] =
    useState<ProjectDocumentSetting>(null);

  const [globalProjectSettings, setGlobalProjectSettings] = useState<
    GlobalProjectSetting[]
  >([]);

  const debouncedProjectDocumentSetting = useDebounce(
    projectDocumentSetting,
    1000
  );

  const [preview, setPreview] = useState<string>(initialPreview ?? null);

  useEffect(() => {
    setPreview(initialPreview);
  }, [initialPreview]);

  useEffect(() => {
    const fetchProjectDocumentSetting = async () => {
      const { data } = await apiFetchProjectDocumentSetting(projectId, type);
      if (data) {
        setProjectDocumentSetting(data);
        setOriginalProjectDocumentSetting(data);
      }
    };
    fetchProjectDocumentSetting();
  }, [projectId, type]);

  useEffect(() => {
    const fetchGlobalProjectDocumentSetting = async () => {
      const { data } = await apiFetchGlobalProjectSetting(type);
      if (data) {
        setGlobalProjectSettings(data);
      }
    };
    fetchGlobalProjectDocumentSetting();
  }, [type]);

  useEffect(() => {
    if (
      debouncedProjectDocumentSetting &&
      debouncedProjectDocumentSetting.objectType != null &&
      originalProjectDocumentSetting !== debouncedProjectDocumentSetting
    ) {
      debouncedProjectDocumentSetting.projectId = projectId;
      const setSetting = async () => {
        if (
          debouncedProjectDocumentSetting.projectSettingId !==
          '00000000-0000-0000-0000-000000000000'
        ) {
          const { data } = await apiUpdateProjectDocumentSetting(
            type,
            projectId,
            debouncedProjectDocumentSetting
          );

          if (data) {
            const { data: previewData } =
              await apiFetchDocumentProjectSettingPreview(projectId, [type]);
            if (previewData) {
              setPreview(previewData[type]);
              notification.open({
                message: t('common:success'),
                description: t('common:successMessages.saveChange'),
              });
            } else {
              notification.open({
                message: t('common:error'),
                description: t('common:errorMessages.saveChangeError'),
              });
            }
          } else {
            notification.open({
              message: t('common:error'),
              description: t('common:errorMessages.saveChangeError'),
            });
          }
        } else {
          const { data } = await apiCreateProjectDocumentSetting(
            type,
            projectId,
            debouncedProjectDocumentSetting
          );
          if (data) {
            const { data: previewData } =
              await apiFetchDocumentProjectSettingPreview(projectId, [type]);
            if (previewData) {
              setProjectDocumentSetting(data);
              setPreview(previewData[type]);
            } else {
              notification.open({
                message: t('common:error'),
                description: t('common:errorMessages.saveChangeError'),
              });
            }
          } else {
            notification.open({
              message: t('common:error'),
              description: t('common:errorMessages.saveChangeError'),
            });
          }
        }
      };
      setSetting();
    }
  }, [
    projectId,
    debouncedProjectDocumentSetting,
    type,
    t,
    originalProjectDocumentSetting,
  ]);

  const handleChangeProjectSetting = (value: string) => {
    setProjectDocumentSetting({
      ...projectDocumentSetting,
      objectJson: { value },
      objectType: type,
      globalProjectSettingId:
        globalProjectSettings?.length > 0
          ? globalProjectSettings[0].globalProjectSettingId
          : null,
    });
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Typography.Title>
        {t(`projects:projectDocumentSettingsManagement.labels.${type}`)}
      </Typography.Title>
      <Radio.Group
        className={classes.radioGroup}
        value={projectDocumentSetting?.useProjectSetting ?? false}
        onChange={(e) => {
          setProjectDocumentSetting({
            ...projectDocumentSetting,
            useProjectSetting: e.target.value,
          });
        }}
      >
        <Radio value={false} className={classes.radioButton}>
          <Flex.Row>
            <div className={classes.radioLabelCol}>
              {t(
                'projects:projectDocumentSettingsManagement.labels.globalSetting'
              )}
            </div>
            <Flex.Item flex={1}>
              <Select
                onChange={(value: string) =>
                  setProjectDocumentSetting({
                    ...projectDocumentSetting,
                    globalProjectSettingId: value,
                    objectType: type,
                    objectJson: projectDocumentSetting.objectJson ?? {
                      value: '',
                    },
                  })
                }
                onClick={(e) => e.preventDefault()}
                className={classes.fullWidth}
                disabled={projectDocumentSetting?.useProjectSetting}
                value={projectDocumentSetting?.globalProjectSettingId}
              >
                {globalProjectSettings.map((globalSetting) => (
                  <Select.Option
                    value={globalSetting.globalProjectSettingId}
                    key={globalSetting.globalProjectSettingId}
                  >
                    {globalSetting.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Flex.Item>
          </Flex.Row>
        </Radio>
        <Radio value={true} className={classes.radioButton}>
          <Flex.Row>
            <div className={classes.radioLabelCol}>
              {t(
                'projects:projectDocumentSettingsManagement.labels.projectSetting'
              )}
            </div>
            <Flex.Item flex={1}>
              <Input
                className={classes.fullWidth}
                onClick={(e) => e.preventDefault()}
                onChange={(e) => handleChangeProjectSetting(e.target.value)}
                disabled={!projectDocumentSetting?.useProjectSetting}
                value={projectDocumentSetting?.objectJson?.value}
              />
            </Flex.Item>
          </Flex.Row>
        </Radio>
      </Radio.Group>

      <Collapse ghost className={classes.collapse}>
        <Collapse.Panel
          header={
            <Flex.Row flex={1}>
              <div className={classes.radioLabelCol}>
                {t('projects:projectDocumentSettingsManagement.preview')}
              </div>

              <Divider className={classes.divider} />
            </Flex.Row>
          }
          key="1"
        >
          <div className={classes.preview}>{previewFunc(type, preview)}</div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ProjectDocumentSettingsManagement;
