import React, { useState } from 'react';
import { Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { colorFromPreset } from '../../util';
import { MailCategoryColorName } from '../../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DownOutlined } from '@ant-design/icons';
import CategoryTag from './CategoryTag';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
  },
  tag: {
    height: 20,
    border: 'none',
    borderRadius: 10,
    fontSize: theme.old.typography.fontSize.small,
    alignItems: 'center',
    '&.ant-tag': {
      padding: `0 ${theme.old.spacing.unit(1)}px`,
    },
    display: 'flex',
  },
  select: {
    width: '100%',
  },
  selectIcon: {
    marginRight: theme.old.spacing.unit(2),
  },
}));

interface ColorMapDto {
  [displayName: string]: MailCategoryColorName;
}

interface MessageDetailsCategorySelectProps {
  values: string[];
  colorMap: ColorMapDto;
  maxCount?: number | 'responsive';
  onChange?: (value: string[]) => void;
}

export const MessageDetailsCategorySelect: React.FC<
  MessageDetailsCategorySelectProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { values, colorMap, maxCount, onChange } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [open, setOpen] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleSelect = async (value: string) => {
    if (onChange) {
      onChange([...(values ?? []), value]);
    }
  };

  const handleDeselect = async (value: string) => {
    const indx = values.findIndex((category) => category === value);
    const nextCategories = values;
    nextCategories.splice(indx, 1);
    if (onChange) {
      onChange([...nextCategories]);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classes.root}>
      <Flex.Row alignItems="center" childrenGap={theme.old.spacing.baseSpacing}>
        <Select<string[]>
          mode={'multiple'}
          value={values ?? []}
          tagRender={(props) => <CategoryTag {...props} colorMap={colorMap} />}
          className={classes.select}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          onBlur={() => {
            setOpen(false);
          }}
          onFocus={() => {
            setOpen(true);
          }}
          onClick={(e) => {
            if (!open) {
              setOpen(true);
            }
            e.stopPropagation();
            e.preventDefault();
          }}
          open={open}
          autoFocus={open}
          maxTagCount={maxCount}
          dropdownMatchSelectWidth={250}
          suffixIcon={<DownOutlined onClick={() => setOpen(!open)} />}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Button
                style={{ borderTop: theme.old.borders.content, width: '100%' }}
                iconProp={['fal', 'sync']}
                type="link"
              >
                {t('mail:loadCategories')}
              </Button>
            </>
          )}
          bordered
        >
          {Object.entries(colorMap ?? []).map(([displayName, color]) => (
            <Select.Option value={displayName} key={displayName}>
              <Flex.Row alignItems="baseline">
                <FontAwesomeIcon
                  icon={['fas', 'tag']}
                  color={colorFromPreset(color, theme)}
                  className={classes.selectIcon}
                />
                <Flex.Item flex={1}>{displayName}</Flex.Item>
              </Flex.Row>
            </Select.Option>
          ))}
        </Select>
      </Flex.Row>
    </div>
  );
};

export default MessageDetailsCategorySelect;
