import internalOffices, * as fromInternalOffices from './internalOffices';
import externalOffices, * as fromExternalOffices from './externalOffices';
import { combineReducers } from 'redux';
import { ExternalOffice, InternalOffice } from '../../../models/Office';
import { OfficeId } from '../../../models/Types';
import { Office } from '../../../models/Office';

export interface OfficesState {
  internalOffices: fromInternalOffices.InternalOfficesState;
  externalOffices: fromExternalOffices.ExternalOfficesState;
}
export interface OfficesByIdState {
  [officeId: string]: InternalOffice | ExternalOffice;
}

export default combineReducers<OfficesState>({
  internalOffices,
  externalOffices,
});

//#region ------------------------------ All Offices
export const getOfficesByIdState: (state: OfficesState) => OfficesByIdState = (
  state
) => ({
  ...fromInternalOffices.getInternalOfficesByIdState(state.internalOffices),
  ...fromExternalOffices.getExternalOfficesByIdState(state.externalOffices),
});

export const getAllOffices: (state: OfficesState) => Office[] = (state) =>
  fromInternalOffices
    .getAllInternalOffices(state.internalOffices)
    .concat(fromExternalOffices.getAllExternalOffices(state.externalOffices));

export const getOffice: (state: OfficesState, officeID: OfficeId) => Office = (
  state,
  officeId
) => ({
  ...fromInternalOffices.getInternalOffice(state.internalOffices, officeId),
  ...fromExternalOffices.getExternalOffice(state.externalOffices, officeId),
});

export const getOfficeIds: (state: OfficesState) => string[] = (state) =>
  fromInternalOffices
    .getInternalOfficeIds(state.internalOffices)
    .concat(fromExternalOffices.getExternalOfficeIds(state.externalOffices));

export const getOfficesIsFetching: (state: OfficesState) => boolean = (state) =>
  fromInternalOffices.getIsFetching(state.internalOffices) &&
  fromExternalOffices.getIsFetching(state.externalOffices);

export const getOfficesHasError: (state: OfficesState) => boolean = (state) =>
  fromInternalOffices.getHasError(state.internalOffices) ||
  fromExternalOffices.getHasError(state.externalOffices);

export const getOfficesErrorMessages: (state: OfficesState) => string = (
  state
) => {
  if (
    fromInternalOffices.getHasError(state.internalOffices) ||
    fromExternalOffices.getHasError(state.externalOffices)
  )
    return 'companies:errorMessages.fetchOfficesError';
  return null;
};

//#region ------------------------------ Internal Office
export const getInternalOfficesByIdState: (
  state: OfficesState
) => fromInternalOffices.InternalOfficesByIdState = (state) =>
  fromInternalOffices.getInternalOfficesByIdState(state.internalOffices);

export const getInternalOfficeIds: (state: OfficesState) => string[] = (
  state
) => fromInternalOffices.getInternalOfficeIds(state.internalOffices);

export const getInternalOffice: (
  state: OfficesState,
  officeId: OfficeId
) => InternalOffice = (state, officeId) =>
  fromInternalOffices.getInternalOffice(state.internalOffices, officeId);

export const getAllInternalOffices: (
  state: OfficesState
) => InternalOffice[] = (state) =>
  fromInternalOffices.getAllInternalOffices(state.internalOffices);

export const getInternalOfficesIsFetching: (state: OfficesState) => boolean = (
  state
) => fromInternalOffices.getIsFetching(state.internalOffices);

export const getInternalOfficesHasError: (state: OfficesState) => boolean = (
  state
) => fromInternalOffices.getHasError(state.internalOffices);

export const getInternalOfficesErrorMessage: (state: OfficesState) => string = (
  state
) => fromInternalOffices.getErrorMessage(state.internalOffices);

//#region ------------------------------ External Office
export const getExternalOfficesByIdState: (
  state: OfficesState
) => fromExternalOffices.ExternalOfficesByIdState = (state) =>
  fromExternalOffices.getExternalOfficesByIdState(state.externalOffices);

export const getExternalOfficeIds: (state: OfficesState) => string[] = (
  state
) => fromExternalOffices.getExternalOfficeIds(state.externalOffices);

export const getExternalOffice: (
  state: OfficesState,
  officeId: OfficeId
) => ExternalOffice = (state, officeId) =>
  fromExternalOffices.getExternalOffice(state.externalOffices, officeId);

export const getAllExternalOffices: (
  state: OfficesState
) => InternalOffice[] = (state) =>
  fromExternalOffices.getAllExternalOffices(state.externalOffices);

export const getExternalOfficesIsFetching: (state: OfficesState) => boolean = (
  state
) => fromExternalOffices.getIsFetching(state.externalOffices);

export const getExternalOfficesHasError: (state: OfficesState) => boolean = (
  state
) => fromExternalOffices.getHasError(state.externalOffices);

export const getExternalOfficesErrorMessage: (state: OfficesState) => string = (
  state
) => fromExternalOffices.getErrorMessage(state.externalOffices);
