import React, { useState } from 'react';

import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import AddUsersDrawer from './AddUsersDrawer';
import NavigationBar from '../../../components/NavigationBar';
import { openAddUserDrawer } from '../actions/addUserDrawer';
import { useParams } from 'react-router-dom';
import PrioUserRoleManagement from './PrioUserRoleManagement';
import PrioOfficeRoleManagement from './PrioOfficeRoleManagement';
import GlobalAndOfficeNavigation from '../../../components/GlobalAndOfficeNavigation';
import useAccessRights from '../hooks/useAccessRights';
import { InternalOffice } from '../../../models/Office';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overvlow: 'hidden',
  },
  module: {
    height: '100%',
    overflow: 'hidden',
  },
  navigation: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  menuItem: {
    '&.ant-menu-item': {
      paddingRight: 34,
    },
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  userManagement: {
    padding: theme.old.spacing.defaultPadding,
    overflow: 'hidden',
  },
  userManagementSearchInput: {
    alignSelf: 'flex-end',
    width: 'inherit',
  },
  prioUserRoleManagement: {
    overflow: 'hidden',
  },
}));

interface UserRolePageProps {
  pathPrefix?: string;
}

export const UserRolePage: React.FC<UserRolePageProps> = (props) => {
  const classes = useStyles();
  const { pathPrefix } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { officeId } = useParams();

  const importUser = () => dispatch(openAddUserDrawer());

  const { showGlobalRoleSettings } = useAccessRights([
    'showGlobalRoleSettings',
  ]);

  const [selectedOffice, setSelectedOffice] = useState<InternalOffice>(null);

  const onSelect = (office: InternalOffice) => {
    setSelectedOffice(office);
  };
  return (
    <>
      <Flex.Column className={classes.root}>
        <NavigationBar>
          {!officeId && showGlobalRoleSettings && (
            <Button onClick={importUser} iconProp={['fal', 'user-plus']}>
              <span>{t('users:importAadUsers.buttons.importUsers')}</span>
            </Button>
          )}
        </NavigationBar>
        <Flex.Row className={classes.module}>
          <Flex.Column
            className={classNames('prio-module-navigation', classes.navigation)}
          >
            <Typography.Title className={classes.title}>
              {t('settings:settingsNav.navigation.user-roles')}
            </Typography.Title>
            <GlobalAndOfficeNavigation
              urlPrefix={pathPrefix ?? ''}
              globalMenuItems={[
                {
                  itemKey: 'user-roles',
                  icon: 'user-cog',
                  tString: 'users:roleNavigation.globalRoleSettings',
                  urlPrefix: officeId ? `${pathPrefix}../` : pathPrefix,
                },
              ]}
              officeMenuItems={[
                {
                  itemKey: 'user-roles',
                  icon: 'building',
                  tString: 'users:roleNavigation.globalRoleSettings',
                  urlPrefix: officeId ? pathPrefix : `${pathPrefix}user-roles/`,
                },
              ]}
              globalAccessMode={'showGlobalRoleSettings'}
              officeAccessMode={'showOfficeRoleSettings'}
              officeId={officeId}
              selectedList={officeId ?? 'user-roles'}
              onSelect={onSelect}
              showOpen
            />
          </Flex.Column>
          <Flex.Item flex={1} className={classes.prioUserRoleManagement}>
            {!officeId && showGlobalRoleSettings ? (
              <PrioUserRoleManagement
                mainComponentClassName={classes.userManagement}
                searchInputClassName={classes.userManagementSearchInput}
              />
            ) : (
              officeId && (
                <PrioOfficeRoleManagement
                  mainComponentClassName={classes.userManagement}
                  officeId={officeId}
                  showGlobalRoleSettings={showGlobalRoleSettings}
                  office={selectedOffice}
                />
              )
            )}
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>
      <AddUsersDrawer />
    </>
  );
};

export default UserRolePage;
