import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import GlobalAndOfficeNavigation, {
  NavItem,
} from '../../../components/GlobalAndOfficeNavigation';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { apiFetchConfigurations } from '../api';
import { Configuration } from '../../../models/Configuration';
import { ConfigurationKeys, OfficeId } from '../../../models/Types';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  menu: {
    background: 'none',
    border: 'none',
    height: '100%',
    padding: `0 ${theme.old.spacing.defaultPadding}px`,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  globalOfficeNavigation: {},
}));

interface TemplatesMenuProps {
  selectedList?: string;
  className?: string;
  urlPrefix?: string;
  officeId?: OfficeId;
}

export const TemplatesMenu: React.FC<TemplatesMenuProps> = (props) => {
  const { className, selectedList, urlPrefix, officeId } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);

  const globalMenu: NavItem[] = [
    {
      itemKey: 'documents',
      icon: 'file',
      tString: 'settings:templatesMenu.navigation.documents',
    },
    {
      itemKey: 'folders',
      icon: 'folder-tree',
      tString: 'settings:templatesMenu.navigation.folders',
    },
    {
      itemKey: 'textBlocks',
      icon: 'file-alt',
      tString: 'settings:templatesMenu.navigation.textBlocks',
    },
  ];

  const officeMenu: NavItem[] = [
    ...(configurations?.find(
      (config) => config.key === ConfigurationKeys.SHOW_OFFICE_SETTING_TEMPLATES
    )?.value === 'true'
      ? [
          {
            itemKey: 'documents',
            icon: 'file' as IconName,
            tString: 'settings:templatesMenu.navigation.documents',
          },
        ]
      : []),
    ...(configurations?.find(
      (config) =>
        config.key ===
        ConfigurationKeys.SHOW_OFFICE_SETTING_PROJECT_FILE_STRUCTURE
    )?.value === 'true'
      ? [
          {
            itemKey: 'folders',
            icon: 'folder-tree' as IconName,
            tString: 'settings:templatesMenu.navigation.folders',
          },
        ]
      : []),
    ...(configurations?.find(
      (config) =>
        config.key === ConfigurationKeys.SHOW_OFFICE_SETTING_TEXT_BLOCK
    )?.value === 'true'
      ? [
          {
            itemKey: 'textBlocks',
            icon: 'file-alt' as IconName,
            tString: 'settings:templatesMenu.navigation.textBlocks',
          },
        ]
      : []),
  ];

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames('prio-module-navigation', classes.root, className)}
    >
      <Typography.Title className={classes.title}>
        {t('settings:templatesMenu.title')}
      </Typography.Title>
      <GlobalAndOfficeNavigation
        className={classes.globalOfficeNavigation}
        urlPrefix={urlPrefix}
        selectedList={selectedList}
        globalMenuItems={globalMenu}
        officeMenuItems={officeMenu}
        globalAccessMode={'showGlobalTemplateSettings'}
        officeAccessMode={'showOfficeTemplateSettings'}
        officeId={officeId}
        showOpen
      />
    </Flex.Column>
  );
};
