import { ContactId, CompanyId } from '../../../models/Types';
import { Reducer } from 'redux';
import {
  OPEN_CONTACTS_DRAWER,
  CLOSE_CONTACTS_DRAWER,
  SET_CONTACTS_DRAWER_TAB,
  SET_CONTACTS_DRAWER_VIEW,
  ContactDrawerView,
  ContactTabs,
  SET_CONTACTS_DRAWER_STATE,
  OpenContactsDrawerAction,
  SetContactsDrawerViewAction,
  SetContactsDrawerTabAction,
  SetContactsDrawerStateAction,
} from '../actions/drawer';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { Contact } from '../../../models/Contact';

export interface ContactsDrawerState {
  open: boolean;
  tab: ContactTabs;
  view: ContactDrawerView;
  selectedContact?: ContactId;
  selectedCompany?: CompanyId;
  changedContact: {
    contact: Contact;
    state: 'edited' | 'created' | 'archived' | 'unarchived';
  };
}

type ContactsDrawerActions = OpenContactsDrawerAction &
  SetContactsDrawerTabAction &
  SetContactsDrawerViewAction &
  SetContactsDrawerStateAction;

const initialState: ContactsDrawerState = {
  open: false,
  tab: 'all',
  view: 'list',
  changedContact: null,
};

const reducer: Reducer<ContactsDrawerState, ContactsDrawerActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OPEN_CONTACTS_DRAWER: {
      const { predefinedState } = action;
      return {
        ...state,
        open: true,
        ...(predefinedState ?? {}),
      };
    }
    case CLOSE_CONTACTS_DRAWER: {
      return {
        ...state,
        open: false,
        selectedCompany: undefined,
        selectedContact: undefined,
      };
    }
    case SET_CONTACTS_DRAWER_STATE: {
      const { partialState } = action;
      return {
        ...state,
        ...partialState,
      };
    }
    case SET_CONTACTS_DRAWER_TAB: {
      const { tab } = action;
      return {
        ...state,
        tab,
      };
    }
    case SET_CONTACTS_DRAWER_VIEW: {
      const { view } = action;
      if (view === 'list')
        return {
          ...state,
          view,
          selectedCompany: undefined,
          selectedContact: undefined,
        };
      return {
        ...state,
        view,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;

export const getOpen = (state: ContactsDrawerState) => state.open;
