import { createContext } from 'react';
import { DocumentTemplateCategory } from '../../../models/DocumentTemplate';

export type DocumentTemplateCategoryContextProps = {
  data: DocumentTemplateCategory[];
  fetchDocumentTemplateCategories: (() => Promise<void>) | undefined;
};

const defaultValue: DocumentTemplateCategoryContextProps = {
  data: [],
  fetchDocumentTemplateCategories: undefined,
};

const DocumentTemplateCategoryContext =
  createContext<DocumentTemplateCategoryContextProps>(defaultValue);

export default DocumentTemplateCategoryContext;
