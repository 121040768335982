import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import ContactSearchPageTable, {
  ContactSearchPageTableRef,
} from './ContactSearchPageTable';
import { useDispatch } from 'react-redux';
import { openContactsDrawer, setContactsDrawerState } from '../actions/drawer';
import { CompanyId, ContactId } from '../../../models/Types';
import {
  ContactCompanyData,
  ContactCompanysCalculatedData,
  ContactSearchResultItem,
} from '../../../models/Contact';

interface ContactSearchPageProps {
  className?: string;
}

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    padding: theme.spacing.large,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ContactSearchPage: React.FC<ContactSearchPageProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const tableRef = useRef<ContactSearchPageTableRef>(null);
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [selectedSearchItems, setSelectedSearchItems] = useState<
    ContactSearchResultItem[]
  >([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onSearchItemClick = (item: ContactSearchResultItem) => {
    const isCompany = item.data.company;
    if (isCompany) {
      onCompanyClick(item.data.company.companyId);
      dispatch(openContactsDrawer({ view: 'companyDetail' }));
    } else {
      onContactClick(item.data.contact.contactId);
      dispatch(openContactsDrawer({ view: 'contactDetail' }));
    }
  };

  const handleOnRowSelectionChange = (item: ContactSearchResultItem[]) => {
    setSelectedSearchItems(item);
  };

  const onContactClick = (contactId: ContactId) => {
    dispatch(
      setContactsDrawerState({
        selectedContact: contactId,
        view: 'contactDetail',
      })
    );
  };
  const onCompanyClick = (companyId: CompanyId) => {
    dispatch(
      setContactsDrawerState({
        selectedCompany: companyId,
        view: 'companyDetail',
      })
    );
  };

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <FilterContextProvider<ContactCompanyData, ContactCompanysCalculatedData>
        searchType="companyContacts"
        equalityFunction={({ calculated: a }, { calculated: b }) =>
          a.name === b.name
        }
      >
        <FilterBar />
        <ContactSearchPageTable
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          ref={tableRef}
          selectedSearchItems={selectedSearchItems}
          tableId={'mcp1'}
          onRowClick={onSearchItemClick}
          onRowSelectionChange={handleOnRowSelectionChange}
        />
      </FilterContextProvider>
    </div>
  );
};

export default ContactSearchPage;
