import {
  Palette,
  Spacing,
  Borders,
  Typography,
  Components,
  BordersReduxState,
} from '.';

const _baseSpacing = 8;
const _rowGutter = 4;
const _borders: BordersReduxState = {
  colors: {
    base: '#ffffff',
    main: '#c2c7cc',
    sub: '#dde0e2',
    content: '#e8eaec',
  },
  width: {
    base: 1,
    bold: 4,
  },
  radius: 2,
};

const primaryColor = '#0052CC';

export const PRIO_DEFAULT_PALETTE: Palette = {
  primaryColor,
  backgroundPalette: {
    base: '#2b3d4e',
    main: '#dde0e2',
    sub: '#f5f6f7',
    content: '#ffffff',
    hover: {
      base: '#556471',
      main: '#d1d5d9',
      sub: '#e8eaec',
      content: '#f0f1f2',
    },
    active: {
      base: '#c2c7cc',
      main: '#b4c1d5',
      sub: '#cdd5e3',
      content: '#e6eaf1',
    },
    alpha: {
      content: 'rgba(255, 255, 255, 0.6)',
    },
  },
  chromaticPalette: {
    black: '#000',
    blue: '#106ebe',
    darkBlue: '#0a4a7c',
    lightBlue: '#b4c1d5',
    darkGreen: '#2e7254',
    darkYellow: '#DE843C',
    green: '#3E9871',
    grey: '#666666',
    lightGrey: '#999999',
    red: '#CF634B',
    yellow: '#EDA42D',
    purple: '#BF41E7',
    white: '#fff',
  },
  boxShadow: {
    regular: '0 0 8px rgb(0 0 0 / 25%)',
    large:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
  },
};

export const PRIO_DEFAULT_SPACING: Spacing = {
  baseSpacing: _baseSpacing,
  rowGutter: _rowGutter,
  unit: (x: number) => _baseSpacing * x,
  defaultPadding: 3 * _baseSpacing,
};

export const PRIO_DEFAULT_TYPOGRAPHY: Typography = {
  colors: {
    base: '#000000',
    inverted: '#ffffff',
    muted: 'rgba(0, 0, 0, 0.6)',
    invertedMuted: 'rgba(255, 255, 255, 0.6)',
  },
  fontWeight: {
    regular: 400,
    bold: 600,
  },
  fontSize: {
    h1: '24px',
    base: '15px',
    label: '14px',
    small: '13px',
    tiny: '12px',
  },
};

export const PRIO_DEFAULT_BORDERS: Borders = {
  ..._borders,
  content: `${_borders.width.base}px solid ${_borders.colors.content}`,
  sub: `${_borders.width.base}px solid ${_borders.colors.sub}`,
  main: `${_borders.width.base}px solid ${_borders.colors.main}`,
  boldMain: `${_borders.width.bold}px solid ${_borders.colors.main}`,
  dashedContent: `${_borders.width.base}px dashed ${_borders.colors.content}`,
  dashedSub: `${_borders.width.base}px dashed ${_borders.colors.sub}`,
  dashedHighlighted: `${_borders.width.base}px dashed ${primaryColor}`,
};

export const PRIO_DEFAULT_COMPONENTS: Components = {
  globalNavigationBar: {
    height: 48,
  },
  globalDropDown: {
    icon: {
      fontSize: 8,
    },
  },
  globalSider: {
    openWidth: 380,
    collapsedWidth: 72,
    css: {
      flexGrow: 1,
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: PRIO_DEFAULT_PALETTE.backgroundPalette.main,
    },
  },
  globalSiderNavigationHeadline: {
    lineHeight: '44px !important',
    marginBottom: '0px!important',
    paddingBottom: '8px!important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  drawerWidth: 720,
  menuMaxWidth: 320,
  mailMenuMaxWidth: 320,
  menuInlineIndent: 12,
  mailListItem: {
    spacing: 8,
    fontSize: 15,
    lineHeight: 1.5715,
    flaggedMessageBackground: '#fffdd9',
  },
  mailFolderItem: {
    spacing: 8,
    fontSize: 15,
    lineHeight: 1.5715,
    itemHeight: 40,
  },
  mailListFilter: {
    boxShadow: PRIO_DEFAULT_PALETTE.boxShadow.large,
    borderRadius: 2,
    border: 0,
    backgroundColor: PRIO_DEFAULT_PALETTE.backgroundPalette.content,
    backgroundClip: 'padding-box',
  },
  documentsTable: {
    tableColumnRowHeight: 40,
    tableRowHeight: 33,
    font: {
      fontSize: 13,
      headerFontSize: 15,
      lineHeight: 1.5715,
    },
    spacing: 6,
    padding: 20,
    documentsMenu: {
      width: 320,
    },
  },

  table: {
    iconColumn: {
      width: '64px',
    },
    primaryColumn: {
      width: '250px',
    },
    secondaryColumn: {
      fontSize: PRIO_DEFAULT_TYPOGRAPHY.fontSize.label,
      color: PRIO_DEFAULT_TYPOGRAPHY.colors.muted,
    },
    menuButton: {
      backgroundColor: PRIO_DEFAULT_PALETTE.backgroundPalette.hover.main,
      color: PRIO_DEFAULT_TYPOGRAPHY.colors.base,
    },
  },
  addressBar: {
    backgroundColor: '#e3edf7',
  },
  documentIcon: {
    size: '18px',
  },
  sider: {
    collapsedWidth: 72,
  },
  tag: {
    border: 0,
    borderRadius: '1000px',
    background: PRIO_DEFAULT_PALETTE.chromaticPalette.grey,
    color: PRIO_DEFAULT_TYPOGRAPHY.colors.inverted,
  },
  navigation: {
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  timeline: {
    smallSegmentBorder: '1px solid #f2f3f4',
  },
};
