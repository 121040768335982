import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Collapse,
  Menu,
  Dropdown,
  Button,
  Modal,
  Divider,
} from 'antd';

import {
  getCurrentRemoteItem,
  getProject,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Project } from '../../../models/Project';
import { DriveUserRemoteItem } from '../../../models/Document';
import classNames from 'classnames';
import {
  deleteRemoteFolder,
  setCurrentRemoteItem,
} from '../actions/remoteFolders';
import { replaceMyDriveItemPath } from '../util';
import { DropTargetMonitor } from 'react-dnd';
import { DndDriveItemDto, DND_TYPE_DRIVE_ITEM_FILE } from '../../../dnd/types';
import DroppableElement from '../../../dnd/components/DroppableElement';
import { DriveItemDropTarget } from '../../../models/Drive';
import DocumentSettingsDrawer from './Drawers/DocumentSettingsDrawer';

import { makePrioStyles } from '../../../theme/utils';
import { DriveFavorite } from '../../../models/DriveFavorite';
import {
  deleteDriveFavorites,
  updateDriveFavorite,
} from '../actions/driveFavorites';
import RenameDriveFavoriteModal from './Modals/RenameDriveFavoriteModal';
import { DriveFavoriteMenuItem } from './DriveFavoriteMenuItem';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { createSelector } from 'reselect';
import {
  DriveFavoriteId,
  ProjectId,
  RemoteDriveItemId,
} from '../../../models/Types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
  },
  settingsSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  settingsDivider: {
    margin: 0,
    marginLeft: '24px',
    width: 'calc(100% - 48px)',
    minWidth: 'calc(100% - 48px)',
    borderColor: theme.old.borders.colors.main,
  },
  settingsButton: {
    fontSize: theme.old.typography.fontSize.base,
    width: '100%',
    height: '64px',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.main,
    },
  },
  settingsButtonContent: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '8px',
    gap: '10px',
    alignItems: 'center',
  },
  settingsIcon: {
    color: 'rgba(0,0,0,0.6)',
  },
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.components.documentsTable.spacing * 2}px ${
      theme.old.components.documentsTable.padding
    }px`,
    margin: '0!important',
  },
  collapseContainer: {
    overflowY: 'auto',
    height: '100%',
  },
  collapse: {
    backgroundColor: 'transparent',
    '& > .ant-collapse-item': {
      borderBottom: 'none',
    },
    '& > .ant-collapse-item-active > .ant-collapse-header': {
      fontWeight: theme.old.typography.fontWeight.bold,
    },
    '&.ant-collapse > .ant-collapse-item': {
      paddingTop: theme.old.components.documentsTable.spacing,
      paddingBottom: theme.old.components.documentsTable.spacing,
      paddingLeft: theme.old.components.documentsTable.padding,
      paddingRight: theme.old.components.documentsTable.padding,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingTop: theme.old.components.documentsTable.spacing,
      paddingBottom: theme.old.components.documentsTable.spacing,
      paddingLeft: 0,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
  },
  deleteIconRow: {
    display: 'flex',
    gap: '5px',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menu: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
    background: 'none',
    border: 'none',
    '&.ant-menu .ant-menu-item-selected:after': {
      display: 'none',
    },
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      paddingRight: 0,
    },
    '& .ant-menu-item:hover > span > a > div > button': {
      display: 'inline-block',
    },
    '& .ant-menu-item > span > a > div > button': {
      display: 'none',
    },
    '& .ant-menu-item-selected > span > a > div > button': {
      display: 'inline-block',
    },
  },
  collapseHeader: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  rowMenuButtonIcon: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
  },
  menuItem: {},
  innerMenuItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
  },
  innerMenuItemSelected: {
    paddingLeft: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.active.main,
  },
  menuItemContent: {
    overflow: 'hidden',
  },
  menuItemTitle: {
    lineHeight: '25px',
    userSelect: 'none',
  },
  menuButtonDefault: {
    padding: '0!important',
    height: 36,
    width: 40,
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
  },
  menuButtonSmall: {
    height: 40,
  },
}));

const remoteItemsSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: RootReducerState) => {
        [id: RemoteDriveItemId]: DriveUserRemoteItem;
      },
      (state: RootReducerState) => RemoteDriveItemId[],
    ],
    DriveUserRemoteItem[]
  >(
    (state) => state.documents.remoteFolders.byId,
    (state) => state.documents.remoteFolders.ids[projectId],
    (byId, ids) =>
      (ids ?? [])
        .map((id) => byId[id])
        .filter((item) => item !== undefined)
        .sort((a, b) => a.name.localeCompare(b.name))
  );

const driveFavoritesSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: RootReducerState) => { [id: DriveFavoriteId]: DriveFavorite },
      (state: RootReducerState) => DriveFavoriteId[],
    ],
    DriveFavorite[]
  >(
    (state) => state.documents.driveFavorites.byId,
    (state) => state.documents.driveFavorites.ids,
    (byId, ids) =>
      (ids ?? [])
        .map((id) => byId[id])
        .filter((x) => (projectId ? x?.projectId === projectId : true))
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
  );

declare type DocumentMenuItemType = 'documentFavorites' | 'remoteFolders';
interface DocumentsMenuProps {
  className?: string;
  projectId: string;
  pathPrefix?: string;
}

export const DocumentsMenu: React.FC<DocumentsMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { className, projectId, pathPrefix } = props;
  const { t } = useTranslation();
  const { selectedList, driveItemId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [documentSettingsDrawerOpen, setDocumentSettingsDrawerOpen] =
    useState<boolean>(false);

  const [renameDriveFavorite, setRenameDriveFavorite] =
    useState<DriveFavorite>(null);

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const remoteItems = useSelector(remoteItemsSelector(projectId));

  const driveFavorites = useSelector(driveFavoritesSelector(projectId));

  const [selectedDriveFavorites, setSelectedDriveFavorites] = useState<
    DriveFavorite[]
  >([]);

  const isAllSelected = selectedList === 'all';

  const currentRemoteItem = useSelector(getCurrentRemoteItem);
  const isAllRemote = currentRemoteItem?.isRootFolder;

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const menu = (item: DriveUserRemoteItem) => (
    <Menu>
      <Menu.Item
        style={{ height: theme.old.components.documentsTable.tableRowHeight }}
        onClick={(e) => {
          e.domEvent.stopPropagation();
          Modal.confirm({
            icon: null,
            title: t('documents:remoteItems.confirmation.delete.title'),
            content: (
              <Flex.Column>
                <Typography.Text>
                  {t('documents:remoteItems.confirmation.delete.content')}
                </Typography.Text>
                <Typography.Text>{item.name}</Typography.Text>
              </Flex.Column>
            ),
            okText: t('documents:remoteItems.confirmation.delete.okText'),
            cancelText: t(
              'documents:remoteItems.confirmation.delete.cancelText'
            ),
            onOk() {
              const promise = new Promise<void>(async (resolve) => {
                dispatch(deleteRemoteFolder(item.driveUserRemoteItemId, item));

                resolve();
              });
              return promise;
            },
            onCancel() {},
          });
        }}
      >
        {t('documents:remoteItems.actions.delete')}
      </Menu.Item>
    </Menu>
  );

  const handleMenuItemClick = (item: DriveUserRemoteItem | null) => {
    dispatch(setCurrentRemoteItem(item?.driveUserRemoteItemId ?? null));
  };

  const openDocumentSettingsDrawer = () => {
    setDocumentSettingsDrawerOpen(true);
  };
  const closeDocumentSettingsDrawer = () => {
    setDocumentSettingsDrawerOpen(false);
  };

  const onOpenDriveFavoriteRenameModal = (driveFavorite: DriveFavorite) => {
    setRenameDriveFavorite(driveFavorite);
  };
  const onRenameDriveFavorite = (
    driveFavorite: DriveFavorite,
    newName: string
  ) => {
    dispatch(
      updateDriveFavorite(
        {
          displayName: newName,
        },
        driveFavorite
      )
    );
    onCloseDriveFavoriteRenameModal();
  };

  const onCloseDriveFavoriteRenameModal = () => {
    setRenameDriveFavorite(null);
  };

  const onDeleteItems = (type: DocumentMenuItemType) => {
    if (type === 'documentFavorites') {
      dispatch(deleteDriveFavorites(selectedDriveFavorites));
    }
    setSelectedDriveFavorites([]);
  };

  //#endregion

  //#region -------------------------------- Effects
  useEffect(() => {
    setSelectedDriveFavorites([]);
  }, [driveItemId]);
  //#endregion

  return (
    <>
      <Flex.Column
        flexGrow={1}
        className={classNames(
          'prio-module-navigation',
          classes.root,
          className
        )}
        justifyContent="space-between"
      >
        <Typography.Title className={classes.title}>
          {t('documents:titles.documents')}
        </Typography.Title>
        <div className={classes.collapseContainer}>
          <Collapse
            bordered={false}
            className={classes.collapse}
            defaultActiveKey={[
              'mainProject',
              'documentFavorites',
              'remoteFolders',
            ]}
          >
            {project && (
              <Collapse.Panel
                className={classes.collapseHeader}
                key={'mainProject'}
                header={`${project?.number ?? '...'} ${
                  project?.shortName ?? ''
                }`}
              >
                <Menu
                  className={classes.menu}
                  mode="inline"
                  selectedKeys={
                    !isAllRemote && isAllSelected ? ['all'] : undefined
                  }
                >
                  <Menu.Item
                    key={'all'}
                    onClick={() => handleMenuItemClick(null)}
                    style={{
                      height:
                        theme.old.components.documentsTable.tableRowHeight,
                    }}
                  >
                    <Link to={`${pathPrefix ?? ''}all`}>
                      <FontAwesomeIcon
                        fixedWidth
                        icon={['fal', 'folder-tree']}
                        className={classes.icon}
                      />
                      {t(`documents:menu.all`)}
                    </Link>
                  </Menu.Item>
                </Menu>
              </Collapse.Panel>
            )}
            {driveFavorites.length > 0 && (
              <Collapse.Panel
                className={classes.collapseHeader}
                key={'documentFavorites'}
                header={t('documents:menu.documentFavorites')}
                extra={
                  selectedDriveFavorites.length > 0 && (
                    <div className={classes.deleteIconRow}>
                      <div>
                        {`${selectedDriveFavorites.length}/${driveFavorites.length}`}
                      </div>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onDeleteItems('documentFavorites');
                        }}
                      >
                        <FontAwesomeIcon icon={['fal', 'trash']} />
                      </Button>
                    </div>
                  )
                }
              >
                <Menu
                  className={classes.menu}
                  mode="inline"
                  selectedKeys={
                    isAllRemote && isAllSelected ? ['all'] : [driveItemId]
                  }
                >
                  {driveFavorites.map((item) => (
                    <Menu.Item
                      key={item.driveItemId}
                      title={item.displayName}
                      style={{
                        paddingLeft: 0,
                        height:
                          theme.old.components.documentsTable.tableRowHeight,
                      }}
                    >
                      <DriveFavoriteMenuItem
                        isSelected={selectedDriveFavorites.some(
                          (x) => x.driveFavoriteId === item.driveFavoriteId
                        )}
                        onSelect={(driveFavorite, isSelected) => {
                          if (!isSelected) {
                            setSelectedDriveFavorites([
                              ...selectedDriveFavorites,
                              driveFavorite,
                            ]);
                          } else {
                            setSelectedDriveFavorites(
                              selectedDriveFavorites.filter(
                                (x) =>
                                  x.driveFavoriteId !== item.driveFavoriteId
                              )
                            );
                          }
                        }}
                        driveFavorite={item}
                        onRename={onOpenDriveFavoriteRenameModal}
                        pathPrefix={pathPrefix}
                      />
                    </Menu.Item>
                  ))}
                </Menu>
              </Collapse.Panel>
            )}
            {project && remoteItems?.length > 0 && (
              <Collapse.Panel
                className={classes.collapseHeader}
                key={'remoteFolders'}
                header={t('documents:menu.remoteFolders')}
              >
                <Menu
                  className={classes.menu}
                  mode="inline"
                  selectedKeys={
                    isAllRemote && isAllSelected ? ['all'] : [driveItemId]
                  }
                >
                  {remoteItems.map((item) => (
                    <Menu.Item
                      key={item.isRootFolder ? 'all' : item.remoteItemId}
                      className={classNames({
                        [classes.menuItem]: !!item.path,
                      })}
                      title={
                        item.path
                          ? replaceMyDriveItemPath(item, project)
                          : undefined
                      }
                      onClick={() => handleMenuItemClick(item)}
                      style={{
                        paddingLeft: 0,
                        height:
                          theme.old.components.documentsTable.tableRowHeight,
                      }}
                    >
                      <RemoteItem
                        remoteItem={item}
                        pathPrefix={pathPrefix}
                        menu={menu}
                      />
                    </Menu.Item>
                  ))}
                </Menu>
              </Collapse.Panel>
            )}
          </Collapse>
        </div>
        <div className={classes.settingsSection}>
          <Divider className={classes.settingsDivider} />
          <Button
            type="text"
            className={classes.settingsButton}
            onClick={openDocumentSettingsDrawer}
          >
            <div className={classes.settingsButtonContent}>
              <FontAwesomeIcon
                className={classes.settingsIcon}
                icon={['fal', 'cog']}
              />
              {t('documents:settings.title')}
            </div>
          </Button>
        </div>
      </Flex.Column>
      <DocumentSettingsDrawer
        open={documentSettingsDrawerOpen}
        onClose={closeDocumentSettingsDrawer}
      />
      {renameDriveFavorite && (
        <RenameDriveFavoriteModal
          driveFavorite={renameDriveFavorite}
          onOk={onRenameDriveFavorite}
          onClose={onCloseDriveFavoriteRenameModal}
        />
      )}
    </>
  );
};

export default DocumentsMenu;

interface RemoteItemProps {
  remoteItem: DriveUserRemoteItem;
  pathPrefix?: string;
  menu: (item: DriveUserRemoteItem) => JSX.Element;
}

const RemoteItem: React.FC<RemoteItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { remoteItem, pathPrefix, menu } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onRemoteDriveItemFolderDrop = (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    target: DriveUserRemoteItem
  ) => {
    if (monitor.didDrop()) {
      return undefined;
    }
    const dndType = monitor.getItemType();

    switch (dndType) {
      case DND_TYPE_DRIVE_ITEM_FILE: {
        // const convertedItem = item as DndDriveItemDto & {
        //   projectId: ProjectId;
        // };
        return {
          type: 'remoteDriveItem',
          object: target,
        } as DriveItemDropTarget;
        // return {
        //   ...convertedItem,
        //   dropTargetType: 'driveItem',
        //   destinationProject: project,
        //   destinationDriveItemId: remoteItem.remoteItemId,
        //   destinationDriveItemIsRoot: remoteItem.isRootFolder,
        // };
      }
      default: {
        return undefined;
      }
    }
  };

  const disableDropRemoteDriveItemFolder = (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    targetDropItem: DriveUserRemoteItem
  ) => {
    const draggedElement = monitor.getItem() as DndDriveItemDto;
    const ids = (draggedElement.driveItems ?? [draggedElement.driveItem]).map(
      (driveItem) => driveItem.id
    );
    return ids.includes(remoteItem.remoteItemId);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.innerMenuItem)}>
      <DroppableElement
        activeOverlay
        accept={[DND_TYPE_DRIVE_ITEM_FILE]}
        onDrop={onRemoteDriveItemFolderDrop}
        disable={disableDropRemoteDriveItemFolder}
        object={remoteItem}
      >
        <Link
          to={`${pathPrefix ?? ''}${
            remoteItem.isRootFolder
              ? 'all'
              : `folder/${remoteItem.remoteItemId}`
          }`}
        >
          <Flex.Row alignItems="center">
            <Flex.Column flex={1} className={classes.menuItemContent}>
              <Flex.Item className={classes.menuItemTitle}>
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', 'folder']}
                  className={classes.icon}
                />
                {remoteItem.name}
              </Flex.Item>
            </Flex.Column>
            <Dropdown
              overlay={menu(remoteItem)}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                icon={
                  <FontAwesomeIcon
                    className={classes.rowMenuButtonIcon}
                    icon={['fal', 'ellipsis-v']}
                  />
                }
                className={classNames(classes.menuButtonDefault, {
                  [classes.menuButtonSmall]: !remoteItem.path,
                })}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            </Dropdown>
          </Flex.Row>
        </Link>
      </DroppableElement>
    </div>
  );
};
