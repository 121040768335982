import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@prio365/prio365-react-library';
import { DoodleType } from '../../../models/Doodle';
import { useDispatch } from 'react-redux';
import { disableDoodle } from '../actions';
import doodleJson from '../doodle.json';
import HappyEaster from './HappyEaster';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import MerryChristmas from './MerryChristmas';
import WelcomeBack from './WelcomeBack';
import { useKeyboardListener } from '@prio365/prio365-react-library';
import Florian from './Florian';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButton: {
    position: 'absolute',
    top: 24,
    right: 24,
  },
  backButtonWhite: {
    color: '#fff',
  },
}));

interface MainDoodleProps {
  type: DoodleType;
}

export const MainDoodle: React.FC<MainDoodleProps> = (props) => {
  //#region ------------------------------ Defaults
  const { type } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClose = () => {
    dispatch(disableDoodle(type, doodleJson[type]));
  };
  //#endregion

  //#region ------------------------------ Components
  let content: ReactNode = null;
  switch (type) {
    case 'happyEaster': {
      content = <HappyEaster />;
      break;
    }
    case 'merryChristmas': {
      content = <MerryChristmas />;
      break;
    }
    case 'florian': {
      content = <Florian />;
      break;
    }
    case 'surprise': {
      content = <WelcomeBack />;
      break;
    }
    default: {
      content = null;
      break;
    }
  }
  //#endregion

  //#region ------------------------------ Effects
  useKeyboardListener({
    Escape: () => {
      handleClose();
    },
    Enter: () => {
      handleClose();
    },
  });
  //#endregion

  return (
    <div className={classes.root}>
      {content}
      <Button
        type="link"
        className={classNames(classes.backButton, {
          [classes.backButtonWhite]: type === 'merryChristmas',
        })}
        onClick={handleClose}
        iconProp={['fal', 'times']}
      >
        <span>{t('doodle:goBack')}</span>
      </Button>
    </div>
  );
};

export default MainDoodle;
