import { combineReducers } from 'redux';
import mailFolders, * as fromMailFolders from './mailFoldersMe';
import messages, * as fromMessages from './messagesMe';
import drafts, * as fromDrafts from './draftsMe';
import mailNavigation, * as fromMailNavigation from './mailNavigation';
import categories, * as fromCategories from './categoriesMe';
import search, * as fromSearch from './searchMe';
import specialMailFolder, * as fromSpecialMailFolder from './specialMailFolderMe';
import { MailFolder } from '../../../../models/MailFolder';
import { Message } from '../../../../models/Message';
import {
  MailCategoryColorName,
  MailFolderId,
  MessageId,
} from '../../../../models/Types';
import {
  MessageIdsByMailFolderId,
  ByMessageId,
  DraftMessagesState,
  SpecialMailFolders,
  MailListNavigationState,
  MailFolderByMailFolderId,
} from '../../actions/types';
import { AdvancedMailSearchDto } from '../../../../models/MailSearch';

export interface MailMeState {
  mailFolders: fromMailFolders.MailFoldersState;
  messages: fromMessages.MessagesState;
  drafts: DraftMessagesState;
  mailNavigation: fromMailNavigation.MailNavigationState;
  categories: fromCategories.CategoryState;
  search: fromSearch.MailSearchState;
  specialMailFolder: SpecialMailFolders;
}

export default combineReducers<MailMeState>({
  mailFolders,
  messages,
  drafts,
  mailNavigation,
  categories,
  search,
  specialMailFolder,
});

//#region ------------------------------ mailFoldersMe
export const getAllMailFoldersMe: (state: MailMeState) => Array<MailFolder> = (
  state
) => fromMailFolders.getAllMailFolders(state.mailFolders);
export const getMeMailFoldersById: (
  state: MailMeState
) => MailFolderByMailFolderId = (state) =>
  fromMailFolders.getMailFoldersById(state.mailFolders);
export const getMailFolderMe: (state: MailMeState, id: string) => MailFolder = (
  state,
  id
) => fromMailFolders.getMailFolder(state.mailFolders, id);

export const getMailFoldersMeIsFetching: (state: MailMeState) => boolean = (
  state
) => fromMailFolders.getIsFetching(state.mailFolders);
export const getMailFoldersMeHasError: (state: MailMeState) => boolean = (
  state
) => fromMailFolders.getHasError(state.mailFolders);
export const getMailFoldersMeErrorMessage: (state: MailMeState) => string = (
  state
) => fromMailFolders.getErrorMessage(state.mailFolders);

export const getActiveMailFolderIdMe: (state: MailMeState) => string = (
  state
) => fromMailFolders.getActiveMailFolderId(state.mailFolders);

export const getOpenMailFolderKeysMe: (state: MailMeState) => MailFolderId[] = (
  state
) => fromMailFolders.getOpenMailFolderKeys(state.mailFolders);
//#endregion

//#region ------------------------------ messagesMe
export const getAllMessagesMe: (
  state: MailMeState,
  mailFolderId: string
) => Array<Message> = (state, mailFolderId) =>
  fromMessages.getAllMessagesMe(state.messages, mailFolderId);
export const getMessageMe: (state: MailMeState, id: string) => Message = (
  state,
  id
) => fromMessages.getMessage(state.messages, id);
export const getMessagesMeById: (state: MailMeState) => {
  [messageId: string]: Message;
} = (state) => fromMessages.getMessagesById(state.messages);
export const getMessagesMeIsFetching: (
  state: MailMeState,
  mailFolderId?: MailFolderId
) => boolean = (state, mailFolderId) =>
  fromMessages.getIsFetching(state.messages, mailFolderId);
export const getMessagesMeHasError: (state: MailMeState) => boolean = (state) =>
  fromMessages.getHasError(state.messages);

export const getIsSingleMessageMeFetching: (state: MailMeState) => boolean = (
  state
) => fromMessages.getIsSingleMessageFetching(state.messages);
export const getMessagesMeNextLink: (
  state: MailMeState,
  mailFolderId: string
) => string = (state, mailFolderId) =>
  fromMessages.getNextLink(state.messages, mailFolderId);

export const getMessagesMeErrorMessage: (state: MailMeState) => string = (
  state
) => fromMessages.getErrorMessage(state.messages);
export const getMessagesMeByIdState: (state: MailMeState) => ByMessageId = (
  state
) => state.messages.byId;
export const getMessagesMeIdsState: (
  state: MailMeState
) => MessageIdsByMailFolderId = (state) => state.messages.ids;
//#endregion

//#region ------------------------------ metaMe
export const getDraftMessagesMe: (state: MailMeState) => Message[] = (state) =>
  fromDrafts.getDraftMessages(state.drafts);

export const getDraftMessageMe: (
  state: MailMeState,
  messageId: MessageId
) => Message = (state, messageId) =>
  fromDrafts.getDraftMessage(state.drafts, messageId);

export const getDraftMessageIsInitialMe: (
  state: MailMeState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromDrafts.getDraftMessageIsInitial(state.drafts, messageId);

export const getActiveDraftMessageIdMe: (state: MailMeState) => MessageId = (
  state
) => fromDrafts.getActiveDraftMessageId(state.drafts);

export const getSendingMessageIdsMe: (state: MailMeState) => MessageId[] = (
  state
) => fromDrafts.getSendingMessageIds(state.drafts);
//#endregion

//#region ------------------------------ categoriesMe
export const getColorForMessageCategoryMe: (
  state: MailMeState,
  displayName: string
) => string = (state, displayName) =>
  fromCategories.getColorForMessageCategory(state.categories, displayName);

export const getMessageCategoryColorMapMe: (state: MailMeState) => {
  [displayName: string]: MailCategoryColorName;
} = (state) => fromCategories.getMessageCategoryColorMap(state.categories);
//#endregion

//#region ------------------------------ search
export const getSearchMeIsFetching: (
  state: MailMeState,
  mailFolderId?: MailFolderId
) => boolean = (state, mailFolderId) =>
  fromSearch.getIsFetching(state.search, mailFolderId);
export const getSearchMeHasError: (state: MailMeState) => boolean = (state) =>
  fromSearch.getHasError(state.search);
export const getSearchMeErrorMessage: (state: MailMeState) => string = (
  state
) => fromSearch.getErrorMessage(state.search);

export const getSearchMeByIdState: (state: MailMeState) => ByMessageId = (
  state
) => fromSearch.getMessagesById(state.search);
export const getSearchMeIds: (state: MailMeState) => string[] = (state) =>
  fromSearch.getMessageIds(state.search);

export const getSearchMeIdsState: (state: MailMeState) => { me: string[] } = (
  state
) => ({ me: fromSearch.getMessageIds(state.search) });

export const getSearchMessageMe: (
  state: MailMeState,
  id: MessageId
) => Message = (state, id) => fromSearch.getMessage(state.search, id);

export const getSearchMessagesMe: (state: MailMeState) => Message[] = (state) =>
  fromSearch.getMessages(state.search);

export const getSearchMeNextLink: (
  state: MailMeState,
  mailFolderId: MailFolderId
) => string = (state, mailFolderId) =>
  fromSearch.getNextLink(state.search, mailFolderId);

export const getlastSearchTermMailMe: (
  state: MailMeState
) => string | AdvancedMailSearchDto = (state) =>
  fromSearch.getlastSearchTermMail(state.search);

export const getCurrentSearchTermMe: (
  state: MailMeState
) => string | AdvancedMailSearchDto = (state) =>
  fromSearch.getCurrentSearchTerm(state.search);

export const getCurrentSearchMailFolderIdMe: (
  state: MailMeState
) => MailFolderId = (state) =>
  fromSearch.getCurrentSearchMailFolderId(state.search);

export const getCurrentSearchKeywordsMe: (state: MailMeState) => string = (
  state
) => fromSearch.getCurrentSearchKeywords(state.search);
//#endregion

//#region ------------------------------ specialMailFoldersMe
export const getSpecialMailFoldersMe: (
  state: MailMeState
) => SpecialMailFolders = (state) =>
  fromSpecialMailFolder.getSpecialMailFolders(state.specialMailFolder);

export const isDeletedFolderMe: (
  state: MailMeState,
  mailFolderId: MailFolderId
) => boolean = (state, mailFolderId) =>
  fromSpecialMailFolder.isDeletedFolder(state.specialMailFolder, mailFolderId);

export const isSendFolderMe: (
  state: MailMeState,
  mailFolderId: MailFolderId
) => boolean = (state, mailFolderId) =>
  fromSpecialMailFolder.isSendFolder(state.specialMailFolder, mailFolderId);

export const isDraftFolderMe: (
  state: MailMeState,
  mailFolderId: MailFolderId
) => boolean = (state, mailFolderId) =>
  fromSpecialMailFolder.isDraftFolder(state.specialMailFolder, mailFolderId);
//#endregion

//#region ------------------------------ mailNavigation
export const getMailMeListNavigationState: (
  state: MailMeState
) => MailListNavigationState = (state) =>
  fromMailNavigation.getListNavigationStateMe(state.mailNavigation);

export const isActiveMailMeEntry: (
  state: MailMeState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMailNavigation.isActiveMe(state.mailNavigation, messageId);

export const isActiveScrollableMailMeEntry: (
  state: MailMeState,
  messageId: MessageId
) => boolean = (state, messageId) =>
  fromMailNavigation.isActiveAndScrollMe(state.mailNavigation, messageId);

export const getActiveMessageMeId: (state: MailMeState) => MessageId = (
  state
) => fromMailNavigation.getActiveMessageIdMe(state.mailNavigation);

export const getMailMeMenuOpen: (state: MailMeState) => boolean = (state) =>
  fromMailNavigation.getMailMenuOpenMe(state.mailNavigation);
//#endregion
