import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import MailSelectionList from '../MailSelectionList';
import MessagePage from '../MessagePage';
import { MailLayoutProps } from '../../util';
import { updateMailSettings } from '../../../userSettings/actions/mailSettings/mail';
import classNames from 'classnames';
import MailListResizable from '../MailListResizable';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../../../theme/utils';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  mailMessageMenuRow: {
    overflow: 'hidden',
    borderRight: theme.old.borders.sub,
    zIndex: 1,
  },
  detailsPane: {
    overflow: 'hidden',
    paddingLeft: theme.old.spacing.unit(0.5),
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  content: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  searchExpandedDetailsPane: {
    paddingTop: (props: any) =>
      2 * (theme.old.spacing.unit(2) / (props?.lessSpacing ?? 1)) + 32,
  },
}));

export const MailRowLayout: React.FC<MailLayoutProps> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId, selectedMessages, onSelectionChange, mailSettings } =
    props;
  const listWidth = mailSettings.mailNavigationSize?.width;
  const classes = useStyles({
    ...props,
    lessSpacing:
      mailSettings.mailListSpacing === 'full'
        ? 1
        : mailSettings.mailListSpacing === 'middle'
        ? 2
        : 4,
  });

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [searchIsExpanded, setSearchIsExpanded] = useState<boolean>(false);
  //#endregion

  return (
    <Flex.Row flex={1} className={classes.content}>
      <MailListResizable
        size={{ width: `${mailSettings.mailNavigationSize?.width}` }}
        listLayout="horizontal"
        onResizeStop={(e, d, r, delta) =>
          dispatch(
            updateMailSettings({
              mailNavigationSize: {
                ...mailSettings.mailNavigationSize,
                width: mailSettings.mailNavigationSize.width + delta.width,
              },
            })
          )
        }
      />
      <Routes>
        <Route
          path={`${projectId === 'favorites' ? 'inbox' : ':mailFolderId'}/*`}
          element={
            <MailSelectionList
              className={classes.mailMessageMenuRow}
              projectId={projectId}
              selectedMessages={selectedMessages}
              onSelectionChange={onSelectionChange}
              itemLayout="vertical"
              mailListSpacing={mailSettings.mailListSpacing}
              mailListNavigationWidth={mailSettings.mailNavigationSize.width}
              automaticRead={mailSettings.automaticRead}
              listWidth={listWidth}
              listLayout="right"
              onSearchExpandedChange={setSearchIsExpanded}
              mailFolderId={projectId === 'favorites' ? 'inbox' : undefined}
            />
          }
        />
      </Routes>
      <Flex.Item
        flex={3}
        className={classNames(classes.detailsPane, {
          [classes.searchExpandedDetailsPane]: searchIsExpanded,
        })}
      >
        <MessagePage projectId={projectId} />
      </Flex.Item>
    </Flex.Row>
  );
};

export default MailRowLayout;
