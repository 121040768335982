import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import {
  Typography,
  Tooltip,
  Divider,
  Select,
  Tabs,
  Modal,
  notification,
  Popover,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import {
  FlagStatus,
  Message,
  MessageAttachment,
  MessageCenterMessage,
  MessageRecipient,
} from '../../../../models/Message';
import { downloadAttachment } from '../EmailComposer/EmailComposer';
import {
  ContactId,
  MailCategoryColorName,
  MessageId,
  ProjectId,
} from '../../../../models/Types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DriveItem } from '../../../../models/Drive';
import {
  getContact,
  getCurrentFolderItem,
  getMailSettings,
  getMessageCategoryColorMap,
  getProject,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { Project } from '../../../../models/Project';
import { useDispatch, useSelector } from 'react-redux';
import MailCategoriesSelector from '../Categories/MailCategoriesSelector';
import { CreateTaskRequest } from '../../../../models/Task';
import TasksDrawer from '../../../tasks/components/TasksDrawer';
import {
  CopyableTextTile,
  copyToClipboard,
} from '../../../../components/CopyableTextTile';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import PrioSpinner from '../../../../components/PrioSpinner';
import { html2text } from '../../util';
import RenameAttachmentsDrawer from '../RenameAttachmentsDrawer';
import { longDateFormatString } from '../../../../util';
import i18n from '../../../../i18n';
import MessageAttachmentList from '../MessageAttachmentList';
import MessageDisplayMainContent, {
  HtmlTextContentRef,
} from './HtmlTextContent';
import QuickActionButtonRow from './QuickActionButtonRow';
import DraftListContainer from './DraftListContainer';
import classNames from 'classnames';
import {
  flagMessage,
  updateMessage,
} from '../../actions/actionControllers/messageActionController';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import ContactPicker from '../../../contacts/components/ContactPicker';
import { Contact } from '../../../../models/Contact';
import { apiAssignMessage, apiUnassignMessage } from '../../api';
import UserAvatar from '../../../../components/UserAvatar';
import { AutoSizer } from 'react-virtualized';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxHeight: '100%',
    '& .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more': {
      padding: '0 16px',
    },
  },
  tabsViewCompressedView: {
    paddingTop: '0px !important',
    padding: '12px !important',
  },
  tabs: {
    padding: theme.old.spacing.defaultPadding,
    paddingTop: 0,
    '& .ant-tabs-content-holder': {
      boxShadow: theme.old.palette.boxShadow.regular,
    },
    '& .ant-tabs-nav-wrap': {
      background: 'transparent',
      flex: 1,
    },
    '& .ant-tabs-tab': {
      flex: 'unset',
      maxWidth: 'unset',
      minWidth: 'unset',
    },
    '& .ant-tabs-extra-content:first-child': {
      overflow: 'hidden',
      flex: 'unset',
      marginRight: theme.old.spacing.unit(1.5),
    },
    '& .prio-mail-tab-invisible': {
      display: 'none',
    },
  },
  singleTab: {
    '& .ant-tabs-nav-wrap': {
      visibility: 'hidden',
    },
  },
  tabsEvent: {
    paddingTop: theme.old.spacing.defaultPadding,
  },
  content: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    flex: 1,
    overflow: 'hidden',
  },
  header: {
    borderBottom: '1px solid #cbcbcb',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.defaultPadding}px ${
      theme.old.spacing.defaultPadding
    }px ${theme.old.spacing.unit(2.5)}px`,
    '& .ant-select-multiple .ant-select-selection-item': {
      padding: 0,
      margin: 0,
      border: 'none',
      background: 'none',
      height: '1.5em',
    },
  },
  headerCompressedView: {
    padding: theme.old.spacing.unit(2),
  },

  whiteSurface: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    overflow: 'hidden',
    position: 'relative',
  },
  primaryColorIcon: {
    color: `${theme.old.palette.primaryColor}!important`,
  },
  addressBarLabelCol: {
    width: theme.old.spacing.unit(4),
    fontSize: theme.old.components.mailFolderItem.fontSize,
    fontWeight: theme.old.typography.fontWeight.bold,
    marginRight: theme.old.spacing.unit(1),
    height: '1.5em',
  },
  addressBarContentCol: {
    width: `calc(100% - ${theme.old.spacing.unit(6)}px - 30px)`,
  },
  flexRow: {
    display: 'flex',
    '&:hover': {
      '& $copyAllButtonInvisible': {
        visibility: 'visible',
      },
    },
  },
  copyAllButtonInvisible: {
    cursor: 'pointer',
    visibility: 'hidden',
    height: 14,
    width: 14,
    fontSize: 14,
    color: theme.old.typography.colors.muted,
  },
  categorySelector: {
    width: '100%',
    '& .ant-select': {
      fontSize: theme.old.components.mailFolderItem.fontSize,
    },
    '& .ant-select-multiple .ant-select-selector': {
      padding: 0,
      border: 'none',
    },
  },
  displayAllAttachmentButton: {
    '&.prio-btn': {
      backgroundColor: 'transparent',
      color: theme.colors.base.primary.default,
      paddingLeft: 17,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.colors.base.primary.dark,
        '& > .prio-button-icon': {
          color: theme.colors.base.primary.dark,
        },
      },
    },
  },
  attachments: {
    overflowY: 'scroll',
    display: 'flex',
    padding: `${theme.old.spacing.unit(1)}px ${theme.old.spacing.unit(
      1
    )}px ${theme.old.spacing.unit(2)}px`,
    '& > div': {
      width: '50%',
      overflow: 'hidden',
    },
  },
  fullContent: {
    width: '100%',
    overflow: 'hidden',
    margin: 'auto 0',
  },
  fullContentInner: {
    paddingLeft: theme.old.spacing.defaultPadding,
    padding: theme.old.spacing.baseSpacing,
  },
  fullContentInnerSpan: {
    margin: 'auto 0',
    paddingLeft: theme.old.spacing.defaultPadding,
  },
  attachmentDivider: {
    margin: 0,
    borderTopColor: '#cbcbcb',
  },
  whiteOverlay: {
    backgroundColor: theme.old.palette.backgroundPalette.alpha.content,
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  addresses: {
    width: '100%',
    '&.ant-select': {
      fontSize: theme.old.components.mailFolderItem.fontSize,
    },
    '& .ant-select-selection-search': {
      width: '0!important',
      overflow: 'hidden',
    },
    '& .ant-select-selector': {
      cursor: 'default!important',
      border: 'none',
    },
    '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: 'none',
    },
    '&.ant-select-multiple .ant-select-selector': {
      padding: 0,
    },
    '&.ant-select-multiple .ant-select-selector::after': {
      lineHeight: '1.5em',
      height: 0,
    },
    '& .ant-select-arrow': {
      top: theme.old.spacing.unit(1.5),
      right: 0,
      height: theme.old.spacing.unit(3),
      width: theme.old.spacing.unit(3),
    },
    '& .ant-select-selection-overflow-item-rest > span': {
      cursor: 'pointer',
      margin: 0,
      padding: 0,
      '-webkit-margin-end': 0,
      '-webkit-padding-start': 0,
      '-webkit-padding-end': 0,
      paddingInlineStart: 0,
      paddingInlineEnd: 0,
    },
    '& .ant-select-selection-overflow-item-rest > span > span': {
      margin: 0,
    },
    '& .ant-select-selection-overflow-item': {
      maxWidth: '250px',
      height: '1.5em',
      '& .ant-select-selection-item-content::after': {
        content: '";"',
      },
      '&:nth-last-child(2) .ant-select-selection-item-content::after': {
        content: '""',
      },
    },
  },
  selectItems: {
    '&.ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
      backgroundColor: theme.old.palette.backgroundPalette.content,
      fontWeight: 'normal',
      cursor: 'default',
    },
    '&.ant-select-item': {
      padding: 0,
    },
    '&:not(:last-child)': {
      borderBottom: theme.old.borders.content,
    },
  },
  copyIconBox: {
    padding: theme.old.spacing.baseSpacing,
    paddingRight: theme.old.spacing.unit(1.5),
    paddingLeft: theme.old.spacing.unit(1.5),
  },
  copyContent: {
    padding: theme.old.spacing.unit(0.5),
    paddingLeft: theme.old.spacing.unit(1.5),
    flex: 1,
  },
  copyableTextTile: {
    width: '100%',
  },
  copyContainerTags: {
    width: '100%',
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  copyContainerTagsTooltip: {
    '&:hover': {
      backgroundColor: '#00000080',
    },
  },
  copyIconBoxTags: {
    backgroundColor: theme.old.palette.backgroundPalette.hover.sub,
    padding: `0 ${theme.old.spacing.unit(2)}px`,
  },
  copyIconBoxTagsTooltip: {
    backgroundColor: '#000000bf',
    padding: `0 ${theme.old.spacing.unit(2)}px`,
  },
  copyValueTextTags: {
    wordBreak: 'break-all',
    color: theme.old.typography.colors.base,
  },
  copyValueTextTagsTooltip: {
    wordBreak: 'break-all',
    color: theme.old.typography.colors.inverted,
  },
  selectionRest: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  selectButton: {
    width: '100%',
    borderTop: theme.old.borders.content,
    '&:hover': {
      borderTop: theme.old.borders.content,
    },
    paddingTop: 4,
    height: 32,
  },
  scrollable: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden scroll',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  timeContainer: {
    display: 'flex',
    marginRight: 'auto',
    width: '100%',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  time: {
    fontWeight: theme.old.typography.fontWeight.regular,
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
    marginBottom: '0.5em',
    paddingTop: theme.old.spacing.unit(1),
  },
  quickActionBar: {
    marginLeft: theme.old.spacing.unit(1.5),
    paddingRight: theme.old.spacing.unit(1.5),
  },
  quickActionBarCompressed: {
    fontSize: theme.old.typography.fontSize.label,
  },
  title: {
    padding: theme.old.spacing.defaultPadding,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.ant-typography': {
      margin: '0px',
    },
  },
  titleViewCompressed: {
    '&.ant-typography': {
      paddingTop: 8,
      paddingLeft: 12,
      marginBottom: 0,
    },
  },
  titleNoSubject: {
    padding: theme.old.spacing.defaultPadding,
    paddingBottom: 0,
    paddingRight: theme.old.spacing.unit(1),
  },
  tabTitleCompressed: {
    fontSize: theme.old.typography.fontSize.label,
  },
  contactPickerAssignmentModal: {
    width: '100%',
  },
  unassignMessageButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  scrollbarContainer: {
    overflow: 'auto hidden',
    height: 4,
  },
}));

interface MessageDisplayProps {
  className?: string;
  message: Message;
  onDelete: (message: Message) => void;
  onReply: () => Promise<void>;
  onReplyAll: () => Promise<void>;
  onForward: () => Promise<void>;
  onMarkRead: () => Promise<void>;
  onPrintEmail?: () => Promise<void>;
  projectId: ProjectId;
  messageAttachments?: MessageAttachment[];
  onOpenInNewWindow?: VoidFunction;
  showActions?: boolean;
  searchKeywords?: string | null;
  openInNewWindow?: boolean;
  isFetching?: boolean;
  disableTitleBar?: boolean;
  messageAttachmentsLoaded?: boolean;
  draftMessages: Message[];
  isEvent?: boolean;
}

export const MessageDisplay: React.FC<MessageDisplayProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    message,
    onDelete,
    onReply,
    onReplyAll,
    onForward,
    onMarkRead,
    onPrintEmail,
    messageAttachments: messageAttachmentsFromProps,
    projectId,
    draftMessages,
    onOpenInNewWindow,
    showActions,
    searchKeywords,
    openInNewWindow,
    isFetching,
    disableTitleBar,
    messageAttachmentsLoaded,
    isEvent,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const ref = useRef(null);

  const [scrollableRef, setScrollableRef] = useState<HTMLDivElement | null>(
    null
  );

  const htmlContentRef = useRef<HtmlTextContentRef>(null);

  const horizontalScrollRef = useRef<HTMLDivElement | null>(null);

  const isMe = projectId === 'me';

  const [selectedMessageAttachmentIds, setSelectedMessageAttachmentIds] =
    useState<string[]>([]);

  const [attachmentDrawerOpen, setAttachmentDrawerOpen] =
    useState<boolean>(false);

  const [assignMessageModalVisible, setAssignMessageModalVisible] =
    useState<boolean>(false);

  const [assignedToId, setAssignedToId] = useState<ContactId>(
    message.customSingleValueExtendedProperties?.messageAssignmentId
  );

  const [modalContactId, setModalContactId] = useState<ContactId>(assignedToId);

  const contact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, assignedToId)
  );

  const [assignedTo, setAssignedTo] = useState<Contact>(contact);

  const [contactPickerDisabled, setContactPickerDisabled] =
    useState<boolean>(false);

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const groupId = project?.groupId;

  const driveItem = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, `root-group-${groupId}`)
  );

  const colorMap = useSelector<
    RootReducerState,
    { [displayName: string]: MailCategoryColorName }
  >((state) => getMessageCategoryColorMap(state, projectId));

  const messageAttachments = (messageAttachmentsFromProps ?? []).filter(
    ({ isInline, name }) =>
      !isInline &&
      !name.match(/^inline-img-/) &&
      !name.match(/\.(p7m)$/) &&
      !name.match(/\.(p7s)$/)
  );

  const messageAttachmentsIncludingHiddenAttachments =
    messageAttachmentsFromProps ?? [];

  const hiddenAttachmentsAvailable =
    messageAttachmentsIncludingHiddenAttachments?.length !==
    messageAttachments?.length;

  const selectedMessageAttachments = messageAttachments.filter((attachment) =>
    selectedMessageAttachmentIds.includes(attachment.id)
  );

  const [task, setTask] = useState<CreateTaskRequest>(null);
  const [attachmentsCollapsed, setAttachmentCollapsed] =
    useState<boolean>(true);

  const mailSettings = useSelector(getMailSettings);

  const [scrollWidth, setScrollWidth] = useState<number>(0);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const openAttachmentDrawer = () => setAttachmentDrawerOpen(true);
  const closeAttachmentDrawer = () => setAttachmentDrawerOpen(false);

  const onToggleFlag = async (message: Message) => {
    let flagStatus: FlagStatus;
    const messageProjectId =
      projectId === 'favorites'
        ? (message as MessageCenterMessage).projectId
        : projectId;
    switch (message.flag.flagStatus) {
      case 'NotFlagged':
        flagStatus = 'Flagged';
        break;
      case 'Flagged':
        flagStatus = 'Complete';
        break;
      case 'Complete':
        flagStatus = 'Flagged';
        break;
    }
    dispatch(
      flagMessage(
        messageProjectId,
        [{ messageId: message.id, flag: { flagStatus } }],
        [{ messageId: message.id, flag: message.flag }]
      )
    );
  };

  const handleFlag = async () => {
    await onToggleFlag(message);
    await new Promise((resolve) => {
      setTimeout(resolve, 500);
    });
  };

  const onAttachmentSelectionChanged = (e: CheckboxChangeEvent) => {
    const attachmentId = e.target.id;
    if (e.target.checked) {
      setSelectedMessageAttachmentIds([
        ...selectedMessageAttachmentIds,
        attachmentId,
      ]);
    } else {
      setSelectedMessageAttachmentIds(
        selectedMessageAttachmentIds.filter((id) => id !== attachmentId)
      );
    }
  };

  const onSelectionChangeAll = (values: string[]) => {
    setSelectedMessageAttachmentIds(values);
  };

  const onDownloadAttachment = async (messageAttachment: MessageAttachment) => {
    if (messageAttachment?.contentBytes?.length > 0) {
      const linkSource = `data:${messageAttachment.contentType};base64,${messageAttachment.contentBytes}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = messageAttachment.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      downloadAttachment(projectId, message.id, messageAttachment, t);
    }
  };

  const onDownloadAll = () => {
    const _attachments = hiddenAttachmentsAvailable
      ? messageAttachmentsIncludingHiddenAttachments
      : messageAttachments;

    selectedMessageAttachmentIds?.forEach((id) => {
      let attachment = _attachments.find((attachment) => attachment.id === id);
      if (attachment?.contentBytes?.length > 0) {
        const linkSource = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = attachment.name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        downloadAttachment(projectId, message.id, attachment, t);
      }
    });
  };

  const createTask = async () => {
    setTask({
      title: message.subject,
      description:
        message.body.contentType === 'html' ||
        message.body.contentType === 'Html' ||
        message.body.contentType === 1
          ? html2text(message.body.content)
          : message.body.content,
      projectId,
    });
  };

  const openAssignMessageModal = async () => {
    setAssignMessageModalVisible(true);
  };

  const assignMessage = async (
    projectId: ProjectId,
    messageId: MessageId,
    contactId: ContactId
  ) => {
    if (projectId && messageId && contactId) {
      setContactPickerDisabled(true);
      const result = await apiAssignMessage(projectId, messageId, contactId);
      setContactPickerDisabled(false);
      if (result) {
        dispatch(updateMessage(projectId, messageId, result.data));
      } else {
        notification.open({
          message: t('common:error'),
          description: t('mail:errorMessages.messages.messageAssignmentError'),
        });
      }
    }
  };

  const unassignMessage = async (
    projectId: ProjectId,
    messageId: MessageId
  ) => {
    if (projectId && messageId) {
      setContactPickerDisabled(true);
      const { result } = await apiUnassignMessage(projectId, messageId);
      setContactPickerDisabled(false);
      if (result.status >= 200 && result.status < 300) {
        const {
          messageAssignmentId: _,
          ...customSingleValueExtendedProperties
        } = message.customSingleValueExtendedProperties;
        dispatch(
          updateMessage(projectId, messageId, {
            customSingleValueExtendedProperties: {
              ...customSingleValueExtendedProperties,
              messageAssignmentId: null,
            },
          })
        );
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:errorMessages.messages.messageUnassignmentError'
          ),
        });
      }
    }
  };

  const handleAssignMessageModalOk = (assignee: ContactId) => {
    if (assignee) {
      assignMessage(projectId, message.id, assignee);
    } else {
      unassignMessage(projectId, message.id);
    }
    setAssignMessageModalVisible(false);
    setAssignedToId(assignee);
    setAssignedTo(contact);
    dispatch(
      updateMessage(projectId, message.id, {
        customSingleValueExtendedProperties: {
          ...message.customSingleValueExtendedProperties,
          messageAssignmentId: assignee,
        },
      })
    );
  };

  const handleDisplayAllAttachments = () => {
    setAttachmentCollapsed(!attachmentsCollapsed);
  };

  const handleOnHtmlContentScroll = ({ scrollLeft }) => {
    if (
      horizontalScrollRef.current &&
      horizontalScrollRef.current.scrollLeft !== scrollLeft
    ) {
      horizontalScrollRef.current.scrollLeft = scrollLeft;
    }
  };

  const handleOnHorizontalScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (htmlContentRef.current) {
      htmlContentRef.current.setScrollPosition({
        scrollLeft: e.currentTarget.scrollLeft,
        scrollTop: e.currentTarget.scrollTop,
      });
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setAssignedTo(contact);
  }, [assignedToId, contact, setAssignedTo]);

  useEffect(() => {
    setAssignedToId(
      message.customSingleValueExtendedProperties?.messageAssignmentId
    );
    setModalContactId(
      message.customSingleValueExtendedProperties?.messageAssignmentId
    );
  }, [
    message.customSingleValueExtendedProperties?.messageAssignmentId,
    setAssignedToId,
  ]);
  //#endregion

  return (
    <>
      <div className={classNames(classes.root, className)} ref={ref}>
        <Flex.Column>
          {!disableTitleBar && (
            <Flex.Row>
              <Typography.Title
                level={3}
                className={classNames(
                  message.subject === ''
                    ? classes.titleNoSubject
                    : classes.title,
                  {
                    [classes.titleViewCompressed]:
                      mailSettings.mailListSpacing === 'tight',
                  }
                )}
                title={`${
                  message.importance === 'high'
                    ? `${t('mail:messageDisplay.highImportance')}! `
                    : ''
                }${
                  message.subject === ''
                    ? `${t('mail:messageDisplay.noSubject')}`
                    : message.subject
                }`}
              >
                {message.importance === 'high' && (
                  <span
                    style={{ color: theme.old.palette.chromaticPalette.red }}
                  >
                    {`${t('mail:messageDisplay.highImportance')}! `}
                  </span>
                )}
                <span>
                  {!message.subject
                    ? `${t('mail:messageDisplay.noSubject')}`
                    : message.subject}
                </span>
              </Typography.Title>
            </Flex.Row>
          )}
        </Flex.Column>
        <Tabs
          className={classNames(classes.tabs, {
            [classes.singleTab]: draftMessages.length === 0,
            [classes.tabsEvent]: isEvent,
            [classes.tabsViewCompressedView]:
              mailSettings.mailListSpacing === 'tight',
          })}
          defaultActiveKey={'mainContent'}
          type="card"
          tabBarStyle={
            mailSettings.mailListSpacing !== 'full'
              ? {
                  margin: 0,
                  height: 32,
                }
              : { margin: 0 }
          }
          hideAdd
          tabBarExtraContent={{
            left: (
              <Flex.Column flex={1}>
                {' '}
                {(message.receivedDateTime || message.createdDateTime) && (
                  <Flex.Item flex={1} className={classes.timeContainer}>
                    <Typography.Text className={classes.time}>
                      {longDateFormatString(
                        message.receivedDateTime ?? message.createdDateTime,
                        i18n.language
                      )}
                    </Typography.Text>
                  </Flex.Item>
                )}
              </Flex.Column>
            ),
            right: showActions && (
              <Flex.Column justifyContent="center">
                <Flex.Row
                  className={classNames(classes.quickActionBar, {
                    [classes.quickActionBarCompressed]:
                      mailSettings.mailListSpacing !== 'full',
                  })}
                >
                  {showActions && (
                    <QuickActionButtonRow
                      projectId={projectId}
                      message={message}
                      listenerRef={ref}
                      messageAttachments={messageAttachments}
                      selectedMessageAttachmentIds={
                        selectedMessageAttachmentIds
                      }
                      openInNewWindow={openInNewWindow}
                      isProject={!isMe}
                      onDownloadAttachment={onDownloadAttachment}
                      onAttachmentSelectionChanged={
                        onAttachmentSelectionChanged
                      }
                      onOpenInNewWindow={onOpenInNewWindow}
                      onDelete={onDelete}
                      onReply={onReply}
                      onReplyAll={onReplyAll}
                      onForward={onForward}
                      onMarkRead={onMarkRead}
                      onPrintEmail={onPrintEmail}
                      createTask={createTask}
                      handleFlag={handleFlag}
                      assignMessage={openAssignMessageModal}
                    />
                  )}
                </Flex.Row>
              </Flex.Column>
            ),
          }}
        >
          <Tabs.TabPane
            key="mainContent"
            tab={
              <Flex.Row
                alignItems="center"
                childrenGap={theme.old.spacing.baseSpacing}
                className={
                  mailSettings.mailListSpacing !== 'full' &&
                  classes.tabTitleCompressed
                }
              >
                <FontAwesomeIcon icon={['fal', 'envelope-open-text']} />
                <Flex.Item className={'prio-mail-tab-invisible'}>
                  {t('mail:mail')}
                </Flex.Item>
              </Flex.Row>
            }
          >
            <Flex.Column width="100%" height="100%">
              <div className={classes.scrollable} ref={setScrollableRef}>
                {isFetching && (
                  <div className={classes.whiteOverlay}>
                    <div className="prio-flex-center-center prio-flex-column">
                      <PrioSpinner size="large" />
                    </div>
                  </div>
                )}
                <Flex.Column
                  className={classNames(classes.header, {
                    [classes.headerCompressedView]:
                      mailSettings.mailListSpacing === 'tight',
                  })}
                >
                  <Flex.Row>
                    <Flex.Column flex={1}>
                      <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
                        <Flex.Column flex={1}>
                          <AddressRow recipients={[message.from]} type="from" />
                          <AddressRow
                            recipients={message.toRecipients}
                            type="to"
                          />
                          <AddressRow
                            recipients={message.ccRecipients}
                            type="cc"
                          />
                          <AddressRow
                            recipients={message.bccRecipients}
                            type="bcc"
                          />
                        </Flex.Column>
                      </Flex.Row>
                      <Flex.Row>
                        <MailCategoriesSelector
                          className={classes.categorySelector}
                          message={message}
                          projectId={projectId}
                          colorMap={colorMap}
                          maxCount={3}
                        />
                      </Flex.Row>
                    </Flex.Column>
                    {assignedTo && !isMe && (
                      <Flex.Column>
                        <Popover
                          placement="left"
                          content={t('mail:messageDisplay.messageAssignedTo', {
                            firstName: assignedTo?.firstName,
                            lastName: assignedTo?.lastName,
                          })}
                        >
                          {' '}
                          <UserAvatar
                            size={'medium'}
                            contact={assignedTo}
                          ></UserAvatar>
                        </Popover>
                      </Flex.Column>
                    )}
                  </Flex.Row>
                </Flex.Column>
                <Flex.Column
                  className={classes.whiteSurface}
                  alignItems="flex-start"
                >
                  {messageAttachmentsLoaded == null ? (
                    <></>
                  ) : messageAttachmentsLoaded ? (
                    <></>
                  ) : (
                    <Flex.Column className={classes.fullContent}>
                      <Flex.Row className={classes.fullContentInner}>
                        <PrioSpinner size="large" />{' '}
                        <span className={classes.fullContentInnerSpan}>
                          {t('mail:messageDisplay.loadingAttachments')}
                        </span>
                      </Flex.Row>
                      <Divider className={classes.attachmentDivider}></Divider>
                    </Flex.Column>
                  )}
                  <MessageAttachmentList
                    selectedMessageAttachmentIds={selectedMessageAttachmentIds}
                    setSelectedMessageAttachmentIds={
                      setSelectedMessageAttachmentIds
                    }
                    messageAttachments={messageAttachments}
                    messageAttachmentsIncludingHiddenAttachments={
                      messageAttachmentsIncludingHiddenAttachments
                    }
                    onDownloadAttachment={onDownloadAttachment}
                    onSelectionChange={onAttachmentSelectionChanged}
                    messageId={message.id}
                    onSelectionChangeAll={onSelectionChangeAll}
                    openAttachmentDrawer={openAttachmentDrawer}
                    downloadAll={onDownloadAll}
                    isMe={isMe}
                    attachmentsCollapsed={attachmentsCollapsed}
                    projectId={projectId}
                    receivedMessage={true}
                  />
                  {messageAttachments.length > 6 && (
                    <Button
                      type="link"
                      className={classes.displayAllAttachmentButton}
                      onClick={handleDisplayAllAttachments}
                      iconProp={[
                        'fal',
                        attachmentsCollapsed ? 'plus' : 'minus',
                      ]}
                    >
                      {attachmentsCollapsed
                        ? t('mail:messageDisplay.displayAllAttachments')
                        : t('mail:messageDisplay.closeDisplayAllAttachments')}
                    </Button>
                  )}
                  {messageAttachmentsLoaded &&
                  (messageAttachments.length > 0 ||
                    messageAttachmentsIncludingHiddenAttachments?.length !==
                      messageAttachments?.length) ? (
                    <Divider
                      className={classes.attachmentDivider}
                      style={{
                        marginTop:
                          messageAttachments.length <= 6 ? 12 : undefined,
                      }}
                    ></Divider>
                  ) : (
                    <></>
                  )}
                </Flex.Column>
                <MessageDisplayMainContent
                  className={classes.content}
                  message={message}
                  searchKeywords={searchKeywords}
                  scrollableRef={scrollableRef}
                  ref={htmlContentRef}
                  onScrollWidthChange={setScrollWidth}
                  onScroll={handleOnHtmlContentScroll}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  height: 'auto',
                  backgroundColor: theme.old.palette.backgroundPalette.content,
                }}
              >
                <AutoSizer disableHeight>
                  {({ width }) => {
                    if (scrollWidth <= width) {
                      return null;
                    }
                    return (
                      <div
                        className={classes.scrollbarContainer}
                        style={{ width }}
                        ref={horizontalScrollRef}
                        onScroll={handleOnHorizontalScroll}
                      >
                        <div
                          style={{
                            width: scrollWidth,
                            height: 1,
                          }}
                        />
                      </div>
                    );
                  }}
                </AutoSizer>
              </div>
            </Flex.Column>
          </Tabs.TabPane>
          {draftMessages.length > 0 && (
            <Tabs.TabPane
              key="draftMessages"
              tab={
                <Flex.Row
                  alignItems="center"
                  childrenGap={theme.old.spacing.baseSpacing}
                  className={
                    mailSettings.mailListSpacing !== 'full' &&
                    classes.tabTitleCompressed
                  }
                >
                  <FontAwesomeIcon icon={['fal', 'pen']} />
                  <Flex.Item className={'prio-mail-tab-invisible'}>
                    {t('mail:drafts')}
                  </Flex.Item>
                  <Flex.Item>{`(${draftMessages.length})`}</Flex.Item>
                </Flex.Row>
              }
            >
              <DraftListContainer
                projectId={projectId}
                draftMessages={draftMessages}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
      <TasksDrawer
        sourceEmailUrl={`${window.REACT_APP_API_REDIRECT_URL}view/${projectId}/message/${message.id}/details`}
        messageId={message.id}
        visible={!!task}
        preSelectedTask={task}
        closeDrawer={() => setTask(null)}
      />
      {messageAttachments !== null && messageAttachments.length > 0 && (
        <RenameAttachmentsDrawer
          projectId={projectId}
          messageId={message.id}
          messageAttachments={selectedMessageAttachments}
          targetFolder={driveItem}
          attachmentDrawerOpen={attachmentDrawerOpen}
          onClose={closeAttachmentDrawer}
        />
      )}
      <Modal
        title={t('mail:messageDisplay.assignMessage')}
        visible={assignMessageModalVisible}
        onOk={() => handleAssignMessageModalOk(modalContactId)}
        onCancel={() => setAssignMessageModalVisible(false)}
        cancelText={t('mail:modals.assignMessage.cancelText')}
        okText={t('mail:modals.assignMessage.okText')}
      >
        <Flex.Row childrenGap={theme.old.spacing.unit(1)} alignItems="center">
          <ContactPicker
            value={modalContactId}
            projectId={projectId}
            onlyInternalProject
            disabled={contactPickerDisabled}
            multiple={false}
            className={classes.contactPickerAssignmentModal}
            onChange={(value) => {
              let contactId: ContactId = null;
              Array.isArray(value)
                ? (contactId = value[0])
                : (contactId = value);
              setModalContactId(contactId);
            }}
          ></ContactPicker>
          {/* )} */}
          <FontAwesomeIcon
            icon={['fal', 'xmark']}
            onClick={() => {
              setModalContactId(null);
            }}
            className={classes.unassignMessageButton}
          ></FontAwesomeIcon>
        </Flex.Row>
      </Modal>
    </>
  );
};

export default MessageDisplay;

interface AddressRowProps {
  recipients: MessageRecipient[];
  type: string;
}

export const AddressRow: React.FC<AddressRowProps> = (props) => {
  const { recipients, type } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const mailSettings = useSelector(getMailSettings);

  const maxTagCount = 5;

  const [open, setOpen] = useState<boolean>(false);

  const [clicked, setClicked] = useState(false);
  const copyCodeToClipboard = () => {
    copyToClipboard(
      recipients.map((recipient) => recipient?.emailAddress?.address).join('; ')
    );
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
      setOpen(false);
    }, 750);
  };

  const copyAllRef = useRef(null);

  return (
    recipients?.length > 0 && (
      <div className={classes.flexRow}>
        <div
          className={classes.addressBarLabelCol}
          style={
            mailSettings?.mailListSpacing !== 'full'
              ? {
                  paddingTop: '0.1em',
                }
              : undefined
          }
        >
          {`${t(`mail:addressBar.types.${type}`)}: `}
        </div>
        <Flex.Row className={classes.addressBarContentCol}>
          <Select
            className={classes.addresses}
            mode="tags"
            open={open}
            value={recipients.map(
              (recipient) => recipient?.emailAddress?.address
            )}
            bordered={false}
            tagRender={(props: CustomTagProps) => {
              return <SelectTag {...props} />;
            }}
            maxTagCount={maxTagCount}
            menuItemSelectedIcon={null}
            searchValue=""
            showSearch={false}
            suffixIcon={null}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(!open);
            }}
            maxTagPlaceholder={(omittedValues) => (
              <div
                className={classes.selectionRest}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {'+ ' +
                  t('common:select.omittedPlaceholder', {
                    count: omittedValues.length,
                  })}
              </div>
            )}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Button
                  ref={copyAllRef}
                  className={classes.selectButton}
                  type="link"
                  iconProp={['fal', clicked ? 'check' : 'copy']}
                  onClick={copyCodeToClipboard}
                >
                  {t('mail:addressBar.copyAll.all')}
                </Button>
              </>
            )}
          >
            {recipients.length > 0 &&
              recipients.map((recipient) => (
                <Select.Option
                  key={Math.random() * (100000 - 1000) + 1000}
                  value={recipient?.emailAddress?.address}
                  title={
                    recipient?.emailAddress?.name ??
                    recipient?.emailAddress?.address
                  }
                  className={classes.selectItems}
                >
                  <CopyableTextTile
                    className={classes.copyableTextTile}
                    valueClassName={classes.copyValueTextTags}
                    containerClassName={classes.copyContainerTags}
                    copyIconBoxClassName={classes.copyIconBoxTags}
                    contentClassName={classes.copyContent}
                    label={
                      recipient?.emailAddress?.name ??
                      recipient?.emailAddress?.address
                    }
                    value={recipient?.emailAddress?.address}
                  />
                </Select.Option>
              ))}
          </Select>
        </Flex.Row>
        {recipients.length > 1 &&
          (type === 'to' || type === 'cc' || type === 'bcc') && (
            <Tooltip overlay={t(`mail:addressBar.copyAll.${type}`)}>
              <div
                ref={copyAllRef}
                className={classes.copyAllButtonInvisible}
                onClick={copyCodeToClipboard}
              >
                <FontAwesomeIcon icon={['fal', clicked ? 'check' : 'copy']} />
              </div>
            </Tooltip>
          )}
      </div>
    )
  );
};
const SelectTag: React.FC<CustomTagProps> = (props: CustomTagProps) => {
  const { label, value } = props;
  const classes = useStyles();
  return (
    <span
      className={'ant-select-selection-item'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Tooltip
        title={
          <CopyableTextTile
            valueClassName={classes.copyValueTextTagsTooltip}
            containerClassName={classes.copyContainerTagsTooltip}
            copyIconBoxClassName={classes.copyIconBoxTagsTooltip}
            value={value as string}
          />
        }
        className={'ant-select-selection-item-content'}
      >
        {(label as ReactElement)?.props?.label}
      </Tooltip>
    </span>
  );
};
