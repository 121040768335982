import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  InternalJobTitleSuggestion,
  CreateInternalJobTitleSuggestion,
} from '../../../models/InternalJobTitleSuggestion';
import { InternalJobTitleSuggestionId } from '../../../models/Types';

/** Contact classification fetching */

export interface FetchInternalJobTitlesCommitAction {
  type: 'PRIO_FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT';
  payload: InternalJobTitleSuggestion[];
}

export const FETCH_INTERNAL_CONTACT_JOB_TITLES_REQUEST =
  PRIO + 'FETCH_INTERNAL_CONTACT_JOB_TITLES_REQUEST';
export const FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT =
  PRIO + 'FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT';
export const FETCH_INTERNAL_CONTACT_JOB_TITLES_ROLLBACK =
  PRIO + 'FETCH_INTERNAL_CONTACT_JOB_TITLES_ROLLBACK';

export const fetchInternalJobTitles = () => ({
  type: FETCH_INTERNAL_CONTACT_JOB_TITLES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/InternalJobTitleSuggestion`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_INTERNAL_CONTACT_JOB_TITLES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchInternalJobTitlesError',
          timeout: 6,
        },
      },
    },
  },
});

export interface InternalJobTitleAction {
  type: string;
  payload?: InternalJobTitleSuggestion;
  rollbackInternalJobTitle?: InternalJobTitleSuggestion;
  meta?: {
    internalJobTitleSuggestionId?: InternalJobTitleSuggestionId;
    temporaryId?: InternalJobTitleSuggestionId;
  };
}

export const UPDATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST =
  PRIO + 'UPDATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST';
export const UPDATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT =
  PRIO + 'UPDATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT';
export const UPDATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK =
  PRIO + 'UPDATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK';

export const updateInternalJobTitle = (
  updatedInternalJobTitle: InternalJobTitleSuggestion,
  rollbackInternalJobTitle: InternalJobTitleSuggestion
) => ({
  type: UPDATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST,
  requiresAuth: true,
  payload: updatedInternalJobTitle,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/InternalJobTitleSuggestion/${updatedInternalJobTitle.internalJobTitleSuggestionId}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/merge-patch+json',
        },
        json: updatedInternalJobTitle,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
        rollbackInternalJobTitle,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateInternalJobTitleError',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST =
  PRIO + 'CREATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST';
export const CREATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT =
  PRIO + 'CREATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT';
export const CREATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK =
  PRIO + 'CREATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK';

export const createInternalJobTitle = (
  titleSuggestion: CreateInternalJobTitleSuggestion,
  temporaryId: InternalJobTitleSuggestionId
) => ({
  type: CREATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST,
  requiresAuth: true,
  payload: titleSuggestion,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/InternalJobTitleSuggestion`,
        method: 'POST',
        json: titleSuggestion,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT,
        meta: {
          temporaryId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
        meta: {
          temporaryId,
        },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.createInternalJobTitleError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

export const DELETE_INTERNAL_CONTACT_JOB_TITLE_REQUEST =
  PRIO + 'DELETE_INTERNAL_CONTACT_JOB_TITLE_REQUEST';
export const DELETE_INTERNAL_CONTACT_JOB_TITLE_COMMIT =
  PRIO + 'DELETE_INTERNAL_CONTACT_JOB_TITLE_COMMIT';
export const DELETE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK =
  PRIO + 'DELETE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK';

export const deleteInternalJobTitle = (
  internalJobTitleSuggestionId: InternalJobTitleSuggestionId
) => ({
  type: DELETE_INTERNAL_CONTACT_JOB_TITLE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/InternalJobTitleSuggestion/${internalJobTitleSuggestionId}`,
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_INTERNAL_CONTACT_JOB_TITLE_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
        meta: { internalJobTitleSuggestionId },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.deleteInternalJobTitleError',
          timeout: 6,
        },
      },
    },
    internalJobTitleSuggestionId,
  },
});
