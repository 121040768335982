import { ExternalJobTitleSuggestion } from '../../../models/ExternalJobTitleSuggestion';
import { combineReducers, Reducer } from 'redux';
import {
  FetchExternalJobTitlesCommitAction,
  ExternalJobTitleAction,
  FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT,
  UPDATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST,
  UPDATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT,
  UPDATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
  DELETE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST,
  DELETE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
  CREATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST,
  CREATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT,
  CREATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
  FETCH_EXTERNAL_CONTACT_JOB_TITLES_ROLLBACK,
  FETCH_EXTERNAL_CONTACT_JOB_TITLES_REQUEST,
} from '../actions/externalJobTitles';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface ExternalJobTitlesState {
  byId: ExternalJobTitlesByIdState;
  ids: string[];
  meta: ExternalJobTitlesMeta;
}
export interface ExternalJobTitlesByIdState {
  [externalJobTitleSuggestionId: string]: ExternalJobTitleSuggestion;
}

const byId: Reducer<
  ExternalJobTitlesByIdState,
  FetchExternalJobTitlesCommitAction & ExternalJobTitleAction
> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT: {
      const { payload } = action;
      return (payload as ExternalJobTitleSuggestion[]).reduce(function (
        map,
        item
      ) {
        map[item.externalJobTitleSuggestionId] = item;
        return map;
      },
      {});
    }
    case CREATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const {
        meta: { temporaryId },
        payload,
      } = action;
      return {
        ...state,
        [temporaryId]: {
          externalJobTitleSuggestionId: temporaryId,
          rowVersion: null,
          ...payload,
        },
      };
    }
    case CREATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT: {
      const {
        payload,
        payload: { externalJobTitleSuggestionId },
      } = action;
      if (state[externalJobTitleSuggestionId]) return state;
      return {
        ...state,
        [externalJobTitleSuggestionId]: payload,
      };
    }
    case UPDATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT:
    case UPDATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const { payload } = action;
      const updatedExternalJobTitle = payload as ExternalJobTitleSuggestion;
      return {
        ...state,
        [updatedExternalJobTitle.externalJobTitleSuggestionId]: updatedExternalJobTitle,
      };
    }
    case UPDATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK: {
      const { rollbackExternalJobTitle } = action;
      return {
        ...state,
        [rollbackExternalJobTitle.externalJobTitleSuggestionId]: rollbackExternalJobTitle,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { };
    }
    default:
      return state;
  }
};

const ids: Reducer<
  Array<string>,
  FetchExternalJobTitlesCommitAction & ExternalJobTitleAction
> = (state = [], action) => {
  switch (action.type) {
    case FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT: {
      const { payload } = action;
      return payload.map((item) => item.externalJobTitleSuggestionId);
    }
    case CREATE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }
    case CREATE_EXTERNAL_CONTACT_JOB_TITLE_COMMIT: {
      const {
        meta: { temporaryId },
        payload: { externalJobTitleSuggestionId },
      } = action;
      if (state.includes(externalJobTitleSuggestionId)) return state;
      return [
        ...state.filter((id) => id !== temporaryId),
        externalJobTitleSuggestionId,
      ];
    }
    case CREATE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return state.filter((id) => id !== temporaryId);
    }
    case DELETE_EXTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const {
        meta: { externalJobTitleSuggestionId },
      } = action;
      return state.filter((id) => id !== externalJobTitleSuggestionId);
    }
    case DELETE_EXTERNAL_CONTACT_JOB_TITLE_ROLLBACK: {
      const {
        meta: { externalJobTitleSuggestionId },
      } = action;
      if (state.includes(externalJobTitleSuggestionId)) return state;
      return [...state, externalJobTitleSuggestionId];
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

export interface ExternalJobTitlesMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ExternalJobTitlesMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_EXTERNAL_CONTACT_JOB_TITLES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_EXTERNAL_CONTACT_JOB_TITLES_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_EXTERNAL_CONTACT_JOB_TITLES_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.fetchExternalJobTitlesError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<ExternalJobTitlesState>({
  byId,
  ids,
  meta,
});

export const getExternalJobTitles: (
  state: ExternalJobTitlesState
) => ExternalJobTitleSuggestion[] = (state) =>
  state.ids.map((id) => state.byId[id]);

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;