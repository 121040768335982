import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import {
  FlagMessagePayload,
  MarkAsReadPayload,
  Message,
  MessageCategorizationPayload,
} from '../../../../models/Message';
import { MailFolderId, MessageId } from '../../../../models/Types';
import { AdvancedMailSearchDto } from '../../../../models/MailSearch';
import {
  FETCH_MAIL_SEARCH_ME_COMMIT,
  FETCH_MAIL_SEARCH_ME_REQUEST,
  FETCH_MAIL_SEARCH_ME_ROLLBACK,
} from '../../actions/me/searchMe';
import { distinct } from '../../../../util';
import {
  CATEGORIZED_MESSAGE_ME,
  COPY_MAIL_ME_TO_PROJECT_COMMIT,
  DELETE_LOCAL_MESSAGE_ME,
  DELETE_MESSAGES_ME_COMMIT,
  DELETE_MESSAGES_ME_REQUEST,
  DELETE_MESSAGES_ME_ROLLBACK,
  FETCH_MESSAGE_ME_DECODE_MIME_COMMIT,
  FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT,
  FETCH_MESSAGE_ME_INLINE_IMAGE_REQUEST,
  FETCH_MESSAGE_ME_INLINE_IMAGE_ROLLBACK,
  FLAG_MESSAGE_ME_REQUEST,
  FLAG_MESSAGE_ME_ROLLBACK,
  MARK_AS_READ_MESSAGE_ME_REQUEST,
  MARK_AS_READ_MESSAGE_ME_ROLLBACK,
  MOVE_MESSAGE_ME_COMMIT,
  MOVE_MESSAGE_ME_REQUEST,
  MOVE_MESSAGE_ME_ROLLBACK,
  SOFTDELETE_MESSAGES_ME_REQUEST,
  SOFTDELETE_MESSAGES_ME_ROLLBACK,
  UPDATE_MESSAGE_ME,
  UPDATE_MESSAGES_ME,
} from '../../actions/me/messagesMe';
import {
  DELETE_MAIL_SEARCH_RESULTS_ME,
  SAVE_LAST_SEARCH_MAIL_ME,
  SET_CURRENT_MAIL_SEARCH_ME,
} from '../../actions/actionControllers/searchActionController';
import { WS_EMAIL_ME_DELETED, WS_EMAIL_ME_UPDATED } from '../../actions/ws';
import { DELETE_LOCAL_MESSAGES } from '../../actions/actionControllers/messageActionController';
import { WS_RECONNECT } from '../../../../sagas/watchWebsocketReconnect';
import { SAGA_REBUILD } from '../../../../util/sagas';
import equals from 'deep-equal';

export interface MailSearchState {
  byId: ByIdState;
  ids: MessageId[];
  nextLink: { [mailFolderId: MailFolderId]: string };
  lastSearchTermMail: AdvancedMailSearchDto | string;
  currentSearchTerm: AdvancedMailSearchDto | string;
  currentSearchMailFolderId: MailFolderId;
  meta: MetaState;
}

export const initialState: MailSearchState = {
  byId: {},
  ids: [],
  nextLink: {},
  lastSearchTermMail: null,
  currentSearchTerm: null,
  currentSearchMailFolderId: null,
  meta: {
    isFetching: [],
    hasError: true,
    isSingleMessageFetching: false,
  },
};

interface ByIdState {
  [messageId: string]: Message;
}

const byId: Reducer<ByIdState, any> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_ME_COMMIT: {
      const {
        payload,
        meta: { nextLink },
      } = action;
      return (payload.messages as Array<Message>).reduce(
        (map: ByIdState | {}, item) => {
          map[item.id] = item;
          return map;
        },
        !(nextLink === null || nextLink === undefined) ? state : {}
      );
    }

    case CATEGORIZED_MESSAGE_ME: {
      const { payload } = action;
      const { messages, isDelete } = payload as MessageCategorizationPayload;
      return messages.reduce(
        (map, { messageId, categories }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              categories: isDelete
                ? (map[messageId]?.categories ?? []).filter(
                    (category) => !categories.includes(category)
                  )
                : distinct([
                    ...(map[messageId]?.categories ?? []),
                    ...categories,
                  ]),
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case MARK_AS_READ_MESSAGE_ME_REQUEST: {
      const { payload } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        (map, { messageId, isRead }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case MARK_AS_READ_MESSAGE_ME_ROLLBACK: {
      const {
        meta: { payload },
      } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        (map, { messageId, isRead }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead: !isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }

    case FLAG_MESSAGE_ME_REQUEST: {
      const { payload } = action;
      const changes = payload as FlagMessagePayload;
      return changes.reduce(
        (map, { messageId, flag }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case FLAG_MESSAGE_ME_ROLLBACK: {
      const {
        meta: { originalPayload },
      } = action;
      const changes = originalPayload as FlagMessagePayload;
      return changes.reduce(
        (map, { messageId, flag }) => {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }

    case MOVE_MESSAGE_ME_COMMIT: {
      const {
        meta: { messageIds },
      } = action;
      return Object.keys(state).reduce((map, messageId) => {
        if (messageIds.includes(messageId)) {
          return map;
        }
        return {
          ...map,
          [messageId]: state[messageId],
        };
      }, {});
    }

    case UPDATE_MESSAGE_ME: {
      const {
        payload: { messageUpdate },
        meta: { messageId },
      }: {
        payload: { messageUpdate: Partial<Message> };
        meta: { messageId: MessageId };
      } = action;
      if (!state[messageId]) {
        return state;
      }
      return {
        ...state,
        [messageId]: {
          ...(state[messageId] ?? {}),
          ...messageUpdate,
        },
      };
    }
    case UPDATE_MESSAGES_ME: {
      const {
        payload: { messageUpdates },
        meta: { messageIds },
      } = action;

      return messageIds.reduce(
        (map, currentId) => {
          const existingMessage = map[currentId];
          const update = messageUpdates.find((u) => u.messageId === currentId);

          if (existingMessage && update) {
            map[currentId] = {
              ...existingMessage,
              ...update.messageUpdate,
            };
          }

          return map;
        },
        { ...state }
      );
    }
    case SOFTDELETE_MESSAGES_ME_REQUEST: {
      const {
        payload: { messageIds },
      } = action;
      return (messageIds as MessageId[]).reduce((map, currentId) => {
        const { [currentId]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }
    case SOFTDELETE_MESSAGES_ME_ROLLBACK: {
      const {
        meta: { rollbackMessages },
      } = action;
      return (rollbackMessages as Message[]).reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, state);
    }

    case DELETE_LOCAL_MESSAGE_ME: {
      const {
        meta: { messageId },
      } = action;
      if (state[messageId]) {
        const { [messageId]: _, ...rest } = state;
        return { ...rest };
      }
      return state;
    }

    case FETCH_MESSAGE_ME_DECODE_MIME_COMMIT:
    case FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT: {
      const {
        payload,
        meta: { messageId },
      } = action;
      if (state[messageId]) {
        return {
          ...state,
          [messageId]: payload,
        };
      }
      return state;
    }

    case WS_EMAIL_ME_DELETED:
    case DELETE_MESSAGES_ME_COMMIT: {
      const {
        meta: { messageIds },
      } = action;
      return ((messageIds as string[]) ?? []).reduce((map, id) => {
        const { [id]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }

    case WS_EMAIL_ME_UPDATED: {
      const {
        payload: { message },
      }: { payload: { message: Message } } = action;
      if (state[message.id]) {
        return {
          ...state,
          [message.id]: message,
        };
      }
      return state;
    }

    case COPY_MAIL_ME_TO_PROJECT_COMMIT: {
      const {
        meta: { deleteMail, messages },
      } = action;
      if (deleteMail) {
        const messageIdsToDelete = messages.map((message) => message.id);
        return Object.keys(state).reduce((map, messageId) => {
          if (messageIdsToDelete.includes(messageId)) {
            return map;
          }
          return {
            ...map,
            [messageId]: state[messageId],
          };
        }, {});
      }
      return state;
    }

    case SAGA_REBUILD:
    case SET_CURRENT_MAIL_SEARCH_ME:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_ME:
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default:
      return state;
  }
};

const ids: Reducer<MessageId[], any> = (state = initialState.ids, action) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_ME_COMMIT: {
      const {
        payload,
        meta: { nextLink },
      } = action;
      return distinct(
        !(nextLink === null || nextLink === undefined)
          ? [
              ...state,
              ...(payload.messages as Array<Message>).map((item) => item.id),
            ]
          : (payload.messages as Array<Message>).map((item) => item.id)
      );
    }

    case WS_EMAIL_ME_DELETED:
    case SOFTDELETE_MESSAGES_ME_REQUEST:
    case DELETE_MESSAGES_ME_REQUEST: {
      const {
        payload: { messageIds },
      } = action;
      return state.filter((id) => !messageIds.includes(id));
    }
    case SOFTDELETE_MESSAGES_ME_ROLLBACK:
    case DELETE_MESSAGES_ME_ROLLBACK: {
      const {
        meta: { messageIds },
      } = action;
      return [...state, ...messageIds];
    }

    case DELETE_LOCAL_MESSAGE_ME: {
      const {
        meta: { messageId },
      } = action;

      return state.filter((mId) => mId !== messageId);
    }

    case MOVE_MESSAGE_ME_REQUEST: {
      const {
        meta: { messageIds },
      } = action;

      return state.filter((id) => !messageIds.includes(id));
    }

    case MOVE_MESSAGE_ME_ROLLBACK: {
      const {
        meta: { messageIds },
      } = action;

      return distinct([...state, ...messageIds]);
    }

    case COPY_MAIL_ME_TO_PROJECT_COMMIT: {
      const {
        meta: { deleteMail, messages },
      } = action;
      if (deleteMail) {
        const messageIdsToDelete = messages.map((message) => message.id);
        return state.filter(
          (messageId) => !messageIdsToDelete.includes(messageId)
        );
      }
      return state;
    }

    case SAGA_REBUILD:
    case SET_CURRENT_MAIL_SEARCH_ME:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_ME:
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default:
      return state;
  }
};

const nextLink: Reducer<{ [mailFolderId: MailFolderId]: string }, any> = (
  state = initialState.nextLink,
  action
) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_ME_COMMIT: {
      const {
        payload,
        meta: { mailFolderId },
      } = action;
      const _mailFolderId = mailFolderId ?? 'allFolders';
      return {
        [_mailFolderId]: payload.nextLink ?? '',
      };
    }

    case SET_CURRENT_MAIL_SEARCH_ME: {
      const { mailFolderId } = action;
      const { [mailFolderId]: nextLink, ...rest } = state;
      if (!(nextLink === undefined || nextLink === null)) {
        return rest;
      }
      return state;
    }

    case SAGA_REBUILD:
    case DELETE_LOCAL_MESSAGES:
    case WS_RECONNECT:
    case DELETE_MAIL_SEARCH_RESULTS_ME:
    case CLEAR_PRIO_CACHE: {
      return initialState.nextLink;
    }
    default:
      return state;
  }
};

const lastSearchTermMail: Reducer<AdvancedMailSearchDto | string, any> = (
  state = initialState.lastSearchTermMail,
  action
) => {
  switch (action.type) {
    case SAVE_LAST_SEARCH_MAIL_ME: {
      const { searchTerm } = action;
      return searchTerm;
    }

    case CLEAR_PRIO_CACHE: {
      return initialState.lastSearchTermMail;
    }
    default:
      return state;
  }
};

const currentSearchTerm: Reducer<AdvancedMailSearchDto | string, any> = (
  state = initialState.currentSearchTerm,
  action
) => {
  switch (action.type) {
    case SET_CURRENT_MAIL_SEARCH_ME: {
      const { searchTerm } = action;
      if (equals(state, searchTerm)) {
        return state;
      }
      return searchTerm;
    }

    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_ME:
    case CLEAR_PRIO_CACHE: {
      return initialState.currentSearchTerm;
    }
    default:
      return state;
  }
};

const currentSearchMailFolderId: Reducer<MailFolderId | 'allFolders', any> = (
  state = initialState.currentSearchMailFolderId,
  action
) => {
  switch (action.type) {
    case SET_CURRENT_MAIL_SEARCH_ME: {
      const { mailFolderId } = action;
      if (state === mailFolderId ?? null) {
        return state;
      }
      return mailFolderId ?? initialState.currentSearchMailFolderId;
    }

    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_ME:
    case CLEAR_PRIO_CACHE: {
      return initialState.currentSearchMailFolderId;
    }
    default:
      return state;
  }
};

interface MetaState {
  isFetching: MailFolderId[];
  hasError: boolean;
  errorMessage?: string;
  isSingleMessageFetching?: boolean;
}

const meta: Reducer<MetaState, any> = (state = initialState.meta, action) => {
  switch (action.type) {
    case FETCH_MAIL_SEARCH_ME_REQUEST: {
      const {
        meta: { mailFolderId },
      } = action;
      return {
        isFetching: [...state.isFetching, mailFolderId],
        hasError: false,
      };
    }
    case FETCH_MAIL_SEARCH_ME_COMMIT: {
      const {
        meta: { mailFolderId },
      } = action;
      return {
        isFetching: state.isFetching.filter((id) => id !== mailFolderId),
        hasError: false,
      };
    }
    case FETCH_MAIL_SEARCH_ME_ROLLBACK: {
      const {
        meta: { mailFolderId },
      } = action;
      return {
        isFetching: state.isFetching.filter((id) => id !== mailFolderId),
        hasError: true,
        errorMessage: 'mail:errorMessages.searchMessages.fetchMessagesError',
      };
    }
    case FETCH_MESSAGE_ME_INLINE_IMAGE_REQUEST: {
      return {
        ...state,
        isSingleMessageFetching: true,
      };
    }
    case FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT: {
      return {
        ...state,
        isSingleMessageFetching: false,
      };
    }
    case FETCH_MESSAGE_ME_INLINE_IMAGE_ROLLBACK: {
      return {
        ...state,
        isSingleMessageFetching: false,
        hasError: true,
        errorMessage: 'mail:errorMessages.messages.fetchError',
      };
    }
    case SAGA_REBUILD:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case DELETE_MAIL_SEARCH_RESULTS_ME:
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default:
      return state;
  }
};

export default combineReducers<MailSearchState>({
  byId,
  ids,
  nextLink,
  lastSearchTermMail,
  currentSearchTerm,
  currentSearchMailFolderId,
  meta,
});

export const getIsFetching: (
  state: MailSearchState,
  mailFolderId?: MailFolderId
) => boolean = (state, mailFolderId) => {
  if (mailFolderId) {
    return state.meta.isFetching.includes(mailFolderId);
  }
  return state.meta.isFetching.length > 0;
};
export const getHasError: (state: MailSearchState) => boolean = (state) => {
  return state.meta.hasError;
};
export const getErrorMessage: (state: MailSearchState) => string = (state) => {
  return state.meta.errorMessage;
};

export const getMessagesById: (state: MailSearchState) => ByIdState = (state) =>
  state.byId;

export const getMessageIds: (state: MailSearchState) => MessageId[] = (state) =>
  state.ids;

export const getMessage: (
  state: MailSearchState,
  messageId: MessageId
) => Message = (state, messageId) => state.byId[messageId];
export const getMessages: (state: MailSearchState) => Message[] = (state) =>
  (state.ids ?? [])
    ?.map((id) => state.byId[id])
    ?.filter((message) => !!message)
    ?.sort((a: Message, b: Message) => {
      return Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime);
    });

export const getNextLink: (
  state: MailSearchState,
  mailFolderId: MailFolderId
) => string = (state, mailFolderId) => state.nextLink[mailFolderId];

export const getlastSearchTermMail: (
  state: MailSearchState
) => AdvancedMailSearchDto | string = (state) => state.lastSearchTermMail;

export const getDocumentsLastSearchTerm: (
  state: MailSearchState
) => AdvancedMailSearchDto | string = (state) => state.currentSearchTerm;

export const getCurrentSearchTerm: (
  state: MailSearchState
) => AdvancedMailSearchDto | string = (state) => state.currentSearchTerm;

export const getCurrentSearchMailFolderId: (
  state: MailSearchState
) => MailFolderId = (state) => state.currentSearchMailFolderId;

export const getCurrentSearchKeywords: (state: MailSearchState) => string = (
  state
) => {
  const searchTerm = state.currentSearchTerm;
  if (!searchTerm) {
    return null;
  }
  return typeof searchTerm === 'string'
    ? searchTerm.toLowerCase().includes('and') ||
      searchTerm.toLowerCase().includes('or')
      ? null
      : searchTerm
    : (searchTerm as AdvancedMailSearchDto)?.keywords ?? null;
};
