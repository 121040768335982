import { combineReducers, Reducer } from 'redux';
import {
  DELETE_PROJECT_NEWS_COMMIT,
  FETCH_PROJECT_NEWS_COMMIT,
  FETCH_PROJECT_NEWS_REQUEST,
  FETCH_PROJECT_NEWS_ROLLBACK,
} from '../actions';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ProjectNews } from '../../../models/Project';
import { ProjectId } from '../../../models/Types';

export interface ProjectNewsState {
  byId: ProjectNewsByIdState;
  ids: ByProjectId;
  meta: ProjectNewsMeta;
}

export interface ProjectNewsByIdState {
  [projectId: string]: ProjectNews;
}

const byId: Reducer<ProjectNewsByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECT_NEWS_COMMIT: {
      const { payload } = action;
      return (payload as Array<ProjectNews>).reduce(function (map, item) {
        map[item.projectId] = item.projectNewsDtos;
        return map;
      }, {});
    }

    case DELETE_PROJECT_NEWS_COMMIT: {
      const {
        meta: { projectId },
      } = action;
      const { [projectId]: news, ...rest } = state;
      if (news) {
        return rest;
      }
      return state;
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export interface ByProjectId {
  [projectId: string]: string[];
}

const ids: Reducer<ByProjectId, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECT_NEWS_COMMIT: {
      const { payload } = action;

      return (payload as Array<ProjectNews>).reduce<ByProjectId>(
        (map, item) => {
          map[item.projectId] = item.projectNewsDtos.map(
            (news) => news.projectNewsId
          );
          return map;
        },
        {}
      );
    }

    case DELETE_PROJECT_NEWS_COMMIT: {
      const {
        meta: { projectId },
      } = action;
      const { [projectId]: projectNews, ...rest } = action;
      if (projectNews) {
        return rest;
      }
      return state;
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface ProjectNewsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ProjectNewsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_PROJECT_NEWS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_PROJECT_NEWS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_PROJECT_NEWS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'projects:errorMessages.fetchProjectNews',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<ProjectNewsState>({
  byId,
  ids,
  meta,
});

export const getProjectNewsByProjectId: (
  state: ProjectNewsState,
  projectId: ProjectId
) => ProjectNews[] = (state, projectId) =>
  (state.byId[projectId] ?? []) as ProjectNews[];

export const projectHasProjectNews: (
  state: ProjectNewsState,
  projectId: string
) => boolean = (state, projectId) =>
  ((state.byId[projectId] ?? []) as ProjectNews[]).length > 0;

export const getIsFetching: (state: ProjectNewsState) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: ProjectNewsState) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: ProjectNewsState) => string = (state) =>
  state.meta.errorMessage;
