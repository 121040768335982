import React from 'react';
import { Row } from 'react-table';
import { OnRowProps } from './VirtualTable2';
import DraggableElement from '../../dnd/components/DraggableElement';

interface DraggableElementWrapperProps<DataType extends object> {
  children: JSX.Element;
  row: Row<DataType>;
  onRow: OnRowProps<DataType>;
  style: React.CSSProperties;
  selectedRows: Row<DataType>[];
}

export const DraggableElementWrapper = <DataType extends object>(
  props: DraggableElementWrapperProps<DataType>
) => {
  //#region ------------------------------ Defaults
  const { children, row, onRow, style, selectedRows } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isDroppable =
    onRow?.onRowDrop?.acceptType && onRow?.onRowDrop.including(row.original);

  const isDraggable = onRow?.onRowDrag?.dragType;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (isDraggable) {
    return (
      <DraggableElement
        id={row.id}
        key={row.id}
        style={!isDroppable ? style : { height: '100%' }}
        dragType={onRow.onRowDrag.dragType}
        item={
          onRow?.onRowDrag?.configureDraggedObject
            ? onRow.onRowDrag.configureDraggedObject(
              row.original,
              selectedRows.map((x) => x.original)
            )
            : row.original
        }
        onDragEnd={onRow.onRowDrag.onDragEnd}
      >
        {children}
      </DraggableElement>
    );
  }
  return children;
};

export default DraggableElementWrapper;
