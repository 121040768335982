import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { Dashboard, DashboardItem } from '../../../../models/Dashboard';
import { ReduxAction } from '../../../../models/Redux';
import {
  ContactId,
  DashboardId,
  DashboardItemId,
} from '../../../../models/Types';
import {
  FETCH_DASHBOARD_ME_COMMIT,
  FETCH_DASHBOARD_ME_REQUEST,
  FETCH_DASHBOARD_ME_ROLLBACK,
} from '../../actions';

export interface DashboardMeState {
  byId: ByIdState;
  meta: MetaState;
  dashboards: DashboardsState;
}

export const initialState: DashboardMeState = {
  byId: {},
  meta: {
    isFetching: false,
    hasError: false,
  },
  dashboards: {},
};

export interface ByIdState {
  [id: DashboardItemId]: DashboardItem;
}

const byId: Reducer<ByIdState, ReduxAction<unknown, Dashboard>> = (
  state = initialState.byId,
  action
) => {
  switch (action.type) {
    case FETCH_DASHBOARD_ME_COMMIT: {
      const {
        payload: { items },
      } = action;
      return items.reduce(
        (map, item) => ({
          ...map,
          [item.dashboardItemId]: item,
        }),
        {}
      );
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default: {
      return state;
    }
  }
};

export interface DashboardsState {
  [dashboardId: DashboardId]: {
    dashboardItemId: DashboardId;
    items: DashboardItemId[];
    name?: string;
    owner?: ContactId[];
  };
}

const dashboards: Reducer<DashboardsState, ReduxAction<unknown, Dashboard>> = (
  state = initialState.dashboards,
  action
) => {
  switch (action.type) {
    case FETCH_DASHBOARD_ME_COMMIT: {
      const {
        payload: { items, dashboardItemId, ...dashboard },
      } = action;
      return {
        ...state,
        [dashboardItemId]: {
          ...dashboard,
          dashboardItemId,
          items: items.map(({ dashboardItemId }) => dashboardItemId),
        },
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.dashboards;
    }
    default: {
      return state;
    }
  }
};

export interface MetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<MetaState, ReduxAction<unknown, Dashboard>> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_DASHBOARD_ME_REQUEST: {
      return {
        isFetching: true,
        hasError: false,
        errorMessage: undefined,
      };
    }
    case FETCH_DASHBOARD_ME_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_DASHBOARD_ME_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'dashboard:messages.errorMessages.fetchDashboardError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<DashboardMeState>({
  byId,
  dashboards,
  meta,
});

export const getDashboards: (state: DashboardMeState) => Dashboard[] = ({
  dashboards,
  byId,
}) =>
  Object.keys(dashboards).reduce<Dashboard[]>(
    (array, projectId) => [
      ...array,
      {
        ...dashboards[projectId],
        items: dashboards[projectId].items.map((id) => byId[id]),
      },
    ],
    []
  );

export const getDashboard: (
  state: DashboardMeState,
  dashboardId: DashboardId
) => Dashboard = ({ dashboards, byId }, dashboardId) => ({
  ...dashboards[dashboardId],
  items: dashboards[dashboardId].items.map((id) => byId[id]),
});

export const getDashboardItem: (
  state: DashboardMeState,
  dashboardItemId: DashboardItemId
) => DashboardItem = ({ byId }, dashboardItemId) => byId[dashboardItemId];
