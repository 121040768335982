import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormTitle from '../../../components/FormTitle';
import ProjectAssignmentTable from './ProjectAssignmentTable';
import { ProjectAssignment } from '../../../models/Project';
import Flex from '../../../components/Flex';
import { Divider } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import useCollapse from 'react-collapsed';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    marginBottom: theme.old.spacing.unit(2),
  },
  projectAssignmentTable: {},
  bottomDivider: {
    marginBottom: theme.old.spacing.unit(2),
  },
}));

interface AssignProjectProps {
  onTableRowChange?: (rowCount: number) => void;
  onProjectAssignmentChange: (
    currentProjectAssignments: ProjectAssignment[]
  ) => void;
}
const AssignProject: React.FC<AssignProjectProps> = (props) => {
  //#region -------------------------------- Variables
  const { t } = useTranslation();
  const { onProjectAssignmentChange } = props;
  const classes = useStyles();
  //#endregion

  //#region -------------------------------- State declaration

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { getCollapseProps } = useCollapse({ isExpanded });
  //#endregion

  //#region -------------------------------- Methods
  const openProjectAssignment = () => {
    setIsExpanded(true);
  };

  const closeProjectsAssignment = () => {
    setIsExpanded(false);
  };
  //#endregion

  //#region -------------------------------- Handle methods
  const handleOnTableRowChange = (rowCount: number) => {
    if (rowCount < 1) {
      closeProjectsAssignment();
    }
  };
  //#endregion

  return (
    <div className={classes.root}>
      {!isExpanded && (
        <>
          <Flex.Row>
            <Flex.Item flex={1}>
              <Button onClick={openProjectAssignment} style={{ width: '100%' }}>
                Projekt hinzufügen
              </Button>
            </Flex.Item>
          </Flex.Row>
        </>
      )}
      <section {...getCollapseProps()}>
        {isExpanded && (
          <>
            <FormTitle title={t('projects:title')} />
            <ProjectAssignmentTable
              className={classes.projectAssignmentTable}
              onTableRowChange={handleOnTableRowChange}
              onProjectAssignmentChange={onProjectAssignmentChange}
            />
          </>
        )}
      </section>
      {!isExpanded && <Divider className={classes.bottomDivider} />}
    </div>
  );
};
export default AssignProject;
