import React from 'react';
import { AccountingOverview } from '../../../models/Accounting';
import { formatMoney } from '../../../util';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import { Card } from '@prio365/prio365-react-library';
import moment from 'moment';
import { DateTimeString } from '../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    '& td': {
      whiteSpace: 'nowrap',
    },
  },
  slideArea: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowY: 'scroll',
    maxHeight: '400px',
    margin: 0,
    padding: '0 10 0 10',
  },
  cardWidget: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
  leftHeader: {
    fontWeight: theme.old.typography.fontWeight.regular,
    textAlign: 'end',
    paddingRight: theme.old.spacing.unit(2),
  },
  topHeader: {
    fontWeight: theme.old.typography.fontWeight.regular,
    fontSize: theme.old.typography.fontSize.tiny,
    color: theme.old.typography.colors.muted,
    paddingRight: theme.old.spacing.unit(2),
  },
  redColor: {
    color: theme.old.palette.chromaticPalette.red,
  },
  greenColor: {
    color: theme.old.palette.chromaticPalette.green,
  },
}));

interface AccountingOverviewTableProps {
  className?: string;
  accountingOverview: AccountingOverview;
  isTotal?: boolean;
  month: DateTimeString;
}

export const AccountingOverviewTable: React.FC<AccountingOverviewTableProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, accountingOverview, isTotal, month } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const coefficient =
    accountingOverview?.outgoingInvoiceSummary?.grossSum?.value !== 0
      ? accountingOverview?.outgoingInvoiceSummary?.netSum?.value /
        accountingOverview?.outgoingInvoiceSummary?.grossSum?.value
      : 1;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion
  const title = isTotal
    ? t('accounting:accountingOverviewTable.total')
    : moment(month).format('MMMM YYYY');

  return (
    <Card
      secondaryCard={true}
      title={title}
      contentNode={
        <table className={classNames(classes.root, className)}>
          <thead>
            <tr>
              <th className={classes.topHeader} />
              <th className={classes.topHeader}>
                {t('accounting:accountingOverviewTable.grossSum')}
              </th>
              <th className={classes.topHeader}>
                {t('accounting:accountingOverviewTable.remainingGross')}
              </th>
              <th className={classes.topHeader}>
                {t('accounting:accountingOverviewTable.netSum')}
              </th>
              <th className={classes.topHeader}>
                {t('accounting:accountingOverviewTable.remainingNet')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                className={classes.leftHeader}
                title={t(
                  'accounting:accountingOverviewTable.outgoingInvoiceSummary'
                )}
              >
                <FontAwesomeIcon icon={['fal', 'envelope-open-dollar']} />
              </th>
              <td>
                {formatMoney(
                  accountingOverview?.outgoingInvoiceSummary?.grossSum
                )}
              </td>
              <td>
                {formatMoney(
                  accountingOverview?.outgoingInvoiceSummary?.grossSumRemaining
                )}
              </td>
              <td>
                {formatMoney(
                  accountingOverview?.outgoingInvoiceSummary?.netSum
                )}
              </td>
              <td>
                {formatMoney({
                  value:
                    coefficient *
                    accountingOverview?.outgoingInvoiceSummary
                      ?.grossSumRemaining?.value,
                  isoCode:
                    accountingOverview?.outgoingInvoiceSummary?.netSum.isoCode,
                })}
              </td>
            </tr>
            <tr>
              <th
                className={classes.leftHeader}
                title={t('accounting:accountingOverviewTable.paymentSummary')}
              >
                <FontAwesomeIcon icon={['fal', 'hand-holding-dollar']} />
              </th>
              <td className={classes.greenColor}>
                {formatMoney(accountingOverview?.paymentSummary?.grossSum)}
              </td>
              <td></td>
            </tr>
            <tr>
              <th
                className={classes.leftHeader}
                title={t('accounting:accountingOverviewTable.refundSummary')}
              >
                <FontAwesomeIcon icon={['fal', 'coins']} />
              </th>
              <td className={classes.redColor}>
                {formatMoney(accountingOverview?.refundSummary?.grossSum)}
              </td>
              <td></td>
              <td className={classes.redColor}>
                {formatMoney({
                  value:
                    accountingOverview?.refundSummary?.grossSum?.value / 1.19,
                  isoCode:
                    accountingOverview?.outgoingInvoiceSummary?.grossSum
                      ?.isoCode,
                })}
              </td>
            </tr>
            <tr>
              <th
                className={classes.leftHeader}
                title={t('accounting:accountingOverviewTable.revenue')}
              >
                <FontAwesomeIcon icon={['fal', 'money-bill-transfer']} />
              </th>
              <td>
                {formatMoney({
                  value:
                    (accountingOverview?.outgoingInvoiceSummary?.grossSum
                      ?.value ?? 0) -
                    (accountingOverview?.refundSummary?.grossSum?.value ?? 0),

                  isoCode:
                    accountingOverview?.outgoingInvoiceSummary?.grossSum
                      ?.isoCode,
                })}
              </td>
              <td></td>
              <td>
                {formatMoney({
                  value:
                    (accountingOverview?.outgoingInvoiceSummary?.netSum
                      ?.value ?? 0) -
                    accountingOverview?.refundSummary?.grossSum?.value / 1.19,
                  isoCode:
                    accountingOverview?.outgoingInvoiceSummary?.netSum?.isoCode,
                })}
              </td>
            </tr>
          </tbody>
        </table>
      }
    ></Card>
  );
};

export default AccountingOverviewTable;
