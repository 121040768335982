import React, { useEffect, useRef, useState } from 'react';
import Flex from '../../../components/Flex';
import { Form, Input, Modal, Select, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { EmailString, ProjectId } from '../../../models/Types';

import {
  EmailSearchResult,
  EmailSearchResultContact,
  EmailSearchResultProject,
} from '../../../models/Message';
import useAddressSearch from '../hooks/useAddressSearch';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import TransBtn from 'rc-select/lib/TransBtn';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import PrioSpinner from '../../../components/PrioSpinner';
import { emailPattern } from '../../../hooks/useEmailValidation';
import { useForm } from 'antd/lib/form/Form';
import useDebounce from '../../../hooks/useDebounce';
import { useDrag, useDrop } from 'react-dnd';
import { DndMailAddressDto, DND_TYPE_EMAIL_ADDRESS } from '../../../dnd/types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Classes } from 'jss';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { apiDeleteEmailSuggestion } from '../api';

const useStylesAdressSelectMail = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    overflow: 'hidden',
    '& .ant-select-selection-item-content .secondary': {
      display: 'none',
    },
    '& .ant-form-item-explain': {
      display: 'none',
    },
    '& .ant-select-selector $emailSuggestionRow .anticon.anticon-close': {
      display: 'none',
    },
  },
  flex1: {
    flex: 1,
    overflow: 'hidden',
    '& .ant-select-selection-item-content .secondary': {
      display: 'none',
    },
  },

  fullWidth: {
    width: '100%',
  },
  loadingOverlay: {
    background: '#FFFFFFBF',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  popover: {
    '& .ant-popover-inner-content': {
      maxHeight: 500,
      maxWidth: 400,
      overflowY: 'scroll',
    },
  },
  secondary: {
    color: theme.old.typography.colors.muted,
  },
  tag: {
    '& .secondary': {
      display: 'none',
    },
  },
  suggestionLabel: {
    fontSize: theme.old.typography.fontSize.tiny,
    color: theme.old.typography.colors.muted,
    padding: `${theme.old.spacing.unit(0.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
    borderBottom: theme.old.borders.content,
  },
  suggestion: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    borderBottom: theme.old.borders.content,
  },
  emailSuggestionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .anticon-close': {
      visibility: 'hidden',
    },
    '&:hover .anticon-close': {
      visibility: 'visible',
    },
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
          padding: `0 ${theme.old.spacing.unit(1.5)}px`,
          background: 'transparent',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
    '&.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover':
      {
        zIndex: 1,
      },
    '& .ant-input-group': {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
      height: 32,
    },
    '& .ant-input-group-addon': { padding: 0, display: 'block' },
    '& .ant-input-group-addon:first-child': { width: 125 },
    '& .ant-input-group-addon:last-child': { width: 50 },
    '& .ant-input-group-addon .ant-select': {
      margin: 0,
    },
    '& .ant-input-affix-wrapper': {
      padding: `0 11px`,
    },
    '& .ant-input-prefix': {
      overflowX: 'scroll',
      overflowY: 'hidden',
      maxWidth: 'calc(100% - 52.125px)',
      paddingTop: 4,
    },
    '& .ant-input-affix-wrapper > input.ant-input': {
      minWidth: 4,
      padding: `4px 0`,
    },
    '& .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector':
      {
        height: 30,
      },
    '& .ant-select-single .ant-select-selector .ant-select-selection-item': {
      lineHeight: '28px',
    },
  },
}));

const filterAdresses: (
  input: string,
  option: OptionData | OptionGroupData
) => boolean = (input, option) => {
  const splittedSearchTerm = input
    ?.split(/\s/)
    ?.map((split) => split.toLocaleLowerCase());

  const address: EmailSearchResultContact | EmailSearchResultProject =
    option.data;

  if (!address) {
    return false;
  }

  const searchObject = {
    ...(address?.eMail ? { eMail: address.eMail } : {}),
    ...(address.type === 'contact'
      ? {
          firstName: (address as EmailSearchResultContact).firstName,
          lastName: (address as EmailSearchResultContact).lastName,
        }
      : {
          projectName: (address as EmailSearchResultProject).projectName,
          projectNumber: (address as EmailSearchResultProject).projectNumber,
        }),
  };

  const searchArray = Object.values(searchObject)
    .filter((value) => !!value)
    .map((value) => value.toLocaleLowerCase());

  let matches = 0;
  splittedSearchTerm.forEach((split) => {
    if (searchArray.find((value) => value.includes(split))) {
      matches++;
    }
  });

  if (splittedSearchTerm.length > 0 && matches >= splittedSearchTerm.length) {
    return true;
  }

  return false;
};

const emailSearchResultToHTMLText = (
  value: EmailSearchResult,
  classes: Classes
) => {
  if (value.type === 'contact') {
    const contact = value as EmailSearchResultContact;
    if (contact.lastName && contact.firstName) {
      return (
        <>
          <span>{`${contact?.firstName} ${contact?.lastName}`}</span>
          <span
            className={classNames('secondary', classes.secondary)}
          >{` <${contact.eMail}>`}</span>
        </>
      );
    }
  } else if (value.type === 'project') {
    const project = value as EmailSearchResultProject;
    if (project.projectName) {
      return (
        <>
          <span>{`${project?.projectName}`}</span>
          <span
            className={classNames('secondary', classes.secondary)}
          >{` <${project.eMail}>`}</span>
        </>
      );
    }
  }
  return (
    <span
      className={classNames('secondary', classes.secondary)}
    >{`<${value.eMail}>`}</span>
  );
};

interface AddressSelectMailProps {
  value?: string[];
  changeHandler?: (value: EmailString[]) => void;
  focus?: boolean;
  bordered?: boolean;
  maxCount?: number | 'responsive';
  open?: boolean;
  closableTags?: boolean;
  projectId: ProjectId;
  excludedMails?: EmailString[];
  tabIndex?: number;
}

const AdressSelectMail: React.FC<AddressSelectMailProps> = React.memo(
  (props) => {
    //#region ------------------------------ Defaults
    const {
      value,
      changeHandler,
      bordered,
      open,
      closableTags,
      projectId,
      excludedMails,
      tabIndex,
      focus,
    } = props;
    const classes = useStylesAdressSelectMail(props);
    const { t } = useTranslation();

    const [form] = useForm();
    const [modalForm] = useForm();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [searchTerm, setSearchTerm] = useState<string>('');
    const isSearching = searchTerm !== '';
    const [isOnline, setIsOnline] = useState<boolean>(false);
    const [dropdownFocus, setDropdownFocus] = useState<boolean>(open);

    const selectRef = useRef(null);

    const {
      addresses,
      isSearching: onlineLoading,
      refreshAddressSuggestions,
    } = useAddressSearch(
      {
        searchTerm,
        isOnline,
        projectId,
      },
      value
    );

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [selectedMail, setSelectedMail] = useState<string>('');
    const [renameMailinputValue, setRenameMailinputValue] =
      useState<string>('');
    const debouncedRenameMailinputValue = useDebounce(
      renameMailinputValue,
      100
    );
    const inputRef = useRef<Input>(null);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOk = () => {
      let i = value.findIndex((email) => email === selectedMail);
      let _value = [...value];
      _value[i] = renameMailinputValue;
      changeHandler(_value);
      setIsModalVisible(false);
      setRenameMailinputValue('');
      setSelectedMail('');
      form.validateFields();
    };

    const handleCancel = () => {
      setIsModalVisible(false);
      setRenameMailinputValue('');
      setSelectedMail('');
    };

    const showModal = (mail: string) => {
      setIsModalVisible(true);
      setRenameMailinputValue(mail);
      setSelectedMail(mail);
      modalForm.setFieldsValue({ renameMailInput: mail });
    };

    const onMailSuggestionDelete = async (email: string) => {
      await apiDeleteEmailSuggestion(projectId, email);
      await refreshAddressSuggestions();
    };
    //#endregion

    //#region ------------------------------ Effects
    const [, dropRef] = useDrop<DndMailAddressDto, any, any>({
      accept: [DND_TYPE_EMAIL_ADDRESS],
      drop: (item, monitor) => {
        const type = monitor.getItemType();
        if (monitor.didDrop()) {
          return undefined;
        }
        switch (type) {
          case DND_TYPE_EMAIL_ADDRESS:
            if (!value.includes(item.value)) {
              changeHandler([...value, item.value]);
            }
            return { close: true };
          default:
            return undefined;
        }
      },
      canDrop: (item) => !value.includes(item.value),
    });

    useEffect(() => {
      setSearchTerm('');
      form.setFieldsValue({
        addressSelect: value,
      });
      form.validateFields();
      // setTagEmails(value);
    }, [value, form]);
    //#endregion

    return (
      <div className={classes.root} ref={dropRef}>
        <Form
          form={form}
          onValuesChange={(currentValues) => {
            if (currentValues.addressSelect) {
              if (changeHandler) {
                changeHandler(currentValues.addressSelect);
              }
            }
          }}
        >
          <Form.Item
            name="addressSelect"
            style={{ marginBottom: 0 }}
            rules={[
              () => ({
                async validator(rule, values) {
                  const validMails = value.filter((email) =>
                    email.match(emailPattern)
                  );
                  if (validMails.length === value.length) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              }),
            ]}
          >
            <Select<EmailString[]>
              ref={selectRef}
              mode="tags"
              maxTagCount={7}
              bordered={bordered ?? false}
              tokenSeparators={['; ', ';', ',']}
              open={dropdownFocus}
              onBlur={(e) => {
                setDropdownFocus(false);
              }}
              autoFocus={focus}
              onMouseDown={(e) => {
                e.preventDefault();
                return false;
              }}
              onSearch={(value: string) => {
                if (value === '') {
                  setIsOnline(false);
                  setDropdownFocus(false);
                } else {
                  setDropdownFocus(true);
                }
                setSearchTerm(value);
              }}
              dropdownRender={(menu) => (
                <Flex.Column>
                  {onlineLoading && (
                    <div className={classes.loadingOverlay}>
                      <PrioSpinner />
                    </div>
                  )}
                  {!isSearching && projectId !== 'favorites' && (
                    <Typography.Text className={classes.suggestionLabel}>
                      {t('contacts:actions.suggestions')}
                    </Typography.Text>
                  )}
                  {menu}
                  {!isOnline && (isSearching || projectId === 'favorites') && (
                    <Button
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setIsOnline(true);
                      }}
                      iconProp={['fal', 'plus']}
                    >
                      {t('contacts:actions.showMore')}
                    </Button>
                  )}
                </Flex.Column>
              )}
              filterOption={filterAdresses}
              tagRender={(props: CustomTagProps) => (
                <TagRenderer
                  showModal={showModal}
                  closable={closableTags}
                  selectedValues={value}
                  {...props}
                />
              )}
              onSelect={() => {
                setIsOnline(false);
                setSearchTerm('');
                setDropdownFocus(false);
              }}
              onClick={(e) => {
                // e.stopPropagation();
                // e.preventDefault();

                if (!dropdownFocus) {
                  setDropdownFocus(true);
                } else {
                  setDropdownFocus(false);
                }
              }}
              //maxTagCount={maxCount}
            >
              {addresses
                .filter(
                  (address) =>
                    !!address &&
                    !excludedMails?.find((e) => e === address.eMail)
                )
                .map((address) => (
                  <Select.Option
                    id={address.eMail}
                    key={address.eMail}
                    value={address.eMail}
                    data={address}
                  >
                    <div className={classes.emailSuggestionRow}>
                      <div
                        style={{
                          flex: 1,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {emailSearchResultToHTMLText(address, classes)}
                      </div>
                      {address.eMail && address.isSuggestion && (
                        <CloseOutlined
                          onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            await onMailSuggestionDelete(address.eMail);
                          }}
                        />
                      )}
                    </div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
        <Modal
          title={t('mail:modals.renameMailAddress.title')}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={t('mail:modals.renameMailAddress.okText')}
          cancelText={t('mail:modals.renameMailAddress.cancelText')}
          okButtonProps={{
            disabled: !debouncedRenameMailinputValue?.match(emailPattern),
          }}
          keyboard
        >
          <Form
            form={modalForm}
            className={classes.flex1}
            onValuesChange={(currentValues) => {
              if (currentValues.renameMailInput) {
                setRenameMailinputValue(currentValues.renameMailInput);
              }
            }}
          >
            <Form.Item
              name="renameMailInput"
              rules={[
                () => ({
                  async validator(rule, value) {
                    if (value.match(emailPattern)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      t('mail:addressBar.errorMessages.incorrectMail')
                    );
                  },
                }),
              ]}
            >
              <Input
                ref={inputRef}
                tabIndex={tabIndex}
                placeholder={selectedMail}
                onPressEnter={() => {
                  handleOk();
                }}
                autoFocus={isModalVisible}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
);

export default AdressSelectMail;

interface TagRendererProps extends CustomTagProps {
  showModal: (mail: string) => void;
  closable: boolean;
  selectedValues: string[];
}

const TagRenderer: React.FC<TagRendererProps> = (props) => {
  //#region ------------------------------ Defaults
  const { label, value, closable, selectedValues, onClose, showModal } = props;
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  const [, dragRef, preview] = useDrag({
    type: DND_TYPE_EMAIL_ADDRESS,
    item: { value, label, closable, selectedValues },
    canDrag: () => true,
    end: (_, monitor) => {
      const item = monitor.getDropResult() as any;
      if (item?.close) {
        onClose();
      }
    },
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);
  //#endregion

  return (
    <>
      <div
        className={'ant-select-selection-item'}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          showModal(value?.toString());
        }}
        ref={dragRef}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        {
          <p
            style={
              value?.toString()?.match(emailPattern)
                ? {}
                : { color: theme.old.palette.chromaticPalette.red }
            }
          >
            {label}
          </p>
        }
        {closable && (
          <TransBtn
            className={'ant-select-selection-item-remove'}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            customizeIcon={<CloseOutlined />}
          />
        )}
      </div>
    </>
  );
};
