import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getGlobalSiderOpen } from '../../../apps/main/rootReducer';
import ProfileMenu from './ProfileMenu';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

interface ProfileModuleNavigationProps {
  className?: string;
}

const useStyles = makePrioStyles((theme) => ({
  root: {
    transition: 'padding 0.2s',
    padding: `0 ${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
  },
  rootOpen: {
    padding: `0 ${theme.old.spacing.defaultPadding}px ${theme.old.spacing.defaultPadding}px`,
  },
  menu: {},
  mainContent: {},
}));

export const ProfileModuleNavigation: React.FC<ProfileModuleNavigationProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;
  const { selectedList } = useParams();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const siderOpen = useSelector(getGlobalSiderOpen);
  //#endregion

  if (!selectedList) {
    return <Navigate to="personnelData" />;
  }

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames(classes.root, className, {
        [classes.rootOpen]: siderOpen,
      })}
    >
      <Flex.Column
        childrenGap={theme.old.spacing.unit(1)}
        className={classes.mainContent}
      >
        <ProfileMenu
          selectedList={selectedList}
          urlPrefix="../"
          className={classes.menu}
          collapsed={!siderOpen}
        />
      </Flex.Column>
    </Flex.Column>
  );
};

export default ProfileModuleNavigation;
