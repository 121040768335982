import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from 'antd';

import { Company } from '../../../models/Company';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import useCollapse from 'react-collapsed';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  label: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: theme.old.typography.fontSize.label,
  },
  radioLabelCol: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  companyRightBox: {
    borderLeft: theme.old.borders.content,
  },
  collapseHeader: {},
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  preview: {
    padding: theme.old.spacing.defaultPadding,
    border: theme.old.borders.sub,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    marginBottom: theme.old.spacing.defaultPadding,
  },
}));

interface ContactCompanyPreviewProps {
  companyData: Company;
}

export const ContactCompanyPreview: React.FC<ContactCompanyPreviewProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { companyData } = props;
  const isExpanded = !!companyData;
  const { getCollapseProps } = useCollapse({ isExpanded });
  //#endregion

  //#region -------------------------------- Methods
  const labelProperty = (value: string, ...values: string[]) => {
    const noDataPlaceholder = (
      <Typography.Text italic>
        {t('contacts:companyPreview.placeholder.noData')}
      </Typography.Text>
    );

    if (/^\s*$/.test(value)) {
      return noDataPlaceholder;
    }

    if (!value) {
      return noDataPlaceholder;
    }
    return value;
  };
  //#endregion

  return (
    <>
      <section {...getCollapseProps()}>
        {
          <div className={classes.preview}>
            <Flex.Column>
              {companyData &&
                companyData.offices.map((office) => (
                  <Flex.Row
                    key={office.officeId}
                    justifyContent="space-between"
                  >
                    <Flex.Item>
                      <Flex.Column>
                        <Flex.Item>
                          <Typography.Text className={classes.label}>
                            {t('contacts:companyPreview.labels.adress')}
                          </Typography.Text>
                        </Flex.Item>
                        <Flex.Row>
                          <Flex.Item>
                            <Typography.Text>
                              {labelProperty(office.street)}
                            </Typography.Text>
                          </Flex.Item>
                        </Flex.Row>
                        <Flex.Row childrenGap={theme.old.spacing.unit(0.75)}>
                          <Flex.Item>
                            <Typography.Text>
                              {labelProperty(office.zip)}
                            </Typography.Text>
                          </Flex.Item>
                          <Flex.Item>
                            <Typography.Text>
                              {labelProperty(office.city)}
                            </Typography.Text>
                          </Flex.Item>
                        </Flex.Row>
                        <Flex.Row>
                          <Flex.Item>
                            <Typography.Text>
                              {labelProperty(office.countryCode)}
                            </Typography.Text>
                          </Flex.Item>
                        </Flex.Row>
                      </Flex.Column>
                    </Flex.Item>
                    <Divider type="vertical" className={classes.divider} />
                    <Flex.Item>
                      <Flex.Column>
                        <Flex.Row>
                          <Flex.Column>
                            <Flex.Item>
                              <Typography.Text className={classes.label}>
                                {t('contacts:companyPreview.labels.phone')}
                              </Typography.Text>
                            </Flex.Item>
                            <Flex.Item>
                              <Typography.Text>
                                {labelProperty(office.phone)}
                              </Typography.Text>
                            </Flex.Item>
                          </Flex.Column>
                        </Flex.Row>
                        <Flex.Row>
                          <Flex.Column>
                            <Flex.Item>
                              <Typography.Text className={classes.label}>
                                {t('contacts:companyPreview.labels.eMail')}
                              </Typography.Text>
                            </Flex.Item>
                            <Flex.Item>
                              <Typography.Text>
                                {labelProperty(office.eMail)}
                              </Typography.Text>
                            </Flex.Item>
                          </Flex.Column>
                        </Flex.Row>
                      </Flex.Column>
                    </Flex.Item>
                  </Flex.Row>
                ))}
            </Flex.Column>
          </div>
        }
      </section>
    </>
  );
};

export default ContactCompanyPreview;
