import { useContext } from 'react';
import {
  InternalProjectContactsContext,
  InternalProjectContactsContextProps,
} from '../context/InternalProjectContactsContext';

export const useInternalProjectContactsContext: () => InternalProjectContactsContextProps =
  () => {
    const context = useContext(InternalProjectContactsContext);

    return context;
  };

export default useInternalProjectContactsContext;
