import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface AdminMenuProps {
  selectedList?: string;
}

const AdminMenu: React.FC<AdminMenuProps> = (props) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="admin">
        <Link to="../admin">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'user-shield']}
            className={classes.icon}
          />
          {t('projects:adminSetting.navigationName')}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default AdminMenu;
