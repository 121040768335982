import { useState } from 'react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getOfficeMeByIdState,
  getOfficeMeIds,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import {
  ProjectId,
  ProjectRole,
  GlobalRole,
  OfficeRole,
  OfficeId,
  CompanyId,
} from '../../../models/Types';
import { distinct } from '../../../util';
import equals from 'deep-equal';
import { createSelector } from 'reselect';
import { User } from '../../../models/User';
import { OfficeMeByIdState } from '../reducers/officeMe';

export type AccessItem =
  | 'writeOtherUserTimeRecord'
  | 'writeOtherUserTimeRecordByProjectRole'
  | 'writeOtherUserTimeRecordByGlobalRole'
  | 'writeOtherUserAbsence'
  | 'writeOtherUserAbsenceByGlobalRole'
  | 'writeOtherUserAbsenceByOfficeRole'
  | 'showControllingModule'
  | 'showOfficesInControllingModule'
  | 'showGlobalInControllingModule'
  | 'showHrModule'
  | 'showOfficesInHrModule'
  | 'showGlobalInHrModule'
  | 'showProjectSettings'
  | 'showProjectSettingsProjectPhase'
  | 'showProjectControlling'
  | 'showAllProjectTimeRecords'
  | 'showPrioSettings'
  | 'showAllPrioSettings'
  | 'showTemplateSettings'
  | 'showGlobalTemplateSettings'
  | 'showOfficeTemplateSettings'
  | 'showUserRoleSettings'
  | 'showUserCompanyData'
  | 'showAddInsSettings'
  | 'showProjectAdminSettings'
  | 'showUserCoreData'
  | 'showUserAbsenceOverview'
  | 'showGlobalRoleSettings'
  | 'showOfficeRoleSettings'
  | 'showGlobalLicenceSettings'
  | 'showOfficeLicenceSettings'
  | 'showUserProfile'
  | 'showProjectModule'
  | 'showContactsModule'
  | 'showCalendar'
  | 'showAllContactsInEmployeePicker'
  | 'archiveContact'
  | 'archiveCompany'
  | 'exportContact'
  | 'exportCompany'
  | 'editInternalContact'
  | 'editExternalContact'
  | 'editInternalCompany'
  | 'editExternalCompany';

const companiesMeRolesSelector = createSelector<
  [
    (state: RootReducerState) => User,
    (state: RootReducerState) => OfficeMeByIdState,
    (state: RootReducerState) => OfficeId[],
  ],
  {
    [key: string]: OfficeRole[];
  }
>(getUserMe, getOfficeMeByIdState, getOfficeMeIds, (userMe, byId, ids) =>
  ids
    .map((id) => byId[id])
    .filter((office) => office)
    .reduce<{ [key: string]: OfficeRole[] }>((currentMap, office) => {
      const currentCompanyRoles = currentMap[office.companyId] ?? [];
      return {
        ...currentMap,
        [office.companyId]: userMe?.prioData?.officeRoles
          ? distinct([
              ...(userMe.prioData.officeRoles[office.officeId] ?? []),
              ...(currentCompanyRoles ?? []),
            ])
          : [],
      };
    }, {})
);

export type RoleAccessMap = Partial<{
  [P in AccessItem]: boolean;
}>;

interface AccessRightsSearchItems {
  projectId?: ProjectId;
  officeId?: OfficeId;
  companyId?: CompanyId;
}

export const useAccessRights: (
  items: AccessItem[],
  searchItems?: AccessRightsSearchItems,
  checkOfficeRolesSpecific?: boolean
) => RoleAccessMap = (
  inputItems: AccessItem[],
  searchItems?: AccessRightsSearchItems,
  checkOfficeRolesSpecific?: boolean
) => {
  const [items, setItems] = useState<AccessItem[]>(inputItems);

  const userMe = useSelector(getUserMe);
  const companiesMeRoles = useSelector(companiesMeRolesSelector);
  useEffect(() => {
    if (!equals(items, inputItems)) {
      setItems(inputItems);
    }
  }, [items, inputItems]);

  const accessRights = useMemo(
    () =>
      items.reduce<RoleAccessMap>(
        (aggregation, current) => ({
          ...aggregation,
          [current]: !userMe
            ? false
            : hasAccess(
                current,
                userMe.prioData.globalRoles,
                (searchItems?.projectId && userMe.prioData.projectRoles
                  ? userMe.prioData.projectRoles[searchItems?.projectId]
                  : Object.values(userMe.prioData.projectRoles).reduce<
                      ProjectRole[]
                    >(
                      (aggregation, current) =>
                        distinct([...aggregation, ...current]),
                      []
                    )) ?? [],
                (searchItems?.officeId && userMe.prioData.officeRoles
                  ? userMe.prioData.officeRoles[searchItems?.officeId]
                  : !checkOfficeRolesSpecific
                  ? Object.values(userMe.prioData.officeRoles).reduce<
                      OfficeRole[]
                    >(
                      (aggregation, current) =>
                        distinct([...aggregation, ...current]),
                      []
                    )
                  : []) ?? [],
                (searchItems?.companyId && companiesMeRoles
                  ? companiesMeRoles[searchItems?.companyId]
                  : []) ?? []
              ),
        }),
        {}
      ),
    [items, userMe, searchItems, companiesMeRoles, checkOfficeRolesSpecific]
  );

  const [savedAccessRights, setSavedAccessRights] = useState<RoleAccessMap>(
    items.reduce(
      (map, item) => ({
        ...map,
        [item]: false,
      }),
      {}
    )
  );

  useEffect(() => {
    if (!equals(accessRights, savedAccessRights)) {
      setSavedAccessRights(accessRights);
    }
  }, [accessRights, savedAccessRights]);
  return useMemo(() => savedAccessRights, [savedAccessRights]);
};

const hasAccess = (
  item: AccessItem,
  globalRoles: GlobalRole[],
  projectRoles: ProjectRole[],
  officeRoles: OfficeRole[],
  companyRoles: OfficeRole[]
) => {
  if (!globalRoles || !projectRoles || !officeRoles || !companyRoles) {
    return false;
  }
  switch (item) {
    case 'writeOtherUserTimeRecord':
      return (
        globalRoles.includes('globalAssistance') ||
        globalRoles.includes('globalHR') ||
        projectRoles.includes('projectAssistance') ||
        globalRoles.includes('globalAdmin') ||
        projectRoles.includes('projectAdmin') ||
        globalRoles.includes('globalController') ||
        projectRoles.includes('projectController') ||
        companyRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAssistance') ||
        companyRoles.includes('officeController')
      );
    case 'writeOtherUserTimeRecordByProjectRole':
      return (
        projectRoles.includes('projectAssistance') ||
        projectRoles.includes('projectAdmin') ||
        projectRoles.includes('projectController')
      );
    case 'writeOtherUserTimeRecordByGlobalRole':
      return (
        globalRoles.includes('globalAssistance') ||
        globalRoles.includes('globalHR') ||
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalController')
      );
    case 'writeOtherUserAbsence':
      return (
        officeRoles.includes('officeHR') ||
        globalRoles.includes('globalHR') ||
        globalRoles.includes('globalAdmin')
      );
    case 'writeOtherUserAbsenceByGlobalRole':
      return (
        globalRoles.includes('globalHR') || globalRoles.includes('globalAdmin')
      );
    case 'writeOtherUserAbsenceByOfficeRole':
      return officeRoles.includes('officeHR');
    case 'showControllingModule':
      return (
        globalRoles.includes('globalController') ||
        officeRoles.includes('officeController') ||
        companyRoles.includes('officeController')
      );
    case 'showOfficesInControllingModule':
      return (
        officeRoles.includes('officeController') ||
        companyRoles.includes('officeController')
      );
    case 'showGlobalInControllingModule':
      return globalRoles.includes('globalController');
    case 'showHrModule':
      return (
        globalRoles.includes('globalHR') ||
        officeRoles.includes('officeHR') ||
        companyRoles.includes('officeHR')
      );
    case 'showOfficesInHrModule':
      return (
        globalRoles.includes('globalHR') ||
        officeRoles.includes('officeHR') ||
        companyRoles.includes('officeHR')
      );
    case 'showGlobalInHrModule':
      return globalRoles.includes('globalHR');
    case 'showProjectSettings':
      return (
        globalRoles.includes('globalAdmin') ||
        projectRoles.includes('projectAdmin') ||
        projectRoles.includes('projectAssistance') ||
        companyRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAssistance') ||
        officeRoles.includes('officeAdmin') ||
        officeRoles.includes('officeAssistance')
      );
    case 'showProjectSettingsProjectPhase':
      return (
        globalRoles.includes('globalAdmin') ||
        projectRoles.includes('projectAdmin') ||
        officeRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAdmin')
      );
    case 'showProjectControlling':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalController') ||
        projectRoles.includes('projectAdmin') ||
        projectRoles.includes('projectController') ||
        companyRoles.includes('officeController')
      );
    case 'showAllProjectTimeRecords':
      return (
        globalRoles.includes('globalController') ||
        globalRoles.includes('globalAssistance') ||
        projectRoles.includes('projectAdmin') ||
        projectRoles.includes('projectAssistance') ||
        projectRoles.includes('projectController') ||
        companyRoles.includes('officeController')
      );
    case 'showPrioSettings':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalHR') ||
        globalRoles.includes('globalAssistance') ||
        officeRoles.includes('officeAdmin') ||
        officeRoles.includes('officeHR') ||
        officeRoles.includes('officeAssistance') ||
        companyRoles.includes('officeAdmin') ||
        companyRoles.includes('officeHR') ||
        companyRoles.includes('officeAssistance')
      );
    case 'showAllPrioSettings':
      return globalRoles.includes('globalAdmin');
    case 'showUserRoleSettings':
      return (
        globalRoles.includes('globalAdmin') ||
        officeRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAdmin')
      );
    case 'showUserCompanyData':
      return (
        globalRoles.includes('globalAdmin') ||
        officeRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAdmin')
      );
    case 'showAddInsSettings':
      return globalRoles.includes('globalAdmin');
    case 'showProjectAdminSettings':
      return globalRoles.includes('globalAdmin');
    case 'showUserCoreData':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalHR') ||
        officeRoles.includes('officeHR')
      );
    case 'showUserAbsenceOverview':
      return (
        globalRoles.includes('globalHR') || officeRoles.includes('officeHR')
      );
    case 'showTemplateSettings':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalAssistance') ||
        officeRoles.includes('officeAdmin') ||
        officeRoles.includes('officeAssistance') ||
        companyRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAssistance')
      );
    case 'showGlobalTemplateSettings':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalAssistance')
      );
    case 'showOfficeTemplateSettings':
      return (
        officeRoles.includes('officeAdmin') ||
        officeRoles.includes('officeAssistance') ||
        companyRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAssistance')
      );
    case 'showGlobalRoleSettings':
      return globalRoles.includes('globalAdmin');
    case 'showOfficeRoleSettings':
      return (
        officeRoles.includes('officeAdmin') ||
        officeRoles.includes('officeHR') ||
        companyRoles.includes('officeAdmin') ||
        companyRoles.includes('officeHR')
      );
    case 'showGlobalLicenceSettings':
      return globalRoles.includes('globalAdmin');
    case 'showOfficeLicenceSettings':
      return (
        officeRoles.includes('officeAdmin') ||
        companyRoles.includes('officeAdmin')
      );
    case 'showUserProfile':
      return globalRoles.includes('globalEmployee');
    case 'showProjectModule':
      return globalRoles.includes('globalEmployee');
    case 'showContactsModule':
      return globalRoles.includes('globalEmployee');
    case 'showCalendar':
      return globalRoles.includes('globalEmployee');
    case 'showAllContactsInEmployeePicker':
      return globalRoles.includes('globalEmployee');
    case 'archiveContact':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalAssistance')
      );
    case 'archiveCompany':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalAssistance')
      );
    case 'exportContact':
      return globalRoles.includes('globalEmployee');
    case 'exportCompany':
      return globalRoles.includes('globalEmployee');
    case 'editInternalContact':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalAssistance') ||
        globalRoles.includes('globalHR') ||
        officeRoles.includes('officeHR') ||
        officeRoles.includes('officeAdmin')
      );
    case 'editExternalContact':
      return globalRoles.includes('globalEmployee');
    case 'editInternalCompany':
      return (
        globalRoles.includes('globalAdmin') ||
        globalRoles.includes('globalAssistance')
      );
    case 'editExternalCompany':
      return globalRoles.includes('globalEmployee');
    default:
      return false;
  }
};

export default useAccessRights;
