import { put } from 'redux-saga/effects';
import { WebSocketMessage } from '../../../models/WebSocket';
import { wsDocumentCreated } from '../actions/ws';
import { notification } from 'antd';
import i18n from '../../../i18n';
import { DocumentSuccessNotification } from '../components/Notifications/DocumentSuccessNotification';
import { Project } from '../../../models/Project';

function* handleDocumentCreated(message: WebSocketMessage, project: Project) {
  if (message.object && message.projectId) {
    const isRoot =
      message?.object?.parentReference?.path.match(/^(\/drive\/root:)$/);
    yield put(
      wsDocumentCreated(
        message?.object,
        message?.projectId,
        isRoot ? null : message?.object?.parentReference?.id,
        project?.groupId
      )
    );

    const key = `open${Date.now()}`;
    notification.open({
      message: i18n.t('common:documents:success'),
      description: (
        <DocumentSuccessNotification
          driveItem={message.object}
          notificationKey={key}
          project={project}
        />
      ),
      key,
      duration: 10,
    });
  } else {
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t(
        'documents:newDocumentForm.errorMessages.createDocumentError'
      ),
    });
  }
}

export default handleDocumentCreated;
