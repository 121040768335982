import { call, put, race, take } from 'redux-saga/effects';
import { ApiResult } from '../api';
import { PRIO } from '../constants';
import { DispatchAction, MetaOffline } from '../models/Redux';
import { LOGGED_OUT } from '../modules/auth/actions';
import { getAccessToken } from '../store/authEffect';
import fetchWithRetry from './fetchWithRetry';

export const SAGA_REBUILD = PRIO + 'SAGA_REBUILD';

const tryParseJSON = (json: string) => {
  if (!json) {
    return null;
  }

  try {
    return JSON.parse(json);
  } catch (e) {
    console.error('Failed to parse unexpected JSON response: '.concat(json), e);
  }
};

const getResponseBody = async (response: Response) => {
  const contentType = response.headers.get('content-type') || false;

  const text = await response.text();
  if (contentType && contentType.indexOf('json') >= 0) {
    return await tryParseJSON(text);
  }

  return text;
};

export const apiCall: (
  url: string,
  method: string,
  body?: any,
  contentType?: string,
  signal?: AbortSignal
) => Promise<ApiResult<any>> = async (
  url,
  method,
  body,
  contentType,
  signal
) => {
  let result = Response.error();
  try {
    result = await fetchWithRetry(url, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': contentType ?? 'application/json',
      },
      method,
      signal,
      ...(body ? { body: JSON.stringify(body) } : {}),
    });
  } catch (error) {
    console.error('Error in saga - apiCall:', error, result);
  }
  return {
    result,
    data:
      result.status >= 200 && result.status < 300
        ? await getResponseBody(result)
        : null,
  };
};

export function* dispatchSaga(
  actionObject: DispatchAction,
  events?: {
    beforeCommit?: (
      data: any,
      actionObject: DispatchAction,
      setCommitEnabled: (value: boolean) => void
    ) => void;
    onRollback?: (actionObject: DispatchAction) => void;
  },
  abortController?: AbortController
) {
  const { type, payload, meta } = actionObject;
  const {
    offline: { effect, rollback, commit },
    ...rest
  } = meta as {
    offline: MetaOffline;
    [key: string]: any;
  };
  try {
    yield put({
      type,
      meta: {
        ...rest,
      },
      ...(payload ? { payload } : {}),
    });

    const {
      task: { result, data },
      cancel,
    } = yield race({
      task: call(
        apiCall,
        effect.url,
        effect.method,
        effect.json,
        effect.headers?.['Content-Type'] ??
          effect.headers?.['content-type'] ??
          'application/json',
        abortController?.signal
      ),
      cancel: take(LOGGED_OUT),
    });

    if (cancel || !(result?.status >= 200 && result?.status < 300)) {
      if (events?.onRollback) {
        yield call(events.onRollback, actionObject);
      }
      const _payload = yield getResponseBody(result);
      yield put({
        ...rollback,
        payload: _payload,
      });
    } else {
      let commitEnabled = true;
      try {
        if (events?.beforeCommit) {
          yield call(
            events.beforeCommit,
            data,
            actionObject,
            (_commitEnabled) => (commitEnabled = _commitEnabled)
          );
        }
      } catch (error) {
        console.error(
          'Error beforeCommit, put COMMIT',
          rollback,
          error,
          actionObject
        );
      } finally {
        if (commitEnabled) {
          yield put({
            payload: data,
            ...commit,
          });
        }
      }
    }
  } catch (error) {
    console.error(
      'Error in dispatch saga. Try to Rollback. Error:',
      error,
      actionObject
    );
    try {
      if (events?.onRollback) {
        yield call(events.onRollback, actionObject);
      }
    } catch (_error) {
      console.error(
        'Error onRollback, put ROLLBACK',
        rollback,
        _error,
        actionObject
      );
    } finally {
      yield put({
        ...rollback,
      });
    }
  }
}
