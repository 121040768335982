import { DocumentTemplate } from '../../../models/DocumentTemplate';
import { Reducer } from 'react';
import { combineReducers } from 'redux';
import {
  DOCUMENT_TEMPLATE_MANAGEMENT_SET_SELECTED_ENTRY,
  DOCUMENT_TEMPLATE_MANAGEMENT_SET_ACTION,
} from '../actions/documentTemplateManagement';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export type DocumentTemplateManagementAction = 'CreateDocumentTemplate' | null;
export interface DocumentTemplateManagementState {
  selectedEntry: DocumentTemplate | null;
  managementAction: DocumentTemplateManagementAction;
}

const selectedEntry: Reducer<DocumentTemplate | null, any> = (
  state = null,
  action
) => {
  switch (action.type) {
    case DOCUMENT_TEMPLATE_MANAGEMENT_SET_SELECTED_ENTRY: {
      const { selectedEntry } = action;
      return selectedEntry;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};
const managementAction: Reducer<DocumentTemplateManagementAction, any> = (
  state = null,
  action
) => {
  switch (action.type) {
    case DOCUMENT_TEMPLATE_MANAGEMENT_SET_ACTION: {
      const { value } = action;
      return value;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

export default combineReducers<DocumentTemplateManagementState>({
  selectedEntry,
  managementAction,
});

export const getSelectedEntry: (
  state: DocumentTemplateManagementState
) => DocumentTemplate | null = (state) => state.selectedEntry;
export const getAction: (
  state: DocumentTemplateManagementState
) => DocumentTemplateManagementAction = (state) => state.managementAction;
