import { useState } from 'react';

const useClickPreventionOnDoubleClick = (
  onClick: (...paramsOnClick: any[]) => void,
  onDoubleClick: (...paramsOnDoubleClick: any[]) => void,
  delay?: number
) => {
  const [, setClick] = useState<'click' | 'doubleClick' | 'none'>('none');
  const [timer, setTimer] = useState(null);

  const handleClick = (...paramsOnClick: any[]) => {
    clearTimeout(timer);
    setClick('click');
    setTimer(
      setTimeout(() => {
        onClick(...paramsOnClick);
        setClick('none');
      }, delay ?? 300)
    );
  };

  const handleDoubleClick = (...paramsOnDoubleClick: any[]) => {
    clearTimeout(timer);
    setClick('doubleClick');
    onDoubleClick(...paramsOnDoubleClick);
    setClick('none');
  };

  return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;
