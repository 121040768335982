import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';

/** Contact fetching */
export const FETCH_USER_ME_REQUEST = PRIO + 'FETCH_USER_ME_REQUEST';
export const FETCH_USER_ME_COMMIT = PRIO + 'FETCH_USER_ME_COMMIT';
export const FETCH_USER_ME_ROLLBACK = PRIO + 'FETCH_USER_ME_ROLLBACK';

export const fetchUserMe = () => ({
  type: FETCH_USER_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: { url: `${apiUrl}/user/User/currentUser`, method: 'GET' },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_USER_ME_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_USER_ME_ROLLBACK,
      },
    },
  },
});
