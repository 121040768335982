import React, { useState, useCallback, useEffect } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import {
  ExternalJobTitleSuggestion,
  CreateExternalJobTitleSuggestion,
} from '../../../models/ExternalJobTitleSuggestion';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import equals from 'deep-equal';
import { ExternalJobTitlesTable } from './ExternalJobTitlesTable';
import { ExternalJobTitleSuggestionId } from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchExternalJobTitles,
  updateExternalJobTitle,
  deleteExternalJobTitle,
  createExternalJobTitle,
} from '../actions/externalJobTitles';
import { createTemporaryId } from '../../../util';
import {
  getExternalJobTitles,
  getExternalJobTitlesIsFetching,
} from '../../../apps/main/rootReducer';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  column: {},
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface ExternalJobTitlesPageProps {
  className?: string;
}

export const ExternalJobTitlesPage: React.FC<ExternalJobTitlesPageProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const { className } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchExternalJobTitles());
  }, [dispatch]);

  const externalJobTitles: ExternalJobTitleSuggestion[] =
    useSelector(getExternalJobTitles);

  const isFetching = useSelector(getExternalJobTitlesIsFetching);

  const [updatedExternalJobTitles, setUpdatedExternalJobTitles] =
    useState<ExternalJobTitleSuggestion[]>(null);

  const onIsDirtyChangedExternalJobTitles = useCallback(
    (isDirty: boolean, hourlyRates: ExternalJobTitleSuggestion[]) => {
      if (isDirty) {
        setUpdatedExternalJobTitles(hourlyRates);
      } else {
        setUpdatedExternalJobTitles(null);
      }
    },
    [setUpdatedExternalJobTitles]
  );

  const onNewJobTitle = async (
    newJobTitle: CreateExternalJobTitleSuggestion
  ) => {
    const temporaryId = createTemporaryId();
    dispatch(createExternalJobTitle(newJobTitle, temporaryId));
  };

  const onDelete = async (
    externalJobTitleSuggestionId: ExternalJobTitleSuggestionId
  ) => {
    dispatch(deleteExternalJobTitle(externalJobTitleSuggestionId));
  };

  const onSave = async () => {
    if (updatedExternalJobTitles !== null) {
      updatedExternalJobTitles.forEach((updatedExternalJobTitle) => {
        const originalExternalJobTitle = externalJobTitles.find(
          (ts) =>
            ts.externalJobTitleSuggestionId ===
            updatedExternalJobTitle.externalJobTitleSuggestionId
        );
        if (
          originalExternalJobTitle &&
          !equals(updatedExternalJobTitle, originalExternalJobTitle)
        ) {
          dispatch(
            updateExternalJobTitle(
              updatedExternalJobTitle,
              originalExternalJobTitle
            )
          );
        }
      });
    }
  };

  if (
    isFetching &&
    (externalJobTitles === null || externalJobTitles.length === 0)
  ) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.column}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <ExternalJobTitlesTable
          className={classes.shadow}
          externalJobTitles={externalJobTitles}
          onIsDirtyChanged={onIsDirtyChangedExternalJobTitles}
          onNewJobTitle={onNewJobTitle}
          onDelete={onDelete}
        />

        <Flex.Row justifyContent="flex-end">
          <Button
            disabled={
              !updatedExternalJobTitles ||
              updatedExternalJobTitles.filter(
                (job) => !job?.name || job?.name === ''
              )?.[0]
                ? true
                : false
            }
            onClick={onSave}
          >
            {t('common:actions.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default ExternalJobTitlesPage;
