import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  CreateTextBlock,
  TextBlock,
} from '../../../models/TextBlock';
import {
  OfficeId,
  TextBlockId,
} from '../../../models/Types';

export interface FetchTextBlocksAction {
  type: 'PRIO_FETCH_TEXT_BLOCKS_COMMIT';
  payload?: TextBlock[];
}

export const FETCH_TEXT_BLOCKS_REQUEST =
  PRIO + 'FETCH_TEXT_BLOCKS_REQUEST';
export const FETCH_TEXT_BLOCKS_COMMIT =
  PRIO + 'FETCH_TEXT_BLOCKS_COMMIT';
export const FETCH_TEXT_BLOCKS_ROLLBACK =
  PRIO + 'FETCH_TEXT_BLOCKS_ROLLBACK';

export const fetchTextBlocks = (officeId?:OfficeId) => ({
  type: FETCH_TEXT_BLOCKS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: officeId ? `${apiUrl}/setting/office/${officeId}/TextBlock` :`${apiUrl}/setting/TextBlock`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_TEXT_BLOCKS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_TEXT_BLOCKS_REQUEST,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchTextBlocksError',
          timeout: 6,
        },
      },
    },
  },
});

export interface TextBlockAction {
  type: string;
  payload?: TextBlock;
  rollbackTextBlock?: TextBlock;
  meta?: {
    textBlockId?: TextBlockId;
    temporaryId?: TextBlockId;
  };
}

export const UPDATE_TEXT_BLOCK_REQUEST =
  PRIO + 'UPDATE_TEXT_BLOCK_REQUEST';
export const UPDATE_TEXT_BLOCK_COMMIT =
  PRIO + 'UPDATE_TEXT_BLOCK_COMMIT';
export const UPDATE_TEXT_BLOCK_ROLLBACK =
  PRIO + 'UPDATE_TEXT_BLOCK_ROLLBACK';

export const updateTextBlock = (
  updatedTextBlock: TextBlock,
  rollbackTextBlock: TextBlock,
  officeId?: OfficeId
) => ({
  type: UPDATE_TEXT_BLOCK_REQUEST,
  requiresAuth: true,
  payload: updatedTextBlock,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: officeId ? `${apiUrl}/setting/office/${officeId}/TextBlock/${updatedTextBlock.textBlockId}` : `${apiUrl}/setting/TextBlock/${updatedTextBlock.textBlockId}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/merge-patch+json',
        },
        json: updatedTextBlock,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_TEXT_BLOCK_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_TEXT_BLOCK_ROLLBACK,
        rollbackTextBlock,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateTextBlockError',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_TEXT_BLOCK_REQUEST =
  PRIO + 'CREATE_TEXT_BLOCK_REQUEST';
export const CREATE_TEXT_BLOCK_COMMIT =
  PRIO + 'CREATE_TEXT_BLOCK_COMMIT';
export const CREATE_TEXT_BLOCK_ROLLBACK =
  PRIO + 'CREATE_TEXT_BLOCK_ROLLBACK';

export const createTextBlock = (
  textBlock: CreateTextBlock,
  temporaryId: TextBlockId,
  officeId?: OfficeId
) => ({
  type: CREATE_TEXT_BLOCK_REQUEST,
  requiresAuth: true,
  payload: textBlock,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: officeId ? `${apiUrl}/setting/office/${officeId}/TextBlock` : `${apiUrl}/setting/TextBlock`,
        method: 'POST',
        json: textBlock,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_TEXT_BLOCK_COMMIT,
        meta: {
          temporaryId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_TEXT_BLOCK_ROLLBACK,
        meta: {
          temporaryId,
        },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.createTextBlockError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

export const ARCHIVE_TEXT_BLOCK_REQUEST =
  PRIO + 'ARCHIVE_TEXT_BLOCK_REQUEST';
export const ARCHIVE_TEXT_BLOCK_COMMIT =
  PRIO + 'ARCHIVE_TEXT_BLOCK_COMMIT';
export const ARCHIVE_TEXT_BLOCK_ROLLBACK =
  PRIO + 'ARCHIVE_TEXT_BLOCK_ROLLBACK';

export const archiveTextBlock = (
  textBlockId: TextBlockId,
  officeId?: OfficeId
) => ({
  type: ARCHIVE_TEXT_BLOCK_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: officeId ? `${apiUrl}/setting/office/${officeId}/TextBlock/${textBlockId}/archive` : `${apiUrl}/setting/TextBlock/${textBlockId}/archive`,
        method: 'PUT',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_TEXT_BLOCK_COMMIT,
        meta: { textBlockId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_TEXT_BLOCK_ROLLBACK,
        meta: { textBlockId },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.archiveTextBlockError',
          timeout: 6,
        },
      },
    },
    textBlockId,
  },
});

export const UNARCHIVE_TEXT_BLOCK_REQUEST =
  PRIO + 'UNARCHIVE_TEXT_BLOCK_REQUEST';
export const UNARCHIVE_TEXT_BLOCK_COMMIT =
  PRIO + 'UNARCHIVE_TEXT_BLOCK_COMMIT';
export const UNARCHIVE_TEXT_BLOCK_ROLLBACK =
  PRIO + 'UNARCHIVE_TEXT_BLOCK_ROLLBACK';

export const unarchiveTextBlock = (
  textBlockId: TextBlockId
) => ({
  type: UNARCHIVE_TEXT_BLOCK_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/TextBlock/${textBlockId}/unarchive`,
        method: 'PUT',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UNARCHIVE_TEXT_BLOCK_COMMIT,
        meta: { textBlockId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UNARCHIVE_TEXT_BLOCK_ROLLBACK,
        meta: { textBlockId },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.unarchiveTextBlockError',
          timeout: 6,
        },
      },
    },
    textBlockId,
  },
});