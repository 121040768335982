import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { makePrioStyles } from '../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface NoPrioUserProps {}

export const NoPrioUser: React.FC<NoPrioUserProps> = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'prio-flex-center-center',
        'prio-flex-column prio-container-fullscreen-height',
        classes.root
      )}
    >
      <Result
        status="403"
        title={t('common:noPrioUser.title')}
        subTitle={t('common:noPrioUser.message')}
      />
    </div>
  );
};

export default NoPrioUser;
