import React from 'react';
import { Modal, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { FolderPermission } from '../../../models/Settings';
import NewFolderForm, {
  CreateFolderRequest,
  valueToPermission,
} from './NewFolderForm';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.old.spacing.unit(1),
  },
}));

interface NewFolderModalProps {
  onOk: (trimmedValue: string, permissions: FolderPermission[]) => void;
  onCancel: () => void;
  visible: boolean;
  currentPath: string[];
  parentPermissions?: FolderPermission[];
  usePermissions?: boolean;
  confirmLoading?: boolean;
}

export const NewFolderModal: React.FC<NewFolderModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    visible,
    onOk,
    onCancel,
    currentPath,
    parentPermissions,
    usePermissions,
    confirmLoading,
  } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [form] = Form.useForm<CreateFolderRequest>();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = (value: CreateFolderRequest) => {
    const permissions: FolderPermission[] = [
      valueToPermission(value.permissions?.[0] ?? 'none', 'admin'),
      valueToPermission(value.permissions?.[1] ?? 'none', 'assistance'),
      valueToPermission(value.permissions?.[2] ?? 'none', 'controller'),
      valueToPermission(value.permissions?.[3] ?? 'none', 'member'),
      valueToPermission(value.permissions?.[4] ?? 'none', 'guest'),
    ];
    onOk(
      value.folderName.trim(),
      permissions.filter((permission) => permission !== null)
    );
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Modal
      className={classes.root}
      title={t('settings:projectFileSystemStructureEditor.newFolder.title')}
      okText={t('settings:projectFileSystemStructureEditor.newFolder.okText')}
      cancelText={t(
        'settings:projectFileSystemStructureEditor.newFolder.cancelText'
      )}
      onOk={handleOk}
      onCancel={handleCancel}
      visible={visible}
      confirmLoading={confirmLoading}
    >
      <NewFolderForm
        parentPermissions={parentPermissions}
        currentPath={currentPath}
        form={form}
        usePermissions={usePermissions}
        onFinish={handleFinish}
      />
    </Modal>
  );
};

export default NewFolderModal;
