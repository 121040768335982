import React from 'react';
import { ContactTag } from '../../../models/Contact';

export type ContactTagsContextType = {
  selectableTags: ContactTag[];
  fetchTags: (() => Promise<void>) | undefined;
};

const ContactTagsContext = React.createContext<ContactTagsContextType>({
  selectableTags: [],
  fetchTags: undefined,
});

export default ContactTagsContext;
