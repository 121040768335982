import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { GlobalRole } from '../models/Types';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface GlobalRolePickerProps {
  className?: string;
  value?: GlobalRole[];
  onChange?: (value: GlobalRole[]) => void;
  disabled?: boolean;
}

export const GlobalRolePicker: React.FC<GlobalRolePickerProps> = (props) => {
  const classes = useStyles();

  const { className, value, onChange, disabled } = props;

  const { t } = useTranslation();

  return (
    <Select
      mode="multiple"
      bordered={true}
      value={value}
      onChange={onChange}
      className={classNames(classes.root, className)}
      disabled={disabled}
      onClick={(event) => event.stopPropagation()}
    >
      <Select.Option value="globalAdmin">
        {t('users:usersTable.columns.globalAdmin')}
      </Select.Option>
      <Select.Option value="globalAssistance">
        {t('users:usersTable.columns.globalAssistance')}
      </Select.Option>
      <Select.Option value="globalEmployee">
        {t('users:usersTable.columns.globalEmployee')}
      </Select.Option>
      <Select.Option value="globalController">
        {t('users:usersTable.columns.globalController')}
      </Select.Option>
      <Select.Option value="globalHR">
        {t('users:usersTable.columns.globalHR')}
      </Select.Option>
    </Select>
  );
};

export default GlobalRolePicker;
