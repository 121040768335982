import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Collapse } from 'antd';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useSelector } from 'react-redux';
import { getUserMe, RootReducerState } from '../../../apps/main/rootReducer';
import { User } from '../../../models/User';
import ProjectInformationMenu from './ProjectInformationMenu';
import GeneralMenu from './GeneralMenu';
import ProjectContactsMenu from './ProjectContactsMenu';
import TimeRecordMenu from './TimeRecordMenu';
import AdminMenu from './AdminMenu';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  collapse: {
    backgroundColor: 'transparent',
    '& > .ant-collapse-item': {
      borderBottom: 'none',
    },
    '& > .ant-collapse-item-active > .ant-collapse-header': {
      fontWeight: theme.old.typography.fontWeight.bold,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 16,
    },
    padding: `0 ${theme.old.spacing.unit(1)}px`,
  },
}));

interface ProjectSettingsMenuProps {
  projectId: string;
  selectedList: string;
}

export const ProjectSettingsMenu: React.FC<ProjectSettingsMenuProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { selectedList } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector<RootReducerState, User>(getUserMe);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column flexGrow={1} className={classes.root}>
      <Typography.Title className={classes.title}>
        <Trans i18nKey="projects:projectSettingsNav.title">Einstellungen</Trans>
      </Typography.Title>
      <Collapse
        bordered={false}
        className={classes.collapse}
        defaultActiveKey={[
          'project-information',
          'generic',
          'project-contacts',
          'time-record',
          'admin',
        ]}
      >
        <Collapse.Panel
          key="project-information"
          header={t('projects:projectSettingsNav.accordion.projectInformation')}
        >
          <ProjectInformationMenu selectedList={selectedList} />
        </Collapse.Panel>
        <Collapse.Panel
          key="generic"
          header={t('projects:projectSettingsNav.accordion.generic')}
        >
          <GeneralMenu selectedList={selectedList} />
        </Collapse.Panel>
        <Collapse.Panel
          key="project-contacts"
          header={t('projects:projectSettingsNav.accordion.projectContacts')}
        >
          <ProjectContactsMenu selectedList={selectedList} />
        </Collapse.Panel>
        <Collapse.Panel
          key="time-record"
          header={t('projects:projectSettingsNav.accordion.timeRecord')}
        >
          <TimeRecordMenu selectedList={selectedList} />
        </Collapse.Panel>
        {userMe.prioData.globalRoles.includes('globalAdmin') ? (
          <Collapse.Panel
            key="admin"
            header={t('projects:projectSettingsNav.accordion.admin')}
          >
            <AdminMenu selectedList={selectedList} />
          </Collapse.Panel>
        ) : null}
      </Collapse>
    </Flex.Column>
  );
};

export default ProjectSettingsMenu;
