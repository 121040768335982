import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';

/** Contact fetching */
export const FETCH_MAIL_ME_CATEGORIES_REQUEST =
  PRIO + 'FETCH_MAIL_ME_CATEGORIES_REQUEST';
export const FETCH_MAIL_ME_CATEGORIES_COMMIT =
  PRIO + 'FETCH_MAIL_ME_CATEGORIES_COMMIT';
export const FETCH_MAIL_ME_CATEGORIES_ROLLBACK =
  PRIO + 'FETCH_MAIL_ME_CATEGORIES_ROLLBACK';

export const fetchMailCategoriesMe = (reloadCache?: boolean) => ({
  type: FETCH_MAIL_ME_CATEGORIES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Outlook/Me/masterCategories${
          reloadCache ? '?reload=true' : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_MAIL_ME_CATEGORIES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MAIL_ME_CATEGORIES_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.categories.fetchError',
          timeout: 3,
        },
      },
    },
  },
});
