import { put } from 'redux-saga/effects';
import { ContactCompanyChangedEvent } from '../../../models/ContactCompany';
import { wsUpdateOrAddCompany } from '../actions';
import { Company } from '../../../models/Company';

export function* handleCompanyChangedMessage(
  contactCompanyChangedEvent: ContactCompanyChangedEvent
) {
  yield put(
    wsUpdateOrAddCompany(contactCompanyChangedEvent.contactOrCompany as Company)
  );
}
