import React, { useState, useCallback, useEffect } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import { Configuration } from '../../../models/Configuration';
import Flex from '../../../components/Flex';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { ConfigurationTable } from './ConfigurationTable';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';
import { asyncForEach } from '../../../util';
import {
  apiCreateConfiguration,
  apiFetchConfigurations,
  apiUpdateConfiguration,
} from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  column: {},
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface ConfigurationPageProps {
  className?: string;
}

export const ConfigurationPage: React.FC<ConfigurationPageProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { className } = props;
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [updatedConfigurations, setUpdatedConfigurations] =
    useState<Configuration[]>(null);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations(signal);
      if (data) setConfigurations(data);
    };
    loadConfigs();
    return () => {
      controller.abort();
    };
  }, []);
  const onIsDirtyChangedConfigurations = useCallback(
    (isDirty: boolean, configurations: Configuration[]) => {
      if (isDirty) {
        setUpdatedConfigurations(configurations);
      } else {
        setUpdatedConfigurations(null);
      }
    },
    [setUpdatedConfigurations]
  );

  const onNewConfiguration = async (newConfiguration: Configuration) => {
    const { data } = await apiCreateConfiguration(newConfiguration);
    if (data) {
      setConfigurations([...configurations, data]);
      if (updatedConfigurations)
        setUpdatedConfigurations([...updatedConfigurations, data]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.createConfigurationsError'),
      });
    }
  };

  const onSave = async () => {
    setSaving(true);
    if (updatedConfigurations !== null) {
      await asyncForEach(updatedConfigurations, async (updatedConfig) => {
        const originalConfig: Configuration = configurations.find(
          (config) => config.configurationId === updatedConfig.configurationId
        );
        if (!equals(updatedConfig, originalConfig)) {
          await saveUpdateConfiguration(updatedConfig, originalConfig);
        }
      });
    }
    setSaving(false);
  };

  const saveUpdateConfiguration = async (
    updatedConfig: Configuration,
    originalConfig: Configuration
  ) => {
    const { data } = await apiUpdateConfiguration(
      updatedConfig,
      originalConfig
    );
    if (data) {
      setConfigurations([
        ...configurations.map((config) =>
          config.configurationId === updatedConfig.configurationId
            ? data
            : config
        ),
      ]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.updateConfigurationsError'),
      });
    }
  };

  if (configurations === null) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.column}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <ConfigurationTable
          className={classes.shadow}
          configurations={configurations}
          onIsDirtyChanged={onIsDirtyChangedConfigurations}
          onNewConfiguration={onNewConfiguration}
        />

        <Flex.Row justifyContent="flex-end">
          <Button disabled={!updatedConfigurations || saving} onClick={onSave}>
            {t('common:actions.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default ConfigurationPage;
