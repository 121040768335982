import React from 'react';
import { useSelector } from 'react-redux';
import { getActiveProjectInMessageCenter } from '../../../../apps/main/rootReducer';
import { Message } from '../../../../models/Message';
import { ProjectId } from '../../../../models/Types';
import MailNavigationBar from '../NavigationBar/MailNavigationBar';

interface MessageCenterNavigationBarProps {
  projectId: ProjectId;
  doShowNewMessage: (
    projectId: ProjectId,
    setIsCreating: (value: boolean) => void
  ) => Promise<void>;
  selectedMessages: Message[];
  clearSelection: VoidFunction;
}

export const MessageCenterNavigationBar: React.FC<
  MessageCenterNavigationBarProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId, doShowNewMessage, ...rest } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const activeProjectId = useSelector(getActiveProjectInMessageCenter);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  return (
    <MailNavigationBar
      projectId={activeProjectId ?? 'favorites'}
      doShowNewMessage={async (setIsCreating) =>
        doShowNewMessage(activeProjectId, setIsCreating)
      }
      isFavoriteView={!projectId}
      {...rest}
    />
  );
};

export default MessageCenterNavigationBar;
