import { useQuery } from '@tanstack/react-query';
import { DocumentTag } from '../../../models/Document';
import { apiFetchMyDocumentTags } from '../../../modules/documents/api';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';

const DocumentTagPickerConsumer: React.FC<FilterPickerConsumerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { data } = useQuery({
    queryKey: ['globalDocumentTags'],
    queryFn: async () => apiFetchMyDocumentTags(),
  });

  const documentTags: DocumentTag[] =
    (data?.data ?? []).sort((a, b) => a.name.localeCompare(b.name)) || [];

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: documentTags.map((tag) => {
          return {
            value: tag.name,
            label: tag.name,
            searchValue: tag.name,
          };
        }),
      })}
    </>
  );
};

export default DocumentTagPickerConsumer;
