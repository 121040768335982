import React from 'react';
import { useDispatch } from 'react-redux';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import MailSelectionList from '../MailSelectionList';
import MessagePage from '../MessagePage';
import { MailLayoutProps } from '../../util';
import { updateMailSettings } from '../../../userSettings/actions/mailSettings/mail';
import MailListResizable from '../MailListResizable';
import * as Sentry from '@sentry/react';
import { makePrioStyles } from '../../../../theme/utils';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  mailMessageMenu: {
    overflow: 'hidden',
  },
  detailsPane: {
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  detailsPaneColumn: {
    overflow: 'hidden',
    borderTop: theme.old.borders.sub,
    paddingTop: theme.old.spacing.unit(0.5),
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
}));

export const MailColumnLayout: React.FC<MailLayoutProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId, selectedMessages, onSelectionChange, mailSettings } =
    props;

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const listHeight = mailSettings.mailNavigationSize?.height;
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column flex={1} className={classes.content}>
      <MailListResizable
        size={{ height: `${mailSettings.mailNavigationSize?.height}` }}
        listLayout="vertical"
        onResizeStop={(e, d, r, delta) =>
          dispatch(
            updateMailSettings({
              mailNavigationSize: {
                ...mailSettings.mailNavigationSize,
                height: mailSettings.mailNavigationSize.height + delta.height,
              },
            })
          )
        }
      />
      <Routes>
        <Route
          path={`${projectId === 'favorites' ? 'inbox' : ':mailFolderId'}/*`}
          element={
            <MailSelectionList
              className={classes.mailMessageMenu}
              projectId={projectId}
              selectedMessages={selectedMessages}
              onSelectionChange={onSelectionChange}
              itemLayout="horizontal"
              mailListSpacing={mailSettings.mailListSpacing}
              mailListNavigationWidth={mailSettings.mailNavigationSize.width}
              automaticRead={mailSettings.automaticRead}
              listLayout="bottom"
              listHeight={listHeight}
              mailSearchExpanded
            />
          }
        />
      </Routes>
      <Flex.Item flex={1} className={classes.detailsPaneColumn}>
        <MessagePage projectId={projectId} />
      </Flex.Item>
    </Flex.Column>
  );
};

export default MailColumnLayout;
