import {
  ProjectId,
  PlanId,
  GroupId,
  EMailSuffix,
  ProjectSettingId,
  GlobalProjectSettingType,
  GlobalProjectSettingId,
  ProjectNewsType,
  CompanyId,
  DateString,
} from './Types';
import { FluentValidationError } from '../models/ValidationResults';
import { ProjectExtension, ProjectExtensionMe } from './ProjectExtension';
import { Moment } from 'moment';

export type ProjectStatus =
  | 'ready'
  | 'waitToCreateProject'
  | 'startProjectInProgress'
  | 'createGroupsInProgress'
  | 'createTeamInProgress'
  | 'createMailboxInProgress'
  | 'createFoldersInProgress'
  | 'finished'
  | 'ready'
  | 'draft'
  | 10;

export interface UpdateProjectDraftDto {
  /**
   * A string property representing the unique number assigned to the project.
   */
  number: string;

  /**
   * A string property representing the name of the project.
   */
  name: string;

  /**
   * A string property representing the unique ID of the company associated with the project.
   */
  companyId?: string;

  /**
   * A string property representing the unique ID of the internalOffice associated with the project.
   */
  internalOfficeId?: string;

  /**
   * A string property representing the short name of the project.
   */
  shortName?: string;

  /**
   * A string property representing the parent project of the new project draft.
   */
  parentProject?: string;
}

export interface CreateProjectDraftDto {
  /**
   * A string property representing the unique number assigned to the project.
   */
  number: string;

  /**
   * A string property representing the name of the project.
   */
  name: string;

  /**
   * A string property representing the unique ID of the company associated with the project.
   */
  companyId?: string;

  /**
   * A string property representing the unique ID of the internalOffice associated with the project.
   */
  internalOfficeId?: string;

  /**
   * A string property representing the short name of the project.
   */
  shortName?: string;

  /**
   * A string property representing the parent project of the new project draft.
   */
  parentProject?: string;
}

export interface AttributeValidations {
  isValid: boolean;
  attributeErrorValidationList?: AttributeValidations[];
}

export const emptyProject = {
  number: '',
  name: '',
  companyId: null,
  subsidiaryId: null,
  isArchived: false,
  shortName: '',
  parentProject: '',
  projectId: '',
};
export interface CreateProjectDraftByProjectDto {
  number: string;
  name: string;
  draftProjectId: string;
  useHourlyRates: boolean;
  useKilometerRates: boolean;
  useInternalProjectContacts: boolean;
  useExternalProjectContacts: boolean;
  useProjectSettings: boolean;
  useDistributionLists: boolean;
}
export interface CreateProjectDraftValidationDto {
  projectName: AttributeValidation;
  projectNumber: AttributeValidation;
}
export const emptyCreateProjectDraftByProjectDto = {
  number: '',
  name: '',
  useHourlyRates: true,
  useKilometerRates: true,
  useInternalProjectContacts: true,
  useExternalProjectContacts: true,
  useProjectSettings: true,
  useDistributionLists: true,
};
export const emptyCreateProjectDraftDto = {
  number: '',
  name: '',
  companyId: null,
  subsidiaryId: null,
  isArchived: false,
  shortName: '',
  parentProject: '',
};
export interface Project {
  projectId: ProjectId;
  groupId?: GroupId;
  number?: string;
  name: string;
  eMailPrefix?: string;
  eMailSuffix?: EMailSuffix;
  companyId?: string;
  isArchived?: boolean;
  shortName?: string;
  oneDrivePath?: string;
  subsidiaryId?: string;
  internalOfficeId?: string;
  isTemporary?: boolean;
  isUpdating?: boolean;
  favorite?: boolean;
  projectFileSystemStructureId?: string;
  masterPlanId?: PlanId;
  projectStatus?: ProjectStatus;
  parentProject?: ProjectId;
  sharedMailboxUserId?: string;
  creatorId?: string;
  rowVersion?: string;
  projectExtensions?: ProjectExtension[];
  projectExtensionMeDtos?: ProjectExtensionMe[];
  isVisible?: boolean;
}

export interface AdditionalProjectInformation {
  additionalProjectInformationId: string;
  projectId: string;
  content1: string;
  content2: string;
  content3: string;
  content4: string;
  content5: string;
}

export interface ProjectMetaData {
  projectMetaDataId?: string;
  projectType: string;
  sector: string;
  summary: string;
  detailedDescription: string;
  internalContactPersonId: string;
  externalContactPersonId: string;
  address: string;
  coordinates: string;
  startDate: DateString | Moment;
  endDate: DateString | Moment;
  mainProjectId?: string;
}

export interface CreateProjectRequest {
  number: string;
  name: string;
  eMailPrefix: string;
  eMailSuffix: EMailSuffix;
  companyId?: string;
  isArchived: boolean;
  shortName?: string;
  subsidiaryId: string;
  projectFileSystemStructureId: string;
}

export interface CreateSubprojectRequest {
  subprojectNumber: string;
  name: string;
  shortName: string;
}

export const emptyCreateProjectRequest = {
  number: '',
  name: '',
  eMailPrefix: '',
  companyId: null,
  isArchived: false,
  shortName: '',
};

export interface ProjectNewsDto {
  projectId: string;
  projectNewsId: string;
  projectNewsType: ProjectNewsType;
  referenceObjectId: string;
  rowVersion: string;
}

export interface ProjectNews {
  projectId: string;
  projectNewsDtos: ProjectNewsDto[];
}

export interface ProjectSetting {
  globalProjectSettingId: GlobalProjectSettingId;
  projectId: ProjectId;
  useProjectSetting: boolean;
  objectType: GlobalProjectSettingType;
  projectSettingId?: ProjectSettingId;
  rowVersion?: string;
}

export interface ProjectMailSetting extends ProjectSetting {
  objectJson?: {
    value: string;
  };
}

export interface ProjectDocumentSetting extends ProjectSetting {
  objectJson?: {
    value: string;
  };
}

export interface ProjectAssignment {
  key: React.Key;
  projectId?: ProjectId;
  jobtitle?: string;
}

export interface ProjectToCreatePreview {
  projectName?: string;
  projectShortName?: string;
  projectEmail?: string;
  projectNumber?: string;
  projectToCreateValidationErrors?: ProjectToCreatePreviewValidationError;
}
export interface ProjectToCreatePreviewValidationError {
  projectName: AttributeValidation;
  projectEmail: AttributeValidation;
  projectNumber: AttributeValidation;
  internalCompanyId: AttributeValidation;
  projectFileSystemStructureId: AttributeValidation;
}
export interface AttributeValidation {
  isValid: boolean;
  description: string;
}
export interface AttributeValidations {
  isValid: boolean;
  descriptions: string[];
}

export interface SubprojectToCreatePreviewValidationError
  extends FluentValidationError {
  errors: {
    ProjectName: string[];
    ProjectNumber: string[];
  };
}

export interface ValidateProjectForCreationModel {
  projectName: string;
  projectShortName: string;
  projectEmail: string;
  projectNumber: string;
  projectFileSystemStructureId: string;
  internalCompany: CompanyId;
}
export interface EmailProjectSettingPreview {
  [emailProjectSettingType: string]: string;
}

export interface DocumentPrefixProjectSettingPreview {
  [documentProjectSettingType: string]: string;
}

export interface ProjectInfo {
  projectId: string;
  name: string;
  shortName: string;
  number: string;
  projectClientCompanyId: string;
  contractorCompanyId: string;
  isVisible: boolean;
}
