import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Company } from '../../models/Company';
import { BasisFilterPicker } from '@prio365/prio365-react-library';
import { Contact } from '../../models/Contact';
import { DocumentTag } from '../../models/Document';
import { Project } from '../../models/Project';
import { Office } from '../../models/Office';
import { useTranslation } from 'react-i18next';
import FilterPickerDynamicConsumer, {
  FilterPickerRolesMap,
} from './PickerContextConsumer/FilterPickerDynamicConsumer';
import { GlobalRole, OfficeRole, ProjectRole } from '../../models/Types';
import { DocumentTemplateCategory } from '../../models/DocumentTemplate';
import { makePrioStyles } from '../../theme/utils';
import { FilterRole } from './types';

export const FilterPickerTypeStrings = [
  'project',
  'contact',
  'internalContact',
  'externalContact',
  'employee',
  'company',
  'internalCompany',
  'externalCompany',
  'office',
  'internalOffice',
  'externalOffice',
  'documentTemplateTag',
  'documentTemplateCategory',
  'contactTag',
  'fileExtension',
  'documentTag',
  'projectsWithSharePoint',
];

export type FilterPickerType = (typeof FilterPickerTypeStrings)[number];

export type OptionsType =
  | Company[]
  | Contact[]
  | Project[]
  | Office[]
  | DocumentTag[]
  | DocumentTemplateCategory[];

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface FilterPickerProps {
  className?: string;
  type?: FilterPickerType;
  roles?: FilterRole[];
  value?: string[];
  prefixOption?: string;
  onChange?: (value: string[], option) => void;
  zIndex?: string;
}

export const FilterPicker: React.FC<FilterPickerProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, type, value, onChange, prefixOption, zIndex, roles } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const mappedRoles: FilterPickerRolesMap = useMemo(() => {
    return {
      projectRoles: (roles ?? []).filter((role) =>
        role.includes('project')
      ) as ProjectRole[],
      officeRoles: (roles ?? []).filter((role) =>
        role.includes('office')
      ) as OfficeRole[],
      globalRoles: (roles ?? []).filter((role) =>
        role.includes('global')
      ) as GlobalRole[],
    };
  }, [roles]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnChange = (value, option) => {
    if (onChange) {
      onChange(
        value.filter((value) => !!value),
        option
      );
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <FilterPickerDynamicConsumer
      type={type}
      roles={mappedRoles}
      value={value ?? []}
      onChange={handleOnChange}
    >
      {({ options, ...rest }) => {
        return (
          <BasisFilterPicker<string[]>
            zIndex={zIndex}
            className={classNames(classes.root, className)}
            options={options}
            value={value ?? []}
            onChange={handleOnChange}
            placeholder={t('components:filter.all')}
            prefixOption={prefixOption}
            mode="multiple"
            {...rest}
          />
        );
      }}
    </FilterPickerDynamicConsumer>
  );
};

export default FilterPicker;
