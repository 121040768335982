import { useSelector } from 'react-redux';
import {
  getContactsByIdState,
  getProjectByIdState,
} from '../../../apps/main/rootReducer';
import { exportToCsv } from '../../../util/export';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { ContactsByIdState } from '../../contacts/reducers/contacts';
import { TimeRecordSearchResultItem } from '../../../models/TimeRecord';

export const exportTimeRecordsToCsv: (
  timeRecords: TimeRecordSearchResultItem[],
  projectsById: ProjectByIdState,
  contactsById: ContactsByIdState
) => void = (timeRecords, projectsById, contactsById) => {
  const dataRows: string[][] = timeRecords.map((timeRecord) => {
    if (timeRecord) {
      const project = timeRecord.data.projectId
        ? projectsById[timeRecord.data.projectId]
        : null;
      const contact = timeRecord.data.contactId
        ? contactsById[timeRecord.data.contactId]
        : null;
      return [
        project?.name,
        timeRecord.data.day,
        timeRecord.calculated.hours?.toFixed(2),
        timeRecord.data.title,
        timeRecord.data.description,
        timeRecord.data.kilometerDistance?.toFixed(2),
        timeRecord.data.invoiceNumber,
        timeRecord.calculated.projectPhase,
        contact?.firstName,
        contact?.lastName,
      ];
    }
    return [];
  });

  const headers = [
    'projectName',
    'day',
    'durationInHours',
    'title',
    'description',
    'kilometerDistance',
    'invoiceNumber',
    'projectPhase',
    'firstName',
    'lastName',
  ];
  exportToCsv(headers, dataRows, 'timeRecords.csv');
};

export function useExportTimeRecordsToCsv(): (
  timeRecords: TimeRecordSearchResultItem[]
) => void {
  const projectsById = useSelector(getProjectByIdState);
  const contactsById = useSelector(getContactsByIdState);

  return (timeRecords: TimeRecordSearchResultItem[]) =>
    exportTimeRecordsToCsv(timeRecords, projectsById, contactsById);
}
