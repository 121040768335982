import React, { useState } from 'react';
import Flex from '../../../components/Flex';
import { TemplatesMenu } from './TemplatesMenu';
import {
  useParams,
  Routes as ReactRouterDomRoutes,
  Route,
} from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';
import ProjectFileSystemStructureTable from './ProjectFileSystemStructureTable';
import NavigationBar from '../../../components/NavigationBar';
import { Button } from '@prio365/prio365-react-library';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  textBlockManagementSetOpen,
  textBlockManagementSetSelectedEntry,
} from '../actions/textBlockManagement';
import classNames from 'classnames';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../theme/types';
import { documentTemplateManagementSetAction } from '../../documents/actions/documentTemplateManagement';
import DocumentTemplateCategoryContextProvider from '../../documents/components/DocumentTemplateCategoryContextProvider';
import DocumentTemplateTagContextProvider from '../../documents/components/DocumentTemplateTagContextProvider';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const ProjectFileSystemStructureEditor = React.lazy(
  () => import('./ProjectFileSystemStructureEditor')
);
const DocumentTemplateManagement = React.lazy(
  () => import('../../documents/components/DocumentTemplateManagement')
);
const TextBlocksManagement = React.lazy(() => import('./TextBlocksManagement'));

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  projectFileSystemStructureEditor: {
    flex: 1,
    width: '100%',
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    width: '100%',
  },
  saveNameButton: {},
  mainContent: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface TemplateSettingsProps {
  className?: string;
  pathPrefix?: string;
}

export const TemplateSettings: React.FC<TemplateSettingsProps> = (props) => {
  const classes = useStyles();
  const { className, pathPrefix } = props;
  const { selectedList, officeId } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const createTextBlock = () => {
    dispatch(textBlockManagementSetOpen(true));
    dispatch(textBlockManagementSetSelectedEntry(null));
  };
  const [documentTemplateDrawerOpen, setDocumentTemplateDrawerOpen] =
    useState(false);

  const [newFolder, setNewFolder] = useState<boolean>(false);

  const openNewFolder = () => setNewFolder(true);
  const closeNewFolder = () => setNewFolder(false);
  const openDrawerToCreateDocumentTemplate = () => {
    dispatch(documentTemplateManagementSetAction('CreateDocumentTemplate'));
    setDocumentTemplateDrawerOpen(true);
  };

  let component: React.ReactNode;
  switch (selectedList) {
    case 'folders':
      component = (
        <Routes>
          <Route
            path=":projectFileSystemStructureId"
            element={
              <ProjectFileSystemStructureEditor
                className={classes.projectFileSystemStructureEditor}
                newFolder={newFolder}
                closeNewFolder={closeNewFolder}
              />
            }
          />
          <Route
            path="new"
            element={
              <ProjectFileSystemStructureEditor
                className={classes.projectFileSystemStructureEditor}
                newFolder={newFolder}
                closeNewFolder={closeNewFolder}
              />
            }
          />
          <Route
            path="/"
            element={
              <ProjectFileSystemStructureTable className={classes.content} />
            }
          />
        </Routes>
      );
      break;
    case 'documents':
      component = (
        <DocumentTemplateManagement
          officeId={officeId}
          setDocumentTemplateDrawerOpen={setDocumentTemplateDrawerOpen}
          documentTemplateDrawerOpen={documentTemplateDrawerOpen}
        />
      );
      break;
    case 'textBlocks':
      component = <TextBlocksManagement officeId={officeId} />;
      break;
    default:
  }
  let navigationItems: React.ReactNode;
  switch (selectedList) {
    case 'folders':
      navigationItems = (
        <Routes>
          <Route
            path="/"
            element={
              <Link to="new">
                <Button iconProp={['fal', 'folder-tree']}>
                  <span>
                    {t(
                      'settings:navigationBar.createProjectFileSystemStructure'
                    )}
                  </span>
                </Button>
              </Link>
            }
          />
          <Route
            path="/*"
            element={
              <Button onClick={openNewFolder} iconProp={['fal', 'folder-plus']}>
                <span>{t('settings:navigationBar.createFolder')}</span>
              </Button>
            }
          />
        </Routes>
      );
      break;
    case 'documents':
      navigationItems = (
        <Button
          onClick={openDrawerToCreateDocumentTemplate}
          iconProp={['fal', 'file-alt']}
        >
          <span>{t('documents:navigationBar.createDocumentTemplate')}</span>
        </Button>
      );
      break;
    case 'textBlocks':
      navigationItems = (
        <Button onClick={createTextBlock} iconProp={['fal', 'file-alt']}>
          <span>{t('settings:textBlock.navigationBar.createTextBlock')}</span>
        </Button>
      );
      break;
    default:
  }

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <DocumentTemplateCategoryContextProvider>
        <DocumentTemplateTagContextProvider>
          <NavigationBar children={navigationItems} />
          <Flex.Row flex={1} className={classes.mainContent}>
            <TemplatesMenu
              selectedList={selectedList}
              urlPrefix={pathPrefix}
              className={classes.menu}
              officeId={officeId}
            />
            {component}
          </Flex.Row>
        </DocumentTemplateTagContextProvider>
      </DocumentTemplateCategoryContextProvider>
    </Flex.Column>
  );
};

export default TemplateSettings;
