import React, { useEffect, useState } from 'react';
import { makePrioStyles } from '../../theme/utils';
import { Card, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from '../../components/Flex';
import { getAccessToken } from '../../store/authEffect';
import { apiUrl } from '../../api';
import { registrationComplete } from '../users/actions/registrationState';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMe } from '../../apps/main/rootReducer';
import RegistrationForm from '../employee/components/RegistrationForm';
import { CreateRegistrationEmployee } from '../../models/Employee';
import useDebounce from '../../hooks/useDebounce';
import { apiCreateRegistrationEmployee } from '../employee/api/index';
import { setPrioVersion } from '../../actions';
import packageJson from '../../../package.json';
import PrioSpinner from '../../components/PrioSpinner';
import fetchWithRetry from '../../util/fetchWithRetry';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 375ms ease-in-out',
  },
  card: {
    width: '35%',
  },
  button: {
    marginTop: theme.old.spacing.unit(2),
  },
}));

interface WelcomeWizardProps {}

export const WelcomeWizard: React.FC<WelcomeWizardProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const userMe = useSelector(getUserMe);

  const [registrationCompleted, setRegistrationCompleted] =
    useState<boolean>(false);

  const debouncedRegistrationCompleted = useDebounce(
    registrationCompleted,
    5000
  );

  const handleOnFinish = async (value: CreateRegistrationEmployee) => {
    const result = await apiCreateRegistrationEmployee(value);
    if (result) {
      setRegistrationCompleted(true);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('employee:messages.error.registerMeError'),
      });
    }
  };

  useEffect(() => {
    if (debouncedRegistrationCompleted) {
      const accept = async () => {
        setIsSaving(true);
        const result = await fetchWithRetry(
          `${apiUrl}/user/User/${userMe?.id}/register`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              'Content-Type': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify({
              registrationCompleted: true,
              firstName: userMe?.givenName,
              lastName: userMe?.surname,
            }),
          }
        );
        if (result.status >= 200 && result.status < 300) {
          dispatch(registrationComplete());
        } else {
          notification.open({
            message: t('common:error'),
            description: t('common:welcome.completeRegistrationError'),
          });
        }
        dispatch(setPrioVersion(packageJson.version));
        setIsSaving(false);
      };
      accept();
    }
  }, [debouncedRegistrationCompleted, dispatch, t, userMe]);

  return (
    <div className={classes.root}>
      <Card title={t('common:welcome.title')} className={classes.card}>
        <Flex.Column>
          {!registrationCompleted ? (
            <Flex.Column>
              <p>{t('common:welcome.form')}</p>
              <RegistrationForm
                onFinish={handleOnFinish}
                actionLabel={t('common:welcome.button')}
              />
            </Flex.Column>
          ) : (
            <Flex.Column alignItems="center">
              <p>{t('common:welcome.description')}</p>
              {(isSaving ||
                registrationCompleted !== debouncedRegistrationCompleted) && (
                <PrioSpinner size="large" />
              )}
            </Flex.Column>
          )}
        </Flex.Column>
      </Card>
    </div>
  );
};

export default WelcomeWizard;
