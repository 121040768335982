import React, { CSSProperties, useEffect, useState } from 'react';
import { Tag } from 'antd';
import { MailCategoryColorName } from '../../../../models/Types';
import { CloseOutlined } from '@ant-design/icons';
import { colorFromPreset, hexToHSL } from '../../util';
import { PrioTheme } from '../../../../theme/types';
import { CustomTagProps } from 'rc-select/lib/interface/generator';
import { useTheme } from 'react-jss';
import classNames from 'classnames';

interface CategoryTagProps extends CustomTagProps {
  className?: string;
  colorMap: {
    [displayName: string]: MailCategoryColorName;
  };
  disableText?: boolean;
  tagNameStyle?: CSSProperties;
  hidden?: boolean;
}

export const CategoryTag: React.FC<CategoryTagProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    value,
    closable,
    onClose,
    colorMap,
    tagNameStyle,
    hidden,
  } = props;

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isClosable, setIsClosable] = useState<boolean>(closable);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setIsClosable(true);
  }, [value]);
  //#endregion

  if (!colorMap) {
    return null;
  }
  //#region ------------------------------ States / Attributes / Selectors
  const colorPreset = colorFromPreset(colorMap[value as string], theme);
  const colorPresetHSLDark = hexToHSL(
    colorFromPreset(colorMap[value as string], theme),
    { lFixed: 30 }
  );
  const colorPresetHSLLight = hexToHSL(
    colorFromPreset(colorMap[value as string], theme),
    { lFixed: 95 }
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  return (
    <Tag
      className={classNames(className, {
        'prio-mail-category-hidden-unless-parent-hover': hidden,
      })}
      style={{ border: `1px solid ${colorPreset}`, color: colorPresetHSLDark }}
      color={colorPresetHSLLight}
      closable={isClosable}
      onClose={(event) => {
        event.stopPropagation();
        event.preventDefault();
        setIsClosable(false);
        onClose();
      }}
      closeIcon={<CloseOutlined style={{ color: colorPresetHSLDark }} />}
    >
      <span
        style={{ ...tagNameStyle }}
        title={value as string}
        className={'prio-mail-category-name'}
      >
        {value}
      </span>
    </Tag>
  );
};

export default CategoryTag;
