import { AppContext } from "../models/Types";
import * as microsoftTeams from "@microsoft/teams-js";

export let officeContext: boolean = false;
export let teamsContext: boolean = false;
export let appContext: AppContext = 'browser';

export function setOutlookContext(isOutlook: boolean) {
    officeContext = isOutlook;
}

export function setTeamsContext(isTeams: boolean) {
    teamsContext = isTeams;
}

export function setAppContext(context: AppContext) {
    appContext = context;
}

export const initializeMicrosoftTeams = new Promise<boolean>((resolve) => {
    microsoftTeams.initialize(() => {
        resolve(true);
    });
    setTimeout(() => { resolve(false) }, 1000);
});