import React from 'react';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import TrainingDayLocationInfo from './TrainingDayLocationInfo';
import TrainingDetailsModule from './TrainingDetailsModule';
import TrainingRequestModule from './TrainingRequestModule';
import { Divider } from 'antd';
import { TrainingOverview } from '../../../models/Training';
import {
  DepartmentsById,
  QualificationsById,
} from '../../hr/components/HRTrainings';
import { isBefore, parseISO } from 'date-fns';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.old.spacing.unit(6),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  dayLocationInfo: {
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
    minwidth: 300,
  },
  divider: {
    height: '100%',
  },
}));

interface TrainingItemProps {
  trainingOverview?: TrainingOverview;
  qualificationsById: QualificationsById;
  departmentsById: DepartmentsById;
}

export const TrainingItem: React.FC<TrainingItemProps> = (props) => {
  const { trainingOverview, qualificationsById, departmentsById } = props;
  const classes = useStyles();
  const sortedTrainingRequests = trainingOverview.trainingRequests.sort(
    (a, b) =>
      isBefore(parseISO(a.registrationDate), parseISO(b.registrationDate))
        ? 1
        : -1
  );
  const myTrainingRequest =
    sortedTrainingRequests.length > 0 ? sortedTrainingRequests[0] : null;

  return (
    <Flex.Row className={classes.root}>
      <Flex.Item flex={1}>
        <TrainingDayLocationInfo
          trainingDays={trainingOverview.trainingDays}
          eventLocation={trainingOverview.eventLocation}
          isOnlineTraining={trainingOverview.isOnlineTraining}
        />
      </Flex.Item>
      <Flex.Item flex={3}>
        <TrainingDetailsModule
          title={trainingOverview.title}
          description={trainingOverview.description}
          trainingManagers={trainingOverview.trainingManagers}
          applicationDeadline={trainingOverview.applicationDeadline}
          trainingOrganizer={trainingOverview.trainingOrganizer}
          eventRoom={trainingOverview.eventRoom}
          trainingTargetQualificationIds={
            trainingOverview.trainingTargetQualificationIds
          }
          trainingTargetDepartmentIds={
            trainingOverview.trainingTargetDepartmentIds
          }
          maxParticipants={trainingOverview.maxParticipants}
          qualificationsById={qualificationsById}
          departmentsById={departmentsById}
        />
      </Flex.Item>
      <Divider type="vertical" className={classes.divider} />
      <Flex.Item flex={1}>
        <TrainingRequestModule
          trainingId={trainingOverview.trainingId}
          myTrainingRequest={myTrainingRequest}
        />
      </Flex.Item>
    </Flex.Row>
  );
};

export default TrainingItem;
