import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { longDateFormatString } from '../../../util';
import i18n from '../../../i18n';
import { Message } from '../../../models/Message';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { useSelector } from 'react-redux';
import { getMailSettings } from '../../../apps/main/rootReducer';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
  },
  rootCompressedView: {
    padding: '12px',
  },
  titleContainer: {
    width: 'calc(100% - 150px)',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titeCompressedView: {
    marginTop: '-6px',
    marginBottom: '2px !important',
  },
  timeContainer: {
    display: 'flex',
  },
  time: {
    fontWeight: theme.old.typography.fontWeight.regular,
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
    marginBottom: '0.5em',
  },
}));

interface ForwardMessageViewProps {
  message: Message;
}

export const ForwardMessageView: React.FC<ForwardMessageViewProps> = (
  props
) => {
  const { message } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const mailSettings = useSelector(getMailSettings);

  return (
    <Flex.Column
      className={classNames(classes.root, {
        [classes.rootCompressedView]: mailSettings.mailListSpacing === 'tight',
      })}
      childrenGap={theme.old.spacing.unit(1)}
    >
      <Flex.Column>
        <Flex.Row className={classes.titleContainer} alignItems="baseline">
          <Typography.Title
            level={3}
            className={classNames(classes.title, {
              [classes.titeCompressedView]:
                mailSettings.mailListSpacing === 'tight',
            })}
          >
            {`${message?.isDraft ? `${t('common:draft')}: ` : ''}${
              message?.subject ?? ''
            }`}
          </Typography.Title>
        </Flex.Row>
        {(message?.receivedDateTime || message?.createdDateTime) && (
          <Flex.Item flex={1} className={classes.timeContainer}>
            <Typography.Text className={classes.time}>
              {longDateFormatString(
                message?.receivedDateTime ?? message?.createdDateTime,
                i18n.language
              )}
            </Typography.Text>
          </Flex.Item>
        )}
      </Flex.Column>
    </Flex.Column>
  );
};

export default ForwardMessageView;
