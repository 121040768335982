import * as ConfigValues from '../../util/configValues';
import { Configuration } from '@azure/msal-browser';
import { logoutWithAccount } from '../../store/authEffect';

export const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const fetchMsGraph: <T>(url: string, accessToken: string) => Promise<T> =
  async (url, accessToken) => {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.json();
  };

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  MAIL_READ: 'Mail.Read',
};

export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [`${ConfigValues.SCOPES}`],
  },
};

export const configuration: Configuration = {
  auth: {
    clientId: `${ConfigValues.CLIENT_ID}`,
    authority: `${ConfigValues.AUTHORITY}`,
    redirectUri: `${ConfigValues.REACT_APP_API_REDIRECT_URL}`,
    postLogoutRedirectUri: `/`,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const loginRequest = {
  scopes: [GRAPH_SCOPES.USER_READ],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const logout = () => {
  logoutWithAccount();
  Office.context.ui.messageParent(JSON.stringify({ status: 'success' }));
};
