import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import DocumentsMenu from './DocumentsMenu';
import DocumentsPage, { DocumentPageRef } from './DocumentsPage';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import { setCurrentRemoteItem } from '../actions/remoteFolders';
import DocumentsNavigationBar from './DocumentsNavigationBar';
import { DriveItem } from '../../../models/Drive';
import { ProjectId } from '../../../models/Types';
import { fetchDriveFavorites } from '../actions/driveFavorites';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../theme/types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    fontSize: theme.old.components.documentsTable.font.fontSize,
    lineHeight: theme.old.components.documentsTable.font.lineHeight,
  },
  menu: {
    maxWidth: theme.old.components.documentsTable.documentsMenu.width,
  },
  page: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    '& .ant-upload': {
      width: '100%',
      height: '100%',
    },
  },
  content: {
    overflow: 'hidden',
    height: '100%',
  },
  table: {
    overflow: 'auto hidden',
  },
  newDocument: {
    width: '100%',
  },
}));

const queryClient = new QueryClient();

interface DocumentsSubModuleProps {
  projectId: ProjectId;
}

export const DocumentsSubModule: React.FC<DocumentsSubModuleProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { projectId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const groupId = useSelector<RootReducerState, string>(
    (state) => getProject(state, projectId)?.groupId
  );
  const documentPageRef = useRef<DocumentPageRef>();
  const selectedDriveItemsRef = useRef<DriveItem[]>([]);
  const [selectedDriveItems, setSelectedDriveItems] = useState<DriveItem[]>([]);
  selectedDriveItemsRef.current = selectedDriveItems;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(fetchDriveFavorites(projectId));
    return () => {
      dispatch(setCurrentRemoteItem(null));
    };
  }, [dispatch, projectId]);
  //#endregion

  return (
    <QueryClientProvider client={queryClient}>
      <Flex.Column
        className={classes.root}
        id={'prio-project-documents-submodule'}
      >
        <DocumentsNavigationBar
          selectedDriveItems={selectedDriveItems}
          setSelectedDriveItems={setSelectedDriveItems}
          projectId={projectId}
          groupId={groupId}
          refetchDocuments={() => documentPageRef.current?.refetchDocuments()}
        />
        <Flex.Row flex={1} className={classes.content}>
          <Routes>
            <Route path="*" element={<Navigate to="all" />} />
            <Route path="/:selectedList/*">
              <Route
                path="*"
                element={
                  <DocumentsMenu
                    className={classes.menu}
                    projectId={projectId}
                    pathPrefix="../../"
                  />
                }
              />
              <Route
                path=":driveItemId"
                element={
                  <DocumentsMenu
                    className={classes.menu}
                    projectId={projectId}
                    pathPrefix="../../"
                  />
                }
              />
            </Route>
          </Routes>
          <Flex.Item flex={1} className={classes.table}>
            <Routes>
              <Route
                path="all"
                element={
                  <DocumentsPage
                    className={classes.page}
                    projectId={projectId}
                    groupId={groupId}
                    pathPrefix="../"
                    selectedDriveItems={selectedDriveItems}
                    setSelectedDriveItems={setSelectedDriveItems}
                    ref={documentPageRef}
                  />
                }
              />
              <Route
                path="folder/:driveItemId"
                element={
                  <DocumentsPage
                    className={classes.page}
                    projectId={projectId}
                    groupId={groupId}
                    pathPrefix="../"
                    selectedDriveItems={selectedDriveItems}
                    setSelectedDriveItems={setSelectedDriveItems}
                    ref={documentPageRef}
                  />
                }
              />
              <Route
                element={
                  <div className="prio-flex-center-center prio-flex-column">
                    <Typography.Text>
                      <Trans i18nKey={'documents:noMenuItemSelected'} />
                    </Typography.Text>
                  </div>
                }
              />
            </Routes>
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>
    </QueryClientProvider>
  );
};

export default DocumentsSubModule;
