import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Typography, Tabs, Divider, Input, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { InternalContact, ExternalContact } from '../../../models/Contact';
import { initials, fullContactTitle, isTemporaryId } from '../../../util';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { getInitialsColorHexFromName } from '../../../util/initialColors';
import CopyableTextTile, {
  copyToClipboard,
} from '../../../components/CopyableTextTile';
import { archiveContact, unarchiveContact, updateContact } from '../actions';
import { openContactsDrawer, setContactsDrawerState } from '../actions/drawer';
import { getCompany, RootReducerState } from '../../../apps/main/rootReducer';
import { Company } from '../../../models/Company';
import useAccessRights from '../../users/hooks/useAccessRights';
import CountryText from '../../../components/CountryText';
import { ContactId } from '../../../models/Types';
import { apiCreateMessageDraft, apiUpdateMessageDraft } from '../../mail/api';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { ProjectMembershipTable } from './ProjectMembershipTable';
import PrioSpinner from '../../../components/PrioSpinner';
import { useProjectMembership } from '../hooks/useProjectMembership';
import ContactTags from './ContactTags';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
  },
  content: {
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  contactLeftBox: {
    borderRight: theme.old.borders.content,
  },
  heading: {
    fontSize: 'unset',
  },
  label: {
    fontSize: theme.old.typography.fontSize.label,
  },
  notesInput: {
    marginTop: theme.old.spacing.unit(1),
  },
  tabs: {
    flex: 1,
    '& .ant-tabs-content': {
      height: '100%',
      width: '100%',
    },
  },
  projectMemberTab: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignContent: 'center',
  },
}));

interface ContactDetailsProps {
  className?: string;
  contentClassName?: string;
  contact: InternalContact & ExternalContact;
  noAvatar?: boolean;
  isSubDrawer?: boolean;
  onEdit?: (contactId: ContactId) => void;
}

export const ContactDetails: React.FC<ContactDetailsProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    contentClassName,
    contact,
    noAvatar,
    isSubDrawer,
    onEdit,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const { projectMembership, isFetchingProjectMember } = useProjectMembership(
    contact.contactId
  );

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isArchived, setIsArchived] = useState<boolean>(
    contact?.isArchived ?? false
  );

  const company = useSelector<RootReducerState, Company>((state) =>
    contact.companyId ? getCompany(state, contact.companyId) : null
  );

  const office = company?.offices.find(
    (_office) => _office.officeId === contact.officeId
  );

  const [showNotesInput, setShowNotesInput] = useState<boolean>(false);
  const [autoFocusNotes, setAutoFocusNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>(null);
  const [clicked, setClicked] = useState<Boolean>(false);

  const fullContactName = fullContactTitle(contact);

  const computedInitials = initials(contact.firstName, contact.lastName);

  const avatarColor = getInitialsColorHexFromName(fullContactName);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const {
    editExternalContact: canEditExternalContact,
    editInternalContact: canEditInternalContact,
  } = useAccessRights(['editExternalContact', 'editInternalContact']);

  const isEditContactEnabled = () => {
    if (isTemporaryId(contact.contactId)) return false;
    if (contact.contactType === 'InternalContact') {
      return canEditInternalContact;
    }
    return canEditExternalContact;
  };

  const handleAddNotesClick = () => {
    setAutoFocusNotes(true);
    setShowNotesInput(true);
  };

  const onNotesBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const updatedNotes = e.target.value.trim();
    if ((contact.notes ?? '') !== updatedNotes) {
      dispatch(
        updateContact(
          { notes: updatedNotes },
          contact.contactId,
          contact.rowVersion,
          contact
        )
      );
    }
    if (updatedNotes === '' && showNotesInput) {
      setShowNotesInput(false);
      setAutoFocusNotes(false);
    }
  };

  const editContact = () => {
    if (isSubDrawer && onEdit) {
      onEdit(contact.contactId);
    } else {
      dispatch(
        openContactsDrawer({
          selectedContact: contact.contactId,
          view: 'contactEdit',
        })
      );
    }
  };

  const onArchiveContact = () => {
    setIsArchived(true);
    dispatch(archiveContact(contact.contactId));
    dispatch(
      setContactsDrawerState({ changedContact: { contact, state: 'archived' } })
    );
  };

  const onUnarchiveContact = () => {
    setIsArchived(false);
    dispatch(unarchiveContact(contact.contactId));
    dispatch(
      setContactsDrawerState({
        changedContact: { contact, state: 'unarchived' },
      })
    );
  };

  const copyCodeToClipboard = () => {
    copyToClipboard(
      `${contact.firstName} ${contact.lastName} ${
        contact.eMail ? `\nEmail: ${contact.eMail}` : ''
      }${contact.phone ? `\nTel: ${contact.phone}` : ''}${
        contact.phone2 ? `\nTel 2: ${contact.phone2}` : ''
      }${contact.cellphone ? `\nMobil: ${contact.cellphone}` : ''}${
        office?.street ? `\n${office?.street}` : ''
      }${office?.zip ? `\n${office?.zip} ` : ''}${
        office?.city ? `${office?.city}` : ''
      }`
    );
    setClicked(true);
    setTimeout(() => setClicked(false), 750);
  };

  const handleOpenEMailMeFromContacts = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    doShowNewEMailMeMessageFromContact();
  };

  const doShowNewEMailMeMessageFromContact = async () => {
    setIsCreating(true);
    const { data: message } = await apiCreateMessageDraft('me');
    if (message) {
      message.toRecipients = [
        {
          emailAddress: {
            name: '',
            address: contact.eMail ?? contact.eMail2,
          },
        },
      ];
      await apiUpdateMessageDraft('me', message.id, message);
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight * 0.6;
      window.open(
        `/view/me/composer/${message.id}`,
        '_blank',
        `width=${width},height=${height},top=${height / 4}`
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }

    setIsCreating(false);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setNotes(contact.notes);
    setShowNotesInput(!!contact.notes);
    setIsArchived(contact?.isArchived ?? false);
  }, [contact]);
  //#endregion

  return (
    <div
      className={classNames(classes.root, className)}
      id="prio-contact-details"
    >
      <Flex.Column
        className={classNames(classes.content, contentClassName)}
        childrenGap={theme.old.spacing.unit(3)}
        padding={`${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(
          3
        )}px`}
      >
        <Flex.Row>
          {!noAvatar && (
            <Avatar
              size={96}
              style={{
                backgroundColor: avatarColor,
                fontSize: 32,
                fontWeight: theme.old.typography.fontWeight.bold,
                minWidth: 96,
              }}
            >
              {computedInitials}
            </Avatar>
          )}
          <Flex.Column
            padding={
              noAvatar
                ? `0 ${theme.old.spacing.unit(2)}px 0 0`
                : `0 ${theme.old.spacing.unit(2)}px`
            }
            flex={1}
            justify-content="center"
          >
            <Typography.Title level={2} style={{ paddingLeft: '8px' }}>
              {fullContactName}
              {contact.contactClassificationName
                ? ` | ${contact.contactClassificationName}`
                : null}
            </Typography.Title>
            <Flex.Row childrenGap={theme.old.spacing.unit(1)}>
              <ContactTags
                maxCount={5}
                contactId={contact.contactId}
                contactTagDtos={contact.contactTagDtos}
              ></ContactTags>
            </Flex.Row>
            <Flex.Row childrenGap={theme.old.spacing.unit(1)} flexWrap="wrap">
              <Button
                type="link"
                iconProp={['fal', 'envelope']}
                onClick={handleOpenEMailMeFromContacts}
                loading={isCreating}
                disabled={isCreating}
              >
                {t('contacts:contactDetails.actions.email')}
              </Button>
              {
                <Flex.Row childrenGap={theme.old.spacing.unit(1)}>
                  <Button
                    type="link"
                    iconProp={['fal', 'phone']}
                    href={
                      contact.contactType === 'InternalContact'
                        ? `msteams:/l/call/0/0?users=${contact.eMail}`
                        : contact.phone?.replace('+', '00') ||
                          contact.phone2?.replace('+', '00') ||
                          contact.cellphone?.replace('+', '00') ||
                          contact.cellphone2?.replace('+', '00')
                        ? `msteams:/l/call/0/0?users=4:${
                            contact.phone?.replace('+', '00') ??
                            contact.phone2?.replace('+', '00') ??
                            contact.cellphone?.replace('+', '00') ??
                            contact.cellphone2?.replace('+', '00')
                          }`
                        : undefined
                    }
                  >
                    {t('contacts:contactDetails.actions.call')}
                  </Button>
                  {contact?.contactType === 'InternalContact' && (
                    <Button
                      type="link"
                      iconProp={['fal', 'video']}
                      href={`msteams:/l/call/0/0?users=${contact.eMail}&withVideo=true`}
                    >
                      {t('contacts:contactDetails.actions.videoCall')}
                    </Button>
                  )}
                </Flex.Row>
                /*
              <Button
                type="link"
                icon={<FontAwesomeIcon icon={['fal', 'file-excel']} />}
              >
                {t('contacts:contactDetails.actions.excel')}
              </Button>
              */
              }
              <Button
                type="link"
                iconProp={['fal', clicked ? 'check' : 'copy']}
                onClick={copyCodeToClipboard}
              >
                {t('contacts:contactDetails.actions.copy')}
              </Button>
            </Flex.Row>
          </Flex.Column>
        </Flex.Row>
        <Tabs className={classes.tabs}>
          <Tabs.TabPane
            tab={t('contacts:contactDetails.pivot.contact')}
            key="contact"
            style={{
              overflowY: 'scroll',
            }}
          >
            <Flex.Row flex={1}>
              <Flex.Column
                width={'50%'}
                childrenGap={theme.old.spacing.unit(1)}
                className={classes.contactLeftBox}
              >
                {company && (
                  <Flex.Column>
                    <Typography.Text
                      type="secondary"
                      className={classNames(classes.label, classes.heading)}
                    >
                      <Trans i18nKey="contacts:contactDetails.labels.company">
                        Firma
                      </Trans>
                    </Typography.Text>
                    <Typography.Text>{company.fullName}</Typography.Text>
                  </Flex.Column>
                )}
                {(office?.street || office?.zip) && (
                  <Flex.Column>
                    <Typography.Text type="secondary" className={classes.label}>
                      <Trans i18nKey="companies:companyDetails.labels.address">
                        Adresse
                      </Trans>
                    </Typography.Text>
                    <Typography.Text>
                      {office?.street && (
                        <>
                          {office?.street}
                          <br />
                        </>
                      )}
                      {(office?.zip || office?.city) && (
                        <>
                          {office?.zip} {office?.city}
                          <br />
                        </>
                      )}
                      <CountryText
                        countryCode={office?.countryCode}
                        federalStateCode={office.federalStateCode}
                      />
                    </Typography.Text>
                  </Flex.Column>
                )}
                {(contact.department || contact.position) && (
                  <Flex.Row>
                    {contact.department && (
                      <Flex.Column flex={1}>
                        <Typography.Text
                          type="secondary"
                          className={classes.label}
                        >
                          <Trans i18nKey="contacts:contactDetails.labels.department">
                            Abteilung
                          </Trans>
                        </Typography.Text>
                        <Typography.Text>{contact.department}</Typography.Text>
                      </Flex.Column>
                    )}
                    {contact.position && (
                      <Flex.Column flex={1}>
                        <Typography.Text
                          type="secondary"
                          className={classes.label}
                        >
                          <Trans i18nKey="contacts:contactDetails.labels.position">
                            Position
                          </Trans>
                        </Typography.Text>
                        <Typography.Text>{contact.position}</Typography.Text>
                      </Flex.Column>
                    )}
                  </Flex.Row>
                )}
                {(contact?.street || contact?.zip) && (company || office) && (
                  <div style={{ paddingRight: 8 }}>
                    <Divider style={{ margin: 0 }} />
                  </div>
                )}
                {(contact?.street || contact?.zip) && (
                  <>
                    <Typography.Text
                      type="secondary"
                      className={classNames(classes.heading, classes.label)}
                    >
                      <Trans i18nKey="contacts:contactDetails.labels.privat">
                        Privat
                      </Trans>
                    </Typography.Text>
                    <Flex.Column>
                      <Typography.Text
                        type="secondary"
                        className={classes.label}
                      >
                        <Trans i18nKey="companies:companyDetails.labels.address">
                          Adresse
                        </Trans>
                      </Typography.Text>
                      <Typography.Text>
                        {contact?.street && (
                          <>
                            {contact?.street}
                            <br />
                          </>
                        )}
                        {(contact?.zip || contact?.city) && (
                          <>
                            {contact?.zip} {contact?.city}
                            <br />
                          </>
                        )}
                      </Typography.Text>
                    </Flex.Column>
                  </>
                )}
                {(contact?.street || contact?.zip) && contact?.website && (
                  <div style={{ paddingRight: 8 }}>
                    <Divider style={{ margin: 0 }} />
                  </div>
                )}
                {contact?.website && (
                  <>
                    <Typography.Text
                      type="secondary"
                      className={classNames(classes.heading, classes.label)}
                    >
                      <Trans i18nKey="contacts:contactDetails.labels.other">
                        Sonstiges
                      </Trans>
                    </Typography.Text>
                    <Flex.Column flex={1}>
                      <Typography.Text
                        type="secondary"
                        className={classes.label}
                      >
                        <Trans i18nKey="contacts:contactDetails.labels.website">
                          Website
                        </Trans>
                      </Typography.Text>
                      <Typography.Text>{contact.website}</Typography.Text>
                    </Flex.Column>
                  </>
                )}
              </Flex.Column>
              <Flex.Column
                width={'50%'}
                padding={`0 0 0 ${theme.old.spacing.unit(4)}px`}
                childrenGap={theme.old.spacing.unit(2)}
              >
                {contact.eMail && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.email')}
                    value={contact.eMail}
                  />
                )}
                {contact.eMail2 && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.email2')}
                    value={contact.eMail2}
                  />
                )}
                {contact.eMail3 && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.email3')}
                    value={contact.eMail3}
                  />
                )}
                {contact.phone && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.phone')}
                    value={contact.phone}
                    type={'phone'}
                    data={contact.phone}
                  />
                )}
                {contact.phone2 && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.phone2')}
                    value={contact.phone2}
                    type={'phone'}
                    data={contact.phone2}
                  />
                )}
                {contact.phone3 && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.phone3')}
                    value={contact.phone3}
                    type={'phone'}
                    data={contact.phone3}
                  />
                )}
                {contact.cellphone && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.cellphone')}
                    value={contact.cellphone}
                    type={'phone'}
                    data={contact.cellphone}
                  />
                )}
                {contact.cellphone2 && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.cellphone2')}
                    value={contact.cellphone2}
                    type={'phone'}
                    data={contact.cellphone2}
                  />
                )}
                {contact.cellphone3 && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.cellphone3')}
                    value={contact.cellphone3}
                    type={'phone'}
                    data={contact.cellphone3}
                  />
                )}
                {contact.fax && (
                  <CopyableTextTile
                    label={t('contacts:contactDetails.labels.fax')}
                    value={contact.fax}
                  />
                )}
              </Flex.Column>
            </Flex.Row>
            <Divider />
            {contact.contactType === 'ExternalContact' ? (
              <Flex.Column alignItems="start">
                <Typography.Text type="secondary" className={classes.label}>
                  <Trans i18nKey="companies:companyDetails.labels.notes">
                    Notizen
                  </Trans>
                </Typography.Text>
                {showNotesInput ? (
                  <Input.TextArea
                    value={notes}
                    onBlur={onNotesBlur}
                    onChange={(e) => setNotes(e.target.value)}
                    autoFocus={autoFocusNotes}
                    className={classes.notesInput}
                  />
                ) : (
                  <Button
                    type="link"
                    onClick={handleAddNotesClick}
                    iconProp={['fal', 'pen']}
                  >
                    <span>
                      {t('companies:companyDetails.actions.addNotesButton')}
                    </span>
                  </Button>
                )}
              </Flex.Column>
            ) : null}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <div className={classes.projectMemberTab}>
                <Typography.Text>{t('projects:title')}</Typography.Text>
                <Typography.Text>
                  {isFetchingProjectMember ? (
                    <PrioSpinner size="small" />
                  ) : projectMembership?.projectInfos?.length ? (
                    `(${projectMembership?.projectInfos?.length})`
                  ) : (
                    '(0)'
                  )}
                </Typography.Text>
              </div>

              // t('projects:title') +
              // ' ' +
              // `${
              //   projectMembership?.projectInfos?.length ? (
              //     `(${projectMembership?.projectInfos?.length})`
              //   ) : (
              //     <PrioSpinner alignSelf />
              //   )
              // }`
            }
            key="projects"
          >
            <ProjectMembershipTable
              isFetching={isFetchingProjectMember}
              projectMembership={projectMembership}
            />
          </Tabs.TabPane>
        </Tabs>
        {contact.contactType !== 'InternalContact' && (
          <Flex.Item alignSelf="flex-end">
            <Button
              onClick={isArchived ? onUnarchiveContact : onArchiveContact}
              disabled={isTemporaryId(contact.contactId)}
              iconProp={['fal', isArchived ? 'trash-restore' : 'trash']}
              type="link"
            >
              <span>
                {isArchived
                  ? t('contacts:contactDetails.actions.unarchive')
                  : t('contacts:contactDetails.actions.archive')}
              </span>
            </Button>
            <Button
              type="link"
              iconProp={['fal', 'user-edit']}
              onClick={editContact}
              disabled={!isEditContactEnabled()}
            >
              {t('common:edit')}
            </Button>
          </Flex.Item>
        )}
      </Flex.Column>
    </div>
  );
};

export default ContactDetails;
