import { PRIO } from '../../../constants';
import { ZendeskSearchResult } from '../../../models/Zendesk';
import { ZendeskDrawerState } from '../reducers/drawer';

export type ZendeskDrawerView = 'help' | 'report' | 'details';

export interface OpenZendeskDrawerAction {
    type: string;
    newState: ZendeskDrawerState;
}

export const OPEN_ZENDESK_DRAWER = PRIO + 'OPEN_ZENDESK_DRAWER';

export const openZendeskDrawer = (
    open: boolean
) => ({
    type: OPEN_ZENDESK_DRAWER,
    newState: { open },
});

export const UPDATE_ZENDESK_DRAWER_VIEW = PRIO + 'UPDATE_ZENDESK_DRAWER_VIEW';

export const updateZendeskDrawerView = (
    view: ZendeskDrawerView
) => ({
    type: UPDATE_ZENDESK_DRAWER_VIEW,
    newState: { view },
});

export const UPDATE_ZENDESK_SEARCH_RESULT = PRIO + 'UPDATE_ZENDESK_SEARCH_RESULT';

export const updateZendeskSearchResult = (
    searchResult: ZendeskSearchResult
) => ({
    type: UPDATE_ZENDESK_SEARCH_RESULT,
    newState: { activeSearchResult: searchResult },
});

export const UPDATE_ZENDESK_SEARCH_TERM = PRIO + 'UPDATE_ZENDESK_SEARCH_TERM';

export const updateZendeskSearchTerm = (
    searchTerm: string
) => ({
    type: UPDATE_ZENDESK_SEARCH_TERM,
    newState: { searchTerm: searchTerm },
});