import React, { useMemo, useState } from 'react';
import { notification, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { TaskForm } from './TaskForm';
import { apiCreateTask } from '../api';
import { CreateTaskRequest, Task } from '../../../models/Task';
import { makePrioStyles } from '../../../theme/utils';
import { ProjectId } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    padding: theme.old.spacing.defaultPadding,
  },
  form: {},
}));

interface TasksWidgetProps {
  projectId?: ProjectId;
}

export const TasksWidget: React.FC<TasksWidgetProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const initialValues = useMemo(() => {
    return projectId ? { projectId, title: '' } : undefined;
  }, [projectId]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish = async (value: CreateTaskRequest | Task) => {
    setIsSaving(true);
    const { result } = await apiCreateTask(value.projectId, value);
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('tasks:form.errorMessages.createTask'),
      });
      return false;
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classes.root}>
      <Typography.Title level={3}>
        {t('tasks:widgetArea.title')}
      </Typography.Title>
      <TaskForm
        actionLabel={t('tasks:form.actionLabel')}
        cancelLabel={t('common:actions.cancel')}
        className={classes.form}
        onFinish={handleFinish}
        disableForm={isSaving}
        initialValues={initialValues}
        resetOnFinish
      />
    </div>
  );
};

export default TasksWidget;
