import React, { useEffect, useState } from 'react';
import { Checkbox, Card, Typography, List, Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ContactId } from '../../../models/Types';
import { ExternalContact } from '../../../models/Contact';
import { makePrioStyles } from '../../../theme/utils';
import useCollapse from 'react-collapsed';

const useStyles = makePrioStyles((theme) => ({
  root: {
    marginTop: theme.old.spacing.unit(3),
  },
  card: {
    '& .ant-card-head': {
      background: theme.old.palette.chromaticPalette.red,
    },

    '& .ant-card-body': {
      padding: '0px',
    },
  },
  cardTitle: {
    color: theme.old.typography.colors.inverted,
  },
  list: {
    minHeight: '77px',
    maxHeight: '311px',
    overflowY: 'auto',
  },
  listItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingTop: theme.old.spacing.unit(2),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
    },
  },
  warningBox: {
    marginTop: theme.old.spacing.unit(1),
  },
  warningList: {
    height: '20vh',
    overflowY: 'auto',
  },
  alert: {
    marginTop: theme.old.spacing.unit(1),
  },
  checkbox: {},
}));

interface DuplicateContactWarningListProps {
  duplicateContacts: ExternalContact[];
  onArchiveContact: (archivedContacts: ContactId[]) => void;
}

const DuplicateContactWarningList: React.FC<
  DuplicateContactWarningListProps
> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { t } = useTranslation();
  const { duplicateContacts, onArchiveContact } = props;
  //#endregion

  //#region -------------------------------- State declaration
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { getCollapseProps } = useCollapse({ isExpanded });

  const [selectedDuplicateContacts, setSelectedDuplicateContacts] = useState<
    ContactId[]
  >([]);
  //#endregion

  //#region -------------------------------- Methods
  const duplicateContactCheckboxChange = (
    selected: boolean,
    duplicateId: ContactId
  ) => {
    selected
      ? setSelectedDuplicateContacts([
          ...selectedDuplicateContacts,
          duplicateId,
        ])
      : setSelectedDuplicateContacts(
          selectedDuplicateContacts.filter((dId) => dId !== duplicateId)
        );
  };
  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    const handleCardOpenState = () => {
      if (duplicateContacts.length >= 1) {
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
      }
    };
    handleCardOpenState();
  }, [duplicateContacts]);

  useEffect(() => {
    onArchiveContact && onArchiveContact(selectedDuplicateContacts);
  }, [selectedDuplicateContacts, onArchiveContact]);
  //#endregion

  //#region -------------------------------- Components
  const checkbox = (duplicateContact: ExternalContact) => {
    return (
      <Tooltip title={t('contacts:newContact.duplicateAlert.action')}>
        <Checkbox
          className={classes.checkbox}
          checked={selectedDuplicateContacts.includes(
            duplicateContact.companyId
          )}
          onChange={(e) =>
            duplicateContactCheckboxChange(
              e.target.checked,
              duplicateContact.companyId
            )
          }
        />
      </Tooltip>
    );
  };

  const renderItem = (item: ExternalContact) => {
    return (
      <List.Item className={classes.listItem} actions={[checkbox(item)]}>
        <Skeleton title={false} loading={false} active>
          <List.Item.Meta
            title={
              <Typography.Text>
                {`${item.title ? item.title : ''} ${item.firstName} ${
                  item.lastName
                }`}
              </Typography.Text>
            }
            description={<Typography.Text>{item.eMail}</Typography.Text>}
          />
        </Skeleton>
      </List.Item>
    );
  };

  //#endregion

  return (
    <>
      <section {...getCollapseProps()} className={classes.root}>
        {isExpanded && (
          <div className={classes.warningBox}>
            <Card
              title={
                <Typography.Text className={classes.cardTitle}>
                  {t('contacts:newContact.duplicateAlert.message')}
                </Typography.Text>
              }
              className={classes.card}
            >
              <List
                className={classes.list}
                itemLayout="horizontal"
                dataSource={duplicateContacts}
                renderItem={renderItem}
              />
            </Card>
          </div>
        )}
      </section>
    </>
  );
};

export default DuplicateContactWarningList;
