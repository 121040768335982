import { ApiResult, apiUrl } from '../../../api';
import {
  Contact,
  ContactDuplicate,
  ContactTag,
  CreateExternalContactBundle,
  ExternalContact,
} from '../../../models/Contact';
import { InternalOffice } from '../../../models/Office';
import {
  ExternalProjectContact,
  ImportProjectMembers,
  InternalProjectContact,
} from '../../../models/ProjectContacts';

import {
  CompanyId,
  ContactId,
  ContactType,
  OfficeId,
} from '../../../models/Types';
import { ContactsCompaniesSearchResult } from '../../../models/search';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiCreateExternalContactBundle: (
  createRequest: CreateExternalContactBundle
) => Promise<ApiResult<ExternalContact>> = async (createRequest) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/Contact/ExternalContactBundle`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ ...createRequest }),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSearchContacts: (
  searchString: string,
  contactType?: ContactType
) => Promise<ApiResult<Contact[]>> = async (searchString, contactType) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/Contact/search/${searchString}${
      contactType ? `/${contactType}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchContact: (
  contactId: ContactId
) => Promise<ApiResult<Contact>> = async (contactId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/Contact/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchInternalOffice: (
  officeId: OfficeId
) => Promise<ApiResult<InternalOffice>> = async (officeId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/InternalOffice/${officeId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSyncContactBook: () => Promise<ApiResult<any>> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/prioHealth/contactsync/me`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return {
    result,
  };
};

export const apiFetchCompanyContacts: (
  companyId: CompanyId
) => Promise<ApiResult<Contact[]>> = async (companyId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/Company/${companyId}/contact`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCheckContactDuplicate: (
  values: ContactDuplicate
) => Promise<ApiResult<Contact[]>> = async (values) => {
  const keysArr = Object.keys(values);
  const valsArr = Object.values(values);
  const validationString = keysArr
    .map((key, idx) => `${key}=${valsArr[idx]}`)
    .join('&');
  const result = await fetchWithRetry(
    `${apiUrl}/contact/Contact/validation?${validationString}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiSearchContactsByProps: (
  searchString: string,
  forceThreshold: number,
  forceResult: boolean,
  signal?: AbortSignal
) => Promise<ApiResult<ContactsCompaniesSearchResult>> = async (
  searchString,
  forceThreshold,
  forceResult,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/search/Search?searchType=companyContacts&searchString=${searchString}&forceThreshold=${forceThreshold}&forceResult=${forceResult}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
      signal,
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiFetchContactTags: () => Promise<
  ApiResult<ContactTag[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/contact/contactTag`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateContactTag: (
  contactTagName: string
) => Promise<ApiResult<ContactTag>> = async (contactTagName) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/ContactTag?newTagName=${contactTagName}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiImportProjectMember: (
  importMembers: ImportProjectMembers,
  projectId: string
) => Promise<
  ApiResult<InternalProjectContact | ExternalProjectContact>
> = async (importProjectMembers, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectMember/${projectId}/import`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ ...importProjectMembers }),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
