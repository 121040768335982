import { put, takeEvery } from 'redux-saga/effects';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import { LOGGED_OUT } from '../modules/auth/actions';

function* logOutRoutine() {
  yield put({ type: RESET_STATE });
}

export default function* watchLoggedOut() {
  yield takeEvery(LOGGED_OUT, logOutRoutine);
}
