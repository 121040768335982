import { call, put, select } from 'redux-saga/effects';
import { getSpecialMailFolders } from '../../../apps/main/rootReducer';
import { MailFolderId, MessageId, ProjectId } from '../../../models/Types';
import {
  deleteLocalMessage,
  wsRevertDeleteMessage,
  wsRevertMessageMove,
} from '../actions/actionControllers/messageActionController';
import { SpecialMailFolders } from '../actions/types';
import { apiFetchMessage } from '../api';

interface MoveMessageToast {
  userId: string;
  destinationId: MailFolderId;
  messageId: MessageId;
}

interface DeleteMessageToast {
  userId: string;
  messageId: MessageId;
}

export function* handleToastMessageMessageMove(
  toastMessage: MoveMessageToast,
  projectId?: ProjectId
) {
  try {
    const { destinationId, messageId } = toastMessage;

    const specialMailFolders: SpecialMailFolders = yield select((state) =>
      getSpecialMailFolders(state, projectId)
    );

    const { data: message } = yield call(apiFetchMessage, projectId, messageId);
    if (message) {
      yield put(
        wsRevertMessageMove(
          projectId,
          destinationId,
          messageId,
          message,
          specialMailFolders?.inboxFolder?.id
        )
      );
    } else {
      yield put(
        deleteLocalMessage(
          projectId,
          specialMailFolders?.inboxFolder?.id === destinationId
            ? 'inbox'
            : destinationId,
          messageId
        )
      );
    }
  } catch (error) {
    console.error(
      'Error in handleToastMessageMessageMove',
      error,
      toastMessage
    );
  }
}

export function* handleToastMessageMessageDelete(
  toastMessage: DeleteMessageToast,
  projectId?: ProjectId
) {
  try {
    const { messageId } = toastMessage;

    const specialMailFolders: SpecialMailFolders = yield select((state) =>
      getSpecialMailFolders(state, projectId)
    );

    const { data: message } = yield call(apiFetchMessage, projectId, messageId);

    if (message) {
      yield put(
        wsRevertDeleteMessage(
          projectId,
          specialMailFolders?.inboxFolder?.id === message.parentFolderId
            ? 'inbox'
            : message.parentFolderId,
          messageId,
          message
        )
      );
    }
  } catch (error) {
    console.error(
      'Error in handleToastMessageMessageDelete',
      error,
      toastMessage
    );
  }
}
