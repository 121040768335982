import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Tabs, Typography } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import {
  MonthlyClose,
  MonthlyCloseCalculatedData,
  MonthlyCloseSearchResultItem,
} from '../../../../models/TimeKeeping';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HRMonthlyCloseDetailsDrawer from './HRMonthlyCloseDetailsDrawer';
import MonthlyCloseTimekeepingDaysTable from '../../../timeKeeping/components/MonthlyCloseTimekeepingDaysTable';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { debouncedFetchOfficeHolidaysMe } from '../../../absences/actions';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& .ant-drawer-body': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-tabs-top': {
      height: '100%',
    },
  },
}));

interface HRMonthlyCloseDrawerProps {
  searchItem?: MonthlyCloseSearchResultItem;
  setSelectedSearchItem: (value: MonthlyCloseSearchResultItem) => void;
  onFinishDetailsTab?: () => void;
}

export const HRMonthlyCloseDrawer: React.FC<HRMonthlyCloseDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { searchItem, onFinishDetailsTab, setSelectedSearchItem } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { optimisticWrite } = useFilterContext<
    MonthlyClose,
    MonthlyCloseCalculatedData
  >();

  const { getContactById } = useContactsContext();

  const contact = getContactById(searchItem?.data?.employeeId);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnFinish = async (monthlyClose: MonthlyClose) => {
    optimisticWrite([
      {
        data: monthlyClose,
        calculated: searchItem?.calculated,
        method: 'update',
      },
    ]);
    if (onFinishDetailsTab) {
      onFinishDetailsTab();
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (contact?.officeId && searchItem) {
      const start = moment(searchItem?.data.month)
        .subtract(1, 'month')
        .startOf('month')
        .toISOString(true)
        .split('T')[0];
      const end = moment(searchItem?.data.month)
        .add(1, 'month')
        .endOf('month')
        .toISOString(true)
        .split('T')[0];
      dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
    }
  }, [contact, searchItem, dispatch]);
  //#endregion

  return (
    <Drawer
      width={theme.old.components.drawerWidth}
      closable={true}
      onClose={() => setSelectedSearchItem(null)}
      visible={!!searchItem}
      destroyOnClose={true}
      className={classes.root}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
    >
      <Flex.Column childrenGap={theme.old.spacing.unit(2)}>
        <Typography.Title level={2} ellipsis>
          {`${moment(searchItem?.data?.month).format('MMMM YYYY')}${
            contact
              ? `, ${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`
              : ''
          }`}
        </Typography.Title>
      </Flex.Column>
      <Tabs>
        <TabPane
          tab={t(
            'hr:timeAndLeaveManagement.monthlyCloseDrawer.detailsTab.tabName'
          )}
          key="details"
        >
          <HRMonthlyCloseDetailsDrawer
            setOpen={() => setSelectedSearchItem(null)}
            monthlyClose={searchItem?.data}
            setSelectedMonthlyClose={() => setSelectedSearchItem(null)}
            officeId={contact?.officeId}
            onFinish={handleOnFinish}
          />
        </TabPane>
        {/* <TabPane tab={'Überblick'} key="timeKeepingDaysOverview"></TabPane> */}
        <TabPane
          tab={t(
            'hr:timeAndLeaveManagement.monthlyCloseDrawer.timekeepingDaysTable.tabName'
          )}
          key="timeKeepingDaysTable"
        >
          <MonthlyCloseTimekeepingDaysTable monthlyClose={searchItem?.data} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default HRMonthlyCloseDrawer;
