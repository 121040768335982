import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ZendeskSearchResult } from '../../../models/Zendesk';
import {
    OpenZendeskDrawerAction,
    OPEN_ZENDESK_DRAWER,
    UPDATE_ZENDESK_DRAWER_VIEW,
    UPDATE_ZENDESK_SEARCH_RESULT,
    UPDATE_ZENDESK_SEARCH_TERM,
    ZendeskDrawerView,
} from '../actions/drawer';

export interface ZendeskDrawerState {
    open?: boolean;
    view?: ZendeskDrawerView;
    activeSearchResult?: ZendeskSearchResult;
    searchTerm?: string;
}

const initialState: ZendeskDrawerState = {
    open: false,
    view: 'help',
    activeSearchResult: null,
    searchTerm: ''
}

const reducer: Reducer<ZendeskDrawerState, OpenZendeskDrawerAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case OPEN_ZENDESK_DRAWER: {
            const { newState } = action;
            return {
                ...state,
                open: newState.open,
            };
        }
        case UPDATE_ZENDESK_DRAWER_VIEW: {
            const { newState } = action;
            return {
                ...state,
                view: newState.view,
            };
        }
        case UPDATE_ZENDESK_SEARCH_RESULT: {
            const { newState } = action;
            return {
                ...state,
                activeSearchResult: newState.activeSearchResult,
            };
        }
        case UPDATE_ZENDESK_SEARCH_TERM: {
            const { newState } = action;
            return {
                ...state,
                searchTerm: newState.searchTerm,
            };
        }
        case CLEAR_PRIO_CACHE: {
            return initialState;
        }
        default:
            return state;
    }
};

export default reducer;

export const getOpen = (state: ZendeskDrawerState) => state.open;

export const getView = (state: ZendeskDrawerState) => state.view;

export const getSearchResult = (state: ZendeskDrawerState) => state.activeSearchResult;

export const getSearchTerm = (state: ZendeskDrawerState) => state.searchTerm;