import {
  actionChannel,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import { SAGA_REBUILD } from '../../../util/sagas';
import { isLoggedIn } from '../../../apps/main/rootReducer';
import { FETCH_MESSAGES_PROJECT_COMMIT } from '../actions/projects/messages';
import {
  FetchMessagesSagaAction,
  SAGA_FETCH_MESSAGES,
  fetchMessagesSagaAction,
} from '../actions/sagas';
import { ReduxAction } from '../../../models/Redux';
import { ProjectId } from '../../../models/Types';
import { MessageNextLinkPayload } from '../../../models/Message';
import { getFavoriteProjectsWithMail } from '../../projects/selectors';
import { Project } from '../../../models/Project';
// import { UPDATE_MAIL_LIST_NAVIGATION_PROJECTS } from '../actions/projects/mailNavigation';
// import { UPDATE_ACTIVE_MAILFOLDER_PROJECT } from '../actions/projects/mailFolders';
import { SET_ACTIVE_PROJECT } from '../../projects/actions';
// import {
//   WS_EMAIL_PROJECT_CREATED,
//   WS_EMAIL_PROJECT_DELETED,
//   WS_EMAIL_PROJECT_UPDATED,
// } from '../actions/ws';

const SAGA_WATCH_RESYNC_MESSAGES = 'SAGA_WATCH_RESYNC_MESSAGES';
export const triggerSagaWatchResyncMessages = (projectId: ProjectId) => ({
  type: SAGA_WATCH_RESYNC_MESSAGES,
  projectId,
  mailFolderId: 'inbox',
});

function* watchResync(action: FetchMessagesSagaAction) {
  try {
    const { projectId: initialProjectId } = action;
    let projectId: ProjectId = initialProjectId;
    if (projectId === 'favorites') {
      const projectFavorites: Project[] = yield select(
        getFavoriteProjectsWithMail
      );
      if (projectFavorites.length > 0) {
        projectId = projectFavorites[0].projectId;
      }
    }

    const commitActionChannel = yield actionChannel(
      (action: any) =>
        action.type === FETCH_MESSAGES_PROJECT_COMMIT &&
        action?.meta?.mailFolderId === 'inbox' &&
        action?.meta?.projectId !== 'me' &&
        action?.meta?.resync &&
        (action?.meta?.nextLink === undefined ||
          action?.meta?.nextLink === null)
    );

    // let delayInMS = 900000;

    const {
      commitAction,
    }: {
      sync: boolean;
      commitAction: ReduxAction<
        {
          projectId: ProjectId;
          nextLink: string;
        },
        MessageNextLinkPayload
      >;
    } = yield race({
      sync: delay(30000),
      commitAction: take(commitActionChannel),
      other: take([SET_ACTIVE_PROJECT]),
    });

    if (commitAction) {
      const {
        payload: { resyncInSeconds },
        meta: { projectId: commitedProjectId },
      } = commitAction;
      projectId = commitedProjectId;
      yield delay(resyncInSeconds * 1000);
      if (resyncInSeconds > 0 && projectId && projectId !== 'me') {
        yield put(fetchMessagesSagaAction(projectId, 'inbox', true));
      }
    }

    // while (true) {
    //   const {
    //     sync,
    //     commitAction,
    //     other,
    //   }: {
    //     sync: boolean;
    //     other: any;
    //     commitAction: ReduxAction<
    //       {
    //         projectId: ProjectId;
    //         nextLink: string;
    //       },
    //       MessageNextLinkPayload
    //     >;
    //   } = yield race({
    //     sync: delay(delayInMS),
    //     commitAction: take(commitActionChannel),
    //     other: take([
    //       SAGA_FETCH_MESSAGES,
    //       UPDATE_MAIL_LIST_NAVIGATION_PROJECTS,
    //       UPDATE_ACTIVE_MAILFOLDER_PROJECT,
    //       SET_ACTIVE_PROJECT,
    //       WS_EMAIL_PROJECT_CREATED,
    //       WS_EMAIL_PROJECT_DELETED,
    //       WS_EMAIL_PROJECT_UPDATED,
    //     ]),
    //   });
    //   const projectUrlMath = window.location.href.match(
    //     /\/module\/prio\/projects\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/mail/
    //   );

    //   const messageCentertUrlMath = window.location.href.match(
    //     /\/module\/prio\/projects\/me\/messageCenter\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/
    //   );

    //   const isMessageCenter = window.location.href.includes(
    //     'module/prio/projects/me/messageCenter/favorites/mail/inbox'
    //   );

    //   if (
    //     document.visibilityState === 'hidden' ||
    //     !document.hasFocus() ||
    //     !(projectUrlMath || isMessageCenter || messageCentertUrlMath)
    //   ) {
    //     break;
    //   }

    //   if (!other) {
    //     if (projectUrlMath && projectUrlMath[1]) {
    //       projectId = projectUrlMath[1];
    //     } else if (messageCentertUrlMath && messageCentertUrlMath[1]) {
    //       projectId = messageCentertUrlMath[1];
    //     }

    //     if (commitAction) {
    //       const {
    //         payload: { resyncInSeconds },
    //         meta: { projectId: commitedProjectId },
    //       } = commitAction;
    //       projectId = commitedProjectId;
    //       yield delay(resyncInSeconds * 1000);
    //       if (resyncInSeconds > 0 && projectId && projectId !== 'me') {
    //         yield put(fetchMessagesSagaAction(projectId, 'inbox', true));
    //       }
    //       delayInMS = 900000;
    //     }
    //     if (sync && projectId && projectId !== 'me') {
    //       if (isMessageCenter) {
    //         const projectFavorites: Project[] = yield select(
    //           getFavoriteProjectsWithMail
    //         );
    //         for (const project of projectFavorites) {
    //           const { projectId } = project;
    //           yield put(
    //             fetchMessagesSagaAction(projectId, 'inbox', true, false, false)
    //           );
    //         }
    //       } else {
    //         yield put(
    //           fetchMessagesSagaAction(projectId, 'inbox', true, false, false)
    //         );
    //       }
    //       delayInMS = 30000;
    //     }
    //   } else {
    //     delayInMS = 900000;
    //   }
    // }
  } catch (error) {
    console.error('Error in watchResyncMessages - watchResync', error);
    yield watchResync(action);
  }
}

function* actionPipeline() {
  try {
    while (true) {
      const initialAction = yield take(
        (action: any) =>
          // (action?.type === SAGA_FETCH_MESSAGES ||
          //   action?.type === SAGA_WATCH_RESYNC_MESSAGES) &&
          action?.type === SAGA_FETCH_MESSAGES &&
          action?.mailFolderId === 'inbox' &&
          action?.projectId !== 'me'
      );
      yield call(watchResync, initialAction);
    }
  } catch (error) {
    console.error('Error in watchResyncMessages - actionPipeline', error);
    yield actionPipeline();
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(actionPipeline), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in watchResyncMessages - mainTask', error);
    yield mainTask();
  }
}

export default function* watchResyncMessages() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
