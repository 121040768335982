import { put } from 'redux-saga/effects';
import { Contact } from '../../../models/Contact';
import { ContactCompanyChangedEvent } from '../../../models/ContactCompany';
import { WebSocketMessage } from '../../../models/WebSocket';
import { wsUpdateOrAddContact } from '../actions';

// eslint-disable-next-line
export function* handleContactMessage(message: WebSocketMessage) {}

export function* handleContactChangedMessage(
  contactCompanyChangedEvent: ContactCompanyChangedEvent
) {
  yield put(
    wsUpdateOrAddContact(contactCompanyChangedEvent.contactOrCompany as Contact)
  );
}
