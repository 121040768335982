import { CompanyClassification } from '../../../models/Settings';
import { Reducer } from 'redux';
import { FETCH_COMPANY_CLASSIFICATIONS_COMMIT } from '../actions/companyClassification';
import { CLEAR_PRIO_CACHE } from '../../../actions';

interface ContactClassificationAction {
  type: 'PRIO_FETCH_COMPANY_CLASSIFICATIONS_COMMIT';
  payload: CompanyClassification[];
}

const companyClassification: Reducer<
  CompanyClassification[],
  ContactClassificationAction
> = (state = [], action) => {
  switch (action.type) {
    case FETCH_COMPANY_CLASSIFICATIONS_COMMIT: {
      return action.payload;
    }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

export default companyClassification;

export const getCompanyClassifications: (
  state: CompanyClassification[]
) => CompanyClassification[] = (state) => state;
