import { Button, Drawer } from '@prio365/prio365-react-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import {
  CreateProjectExtension,
  ProjectExtension,
  UpdateProjectExtension,
} from '../../../../models/ProjectExtension';
import { ProjectExtensionId, ProjectId } from '../../../../models/Types';
import { PrioTheme } from '../../../../theme/types';
import {
  apiCreateProjectExtensionForProject,
  apiUpdateProjectExtensionForProject,
} from '../../api';
import LinkProjectExtensionForm from './ExtensionForms/Link/LinkProjectExtensionForm';
import ExtensionAccessList from './ExtensionAccessList';
import SharepointProjectExtensionForm from './ExtensionForms/Sharepoint/SharepointProjectExtensionForm';
import PlannerProjectExtensionForm from './ExtensionForms/Planner/PlannerProjectExtensionForm';
import JiraProjectExtensionForm from './ExtensionForms/jira/JiraProjectExtensionForm';
import SharedMailboxProjectExtensionForm from './ExtensionForms/SharedMailbox/SharedMailboxProjectExtensionForm';

interface ProjectExtensionDrawerProps {
  projectExtensionDrawerVisible: boolean;
  setProjectExtensionDrawerVisible: (boolean) => void;
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectExtensionDrawerTab: ProjectExtensionDrawerTabs;
  setProjectExtensionDrawerTab: (ProjectExtensionDrawerTabs) => void;
  projectId: ProjectId;
  projectExtensionCreated: (projectExtension: ProjectExtension) => void;
  projectExtensionUpdated: (projectExtension: ProjectExtension) => void;
  removeExtension?: (projectExtension: ProjectExtension) => void;
  reloadProjectExtension: () => void;
}

export type ProjectExtensionDrawerTabs = 'settings' | 'users';

export const ProjectExtensionDrawer: React.FC<ProjectExtensionDrawerProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    projectExtensionDrawerVisible,
    setProjectExtensionDrawerVisible,
    projectExtension,
    addProjectExtension,
    projectExtensionDrawerTab,
    setProjectExtensionDrawerTab,
    projectExtensionCreated,
    projectExtensionUpdated,
    projectId,
    reloadProjectExtension,
    removeExtension,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onClose = () => {
    setProjectExtensionDrawerVisible(false);
  };

  const deleteProjectExtension = () => {
    if (removeExtension) {
      removeExtension(projectExtension);
    }
  };

  const createProjectExtension = async (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ): Promise<ProjectExtension> => {
    // create new project extension with api
    const { data } = await apiCreateProjectExtensionForProject(projectId, [
      createProjectExtension,
    ]);
    if (data) {
      var createdExtension = data.filter(
        (e) => e.displayName === createProjectExtension.displayName
      )[0];
      projectExtensionCreated(createdExtension);
      return createdExtension;
    } else {
      return null;
    }
  };

  const updateProjectExtension = async (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    updateprojectExtension: UpdateProjectExtension
  ): Promise<ProjectExtension> => {
    // create new project extension with api
    const { data } = await apiUpdateProjectExtensionForProject(
      projectId,
      projectExtensionId,
      updateprojectExtension
    );
    if (data) {
      projectExtensionUpdated(data);
      return data;
    } else {
      return null;
    }
  };
  //#endregion

  //#region ------------------------------ Components
  let component: React.ReactNode;

  switch (
    projectExtension?.projectExtensionType ||
    addProjectExtension?.projectExtensionType
  ) {
    case 'sharedMailbox':
      component = (
        <SharedMailboxProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;
    case 'sharepoint':
      component = (
        <SharepointProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;

    case 'url':
      component = (
        <LinkProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;

    case 'planner':
      component = (
        <PlannerProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;

    case 'jira':
      component = (
        <JiraProjectExtensionForm
          projectExtension={projectExtension}
          addProjectExtension={addProjectExtension}
          projectId={projectId}
          createProjectExtension={createProjectExtension}
          updateProjectExtension={updateProjectExtension}
        />
      );
      break;
    default:
      component = <p>{t(`projects:addIn.notSupportedYet`)}</p>;
      break;
  }
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Drawer
      visible={projectExtensionDrawerVisible}
      onClose={onClose}
      defaultTab={projectExtensionDrawerTab}
      footer={
        <>
          {addProjectExtension == null ?? (
            <Button
              type="link"
              style={{ color: theme.colors.base.red.default }}
              onClick={() => {
                deleteProjectExtension();
              }}
            >
              {t('common:actions.delete')}
            </Button>
          )}
        </>
      }
      onTabChange={(key) =>
        setProjectExtensionDrawerTab(key as ProjectExtensionDrawerTabs)
      }
      title={
        t('projects:addIn.title') +
        ' - ' +
        t(
          'projects:addIn.' +
            (projectExtension?.projectExtensionType ??
              addProjectExtension?.projectExtensionType)
        )
      }
      tabs={[
        {
          label: t('projects:addIn.tab.projectExtension'),
          key: 'settings',
          content: component,
        },

        {
          label: t('projects:addIn.tab.projectExtensionAccess'),
          key: 'users',
          disabled: !projectExtension?.projectExtensionId,
          content: (
            <ExtensionAccessList
              projectId={projectId}
              projectExtension={projectExtension}
              reloadProjectExtension={reloadProjectExtension}
            />
          ),
        },
      ]}
    ></Drawer>
  );
};

export default ProjectExtensionDrawer;
