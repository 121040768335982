import React from 'react';
import CompanyCardSkeleton from './CompanyCardSkeleton';
import Flex from '../../../components/Flex';

export const CompanyListSkeleton: React.FC = () => {
  return (
    <Flex.Row>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <CompanyCardSkeleton key={index} />
        ))}
    </Flex.Row>
  );
};

export default CompanyListSkeleton;
