import { TitleSuggestion } from '../../../models/TitleSuggestion';
import { combineReducers, Reducer } from 'redux';
import {
  FetchTitleSuggestionsCommitAction,
  TitleSuggestionAction,
  FETCH_TITLE_SUGGESTIONS_COMMIT,
  UPDATE_TITLE_SUGGESTION_REQUEST,
  UPDATE_TITLE_SUGGESTION_COMMIT,
  UPDATE_TITLE_SUGGESTION_ROLLBACK,
  DELETE_TITLE_SUGGESTION_REQUEST,
  DELETE_TITLE_SUGGESTION_ROLLBACK,
  CREATE_TITLE_SUGGESTION_REQUEST,
  CREATE_TITLE_SUGGESTION_COMMIT,
  CREATE_TITLE_SUGGESTION_ROLLBACK,
  FETCH_TITLE_SUGGESTIONS_REQUEST,
  FETCH_TITLE_SUGGESTIONS_ROLLBACK,
} from '../actions/titleSuggestions';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface TitleSuggestionsState {
  byId: TitleSuggestionsByIdState;
  ids: string[];
  meta: TitleSuggestionsMeta;
}
export interface TitleSuggestionsByIdState {
  [titleSuggestionId: string]: TitleSuggestion;
}

const byId: Reducer<
  TitleSuggestionsByIdState,
  FetchTitleSuggestionsCommitAction & TitleSuggestionAction
> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TITLE_SUGGESTIONS_COMMIT: {
      const { payload } = action;
      return (payload as TitleSuggestion[]).reduce(function (map, item) {
        map[item.titleSuggestionId] = item;
        return map;
      }, {});
    }
    case CREATE_TITLE_SUGGESTION_REQUEST: {
      const {
        meta: { temporaryId },
        payload,
      } = action;
      return {
        ...state,
        [temporaryId]: {
          titleSuggestionId: temporaryId,
          rowVersion: null,
          ...payload,
        },
      };
    }
    case CREATE_TITLE_SUGGESTION_COMMIT: {
      const {
        payload,
        payload: { titleSuggestionId },
      } = action;
      if (state[titleSuggestionId]) return state;
      return {
        ...state,
        [titleSuggestionId]: payload,
      };
    }
    case UPDATE_TITLE_SUGGESTION_COMMIT:
    case UPDATE_TITLE_SUGGESTION_REQUEST: {
      const { payload } = action;
      const updatedTitleSuggestion = payload as TitleSuggestion;
      return {
        ...state,
        [updatedTitleSuggestion.titleSuggestionId]: updatedTitleSuggestion,
      };
    }
    case UPDATE_TITLE_SUGGESTION_ROLLBACK: {
      const { rollbackTitleSuggestion } = action;
      return {
        ...state,
        [rollbackTitleSuggestion.titleSuggestionId]: rollbackTitleSuggestion,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const ids: Reducer<
  Array<string>,
  FetchTitleSuggestionsCommitAction & TitleSuggestionAction
> = (state = [], action) => {
  switch (action.type) {
    case FETCH_TITLE_SUGGESTIONS_COMMIT: {
      const { payload } = action;
      return payload.map((item) => item.titleSuggestionId);
    }
    case CREATE_TITLE_SUGGESTION_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }
    case CREATE_TITLE_SUGGESTION_COMMIT: {
      const {
        meta: { temporaryId },
        payload: { titleSuggestionId },
      } = action;
      if (state.includes(titleSuggestionId)) return state;
      return [...state.filter((id) => id !== temporaryId), titleSuggestionId];
    }
    case CREATE_TITLE_SUGGESTION_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return state.filter((id) => id !== temporaryId);
    }
    case DELETE_TITLE_SUGGESTION_REQUEST: {
      const {
        meta: { titleSuggestionId },
      } = action;
      return state.filter((id) => id !== titleSuggestionId);
    }
    case DELETE_TITLE_SUGGESTION_ROLLBACK: {
      const {
        meta: { titleSuggestionId },
      } = action;
      if (state.includes(titleSuggestionId)) return state;
      return [...state, titleSuggestionId];
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

export interface TitleSuggestionsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<TitleSuggestionsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_TITLE_SUGGESTIONS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_TITLE_SUGGESTIONS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_TITLE_SUGGESTIONS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.fetchTitleSuggestionsError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<TitleSuggestionsState>({
  byId,
  ids,
  meta,
});

export const getTitleSuggestions: (
  state: TitleSuggestionsState
) => TitleSuggestion[] = (state) => state.ids.map((id) => state.byId[id]);

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;