import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: 125,
    height: theme.controlHeight.l ?? 40,
    backgroundColor: theme.colors.base.blue[20],
    borderRadius: theme.borderRadius.small,
  },
}));

interface FilterPickerSkeletonProps {
  className?: string;
}

const FilterPickerSkeleton: React.FC<FilterPickerSkeletonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return <div className={classNames(classes.root, className)} />;
};

export default FilterPickerSkeleton;
