import React, { useState, useCallback, useEffect } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import { KilometerRateSuggestion } from '../../../models/KilometerRate';
import Flex from '../../../components/Flex';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { useTranslation } from 'react-i18next';
import {
  apiSetKilometerRateSuggestion,
  apiFetchKilometerRateSuggestion,
} from '../api';
import KilometerRateSuggestionInput from './KilometerRateSuggestionInput';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  column: {},
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface KilometerRateSuggestionsPageProps {
  className?: string;
}

export const KilometerRateSuggestionsPage: React.FC<
  KilometerRateSuggestionsPageProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;
  const { t } = useTranslation();
  const [kilometerRateSuggestion, setKilometerRateSuggestion] =
    useState<KilometerRateSuggestion | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [updatedKilometerRateSuggestion, setUpdatedKilometerRateSuggestion] =
    useState<KilometerRateSuggestion>(null);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadSuggestion = async () => {
      const { data } = await apiFetchKilometerRateSuggestion(signal);
      if (data) setKilometerRateSuggestion(data);
    };
    loadSuggestion();
    return () => {
      controller.abort();
    };
  }, []);
  const onIsDirtyChangedKilometerRates = useCallback(
    (isDirty: boolean, kilometerRateSuggestion: KilometerRateSuggestion) => {
      if (isDirty) {
        setUpdatedKilometerRateSuggestion(kilometerRateSuggestion);
      } else {
        setUpdatedKilometerRateSuggestion(null);
      }
    },
    [setUpdatedKilometerRateSuggestion]
  );

  const onSave = async () => {
    setSaving(true);
    if (updatedKilometerRateSuggestion !== null) {
      const { data } = await apiSetKilometerRateSuggestion(
        updatedKilometerRateSuggestion
      );
      if (data) {
        setKilometerRateSuggestion(data);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('settings:errorMessages.setKilometerRateError'),
        });
      }

      setSaving(false);
    }
  };

  if (!kilometerRateSuggestion) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.column}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <KilometerRateSuggestionInput
          className={classes.shadow}
          kilometerRateSuggestion={kilometerRateSuggestion}
          onIsDirtyChanged={onIsDirtyChangedKilometerRates}
        />
        <Flex.Row justifyContent="flex-end">
          <Button
            disabled={!updatedKilometerRateSuggestion || saving}
            onClick={onSave}
          >
            {t('settings:kilometerRateTable.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default KilometerRateSuggestionsPage;
