import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import {
  TitleSuggestion,
  CreateTitleSuggestion,
} from '../../../models/TitleSuggestion';
import { TitleSuggestionId } from '../../../models/Types';

/** Contact classification fetching */

export interface FetchTitleSuggestionsCommitAction {
  type: 'PRIO_FETCH_TITLE_SUGGESTIONS_COMMIT';
  payload: TitleSuggestion[];
}

export const FETCH_TITLE_SUGGESTIONS_REQUEST =
  PRIO + 'FETCH_TITLE_SUGGESTIONS_REQUEST';
export const FETCH_TITLE_SUGGESTIONS_COMMIT =
  PRIO + 'FETCH_TITLE_SUGGESTIONS_COMMIT';
export const FETCH_TITLE_SUGGESTIONS_ROLLBACK =
  PRIO + 'FETCH_TITLE_SUGGESTIONS_ROLLBACK';

export const fetchTitleSuggestions = () => ({
  type: FETCH_TITLE_SUGGESTIONS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/TitleSuggestion`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_TITLE_SUGGESTIONS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_TITLE_SUGGESTIONS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.fetchTitleSuggestionsError',
          timeout: 6,
        },
      },
    },
  },
});

export interface TitleSuggestionAction {
  type: string;
  payload?: TitleSuggestion;
  rollbackTitleSuggestion?: TitleSuggestion;
  meta?: {
    titleSuggestionId?: TitleSuggestionId;
    temporaryId?: TitleSuggestionId;
  };
}

export const UPDATE_TITLE_SUGGESTION_REQUEST =
  PRIO + 'UPDATE_TITLE_SUGGESTION_REQUEST';
export const UPDATE_TITLE_SUGGESTION_COMMIT =
  PRIO + 'UPDATE_TITLE_SUGGESTION_COMMIT';
export const UPDATE_TITLE_SUGGESTION_ROLLBACK =
  PRIO + 'UPDATE_TITLE_SUGGESTION_ROLLBACK';

export const updateTitleSuggestion = (
  updatedTitleSuggestion: TitleSuggestion,
  rollbackTitleSuggestion: TitleSuggestion
) => ({
  type: UPDATE_TITLE_SUGGESTION_REQUEST,
  requiresAuth: true,
  payload: updatedTitleSuggestion,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/TitleSuggestion/${updatedTitleSuggestion.titleSuggestionId}`,
        method: 'PATCH',
        headers: {
          'content-type': 'application/merge-patch+json',
        },
        json: updatedTitleSuggestion,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_TITLE_SUGGESTION_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_TITLE_SUGGESTION_ROLLBACK,
        rollbackTitleSuggestion,
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateTitleSuggestionError',
          timeout: 6,
        },
      },
    },
  },
});

export const CREATE_TITLE_SUGGESTION_REQUEST =
  PRIO + 'CREATE_TITLE_SUGGESTION_REQUEST';
export const CREATE_TITLE_SUGGESTION_COMMIT =
  PRIO + 'CREATE_TITLE_SUGGESTION_COMMIT';
export const CREATE_TITLE_SUGGESTION_ROLLBACK =
  PRIO + 'CREATE_TITLE_SUGGESTION_ROLLBACK';

export const createTitleSuggestion = (
  titleSuggestion: CreateTitleSuggestion,
  temporaryId: TitleSuggestionId
) => ({
  type: CREATE_TITLE_SUGGESTION_REQUEST,
  requiresAuth: true,
  payload: titleSuggestion,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/TitleSuggestion`,
        method: 'POST',
        json: titleSuggestion,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_TITLE_SUGGESTION_COMMIT,
        meta: {
          temporaryId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_TITLE_SUGGESTION_ROLLBACK,
        meta: {
          temporaryId,
        },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.createTitleSuggestionError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

export const DELETE_TITLE_SUGGESTION_REQUEST =
  PRIO + 'DELETE_TITLE_SUGGESTION_REQUEST';
export const DELETE_TITLE_SUGGESTION_COMMIT =
  PRIO + 'DELETE_TITLE_SUGGESTION_COMMIT';
export const DELETE_TITLE_SUGGESTION_ROLLBACK =
  PRIO + 'DELETE_TITLE_SUGGESTION_ROLLBACK';

export const deleteTitleSuggestion = (
  titleSuggestionId: TitleSuggestionId
) => ({
  type: DELETE_TITLE_SUGGESTION_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/TitleSuggestion/${titleSuggestionId}`,
        method: 'DELETE',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_TITLE_SUGGESTION_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_TITLE_SUGGESTION_ROLLBACK,
        meta: { titleSuggestionId },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.deleteTitleSuggestionError',
          timeout: 6,
        },
      },
    },
    titleSuggestionId,
  },
});
