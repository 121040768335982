import React, { ReactNode, useCallback, useMemo } from 'react';
import { ContactsContext } from '../context';
import { useSelector } from 'react-redux';
import {
  getContactsByIdState,
  getContactIdsState,
} from '../../../apps/main/rootReducer';
import { Contact } from '../../../models/Contact';
import { sortContactsHelper } from '../utils';

interface ContactsProviderProps {
  children?: ReactNode;
}

export const ContactsProvider: React.FC<ContactsProviderProps> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const byId = useSelector(getContactsByIdState);

  const contactIds = useSelector(getContactIdsState);

  const contacts: Contact[] = useMemo(() => {
    return contactIds
      .map((id) => byId[id])
      .filter((contact) => !!contact)
      .sort(sortContactsHelper);
  }, [contactIds, byId]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getContactById = useCallback(
    (id: string) => {
      return byId[id];
    },
    [byId]
  );

  const getContactByProperty = useCallback(
    (property: keyof Contact, value: any) => {
      return contacts.find((project) => project[property] === value);
    },
    [contacts]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        getContactById,
        getContactByProperty,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export default ContactsProvider;
