import React from 'react';
import { Menu } from 'antd';
import { useDispatch } from 'react-redux';
import { setContactsDrawerView } from '../actions/drawer';
import { useTranslation } from 'react-i18next';

interface NewContactMenuProps {
  closeDropdown: VoidFunction;
}

export const NewContactMenu: React.FC<NewContactMenuProps> = ({
  closeDropdown,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const switchToNewCompanyView = () => {
    dispatch(setContactsDrawerView('companyNew'));
  };

  const switchToNewContactView = () => {
    dispatch(setContactsDrawerView('contactNew'));
  };
  return (
    <Menu>
      <Menu.Item onClick={switchToNewContactView}>
        {t('common:contact')}
      </Menu.Item>
      <Menu.Item onClick={switchToNewCompanyView}>
        {t('common:company')}
      </Menu.Item>
    </Menu>
  );
};
