import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Divider,
  Checkbox,
  Avatar,
  Select,
  FormInstance,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import { makePrioStyles } from '../../../theme/utils';

import { emailPattern } from '../../../hooks/useEmailValidation';
import {
  Company,
  CompanyDuplicate,
  CreateExternalCompanyRequest,
  emptyCreateCompanyRequest,
  ExternalCompany,
} from '../../../models/Company';
import { CountryPicker } from '../../../components/CountryPicker';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCompanyClassifications } from '../../../apps/main/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanyClassifications } from '../../settings/actions/companyClassification';
import { rowGutter, colon } from '../../../util/forms';
import {
  debounceFunction,
  defaultPhonePattern,
  defaultPhonePlaceholder,
} from '../../../util';
import { apiFetchConfigurations } from '../../settings/api';
import { Configuration } from '../../../models/Configuration';
import { CompanyId, ConfigurationKeys } from '../../../models/Types';
import { apiCheckCompanyDuplicate } from '../api';
import FormTitle from '../../../components/FormTitle';
import DuplicateCompanyWarningList from './DuplicateCompanyWarningList';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
  },
  checkbox: {
    marginTop: theme.old.spacing.unit(2),
  },
  alert: {
    marginTop: theme.old.spacing.unit(1),
  },
  tabs: {
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  tabsPane: {
    height: '100%',
  },
  scrollable: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
}));

interface ExternalCompanyFormProps {
  className?: string;
  initialValues?: Company;
  editMode?: boolean;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  avatar?: boolean;
  form: FormInstance;
  onFinish?: (
    value: CreateExternalCompanyRequest | Company,
    companyIdsToArchive?: CompanyId[]
  ) => void;
  onCancel?: () => void;
}

export const ExternalCompanyForm: React.FC<ExternalCompanyFormProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    initialValues,
    actionLabel,
    cancelLabel,
    editMode,
    disableActionButton,
    disableForm,
    avatar,
    form,
    onFinish,
    onCancel,
  } = props;

  const { t } = useTranslation();
  const companyClassifications = useSelector(getCompanyClassifications);
  const dispatch = useDispatch();

  //#endregion

  //#region -------------------------------- State declaration
  const [foundDuplicates, setFoundDuplicates] = useState<ExternalCompany[]>([]);

  const [selectedDuplicateCompanies, setSelectedDuplicateCompanies] = useState<
    CompanyId[]
  >([]);

  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  //#endregion

  //#region -------------------------------- Methods
  const cancel = () => {
    form.resetFields();
    setSelectedDuplicateCompanies([]);
    onCancel();
  };

  const searchDuplicateCompanies = debounceFunction(
    async (companyDuplicate: CompanyDuplicate) => {
      const { shortName, fullName } = companyDuplicate;
      let companies: Company[] = [];
      if (shortName && fullName) {
        const { data } = await apiCheckCompanyDuplicate({
          shortName,
          fullName,
        });
        companies = data;
      } else if (shortName) {
        const { data } = await apiCheckCompanyDuplicate({
          shortName,
        });
        companies = data;
      } else if (fullName) {
        const { data } = await apiCheckCompanyDuplicate({
          fullName,
        });
        companies = data;
      }

      if (companies && companies.length > 0) {
        setFoundDuplicates(companies);
        setSelectedDuplicateCompanies((selectedDuplicateCompanies) =>
          selectedDuplicateCompanies.filter(
            (companyId) =>
              companies.findIndex((d) => d.companyId === companyId) > -1
          )
        );
      } else {
        setFoundDuplicates([]);
        setSelectedDuplicateCompanies([]);
      }
    },
    500
  );

  //#endregion

  //#region -------------------------------- Handle methods
  const handleFinish: (
    value: CreateExternalCompanyRequest | Company
  ) => void = (value) => {
    onFinish(value, selectedDuplicateCompanies);
  };
  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    dispatch(fetchCompanyClassifications());
  }, [dispatch]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  useEffect(() => {
    if (!disableForm) {
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) setConfigurations(data);
      };
      loadConfigs();
    }
  }, [disableForm]);

  //#endregion

  return (
    <Form
      className={classNames(classes.root, className)}
      initialValues={initialValues ?? emptyCreateCompanyRequest}
      form={form}
      onValuesChange={(
        changedValues: CreateExternalCompanyRequest,
        allValues: CreateExternalCompanyRequest
      ) => {
        if (changedValues.shortName?.startsWith(allValues.fullName ?? '')) {
          form.setFieldsValue({
            ...allValues,
            fullName: changedValues.shortName,
          });
        }
        if (changedValues.fullName || changedValues.shortName) {
          searchDuplicateCompanies({
            fullName: allValues.fullName,
            shortName: allValues.shortName,
          });
        }
      }}
      onFinish={handleFinish}
      layout="vertical"
    >
      <Flex.Row>
        {avatar && (
          <Avatar
            icon={<FontAwesomeIcon icon={['fal', 'building']} />}
            size={96}
            style={{
              backgroundColor: theme.old.palette.primaryColor,
              fontSize: 48,
              fontWeight: theme.old.typography.fontWeight.bold,
              minWidth: 96,
              marginRight: theme.old.spacing.defaultPadding,
            }}
          />
        )}
        <Flex.Item flex={1}>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={12}>
              <Form.Item
                name={['shortName']}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('companies:form.validation.missingShortName'),
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={t('companies:form.labelsRequired.shortName')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['fullName']}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('companies:form.validation.missingFullName'),
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={t('companies:form.labelsRequired.fullName')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={8}>
              <Form.Item
                name={['isClient']}
                colon={colon}
                valuePropName="checked"
              >
                <Checkbox disabled={disableForm}>
                  {t('companies:form.labels.isClient')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['isServiceProvider']}
                colon={colon}
                valuePropName="checked"
              >
                <Checkbox disabled={disableForm}>
                  {t('companies:form.labels.isServiceProvider')}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={['companyClassificationName']}
                colon={colon}
                rules={[
                  {
                    required: false,
                    message: t(
                      'companies:form.validation.missingCompanyClassification'
                    ),
                  },
                ]}
              >
                <Select
                  disabled={disableForm}
                  placeholder={t(
                    'companies:form.labels.companyClassificationName'
                  )}
                >
                  {companyClassifications.map((classification) => (
                    <Select.Option
                      key={classification.name}
                      value={classification.name}
                    >
                      {classification.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Flex.Item>
      </Flex.Row>
      <FormTitle />
      <div className={classes.scrollable}>
        <Form.List name={['offices']}>
          {(fields) =>
            fields.map((field, index) => (
              <div key={field.key}>
                <Row gutter={theme.old.spacing.unit(rowGutter)}>
                  <Col span={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'street']}
                      label={t('companies:form.labels.street')}
                      colon={colon}
                    >
                      <Input disabled={disableForm} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={theme.old.spacing.unit(rowGutter)}>
                  <Col span={8}>
                    <Form.Item
                      name={[field.name, 'zip']}
                      label={t('companies:form.labels.zip')}
                      colon={colon}
                    >
                      <Input disabled={disableForm} />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      name={[field.name, 'city']}
                      label={t('companies:form.labels.city')}
                      colon={colon}
                    >
                      <Input disabled={disableForm} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={theme.old.spacing.unit(rowGutter)}>
                  <Col span={24}>
                    <Form.Item
                      name={[field.name, 'countryCode']}
                      label={t('companies:form.labels.countryCode')}
                    >
                      <CountryPicker
                        disabled={disableForm}
                        placeholder={t(
                          'companies:form.placeholder.countryCode'
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={theme.old.spacing.unit(rowGutter)}>
                  <Col span={24}>
                    <Form.Item
                      name={[field.name, 'eMail']}
                      label={t('companies:form.labels.eMail')}
                      colon={colon}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          message: t('companies:form.validation.invalidEmail'),
                          pattern: emailPattern,
                        },
                      ]}
                    >
                      <Input disabled={editMode || disableForm} type="email" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={theme.old.spacing.unit(rowGutter)}>
                  <Col span={24}>
                    <Form.Item
                      name={[field.name, 'phone']}
                      label={t('companies:form.labels.phone')}
                      colon={colon}
                      rules={[
                        {
                          message: t(
                            'companies:form.validation.invalidPhoneNumber'
                          ),
                          pattern: configurations
                            ? configurations.find(
                                (config) =>
                                  config.key === ConfigurationKeys.REGEX_PHONE
                              )
                              ? new RegExp(
                                  configurations.find(
                                    (config) =>
                                      config.key ===
                                      ConfigurationKeys.REGEX_PHONE
                                  ).value
                                )
                              : defaultPhonePattern
                            : defaultPhonePattern,
                        },
                      ]}
                    >
                      <Input
                        disabled={editMode || disableForm}
                        placeholder={
                          configurations?.find(
                            (config) =>
                              config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                          )?.value ?? defaultPhonePlaceholder
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))
          }
        </Form.List>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name={['website']}
              label={t('companies:form.labels.website')}
              colon={colon}
            >
              <Input disabled={editMode || disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name={['notes']}
              label={t('companies:form.labels.notes')}
              colon={colon}
            >
              <Input.TextArea disabled={editMode || disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <DuplicateCompanyWarningList
          duplicateCompanys={foundDuplicates}
          onArchiveCompany={setSelectedDuplicateCompanies}
        />
      </div>
      <Row justify="end" className={classes.actionButtonsRow}>
        <Col span={24}>
          <Form.Item className={classes.submitButtonFormItem}>
            {onCancel && (
              <Button
                type="default"
                onClick={cancel}
                style={{ marginRight: '8px' }}
              >
                {cancelLabel}
              </Button>
            )}
            <Button htmlType="submit" disabled={disableActionButton}>
              {actionLabel}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ExternalCompanyForm;
