import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Modal } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import Flex from '../../../../components/Flex';
import { DriveFavorite } from '../../../../models/DriveFavorite';
import DebouncedInputSearch from '../../../../components/DebouncedInputField/DebouncedInputSearch';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: theme.old.spacing.unit(1),
  },
}));

interface RenameDriveFavoriteModalProps {
  onClose: VoidFunction;
  onOk: (driveFavorite: DriveFavorite, newName: string) => void;
  driveFavorite: DriveFavorite;
}

export const RenameDriveFavoriteModal: React.FC<
  RenameDriveFavoriteModalProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    onClose: handleCloseFromParent,
    onOk: handleOkFromParent,
    driveFavorite,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [currentName, setCurrentName] = useState<string>(
    driveFavorite?.displayName
  );
  const [renameValid, setRenameValid] = useState<boolean>(true);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const onOk = () => {
    if (renameValid) {
      handleOkFromParent(driveFavorite, currentName.trim());
    }
  };

  const onClose = () => {
    handleCloseFromParent();
  };

  const onRenameInputChange = (value: string) => {
    setCurrentName(value);
    setRenameValid(!!value && !!value.trim());
  };
  //#endregion

  //#region ------------------------------ Effects

  //#endregion

  return (
    <Modal
      title={t('documents:confirmation.rename.title')}
      visible={!!driveFavorite}
      onOk={onOk}
      onCancel={onClose}
      okText={t('documents:confirmation.rename.okText')}
      cancelText={t('documents:confirmation.rename.cancelText')}
      className={classes.root}
      okButtonProps={{
        disabled: !renameValid,
      }}
    >
      <div>
        <div>
          {t('documents:confirmation.rename.content', {
            fileName: driveFavorite?.displayName,
          })}
        </div>
        <Flex.Row alignItems="baseline" childrenGap={theme.old.spacing.unit(1)}>
          <DebouncedInputSearch
            value={currentName}
            onChange={onRenameInputChange}
            className={classes.marginTop}
          />
        </Flex.Row>
        <Alert
          message={t('documents:confirmation.rename.invalidFavoriteName')}
          type="error"
          style={{ visibility: renameValid ? 'hidden' : 'visible' }}
          className={classes.marginTop}
        />
      </div>
    </Modal>
  );
};

export default RenameDriveFavoriteModal;
