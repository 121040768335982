import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import {
  apiFetchOfficeBirthdays,
  apiFetchCompanyBirthdays,
} from '../../../employee/api';
import { Birthday } from '../../../../models/Employee';
import moment, { Moment } from 'moment-timezone';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import UserAvatar from '../../../../components/UserAvatar';
import { Contact } from '../../../../models/Contact';
import { PrioSpinner } from '@prio365/prio365-react-library';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import { useQuery } from '@tanstack/react-query';
import { User } from '../../../../models/User';
import { distinctByProperty } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflowX: 'auto',
  },
  avatar: {
    marginBottom: theme.old.spacing.unit(3),
    marginRight: theme.old.spacing.unit(2),
    marginLeft: theme.old.spacing.unit(2),
  },
  birthdayRow: {
    overflowX: 'auto',
    width: '100%',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '101px',
    gap: theme.old.spacing.unit(2),
  },
  name: {
    width: '100%',
    height: 50,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center',
    hyphens: 'auto',
    display: '-webkit-box',
    WebkitLineClamp: 2, // limit to 2 lines
    WebkitBoxOrient: 'vertical',
  },
  dateGroup: {
    textAlign: 'center',
    marginBottom: theme.old.spacing.unit(2),
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
  },
  header: {
    fontSize: theme.font.fontSize.large,
    fontWeight: theme.font.fontWeight.bold,
  },
  noBirthdaysText: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
    textAlign: 'center',
    display: 'inline-block',
    width: '55%',
  },
}));

const useFetchBirthdays = (
  filter: { from: Moment; to: Moment },
  currentUser: User
) => {
  const { data: companyBirthdays } = useQuery({
    queryKey: ['companyBirthdays'],
    queryFn: () => apiFetchCompanyBirthdays(filter),
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });
  const { data: officeBirthdays } = useQuery({
    queryKey: ['officeBirthdays'],
    queryFn: () => apiFetchOfficeBirthdays(filter),
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
  });

  const combinedBirthdays: Birthday[] = useMemo(() => {
    return distinctByProperty(
      [...(companyBirthdays?.data ?? []), ...(officeBirthdays?.data ?? [])].map(
        (birthday) => ({
          ...birthday,
          employeeId: birthday.employeeId?.toLowerCase(),
        })
      ),
      'employeeId'
    ).filter(
      ({ employeeId }) =>
        employeeId.toLowerCase() !== currentUser.id.toLowerCase()
    );
  }, [companyBirthdays, officeBirthdays, currentUser]);

  return {
    birthdays: combinedBirthdays as Birthday[] | undefined,
  };
};

interface DashboardUpcomingBirthdaysItemProps {
  className?: string;
}

export const DashboardUpcomingBirthdaysItem: React.FC<
  DashboardUpcomingBirthdaysItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  const { getContactById } = useContactsContext();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const currentDate = useMemo(() => moment().startOf('day'), []);
  const range = useMemo(() => moment().add(14, 'days'), []);
  const startDate = useMemo(() => moment().subtract(3, 'days'), []);
  const currentUser = useSelector(getUserMe);

  const { birthdays } = useFetchBirthdays(
    { from: startDate, to: range },
    currentUser
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      childrenGap={24}
    >
      <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
        <div className={classes.header} style={{ margin: 0 }}>
          {t('dashboard:upcomingBirthdays.title')}
        </div>
        {birthdays?.length > 0 && (
          <div className={classes.header}> ({birthdays?.length})</div>
        )}
      </Flex.Row>
      {birthdays === undefined && (
        <Flex.Row flex={1} justifyContent="center">
          <PrioSpinner />
        </Flex.Row>
      )}
      {birthdays?.length === 0 && (
        <Flex.Row flex={1} justifyContent="center">
          <div className={classes.noBirthdaysText}>
            {t('dashboard:upcomingBirthdays.noBirthdayWithinNext14Days')}
          </div>
        </Flex.Row>
      )}
      {birthdays?.length > 0 && (
        <Flex.Row childrenGap={24} className={classes.birthdayRow}>
          {birthdays
            .map((birthday, index) => {
              const birthdayMoment = moment(birthday.birthday);
              const nextBirthday = birthdayMoment.set(
                'year',
                currentDate.year()
              );

              const daysUntilBirthday = nextBirthday.diff(currentDate, 'days');

              const contact = getContactById(
                birthday?.employeeId?.toLowerCase()
              );

              return {
                index,
                nextBirthday,
                daysUntilBirthday,
                contact,
              };
            })
            .sort((a, b) => a.daysUntilBirthday - b.daysUntilBirthday)
            .map(({ index, nextBirthday, daysUntilBirthday, contact }) => {
              return (
                <Item
                  key={index}
                  index={index}
                  nextBirthday={nextBirthday}
                  daysUntilBirthday={daysUntilBirthday}
                  contact={contact}
                />
              );
            })}
        </Flex.Row>
      )}
    </Flex.Column>
  );
};

export default DashboardUpcomingBirthdaysItem;

interface ItemProps {
  index: number;
  nextBirthday: Moment;
  daysUntilBirthday: number;
  contact: Contact;
}

const Item: React.FC<ItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { index, nextBirthday, daysUntilBirthday, contact } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getDateDescription = (daysUntilBirthday: number) => {
    if (daysUntilBirthday === 0) {
      return t('dashboard:upcomingBirthdays.today');
    } else if (daysUntilBirthday === 1) {
      return t('dashboard:upcomingBirthdays.tomorrow');
    } else if (daysUntilBirthday === -1) {
      return t('dashboard:upcomingBirthdays.yesterday');
    } else if (daysUntilBirthday > 1) {
      return t('dashboard:upcomingBirthdays.inDays', {
        days: daysUntilBirthday,
      });
    } else {
      return t('dashboard:upcomingBirthdays.daysAgo', {
        days: Math.abs(daysUntilBirthday),
      });
    }
  };
  //#endregion

  return (
    <div key={index} className={classes.item}>
      <UserAvatar contact={contact} size="extraLarge"></UserAvatar>
      <div
        className={classes.name}
        title={contact && `${contact.firstName} ${contact.lastName}`}
      >
        {contact && `${contact.firstName} ${contact.lastName}`}
      </div>
      <div className={classes.dateGroup}>
        <div>{nextBirthday.format('DD.MM.')}</div>
        <div style={{ fontSize: theme.font.fontSize.extraSmall }}>
          {getDateDescription(daysUntilBirthday)}
        </div>
      </div>
    </div>
  );
};
