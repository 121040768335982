import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  RootReducerState,
  getAbsenceOverview,
  getAbsenceProposalsMe,
} from '../../../apps/main/rootReducer';
import { AbsenceOverviewState } from '../reducers/absences';
import {
  AbsenceOverview,
  AbsenceProposal,
} from '../../../models/AbsenceProposal';
import { closeTimeAndLeaveManagementDrawer } from '../../timeAndLeaveManagement/actions';
import NewAbsenceProposal from './NewAbsenceProposal';
import { DateTimeString } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

const absenceOverviewSelector = createSelector<
  [
    (state: RootReducerState) => AbsenceOverviewState,
    (state: RootReducerState) => AbsenceProposal[]
  ],
  AbsenceOverview
>(getAbsenceOverview, getAbsenceProposalsMe, (overview, absenceProposals) => ({
  ...overview,
  absenceProposals,
}));

interface AbsencesMeDrawerContentProps {
  className?: string;
  startDateTime?: DateTimeString;
  endDateTime?: DateTimeString;
}

export const AbsencesMeDrawerContent: React.FC<AbsencesMeDrawerContentProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, startDateTime, endDateTime } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const absenceOverview = useSelector(absenceOverviewSelector);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClose = () => {
    dispatch(closeTimeAndLeaveManagementDrawer());
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <NewAbsenceProposal
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        absenceOverview={absenceOverview}
        onFinish={handleClose}
      />
    </div>
  );
};

export default AbsencesMeDrawerContent;
