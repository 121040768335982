import React, { ReactNode, useCallback, useMemo } from 'react';
import { ProjectsContext } from '../context';
import { useSelector } from 'react-redux';
import {
  getAllProjectIds,
  getMyProjectIds,
  getProjectByIdState,
} from '../../../apps/main/rootReducer';
import { Project } from '../../../models/Project';
import { sortProjects } from '../utils';

interface ProjectsProviderProps {
  children?: ReactNode;
}

export const ProjectsProvider: React.FC<ProjectsProviderProps> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const byId = useSelector(getProjectByIdState);

  const myProjectsIds = useSelector(getMyProjectIds);

  const allProjectIds = useSelector(getAllProjectIds);

  const myProjects: Project[] = useMemo(() => {
    return sortProjects(
      myProjectsIds.map((id) => byId[id]).filter((project) => !!project)
    );
  }, [myProjectsIds, byId]);

  const favorites = useMemo(() => {
    return myProjects.filter((project) => project.favorite);
  }, [myProjects]);

  const allProjects: Project[] = useMemo(() => {
    return sortProjects(
      allProjectIds.map((id) => byId[id]).filter((project) => !!project)
    );
  }, [allProjectIds, byId]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getProjectById = useCallback(
    (id: string) => {
      return byId[id];
    },
    [byId]
  );

  const getProjectByProperty = useCallback(
    (property: keyof Project, value: any) => {
      const projects = Object.values(byId);
      return projects.find((project) => project[property] === value);
    },
    [byId]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <ProjectsContext.Provider
      value={{
        myProjects,
        favorites,
        allProjects,
        getProjectById,
        getProjectByProperty,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export default ProjectsProvider;
