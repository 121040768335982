import React, { CSSProperties, useEffect, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  confetti: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    animation: '$bang 750ms ease-out forwards',
  },
  welcomeText: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    animation: '$fade_in 250ms ease forwards 500ms',
    opacity: 0,
  },
  armContainer: {
    width: 45,
    position: 'relative',
  },
  arm: {
    position: 'relative',
    height: 88,
    transformOrigin: 'calc(100% - 8px) 100%',
    transform: 'rotate(2deg)',
    animation: '$wiggle 2s infinite',
  },
  hand: {
    position: 'absolute',
    right: -4,
    bottom: 38,
    height: 24,
    width: 24,
    borderRadius: '50%',
    backgroundColor: theme.old.palette.backgroundPalette.base,
    '&::after': {
      content: '""',
      position: 'absolute',
      height: 24,
      width: 16,
      borderRadius: 6,
      top: -12,
      left: 3,
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
  },
  upperArm: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 16,
    height: 40,
    backgroundColor: theme.old.palette.backgroundPalette.base,
    borderRadius: 6,
  },
  '@keyframes bang': {
    '0%': {
      opacity: 0,
      transform: 'translate3d(0,0,0)',
    },
    '10%': {
      opacity: 1,
    },
    '75%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@keyframes fade_in': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes wiggle': {
    '0%': {
      transform: 'rotate(2deg)',
    },
    '50%': {
      transform: 'rotate(-30deg)',
    },
    '100%': {
      transform: 'rotate(2deg)',
    },
  },
}));

const random = (value: number) => Math.floor(Math.random() * value);

interface WelcomeBackProps {
  className?: string;
}

export const WelcomeBack: React.FC<WelcomeBackProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const [text1, setText1] = useState<string>(null);
  const [text2, setText2] = useState<string>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleButtonClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 1000);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const wait = (milliseconds: number) => {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    };

    const typeText = async () => {
      await wait(1000);
      const text1 = 'Hey Joni, I am glad to see you again!';
      for (let i = 0; i < text1.length; i++) {
        await wait(50);
        setText1(text1.slice(0, i + 1));
      }
      await wait(1000);
      const text2 = 'Hope you are doing well!';
      for (let i = 0; i < text2.length; i++) {
        await wait(50);
        setText2(text2.slice(0, i + 1));
      }
    };
    typeText();
  }, []);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      {isClicked
        ? new Array(250)
            .fill(0)
            .map((_, i) => <Confetti className={classes.confetti} key={i} />)
        : null}
      <Flex.Column className={classes.welcomeText} childrenGap={24}>
        <Flex.Row childrenGap={24} alignItems="center">
          <Flex.Row>
            <Flex.Item className={classes.armContainer}>
              <div className={classes.arm}>
                <div className={classes.upperArm} />
                <div className={classes.hand} />
              </div>
            </Flex.Item>
            <Flex.Item>
              <FontAwesomeIcon
                icon={['fad', 'user-robot']}
                style={
                  {
                    fontSize: 88,
                    '--fa-primary-color':
                      theme.old.palette.backgroundPalette.base,
                    '--fa-secondary-color':
                      theme.old.palette.backgroundPalette.hover.base,
                  } as CSSProperties
                }
              />
            </Flex.Item>
          </Flex.Row>
          <Flex.Column width={'400px'}>
            <Typography.Title level={1} style={{ margin: 0, marginBottom: 8 }}>
              {text1}
            </Typography.Title>
            {text2 && (
              <Typography.Title level={1} style={{ margin: 0 }}>
                {text2}
              </Typography.Title>
            )}
          </Flex.Column>
        </Flex.Row>
        <Button onClick={handleButtonClick} disabled={isClicked}>
          Klick mich!
        </Button>
      </Flex.Column>
    </div>
  );
};

export default WelcomeBack;

interface ConfettiProps {
  className?: string;
}

const Confetti: React.FC<ConfettiProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const style: CSSProperties = {
    width: random(10) + 10,
    height: random(10) + 10,
    background: `hsl(${random(360)},${random(50) + 50}%,${random(20) + 50}%)`,
    transform:
      'translate3d(' +
      random(1000) * (random(10) % 2 === 0 ? -1 : 1) +
      'px, ' +
      random(1000) * (random(10) % 2 === 0 ? -1 : 1) +
      'px, ' +
      random(10000) +
      'px) rotate(' +
      random(360) +
      'deg)',
    ...(random(10) % 2 === 0 ? { borderRadius: '50%' } : {}),
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)} style={style}></div>
  );
};
