import React, { useEffect, useState } from 'react';

import { Table, notification, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { User } from '../../../models/User';
import { TableRowSelection } from 'antd/lib/table/interface';
import Flex from '../../../components/Flex';
import { useDispatch } from 'react-redux';
import { closeAddUserDrawer } from '../actions/addUserDrawer';
import { apiFetchImportableUsers, apiImportAadUsers } from '../api';
import useDebounce from '../../../hooks/useDebounce';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  table: {
    marginLeft: -theme.old.spacing.unit(3),
    marginRight: -theme.old.spacing.unit(3),
    maxWidth: `calc(100% + ${theme.old.spacing.unit(6)}px)`,
    flex: 1,
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  buttonRow: {
    marginTop: theme.old.spacing.unit(1),
  },
  search: {
    marginTop: theme.old.spacing.unit(1),
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
}));

interface ImportAadUsersProps {
  className?: string;
}

export const ImportAadUsers: React.FC<ImportAadUsersProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [importableUsers, setImportableUsers] = useState<User[] | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [importing, setImporting] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>('');

  const debouncedSearch = useDebounce(searchInput, 1000);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    const loadImportableUsers = async () => {
      setIsFetching(true);
      const { data } =
        debouncedSearch !== ''
          ? await apiFetchImportableUsers(debouncedSearch)
          : await apiFetchImportableUsers();
      if (data) {
        setImportableUsers(data);
      }
      setIsFetching(false);
    };
    loadImportableUsers();
  }, [debouncedSearch]);

  const columns: ColumnProps<User>[] = [
    {
      title: t('users:importAadUsers.columnsTitles.name'),
      dataIndex: 'displayName',
      defaultSortOrder: 'ascend',
    },
    {
      title: t('users:importAadUsers.columnsTitles.email'),
      dataIndex: 'userPrincipalName',
    },
  ];

  const rowSelection: TableRowSelection<User> = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys as string[]);
    },
    type: 'checkbox',
    columnWidth: theme.old.spacing.unit(8),
  };

  const cancel = () => {
    setSelectedRowKeys([]);
    dispatch(closeAddUserDrawer());
  };

  const importSelectedUsers = async () => {
    setImporting(true);
    const { result } = await apiImportAadUsers(selectedRowKeys);
    setImporting(false);
    const success = result.status >= 200 && result.status < 300;
    if (success) {
      notification.open({
        message: t('common:success'),
        description: t('users:successMessages.importUserSuccess', {
          userCount: selectedRowKeys.length,
        }),
      });
      setImportableUsers(
        importableUsers.filter((user) => !selectedRowKeys.includes(user.id))
      );
      setSelectedRowKeys([]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('users:errorMessages.usersImportError'),
      });
    }
  };

  if (importableUsers === null) {
    return (
      <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
        <PrioSpinner size="large" />
      </div>
    );
  }

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      childrenGap={theme.old.spacing.unit(1)}
    >
      <Input.Search
        className={classes.search}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder={t('users:importAadUsers.searchPlaceholder')}
        allowClear
      />
      {!isFetching ? (
        <Table
          className={classes.table}
          dataSource={importableUsers}
          columns={columns}
          pagination={false}
          rowKey={(record) => record.id}
          rowSelection={rowSelection}
          locale={{
            filterTitle: t('common:table.filterTitle'),
            filterConfirm: t('common:table.filterConfirm'),
            filterReset: t('common:table.filterReset'),
            emptyText: t('common:table.emptyText'),
            selectAll: t('common:table.selectAll'),
            selectInvert: t('common:table.selectInvert'),
            selectionAll: t('common:table.selectionAll'),
            sortTitle: t('common:table.sortTitle'),
            expand: t('common:table.expand'),
            collapse: t('common:table.collapse'),
            triggerDesc: t('common:table.triggerDesc'),
            triggerAsc: t('common:table.triggerAsc'),
            cancelSort: t('common:table.cancelSort'),
          }}
          scroll={{ y: 'calc(100vh - 300px)' }}
        />
      ) : (
        <Flex.Item flex={1}>
          <div className="prio-flex-center-center prio-flex-column">
            <PrioSpinner />
          </div>
        </Flex.Item>
      )}
      <Flex.Row className={classes.buttonRow}>
        <Flex.Item flex={1}>
          <Button onClick={cancel} type="link">
            {' '}
            {t('common:actions.cancel')}
          </Button>
        </Flex.Item>

        <Button
          disabled={selectedRowKeys.length === 0 || importing}
          onClick={importSelectedUsers}
        >
          {`${selectedRowKeys.length} ${t(
            'users:importAadUsers.buttons.importUsers'
          )}`}
        </Button>
      </Flex.Row>
    </Flex.Column>
  );
};

export default ImportAadUsers;
