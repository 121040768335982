import { useEffect, useMemo, useRef, useState } from 'react';
import { DefaultSearchParameterItem, FilterBarData } from '../types';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getReduxSearchString,
  RootReducerState,
} from '../../../apps/main/rootReducer';

const useSearchString = (
  searchFilterConfig: FilterBarData,
  customDefaultSearchParameters: DefaultSearchParameterItem[],
  ignoreBackendDefaultSearchParameters: boolean,
  disableURLQuery: boolean
) => {
  const [searchParams] = useSearchParams();
  const { officeId, projectId } = useParams();
  const location = useLocation();
  const pathType = location.pathname.includes('/me/')
    ? 'me'
    : location.pathname.includes('/openproposal')
    ? 'openproposal'
    : 'allproposals';

  const lastSearch = useSelector((state: RootReducerState) =>
    getReduxSearchString(
      state,
      searchFilterConfig?.searchType,
      officeId,
      projectId,
      pathType
    )
  );

  const defaultSearchParametersFromSearchConfig = useMemo(() => {
    const filters = searchFilterConfig?.searchableParameters;
    const pickers =
      filters?.reduce((acc, f) => {
        const isDefaultParameter = !!f?.defaultValues;

        const defaultParameters: DefaultSearchParameterItem[] =
          (f?.defaultValues?.reduce((acc, value) => {
            const isInCustom = customDefaultSearchParameters?.find(
              (v) => v?.parameterName === f?.parameterName
            );

            const parameterName = f?.parameterName;
            const parameterValues = value;

            const parameter: DefaultSearchParameterItem = {
              parameterName,
              defaultValue: parameterValues?.defaultValue,
              defaultMethod: parameterValues?.defaultMethod,
            };

            return isDefaultParameter && !isInCustom
              ? [...acc, parameter]
              : acc;
          }, []) as DefaultSearchParameterItem[]) || [];
        if (!isDefaultParameter) return acc;
        return [...acc, ...defaultParameters];
      }, []) || [];
    return pickers;
  }, [customDefaultSearchParameters, searchFilterConfig]);

  const combinedDefaultParameter = useMemo(() => {
    return [
      ...customDefaultSearchParameters,
      ...(ignoreBackendDefaultSearchParameters
        ? []
        : defaultSearchParametersFromSearchConfig),
    ];
  }, [
    customDefaultSearchParameters,
    defaultSearchParametersFromSearchConfig,
    ignoreBackendDefaultSearchParameters,
  ]);

  const defaultSearchParameters: string = useMemo(() => {
    const defaultValuesString = combinedDefaultParameter.reduce(
      (acc, value) => {
        const _value = value
          ? `${value?.parameterName} ${value?.defaultMethod} '${
              Array.isArray(value?.defaultValue)
                ? value?.defaultValue?.join("','")
                : value?.defaultValue
            }'`
          : '';

        if (!_value) {
          return acc;
        }
        return `${acc}${acc ? ' & ' : ''}${_value}`.trim();
      },
      ''
    );

    return defaultValuesString;
  }, [combinedDefaultParameter]);

  const searchParamValue = !disableURLQuery ? searchParams.get('s') : null;
  const searchParamValueRef = useRef<string>(searchParamValue);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [searchString, setSearchString] = useState<string>(
    searchParamValueRef.current ??
      (lastSearch && isFirstLoad ? lastSearch : defaultSearchParameters) ??
      null
  );

  useEffect(() => {
    if (searchParamValueRef.current) {
      return;
    }

    setIsFirstLoad((prevIsFirstLoad) => {
      if (prevIsFirstLoad) {
        return false;
      } else {
        setSearchString(defaultSearchParameters ?? null);
        return prevIsFirstLoad;
      }
    });
  }, [defaultSearchParameters]);

  if (!searchFilterConfig) {
    return {
      searchString: null,
      defaultSearchParameters: '',
      setSearchString: null,
    };
  }

  return { searchString, defaultSearchParameters, setSearchString };
};

export default useSearchString;
