import React from 'react';
import Drawer from '@prio365/prio365-react-library/lib/Drawer';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import Flex from '../../../components/Flex';
import { EmergencyContact, Employee } from '../../../models/Employee';
import { EditPersonnelFileDataForm } from './EditPersonnelDataForm';
import { EmployeeId } from '../../../models/Types';
import { PrioTheme } from '../../../theme/types';
import EmergencyContactList from './EmergencyContactList';

interface EditPersonnelDataDrawerProps {
  onClose?: VoidFunction;
  open?: boolean;
  employeeId?: EmployeeId;
  employee?: Employee;
  patchEmployee: (patchData: Employee) => Promise<void>;
  setIsEmergencyContactsUpdated?: () => void;
  emergencyContactList?: EmergencyContact[];
}
const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  importUser: {
    height: '100%',
    padding: theme.spacing.large,
  },
  importAadUser: {
    flex: 1,
  },
  title: {
    paddingBottom: theme.old.spacing.unit(2),
    marginTop: -theme.old.spacing.unit(1),
  },
  scrollabe: {
    flex: 1,
    overflow: 'auto',
  },
  drawer: {
    zIndex: 999,
    padding: 0,
    overflowY: 'auto',
  },
}));

export const EditPersonnelDataDrawer: React.FC<EditPersonnelDataDrawerProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    onClose,
    open,
    employeeId,
    employee,
    patchEmployee,
    emergencyContactList,
    setIsEmergencyContactsUpdated,
  } = props;

  const closeDrawer = () => {
    if (onClose) {
      onClose();
    }
  };

  const renderEmergencyContacts = () => {
    return (
      <EmergencyContactList
        items={emergencyContactList}
        setIsEmergencyContactsUpdated={setIsEmergencyContactsUpdated}
      />
    );
  };

  const renderPersonneldataContent = () => {
    return (
      <Flex.Column className={classes.importUser}>
        <div className={classes.scrollabe}>
          <EditPersonnelFileDataForm
            employeeId={employeeId}
            employee={employee}
            isSaving={false}
            onClose={onClose}
            datePickerLocale={undefined}
            patchEmployee={patchEmployee}
          />
        </div>
      </Flex.Column>
    );
  };

  return (
    <Drawer
      closable={true}
      onClose={closeDrawer}
      visible={open}
      customWidth={theme.old.components.drawerWidth}
      contentClassName={classes.drawer}
      title={t('profile:titles.drawerTitle')}
      tabs={[
        {
          key: 'editPersonnelData',
          label: t('profile:titles.editPersonneldata'),
          content: renderPersonneldataContent(),
        },
        {
          key: 'editEmergencyContacts',
          label: t('profile:titles.editEmergencyContacts'),
          content: renderEmergencyContacts(),
        },
      ]}
    ></Drawer>
  );
};

export default EditPersonnelDataDrawer;
