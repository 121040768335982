import React from 'react';
import { makePrioStyles } from '../../theme/utils';
import ThemeSettingsForm from './ThemeSettingsForm';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    padding: theme.old.spacing.defaultPadding,
  },
}));

interface ThemeSettingsModuleProps {}

export const ThemeSettingsModule: React.FC<ThemeSettingsModuleProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classes.root}>
      <ThemeSettingsForm />
    </div>
  );
};

export default ThemeSettingsModule;
