import { PRIO } from '../../../constants';
import { apiUrl } from '../../../api';
import { DocumentTemplate } from '../../../models/DocumentTemplate';

/** Company fetching */
export const FETCH_DOCUMENT_TEMPLATES_REQUEST =
  PRIO + 'FETCH_DOCUMENT_TEMPLATES_REQUEST';
export const FETCH_DOCUMENT_TEMPLATES_COMMIT =
  PRIO + 'FETCH_DOCUMENT_TEMPLATES_COMMIT';
export const FETCH_DOCUMENT_TEMPLATES_ROLLBACK =
  PRIO + 'FETCH_DOCUMENT_TEMPLATES_ROLLBACK';

export const fetchDocumentTemplates = () => ({
  type: FETCH_DOCUMENT_TEMPLATES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: { url: `${apiUrl}/document/DocumentTemplate`, method: 'GET' },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_DOCUMENT_TEMPLATES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_DOCUMENT_TEMPLATES_ROLLBACK,
        snackbarErrorMessage: {
          label:
            'documents:documentTemplates.errorMessages.fetchDocumentTemplatesError',
          timeout: 6,
        },
      },
    },
  },
});

export const fetchUnArchivedDocumentTemplates = (wantArchived: boolean) => ({
  type: FETCH_DOCUMENT_TEMPLATES_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: { url: `${apiUrl}/document/DocumentTemplate?includeArchived=${wantArchived}`, method: 'GET' },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_DOCUMENT_TEMPLATES_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_DOCUMENT_TEMPLATES_REQUEST,
        snackbarErrorMessage: {
          label:
            'documents:documentTemplates.errorMessages.fetchDocumentTemplatesError',
          timeout: 6,
        },
      },
    },
  },
});

export const DELETE_DOCUMENT_TEMPLATE =
  PRIO + 'DELETE_DOCUMENT_TEMPLATE';

export const deleteDocumentTemplate = (value: DocumentTemplate) => ({
  type: DELETE_DOCUMENT_TEMPLATE,
  value,
});