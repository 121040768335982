import React from 'react';
import {
  GlobalAndOfficeNavigation,
  NavItem,
} from '../../../components/GlobalAndOfficeNavigation';
import GlobalAndOfficeMenu from '../../../components/GlobalAndOfficeMenu';

interface HRMenuProps {
  selectedList?: string;
  officeId?: string;
  urlPrefix?: string;
  open?: boolean;
  isSubMenu?: boolean;
}

export const HRMenu: React.FC<HRMenuProps> = (props) => {
  const { selectedList, officeId, urlPrefix, open, isSubMenu } = props;

  const globalNavItems: NavItem[] = [
    {
      itemKey: 'absence',
      icon: 'island-tropical',
      tString: 'hr:navigation.absence',
    },
    /*
    {
      itemKey: 'timekeeping',
      icon: 'business-time',
      tString: 'hr:navigation.timekeeping',
      urlSuffix: 'timekeeping',
    },
   
    {
      itemKey: 'trainings',
      icon: 'university',
      tString: 'hr:navigation.trainings',
    },
    */
    {
      itemKey: 'personnelFiles',
      icon: 'address-card',
      tString: 'hr:navigation.personnelFiles',
    },
  ];

  const globalSubMenuItem: NavItem = {
    itemKey: 'absence',
    tString: 'hr:navigation.allHr',
  };
  const officeNavItems: NavItem[] = [
    {
      itemKey: 'absence',
      icon: 'island-tropical',
      tString: 'hr:navigation.absence',
    },
    {
      itemKey: 'timekeeping',
      icon: 'business-time',
      tString: 'hr:navigation.timekeeping',
    },
    {
      itemKey: 'personnelFiles',
      icon: 'address-card',
      tString: 'hr:navigation.personnelFiles',
    },
  ];

  if (!open && !isSubMenu) {
    return (
      <GlobalAndOfficeMenu
        urlPrefix={urlPrefix}
        selectedKey={selectedList}
        officeId={officeId}
        globalMenuItems={globalNavItems}
        officeMenuItems={officeNavItems}
        globalAccessMode={'showGlobalInHrModule'}
        officeAccessMode={'showOfficesInHrModule'}
        openSider={open}
      />
    );
  }

  return (
    <GlobalAndOfficeNavigation
      urlPrefix={urlPrefix}
      globalMenuItems={globalNavItems}
      officeMenuItems={officeNavItems}
      selectedList={selectedList}
      officeId={officeId}
      globalAccessMode={'showGlobalInHrModule'}
      officeAccessMode={'showOfficesInHrModule'}
      showOpen={open}
      isSubMenu={isSubMenu}
      globalSubMenuItem={globalSubMenuItem}
    />
  );
};
