import { ApiResult, apiUrl } from '../../../api';
import { ReadNotifications } from '../../../models/Notification';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiUpdatedReadNotifications: (
  notifications: ReadNotifications
) => Promise<ApiResult<void>> = async (notifications) => {
  const result = await fetchWithRetry(
    `${apiUrl}/notification/notification/me/read`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(notifications),
    }
  );
  return {
    result,
  };
};
