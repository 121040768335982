import React from 'react';

interface SvgIconProps {
  width?: number;
  height?: number;
  color?: string;
  path: JSX.Element;
  style?: React.CSSProperties;
}

export const SvgIcon: React.FC<SvgIconProps> = (props) => {
  //#region ------------------------------ Defaults
  const { width = 24, height = 24, color = 'black', path, style } = props;
  //#endregion

  return (
    <svg
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 94 94"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <>{path}</>
    </svg>
  );
};

export default SvgIcon;
