export const nameof = <T>(name: keyof T) => name;

export const getEmailSuffixIndex: (
  email: string,
  suffixes: string[]
) => number = (email: string, suffixes: string[]) => {
  // Split the email by '@'
  const emailParts = email.split('@');
  // Get the email suffix
  const emailSuffix = emailParts[emailParts.length - 1];
  // Search for the email suffix in the suffixes array
  return suffixes.indexOf(emailSuffix);
};

export const getEmailprefix: (email: string) => string = (email: string) => {
  // Split the email by '@'
  const emailParts = email.split('@');
  return emailParts[0];
};
