import React from 'react';
import { Project } from '../../../models/Project';
import { Typography, Card } from 'antd';

interface ProjectCardProps {
  project: Project;
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => (
  <Card title={project.name}>
    <Typography.Text>{project.name}</Typography.Text>
    <Typography.Text>{project.eMailPrefix}</Typography.Text>
  </Card>
);

export default ProjectCard;
