import {
  AbsenceProposalId,
  DateTimeString,
  MedicalCertificateId,
} from './Types';

export const medicalCertificateType = [
  'initialMedicalCertificate',
  'followUpMedicalCertificate',
  'hospitalStay',
  'childIllnessCertificate',
  'rehabilitationTherapyCertificate',
  'reintegrationCertificate',
] as const;

export type MedicalCertificateType = (typeof medicalCertificateType)[number];

export const eMedicalCertificateState = [
  'new',
  'pending',
  'approved',
  'rejected',
] as const;

export type MedicalCertificateState = (typeof eMedicalCertificateState)[number];

export interface MedicalCertificate {
  medicalCertificateId: MedicalCertificateId;
  medicalCertificateType: MedicalCertificateType;
  from: DateTimeString;
  to: DateTimeString;
  attachmentUploaded: boolean;
  attachmentSentToHrAt?: DateTimeString | null;
  absenceProposalId: AbsenceProposalId;
  lastModifiedAt: DateTimeString;
  state: MedicalCertificateState;
}

export interface CreateMedicalCertificate {
  medicalCertificateType: MedicalCertificateType;
  from: DateTimeString;
  to: DateTimeString;
  absenceProposalId?: AbsenceProposalId;
}

export interface UpdateMedicalCertificate {
  medicalCertificateType: MedicalCertificateType;
  from: DateTimeString;
  to: DateTimeString;
}

export interface MedicalCertificateAttachmentRequest {
  medicalCertificateId: MedicalCertificateId;
  medicalCertificate: FormData;
}
