import { useEffect, useMemo } from 'react';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useAccessRights2 from '../../../modules/users/hooks/useAccessRights2';
import useProjectsContext from '../../../modules/projects/hooks/useProjectsContext';
import { syncGlobalProjects } from '../../../modules/projects/actions';
import { useDispatch } from 'react-redux';
import { ProjectExtensionType } from '../../../models/ProjectExtension';

interface ProjectFilterPickerConsumerProps extends FilterPickerConsumerProps {
  extensionRequired?: ProjectExtensionType;
}

const ProjectFilterPickerConsumer: React.FC<
  ProjectFilterPickerConsumerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children, roles, extensionRequired } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    checkGlobalRoles,
    checkProjectRolesOfProjectId,
    checkOfficeRolesOfProjectId,
  } = useAccessRights2();

  const { allProjects, myProjects } = useProjectsContext();
  const dispatch = useDispatch();

  const projects = useMemo(() => {
    if (
      roles.globalRoles.length > 0 &&
      checkGlobalRoles(
        roles.globalRoles.filter((role) => role !== 'globalEmployee')
      )
    ) {
      return allProjects;
    }
    if (roles.officeRoles.length > 0 && roles.projectRoles.length > 0) {
      return allProjects.filter(
        (project) =>
          checkOfficeRolesOfProjectId(roles.officeRoles, project.projectId) ||
          checkProjectRolesOfProjectId(roles.projectRoles, project.projectId)
      );
    }
    return myProjects;
  }, [
    roles,
    myProjects,
    allProjects,
    checkGlobalRoles,
    checkProjectRolesOfProjectId,
    checkOfficeRolesOfProjectId,
  ]);

  const projectsWithRequiredExtensions = useMemo(() => {
    return projects.filter(
      (project) =>
        project.projectExtensionMeDtos?.find(
          (ext) =>
            ext.projectExtensionType === extensionRequired &&
            ext.projectExtensionState === 'ready'
        )
    );
  }, [projects, extensionRequired]);

  const _projects = extensionRequired
    ? projectsWithRequiredExtensions
    : projects;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (
      (roles.globalRoles.length > 0 &&
        checkGlobalRoles(
          roles.globalRoles.filter((role) => role !== 'globalEmployee')
        )) ||
      (roles.officeRoles.length > 0 && roles.projectRoles.length > 0)
    ) {
      dispatch(syncGlobalProjects());
    }
  }, [roles, checkGlobalRoles, dispatch]);
  //#endregion

  return (
    <>
      {children({
        options: _projects.map(({ projectId, name, number }) => {
          return {
            value: projectId,
            label: `${number} - ${name}`,
            searchValue: `${number} ${name} ${projectId}`,
          };
        }),
      })}
    </>
  );
};

export default ProjectFilterPickerConsumer;
