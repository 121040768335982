import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Message, MessageAttachment } from '../../../../models/Message';
import i18n from '../../../../i18n';
import moment from 'moment';

const firstTdStyle: CSSProperties = {
  width: '30%',
  fontWeight: 600,
  verticalAlign: 'top',
};

const secondTdStyle: CSSProperties = {
  width: '70%',
  verticalAlign: 'top',
};

const parseEmailAddress = (emailAddress: {
  name?: string;
  address: string;
}) => {
  return emailAddress.name
    ? `${emailAddress.name} <${emailAddress.address}>`
    : emailAddress.address;
};

const mapFlagStatus = (flagStatus: 'NotFlagged' | 'Flagged' | 'Complete') => {
  switch (flagStatus) {
    case 'NotFlagged':
      return i18n.t('mail:print.flagStatus.notFlagged');
    case 'Flagged':
      return i18n.t('mail:print.flagStatus.flagged');
    case 'Complete':
      return i18n.t('mail:print.flagStatus.complete');
    default:
      return '';
  }
};

interface MessageOverviewProps {
  message: Message;
  messageAttachments: MessageAttachment[];
}

export const MessageOverview: React.FC<MessageOverviewProps> = (props) => {
  //#region ------------------------------ Defaults
  const { message, messageAttachments } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <table
      style={{
        width: '100%',
      }}
    >
      <tbody>
        {message.from.emailAddress && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.from')}:`}</td>
            <td style={secondTdStyle}>
              {parseEmailAddress(message.from.emailAddress)}
            </td>
          </tr>
        )}
        {message.receivedDateTime && (
          <tr>
            <td style={firstTdStyle}>{`${t(
              'mail:print.recivedDateTime'
            )}:`}</td>
            <td style={secondTdStyle}>
              {moment(message.receivedDateTime).format(
                'dd, DD. MMM. YYYY HH:mm'
              )}
            </td>
          </tr>
        )}
        {message.toRecipients?.length > 0 && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.to')}:`}</td>
            <td style={secondTdStyle}>
              {message.toRecipients
                .map(({ emailAddress }) => parseEmailAddress(emailAddress))
                .join(', ')}
            </td>
          </tr>
        )}
        {message.ccRecipients?.length > 0 && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.cc')}:`}</td>
            <td style={secondTdStyle}>
              {message.ccRecipients
                .map(({ emailAddress }) => parseEmailAddress(emailAddress))
                .join(', ')}
            </td>
          </tr>
        )}
        {message.bccRecipients?.length > 0 && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.bcc')}:`}</td>
            <td style={secondTdStyle}>
              {message.bccRecipients
                .map(({ emailAddress }) => parseEmailAddress(emailAddress))
                .join(', ')}
            </td>
          </tr>
        )}
        {messageAttachments.length > 0 && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.attachments')}:`}</td>
            <td style={secondTdStyle}>
              {messageAttachments
                .map((attachment) => attachment.name)
                .join(', ')}
            </td>
          </tr>
        )}
        {message.flag?.flagStatus !== 'NotFlagged' && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.flag')}:`}</td>
            <td style={secondTdStyle}>
              {mapFlagStatus(message.flag.flagStatus)}
            </td>
          </tr>
        )}
        {message.categories?.length > 0 && (
          <tr>
            <td style={firstTdStyle}>{`${t('mail:print.categories')}:`}</td>
            <td style={secondTdStyle}>{message.categories.join(', ')}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default MessageOverview;
