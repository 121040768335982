import React, { useState, useCallback, useEffect } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import { HourlyRateSuggestion } from '../../../models/HourlyRate';
import Flex from '../../../components/Flex';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { HourlyRateSuggestionsTable } from './HourlyRateSuggestionsTable';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';
import { asyncForEach } from '../../../util';
import { HourlyRateSuggestionId } from '../../../models/Types';
import {
  apiCreateHourlyRateSuggestion,
  apiDeleteHourlyRateSuggestion,
  apiFetchHourlyRateSuggestions,
  apiUpdateHourlyRateSuggestion,
} from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
  },
  spin: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    width: '100%',
  },
  column: {},
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
}));

interface HourlyRateSuggestionsPageProps {
  className?: string;
}

export const HourlyRateSuggestionsPage: React.FC<
  HourlyRateSuggestionsPageProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { className } = props;
  const [hourlyRateSuggestions, setHourlyRateSuggestions] = useState<
    HourlyRateSuggestion[] | null
  >(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [updatedHourlyRateSuggestions, setUpdatedHourlyRateSuggestions] =
    useState<HourlyRateSuggestion[]>(null);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const loadSuggestions = async () => {
      const { data } = await apiFetchHourlyRateSuggestions(signal);
      if (data) setHourlyRateSuggestions(data);
    };
    loadSuggestions();
    return () => {
      controller.abort();
    };
  }, []);
  const onIsDirtyChangedHourlyRates = useCallback(
    (isDirty: boolean, hourlyRates: HourlyRateSuggestion[]) => {
      if (isDirty) {
        setUpdatedHourlyRateSuggestions(hourlyRates);
      } else {
        setUpdatedHourlyRateSuggestions(null);
      }
    },
    [setUpdatedHourlyRateSuggestions]
  );

  const onNewSuggestion = async (newSuggestion: HourlyRateSuggestion) => {
    const { data } = await apiCreateHourlyRateSuggestion(newSuggestion);
    if (data) {
      setHourlyRateSuggestions([...hourlyRateSuggestions, data]);
      if (updatedHourlyRateSuggestions)
        setUpdatedHourlyRateSuggestions([
          ...updatedHourlyRateSuggestions,
          data,
        ]);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.createHourlyRateError'),
      });
    }
  };

  const onDelete = async (hourlyRateSuggestionId: HourlyRateSuggestionId) => {
    setHourlyRateSuggestions(
      hourlyRateSuggestions.filter(
        (hourlyRateSuggestion) =>
          hourlyRateSuggestion.hourlyRateSuggestionId !== hourlyRateSuggestionId
      )
    );
    const { result } = await apiDeleteHourlyRateSuggestion(
      hourlyRateSuggestionId
    );

    if (result.status >= 200 && result.status < 300) {
    } else {
      setHourlyRateSuggestions(hourlyRateSuggestions);
      notification.open({
        message: t('common:error'),
        description: t('settings:errorMessages.deleteHourlyRateError'),
      });
    }
  };

  const onSave = async () => {
    setSaving(true);
    if (updatedHourlyRateSuggestions !== null) {
      await asyncForEach(
        updatedHourlyRateSuggestions,
        async (updatedHourlyRateSuggestion) => {
          const originalRateSuggestion: HourlyRateSuggestion =
            hourlyRateSuggestions.find(
              (suggestion) =>
                suggestion.hourlyRateSuggestionId ===
                updatedHourlyRateSuggestion.hourlyRateSuggestionId
            );
          if (!equals(updatedHourlyRateSuggestion, originalRateSuggestion)) {
            await saveUpdateHourlyRateSuggestion(
              updatedHourlyRateSuggestion,
              originalRateSuggestion
            );
          }
        }
      );
    }
    setSaving(false);
  };

  const saveUpdateHourlyRateSuggestion = async (
    updatedHourlyRateSuggestion: HourlyRateSuggestion,
    originalRateSuggestion: HourlyRateSuggestion
  ) => {
    const { data } = await apiUpdateHourlyRateSuggestion(
      updatedHourlyRateSuggestion,
      originalRateSuggestion
    );
    if (data) {
      setHourlyRateSuggestions([
        ...hourlyRateSuggestions.map((suggestion) =>
          suggestion.hourlyRateSuggestionId ===
          updatedHourlyRateSuggestion.hourlyRateSuggestionId
            ? data
            : suggestion
        ),
      ]);
    } else {
      notification.open({
        message: t('common:error'),
        description:
          t('settings:errorMessages.updateHourlyRateError') +
          ` (${updatedHourlyRateSuggestion.name})`,
      });
    }
  };

  if (hourlyRateSuggestions === null) {
    return (
      <div className={classes.spin}>
        <div className="prio-flex-center-center prio-flex-column prio-container-fullscreen-height">
          <PrioSpinner size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.column}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <HourlyRateSuggestionsTable
          className={classes.shadow}
          hourlyRateSuggestions={hourlyRateSuggestions}
          onIsDirtyChanged={onIsDirtyChangedHourlyRates}
          onNewSuggestion={onNewSuggestion}
          onDelete={onDelete}
        />

        <Flex.Row justifyContent="flex-end">
          <Button
            disabled={!updatedHourlyRateSuggestions || saving}
            onClick={onSave}
          >
            {t('common:actions.save')}
          </Button>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default HourlyRateSuggestionsPage;
