import { InternalJobTitleSuggestion } from '../../../models/InternalJobTitleSuggestion';
import { combineReducers, Reducer } from 'redux';
import {
  FetchInternalJobTitlesCommitAction,
  InternalJobTitleAction,
  FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT,
  UPDATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST,
  UPDATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT,
  UPDATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
  DELETE_INTERNAL_CONTACT_JOB_TITLE_REQUEST,
  DELETE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
  CREATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST,
  CREATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT,
  CREATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK,
  FETCH_INTERNAL_CONTACT_JOB_TITLES_REQUEST,
  FETCH_INTERNAL_CONTACT_JOB_TITLES_ROLLBACK,
} from '../actions/internalJobTitles';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface InternalJobTitlesState {
  byId: InternalJobTitlesByIdState;
  ids: string[];
  meta: InternalJobTitlesMeta;
}
export interface InternalJobTitlesByIdState {
  [internalJobTitleSuggestionId: string]: InternalJobTitleSuggestion;
}

const byId: Reducer<
  InternalJobTitlesByIdState,
  FetchInternalJobTitlesCommitAction & InternalJobTitleAction
> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT: {
      const { payload } = action;
      return (payload as InternalJobTitleSuggestion[]).reduce(function (
        map,
        item
      ) {
        map[item.internalJobTitleSuggestionId] = item;
        return map;
      },
      {});
    }
    case CREATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const {
        meta: { temporaryId },
        payload,
      } = action;
      return {
        ...state,
        [temporaryId]: {
          internalJobTitleSuggestionId: temporaryId,
          rowVersion: null,
          ...payload,
        },
      };
    }
    case CREATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT: {
      const {
        payload,
        payload: { internalJobTitleSuggestionId },
      } = action;
      if (state[internalJobTitleSuggestionId]) return state;
      return {
        ...state,
        [internalJobTitleSuggestionId]: payload,
      };
    }
    case UPDATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT:
    case UPDATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const { payload } = action;
      const updatedInternalJobTitle = payload as InternalJobTitleSuggestion;
      return {
        ...state,
        [updatedInternalJobTitle.internalJobTitleSuggestionId]: updatedInternalJobTitle,
      };
    }
    case UPDATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK: {
      const { rollbackInternalJobTitle } = action;
      return {
        ...state,
        [rollbackInternalJobTitle.internalJobTitleSuggestionId]: rollbackInternalJobTitle,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const ids: Reducer<
  Array<string>,
  FetchInternalJobTitlesCommitAction & InternalJobTitleAction
> = (state = [], action) => {
  switch (action.type) {
    case FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT: {
      const { payload } = action;
      return payload.map((item) => item.internalJobTitleSuggestionId);
    }
    case CREATE_INTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }
    case CREATE_INTERNAL_CONTACT_JOB_TITLE_COMMIT: {
      const {
        meta: { temporaryId },
        payload: { internalJobTitleSuggestionId },
      } = action;
      if (state.includes(internalJobTitleSuggestionId)) return state;
      return [
        ...state.filter((id) => id !== temporaryId),
        internalJobTitleSuggestionId,
      ];
    }
    case CREATE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return state.filter((id) => id !== temporaryId);
    }
    case DELETE_INTERNAL_CONTACT_JOB_TITLE_REQUEST: {
      const {
        meta: { internalJobTitleSuggestionId },
      } = action;
      return state.filter((id) => id !== internalJobTitleSuggestionId);
    }
    case DELETE_INTERNAL_CONTACT_JOB_TITLE_ROLLBACK: {
      const {
        meta: { internalJobTitleSuggestionId },
      } = action;
      if (state.includes(internalJobTitleSuggestionId)) return state;
      return [...state, internalJobTitleSuggestionId];
    }

    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

export interface InternalJobTitlesMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<InternalJobTitlesMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_INTERNAL_CONTACT_JOB_TITLES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_INTERNAL_CONTACT_JOB_TITLES_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_INTERNAL_CONTACT_JOB_TITLES_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.fetchInternalJobTitlesError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<InternalJobTitlesState>({
  byId,
  ids,
  meta,
});

export const getInternalJobTitles: (
  state: InternalJobTitlesState
) => InternalJobTitleSuggestion[] = (state) =>
  state.ids.map((id) => state.byId[id]);

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;