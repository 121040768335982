import { fetchMailSearchProjects } from '../projects/search';
import { fetchMailSearchMe } from '../me/searchMe';
import { PRIO } from '../../../../constants';
import { AdvancedMailSearchDto } from '../../../../models/MailSearch';
import { MailFolderId } from '../../../../models/Types';

export const fetchMailSearch = (
  searchTerm: AdvancedMailSearchDto | string,
  projectId: string,
  mailFolderId?: MailFolderId,
  nextLink?: string
) => {
  if (projectId === 'me') {
    return fetchMailSearchMe(searchTerm, mailFolderId, nextLink);
  }
  return fetchMailSearchProjects(searchTerm, projectId, mailFolderId, nextLink);
};

export const DELETE_MAIL_SEARCH_RESULTS_PROJECT =
  PRIO + 'DELETE_MAIL_SEARCH_RESULTS_PROJECT';
export const DELETE_MAIL_SEARCH_RESULTS_ME =
  PRIO + 'DELETE_MAIL_SEARCH_RESULTS_ME';

export const deleteMailSearchResults = (isMe?: boolean) => ({
  type: isMe
    ? DELETE_MAIL_SEARCH_RESULTS_ME
    : DELETE_MAIL_SEARCH_RESULTS_PROJECT,
});

export const SET_CURRENT_MAIL_SEARCH_PROJECT =
  PRIO + 'SET_CURRENT_MAIL_SEARCH_PROJECT';
export const SET_CURRENT_MAIL_SEARCH_ME = PRIO + 'SET_CURRENT_MAIL_SEARCH_ME';

export const setCurrentMailSearch = (
  searchTerm: AdvancedMailSearchDto | string,
  projectId: string,
  mailFolderId: MailFolderId,
  nextLink?: string
) => ({
  type:
    projectId === 'me'
      ? SET_CURRENT_MAIL_SEARCH_ME
      : SET_CURRENT_MAIL_SEARCH_PROJECT,
  searchTerm,
  projectId,
  nextLink,
  mailFolderId,
});

export const SAVE_LAST_SEARCH_MAIL_PROJECT =
  PRIO + 'SAVE_LAST_SEARCH_MAIL_PROJECT';

export const SAVE_LAST_SEARCH_MAIL_ME = PRIO + 'SAVE_LAST_SEARCH_MAIL_ME';

export const saveLastSearchMail = (
  searchTerm: AdvancedMailSearchDto | string,
  projectId: string,
  mailFolderId: MailFolderId,
  nextLink?: string
) => ({
  type:
    projectId === 'me'
      ? SAVE_LAST_SEARCH_MAIL_ME
      : SAVE_LAST_SEARCH_MAIL_PROJECT,
  searchTerm,
  projectId,
  nextLink,
  mailFolderId,
});
