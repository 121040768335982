import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconForAbsenceType } from '../../../../util/icon';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import moment from 'moment';
import { generateMultipleDaysAbsenceTitle } from '../../../timeAndLeaveManagement/components/Calendar/MonthEventComponent';

interface ComponentProps {
  className?: string;
  absenceProposal: AbsenceProposal;
  isHrContext?: boolean;
}

export const Component: React.FC<ComponentProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, absenceProposal, isHrContext } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Components
  const content = useMemo(() => {
    const ignoreAbsenceType =
      !isHrContext && absenceProposal?.absenceType !== 'annualLeavePlanning';
    if (moment(absenceProposal.from).isSame(absenceProposal.to, 'day')) {
      return `${
        ignoreAbsenceType
          ? ''
          : `${t(
              `absences:form.absenceTypes.${absenceProposal?.absenceType}`
            )}, `
      }${moment(absenceProposal.from).format('DD.MM.YYYY')} ${
        absenceProposal.firstIsHalfDay === true
          ? `(${t('timeAndLeaveManagement:calendar.events.firstHalfOfDay')})`
          : absenceProposal.lastIsHalfDay === true
          ? `(${t('timeAndLeaveManagement:calendar.events.secondHalfOfDay')})`
          : ''
      }`;
    }
    return generateMultipleDaysAbsenceTitle(
      absenceProposal,
      !isHrContext && absenceProposal?.absenceType !== 'annualLeavePlanning'
    );
  }, [absenceProposal, isHrContext, t]);
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={className}
      style={{
        flex: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      }}
    >
      <FontAwesomeIcon
        icon={['fal', getIconForAbsenceType(absenceProposal.absenceType)]}
      />
      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default Component;
