import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Tooltip } from '@prio365/prio365-react-library';
import { ProjectId } from '../../../../models/Types';
import { useDispatch } from 'react-redux';

import { Message } from '../../../../models/Message';
import ContactPicker from '../../../contacts/components/ContactPicker';
import { message } from 'antd';
import { apiAssignMessages } from '../../api';
import { updateMessages } from '../../actions/actionControllers/messageActionController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AssignMailsButtonProps {
  projectId: ProjectId;
  selectedMessages: Message[];
}

export const AssignMailsButton: React.FC<AssignMailsButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { selectedMessages, projectId } = props;

  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const { mailFolderId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const onAssign = async () => {
    setVisible(true);
  };

  const handleOk = async (contactId) => {
    if (!mailFolderId || !(selectedMessages?.length > 0)) return;
    setLoading(true);
    const messageIds = selectedMessages.map((m) => m.id);
    const { result } = await apiAssignMessages(
      projectId,
      messageIds,
      contactId
    );

    if (result.status >= 200 && result.status < 300) {
      const updates = messageIds.map((id) => {
        const message = selectedMessages.find((m) => m.id === id);
        return {
          messageId: id,
          messageUpdate: {
            customSingleValueExtendedProperties: {
              ...message?.customSingleValueExtendedProperties,
              messageAssignmentId: contactId || null,
            },
          },
        };
      });

      dispatch(updateMessages(projectId, messageIds, updates));
      setLoading(false);
    } else {
      message.error(t('mail:navigationBar.assignModal.error'));
    }
    value && setValue('');
    setVisible(false);
  };
  const onClose = () => {
    setVisible(false);
  };
  //#endregion

  //#region ------------------------------ Components
  const wrapTooltip = (children: ReactElement, title: ReactNode) => {
    return title ? (
      <Tooltip overlay={title} placement="bottom">
        {children}
      </Tooltip>
    ) : (
      children
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (selectedMessages.length > 0) {
      const firstMessageAssignmentId =
        selectedMessages[0]?.customSingleValueExtendedProperties
          ?.messageAssignmentId;
      const allSame = selectedMessages.every(
        (m) =>
          m.customSingleValueExtendedProperties?.messageAssignmentId ===
            firstMessageAssignmentId ||
          m.customSingleValueExtendedProperties?.messageAssignmentId === null
      );

      if (allSame && firstMessageAssignmentId) {
        setValue(firstMessageAssignmentId);
      } else {
        setValue(null);
      }
    }
  }, [selectedMessages]);
  //#endregion

  return (
    <>
      {wrapTooltip(
        <Button
          type="default"
          onClick={onAssign}
          iconProp={['fal', 'user-plus']}
          disabled={selectedMessages.length > 200}
        >
          <span>{t('mail:navigationBar.assignMessages')}</span>
        </Button>,
        selectedMessages.length > 200
          ? t('mail:navigationBar.toManySelected')
          : undefined
      )}
      <Modal
        visible={visible}
        title={t('mail:navigationBar.assignModal.title')}
        onClose={onClose}
        onOk={() => handleOk(value)}
        okText={t('mail:navigationBar.assignModal.okText')}
        cancelText={t('mail:navigationBar.assignModal.cancelText')}
        destroyOnClose
        okButtonProps={{ loading: loading }}
      >
        <div style={{ display: 'flex', gap: '14px', alignItems: 'center' }}>
          <ContactPicker
            style={{ width: '91%' }}
            projectId={projectId}
            onlyInternalProject
            multiple={false}
            onChange={(value) => setValue(value as string)}
            value={value}
          />
          <FontAwesomeIcon
            icon={['fal', 'xmark']}
            onClick={() => setValue('')}
            style={{ cursor: 'pointer', fontSize: '18px' }}
          />
        </div>
        {!value && (
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              marginTop: '8px',
            }}
          >
            <FontAwesomeIcon
              icon={['fal', 'info-circle']}
              style={{ margin: 'auto 0' }}
            />
            <span>{t('mail:navigationBar.assignModal.info')}</span>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AssignMailsButton;
