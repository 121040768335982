import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import { MailCategoryColorName } from '../../../../models/Types';
import { FETCH_MAIL_ME_CATEGORIES_COMMIT } from '../../actions/me/categoriesMe';
import { MailMeCategoryAction } from '../../actions/types';

export interface CategoryState {
  [displayName: string]: MailCategoryColorName;
}

const categories: Reducer<CategoryState, MailMeCategoryAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case FETCH_MAIL_ME_CATEGORIES_COMMIT: {
      const { payload } = action;
      return payload.reduce(function (map, item) {
        map[item.displayName] = item.color;
        return map;
      }, {});
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export default categories;

export const getColorForMessageCategory = (
  state: CategoryState,
  displayName: string
) => state[displayName];

export const getMessageCategoryColorMap = (state: CategoryState) => state;
