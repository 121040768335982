import React, { useState } from 'react';
import { CreateInvoice } from '../../../models/Accounting';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiUpdateInvoice } from '../api';
import { OfficeId, ProjectId } from '../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import {
  InvoicesSearchResult,
  InvoicesSearchResultCalculatedData,
  SearchResultInvoicesPage,
} from './InvoicesTableNew';
import InvoiceDetailsPaymentsNew from './InvoiceDetailsPaymentsNew';
import InvoiceFormNew from './InvoiceFormNew';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  value: {
    textAlign: 'right',
    flex: 1,
  },
  first1: {
    height: '100%',
  },
  warning: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface InvoiceDetailsProps {
  invoice: SearchResultInvoicesPage;
  reloadInvoices: VoidFunction;
  officeId?: OfficeId;
  projectId?: ProjectId;
  handleDrawerClose: () => void;
  userMeHasOfficeControllerRole: boolean;
}

type TabKeys = 'payments' | 'invoice';

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const {
    invoice,
    reloadInvoices,
    projectId,
    officeId,
    handleDrawerClose,
    userMeHasOfficeControllerRole,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [activeTabKey, setActiveTabKey] = useState<TabKeys>('payments');

  const tabsChange = (activeKey: TabKeys) => setActiveTabKey(activeKey);

  const invoiceComplete = !!invoice?.calculated?.currency;

  const { optimisticWrite } = useFilterContext<
    InvoicesSearchResult,
    InvoicesSearchResultCalculatedData
  >();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleInvoiceUpdate = (invoiceUpdate: CreateInvoice) => {
    optimisticWrite([
      {
        data: invoice.data,
        method: 'update',
        callback: async () => {
          const { result, data } = await apiUpdateInvoice(
            invoice.data.invoiceId,
            invoiceUpdate,
            userMeHasOfficeControllerRole ? officeId : undefined,
            projectId
          );
          if (result.ok) {
            return {
              result,
              data: {
                data: {
                  ...invoice.data,
                  ...data,
                },
                calculated: {
                  ...invoice.calculated,
                  currency: data.netSum.isoCode,
                  paidBalance: data.invoicePayments.reduce(
                    (sum, payment) => sum + payment.debitSum.value,
                    0
                  ),
                  netTotalSum: data.invoicePositions.reduce(
                    (sum, payment) =>
                      sum + payment.pricePerUnit.value * payment.amount,
                    0
                  ),
                  grossTotalSum: data.invoicePositions.reduce(
                    (sum, payment) =>
                      sum +
                      ((payment.pricePerUnit.value * (100 + payment.vat)) /
                        100) *
                        payment.amount,
                    0
                  ),
                },
              },
            };
          } else {
            return { result, data: null };
          }
        },
      },
    ]);
    handleDrawerClose();
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classes.first1}>
      <Flex.Column
        childrenGap={theme.old.spacing.unit(2)}
        className={classes.root}
      >
        <Typography.Title
          level={2}
          style={{ fontWeight: theme.old.typography.fontWeight.regular }}
        >
          <FontAwesomeIcon icon={['fal', 'file']} />
          <span> </span>
          <span>{invoice.data.number}</span>
        </Typography.Title>
        {invoiceComplete ? (
          <Tabs
            animated={false}
            defaultActiveKey="payments"
            activeKey={activeTabKey}
            onChange={tabsChange}
            className={classes.root}
          >
            <TabPane
              tab={t('accounting:invoiceDetails.tabs.payments')}
              key="payments"
              className={classes.root}
            >
              <InvoiceDetailsPaymentsNew
                invoice={invoice}
                reloadInvoices={reloadInvoices}
                officeId={officeId}
                projectId={projectId}
                handleDrawerClose={handleDrawerClose}
              />
            </TabPane>
            <TabPane
              tab={t('accounting:invoiceDetails.tabs.invoice')}
              key="invoice"
            >
              <InvoiceFormNew
                actionLabel={t('accounting:invoiceForm.actions.update')}
                initialValues={invoice}
                onFinish={handleInvoiceUpdate}
                isoCode={invoice?.calculated?.currency}
                projectId={projectId}
                officeId={officeId}
              />
            </TabPane>
          </Tabs>
        ) : (
          <div className={classes.warning}>
            <div>{t('accounting:invoiceDetails.notCompleted1')}</div>
            <div>{t('accounting:invoiceDetails.notCompleted2')}</div>
          </div>
        )}
      </Flex.Column>
    </div>
  );
};

export default InvoiceDetails;
