import { apiUrl } from '../../../../api';
import { PRIO } from '../../../../constants';
import { AdvancedMailSearchDto } from '../../../../models/MailSearch';
import { MailFolderId, ProjectId } from '../../../../models/Types';
import equals from 'deep-equal';

export const FETCH_MAIL_SEARCH_PROJECT_REQUEST =
  PRIO + 'FETCH_MAIL_SEARCH_PROJECT_REQUEST';
export const FETCH_MAIL_SEARCH_PROJECT_COMMIT =
  PRIO + 'FETCH_MAIL_SEARCH_PROJECT_COMMIT';
export const FETCH_MAIL_SEARCH_PROJECT_ROLLBACK =
  PRIO + 'FETCH_MAIL_SEARCH_PROJECT_ROLLBACK';

export const fetchMailSearchProjects = (
  searchTerm: AdvancedMailSearchDto | string,
  projectId: ProjectId,
  mailFolderId?: MailFolderId,
  nextLink?: string
) => {
  const isString: boolean = typeof searchTerm === 'string';
  const url: string = isString
    ? `${apiUrl}/email/Email/project/${projectId}/${
        mailFolderId ? `mailFolder/${mailFolderId}/` : ''
      }message?search=${searchTerm}${nextLink ? `&nextLink=${nextLink}` : ''}`
    : `${apiUrl}/email/Email/project/${projectId}/${
        mailFolderId ? `mailFolder/${mailFolderId}/` : ''
      }message/search${
        equals(searchTerm, {})
          ? ''
          : `?${Object.keys(searchTerm)
              .filter(
                (key) =>
                  !(searchTerm[key] === undefined || searchTerm[key] === null)
              )
              .map(
                (key) =>
                  `${key}=${
                    Array.isArray(searchTerm[key])
                      ? searchTerm[key].join(',')
                      : searchTerm[key]
                  }`
              )
              .join('&')}`
      }${
        nextLink
          ? `${equals(searchTerm, {}) ? '?' : '&'}nextLink=${nextLink}`
          : ''
      }`;
  return {
    type: FETCH_MAIL_SEARCH_PROJECT_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url,
          method: 'GET',
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: FETCH_MAIL_SEARCH_PROJECT_COMMIT,
          meta: { searchTerm, projectId, nextLink, mailFolderId },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: FETCH_MAIL_SEARCH_PROJECT_ROLLBACK,
          meta: { searchTerm, projectId, nextLink, mailFolderId },
          snackbarErrorMessage: {
            label: 'mail:errorMessages.searchMessages.fetchMessagesError',
            timeout: 6,
          },
        },
      },
      searchTerm,
      projectId,
      nextLink,
      mailFolderId,
    },
  };
};
