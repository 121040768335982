import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import { HRModuleNavigation } from '../hr/components/HRModuleNavigation';
import { HRModule } from '../hr/components/HRModule';
import GlobalSider from '../../components/GlobalSider';
import { useTranslation } from 'react-i18next';
import Flex from '../../components/Flex';
import GlobalNavigationBar from '../../components/GlobalNavigationBar';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../theme/utils';
import classNames from 'classnames';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  navigation: {
    height: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  content: {
    flex: 2,
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
}));

export const HR: React.FC<{}> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classNames('prio-deprecated-design', classes.root)}>
      <GlobalSider
        title={t('hr:module')}
        selectedMainMenuItem={'hr'}
        subMenu={
          <Routes>
            <Route
              path="*"
              element={
                <HRModuleNavigation className={classes.navigation} isSubMenu />
              }
            />
            <Route
              path=":selectedList/*"
              element={
                <HRModuleNavigation className={classes.navigation} isSubMenu />
              }
            />
            <Route
              path="office/:officeId/:selectedList/*"
              element={
                <HRModuleNavigation className={classes.navigation} isSubMenu />
              }
            />
          </Routes>
        }
      >
        <Routes>
          <Route
            path="*"
            element={<HRModuleNavigation className={classes.navigation} />}
          />
          <Route
            path=":selectedList/*"
            element={<HRModuleNavigation className={classes.navigation} />}
          />
          <Route
            path="office/:officeId/:selectedList/*"
            element={<HRModuleNavigation className={classes.navigation} />}
          />
        </Routes>
      </GlobalSider>
      <Flex.Column flex={1} className={classes.fullHeight}>
        <GlobalNavigationBar module="hr" />
        <div className={classes.content}>
          <HRModule />
        </div>
      </Flex.Column>
    </div>
  );
};

export default HR;
