import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Dropdown, Menu, Table } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProjectFileSystemStructureIsFetching,
  getProjectFileSystemStructures,
} from '../../../apps/main/rootReducer';
import { ProjectFileSystemStructure } from '../../../models/Settings';
import {
  deleteProjectFileSystemStructure,
  fetchProjectFileSystemStructures,
} from '../actions/projectFileSystemStructure';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makePrioStyles } from '../../../theme/utils';
import { tableTranslations } from '../../../util/table';
import { MENU_BUTTON_SIZE } from '../../../constants';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  menuColum: {
    padding: '0!important',
    width: MENU_BUTTON_SIZE,
  },
  menuButton: {
    height: MENU_BUTTON_SIZE,
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
}));

interface ProjectFileSystemStructureTableProps {
  className?: string;
}

export const ProjectFileSystemStructureTable: React.FC<
  ProjectFileSystemStructureTableProps
> = (props) => {
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchProjectFileSystemStructures());
  }, [dispatch]);

  const projectFileSystemStructures: ProjectFileSystemStructure[] = useSelector(
    getProjectFileSystemStructures
  );

  const isFetching = useSelector(getProjectFileSystemStructureIsFetching);

  const menu = (record: ProjectFileSystemStructure) => (
    <Menu>
      <Menu.Item
        onClick={(e) => {
          e.domEvent.stopPropagation();
          dispatch(
            deleteProjectFileSystemStructure(
              record.projectFileSystemStructureId,
              record
            )
          );
        }}
      >
        {t('settings:projectFileSystemStructureTable.tableActions.delete')}
      </Menu.Item>
    </Menu>
  );

  const columns: ColumnProps<ProjectFileSystemStructure>[] = [
    {
      width: 40,
      align: 'center',
      render: (_, record) => (
        <Link to={record.projectFileSystemStructureId}>
          <FontAwesomeIcon icon={['fal', 'folder-tree']} />
        </Link>
      ),
    },
    {
      width: 250,
      title: t('settings:projectFileSystemStructureTable.columnTitles.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      render: (value, record) => (
        <Link to={record.projectFileSystemStructureId}>{value}</Link>
      ),
    },
    {
      render: (_, record) => {
        return (
          <Dropdown
            overlay={menu(record)}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button
              type="default"
              iconProp={['fal', 'ellipsis-v']}
              className={classes.menuButton}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Dropdown>
        );
      },
      className: classes.menuColum,
      width: theme.old.components.table.iconColumn.width,
    },
  ];

  return (
    <Table
      className={classNames(classes.root, className)}
      dataSource={projectFileSystemStructures}
      columns={columns}
      rowKey={(record) => record.projectFileSystemStructureId}
      rowSelection={{
        type: 'checkbox',
      }}
      locale={tableTranslations(t)}
      loading={{
        spinning: isFetching && projectFileSystemStructures.length === 0,
        indicator: <PrioSpinner alignSelf />,
      }}
    />
  );
};

export default ProjectFileSystemStructureTable;
