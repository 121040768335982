import { Reducer } from 'react';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  SET_LAST_ACCOUNTING_LOCATION,
  LastAccountingLocation,
} from '../actions';

const reducer: Reducer<string, LastAccountingLocation> = (
  state = null,
  action
) => {
  switch (action.type) {
    case SET_LAST_ACCOUNTING_LOCATION: {
      return action.accountingLocation;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

export const getLastAccountingLocation = (state: string) => state;

export default reducer;
