export interface FluentValidationError {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: {};
}

export const parseErrorObjectToString = (errorObject) => {
  let errorString = '';
  for (let key in errorObject) {
    errorString += errorObject[key].join(' ');
  }
  return errorString;
};

export interface EMailValidationResult {
  validEmail: boolean;
}

export interface ProjectNumberValidationResult {
  isValid: boolean;
}
