import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { colon } from '../../../../util/forms';
import { useTranslation } from 'react-i18next';
import useDatePickerLocale from '../../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import { emailPattern } from '../../../../hooks/useEmailValidation';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
}));

export type LeavingReason =
  | 'layoffByEmployer'
  | 'quittingByEmployee'
  | 'terminationAgreement'
  | 'temporaryEmployment'
  | 'death';

export interface OffBoardEmployee {
  leavingDate?: Moment;
  leavingReason?: LeavingReason;
  lockAccountDate?: Moment;
  forwardEmailsTo?: string;
  attachment?: FileList;
}

interface OffBoardingModalProps {
  open: boolean;
  offBoardEmployee: (data: OffBoardEmployee) => Promise<boolean>;
  onClose: VoidFunction;
}

export const OffBoardingModal: React.FC<OffBoardingModalProps> = (props) => {
  const classes = useStyles();

  const { open, offBoardEmployee, onClose } = props;

  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const [form] = Form.useForm<OffBoardEmployee>();

  useEffect(() => {
    form.setFieldsValue({ lockAccountDate: moment() });
  });

  const datePickerLocale: PickerLocale = useDatePickerLocale();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        setSubmitting(true);
        const result = await offBoardEmployee(values);
        setSubmitting(false);
        if (result) {
          form.resetFields();
          onClose();
        }
      }
    } catch {}
  };

  const disableForm = submitting;

  return (
    <Modal
      title={t('hr:offBoarding.modal.title')}
      visible={open}
      onOk={handleOk}
      confirmLoading={submitting}
      onCancel={onClose}
      okText={t('hr:offBoarding.modal.okText')}
      cancelText={t('hr:offBoarding.modal.cancelText')}
    >
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('hr:offBoarding.modal.form.labels.leavingDate')}
              name="leavingDate"
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:offBoarding.modal.form.validation.missing.leavingDate'
                  ),
                },
              ]}
            >
              <DatePicker
                disabled={disableForm}
                locale={datePickerLocale}
                format="DD.MM.YYYY"
                suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                className={classes.fullWidth}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('hr:offBoarding.modal.form.labels.leavingReason')}
              name="leavingReason"
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:offBoarding.modal.form.validation.missing.leavingReason'
                  ),
                },
              ]}
            >
              <Select<LeavingReason> disabled={disableForm}>
                <Select.Option value="layoffByEmployer">
                  {t(
                    'hr:offBoarding.modal.form.labels.leavingReasons.layoffByEmployer'
                  )}
                </Select.Option>
                <Select.Option value="quittingByEmployee">
                  {t(
                    'hr:offBoarding.modal.form.labels.leavingReasons.quittingByEmployee'
                  )}
                </Select.Option>
                <Select.Option value="terminationAgreement">
                  {t(
                    'hr:offBoarding.modal.form.labels.leavingReasons.terminationAgreement'
                  )}
                </Select.Option>
                <Select.Option value="temporaryEmployment">
                  {t(
                    'hr:offBoarding.modal.form.labels.leavingReasons.temporaryEmployment'
                  )}
                </Select.Option>
                <Select.Option value="death">
                  {t('hr:offBoarding.modal.form.labels.leavingReasons.death')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('hr:offBoarding.modal.form.labels.lockAccountDate')}
              name="lockAccountDate"
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t(
                    'hr:offBoarding.modal.form.validation.missing.lockAccountDate'
                  ),
                },
              ]}
            >
              <DatePicker
                disabled={disableForm}
                locale={datePickerLocale}
                format="DD.MM.YYYY"
                suffixIcon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} />}
                className={classes.fullWidth}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('hr:offBoarding.modal.form.labels.forwardEmailsTo')}
              name="forwardEmailsTo"
              colon={colon}
              rules={[
                {
                  pattern: emailPattern,
                  message: t(
                    'hr:offBoarding.modal.form.validation.invalidEmail'
                  ),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t('hr:offBoarding.modal.form.labels.attachment')}
              name="attachment"
              colon={colon}
              valuePropName="files"
            >
              <Input type="file" disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default OffBoardingModal;
