import React, { useState } from 'react';
import { Form, notification } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import AddEmployeeForm from './AddEmployeeForm';
import { AddEmployee } from '../../../models/Employee';
import { apiSubmitEmployeeOnboarding } from '../../employee/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Button, Drawer } from '@prio365/prio365-react-library';

interface AddEmployeeDrawerProps {
  onDrawerClose: VoidFunction;
  isDrawerOpen: boolean;
}
const useStyles = makePrioStyles((theme) => ({
  root: {},
  noPadding: {
    padding: 0,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  importUser: {
    height: '100%',
  },
  importAadUser: {
    flex: 1,
  },
  title: {
    paddingBottom: theme.old.spacing.unit(2),
    marginTop: -theme.old.spacing.unit(1),
  },
  scrollabe: {
    flex: 1,
    overflow: 'auto',
  },
  drawer: {
    zIndex: 999,
  },
  customDrawerContent: {
    paddingBottom: 0,
  },
}));

export const AddEmployeeDrawer: React.FC<AddEmployeeDrawerProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { onDrawerClose, isDrawerOpen } = props;
  const [addEmployeeForm] = Form.useForm<AddEmployee>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [disableActionButton, setDisableActionButton] = useState<boolean>(true);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const submitEmployeeOnboarding: (
    addEmployee: AddEmployee
  ) => Promise<boolean> = async (addEmployee) => {
    const { data } = await apiSubmitEmployeeOnboarding(
      addEmployee,
      addEmployee.officeId
    );
    if (data) {
      onDrawerClose();
      notification.open({
        message: t('common:success'),
        description: t(
          'hr:addEmployeeDrawer.successMessages.submitEmployeeOnboardingSuccess'
        ),
        duration: 15,
      });
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'hr:addEmployeeDrawer.errorMessages.submitEmployeeOnboardingError'
        ),
      });
      return false;
    }
  };

  const onDrawerSave = () => {
    addEmployeeForm.submit();
  };

  const onDrawerCancel = () => {
    onDrawerClose();
    addEmployeeForm.resetFields();
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Drawer Components
  const drawerContent = () => {
    return (
      <AddEmployeeForm
        form={addEmployeeForm}
        actionLabel={t('hr:addEmployeeDrawer.actions.submitForm')}
        onFinishCreate={submitEmployeeOnboarding}
        setDisableActionButton={setDisableActionButton}
      />
    );
  };

  const drawerFooter = () => {
    return (
      <Flex.Row justifyContent="flex-end">
        <Button
          type="default"
          onClick={onDrawerCancel}
          style={{ marginRight: theme.old.spacing.unit(1) }}
        >
          {t('common:actions.cancel')}
        </Button>
        <Button
          type="primary"
          onClick={onDrawerSave}
          htmlType="submit"
          disabled={disableActionButton}
        >
          {t('common:save')}
        </Button>
      </Flex.Row>
    );
  };
  //#endregion

  return (
    <Drawer
      title={t('hr:addEmployeeDrawer.title')}
      onClose={onDrawerCancel}
      visible={isDrawerOpen}
      children={drawerContent()}
      footer={drawerFooter()}
      contentClassName={classes.customDrawerContent}
    />
  );
};

export default AddEmployeeDrawer;
