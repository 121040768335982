import React from 'react';
import { Card, Skeleton } from 'antd';

export const CompanyCardSkeleton: React.FC = () => {
  return (
    <Card title={<Skeleton />}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Card>
  );
};

export default CompanyCardSkeleton;
