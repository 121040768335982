import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loggedIn, setIsInitialDataLoading } from './actions';
import { fetchUserRegistrationState } from '../users/actions/registrationState';
import { useMsal } from '@azure/msal-react';
import * as ConfigValues from '../../util/configValues';
import * as microsoftTeams from '@microsoft/teams-js';
import {
  AccountInfo,
  AuthError,
  PublicClientApplication,
} from '@azure/msal-browser';
import { isLoggedIn } from '../../apps/main/rootReducer';
import equals from 'deep-equal';

interface LoginMessage {
  message: string;
  origin: string;
}

interface LoginErrorMessage {
  error: number;
}

export interface Auth {
  error: string;
  onSignIn: any;
  onSignOut: any;
  [propertyName: string]: any;
}

const useAuth: (
  isOfficeContext: boolean,
  isTeamsContext: boolean,
  instance
) => Auth = (
  isOfficeContext: boolean,
  isTeamsContext: boolean,
  instance: PublicClientApplication
) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string>(null);
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const isLoggedInSelector: boolean = useSelector(isLoggedIn);
  const accountInfo = instance.getActiveAccount();

  const [account, setAccount] = useState<AccountInfo>(accountInfo);

  useEffect(() => {
    if (!equals(account, accountInfo)) {
      setAccount(accountInfo);
    }
  }, [accountInfo, account]);

  useEffect(() => {
    const effect = async () => {
      if (account) {
        instance
          .acquireTokenSilent({
            scopes: [`${ConfigValues.SCOPES}`],
            account: account,
          })
          .then((response) => {
            if (response) {
              loginProcess();
            }
          })
          .catch((err) => {
            if (!error) {
              setError(err);
              if (err instanceof AuthError) {
                instance
                  .acquireTokenPopup({
                    scopes: [`${ConfigValues.SCOPES}`],
                    account: account,
                  })
                  .then((accessTokenResponse) => {
                    if (accessTokenResponse) {
                      loginProcess();
                      setError(null);
                    }
                  })
                  .catch((errorErr) => {
                    setError(errorErr);
                  });
              }
            }
          });
      } else if (accounts?.length === 1) {
        // only one account logged in - login
        instance.setActiveAccount(accounts[0]);
        instance
          .acquireTokenSilent({
            scopes: [`${ConfigValues.SCOPES}`],
            account: account,
          })
          .then((response) => {
            if (response) {
              loginProcess();
            }
          })
          .catch((err) => {
            if (!error) {
              setError(err);
              if (err instanceof AuthError) {
                instance
                  .acquireTokenPopup({
                    scopes: [`${ConfigValues.SCOPES}`],
                    account: account,
                  })
                  .then((accessTokenResponse) => {
                    if (accessTokenResponse) {
                      loginProcess();
                      setError(null);
                    }
                  })
                  .catch((errorErr) => {
                    setError(errorErr);
                  });
              }
            }
          });
      } else {
        // nothing to do
      }

      function loginProcess() {
        if (!isLoggedInSelector) {
          dispatch(fetchUserRegistrationState());
          dispatch(setIsInitialDataLoading(true));
          dispatch(loggedIn());
          if (window.location.href.includes('/login')) {
            window.location.replace('/');
          }
        }
      }
    };
    effect();
  }, [
    dispatch,
    t,
    instance,
    account,
    accounts,
    isTeamsContext,
    isLoggedInSelector,
    error,
  ]);

  const onSignIn = useCallback(
    async (loginWithAccount) => {
      if (loginWithAccount) {
        instance.setActiveAccount(loginWithAccount);
        dispatch(fetchUserRegistrationState());
        dispatch(setIsInitialDataLoading(true));
        dispatch(loggedIn());
      } else {
        if (accounts === undefined || accounts.length === 0) {
          instance.loginPopup().then((currentAccount) => {
            instance.setActiveAccount(currentAccount.account);
            dispatch(fetchUserRegistrationState());
            dispatch(setIsInitialDataLoading(true));
            dispatch(loggedIn());
            if (window.location.href.includes('/login')) {
              window.location.replace('/');
            }
          });
        } else {
          instance.setActiveAccount(accounts[0]);
          instance
            .acquireTokenSilent({
              scopes: [`${ConfigValues.SCOPES}`],
              account: account,
            })
            .then((response) => {
              if (response) {
                dispatch(fetchUserRegistrationState());
                dispatch(setIsInitialDataLoading(true));
                dispatch(loggedIn());
              }
            })
            .catch((err) => {
              if (!error) {
                setError(err);
                if (err instanceof AuthError) {
                  instance
                    .acquireTokenPopup({
                      scopes: [`${ConfigValues.SCOPES}`],
                      account: account,
                    })
                    .then((accessTokenResponse) => {
                      if (accessTokenResponse) {
                        dispatch(fetchUserRegistrationState());
                        dispatch(setIsInitialDataLoading(true));
                        dispatch(loggedIn());
                        setError(null);
                      }
                    })
                    .catch((errorErr) => {
                      setError(errorErr);
                    });
                }
              }
            });
        }
      }
    },
    [setError, dispatch, account, accounts, instance, error]
  );

  const onSignInTeams = useCallback(async () => {
    const dialogLoginUrl: string =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      '/teams/redirect';
    microsoftTeams.authentication.authenticate({
      url: dialogLoginUrl,
      width: 600,
      height: 535,
      successCallback: () => {
        let newAccounts = instance.getAllAccounts();
        instance.setActiveAccount(newAccounts[0]);
        instance
          .acquireTokenSilent({
            scopes: [`${ConfigValues.SCOPES}`],
            account: account,
          })
          .then((response) => {
            if (response) {
              dispatch(fetchUserRegistrationState());
              dispatch(setIsInitialDataLoading(true));
              dispatch(loggedIn());
            }
          })
          .catch((err) => {
            if (!error) {
              setError(err);
              if (err instanceof AuthError) {
                instance
                  .acquireTokenPopup({
                    scopes: [`${ConfigValues.SCOPES}`],
                    account: account,
                  })
                  .then((accessTokenResponse) => {
                    if (accessTokenResponse) {
                      dispatch(fetchUserRegistrationState());
                      dispatch(setIsInitialDataLoading(true));
                      dispatch(loggedIn());
                      setError(null);
                    }
                  })
                  .catch((errorErr) => {
                    setError(errorErr);
                  });
              }
            }
          });
      },
      failureCallback: function (reason) {
        setError(reason);
      },
    });
  }, [setError, dispatch, account, instance, error]);

  const onSignInOffice = useCallback(async () => {
    let loginDialog: Office.Dialog;
    const dialogLoginUrl: string =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      '/office/redirect';

    await Office.context.ui.displayDialogAsync(
      dialogLoginUrl,
      { height: 40, width: 30 },
      (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          setError(`${result.error.code} ${result.error.message}`);
        } else {
          loginDialog = result.value;
          loginDialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            processLoginMessage
          );
        }
      }
    );

    const processLoginMessage = (args: LoginMessage | LoginErrorMessage) => {
      loginDialog.close();
      let newAccounts = instance.getAllAccounts();
      instance.setActiveAccount(newAccounts[0]);
      instance
        .acquireTokenSilent({
          scopes: [`${ConfigValues.SCOPES}`],
          account: account,
        })
        .then((response) => {
          if (response) {
            dispatch(fetchUserRegistrationState());
            dispatch(setIsInitialDataLoading(true));
            dispatch(loggedIn());
          }
        })
        .catch((err) => {
          if (!error) {
            setError(err);
            if (err instanceof AuthError) {
              instance
                .acquireTokenPopup({
                  scopes: [`${ConfigValues.SCOPES}`],
                  account: account,
                })
                .then((accessTokenResponse) => {
                  if (accessTokenResponse) {
                    dispatch(fetchUserRegistrationState());
                    dispatch(setIsInitialDataLoading(true));
                    dispatch(loggedIn());
                    setError(null);
                  }
                })
                .catch((errorErr) => {
                  setError(errorErr);
                });
            }
          }
        });
    };
  }, [setError, dispatch, account, instance, error]);

  const onSignOut = useCallback(async () => {
    instance.logoutRedirect();
  }, [instance]);

  return {
    error,
    onSignIn: isTeamsContext
      ? onSignInTeams
      : isOfficeContext
      ? onSignInOffice
      : onSignIn,
    onSignOut: onSignOut,
    account,
    accounts,
  };
};

export default useAuth;
