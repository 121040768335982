import { put } from 'redux-saga/effects';
import { WebSocketMessage } from '../../../models/WebSocket';
import { fetchNotificationsSaga } from '../actions/saga';

export declare type NotificationMessageTypes = 'created' | 'updated';

export function* handleNotificationMessage(message: WebSocketMessage) {
  switch (message.type) {
    case 'created':
      yield put(fetchNotificationsSaga());
      break;
    case 'updated':
      yield put(fetchNotificationsSaga());
      break;
  }
}
