import React, {
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  TimeRecord,
  TimeRecordCalculatedData,
  TimeRecordSearchResultItem,
} from '../../../models/TimeRecord';
import { formatMinutesFloat } from '../../../util';
import moment from 'moment';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import FilterResultNoItemsScreen from '../../../components/Filter/FilterResultNoItemsScreen';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { useExportTimeRecordsToCsv } from '../export';
import useProjectsContext from '../../projects/hooks/useProjectsContext';
import { sumUpDurationInMinutes } from '../util';
import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import { makePrioStyles } from '../../../theme/utils';
import { sortContactsHelper } from '../../contacts/utils';

const useStyles = makePrioStyles((theme) => ({
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
export interface TimeRecordsPageTableRef {
  fetchTimeRecords: () => void;
  timeRecords: TimeRecordSearchResultItem[];
}

interface TimeRecordsTableProps {
  className?: string;
  tableId: string;
  selectedTimeRecords: TimeRecord[];
  onRowClick?: (entry: TimeRecord) => void;
  onSelectionChange?: (items: TimeRecord[]) => void;
  onDelete?: () => void;
  setTimeRecords?: (value: TimeRecord[]) => void;
  setReportDrawerVisible?: (value: boolean) => void;
}

export const TimeRecordsPageTable = forwardRef(
  (props: TimeRecordsTableProps, ref: React.Ref<TimeRecordsPageTableRef>) => {
    //#region ------------------------------ Defaults

    const {
      className,
      tableId,
      selectedTimeRecords,
      onRowClick,
      onSelectionChange,
      onDelete,
      setTimeRecords,
      setReportDrawerVisible,
    } = props;
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { getContactById } = useContactsContext();
    const { getProjectById } = useProjectsContext();

    const { data, isLoading, fetchSearch } = useFilterContext<
      TimeRecord,
      TimeRecordCalculatedData
    >();

    const timeRecords = useMemo(
      () =>
        data?.items?.sort((a, b) =>
          moment(b.data.day).diff(moment(a.data.day))
        ) ?? [],
      [data?.items]
    );

    const _timeRecords: TimeRecord[] = useMemo(
      () => timeRecords.map((tr) => tr.data) ?? [],
      [timeRecords]
    );

    const _selectedTimeRecords: TimeRecordSearchResultItem[] = useMemo(
      () =>
        selectedTimeRecords.map((selectedTimeRecord) => {
          return timeRecords.find(
            (timeRecord) =>
              timeRecord.data.timeRecordId === selectedTimeRecord.timeRecordId
          );
        }) ?? [],
      [selectedTimeRecords, timeRecords]
    );
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnRowClick: (
      item: TimeRecordSearchResultItem
    ) => VirtualListItemOnRowProps = useCallback(
      (item) => {
        return {
          onClick: (e) => {
            onRowClick(item.data);
          },
        };
      },
      [onRowClick]
    );

    const handleOnSelectionChange: (
      items: TimeRecordSearchResultItem[]
    ) => void = useCallback(
      (items) => {
        onSelectionChange(items.map((item) => item.data));
      },
      [onSelectionChange]
    );

    const exportToCsv = useExportTimeRecordsToCsv();

    const createReport = () => {
      setReportDrawerVisible?.(true);
    };
    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<TimeRecordSearchResultItem>[] = useMemo(
      () => [
        {
          id: 'day',
          accessor: 'data.day',
          title: t('timeRecords:table.columnTitle.day'),
          width: 110,
          sortingFn: (rowA, rowB) =>
            rowA.data.day?.localeCompare(rowB.data.day),
          Cell: ({ originalData: { data } }) => (
            <TableCell>{moment(data.day).format('DD.MM.YYYY')}</TableCell>
          ),
          alignSelf: true,
        },
        {
          id: 'projectId',
          accessor: 'data.projectId',
          title: t('timeRecords:table.columnTitle.projectName'),

          width: 170,
          sortingFn: (rowA, rowB) =>
            getProjectById(rowA.data.projectId)?.number.localeCompare(
              getProjectById(rowB.data.projectId)?.number
            ),
          Cell: ({ originalData: { data } }) => {
            const project = getProjectById(data.projectId);
            return (
              <TableCell>
                {data.projectId && `${project?.number} ${project?.shortName}`}
              </TableCell>
            );
          },
          alignSelf: true,
        },
        {
          id: 'title',
          accessor: 'data.title',
          title: t('timeRecords:table.columnTitle.title'),
          width: 170,
          sortingFn: (rowA, rowB) =>
            rowA.data.title?.localeCompare(rowB.data.title),
          Cell: ({ originalData: { data } }) => (
            <TableCell>{data.title}</TableCell>
          ),
          alignSelf: true,
        },
        {
          id: 'durationInMinutes',
          accessor: 'data.durationInMinutes',
          title: t('timeRecords:table.columnTitle.duration'),

          width: 100,
          sortingFn: (rowA, rowB) =>
            sumUpDurationInMinutes(rowA.data.timeRecordEntries) -
            sumUpDurationInMinutes(rowB.data.timeRecordEntries),
          Cell: ({ originalData }) => (
            <TableCell>
              {formatMinutesFloat(
                sumUpDurationInMinutes(originalData.data.timeRecordEntries) ?? 0
              )}
            </TableCell>
          ),
          alignSelf: true,
        },
        {
          id: 'kilometerDistance',
          accessor: 'data.kilometerDistance',
          title: t('timeRecords:table.columnTitle.kilometerDistance'),

          width: 100,
          sortingFn: (rowA, rowB) =>
            rowA.data.kilometerDistance - rowB.data.kilometerDistance,
          Cell: ({ originalData: { data } }) => (
            <TableCell>{`${data.kilometerDistance} km`}</TableCell>
          ),
          alignSelf: true,
        },
        {
          id: 'projectPhase',
          accessor: 'calculated.projectPhase',
          title: t('timeRecords:table.columnTitle.projectPhase'),

          width: 140,
          sortingFn: (a, b) => {
            const phaseA = a.calculated.projectPhase || '';
            const phaseB = b.calculated.projectPhase || '';

            return phaseA.localeCompare(phaseB);
          },
          Cell: ({ originalData: { calculated } }) => (
            <TableCell>{calculated.projectPhase ?? '-'}</TableCell>
          ),
          alignSelf: true,
        },
        {
          id: 'contactId',
          accessor: 'data.contactId',
          title: t('timeRecords:table.columnTitle.contactName'),

          width: 160,
          sortingFn: (rowA, rowB) =>
            sortContactsHelper(
              getContactById(rowA.data.contactId),
              getContactById(rowB.data.contactId)
            ),
          Cell: ({ originalData: { data } }) => (
            <TableCell>
              {getContactById(data.contactId)?.firstName +
                ' ' +
                getContactById(data.contactId)?.lastName}
            </TableCell>
          ),
          alignSelf: true,
        },
        {
          id: 'invoiceNumber',
          accessor: 'data.invoiceNumber',
          title: t('timeRecords:table.columnTitle.invoiceNumber'),

          width: 100,
          sortingFn: (a, b) =>
            a.data.invoiceNumber?.localeCompare(b.data.invoiceNumber),
          Cell: ({ originalData: { data } }) => (
            <TableCell>{data.invoiceNumber ?? '-'}</TableCell>
          ),
          alignSelf: true,
        },
      ],
      [getContactById, t, getProjectById]
    );
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      setTimeRecords?.(_timeRecords);
    }, [_timeRecords, setTimeRecords]);

    useImperativeHandle(ref, () => ({
      fetchTimeRecords: () => {
        fetchSearch();
      },
      timeRecords: timeRecords,
    }));
    //#endregion

    return (
      <FilterContextVirtualTable<TimeRecordSearchResultItem>
        id={tableId}
        className={className}
        data={timeRecords}
        columns={columns}
        onRow={handleOnRowClick}
        onSelectionChange={handleOnSelectionChange}
        onCheckEquality={(a, b) => a.data.timeRecordId === b.data.timeRecordId}
        resizable="absolute"
        noItemsScreen={<FilterResultNoItemsScreen />}
        loading={
          isLoading && {
            type: 'noItems',
          }
        }
        rowsAreSelectable
        actionBarButtons={[
          {
            children: t(
              'timeRecords:table.actionBarActions.exportSelectedToCsv'
            ),
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(_selectedTimeRecords);
            },
          },
          {
            children: t('timeRecords:table.actionBarActions.exportToCSV'),
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(timeRecords);
            },
          },
          {
            children: t('timeRecords:table.actionBarActions.deleteSelected'),
            iconProp: ['fal', 'trash'],
            onClick: onDelete,
          },
          {
            children: t('timeRecords:table.actionBarActions.createReport'),
            iconProp: ['fal', 'file-excel'],
            onClick: createReport,
          },
        ]}
      />
    );
  }
);

export default TimeRecordsPageTable;

interface TableCellProps {
  children?: string;
}

export const TableCell: React.FC<TableCellProps> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  const classes = useStyles();
  //#endregion

  return (
    <div className={classes.tableCell} title={children}>
      {children}
    </div>
  );
};
