import React, { useState } from 'react';

import useDebounce from '../../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TextBlock } from '../../../models/TextBlock';
import { textBlockSelector } from '../../settings/selectors';
import TextBlocksTable from './TextBlocksTable';
import { fetchTextBlocks } from '../actions/textBlocks';
import { getTextBlocksIsFetching } from '../../../apps/main/rootReducer';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface TextBlocksModalProps {
  visible: boolean;
  insertTextBlocks: (textBlocks: TextBlock[]) => void;
  onCancelTextBlocks: () => void;
  popoverVisible?: boolean;
}

export const TextBlocksModal: React.FC<TextBlocksModalProps> = (props) => {
  const classes = useStyles();
  const { visible, onCancelTextBlocks, insertTextBlocks, popoverVisible } =
    props;
  const { t } = useTranslation();

  const debouncedVisible = useDebounce(visible, 10);

  const textBlocks: TextBlock[] = useSelector(textBlockSelector);
  const isFetching = useSelector(getTextBlocksIsFetching);
  const [selectedTextBlocks, setSelectedTextBlocks] = useState<TextBlock[]>([]);

  const handleInsert = () => {
    insertTextBlocks(selectedTextBlocks);
    setSelectedTextBlocks([]);
  };

  const handleCancel = () => {
    onCancelTextBlocks();
    setSelectedTextBlocks([]);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchTextBlocks());
  }, [dispatch]);

  return (
    <Modal
      title={t('mail:modals.textBlocks.title')}
      visible={debouncedVisible}
      okText={t('mail:modals.textBlocks.okText')}
      cancelText={t('mail:modals.textBlocks.cancelText')}
      onOk={handleInsert}
      onCancel={handleCancel}
      width={'50%'}
      className={classes.root}
    >
      <TextBlocksTable
        textBlocks={visible ? textBlocks : []}
        onRowSelectionChange={(selectedEntries) =>
          setSelectedTextBlocks(selectedEntries)
        }
        showPopover={popoverVisible}
        loading={isFetching && textBlocks.length === 0}
      />
    </Modal>
  );
};

export default TextBlocksModal;
