import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import { DispatchAction } from '../../../models/Redux';
import { SearchType } from '../types';

/** Searchable Filter Parameter fetching */
export const FETCH_SEARCH_CONFIG_REQUEST = PRIO + 'FETCH_SEARCH_CONFIG_REQUEST';
export const FETCH_SEARCH_CONFIG_COMMIT = PRIO + 'FETCH_SEARCH_CONFIG_COMMIT';
export const FETCH_SEARCH_CONFIG_ROLLBACK =
  PRIO + 'FETCH_SEARCH_CONFIG_ROLLBACK';

export const fetchSearchConfigBySearchType: (
  searchType: SearchType
) => DispatchAction<{ searchType: SearchType }> = (searchType: SearchType) => ({
  type: FETCH_SEARCH_CONFIG_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/search/Search/parameters?searchType=${searchType}`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_SEARCH_CONFIG_COMMIT,
        meta: { searchType },
      },

      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_SEARCH_CONFIG_ROLLBACK,
        snackbarErrorMessage: {
          label:
            'components:filter.messages.errorMessages.fetchSearchConfigError',
          timeout: 6,
        },
        meta: { searchType },
      },
    },
    searchType,
  },
});

export const SET_SEARCH_STRING = PRIO + 'SET_SEARCH_STRING';
export const setReduxSearchString = (
  context: string,
  searchString: string,
  officeId: string | null = null,
  projectId: string | null = null,
  path: string = ''
) => ({
  type: SET_SEARCH_STRING,
  payload: { context, searchString, officeId, projectId, path },
});

export const CLEAR_SEARCH_STRING = PRIO + 'CLEAR_SEARCH_STRING';
export const clearReduxSearchString = (
  context: string,
  officeId: string | null = null,
  projectId: string | null = null,
  path: string = ''
) => ({
  type: CLEAR_SEARCH_STRING,
  payload: { context, officeId, projectId, path },
});
