import { ApiResult, apiUrl } from '../../../api';
import {
  CustomerCourseParticipationReadDto,
  ReteachCourseReadDto,
} from '../../../models/Training';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiFetchReteachParticipation: () => Promise<
  ApiResult<CustomerCourseParticipationReadDto[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/training/reteach/participation`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchReteachCourses: () => Promise<
  ApiResult<ReteachCourseReadDto[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/training/reteach/course`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
