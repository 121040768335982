import React from 'react';
import { Message } from '../../../../models/Message';
import {
  MailCategoryColorName,
  MailFolderId,
  MailSelectionListSpacing,
  ProjectId,
} from '../../../../models/Types';
import { Classes } from 'jss';
import { makePrioStyles } from '../../../../theme/utils';
import MailListItemHorizontal from './MailListItemHorizontal';
import MailListItemVertical from './MailListItemVertical';
import { PrioTheme } from '../../../../theme/types';

export const useListItemStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    userSelect: 'none',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    fontSize: theme.old.components.mailListItem.fontSize,
    alignItems: 'center',
    color: theme.old.typography.colors.muted,
    '& div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .ant-checkbox-wrapper': {
      marginRight: theme.old.components.mailListItem.spacing + 4,
      marginLeft: theme.old.components.mailListItem.spacing + 4,
    },
    '& .prio-mail-list-item-mail-address': {
      color: theme.old.typography.colors.base,
    },
    '&:hover $quickActionRow': {
      display: 'flex',
      flexDirection: 'row',
    },
    '&:hover $informationIconRow': {
      display: 'none',
    },
    '&:hover $assignmentIcon': {
      display: 'block',
    },
    '&.prio-mail-list-item-is-flagged': {
      backgroundColor:
        theme.old.components.mailListItem.flaggedMessageBackground,
    },
    '& .prio-virtual-list-item-checkBoxContainer': {
      width: 16 + theme.old.components.mailListItem.spacing * 4,
    },
  },
  isUnread: {
    '& .prio-virtual-list-item-checkBoxContainer': {
      position: 'relative',
    },
    '& .prio-virtual-list-item-checkBoxContainer::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: 4,
      backgroundColor: theme.old.palette.primaryColor,
    },
    '& .prio-mail-list-item-mail-address': {
      fontWeight: theme.old.typography.fontWeight.bold,
    },
    '& .prio-mail-list-item-subject': {
      color: theme.old.palette.primaryColor,
      fontWeight: theme.old.typography.fontWeight.bold,
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    '& > div:not(:last-child)': {
      marginRight: theme.old.components.mailListItem.spacing,
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  quickActionsRoot: {
    marginRight: theme.old.components.mailListItem.spacing,
    '& $quickAction:not(:first-child), $informationIcon:not(:first-child)': {
      marginLeft: theme.old.components.mailListItem.spacing,
    },
  },
  quickActionRow: {
    display: 'none',
  },
  quickAction: {
    minWidth: theme.old.components.mailListItem.fontSize + 2,
    cursor: 'pointer',
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  quickActionDisabled: {
    color: theme.old.typography.colors.muted,
    cursor: 'not-allowed',
  },
  informationIconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  informationIcon: {
    minWidth: theme.old.components.mailListItem.fontSize + 2,
  },
  categoryAndDateRow: {
    '& .prio-mail-list-item-receivedDateTime': {
      position: 'absolute',
      right: theme.old.components.mailListItem.spacing,
    },
  },
  categoryList: {
    flex: 1,
  },
  assignmentIcon: {
    display: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.old.palette.primaryColor,
    },
  },
  assignmentIconAssigned: {
    display: 'block',
  },
  favoritesViewProjectName: {
    fontWeight: theme.old.typography.fontWeight.regular,
    color: theme.old.palette.primaryColor,
  },
}));

export declare type MailListItemClassNames =
  | 'root'
  | 'flexRow'
  | 'flexColumn'
  | 'isUnread'
  | 'quickActionsRoot'
  | 'informationIconRow'
  | 'quickActionRow'
  | 'quickAction'
  | 'quickActionDisabled'
  | 'informationIcon'
  | 'categoryAndDateRow'
  | 'categoryList'
  | 'assignmentIcon'
  | 'assignmentIconAssigned'
  | 'favoritesViewProjectName';

export interface MailListItemProps {
  message: Message;
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  className?: string;
  classes: Classes<MailListItemClassNames>;
  classesOfCategoryList: Classes<
    | 'root'
    | 'flexRow'
    | 'tag'
    | 'plus'
    | 'selectIcon'
    | 'tagList'
    | 'tagRoot'
    | 'tagCloseIconNoMargin'
    | 'mailCategoryMenu'
  >;
  isSendOrDraftFolder: boolean;
  isFavoritesView: boolean;
  colorMap: { [displayName: string]: MailCategoryColorName };
  itemLayout: 'vertical' | 'horizontal';
  mailListSpacing: MailSelectionListSpacing;
  mailListNavigationWidth: number;
  selected: boolean;
  selectedMessages: Message[];
  loading: boolean;
  getInboxFolderId: (projectId: ProjectId) => MailFolderId;
  onDeselectMessage: (message: Message) => void;
}

export const MailListItem: React.FC<MailListItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { itemLayout } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects;
  //#endregion

  if (itemLayout === 'horizontal') {
    return <MailListItemHorizontal {...props} />;
  }

  return <MailListItemVertical {...props} />;
};

export default MailListItem;
