import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import ContactForm from './ContactForm';
import { CreateContactRequest, Contact } from '../../../models/Contact';
import { archiveContact, createContact } from '../actions';
import { setContactsDrawerState, closeContactsDrawer } from '../actions/drawer';
import { Dropdown, Typography, Form, Drawer } from 'antd';
import { createTemporaryId } from '../../../util';
import {
  getCompany,
  getContactsDrawerState,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { ContactsDrawerState } from '../reducers/drawer';
import { NewContactMenu } from './NewContactMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExternalCompanyForm from '../../companies/components/ExternalCompanyForm';
import { Company, CreateExternalCompanyRequest } from '../../../models/Company';
import { ProjectAssignment } from '../../../models/Project';
import { archiveCompany, createExternalCompany } from '../../companies/actions';
import { ContactId } from '../../../models/Types';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px`,
  },
  actionButtonsRow: {
    marginBottom: theme.old.spacing.unit(2),
  },
  dropDownIcon: {
    marginLeft: theme.old.spacing.unit(0.75),
    height: 20,
    fontSize: 8,
  },
  alert: {
    marginTop: theme.old.spacing.unit(1),
  },
  checkbox: {
    marginTop: theme.old.spacing.unit(2),
  },
  contactForm: {
    overflow: 'hidden',
  },
  drawerRoot: {
    height: '100%',
  },
}));

interface CreateContactProps {
  className?: string;
  noAvatar?: boolean;
  initialValues?: Contact;
  isSubDrawer?: boolean;
  onCancel?: VoidFunction;
  onFinish?: (contactId: ContactId) => void;
}

export const CreateContact: React.FC<CreateContactProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contactForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const { initialValues, isSubDrawer, onCancel, onFinish } = props;
  const { open, selectedCompany: preselectedCompanyId } = useSelector<
    RootReducerState,
    ContactsDrawerState
  >(getContactsDrawerState);
  const initialCompany = useSelector<RootReducerState, Company>(
    (state: RootReducerState) => getCompany(state, preselectedCompanyId)
  );
  //#endregion

  //#region -------------------------------- State declaration
  const [projectAssignments, setProjectAssignments] = useState<
    ProjectAssignment[]
  >([]);
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const [companyFormIsOpen, setCompanyFormIsOpen] = useState<boolean>(false);
  //#endregion

  //#region -------------------------------- Methods
  const closeDropDown = () => setDropDownOpen(false);
  //#endregion

  //#region -------------------------------- Handle methods
  const handleOpenCompanyDrawer = () => {
    setCompanyFormIsOpen(true);
  };

  const handleCloseCompanyDrawer = () => {
    setCompanyFormIsOpen(false);
  };

  const handleProjectAssignmentChange = (
    currentProjectAssignments: ProjectAssignment[]
  ) => {
    setProjectAssignments(currentProjectAssignments);
  };

  const handleOnCompanyDrawerClose: (
    value: CreateExternalCompanyRequest,
    companyIdsToArchive?: string[]
  ) => void = (value, companyIdsToArchive) => {
    const companyTemporaryId = createTemporaryId();
    dispatch(createExternalCompany(value, companyTemporaryId));
    contactForm.setFieldsValue({ companyId: companyTemporaryId });

    if (companyIdsToArchive) {
      companyIdsToArchive.forEach((companyId) => {
        dispatch(archiveCompany(companyId));
      });
    }

    setCompanyFormIsOpen(false);
  };

  const handleOnFormsSubmit: (
    value: CreateContactRequest,
    contactIdsToArchive?: string[]
  ) => void = async (value, contactIdsToArchive) => {
    const contactTemporaryId = createTemporaryId();
    value.projectList = projectAssignments.map((x) => ({
      projectId: x.projectId,
      jobTitle: x.jobtitle,
    }));
    dispatch(createContact(value, contactTemporaryId));

    if (contactIdsToArchive) {
      contactIdsToArchive.forEach((contactId) => {
        dispatch(archiveContact(contactId));
      });
    }
    if (isSubDrawer && onFinish) {
      onFinish(contactTemporaryId);
    } else {
      dispatch(
        setContactsDrawerState({
          selectedContact: contactTemporaryId,
          view: 'contactDetail',
        })
      );
    }
    dispatch(
      setContactsDrawerState({
        changedContact: {
          contact: {
            contactId: contactTemporaryId,
            ...value,
          },
          state: 'created',
        },
      })
    );
  };

  const handleCancel = () => {
    if (isSubDrawer && onCancel) {
      onCancel();
    } else {
      dispatch(closeContactsDrawer());
    }
  };

  //#endregion

  return (
    <>
      <Flex.Column
        id="prio-global-contacts-drawer-form"
        className={classNames(classes.root, className)}
        childrenGap={theme.old.spacing.unit(5)}
      >
        {isSubDrawer ? (
          <Typography.Title>{t('common:contact')}</Typography.Title>
        ) : (
          <Dropdown
            visible={dropDownOpen}
            overlay={<NewContactMenu closeDropdown={closeDropDown} />}
            trigger={['click']}
            onVisibleChange={setDropDownOpen}
          >
            <Typography.Title>
              {t('common:contact')}
              <FontAwesomeIcon
                icon={['fal', 'chevron-down']}
                size="xs"
                fixedWidth
                flip={dropDownOpen ? 'vertical' : null}
                className={classes.dropDownIcon}
              />
            </Typography.Title>
          </Dropdown>
        )}
        <Flex.Column
          className={classes.contactForm}
          childrenGap={theme.old.spacing.unit(5)}
        >
          <ContactForm
            mode="create"
            form={contactForm}
            actionLabel={t('contacts:newContact.actionLabel')}
            actionLabelIgnore={t('contacts:newContact.actionLabelIgnore')}
            actionLabelArchive={t('contacts:newContact.actionLabelArchive')}
            cancelLabel={t('common:actions.cancel')}
            initialCompany={initialCompany}
            onCancel={handleCancel}
            onFinish={handleOnFormsSubmit}
            initialValues={initialValues}
            onProjectAssignmentChange={handleProjectAssignmentChange}
            openCompanyDrawer={handleOpenCompanyDrawer}
            disableForm={!open}
            avatar
          />
        </Flex.Column>
      </Flex.Column>
      {!isSubDrawer && (
        <Drawer
          visible={companyFormIsOpen}
          onClose={handleCloseCompanyDrawer}
          width="40vw"
          placement="right"
          closable={true}
          destroyOnClose
        >
          <Flex.Column
            className={classes.drawerRoot}
            childrenGap={theme.old.spacing.unit(5)}
          >
            <Typography.Title>{t('common:company')}</Typography.Title>
            <ExternalCompanyForm
              form={companyForm}
              actionLabel={t('companies:newCompany.actionLabel')}
              cancelLabel={t('common:actions.cancel')}
              onCancel={handleCloseCompanyDrawer}
              onFinish={handleOnCompanyDrawerClose}
              avatar
            />
          </Flex.Column>
        </Drawer>
      )}
    </>
  );
};

export default CreateContact;
