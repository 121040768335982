import { Tooltip } from '@prio365/prio365-react-library';
import moment, { Moment } from 'moment';
import { OfficeHoliday } from '../../../../models/AbsenceProposal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

interface AbsenceTimelineHeaderSectionItemProps {
  date: Moment;
  officeHolidays: OfficeHoliday[];
}

const AbsenceTimelineHeaderSectionItem: React.FC<
  AbsenceTimelineHeaderSectionItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { date, officeHolidays } = props;
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isToday = moment().isSame(date, 'day');
  const holiday = officeHolidays.find((holiday) =>
    moment(holiday.date).isSame(date, 'day')
  );
  const prevIsHoliday = officeHolidays.find((holiday) =>
    moment(holiday.date).isSame(date.clone().subtract(1, 'day'), 'day')
  );
  const nextIsHoliday = officeHolidays.find((holiday) =>
    moment(holiday.date).isSame(date.clone().add(1, 'day'), 'day')
  );
  const isSaturday = date.day() === 6;
  const isSunday = date.day() === 0;
  const color = isToday
    ? theme.colors.application.typography.active
    : !!holiday
    ? theme.colors.base.green.default
    : undefined;
  const backgroundColor = isToday
    ? theme.colors.application.background.hover
    : !!holiday
    ? theme.colors.base.green[20]
    : isSaturday || isSunday
    ? theme.colors.application.background.light
    : undefined;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Tooltip overlay={holiday?.name} placement="top">
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 60,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderTopLeftRadius:
            !prevIsHoliday && !isSunday && (!!holiday || isToday || isSaturday)
              ? 4
              : 0,

          borderTopRightRadius:
            !nextIsHoliday && !isSaturday && (!!holiday || isToday || isSunday)
              ? 4
              : 0,
          color,
          backgroundColor,
        }}
      >
        <span
          style={{
            fontWeight: theme.font.fontWeight.bold,
            fontSize: theme.font.fontSize.small,
          }}
        >
          {moment(date).format('D')}
        </span>
        <span
          style={{
            fontSize: theme.font.fontSize.extraSmall,
          }}
        >
          {moment(date).format('ddd')}
        </span>
        {isToday && (
          <div
            style={{
              height: 2,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: theme.colors.base.primary.default,
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default AbsenceTimelineHeaderSectionItem;
