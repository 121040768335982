import { useMemo } from 'react';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useAccessRights2 from '../../../modules/users/hooks/useAccessRights2';
import useContactsContext from '../../../modules/contacts/hooks/useContactsProvider';
import useOfficesContext from '../../../modules/companies/hooks/useOfficesContext';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';

const EmployeeFilterPickerConsumer: React.FC<FilterPickerConsumerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children, roles } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { contacts: _contacts, getContactById } = useContactsContext();
  const { myOffices } = useOfficesContext();

  const userMe = useSelector(getUserMe);
  const contactMe = useMemo(() => {
    return getContactById(userMe?.id);
  }, [getContactById, userMe]);

  const myOfficesIds = useMemo(() => {
    return myOffices.map(({ officeId }) => officeId);
  }, [myOffices]);

  const {
    checkGlobalRoles,
    checkOfficeRolesOfCompanyId,
    checkOfficeRolesOfOfficeId,
    checkProjectRolesOfCompanyId,
    checkProjectRolesOfOfficeId,
  } = useAccessRights2();

  const contacts = useMemo(() => {
    const internalContacts = _contacts.filter(
      ({ contactType }) => contactType === 'InternalContact'
    );
    if (
      roles.globalRoles.length > 0 &&
      checkGlobalRoles(
        roles.globalRoles.filter((role) => role !== 'globalEmployee')
      )
    ) {
      return internalContacts;
    }
    if (roles.officeRoles.length > 0 && roles.projectRoles.length > 0) {
      return _contacts.filter(
        (contact) =>
          checkOfficeRolesOfOfficeId(roles.officeRoles, contact.officeId) ||
          checkOfficeRolesOfCompanyId(roles.officeRoles, contact.companyId) ||
          checkProjectRolesOfOfficeId(roles.projectRoles, contact.officeId) ||
          checkProjectRolesOfCompanyId(roles.projectRoles, contact.companyId)
      );
    }
    return internalContacts.filter(
      ({ officeId }) =>
        contactMe?.officeId === officeId || myOfficesIds.includes(officeId)
    );
  }, [
    contactMe,
    roles,
    _contacts,
    myOfficesIds,
    checkGlobalRoles,
    checkOfficeRolesOfOfficeId,
    checkOfficeRolesOfCompanyId,
    checkProjectRolesOfOfficeId,
    checkProjectRolesOfCompanyId,
  ]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: contacts.map(({ contactId, firstName, lastName }) => {
          return {
            value: contactId,
            label: `${firstName} ${lastName}`,
            searchValue: `${firstName} ${lastName} ${contactId}`,
          };
        }),
      })}
    </>
  );
};

export default EmployeeFilterPickerConsumer;
