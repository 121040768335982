import React from 'react';
import { Trans } from 'react-i18next';
import { Company } from '../../../models/Company';
import { Card, Typography } from 'antd';

interface CompanyCardProps {
  company: Company;
}

export const CompanyCard: React.FC<CompanyCardProps> = ({ company }) => (
  <Card title={company.fullName}>
    <Typography.Text>{company.fullName}</Typography.Text>
    {company.offices[0]?.eMail && (
      <Typography.Text>
        <Trans i18nKey="companies:form.eMail">E-Mail</Trans>:{' '}
        {company.offices[0]?.eMail}
      </Typography.Text>
    )}
    {company.website && (
      <Typography.Text>
        <Trans i18nKey="companies:form.website">Website</Trans>:{' '}
        {company.website}
      </Typography.Text>
    )}
    {company.offices[0]?.phone && (
      <Typography.Text>
        <Trans i18nKey="companies:form.phone">Telefon</Trans>:{' '}
        {company.offices[0]?.phone}
      </Typography.Text>
    )}
  </Card>
);

export default CompanyCard;
