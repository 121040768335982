import React from 'react';
import GlobalAndOfficeMenu from '../../../components/GlobalAndOfficeMenu';
import {
  GlobalAndOfficeNavigation,
  NavItem,
} from '../../../components/GlobalAndOfficeNavigation';

interface ControllingMenuProps {
  selectedList?: string;
  officeId?: string;
  urlPrefix?: string;
  open?: boolean;
  isSubMenu?: boolean;
}

export const ControllingMenu: React.FC<ControllingMenuProps> = (props) => {
  const { selectedList, urlPrefix, officeId, open, isSubMenu } = props;

  const globalNavItems: NavItem[] = [
    {
      itemKey: 'invoices',
      icon: 'arrow-right-arrow-left',
      tString: 'controlling:navigation.invoices',
      showWarning: true,
    },
    {
      itemKey: 'incomingInvoices',
      icon: 'arrow-right',
      tString: 'controlling:navigation.incomingInvoices',
    },
    {
      itemKey: 'outgoingInvoices',
      icon: 'arrow-left',
      tString: 'controlling:navigation.outgoingInvoices',
    },
    {
      itemKey: 'timeRecords',
      icon: 'user-clock',
      tString: 'controlling:navigation.timeRecords',
    },
  ];

  const officeNavItems: NavItem[] = [
    {
      itemKey: 'invoices',
      icon: 'arrow-right-arrow-left',
      tString: 'controlling:navigation.invoices',
      showWarning: true,
    },
    {
      itemKey: 'incomingInvoices',
      icon: 'arrow-right',
      tString: 'controlling:navigation.incomingInvoices',
    },
    {
      itemKey: 'outgoingInvoices',
      icon: 'arrow-left',
      tString: 'controlling:navigation.outgoingInvoices',
    },
    {
      itemKey: 'timeRecords',
      icon: 'user-clock',
      tString: 'controlling:navigation.timeRecords',
    },
  ];

  const globalSubMenuItem: NavItem = {
    itemKey: 'invoices',
    tString: 'controlling:navigation.allAccounting',
  };

  if (!open && !isSubMenu) {
    return (
      <GlobalAndOfficeMenu
        urlPrefix={urlPrefix}
        selectedKey={selectedList}
        officeId={officeId}
        globalMenuItems={globalNavItems}
        officeMenuItems={officeNavItems}
        globalAccessMode={'showGlobalInControllingModule'}
        officeAccessMode={'showOfficesInControllingModule'}
        openSider={open}
      />
    );
  }

  return (
    <GlobalAndOfficeNavigation
      urlPrefix={urlPrefix}
      globalMenuItems={globalNavItems}
      officeMenuItems={officeNavItems}
      selectedList={selectedList}
      officeId={officeId}
      globalAccessMode={'showGlobalInControllingModule'}
      officeAccessMode={'showOfficesInControllingModule'}
      showOpen={open}
      isSubMenu={isSubMenu}
      globalSubMenuItem={globalSubMenuItem}
    />
  );
};
