import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import { OfficeId } from '../../../models/Types';
import { User } from '../../../models/User';

/** Contact classification fetching */
export const FETCH_USERS_REQUEST = PRIO + 'FETCH_USERS_REQUEST';
export const FETCH_USERS_COMMIT = PRIO + 'FETCH_USERS_COMMIT';
export const FETCH_USERS_ROLLBACK = PRIO + 'FETCH_USERS_ROLLBACK';

export const fetchUsers = (officeId?: OfficeId) => ({
  type: FETCH_USERS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url:
          officeId !== undefined && officeId !== ''
            ? `${apiUrl}/user/office/${officeId}/User`
            : `${apiUrl}/user/User`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_USERS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_USERS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'users:errorMessages.fetchUsersError',
          timeout: 6,
        },
      },
    },
  },
});

export const UPDATE_USER_REQUEST = PRIO + 'UPDATE_USER_REQUEST';
export const UPDATE_USER_COMMIT = PRIO + 'UPDATE_USER_COMMIT';
export const UPDATE_USER_ROLLBACK = PRIO + 'UPDATE_USER_ROLLBACK';

export const updateUser = (user: User, rollbackUser: User) => ({
  type: UPDATE_USER_REQUEST,
  requiresAuth: true,
  payload: user,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/setting/User/${user.id}`,
        method: 'PUT',
        json: user,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_USER_COMMIT,

        meta: {
          contactId: user.id,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_USER_ROLLBACK,
        meta: {
          contactId: user.id,
        },
        snackbarErrorMessage: {
          label: 'settings:errorMessages.updateUser',
          timeout: 6,
        },
        rollbackUser,
      },
    },
    contactId: user.id,
  },
});

export const UPDATE_USER_GLOBAL_ROLES_REQUEST =
  PRIO + 'UPDATE_USER_GLOBAL_ROLES_REQUEST';
export const UPDATE_USER_GLOBAL_ROLES_COMMIT =
  PRIO + 'UPDATE_USER_GLOBAL_ROLES_COMMIT';
export const UPDATE_USER_GLOBAL_ROLES_ROLLBACK =
  PRIO + 'UPDATE_USER_GLOBAL_ROLES_ROLLBACK';

export const updateUserGlobalRoles = (user: User, rollbackUser: User) => ({
  type: UPDATE_USER_GLOBAL_ROLES_REQUEST,
  requiresAuth: true,
  payload: user.prioData.globalRoles,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/user/User/${user.id}/globalRoles`,
        method: 'POST',
        json: {
          globalRoleTypes: user.prioData.globalRoles,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_USER_GLOBAL_ROLES_COMMIT,
        meta: {
          contactId: user.id,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_USER_GLOBAL_ROLES_ROLLBACK,
        meta: {
          contactId: user.id,
        },
        snackbarErrorMessage: {
          label: 'users:errorMessages.updaterUserGlobalRolesError',
          parameters: { displayName: user.displayName },
          timeout: 6,
        },
        rollbackUser,
      },
    },
    contactId: user.id,
  },
});

export const UPDATE_USER_OFFICE_ROLES_REQUEST =
  PRIO + 'UPDATE_USER_OFFICE_ROLES_REQUEST';
export const UPDATE_USER_OFFICE_ROLES_COMMIT =
  PRIO + 'UPDATE_USER_OFFICE_ROLES_COMMIT';
export const UPDATE_USER_OFFICE_ROLES_ROLLBACK =
  PRIO + 'UPDATE_USER_OFFICE_ROLES_ROLLBACK';

// officeAdmin, officeHR, globalAdmin, globalHR
export const updateUserOfficeRoles = (
  user: User,
  rollbackUser: User,
  officeId: OfficeId
) => ({
  type: UPDATE_USER_OFFICE_ROLES_REQUEST,
  requiresAuth: true,
  payload: user.prioData.officeRoles[officeId] ?? [],
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/user/User/${user.id}/Office/${officeId}/officeRoles`,
        method: 'PUT',
        json: {
          roles: user.prioData.officeRoles[officeId],
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_USER_OFFICE_ROLES_COMMIT,
        meta: {
          contactId: user.id,
          officeId: officeId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_USER_OFFICE_ROLES_ROLLBACK,
        meta: {
          contactId: user.id,
        },
        snackbarErrorMessage: {
          label: 'users:errorMessages.updaterUserOfficeRolesError',
          parameters: { displayName: user.displayName },
          timeout: 6,
        },
        rollbackUser,
      },
    },
    contactId: user.id,
    officeId: officeId,
    officeRoles: user.prioData.officeRoles[officeId] ?? [],
  },
});

export const UPDATE_GLOBAL_USER_OFFICE_ROLES_REQUEST =
  PRIO + 'UPDATE_GLOBAL_USER_OFFICE_ROLES_REQUEST';
export const UPDATE_GLOBAL_USER_OFFICE_ROLES_COMMIT =
  PRIO + 'UPDATE_GLOBAL_USER_OFFICE_ROLES_COMMIT';
export const UPDATE_GLOBAL_USER_OFFICE_ROLES_ROLLBACK =
  PRIO + 'UPDATE_GLOBAL_USER_OFFICE_ROLES_ROLLBACK';

// globalAdmin, globalHR
export const updateGlobalUserOfficeRoles = (
  user: User,
  rollbackUser: User,
  officeId: OfficeId
) => ({
  type: UPDATE_GLOBAL_USER_OFFICE_ROLES_REQUEST,
  requiresAuth: true,
  payload: user.prioData.officeRoles[officeId] ?? [],
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/user/User/${user.id}/officeRoles`,
        method: 'PUT',
        json: {
          officeId: officeId,
          roles: user.prioData.officeRoles[officeId] ?? [],
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_GLOBAL_USER_OFFICE_ROLES_COMMIT,
        meta: {
          contactId: user.id,
          officeId: officeId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_GLOBAL_USER_OFFICE_ROLES_ROLLBACK,
        meta: {
          contactId: user.id,
        },
        snackbarErrorMessage: {
          label: 'users:errorMessages.updaterUserOfficeRolesError',
          parameters: { displayName: user.displayName },
          timeout: 6,
        },
        rollbackUser,
      },
    },
    contactId: user.id,
    officeId: officeId,
  },
});
