import React, { useRef } from 'react';
import classNames from 'classnames';
import Flex from '../../../components/Flex';
import { useParams } from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';
import NavigationBar from '../../../components/NavigationBar';
import { HRAbsenceMenu } from './HRAbsenceMenu';
import { HRAbsenceOpenProposalPage } from './HRAbsenceOpenProposalPage';
import { HRAbsenceAllProposalPage } from './HRAbsenceAllProposalPage';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import HRRemainingAbsencePage from './HRRemainingAbsencePage';
import { PrioTheme } from '../../../theme/types';
import HRHolidayPage, { HRHolidayPageRef } from './HRHolidaysPage';
import AbsenceTimelinePage from './AbsenceTimeline/AbsenceTimelinePage';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    flex: 1,
    width: '100%',
    overflow: 'hidden',
  },
  overflow: {
    overflow: 'hidden',
  },
}));

interface HRAbsenceProps {
  className?: string;
  pathPrefix?: string;
}

export const HRAbsence: React.FC<HRAbsenceProps> = (props) => {
  const classes = useStyles();
  const { className, pathPrefix } = props;
  const { selectedList, officeId } = useParams();
  const { t } = useTranslation();

  const holidayPageRef = useRef<HRHolidayPageRef>(null);

  let component: React.ReactNode;
  switch (selectedList) {
    case 'overview':
      component = <AbsenceTimelinePage officeId={officeId} isHrContext />;
      break;
    case 'openproposals':
      component = (
        <HRAbsenceOpenProposalPage
          className={classNames(classes.content)}
          officeId={officeId}
        />
      );
      break;
    case 'allproposals':
      component = (
        <HRAbsenceAllProposalPage
          className={classNames(classes.content)}
          officeId={officeId}
        />
      );
      break;
    case 'remainingabsence':
      component = (
        <HRRemainingAbsencePage
          className={classNames(classes.content)}
          officeId={officeId}
        />
      );
      break;
    case 'holidays':
      component = <HRHolidayPage officeId={officeId} ref={holidayPageRef} />;
      break;
    default:
  }

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>
        {selectedList === 'holidays' ? (
          <Button
            onClick={() => holidayPageRef?.current?.addOfficeHoliday()}
            iconProp={['fal', 'plus']}
          >
            <span>{t('absences:form.officeHolidays.newHolidayButton')}</span>
          </Button>
        ) : null}
      </NavigationBar>
      <Flex.Row flex={1} className={classes.overflow}>
        <HRAbsenceMenu
          selectedList={selectedList}
          urlPrefix={pathPrefix}
          className={classes.menu}
          officeId={officeId}
        />
        {component}
      </Flex.Row>
    </Flex.Column>
  );
};

export default HRAbsence;
