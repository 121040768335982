import { createSelector } from 'reselect';
import {
  RootReducerState,
  getTimeKeepingDayByIdState,
  getMonthlyCloseMeTimeKeepingDayIds,
  getTimeRecordIds,
  getTimeRecordsByIdState,
  getUserMe,
  getAbsenceByIdState,
  getAbsenceIds,
  getMonthlyCloseMeByMonth,
  getAbsencesMeIsFetching,
  getTimeRecordsIsFetching,
  getMonthlyCloseMeIsFetching,
} from '../../../apps/main/rootReducer';
import { TimeRecordsByIdState } from '../../timeRecords/reducers/timeRecords';
import {
  AbsenceProposalId,
  DateTimeString,
  TimeKeepingDayId,
  TimeRecordId,
} from '../../../models/Types';
import { User } from '../../../models/User';
import { TimelineItem } from '../../../components/Timeline/types';
import { TimeKeepingByIdState } from '../../timeKeeping/reducers/timeKeepingDaysMe';
import { distinct, isTemporaryId } from '../../../util';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import i18n from '../../../i18n';
import { MonthlyClose, TimeKeepingDay } from '../../../models/TimeKeeping';
import { TimeRecord } from '../../../models/TimeRecord';
import { AbsencesById } from '../../absences/reducers/absences';
import moment from 'moment';
import {
  CustomCalendarEvent,
  TimeTrackingSuggestion,
} from '../../../models/Calendar';
import { v4 as uuid } from 'uuid';

export interface TimeAndLeaveManagementTimelineItem extends TimelineItem {
  type:
    | 'absence'
    | 'timeKeeping'
    | 'timeRecord'
    | 'timeTrackingSuggestion'
    | 'timeKeepingSuggestion';
  entries: {
    startTime: string;
    endTime: string;
  }[];
  value: AbsenceProposal | TimeKeepingDay | TimeRecord | TimeTrackingSuggestion;
  state: 'ok' | 'error' | 'temporary';
}

const acceptedAbsenceStates = [
  'planned',
  'requested',
  'accepted',
  'revokeRequested',
  'revokeDeclined',
];

export const absencesMeTimelineSelector = (
  monthDateTime: DateTimeString,
  minRange: string,
  maxRange: string
) =>
  createSelector<
    [
      (state: RootReducerState) => AbsencesById,
      (state: RootReducerState) => AbsenceProposalId[],
    ],
    TimeAndLeaveManagementTimelineItem[]
  >(getAbsenceByIdState, getAbsenceIds, (byId, ids) =>
    ids.reduce((map, id) => {
      const absenceProposal = byId[id];
      if (
        !absenceProposal ||
        !acceptedAbsenceStates.includes(absenceProposal.absenceState)
      ) {
        return map;
      }
      const {
        from,
        to,
        absenceProposalId,
        absenceType,
        firstIsHalfDay,
        lastIsHalfDay,
      } = absenceProposal;

      if (
        !(
          moment(from).isSame(monthDateTime, 'month') ||
          moment(to).isSame(monthDateTime, 'month')
        )
      ) {
        return map;
      }

      const diffInDays = moment(to).diff(moment(from), 'days');
      let items: TimeAndLeaveManagementTimelineItem[] = [];

      for (let i = 0; i <= diffInDays; i++) {
        const date = moment(from).add(i, 'days').format('YYYY-MM-DD');
        let startHour = minRange;
        let endHour = maxRange;
        if (diffInDays === 0) {
          if (firstIsHalfDay || lastIsHalfDay) {
            if (firstIsHalfDay && !lastIsHalfDay) {
              endHour = '13:00';
            } else if (!firstIsHalfDay && lastIsHalfDay) {
              startHour = '13:00';
            }
          }
        } else {
          if (i === 0 && firstIsHalfDay) {
            startHour = '13:00';
          }
          if (i === diffInDays && lastIsHalfDay) {
            endHour = '13:00';
          }
        }
        items.push({
          groupId: 'timeKeeping',
          startDateTime: `${date}T${startHour}`,
          endDateTime: `${date}T${endHour}`,
          id: absenceProposalId,
          title: i18n.t(`absences:form.absenceTypes.${absenceType}`),
          disabled: true,
          type: 'absence',
          entries: [
            {
              startTime: `${date}T${startHour}`,
              endTime: `${date}T${endHour}`,
            },
          ],
          value: absenceProposal,
          state: 'ok',
        });
      }

      return [...map, ...items];
    }, [] as TimeAndLeaveManagementTimelineItem[])
  );

export const timeRecordsTimelineSelector = createSelector<
  [
    (state: RootReducerState) => TimeRecordsByIdState,
    (state: RootReducerState) => TimeRecordId[],
    (state: RootReducerState) => User,
  ],
  TimeAndLeaveManagementTimelineItem[]
>(getTimeRecordsByIdState, getTimeRecordIds, getUserMe, (byId, ids, user) =>
  ids.reduce((currentArray, id) => {
    const timeRecord = byId[id];
    if (!!timeRecord && timeRecord.contactId === user.id) {
      const { timeRecordEntries } = timeRecord;
      const sortedEntries = timeRecordEntries.sort(
        ({ startTime: aStartTime }, { startTime: bStartTime }) =>
          Date.parse(aStartTime) - Date.parse(bStartTime)
      );
      currentArray.push({
        groupId: timeRecord.projectId,
        startDateTime: sortedEntries[0].startTime,
        endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
        id: timeRecord.timeRecordId,
        title: timeRecord.title,
        type: 'timeRecord',
        entries: timeRecordEntries,
        value: timeRecord,
        state: 'ok',
      });
    }
    return currentArray;
  }, [] as TimeAndLeaveManagementTimelineItem[])
);

export const timeKeepingTimelineSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [
      (state: RootReducerState) => TimeKeepingByIdState,
      (state: RootReducerState) => TimeKeepingDayId[],
    ],
    TimeAndLeaveManagementTimelineItem[]
  >(
    getTimeKeepingDayByIdState,
    (state) => getMonthlyCloseMeTimeKeepingDayIds(state, monthDateTime),
    (byId, ids) =>
      ids.reduce((currentArray, id) => {
        const timeKeepingDay = byId[id];
        if (!!timeKeepingDay) {
          const { timeKeepingEntries } = timeKeepingDay;
          const sortedEntries = timeKeepingEntries.sort(
            ({ startTime: aStartTime }, { startTime: bStartTime }) =>
              Date.parse(aStartTime) - Date.parse(bStartTime)
          );
          currentArray.push({
            groupId: 'timeKeeping',
            startDateTime: sortedEntries[0].startTime,
            endDateTime: sortedEntries[sortedEntries.length - 1].endTime,
            id: timeKeepingDay.timeKeepingDayId,
            title: '',
            disabled: isTemporaryId(timeKeepingDay.timeKeepingDayId),
            type: 'timeKeeping',
            entries: timeKeepingEntries,
            value: timeKeepingDay,
            state: 'ok',
          });
        }
        return currentArray;
      }, [] as TimeAndLeaveManagementTimelineItem[])
  );

export const timelineItemsSelector = (
  monthDateTime: DateTimeString,
  minRange: string,
  maxRange: string
) =>
  createSelector<
    [
      (state: RootReducerState) => TimeAndLeaveManagementTimelineItem[],
      (state: RootReducerState) => TimeAndLeaveManagementTimelineItem[],
      (state: RootReducerState) => TimeAndLeaveManagementTimelineItem[],
    ],
    TimeAndLeaveManagementTimelineItem[]
  >(
    absencesMeTimelineSelector(monthDateTime, minRange, maxRange),
    timeKeepingTimelineSelector(monthDateTime),
    timeRecordsTimelineSelector,
    (absenceProposalItems, timeKeepingItems, timeRecordItems) => [
      ...absenceProposalItems,
      ...timeKeepingItems,
      ...timeRecordItems,
    ]
  );

export const absencesMeCalendarSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [
      (state: RootReducerState) => AbsencesById,
      (state: RootReducerState) => AbsenceProposalId[],
    ],
    CustomCalendarEvent[]
  >(getAbsenceByIdState, getAbsenceIds, (byId, ids) =>
    ids.reduce((map, id) => {
      const absenceProposal = byId[id];
      if (
        !absenceProposal ||
        !acceptedAbsenceStates.includes(absenceProposal.absenceState)
      ) {
        return map;
      }
      const {
        from,
        to,
        absenceProposalId,
        absenceType,
        absenceState,
        firstIsHalfDay,
        lastIsHalfDay,
      } = absenceProposal;

      if (
        !(
          moment(from).isSame(monthDateTime, 'month') ||
          moment(to).isSame(monthDateTime, 'month')
        )
      ) {
        return map;
      }

      const event: CustomCalendarEvent = {
        id: absenceProposalId,
        title: i18n.t(`absences:form.absenceTypes.${absenceType}`),
        start: new Date(from),
        end: new Date(to),
        allDay: from === to && (firstIsHalfDay || lastIsHalfDay) ? false : true,
        resources: {
          resourceId: absenceProposalId,
          type: 'absence',
          absenceType,
          absenceState,
          firstIsHalfDay,
          lastIsHalfDay,
        },
      };

      return [...map, event];
    }, [])
  );

export const timeRecordsCalendarSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [
      (state: RootReducerState) => TimeRecordsByIdState,
      (state: RootReducerState) => TimeRecordId[],
      (state: RootReducerState) => User,
    ],
    { [day: DateTimeString]: CustomCalendarEvent[] }
  >(getTimeRecordsByIdState, getTimeRecordIds, getUserMe, (byId, ids, user) =>
    ids.reduce(
      (map, id) => {
        const timeRecord = byId[id];
        if (
          !!timeRecord &&
          timeRecord.contactId === user.id &&
          timeRecord.day.substring(0, 7) === monthDateTime
        ) {
          const sortedEntries = timeRecord.timeRecordEntries.sort(
            ({ startTime: aStartTime }, { startTime: bStartTime }) =>
              Date.parse(aStartTime) - Date.parse(bStartTime)
          );
          const event: CustomCalendarEvent = {
            id: timeRecord.timeRecordId,
            title: timeRecord.title,
            start: new Date(sortedEntries[0].startTime),
            end: new Date(sortedEntries[sortedEntries.length - 1].endTime),
            allDay: false,
            resources: {
              resourceId: timeRecord.timeRecordId,
              type: 'timeRecord',
            },
          };
          map[timeRecord.day] = [...(map[timeRecord.day] ?? []), event];
        }
        return map;
      },
      {} as { [day: DateTimeString]: CustomCalendarEvent[] }
    )
  );

export const timeKeepingCalendarSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [
      (state: RootReducerState) => TimeKeepingByIdState,
      (state: RootReducerState) => TimeKeepingDayId[],
    ],
    { [day: DateTimeString]: CustomCalendarEvent[] }
  >(
    getTimeKeepingDayByIdState,
    (state) => getMonthlyCloseMeTimeKeepingDayIds(state, monthDateTime),
    (byId, ids) =>
      ids.reduce(
        (map, id) => {
          const timeKeepingDay = byId[id];
          if (timeKeepingDay) {
            const { timeKeepingEntries, timeKeepingDayId, state } =
              timeKeepingDay;
            const sortedEntries = timeKeepingEntries.sort(
              ({ startTime: aStartTime }, { startTime: bStartTime }) =>
                Date.parse(aStartTime) - Date.parse(bStartTime)
            );
            const event: CustomCalendarEvent = {
              id: timeKeepingDayId,
              title: '',
              start: new Date(sortedEntries[0].startTime),
              end: new Date(sortedEntries[sortedEntries.length - 1].endTime),
              allDay: false,
              resources: {
                resourceId: timeKeepingDayId,
                type: 'timeKeeping',
                state,
              },
            };
            const day = sortedEntries[0].startTime.split('T')[0];
            map[day] = [...(map[day] ?? []), event];
          }
          return map;
        },
        {} as { [day: DateTimeString]: CustomCalendarEvent[] }
      )
  );

export interface TimeAndLeaveCalendarEvent
  extends Omit<CustomCalendarEvent, 'resources'> {
  resources: CustomCalendarEvent[];
}

export const calendarEventsSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [
      (state: RootReducerState) => CustomCalendarEvent[],
      (state: RootReducerState) => {
        [day: DateTimeString]: CustomCalendarEvent[];
      },
      (state: RootReducerState) => {
        [day: DateTimeString]: CustomCalendarEvent[];
      },
    ],
    TimeAndLeaveCalendarEvent[]
  >(
    absencesMeCalendarSelector(monthDateTime),
    timeKeepingCalendarSelector(monthDateTime),
    timeRecordsCalendarSelector(monthDateTime),
    (absenceProposalEvents, timeKeepingEvents, timeRecordEvents) => [
      ...absenceProposalEvents.map((event) => ({
        ...event,
        resources: [event],
      })),
      ...distinct(
        Object.keys(timeKeepingEvents).concat(Object.keys(timeRecordEvents))
      ).reduce((currentArray, day) => {
        const _timeKeepingEvents = timeKeepingEvents[day] ?? [];
        const _timeRecordEvents = timeRecordEvents[day] ?? [];
        const _events = [..._timeKeepingEvents, ..._timeRecordEvents];
        if (_events.length > 0) {
          const event: TimeAndLeaveCalendarEvent = {
            id: uuid(),
            title: '',
            allDay: false,
            start: new Date(day),
            end: new Date(day),
            resources: _events,
          };
          currentArray.push(event);
        }
        return currentArray;
      }, [] as TimeAndLeaveCalendarEvent[]),
    ]
  );

export const missingWorkingDaysSelector = (monthDateTime: DateTimeString) =>
  createSelector<
    [(state: RootReducerState) => MonthlyClose],
    { [day: DateTimeString]: boolean }
  >(
    (state) => getMonthlyCloseMeByMonth(state, monthDateTime),
    (monthlyClose) => {
      if (monthlyClose) {
        const { timeKeepingDays, shouldBeWorkingDays } = monthlyClose;
        return shouldBeWorkingDays.reduce(
          (map, day) => {
            const timeKeepingDay = timeKeepingDays.find(
              ({ timeKeepingEntries }) =>
                moment(timeKeepingEntries[0]?.startTime).isSame(day, 'day')
            );
            if (!timeKeepingDay) {
              map[moment(day).toISOString(true).split('T')[0]] = true;
            }
            return map;
          },
          {} as { [day: DateTimeString]: boolean }
        );
      }
      return {};
    }
  );

export const timeTrackingLoadingSelector = createSelector<
  [
    (state: RootReducerState) => boolean,
    (state: RootReducerState) => boolean,
    (state: RootReducerState) => boolean,
  ],
  boolean
>(
  getAbsencesMeIsFetching,
  getTimeRecordsIsFetching,
  getMonthlyCloseMeIsFetching,
  (absencesMeIsFetching, timeRecordsMeIsFetching, monthlyCloseMeIsFetching) => {
    return (
      absencesMeIsFetching ||
      timeRecordsMeIsFetching ||
      monthlyCloseMeIsFetching
    );
  }
);
