import { combineReducers } from 'redux';
import { ProjectId } from '../../../../models/Types';
import meta, * as fromMeta from './meta';

export interface FavoritesMailState {
  meta: fromMeta.MetaState;
}

export default combineReducers<FavoritesMailState>({
  meta,
});

export const getActiveProjectInMessageCenter: (
  state: FavoritesMailState
) => ProjectId = (state) =>
  fromMeta.getActiveProjectInMessageCenter(state.meta);
