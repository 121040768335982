import React, { ReactNode } from 'react';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import Flex from '../../components/Flex';
import { PrioTheme } from '../../theme/types';
import { PrioSpinner, VirtualList } from '@prio365/prio365-react-library';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { makePrioStyles } from '../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  header: {
    padding: theme.spacing.regular,
    borderBottom: `1px solid ${theme.colors.application.border}`,
    backgroundColor: `${theme.colors.application.background.dark}`,
  },
  search: {
    width: '100%',
    height: `${theme.spacing.regular * 4}px`,
    padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    backgroundColor: `${theme.colors.application.background.selected}`,
    display: 'flex',
  },
  list: {
    height: '100%',
    marginTop: '0px !important',
    backgroundColor: `${theme.colors.application.background.default}`,
    '& p': {
      fontStyle: 'italic',
      textAlign: 'center',
      padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    },
  },
  content: {
    //backgroundColor: `${theme.colors.application.background.default}`,
    margin: `0px 0px 0px ${theme.spacing.regular}px`,
    overflow: 'auto',
    height: '100%',
  },
}));

interface ListMenuAreaProps {
  className?: string;
  menuItems: any;
  isLoading: boolean;
  menuItemIdKey: string;
  menuItemEmptyString?: string;
  listItem: React.FC<{
    index: number;
    key: string;
    isScrolling: boolean;
    isVisible: boolean;
  }>;
  pageNode: ReactNode;
}

export const ListMenuArea: React.FC<ListMenuAreaProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    menuItems,
    isLoading,
    pageNode,
    listItem,
    menuItemIdKey,
    menuItemEmptyString,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      <Flex.Column className={classes.root}>
        <Flex.Row className={classes.root}>
          <Flex.Column
            childrenGap={theme.spacing.regular}
            flex={1}
            className={classes.root}
          >
            <div className={classes.list}>
              <VirtualList.ArrowKeyList
                id="listMenuAreaVList"
                items={menuItems ? menuItems : []}
                enableContainer={false}
                loading={
                  isLoading
                    ? {
                        type: 'overlay',
                        indicator: (
                          <div className={classes.root}>
                            <PrioSpinner />
                          </div>
                        ),
                      }
                    : null
                }
                noItemsScreen={!isLoading ? <p>{menuItemEmptyString}</p> : null}
                onActiveChange={(index) => {
                  navigate(menuItems[index][menuItemIdKey]);
                }}
                rowHeight={64}
                rowRenderer={({ index, key, isScrolling, isVisible }) => {
                  const ListItemComponent = listItem;
                  return (
                    <ListItemComponent
                      index={index}
                      key={key}
                      isScrolling={isScrolling}
                      isVisible={isVisible}
                    />
                  );
                }}
              />
            </div>
          </Flex.Column>
          <Flex.Column
            childrenGap={theme.spacing.regular}
            flex={2}
            className={classes.content}
          >
            <Routes>
              <Route path=":id" element={pageNode} />
            </Routes>
          </Flex.Column>
        </Flex.Row>
      </Flex.Column>
    </>
  );
};

export default ListMenuArea;
