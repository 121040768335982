import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface CenterProps {
  className?: string;
  fullScreenHeight?: boolean;
  children?: React.ReactNode;
}

export const Center: React.FC<CenterProps> = (props) => {
  const classes = useStyles();
  const { className, children, fullScreenHeight } = props;

  return (
    <div
      className={classNames(
        'prio-flex-center-center',
        'prio-flex-column',
        classes.root,
        className,
        ...(fullScreenHeight ? ['prio-container-fullscreen-height'] : [])
      )}
    >
      {children}
    </div>
  );
};
