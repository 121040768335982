import { put } from 'redux-saga/effects';
import { wsDocumentCreatedFromTemplate } from '../actions/ws';
import { notification } from 'antd';
import i18n from '../../../i18n';
import { DocumentSuccessNotification } from '../components/Notifications/DocumentSuccessNotification';
import { Project } from '../../../models/Project';
import { SavedDocumentFromTemplateDto } from '../../../models/Document';

function* handleDocumentFromTemplateCreated(
  createdDocumentFromTemplate: SavedDocumentFromTemplateDto,
  project: Project
) {
  if (createdDocumentFromTemplate.driveItem && project.projectId) {
    const isRoot =
      createdDocumentFromTemplate?.driveItem?.parentReference?.path.match(
        /^(\/drive\/root:)$/
      );
    yield put(
      wsDocumentCreatedFromTemplate(
        createdDocumentFromTemplate?.driveItem,
        project.projectId,
        isRoot
          ? null
          : createdDocumentFromTemplate?.driveItem?.parentReference?.id,
        project.groupId
      )
    );

    const key = `open${Date.now()}`;
    notification.open({
      message: i18n.t('common:documents:success'),
      description: (
        <DocumentSuccessNotification
          driveItem={createdDocumentFromTemplate.driveItem}
          notificationKey={key}
          project={project}
        />
      ),
      key,
      duration: 10,
    });
  } else {
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t(
        'documents:newDocumentForm.errorMessages.createDocumentError'
      ),
    });
  }
}

export default handleDocumentFromTemplateCreated;
