import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useStyles = makePrioStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  messageButtonsDisabled: {
    cursor: 'not-allowed',
  },
}));

interface QuickActionButtonProps {
  className?: string;
  onClick: () => Promise<void>;
  tooltipText: string;
  icon: IconProp;
  color?: string;
  disabled?: boolean;
}

const QuickActionButton: React.FC<QuickActionButtonProps> = (props) => {
  //#region ------------------------------ Defaults
  const { onClick, tooltipText, icon, color, disabled } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsWaiting(true);
    onClick().then(() => setIsWaiting(false));
  };
  //#endregion

  return (
    <Tooltip title={tooltipText}>
      <FontAwesomeIcon
        onClick={isWaiting || disabled ? undefined : handleOnClick}
        icon={icon}
        color={
          isWaiting || disabled
            ? theme.old.palette.chromaticPalette.grey
            : color ?? theme.old.palette.primaryColor
        }
        className={classNames(classes.root, {
          [classes.messageButtonsDisabled]: isWaiting,
        })}
      />
    </Tooltip>
  );
};

export default QuickActionButton;
