import { PRIO } from '../../../constants';
import { NotificationId, CompanyId } from '../../../models/Types';

export const OPEN_NOTIFICATIONS_DRAWER = PRIO + 'OPEN_NOTIFICATIONS_DRAWER';
export const CLOSE_NOTIFICATIONS_DRAWER = PRIO + 'CLOSE_NOTIFICATIONS_DRAWER';
export const SET_NOTIFICATIONS_DRAWER_TAB =
  PRIO + 'SET_NOTIFICATIONS_DRAWER_TAB';
export const SET_NOTIFICATIONS_DRAWER_VIEW =
  PRIO + 'SET_NOTIFICATIONS_DRAWER_VIEW';
export const SET_NOTIFICATIONS_DRAWER_STATE =
  PRIO + 'SET_NOTIFICATIONS_DRAWER_STATE';

export type NotificationTabs = 'all' | 'absences' | 'trainings';
export type NotificationDrawerView = 'list' | 'notificationDetail';

interface PartialNotificationsDrawerState {
  tab?: NotificationTabs;
  view?: NotificationDrawerView;
  selectedNotification?: NotificationId;
  selectedCompany?: CompanyId;
}
export interface OpenNotificationsDrawerAction {
  type: string;
  predefinedState?: PartialNotificationsDrawerState;
}
export interface SetNotificationsDrawerTabAction {
  type: string;
  tab: NotificationTabs;
}
export interface SetNotificationsDrawerViewAction {
  type: string;
  view: NotificationDrawerView;
}
export interface SetNotificationsDrawerStateAction {
  type: string;
  partialState: PartialNotificationsDrawerState;
}

export const openNotificationsDrawer = (
  predefinedState?: PartialNotificationsDrawerState
) => ({
  type: OPEN_NOTIFICATIONS_DRAWER,
  predefinedState,
});
export const closeNotificationsDrawer = () => ({
  type: CLOSE_NOTIFICATIONS_DRAWER,
});
export const setNotificationsDrawerState: (
  partialState: PartialNotificationsDrawerState
) => SetNotificationsDrawerStateAction = (
  partialState: PartialNotificationsDrawerState
) => ({
  type: SET_NOTIFICATIONS_DRAWER_STATE,
  partialState,
});
export const setNotificationsDrawerTab: (
  tab: NotificationTabs
) => SetNotificationsDrawerTabAction = (tab: NotificationTabs) => ({
  type: SET_NOTIFICATIONS_DRAWER_TAB,
  tab,
});
export const setNotificationsDrawerView: (
  tab: NotificationDrawerView
) => SetNotificationsDrawerViewAction = (view: NotificationDrawerView) => ({
  type: SET_NOTIFICATIONS_DRAWER_VIEW,
  view,
});
