import React, { forwardRef, Component } from 'react';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDatePickerLocale from '../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

export declare type SharedTimeProps<Moment> = {
  format?: string;
  showNow?: boolean;
  showHour?: boolean;
  showMinute?: boolean;
  showSecond?: boolean;
  use12Hours?: boolean;
  hourStep?: number;
  minuteStep?: number;
  secondStep?: number;
  hideDisabledOptions?: boolean;
  defaultValue?: Moment;
};

interface BasisDatePickerProps {
  className?: string;
  disabled?: boolean;
  extraFooter?: JSX.Element;
  showToday?: boolean;
  showTime?: boolean | SharedTimeProps<Moment>;
  showNow?: boolean;
  open?: boolean;
  suffixIcon?: JSX.Element;
  suffixIconFontAwesome?: [IconPrefix, IconName];
  value?: Moment;
  defaultValue?: Moment;
  format?: string;
  onClick?: (
    mouseEvent: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void;
  onSelect?: (moment: Moment) => void;
  onOk?: (moment: Moment) => void;
  onBlur?: (focusEvent: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (focusEvent: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (moment: Moment) => void;
}

export const BasisDatePicker = forwardRef<
  Component<PickerProps<Moment>, any, any>,
  BasisDatePickerProps
>((props, ref) => {
  const {
    className,
    extraFooter,
    disabled,
    showToday,
    open,
    showTime,
    showNow,
    suffixIcon,
    suffixIconFontAwesome,
    value,
    defaultValue,
    format,
    onClick,
    onSelect,
    onOk,
    onBlur,
    onFocus,
    onChange,
  } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ useEffects
  //#endregion

  return (
    <DatePicker
      locale={datePickerLocale}
      disabled={disabled}
      format={format}
      ref={ref}
      value={value}
      defaultValue={defaultValue}
      suffixIcon={
        suffixIcon ? (
          suffixIcon
        ) : suffixIconFontAwesome ? (
          <FontAwesomeIcon icon={suffixIconFontAwesome} />
        ) : null
      }
      showTime={showTime}
      showNow={showNow}
      onClick={onClick}
      onSelect={onSelect}
      onBlur={onBlur}
      onOk={onOk}
      onFocus={onFocus}
      open={open}
      className={classNames(classes.root, className)}
      renderExtraFooter={extraFooter ? () => extraFooter : null}
      showToday={showToday}
      onChange={onChange}
    />
  );
});

export default BasisDatePicker;
