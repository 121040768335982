import { apiUrl } from '../../../api';
import { PRIO } from '../../../constants';
import { DashboardItem } from '../../../models/Dashboard';
import { DispatchAction } from '../../../models/Redux';

export const FETCH_DASHBOARD_ME_REQUEST = PRIO + 'FETCH_DASHBOARD_ME_REQUEST';
export const FETCH_DASHBOARD_ME_COMMIT = PRIO + 'FETCH_DASHBOARD_ME_COMMIT';
export const FETCH_DASHBOARD_ME_ROLLBACK = PRIO + 'FETCH_DASHBOARD_ME_ROLLBACK';

export const fetchDashboardActionMe: () => DispatchAction<
  unknown,
  DashboardItem[]
> = () => ({
  type: FETCH_DASHBOARD_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_DASHBOARD_ME_COMMIT,
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_DASHBOARD_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'dashboard:messages.errorMessages.fetchDashboardError',
          timeout: 6,
        },
      },
    },
  },
});
