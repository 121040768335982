import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';
import {
  CopyDriveItem,
  DriveItem,
  DriveItemFolderDto,
} from '../../../models/Drive';
import { DriveItemId, GroupId } from '../../../models/Types';
import { SAGA_REBUILD } from '../../../util/sagas';
import {
  FETCH_DRIVE_ITEMS_COMMIT,
  FETCH_DRIVE_ITEMS_REQUEST,
  FETCH_DRIVE_ITEMS_ROLLBACK,
  DRIVE_ITEM_RENAMED,
  DRIVE_ITEM_DELETED,
  DRIVE_ITEM_NEW_FOLDER_CREATED,
  PURGE_DRIVE_ITEMS,
  SET_CURRENT_DRIVE_ITEM_ID,
  COPY_ITEMS_INTO_FOLDER_COMMIT,
  COPY_ITEMS_INTO_FOLDER_REQUEST,
  COPY_ITEMS_INTO_FOLDER_ROLLBACK,
  DRIVE_ITEMS_DELETED,
  DRIVE_ITEM_CREATED,
  ADD_EML_TO_DRIVE_ITEM_COMMIT,
  UPDATE_DRIVE_ITEM,
} from '../actions';
import {
  WS_DOCUMENT_CREATED,
  WS_DOCUMENT_CREATED_FROM_TEMPLATE,
  WS_FETCH_ARCHIVE_UNZIPPED,
  WS_FETCH_DOCUMENT_COPIED_OR_MOVED,
} from '../actions/ws';
import { TRANSLATED_BACKEND_ERROR } from '../../../constants';
import i18n from '../../../i18n';
import { isDriveItemFolder } from '../util';

const PURGE_DRIVE_ITEMS_THRESHOLD = 1024;
const PURGE_DRIVE_ITEMS_NEW_SIZE = PURGE_DRIVE_ITEMS_THRESHOLD - 128;

const sortHelper = (a: DriveItem, b: DriveItem) =>
  isDriveItemFolder(a) && !isDriveItemFolder(b)
    ? -1
    : isDriveItemFolder(b) && !isDriveItemFolder(a)
    ? 1
    : a.name.localeCompare(b.name);

export interface CurrentFolderState {
  meta: CurrentFolderMeta;
  folders: FolderMap;
  currentDriveItemId?: DriveItemId;
  disabledItems: DriveItemId[];
  redirect: RedirectState;
}

export interface FolderMap {
  [driveItemId: string]: FolderState;
}

export interface FolderState {
  dateTime: number;
  item?: DriveItem;
  value?: DriveItem[];
  nextLink?: string;
}

export interface CurrentFolderMeta {
  isFetching: boolean;
  foldersToFetch: {
    [driveItemId: string]: {
      isFetching: boolean;
      isFetchingWithNextLink: boolean;
      errorMessage?: string;
    };
  };
  hasError: boolean;
  errorMessage?: string;
}

const folders: Reducer<FolderMap, any> = (state = {}, action) => {
  if (action.type === CLEAR_PRIO_CACHE) {
    return {};
  }

  if (action.type === PURGE_DRIVE_ITEMS) {
    return purgeDriveItems(state);
  }

  if (!action.meta) return state;

  if (action.type === WS_FETCH_DOCUMENT_COPIED_OR_MOVED) {
    const {
      payload,
      meta: { destinationGroupId },
    } = action;
    const parentFolderIsRoot =
      (payload as DriveItem)?.parentReference?.path === '/drive/root:';
    const key = parentFolderIsRoot
      ? `root-group-${destinationGroupId}`
      : (payload as DriveItem).parentReference?.id;
    return {
      ...state,
      [key]: {
        item: item(state[key]?.item, action) ?? state[key]?.item,
        value: value(state[key]?.value, action) ?? state[key]?.value,
        dateTime: Date.now(),
      },
    };
  }

  if (action.type === WS_FETCH_ARCHIVE_UNZIPPED) {
    const {
      payload,
      meta: { groupId },
    } = action;
    const parentFolderIsRoot =
      (payload as DriveItem)?.parentReference?.path === '/drive/root:';
    const key = parentFolderIsRoot
      ? `root-group-${groupId}`
      : (payload as DriveItem).parentReference?.id;
    return {
      ...state,
      [key]: {
        item: item(state[key]?.item, action) ?? state[key]?.item,
        value: value(state[key]?.value, action) ?? state[key]?.value,
        dateTime: Date.now(),
      },
    };
  }

  if (
    action.type === COPY_ITEMS_INTO_FOLDER_REQUEST ||
    action.type === COPY_ITEMS_INTO_FOLDER_COMMIT ||
    action.type === COPY_ITEMS_INTO_FOLDER_ROLLBACK
  ) {
    const {
      meta: {
        originalDriveItems,
        driveItemsToMove,
        sourceDriveItemId,
        sourceIsRoot,
        sourceGroupId,
        destinationDriveItemId,
        destinationIsRoot,
        destinationGroupId,
        temporaryDriveItemsToMove,
      },
    } = action;

    const sourceKey = sourceIsRoot
      ? `root-group-${sourceGroupId}`
      : sourceDriveItemId;
    const destinationKey = destinationIsRoot
      ? `root-group-${destinationGroupId}`
      : destinationDriveItemId;

    const dateTimeSource =
      action.type === COPY_ITEMS_INTO_FOLDER_COMMIT
        ? Date.now()
        : state[sourceKey]?.dateTime;

    const dateTimeDestination =
      action.type === COPY_ITEMS_INTO_FOLDER_COMMIT
        ? Date.now()
        : state[destinationKey]?.dateTime;

    const driveItems: DriveItem[] =
      action.type === COPY_ITEMS_INTO_FOLDER_REQUEST
        ? state[sourceKey]?.value
            ?.filter((item) =>
              (driveItemsToMove as CopyDriveItem[]).find(
                (moveItem) => moveItem.driveItemId === item.id
              )
            )
            ?.map((item) => {
              const movedName = (driveItemsToMove as CopyDriveItem[]).find(
                (moveItem) => moveItem.driveItemId === item.id
              ).name;
              return {
                ...item,
                id: temporaryDriveItemsToMove[item.id] ?? item.id,
                name: movedName,
                parentReference: {
                  ...item.parentReference,
                  id: state[destinationKey]?.item?.id,
                },
              };
            })
        : state[destinationKey]?.value
            ?.filter((item) =>
              (driveItemsToMove as CopyDriveItem[]).find(
                (moveItem) => moveItem.driveItemId === item.id
              )
            )
            ?.map((item) => ({
              ...item,
              id: temporaryDriveItemsToMove[item.id] ?? item.id,
              name: (originalDriveItems as DriveItem[]).find(
                (originalItem) => originalItem.id === item.id
              )?.name,
              parentReference: {
                ...item.parentReference,
                id: state[sourceKey]?.item.id,
              },
            }));

    return {
      ...state,
      [sourceKey]: {
        item:
          item(state[sourceKey]?.item, {
            ...action,
            isSource: true,
            driveItems,
          }) ?? state[sourceKey]?.item,
        value:
          value(state[sourceKey]?.value, {
            ...action,
            isSource: true,
            driveItems,
          }) ?? state[sourceKey]?.value,
        dateTime: dateTimeSource,
      },
      [destinationKey]: {
        item:
          item(state[destinationKey]?.item, {
            ...action,
            isSource: false,
            driveItems,
          }) ?? state[destinationKey]?.item,
        value:
          value(state[destinationKey]?.value, {
            ...action,
            isSource: false,
            driveItems,
          }) ?? state[destinationKey]?.value,
        dateTime: dateTimeDestination,
        nextLink:
          nextLink(state[destinationKey]?.nextLink, {
            ...action,
            isTarget: false,
            driveItems,
          }) ?? state[destinationKey]?.nextLink,
      },
    };
  }

  if (action.type === ADD_EML_TO_DRIVE_ITEM_COMMIT) {
    const {
      payload,
      meta: { destinationGroupId },
    } = action;
    const key =
      payload.parentReference?.path === '/drive/root:'
        ? `root-group-${destinationGroupId}`
        : payload.parentReference.id;
    const dateTime =
      action.type === FETCH_DRIVE_ITEMS_COMMIT
        ? Date.now()
        : state[key]?.dateTime;
    return {
      ...state,
      [key]: {
        item: item(state[key]?.item, action) ?? state[key]?.item,
        value: value(state[key]?.value, action) ?? state[key]?.value,
        dateTime,
        nextLink: nextLink(state[key]?.nextLink, action),
      },
    };
  }

  if (action.type === UPDATE_DRIVE_ITEM) {
    const {
      driveItem,
      meta: { groupId, isParentRoot },
    } = action;
    const key = isParentRoot
      ? `root-group-${groupId}`
      : driveItem.parentReference.id;
    const dateTime = Date.now();
    if (state[key]) {
      return {
        ...state,
        [key]: {
          item: state[key].item,
          value: value(state[key]?.value, action),
          dateTime,
          nextLink: state[key]?.nextLink,
        },
      };
    }
    return state;
  }

  const {
    meta: { driveItemId, groupId },
  } = action;

  if (!driveItemId && !groupId) {
    return state;
  }
  const key = driveItemId ?? `root-group-${groupId}`;

  const dateTime =
    action.type === FETCH_DRIVE_ITEMS_COMMIT
      ? Date.now()
      : state[key]?.dateTime;

  if (action.type === DRIVE_ITEM_DELETED) {
    const { driveItemId } = action;

    const { [driveItemId]: toDelete, ...rest } = state;
    if (toDelete) {
      return {
        ...rest,
        [key]: {
          item: item(state[key]?.item, action) ?? state[key]?.item,
          value: value(state[key]?.value, action) ?? state[key]?.value,
          dateTime,
          nextLink:
            nextLink(state[key]?.nextLink, action) ?? state[key]?.nextLink,
        },
      };
    }
    return {
      ...state,
      [key]: {
        item: item(state[key]?.item, action) ?? state[key]?.item,
        value: value(state[key]?.value, action) ?? state[key]?.value,
        dateTime,
        nextLink:
          nextLink(state[key]?.nextLink, action) ?? state[key]?.nextLink,
      },
    };
  }

  if (action.type === DRIVE_ITEMS_DELETED) {
    const { driveItemIds } = action;
    const newState = (driveItemIds as DriveItemId[]).reduce(
      (previous, current) => {
        const { [current]: toDelete, ...rest } = previous;
        if (toDelete) {
          return rest;
        }
        return previous;
      },
      state
    );
    return {
      ...newState,
      [key]: {
        item: item(state[key]?.item, action) ?? state[key]?.item,
        value: value(state[key]?.value, action) ?? state[key]?.value,
        dateTime,
        nextLink:
          nextLink(state[key]?.nextLink, action) ?? state[key]?.nextLink,
      },
    };
  }

  if (action?.meta?.nextLink) {
    return {
      ...state,
      [key]: {
        item: item(state[key]?.item, action) ?? state[key]?.item,
        value: value(state[key]?.value, action) ?? [],
        dateTime,
        nextLink: action?.payload?.nextLink,
      },
    };
  }
  return {
    ...state,
    [key]: {
      item: item(state[key]?.item, action) ?? state[key]?.item,
      value: value(state[key]?.value, action) ?? state[key]?.value,
      dateTime,
      nextLink: nextLink(state[key]?.nextLink, action),
    },
  };
};

const purgeDriveItems: (state: FolderMap) => FolderMap = (state) => {
  const entries = Object.entries(state);
  if (entries.length < PURGE_DRIVE_ITEMS_THRESHOLD) {
    return state;
  }
  const newEntries = entries
    .sort(
      ([key1, data1], [key2, data2]) =>
        (data2.dateTime ?? 0) - (data1.dateTime ?? 0)
    )
    .slice(0, PURGE_DRIVE_ITEMS_NEW_SIZE);
  return Object.fromEntries(newEntries);
};

const item: Reducer<DriveItem | null, any> = (state = null, action) => {
  switch (action.type) {
    case FETCH_DRIVE_ITEMS_COMMIT: {
      const { payload } = action;
      return (payload as DriveItemFolderDto).item;
    }

    case DRIVE_ITEM_RENAMED: {
      const { driveItem } = action;
      if (state?.id === driveItem.id) {
        return driveItem;
      }
      return state;
    }

    case DRIVE_ITEMS_DELETED: {
      const { driveItemIds } = action;
      if ((driveItemIds as DriveItemId[]).includes(state?.id)) return null;

      return state;
    }

    case DRIVE_ITEM_DELETED: {
      const { driveItemId } = action;
      if (state?.id === driveItemId) return null;

      return state;
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};
const value: Reducer<DriveItem[], any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_DRIVE_ITEMS_COMMIT: {
      const { payload, meta } = action;
      if (meta.nextLink) {
        return state.concat((payload as DriveItemFolderDto).children);
      } else {
        return (payload as DriveItemFolderDto).children;
      }
    }

    case DRIVE_ITEM_RENAMED: {
      const { driveItem } = action;
      return state.map((item) => (driveItem.id === item.id ? driveItem : item));
    }
    case DRIVE_ITEMS_DELETED: {
      const { driveItemIds } = action;
      return state.filter(
        (driveItem) => !(driveItemIds as DriveItemId[]).includes(driveItem.id)
      );
    }

    case DRIVE_ITEM_DELETED: {
      const { driveItemId } = action;
      return state.filter((driveItem) => driveItem.id !== driveItemId);
    }

    case UPDATE_DRIVE_ITEM: {
      const { driveItem } = action;
      return state.map((item) => (driveItem.id === item.id ? driveItem : item));
    }

    case WS_FETCH_DOCUMENT_COPIED_OR_MOVED: {
      const {
        payload,
        meta: { sourceDriveItemId, temporarySourceDriveItemId },
      } = action;
      return [
        ...state.filter(
          (stateItem) =>
            (temporarySourceDriveItemId ?? sourceDriveItemId) !== stateItem.id
        ),
        payload as DriveItem,
      ].sort(sortHelper);
    }

    case WS_FETCH_ARCHIVE_UNZIPPED: {
      const {
        payload,
        meta: { driveItemId },
      } = action;
      return [
        ...state.filter((stateItem) => driveItemId !== stateItem.id),
        payload as DriveItem,
      ].sort(sortHelper);
    }

    case WS_DOCUMENT_CREATED: {
      const { payload } = action;
      return ([...state, payload] as DriveItem[]).sort(sortHelper);
    }

    case WS_DOCUMENT_CREATED_FROM_TEMPLATE: {
      const { payload } = action;
      return ([...state, payload] as DriveItem[]).sort(sortHelper);
    }

    case ADD_EML_TO_DRIVE_ITEM_COMMIT:
    case DRIVE_ITEM_CREATED: {
      const { payload } = action;
      return ([...state, payload] as DriveItem[]).sort(sortHelper);
    }

    case DRIVE_ITEM_NEW_FOLDER_CREATED: {
      const { driveItem } = action;
      return ([...state, driveItem] as DriveItem[]).sort(sortHelper);
    }

    case COPY_ITEMS_INTO_FOLDER_REQUEST: {
      const {
        meta: { driveItemsToMove },
        driveItems,
        isSource,
      }: {
        meta: { driveItemsToMove: CopyDriveItem[] };
        driveItems: DriveItem[];
        isSource: boolean;
      } = action;

      const driveItemIds = (driveItems ?? []).map((driveItem) => driveItem.id);

      if (!isSource) {
        return (
          [
            ...state,
            ...(driveItems ?? []).filter(
              (item) => !state.find((stateItem) => stateItem.id === item.id)
            ),
          ] as DriveItem[]
        ).sort(sortHelper);
      }

      return state.filter(
        (item) =>
          !driveItemIds.includes(item.id) ||
          !driveItemsToMove.find((moveItem) => moveItem.driveItemId === item.id)
            ?.deleteSourceDriveItem
      );
    }

    case COPY_ITEMS_INTO_FOLDER_ROLLBACK: {
      const {
        driveItems,
        isSource,
      }: {
        driveItems: DriveItem[];
        isSource: boolean;
      } = action;

      const driveItemIds = (driveItems ?? []).map((driveItem) => driveItem.id);

      if (!isSource) {
        return state.filter((item) => !driveItemIds.includes(item.id));
      }
      return (
        [
          ...state,
          ...(driveItems ?? []).filter(
            (item) => !state.find((stateItem) => stateItem.id === item.id)
          ),
        ] as DriveItem[]
      ).sort(sortHelper);
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

const nextLink: Reducer<string | string, any> = (state = null, action) => {
  switch (action.type) {
    case FETCH_DRIVE_ITEMS_COMMIT: {
      const { payload } = action;
      return (payload as DriveItemFolderDto).nextLink;
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

const meta: Reducer<CurrentFolderMeta, any> = (
  state = { isFetching: false, hasError: false, foldersToFetch: {} },
  action
) => {
  switch (action.type) {
    case FETCH_DRIVE_ITEMS_REQUEST: {
      const {
        meta: { driveItemId, nextLink },
      } = action;
      return {
        ...state,
        foldersToFetch: {
          ...state.foldersToFetch,
          [driveItemId]: {
            isFetching: true,
            isFetchingWithNextLink: nextLink ? true : false,
          },
        },
        isFetching: true,
      };
    }
    case FETCH_DRIVE_ITEMS_COMMIT: {
      const {
        meta: { driveItemId },
      } = action;
      return {
        ...state,
        foldersToFetch: {
          ...state.foldersToFetch,
          [driveItemId]: {
            isFetching: false,
            isFetchingWithNextLink: false,
          },
        },
        isFetching: false,
      };
    }
    case FETCH_DRIVE_ITEMS_ROLLBACK: {
      const {
        payload,
        meta: { driveItemId },
      } = action;
      const errorMessage = TRANSLATED_BACKEND_ERROR.includes(
        payload?.TranslatedMessage
      )
        ? i18n.t('documents:errorMessages.fetchDriveItemsError')
        : payload?.TranslatedMessage;

      return {
        ...state,
        foldersToFetch: {
          ...state.foldersToFetch,
          [driveItemId]: {
            isFetching: false,
            isFetchingWithNextLink: false,
            errorMessage,
          },
        },
        isFetching: false,
        hasError: true,
        errorMessage: 'documents:errorMessages.fetchDriveItemsError',
      };
    }

    case SAGA_REBUILD:
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return {
        isFetchingNextLink: false,
        isFetching: false,
        hasError: false,
        driveItemFolderId: null,
        foldersToFetch: {},
      };
    }
    default:
      return state;
  }
};

const currentDriveItemId: Reducer<DriveItemId | null, any> = (
  state = null,
  action
) => {
  switch (action.type) {
    case SET_CURRENT_DRIVE_ITEM_ID: {
      const { driveItemId } = action;
      if (!driveItemId) return null;
      return driveItemId;
    }

    default:
      return state;
  }
};

const disabledItems: Reducer<DriveItemId[], any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_DRIVE_ITEMS_COMMIT: {
      const { payload } = action;
      const ids = (payload as DriveItemFolderDto).children.map(
        (child) => child.id
      );
      return state.filter((stateItemId) => !ids.includes(stateItemId));
    }

    case COPY_ITEMS_INTO_FOLDER_REQUEST: {
      const {
        meta: { originalDriveItems, temporaryDriveItemsToMove },
      } = action;
      return [
        ...state,
        ...((originalDriveItems ?? []) as DriveItem[])
          .filter(
            (item) => !Object.keys(temporaryDriveItemsToMove).includes(item.id)
          )
          .map((item) => item.id),
        ...Object.values<string>(temporaryDriveItemsToMove),
      ];
    }

    case COPY_ITEMS_INTO_FOLDER_ROLLBACK: {
      const {
        meta: { originalDriveItems, temporaryDriveItemsToMove },
      } = action;
      return state.filter(
        (stateItemId) =>
          !(
            ((originalDriveItems ?? []) as DriveItem[]).find(
              (item) => item.id === stateItemId
            ) ||
            Object.values<string>(temporaryDriveItemsToMove).includes(
              stateItemId
            )
          )
      );
    }

    case WS_FETCH_ARCHIVE_UNZIPPED: {
      const {
        meta: { driveItemId },
      } = action;

      return state.filter((id) => !(id === driveItemId));
    }
    case WS_FETCH_DOCUMENT_COPIED_OR_MOVED: {
      const {
        meta: { sourceDriveItemId, temporarySourceDriveItemId },
      } = action;

      return state.filter(
        (id) => !(id === sourceDriveItemId || id === temporarySourceDriveItemId)
      );
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

interface RedirectState {
  [key: DriveItemId]: string;
}

const redirect: Reducer<RedirectState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DRIVE_ITEMS_COMMIT: {
      const { payload } = action;
      const ids = (payload as DriveItemFolderDto).children.map(
        (child) => child.id
      );
      return Object.keys(state).reduce((map, stateItemId) => {
        if (!ids.includes(stateItemId)) {
          return {
            ...map,
            [stateItemId]: state[stateItemId],
          };
        }
        return map;
      }, {});
    }

    case COPY_ITEMS_INTO_FOLDER_REQUEST: {
      const {
        meta: { temporaryDriveItemsToMove },
      } = action;
      return {
        ...state,
        ...temporaryDriveItemsToMove,
      };
    }

    case COPY_ITEMS_INTO_FOLDER_ROLLBACK: {
      const {
        meta: { temporaryDriveItemsToMove },
      } = action;
      return Object.keys(state)
        .filter(
          (driveItemId) =>
            !Object.keys(
              temporaryDriveItemsToMove as { [key: string]: string }
            ).includes(driveItemId)
        )
        .reduce(
          (map, driveItemId) => ({
            ...map,
            [driveItemId]: state[driveItemId],
          }),
          {}
        );
    }

    case WS_FETCH_ARCHIVE_UNZIPPED: {
      const {
        meta: { driveItemId },
      } = action;

      return Object.keys(state)
        .filter((x) => driveItemId !== x)
        .reduce(
          (map, driveItemId) => ({
            ...map,
            [driveItemId]: state[driveItemId],
          }),
          {}
        );
    }
    case WS_FETCH_DOCUMENT_COPIED_OR_MOVED: {
      const {
        meta: { sourceDriveItemId },
      } = action;

      return Object.keys(state)
        .filter((driveItemId) => sourceDriveItemId !== driveItemId)
        .reduce(
          (map, driveItemId) => ({
            ...map,
            [driveItemId]: state[driveItemId],
          }),
          {}
        );
    }

    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  folders,
  meta,
  currentDriveItemId,
  disabledItems,
  redirect,
});

export const getItem: (
  state: CurrentFolderState,
  driveItemId: DriveItemId | 'root'
) => DriveItem = (state: CurrentFolderState, driveItemId) =>
  state.folders[driveItemId]?.item;

export const getChildren: (
  state: CurrentFolderState,
  driveItemId: DriveItemId | 'root'
) => DriveItem[] = (state: CurrentFolderState, driveItemId) => {
  return state.folders[driveItemId]?.value;
};

export const getMeta = (state: CurrentFolderState) => state.meta;

export const getErrorOfDriveItem = (
  state: CurrentFolderState,
  driveItemId: DriveItemId
) => state.meta.foldersToFetch[driveItemId]?.errorMessage ?? null;

export const getDriveItemIsFetching = (
  state: CurrentFolderState,
  driveItemId: string
) =>
  state.meta.foldersToFetch[driveItemId] ?? {
    isFetching: false,
    isFetchingWithNextLink: false,
  };

export const getNextLink = (
  state: CurrentFolderState,
  driveItemId: DriveItemId
) => state.folders[driveItemId]?.nextLink;

export const getCurrentDriveItem: (state: CurrentFolderState) => DriveItem = (
  state: CurrentFolderState
) => state.folders[state.currentDriveItemId]?.item;

export const isDisabledDriveItem: (
  state: CurrentFolderState,
  driveItemId: DriveItemId
) => boolean = (state, driveItemId) =>
  state.disabledItems.includes(driveItemId);

export const getDisabledItems: (state: CurrentFolderState) => DriveItemId[] = (
  state
) => state.disabledItems;

export const getRedirect: (
  state: CurrentFolderState,
  driveItemId: DriveItemId
) => string = (state, driveItemId) => state.redirect[driveItemId];

export const getRecursiveDriveItemIdPath: (
  state: CurrentFolderState,
  driveItemId: DriveItemId,
  groupId: GroupId
) => DriveItemId[] = (state, driveItemId, groupId) => {
  const driveItem = state.folders[driveItemId]?.item;
  if (!driveItemId || !groupId || !driveItem) {
    return [];
  }
  if (driveItemId === `root-group-${groupId}`) {
    return [`root-group-${groupId}`];
  }
  if (driveItem.parentReference?.path === '/drive/root:') {
    return [`root-group-${groupId}`, driveItem.id];
  }
  return [
    ...getRecursiveDriveItemIdPath(
      state,
      driveItem.parentReference.id,
      groupId
    ),
    driveItem.id,
  ];
};

export const getPartialCurrentFolderState: (
  state: CurrentFolderState,
  driveItemIds: DriveItemId[]
) => FolderMap = (state, driveItemIds) =>
  driveItemIds.reduce(
    (map, driveItemId) => ({
      ...map,
      [driveItemId]: state.folders?.[driveItemId],
    }),
    {}
  );
