import classNames from 'classnames';
import { CSSProperties } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { makePrioStyles } from '../../theme/utils';
import React from 'react';

interface DroppableElementProps<DataType extends object> {
  id?: React.Key;

  accept: string[];
  object?: DataType;
  children?: React.ReactNode;
  style?: CSSProperties;
  activeOverlay: boolean;
  disable?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    object: DataType,
    isOverCurrent: boolean
  ) => boolean;
  onDrop?: (
    item: any,
    monitor: DropTargetMonitor<unknown, unknown>,
    targetItem: DataType
  ) => object;
  collect?: (monitor: DropTargetMonitor<unknown, unknown>) => any;
  onOver?: (isOver: boolean, canDrop: boolean, id: React.Key) => void;
  onAction?: DynamicActions;
}

interface DynamicActions {
  [key: string]: Function;
}

const useStyles = makePrioStyles((theme) => ({
  root: {
    zIndex: 99999,
    height: '100%',
    width: '100%',
  },
  overlay: {
    backgroundColor: 'blue',
  },
  activeDroptarget: {
    outline: `1px dashed ${theme.old.palette.primaryColor}`,
    outlineWidth: 2,
    outlineOffset: '-2px',
  },
}));
const DroppableElement = <DataType extends object>(
  props: DroppableElementProps<DataType>
) => {
  //#region ------------------------------ Defaults
  const {
    accept,
    onAction,
    children,
    id,
    object,
    style,
    activeOverlay,
    disable,
    onDrop,
    collect,
    onOver,
  } = props;
  const classes = useStyles();

  const [{ isOver, canDrop, isOverCurrent }, drop] = useDrop({
    accept: accept,
    canDrop: disable
      ? (item, monitor) => !disable(item, monitor, object, isOverCurrent)
      : () => true,
    drop: (item, monitor) => {
      if (onDrop) {
        return onDrop(item, monitor, object);
      }
      return object;
    },
    collect: collect
      ? collect
      : (monitor) => ({
          isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
          isOverCurrent: !!monitor.isOver({ shallow: true }),
        }),
    ...onAction,
  });

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const isOverelement = () => {
    onOver && onOver(isOver, canDrop, id);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion
  isOverelement();

  //@ts-ignore
  return (
    <div
      ref={drop}
      style={style ?? null}
      className={classNames(
        classes.root,
        isOver && isOverCurrent && canDrop && activeOverlay
          ? classes.activeDroptarget
          : null
      )}
    >
      {children}
    </div>
  );
};

export default DroppableElement;
