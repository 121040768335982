import { useContext, useMemo } from 'react';
import { CompaniesContext, CompaniesContextProps } from '../context';

export const useCompaniesContext: (
  includeArchived?: boolean
) => CompaniesContextProps = (includeArchived = false) => {
  const context = useContext(CompaniesContext);

  return useMemo(() => {
    const { companies, ...restContext } = context;
    const _companies = includeArchived
      ? companies
      : companies.filter((company) => !company.isArchived);
    return {
      companies: _companies,
      ...restContext,
    };
  }, [context, includeArchived]);
};

export default useCompaniesContext;
