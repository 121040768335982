import React, { CSSProperties, useEffect, useRef } from 'react';

import { makePrioStyles } from '../theme/utils';
import PrioSpinner from './PrioSpinner';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
  },
  overlay: {
    transition: '0.3s',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
  },
}));
interface LoadingOverlayProps {
  children: React.ReactNode;
  isLoading: boolean;
  loadingText?: string;
  placeHolder?: {
    isShowing: boolean;
    placeHolder: JSX.Element;
  };
  bodyStyles?: CSSProperties;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles(props);

  const { isLoading, children, placeHolder, loadingText, bodyStyles } = props;
  const overlayRef = useRef<HTMLDivElement>(null);
  //#endregion
  //#region -------------------------------- State declaration
  //#endregion
  //#region -------------------------------- Methods
  //#endregion
  //#region -------------------------------- Handle methods
  //#endregion
  //#region -------------------------------- Hooks
  useEffect(() => {
    if (overlayRef && overlayRef.current) {
      overlayRef.current.style.opacity = isLoading ? '1' : '0';
      overlayRef.current.style.zIndex = isLoading ? '999999999' : '0';
      overlayRef.current.style.visibility = isLoading ? 'visible' : 'hidden';
    }
  }, [isLoading]);

  //#endregion

  return (
    <div className={classes.root} style={bodyStyles}>
      <div ref={overlayRef} className={classes.overlay}>
        <PrioSpinner alignSelf tip={loadingText} />
      </div>

      {placeHolder?.isShowing ? placeHolder.placeHolder : children}
    </div>
  );
};

export default LoadingOverlay;
