import { Reducer, combineReducers } from 'redux';
import { ReduxAction } from '../../../models/Redux';
import { CLEAR_PRIO_CACHE, RESET_PRIO_CACHE } from '../../../actions';
import { DateTimeString, TimeKeepingDayId } from '../../../models/Types';
import { MonthlyClose, TimeKeepingDay } from '../../../models/TimeKeeping';
import {
  CREATE_TIMEKEEPING_DAY,
  DELETE_TIMEKEEPING_DAY,
  FETCH_MONTHLY_CLOSE_ME_COMMIT,
  FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT,
  UPDATE_TIMEKEEPING_DAY,
} from '../actions';
import { distinct } from '../../../util';

export interface TimeKeepingDaysMeReducerState {
  byId: TimeKeepingByIdState;
  ids: string[];
}

export const initialState: TimeKeepingDaysMeReducerState = {
  byId: {},
  ids: [],
};

declare type MetaObject = {
  from: DateTimeString;
  to: DateTimeString;
  isInitialFetch: boolean;
  monthDateTime: DateTimeString;
  timeKeepingDayId: TimeKeepingDayId;
};

declare type PayloadObject = MonthlyClose[] &
  MonthlyClose &
  TimeKeepingDay[] &
  TimeKeepingDay;

export interface TimeKeepingByIdState {
  [timeKeepingDayId: TimeKeepingDayId]: TimeKeepingDay;
}

const byId: Reducer<
  TimeKeepingByIdState,
  ReduxAction<MetaObject, PayloadObject>
> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        payload,
        meta: { isInitialFetch },
      } = action as ReduxAction<MetaObject, MonthlyClose[]>;
      return payload.reduce(
        (map, monthlyClose) => ({
          ...map,
          ...monthlyClose.timeKeepingDays.reduce(
            (timeKeepingDaysMap, timeKeepingDay) => ({
              ...timeKeepingDaysMap,
              [timeKeepingDay.timeKeepingDayId]: timeKeepingDay,
            }),
            {}
          ),
        }),
        isInitialFetch ? {} : state
      );
    }
    case FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        payload: { timeKeepingDays },
      } = action as ReduxAction<MetaObject, MonthlyClose>;
      return timeKeepingDays.reduce(
        (map, timeKeepingDay) => ({
          ...map,
          [timeKeepingDay.timeKeepingDayId]: timeKeepingDay,
        }),
        state
      );
    }
    case CREATE_TIMEKEEPING_DAY: {
      const { payload } = action as ReduxAction<MetaObject, TimeKeepingDay>;
      return {
        ...state,
        [payload.timeKeepingDayId]: payload,
      };
    }
    case UPDATE_TIMEKEEPING_DAY: {
      const {
        meta: { timeKeepingDayId },
        payload,
      } = action as ReduxAction<MetaObject, TimeKeepingDay>;
      const { [timeKeepingDayId]: _, ...rest } = state;
      return {
        ...rest,
        [payload.timeKeepingDayId]: payload,
      };
    }
    case DELETE_TIMEKEEPING_DAY: {
      const {
        meta: { timeKeepingDayId },
      } = action;
      const { [timeKeepingDayId]: _, ...rest } = state;
      return rest;
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default: {
      return state;
    }
  }
};

const ids: Reducer<
  TimeKeepingDayId[],
  ReduxAction<MetaObject, PayloadObject>
> = (state = initialState.ids, action) => {
  switch (action.type) {
    case FETCH_MONTHLY_CLOSE_ME_COMMIT: {
      const {
        payload,
        meta: { isInitialFetch },
      } = action as ReduxAction<MetaObject, MonthlyClose[]>;
      return payload.reduce(
        (ids, monthlyClose) =>
          distinct([
            ...ids,
            ...monthlyClose.timeKeepingDays.map(
              (timeKeepingDay) => timeKeepingDay.timeKeepingDayId
            ),
          ]),
        isInitialFetch ? [] : state
      );
    }
    case FETCH_SINGLE_MONTHLY_CLOSE_ME_COMMIT: {
      const { payload } = action as ReduxAction<MetaObject, MonthlyClose>;
      return distinct([
        ...state,
        ...payload.timeKeepingDays.map(
          (timeKeepingDay) => timeKeepingDay.timeKeepingDayId
        ),
      ]);
    }
    case CREATE_TIMEKEEPING_DAY: {
      const {
        payload: { timeKeepingDayId },
      } = action;
      return [...state, timeKeepingDayId];
    }
    case UPDATE_TIMEKEEPING_DAY: {
      const {
        meta: { timeKeepingDayId },
        payload: { timeKeepingDayId: newTimeKeepingDayId },
      } = action as ReduxAction<MetaObject, TimeKeepingDay>;
      return state.map((id) =>
        id === timeKeepingDayId ? newTimeKeepingDayId : id
      );
    }
    case DELETE_TIMEKEEPING_DAY: {
      const {
        meta: { timeKeepingDayId },
      } = action;
      return state.filter((id) => id !== timeKeepingDayId);
    }
    case RESET_PRIO_CACHE:
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers<TimeKeepingDaysMeReducerState>({
  byId,
  ids,
});

export const getTimeKeepingByIdState: (
  state: TimeKeepingDaysMeReducerState
) => TimeKeepingByIdState = ({ byId }) => byId;

export const getTimeKeepingIdsState: (
  state: TimeKeepingDaysMeReducerState
) => TimeKeepingDayId[] = ({ ids }) => ids;

export const getAllTimeKeepingDaysMe: (
  state: TimeKeepingDaysMeReducerState
) => TimeKeepingDay[] = ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((timeKeepingDay) => !!timeKeepingDay);

export const getTimeKeepingDayMe: (
  state: TimeKeepingDaysMeReducerState,
  id: TimeKeepingDayId
) => TimeKeepingDay = ({ byId }, id) => byId[id];
