import React, { useCallback, useEffect, useState } from 'react';
import { notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { GlobalAbsenceDrawerTabs } from '../components/GlobalAbsenceDrawerTabs';
import { AbsenceTabs, AbsenceDrawerView } from '../actions/drawer';
import {
  AbsenceOverview,
  AbsenceProposal,
} from '../../../models/AbsenceProposal';
import UpdateAbsenceProposal from './UpdateAbsenceProposal';
import { Center } from '../../../components/Center';
import { makePrioStyles } from '../../../theme/utils';

import Flex from '../../../components/Flex';
import { apiFetchAbsencesMe } from '../api';
import PrioSpinner from '../../../components/PrioSpinner';

interface AbsenceTaskPaneProps {}
const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.old.spacing.defaultPadding,
  },
  tabs: {
    height: '100%',
  },
  tabsContent: {
    overflow: 'hidden',
  },
  updateAbsenceProposal: {
    marginTop: theme.old.spacing.unit(4),
  },
  spinContainer: {
    height: 400,
  },
}));

export const AbsenceTaskPane: React.FC<AbsenceTaskPaneProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState<boolean>();
  const [view, setView] = useState<AbsenceDrawerView>('tabs');
  const [tab, setTab] = useState<AbsenceTabs>('new');
  const [selectedAbsenceProposal, setSelectedAbsenceProposal] =
    useState<AbsenceProposal | null>();

  const [absenceOverview, setAbsenceOverview] =
    useState<AbsenceOverview | null>();

  const fetchAbsenceInfo = useCallback(async () => {
    setIsFetching(true);
    const { result, data } = await apiFetchAbsencesMe();
    setIsFetching(false);
    if (result.status >= 200 && result.status < 300) {
      setAbsenceOverview(data);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.fetchOverviewError'),
      });
    }
  }, [t]);

  useEffect(() => {
    fetchAbsenceInfo();
  }, [fetchAbsenceInfo]);

  const onTabChange = (activeKey: AbsenceTabs) => {
    setTab(activeKey);
  };
  const onAbsenceClick = (absenceProposal: AbsenceProposal) => {
    setSelectedAbsenceProposal(absenceProposal);
    setView('edit');
  };

  const onSuccessfulFormSubmit = () => {
    fetchAbsenceInfo();
    goBack();
  };

  const goBack = () => {
    setView('tabs');
    setSelectedAbsenceProposal(null);
  };

  if (isFetching || !absenceOverview)
    return (
      <Center className={classes.spinContainer}>
        <PrioSpinner />
      </Center>
    );

  const renderContent = () => {
    switch (view) {
      case 'tabs':
        return (
          <Flex.Column className={classes.tabs}>
            <Typography.Title level={2}>{t('hr:title')}</Typography.Title>
            <Flex.Item flex={1} className={classes.tabsContent}>
              <GlobalAbsenceDrawerTabs
                activeKey={tab}
                onChange={onTabChange}
                onAbsenceClick={onAbsenceClick}
                absenceOverview={absenceOverview}
              />
            </Flex.Item>
          </Flex.Column>
        );
      case 'edit':
        //return <AbsenceDetailsDrawer />;
        if (selectedAbsenceProposal)
          return (
            <>
              <Button
                onClick={goBack}
                type="link"
                iconProp={['fal', 'chevron-left']}
              >
                {t('common:back')}
              </Button>
              <UpdateAbsenceProposal
                className={classes.updateAbsenceProposal}
                absenceProposal={selectedAbsenceProposal}
                onFinish={onSuccessfulFormSubmit}
                absenceOverview={absenceOverview}
              />
            </>
          );
        return (
          <Center className={classes.spinContainer}>
            <PrioSpinner />
          </Center>
        );

      default:
        return <></>;
    }
  };
  return <div className={classes.root}>{renderContent()}</div>;
};

export default AbsenceTaskPane;
