import { HistoryItem } from '@prio365/prio365-react-library';
import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';
import { FilterBarData, GenericSearchResult, SearchType } from '../types';
import { QueryError } from '../../../queries/clientConfiguration';
import i18n from '../../../i18n';

const returnErrorOrResult: <T>(
  result: Response,
  errorString: string,
  enrichData?: (data: T) => T
) => Promise<ApiResult<T>> = async (result, errorString, enrichData) => {
  const data = await result.json();

  if (result.status >= 200 && result.status < 300) {
    return {
      result,
      data: enrichData ? enrichData(data) : data,
    };
  }

  if (
    data !== null &&
    data.TranslatedMessage !== null &&
    data.TranslatedMessage !== 'UnhandledException'
  ) {
    errorString = data.TranslatedMessage;
  }

  throw new QueryError(errorString, result);
};

export const apiFetchSearchParameters: (
  searchType: string
) => Promise<ApiResult<FilterBarData>> = async (searchType) => {
  const result = await fetchWithRetry(
    `${apiUrl}/search/Search/parameters?searchType=${encodeURI(searchType)}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  return returnErrorOrResult(
    result,
    i18n.t('components:filter.messages.errorMessages.fetchSearchConfigError')
  );
};

export const apiFetchSearchData: <
  ResultData = unknown,
  CalculatedData = unknown,
>(
  searchType: SearchType,
  searchString: string,
  forceThreshold: number,
  forceResult: boolean
) => Promise<
  ApiResult<GenericSearchResult<ResultData, CalculatedData>>
> = async (
  searchType,
  searchString = '',
  forceThreshold = 1000,
  forceResult = false
) => {
  const result = await fetch(
    `${apiUrl}/search/Search?searchType=${encodeURIComponent(
      searchType
    )}&searchString=${encodeURIComponent(
      searchString
    )}&forceThreshold=${encodeURIComponent(
      forceThreshold
    )}&forceResult=${encodeURIComponent(forceResult)}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  return returnErrorOrResult(
    result,
    i18n.t('components:filter.messages.errorMessages.fetchSearchError'),
    (data) => {
      return {
        totalItems: data.totalItems,
        items:
          data.items?.map((item) => ({
            data: item.data,
            calculated: item.calculated,
            isTemporary: false,
          })) ?? null,
      };
    }
  );
};

export const apiFetchSearchHistory: (
  searchType: string,
  onlyBookmarked?: boolean,
  amount?: number
) => Promise<ApiResult<HistoryItem[]>> = async (
  searchType,
  onlyBookmarked = false,
  amount = 25
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/search/Search/history?searchType=${searchType}&onlyBookmarked=${onlyBookmarked}&amount=${amount}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiPutSearchHistory: (
  searchHistoryId: string,
  body: {
    description?: string;
    isBookmarked?: boolean;
    sortKey?: number;
  }
) => Promise<ApiResult<HistoryItem>> = async (searchHistoryId, body) => {
  const result = await fetchWithRetry(
    `${apiUrl}/search/Search/history/${searchHistoryId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteSearchHistoryByIds: (
  searchHistoryIds: string
) => Promise<ApiResult<void>> = async (searchHistoryIds) => {
  const result = await fetchWithRetry(
    `${apiUrl}/search/Search/history?searchHistoryIds=${searchHistoryIds}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return {
    result,
    data: undefined,
  };
};

export const apiDeleteSearchHistory: (
  searchType: string,
  includeBookmarked?: boolean
) => Promise<ApiResult<void>> = async (searchType, includeBookmarked) => {
  const result = await fetchWithRetry(
    `${apiUrl}/search/Search/ClearHistory?searchType=${searchType}${
      includeBookmarked ? '&deleteBookmarked=true' : ''
    }`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return {
    result,
    data: undefined,
  };
};
