import { PRIO } from '../../../constants';
import { Message } from '../../../models/Message';
import { MailFolderId, MessageId, ProjectId } from '../../../models/Types';

export const WS_EMAIL_PROJECT_CREATED = PRIO + 'WS_EMAIL_PROJECT_CREATED';
export const WS_EMAIL_ME_CREATED = PRIO + 'WS_EMAIL_ME_CREATED';

export const wsEmailCreated = (
  message: Message,
  projectId: ProjectId,
  mailFolderId: MailFolderId,
  messageAlreadyExists: boolean,
  wsMailFolderId: MailFolderId
) => ({
  type: projectId === 'me' ? WS_EMAIL_ME_CREATED : WS_EMAIL_PROJECT_CREATED,
  payload: { message },
  meta: { projectId, mailFolderId, messageAlreadyExists, wsMailFolderId },
});

export const WS_EMAIL_PROJECT_UPDATED = PRIO + 'WS_EMAIL_PROJECT_UPDATED';
export const WS_EMAIL_ME_UPDATED = PRIO + 'WS_EMAIL_ME_UPDATED';

export const wsEmailUpdated = (
  message: MessageEvent,
  projectId: ProjectId,
  mailFolderId: MailFolderId
) => ({
  type: projectId === 'me' ? WS_EMAIL_ME_UPDATED : WS_EMAIL_PROJECT_UPDATED,
  payload: { message },
  meta: { projectId, mailFolderId },
});

export const WS_EMAIL_PROJECT_DELETED = PRIO + 'WS_EMAIL_PROJECT_DELETED';
export const WS_EMAIL_ME_DELETED = PRIO + 'WS_EMAIL_ME_DELETED';

export const wsEmailDeleted = (
  messageId: MessageId,
  mailFolderId: MailFolderId,
  projectId: ProjectId
) => ({
  type: projectId === 'me' ? WS_EMAIL_ME_DELETED : WS_EMAIL_PROJECT_DELETED,
  payload: { messageIds: [messageId] },
  meta: { projectId, mailFolderId, messageIds: [messageId] },
});
