import classNames from 'classnames';
import React, { forwardRef, ReactNode, RefAttributes } from 'react';
import { makePrioStyles } from '../theme/utils';

export declare type Alignment =
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'baseline'
  | 'stretch';

export declare type Wrap = 'nowrap' | 'wrap' | 'wrap-reverse' | 'inherit';

const useStylesRow = makePrioStyles((theme) => ({
  root: (props: RowProps) => {
    const { children, classes: _, childrenGap, ...styleProps } = props;
    return {
      display: 'flex',
      flexDirection: 'row',
      ...(props.childrenGap
        ? {
            '& > :not(:first-child) ': {
              marginLeft: props.childrenGap,
            },
          }
        : {}),
      ...styleProps,
    };
  },
}));

const useStylesColumn = makePrioStyles((theme) => ({
  root: (props: ColumnProps) => {
    const { children, classes: _, childrenGap, ...styleProps } = props;
    return {
      display: 'flex',
      flexDirection: 'column',
      ...(props.childrenGap
        ? {
            '& > :not(:first-child) ': {
              marginTop: props.childrenGap,
            },
          }
        : {}),
      ...styleProps,
    };
  },
}));

const useStylesItem = makePrioStyles((theme) => ({
  root: (props: ItemProps) => {
    const { children, classes: _, ...styleProps } = props;
    return {
      ...styleProps,
    };
  },
}));

interface RowProps {
  className?: string;
  children?: ReactNode;
  childrenGap?: number;
  padding?: number | string;
  flexGrow?: number;
  flexShrink?: number;
  flex?: number | string;
  justifyContent?: Alignment;
  alignItems?: Alignment;
  flexWrap?: Wrap;
  id?: string;
  [props: string]: any;
}

interface FlexRowProps extends RowProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Row: React.FC<FlexRowProps> = forwardRef((props, ref) => {
  const classes = useStylesRow(props);
  const { className, children, onClick, onDoubleClick, id, furtherProps } =
    props;
  return (
    <div
      ref={ref}
      id={id}
      className={classNames(classes.root, className)}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      onDoubleClick={(e) => {
        if (onDoubleClick) {
          onDoubleClick(e);
        }
      }}
      {...furtherProps}
    >
      {children}
    </div>
  );
});

interface ColumnProps {
  className?: string;
  children?: ReactNode;
  childrenGap?: number;
  padding?: number | string;
  flexGrow?: number;
  flexShrink?: number;
  flex?: number | string;
  justifyContent?: Alignment;
  alignItems?: Alignment;
  flexWrap?: Wrap;
  id?: string;
  [props: string]: any;
}

interface ColumnRowProps extends ColumnProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Column: React.FC<ColumnRowProps> = forwardRef((props, ref) => {
  const classes = useStylesColumn(props);
  const { className, children, id, furtherProps, onClick } = props;
  return (
    <div
      ref={ref}
      id={id}
      className={classNames(classes.root, className)}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      {...furtherProps}
    >
      {children}
    </div>
  );
});
interface ItemProps {
  className?: string;
  children?: ReactNode;
  flexGrow?: number;
  flexShrink?: number;
  flex?: number | string;
  padding?: number | string;
  alignSelf?: Alignment;
  id?: string;
  [props: string]: any;
}

export const Item: React.FC<ItemProps & RefAttributes<HTMLDivElement>> =
  forwardRef((props, ref) => {
    const classes = useStylesItem(props);
    const { className, children, id, furtherProps } = props;

    return (
      <div
        id={id}
        ref={ref}
        className={classNames(classes.root, className)}
        {...furtherProps}
      >
        {children}
      </div>
    );
  });
// eslint-disable-next-line
export default {
  Row,
  Column,
  Item,
};
