import { createContext } from 'react';
import { DocumentTemplateTag } from '../../../models/DocumentTemplate';

export type DocumentTemplateTagContextProps = {
  data: DocumentTemplateTag[];
  fetchDocumentTemplateTags: (() => Promise<void>) | undefined;
};

const defaultValue: DocumentTemplateTagContextProps = {
  data: [],
  fetchDocumentTemplateTags: undefined,
};

const DocumentTemplateTagContext =
  createContext<DocumentTemplateTagContextProps>(defaultValue);

export default DocumentTemplateTagContext;
