import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import ContactsTaskPane from '../contacts/components/ContactsTaskPane';
import TimeRecordsTaskPane from '../timeRecords/components/TimeRecordsTaskPane';
import AbsenceTaskPane from '../absences/components/AbsenceTaskPane';
import Companies from '../companies/components/Companies';
import Projects from '../projects/components/Projects';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

export const TaskPane: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path="contacts/*" element={<ContactsTaskPane />} />
      <Route path="companies/*" element={<Companies />} />
      <Route path="projects/*" element={<Projects />} />
      <Route path="timeRecord/*" element={<TimeRecordsTaskPane />} />
      <Route path="absence/*" element={<AbsenceTaskPane />} />
    </Routes>
  );
};

export default TaskPane;
