import { useEffect, useMemo } from 'react';
import i18n from '../i18n';

declare type ModuleType =
  | 'projects'
  | 'contacts'
  | 'hr'
  | 'controlling'
  | 'settings'
  | 'profile';

const moduleTranslationMap = {
  projects: 'projects:navigation',
  contacts: 'contacts:contactsNav.navigation',
  controlling: 'controlling:navigation',
  hr: 'hr:navigation',
  settings: 'settings:settingsNav.navigation',
  profile: 'profile:projectsProfileNavigation',
};

const useTabBarTitle = (
  module: ModuleType,
  secondItem?: string,
  thirdItem?: string
) => {
  const title = useMemo(() => {
    return [
      thirdItem ? undefined : i18n.t(`common:links.${module}`),
      secondItem,
      thirdItem
        ? i18n.t(`${moduleTranslationMap[module]}.${thirdItem}`)
        : undefined,
    ]
      .filter((part) => !!part)
      .join(' – ');
  }, [module, secondItem, thirdItem]);
  useEffect(() => {
    document.title = `Prio365${title ? ` – ${title}` : ''}`;
  }, [title]);
};

export default useTabBarTitle;
