import React from 'react';
import { Form, Select, DatePicker, Checkbox, InputNumber } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { colon } from '../../../util/forms';
import { useTranslation } from 'react-i18next';
import { CreateRegistrationEmployee } from '../../../models/Employee';
import useDatePickerLocale from '../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { Moment } from 'moment-timezone';
import { Gender, DaysOfWork } from '../../../models/Types';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    '& .ant-form-item-label > label': {
      wordBreak: 'break-word',
      overflow: 'hidden',
      whiteSpace: 'normal',
    },
    '& .ant-form-item-control': {
      justifyContent: 'center',
    },
  },
  fullWidth: {
    width: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  checkbox: {
    '& .ant-form-item-label > label': {
      height: '100%',
    },
  },
}));

interface FormEntry {
  gender: Gender;
  birthday: Moment;
  allowShareBirthday: boolean;
  allowShareBirthdayCompany: boolean;
  dateOfEmployment?: Moment;
  hoursPerWeek: number;
  daysOfWork: DaysOfWork[];
}

interface RegistrationFormProps {
  onFinish: (value: CreateRegistrationEmployee) => void;
  actionLabel?: string;
  disableForm?: boolean;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = (props) => {
  const classes = useStyles();
  const { onFinish, actionLabel, disableForm } = props;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const handleFinish = (value: FormEntry) => {
    const { dateOfEmployment, ...rest } = value;
    const employeeData: CreateRegistrationEmployee = dateOfEmployment
      ? {
          ...rest,
          birthday: rest.birthday.toISOString(true).split('T')[0],
          dateOfEmployment: dateOfEmployment.toISOString(true).split('T')[0],
          workingDays: rest.daysOfWork.length,
        }
      : {
          ...rest,
          birthday: rest.birthday.toISOString(true).split('T')[0],
          workingDays: rest.daysOfWork.length,
        };
    onFinish(employeeData);
  };

  const datePickerLocale: PickerLocale = useDatePickerLocale();

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  const emptyValues: FormEntry = {
    gender: undefined,
    birthday: undefined,
    allowShareBirthday: false,
    allowShareBirthdayCompany: false,
    hoursPerWeek: 40,
    daysOfWork: ['mon', 'tue', 'wed', 'thu', 'fri'],
  };

  return (
    <Form<FormEntry>
      {...layout}
      className={classes.root}
      form={form}
      onFinish={handleFinish}
      initialValues={emptyValues}
    >
      <Form.Item
        name="gender"
        label={t('employee:registrationForm.labels.gender')}
        colon={colon}
        rules={[
          {
            required: true,
            message: t('employee:registrationForm.validation.missingGender'),
          },
        ]}
      >
        <Select disabled={disableForm} className={classes.fullWidth}>
          <Select.Option value="male">
            {t('employee:registrationForm.gender.male')}
          </Select.Option>
          <Select.Option value="female">
            {t('employee:registrationForm.gender.female')}
          </Select.Option>
          <Select.Option value="diverse">
            {t('employee:registrationForm.gender.diverse')}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="birthday"
        label={t('employee:registrationForm.labels.birthday')}
        colon={colon}
        rules={[
          {
            required: true,
            message: t('employee:registrationForm.validation.missingBirthday'),
          },
        ]}
      >
        <DatePicker
          disabled={disableForm}
          locale={datePickerLocale}
          className={classes.fullWidth}
          format={'DD.MM.YYYY'}
        />
      </Form.Item>
      <Form.Item
        name="allowShareBirthday"
        colon={colon}
        label={t('employee:registrationForm.labels.allowShareBirthday')}
        className={classes.checkbox}
        valuePropName="checked"
      >
        <Checkbox disabled={disableForm} />
      </Form.Item>
      <Form.Item
        name="allowShareBirthdayCompany"
        colon={colon}
        label={t('employee:registrationForm.labels.allowShareBirthdayCompany')}
        className={classes.checkbox}
        valuePropName="checked"
      >
        <Checkbox disabled={disableForm} />
      </Form.Item>
      <Form.Item
        name="dateOfEmployment"
        label={t('employee:registrationForm.labels.dateOfEmployment')}
        colon={colon}
      >
        <DatePicker
          disabled={disableForm}
          locale={datePickerLocale}
          className={classes.fullWidth}
          format={'DD.MM.YYYY'}
        />
      </Form.Item>
      <Form.Item
        name="hoursPerWeek"
        label={t('employee:registrationForm.labels.hoursPerWeek')}
        colon={colon}
        rules={[
          {
            required: true,
            message: t(
              'employee:registrationForm.validation.missingHoursPerWeek'
            ),
          },
        ]}
      >
        <InputNumber
          min={1}
          max={168}
          className={classes.fullWidth}
          disabled={disableForm}
        />
      </Form.Item>
      <Form.Item
        name="daysOfWork"
        label={t('employee:registrationForm.labels.workingDays')}
        colon={colon}
        rules={[
          {
            required: true,
            message: t(
              'employee:registrationForm.validation.missingWorkingDays'
            ),
          },
        ]}
      >
        <Select
          disabled={disableForm}
          mode="multiple"
          className={classes.fullWidth}
        >
          <Select.Option value="mon">
            {t('employee:registrationForm.days.mon')}
          </Select.Option>
          <Select.Option value="tue">
            {t('employee:registrationForm.days.tue')}
          </Select.Option>
          <Select.Option value="wed">
            {t('employee:registrationForm.days.wed')}
          </Select.Option>
          <Select.Option value="thu">
            {t('employee:registrationForm.days.thu')}
          </Select.Option>
          <Select.Option value="fri">
            {t('employee:registrationForm.days.fri')}
          </Select.Option>
          <Select.Option value="sat">
            {t('employee:registrationForm.days.sat')}
          </Select.Option>
          <Select.Option value="sun">
            {t('employee:registrationForm.days.sun')}
          </Select.Option>
        </Select>
      </Form.Item>
      <Flex.Row justifyContent="flex-end">
        <Form.Item className={classes.submitButtonFormItem}>
          <Button htmlType="submit" disabled={disableForm}>
            {actionLabel}
          </Button>
        </Form.Item>
      </Flex.Row>
    </Form>
  );
};

export default RegistrationForm;
