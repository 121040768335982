import { fetchProjectMailCategories } from '../projects/categories';
import { fetchMailCategoriesMe } from '../me/categoriesMe';

export const fetchMailCategories = (
  projectId: string,
  reloadCache?: boolean
) => {
  if (projectId === 'me') {
    return fetchMailCategoriesMe(reloadCache);
  }
  return fetchProjectMailCategories(projectId, reloadCache);
};
