import { useCallback, useState } from 'react';
import { DriveItem } from '../../../models/Drive';
import { checkIfLink } from '../util';
import {
  apiCopyAs,
  apiDownloadDriveItem,
  apiFetchDocument,
  apiFetchDocumentWithCaching,
} from '../api';
import { notification } from 'antd';
import { useGetLocalUrlByDriveId } from './useGetLocalUrlByDriveId';
import { GroupId, ProjectId } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import { getDocumentsCachingEnabled } from '../../../apps/main/rootReducer';
import { useSelector } from 'react-redux';

export const useDriveItemActions = (
  groupId?: GroupId,
  projectId?: ProjectId
) => {
  const _groupId = groupId;
  const _projectId = projectId;

  const [isFetchingUrl, setIsFetchingUrl] = useState<boolean>(false);

  const { t } = useTranslation();
  const { getLocalUrlByDriveItem } = useGetLocalUrlByDriveId();

  const isDocumentsCachingEnabled =
    useSelector(getDocumentsCachingEnabled) ?? false;

  const openLinkInBrowser = (urlName: string) => {
    const url = urlName.slice(0, -4);
    window.open('https://' + url, '_blank');
  };
  const openInBrowser = useCallback(
    async (driveItem: DriveItem, groupId?: GroupId, projectId?: ProjectId) => {
      const isLink = checkIfLink(driveItem?.name);
      if (isLink) {
        openLinkInBrowser(driveItem.name);

        return;
      }
      if (!isFetchingUrl) {
        const a = document.createElement('a');
        let item: DriveItem = driveItem;
        if (!driveItem.parentReference?.path) {
          setIsFetchingUrl(true);
          const { data } =
            isDocumentsCachingEnabled === true
              ? await apiFetchDocumentWithCaching(
                  projectId ?? _projectId,
                  driveItem.id
                )
              : await apiFetchDocument(groupId ?? _groupId, driveItem.id);
          setIsFetchingUrl(false);
          if (data) {
            item = data;
          } else {
            notification.open({
              message: t('common:error'),
              description: t('documents:errorMessages.fetchDriveItemError'),
            });
            document.body.removeChild(a);
            return;
          }
        }
        a.href = item.webUrl;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    },
    [_groupId, isFetchingUrl, t, _projectId, isDocumentsCachingEnabled]
  );

  const openLocally = useCallback(
    async (
      driveItem: DriveItem,
      openPdf,
      groupId?: GroupId,
      projectId?: ProjectId
    ) => {
      if (!isFetchingUrl) {
        const a = document.createElement('a');

        var url = await getLocalUrlByDriveItem(
          groupId ?? _groupId,
          projectId ?? _projectId,
          driveItem,
          setIsFetchingUrl,
          openPdf
        );
        if (url) {
          a.href = url;

          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
        }
        document.body.removeChild(a);
      }
    },
    [getLocalUrlByDriveItem, _groupId, isFetchingUrl, _projectId]
  );

  const copyAsPdf = useCallback(
    async (driveItem: DriveItem, projectId?: ProjectId) => {
      notification.open({
        message: t('common:loading'),
        description: t('documents:inProgressMessage.creatingPDFIsInProgress'),
      });
      await apiCopyAs(projectId ?? _projectId, driveItem.id, 'pdf');
    },
    [_projectId, t]
  );

  const downloadDriveItem = useCallback(
    async (driveItem: DriveItem, groupId?: GroupId) => {
      if (!(await apiDownloadDriveItem(groupId ?? _groupId, driveItem))) {
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.downloadFileError'),
        });
      }
    },
    [_groupId, t]
  );
  return {
    openInBrowser,
    openLocally,
    openLinkInBrowser,
    copyAsPdf,
    downloadDriveItem,
  };
};
