import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DriveItem } from '../../../../models/Drive';
import { Link } from 'react-router-dom';
import { DriveItemId, GroupId, ProjectId } from '../../../../models/Types';
import { DriveUserRemoteItem } from '../../../../models/Document';
import { Project } from '../../../../models/Project';
import { useSelector } from 'react-redux';
import {
  getRecursiveDriveItemIdPath,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { makePrioStyles } from '../../../../theme/utils';
import DriveItemBreadcrumbItem from './DriveItemBreadcrumbItem';
import equals from 'deep-equal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import CascaderBreadcrumbItem from './CascaderBreadcrumbItem';
import FirstBreadcrumbItem from './FirstBreadcrumbItem';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../../../components/CopyableTextTile';
import * as ConfigValues from '../../../../util/configValues';
import classNames from 'classnames';
import { spreadBreadcrumbItems } from '../../util';
import { BreadcrumbItem } from '../../../../models/Breadcrumb';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'inline-flex',
    width: '100%',
    overflow: 'hidden',
  },
  clickable: {
    cursor: 'pointer',
  },
  breadCrumb: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    color: theme.old.typography.colors.muted,
    fontVariant: 'tabular-nums',
    listStyle: 'none',
    fontSize: theme.old.typography.fontSize.base,
    '& a': {
      color: theme.old.typography.colors.muted,
      '&:hover': {
        color: theme.old.palette.primaryColor,
      },
    },
    '& > span:last-child': {
      display: 'flex',
      alignItems: 'center',
      color: theme.old.typography.colors.base,
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 44,
      '& > span': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '& > span:last-child a': {
      color: theme.old.typography.colors.base,
    },
    '& > span:last-child .ant-breadcrumb-separator:last-child': {
      display: 'none',
    },

    '& > span:nth-child(3):not(:last-child)': {
      display: 'flex',
      overflow: 'hidden',
    },
  },
}));

const useDriveItemIdPath: (
  driveItemId: DriveItemId,
  groupId: GroupId,
  path: string
) => [BreadcrumbItem[], boolean] = (driveItemIdProp, groupIdProp, pathProp) => {
  const [driveItemId, setDriveItemId] = useState<DriveItemId>(driveItemIdProp);
  const [groupId, setGroupId] = useState<GroupId>(groupIdProp);

  const reversedLabels = useMemo(
    () => pathProp.split('/').splice(3).reverse(),
    [pathProp]
  );

  const driveItemIds = useSelector<RootReducerState, DriveItemId[]>((state) =>
    getRecursiveDriveItemIdPath(state, driveItemId, groupId)
  );

  const isFullPathAvailable = useMemo(
    () => driveItemIds.includes(`root-group-${groupId}`),
    [driveItemIds, groupId]
  );

  const filteredReversedIds = driveItemIds
    .filter((id) => id !== `root-group-${groupId}`)
    .reverse();

  useEffect(() => {
    setDriveItemId(driveItemIdProp);
  }, [driveItemIdProp]);

  useEffect(() => {
    setGroupId(groupIdProp);
  }, [groupIdProp]);

  return useMemo(
    () => [
      filteredReversedIds
        .map((id, index) => ({
          id,
          index:
            filteredReversedIds.length - (isFullPathAvailable ? 0 : 1) - index,
          label: reversedLabels[index],
          isHidden: false,
        }))
        .reverse(),
      isFullPathAvailable,
    ],
    [filteredReversedIds, reversedLabels, isFullPathAvailable]
  );
};

const useResponsiveBreadcrumbs: (
  driveItemId: DriveItemId,
  groupId: GroupId,
  path: string,
  isInDrawer?: boolean
) => [BreadcrumbItem[], boolean, React.MutableRefObject<HTMLDivElement>] = (
  driveItemId,
  groupId,
  path,
  isInDrawer
) => {
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme<PrioTheme>();

  const [breadcrumbItems, isFullPathAvailable] = useDriveItemIdPath(
    driveItemId,
    groupId,
    path
  );

  const [currentBreadcrumbItems, setCurrentBreadcrumbItems] = useState<
    BreadcrumbItem[]
  >([]);

  const [resizeObserver, setResizeObserver] = useState<ResizeObserver>(null);

  useEffect(() => {
    spreadBreadcrumbItems(
      resizeObserver,
      currentBreadcrumbItems,
      breadcrumbItems,
      theme,
      isInDrawer,
      equals,
      setCurrentBreadcrumbItems,
      setResizeObserver
    );
  }, [
    resizeObserver,
    currentBreadcrumbItems,
    breadcrumbItems,
    theme,
    isInDrawer,
  ]);

  useEffect(() => {
    if (ref.current && resizeObserver) {
      resizeObserver.observe(ref.current.parentElement);
    }
  }, [ref, resizeObserver]);

  useEffect(() => {
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [resizeObserver]);

  return useMemo(
    () => [currentBreadcrumbItems, isFullPathAvailable, ref],
    [currentBreadcrumbItems, isFullPathAvailable, ref]
  );
};

interface DriveItemBreadcrumbProps {
  className?: string;
  driveItem?: DriveItem;
  pathPrefix?: string;
  project?: Project;
  onItemClick?: (driveItemId: DriveItemId) => void;
  remoteItem?: DriveUserRemoteItem;
  addProjectList?: boolean;
  onRootChange?: (projectId: ProjectId) => void;
  disableLinkNavigation?: boolean;
  enableCopyLink?: boolean;
  handleReloadDocuments?: () => void;
  isReloadingDocuments?: boolean;
}

export const DriveItemBreadcrumb: React.FC<DriveItemBreadcrumbProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    driveItem,
    pathPrefix,
    remoteItem,
    addProjectList,
    onItemClick,
    project,
    onRootChange,
    disableLinkNavigation,
    enableCopyLink,
    handleReloadDocuments,
    isReloadingDocuments,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Selectors
  const [selectedProject, setSelectedProject] = useState<Project>(project);

  const [breadcrumbItems, isFullPathAvailable, ref] = useResponsiveBreadcrumbs(
    driveItem?.name === 'root'
      ? `root-group-${selectedProject?.groupId}`
      : driveItem?.id,
    selectedProject?.groupId,
    driveItem
      ? `${driveItem?.parentReference?.path}/${driveItem?.name}`
      : '/drive/root:',
    handleReloadDocuments ? false : true
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onBreadcrumbClick = useCallback(
    (driveItemId: DriveItemId) => {
      if (onItemClick) {
        onItemClick(driveItemId);
      }
    },
    [onItemClick]
  );

  const onCopyClick = useCallback(
    (setOpenCopyMenu: (value: boolean) => void) => {
      setOpenCopyMenu(false);
    },
    []
  );
  //#endregion

  //#region ------------------------------ Components
  const dropDownMenu = useCallback(
    (setOpenCopyMenu: (value: boolean) => void) => (
      <div className="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light">
        <div
          className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            copyToClipboard(
              `${ConfigValues.REACT_APP_API_REDIRECT_URL}module/prio/projects/${
                selectedProject.projectId
              }/documents/${
                driveItem?.name === 'root' ? 'all' : `folder/${driveItem?.id}`
              }`
            );
            onCopyClick(setOpenCopyMenu);
          }}
        >
          {t('documents:breadcrumbMenu.copyPrioUrl')}
        </div>
        <div
          className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            copyToClipboard(driveItem.webDavUrl);
            onCopyClick(setOpenCopyMenu);
          }}
        >
          {t('documents:breadcrumbMenu.copySharepointUrl')}
        </div>
      </div>
    ),
    [driveItem, selectedProject, t, onCopyClick]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setSelectedProject(project);
  }, [project]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.breadCrumb}>
        <FirstBreadcrumbItem
          project={selectedProject}
          addProjectList={addProjectList}
          activeDriveItemId={
            breadcrumbItems.length > 0 ? breadcrumbItems[0].id : null
          }
          pathPrefix={pathPrefix}
          remoteItem={remoteItem}
          disableLinkNavigation={disableLinkNavigation}
          onClick={(driveItemId) => {
            if (driveItemId.includes('root-group')) {
              onBreadcrumbClick(null);
            } else {
              onBreadcrumbClick(driveItemId);
            }
          }}
          onRootChange={(project) => {
            setSelectedProject(project);
            if (onRootChange) {
              onRootChange(project.projectId);
            }
          }}
          copyDropDown={
            enableCopyLink &&
            driveItem &&
            breadcrumbItems.length === 0 &&
            dropDownMenu
          }
          handleReloadDocuments={handleReloadDocuments}
          isReloadingDocuments={isReloadingDocuments}
        />
        {breadcrumbItems.length > 0 && (
          <CascaderBreadcrumbItem
            isFullPathAvailable={isFullPathAvailable}
            breadcrumbItems={breadcrumbItems}
            pathPrefix={pathPrefix}
            onCascaderOptionClick={onBreadcrumbClick}
            onCascaderContentClick={onBreadcrumbClick}
            disableLinkNavigation={disableLinkNavigation}
          />
        )}
        {breadcrumbItems.length > 1 && (
          <span ref={ref} style={{ height: '25px', paddingTop: '1px' }}>
            {breadcrumbItems.map(
              (item, index) =>
                index !== breadcrumbItems.length - 1 && (
                  <DriveItemBreadcrumbItem
                    key={item.index + 1}
                    driveItemId={item.id}
                    onClick={() => onBreadcrumbClick(item.id)}
                    activeDriveItemId={
                      index < breadcrumbItems.length - 1
                        ? breadcrumbItems[index + 1].id
                        : null
                    }
                    onBreadcrumbClick={onBreadcrumbClick}
                    title={item.label}
                    disableLinkNavigation={disableLinkNavigation}
                    pathPrefix={pathPrefix}
                    clickable
                    handleReloadDocuments={handleReloadDocuments}
                    isReloadingDocuments={isReloadingDocuments}
                    isNotLastBreadcrumbItem
                  >
                    {item.id && pathPrefix ? (
                      <Link to={`${pathPrefix}folder/${item.id}`}>
                        {item.label}
                      </Link>
                    ) : (
                      item.label
                    )}
                  </DriveItemBreadcrumbItem>
                )
            )}
          </span>
        )}
        {breadcrumbItems.length > 0 && (
          <DriveItemBreadcrumbItem
            key={breadcrumbItems.length}
            driveItemId={breadcrumbItems[breadcrumbItems.length - 1].id}
            onClick={() =>
              onBreadcrumbClick(breadcrumbItems[breadcrumbItems.length - 1].id)
            }
            title={breadcrumbItems[breadcrumbItems.length - 1].label}
            copyDropDown={
              enableCopyLink &&
              driveItem &&
              breadcrumbItems.length > 0 &&
              dropDownMenu
            }
            onBreadcrumbClick={onBreadcrumbClick}
            disableLinkNavigation={disableLinkNavigation}
            pathPrefix={pathPrefix}
            clickable
            handleReloadDocuments={handleReloadDocuments}
            isReloadingDocuments={isReloadingDocuments}
          >
            {breadcrumbItems[breadcrumbItems.length - 1].id && pathPrefix ? (
              <Link
                to={`${pathPrefix}folder/${
                  breadcrumbItems[breadcrumbItems.length - 1].id
                }`}
              >
                {breadcrumbItems[breadcrumbItems.length - 1].label}
              </Link>
            ) : (
              breadcrumbItems[breadcrumbItems.length - 1].label
            )}
          </DriveItemBreadcrumbItem>
        )}
      </div>
    </div>
  );
};

export default DriveItemBreadcrumb;
