import { Reducer } from 'react';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  CLEAR_SEARCH_STRING,
  FETCH_SEARCH_CONFIG_COMMIT,
  FETCH_SEARCH_CONFIG_REQUEST,
  FETCH_SEARCH_CONFIG_ROLLBACK,
  SET_SEARCH_STRING,
} from '../actions';
import { combineReducers } from 'redux';
import { FilterBarData, SearchType } from '../types';
import { DispatchAction } from '../../../models/Redux';
import moment from 'moment';

export type BySearchType = Partial<Record<SearchType, FilterBarData>>;

export const initialState = {
  searchConfigBySearchType: {} as BySearchType,
  meta: { isFetchingSearchParameters: false },
  lastSearchString: {},
};

const searchConfigBySearchType: Reducer<
  BySearchType,
  DispatchAction<{ searchType: SearchType }>
> = (state = initialState.searchConfigBySearchType, action) => {
  switch (action.type) {
    case FETCH_SEARCH_CONFIG_COMMIT: {
      const { payload } = action;
      const { searchType } = action.meta;

      return {
        ...state,
        [searchType]: {
          ...payload,
          fetchDate: moment().format('YYYY-MM-DD hh:mm:ss'),
        },
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.searchConfigBySearchType;
    }
    default:
      return state;
  }
};

interface FilterMeta {
  isFetchingSearchParameters: boolean;
}

const meta: Reducer<FilterMeta, any> = (state = initialState.meta, action) => {
  switch (action.type) {
    case FETCH_SEARCH_CONFIG_REQUEST: {
      return {
        ...state,
        isFetchingSearchParameters: true,
      };
    }
    case FETCH_SEARCH_CONFIG_COMMIT: {
      return {
        ...state,
        isFetchingSearchParameters: false,
      };
    }
    case FETCH_SEARCH_CONFIG_ROLLBACK: {
      return {
        ...state,
        isFetchingSearchParameters: false,
      };
    }
    default:
      return state;
  }
};

export interface FilterLastSearchStringState {
  [key: string]: string | null;
  officeId?: string | null;
  projectId?: string | null;
}

const generateKey = (
  context: string,
  officeId: string | null,
  projectId: string | null,
  path: string
) => {
  if (path === 'me') {
    return `${context}_me`;
  } else if (path === 'openproposal') {
    if (officeId) {
      return `${context}_openproposal_office_${officeId}`;
    }
    return `${context}_openproposal`;
  } else if (officeId) {
    return `${context}_office_${officeId}`;
  } else if (projectId) {
    return `${context}_project_${projectId}`;
  } else {
    return context;
  }
};

const lastSearchString: Reducer<FilterLastSearchStringState, any> = (
  state = initialState.lastSearchString,
  action
) => {
  switch (action.type) {
    case SET_SEARCH_STRING: {
      const { context, searchString, officeId, projectId, path } =
        action.payload;
      const key = generateKey(context, officeId, projectId, path);
      return {
        ...state,
        [key]: searchString,
      };
    }
    case CLEAR_SEARCH_STRING: {
      const { context, officeId, projectId, path } = action.payload;
      const key = generateKey(context, officeId, projectId, path);
      return {
        ...state,
        [key]: null,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.lastSearchString;
    }
    default:
      return state;
  }
};

export interface FilterReducerState {
  searchConfigBySearchType: BySearchType;
  meta: FilterMeta;
  lastSearchString: FilterLastSearchStringState;
}

export default combineReducers<FilterReducerState>({
  searchConfigBySearchType,
  meta,
  lastSearchString,
});

export const getSearchConfigBySearchType: (
  state: FilterReducerState,
  searchType: SearchType
) => FilterBarData = (state, searchType) => {
  return state.searchConfigBySearchType[searchType];
};

export const getIsFetchingSearchConfig: (
  state: FilterReducerState
) => boolean = (state) => state.meta.isFetchingSearchParameters;

export const getReduxSearchString = (
  state: FilterReducerState,
  context: string,
  officeId: string | null,
  projectId: string | null,
  path: string
): string | null => {
  const key = generateKey(context, officeId, projectId, path);
  return state.lastSearchString[key] || null;
};
