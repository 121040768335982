import React from 'react';
import { Moment } from 'moment';
import { TimelineGroup, TimelineItem } from './types';
import moment from 'moment';

export interface TimelineRowContextProps {
  visibleTimeStart: Moment;
  visibleTimeEnd: Moment;
  startTime: Moment;
  endTime: Moment;
  prefixWidth: number;
  suffixWidth: number;
  prefixClassName?: string;
  groupHeaderRenderer?: (
    group: TimelineGroup,
    items: TimelineItem[]
  ) => React.ReactNode;
  groupSuffixRenderer?: (
    group: TimelineGroup,
    items: TimelineItem[],
    visibleTimeStart: Moment,
    visibleTimeEnd: Moment,
    startTime: Moment,
    endTime: Moment
  ) => React.ReactNode;
}

const TIMELINE_ROW_CONTEXT_INITIAL_STATE: TimelineRowContextProps = {
  visibleTimeStart: moment().startOf('day'),
  visibleTimeEnd: moment().endOf('day'),
  startTime: moment().startOf('day'),
  endTime: moment().endOf('day'),
  prefixWidth: 200,
  suffixWidth: 0,
  prefixClassName: undefined,
  groupHeaderRenderer: undefined,
  groupSuffixRenderer: undefined,
};

export const TimelineRowContext = React.createContext<TimelineRowContextProps>(
  TIMELINE_ROW_CONTEXT_INITIAL_STATE
);

export const useTimelineRowContext = () => React.useContext(TimelineRowContext);

interface TimelineRowProviderProps {
  value: Partial<TimelineRowContextProps>;
  children: React.ReactNode;
}

export const TimelineRowProvider: React.FC<TimelineRowProviderProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { value, children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <TimelineRowContext.Provider
      value={{
        ...TIMELINE_ROW_CONTEXT_INITIAL_STATE,
        ...Object.keys(value).reduce((acc, key) => {
          if (value[key] !== undefined) {
            acc[key] = value[key];
          }
          return acc;
        }, {}),
      }}
    >
      {children}
    </TimelineRowContext.Provider>
  );
};

export default TimelineRowProvider;
