import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { Doodle, DoodleType } from '../../../models/Doodle';
import { DateTimeString } from '../../../models/Types';
import { DISABLE_DOODLE, DoodleAction, ENABLE_DOODLE } from '../actions';
import doodleJson from '../doodle.json';

export interface DoodleTypeState {
  start: DateTimeString;
  end: DateTimeString;
  disabled: boolean;
}

export interface DoodleState {
  [type: string]: DoodleTypeState;
}

const doodle: Reducer<DoodleState, DoodleAction> = (state = {}, action) => {
  switch (action.type) {
    case DISABLE_DOODLE: {
      const { doodle, doodleType } = action;
      return {
        ...state,
        [doodleType]: {
          ...doodle,
          disabled: true,
        },
      };
    }

    case ENABLE_DOODLE: {
      const { doodle, doodleType } = action;
      return {
        ...state,
        [doodleType]: {
          ...doodle,
          disabled: false,
        },
      };
    }

    case CLEAR_PRIO_CACHE: {
      return Object.keys(doodleJson).reduce<DoodleState>(
        (map, type) => ({
          ...map,
          [type]: {
            ...(doodleJson[type] as Doodle),
            disabled: false,
          },
        }),
        {}
      );
    }
    default: {
      return state;
    }
  }
};

export default doodle;

export const getDoodleState: (state: DoodleState) => DoodleState = (state) =>
  state;

export const getDoodleTypeState: (
  state: DoodleState,
  type: DoodleType
) => DoodleTypeState = (state, type) => state[type];
