import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Checkbox,
  Collapse,
  Divider,
  Form,
  FormInstance,
  Input,
  // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
  //notification,
  Select,
  TimePicker,
  Tooltip,
  Typography,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import Table, { ColumnProps } from 'antd/lib/table';
import useCollapse from 'react-collapsed';
import * as MomentObject from 'moment-timezone';
import { Moment } from 'moment';
import {
  calculateRemainingQuarterSteps,
  // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
  //DATE_FORMAT,
  PRETTY_DATE_FORMAT,
} from '../../../util/index';
import { extendMoment } from 'moment-range';
import {
  DayRecordEntry,
  TimeRecordEntryMoment,
  TimeRecordFormModel,
} from '../../../../../models/TimeRecord';
import { makePrioStyles } from '../../../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Flex from '../../../../../components/Flex';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AbsentWorkDaysResult } from '../../../../../models/AbsenceProposal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../../theme/types';
// TODO: Implement when project admin role gets added to the calculateOffdays endpoint
//import { apiFetchAbsentWorkDays } from '../../../../hr/api';
//import { useSelector } from 'react-redux';
//import { getUserMeContactId } from '../../../../../apps/main/rootReducer';
//import { ApiResult } from '../../../../../api';

const moment = extendMoment(MomentObject);

const useStyles = makePrioStyles((theme) => ({
  root: {},
  controlBarCollapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
    alignItems: 'center',
  },
  controlBarCollapseHeader: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  controlBar: {
    marginBottom: theme.old.spacing.unit(3),
  },

  previewTable: {
    borderRadius: '20px',
    '& .ant-table-row .ant-table-cell .create-contact-form-project-assignment-deleteButton':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .create-contact-form-project-assignment-deleteButton':
      {
        visibility: 'visible',
      },
  },
  timeRecordTable: {},
  previewTableRow: {},
  weekendRow: {
    borderLeft: `5px solid black`,
  },
  holidayRow: {
    borderLeft: `5px solid ${theme.old.palette.chromaticPalette.yellow}`,
  },
  dayTypeIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  dayType: {
    height: '100%',
    width: 50,
  },
  dayTypeCell: {
    height: 64,
    padding: '2px 0!important',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  duration: {
    marginRight: theme.old.spacing.unit(2),
    transition: 'border-color 0.3s',
  },
  preview: {
    padding: theme.old.spacing.defaultPadding,
    border: theme.old.borders.sub,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    marginBottom: theme.old.spacing.defaultPadding,
  },
}));

interface TimeRecordPreviewProps {
  isExpanded: boolean;
  workingDaysRange: Moment[];
  pickedTimeRecords: TimeRecordEntryMoment[];
  form: FormInstance<TimeRecordFormModel>;
}

export const TimeRecordPreview: React.FC<TimeRecordPreviewProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const { isExpanded, workingDaysRange, pickedTimeRecords, form } = props;
  const contactId = form.getFieldValue(['contactId']);
  // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
  //const meContactId = useSelector(getUserMeContactId);
  const columns: ColumnProps<DayRecordEntry>[] = [
    {
      className: classes.dayTypeCell,
      render: (_, record) => {
        return <div>{getDayEntryIcon(record)}</div>;
      },
      width: 50,
    },
    {
      render: (_, record, index) => (
        <Typography.Text>{record.date.format('dddd')}</Typography.Text>
      ),
    },
    {
      render: (_, record, index) => (
        <Typography.Text>
          {record.date.format(PRETTY_DATE_FORMAT)}
        </Typography.Text>
      ),
    },
    {
      render: (_, record, index) => (
        <Button
          className={classNames(
            'create-contact-form-project-assignment-deleteButton'
          )}
          onClick={() => {
            handleDeleteDayRecord(record.key);
          }}
          type="link"
          iconProp={['fal', 'trash']}
        ></Button>
      ),
      width: theme.old.spacing.unit(2),
    },
  ];

  const { getCollapseProps } = useCollapse({ isExpanded });

  //#endregion

  //#region -------------------------------- State declaration
  const [dayRecordEntries, setDayRecordEntries] = useState<DayRecordEntry[]>(
    []
  );
  const [includeWeekends, setIncludeWeekends] = useState<boolean>(true);
  const [
    includeHolidays,
    // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
    //setIncludeHolidays
  ] = useState<boolean>(true);
  var absentWorkDaysRef = useRef<AbsentWorkDaysResult>();
  const [uniqueNumberKey, setUniqueNumberKey] = useState<number>(
    pickedTimeRecords.length
  );

  //#endregion

  //#region -------------------------------- Methods

  // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
  // const isMe = () => {
  //   if (meContactId === contactId) {
  //     return true;
  //   }
  //   return false;
  // };

  //Converts Slider-Index into Timestring
  const calculateTime = (range: [number, number], startTime: number) => {
    let minStr = '';
    var _value = range[1] - range[0];
    const _x = (_value * 15) / 60;
    const hour = Math.trunc(_x) + startTime;
    const min = Math.round((_x - Math.trunc(_x)) * 60);
    if (min.toString().length === 1) {
      minStr = min.toString() + '0';
    } else {
      minStr = min.toString();
    }
    return `${hour}:${minStr}`;
  };

  //Converts Moment into Slider-Index
  const decalculateTime = (m1: Moment, m2: Moment) => {
    const hour1 = ((m1.hour() - 6) / 15) * 60;
    const min1 = m1.minute() / 15;

    const index1 = hour1 + min1;

    const hour2 = ((m2.hour() - 6) / 15) * 60;
    const min2 = m2.minute() / 15;

    const index2 = hour2 + min2;
    return [index1, index2];
  };

  const getRowCss = (record: DayRecordEntry) => {
    if (isWeekend(record.date)) {
      return classes.weekendRow;
    }
    if (isHoliday(record.date)) {
      return classes.holidayRow;
    }
    return null;
  };

  const getDayEntryIcon = (record: DayRecordEntry) => {
    if (record.isWeekend) {
      return (
        <Tooltip
          title={t('timeRecords:form.timeRecordPreview.tooltip.weekend')}
        >
          <FontAwesomeIcon
            className={classes.dayTypeIcon}
            icon={['fal', 'glass-citrus']}
            size="1x"
            color={theme.old.palette.chromaticPalette.grey}
          />
        </Tooltip>
      );
    }
    if (record.isHoliday) {
      return (
        <Tooltip title={record.holidayName}>
          <FontAwesomeIcon
            className={classes.dayTypeIcon}
            icon={['fal', 'calendar-star']}
            size="1x"
            color={theme.old.palette.chromaticPalette.yellow}
          />
        </Tooltip>
      );
    }
    return null;
  };

  const isWeekend = (date: Moment) => {
    if (date.day() === 6 || date.day() === 0) {
      return true;
    }
    return false;
  };

  const isHoliday = (date: Moment) => {
    if (
      absentWorkDaysRef &&
      absentWorkDaysRef.current &&
      absentWorkDaysRef.current.publicHolidays
    ) {
      const isPublicHoliday = absentWorkDaysRef.current.publicHolidays.some(
        (publicHoliday) => date.isSame(moment(publicHoliday.date), 'day')
      );

      if (isPublicHoliday) {
        return true;
      }
    }

    return false;
  };

  //#endregion

  //#region -------------------------------- Handle methods

  const handleDeleteDayRecord = (key: React.Key) => {
    setDayRecordEntries([
      ...dayRecordEntries.filter((dayRecord) => dayRecord.key !== key),
    ]);
  };

  const handleAddTimeRecordEntry = (dayRecordKey: React.Key) => {
    setDayRecordEntries([
      ...dayRecordEntries.map((dayRecord) => {
        if (dayRecord.key !== dayRecordKey) {
          return dayRecord;
        }

        var newTimerecord: TimeRecordEntryMoment = null;

        if (dayRecord.timeRecordEntries.length >= 1) {
          const start = moment(
            dayRecord.timeRecordEntries[
              dayRecord.timeRecordEntries.length - 1
            ].endTime.toISOString()
          );

          const end = moment(start.toISOString());

          newTimerecord = {
            key: uniqueNumberKey,
            dayRecordKey: dayRecordKey,
            startTime: start,
            endTime: end,
          };
        } else {
          newTimerecord = {
            key: 0,
            dayRecordKey: dayRecordKey,
            startTime: moment(),
            endTime: moment(),
          };
        }
        setUniqueNumberKey(uniqueNumberKey + 1);
        return {
          ...dayRecord,
          timeRecordEntries: [...dayRecord.timeRecordEntries, newTimerecord],
        };
      }),
    ]);
  };

  const handleDeleteTimeRecord = (
    dayRecordKey: React.Key,
    timeRecordKey: React.Key
  ) => {
    setDayRecordEntries([
      ...dayRecordEntries.map((dayRecord) => {
        if (dayRecord.key !== dayRecordKey) {
          return dayRecord;
        }
        return {
          ...dayRecord,
          timeRecordEntries: dayRecord.timeRecordEntries.filter(
            (timeRecord) => timeRecord.key !== timeRecordKey
          ),
        };
      }),
    ]);
  };

  const handleOnChangeTimeRecordEntry = (
    dayRecordKey: React.Key,
    timeRecordKey: React.Key,
    times: Moment[]
  ) => {
    setDayRecordEntries([
      ...dayRecordEntries.map((dayRecord) => {
        if (dayRecord.key !== dayRecordKey) {
          return dayRecord;
        }
        var updatedTimeRecords = dayRecord.timeRecordEntries;
        const index = updatedTimeRecords.findIndex(
          (timeRecord) => timeRecord.key === timeRecordKey
        );

        return {
          ...dayRecord,
          timeRecordEntries: [
            ...dayRecord.timeRecordEntries.slice(0, index),
            {
              ...dayRecord.timeRecordEntries[index],
              startTime: moment(times[0].toISOString()),
              endTime: moment(times[1].toISOString()),
            },
            ...dayRecord.timeRecordEntries.slice(index + 1),
          ],
        };
      }),
    ]);
  };

  const handleOnChangeKilometerDistance = (
    dayRecordKey: React.Key,
    kilometer: number
  ) => {
    setDayRecordEntries([
      ...dayRecordEntries.map((dayRecord) => {
        if (dayRecord.key !== dayRecordKey) {
          return dayRecord;
        }
        return {
          ...dayRecord,
          kilometerDistance: kilometer,
        };
      }),
    ]);
  };

  const handleOnCurrentWorkDurationChange = (
    dayRecordKey: React.Key,
    timeRecordKey: React.Key,
    value: number
  ) => {
    setDayRecordEntries([
      ...dayRecordEntries.map((dayRecord) => {
        if (dayRecord.key !== dayRecordKey) {
          return dayRecord;
        }
        var updatedTimeRecords = dayRecord.timeRecordEntries;
        const index = updatedTimeRecords.findIndex(
          (timeRecord) => timeRecord.key === timeRecordKey
        );

        return {
          ...dayRecord,
          timeRecordEntries: [
            ...dayRecord.timeRecordEntries.slice(0, index),
            {
              ...dayRecord.timeRecordEntries[index],
              endTime: moment(
                dayRecord.timeRecordEntries[index].startTime.toISOString()
              ).add(value, 'minutes'),
            },
            ...dayRecord.timeRecordEntries.slice(index + 1),
          ],
        };
      }),
    ]);
  };

  const handleOnChangeIncludeWeekends = (e: CheckboxChangeEvent) => {
    setIncludeWeekends(e.target.checked);
  };

  // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
  // const handleOnChangeIncludeHolidays = (e: CheckboxChangeEvent) => {
  //   setIncludeHolidays(e.target.checked);
  // };

  //#endregion

  //#region -------------------------------- Hooks

  useEffect(() => {
    // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
    // const getHolidayName = (date: Moment) => {
    //   if (
    //     absentWorkDaysRef &&
    //     absentWorkDaysRef.current &&
    //     absentWorkDaysRef.current.publicHolidays
    //   ) {
    //     const publicHoliday = absentWorkDaysRef.current.publicHolidays.find(
    //       (publicHoliday) => date.isSame(moment(publicHoliday.date), 'day')
    //     );

    //     if (publicHoliday) {
    //       return publicHoliday.name;
    //     }
    //   }

    //   return t('timeRecords:form.timeRecordPreview.tooltip.holiday');
    // };

    // const fetchAbsenceOffdays = async () => {
    //   const startDate = workingDaysRange[0];
    //   const endDate = workingDaysRange[1];
    //   try {
    //     var result: ApiResult<AbsentWorkDaysResult>;
    //     if (isMe()) {
    //       result = await apiFetchAbsentWorkDays(
    //         startDate.format(DATE_FORMAT),
    //         endDate.format(DATE_FORMAT),
    //         false,
    //         false
    //       );
    //     } else {
    //       result = await apiFetchAbsentWorkDays(
    //         startDate.format(DATE_FORMAT),
    //         endDate.format(DATE_FORMAT),
    //         false,
    //         false,
    //         contactId
    //       );
    //     }
    //     if (result) {
    //       if (result.data) {
    //         absentWorkDaysRef.current = result.data;
    //       } else {
    //         notification.open({
    //           message: t('common:error'),
    //           description: t('timeRecords:errorMessages.fetchOffDays'),
    //         });
    //       }
    //     }
    //   } catch {}
    // };

    const updateDayWorkEntries = async () => {
      const startDate = workingDaysRange[0];
      const endDate = workingDaysRange[1];
      // TODO: Implement when project admin role gets added to the calculateOffdays endpoint
      //await fetchAbsenceOffdays();
      const range = moment.range(startDate, endDate);
      var intermediateDayRecordEntryArray: DayRecordEntry[] = [];
      var dates = Array.from(range.by('days'));

      dates.forEach((element, index) => {
        var copyArray: TimeRecordEntryMoment[] = [];
        pickedTimeRecords.forEach((timeRecord, timeRecordIndex) => {
          copyArray.push({
            key: timeRecordIndex,
            dayRecordKey: index,
            startTime: moment(
              `${element.toISOString(true).split('T')[0]}T${
                timeRecord.startTime.toISOString(true).split('T')[1]
              }`
            ),
            endTime: moment(
              `${element.toISOString(true).split('T')[0]}T${
                timeRecord.endTime.toISOString(true).split('T')[1]
              }`
            ),
          });
        });
        intermediateDayRecordEntryArray.push({
          key: index,
          date: element,
          isWeekend: isWeekend(element),
          isHoliday: isHoliday(element),
          // holidayName: getHolidayName(element),
          holidayName: '',
          timeRecordEntries: copyArray,
        });
      });
      if (!includeHolidays && !includeWeekends) {
        intermediateDayRecordEntryArray =
          intermediateDayRecordEntryArray.filter(
            (element) => !element.isHoliday && !element.isWeekend
          );
      } else if (!includeHolidays) {
        intermediateDayRecordEntryArray =
          intermediateDayRecordEntryArray.filter(
            (element) => !element.isHoliday
          );
      } else if (!includeWeekends) {
        intermediateDayRecordEntryArray =
          intermediateDayRecordEntryArray.filter(
            (element) => !element.isWeekend
          );
      }

      setDayRecordEntries(intermediateDayRecordEntryArray);
    };

    if (workingDaysRange && workingDaysRange.length >= 1 && pickedTimeRecords) {
      updateDayWorkEntries();
    }
  }, [
    workingDaysRange,
    pickedTimeRecords,
    includeWeekends,
    includeHolidays,
    contactId,
    t,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      dayRecordEntries: dayRecordEntries,
    });
  }, [dayRecordEntries, form]);

  //#endregion

  //#region -------------------------------- Components
  const expandableRowContent = (dayRecord: DayRecordEntry) => {
    const expandableRowContentColumns: ColumnProps<TimeRecordEntryMoment>[] = [
      {
        render: (_, record, index) => (
          <TimePicker.RangePicker
            style={{ width: '100%' }}
            format="HH:mm"
            suffixIcon={null}
            minuteStep={15}
            allowClear={false}
            showNow={false}
            value={[record.startTime, record.endTime]}
            onChange={(values) => {
              handleOnChangeTimeRecordEntry(
                record.dayRecordKey,
                record.key,
                values
              );
            }}
          />
        ),
        width: theme.old.spacing.unit(60),
      },

      {
        render: (_, record, index) => (
          <div className={classes.duration}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={(e: string) => {
                handleOnCurrentWorkDurationChange(
                  record.dayRecordKey,
                  record.key,
                  parseInt(e)
                );
              }}
              value={calculateTime(
                [
                  decalculateTime(
                    record === undefined
                      ? moment('8:00', 'h:mm a')
                      : record.startTime,
                    record === undefined
                      ? moment('16:00', 'h:mm a')
                      : record.endTime
                  )[0],
                  decalculateTime(
                    record === undefined
                      ? moment('8:00', 'h:mm a')
                      : record.startTime,
                    record === undefined
                      ? moment('16:00', 'h:mm a')
                      : record.endTime
                  )[1],
                ],
                0
              )}
            >
              {calculateRemainingQuarterSteps(
                record.startTime.hours(),
                record.startTime.minutes()
              ).map((quarterStep) => (
                <Select.Option value={quarterStep[1]}>
                  {quarterStep[0]}
                </Select.Option>
              ))}
            </Select>
          </div>
        ),
        width: theme.old.spacing.unit(20),
      },
      {
        render: (_, record, index) => {
          return (
            dayRecord.timeRecordEntries.length > 1 && (
              <Button
                className={classNames(
                  'create-contact-form-project-assignment-deleteButton'
                )}
                onClick={() => {
                  handleDeleteTimeRecord(record.dayRecordKey, index);
                }}
                iconProp={['fal', 'trash']}
              ></Button>
            )
          );
        },
        width: theme.old.spacing.unit(2),
      },
    ];

    return (
      <Flex.Column childrenGap={20}>
        <Flex.Item>
          <Table
            className={classes.timeRecordTable}
            columns={expandableRowContentColumns}
            pagination={false}
            showHeader={false}
            dataSource={dayRecord.timeRecordEntries}
          />
        </Flex.Item>
        <Flex.Item>
          <Flex.Item>
            <Button
              type="link"
              style={{ paddingLeft: 0 }}
              onClick={() => {
                handleAddTimeRecordEntry(dayRecord.key);
              }}
              iconProp={['fal', 'plus']}
            >
              {t('timeRecords:form.actions.addTimeRecordEntry')}
            </Button>
          </Flex.Item>
        </Flex.Item>
        <Flex.Item>
          <Form.Item
            label={t('timeRecords:form.labels.kilometerDistance')}
            rules={[
              {
                message: t(
                  'timeRecords:form.validation.invalidKilometerDistance'
                ),
                pattern: new RegExp('[0-9]+'),
              },
              () => ({
                async validator(rule, value) {
                  if (value < 0) {
                    return Promise.reject(
                      t('timeRecords:form.validation.invalidKilometerDistance')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="number"
              pattern="[0-9]"
              min={0}
              suffix={
                <FontAwesomeIcon
                  icon={['fal', 'road']}
                  className={classes.mutedColor}
                />
              }
              onChange={(e) => {
                handleOnChangeKilometerDistance(
                  dayRecord.key,
                  parseInt(e.target.value)
                );
              }}
            />
          </Form.Item>
        </Flex.Item>
      </Flex.Column>
    );
  };

  const controlBar = () => {
    return (
      <Collapse ghost className={classes.controlBarCollapse}>
        <Collapse.Panel
          key="1"
          header={
            <Flex.Row className={classes.controlBarCollapseHeader}>
              <Flex.Item>
                <Typography.Text>Filter</Typography.Text>
              </Flex.Item>
              <Flex.Item>
                <Divider />
              </Flex.Item>
            </Flex.Row>
          }
        >
          <Card className={classes.controlBar}>
            <Flex.Row>
              <Flex.Item>
                <Checkbox
                  value={includeWeekends}
                  onChange={handleOnChangeIncludeWeekends}
                >
                  <Typography.Text>
                    {t(
                      'timeRecords:form.timeRecordPreview.controlBar.includeWeekends'
                    )}
                  </Typography.Text>
                </Checkbox>
              </Flex.Item>
              {/* TODO: Implement when project admin role gets added to the calculateOffdays endpoint */}
              {/* <Flex.Item>
                <Checkbox
                  value={includeHolidays}
                  onChange={handleOnChangeIncludeHolidays}
                >
                  <Typography.Text>
                    {t(
                      'timeRecords:form.timeRecordPreview.controlBar.includeHolidays'
                    )}
                  </Typography.Text>
                </Checkbox>
              </Flex.Item> */}
              {/* <Flex.Item>
                <Checkbox
                  value={includeVacations}
                  onChange={handleOnChangeIncludeVacations}
                >
                  <Typography.Text>
                    {t(
                      'timeRecords:form.timeRecordPreview.controlBar.includeVacations'
                    )}
                  </Typography.Text>
                </Checkbox>
              </Flex.Item> */}
            </Flex.Row>
          </Card>
        </Collapse.Panel>
      </Collapse>
    );
  };
  //#endregion

  return (
    <section {...getCollapseProps()}>
      <div className={classes.preview}>
        {controlBar()}
        <Table
          className={classes.previewTable}
          rowClassName={getRowCss}
          columns={columns}
          pagination={false}
          showHeader={false}
          expandable={{
            expandedRowRender: (record) => expandableRowContent(record),
          }}
          dataSource={dayRecordEntries}
        />
      </div>
    </section>
  );
};

export default TimeRecordPreview;
