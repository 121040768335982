import React, { useCallback, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavigationBar from '../../../components/NavigationBar';
import InvoicesManagement from './InvoicesManagement';
import { ProjectId } from '../../../models/Types';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  excel: {
    marginLeft: theme.old.spacing.defaultPadding,
  },
  excelIcon: {
    marginLeft: theme.old.spacing.unit(1.5),
    marginRight: theme.old.spacing.unit(1.5),
  },
}));

interface InvoicesControllingSubModuleProps {
  className?: string;
  projectId: ProjectId;
}

export const InvoicesControllingSubModule: React.FC<
  InvoicesControllingSubModuleProps
> = (props) => {
  const { className, projectId } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [createInvoiceActive, setCreateInvoiceActive] =
    useState<boolean>(false);

  const toggleCreateInvoiceActive = () =>
    setCreateInvoiceActive(!createInvoiceActive);

  const onPanelClose = useCallback(() => setCreateInvoiceActive(false), []);

  if (!projectId) return null;

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>
        <Button
          type="primary"
          onClick={toggleCreateInvoiceActive}
          iconProp={['fal', 'file-medical']}
        >
          <span>{t('accounting:navigationBar.createInvoice')}</span>
        </Button>
        {false && (
          <div className={classes.excel}>
            <FontAwesomeIcon
              className={classes.excelIcon}
              icon={['fal', 'file-excel']}
            />
            <span>{t('accounting:navigationBar.exportExcel')}</span>
          </div>
        )}
      </NavigationBar>
      <InvoicesManagement
        createInvoiceActive={createInvoiceActive}
        onClose={onPanelClose}
        projectId={projectId}
      />
    </Flex.Column>
  );
};

export default InvoicesControllingSubModule;
