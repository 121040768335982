import { combineReducers, Reducer } from 'redux';
import {
  FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT,
  CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  FETCH_EXTERNAL_PROJECT_CONTACTS_REQUEST,
  FETCH_EXTERNAL_PROJECT_CONTACTS_ROLLBACK,
  CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
  CREATE_EXTERNAL_PROJECT_CONTACT_COMMIT,
  UPDATE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  UPDATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
  UPDATE_EXTERNAL_PROJECT_CONTACT_COMMIT,
  ARCHIVE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
  ARCHIVE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  UPDATE_PROJECT_CONTACTS_REQUEST,
  UPDATE_PROJECT_CONTACTS_COMMIT,
  UPDATE_PROJECT_CONTACTS_ROLLBACK,
} from '../actions';
import {
  ExternalProjectContact,
  UpdateProjectContact,
} from '../../../models/ProjectContacts';
import { ProjectId } from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface ExternalProjectContactsState {
  data: DataState;
  meta: ExternalProjectContactsMeta;
}

export interface DataState {
  [projectId: string]: ExternalProjectContact[];
}

const data: Reducer<DataState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: payload,
      };
    }

    /** CREATE */

    case CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []),
          {
            ...payload,
            isTemporary: true,
            latestUpdate: new Date().toISOString(),
          },
        ],
      };
    }

    case CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId, contactId },
      } = action;
      const contacts = state[projectId];
      if (!contacts) return state;
      return {
        ...state,
        [projectId]: contacts.filter(
          (projectContact: ExternalProjectContact) =>
            projectContact.contactId !== contactId
        ),
      };
    }

    case CREATE_EXTERNAL_PROJECT_CONTACT_COMMIT: {
      const {
        meta: { projectId, contactId },
        payload: createdContact,
      } = action;
      const contacts = state[projectId];
      if (!contacts) return state;
      return {
        ...state,
        [projectId]: contacts.map((projectContact: ExternalProjectContact) =>
          projectContact.contactId === contactId
            ? createdContact
            : projectContact
        ),
      };
    }

    /** UPDATE */

    case UPDATE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        meta: { projectId, projectContacts },
      } = action;
      const changedInternalProjectContacts =
        projectContacts as ExternalProjectContact[];
      const changedInternalProjectContactsIds =
        changedInternalProjectContacts.map(
          (contact) => contact.externalProjectContactId
        );
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (projectContact) =>
              !changedInternalProjectContactsIds.includes(
                projectContact.externalProjectContactId
              )
          ),
          ...changedInternalProjectContacts.map((contact) => contact),
        ],
      };
    }

    case UPDATE_EXTERNAL_PROJECT_CONTACT_COMMIT: {
      const {
        payload,
        meta: { projectId, projectContacts },
      } = action;

      const updatedContacts =
        payload.internalProjectContacts as ExternalProjectContact[];
      const changedInternalProjectContacts =
        projectContacts as ExternalProjectContact[];
      const changedInternalProjectContactsIds =
        changedInternalProjectContacts.map(
          (contact) => contact.externalProjectContactId
        );

      return {
        ...state,
        [projectId]: (state[projectId] ?? []).map((projectContact) => {
          if (
            changedInternalProjectContactsIds.includes(
              projectContact.externalProjectContactId
            )
          ) {
            const updatedContact = updatedContacts.find(
              (contact) =>
                contact.externalProjectContactId ===
                projectContact.externalProjectContactId
            );

            if (updatedContact) {
              return {
                projectContact,
              };
            }
          }

          return projectContact;
        }),
      };
    }

    case UPDATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId, rollbackContacts },
      } = action;

      const _rollbackContacts = rollbackContacts as ExternalProjectContact[];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const rollbackContact = _rollbackContacts.find(
              (contact) =>
                contact.externalProjectContactId ===
                projectContact.externalProjectContactId
            );
            return rollbackContact ?? projectContact;
          }),
        ],
      };
    }

    case UPDATE_PROJECT_CONTACTS_REQUEST: {
      const {
        meta: { projectId, request },
      } = action;
      const _request = request as UpdateProjectContact[];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const updatedContact = _request.find(
              (contact) =>
                contact.externalProjectContactId ===
                projectContact.externalProjectContactId
            );
            return { ...projectContact, ...(updatedContact ?? {}) };
          }),
        ],
      };
    }

    case UPDATE_PROJECT_CONTACTS_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;

      const _payload: ExternalProjectContact[] =
        payload.externalProjectContacts ?? [];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const updatedContact = _payload.find(
              (contact) =>
                contact.externalProjectContactId ===
                projectContact.externalProjectContactId
            );
            return updatedContact ?? projectContact;
          }),
        ],
      };
    }

    case UPDATE_PROJECT_CONTACTS_ROLLBACK: {
      const {
        meta: { projectId, rollback },
      } = action;

      const _rollback = rollback as ExternalProjectContact[];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const rollbackContact = _rollback.find(
              (contact) =>
                contact.externalProjectContactId ===
                projectContact.externalProjectContactId
            );
            return rollbackContact ?? projectContact;
          }),
        ],
      };
    }

    case ARCHIVE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId },
        rollbackContact,
      } = action;
      if (!rollbackContact) return state;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []),
          {
            ...rollbackContact,
          },
        ],
      };
    }

    case ARCHIVE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        meta: { projectId, externalProjectContactId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (projectContact) =>
              projectContact.externalProjectContactId !==
              externalProjectContactId
          ),
        ],
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface ExternalProjectContactsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ExternalProjectContactsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_EXTERNAL_PROJECT_CONTACTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_EXTERNAL_PROJECT_CONTACTS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_EXTERNAL_PROJECT_CONTACTS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage:
          'projects:errorMessages.fetchExternalProjectContactsError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<ExternalProjectContactsState>({
  data,
  meta,
});

export const getData: (state: any) => DataState = (state: any) => state.data;
export const getAllExternalProjectContacts: (
  state: any,
  projectId: ProjectId
) => ExternalProjectContact[] = (state: any, projectId: ProjectId) =>
  state.data[projectId] ?? [];

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
