import { PRIO } from '../../../../constants';
import { ProjectId } from '../../../../models/Types';

export const SET_ACTIVE_PROJECT_IN_MESSAGE_CENTER =
  PRIO + 'SET_ACTIVE_PROJECT_IN_MESSAGE_CENTER';

export const setActiveProjectInMessageCenter = (projectId: ProjectId) => ({
  type: SET_ACTIVE_PROJECT_IN_MESSAGE_CENTER,
  projectId,
});
