// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/third_party/font_awesome.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import FroalaEditor from 'froala-editor';
import FroalaEditorComponent from 'react-froala-wysiwyg';

// plugins
import 'froala-editor/js/plugins.pkgd.min.js';

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { makePrioStyles } from '../../../../theme/utils';
import TextBlocksModal from '../../../settings/components/TextBlocksModal';
import { TextBlock } from '../../../../models/TextBlock';
import { useTranslation } from 'react-i18next';
import { faSignature } from '@fortawesome/pro-regular-svg-icons';

import { v4 as uuid } from 'uuid';
import {
  DEFAULT_BR_VALUE,
  DEFAULT_FROALA_CONFIG,
  DEFAULT_FROALA_EVENTS,
  DEFAULT_TOOLBAR_BUTTONS,
} from '../../utils';
import { icon } from '@fortawesome/fontawesome-svg-core';
import SignatureInsertModal from './SignatureInsert/SignatureModal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
    '& .fr-box.fr-basic .fr-wrapper': {
      border: 'none',
    },
    '& .fr-box.fr-basic': {
      borderRadius: 0,
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    '& .fr-box.fr-basic.fr-bottom .fr-wrapper': {
      border: 'none',
      borderRadius: 0,
      flex: 1,
      overflow: 'scroll',
    },
    '& .fr-box.fr-basic .fr-element': {
      padding: theme.old.spacing.unit(2),
      fontFamily: 'inherit',
    },
    '& .fr-toolbar.fr-bottom': {
      border: 'none',
      borderRadius: 0,
      '& .fr-newline': {
        display: 'none',
      },
    },
    '& .fr-toolbar .fr-btn-grp': {
      margin: `0 ${theme.old.spacing.unit(2)}px`,
    },
    '& .fr-toolbar .fr-command.fr-btn.fr-active, .fr-popup .fr-command.fr-btn.fr-active, .fr-modal .fr-command.fr-btn.fr-active':
      {
        color: 'var(--ant-primary-5)',
      },
    '& .fr-toolbar .fr-more-toolbar': {
      position: 'inherit',
    },
    '& .fr-quick-insert': {
      left: '64px!important',
    },
    '& .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn':
      {
        borderRadius: 2,
      },
    '& .fr-toolbar.fr-bottom .fr-command.fr-btn.fr-open': {
      borderRadius: '0 0 2px 2px',
    },
  },
}));

const createFileInEditor = (file: Blob, editor: any) => {
  let imageUUID = uuid();

  let url = URL.createObjectURL(file);
  editor.image.insert(
    url,
    null,
    { name: 'inline-image-' + imageUUID, id: imageUUID },
    editor.image.get()
  );
};

export interface MailEditorRefProps {
  getHtml: () => string;
}

interface MailEditorProps {
  toolbarContainer: string;
  body?: string;
  textUpdated?: (body: string) => void;
  disabled?: boolean;
  autofocus?: boolean;
}

export const MailEditor = forwardRef<MailEditorRefProps, MailEditorProps>(
  (props, ref) => {
    //#region ------------------------------ Defaults
    const classes = useStyles();
    const { autofocus, body, toolbarContainer, textUpdated } = props;
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const innerRef = useRef(null);

    const bodyState = React.useMemo(
      () => (body.match(/^(<div><br><\/div>|\s*)$/) ? DEFAULT_BR_VALUE : body),
      [body]
    );

    const [showTextBlocksModal, setShowTextBlocksModal] =
      useState<boolean>(false);
    const [showInsertSignatureModal, setShowInsertSignatureModal] =
      useState<boolean>(false);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleCancelTextBlocks = () => {
      setShowTextBlocksModal(false);
    };

    const handleCancelInsertSignature = () => {
      setShowInsertSignatureModal(false);
    };

    const handleInsertTextBlocks = (textBlocks: TextBlock[]) => {
      innerRef?.current?.editor.selection.restore();
      innerRef?.current?.editor.html.insert(
        textBlocks.map((textBlock) => textBlock.content).join('\n')
      );
      innerRef?.current?.editor.undo.saveStep();
      setShowTextBlocksModal(false);
    };

    const handleInsertSignature = (value: string) => {
      innerRef?.current?.editor.selection.restore();
      innerRef?.current?.editor.html.insert(value);
      innerRef?.current?.editor.undo.saveStep();
      setShowInsertSignatureModal(false);
    };
    const handleClearFormatting = () => {
      innerRef?.current?.editor.commands.clearFormatting();
      innerRef?.current?.editor.fontFamily.apply('sans-serif');
    };

    const handleInsertPlainFromClipboard = () => {
      navigator.clipboard
        .readText()
        ?.then((text) => innerRef?.current?.editor.html.insert(text));
    };

    const handleDropEvent = (dropEvent) => {
      const itemList: DataTransferItemList =
        dropEvent?.originalEvent?.dataTransfer?.items ?? null;
      const files: FileList =
        dropEvent?.originalEvent?.dataTransfer?.files ?? null;

      var areNonImageFilesOrText: boolean = true;
      var isNoFile: boolean = false;

      if (itemList) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; areNonImageFilesOrText && i < itemList.length; i++) {
          // If dropped items aren't files, reject them
          if (itemList[i]?.kind === 'file') {
            if (!itemList[i].type.match(/image\/(\D)+/)) {
              areNonImageFilesOrText = false;
            }
          } else {
            isNoFile = true;
          }
        }
      } else if (files) {
        //Use DataTransfer interface to access the file(s)
        for (
          var index = 0;
          areNonImageFilesOrText && index < files.length;
          index++
        ) {
          if (files.item(index).type.match(/image\/(\D)+/)) {
            areNonImageFilesOrText = false;
          }
        }
      } else {
        isNoFile = true;
      }

      if (areNonImageFilesOrText) {
        if (!isNoFile) {
          dropEvent.preventDefault();
          dropEvent.stopPropagation();
        }
        return true;
      }
      return false;
    };

    //#endregion

    //#region ------------------------------ Effects
    //#endregion

    /**---------------------------------- Icons  */
    FroalaEditor.DefineIconTemplate('font_awesome', '[HTML]');
    FroalaEditor.DefineIconTemplate('font_awesome_quick', '[HTML]');
    FroalaEditor.DefineIcon('textBlocksIcon', {
      NAME: 'file-alt',
      template: 'font_awesome_quick',
    });
    FroalaEditor.DefineIcon('textBlocksQuickIcon', {
      NAME: 'file-alt',
      SVG_KEY: 'insertMore',
    });
    FroalaEditor.DefineIcon('signatureInsertIcon', {
      NAME: 'signature',
      template: 'font_awesome_quick',
      HTML: [
        icon(faSignature).html[0].slice(0, 4),
        " style='margin-bottom: 4px'",
        icon(faSignature).html[0].slice(4),
      ].join(''),
    });
    FroalaEditor.DefineIcon('pastePlainFromClipboardTest', {
      NAME: 'file-alt',
      template: 'font_awesome_quick',
    });
    FroalaEditor.DefineIcon('clearCustomFormattingIcon', {
      NAME: 'clearCustomFormattingIcon',
      SVG_KEY: 'clearFormatting',
    });
    FroalaEditor.DefineIcon('pastePlainTextIcon', {
      NAME: 'pastePlainTextIcon',
      SVG_KEY: 'clearFormatting',
    });

    /**---------------------------------- Toolbar  */
    FroalaEditor.RegisterCommand('textBlocks', {
      title: t('mail:composer.textBlocks'),
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        setShowTextBlocksModal(true);
      },
      icon: 'textBlocksQuickIcon',
    });

    FroalaEditor.RegisterCommand('clearCustomFormatting', {
      title: t('mail:composer.clearFormatting'),
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        handleClearFormatting();
      },
      icon: 'clearCustomFormattingIcon',
    });

    /**---------------------------------- Icons  */
    FroalaEditor.RegisterQuickInsertButton('signatureInsert', {
      title: t('mail:composer.signature'),
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        setShowInsertSignatureModal(true);
      },
      icon: 'signatureInsertIcon',
    });

    FroalaEditor.RegisterQuickInsertButton('textBlocksQuick', {
      title: t('mail:composer.textBlocks'),
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        setShowTextBlocksModal(true);
      },
      icon: 'textBlocksQuickIcon',
    });

    FroalaEditor.RegisterQuickInsertButton('pastePlainText', {
      title: t('mail:composer.pastePlain'),
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        handleInsertPlainFromClipboard();
      },
      icon: 'pastePlainTextIcon',
    });

    const config = {
      ...DEFAULT_FROALA_CONFIG,
      placeholderText: '',
      autofocus: autofocus,
      wordPasteModal: true,
      language: 'de',
      // iconsTemplate: 'font_awesome_5',
      toolbarBottom: true,
      toolbarContainer: `#${toolbarContainer}`,
      toolbarButtons: {
        moreText: {
          ...DEFAULT_TOOLBAR_BUTTONS.text,
          buttonsVisible: 5,
        },
        moreParagraph: {
          ...DEFAULT_TOOLBAR_BUTTONS.paragraph,
          buttonsVisible: 2,
        },
        moreRich: {
          ...DEFAULT_TOOLBAR_BUTTONS.additional,
          buttonsVisible: 3,
        },
      },
      quickInsertEnabled: true,
      quickInsertButtons: [
        'image',
        'video',
        'embedly',
        'table',
        'ul',
        'ol',
        'hr',
        'textBlocksQuick',
        'pastePlainText',
        'signatureInsert',
      ],
      events: {
        ...DEFAULT_FROALA_EVENTS,
        contentChanged: function () {
          // Do something here.
          // this is the editor instance.
          if (textUpdated) {
            textUpdated(this.html.get());
          }
        },
        initialized: function () {
          this.events.on('drop', handleDropEvent, true);
        },
        'image.beforeUpload': function (files) {
          var editor = this;
          if (files.length) {
            if (files.length > 1) {
              for (let index = 0; index < files.length; index++) {
                createFileInEditor(files[index], editor);
              }
            } else {
              createFileInEditor(files[0], editor);
            }
          }
          editor.popups.hideAll();
          return false;
        },
        blur: function () {
          this.selection.save();
        },
      },
    };

    useImperativeHandle(ref, () => ({
      getHtml: () => {
        return innerRef?.current?.editor?.html?.get() ?? '';
      },
    }));

    return (
      <div className={classes.root}>
        <FroalaEditorComponent
          ref={innerRef}
          tag="textarea"
          config={config}
          model={bodyState}
        />
        {showTextBlocksModal && (
          <TextBlocksModal
            visible={showTextBlocksModal}
            onCancelTextBlocks={handleCancelTextBlocks}
            insertTextBlocks={handleInsertTextBlocks}
            popoverVisible={true}
          />
        )}
        {true && (
          <SignatureInsertModal
            visible={showInsertSignatureModal}
            onCancelSignatureInsert={handleCancelInsertSignature}
            insertSignatureInsert={handleInsertSignature}
          />
        )}
      </div>
    );
  }
);

export default MailEditor;
