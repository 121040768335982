import { PRIO } from '../../../constants';
import { ReduxAction } from '../../../models/Redux';
import { DriveItemId, ProjectId } from '../../../models/Types';

export interface LastOpenDriveItemFolderLocation {
  documentsPage: DriveItemId;
  documentsWidget: DriveItemId;
}
export interface UpdateLastOpenDriveItemFolderAction {
  type: string;
  lastOpenDriveItemFolderIds: Partial<LastOpenDriveItemFolderLocation>;
  projectId: ProjectId;
}

export const UPDATE_LAST_OPEN_DRIVE_FOLDER =
  PRIO + 'UPDATE_LAST_OPEN_DRIVE_FOLDER';

export const updateDriveItemPath: (
  projectId: ProjectId,
  lastOpenDriveItemFolderIds: Partial<LastOpenDriveItemFolderLocation>
) => ReduxAction = (projectId, lastOpenDriveItemFolderIds) => ({
  type: UPDATE_LAST_OPEN_DRIVE_FOLDER,
  lastOpenDriveItemFolderIds,
  projectId,
});
