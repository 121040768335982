import { PRIO } from '../../../constants';

/* Set last project location */
export interface LastAccountingLocation {
  type: string;
  accountingLocation: string;
}

export const SET_LAST_ACCOUNTING_LOCATION =
  PRIO + 'SET_LAST_ACCOUNTING_LOCATION';

export const setLastAccountingLocation: (accountingLocation: string) => LastAccountingLocation = (
  accountingLocation: string
) => ({
  type: SET_LAST_ACCOUNTING_LOCATION,
  accountingLocation,
});