import { useContext, useMemo } from 'react';
import { ContactsContext, ContactsContextProps } from '../context';

export const useContactsContext: (
  includeArchived?: boolean
) => ContactsContextProps = (includeArchived = false) => {
  const context = useContext(ContactsContext);

  return useMemo(() => {
    const { contacts, ...restContext } = context;
    const _contacts = includeArchived
      ? contacts
      : contacts.filter((contact) => !contact.isArchived);
    return {
      contacts: _contacts,
      ...restContext,
    };
  }, [context, includeArchived]);
};

export default useContactsContext;
