import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form } from 'antd';
import { PrioTheme } from '../../../../theme/types';
// import DocumentProtocolItem from './DocumentProtocolItem';
import { DEBOUNCED_DOCUMENT_META_DATA_UPDATE } from '../../sagas/watchUpdateDriveItemMetaData';
import {
  DriveItem,
  ListItemFieldColumnName,
  UpdateDriveItemField,
} from '../../../../models/Drive';
import { useDispatch } from 'react-redux';
import { useTheme } from 'react-jss';
import { makePrioStyles } from '../../../../theme/utils';
import {
  DocumentMetaData,
  DocumentTag,
  DriveItemVersion,
} from '../../../../models/Document';
import { DriveItemId, DriveItemListItemId } from '../../../../models/Types';
import DebouncedInputSearch from '../../../../components/DebouncedInputField/DebouncedInputSearch';
import DocumentTagPicker from '../DocumentTagPicker';
import CompanyPicker from '../../../companies/components/CompanyPicker';
import ContactPicker from '../../../contacts/components/ContactPicker';
import DebouncedInputTextArea from '../../../../components/DebouncedInputTextArea';
import Flex from '../../../../components/Flex';
import { apiFetchSavedAttachmentMetadata } from '../../../mail/api';
import { apiFetchProjectDocumentTags } from '../../api';
import DocumentVersionItem from '../DocumentVersionItem';
import { Button } from '@prio365/prio365-react-library';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '& .ant-form-item:last-child': {
      marginBottom: 0,
    },
  },
  linkInnerContainer: {
    width: 'fit-content',
    paddingRight: theme.spacing.small,
    '&:hover': { cursor: 'pointer' },
  },
  linkOuterContainer: {
    '& .ant-form-item-control-input': {
      minHeight: 0,
    },
  },
  pickerWidth: {
    width: '314px!important',
  },
  formAction: {
    background: 'transparent!important',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: theme.font.fontSize.extraSmall,
  },
}));

interface SingleDriveItemFieldDto {
  listItemId?: DriveItemListItemId;
  driveItemId: DriveItemId;
  columnName: ListItemFieldColumnName;
  values: string[];
}

type DriveItemFieldDtos = Array<SingleDriveItemFieldDto>;

interface PreviewModalMetaDataFormProps {
  className?: string;
  driveItem: DriveItem;
  groupId: string;
  activeProjectId: string;
  driveItemVersions: DriveItemVersion[];
}

export const PreviewModalMetaDataForm: React.FC<PreviewModalMetaDataFormProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const {
      className,
      driveItem,
      groupId,
      activeProjectId,
      driveItemVersions,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const theme = useTheme<PrioTheme>();
    const dispatch = useDispatch();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [showDriveItemVersions, setShowDriveItemVersions] =
      useState<boolean>(false);

    const [baseDriveItemFieldDtos, setBaseDriveItemFieldDtos] =
      useState<DriveItemFieldDtos>([]);

    const relevantMetaDataTypes = useMemo(
      () => [
        'Prio365AlternativeName',
        'Prio365CompanyIds',
        'Prio365ContactIds',
        'Prio365Description',
        'Prio365DriveItemTags',
        'Prio365AttachedMessageId',
      ],
      []
    );

    const initialItemFields = useMemo(
      () => driveItem.listItemFields,
      [driveItem.listItemFields]
    );

    const initialDocumentMetaDataValues = useMemo(
      () =>
        relevantMetaDataTypes.reduce(
          (acc, type) => {
            acc[type] = driveItem.listItemFields?.[type] ?? [];
            return acc;
          },
          {} as { key: string; value: string[] }
        ),
      [driveItem.listItemFields, relevantMetaDataTypes]
    );

    const initialDriveItemFieldDtos: DriveItemFieldDtos = useMemo(() => {
      const helperArray = Object.entries(initialDocumentMetaDataValues).map(
        ([key, value]) => {
          return {
            listItemId: driveItem?.sharepointIds?.listItemId,
            driveItemId: driveItem?.id,
            columnName: key as ListItemFieldColumnName,
            values: value as string[],
          };
        }
      );
      return helperArray;
    }, [
      initialDocumentMetaDataValues,
      driveItem?.sharepointIds?.listItemId,
      driveItem?.id,
    ]);

    const originalDriveItemFieldDtos = useMemo(() => {
      return baseDriveItemFieldDtos.length > 0
        ? baseDriveItemFieldDtos
        : initialDriveItemFieldDtos;
    }, [initialDriveItemFieldDtos, baseDriveItemFieldDtos]);

    const [projectDocumentTags, setProjectDocumentTags] = useState<
      DocumentTag[]
    >([]);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const setsAreEqual = (setA, setB) => {
      if (setA.size !== setB.size) return false;
      for (const a of setA) if (!setB.has(a)) return false;
      return true;
    };

    const onLinkToMessageClick = async () => {
      const { data } = await apiFetchSavedAttachmentMetadata(
        initialDocumentMetaDataValues['Prio365AttachedMessageId']
      );

      if (data) {
        const openMessageInNewWindow = () => {
          const projectIdOfMessage = data[0]?.projectId;
          const messageId =
            initialDocumentMetaDataValues['Prio365AttachedMessageId']?.[0];

          if (messageId && projectIdOfMessage) {
            const width = window.screen.availWidth / 2;
            const height = window.screen.availHeight / 2;
            window.open(
              `/view/${projectIdOfMessage}/message/${messageId}/details`,
              '_blank',
              `width=${width},height=${height},noopener,noreferrer`
            );
          }
        };

        openMessageInNewWindow();
      }
    };

    const reduceUpdateDriveItemFieldDtos = useCallback(
      (update: DriveItemFieldDtos) => {
        setBaseDriveItemFieldDtos(update);
        const _reducedUpdateDriveItemFieldDtos = update.filter(
          (dto1) =>
            !originalDriveItemFieldDtos.some((dto2) => {
              const set1 = new Set(
                dto1.values.map((obj) =>
                  JSON.stringify(obj, (key, value) => value)
                )
              );
              const set2 = new Set(
                dto2.values.map((obj) =>
                  JSON.stringify(obj, (key, value) => value)
                )
              );

              return (
                dto1.driveItemId === dto2.driveItemId &&
                dto1.columnName === dto2.columnName &&
                setsAreEqual(set1, set2)
              );
            })
        );

        return _reducedUpdateDriveItemFieldDtos;
      },
      [originalDriveItemFieldDtos]
    );

    const updateMetaData = async (values: DocumentMetaData) => {
      const updateDriveItemFieldDtos: DriveItemFieldDtos = [];
      const metaDataTypes = Object.keys(values);
      metaDataTypes.forEach((metaDataType) => {
        if (metaDataType === 'Prio365AttachedMessageId') return;
        updateDriveItemFieldDtos.push({
          listItemId: driveItem?.sharepointIds?.listItemId,
          driveItemId: driveItem.id,
          columnName: metaDataType as ListItemFieldColumnName,
          values: Array.isArray(values[metaDataType])
            ? values[metaDataType]
            : [values[metaDataType]],
        });
      });

      const reducedUpdateDriveItemFieldDtos: DriveItemFieldDtos =
        reduceUpdateDriveItemFieldDtos(updateDriveItemFieldDtos);

      const requestBody: UpdateDriveItemField = {
        groupId,
        updateDriveItemFieldDtos: reducedUpdateDriveItemFieldDtos,
      };

      const isParentRoot = driveItem.parentReference.path === '/drive/root:';

      if (groupId) {
        dispatch({
          type: DEBOUNCED_DOCUMENT_META_DATA_UPDATE,
          payload: {
            requestBody,
            driveItemId: driveItem.parentReference.id,
            isRoot: isParentRoot,
          },
        });

        const updatedDriveItem: DriveItem = driveItem;

        const updatedItemFields = initialItemFields;
        Object.keys(updateDriveItemFieldDtos).forEach((key) => {
          const columnName = updateDriveItemFieldDtos[key].columnName;
          const values = updateDriveItemFieldDtos[key].values;
          updatedItemFields[columnName] = values;
        });

        updatedDriveItem.listItemFields = updatedItemFields;
      }
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      form.setFieldsValue(initialDocumentMetaDataValues);
    }, [form, initialDocumentMetaDataValues]);

    useEffect(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      const loadProjectDocumentTags = async () => {
        try {
          const { data } = await apiFetchProjectDocumentTags(
            activeProjectId,
            signal
          );

          if (data && Array.isArray(data)) {
            setProjectDocumentTags(
              data.sort((a, b) => a.name.localeCompare(b.name))
            );
          }
        } catch {}
      };
      loadProjectDocumentTags();
      return () => {
        controller.abort();
      };
    }, [setProjectDocumentTags, activeProjectId]);

    useEffect(() => {
      setShowDriveItemVersions(false);
    }, [driveItem]);
    //#endregion

    return (
      <Form<DocumentMetaData>
        className={classNames(classes.form, className)}
        initialValues={initialDocumentMetaDataValues}
        form={form}
        layout="vertical"
        onValuesChange={(_, values) => {
          updateMetaData(values);
        }}
      >
        <Form.Item
          label={t('documents:documentMetaData.form.alternativeName')}
          name="Prio365AlternativeName"
        >
          <DebouncedInputSearch
            defaultValue={initialDocumentMetaDataValues['alternativeName']?.[0]}
          ></DebouncedInputSearch>
        </Form.Item>
        <Form.Item
          label={t('documents:documentMetaData.form.tags')}
          name="Prio365DriveItemTags"
        >
          <DocumentTagPicker
            value={initialDocumentMetaDataValues['Prio365DriveItemTags']}
            selectableDocumentTags={projectDocumentTags}
            className={classes.pickerWidth}
            projectId={activeProjectId}
          />
        </Form.Item>
        <Form.Item
          label={t('documents:documentMetaData.form.companies')}
          name="Prio365CompanyIds"
        >
          <CompanyPicker
            multiple
            value={initialDocumentMetaDataValues['Prio365CompanyIds']}
            className={classes.pickerWidth}
            label={t('documents:documentMetaData.companyPickerPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('documents:documentMetaData.form.contacts')}
          name="Prio365ContactIds"
        >
          <ContactPicker
            multiple
            onlyInternalProject
            value={initialDocumentMetaDataValues['contactIds']}
            className={classes.pickerWidth}
            label={t('documents:documentMetaData.contactPickerPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('documents:documentMetaData.form.description')}
          name="Prio365Description"
        >
          <DebouncedInputTextArea
            defaultValue={initialDocumentMetaDataValues['description']?.[0]}
            autoSize={{ minRows: 2, maxRows: 5 }}
          ></DebouncedInputTextArea>
        </Form.Item>
        {initialDocumentMetaDataValues['Prio365AttachedMessageId'][0] && (
          <Form.Item
            label={t('documents:documentMetaData.form.link')}
            name="Prio365AttachedMessageId"
            className={classes.linkOuterContainer}
          >
            <Flex.Row
              alignItems="center"
              childrenGap={theme.spacing.small}
              className={classes.linkInnerContainer}
            >
              <Button
                className={classes.formAction}
                type="link"
                onClick={onLinkToMessageClick}
              >
                {t('documents:documentMetaData.form.linkToMessage')}
              </Button>
            </Flex.Row>
          </Form.Item>
        )}
        {driveItemVersions.length > 0 && (
          <Form.Item
            label={[
              t('documents:documentMetaData.form.versions', {
                amount: driveItemVersions.length,
              }),
            ]}
          >
            <Flex.Column childrenGap={theme.spacing.small}>
              {showDriveItemVersions ? (
                driveItemVersions.map((item) => (
                  <DocumentVersionItem key={item.id} item={item} />
                ))
              ) : (
                <Flex.Row>
                  <Button
                    className={classes.formAction}
                    type="link"
                    onClick={() => setShowDriveItemVersions(true)}
                  >
                    {t('documents:documentMetaData.form.showVersions')}
                  </Button>
                </Flex.Row>
              )}
            </Flex.Column>
          </Form.Item>
        )}
        {/* TODO: wird vorerst nicht benötigt. Warten auf protocol items designs and models

        <Form.Item
          label={[
            t('documents:documentMetaData.form.protocol', {
              amount: initialDocumentMetaDataValues.protocolItems.length,
            }),
          ]}
        >
          <Flex.Column childrenGap={theme.spacing.small}>
            {initialDocumentMetaDataValues.protocolItems.map((item) => (
              <DocumentProtocolItem key={item.id} item={item} />
            ))}
          </Flex.Column>
        </Form.Item> */}
      </Form>
    );
  });

export default PreviewModalMetaDataForm;
