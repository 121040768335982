import { combineReducers } from 'redux';
import lastAccountingLocation, * as fromLastAccountingLocation from './lastAccountingLocation';

export interface AccountingReducerState {
  lastAccountingLocation: string;
}

export default combineReducers<AccountingReducerState>({
  lastAccountingLocation,
});

export const getLastAccountingLocation: (state: any) => string = (state: any) =>
  fromLastAccountingLocation.getLastAccountingLocation(state.lastAccountingLocation);
