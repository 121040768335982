import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Popover, Typography } from 'antd';
import {
  getCurrentPrioVersion,
  getZendeskDrawerOpen,
  getZendeskDrawerView,
} from '../../../apps/main/rootReducer';
import { useTranslation } from 'react-i18next';
import {
  openZendeskDrawer,
  updateZendeskDrawerView,
  updateZendeskSearchResult,
} from '../actions/drawer';
import { ZendeskWidgetTabs } from './ZendeskWidgetTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import ZendeskWidgetDetails from './ZendeskWidgetDetails';
import { ZendeskSearchResult } from '../../../models/Zendesk';
import { makePrioStyles } from '../../../theme/utils';
import DataRefreshForm from './DataRefreshForm';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import classNames from 'classnames';

interface GlobalZendeskDrawerProps {
  className?: string;
}
const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& .ant-drawer-body': {
      padding: 0,
    },
    zIndex: 999,
  },
  tabs: {
    height: '100%',
    overflow: 'hidden',
  },
  tabsContent: {
    height: '100%',
    overflow: 'hidden',
  },
  tabsTitle: {
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px 0`,
  },
  details: {
    padding: theme.old.spacing.unit(3),
  },
  icon: {
    color: `${theme.old.typography.colors.muted}!important`,
  },
}));

export const GlobalZendeskDrawer: React.FC<GlobalZendeskDrawerProps> = (
  props
) => {
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const open = useSelector(getZendeskDrawerOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeDrawer = () => dispatch(openZendeskDrawer(false));

  const view = useSelector(getZendeskDrawerView);
  const prioVersion = useSelector(getCurrentPrioVersion);

  const openDetails = (item: ZendeskSearchResult) => {
    dispatch(updateZendeskSearchResult(item));
    dispatch(updateZendeskDrawerView('details'));
  };

  const closeDetails = () => {
    dispatch(updateZendeskDrawerView('help'));
  };

  const renderContent = () => {
    switch (view) {
      case 'report':
      case 'help':
        return (
          <Flex.Column className={classes.tabs}>
            <Flex.Row
              alignItems="baseline"
              childrenGap={theme.old.spacing.baseSpacing}
              className={classes.tabsTitle}
            >
              <Typography.Title level={2}>
                {t('zendesk:drawer.title')}
              </Typography.Title>
              <Popover
                title={t('zendesk:appVersion', {
                  version: prioVersion,
                })}
                content={<DataRefreshForm />}
                trigger="click"
              >
                <FontAwesomeIcon
                  id={'prio-hidden-support-button'}
                  className={classes.icon}
                  icon={['fal', 'info-circle']}
                />
              </Popover>
            </Flex.Row>
            <Flex.Item className={classes.tabsContent}>
              <ZendeskWidgetTabs openDetails={openDetails} activeKey={view} />
            </Flex.Item>
          </Flex.Column>
        );

      case 'details':
        return (
          <ZendeskWidgetDetails
            closeDetails={closeDetails}
            className={classes.details}
          />
        );

      default:
        return <></>;
    }
  };

  let bodyStyle: React.CSSProperties | null = null;

  return (
    <Drawer
      className={classNames(classes.root, className)}
      placement="right"
      closable={true}
      onClose={closeDrawer}
      visible={open}
      width={theme.old.components.drawerWidth}
      bodyStyle={bodyStyle}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
      destroyOnClose
    >
      {renderContent()}
    </Drawer>
  );
};

export default GlobalZendeskDrawer;
