import React, { useState } from 'react';
import { Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../theme/utils';
import Flex from './Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    userSelect: 'none',
  },
  container: {
    width: '100%',
    '&:hover': {
      background: theme.old.palette.backgroundPalette.hover.content,
      '& > div': {
        visibility: 'visible',
      },
    },
  },
  content: {
    padding: theme.old.spacing.unit(1),
    flex: 1,
    overflow: 'hidden',
  },
  label: {
    fontSize: theme.old.typography.fontSize.label,
  },
  buttonNoTransition: {
    transition: 'none !important',
  },
  copyIconBox: {
    cursor: 'pointer',
    padding: theme.old.spacing.unit(2),
    background: theme.old.palette.backgroundPalette.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'hidden',
  },
  copyIcon: {},
  value: {},
}));

interface CopyableTextTileProps {
  className?: string;
  label?: string;
  value: string;
  type?: 'phone' | 'mail';
  data?: string;
  valueClassName?: string;
  contentClassName?: string;
  containerClassName?: string;
  copyIconBoxClassName?: string;
}

export const CopyableTextTile: React.FC<CopyableTextTileProps> = (props) => {
  const classes = useStyles();
  const {
    className,
    valueClassName,
    contentClassName,
    containerClassName,
    copyIconBoxClassName,
    label,
    value,
    type,
    data,
  } = props;
  const [clicked, setClicked] = useState(false);

  const copyCodeToClipboard = () => {
    copyToClipboard(value);
    setClicked(true);
    setTimeout(() => setClicked(false), 750);
  };

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Row
        className={classNames(classes.container, containerClassName)}
        justifyContent="stretch"
      >
        <Flex.Column className={classNames(classes.content, contentClassName)}>
          {label && (
            <Typography.Text type="secondary" className={classes.label}>
              {label}
            </Typography.Text>
          )}
          <Typography.Text
            className={classNames(classes.value, valueClassName)}
          >
            {value}
          </Typography.Text>
        </Flex.Column>
        <div
          className={classNames(classes.copyIconBox, copyIconBoxClassName)}
          onClick={copyCodeToClipboard}
        >
          <FontAwesomeIcon
            icon={['fal', clicked ? 'check' : 'copy']}
            className={classes.copyIcon}
          />
        </div>
        {type === 'phone' && (
          <div
            className={classNames(classes.copyIconBox, copyIconBoxClassName)}
          >
            <Button
              className={classes.buttonNoTransition}
              type="link"
              iconProp={['fal', 'phone']}
              href={`msteams:/l/call/0/0?users=4:${data.replace('+', '00')}`}
            ></Button>
          </div>
        )}
      </Flex.Row>
    </div>
  );
};

export const copyToClipboard = (str: string) => {
  const el: HTMLTextAreaElement = document.createElement('textarea'); // Create a <textarea> element
  let storeContentEditable = el.contentEditable;
  let storeReadOnly = el.readOnly;

  el.value = str; // Set its value to the string that you want copied
  el.contentEditable = 'true';
  el.readOnly = false;
  el.setAttribute('readonly', 'false'); // Make it readonly false for iOS compatability
  el.setAttribute('contenteditable', 'true'); // Make it editable for iOS
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  el.setSelectionRange(0, 999999);
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }

  el.contentEditable = storeContentEditable;
  el.readOnly = storeReadOnly;
};

export default CopyableTextTile;
