import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Typography, Tabs, Divider, Input, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { getInitialsColorHexFromName } from '../../../util/initialColors';
import { InternalCompany, ExternalCompany } from '../../../models/Company';
import CountryText from '../../../components/CountryText';
import { CopyableTextTile } from '../../../components/CopyableTextTile';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveCompany,
  unarchiveCompany,
  updateExternalCompany,
} from '../actions';
import {
  openContactsDrawer,
  setContactsDrawerState,
} from '../../contacts/actions/drawer';
import { ContactListItem } from '../../contacts/components/ContactList';
import { Contact } from '../../../models/Contact';
import CompaniesContactList from './CompaniesContactList';
import useAccessRights from '../../users/hooks/useAccessRights';
import { apiFetchCompanyContacts } from '../../contacts/api';
import { getContactsDrawerState } from '../../../apps/main/rootReducer';
import { CompanyId, ContactId } from '../../../models/Types';
import { isTemporaryId } from '../../../util';
import { apiCreateMessageDraft, apiUpdateMessageDraft } from '../../mail/api';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import ContactTags from '../../contacts/components/ContactTags';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
  },
  content: {
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  firstActionButton: {
    paddingLeft: 0,
  },
  contactLeftBox: {
    borderRight: theme.old.borders.content,
  },
  label: {
    fontSize: theme.old.typography.fontSize.label,
  },
  title: {
    marginBottom: '0.35em!important',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  notesInput: {
    marginTop: theme.old.spacing.unit(1),
  },
  hiddenOverflow: {
    overflow: 'hidden',
  },
  tabs: {
    '&.ant-tabs': {
      height: '100%',
    },
    '& .ant-tabs-content': {
      height: '100%',
      overflow: 'auto',
    },
  },
}));

interface CompanyDetailsProps {
  className?: string;
  contentClassName?: string;
  company: InternalCompany & ExternalCompany;
  noAvatar?: boolean;
  isDrawer?: boolean;
  onNewContact?: (companyId?: CompanyId) => void;
  onItemClick?: (contactId: ContactId) => void;
}

export const CompanyDetails: React.FC<CompanyDetailsProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    contentClassName,
    company,
    noAvatar,
    isDrawer,
    onNewContact,
    onItemClick,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [showNotesInput, setShowNotesInput] = useState<boolean>(false);
  const [autoFocusNotes, setAutoFocusNotes] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>(null);

  const [contacts, setContacts] = useState<Contact[]>([]);
  const notArchivedContacts = contacts.filter(
    (contact) => !contact?.isArchived
  );

  const items: ContactListItem[] = notArchivedContacts?.map(
    (contact: Contact) => {
      const item: ContactListItem = {
        type: 'contact',
        value: contact,
      };
      return item;
    }
  );

  const computedInitials = company.fullName
    .split(' ')
    .slice(0, 2)
    .map((word) => word.substring(0, 1))
    .join('');

  const avatarColor = getInitialsColorHexFromName(company.fullName);

  const companyRoles = [
    ...(company.isClient ? [t('companies:companyDetails.roles.client')] : []),
    ...(company.isServiceProvider
      ? [t('companies:companyDetails.roles.serviceProvider')]
      : []),
  ];

  const { editInternalCompany: canEditInternalCompany } = useAccessRights(
    ['editInternalCompany'],
    {
      companyId: company.companyId,
    },
    true
  );

  const { editExternalCompany: canEditExternalCompany } = useAccessRights(
    ['editExternalCompany'],
    {
      companyId: company.companyId,
    },
    true
  );

  const { changedContact } = useSelector(getContactsDrawerState);

  const [activeKey, setActiveKey] = useState<string>('contact');

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleAddNotesClick = () => {
    setAutoFocusNotes(true);
    setShowNotesInput(true);
  };

  const handleOpenEMailMeFromContacts = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    doShowNewEMailMeMessageFromContact();
  };

  const isEditCompanyEnabled = () => {
    if (company.companyType === 'InternalCompany') {
      return canEditInternalCompany;
    }
    return canEditExternalCompany;
  };

  const onNotesBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const updatedNotes = e.target.value.trim();
    if ((company.notes ?? '') !== updatedNotes) {
      if (company.companyType === 'ExternalCompany') {
        dispatch(
          updateExternalCompany(
            { notes: updatedNotes },
            company.companyId,
            company.rowVersion,
            company
          )
        );
      }
    }
    if (updatedNotes === '' && showNotesInput) {
      setShowNotesInput(false);
      setAutoFocusNotes(false);
    }
  };

  const editCompany = () => {
    dispatch(
      openContactsDrawer({
        selectedCompany: company.companyId,
        view: 'companyEdit',
      })
    );
  };

  const onArchiveCompany = () => {
    dispatch(archiveCompany(company.companyId));
  };

  const onUnarchiveCompany = () => {
    dispatch(unarchiveCompany(company.companyId));
  };

  const showNewContactDrawer = () => {
    if (isDrawer && onNewContact) {
      onNewContact(company.companyId);
    } else {
      dispatch(
        openContactsDrawer({
          view: 'contactNew',
          selectedCompany: company.companyId,
        })
      );
    }
  };

  const doShowNewEMailMeMessageFromContact = async () => {
    setIsCreating(true);
    const { data: message } = await apiCreateMessageDraft('me');
    if (message) {
      message.toRecipients = [
        {
          emailAddress: {
            name: '',
            address: company.offices && company.offices[0].eMail,
          },
        },
      ];
      await apiUpdateMessageDraft('me', message.id, message);
      const width = window.screen.availWidth / 2;
      const height = window.screen.availHeight * 0.6;
      window.open(
        `/view/me/composer/${message.id}`,
        '_blank',
        `width=${width},height=${height},top=${height / 4}`
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }

    setIsCreating(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (company?.companyId && !isTemporaryId(company.companyId)) {
      const loadContacts = async () => {
        const { result, data } = await apiFetchCompanyContacts(
          company.companyId
        );
        if (result.status >= 200 && result.status < 300) {
          setContacts(data?.filter((contact) => !contact.isArchived));
        }
      };
      loadContacts();
    }
  }, [company?.companyId]);

  useEffect(() => {
    setNotes(company.notes);
    setShowNotesInput(!!company.notes);
  }, [company]);

  useEffect(() => {
    if (changedContact) {
      switch (changedContact.state) {
        case 'created': {
          setContacts(
            [...contacts, changedContact.contact].sort(
              (a: Contact, b: Contact) => {
                const lastNameCompare = a.lastName?.localeCompare(b.lastName);
                if (lastNameCompare !== 0) return lastNameCompare;
                return a.firstName?.localeCompare(b.firstName);
              }
            )
          );
          break;
        }
        case 'edited': {
          const indx = contacts.findIndex(
            (contact) => contact.contactId === changedContact.contact.contactId
          );
          if (indx > -1) {
            const contactsCopy = contacts;
            contactsCopy.splice(indx, 1, {
              ...changedContact.contact,
            });
            setContacts(contactsCopy);
          }
          break;
        }
        case 'unarchived':
        case 'archived': {
          const indx = contacts.findIndex(
            (contact) => contact.contactId === changedContact.contact.contactId
          );
          if (indx > -1) {
            const contactsCopy = contacts;
            const contactCopy = contacts[indx];
            contactsCopy.splice(indx, 1, {
              ...contactCopy,
              isArchived: !contactCopy.isArchived,
            });
            setContacts(contactsCopy);
          }
          break;
        }
      }
      dispatch(setContactsDrawerState({ changedContact: null }));
    }
  }, [contacts, changedContact, dispatch]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classNames(classes.content, contentClassName)}
        childrenGap={theme.old.spacing.unit(3)}
        padding={`${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(
          3
        )}px`}
      >
        <Flex.Row>
          {!noAvatar && (
            <Avatar
              size={96}
              style={{
                backgroundColor: avatarColor,
                fontSize: 32,
                fontWeight: theme.old.typography.fontWeight.bold,
                minWidth: 96,
              }}
            >
              {computedInitials}
            </Avatar>
          )}
          <Flex.Column
            padding={
              noAvatar
                ? `0 ${theme.old.spacing.unit(2)}px 0 0`
                : `0 ${theme.old.spacing.unit(2)}px`
            }
            flex={1}
            justify-content="center"
          >
            <Typography.Title
              level={2}
              className={classes.title}
              style={{ paddingLeft: '8px' }}
            >
              {company.fullName}
              {company.companyClassificationName
                ? ` | ${company.companyClassificationName}`
                : null}
            </Typography.Title>
            <Typography.Text className={classes.mutedColor}>
              {companyRoles.join(', ')}
            </Typography.Text>
            <Flex.Row childrenGap={theme.old.spacing.unit(1)}>
              <ContactTags
                maxCount={5}
                contactTagDtos={company.contactTagDtos}
                companyId={company.companyId}
              ></ContactTags>
            </Flex.Row>
            <Flex.Row>
              <Button
                disabled={company.offices[0]?.eMail === null || isCreating}
                type="link"
                iconProp={['fal', 'envelope']}
                onClick={handleOpenEMailMeFromContacts}
                loading={isCreating}
              >
                {t('companies:companyDetails.actions.email')}
              </Button>
              {/*
              <Button
                type="link"
                icon={<FontAwesomeIcon icon={['fal', 'phone']} />}
                href={
                  company.offices && company.offices[0]?.phone
                    ? `tel:${company.offices[0]?.phone}`
                    : undefined
                }
              >
                {t('companies:companyDetails.actions.call')}
              </Button>
              <Button
                type="link"
                icon={<FontAwesomeIcon icon={['fal', 'file-excel']} />}
              >
                {t('companies:companyDetails.actions.excel')}
              </Button>
              */}
            </Flex.Row>
          </Flex.Column>
        </Flex.Row>
        <Flex.Column flex={1} className={classes.hiddenOverflow}>
          <Flex.Item className={classes.hiddenOverflow}>
            <Tabs
              onChange={setActiveKey}
              activeKey={activeKey}
              className={classes.tabs}
            >
              <Tabs.TabPane
                tab={t('companies:companyDetails.pivot.contact')}
                key="contact"
              >
                {company.offices?.map((office) => (
                  <Flex.Row flex={1} key={office.officeId}>
                    <Flex.Column
                      flex={1}
                      childrenGap={theme.old.spacing.unit(1)}
                      className={classes.contactLeftBox}
                    >
                      {(office.street || office.zip) && (
                        <Flex.Column>
                          <Typography.Text
                            type="secondary"
                            className={classes.label}
                          >
                            <Trans i18nKey="companies:companyDetails.labels.address">
                              Adresse
                            </Trans>
                          </Typography.Text>
                          <Typography.Text>
                            {office.street && (
                              <>
                                {office.street}
                                <br />
                              </>
                            )}
                            {(office.zip || office.city) && (
                              <>
                                {office.zip} {office.city}
                                <br />
                              </>
                            )}
                            <CountryText
                              countryCode={office.countryCode}
                              federalStateCode={office.federalStateCode}
                            />
                          </Typography.Text>
                        </Flex.Column>
                      )}
                    </Flex.Column>
                    <Flex.Column
                      flex={1}
                      padding={`0 0 0 ${theme.old.spacing.defaultPadding}px`}
                      childrenGap={theme.old.spacing.unit(2)}
                    >
                      {office.eMail && (
                        <CopyableTextTile
                          label={t('companies:companyDetails.labels.email')}
                          value={office.eMail}
                        />
                      )}
                      {office.phone && (
                        <CopyableTextTile
                          label={t('companies:companyDetails.labels.phone')}
                          value={office.phone}
                        />
                      )}
                    </Flex.Column>
                  </Flex.Row>
                ))}
                {company.website && (
                  <Flex.Column>
                    <Divider />
                    <Typography.Text type="secondary" className={classes.label}>
                      <Trans i18nKey="companies:companyDetails.labels.website">
                        Webseite
                      </Trans>
                    </Typography.Text>
                    <a
                      href={`${
                        company.website.startsWith('http') ? '' : 'http://'
                      }${company.website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography.Text>{company.website}</Typography.Text>
                    </a>
                  </Flex.Column>
                )}
                <Divider />
                <Flex.Column alignItems="start">
                  <Typography.Text type="secondary" className={classes.label}>
                    <Trans i18nKey="companies:companyDetails.labels.notes">
                      Notizen
                    </Trans>
                  </Typography.Text>
                  {showNotesInput ? (
                    <Input.TextArea
                      value={notes}
                      onBlur={onNotesBlur}
                      onChange={(e) => setNotes(e.target.value)}
                      autoFocus={autoFocusNotes}
                      className={classes.notesInput}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  ) : (
                    <Button
                      type="link"
                      onClick={handleAddNotesClick}
                      iconProp={['fal', 'pen']}
                    >
                      <span>
                        {t('companies:companyDetails.actions.addNotesButton')}
                      </span>
                    </Button>
                  )}
                </Flex.Column>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('companies:companyDetails.contactList.contacts', {
                  count: items?.length ?? 0,
                })}
                key="contacts"
              >
                <CompaniesContactList
                  items={items}
                  isDrawer={isDrawer}
                  onItemClick={onItemClick}
                />
              </Tabs.TabPane>
            </Tabs>
          </Flex.Item>
        </Flex.Column>
        <Flex.Row>
          <Flex.Item flex={1}>
            {activeKey === 'contacts' && (
              <Button
                type="link"
                onClick={showNewContactDrawer}
                iconProp={['fal', 'plus']}
              >
                {t('companies:companyDetails.contactList.addNewKontakt')}
              </Button>
            )}
          </Flex.Item>
          <Flex.Item>
            <Button
              onClick={
                company.isArchived ? onUnarchiveCompany : onArchiveCompany
              }
              type="link"
              iconProp={['fal', company.isArchived ? 'trash-restore' : 'trash']}
              style={{
                display:
                  company.companyType === 'InternalCompany'
                    ? 'none'
                    : 'inline-block',
                marginRight: theme.old.spacing.unit(1),
              }}
            >
              <span>
                {company.isArchived
                  ? t('companies:companyDetails.actions.unarchive')
                  : t('companies:companyDetails.actions.archive')}
              </span>
            </Button>
            {company.companyType === 'ExternalCompany' && (
              <Button
                type="link"
                iconProp={['fal', 'user-edit']}
                onClick={editCompany}
                disabled={!isEditCompanyEnabled()}
              >
                {t('common:edit')}
              </Button>
            )}
          </Flex.Item>
        </Flex.Row>
      </Flex.Column>
    </div>
  );
};

export default CompanyDetails;
