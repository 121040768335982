import { useDispatch, useSelector } from 'react-redux';
import { getDoodleState } from '../../../apps/main/rootReducer';
import { Doodle, DoodleType } from '../../../models/Doodle';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import doodleJson from '../doodle.json';
import { enableDoodle } from '../actions';

const useDoodle: () => DoodleType = () => {
  const dispatch = useDispatch();
  const [currentDoodle, setCurrentDoodle] = useState<DoodleType>('none');
  const doodleState = useSelector(getDoodleState);

  useEffect(() => {
    const typesFromJson = Object.keys(doodleJson) as DoodleType[];
    typesFromJson.forEach((typeFromJson) => {
      if (
        !moment().isBetween(
          (doodleJson[typeFromJson] as Doodle).start,
          (doodleJson[typeFromJson] as Doodle).end
        )
      ) {
        dispatch(
          enableDoodle(typeFromJson, doodleJson[typeFromJson] as Doodle)
        );
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const typesFromJson = Object.keys(doodleJson) as DoodleType[];
    const result = typesFromJson.find(
      (typeFromJson) =>
        (!doodleState[typeFromJson] || !doodleState[typeFromJson]?.disabled) &&
        moment().isBetween(
          (doodleJson[typeFromJson] as Doodle).start,
          (doodleJson[typeFromJson] as Doodle).end
        )
    );
    if (result) {
      setCurrentDoodle(result);
    } else {
      setCurrentDoodle('none');
    }
  }, [doodleState, dispatch]);

  return currentDoodle;
};

export default useDoodle;
