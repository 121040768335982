import { notification } from 'antd';
import { put, select } from 'redux-saga/effects';
import { getProject, RootReducerState } from '../../../apps/main/rootReducer';
import i18n from '../../../i18n';
import { Project } from '../../../models/Project';
import { WebSocketMessage } from '../../../models/WebSocket';
import { fetchUserMe } from '../../users/actions';
import { fetchProjects, wsUpdateProjectExtensionMe } from '../actions';
import {
  ContactId,
  ProjectExtensionId,
  ProjectId,
} from '../../../models/Types';
import { ExtensionUserState } from '../../../models/ProjectExtension';

export declare type InternalProjectContactMessageTypes =
  | 'created'
  | 'updated'
  | 'deleted';

export interface ProjectExtensionAccessStateUpdatedEvent {
  projectId: ProjectId;
  projectExtensionId: ProjectExtensionId;
  extensionAccessState: ExtensionUserState;
  contactId: ContactId;
}

export function* handleInternalProjectContactMessage(
  message: WebSocketMessage
) {
  const project: Project = yield select<(state: RootReducerState) => Project>(
    (state) => getProject(state, message.projectId)
  );

  switch (message.type) {
    case 'created':
      yield put(fetchProjects(true));
      yield put(fetchUserMe());
      if (project !== undefined) {
        const key = `open${Date.now()}`;
        notification.open({
          message: i18n.t('projects:internalProjectContact:success'),
          description: i18n.t('projects:internalProjectContact.added', {
            name: project?.name,
            number: project?.number,
          }),
          key,
          duration: 5,
        });
      }
      break;
    case 'updated':
      yield put(fetchProjects(true));
      yield put(fetchUserMe());
      break;
    case 'deleted':
      yield put(fetchProjects(true));
      yield put(fetchUserMe());
      break;
  }
}

export function* handleProjectExtensionAccessUpdateMessage(
  message: WebSocketMessage
) {
  if (message.type as any) {
    yield put(
      wsUpdateProjectExtensionMe(
        message.type as any as ProjectExtensionAccessStateUpdatedEvent
      )
    );
  }
}
