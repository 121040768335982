import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  UpdateCurrentFolderMetaStateAction,
  UPDATE_CURRENT_FOLDER_META_STATE,
} from '../actions/meta';

export interface CurrentFolderMetaState {
  createNewDocumentOpen?: boolean;
  areMultipleSelected?: boolean;
  copyDocumentsDrawerState?: 'none' | 'copy' | 'move';
  showDeleteDocumentsModal?: boolean;
  showCreateRemoteFolderModal?: boolean;
}

const initialState: CurrentFolderMetaState = {
  createNewDocumentOpen: false,
  areMultipleSelected: false,
  copyDocumentsDrawerState: 'none',
  showDeleteDocumentsModal: false,
};

const meta: Reducer<
  CurrentFolderMetaState,
  UpdateCurrentFolderMetaStateAction
> = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_FOLDER_META_STATE: {
      const { type, ...changed } = action;
      return {
        ...state,
        ...changed,
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default meta;

export const getCreateNewDocumentOpen = (state: CurrentFolderMetaState) =>
  state.createNewDocumentOpen;
export const getAreMultipleSelected = (state: CurrentFolderMetaState) =>
  state.areMultipleSelected;
export const getCopyDocumentsDrawerState = (state: CurrentFolderMetaState) =>
  state.copyDocumentsDrawerState;
export const getMeta = (state: CurrentFolderMetaState) => state;
