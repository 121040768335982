import React from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { useParams, Navigate } from 'react-router-dom';
import { ControllingMenu } from './ControllingMenu';
import { useSelector } from 'react-redux';
import {
  getGlobalSiderOpen,
  getOfficeMeAllOffices,
  getUserMe,
} from '../../../apps/main/rootReducer';
import useAccessRights from '../../users/hooks/useAccessRights';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  menu: {},
  mainContent: {
    overflow: 'hidden',
  },
  menuContent: {
    overflow: 'hidden',
    height: '100%',
  },
}));

interface ControllingModuleNavigationProps {
  className?: string;
  isSubMenu?: boolean;
}

export const ControllingModuleNavigation: React.FC<
  ControllingModuleNavigationProps
> = (props) => {
  const { className, isSubMenu } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { selectedList, officeId } = useParams();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const me = useSelector(getUserMe);
  const myOffices = useSelector(getOfficeMeAllOffices);
  const openSider = useSelector(getGlobalSiderOpen);
  const { showGlobalInControllingModule } = useAccessRights([
    'showGlobalInControllingModule',
  ]);

  const firstOfficeIdWhereController = myOffices.find(
    (office) =>
      me.prioData.officeRoles[office.officeId]?.includes('officeController')
  )?.officeId;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (!selectedList) {
    if (showGlobalInControllingModule) {
      return <Navigate to="invoices" />;
    } else if (firstOfficeIdWhereController) {
      return (
        <Navigate to={`office/${firstOfficeIdWhereController}/invoices`} />
      );
    }
  }

  return (
    <Flex.Column flexGrow={1} className={classNames(classes.root, className)}>
      <Flex.Column
        childrenGap={theme.old.spacing.unit(1)}
        className={classes.mainContent}
      >
        <Flex.Item className={classes.menuContent} flex={1}>
          <ControllingMenu
            selectedList={selectedList}
            urlPrefix="../"
            officeId={officeId}
            open={openSider}
            isSubMenu={isSubMenu}
          />
        </Flex.Item>
      </Flex.Column>
    </Flex.Column>
  );
};

export default ControllingModuleNavigation;
