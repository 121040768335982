import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useDispatch } from 'react-redux';

import { makePrioStyles } from '../../../theme/utils';
import ContactSearch from './ContactSearch';
import useDebounce from '../../../hooks/useDebounce';
import { ContactId, CompanyId } from '../../../models/Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openContactsDrawer } from '../actions/drawer';
import {} from '../../companies/actions/';
import classNames from 'classnames';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  newContactActionButtonIcon: {
    fontSize: theme.old.typography.fontSize.base,
  },
  tabBar: {
    padding: theme.old.spacing.unit(3),
  },
  tabPane: {
    height: '100%',
  },
  contactSearch: {
    height: '100%',
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: theme.old.borders.content,
        '& .ant-input-search-button': {
          height: '100%',
          background: 'transparent',
          padding: 0,
          width: 32,
          color: 'rgba(0, 0, 0, 0.6)',
          '&:hover': {
            background: 'transparent',
          },
        },
        '&:hover': {
          borderColor: 'var(--ant-primary-5)',
        },
      },
  },
}));

interface ContactsTabsProps {
  className?: string;
  tabBarClassName?: string;
  activeKey?: 'all' | 'contacts' | 'companies';
  searchInput: string;
  setSearchInput: (value: string) => void;
  onChange?: (activeKey: 'all' | 'contacts' | 'companies') => void;
  onContactClick?: (contactId: ContactId) => void;
  onCompanyClick?: (companyId: CompanyId) => void;
}

export const ContactsTabs: React.FC<ContactsTabsProps> = (props) => {
  //#region -------------------------------- Variables
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    className,
    tabBarClassName,
    activeKey,
    searchInput,
    setSearchInput,
    onChange,
    onContactClick,
    onCompanyClick,
  } = props;
  const debouncedSearchInput = useDebounce(searchInput, 500);
  const dispatch = useDispatch();
  //#endregion

  //#region -------------------------------- State declaration
  const [isOnline, setIsOnline] = useState<boolean>(false);
  //#endregion

  //#region -------------------------------- Methods
  const showDrawerOfCurrentTab = () => {
    if (activeKey === 'all') {
      dispatch(openContactsDrawer({ view: 'contactNew' }));
    }
    if (activeKey === 'contacts') {
      dispatch(openContactsDrawer({ view: 'contactNew' }));
    }
    if (activeKey === 'companies') {
      dispatch(openContactsDrawer({ view: 'companyNew' }));
    }
  };

  //#endregion

  //#region -------------------------------- Hooks

  //#endregion

  //#region -------------------------------- Components
  const newContactActionButton = (
    <Button
      type="primary"
      onClick={showDrawerOfCurrentTab}
      iconProp={['fal', 'plus']}
    ></Button>
  );

  //#endregion

  return (
    <Tabs
      className={classNames(classes.root, className)}
      animated={false}
      defaultActiveKey="all"
      activeKey={activeKey}
      onChange={onChange}
      tabBarExtraContent={newContactActionButton}
      renderTabBar={(props, DefaultTabBar) => (
        <div className={classNames(classes.tabBar, tabBarClassName)}>
          <DefaultTabBar {...props} />
          <SearchInput
            searchInput={searchInput}
            isOnline={isOnline}
            setSearchInput={setSearchInput}
          />
        </div>
      )}
      destroyInactiveTabPane
    >
      <TabPane
        tab={t('contacts:taskPane.tabs.all')}
        key="all"
        className={classes.tabPane}
      >
        <TabContent
          type="all"
          searchTerm={debouncedSearchInput}
          onContactClick={onContactClick}
          onCompanyClick={onCompanyClick}
          setIsOnline={setIsOnline}
          isOnline={isOnline}
        />
      </TabPane>
      <TabPane
        tab={t('contacts:taskPane.tabs.contacts')}
        key="contacts"
        className={classes.tabPane}
      >
        <TabContent
          type="allContacts"
          searchTerm={debouncedSearchInput}
          onContactClick={onContactClick}
          onCompanyClick={onCompanyClick}
          setIsOnline={setIsOnline}
          isOnline={isOnline}
        />
      </TabPane>
      <TabPane
        tab={t('contacts:taskPane.tabs.companies')}
        key="companies"
        className={classes.tabPane}
      >
        <TabContent
          type="allCompanies"
          searchTerm={debouncedSearchInput}
          onContactClick={onContactClick}
          onCompanyClick={onCompanyClick}
          setIsOnline={setIsOnline}
          isOnline={isOnline}
        />
      </TabPane>
    </Tabs>
  );
};

export default ContactsTabs;

interface SearchInputProps {
  searchInput: string;
  isOnline: boolean;
  setSearchInput: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  //#region ------------------------------ Defaults
  const { searchInput, isOnline, setSearchInput } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [currentInput, setCurrentInput] = useState<string>('');
  const [timer, setTimer] = useState<any>(null);
  const [debouncedCurrentInput, setDebouncedCurrentInput] =
    useState<string>('');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setCurrentInput(text);
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        setSearchInput(text);
        setDebouncedCurrentInput(text);
      }, 250)
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (searchInput !== debouncedCurrentInput) {
      setCurrentInput(searchInput);
      setDebouncedCurrentInput(searchInput);
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [searchInput, debouncedCurrentInput, timer]);
  //#endregion

  return (
    <Input.Search
      allowClear
      size="middle"
      placeholder={t('contacts:taskPane.search.placeHolder')}
      onChange={handleChange}
      value={currentInput}
      className={classes.search}
      enterButton={isOnline && <FontAwesomeIcon icon={['fal', 'globe']} />}
    />
  );
};

interface TabContentProps {
  type: 'all' | 'allContacts' | 'allCompanies';
  searchTerm: string;
  onContactClick?: (contactId: ContactId) => void;
  onCompanyClick?: (companyId: CompanyId) => void;
  searchTypeChanged?: (isOnline: boolean) => void;
  setIsOnline?: (value: boolean) => void;
  isOnline?: boolean;
}

const TabContent: React.FC<TabContentProps> = (props) => {
  const {
    type,
    searchTerm,
    onContactClick,
    onCompanyClick,
    setIsOnline,
    isOnline,
  } = props;
  const classes = useStyles();

  const list = useMemo(
    () => (
      <ContactSearch
        type={type}
        searchTerm={searchTerm}
        onContactClick={onContactClick}
        onCompanyClick={onCompanyClick}
        className={classes.contactSearch}
        searchTypeChanged={setIsOnline}
        isOnlineFromOuter={isOnline}
      />
    ),
    [
      type,
      searchTerm,
      onContactClick,
      onCompanyClick,
      classes,
      setIsOnline,
      isOnline,
    ]
  );
  return list;
};
