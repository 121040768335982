import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import ZendeskWidgetReport from './ZendeskWidgetReport';
import ZendeskWidgetHelpForm from './ZendeskWidgetHelp';
import { ZendeskSearchResult } from '../../../models/Zendesk';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch } from 'react-redux';
import { updateZendeskDrawerView, ZendeskDrawerView } from '../actions/drawer';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '&.ant-tabs': {
      overflow: 'visible',
    },
    '& .ant-tabs-nav': {
      margin: `0 ${theme.old.spacing.unit(3)}px`,
    },
    '& .ant-tabs-content-holder': {},
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-tabs-tabpane': {
      height: '100%',
      overflow: 'hidden',
    },
  },
  prioNewsButton: {
    marginBottom: '8px',
  },
}));

interface ZendeskWidgetTabsProps {
  activeKey?: 'help' | 'report';
  onClick?: VoidFunction;
  openDetails?: (item: ZendeskSearchResult) => void;
}

export const ZendeskWidgetTabs: React.FC<ZendeskWidgetTabsProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { activeKey } = props;
  const dispatch = useDispatch();

  return (
    <Tabs
      animated={false}
      defaultActiveKey="help"
      activeKey={activeKey ?? 'help'}
      className={classes.root}
      onTabClick={(key) =>
        dispatch(updateZendeskDrawerView(key as ZendeskDrawerView))
      }
    >
      <TabPane tab={t('zendesk:drawer.tabs.functions')} key="help">
        <ZendeskWidgetHelpForm />
      </TabPane>
      <TabPane tab={t('zendesk:drawer.tabs.report')} key="report">
        <ZendeskWidgetReport />
      </TabPane>
    </Tabs>
  );
};

export default ZendeskWidgetTabs;
