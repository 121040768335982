import { PRIO } from '../constants';

export const RESET_META = PRIO + 'RESET_META';

export const resetMeta = () => ({
  type: RESET_META,
});

export const CLEAR_PRIO_CACHE = PRIO + 'CLEAR_PRIO_CACHE';
export const clearPrioCache = () => ({
  type: CLEAR_PRIO_CACHE,
});

export const SET_PRIO_VERSION = PRIO + 'SET_PRIO_VERSION';

export const setPrioVersion = (prioVersion: string) => ({
  type: SET_PRIO_VERSION,
  prioVersion,
});

export const CLEAR_PRIO_CONTACTS = PRIO + 'CLEAR_PRIO_CONTACTS';
export const clearPrioContacts = () => ({
  type: CLEAR_PRIO_CONTACTS,
});

export const RESET_PRIO_CACHE = PRIO + 'RESET_PRIO_CACHE';
export const resetPrioCache = () => ({
  type: RESET_PRIO_CACHE,
});
