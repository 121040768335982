import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  FETCH_AI_SETTING_COMMIT,
  FETCH_AI_SETTING_REQUEST,
  FETCH_AI_SETTING_ROLLBACK,
} from '../actions/aiSetting';
import { Setting } from '../../../models/Settings';

interface AiSettingAction {
  type: string;
  payload: string;
}

export interface AiSettingState {
  settings: Setting[];
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const initialStates: AiSettingState = {
  settings: [],
  isFetching: false,
  hasError: false,
};

const aiSetting: Reducer<AiSettingState, AiSettingAction> = (
  state = initialStates,
  action
) => {
  switch (action.type) {
    case FETCH_AI_SETTING_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_AI_SETTING_COMMIT: {
      return {
        ...state,
        settings: action.payload as unknown as Setting[],
        isFetching: false,
        hasError: false,
      };
    }
    case FETCH_AI_SETTING_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'settings:errorMessages.fetchAiSettingError',
      };
    }

    case CLEAR_PRIO_CACHE: {
      return initialStates;
    }
    default:
      return state;
  }
};
export default aiSetting;

export const getIsKeyEnabled = (
  state: AiSettingState,
  key: string
): boolean => {
  const setting = state.settings?.find((s) => s.key === key);
  if (!setting) {
    return false;
  }
  const parsedValue = JSON.parse(setting?.jsonValue || '{}');
  return parsedValue?.IsEnabled;
};

export const getIsFetchingAiSetting: (state: AiSettingState) => boolean = (
  state
) => state?.isFetching;

export const getHasErrorAiSetting: (state: AiSettingState) => boolean = (
  state
) => state?.hasError;

export const getErrorMessageAiSetting: (state: AiSettingState) => string = (
  state
) => state?.errorMessage;
