import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavItem } from './GlobalAndOfficeNavigation';
import useAccessRights, {
  AccessItem,
} from '../modules/users/hooks/useAccessRights';
import classNames from 'classnames';
import { makePrioStyles } from '../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    background: 'none',
    border: 'none',
    padding: theme.old.spacing.unit(1.5),
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  menuItem: {
    '& .ant-menu-item': {
      paddingRight: 34,
    },
  },
  navItemText: {
    '&.ant-typography': {
      color: 'unset',
    },
  },
  menuCollapsed: {
    transition: 'padding 0.2s',
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: `0 ${theme.old.spacing.unit(1.875)}px`,
      borderRadius: 2,
    },
  },
}));

interface GlobalAndOfficeMenuProps {
  selectedKey: string;
  urlPrefix?: string;
  customUrl?: string;
  globalMenuItems: NavItem[];
  officeMenuItems: NavItem[];
  officeAccessMode: AccessItem;
  globalAccessMode: AccessItem;
  officeId?: string;
  openSider: boolean;
}

export const GlobalAndOfficeMenu: React.FC<GlobalAndOfficeMenuProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    selectedKey,
    urlPrefix,
    customUrl,
    officeAccessMode,
    globalAccessMode,
    officeId,
    globalMenuItems,
    officeMenuItems,
    openSider,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { [officeAccessMode]: officeAccess, [globalAccessMode]: globalAccess } =
    useAccessRights([officeAccessMode, globalAccessMode]);

  const navItems = useMemo(() => {
    if (officeId) {
      if (officeAccess) {
        return officeMenuItems;
      }
      return null;
    } else {
      if (globalAccess) {
        return globalMenuItems;
      }
      return null;
    }
  }, [officeId, officeAccess, globalAccess, officeMenuItems, globalMenuItems]);
  //#endregion

  return (
    <Menu
      mode="inline"
      className={classNames(classes.root, {
        [classes.menuCollapsed]: !openSider,
      })}
      selectedKeys={[selectedKey]}
      inlineIndent={theme.old.components.menuInlineIndent}
    >
      {navItems?.map((item) => (
        <Menu.Item
          key={item.itemKey}
          className={classes.menuItem}
          title={
            <>
              {item.icon && (
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', item.icon]}
                  className={classes.icon}
                />
              )}
              <Typography.Text className={classes.navItemText}>
                {t(item.tString)}
              </Typography.Text>
            </>
          }
        >
          <Link
            to={
              customUrl ??
              item.customUrl ??
              `${item.urlPrefix ?? urlPrefix ?? ''}${
                officeId ? `office/${officeId}/${item.itemKey}` : item.itemKey
              }${item.urlSuffix ? `/${item.urlSuffix}` : ''}`
            }
          >
            {item.icon && (
              <FontAwesomeIcon
                fixedWidth
                icon={['fal', item.icon]}
                className={classes.icon}
              />
            )}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default GlobalAndOfficeMenu;
