import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';

import {
  getPrioUsers,
  getUsersIsFetching,
} from '../../../apps/main/rootReducer';
import { fetchUsers } from '../actions/users';
import { ContactId } from '../../../models/Types';
import { User } from '../../../models/User';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface UserPickerProps {
  value?: string | string[];
  label?: string;
  onChange?: (value: string | string[]) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
  fetch?: boolean;
  loading?: boolean;
}

export const UserPicker: React.FC<UserPickerProps> = React.memo((props) => {
  const classes = useStyles();
  const {
    className,
    value,
    label,
    onChange,
    disabled,
    multiple,
    fetch,
    loading,
  } = props;
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (fetch) dispatch(fetchUsers());
  }, [dispatch, fetch]);

  const users: User[] = useSelector(getPrioUsers);

  const isFetching = useSelector(getUsersIsFetching);

  const handleOnChange: (value) => void = onChange
    ? (value) => {
        onChange(value ?? '');
      }
    : null;

  return (
    <Select<ContactId | ContactId[]>
      className={classNames(classes.root, className)}
      mode={multiple ? 'multiple' : null}
      showSearch
      value={value ?? (multiple ? [] : value)}
      placeholder={label}
      optionFilterProp="label"
      onChange={handleOnChange}
      filterOption={(input, option) => {
        return (
          option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >=
          0
        );
      }}
      disabled={disabled}
      loading={loading || (isFetching && users.length === 0)}
    >
      {users.map((user: User) => (
        <Select.Option
          value={user.id}
          key={user.id}
          label={`${user.givenName} ${user.surname}`}
        >
          {`${user.givenName} ${user.surname}`}
        </Select.Option>
      ))}
    </Select>
  );
});

export default UserPicker;
