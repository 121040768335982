import { PRIO } from '../../../constants';

export const LOGGED_IN = PRIO + 'LOGGED_IN';
export const LOGGED_OUT = PRIO + 'LOGGED_OUT';
export const SET_IS_INITIAL_DATA_LOADING = PRIO + 'SET_IS_INITIAL_DATA_LOADING';

export const loggedIn = () => {
  return { type: LOGGED_IN };
};

export const loggedOut = () => {
  return { type: LOGGED_OUT };
};

export const setIsInitialDataLoading = (isInitialDataLoading: boolean) => {
  return { type: SET_IS_INITIAL_DATA_LOADING, isInitialDataLoading };
};
