import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import useMailSearch from '../hooks/useMailSearch';
import {
  getMailSettings,
  getMessagesIsFetching,
  getProjectsSpecialMailFoldersState,
  getSpecialMailFolders,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Message } from '../../../models/Message';
import { MailListSkeleton } from './MailListSkeleton';
import { MailList } from './MailList/MailList';
import {
  MailFolderId,
  MailSelectionListSpacing,
  MessageViewLayout,
  ProjectId,
} from '../../../models/Types';
import { useParams } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import { DND_TYPE_EMAIL } from '../../../dnd/types';
import MailSearch, { MailSearchRef } from './MailSearch/MailSearch';
import { createSelector } from 'reselect';
import { SpecialMailFolders } from '../actions/types';
import MoveMessageModal from './MoveMessageModal';
import classNames from 'classnames';
import {
  AdvancedMailSearchDto,
  MailSearchFormModel,
} from '../../../models/MailSearch';
import useFetchMessages from '../hooks/useFetchMessages';
import { setCurrentMailSearch } from '../actions/actionControllers/searchActionController';
import { getFavoriteProjects } from '../../projects/selectors';
import { makePrioStyles } from '../../../theme/utils';
import { SpecialMailFolderByProjectId } from '../reducers/projects/specialMailFolder';
import { useNavigate } from 'react-router-dom';
import { useKeyboardListener } from '@prio365/prio365-react-library';
import { deleteMessageSagaAction } from '../actions/sagas';
import { PrioTheme } from '../../../theme/types';
import {
  IgnoreValueConfig,
  propertyHasValue,
} from '../../../components/UltimateFilter/createUltimateFilter';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  dragRoot: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
    paddingRight: '1px',
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.old.components.mailListItem.spacing,
    paddingLeft: theme.old.components.mailListItem.spacing + 4,
    borderBottom: theme.old.borders.content,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: theme.old.palette.chromaticPalette.white,
    overflow: 'hidden',
  },
  searchContainerExpanded: {
    right: 0,
  },
  mailSearch: {
    flex: 1,
  },
  mailList: {
    overflow: 'hidden',
    '& .ant-list-split .ant-list-item:last-child': {
      borderBottom: theme.old.borders.content,
    },
  },
  selectAllCheckbox: {
    marginRight: theme.old.components.mailListItem.spacing,
    padding: '4px 0',
  },
  search: {
    '&.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child':
      {
        border: '#d9d9d9',
        '& .ant-input-search-button': {
          height: '100%',
        },
        '&:hover': {
          borderColor: '#3189cc',
        },
      },
  },
  placeholder: {
    height: 2 * theme.old.components.mailListItem.spacing + 32,
    width: '100%',
  },
  noItem: {
    width: '100%',
    height: '100%',
    wordWrap: 'break-word',
    overflow: 'hidden',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
}));

const specialMailFoldersSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: RootReducerState) => SpecialMailFolders,
      (state: RootReducerState) => SpecialMailFolderByProjectId,
    ],
    SpecialMailFolders | SpecialMailFolderByProjectId
  >(
    (state) => getSpecialMailFolders(state, projectId),
    getProjectsSpecialMailFoldersState,
    (specialMailFolders, byId) =>
      projectId === 'favorites' && !specialMailFolders
        ? byId
        : specialMailFolders
  );

interface MailSelectionListProps {
  className?: string;
  projectId: string;
  selectedMessages: Message[];
  onSelectionChange: (selectedIds: Message[]) => void;
  itemLayout: 'vertical' | 'horizontal';
  mailListSpacing: MailSelectionListSpacing;
  mailListNavigationWidth: number;
  automaticRead: boolean;
  prefix?: string;
  mailSearchExpanded?: boolean;
  listLayout: MessageViewLayout;
  listWidth?: number;
  listHeight?: number;
  onSearchExpandedChange?: (value: boolean) => void;
  mailFolderId?: MailFolderId;
}

export const MailSelectionList: React.FC<MailSelectionListProps> = React.memo(
  (props) => {
    const { mailFolderId: paramsMailFolderId } = useParams();
    return (
      <MemoizedMailSelectionList
        {...props}
        paramsMailFolderId={paramsMailFolderId}
      />
    );
  }
);

interface MemoizedMailSelectionListProps extends MailSelectionListProps {
  paramsMailFolderId: MailFolderId;
}

const MemoizedMailSelectionList: React.FC<MemoizedMailSelectionListProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const {
      projectId,
      selectedMessages,
      onSelectionChange,
      itemLayout,
      mailListSpacing,
      automaticRead,
      prefix,
      mailSearchExpanded,
      listLayout,
      listWidth,
      listHeight,
      onSearchExpandedChange,
      mailFolderId: componentMailFolderId,
      className,
      paramsMailFolderId,
      mailListNavigationWidth,
    } = props;
    const mailFolderId = componentMailFolderId ?? paramsMailFolderId;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const classes = useStyles({
      ...props,
      lessSpacing:
        mailListSpacing === 'full' ? 1 : mailListSpacing === 'middle' ? 2 : 4,
    });
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const [droppedItem, setDroppedItem] = useState<{
      message: Message;
      selectedMessages: Message[];
      projectId: ProjectId;
    }>({ message: null, selectedMessages: [], projectId });

    const isFetching = useSelector<RootReducerState, boolean>((state) =>
      getMessagesIsFetching(state, projectId, mailFolderId)
    );

    const [items, isFetchingSearchResults, searchTerm, searchTermMailFolderId] =
      useMailSearch({
        projectId,
        mailFolderId,
      });

    const allItemsSelected =
      selectedMessages.length && selectedMessages.length === items.length;

    const { deleteMovedMessageMe } = useSelector(getMailSettings);

    const favoriteProjects = useSelector(getFavoriteProjects);
    const specialMailFoldersState = useSelector(
      specialMailFoldersSelector(projectId)
    );

    const focusRef = useRef<boolean>(false);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const selectAllClicked = () => {
      if (allItemsSelected) {
        onSelectionChange([]);
      } else {
        onSelectionChange(items);
      }
    };

    const getInboxFolderId = (_projectId: ProjectId) => {
      return projectId === 'favorites'
        ? specialMailFoldersState?.[_projectId]?.['inboxFolder']?.id
        : specialMailFoldersState?.['inboxFolder']?.id;
    };

    const valuesIgnoreConfig = {
      hasAttachments: [false],
      isRead: [true],
      categoryAndConjunction: [false],
    };

    const handleOnSearch = (
      value: AdvancedMailSearchDto | string,
      mailFolderId: MailFolderId
    ) => {
      let searchTerm = value;
      if (typeof value === 'object') {
        const cleanedObject = cleanObject(value, valuesIgnoreConfig, false);
        searchTerm = flattenObject(cleanedObject);
      }
      onSelectionChange([]);
      if (searchTerm && Object.keys(searchTerm).length > 0) {
        if (projectId === 'favorites') {
          favoriteProjects.forEach((project) => {
            dispatch(
              setCurrentMailSearch(
                searchTerm,
                project.projectId,
                mailFolderId ? 'inbox' : 'allFolders'
              )
            );
          });
        } else {
          dispatch(
            setCurrentMailSearch(
              searchTerm,
              projectId,
              mailFolderId ?? 'allFolders'
            )
          );
        }
      } else {
        if (projectId === 'favorites') {
          favoriteProjects.forEach((project) => {
            dispatch(
              setCurrentMailSearch(
                null,
                project.projectId,
                mailFolderId ?? 'allFolders'
              )
            );
          });
        } else {
          dispatch(
            setCurrentMailSearch(null, projectId, mailFolderId ?? 'allFolders')
          );
        }
      }
    };
    //#endregion

    //#region ------------------------------ Effects
    const [, dropRef] = useDrop({
      accept: [DND_TYPE_EMAIL],
      drop: (item) => {
        if ((item as any).isPersonal as boolean) {
          return {
            destinationMailFolder: mailFolderId,
            message: (item as any).message as Message,
            projectId: projectId,
          };
        }
        return undefined;
      },
      canDrop: (item) => {
        return (
          !searchTerm &&
          !items.find((i) => i.id === ((item as any).message as Message).id)
        );
      },
    });

    useFetchMessages(projectId, mailFolderId);

    useKeyboardListener({
      Delete: () => {
        if (focusRef.current) {
          dispatch(
            deleteMessageSagaAction(projectId, mailFolderId, selectedMessages, {
              navigate,
            })
          );
          onSelectionChange([]);
        }
      },
    });
    //#endregion

    return (
      <>
        <MailSearchWithSelection
          projectId={projectId}
          valuesIgnoreConfig={valuesIgnoreConfig}
          specialMailFolders={
            projectId !== 'favorites'
              ? (specialMailFoldersState as SpecialMailFolders)
              : undefined
          }
          allItemsSelected={allItemsSelected}
          selectAllClicked={selectAllClicked}
          isExpanded={mailSearchExpanded}
          width={listWidth}
          onExpandedChange={onSearchExpandedChange}
          mailListSpacing={mailListSpacing}
          onSearch={handleOnSearch}
          mailFolderId={mailFolderId}
          currentSearchTerm={searchTerm}
          currentSearchMailFolderId={searchTermMailFolderId}
        />
        <div
          className={classNames(classes.root, className)}
          style={{ width: listWidth ?? '100%', height: listHeight ?? '100%' }}
          tabIndex={-1}
          onFocus={() => {
            focusRef.current = true;
          }}
          onBlur={() => {
            focusRef.current = false;
          }}
        >
          <div className={classes.placeholder} />
          <div ref={dropRef} className={classes.dragRoot}>
            {(isFetching || isFetchingSearchResults) && items.length === 0 ? (
              <MailListSkeleton />
            ) : !!searchTerm && items.length === 0 ? (
              <div className={classes.noItem}>{t('mail:noItemsFound')}</div>
            ) : (
              <MailList
                items={items}
                onSelectionChange={onSelectionChange}
                selection={selectedMessages}
                className={classes.mailList}
                mailFolderId={
                  !searchTerm
                    ? mailFolderId
                    : searchTermMailFolderId === 'allFolders'
                    ? null
                    : searchTermMailFolderId
                }
                projectId={projectId}
                itemLayout={itemLayout}
                mailListSpacing={mailListSpacing}
                mailListNavigationWidth={mailListNavigationWidth}
                hiddenLayout={listLayout === 'hidden'}
                prefix={prefix}
                automaticRead={automaticRead}
                moveToFolder={(message, selectedMessages, projectId) => {
                  setDroppedItem({
                    message,
                    selectedMessages,
                    projectId,
                  });
                }}
                deleteMovedMessageMe={deleteMovedMessageMe}
                getInboxFolderId={getInboxFolderId}
              />
            )}
          </div>
          {droppedItem.message && (
            <MoveMessageModal
              movedMessage={droppedItem.message}
              selectedMessages={droppedItem.selectedMessages}
              projectId={projectId}
              defaultSelected={droppedItem.projectId}
              onOk={() => {
                setDroppedItem({
                  message: null,
                  selectedMessages: [],
                  projectId: projectId,
                });
              }}
              onCancel={() => {
                setDroppedItem({
                  message: null,
                  selectedMessages: [],
                  projectId: projectId,
                });
              }}
              onSelectionChange={onSelectionChange}
              mailFolderId={mailFolderId}
            />
          )}
        </div>
      </>
    );
  });

export default MailSelectionList;

interface MailSearchWithSelectionProps {
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  specialMailFolders: SpecialMailFolders;
  allItemsSelected: boolean;
  valuesIgnoreConfig?: IgnoreValueConfig<MailSearchFormModel>;
  selectAllClicked: VoidFunction;
  isExpanded?: boolean;
  width?: number;
  onExpandedChange?: (value: boolean) => void;
  mailListSpacing: MailSelectionListSpacing;
  onSearch: (
    value: AdvancedMailSearchDto | string,
    mailFolderId: MailFolderId
  ) => void;
  currentSearchTerm?: AdvancedMailSearchDto | string;
  currentSearchMailFolderId?: MailFolderId;
}

const MailSearchWithSelection: React.FC<MailSearchWithSelectionProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    projectId,
    mailFolderId,
    specialMailFolders,
    allItemsSelected,
    isExpanded: defaultIsExpanded,
    selectAllClicked,
    width,
    onExpandedChange,
    mailListSpacing,
    onSearch,
    currentSearchMailFolderId,
    valuesIgnoreConfig,
  } = props;

  const classes = useStyles({
    ...props,
    lessSpacing:
      mailListSpacing === 'full' ? 1 : mailListSpacing === 'middle' ? 2 : 4,
  });
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const mailSearchRef = useRef<MailSearchRef>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const onTextSearchSubmit = (value: string, mailFolderId: MailFolderId) => {
    onSearch(value, mailFolderId);
  };

  const onAdvancedSearchSubmit = (
    value: AdvancedMailSearchDto,
    mailFolderId: MailFolderId
  ) => {
    onSearch(value, mailFolderId);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (onExpandedChange) onExpandedChange(isExpanded);
  }, [isExpanded, onExpandedChange]);
  //#endregion

  return (
    <div
      className={classNames(classes.searchContainer, {
        [classes.searchContainerExpanded]: defaultIsExpanded || isExpanded,
      })}
      style={{
        width: defaultIsExpanded || isExpanded ? '100%' : width - 1,
      }}
    >
      <Checkbox
        checked={allItemsSelected}
        onClick={selectAllClicked}
        className={classes.selectAllCheckbox}
      />
      <MailSearch
        ref={mailSearchRef}
        expanded={isExpanded}
        projectId={projectId}
        valuesIgnoreConfig={valuesIgnoreConfig}
        searchOptions={{
          searchTextInput: {
            onSubmit: onTextSearchSubmit,
            onChange: (_, __, hasValue) => {
              if (hasValue && !isExpanded) {
                setIsExpanded(true);
              }
            },
          },
          advancedSearch: {
            onSubmit: onAdvancedSearchSubmit,
            onChange: (_, __, hasValue) => {
              if (hasValue && !isExpanded) {
                setIsExpanded(true);
              }
            },
          },
        }}
        mailFolderId={mailFolderId}
        specialMailFolders={specialMailFolders}
        onFocus={() => setIsExpanded(true)}
        onBlur={(hasValue) =>
          hasValue ? setIsExpanded(true) : setIsExpanded(false)
        }
        className={classes.mailSearch}
        currentSearchMailFolderId={currentSearchMailFolderId}
      />
    </div>
  );
};

//#region -------------------------------- Helpers
const flattenObject = (ob: any): any => {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object' && ob[i] !== null && !Array.isArray(ob[i])) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

const cleanObject = (
  obj: object,
  ignoreValuesConfig: IgnoreValueConfig<MailSearchFormModel> = {},
  cleanArrays = true
) => {
  if (obj === undefined || obj === null) return {};

  const cleanedObj = {};
  const keys = Object.keys(obj);

  for (let key of keys) {
    const value = obj[key];

    if (Array.isArray(value)) {
      // If cleanArrays is true, clean the array, otherwise include it if it's non-empty
      const cleanedValue = cleanArrays
        ? cleanArray(value, ignoreValuesConfig)
        : value;
      if (cleanedValue.length > 0) {
        cleanedObj[key] = cleanedValue;
      }
    } else if (typeof value === 'object' && value !== null) {
      // For objects, recurse
      const cleanedValue = cleanObject(value, ignoreValuesConfig, cleanArrays);
      if (Object.keys(cleanedValue).length > 0) {
        cleanedObj[key] = cleanedValue;
      }
    } else {
      // For primitives, check propertyHasValue
      if (propertyHasValue(value, key, ignoreValuesConfig)) {
        cleanedObj[key] = value;
      }
    }
  }

  return cleanedObj;
};

const cleanArray = (
  arr: any[],
  ignoreValuesConfig: IgnoreValueConfig<MailSearchFormModel>
) => {
  return arr.filter((item) =>
    propertyHasValue(item, 'arrayElement', ignoreValuesConfig)
  );
};

// //#endregion
