/**
 * @file Index.tsx the react container for the sidebar application
 * @author Jonathan Sommer <j.sommer@fc-gruppe.de>
 * @copyright (c) FC-Digitalisierung GmbH 2020
 * @license All rights reserved.
 */

import { createStore, applyMiddleware, compose, Store } from 'redux';
import { createOffline } from '@redux-offline/redux-offline';
import { Config } from '@redux-offline/redux-offline/lib/types';
import { persistStore, persistReducer } from 'redux-persist';
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults';
import thunk from 'redux-thunk';
import createSagaMiddleware, { END, Saga, Task } from 'redux-saga';
import localforage from 'localforage';
import effect from './authEffect';
import discard from './discard';
import retry from './retry';
import { SAGA_REBUILD } from '../util/sagas';

const PRODUCTION = process.env.NODE_ENV === 'production';

// inject develober tools
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

interface ExtendedStore extends Store {
  runSaga<S extends Saga>(saga: S, ...args: Parameters<S>): Task;
  close(): void;
}

const configureStore: any = (rootReducer, rootSaga) => {
  let onError = (error: Error, info: { sagaStack: string }) => {
    console.error('Critical error in saga occured', error, info?.sagaStack);
    if (store) {
      store.runSaga(rootSaga, store);
      store.dispatch({ type: SAGA_REBUILD });
    }
  };
  const sagaMiddleware = createSagaMiddleware({
    onError,
  });

  localforage.config({
    driver: localforage.INDEXEDDB,
    name: 'Prio',
    storeName: 'prio',
  });

  const persistOptions = {
    ...defaultOfflineConfig.persistOptions,
    key: 'prio',
    storage: localforage,
    blacklist: ['auth', 'document', 'mail.meta.draftMessage'],
  };

  const offlineConfig: Config = {
    ...defaultOfflineConfig,
    effect,
    discard,
    retry,
    detectNetwork: (callback) => {
      callback(true);
    },
    //note: type definition is incorrect
    //@ts-ignore
    persist: false,
  };

  const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore,
  } = createOffline(offlineConfig);

  const persistedReducer = persistReducer(
    persistOptions,
    offlineEnhanceReducer(rootReducer)
  );

  const productionMiddlewares = [sagaMiddleware, thunk, offlineMiddleware];
  const devMiddlewares = [sagaMiddleware, thunk, offlineMiddleware];

  const middlewares = PRODUCTION ? productionMiddlewares : devMiddlewares;
  const middleware = applyMiddleware(...middlewares);

  const composeEnhancers = PRODUCTION
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store: ExtendedStore = createStore(
    persistedReducer,
    composeEnhancers(offlineEnhanceStore, middleware)
  );
  const persistor = persistStore(store);

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return { persistor, store };
};

export default configureStore;
