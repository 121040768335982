// JobTitleSelect.tsx
import React, { useEffect, useMemo, useState } from 'react';
import { Select } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from '../../../apps/main/rootReducer';
import { fetchInternalJobTitles } from '../../settings/actions/internalJobTitles';
import { fetchExternalJobTitles } from '../../settings/actions/externalJobTitles';
import { debounceFunction } from '../../../util';
import { createSelector } from 'reselect';
import { InternalJobTitlesByIdState } from '../../settings/reducers/internalJobTitles';
import { ExternalJobTitlesByIdState } from '../../settings/reducers/externalJobTitles';

const debouncedSetSearchValue = debounceFunction((value, setSearchValue) => {
  if (value) {
    setSearchValue(value);
  } else {
    setSearchValue(null);
  }
}, 500);

const jobTitleSelector = createSelector<
  [
    (state: RootReducerState) => InternalJobTitlesByIdState,
    (state: RootReducerState) => string[],
    (state: RootReducerState) => ExternalJobTitlesByIdState,
    (state: RootReducerState) => string[],
  ],
  {
    label: string;
    value: string;
    searchValue: string;
  }[]
>(
  (state) => state.settings.internalJobTitles.byId,
  (state) => state.settings.internalJobTitles.ids,
  (state) => state.settings.externalJobTitles.byId,
  (state) => state.settings.externalJobTitles.ids,
  (
    internalJobTitlesById,
    internalJobTitlesIds,
    externalJobTitlesById,
    externalJobTitlesIds
  ) => {
    const internalJobTitles = internalJobTitlesIds
      .map((id) => internalJobTitlesById[id])
      .filter(Boolean);
    const externalJobTitles = externalJobTitlesIds
      .map((id) => externalJobTitlesById[id])
      .filter(Boolean);

    return [...internalJobTitles, ...externalJobTitles].map((title) => ({
      label: title.name,
      value: title.name,
      searchValue: title.name,
    }));
  }
);

interface JobTitleSelectProps {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
}

const JobTitleSelect: React.FC<JobTitleSelectProps> = ({
  onChange,
  value,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState<string>(null);

  const jobTitleOptions = useSelector(jobTitleSelector);

  const jobTitleOptionsWithSearchValue = useMemo(() => {
    return [
      ...jobTitleOptions,
      ...(searchValue
        ? [
            {
              label: searchValue,
              value: searchValue,
            },
          ]
        : []),
    ];
  }, [jobTitleOptions, searchValue]);

  useEffect(() => {
    dispatch(fetchInternalJobTitles());
    dispatch(fetchExternalJobTitles());
  }, [dispatch]);

  const handleSearch = (value: string) => {
    debouncedSetSearchValue(value, setSearchValue);
  };
  return (
    <Select
      showSearch
      options={jobTitleOptionsWithSearchValue}
      onChange={onChange}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      onSearch={handleSearch}
      value={value ?? ''}
      disabled={disabled}
      size="small"
    />
  );
};

export default JobTitleSelect;
