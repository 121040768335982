import React, {
  useState,
  ReactNode,
  Reducer,
  useReducer,
  useEffect,
  useRef,
} from 'react';
import { Table, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import equals from 'deep-equal';

import { ContactPicker } from '../../contacts/components/ContactPicker';
import { makePrioStyles } from '../../../theme/utils';
import {
  ExternalProjectContact,
  ExternalProjectContactItem,
} from '../../../models/ProjectContacts';
import { useDispatch, useSelector } from 'react-redux';
import {
  createExternalProjectContact,
  updateExternalProjectContact,
  archiveExternalProjectContact,
} from '../actions';
import {
  ProjectId,
  ContactId,
  ExternalProjectContactId,
} from '../../../models/Types';
import Flex from '../../../components/Flex';
import { fetchExternalJobTitles } from '../../settings/actions/externalJobTitles';
import {
  getAllCompanies,
  getCompaniesByIdState,
  getExternalJobTitles,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { ExternalJobTitleSuggestion } from '../../../models/ExternalJobTitleSuggestion';
import { tableTranslations } from '../../../util/table';
import { apiFetchContact } from '../../contacts/api';
import classNames from 'classnames';
import PrioSpinner from '../../../components/PrioSpinner';
import { Company } from '../../../models/Company';
import PrioAutoComplete from '../../../components/PrioAutoComplete';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  rootsRoot: {
    overflow: 'hidden',
    height: '100%',
  },
  root: {
    overflow: 'hidden',
    maxHeight: '100%',
    '& .ant-table-row .ant-table-cell .external-project-contacts-table-delete-button':
      {
        visibility: 'hidden',
      },
    '& .ant-table-row:hover .ant-table-cell .external-project-contacts-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& .ant-table-sticky-holder': {
      zIndex: 0,
    },
    '& .ant-table-sticky-scroll': {
      display: 'none',
    },
    '& .ant-spin-nested-loading': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-spin-container': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table': {
      overflow: 'hidden',
      height: '100%',
    },
    '& .ant-table-container': {
      overflow: 'hidden',
      height: '100%',
    },
  },
  dropDown: {
    width: '100%',
    '&.ant-select-single .ant-select-selector': {
      paddingLeft: 0,
    },
    '& .ant-select-selection-item': {
      color: theme.old.palette.primaryColor,
    },
  },
  dropDownDisabled: {
    '& .ant-select-selection-item': {
      opacity: 0.5,
    },
  },
  contactPicker: {
    width: '100%',
  },
  footer: {
    padding: theme.old.spacing.unit(2),
  },
  disabledButton: {
    '&.ant-btn:not(.ant-btn-primary) > .svg-inline--fa': {
      opacity: 0.5,
    },
  },
  addedRow: {
    background: theme.old.palette.backgroundPalette.sub,
    '& > td': {
      background: theme.old.palette.backgroundPalette.sub,
    },
  },
  companyName: {
    width: '100%',
    ...theme.old.components.table.secondaryColumn,
  },
}));

interface ExternalProjectContactsTableProps {
  className?: string;
  contactItems: ExternalProjectContactItem[];
  projectId: ProjectId;
  loading?: boolean;
}

interface TableEntry {
  key: string;
  name: string;
  companyName: string;
  lastName: string;
  jobTitle: string;
  addUserRow: boolean;
  isTemporary: boolean;
}

interface NewProjectContact {
  contactId?: ContactId;
  companyName?: string;
  projectId: ProjectId;
  jobTitle: string;
  isValid: boolean;
}

type UpdateNewProjectContactKey = 'contactId' | 'jobTitle' | 'companyName';

type UpdateNewProjectContactValue = string | boolean;

interface UpdateNewProjectContactAction {
  key: UpdateNewProjectContactKey;
  value: UpdateNewProjectContactValue;
}

export const ExternalProjectContactsTable: React.FC<
  ExternalProjectContactsTableProps
> = (props) => {
  const classes = useStyles();
  const {
    className,
    contactItems: originalContactItems,
    projectId,
    loading,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addUserActive, setNewProjectContactRowActive] = useState(false);
  const [modalDeleteContactVisible, setModalDeleteContactVisible] =
    useState<boolean>(false);
  const [contactToDelete, setContactToDelete] = useState<string>(null);

  const [contactItems, setContactItems] =
    useState<ExternalProjectContactItem[]>(originalContactItems);
  useEffect(() => {
    setContactItems(originalContactItems);
  }, [originalContactItems]);

  useEffect(() => {
    dispatch(fetchExternalJobTitles());
  }, [dispatch]);

  const jobTitleSuggestions = useSelector<
    RootReducerState,
    ExternalJobTitleSuggestion[]
  >(getExternalJobTitles);

  const companys = useSelector<RootReducerState, Company[]>((state) =>
    getAllCompanies(state)
  );

  const validate: (state: NewProjectContact) => boolean = (
    state: NewProjectContact
  ) => !!state.contactId && state.jobTitle !== '';

  const emptyNewProjectContact: NewProjectContact = {
    projectId,
    jobTitle: '',
    isValid: false,
  };

  const [newProjectContact, updateNewProjectContact] = useReducer<
    Reducer<NewProjectContact, UpdateNewProjectContactAction | 'clear'>
  >(
    (
      state: NewProjectContact,
      action: UpdateNewProjectContactAction | 'clear'
    ) => {
      if (action === 'clear') {
        return emptyNewProjectContact;
      }
      const updatedState = { ...state, [action.key]: action.value };
      return {
        ...updatedState,
        isValid: validate(updatedState),
      };
    },
    emptyNewProjectContact
  );

  const updateValue = (
    externalProjectContactId: string,
    key: UpdateNewProjectContactKey,
    value: UpdateNewProjectContactValue
  ) => {
    const updatedContactItems = contactItems.map((cI) =>
      cI.externalProjectContact.externalProjectContactId ===
      externalProjectContactId
        ? {
            ...cI,
            externalProjectContact: {
              ...cI.externalProjectContact,
              [key]: value,
            },
          }
        : cI
    );
    setContactItems(updatedContactItems);
  };

  const saveChanges = () => {
    contactItems.forEach((contactItem) => {
      const originalContactItem = (
        originalContactItems as ExternalProjectContactItem[]
      ).find(
        (projectContact) =>
          projectContact.externalProjectContact.externalProjectContactId ===
          contactItem.externalProjectContact.externalProjectContactId
      );
      if (!equals(contactItem, originalContactItem)) {
        dispatch(
          updateExternalProjectContact(
            contactItem.externalProjectContact.projectId,
            [
              {
                jobTitle: contactItem.externalProjectContact.jobTitle,
                rowVersion: contactItem.externalProjectContact.rowVersion,
                externalProjectContactId:
                  contactItem.externalProjectContact.externalProjectContactId,
                projectId: contactItem.externalProjectContact.projectId,
                contactId: contactItem.externalProjectContact.contactId,
              } as ExternalProjectContact,
            ],
            [originalContactItem.externalProjectContact]
          )
        );
      }
    });
  };

  const saveNewProjectContact = async () => {
    const { contactId, projectId, jobTitle } = newProjectContact;
    const { result } = await apiFetchContact(contactId);
    if (result.status >= 200 && result.status < 300) {
      dispatch(
        createExternalProjectContact({ contactId, projectId, jobTitle })
      );
    }
    cancelNewProjectContactRow();
    updateNewProjectContact('clear');
  };

  const deleteProjectContact = (
    externalProjectContactId: ExternalProjectContactId
  ) => {
    const contactItem = (
      originalContactItems as ExternalProjectContactItem[]
    ).find(
      (projectContact) =>
        projectContact.externalProjectContact.externalProjectContactId ===
        externalProjectContactId
    );
    dispatch(
      archiveExternalProjectContact(
        externalProjectContactId,
        contactItem.externalProjectContact.projectId,
        contactItem.externalProjectContact
      )
    );
    setModalDeleteContactVisible(false);
    setContactToDelete(null);
  };

  const tableBottomRef = useRef(null);

  const companiesById = useSelector(getCompaniesByIdState);

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('projects:projectContactsTable.columnTitles.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      fixed: 'left',
      sorter: (a, b) => a.lastName?.localeCompare(b.lastName),
      render: (value, record: TableEntry, index: number) =>
        record.addUserRow ? (
          <>
            <ContactPicker
              className={classes.contactPicker}
              value={newProjectContact.contactId}
              onChange={(contactId, companyId) => {
                updateNewProjectContact({
                  key: 'contactId',
                  value: contactId as string,
                });
                updateNewProjectContact({
                  key: 'companyName',
                  value: companiesById[companyId].fullName ?? '',
                });
              }}
              excludedContactIds={contactItems.map(
                (cI) => cI.contact.contactId
              )}
              contactType="ExternalContact"
            />
            <div ref={tableBottomRef}></div>
          </>
        ) : (
          <span style={{ opacity: addUserActive && 0.5 }}>{value}</span>
        ),
      width: 200,
    },
    {
      title: t('projects:projectContactsTable.columnTitles.companyName'),
      dataIndex: 'companyName',
      sorter: (a, b) => a.companyName?.localeCompare(b.companyName),
      render: (value, record: TableEntry, index: number) => (
        <span
          className={classes.companyName}
          style={{ opacity: addUserActive && 0.5 }}
        >
          {record.addUserRow ? newProjectContact.companyName ?? '' : value}
        </span>
      ),
      width: 200,
    },
    {
      title: t('projects:projectContactsTable.columnTitles.jobTitle'),
      dataIndex: 'jobTitle',
      sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
      render: (value, record) => (
        <PrioAutoComplete
          disabled={record.isTemporary || (!record.addUserRow && addUserActive)}
          options={jobTitleSuggestions.map((x, i) => ({
            label: x.name,
            value: i.toString(),
          }))}
          value={record.addUserRow ? newProjectContact.jobTitle : value}
          onChange={(value) => {
            record.addUserRow
              ? updateNewProjectContact({
                  key: 'jobTitle',
                  value: value,
                })
              : updateValue(record.key, 'jobTitle', value);
          }}
        />
      ),
      width: 200,
    },
    {
      render: (_, record) =>
        record.addUserRow ? (
          <Button
            disabled={!newProjectContact.isValid}
            onClick={saveNewProjectContact}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            type="link"
            onClick={() => handleDelete(record.key)}
            disabled={
              record.isTemporary || (!record.addUserRow && addUserActive)
            }
            className={classNames(
              'external-project-contacts-table-delete-button',
              {
                [classes.disabledButton]: addUserActive,
              }
            )}
            iconProp={['fal', 'trash']}
          ></Button>
        ),
      width: 80,
    },
  ];

  const handleDelete = (key: string) => {
    setModalDeleteContactVisible(true);
    setContactToDelete(key);
  };

  const data: TableEntry[] = contactItems.map(
    (contactItem: ExternalProjectContactItem, index: number) => {
      const _company = companys.find(
        (company) => company.companyId === contactItem.contact.companyId
      );
      return {
        key: contactItem.externalProjectContact.externalProjectContactId,
        name:
          contactItem.contact.firstName + ' ' + contactItem.contact.lastName,
        companyName:
          _company?.fullName ??
          _company?.fullName2 ??
          _company?.shortName ??
          '',
        lastName: contactItem.contact.lastName,
        jobTitle: contactItem.externalProjectContact.jobTitle,
        isTemporary: contactItem.externalProjectContact.isTemporary ?? false,
        addUserRow: false,
      };
    }
  );

  const enableNewProjectContactRow = () => setNewProjectContactRowActive(true);
  const cancelNewProjectContactRow = () => setNewProjectContactRowActive(false);

  const rowSelection: TableRowSelection<TableEntry> = {
    onChange: (selectedRowKeys, selectedRows) => {},
    fixed: true,
    renderCell: (
      value: boolean,
      record: TableEntry,
      index: number,
      originNode: ReactNode
    ) =>
      record.addUserRow ? (
        <FontAwesomeIcon
          icon={['fal', 'times']}
          onClick={cancelNewProjectContactRow}
        />
      ) : (
        originNode
      ),
  };

  const isDirty = !equals(originalContactItems, contactItems);

  useEffect(() => {
    if (addUserActive && tableBottomRef?.current) {
      tableBottomRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [addUserActive, tableBottomRef]);

  return (
    <Flex.Column className={classes.rootsRoot}>
      <Table
        className={classNames(classes.root, className)}
        columns={columns}
        pagination={false}
        scroll={{
          x: '100%',
          y: 'calc(100% - 56px)',
        }}
        dataSource={
          addUserActive
            ? [
                ...data,
                {
                  key: 'addUser',
                  name: '',
                  companyName: '',
                  projectRoles: [],
                  jobTitle: '',
                  hourlyRateId: '',
                  emailNotificationEnabled: false,
                  addUserRow: true,
                },
              ]
            : data
        }
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowClassName={(record) => {
          if (record.addUserRow) {
            return classes.addedRow;
          } else {
            return '';
          }
        }}
        locale={tableTranslations(t)}
        loading={{
          spinning: loading,
          indicator: <PrioSpinner alignSelf />,
        }}
        sticky
      />
      <Flex.Row className={classes.footer}>
        <Button
          onClick={enableNewProjectContactRow}
          disabled={isDirty || addUserActive}
          iconProp={['fal', 'user-plus']}
        >
          {t('common:add')}
        </Button>
        <Flex.Item flex={1} />
        <Button onClick={saveChanges} disabled={!isDirty || addUserActive}>
          {t('common:save')}
        </Button>
      </Flex.Row>
      <Modal
        visible={modalDeleteContactVisible}
        onOk={() => deleteProjectContact(contactToDelete)}
        onCancel={() => {
          setModalDeleteContactVisible(false);
        }}
        title={t('projects:modalDeleteProjectContact.title')}
        okText={t('projects:modalDeleteProjectContact.actionButton')}
        cancelText={t('projects:modalDeleteProjectContact.cancelButton')}
      >
        {t('projects:modalDeleteProjectContact.externalContact.content')}
      </Modal>
    </Flex.Column>
  );
};

export default ExternalProjectContactsTable;
