import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { notification, Tabs, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import {
  CreateTrainingRequest,
  Training,
  TrainingRequest,
} from '../../../models/Training';
import TrainingsTable from './TrainingsTable';
import TrainingForm from './TrainingForm';

import { Company } from '../../../models/Company';
import { QualificationsById } from './HRTrainings';
import { TrainingRequestTable } from './TrainingRequestTable';
import { Center } from '../../../components/Center';
import { ContactId, TrainingId } from '../../../models/Types';
import ContactPicker from '../../contacts/components/ContactPicker';
import {
  apiCreateTraining,
  apiCreateTrainingRequests,
  apiFetchTrainingRequests,
  apiUpdateTraining,
} from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const panelWidth = 600;

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    flex: 1,
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    overflow: 'hidden',
    overflowY: 'auto',
    height: '100%',
    position: 'relative',
  },
  panelChild: {
    width: panelWidth,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    borderLeft: theme.old.borders.sub,
    position: 'relative',
    padding: theme.old.spacing.defaultPadding,
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    overflow: 'hidden',
    height: '100%',
  },
  tabs: {
    overflow: 'hidden',
    height: '100%',
    '& .ant-tab-content-top': {
      height: '100%',
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  table: {
    marginBottom: theme.old.spacing.unit(2),
    marginLeft: -theme.old.spacing.unit(2.5),
    // marginRight: -theme.old.spacing.unit(2.5),
    maxWidth: `calc(100% + ${theme.old.spacing.unit(3)}px)`,
    // maxWidth: `calc(100%)`,
    overflowX: 'visible',
    height: '100%',
    overflowY: 'auto',
  },
  addButton: {
    textAlign: 'left',
    backgroundColor: 'transparent',
    '& .svg-inline--fa': {
      color: 'currentColor!important',
    },
  },
  scrollable: {
    height: 'calc(100% - 48px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  scrollableEdit: {
    height: '100%',
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
  },
}));

interface HRTrainingsProps {
  onRowClick?: (entry: Training) => void;
  trainings: Training[];
  companiesById: { [key: string]: Company };
  qualificationsById: QualificationsById;
  triggerReload: VoidFunction;
  closeNewTraining: VoidFunction;
  newTrainingOpen: boolean;
  loading?: boolean;
}

export const HRTrainingsPage: React.FC<HRTrainingsProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const {
    trainings,
    companiesById,
    qualificationsById,
    triggerReload,
    closeNewTraining,
    newTrainingOpen,
    loading,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isInviting, setIsInviting] = useState<boolean>(false);

  const [selectedTraining, setSelectedTraining] = useState<Training | null>(
    null
  );
  const [inviteParticipantShown, setInviteParticipantShown] =
    useState<boolean>(false);
  const [participantsToInvite, setParticipantsToInvite] = useState<ContactId[]>(
    []
  );
  const [
    selectedTrainingTrainingRequests,
    setSelectedTrainingTrainingRequests,
  ] = useState<TrainingRequest[]>([]);

  const showParticipantInvitation = () => {
    setInviteParticipantShown(true);
  };

  const fetchTrainingRequests = useCallback(
    async (trainingId: TrainingId) => {
      const { data } = await apiFetchTrainingRequests(trainingId);

      if (data) {
        setSelectedTrainingTrainingRequests(data);
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'hr:trainings.errorMessages.fetchTrainingRequestsError'
          ),
        });
      }
    },
    [t]
  );

  const triggerTrainingRequestReload = () => {
    if (selectedTraining) {
      fetchTrainingRequests(selectedTraining.trainingId);
    }
  };
  const handleParticipantsToInviteChange = (
    participants: string | string[]
  ) => {
    setParticipantsToInvite(participants as string[]);
  };

  const handleClose = () => {
    if (newTrainingOpen) {
      closeNewTraining();
    } else {
      setOpen(false);
      setSelectedTraining(null);
    }
  };

  const onTrainingSelect = (training: Training) => {
    fetchTrainingRequests(training.trainingId);
    setSelectedTraining(training);
    setOpen(true);
  };

  const updateTraining: (training: Training) => Promise<boolean> = async (
    training
  ) => {
    setIsSaving(true);
    const { result } = await apiUpdateTraining(training);
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      triggerReload();
      handleClose();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.updateTrainingError'),
      });
      return false;
    }
  };

  const createTraining: (
    training: CreateTrainingRequest
  ) => Promise<boolean> = async (training) => {
    setIsSaving(true);
    const { result } = await apiCreateTraining(training);
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      triggerReload();
      handleClose();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.createTrainingError'),
      });
      return false;
    }
  };

  const inviteSelectedParticipants = async () => {
    setIsInviting(true);
    const trainingId = selectedTraining.trainingId;

    const { result } = await apiCreateTrainingRequests(
      trainingId,
      participantsToInvite
    );

    if (result.status >= 200 && result.status < 300) {
      setInviteParticipantShown(false);
      setParticipantsToInvite([]);
      fetchTrainingRequests(trainingId);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.inviteParticipantsError'),
      });
    }
    setIsInviting(false);
  };

  const width = open || newTrainingOpen ? panelWidth : 0;
  const opacity = open || newTrainingOpen ? '100%' : '0%';

  const emptyCreateTrainingsRequest: Training = useMemo(
    () => ({
      title: '',
      trainingId: null,
      eventLocation: '',
      trainingManagerIds: [],
      trainingManagers: [],
      trainingOrganizer: null,
      trainingTargetDepartmentIds: [],
      trainingTargetQualificationIds: [],
      trainingTargetAudienceIds: [],
      trainingDays: [
        {
          startTime: moment('09:00', 'HH:mm').add('1', 'month').toISOString(),
          endTime: moment('12:00', 'HH:mm').add('1', 'month').toISOString(),
        },
      ],
      isOnlineTraining: false,
      isPrivateTraining: false,
      isActive: false,
      isArchived: false,
    }),
    []
  );

  if (!trainings || !companiesById || !qualificationsById) {
    return (
      <Flex.Row className={classes.root}>
        <div className={classes.content}>
          <Center>
            <PrioSpinner />
          </Center>
        </div>
      </Flex.Row>
    );
  }

  return (
    <Flex.Row className={classes.root}>
      <div className={classes.content}>
        <TrainingsTable
          trainings={trainings}
          companiesById={companiesById}
          qualificationsById={qualificationsById}
          triggerReload={triggerReload}
          onRowClick={onTrainingSelect}
          loading={loading}
        />
      </div>
      <div className={classes.panel} style={{ width, opacity }}>
        <div className={classes.panelChild}>
          {newTrainingOpen && (
            <Flex.Column
              childrenGap={theme.old.spacing.unit(1)}
              className={classes.fullHeight}
            >
              <Typography.Title level={2}>
                {t('hr:trainings.newTrainingTitle')}
              </Typography.Title>
              <div className={classes.scrollable}>
                <TrainingForm
                  initialValues={emptyCreateTrainingsRequest}
                  actionLabel={t('hr:trainings.form.actions.save')}
                  onFinish={createTraining}
                  cancelLabel={t('hr:trainings.form.actions.cancel')}
                  deleteLabel={t('hr:trainings.form.actions.delete')}
                  onCancel={handleClose}
                  disableForm={isSaving}
                />
              </div>
            </Flex.Column>
          )}
          {!newTrainingOpen && selectedTraining && (
            <Flex.Column
              childrenGap={theme.old.spacing.unit(1)}
              className={classes.fullHeight}
              // style={{ overflow: 'hidden' }}
            >
              <Typography.Title level={2}>
                {selectedTraining.title}
              </Typography.Title>
              <Tabs className={classes.tabs} style={{ height: '100%' }}>
                <Tabs.TabPane
                  tab={t('hr:trainings.tabs.details')}
                  key="details"
                  className={classes.fullHeight}
                >
                  <div className={classes.scrollableEdit}>
                    <TrainingForm
                      initialValues={selectedTraining}
                      actionLabel={t('hr:trainings.form.actions.save')}
                      onFinish={updateTraining}
                      cancelLabel={t('hr:trainings.form.actions.cancel')}
                      deleteLabel={t('hr:trainings.form.actions.delete')}
                      onCancel={handleClose}
                      disableForm={isSaving}
                    />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t('hr:trainings.tabs.participants')}
                  key="participants"
                >
                  <div className={classes.fullHeight}>
                    <Flex.Column className={classes.fullHeight}>
                      <TrainingRequestTable
                        trainingId={selectedTraining.trainingId}
                        trainingRequests={selectedTrainingTrainingRequests}
                        triggerReload={triggerTrainingRequestReload}
                        className={classes.table}
                      />
                      {inviteParticipantShown ? (
                        <Flex.Column childrenGap={theme.old.spacing.unit(1)}>
                          <ContactPicker
                            multiple
                            contactType="InternalContact"
                            value={participantsToInvite}
                            onChange={handleParticipantsToInviteChange}
                            disabled={isInviting}
                            label={t('hr:trainings.selectParticipants')}
                            excludedContactIds={selectedTrainingTrainingRequests?.map(
                              (request) => request.participant.contactId
                            )}
                          />
                          <Button
                            disabled={
                              isInviting ||
                              !participantsToInvite ||
                              participantsToInvite.length === 0
                            }
                            onClick={inviteSelectedParticipants}
                          >
                            {t('hr:trainings.actions.inviteSelected')}
                          </Button>
                        </Flex.Column>
                      ) : (
                        <Button
                          type="link"
                          className={classes.addButton}
                          onClick={showParticipantInvitation}
                          iconProp={['fal', 'plus']}
                        >
                          <span>
                            {t('hr:trainings.actions.inviteParticipant')}
                          </span>
                        </Button>
                      )}
                    </Flex.Column>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </Flex.Column>
          )}
        </div>
        <Button
          onClick={handleClose}
          shape="circle"
          iconProp={['fal', 'times']}
          className={classes.closeButton}
          type="link"
        />
      </div>
    </Flex.Row>
  );
};

export default HRTrainingsPage;
