import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Button, PrioSpinner } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getMonthlyCloseMeByMonth,
  getMonthlyClosesMeInRange,
  getUserMe,
} from '../../../../apps/main/rootReducer';
import { OfficeId } from '../../../../models/Types';
import { apiFetchPendingActions } from '../../api';
import { useNavigate } from 'react-router-dom';
import { MonthlyClose } from '../../../../models/TimeKeeping';
import moment, { Moment } from 'moment';
import {
  apiFetchEditableMonthlyClose,
  apiFetchMonthlyCloseMeById,
} from '../../../timeKeeping/api';
import { debounceMonthlyCloseMe } from '../../../timeKeeping/actions';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import { useQuery } from '@tanstack/react-query';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  header: {
    fontSize: theme.font.fontSize.large,
    fontWeight: theme.font.fontWeight.bold,
  },
  pendingActionItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minWidth: '140px',
    maxWidth: '140px',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    border: `1px solid ${theme.colors.base.primary.default}`,
    background: theme.colors.base.blue[20],
  },
  iconWrapperHeight2: {
    width: 64,
    height: 64,
  },
  number: {
    fontSize: 24,
  },
  type: {
    fontSize: 16,
    width: '100%',
    textAlign: 'center',
    hyphens: 'auto',
  },
  numberTypeGroup: {
    width: '100%',
  },
  rowH2: {
    marginTop: '24px',
    marginBottom: theme.spacing.small,
  },
  rowNoItems: {
    justifyContent: 'center',
  },
  pendingActionItemsRow: {
    display: 'flex',
    flex: 1,
    gap: theme.spacing.small,
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  actionButtonH1: {
    fontSize: theme.font.fontSize.small,
  },
  noPendingActionsIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.small,
    borderRadius: '100%',
    border: `1px solid ${theme.colors.base.green.default}`,
    background: theme.colors.base.green[20],
    height: 48,
    width: 48,
  },
  noPendingActionsText: {
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.small,
  },
  noPendingActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.small,
    justifyContent: 'center',
    heigh: `calc(100% - ${theme.old.spacing.unit(6)})`,
  },
  centerInRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100% - 44px)',
  },
  actionItemSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
    width: 140,
  },
}));

type PendingActionType =
  | 'timeKeepingDays'
  | 'monthlyCloseClosures'
  | 'absences'
  | 'timeKeepingEntriesHR'
  | 'compensationPayments';

interface PendingAction {
  iconName: IconName;
  numberOfActions: number;
  type: PendingActionType;
  linkToAction: string;
  label: string;
}

interface DashboardPendingActionsItemProps {
  className?: string;
  height?: number;
}

const generateSearchString = (
  type: PendingActionType,
  isGlobalHR: boolean,
  officeIdsWhereOfficeHR: OfficeId[]
) => {
  const parsedOfficeIds = officeIdsWhereOfficeHR
    .map((officeId) => `'${officeId}'`)
    .join(',')
    .toString();
  let stateValue = '';
  let officeIdValue = '';

  if (type === 'absences') {
    stateValue = `Data.AbsenceState eq 'requested','revokeRequested'`;
    officeIdValue = `Data.OfficeId eq ${parsedOfficeIds}`;
  }

  if (type === 'timeKeepingEntriesHR') {
    stateValue = `Data.State eq 'ApprovalRequested'`;
    officeIdValue = `Calculated.OfficeId eq ${parsedOfficeIds}`;
  }

  if (type === 'compensationPayments') {
    stateValue = `Calculated.IsApproved eq 'false'`;
    officeIdValue = `Calculated.OfficeId eq ${parsedOfficeIds}`;
  }

  return `${stateValue}${isGlobalHR ? '' : `%26 ${officeIdValue}`}`;
};

const generateLinkToActions = (
  type: PendingActionType,
  userOfficeId: OfficeId,
  isGlobalHR: boolean,
  officeIdsWhereOfficeHR: OfficeId[],
  closableMonth?: string,
  earliestDayWithoutTimeKeeping?: string
) => {
  const officeId = officeIdsWhereOfficeHR.find(
    (officeId) => officeId === userOfficeId
  )
    ? userOfficeId
    : officeIdsWhereOfficeHR[0];

  let pathPrefix = '/module/prio/hr/';
  let query = `?s=${generateSearchString(
    type,
    isGlobalHR,
    officeIdsWhereOfficeHR
  )}`;
  let path = '';
  let pickedFilters = '';

  if (type === 'absences') {
    path = `${isGlobalHR ? '' : `office/${officeId}/`}absence/openproposals`;
    pickedFilters = `&pickedFilters=Data.AbsenceState`;
  }

  if (type === 'timeKeepingEntriesHR') {
    path = `office/${officeId}/timekeeping/timekeepingDays`;
    pickedFilters = `&pickedFilters=Data.State`;
  }

  if (type === 'compensationPayments') {
    path = `office/${officeId}/timekeeping/compensationPayments`;
  }

  if (type === 'monthlyCloseClosures') {
    pathPrefix = '';
    path = `/module/prio/projects/me/timeAndLeaveManagement/summary`;
    query = `?month=${closableMonth}`;
  }

  if (type === 'timeKeepingDays') {
    pathPrefix = '';
    path = `/module/prio/projects/me/timeAndLeaveManagement/summary`;
    query = `?month=${earliestDayWithoutTimeKeeping}`;
  }

  return `${pathPrefix}${path}${query}${pickedFilters}`;
};

const usePendingAction = (
  searchType: string,
  searchString: string,
  isHR: boolean
) => {
  const threshold = 1000;
  const forceResult = true;

  const { data, isLoading } = useQuery({
    queryKey: [searchType, searchString, threshold, forceResult],
    queryFn: () =>
      apiFetchPendingActions(
        searchType ?? '',
        searchString ?? '',
        threshold,
        forceResult
      ),
    staleTime: 1000 * 60 * 60 * 20, // 20 hours
    enabled: isHR,
  });
  const number = data?.data?.totalItems ?? undefined;
  const _isLoading = isHR ? isLoading : false;
  return { number, isLoading: _isLoading };
};

export const DashboardPendingActionsItem: React.FC<
  DashboardPendingActionsItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, height = 2 } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);

  const employeeId = useMemo(() => {
    return userMe?.id;
  }, [userMe?.id]);

  const { getContactById } = useContactsContext();
  const officeId = getContactById(employeeId)?.officeId;

  const isGlobalHR = userMe?.prioData?.globalRoles?.includes('globalHR');
  const officeIdsWhereOfficeHR: OfficeId[] = useMemo(
    () =>
      Object.entries(userMe?.prioData?.officeRoles)
        .filter(([_, roles]) => {
          return roles.includes('officeHR');
        })
        .map(([officeId, _]) => officeId),
    [userMe?.prioData?.officeRoles]
  );

  const [earliestMonthToClose, setEarliestMonthToClose] =
    useState<string>(undefined);

  const [earliestDayWithoutTimeKeeping, setEarliestDayWithoutTimeKeeping] =
    useState<string>(undefined);

  const monthlyClosesMeInRange = useSelector<RootReducerState, MonthlyClose[]>(
    (state) =>
      getMonthlyClosesMeInRange(
        state,
        moment(earliestMonthToClose)?.subtract(1, 'month')?.toISOString(true),
        moment().toISOString(true)
      )
  );

  const currentMonthlyClose = useSelector<RootReducerState, MonthlyClose>(
    (state) =>
      getMonthlyCloseMeByMonth(
        state,
        moment().toISOString(true).substring(0, 7)
      )
  );

  const missingTimeKeepingDays = useMemo(() => {
    const calculateMissingDays = (monthlyCloses: MonthlyClose[]) => {
      if (!monthlyCloses?.[0]) return undefined;
      const number = monthlyCloses.reduce((acc, monthlyClose) => {
        const { shouldBeWorkingDays, timeKeepingDays } = monthlyClose;
        const numberOfMissingDays = shouldBeWorkingDays.reduce((acc2, day) => {
          const timeKeepingDay = timeKeepingDays.find((timeKeepingDay) =>
            moment(timeKeepingDay?.timeKeepingEntries[0]?.startTime).isSame(
              day,
              'day'
            )
          );
          if (!timeKeepingDay) {
            acc2.push(day.split('T')[0]);
          }
          return acc2;
        }, [] as string[]);
        return [...acc, ...numberOfMissingDays];
      }, []);
      return number;
    };
    if (
      moment().isSameOrBefore(moment(monthlyClosesMeInRange?.[0]?.month)) &&
      currentMonthlyClose
    ) {
      return calculateMissingDays([currentMonthlyClose]);
    }
    return calculateMissingDays(monthlyClosesMeInRange);
  }, [monthlyClosesMeInRange, currentMonthlyClose]);

  const [numberOfOpenTimekeepingDays, setNumberOfOpenTimekeepingDays] =
    useState<number>(undefined);
  const [
    numberOfMissingMonthlyCloseClosures,
    setNumberOfMissingMonthlyCloseClosures,
  ] = useState<number>(undefined);

  const { number: numberOfPendingAbsenceProposals, isLoading: isApLoading } =
    usePendingAction(
      'absenceProposals',
      generateSearchString('absences', isGlobalHR, officeIdsWhereOfficeHR),
      isGlobalHR || officeIdsWhereOfficeHR.length > 0
    );

  const { number: numberOfPendingTimeKeepingEntries, isLoading: isTkLoading } =
    usePendingAction(
      'timekeepingDays',
      generateSearchString(
        'timeKeepingEntriesHR',
        isGlobalHR,
        officeIdsWhereOfficeHR
      ),
      isGlobalHR || officeIdsWhereOfficeHR.length > 0
    );

  const {
    number: numberOfPendingCompensationPayments,
    isLoading: isCpLoading,
  } = usePendingAction(
    'compensationPayments',
    generateSearchString(
      'compensationPayments',
      isGlobalHR,
      officeIdsWhereOfficeHR
    ),
    isGlobalHR || officeIdsWhereOfficeHR.length > 0
  );

  const gotFirstResult =
    !!numberOfOpenTimekeepingDays ||
    !!numberOfMissingMonthlyCloseClosures ||
    ((isGlobalHR || officeIdsWhereOfficeHR.length > 0) &&
      (!!numberOfPendingTimeKeepingEntries ||
        !!numberOfPendingAbsenceProposals ||
        !!numberOfPendingCompensationPayments));

  const isLoading =
    numberOfOpenTimekeepingDays === undefined ||
    numberOfMissingMonthlyCloseClosures === undefined ||
    isApLoading ||
    isTkLoading ||
    isCpLoading;

  const pendingActions: PendingAction[] = useMemo(
    () =>
      [
        {
          label: t(`dashboard:pendingActions.actions.book`),
          iconName: 'business-time',
          numberOfActions: numberOfOpenTimekeepingDays,
          type: 'timeKeepingDays',
          linkToAction: generateLinkToActions(
            'timeKeepingDays',
            officeId,
            isGlobalHR,
            officeIdsWhereOfficeHR,
            undefined,
            earliestDayWithoutTimeKeeping
          ),
        } as PendingAction,
        {
          label: t(`dashboard:pendingActions.actions.close`),
          iconName: 'clock-seven',
          numberOfActions: numberOfMissingMonthlyCloseClosures,
          type: 'monthlyCloseClosures',
          linkToAction: generateLinkToActions(
            'monthlyCloseClosures',
            officeId,
            isGlobalHR,
            officeIdsWhereOfficeHR,
            earliestMonthToClose
          ),
        } as PendingAction,
        {
          label: t(`dashboard:pendingActions.actions.approve`),
          iconName: 'island-tropical',
          numberOfActions: numberOfPendingAbsenceProposals,
          type: 'absences',
          linkToAction: generateLinkToActions(
            'absences',
            officeId,
            isGlobalHR,
            officeIdsWhereOfficeHR
          ),
        } as PendingAction,
        {
          label: t(`dashboard:pendingActions.actions.approve`),
          iconName: 'business-time',
          numberOfActions: numberOfPendingTimeKeepingEntries,
          type: 'timeKeepingEntriesHR',
          linkToAction: generateLinkToActions(
            'timeKeepingEntriesHR',
            officeId,
            isGlobalHR,
            officeIdsWhereOfficeHR
          ),
        } as PendingAction,
        {
          label: t(`dashboard:pendingActions.actions.approve`),
          iconName: 'money-bill',
          numberOfActions: numberOfPendingCompensationPayments,
          type: 'compensationPayments',
          linkToAction: generateLinkToActions(
            'compensationPayments',
            officeId,

            isGlobalHR,
            officeIdsWhereOfficeHR
          ),
        } as PendingAction,
      ].filter((pendingAction) => pendingAction?.numberOfActions > 0),
    [
      isGlobalHR,
      numberOfOpenTimekeepingDays,
      numberOfMissingMonthlyCloseClosures,
      numberOfPendingAbsenceProposals,
      numberOfPendingTimeKeepingEntries,
      numberOfPendingCompensationPayments,
      officeIdsWhereOfficeHR,
      t,
      earliestDayWithoutTimeKeeping,
      earliestMonthToClose,
      officeId,
    ]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const debounceFetchMonthlyClose = useCallback(
    (day: Moment) => {
      const start = day.startOf('month').toISOString(true).split('T')[0];
      const end = moment().endOf('month').toISOString(true).split('T')[0];
      dispatch(debounceMonthlyCloseMe(start, end));
    },
    [dispatch]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (employeeId) {
      const fetchClosableMonthlyClose = async () => {
        const { data } = await apiFetchEditableMonthlyClose(employeeId);

        if (data) {
          const { nextToCloseMonthlyCloseId } = data;

          const { data: nextToCloseMonthlyClose } =
            await apiFetchMonthlyCloseMeById(nextToCloseMonthlyCloseId);

          if (nextToCloseMonthlyClose) {
            const _diff = moment()
              .endOf('month')
              .diff(moment(nextToCloseMonthlyClose.month), 'month');
            setEarliestMonthToClose(
              moment(nextToCloseMonthlyClose.month)
                ?.toISOString(true)
                .substring(0, 7)
            );
            setNumberOfMissingMonthlyCloseClosures(_diff < 0 ? 0 : _diff);
            debounceFetchMonthlyClose(moment(nextToCloseMonthlyClose.month));
          }
        }
      };
      fetchClosableMonthlyClose();
    }
  }, [employeeId, debounceFetchMonthlyClose]);

  useEffect(() => {
    setNumberOfOpenTimekeepingDays(missingTimeKeepingDays?.length);
    setEarliestDayWithoutTimeKeeping(
      missingTimeKeepingDays?.[0]?.substring(0, 7)
    );
  }, [missingTimeKeepingDays]);

  //#endregion

  //#region ------------------------------ Components
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row childrenGap={theme.spacing.small} marginBottom={8}>
        <Flex.Item>
          <div className={classes.header}>
            {t('dashboard:pendingActions.title')}
          </div>
        </Flex.Item>
      </Flex.Row>
      <PendingActionItemsRow
        height={height}
        pendingActions={pendingActions}
        isLoading={isLoading}
        gotFirstResult={gotFirstResult}
      />
    </Flex.Column>
  );
};

export default DashboardPendingActionsItem;

//#region ------------------------------ PendingActionItems
interface PendingActionItemProps extends PendingAction {
  className?: string;
}

const PendingActionItemH2: React.FC<PendingActionItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, iconName, numberOfActions, type, linkToAction, label } =
    props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onButtonClick = () => {
    navigate(linkToAction);
  };
  //#endregion

  return (
    <div
      className={classNames(classes.pendingActionItem, className)}
      style={{
        gap: theme.spacing.regular,
      }}
    >
      <div
        className={classNames(classes.iconWrapper, {
          [classes.iconWrapperHeight2]: true,
        })}
      >
        <FontAwesomeIcon
          icon={['fal', iconName]}
          size={'xl'}
          color={theme.colors.base.primary.default}
        />
      </div>
      <Flex.Column alignItems="center" className={classes.numberTypeGroup}>
        <div id="number" className={classNames(classes.number)}>
          {numberOfActions}
        </div>
        <div
          className={classes.type}
          style={{ height: 51 }}
          title={t(`dashboard:pendingActions.type.${type}`)}
        >
          {t(`dashboard:pendingActions.type.${type}`)}
        </div>
      </Flex.Column>
      <Button type="link" onClick={onButtonClick}>
        {label}
      </Button>
    </div>
  );
};

const PendingActionItemH1: React.FC<PendingActionItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, numberOfActions, type, linkToAction, label } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onButtonClick = () => {
    navigate(linkToAction);
  };
  //#endregion

  return (
    <div className={classNames(classes.pendingActionItem, className)}>
      <Flex.Column
        alignItems="center"
        className={classes.numberTypeGroup}
        childrenGap={4}
      >
        <div style={{ fontSize: 18 }}>{numberOfActions}</div>
        <div
          className={classes.type}
          style={{ fontSize: 15 }}
          title={t(`dashboard:pendingActions.type.${type}`)}
        >
          {t(`dashboard:pendingActions.type.${type}`)}
        </div>
      </Flex.Column>
      <Button
        className={classes.actionButtonH1}
        type="link"
        onClick={onButtonClick}
      >
        {label}
      </Button>
    </div>
  );
};

//#endregion

//#region ------------------------------ PendingActionItemsRow
interface PendingActionItemsRowProps {
  className?: string;
  height: number;
  pendingActions: PendingAction[];
  isLoading: boolean;
  gotFirstResult: boolean;
}

const PendingActionItemsRow: React.FC<PendingActionItemsRowProps> = (props) => {
  //#region ------------------------------ Defaults
  const { height, pendingActions, isLoading, gotFirstResult } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  return (
    <div
      className={classNames(
        { [classes.rowH2]: height === 2 },
        { [classes.rowNoItems]: pendingActions.length === 0 },
        classes.pendingActionItemsRow
      )}
    >
      {isLoading && !gotFirstResult && (
        <div className={classes.centerInRow}>
          <PrioSpinner size="large" />
        </div>
      )}
      {!isLoading && pendingActions.length === 0 && (
        <div className={classes.noPendingActionsContainer}>
          <div className={classes.noPendingActionsIcon}>
            <FontAwesomeIcon
              icon={['fal', 'check']}
              size={'lg'}
              color={theme.colors.base.green.default}
            />
          </div>
          <div className={classes.noPendingActionsText}>
            {t(`dashboard:pendingActions.noActions`)}
          </div>
        </div>
      )}
      {pendingActions.map((pendingAction, index) => {
        if (height === 1) {
          return (
            <PendingActionItemH1
              key={index}
              iconName={pendingAction.iconName}
              numberOfActions={pendingAction.numberOfActions}
              type={pendingAction.type}
              linkToAction={pendingAction.linkToAction}
              label={pendingAction.label}
            />
          );
        }
        return (
          <PendingActionItemH2
            key={index}
            iconName={pendingAction.iconName}
            numberOfActions={pendingAction.numberOfActions}
            type={pendingAction.type}
            linkToAction={pendingAction.linkToAction}
            label={pendingAction.label}
          />
        );
      })}
      {gotFirstResult && isLoading && (
        <div className={classes.actionItemSpinner}>
          <PrioSpinner />
        </div>
      )}
    </div>
  );
};
//#endregion
