import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';

/** Contact fetching */
export const FETCH_SPECIAL_MAIL_FOLDERS_ME_REQUEST =
  PRIO + 'FETCH_SPECIAL_MAIL_FOLDERS_ME_REQUEST';
export const FETCH_SPECIAL_MAIL_FOLDERS_ME_COMMIT =
  PRIO + 'FETCH_SPECIAL_MAIL_FOLDERS_ME_COMMIT';
export const FETCH_SPECIAL_MAIL_FOLDERS_ME_ROLLBACK =
  PRIO + 'FETCH_SPECIAL_MAIL_FOLDERS_ME_ROLLBACK';

export const fetchSpecialMailFoldersMe = () => ({
  type: FETCH_SPECIAL_MAIL_FOLDERS_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/MailFolder/Me/specialMailFolder`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_SPECIAL_MAIL_FOLDERS_ME_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_SPECIAL_MAIL_FOLDERS_ME_ROLLBACK,
      },
    },
  },
});
