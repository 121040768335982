import { CLEAR_PRIO_CACHE } from '../../../actions';
import { InternalOffice } from '../../../models/Office';

import { combineReducers, Reducer } from 'redux';
import { distinct } from '../../../util';
import { OfficeId } from '../../../models/Types';
import {
  FETCH_INTERNAL_OFFICES_COMMIT,
  FETCH_INTERNAL_OFFICES_REQUEST,
  FETCH_INTERNAL_OFFICES_ROLLBACK,
} from '../actions';

export interface InternalOfficesState {
  byId: InternalOfficesByIdState;
  ids: string[];
  meta: InternalOfficesMetaState;
}

export interface InternalOfficesByIdState {
  [officeId: string]: InternalOffice;
}

interface InternalOfficesAction {
  type: string;
  payload?: InternalOffice[];
}

interface InternalOfficesMetaState {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const byId: Reducer<InternalOfficesByIdState, InternalOfficesAction> = (
  state = {},
  action
) => {
  switch (action.type) {
    case FETCH_INTERNAL_OFFICES_COMMIT: {
      const { payload } = action;
      return payload.reduce(function (map, item) {
        map[item.officeId] = item;
        return map;
      }, state);
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const ids: Reducer<string[], InternalOfficesAction> = (state = [], action) => {
  switch (action.type) {
    case FETCH_INTERNAL_OFFICES_COMMIT: {
      const { payload } = action;
      return distinct([...state, ...payload.map((item) => item.officeId)]);
    }
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

const meta: Reducer<InternalOfficesMetaState, InternalOfficesAction> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_INTERNAL_OFFICES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_INTERNAL_OFFICES_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_INTERNAL_OFFICES_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'companies:errorMessages.fetchInternalOfficesError',
      };
    }

    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<InternalOfficesState>({
  byId,
  ids,
  meta,
});

export const getInternalOfficesByIdState: (
  state: InternalOfficesState
) => InternalOfficesByIdState = (state) => state.byId;

export const getInternalOfficeIds: (state: InternalOfficesState) => string[] = (
  state
) => state.ids;

export const getInternalOffice: (
  state: InternalOfficesState,
  officeId: OfficeId
) => InternalOffice = (state, officeId) => state.byId[officeId];

export const getAllInternalOffices: (
  state: InternalOfficesState
) => InternalOffice[] = (state) => {
  const offices = state.ids
    ?.map((id) => state.byId[id])
    ?.sort((a, b) => a.name.localeCompare(b.name));
  return offices;
};

export const getIsFetching: (state: InternalOfficesState) => boolean = (
  state
) => state.meta.isFetching;

export const getHasError: (state: InternalOfficesState) => boolean = (state) =>
  state.meta.hasError;

export const getErrorMessage: (state: InternalOfficesState) => string = (
  state
) => state.meta.errorMessage;
