import React from 'react';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesDe from 'i18n-iso-countries/langs/de.json';
import { Select } from 'antd';
import { makePrioStyles } from '../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
}));

countries.registerLocale(countriesEn);
countries.registerLocale(countriesDe);

interface CountryPickerProps {
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  disabled?: boolean;
}

export const CountryPicker: React.FC<CountryPickerProps> = ({
  value,
  placeholder,
  onChange,
  disabled,
}) => {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const handleOnChange: (value: string) => void = (value: string) => {
    onChange && onChange(value ?? '');
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={handleOnChange}
      disabled={disabled}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      className={disabled ? classes.disabledPicker : ''}
    >
      {Object.entries<string | string[]>(
        countries.getNames(i18n.language.substring(0, 2))
      ).map(([key, value]) => {
        const name = Array.isArray(value) ? value[0] : value;
        return (
          <Select.Option key={key} value={key}>
            {name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default CountryPicker;
