import React from 'react';

import { Table, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { InternalCompany } from '../../../models/Company';
import { InternalOffice } from '../../../models/Office';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tableTranslations } from '../../../util/table';
import equals from 'deep-equal';
import { MENU_BUTTON_SIZE } from '../../../constants';

import { isTemporaryId } from '../../../util';
import { useDispatch } from 'react-redux';

import { archiveInternalOffice, archiveCompany } from '../actions';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  companyOfficeIcon: {
    marginLeft: theme.old.spacing.unit(1),
    marginRight: theme.old.spacing.unit(2),
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(4) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(4) > button': {
      visibility: 'visible',
    },
  },
  selectedRow: {
    '& > td': {
      background: theme.old.palette.backgroundPalette.active.content,
    },
  },
  menuColum: {
    padding: '0!important',
    width: MENU_BUTTON_SIZE,
  },
  secondaryColumn: theme.old.components.table.secondaryColumn,
}));

export interface TableEntry {
  company?: InternalCompany;
  office?: InternalOffice;
  children?: TableEntry[];
}

interface InternalCompanyTableProps {
  rootEntry: TableEntry;
  onRowClick?: (entry: TableEntry) => void;
  selectedEntry?: TableEntry;
}

export const InternalCompanyTable: React.FC<InternalCompanyTableProps> = (
  props
) => {
  const classes = useStyles();

  const { rootEntry: rootCompany, onRowClick, selectedEntry } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('companies:internalCompanyTable.columnsTitles.name'),
      render: (_, record) => (
        <>
          <FontAwesomeIcon
            icon={['fal', record.company ? 'building' : 'map-marker-alt']}
            className={classes.companyOfficeIcon}
          />
          {record.company?.fullName ?? record.office?.name}
        </>
      ),
    },

    {
      title: t('companies:internalCompanyTable.columnsTitles.shortName'),
      className: classes.secondaryColumn,
      render: (_, record) => <> {record.company?.shortName} </>,
    },

    {
      title: t('companies:internalCompanyTable.columnsTitles.type'),
      className: classes.secondaryColumn,
      render: (_, record) =>
        record.company
          ? record.company.parentCompanyId
            ? t('companies:internalCompanyTable.subsidiary')
            : t('companies:internalCompanyTable.rootCompany')
          : t('companies:internalCompanyTable.office'),
    },

    {
      render: (_, record) =>
        !(record.company && !record.company.parentCompanyId) && (
          <Button
            type="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleArchive(record);
            }}
            iconProp={['fal', 'trash']}
          />
        ),
      className: classes.menuColum,
    },
  ];

  const handleArchive = (record: TableEntry) => {
    Modal.confirm({
      icon: null,
      title: t('companies:internalCompanyTable.confirmation.archive.title'),
      content: t(
        'companies:internalCompanyTable.confirmation.archive.content',
        {
          companyName: record.company?.fullName ?? record.office?.name,
        }
      ),
      okText: t('companies:internalCompanyTable.confirmation.archive.okText'),
      cancelText: t(
        'companies:internalCompanyTable.confirmation.archive.cancelText'
      ),

      onOk() {
        if (record.office) {
          if (isTemporaryId(record.office.officeId)) return;
          dispatch(
            archiveInternalOffice(
              record.office.companyId,
              record.office.officeId,
              record.office
            )
          );
        } else if (record.company) {
          if (isTemporaryId(record.company.companyId)) return;
          dispatch(archiveCompany(record.company.companyId));
        }
      },
      onCancel() {},
    });
  };

  return (
    <Table<TableEntry>
      className={classes.root}
      dataSource={[rootCompany]}
      columns={columns}
      scroll={{
        x: true,
        y: 'calc(100vh - 210px)',
      }}
      rowKey={(record) => record.office?.officeId ?? record.company?.companyId}
      locale={tableTranslations(t)}
      defaultExpandAllRows
      rowClassName={classes.row}
      onRow={(record: TableEntry) => {
        return {
          className: equals(record, selectedEntry) ? classes.selectedRow : null,
          onClick: onRowClick
            ? () => {
                onRowClick(record);
              }
            : null,
        };
      }}
      pagination={false}
    />
  );
};

export default InternalCompanyTable;
