import React, { useEffect } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import MyAbsencesOverview from './MyAbsencesOverview';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAbsenceOverview,
  getAbsenceProposalsMe,
  getAbsencesMeIsFetching,
} from '../../../apps/main/rootReducer';
import { fetchAbsencesMe } from '../actions';
import { Center } from '../../../components/Center';
import PrioSpinner from '../../../components/PrioSpinner';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface MyAbsencesOverviewWrapperProps {
  className?: string;
}

export const MyAbsencesOverviewWrapper: React.FC<
  MyAbsencesOverviewWrapperProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const absenceOverview = useSelector(getAbsenceOverview);
  const absenceProposals = useSelector(getAbsenceProposalsMe);
  const isFetching = useSelector(getAbsencesMeIsFetching);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(fetchAbsencesMe());
  }, [dispatch]);
  //#endregion

  if (!absenceOverview && isFetching) {
    return (
      <Center>
        <PrioSpinner size="large" />
      </Center>
    );
  }

  return (
    <MyAbsencesOverview
      className={classNames(classes.root, className)}
      absenceOverview={{ ...absenceOverview, absenceProposals }}
      canSelfSelectAbsence
    />
  );
};

export default MyAbsencesOverviewWrapper;
