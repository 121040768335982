import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {
  RootReducerState,
  getProjectByIdState,
} from '../../../apps/main/rootReducer';
import useDebounce from '../../../hooks/useDebounce';
import { Contact } from '../../../models/Contact';
import {
  EmailSearchResult,
  EmailSearchResultContact,
  EmailSearchResultProject,
} from '../../../models/Message';
import { ProjectId } from '../../../models/Types';
import { distinctByProperty } from '../../../util';
import { ContactsByIdState } from '../../contacts/reducers/contacts';
import { ProjectByIdState } from '../../projects/reducers/projects';
import { apiFetchAddressSuggestions, apiSearchEmailAddress } from '../api';

const sortAdresses: (
  a: EmailSearchResultContact | EmailSearchResultProject,
  b: EmailSearchResultContact | EmailSearchResultProject
) => number = (a, b) => {
  if (a.eMail === b.eMail) {
    if (a.type === 'contact') {
      const castA: EmailSearchResultContact = a as EmailSearchResultContact;
      const castB: EmailSearchResultContact = b as EmailSearchResultContact;
      const lastNameCompare = castA.lastName?.localeCompare(castB.lastName);
      if (lastNameCompare !== 0) return lastNameCompare;
      return castA.firstName?.localeCompare(castB.firstName);
    } else {
      const castA: EmailSearchResultProject = a as EmailSearchResultProject;
      const castB: EmailSearchResultProject = b as EmailSearchResultProject;
      const lastNameCompare = castA.projectNumber?.localeCompare(
        castB.projectNumber
      );
      if (lastNameCompare !== 0) return lastNameCompare;
      return castA.projectName?.localeCompare(castB.projectName);
    }
  }
  return a?.eMail?.localeCompare(b.eMail);
};

const emailSearchProjectsSelector = createSelector<
  [
    (state: RootReducerState) => string[],
    (state: RootReducerState) => ProjectByIdState,
  ],
  EmailSearchResultProject[]
>(
  (state) => state.projects.projects.myIds,
  (state) => state.projects.projects.byId,
  (myIds, byId) => {
    return (myIds ?? []).reduce<EmailSearchResultProject[]>((array, id) => {
      const project = byId[id];
      if (project.parentProject) {
        return array;
      }
      array.push({
        id: id,
        eMail: `${project.eMailPrefix}@${project.eMailSuffix}` ?? null,
        type: 'project',
        projectName: project.name,
        projectNumber: project.number,
      });
      return array;
    }, []);
  }
);

const emailSearchContactsSelector = createSelector<
  [
    (state: RootReducerState) => string[],
    (state: RootReducerState) => ContactsByIdState,
  ],
  EmailSearchResultContact[]
>(
  (state) => state.contacts.contacts.ids,
  (state) => state.contacts.contacts.byId,
  (ids, byId) =>
    (ids ?? [])
      .map((id) => byId[id])
      .filter(
        (contact: Contact) =>
          contact !== undefined && contact !== null && !contact.isArchived
      )
      .map((contact) => ({
        id: contact.contactId,
        eMail: contact.eMail ?? contact.eMail2 ?? null,
        type: 'contact',
        lastName: contact.lastName,
        firstName: contact.firstName,
      }))
);

interface AddressSearchProps {
  searchTerm: string;
  projectId: ProjectId;
  isOnline?: boolean;
}

interface UseAddressSearch {
  addresses: EmailSearchResult[];
  isSearching: boolean;
  refreshAddressSuggestions: () => Promise<void>;
}

const useAddressSearch = (
  props: AddressSearchProps,
  selectedAdresses?: string[]
): UseAddressSearch => {
  const { searchTerm, isOnline, projectId } = props;

  const offlineContacts = useSelector(emailSearchContactsSelector);
  const offlineProjects = useSelector(emailSearchProjectsSelector);

  const projectById = useSelector(getProjectByIdState);

  const offlineAddresses = useMemo(
    () => [...offlineContacts, ...offlineProjects].sort(sortAdresses),
    [offlineContacts, offlineProjects]
  );

  const [addressesFromOnlineSearch, setAddressesFromOnlineSearch] = useState<
    (EmailSearchResultContact | EmailSearchResultProject)[]
  >([]);

  const [isSearching, setIsSearching] = useState<boolean>(false);

  const isEmptySearch = searchTerm === '';

  const [addressSuggestions, setAddressSuggestions] = useState<
    EmailSearchResult[]
  >([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const onlineSearchResults = useMemo(
    () =>
      distinctByProperty(
        [
          ...addressesFromOnlineSearch,
          ...addressSuggestions.filter(
            (suggestion) =>
              !addressesFromOnlineSearch.find(
                (address) => address.id === suggestion.id
              )
          ),
        ].sort(sortAdresses),
        'eMail'
      ),
    [addressesFromOnlineSearch, addressSuggestions]
  );

  const offlineSearchResults = useMemo(
    () =>
      distinctByProperty(
        isEmptySearch && projectId !== 'favorites'
          ? [
              ...addressSuggestions,
              ...(selectedAdresses ?? [])
                .map((address) =>
                  offlineAddresses.find(
                    (offlineAddress) => offlineAddress.eMail === address
                  )
                )
                .filter(
                  (address) =>
                    address &&
                    !addressSuggestions.find(
                      (suggestion) => address.id === suggestion.id
                    )
                ),
            ]
          : [
              ...offlineAddresses,
              ...addressSuggestions.filter(
                (suggestion) =>
                  !offlineAddresses.find(
                    (address) => address.id === suggestion.id
                  )
              ),
            ].sort(sortAdresses),
        'eMail'
      ),
    [
      isEmptySearch,
      projectId,
      addressSuggestions,
      offlineAddresses,
      selectedAdresses,
    ]
  );

  const refreshAddressSuggestions = async () => {
    setIsSearching(true);
    const { result, data } = await apiFetchAddressSuggestions(projectId, 15);
    if (result.status >= 200 && result.status < 300) {
      setAddressSuggestions(
        data.map((entry, index) =>
          entry.type === 'external'
            ? {
                id: `${index}`,
                eMail: entry.eMail,
                type: entry.type,
                isSuggestion: true,
              }
            : { ...entry, isSuggestion: true }
        )
      );
    }
    setIsSearching(false);
  };

  useEffect(() => {
    const fetchAddressSuggestions = async () => {
      setIsSearching(true);
      const { result, data } = await apiFetchAddressSuggestions(projectId, 15);

      if (result.status >= 200 && result.status < 300) {
        setAddressSuggestions(
          data
            .map((entry, index) =>
              entry.type === 'external'
                ? {
                    id: `${index}`,
                    eMail: entry.eMail,
                    type: entry.type,
                    isSuggestion: true,
                  }
                : { ...entry, isSuggestion: true }
            )
            .filter(
              (entry) =>
                entry.type !== 'project' ||
                !projectById[entry.id]?.parentProject
            )
        );
      }
      setIsSearching(false);
    };
    if (projectId !== 'favorites') {
      fetchAddressSuggestions();
    }
  }, [projectId, projectById]);

  useEffect(() => {
    if (debouncedSearchTerm && isOnline && debouncedSearchTerm.length > 2) {
      const searchOnline = async () => {
        setIsSearching(true);
        try {
          const { result, data } =
            await apiSearchEmailAddress(debouncedSearchTerm);
          if (result.status >= 200 && result.status < 300) {
            const sorted = data
              .filter(
                (item) =>
                  item?.eMail &&
                  (item?.type !== 'project' ||
                    !projectById[item?.id]?.parentProject)
              )

              .sort(sortAdresses);

            setAddressesFromOnlineSearch(sorted);
          }
        } catch {
          console.warn('Error occured in useAddressSearch');
        }
        setIsSearching(false);
      };
      searchOnline();
    } else {
      setAddressesFromOnlineSearch([]);
    }
  }, [debouncedSearchTerm, isOnline, projectById]);

  if (addressesFromOnlineSearch.length > 0) {
    return {
      addresses: onlineSearchResults,
      isSearching,
      refreshAddressSuggestions,
    };
  }

  return {
    addresses: offlineSearchResults,
    isSearching,
    refreshAddressSuggestions,
  };
};

export default useAddressSearch;
