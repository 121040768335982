import React from 'react';
import { Notification, NotificationType } from '../../../models/Notification';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Typography } from 'antd';
import ContactText from '../../contacts/components/ContactText';
import { ContactId } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.unit(2),
  },
  secondaryText: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
  },
}));

interface NotificationItemProps {
  notification: Notification;
  onClose?: VoidFunction;
}

export const NotificationItem: React.FC<NotificationItemProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const {
    notification: { type },
    notification,
    onClose,
  } = props;

  const link = getLink(notification);

  const render = () => (
    <Flex.Row
      className={classes.root}
      alignItems="center"
      childrenGap={theme.old.spacing.unit(2)}
    >
      <FontAwesomeIcon
        icon={['fal', getIcon(type)]}
        color={theme.old.palette.primaryColor}
      />
      <Flex.Column>
        <ContactText
          contactId={getContactId(notification)}
          fallback={
            <Typography.Text>{t('common:unknownContact')}</Typography.Text>
          }
        />
        <Typography.Text className={classes.secondaryText}>
          {getSecondaryText(notification, t)}
        </Typography.Text>
      </Flex.Column>
      {getDetails(notification, t)}
    </Flex.Row>
  );

  if (link) {
    return (
      <Link to={link} onClick={onClose}>
        {render()}
      </Link>
    );
  }
  return render();
};

const getIcon: (type: NotificationType) => IconName = (type) => {
  return 'empty-set';
};
const getContactId: (notification: Notification) => ContactId = (
  notification
) => {
  return null;
};
const getSecondaryText: (
  notification: Notification,
  t: TFunction
) => ContactId = (notification, t) => {
  return t(`notifications:secondaryLabels.${notification.type}`);
};

const getDetails: (
  notification: Notification,
  t: TFunction
) => React.ReactNode = (notification, t) => {
  return null;
};
const getLink: (notification: Notification) => string = (notification) => {
  return null;
};

export default NotificationItem;
