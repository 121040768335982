import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { AbsenceProposal } from '../../../../models/AbsenceProposal';
import { Divider, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import PrioSpinner from '../../../../components/PrioSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import { compactDateWithWeekDayFormatString } from '../../../../util';
import { getIconForAbsenceType } from '../../../../util/icon';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAbsenceByIdState,
  getAbsenceIds,
  getAbsenceOverview,
  getAbsencesMeIsFetching,
  getContacts,
  getUserMe,
} from '../../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { AbsencesById } from '../../../absences/reducers/absences';
import { AbsenceProposalId } from '../../../../models/Types';
import moment, { Moment } from 'moment';
import { debounceAbsencesMe } from '../../../absences/actions';
import { Link } from 'react-router-dom';
import { apiFetchFutureAbsenceProposalsWithUserInvolved } from '../../../absences/api';
import { Contact } from '../../../../models/Contact';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const { Text } = Typography;

// const ABSENCE = 'absence';
const SUBSTITUTION = 'substitution';
const NOTIFICATION = 'notification';

type PanelType = 'substitution' | 'notification';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  spinner: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: '20px',
  },
  chevronIcon: {
    marginRight: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chevronIconMuted: {
    marginRight: '10px',
    color: theme.old.palette.chromaticPalette.lightGrey,
    fontSize: theme.old.typography.fontSize.small,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noHeight: {
    height: 0,
    overflow: 'hidden',
    marginBottom: 8,
  },
  transition: {
    transition: 'all 0.5s ease-in-out',
  },
  spacingDividerPanelgroup: {
    margin: '8px 0px',
  },
  mutedTitle: {
    '&.ant-typography': {
      color: theme.old.palette.chromaticPalette.lightGrey,
      fontSize: theme.old.typography.fontSize.small,
    },
  },
  marginBottomBig: {
    marginBottom: 24,
  },
  marginBottomSmall: {
    marginBottom: 8,
  },
  maxListHeightSubstitutions: {
    overflowY: 'scroll',
    maxHeight: '142px',
  },
  maxListHeightNotifications: {
    overflowY: 'scroll',
    maxHeight: '156px',
  },
  marginBottomSubstitutionList: {
    marginBottom: '8px',
  },
  fullWidth: {
    width: '100%',
  },
  reloadIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.old.palette.chromaticPalette.lightGrey,
  },
}));

const absenceProposalSelector = createSelector<
  [
    (state: RootReducerState) => AbsencesById,
    (state: RootReducerState) => AbsenceProposalId[],
  ],
  AbsenceProposal[]
>(getAbsenceByIdState, getAbsenceIds, (absencesById, absenceIds) => {
  return absenceIds
    .reduce((currentArray, id) => {
      const absence = absencesById[id];
      if (absence && moment(absence.to).isSameOrAfter(moment())) {
        currentArray.push(absence);
      }
      return currentArray;
    }, [] as AbsenceProposal[])
    .sort((a, b) => {
      return moment(a.from).diff(moment(b.from));
    });
});

const useAbsenceProposalsInvolved = (filter: { from: Moment; to: Moment }) => {
  const { data, isLoading } = useQuery({
    queryKey: ['absenceProposalsInvolved'],
    queryFn: () => apiFetchFutureAbsenceProposalsWithUserInvolved(filter),
    staleTime: 1000 * 60 * 60, // 1 hour
  });
  return { absenceProposalsInvolved: data?.data, isLoading };
};

interface DashboardAbsenceOverviewItemProps {
  className?: string;
}

export const DashboardAbsenceOverviewItem: React.FC<
  DashboardAbsenceOverviewItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isSubstitutionExpanded, setIsSubstitutionExpanded] =
    useState<boolean>(false);
  const [isNotificationExpanded, setIsNotificationExpanded] =
    useState<boolean>(false);

  const absenceOverview = useSelector(getAbsenceOverview);
  const absenceProposals = useSelector(absenceProposalSelector).filter(
    (absence) => {
      return !(
        absence.absenceType === 'annualLeavePlanning' ||
        absence.absenceState === 'revokeAccepted' ||
        absence.absenceState === 'declined'
      );
    }
  );

  const isFetching = useSelector(getAbsencesMeIsFetching);

  const userMe = useSelector(getUserMe);

  const { absenceProposalsInvolved, isLoading } = useAbsenceProposalsInvolved({
    from: moment().startOf('day'),
    to: moment().add(7, 'days'),
  });

  const myUpcomingSubstitutions = absenceProposalsInvolved
    ?.filter((absence) => {
      return absence.substituteId === userMe?.id;
    })
    ?.sort((a, b) => {
      return moment(a.from).diff(moment(b.from));
    });

  const myUpcomingNotifications = absenceProposalsInvolved
    ?.filter((absence) => {
      return absence.notifyContactIds?.includes(userMe?.id as string);
    })
    ?.sort((a, b) => {
      return moment(a.from).diff(moment(b.from));
    });

  const applicantAbsenceProposalSubstitution = useSelector(
    (state: RootReducerState) =>
      getContacts(state, myUpcomingSubstitutions?.map((a) => a.applicantId))
  );

  const applicantAbsenceProposalNotification = useSelector(
    (state: RootReducerState) =>
      getContacts(state, myUpcomingNotifications?.map((a) => a.applicantId))
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getName = (value: Contact) => {
    if (value) {
      return value.firstName + ' ' + value.lastName;
    }
    return '';
  };

  const getTitle = (value: AbsenceProposal) => {
    if (value) {
      const from = compactDateWithWeekDayFormatString(value.from);
      const to = compactDateWithWeekDayFormatString(value.to);
      return from === to ? from : from + ' - ' + to;
    }
    return '';
  };

  const getFlex = (from: string, to: string) => {
    return moment(from).isSame(moment(to), 'day');
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(debounceAbsencesMe());
  }, [dispatch]);
  //#endregion

  //#region ------------------------------ Components
  const panel = (
    absenceProposal: AbsenceProposal,
    icon: IconName,
    panelType: PanelType,
    applicant: Contact
  ) => {
    const date = getTitle(absenceProposal);
    const isOneDay = moment(absenceProposal.from).isSame(
      moment(absenceProposal.to),
      'day'
    );

    const generateNote = (date: string) => {
      if (panelType === SUBSTITUTION) {
        return t('dashboard:absencesAndSubstitutions.substitutionPanelNote', {
          name: getName(applicant),
          date,
        });
      }
      if (panelType === NOTIFICATION) {
        if (isOneDay) {
          return t(
            'dashboard:absencesAndSubstitutions.notificationPanelNoteOneDay',
            {
              name: getName(applicant),
              date,
            }
          );
        } else {
          return t(
            'dashboard:absencesAndSubstitutions.notificationPanelNoteMultipleDays',
            {
              name: getName(applicant),
              date,
            }
          );
        }
      }
      return '';
    };

    return (
      <Flex.Row
        childrenGap={theme.old.spacing.unit(2)}
        alignItems="center"
        className={classes.fullWidth}
      >
        <FontAwesomeIcon
          icon={['fal', icon]}
          color={theme.old.palette.primaryColor}
          className={classes.icon}
        />
        <div style={{ width: 'calc(100% - 36px' }} title={generateNote(date)}>
          <Text ellipsis>{generateNote(date)}</Text>
        </div>
      </Flex.Row>
    );
  };

  const summary = (
    absenceProposals: AbsenceProposal[],
    panelType: PanelType,
    iconName: IconName
  ) => {
    const count = absenceProposals?.length;
    const expandPanel = (panelType: PanelType) => {
      panelType === SUBSTITUTION
        ? setIsSubstitutionExpanded(!isSubstitutionExpanded)
        : setIsNotificationExpanded(!isNotificationExpanded);
    };

    return count === 1 ? (
      panel(
        absenceProposals?.[0],
        iconName,
        panelType,
        panelType === SUBSTITUTION
          ? applicantAbsenceProposalSubstitution?.[0]
          : applicantAbsenceProposalNotification?.[0]
      )
    ) : (
      <Flex.Row
        childrenGap={theme.old.spacing.unit(2)}
        alignItems="center"
        className={classes.fullWidth}
      >
        <FontAwesomeIcon
          icon={['fal', iconName]}
          color={theme.old.palette.primaryColor}
          className={classes.icon}
        />
        <Flex.Item flex={1}>
          <Text>
            {panelType === SUBSTITUTION
              ? t('dashboard:absencesAndSubstitutions.summarySubstitutions', {
                  count: count,
                })
              : t('dashboard:absencesAndSubstitutions.summaryNotifications', {
                  count: count,
                })}
          </Text>
        </Flex.Item>
        {count > 1 && (
          <FontAwesomeIcon
            icon={['fal', 'chevron-down']}
            className={classes.chevronIcon}
            onClick={() => expandPanel(panelType)}
          />
        )}
      </Flex.Row>
    );
  };

  const panelGroup = (
    absenceProposals: AbsenceProposal[],
    isPanelgroupExpanded: boolean,
    panelType: PanelType,
    iconName: IconName
  ) => {
    return (
      <Flex.Column style={{ overflow: 'hidden', width: '100%' }}>
        {!isPanelgroupExpanded ? (
          <div className={classes.fullWidth}>
            {absenceProposals?.length === 0 ? (
              <Text
                type="secondary"
                style={{ paddingBottom: '8px', fontSize: 13 }}
              >
                {t(
                  `dashboard:absencesAndSubstitutions.${
                    panelType === SUBSTITUTION
                      ? 'noSubstitutions'
                      : 'noNotifications'
                  }`
                )}
              </Text>
            ) : (
              <>{summary(absenceProposals, panelType, iconName)}</>
            )}
          </div>
        ) : (
          <Flex.Column
            classNames={classNames({
              [classes.marginBottomSubstitutionList]:
                panelType === SUBSTITUTION,
            })}
            style={{ overflow: 'hidden', marginBottom: '8px' }}
          >
            {absenceProposals?.map((absenceProposal, index) => {
              return (
                <>
                  {panel(
                    absenceProposal,
                    iconName,
                    panelType,
                    panelType === SUBSTITUTION
                      ? applicantAbsenceProposalSubstitution[index]
                      : applicantAbsenceProposalNotification[index]
                  )}
                  {index !== absenceProposals.length - 1 && (
                    <Divider
                      className={classNames(classes.spacingDividerPanelgroup, {
                        [classes.marginBottomBig]:
                          index === absenceProposals.length - 1 &&
                          panelType === SUBSTITUTION,
                      })}
                    />
                  )}
                </>
              );
            })}
          </Flex.Column>
        )}
      </Flex.Column>
    );
  };
  // #endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      {isFetching && !absenceOverview ? (
        <Flex.Item flex={1} className={classes.spinner}>
          <PrioSpinner size="large" />
        </Flex.Item>
      ) : (
        <>
          <Flex.Row
            childrenGap={theme.old.spacing.baseSpacing}
            marginBottom={8}
          >
            <Flex.Item>
              <Typography.Title level={3} ellipsis style={{ marginBottom: 0 }}>
                {t('dashboard:absencesAndSubstitutions.title')}
              </Typography.Title>
            </Flex.Item>
            <Flex.Item flex={1}>
              {isLoading ? (
                <PrioSpinner size="small" />
              ) : (
                <FontAwesomeIcon
                  icon={['fal', 'sync']}
                  onClick={() =>
                    queryClient.refetchQueries(['absenceProposalsInvolved'])
                  }
                  className={classes.reloadIcon}
                ></FontAwesomeIcon>
              )}
            </Flex.Item>
            <Link to={'../timeAndLeaveManagement/absences'}>
              <Button
                iconProp={['fal', 'arrow-right-to-bracket']}
                type="default"
                style={{ fontSize: 16 }}
              />
            </Link>
          </Flex.Row>

          {/* ABSENCE */}
          <Flex.Row
            flex={isSubstitutionExpanded || isNotificationExpanded ? 0 : 1}
          >
            <Flex.Column flex={1}>
              <Typography.Title
                level={4}
                ellipsis
                className={classNames({
                  [classes.mutedTitle]:
                    isSubstitutionExpanded || isNotificationExpanded,
                })}
              >
                {t('dashboard:absencesAndSubstitutions.nextAbsence')}
              </Typography.Title>

              <Flex.Item
                className={classNames(classes.transition, {
                  [classes.noHeight]:
                    isSubstitutionExpanded || isNotificationExpanded,
                })}
              >
                {absenceProposals?.length <= 0 ? (
                  <Text type="secondary" style={{ fontSize: 13 }}>
                    {t('dashboard:absencesAndSubstitutions.noAbsences')}
                  </Text>
                ) : (
                  <Flex.Column childrenGap={theme.old.spacing.unit(1)}>
                    <Flex.Row
                      childrenGap={theme.old.spacing.unit(2)}
                      alignItems="center"
                    >
                      <FontAwesomeIcon
                        icon={[
                          'fal',
                          getIconForAbsenceType(
                            absenceProposals?.[0]?.absenceType
                          ),
                        ]}
                        color={theme.old.palette.primaryColor}
                        className={classes.icon}
                      />
                      <Flex.Item
                        flex={
                          getFlex(
                            absenceProposals?.[0]?.from,
                            absenceProposals?.[0]?.to
                          )
                            ? 1
                            : 5
                        }
                      >
                        <Text>{getTitle(absenceProposals?.[0])}</Text>
                      </Flex.Item>
                      <Flex.Item
                        flex={
                          getFlex(
                            absenceProposals?.[0]?.from,
                            absenceProposals?.[0]?.to
                          )
                            ? 2
                            : 3
                        }
                      >
                        {t(
                          `absences:types.${absenceProposals?.[0]?.absenceType}`
                        )}
                      </Flex.Item>
                    </Flex.Row>
                  </Flex.Column>
                )}
              </Flex.Item>
            </Flex.Column>
          </Flex.Row>

          {/* SUBSTITUTIONS */}
          <Flex.Row
            flex={isNotificationExpanded ? 0 : 1}
            className={classes.fullWidth}
          >
            <Flex.Column flex={1} className={classes.fullWidth}>
              <Flex.Row alignItems="center">
                <Flex.Item flex={1}>
                  <Typography.Title
                    level={4}
                    ellipsis
                    className={classNames({
                      [classes.mutedTitle]: isNotificationExpanded,
                    })}
                  >
                    {t('dashboard:absencesAndSubstitutions.nextSubstitution')}
                  </Typography.Title>
                </Flex.Item>
                {isSubstitutionExpanded && (
                  <FontAwesomeIcon
                    icon={['fal', 'chevron-up']}
                    className={classes.chevronIcon}
                    onClick={() =>
                      setIsSubstitutionExpanded(!isSubstitutionExpanded)
                    }
                  />
                )}
                {isNotificationExpanded &&
                  myUpcomingSubstitutions?.length > 1 && (
                    <FontAwesomeIcon
                      icon={['fal', 'chevron-down']}
                      className={classes.chevronIconMuted}
                      onClick={() => {
                        setIsSubstitutionExpanded(!isSubstitutionExpanded);
                        setIsNotificationExpanded(!isNotificationExpanded);
                      }}
                    />
                  )}
              </Flex.Row>
              <Flex.Item
                className={classNames(classes.transition, {
                  [classes.noHeight]: isNotificationExpanded,
                  [classes.maxListHeightSubstitutions]: isSubstitutionExpanded,
                  [classes.marginBottomBig]: isSubstitutionExpanded,
                  [classes.marginBottomSmall]: !isSubstitutionExpanded,
                })}
              >
                {panelGroup(
                  myUpcomingSubstitutions,
                  isSubstitutionExpanded,
                  SUBSTITUTION,
                  'user-group'
                )}
              </Flex.Item>
            </Flex.Column>
          </Flex.Row>

          {/* NOTIFICATIONS */}
          <Flex.Row flex={1}>
            <Flex.Column flex={1} className={classes.fullWidth}>
              <Flex.Row>
                <Flex.Item flex={1}>
                  <Typography.Title
                    level={4}
                    ellipsis
                    className={classNames({
                      [classes.mutedTitle]: isSubstitutionExpanded,
                    })}
                  >
                    {t('dashboard:absencesAndSubstitutions.nextNotification')}
                  </Typography.Title>
                </Flex.Item>
                {isNotificationExpanded && (
                  <FontAwesomeIcon
                    icon={['fal', 'chevron-up']}
                    className={classes.chevronIcon}
                    onClick={() =>
                      setIsNotificationExpanded(!isNotificationExpanded)
                    }
                  />
                )}
                {isSubstitutionExpanded &&
                  myUpcomingNotifications.length > 1 && (
                    <FontAwesomeIcon
                      icon={['fal', 'chevron-down']}
                      className={classes.chevronIconMuted}
                      onClick={() => {
                        setIsSubstitutionExpanded(!isSubstitutionExpanded);
                        setIsNotificationExpanded(!isNotificationExpanded);
                      }}
                    />
                  )}
              </Flex.Row>
              <Flex.Item
                className={classNames(classes.transition, {
                  [classes.noHeight]: isSubstitutionExpanded,
                  [classes.maxListHeightNotifications]: isNotificationExpanded,
                })}
              >
                {panelGroup(
                  myUpcomingNotifications,
                  isNotificationExpanded,
                  NOTIFICATION,
                  'bell'
                )}
              </Flex.Item>
            </Flex.Column>
          </Flex.Row>
        </>
      )}
    </Flex.Column>
  );
};

export default DashboardAbsenceOverviewItem;
