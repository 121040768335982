import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Col, Row } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../../package.json';
import { clearPrioCache, setPrioVersion } from '../../../actions';
import history from '../../../apps/main/history';
import { rowGutter } from '../../../util/forms';
import { fetchContactsOffset } from '../../contacts/actions';
import { fetchCompanies } from '../../companies/actions';
import {
  getCompaniesIsFetching,
  getContactsIsFetching,
} from '../../../apps/main/rootReducer';
import { makePrioStyles } from '../../../theme/utils';

interface DataRefreshFormProps {}
const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
}));

export const DataRefreshForm: React.FC<DataRefreshFormProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isContacsFetching = useSelector(getContactsIsFetching);
  const isCompaniesFetching = useSelector(getCompaniesIsFetching);

  const handleResetCache = () => {
    dispatch(clearPrioCache());
    dispatch(setPrioVersion(packageJson.version));
    setTimeout(() => {
      history.replace('/module');
      window.location.reload();
    }, 2500);
  };

  const handleContactsReload = () => {
    dispatch(fetchContactsOffset());
    dispatch(fetchCompanies());
  };

  const handleDownloadPrioDesktop = () => {
    window.open(
      window.location.protocol +
        '//' +
        window.location.host +
        '/desktop/prio365-desktop.exe'
    );
  };

  return (
    <Form layout="vertical" className={classes.root}>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item label={t('zendesk:refreshPrioForm.labels.all')}>
            <Button
              id={'prio-refresh-button'}
              className={classes.fullWidth}
              onClick={() => handleResetCache()}
            >
              {t('zendesk:resetCache')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item label={t('zendesk:refreshPrioForm.labels.contacts')}>
            <Button
              className={classes.fullWidth}
              loading={isContacsFetching || isCompaniesFetching}
              disabled={isContacsFetching || isCompaniesFetching}
              onClick={handleContactsReload}
            >
              {t('contacts:reload')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item label={t('zendesk:refreshPrioForm.labels.prioDesktop')}>
            <Button
              className={classes.fullWidth}
              onClick={handleDownloadPrioDesktop}
            >
              {t('zendesk:refreshPrioForm.labels.prioDesktopDownload')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DataRefreshForm;
