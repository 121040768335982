import { ApiResult, apiUrl } from '../../../api';
import { Bucket, CreatePlannerRequest } from '../../../models/Planner';
import { Project } from '../../../models/Project';
import {
  CreateJiraTask,
  CreateTaskRequest,
  JiraIssue,
  JiraIssueType,
  JiraProject,
  JiraUser,
  MessageTask,
  TaskDetail,
} from '../../../models/Task';
import { ProjectId } from '../../../models/Types';
import { getAccessToken } from '../../../store/authEffect';
import {
  CreateTodoTaskRequest,
  TodoList,
  TodoTask,
} from '../../../models/Todo';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiCreateTask: (
  projectId: ProjectId,
  task: CreateTaskRequest
) => Promise<ApiResult<void>> = async (projectId, task) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/planner/masterPlan/createTask`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(task),
    }
  );
  return {
    result,
  };
};

export const apiCreatePlanner: (
  projectId: ProjectId,
  planner: CreatePlannerRequest
) => Promise<ApiResult<Project>> = async (projectId, task) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/Planner/createPlan`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(task),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchBucketsByProjectId: (
  projectId: ProjectId
) => Promise<ApiResult<Bucket[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/planner/bucket`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTodoLists: () => Promise<
  ApiResult<TodoList[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/project/todo/lists`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateTodoTask: (
  todoListId: string,
  todoTask: CreateTodoTaskRequest
) => Promise<ApiResult<TodoTask>> = async (
  todoListId: string,
  todoTask: CreateTodoTaskRequest
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/todo/lists/${todoListId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(todoTask),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

//optional projectId

export const apiFetchJiraUsers: (
  projectId?: string
) => Promise<ApiResult<JiraUser[]>> = async (projectId: string) => {
  const result = await fetchWithRetry(
    `${apiUrl}/atlassian/Jira/users${
      projectId ? `?projectId=${projectId}` : ''
    }`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchJiraIssueTypes: () => Promise<
  ApiResult<JiraIssueType[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/atlassian/Jira/issuetype`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateJiraTask: (
  jiraTask: CreateJiraTask
) => Promise<ApiResult<TodoTask>> = async (jiraTask: CreateJiraTask) => {
  const result = await fetchWithRetry(`${apiUrl}/atlassian/jira/issue`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(jiraTask),
  });

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchJiraProjectById: (
  jiraProjectId: string
) => Promise<ApiResult<JiraProject>> = async (jiraProjectId: string) => {
  const result = await fetchWithRetry(
    `${apiUrl}/atlassian/Jira/project/${jiraProjectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTasksForAMessage: (
  projectId: string,
  messageId: string
) => Promise<ApiResult<MessageTask[]>> = async (
  projectId: string,
  messageId: string
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/${projectId}/message/${messageId}/tasks`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTaskDdetails: (
  projectId: ProjectId,
  tasks: MessageTask[]
) => Promise<ApiResult<TaskDetail[]>> = async (projectId, tasks) => {
  const result = await fetchWithRetry(
    `${apiUrl}/email/Email/${projectId}/taskDetails`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(tasks),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchJiraIssueById: (
  jiraIssueId: string
) => Promise<ApiResult<JiraIssue>> = async (jiraIssueId: string) => {
  const result = await fetchWithRetry(
    `${apiUrl}/atlassian/Jira/issue/${jiraIssueId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
