import React from 'react';
import { Contact } from '../../../models/Contact';
import { ContactId } from '../../../models/Types';

export interface ContactsContextProps {
  contacts: Contact[];
  getContactById: (id: ContactId) => Contact | undefined;
  getContactByProperty: (
    property: keyof Contact,
    value: string
  ) => Contact | undefined;
}

const INITIAL_CONTACTS_CONTEXT_VALUE: ContactsContextProps = {
  contacts: [],
  getContactById: () => undefined,
  getContactByProperty: () => undefined,
};

export const ContactsContext = React.createContext<ContactsContextProps>(
  INITIAL_CONTACTS_CONTEXT_VALUE
);
