import React, { useState } from 'react';
import {
  AbsenceOverview,
  AbsenceProposal,
} from '../../../models/AbsenceProposal';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { AbsenceProposalTable } from './AbsenceProposalTable';
import { isInPast } from '../../../util';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';
import UpdateAbsenceProposal from './UpdateAbsenceProposal';
import classNames from 'classnames';
import { AbsenceOverviewBarChart } from './overview/AbsenceOverviewBarChart';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
  },
  fullHeight: {
    '& .ant-drawer-body': {
      ' & > div': {
        height: '100%',
      },
    },
  },
  table: {
    marginTop: theme.old.spacing.unit(3),
    flex: 1,
  },
  toggleHistoryButton: {
    marginTop: theme.old.spacing.unit(2),
  },
}));

interface MyAbsencesOverviewProps {
  className?: string;
  absenceOverview: AbsenceOverview;
  onAbsenceProposalSelect?: (absenceProposal: AbsenceProposal) => void;
  canSelfSelectAbsence?: boolean;
}

export const MyAbsencesOverview: React.FC<MyAbsencesOverviewProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    absenceOverview,
    canSelfSelectAbsence,
    onAbsenceProposalSelect,
  } = props;
  const {
    absenceOverview: { absenceProposals },
  } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [showHistoricRequests, setShowHistoricRequests] =
    useState<boolean>(false);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [absenceProposal, setAbsenceProposal] = useState<AbsenceProposal>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const toggleHistory = () => setShowHistoricRequests(!showHistoricRequests);

  const handleOnAbsenceProposalSelect = (absenceProposal: AbsenceProposal) => {
    if (canSelfSelectAbsence) {
      setAbsenceProposal(absenceProposal);
      setDrawerOpen(true);
    }
    if (onAbsenceProposalSelect) {
      onAbsenceProposalSelect(absenceProposal);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <AbsenceOverviewBarChart absenceOverview={absenceOverview} />
      <Button
        type="link"
        className={classes.toggleHistoryButton}
        onClick={toggleHistory}
      >
        {showHistoricRequests
          ? t('absences:absenceOverview.hideHistory')
          : t('absences:absenceOverview.showHistory')}
      </Button>
      <AbsenceProposalTable
        absenceProposals={(absenceProposals ?? [])
          ?.filter(function (obj) {
            if (!showHistoricRequests) {
              return obj.absenceState !== 'revokeAccepted' && !isInPast(obj.to);
            }
            return obj.absenceState !== 'revokeAccepted';
          })
          .sort((a, b) => {
            if (showHistoricRequests) {
              return Date.parse(b.from) - Date.parse(a.from);
            }
            return Date.parse(a.from) - Date.parse(b.from);
          })}
        className={classes.table}
        onRowClick={handleOnAbsenceProposalSelect}
        tableId="prio-my-absences"
        hasMenuButton
      />
      {canSelfSelectAbsence && (
        <Drawer
          width={theme.old.components.drawerWidth}
          closable={true}
          onClose={() => setDrawerOpen(false)}
          visible={drawerOpen}
          closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
          destroyOnClose={true}
          className={classes.fullHeight}
        >
          <UpdateAbsenceProposal
            absenceOverview={absenceOverview}
            absenceProposal={absenceProposal}
            onFinish={() => setDrawerOpen(false)}
            setDrawerOpen={setDrawerOpen}
          />
        </Drawer>
      )}
    </Flex.Column>
  );
};

export default MyAbsencesOverview;
