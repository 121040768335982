import {
  actionChannel,
  all,
  call,
  cancelled,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {
  getActiveMessageId,
  getActiveProjectInMessageCenter,
  getMessage,
  getMovingMessages,
  getProjectsSpecialMailFoldersState,
  getSpecialMailFolders,
  isLoggedIn,
  isMailDeletedFolder,
} from '../../../apps/main/rootReducer';
import { Message, MessageCenterMessage } from '../../../models/Message';
import { MessageId, ProjectId } from '../../../models/Types';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import { setUnreadItemCount } from '../actions/actionControllers/mailFoldersController';
import { softdeleteMessages } from '../actions/actionControllers/messageActionController';
import {
  DeleteMessagesSagaAction,
  SAGA_DELETE_MESSAGES,
} from '../actions/sagas';
import { deleteMessages } from '../actions/actionControllers/messageActionController';
import { SpecialMailFolders } from '../actions/types';
import { SpecialMailFolderByProjectId } from '../reducers/projects/specialMailFolder';
import { combinedMessageSelector } from '../hooks/useMailSearch';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import { notification } from 'antd';
import i18n from '../../../i18n';
import { SAGA_REBUILD } from '../../../util/sagas';
import { putRunDebounceSaga } from '../../../sagas/watchDebouncedCalls';

const findNextIndex = (
  index: number,
  selectedMessages: Message[],
  mailList: Message[],
  lastIndex: number
) => {
  if (index >= lastIndex) {
    return lastIndex;
  }
  if (
    !selectedMessages.map((message) => message.id).includes(mailList[index].id)
  ) {
    return index;
  }
  return findNextIndex(index + 1, selectedMessages, mailList, lastIndex);
};

const findPreviousIndex = (
  index: number,
  selectedMessages: Message[],
  mailList: Message[]
) => {
  if (index <= 0) {
    return 0;
  }
  if (
    !selectedMessages.map((message) => message.id).includes(mailList[index].id)
  ) {
    return index;
  }
  return findPreviousIndex(index - 1, selectedMessages, mailList);
};

function* handleDeleteMessages(action: DeleteMessagesSagaAction) {
  const {
    projectId,
    mailFolderId,
    messages: selectedMessages,
    navigateProps,
    onAfter,
    hardDelete,
  } = action;
  try {
    const activeMessageId = yield select((state) =>
      getActiveMessageId(state, projectId)
    );
    const activeMessage = yield select((state) =>
      getMessage(state, projectId, activeMessageId)
    );
    const mailList: Message[] = yield select(
      combinedMessageSelector(false, projectId, mailFolderId)
    );

    const movingMessageIds: MessageId[] = yield select(getMovingMessages);

    const messages: Message[] = (
      selectedMessages.length > 0 ? selectedMessages : [activeMessage]
    ).filter((message) => !!message && !movingMessageIds.includes(message.id));

    if (messages.length > 0) {
      if (projectId === 'favorites') {
        const projectsSpecialMailFolders: SpecialMailFolderByProjectId =
          yield select(getProjectsSpecialMailFoldersState);
        const activeProjectIdInMessageCenter = yield select(
          getActiveProjectInMessageCenter
        );
        const projectIdCluster = (messages as MessageCenterMessage[]).reduce<{
          [projectId: ProjectId]: Message[];
        }>(
          (cluster, message) => ({
            ...cluster,
            [message.projectId ?? activeProjectIdInMessageCenter]: [
              ...(cluster[
                message.projectId ?? activeProjectIdInMessageCenter
              ] ?? []),
              message,
            ],
          }),
          {}
        );
        yield all(
          Object.keys(projectIdCluster).map((id) =>
            put(
              putRunDebounceSaga(
                softdeleteMessages(
                  id,
                  (projectIdCluster[id] ?? [])
                    ?.filter((message) => !!message)
                    ?.map((message) => message.id),
                  projectIdCluster[id] ?? [],
                  'inbox'
                )
              )
            )
          )
        );
        if (projectsSpecialMailFolders) {
          yield all(
            Object.keys(projectIdCluster).map((id) =>
              put(
                setUnreadItemCount(
                  id,
                  projectsSpecialMailFolders?.[id]?.inboxFolder?.id,
                  (projectIdCluster[id] ?? []).filter(
                    (message) => !message?.isRead
                  ).length,
                  'remove'
                )
              )
            )
          );
        }
      } else {
        const isDeletedFolder: boolean = yield select((state) =>
          isMailDeletedFolder(state, projectId, mailFolderId)
        );
        const specialMailFolders: SpecialMailFolders = yield select((state) =>
          getSpecialMailFolders(state, projectId)
        );
        if (hardDelete || isDeletedFolder) {
          yield put(
            putRunDebounceSaga(
              deleteMessages(
                projectId,
                mailFolderId,
                messages.map((message) => message.id)
              )
            )
          );
        } else {
          yield put(
            putRunDebounceSaga(
              softdeleteMessages(
                projectId,
                messages.map((message) => message.id),
                messages,
                mailFolderId
              )
            )
          );
        }
        yield put(
          setUnreadItemCount(
            projectId,
            mailFolderId === 'inbox'
              ? specialMailFolders?.inboxFolder?.id ?? mailFolderId
              : mailFolderId,
            messages.filter((message) => !message.isRead).length,
            'remove'
          )
        );
      }

      if (messages.map((message) => message.id).includes(activeMessageId)) {
        const mailListAfterDelete: Message[] = yield select(
          combinedMessageSelector(false, projectId, mailFolderId)
        );

        const activeMessageIdIndex = mailList.findIndex(
          (message) => message.id === activeMessageId
        );
        if (activeMessageIdIndex > -1 && mailListAfterDelete.length > 0) {
          const lastIndex = findPreviousIndex(
            mailList.length - 1,
            messages,
            mailList
          );
          const index = findNextIndex(
            activeMessageIdIndex,
            messages,
            mailList,
            lastIndex
          );

          yield put(setMailListNavigationState(mailList[index].id, projectId));
          if (navigateProps) {
            const { navigate, prefix } = navigateProps;
            navigate(
              `../${prefix ? `${prefix}/` : ''}${mailFolderId}/message/${
                mailList[index].id
              }/details`
            );
          }
        } else {
          yield put(setMailListNavigationState(null, projectId));
          if (navigateProps) {
            const { navigate, prefix } = navigateProps;
            navigate(`../${prefix ? `${prefix}/` : ''}${mailFolderId}`);
          }
        }
        if (onAfter) {
          onAfter();
        }
      }
    }
  } catch (error) {
    console.error('Error in message saga deleting - Original Error', error);
    console.error(
      'Error in message saga deleting - Data',
      `projectId: ${projectId},`,
      `mailFolderId: ${mailFolderId},`,
      `selectedMessages: ${selectedMessages},`
    );
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.messages.deleteMessagesError'),
    });
  }
}

function* actionPipeline() {
  let lastAction: DeleteMessagesSagaAction = null;
  try {
    const channel = yield actionChannel(SAGA_DELETE_MESSAGES);
    while (true) {
      const payload = yield take(channel);
      try {
        lastAction = payload;
        yield call(handleDeleteMessages, payload);
        lastAction = null;
      } catch (error) {
        console.error('Error in watchDeleteMessages - actionPipeline', error);
        notification.open({
          message: i18n.t('common:error'),
          description: i18n.t(
            'mail:errorMessages.messages.deleteMessagesError'
          ),
        });
      }
    }
  } finally {
    if (yield cancelled() && lastAction !== null) {
      yield call(handleDeleteMessages, lastAction);
      lastAction = null;
    }
  }
}

function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(actionPipeline), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in watchDeleteMessages - mainTask', error);
    notification.open({
      message: i18n.t('common:error'),
      description: i18n.t('mail:errorMessages.messages.deleteMessagesError'),
    });
    yield mainTask();
  }
}

export default function* watchDeleteMessages() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
