import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Input, notification, Select } from 'antd';
import { Button, Pill } from '@prio365/prio365-react-library';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { apiCreateContactTag } from '../api';
import { ContactTag } from '../../../models/Contact';
import { useDispatch } from 'react-redux';
import {
  assignContactTagToContactOrCompany,
  unassignContactTagFromContactOrCompany,
} from '../actions';
import useContactTags from '../hooks/useContactTags';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  selectIcon: {
    paddingLeft: '10px ',
    marginRight: '-10px',
  },
  select: {
    width: '100%',
    '& .ant-select-selection-item': {
      backgroundColor: '  #eef3fa',
      color: '#42526e',
      fontSize: '12px',
      marginInline: '8px',
    },
  },
  categoryButton: {
    cursor: 'pointer',
  },
}));

interface ContactTagsProps {
  className?: string;
  maxCount?: number | 'responsive';
  contactId?: string;
  companyId?: string;
  contactTagDtos?: ContactTag[];
}

export const ContactTags: React.FC<ContactTagsProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { className, maxCount, contactId, companyId, contactTagDtos } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { selectableTags, fetchTags } = useContactTags();

  const [newTagName, setNewTagName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isCreatingTag, setIsCreatingTag] = useState<boolean>(false);

  const selectedTags = useMemo(() => {
    return contactTagDtos?.sort((a, b) => a.name.localeCompare(b.name)) || [];
  }, [contactTagDtos]);

  const selectedTagIds = useMemo(
    () => selectedTags?.map((tag) => tag.contactTagId) || [],
    [selectedTags]
  );

  const tags = useMemo(() => {
    const remaining = selectableTags
      .filter((tag) => !selectedTagIds.includes(tag.contactTagId))
      .sort((a, b) => a.name.localeCompare(b.name));
    return [...selectedTags, ...remaining];
  }, [selectableTags, selectedTagIds, selectedTags]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const createTag = async () => {
    setIsCreatingTag(true);
    const { data } = await apiCreateContactTag(newTagName);
    if (data) {
      setNewTagName('');
      fetchTags();
      dispatch(assignContactTagToContactOrCompany(contactId, companyId, data));
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'documents:documentTagPicker.errorMessages.createTagError'
        ),
      });
    }
    setIsCreatingTag(false);
  };

  const onNewTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagName(event.target.value);
  };

  const handleSelect = async (id: string) => {
    const tag = selectableTags.find((t) => t.contactTagId === id);
    dispatch(assignContactTagToContactOrCompany(contactId, companyId, tag));
  };

  const handleDeselect = async (id: string) => {
    const tag = selectableTags.find((t) => t.contactTagId === id);
    dispatch(unassignContactTagFromContactOrCompany(contactId, companyId, tag));
  };

  const handleDropdownVisibleChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Row alignItems="center" childrenGap={20}>
        <div
          className={classes.categoryButton}
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          <FontAwesomeIcon
            icon={['fal', 'plus-circle']}
            className={classes.selectIcon}
            color="#42526E"
          />
        </div>
        <Select
          mode={'multiple'}
          bordered={false}
          tagRender={(props) => (
            <Pill
              children={props.label}
              style={{ marginInline: '8px' }}
              size="small"
            />
          )}
          className={classes.select}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          value={selectedTagIds}
          open={open}
          maxTagCount={maxCount}
          dropdownMatchSelectWidth={500}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          optionFilterProp="label"
          options={tags.map((tag) => ({
            value: tag.contactTagId,
            label: tag.name,
          }))}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <>
                <Divider style={{ margin: '4px 0' }} />
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    padding: 8,
                  }}
                >
                  <Input
                    style={{ flex: 'auto', marginRight: '8px' }}
                    value={newTagName}
                    onChange={onNewTagNameChange}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === 'Enter') {
                        createTag();
                      }
                    }}
                  />
                  <Button
                    disabled={isCreatingTag || newTagName.trim().length === 0}
                    loading={isCreatingTag}
                    onClick={(e) => {
                      e.stopPropagation();
                      createTag();
                    }}
                    iconProp={['fal', 'check']}
                    tooltip={t('documents:documentTagPicker.addAction')}
                  />
                </div>
              </>
            </div>
          )}
        />
      </Flex.Row>
    </div>
  );
};

export default ContactTags;
