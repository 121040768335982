import React from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Collapse, List, Typography } from 'antd';
import { Attendee, CalendarEvent } from '../../../models/Calendar';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';

const listItemHeight = 64;

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflow: 'auto',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listItem: {
    paddingLeft: theme.old.spacing.defaultPadding,
    paddingRight: theme.old.spacing.defaultPadding,
    paddingTop: theme.old.spacing.unit(2),
    '&:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.content,
      cursor: 'pointer',
    },
    '& .ant-list-vertical .ant-list-item-meta': {
      marginBottom: 0,
    },
    height: listItemHeight,
    overflow: 'hidden',
    position: 'relative',
  },
  responseStatus: {
    width: 4,
    backgroundColor: theme.old.palette.chromaticPalette.grey,
    position: 'absolute',
    top: 2,
    bottom: 2,
    left: 0,
  },
  listItemMeta: {
    marginTop: theme.old.spacing.unit(2),
    '& .ant-list-item-meta-title': {
      marginBottom: 0,
      fontSize: theme.old.typography.fontSize.base,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .ant-list-item-meta': {
      marginBottom: 0,
    },
    '& .ant-list-item-meta-content': {
      marginBottom: 0,
    },
  },
  row: {
    height: '100%',
  },
  collapse: {
    flex: 1,
    '&.ant-collapse': {
      border: 'none',
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
    },
    '&.ant-collapse > .ant-collapse-item': {
      border: 'none',
    },
    '& .ant-collapse-content-box': {
      padding: 0,
    },
    '& .ant-collapse-content': {
      border: 'none',
    },
  },
  mailAddress: {
    fontSize: theme.old.typography.fontSize.label,
    color: theme.old.typography.colors.muted,
  },
}));

const mapStatusToColor: (
  status:
    | 'None'
    | 'Organizer'
    | 'TentativelyAccepted'
    | 'Accepted'
    | 'Declined'
    | 'NotResponded',
  theme: PrioTheme
) => string = (status, theme) => {
  switch (status) {
    case 'Accepted':
      return theme.old.palette.chromaticPalette.green;
    case 'Declined':
      return theme.old.palette.chromaticPalette.red;
    case 'TentativelyAccepted':
      return theme.old.palette.chromaticPalette.yellow;
    default:
      return theme.old.palette.chromaticPalette.grey;
  }
};

interface EventDetailsProps {
  className?: string;
  event: CalendarEvent;
}

export const EventDetails: React.FC<EventDetailsProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { className, event } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ Defaults
  const requiredAttendees =
    event?.attendees?.filter(
      (attendee) =>
        attendee.type === 'Required' &&
        attendee.emailAddress.address !==
          event?.organizer?.emailAddress?.address
    ) ?? [];
  const optionalAttendees =
    event?.attendees?.filter(
      (attendee) =>
        attendee.type === 'Optional' &&
        attendee.emailAddress.address !==
          event?.organizer?.emailAddress?.address
    ) ?? [];
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      {event?.organizer && (
        <Flex.Column>
          <Typography.Title level={4} className={classes.title}>
            {t('calendar:eventOverview.organizer')}
          </Typography.Title>
          <Typography.Text>
            {event.organizer.emailAddress.name ??
              event.organizer.emailAddress.address}
          </Typography.Text>
        </Flex.Column>
      )}
      {event?.attendees && (
        <Collapse className={classes.collapse}>
          {requiredAttendees.length > 0 && (
            <Collapse.Panel
              key="required"
              header={
                <Typography.Title level={4} className={classes.title}>
                  {t('calendar:eventOverview.attendee.type.required')}
                </Typography.Title>
              }
            >
              <AttendeeList attendees={requiredAttendees} />
            </Collapse.Panel>
          )}
          {optionalAttendees.length > 0 && (
            <Collapse.Panel
              key="optional"
              header={
                <Typography.Title level={4} className={classes.title}>
                  {t('calendar:eventOverview.attendee.type.optional')}
                </Typography.Title>
              }
            >
              <AttendeeList attendees={optionalAttendees} />
            </Collapse.Panel>
          )}
        </Collapse>
      )}
    </Flex.Column>
  );
};

export default EventDetails;

interface AttendeeListProps {
  attendees: Attendee[];
}

const AttendeeList: React.FC<AttendeeListProps> = (props) => {
  //#region ------------------------------ Defaults
  const { attendees } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={attendees}
      renderItem={(attendee, index) => (
        <List.Item key={index} className={classes.listItem}>
          <Flex.Row className={classes.row} alignItems={'center'}>
            <div
              className={classes.responseStatus}
              style={{
                backgroundColor: mapStatusToColor(
                  attendee.status.response,
                  theme
                ),
              }}
            />
            <List.Item.Meta
              className={classes.listItemMeta}
              title={
                <>
                  {attendee.emailAddress.name ?? attendee.emailAddress.address}
                  {attendee.emailAddress.name && (
                    <span
                      className={classes.mailAddress}
                    >{` (${attendee.emailAddress.address})`}</span>
                  )}
                </>
              }
              description={t(
                `calendar:eventOverview.attendee.status.${attendee.status.response.toLowerCase()}`
              )}
            />
          </Flex.Row>
        </List.Item>
      )}
    ></List>
  );
};
