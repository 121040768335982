import { PRIO } from '../../../constants';
import { ReduxAction } from '../../../models/Redux';
import {
  AbsenceProposalId,
  TimeKeepingDayId,
  TimeRecordId,
} from '../../../models/Types';
import * as fromReducer from '../reducers/drawer';

export const OPEN_TIME_AND_LEAVE_MANAGEMENT_DRAWER =
  PRIO + 'OPEN_TIME_AND_LEAVE_MANAGEMENT_DRAWER';

export const openTimeAndLeaveManagementDrawer: (value?: {
  tab?: fromReducer.TimeAndLeaveDrawerTab;
  selectedTimeRange?: fromReducer.TimeAndLeaveTimeRange;
  selectedId?: AbsenceProposalId | TimeRecordId | TimeKeepingDayId;
}) => ReduxAction<Partial<fromReducer.TimeAndLeaveDrawerState>> = (value) => ({
  type: OPEN_TIME_AND_LEAVE_MANAGEMENT_DRAWER,
  meta: value ?? {},
});

export const CLOSE_TIME_AND_LEAVE_MANAGEMENT_DRAWER =
  PRIO + 'CLOSE_TIME_AND_LEAVE_MANAGEMENT_DRAWER';

export const closeTimeAndLeaveManagementDrawer: (
  createdObjectType?: fromReducer.CreatedObjectType
) => ReduxAction<fromReducer.CreatedObjectType> = (createdObjectType) => ({
  type: CLOSE_TIME_AND_LEAVE_MANAGEMENT_DRAWER,
  meta: createdObjectType,
});

export const SET_TIME_AND_LEAVE_MANAGEMENT_DRAWER =
  PRIO + 'SET_TIME_AND_LEAVE_MANAGEMENT_DRAWER';

export const setTimeAndLeaveManagementDrawer: (
  value: Partial<fromReducer.TimeAndLeaveDrawerState>
) => ReduxAction<Partial<fromReducer.TimeAndLeaveDrawerState>> = (value) => ({
  type: SET_TIME_AND_LEAVE_MANAGEMENT_DRAWER,
  meta: value,
});
