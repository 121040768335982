import { Moment } from 'moment-timezone';
import { TimeRecord, TimeRecordEntry } from '../../../models/TimeRecord';
import moment from 'moment';

export const sumUpDurationInMinutes = (timeRecordEntries: TimeRecordEntry[]) =>
  timeRecordEntries.reduce(
    (sum, entry) =>
      sum +
      Math.round(
        (Date.parse(entry.endTime) - Date.parse(entry.startTime)) / 60000
      ),
    0
  );

export const sumUpDurationInMinutesOfDay = (timeRecords: TimeRecord[]) =>
  timeRecords.reduce((sum, entry) => sum + entry.durationInMinutes, 0);

export const calculateMinutes = (time: Moment) => {
  return time.hours() * 60 + time.minutes();
};

export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const calculateRemainingQuarterSteps = (
  fromTimeInHours: number,
  pickedQuarterMinute: number
) => {
  const remainingMinutes =
    dayInMinutes - fromTimeInHours * 60 - pickedQuarterMinute - 15;

  const quarters: any[][] = [];
  var hourCount = 0;
  var quarterStep = 15;

  range(15, remainingMinutes, 15).forEach((minutes) => {
    if (minutes % 60 === 0) {
      hourCount++;
      quarterStep = 15;
      quarters.push([`${hourCount}:00`, minutes]);
    } else {
      quarters.push([`${hourCount}:${quarterStep}`, minutes]);
      quarterStep = quarterStep + 15;
    }
  });
  return quarters;
};

export const calculateRelativeDateRange = (relativeValue: string) => {
  const regex = /([ltn])(\d*)([mywdqhil])/;
  const match = relativeValue.match(regex);

  if (!match) return null;

  const type = match[1];
  const amount = match[2] ? parseInt(match[2], 10) : 1;
  const unit = match[3];

  const range: {
    from: moment.Moment | null;
    to: moment.Moment | null;
  } = { from: null, to: null };

  if (type === 't') {
    switch (unit) {
      case 'y':
        range.from = moment().utc().startOf('year');
        range.to = moment().utc().endOf('year');
        break;
      case 'q':
        range.from = moment().utc().startOf('quarter');
        range.to = moment().utc().endOf('quarter');
        break;
      case 'm':
        range.from = moment().utc().startOf('month');
        range.to = moment().utc().endOf('month');
        break;
      case 'w':
        range.from = moment().utc().startOf('isoWeek');
        range.to = moment().utc().endOf('isoWeek');
        break;
      case 'd':
        range.from = moment().utc().startOf('day');
        range.to = moment().utc().endOf('day');
        break;
      default:
        break;
    }
  } else if (type === 'l') {
    switch (unit) {
      case 'y':
        range.from = moment().utc().subtract(amount, 'years').startOf('year');
        range.to = moment().utc().subtract(1, 'years').endOf('year');
        break;
      case 'q':
        range.from = moment()
          .utc()
          .subtract(amount, 'quarters')
          .startOf('quarter');
        range.to = moment().utc().subtract(1, 'quarters').endOf('quarter');
        break;
      case 'm':
        range.from = moment().utc().subtract(amount, 'months').startOf('month');
        range.to = moment().utc().subtract(1, 'months').endOf('month');
        break;
      case 'w':
        range.from = moment()
          .utc()
          .subtract(amount, 'weeks')
          .startOf('isoWeek');
        range.to = moment().utc().subtract(1, 'weeks').endOf('isoWeek');
        break;
      case 'd':
        range.from = moment().utc().subtract(amount, 'days').startOf('day');
        range.to = moment().utc().subtract(1, 'days').endOf('day');
        break;
      default:
        break;
    }
  } else if (type === 'n') {
    switch (unit) {
      case 'y':
        range.from = moment().utc().startOf('year').add(1, 'year');
        range.to = moment().utc().add(amount, 'years').endOf('year');
        break;
      case 'q':
        range.from = moment().utc().startOf('quarter').add(1, 'quarter');
        range.to = moment().utc().add(amount, 'quarters').endOf('quarter');
        break;
      case 'm':
        range.from = moment().utc().startOf('month').add(1, 'month');
        range.to = moment().utc().add(amount, 'months').endOf('month');
        break;
      case 'w':
        range.from = moment().utc().startOf('isoWeek').add(1, 'week');
        range.to = moment().utc().add(amount, 'weeks').endOf('isoWeek');
        break;
      case 'd':
        range.from = moment().utc().startOf('day').add(1, 'day');
        range.to = moment().utc().add(amount, 'days').endOf('day');
        break;
      default:
        break;
    }
  }
  return range;
};

export const dayInMinutes = 1440;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const PRETTY_DATE_FORMAT = 'D MMM YYYY';
