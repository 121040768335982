import { MutableRefObject, useEffect } from 'react';

import { useFormikContext } from 'formik';

import moment from 'moment';

function isObject(item: any): boolean {
  return (
    item && typeof item === 'object' && !Array.isArray(item) && item !== null
  );
}

function getChangedField<T extends object>(
  values: T,
  initialValues: T,
  parentKey: string = '',
  isNested: boolean = false
): Array<[string, any]> {
  let changes: Array<[string, any]> = [];

  if (
    values === null ||
    values === undefined ||
    initialValues === null ||
    initialValues === undefined
  )
    return [];

  const allKeys = new Set([
    ...Object.keys(values),
    ...Object.keys(initialValues),
  ]);

  //@ts-ignore
  for (const key of allKeys) {
    const fullKey = isNested ? `${parentKey}.${key}` : key;

    // Check if the property exists in initialValues
    if (!(key in initialValues)) {
      changes.push([fullKey, values[key]]);
      continue;
    }

    if (
      (values[key] === null || values[key] === undefined) &&
      initialValues[key] !== null
    ) {
      changes.push([fullKey, null]);
      continue;
    }

    // Special handling for moments
    if (moment.isMoment(values[key]) || moment.isMoment(initialValues[key])) {
      if (
        !moment.isMoment(values[key]) ||
        !moment.isMoment(initialValues[key]) ||
        !values[key].isSame(initialValues[key])
      ) {
        changes.push([fullKey, values[key]]);
      }
      continue;
    }

    // If it's an object, dive deeper to find changes
    if (isObject(values[key])) {
      const nestedChanges = getChangedField(
        values[key],
        initialValues[key],
        fullKey,
        true
      );

      changes = changes.concat(nestedChanges);
      continue;
    }

    if (values[key] !== initialValues[key]) {
      changes.push([fullKey, values[key]]);
    }
  }

  return changes;
}

interface FormObserverProps<T> {
  onChange: (values: T, changedValue: [keyof T, any]) => void;
  prevValuesRef: MutableRefObject<T>;
}

export const FormObserver = <T extends {} = any>({
  onChange,
  prevValuesRef,
}: FormObserverProps<T>) => {
  const { values } = useFormikContext<T>();

  useEffect(() => {
    const changes = getChangedField(values, prevValuesRef?.current);
    if (changes.length > 0) {
      if (changes.length === 1) {
        // Single change
        const [fullKey, value] = changes[0];
        const lastKey = fullKey.split('.').pop() || fullKey; // Extract the last part of the key

        onChange(values, [lastKey as keyof T, value]);
      } else {
        // Multiple changes, group by parent key
        const groupedChanges: { [key: string]: any } = {};
        changes.forEach(([fullKey, value]) => {
          const lastKey = fullKey.split('.').pop() || fullKey; // Extract the last part of the key
          groupedChanges[lastKey] = value;
        });

        Object.entries(groupedChanges).forEach(([key, value]) => {
          onChange(values, [key as keyof T, value]);
        });
      }

      prevValuesRef.current = values; // Update the ref after calling the onChange
    }
  }, [onChange, prevValuesRef, values]);

  return null;
};
