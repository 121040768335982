import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { Typography } from 'antd';
import { Button, PrioSpinner } from '@prio365/prio365-react-library';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../theme/types';
import { useTheme } from 'react-jss';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  confetti: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    animation: '$bang 750ms ease-out forwards',
  },
  welcomeText: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    animation: '$fade_in 250ms ease forwards 500ms',
    opacity: 0,
  },
  armContainer: {
    width: 45,
    position: 'relative',
  },
  arm: {
    position: 'relative',
    height: 88,
    transformOrigin: 'calc(100% - 8px) 100%',
    transform: 'rotate(2deg)',
    animation: '$wiggle 2s infinite',
  },
  hand: {
    position: 'absolute',
    right: -4,
    bottom: 38,
    height: 24,
    width: 24,
    borderRadius: '50%',
    backgroundColor: theme.old.palette.backgroundPalette.base,
    '&::after': {
      content: '""',
      position: 'absolute',
      height: 24,
      width: 16,
      borderRadius: 6,
      top: -12,
      left: 3,
      backgroundColor: theme.old.palette.backgroundPalette.content,
    },
  },
  upperArm: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 16,
    height: 40,
    backgroundColor: theme.old.palette.backgroundPalette.base,
    borderRadius: 6,
  },
  crawl: {
    animation: '$crawl 0.1s',
    animationIterationCount: 'infinite',
  },
  shake: {
    animation: '$shake 0.5s',
    animationIterationCount: 'infinite',
  },
  '@keyframes bang': {
    '0%': {
      opacity: 0,
      transform: 'translate3d(0,0,0)',
    },
    '10%': {
      opacity: 1,
    },
    '75%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@keyframes fade_in': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes wiggle': {
    '0%': {
      transform: 'rotate(1deg)',
    },
    '50%': {
      transform: 'rotate(-1deg)',
    },
    '100%': {
      transform: 'rotate(1deg)',
    },
  },
  '@keyframes crawl': {
    '0%': {
      transform: 'rotate(2deg)',
    },
    '50%': {
      transform: 'rotate(-30deg)',
    },
    '100%': {
      transform: 'rotate(2deg)',
    },
  },
  '@keyframes shake': {
    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
    '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
    '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
    '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
    '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
    '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
    '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
    '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
    '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
    '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
    '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' },
  },
}));

const random = (value: number) => Math.floor(Math.random() * value);

interface WelcomeBackProps {
  className?: string;
}

export const Florian: React.FC<WelcomeBackProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const [text1, setText1] = useState<string>(null);
  const [text2, setText2] = useState<string>(null);
  const [text3, setText3] = useState<string>(null);

  const [gameOver, setGameOver] = useState<boolean>(false);
  const [highscore, setHighscore] = useState<number>(0);
  const [score, setScore] = useState<number>(0);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleButtonClick = () => {
    setGameOver(false);
    setIsClicked(true);
  };

  const handleGameOver = (score: number) => {
    setTimeout(() => {
      setHighscore((highscore) => (score > highscore ? score : highscore));
      setScore(score);
      setGameOver(true);
      setIsClicked(false);
    }, 100);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const wait = (milliseconds: number) => {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    };

    const typeText = async () => {
      await wait(1000);
      const text1 = 'Hey Florian, I aM gLaD tO sEe yoU aGaiN!';
      for (let i = 0; i < text1.length; i++) {
        await wait(50);
        setText1(text1.slice(0, i + 1));
      }
      await wait(1000);
      const text2 = "Oh No, I dOn't fEeL sO gOoD...";
      for (let i = 0; i < text2.length; i++) {
        await wait(50);
        setText2(text2.slice(0, i + 1));
      }
      await wait(1000);
      const text3 = "SeEms I'vE gOt soMe bUGs to FIx...";
      for (let i = 0; i < text3.length; i++) {
        await wait(50);
        setText3(text3.slice(0, i + 1));
      }
    };
    typeText();
  }, []);
  //#endregion

  return gameOver ? (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '16px',
        background: '#000000',
        color: '#ffffff',
      }}
    >
      <h1
        style={{
          color: 'red',
          fontSize: '100px',
        }}
      >
        GAME OVER
      </h1>
      <p>High Score: {highscore}</p>
      <p>Score: {score}</p>
      <Button onClick={handleButtonClick} disabled={isClicked}>
        Try again!
      </Button>
    </div>
  ) : !isClicked ? (
    <div className={classNames(classes.root, className)}>
      <Flex.Column className={classes.welcomeText} childrenGap={24}>
        <Flex.Row childrenGap={24} alignItems="center">
          <Flex.Row>
            <Flex.Item className={classes.armContainer}>
              <div className={classes.arm}>
                <div className={classes.upperArm} />
                <div className={classes.hand} />
              </div>
            </Flex.Item>
            <Flex.Item>
              <FontAwesomeIcon
                icon={['fad', 'user-robot']}
                style={
                  {
                    fontSize: 88,
                    '--fa-primary-color':
                      theme.old.palette.backgroundPalette.base,
                    '--fa-secondary-color':
                      theme.old.palette.backgroundPalette.hover.base,
                  } as CSSProperties
                }
              />
            </Flex.Item>
          </Flex.Row>
          <Flex.Column width={'400px'}>
            <Typography.Title level={1} style={{ margin: 0, marginBottom: 8 }}>
              {text1}
            </Typography.Title>
            {text2 && (
              <Typography.Title level={1} style={{ margin: 0 }}>
                {text2}
              </Typography.Title>
            )}
            {text3 && (
              <Typography.Title level={1} style={{ margin: 0 }}>
                {text3}
              </Typography.Title>
            )}
          </Flex.Column>
        </Flex.Row>
        <Button onClick={handleButtonClick} disabled={isClicked}>
          Fix Bugs!
        </Button>
      </Flex.Column>
    </div>
  ) : (
    <BUGS onGameOver={handleGameOver} highscore={highscore} />
  );
};

export default Florian;

interface BugsProps {
  onGameOver: (score: number) => void;
  highscore: number;
}

interface BugState {
  keys: string[];
  id: string;
  startPlane: string;
  startValue: number;
}

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
const COOLDOWN = 1500;
const MIN_COOLDOWN = 500;
const COOLDOWN_RATE = 10;
const BUG_KEY_MODIFIER = 25; // when n bugs are killed then more keys are added to destroy

const BUGS: React.FC<BugsProps> = (props) => {
  //#region ------------------------------ Defaults
  const { onGameOver, highscore } = props;
  const classes = useStyles();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [cooldown, setCooldown] = useState<number>(COOLDOWN); // in ms
  const [bugs, setBugs] = useState<BugState[]>([]); // in ms
  const [score, setScore] = useState<number>(0); // bugs killed
  const [lives, setLives] = useState<number>(3); // lives left

  const [isHit, setIsHit] = useState(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const addBug = useCallback(() => {
    const id = uniqueId();

    const howManyKeys = Math.floor(score / BUG_KEY_MODIFIER) + 1;

    const keys = [];
    const decrease = random(howManyKeys);
    for (let i = 0; i < howManyKeys - decrease; i++) {
      const key = ALPHABET[random(ALPHABET.length)];
      keys.push(key);
    }

    const startPlane = ['x', 'y'][random(2)];
    const startValue = random(100);

    const bug = { id, keys, startPlane, startValue };
    setBugs((bugs) => [...bugs, bug]);
  }, [score]);

  function uniqueId() {
    return (
      Date.now().toString(36) +
      Math.floor(
        Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)
      ).toString(36)
    );
  }

  const onHasReachedMiddle = useCallback((id: string) => {
    setLives((lives) => (lives <= 0 ? 0 : lives - 1));
    setBugs((bugs) => bugs.filter((bug) => bug.id !== id));
    setIsHit(true);

    setTimeout(() => {
      setIsHit(false);
    }, 250);
  }, []);

  const onDestroy = useCallback((id: string) => {
    setBugs((bugs) => bugs.filter((bug) => bug.id !== id));
    setScore((score) => score + 1);
  }, []);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const interval = setInterval(() => {
      if (cooldown <= MIN_COOLDOWN) {
        if (cooldown % 2 === 0) setCooldown(cooldown - 1);
        else if (cooldown % 2 === 1) setCooldown(cooldown + 1);
        else setCooldown(500);
      } else setCooldown(cooldown - COOLDOWN_RATE);

      addBug();
    }, cooldown);

    return () => clearInterval(interval);
  }, [cooldown, addBug]);

  useEffect(() => {
    if (lives <= 0) {
      onGameOver(score);
    }
  }, [lives, onGameOver, score]);
  //#endregion

  return (
    <div
      className={isHit ? classes.shake : null}
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        transition: 'all 0.25s ease',
        backgroundColor: isHit
          ? '#ff9999'
          : lives <= 1
          ? '#ffbbbb'
          : 'transparent',
      }}
    >
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: '16px',
          left: '16px',
          zIndex: '10',
          gap: '0px',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'row',
          }}
        >
          {Array.from(Array(lives).keys()).map((i) => (
            <FontAwesomeIcon
              icon={['fas', 'heart']}
              key={i}
              size="2xl"
              style={{ color: '#ff0000', margin: '4px' }}
            />
          ))}
        </div>
        {highscore > 0 && (
          <p
            style={{
              marginBottom: '-4px',
            }}
          >
            Highscore: {highscore}
          </p>
        )}
        <p
          style={{
            marginBottom: '-4px',
          }}
        >
          Score: {score}
        </p>

        <p
          style={{
            marginBottom: '-4px',
          }}
        >
          Multiplier: {Math.floor(score / BUG_KEY_MODIFIER) + 1}x (next at{' '}
          {BUG_KEY_MODIFIER * (Math.floor(score / BUG_KEY_MODIFIER) + 1)})
        </p>
      </div>
      {bugs.map((bug) => (
        <BUG
          key={bug.id}
          bugData={bug}
          onHasReachedMiddle={onHasReachedMiddle}
          onDestroy={onDestroy}
        />
      ))}
      <div
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          bottom: '0px',
          right: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '1 ',
        }}
      >
        <PrioSpinner size="large" />
      </div>
    </div>
  );
};

interface BugProps {
  bugData: BugState;
  onHasReachedMiddle?: (id: string) => void;
  onDestroy: (id: string) => void;
}

const STEPS_TO_MIDDLE = 70; // steps the bug has to take before it reaches middle (steps occur once per INTERVAL_SPEED)
const INTERVAL_SPEED = 100; // in ms

const BUG: React.FC<BugProps> = (props) => {
  //#region ------------------------------ Defaults

  const { bugData, onHasReachedMiddle, onDestroy } = props;

  const { id, keys, startPlane, startValue } = bugData;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [x, setX] = useState<number>(
    startPlane === 'x' ? [0, 100][random(2)] : startValue
  );
  const [y, setY] = useState<number>(
    startPlane === 'y' ? [0, 100][random(2)] : startValue
  );

  const [hasReachedMiddle, setHasReachedMiddle] = useState<boolean>(false);

  const rndRotation = useMemo(() => random(360), []);

  const [lettersRemoved, setLettersRemoved] = useState<number>(0);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleKeypress = useCallback(
    (e) => {
      const key = e.key.toLowerCase();
      setLettersRemoved((lettersRemoved) => {
        if (keys[lettersRemoved] === key) {
          return lettersRemoved + 1;
        }
        return lettersRemoved;
      });
    },
    [keys]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    document.addEventListener('keydown', handleKeypress);

    // i want to move the bug slowly to the center of the screen with a given speed
    const interval = setInterval(() => {
      const xStep = (50 - x) / STEPS_TO_MIDDLE;
      const yStep = (50 - y) / STEPS_TO_MIDDLE;

      setX((x) => (Math.floor(x) === 50 ? x : x + xStep));
      setY((y) => (Math.floor(y) === 50 ? y : y + yStep));
    }, INTERVAL_SPEED);

    return () => {
      document.removeEventListener('keydown', handleKeypress);
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [handleKeypress]);

  useEffect(() => {
    if (Math.floor(x) === 50 && Math.floor(y) === 50) {
      setHasReachedMiddle(true);
    } else {
      setHasReachedMiddle(false);
    }
  }, [x, y]);

  useEffect(() => {
    if (hasReachedMiddle) {
      onHasReachedMiddle(id);
    }
  }, [id, hasReachedMiddle, onHasReachedMiddle]);

  useEffect(() => {
    if (lettersRemoved === keys.length) {
      onDestroy(id);
    }
  }, [lettersRemoved, id, onDestroy, keys.length]);
  //#endregion

  return (
    <div
      style={{
        position: 'absolute',
        transform: `translate3d(${x}vw, ${y}vh, 0)`,
        transition: `all ${INTERVAL_SPEED}ms linear`,
        zIndex: '5',
      }}
    >
      <div
        style={{
          marginTop: '-32px',
          marginLeft: '-32px',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {keys.map((key, i) => (
            <p
              key={i}
              style={{
                textTransform: 'uppercase',
                color: i < lettersRemoved ? '#00ff00' : '#fff',
                fontSize: '28px',
                fontWeight: 'bold',
                margin: '0px',
                padding: '0px',
                fontFamily: 'monospace',
                letterSpacing: '2px',
                textShadow:
                  '2px 0 0 #000, 0 -2px 0 #000, 0 2px 0 #000, -2px 0 0 #000',
              }}
            >
              {key}
            </p>
          ))}
        </div>
        <FontAwesomeIcon
          rotate={rndRotation}
          icon={['fas', 'bug']}
          size="2xl"
          color="#ff0000"
        />
      </div>
    </div>
  );
};
