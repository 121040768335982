import { all, fork } from 'redux-saga/effects';
import watchDriveItemsFetch from '../modules/documents/sagas/watchDriveItemsFetch';
import watchUploadFiles from '../modules/documents/sagas/watchUploadFiles';
import watchUpdateDriveItemMetaData from '../modules/documents/sagas/watchUpdateDriveItemMetaData';

export default function* watchDriveItems() {
  yield all([
    fork(watchDriveItemsFetch),
    fork(watchUploadFiles),
    fork(watchUpdateDriveItemMetaData),
  ]);
}
