import React, { useState } from 'react';
import { CreateInvoice, Invoice } from '../../../models/Accounting';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { notification, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvoiceDetailsPayments } from './InvoiceDetailsPayments';
import { InvoiceForm } from './InvoiceForm';
import { apiUpdateInvoice } from '../api';
import { OfficeId, ProjectId } from '../../../models/Types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const { TabPane } = Tabs;

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  value: {
    textAlign: 'right',
    flex: 1,
  },
  first1: {
    height: '100%',
  },
}));

interface InvoiceDetailsProps {
  invoice: Invoice;
  reloadInvoices: VoidFunction;
  onSubmit?: () => void;
  officeId?: OfficeId;
  projectId?: ProjectId;
  handleDrawerClose: () => void;
}

type TabKeys = 'payments' | 'invoice';

export const InvoiceDetails: React.FC<InvoiceDetailsProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const {
    invoice,
    reloadInvoices,
    projectId,
    officeId,
    onSubmit,
    handleDrawerClose,
  } = props;

  const [activeTabKey, setActiveTabKey] = useState<TabKeys>('payments');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const tabsChange = (activeKey: TabKeys) => setActiveTabKey(activeKey);

  const handleInvoiceUpdate: (
    invoiceUpdate: CreateInvoice
  ) => Promise<boolean> = async (invoiceUpdate) => {
    setIsSaving(true);
    const { result } = await apiUpdateInvoice(
      invoice.invoiceId,
      invoiceUpdate,
      officeId,
      projectId
    );
    setIsSaving(false);
    if (result.status >= 200 && result.status < 300) {
      reloadInvoices();
      onSubmit?.();
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('accounting:errorMessages.updateInvoice'),
      });
      onSubmit?.();
      return false;
    }
  };

  return (
    <div className={classes.first1}>
      <Flex.Column
        childrenGap={theme.old.spacing.unit(2)}
        className={classes.root}
      >
        <Typography.Title
          level={2}
          style={{ fontWeight: theme.old.typography.fontWeight.regular }}
        >
          <FontAwesomeIcon icon={['fal', 'file']} />
          <span> </span>
          <span>{invoice.number}</span>
        </Typography.Title>
        <Tabs
          animated={false}
          defaultActiveKey="payments"
          activeKey={activeTabKey}
          onChange={tabsChange}
          className={classes.root}
        >
          <TabPane
            tab={t('accounting:invoiceDetails.tabs.payments')}
            key="payments"
            className={classes.root}
            // style={{ overflowY: 'auto' }}
          >
            <InvoiceDetailsPayments
              invoice={invoice}
              reloadInvoices={reloadInvoices}
              officeId={officeId}
              projectId={projectId}
              handleDrawerClose={handleDrawerClose}
            />
          </TabPane>
          <TabPane
            tab={t('accounting:invoiceDetails.tabs.invoice')}
            key="invoice"
          >
            <InvoiceForm
              actionLabel={t('accounting:invoiceForm.actions.update')}
              initialValues={invoice}
              onFinish={handleInvoiceUpdate}
              isoCode={invoice?.grossSum?.isoCode}
              disableForm={isSaving}
              projectId={projectId}
              officeId={officeId}
            />
          </TabPane>
        </Tabs>
      </Flex.Column>
    </div>
  );
};

export default InvoiceDetails;
