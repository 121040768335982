import { CustomTagProps } from 'rc-select/lib/interface/generator';
import React, { CSSProperties, useEffect, useState } from 'react';
import { MailCategoryColorName } from '../../../../models/Types';
import { makePrioStyles } from '../../../../theme/utils';
import { colorFromPreset, hexToHSL } from '../../util';
import Tag from 'antd/es/tag';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: 20,
    border: 'none',
    borderRadius: 10,
    fontSize: theme.old.typography.fontSize.small,
    alignItems: 'center',
    '&.ant-tag': {
      padding: `0 ${theme.old.spacing.unit(1)}px`,
      minWidth: 28,
    },
    display: 'flex',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& > .name': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  closeIconNoMargin: {
    '& .ant-tag-close-icon': {
      margin: 0,
      opacity: 0,
    },
    '&:hover': {
      '& .ant-tag-close-icon': {
        opacity: 1,
      },
    },
  },
}));

interface CategoryTagProps extends CustomTagProps {
  className?: string;
  colorMap: {
    [displayName: string]: MailCategoryColorName;
  };
  disableText?: boolean;
  tagNameStyle?: CSSProperties;
}

export const CategoryTag: React.FC<CategoryTagProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    value,
    closable,
    onClose,
    colorMap,
    disableText,
    tagNameStyle,
  } = props;

  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isClosable, setIsClosable] = useState<boolean>(closable);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setIsClosable(true);
  }, [value]);
  //#endregion

  if (!colorMap) {
    return null;
  }
  //#region ------------------------------ States / Attributes / Selectors
  const colorPreset = colorFromPreset(colorMap[value as string], theme);
  const colorPresetHSLDark = hexToHSL(
    colorFromPreset(colorMap[value as string], theme),
    { lFixed: 30 }
  );
  const colorPresetHSLLight = hexToHSL(
    colorFromPreset(colorMap[value as string], theme),
    { lFixed: 95 }
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  return (
    <Tag
      className={classNames(classes.root, className, 'prio-mail-category-tag', {
        [classes.closeIconNoMargin]: disableText,
      })}
      style={{ border: `1px solid ${colorPreset}`, color: colorPresetHSLDark }}
      color={colorPresetHSLLight}
      closable={isClosable}
      onClose={(event) => {
        event.stopPropagation();
        event.preventDefault();
        setIsClosable(false);
        onClose();
      }}
      closeIcon={<CloseOutlined style={{ color: colorPresetHSLDark }} />}
    >
      <span
        style={tagNameStyle}
        title={value as string}
        className="prio-mail-category-name"
      >
        {value}
      </span>
    </Tag>
  );
};

export default CategoryTag;
