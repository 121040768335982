import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import Flex from '../../../components/Flex';
import TrainingList from './TrainingList';
import PersonnelDatePage from './PersonnelDataPage';
import { makePrioStyles } from '../../../theme/utils';
import TimeRecordsModule from '../../timeRecords/components/TimeRecordsModule';
import * as Sentry from '@sentry/react';
import PrioExtensionSettingsPage from '../../settings/components/PrioExtensionSettingsPage';
import PrioAppPage from './PrioAppPage';
import * as ConfigValues from '../../../util/configValues';
import UserAddInsConfig from '../../projects/components/UserAddInsConfig';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
  moduleRoot: {
    height: '100%',
    overflow: 'hidden',
  },
  newPage: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: `${theme.old.spacing.defaultPadding}px ${
      theme.old.spacing.defaultPadding * 2
    }px`,

    backgroundColor: theme.colors.application.background.light,
  },
  body: {
    overflow: 'hidden',
  },
  manu: {
    maxWidth: theme.old.components.menuMaxWidth,
    overflow: 'auto',
  },
}));

interface ProfileModuleProps {}

export const ProfileModule: React.FC<ProfileModuleProps> = (props) => {
  const classes = useStyles();

  return (
    <Flex.Column className={classes.root}>
      <div className={classes.moduleRoot}>
        <Routes>
          <Route path="overview" />
          <Route path="trainings" element={<TrainingList />} />
          <Route path="personalFile" />
          <Route
            path="timeRecords"
            element={<TimeRecordsModule contextType="me" />}
          />
          <Route path="personnelData/*" element={<PersonnelDatePage />} />
          <Route
            path="prioExtension/*"
            element={<PrioExtensionSettingsPage />}
          />
          <Route
            path="AddIns/*"
            element={
              <Flex.Column className={classes.root}>
                <Flex.Row flex={1} className={classes.body}>
                  <Flex.Item flex={1} className={classes.newPage}>
                    <UserAddInsConfig className={classes.fullHeight} />
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
            }
          />
          {ConfigValues.FLUTTER_APP_TENANT_NAME &&
            ConfigValues.FLUTTER_APP_CLIENT_ID && (
              <Route path="prioApp" element={<PrioAppPage />} />
            )}
        </Routes>
      </div>
    </Flex.Column>
  );
};

export default ProfileModule;
