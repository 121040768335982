import React from 'react';
import { Skeleton } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme) => ({
  item: {
    paddingLeft: theme.old.spacing.unit(1.5),
    paddingRight: theme.old.spacing.unit(3),
    marginTop: theme.old.spacing.unit(0.5),
    marginBottom: theme.old.spacing.unit(0.5),
  },
  input: {
    width: '135px',
    height: '16px',
    overflow: 'hidden',
    marginRight: '12px',
    marginLeft: 'calc(32px - 15%)',
  },
  avatar: {
    overflow: 'hidden',
    width: '24px',
    height: '24px',
    marginRight: '2px',
  },
  topBottomMargin: {
    marginTop: 18,
    marginBottom: 18,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'calc(5% + 18px)',
  },
  icon: {
    marginLeft: '4px',
    overflow: 'hidden',
    height: '16px',
    width: '16px',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const MailMenuSkeleton: React.FC = (props) => {
  const classes = useStyles();
  return (
    <div>
      {Array(4)
        .fill(null)
        .map((_, index) => (
          <div className={classes.topBottomMargin} key={index}>
            <div style={{ height: '24px' }}>
              <Skeleton.Avatar className={classes.avatar} size={'small'} />
            </div>
            <div style={{ height: 16, display: 'flex', alignItems: 'center' }}>
              <Skeleton.Input className={classes.input} />
              <span className={classes.icon}>
                <FontAwesomeIcon
                  icon={['fal', 'ellipsis-v']}
                  color={'rgb(0, 0, 0, 0.2)'}
                />
              </span>
              <span className={classes.icon}>
                <FontAwesomeIcon
                  icon={['fal', 'chevron-down']}
                  color={'rgb(0, 0, 0, 0.2)'}
                />
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MailMenuSkeleton;
