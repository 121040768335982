import React, { useEffect, useState, useReducer, Reducer } from 'react';

import { Table, Input } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import {
  TitleSuggestion,
  CreateTitleSuggestion,
} from '../../../models/TitleSuggestion';
import { TitleSuggestionId } from '../../../models/Types';
import equals from 'deep-equal';
import { isTemporaryId } from '../../../util';
import { tableTranslations } from '../../../util/table';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-row .ant-table-cell .title-suggestion-table-delete-button': {
      visibility: 'hidden',
    },
    '& .ant-table-row:hover .ant-table-cell .title-suggestion-table-delete-button':
      {
        visibility: 'visible',
      },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
}));

interface TitleSuggestionsTableProps {
  className?: string;
  titleSuggestions: TitleSuggestion[];
  onIsDirtyChanged?: (
    isDirty: boolean,
    titleSuggestions?: TitleSuggestion[]
  ) => void;
  onNewSuggestion?: (titleSuggestion: CreateTitleSuggestion) => void;
  onDelete?: (titleSuggestionId: TitleSuggestionId) => void;
}

interface TableEntry extends TitleSuggestion {
  key: string;
  addTitleSuggestionRow: boolean;
  isTemporary: boolean;
}

interface UpdateNewTitleSuggestionAction {
  value: string;
}

interface NewTitleSuggestion extends CreateTitleSuggestion {
  isValid: boolean;
}

export const TitleSuggestionsTable: React.FC<TitleSuggestionsTableProps> = (
  props
) => {
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    titleSuggestions: originalTitleSuggestions,
    onIsDirtyChanged,
    onNewSuggestion,
    onDelete,
  } = props;

  const [titleSuggestions, setTitleSuggestions] = useState<TitleSuggestion[]>(
    originalTitleSuggestions
  );

  const [newTitleSuggestionRowActive, setNewTitleSuggestionRowActive] =
    useState(false);

  useEffect(() => {
    setTitleSuggestions(originalTitleSuggestions);
    onIsDirtyChanged(false, originalTitleSuggestions);
  }, [originalTitleSuggestions, onIsDirtyChanged]);

  const validate: (state: NewTitleSuggestion) => boolean = (
    state: NewTitleSuggestion
  ) => state.value.trim().length > 0;

  const emptyNewTitleSuggestion: NewTitleSuggestion = {
    value: '',
    isValid: false,
  };

  const [newTitleSuggestion, updateNewTitleSuggestion] = useReducer<
    Reducer<NewTitleSuggestion, UpdateNewTitleSuggestionAction | 'clear'>
  >(
    (
      state: NewTitleSuggestion,
      action: UpdateNewTitleSuggestionAction | 'clear'
    ) => {
      if (action === 'clear') {
        return emptyNewTitleSuggestion;
      }
      const updatedState = { ...state, value: action.value };
      return {
        ...updatedState,
        isValid: validate(updatedState),
      };
    },
    emptyNewTitleSuggestion
  );

  const updateTitleSuggestion = (
    titleSuggestionId: TitleSuggestionId,
    value: string
  ) => {
    const updatedTitleSuggestions = titleSuggestions.map((entry) =>
      entry.titleSuggestionId === titleSuggestionId
        ? { ...entry, value }
        : entry
    );
    setTitleSuggestions(updatedTitleSuggestions);

    if (onIsDirtyChanged) {
      const isDirty = !equals(
        updatedTitleSuggestions,
        originalTitleSuggestions
      );
      onIsDirtyChanged(isDirty, updatedTitleSuggestions);
    }
  };

  const saveNewTitleSuggestion = () => {
    if (onNewSuggestion) {
      onNewSuggestion(newTitleSuggestion);
      cancelNewTitleSuggestionRow();
      updateNewTitleSuggestion('clear');
    }
  };

  const deleteTitleSuggestion = (titleSuggestionId: TitleSuggestionId) => {
    if (onDelete) {
      onDelete(titleSuggestionId);
    }
  };

  const columns: ColumnProps<TableEntry>[] = [
    {
      title: t('settings:titleSuggestionsTable.columnTitles.value'),
      dataIndex: 'value',
      defaultSortOrder: 'ascend',
      render: (value, record) => (
        <Input
          value={
            record.addTitleSuggestionRow ? newTitleSuggestion.value : value
          }
          onChange={(e) =>
            record.addTitleSuggestionRow
              ? updateNewTitleSuggestion({ value: e.currentTarget.value })
              : updateTitleSuggestion(
                  record.titleSuggestionId,
                  e.currentTarget.value
                )
          }
          disabled={record.isTemporary}
        />
      ),
    },
    {
      render: (_, record) =>
        record.addTitleSuggestionRow ? (
          <Button
            disabled={!newTitleSuggestion.isValid}
            onClick={saveNewTitleSuggestion}
            iconProp={['fal', 'plus']}
          ></Button>
        ) : (
          <Button
            onClick={() => deleteTitleSuggestion(record.titleSuggestionId)}
            className="title-suggestion-table-delete-button"
            disabled={record.isTemporary}
            iconProp={['fal', 'trash']}
            type="link"
          ></Button>
        ),
      width: 80,
    },
  ];

  const data: TableEntry[] = titleSuggestions.map(
    (titleSuggestion: TitleSuggestion, index: number) => ({
      key: titleSuggestion.titleSuggestionId,
      ...titleSuggestion,
      isTemporary: isTemporaryId(titleSuggestion.titleSuggestionId),
      addTitleSuggestionRow: false,
    })
  );

  const enableNewTitleSuggestionRow = () =>
    setNewTitleSuggestionRowActive(true);
  const cancelNewTitleSuggestionRow = () =>
    setNewTitleSuggestionRowActive(false);

  const footer = () => (
    <div>
      <Button
        type="link"
        onClick={enableNewTitleSuggestionRow}
        disabled={newTitleSuggestionRowActive}
        iconProp={['fal', 'plus']}
      >
        {t('settings:titleSuggestionsTable.actions.add')}
      </Button>
    </div>
  );

  return (
    <Table
      className={classNames(classes.root, className)}
      dataSource={
        newTitleSuggestionRowActive
          ? ([
              ...data,
              {
                key: 'addTitleSuggestion',
                value: '',
                addTitleSuggestionRow: true,
              },
            ] as TableEntry[])
          : data
      }
      columns={columns}
      pagination={false}
      rowSelection={{
        type: 'checkbox',
      }}
      footer={footer}
      locale={tableTranslations(t)}
    />
  );
};

export default TitleSuggestionsTable;
