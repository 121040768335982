import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import Flex from '../../../../components/Flex';
import {
  DocumentsSearchResultItem,
  DriveItem,
  Preview,
} from '../../../../models/Drive';
import {
  DefaultDateTimeFormatString,
  formatHumanFileSize,
} from '../../../../util';
import { iconForMimeType } from '../../util';
import { PrioTheme } from '../../../../theme/types';
import i18n from '../../../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenCurrentPreviewModal } from '../../../../apps/main/rootReducer';
import { MessageAttachment } from '../../../../models/Message';
import { apiDownloadDriveItem } from '../../api';
import { GroupId } from '../../../../models/Types';
import { makePrioStyles } from '../../../../theme/utils';
import { DriveItemVersion } from '../../../../models/Document';
import { downloadAttachment } from '../../../mail/components/EmailComposer/EmailComposer';
import useProjectsContext from '../../../projects/hooks/useProjectsContext';
import { useNavigate } from 'react-router-dom';
import { closeGlobalDocumentsSearchModal } from '../../actions/globalDocumentsSearchModal';
import { PreviewListItem } from '../../reducers/previewModal';
import { getPreviewListItemTypeName } from './PreviewModal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '48px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    alignItems: 'baseline',
  },
  previewItemsSmallerPadding: {
    backgroundColor: theme.colors.application.background.default,
    padding: theme.spacing.small,
    paddingLeft: theme.spacing.regular,
  },
  nameLabel: {
    maxWidth: '500px',
  },
  label: {
    color: 'rgba(0,0,0,0.6)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  boldLabel: {
    fontWeight: 700,
  },
  descriptionRow: {
    fontSize: theme.font.fontSize.small,
  },
}));

interface PreviewModalNavBarProps {
  className?: string;
  groupId: GroupId;
  preview: Preview;
  selectedDriveItems?: PreviewListItem[];
  currentDriveItemVersion?: DriveItemVersion;
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  itemList: PreviewListItem[];
}

export const PreviewModalNavBar: React.FC<PreviewModalNavBarProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    groupId,
    preview,
    selectedDriveItems,
    currentDriveItemVersion,
    onClose = () => {},
    itemList,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as PrioTheme;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const driveItemsSelected = selectedDriveItems?.length > 0;
  const totalSizeOfSelectedDriveItems = useMemo(
    () =>
      selectedDriveItems?.reduce((prev, curr) => {
        switch (getPreviewListItemTypeName(curr)) {
          case 'DocumentsSearchResultItem': {
            return prev + (curr as DocumentsSearchResultItem).data.size;
          }
          default: {
            return prev + (curr as DriveItem | MessageAttachment).size;
          }
        }
      }, 0),
    [selectedDriveItems]
  );
  const previewModal = useSelector(getOpenCurrentPreviewModal);
  const dispatch = useDispatch();

  const id = useMemo(() => {
    switch (getPreviewListItemTypeName(preview?.item)) {
      case 'DocumentsSearchResultItem': {
        return (preview?.item as DocumentsSearchResultItem)?.data?.id;
      }
      default: {
        return (preview?.item as MessageAttachment | DriveItem)?.id;
      }
    }
  }, [preview?.item]);

  const name = useMemo(() => {
    switch (getPreviewListItemTypeName(preview?.item)) {
      case 'DocumentsSearchResultItem': {
        return (preview?.item as DocumentsSearchResultItem)?.data?.name ?? '';
      }
      default: {
        return (preview?.item as MessageAttachment | DriveItem)?.name ?? '';
      }
    }
  }, [preview?.item]);

  const size = useMemo(() => {
    switch (getPreviewListItemTypeName(preview?.item)) {
      case 'DocumentsSearchResultItem': {
        return (preview?.item as DocumentsSearchResultItem)?.data?.size;
      }
      default: {
        return (preview?.item as MessageAttachment | DriveItem)?.size;
      }
    }
  }, [preview?.item]);
  const { getProjectById } = useProjectsContext();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const downloadSmimeAttachment = async (
    messageAttachment: MessageAttachment
  ) => {
    if (messageAttachment?.contentBytes?.length > 0) {
      const linkSource = `data:${messageAttachment.contentType};base64,${messageAttachment.contentBytes}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = messageAttachment.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const onItemDownload = useCallback(
    async (item: PreviewListItem) => {
      switch (getPreviewListItemTypeName(item)) {
        case 'DocumentsSearchResultItem': {
          if (
            !(await apiDownloadDriveItem(
              (item as DocumentsSearchResultItem).calculated.groupId,
              (item as DocumentsSearchResultItem).data
            ))
          ) {
            notification.open({
              message: t('common:error'),
              description: t('documents:errorMessages.downloadFileError'),
            });
          }
          break;
        }
        case 'DriveItem': {
          if (!(await apiDownloadDriveItem(groupId, item as DriveItem))) {
            notification.open({
              message: t('common:error'),
              description: t('documents:errorMessages.downloadFileError'),
            });
          }
          break;
        }
        case 'MessageAttachment': {
          const _item = item as MessageAttachment;
          const itemListItem = (itemList as Array<MessageAttachment>).find(
            (i) => i.id === id
          ) as MessageAttachment;
          const attachment = {
            ..._item,
            contentBytes: itemListItem?.contentBytes ?? null,
          } as MessageAttachment;

          if (attachment.contentBytes) {
            downloadSmimeAttachment(attachment).catch((error) => {
              notification.open({
                message: t('common:error'),
                description: t('documents:errorMessages.downloadFileError'),
              });
            });
          } else {
            downloadAttachment(
              previewModal.projectId,
              previewModal.messageId,
              {
                ...attachment,
                contentBytes: null,
              },
              t
            );
          }
          break;
        }
      }
    },
    [groupId, t, id, itemList, previewModal]
  );

  const onOpenParentFolder = useCallback(() => {
    const isDriveItem =
      getPreviewListItemTypeName(preview?.item) === 'DriveItem';
    const projectId = isDriveItem
      ? previewModal.projectId
      : (preview.item as DocumentsSearchResultItem)?.calculated
          ?.projectIds?.[0];
    const parentDriveItemId = previewModal.driveItem.parentReference?.id;
    let url;
    if (!!!parentDriveItemId) {
      url = `/module/prio/projects/${projectId}/documents/all`;
    } else {
      url = `/module/prio/projects/${projectId}/documents/folder/${parentDriveItemId}`;
    }
    dispatch(closeGlobalDocumentsSearchModal());
    navigate(url);
  }, [navigate, previewModal, preview, dispatch]);

  // TODO: Share Funtion wird erst später umgesetzt
  // const onItemShare = useCallback(async (item: DriveItem) => {}, []);

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      className={classNames(
        classes.root,
        className,
        classes.previewItemsSmallerPadding
      )}
    >
      <Flex.Row flex={1}>
        {/* Beschreibung */}
        {!driveItemsSelected && (
          <Flex.Row
            flex={1}
            alignItems="center"
            childrenGap={theme.spacing.large}
            className={classes.descriptionRow}
          >
            <Flex.Item flexShrink={1} className={classes.nameLabel}>
              <div title={name}>
                <Typography.Text ellipsis>{name}</Typography.Text>
              </div>
            </Flex.Item>
            {getPreviewListItemTypeName(preview?.item) !==
              'MessageAttachment' && (
              <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    <span className={classes.boldLabel}>{`${t(
                      'documents:documentDetails.labels.version'
                    )}`}</span>
                    <span>:</span>
                  </Typography.Text>
                </Flex.Item>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    {currentDriveItemVersion?.id}
                  </Typography.Text>
                </Flex.Item>
              </Flex.Row>
            )}
            {getPreviewListItemTypeName(preview?.item) ===
              'DocumentsSearchResultItem' && (
              <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    <span className={classes.boldLabel}>{`${t(
                      'documents:documentDetails.labels.project'
                    )}`}</span>
                    <span>:</span>
                  </Typography.Text>
                </Flex.Item>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    {
                      getProjectById(
                        (preview?.item as DocumentsSearchResultItem)?.calculated
                          ?.projectIds?.[0]
                      )?.name
                    }
                  </Typography.Text>
                </Flex.Item>
              </Flex.Row>
            )}
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.type'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {preview?.mimeType
                    ? t(
                        `documents:fileTypeNames.${iconForMimeType(
                          preview.mimeType
                        )}`
                      )
                    : ''}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.size'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {size ? formatHumanFileSize(size) : ''}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
            {preview?.optional?.lastModifiedDateTime && (
              <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    <span className={classes.boldLabel}>{`${t(
                      'documents:documentDetails.labels.lastModifiedDateTime'
                    )}`}</span>
                    <span>:</span>
                  </Typography.Text>
                </Flex.Item>
                <Flex.Row flexGrow={0} childrenGap={theme.spacing.small}>
                  <Flex.Item>
                    <Typography.Text className={classes.label}>
                      {DefaultDateTimeFormatString(
                        preview?.optional?.lastModifiedDateTime,
                        i18n.language
                      )}
                    </Typography.Text>
                  </Flex.Item>
                  {getPreviewListItemTypeName(preview?.item) !==
                    'MessageAttachment' &&
                    (preview.item as DriveItem).lastModifiedBy?.user
                      ?.displayName && (
                      <Flex.Item>
                        <Typography.Text className={classes.label}>
                          |
                        </Typography.Text>
                      </Flex.Item>
                    )}
                  {getPreviewListItemTypeName(preview?.item) !==
                    'MessageAttachment' && (
                    <Flex.Item>
                      <Typography.Text className={classes.label}>
                        {
                          (preview.item as DriveItem).lastModifiedBy?.user
                            ?.displayName
                        }
                      </Typography.Text>
                    </Flex.Item>
                  )}
                </Flex.Row>
              </Flex.Row>
            )}
          </Flex.Row>
        )}
        {/* Beschreibung MultiSelect */}
        {driveItemsSelected && (
          <Flex.Row
            flex={1}
            alignItems="center"
            childrenGap={theme.spacing.large}
          >
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.numberOfSelectedDriveItems'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {selectedDriveItems?.length}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.totalSize'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {formatHumanFileSize(totalSizeOfSelectedDriveItems)}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
          </Flex.Row>
        )}
        {/* Actions */}
        {!driveItemsSelected && (
          <Flex.Row justifyContent="end" alignItems="center">
            {getPreviewListItemTypeName(preview?.item) ===
              'DocumentsSearchResultItem' && (
              <Flex.Item flexGrow={0}>
                <Button
                  type="link"
                  onClick={(e) => {
                    onClose(e);
                    onOpenParentFolder();
                  }}
                >
                  {t('documents:documentDetails.actions.openFolder')}
                </Button>
              </Flex.Item>
            )}
            {getPreviewListItemTypeName(preview?.item) !==
              'MessageAttachment' && (
              <Flex.Item flexGrow={0}>
                <Button
                  type="link"
                  href={preview?.optional?.driveItemWebUrl + '?web=1'}
                  disabled={
                    !['pdf', 'docx', 'doc', 'xlsx', 'xls', 'pptx', 'ppt'].some(
                      (ext) => preview?.optional.driveItemWebUrl?.includes(ext)
                    )
                  }
                >
                  {t('documents:documentDetails.actions.openInWebBrowser')}
                </Button>
              </Flex.Item>
            )}
            {preview?.optional?.localDriveItemUrl?.includes(':ofe|ofc|u|') && (
              <Flex.Item flexGrow={0}>
                <Button
                  type="link"
                  href={preview?.optional?.localDriveItemUrl ?? undefined}
                >
                  {t('documents:documentDetails.actions.openLocally')}
                </Button>
              </Flex.Item>
            )}
            <Flex.Item flexGrow={0}>
              <Button type="link" onClick={() => onItemDownload(preview?.item)}>
                {t('documents:documentDetails.actions.download')}
              </Button>
            </Flex.Item>
            {/* TODO: Share Funktion kommt später erst
            {isDriveItem(preview) && (
              <Flex.Item flexGrow={0}>
                <Button
                  type="link"
                  className={classes.actionButton}
                  onClick={() =>
                    onItemShare(preview?.item as DriveItem)
                  }
                >
                  {t('documents:documentDetails.actions.share')}
                </Button>
              </Flex.Item>
            )} */}
            <Flex.Item flexGrow={0}>
              <Button
                type="default"
                onClick={(e) => onClose(e)}
                iconProp={['fal', 'xmark']}
              />
            </Flex.Item>
          </Flex.Row>
        )}
      </Flex.Row>
    </Flex.Row>
  );
};

export default PreviewModalNavBar;
