import { createSelector } from 'reselect';
import {
  InternalProjectContactItem,
  ExternalProjectContactItem,
} from '../../../models/ProjectContacts';
import { ContactsByIdState } from '../../contacts/reducers/contacts';
import { CompaniesByIdState } from '../../companies/reducers/companies';
import * as fromInternalProjectContacts from '../reducers/internalProjectContacts';
import * as fromExternalProjectContacts from '../reducers/externalProjectContacts';
import {
  getInternalProjectContactsData,
  getExternalProjectContactsData,
  RootReducerState,
  getAllProjects,
  getProjectsOverview,
  getProjectByIdState,
  getMyProjectIds,
  getContactIdsState,
  getContactsByIdState,
} from '../../../apps/main/rootReducer';
import { Company } from '../../../models/Company';
import {
  InternalContact,
  ExternalContact,
  Contact,
} from '../../../models/Contact';
import { Project } from '../../../models/Project';
import {
  ContactId,
  OfficeId,
  ProjectId,
  ProjectTabViews,
} from '../../../models/Types';
import { ProjectByIdState } from '../reducers/projects';

const contactsByIdSelector = (state: RootReducerState) =>
  state.contacts.contacts.byId;
const companiesByIdSelector = (state: RootReducerState) =>
  state.companies.companies.byId;

export const internalProjectContactsSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: any) => fromInternalProjectContacts.DataState,
      (state: any) => ContactsByIdState
    ],
    InternalProjectContactItem[]
  >(
    getInternalProjectContactsData,
    contactsByIdSelector,
    (data: fromInternalProjectContacts.DataState, byId: ContactsByIdState) =>
      (data[projectId] ?? [])
        .map((projectContact) => ({
          internalProjectContact: projectContact,
          contact: byId[projectContact.contactId],
        }))
        .filter((item) => !!item.contact)
        .sort((item) =>
          item.contact.lastName.localeCompare(item.contact.lastName)
        )
  );

export const internalProjectContactContactSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: any) => fromInternalProjectContacts.DataState,
      (state: any) => ContactsByIdState
    ],
    Contact[]
  >(
    getInternalProjectContactsData,
    contactsByIdSelector,
    (data: fromInternalProjectContacts.DataState, byId: ContactsByIdState) =>
      (data[projectId] ?? [])
        .map((projectContact) => ({
          internalProjectContact: projectContact,
          contact: byId[projectContact.contactId],
        }))
        .filter((item) => !!item.contact)
        .sort((item) =>
          item.contact.lastName.localeCompare(item.contact.lastName)
        )
        .map((c) => c.contact)
  );

export const internalProjectContactContactIdSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: any) => fromInternalProjectContacts.DataState,
      (state: any) => ContactsByIdState
    ],
    ContactId[]
  >(
    getInternalProjectContactsData,
    contactsByIdSelector,
    (data: fromInternalProjectContacts.DataState, byId: ContactsByIdState) =>
      (data[projectId] ?? [])
        .map((projectContact) => ({
          internalProjectContact: projectContact,
          contact: byId[projectContact.contactId],
        }))
        .filter((item) => !!item.contact)
        .map((c) => c.contact.contactId)
  );

export const isInternalProjectContactSelector = (
  projectId: ProjectId,
  contactId: ContactId
) =>
  createSelector<
    [(state: any) => fromInternalProjectContacts.DataState],
    boolean
  >(
    getInternalProjectContactsData,
    (data: fromInternalProjectContacts.DataState) =>
      (data[projectId] ?? [])
        .map((projectContact) => projectContact.contactId)
        .includes(contactId)
  );

export const externalProjectContactContactsSelector = (projectId) =>
  createSelector<
    [
      (state: any) => fromExternalProjectContacts.DataState,
      (state: any) => ContactsByIdState
    ],
    Contact[]
  >(
    getExternalProjectContactsData,
    contactsByIdSelector,
    (data: fromExternalProjectContacts.DataState, byId: ContactsByIdState) =>
      (data[projectId] ?? [])
        .map((projectContact) => byId[projectContact.contactId])
        .filter((item) => !!item)
        .sort((item) => item.lastName.localeCompare(item.lastName))
  );

export const externalProjectContactsSelector = (projectId) =>
  createSelector<
    [
      (state: any) => fromExternalProjectContacts.DataState,
      (state: any) => ContactsByIdState
    ],
    ExternalProjectContactItem[]
  >(
    getExternalProjectContactsData,
    contactsByIdSelector,
    (data: fromExternalProjectContacts.DataState, byId: ContactsByIdState) =>
      (data[projectId] ?? [])
        .map((projectContact) => ({
          externalProjectContact: projectContact,
          contact: byId[projectContact.contactId],
        }))
        .filter((item) => !!item.contact)
        .sort((item) =>
          item.contact.lastName.localeCompare(item.contact.lastName)
        )
  );
export const projectCompaniesSelector = (projectId) =>
  createSelector<
    [
      (state: any) => fromExternalProjectContacts.DataState,
      (state: any) => ContactsByIdState,
      (state: any) => CompaniesByIdState
    ],
    Company[]
  >(
    getExternalProjectContactsData,
    contactsByIdSelector,
    companiesByIdSelector,
    (
      data: fromExternalProjectContacts.DataState,
      contactsById: ContactsByIdState,
      companiesById: CompaniesByIdState
    ) => {
      if (!contactsById || !companiesById) return [];
      return Array.from(
        new Set(
          (data[projectId] ?? [])
            .map(
              (projectContact) =>
                (
                  contactsById[projectContact.contactId] as InternalContact &
                    ExternalContact
                )?.companyId
            )
            .filter((companyId) => !!companyId)
        )
      )
        .map((companyId) => companiesById[companyId])
        .filter((company) => !!company);
    }
  );

export const getFavoriteProjects = createSelector<
  [
    (state: RootReducerState) => ProjectByIdState,
    (state: RootReducerState) => ProjectId[]
  ],
  Project[]
>(getProjectByIdState, getMyProjectIds, (byId, ids) =>
  ids
    .map((id) => byId[id])
    .filter((project) => project?.favorite)
    .sort((a: Project, b: Project) => {
      const numberCompare = a.number.localeCompare(b.number);
      if (numberCompare !== 0) return numberCompare;
      return a.name.localeCompare(b.name);
    })
);

export const getFavoriteProjectsWithMail = createSelector<
  [
    (state: RootReducerState) => ProjectByIdState,
    (state: RootReducerState) => ProjectId[]
  ],
  Project[]
>(getProjectByIdState, getMyProjectIds, (byId, ids) =>
  ids
    .map((id) => byId[id])
    .filter((project) => project?.favorite && project?.sharedMailboxUserId)
    .sort((a: Project, b: Project) => {
      const numberCompare = a.number.localeCompare(b.number);
      if (numberCompare !== 0) return numberCompare;
      return a.name.localeCompare(b.name);
    })
);

export const getFavoriteProjectsLength = createSelector<
  [
    (state: RootReducerState) => ProjectByIdState,
    (state: RootReducerState) => ProjectId[]
  ],
  number
>(
  getProjectByIdState,
  getMyProjectIds,
  (byId, ids) => ids.filter((id) => byId[id]?.favorite).length
);

export const projectsSelector = (type: ProjectTabViews) =>
  createSelector<
    [
      (state: RootReducerState) => Project[],
      (state: RootReducerState) => Project[]
    ],
    Project[]
  >(
    getProjectsOverview,
    getAllProjects,
    (projectsOverview: Project[], allProjects: Project[]) => {
      switch (type) {
        case 'all': {
          return projectsOverview;
        }
        case 'myProjects': {
          return allProjects;
        }
        case 'favorites': {
          return allProjects.filter((p) => p.favorite);
        }
        default: {
          return allProjects;
        }
      }
    }
  );

export const internalOfficeContactContactSelector = (officeId: OfficeId) =>
  createSelector<
    [
      (state: RootReducerState) => ContactId[],
      (state: RootReducerState) => ContactsByIdState
    ],
    Contact[]
  >(getContactIdsState, getContactsByIdState, (ids, byId) =>
    (ids ?? []).reduce((currentArray, contactId) => {
      const contact = byId[contactId];
      if (
        contact &&
        contact.officeId === officeId &&
        contact.contactType === 'InternalContact'
      ) {
        currentArray.push(contact);
      }
      return currentArray;
    }, [] as Contact[])
  );
