import React from 'react';
import PrioSpinner from '../../../components/PrioSpinner';
import { PublicClientApplication } from '@azure/msal-browser';

interface OfficeRedirectSignInProps {
  msalInstance: PublicClientApplication;
}
export const OfficeRedirectSignIn: React.FC<OfficeRedirectSignInProps> = (
  props
) => {
  const { msalInstance } = props;

  React.useEffect(() => {
    var inProgress = localStorage.getItem('officeLoginInProgress');
    if (inProgress === null || inProgress === '') {
      localStorage.setItem('officeLoginInProgress', 'true');
      msalInstance.loginRedirect();
    } else {
      setTimeout(() => {
        Office?.context.ui.messageParent(JSON.stringify({ status: 'success' }));
      }, 3000);
    }
  });

  return <PrioSpinner />;
};

export default OfficeRedirectSignIn;
