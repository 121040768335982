import { Reducer, useReducer } from 'react';
import {
  AdvancedDocumentSearchDto,
  AdvancedDocumentSearchAction,
} from '../../../../models/Document';

export const useDocumentSearch = () => {
  const initialState: AdvancedDocumentSearchDto = {};

  const [advancedSearchTerm, dispatchAdvancedSearchTerm] = useReducer<
    Reducer<AdvancedDocumentSearchDto, AdvancedDocumentSearchAction>
  >(
    (
      state: AdvancedDocumentSearchDto,
      action: AdvancedDocumentSearchAction
    ) => {
      const filter = action.type;
      const value = action.value;

      switch (filter) {
        case 'QUICKSEARCH': {
          const advancedSearchDto = value as AdvancedDocumentSearchDto;
          return advancedSearchDto;
        }
        case 'UPDATE': {
          const advancedSearchDto = value as AdvancedDocumentSearchDto;
          return advancedSearchDto;
        }
        case 'RESET': {
          return initialState;
        }
        default: {
          const newState = {
            ...state,
            [filter]: value,
          };

          return newState;
        }
      }
    },
    initialState
  );

  return {
    advancedSearchTerm,
    dispatchAdvancedSearchTerm,
  };
};
