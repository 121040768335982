import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BasisPicker,
  BasisPickerSelectOptions,
} from '../../../../components/BasisPicker';
import { FormInstance } from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { TaskStatus, TodoTaskFormModel } from '../../../../models/Todo';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface StatusPickerProps {
  form?: FormInstance<TodoTaskFormModel>;
  disabled?: boolean;
}

export const StatusPicker: React.FC<StatusPickerProps> = (props) => {
  const { form, disabled } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const selectOptions: BasisPickerSelectOptions[] = [
    {
      label: t('tasks:todoForm.taskStatusPicker.options.notStarted'),
      value: 'notStarted',
      icon: {
        name: 'circle',
        color: theme.old.palette.chromaticPalette.grey,
      },
    },
    {
      label: t('tasks:todoForm.taskStatusPicker.options.inProgress'),
      value: 'inProgress',
      icon: {
        name: 'adjust',
        color: theme.old.palette.chromaticPalette.blue,
        rotation: 270,
        prefix: 'fas',
      },
    },
    {
      label: t('tasks:todoForm.taskStatusPicker.options.completed'),
      value: 'completed',
      icon: {
        name: 'check-circle',
        color: theme.old.palette.chromaticPalette.green,
      },
    },
    {
      label: t('tasks:todoForm.taskStatusPicker.options.waitingOnOthers'),
      value: 'waitingOnOthers',
      icon: {
        name: 'dot-circle',
        color: theme.old.palette.chromaticPalette.yellow,
      },
    },
    {
      label: t('tasks:todoForm.taskStatusPicker.options.deferred'),
      value: 'deferred',
      icon: {
        name: 'times-circle',
        color: theme.old.palette.chromaticPalette.red,
      },
    },
  ];

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnSelect = (status: TaskStatus) => {
    form.setFieldsValue({
      status: status,
    });
  };
  //#endregion

  //#region ------------------------------ useEffects
  //#endregion

  return (
    <BasisPicker
      className={classes.root}
      disabled={disabled}
      onSelect={handleOnSelect}
      suffixIconFontAwesome={['fal', 'dot-circle']}
      allowClear
      items={selectOptions}
    />
  );
};

export default StatusPicker;
