import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMyTimeRecords,
  getTimeRecordsIsFetching,
} from '../../../apps/main/rootReducer';
import {
  groupBy,
  formatMinutes,
  truncateString,
  isoDateFormat,
  todayDateString,
  groupByFunction,
} from '../../../util';
import { Table, Typography, Divider } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { TimeRecord } from '../../../models/TimeRecord';
import { ProjectText } from '../../projects/components/ProjectText';
import { ColumnProps } from 'antd/lib/table';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useTranslation } from 'react-i18next';
import { TimeRecordId } from '../../../models/Types';
import { sumUpDurationInMinutes, sumUpDurationInMinutesOfDay } from '../util';
import { fetchMyTimeRecords } from '../actions';
import PrioSpinner from '../../../components/PrioSpinner';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    fontSize: 14,
    height: '100%',
  },
  spin: {
    height: '100%',
    justifyContent: 'center',
  },
  table: {
    whiteSpace: 'nowrap',
    '&.ant-table-wrapper': {
      marginTop: 0,
    },
    '& .ant-table': {
      fontSize: 14,
    },
    '& .ant-table-row.ant-table-row-level-0:hover > td': {
      background: theme.old.palette.backgroundPalette.hover.content,
    },
    '& .ant-table-row.ant-table-row-level-0.expanded-row': {
      background: theme.old.palette.backgroundPalette.hover.content,
      '&:hover > td': {
        background: theme.old.palette.backgroundPalette.hover.sub,
      },
    },
    '& .ant-table-row.ant-table-row-level-1': {
      background: theme.old.palette.backgroundPalette.hover.content,
      '&:hover > td': {
        background: theme.old.palette.backgroundPalette.hover.sub,
      },
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  today: {
    marginBottom: theme.old.spacing.unit(2),
    paddingRight: theme.old.spacing.unit(2),
  },
  lastDays: {
    marginTop: `${theme.old.spacing.unit(4)}px !important`,
    marginBottom: theme.old.spacing.unit(2),
    paddingRight: theme.old.spacing.unit(2),
  },
  row: {
    cursor: 'pointer',
    '& > .ant-table-cell-with-append': {
      width: 290,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
  },
  loadItem: {
    paddingTop: theme.old.spacing.unit(1),
    display: 'flex',
    justifyContent: 'center',
  },
  scrollable: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

interface MyTimeRecordsOverviewProps {
  onTimeRecordClick?: (timeRecordId: TimeRecordId) => void;
}

interface TableEntryKW {
  key: string;
  fromTo?: string;
  kwNumber?: number;
  sum?: string;
  days?: TableEntryDay[];
}

interface TableEntryDay {
  key: string;
  children?: TableEntry[];
}

interface TableEntry extends TimeRecord {
  key: string;
}

export const MyTimeRecordsOverview: React.FC<MyTimeRecordsOverviewProps> = (
  props
) => {
  const { onTimeRecordClick } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myTimeRecords = useSelector(getMyTimeRecords);

  const isFetching = useSelector(getTimeRecordsIsFetching);

  const today = todayDateString;
  const todayFormatted = moment().format('ddd DD.MM.YYYY');

  const groupedTimeRecords = groupBy(myTimeRecords, 'day');

  const todayData: TableEntry[] = groupedTimeRecords[today]?.map(
    (timeRecord) => ({
      ...timeRecord,
      key: timeRecord.timeRecordId,
      durationInMinutes:
        timeRecord.durationInMinutes ??
        sumUpDurationInMinutes(timeRecord.timeRecordEntries),
    })
  );

  //Use moment-range instead?
  const groupedKWs = groupByFunction(
    Object.keys(groupedTimeRecords)
      .filter((record) => moment(today).diff(moment(record), 'days') !== 0)
      .sort((a, b) => moment(b).diff(moment(a), 'days')),
    (day) => {
      const kwStart = moment(day).startOf('week');
      const kwEnd = moment(day).endOf('week');
      return `${kwStart.format(isoDateFormat)} - ${kwEnd.format(
        isoDateFormat
      )}`;
    }
  );

  const [loadedKWs, setLoadedKWs] = useState<number>(4);

  useEffect(() => {
    dispatch(
      fetchMyTimeRecords(
        moment().subtract(loadedKWs, 'weeks').format(isoDateFormat),
        todayDateString
      )
    );
  }, [loadedKWs, dispatch]);

  const lastKWs = Object.keys(groupedKWs)
    .filter((key) => groupedKWs[key].length)
    .sort((a, b) => {
      const startA = moment(groupedKWs[a][0]);
      const startB = moment(groupedKWs[b][0]);
      return startB.diff(startA);
    })
    .slice(0, loadedKWs);

  const formatKW: (day: string) => string = (day) => {
    const kwStart = moment(day).startOf('week');
    const kwEnd = moment(day).endOf('week');
    const sameYear = kwEnd.diff(kwStart, 'years') === 0;
    return `${kwStart.format(
      sameYear ? 'DD.MM.' : 'DD.MM.YYYY'
    )} - ${kwEnd.format('DD.MM.YYYY')}`;
  };

  const lastKWsData: TableEntryKW[] = lastKWs.map((kw) => ({
    key: kw,
    fromTo: formatKW(groupedKWs[kw][0]),
    kwNumber: moment(groupedKWs[kw][0]).week(),
    sum: formatMinutes(
      groupedKWs[kw].reduce(
        (sum, day) =>
          sum + sumUpDurationInMinutesOfDay(groupedTimeRecords[day]),
        0
      )
    ),
    days: groupedKWs[kw].map((day) => ({
      key: day,
      day: moment(day, isoDateFormat).format('dd. DD.MM.YYYY'),
      durationInMinutes: sumUpDurationInMinutesOfDay(groupedTimeRecords[day]),
      children: groupedTimeRecords[day].map((value) => ({
        ...value,
        key: value.timeRecordId,
        durationInMinutes:
          value.durationInMinutes ??
          sumUpDurationInMinutes(value.timeRecordEntries),
      })),
    })),
  }));

  const columns: ColumnProps<TableEntryDay & TableEntry>[] = [
    {
      title: 'Tag / Projekt',
      dataIndex: 'day',
      key: 'day',
      render: (value, record: TableEntry) =>
        record.projectId ? (
          <ProjectText
            projectId={record.projectId}
            shortName
            showNumber
            className={classes.title}
            style={{ width: 250 }}
          />
        ) : (
          value
        ),
      width: 250,
    },
    {
      title: 'Titel',
      dataIndex: 'title',
      key: 'title',
      render: (value) =>
        value && (
          <div style={{ width: 153, overflow: 'hidden' }}>
            {' '}
            {truncateString(value, 25)}
          </div>
        ),
      width: 153,
    },
    {
      title: 'Duration',
      dataIndex: 'durationInMinutes',
      key: 'durationInMinutes',
      align: 'right',
      render: (value) => (
        <div style={{ maxWidth: 77, overflow: 'hidden' }}>
          {' '}
          {formatMinutes(value ?? 0)}{' '}
        </div>
      ),
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleOnExpand = (expanded: boolean, record: TableEntryDay) => {
    const keys = expandedRowKeys;
    const expandedKeys = expanded
      ? keys.concat(record.key)
      : keys.filter((k) => k !== record.key);
    setExpandedRowKeys(expandedKeys);
  };

  if (isFetching && myTimeRecords.length === 0) {
    return (
      <div className="prio-flex-center-center prio-flex-column">
        <PrioSpinner size="large" />
      </div>
    );
  }

  return (
    <div className={classes.scrollable}>
      <Flex.Column
        childrenGap={theme.old.spacing.unit(1)}
        paddingTop={theme.old.spacing.unit(1)}
        className={classes.root}
      >
        <Flex.Row
          className={classes.today}
          alignItems="center"
          childrenGap={theme.old.spacing.baseSpacing}
        >
          <Flex.Row
            flex={1}
            childrenGap={theme.old.spacing.baseSpacing}
            alignItems="center"
          >
            <Typography.Text className={classes.title}>
              {t('common:today')},
            </Typography.Text>
            <Typography.Text>{todayFormatted}</Typography.Text>
          </Flex.Row>
          {todayData && (
            <Typography.Text className={classes.title}>
              {formatMinutes(
                todayData.reduce(
                  (sum, record) => sum + record.durationInMinutes,
                  0
                )
              )}
            </Typography.Text>
          )}
        </Flex.Row>
        <Divider style={{ margin: 0 }} />
        {todayData?.length ? (
          <Table
            className={classes.table}
            columns={columns}
            dataSource={todayData}
            showHeader={false}
            pagination={false}
            onRow={
              onTimeRecordClick &&
              ((record: TableEntryDay & TableEntry, rowIndex) => {
                return {
                  onClick: () => {
                    if (record.timeRecordId) {
                      onTimeRecordClick(record.timeRecordId);
                    }
                  }, // click row
                };
              })
            }
            rowClassName={classes.row}
          />
        ) : (
          <Typography.Text>{t('common:noData')}</Typography.Text>
        )}
        {lastKWsData.map((kw) => (
          <Flex.Column key={kw.key}>
            <Flex.Row
              className={classes.lastDays}
              childrenGap={theme.old.spacing.baseSpacing}
            >
              <Flex.Row
                flex={1}
                childrenGap={theme.old.spacing.baseSpacing}
                alignItems="center"
              >
                <Typography.Text className={classes.title}>
                  {`KW ${kw.kwNumber}`}
                </Typography.Text>
                <Typography.Text>{kw.fromTo}</Typography.Text>
              </Flex.Row>
              <Typography.Text className={classes.title}>
                {kw.sum}
              </Typography.Text>
            </Flex.Row>
            <Divider style={{ margin: 0 }} />
            <Table
              className={classes.table}
              columns={columns}
              dataSource={kw.days}
              showHeader={false}
              pagination={false}
              onRow={
                onTimeRecordClick &&
                ((record: TableEntryDay & TableEntry) => {
                  return {
                    className: expandedRowKeys.includes(record.key)
                      ? 'expanded-row'
                      : classes.row,
                    onClick: () => {
                      if (record.timeRecordId) {
                        onTimeRecordClick(record.timeRecordId);
                      } else {
                        handleOnExpand(
                          !expandedRowKeys.includes(record.key),
                          record
                        );
                      }
                    }, // click row
                  };
                })
              }
              onExpand={handleOnExpand}
              expandedRowKeys={expandedRowKeys}
              indentSize={0}
            />
          </Flex.Column>
        ))}
        <Flex.Item className={classes.loadItem}>
          <Button
            type="link"
            onClick={() => setLoadedKWs(loadedKWs + 4)}
            iconProp={['fal', 'sync']}
          >
            {t('timeRecords:taskPane.tabs.actions.loadOlderEntries')}
          </Button>
        </Flex.Item>
      </Flex.Column>
    </div>
  );
};

export default MyTimeRecordsOverview;
