import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../../actions';
import {
  FlagMessagePayload,
  MarkAsReadPayload,
  Message,
  MessageCategorizationPayload,
} from '../../../../models/Message';
import { MailFolderId, MessageId } from '../../../../models/Types';
import { WS_RECONNECT } from '../../../../sagas/watchWebsocketReconnect';
import { distinct } from '../../../../util';
import { SAGA_REBUILD } from '../../../../util/sagas';
import {
  DELETE_LOCAL_MESSAGES,
  DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME,
} from '../../actions/actionControllers/messageActionController';
import {
  FETCH_MESSAGES_ME_REQUEST,
  FETCH_MESSAGES_ME_ROLLBACK,
  FETCH_MESSAGES_ME_COMMIT,
  DELETE_MESSAGES_ME_REQUEST,
  DELETE_MESSAGES_ME_ROLLBACK,
  CATEGORIZED_MESSAGE_ME,
  MARK_AS_READ_MESSAGE_ME_REQUEST,
  MARK_AS_READ_MESSAGE_ME_ROLLBACK,
  FLAG_MESSAGE_ME_REQUEST,
  FLAG_MESSAGE_ME_ROLLBACK,
  UPDATE_MESSAGE_ME,
  DELETE_LOCAL_MESSAGE_ME,
  FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT,
  FETCH_MESSAGE_ME_INLINE_IMAGE_REQUEST,
  FETCH_MESSAGE_ME_INLINE_IMAGE_ROLLBACK,
  FETCH_MESSAGE_ME_DECODE_MIME_COMMIT,
  SOFTDELETE_MESSAGES_ME_REQUEST,
  SOFTDELETE_MESSAGES_ME_ROLLBACK,
  SOFTDELETE_MESSAGES_ME_COMMIT,
  PUT_MESSAGE_ME_ATTACHMENT_TO_CACHE,
  COPY_MAIL_ME_TO_PROJECT_COMMIT,
  MOVE_MESSAGE_ME_COMMIT,
  FETCH_MESSAGE_ME_COMMIT,
  MOVE_MESSAGE_ME_REQUEST,
  MOVE_MESSAGE_ME_ROLLBACK,
  WS_REVERT_MESSAGE_DELETE_ME,
  WS_REVERT_MESSAGE_MOVE_ME,
  DELETE_MESSAGES_ME_COMMIT,
  ADD_ME_MESSAGE_TO_CACHE,
  UPDATE_MESSAGES_ME,
} from '../../actions/me/messagesMe';
import { MessageIdsByMailFolderId, ByMessageId } from '../../actions/types';
import {
  WS_EMAIL_ME_CREATED,
  WS_EMAIL_ME_DELETED,
  WS_EMAIL_ME_UPDATED,
} from '../../actions/ws';

export interface MessagesState {
  byId: ByMessageId;
  ids: MessageIdsByMailFolderId;
  meta: MessagesMeta;
  nextLink: NextLinkState;
}

export const initialState: MessagesState = {
  byId: {},
  ids: {},
  meta: {
    isFetching: [],
    hasError: false,
    isSingleMessageFetching: false,
  },
  nextLink: {},
};

const byId: Reducer<ByMessageId, any> = (state = initialState.byId, action) => {
  switch (action.type) {
    case FETCH_MESSAGES_ME_COMMIT: {
      const { payload } = action;
      return (payload.messages as Array<Message>).reduce(function (
        map: ByMessageId | {},
        item
      ) {
        map[item.id] = item;
        return map;
      }, state);
    }

    case FETCH_MESSAGE_ME_COMMIT: {
      const { payload } = action;
      const { body, event, messageAttachments, ...restMessage } =
        payload as Message;
      return {
        ...state,
        [payload.id]: {
          ...restMessage,
          attachments: messageAttachments ?? state[payload.id]?.attachments,
        },
      };
    }

    case CATEGORIZED_MESSAGE_ME: {
      const { payload } = action;
      const { messages, isDelete } = payload as MessageCategorizationPayload;
      return messages.reduce(
        function (map, { messageId, categories }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              categories: isDelete
                ? (map[messageId]?.categories ?? [])?.filter(
                    (category) => !categories.includes(category)
                  )
                : distinct([
                    ...(map[messageId]?.categories ?? []),
                    ...categories,
                  ]),
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case MARK_AS_READ_MESSAGE_ME_REQUEST: {
      const { payload } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        function (map, { messageId, isRead }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case MARK_AS_READ_MESSAGE_ME_ROLLBACK: {
      const {
        meta: { payload },
      } = action;
      const changes = payload as MarkAsReadPayload;
      return changes.reduce(
        function (map, { messageId, isRead }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              isRead: !isRead,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case FLAG_MESSAGE_ME_REQUEST: {
      const { payload } = action;
      const changes = payload as FlagMessagePayload;
      return changes.reduce(
        function (map, { messageId, flag }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }
    case FLAG_MESSAGE_ME_ROLLBACK: {
      const {
        meta: { originalPayload },
      } = action;
      const changes = originalPayload as FlagMessagePayload;
      return changes.reduce(
        function (map, { messageId, flag }) {
          if (map[messageId]) {
            map[messageId] = {
              ...map[messageId],
              flag,
            };
          }
          return map;
        },
        { ...state }
      );
    }

    case COPY_MAIL_ME_TO_PROJECT_COMMIT: {
      const {
        meta: { deleteMail, messages },
      } = action;
      if (deleteMail) {
        const messageIdsToDelete = messages.map((message) => message.id);
        return Object.keys(state).reduce((newState, messageId) => {
          if (messageIdsToDelete.includes(messageId)) {
            return newState;
          }
          return {
            ...newState,
            [messageId]: state[messageId],
          };
        }, {});
      }
      return state;
    }

    case UPDATE_MESSAGE_ME: {
      const {
        payload: { messageUpdate },
        meta: { messageId },
      }: {
        payload: { messageUpdate: Partial<Message> };
        meta: { messageId: MessageId };
      } = action;
      if (state[messageId]) {
        return {
          ...state,
          [messageId]: {
            ...(state[messageId] ?? {}),
            ...messageUpdate,
          },
        };
      }
      return state;
    }
    case UPDATE_MESSAGES_ME: {
      const {
        payload: { messageUpdates },
        meta: { messageIds },
      } = action;
    
      return messageIds.reduce((map, currentId) => {
        const existingMessage = map[currentId];
        const update = messageUpdates.find((u) => u.messageId === currentId);
    
        if (existingMessage && update) {
          map[currentId] = {
            ...existingMessage,
            ...update.messageUpdate,
          };
        }
    
        return map;
      }, { ...state });
    }
    case SOFTDELETE_MESSAGES_ME_REQUEST: {
      const {
        payload: { messageIds },
      } = action;
      return (messageIds as MessageId[]).reduce((map, currentId) => {
        const { [currentId]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }
    case SOFTDELETE_MESSAGES_ME_ROLLBACK: {
      const {
        meta: { rollbackMessages },
      } = action;
      return (rollbackMessages as Message[]).reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, state);
    }
    case DELETE_LOCAL_MESSAGE_ME: {
      const {
        meta: { messageId },
      } = action;
      if (state[messageId]) {
        const { [messageId]: _, ...rest } = state;
        return { ...rest };
      }
      return state;
    }

    case FETCH_MESSAGE_ME_DECODE_MIME_COMMIT:
    case FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT: {
      const {
        payload,
        meta: { messageId },
      } = action;
      return {
        ...state,
        [messageId]: payload,
      };
    }

    case WS_EMAIL_ME_UPDATED: {
      const {
        payload: { message },
      }: { payload: { message: Message } } = action;
      if (state[message.id]) {
        return {
          ...state,
          [message.id]: message,
        };
      }
      return state;
    }

    case WS_EMAIL_ME_CREATED: {
      const {
        payload: { message },
      }: { payload: { message: Message } } = action;
      return {
        ...state,
        [message.id]: message,
      };
    }
    case WS_EMAIL_ME_DELETED:
    case DELETE_MESSAGES_ME_COMMIT: {
      const {
        meta: { messageIds },
      } = action;
      return ((messageIds as string[]) ?? []).reduce((map, id) => {
        const { [id]: message, ...rest } = map;
        if (message) {
          return rest;
        }
        return map;
      }, state);
    }

    case MOVE_MESSAGE_ME_REQUEST: {
      const {
        meta: { destinationId, messageIds, inboxFolderId },
      } = action;

      const parentFolderId: MailFolderId =
        destinationId === 'inbox' ? inboxFolderId : destinationId;

      return {
        ...state,
        ...messageIds.reduce((map: ByMessageId, id: MessageId) => {
          const message = state[id];
          if (message) {
            return {
              ...map,
              [id]: { ...message, parentFolderId },
            };
          }
          return map;
        }, {} as ByMessageId),
      };
    }

    case MOVE_MESSAGE_ME_COMMIT: {
      const {
        payload: { messages, failedMessageIds },
        meta: { messageIds },
      } = action;

      const removedMessageIds = messageIds.filter(
        (messageId) => !failedMessageIds.includes(messageId)
      );

      return {
        ...Object.keys(state).reduce((map, messageId) => {
          if (removedMessageIds.includes(messageId)) {
            return map;
          }
          return {
            ...map,
            [messageId]: state[messageId],
          };
        }, {}),
        ...messages.reduce(
          (map, message) => ({
            ...map,
            [message.id]: message,
          }),
          {}
        ),
      };
    }

    case MOVE_MESSAGE_ME_ROLLBACK: {
      const {
        meta: { originId, messageIds, inboxFolderId },
      } = action;

      const parentFolderId = originId === 'inbox' ? inboxFolderId : originId;

      return {
        ...state,
        ...messageIds.reduce((map, id) => {
          const message = state[id];
          if (message) {
            return {
              ...map,
              [id]: { ...message, parentFolderId },
            };
          }
          return map;
        }, {}),
      };
    }

    case WS_REVERT_MESSAGE_MOVE_ME: {
      const { messageId, message } = action;

      return {
        ...state,
        [messageId]: message,
      };
    }

    case WS_REVERT_MESSAGE_DELETE_ME: {
      const { messageId, message } = action;

      return {
        ...state,
        [messageId]: message,
      };
    }

    case PUT_MESSAGE_ME_ATTACHMENT_TO_CACHE: {
      const {
        meta: { messageId },
        payload,
      } = action;
      if (state[messageId]) {
        return {
          ...state,
          [messageId]: {
            ...state[messageId],
            messageAttachments: payload,
          },
        };
      }
      return state;
    }

    case ADD_ME_MESSAGE_TO_CACHE: {
      const { message } = action;
      if (message && !state[message.id]) {
        const { body, event, messageAttachments, ...restMessage } =
          message as Message;
        return {
          ...state,
          [message.id]: {
            ...restMessage,
            attachments: messageAttachments ?? state[message.id]?.attachments,
          },
        };
      }
      return state;
    }

    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME:
    case SAGA_REBUILD:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.byId;
    }
    default: {
      return state;
    }
  }
};

const ids: Reducer<MessageIdsByMailFolderId, any> = (
  state = initialState.ids,
  action
) => {
  switch (action.type) {
    case FETCH_MESSAGES_ME_COMMIT: {
      const {
        payload,
        meta: { mailFolderId, nextLink, movingMessageIds },
      } = action;

      const keepedMovingMessageIds = (state[mailFolderId] ?? []).filter(
        (messageId) => movingMessageIds.includes(messageId)
      );

      return {
        ...state,
        [mailFolderId]: distinct(
          !(nextLink === null || nextLink === undefined)
            ? state[mailFolderId]
              ? [
                  ...state[mailFolderId],
                  ...(payload.messages as Array<Message>).map(
                    (item) => item.id
                  ),
                  ...keepedMovingMessageIds,
                ]
              : [
                  ...(payload.messages as Array<Message>).map(
                    (item) => item.id
                  ),
                  ...keepedMovingMessageIds,
                ]
            : [
                ...(payload.messages as Array<Message>).map((item) => item.id),
                ...keepedMovingMessageIds,
              ]
        ),
      };
    }

    case SOFTDELETE_MESSAGES_ME_REQUEST:
    case DELETE_MESSAGES_ME_REQUEST: {
      const {
        payload: { messageIds },
        meta,
      } = action;
      const mailFolderId = meta.mailFolderId;
      if (mailFolderId) {
        return {
          ...state,
          [mailFolderId]: (state[mailFolderId] ?? [])?.filter(
            (id) => !messageIds.includes(id)
          ),
        };
      }
      return state;
    }
    case SOFTDELETE_MESSAGES_ME_COMMIT: {
      const { meta } = action;
      const mailFolderId = meta.mailFolderId;
      const messageIds = meta.messageIds;
      if (!mailFolderId) {
        return Object.keys(state).reduce((map, currentId) => {
          map[currentId] = (map[currentId] ?? [])?.filter(
            (id) => !messageIds.includes(id)
          );
          return map;
        }, state);
      }
      return state;
    }
    case SOFTDELETE_MESSAGES_ME_ROLLBACK:
    case DELETE_MESSAGES_ME_ROLLBACK: {
      const { meta } = action;
      const messageIds = meta.messageIds;
      const mailFolderId = meta.mailFolderId;
      if (mailFolderId) {
        return {
          ...state,
          [mailFolderId]: distinct([
            ...(state[mailFolderId] ?? []),
            ...messageIds,
          ]),
        };
      }
      return state;
    }

    case MOVE_MESSAGE_ME_REQUEST: {
      const {
        meta: { messageIds, originId, destinationId },
      } = action;

      return {
        ...state,
        [originId]: (state[originId] ?? [])?.filter(
          (mId) => !messageIds.includes(mId)
        ),
        [destinationId]: distinct([
          ...(state[destinationId] ?? []),
          ...messageIds,
        ]),
      };
    }

    case MOVE_MESSAGE_ME_COMMIT: {
      const {
        payload: { messages },
        meta: { destinationId, messageIds },
      } = action;

      const redirectedMessageIds = messages.map(({ id }) => id);

      return {
        ...state,
        [destinationId]: distinct([
          ...(state[destinationId] ?? []).filter((messageId) =>
            messageIds.includes(messageId)
          ),
          ...redirectedMessageIds,
        ]),
      };
    }

    case MOVE_MESSAGE_ME_ROLLBACK: {
      const {
        meta: { messageIds, originId, destinationId },
      } = action;

      return {
        ...state,
        [originId]: distinct([...(state[originId] ?? []), ...messageIds]),
        [destinationId]: (state[destinationId] ?? [])?.filter(
          (mId) => !messageIds.includes(mId)
        ),
      };
    }

    case COPY_MAIL_ME_TO_PROJECT_COMMIT: {
      const {
        meta: { deleteMail, messages },
      } = action;
      if (deleteMail) {
        const messageIdsByMailFolderId: MessageIdsByMailFolderId =
          messages.reduce(
            (map, message) => ({
              ...map,
              [message.parentFolderId]: distinct([
                ...(map[message.parentFolderId] ?? []),
                message.id,
              ]),
            }),
            {}
          );
        return Object.keys(messageIdsByMailFolderId).reduce(
          (map, mailFolderId) => {
            if (map[mailFolderId]) {
              return {
                ...map,
                [mailFolderId]: (map[mailFolderId] ?? [])?.filter(
                  (messageId) =>
                    !messageIdsByMailFolderId[mailFolderId].includes(messageId)
                ),
              };
            } else if (
              map.inbox?.find((messageId) =>
                messageIdsByMailFolderId[mailFolderId].includes(messageId)
              )
            ) {
              return {
                ...map,
                inbox: (map.inbox ?? [])?.filter(
                  (messageId) =>
                    !messageIdsByMailFolderId[mailFolderId].includes(messageId)
                ),
              };
            }
            return map;
          },
          state
        );
      }
      return state;
    }

    case DELETE_LOCAL_MESSAGE_ME: {
      const {
        meta: { mailFolderId, messageId },
      } = action;
      return {
        ...state,
        [mailFolderId]: (state[mailFolderId] ?? [])?.filter(
          (id) => id !== messageId
        ),
      };
    }

    case WS_EMAIL_ME_DELETED: {
      const {
        payload: { messageIds },
        meta,
      } = action;
      const mailFolderId = meta.mailFolderId;
      if (mailFolderId) {
        return {
          ...state,
          [mailFolderId]: (state[mailFolderId] ?? [])?.filter(
            (id) => !messageIds.includes(id)
          ),
        };
      }
      return Object.keys(state).reduce((map, currentId) => {
        map[currentId] = (map[currentId] ?? [])?.filter(
          (id) => !messageIds.includes(id)
        );
        return map;
      }, state);
    }

    case WS_EMAIL_ME_CREATED: {
      const {
        payload: { message },
        meta: { mailFolderId },
      }: {
        payload: { message: Message };
        meta: { mailFolderId: MailFolderId };
      } = action;
      if (!mailFolderId) return state;
      if (state[mailFolderId]?.includes(message.id)) {
        return state;
      }
      return {
        ...state,
        [mailFolderId]: distinct([...(state[mailFolderId] ?? []), message.id]),
      };
    }

    case WS_REVERT_MESSAGE_MOVE_ME: {
      const { destinationId, messageId, message, inboxFolderId } = action;

      const sourceId =
        inboxFolderId === message.parentFolderId
          ? 'inbox'
          : message.parentFolderId;
      const _destinationId =
        inboxFolderId === destinationId ? 'inbox' : destinationId;

      return {
        ...state,
        [_destinationId]: (state[_destinationId] ?? []).filter(
          (id) => id !== messageId
        ),
        [sourceId]: distinct([...(state[sourceId] ?? []), messageId]),
      };
    }

    case WS_REVERT_MESSAGE_DELETE_ME: {
      const { sourceId, messageId } = action;

      return {
        ...state,
        [sourceId]: distinct([...(state[sourceId] ?? []), messageId]),
      };
    }

    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME:
    case SAGA_REBUILD:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.ids;
    }
    default:
      return state;
  }
};

interface MessagesMeta {
  isFetching: MailFolderId[];
  hasError: boolean;
  errorMessage?: string;
  isSingleMessageFetching?: boolean;
}

const meta: Reducer<MessagesMeta, any> = (
  state = initialState.meta,
  action
) => {
  switch (action.type) {
    case FETCH_MESSAGES_ME_REQUEST: {
      const {
        meta: { mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: [...state.isFetching, mailFolderId],
      };
    }
    case FETCH_MESSAGES_ME_COMMIT: {
      const {
        meta: { mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: state.isFetching.filter((id) => id !== mailFolderId),
      };
    }
    case FETCH_MESSAGES_ME_ROLLBACK: {
      const {
        meta: { mailFolderId },
      } = action;
      return {
        ...state,
        isFetching: state.isFetching.filter((id) => id !== mailFolderId),
        hasError: true,
        errorMessage: 'mail:errorMessages.messages.fetchError',
      };
    }
    case FETCH_MESSAGE_ME_INLINE_IMAGE_REQUEST: {
      return {
        ...state,
        isSingleMessageFetching: true,
      };
    }
    case FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT: {
      return {
        ...state,
        isSingleMessageFetching: false,
      };
    }
    case FETCH_MESSAGE_ME_INLINE_IMAGE_ROLLBACK: {
      return {
        ...state,
        isSingleMessageFetching: false,
        hasError: true,
        errorMessage: 'mail:errorMessages.messages.fetchError',
      };
    }
    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME:
    case SAGA_REBUILD:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.meta;
    }
    default:
      return state;
  }
};

interface NextLinkState {
  [mailFolderId: string]: string;
}

const nextLink: Reducer<NextLinkState, any> = (
  state = initialState.nextLink,
  action
) => {
  switch (action.type) {
    case FETCH_MESSAGES_ME_COMMIT: {
      const {
        payload,
        meta: { mailFolderId },
      } = action;
      return {
        ...state,
        [mailFolderId]: payload.nextLink ?? '',
      };
    }
    case DELETE_LOCAL_MESSAGES_OF_MAILBOX_ME:
    case SAGA_REBUILD:
    case WS_RECONNECT:
    case DELETE_LOCAL_MESSAGES:
    case CLEAR_PRIO_CACHE: {
      return initialState.nextLink;
    }
    default:
      return state;
  }
};

export default combineReducers<MessagesState>({
  byId,
  ids,
  meta,
  nextLink,
});

export const getAllMessagesMe: (
  state: MessagesState,
  mailFolderId: string
) => Array<Message> = (state, mailFolderId) =>
  (state.ids[mailFolderId] ?? [])
    .map((id) => state.byId[id])
    .filter((message) => !!message)
    .sort((a: Message, b: Message) => {
      return Date.parse(b.receivedDateTime) - Date.parse(a.receivedDateTime);
    });

export const getMessagesById: (state: MessagesState) => {
  [messageId: string]: Message;
} = (state) => state.byId;

export const getMessage: (
  state: MessagesState,
  messageId: string
) => Message = (state, messageId) => state.byId[messageId];

export const getIsSingleMessageFetching: (state: MessagesState) => boolean = (
  state
) => state.meta.isSingleMessageFetching;
export const getIsFetching: (
  state: MessagesState,
  mailFolderId?: MailFolderId
) => boolean = (state, mailFolderId) => {
  if (mailFolderId) {
    return state.meta.isFetching.includes(mailFolderId);
  }
  return state.meta.isFetching.length > 0;
};
export const getHasError: (state: MessagesState) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: MessagesState) => string = (state) =>
  state.meta.errorMessage;

export const getNextLink: (
  state: MessagesState,
  mailFolderId: string
) => string = (state, mailFolderId) => {
  if (state.nextLink[mailFolderId] !== null) {
    return state.nextLink[mailFolderId];
  }
  return null;
};
