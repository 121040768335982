import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Flex from '../../../components/Flex';
import { useParams } from 'react-router-dom';
import NavigationBar from '../../../components/NavigationBar';
import { HRTrainingsMenu } from './HRTrainingsMenu';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Training } from '../../../models/Training';
import { HRTrainingsPage } from './HRTrainingsPage';
import { useSelector } from 'react-redux';
import { isBefore, parseISO } from 'date-fns';
import { Qualification } from '../../../models/Qualification';
import { getCompaniesByIdState } from '../../../apps/main/rootReducer';
import { Department } from '../../../models/Department';
import { apiFetchTrainings, apiFetchQualifications } from '../api';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
  },
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    width: '100%',
  },
  height: {
    height: 'calc(100% - 64px)',
  },
}));

interface HRTrainingsProps {
  className?: string;
  pathPrefix?: string;
}

export type QualificationsById = {
  [qualificationId: string]: Qualification;
};
export type DepartmentsById = {
  [departmentId: string]: Department;
};

export const HRTrainings: React.FC<HRTrainingsProps> = (props) => {
  const classes = useStyles();
  const { pathPrefix, className } = props;
  const { selectedList } = useParams();
  const { t } = useTranslation();

  const [allTrainings, setAllTrainings] = useState<Training[]>([]);
  const [qualificationsById, setQualificationsById] =
    useState<QualificationsById>({});

  const [newTrainingOpen, setNewTrainingOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchTrainings = useCallback(async () => {
    setIsLoading(true);
    const { data } = await apiFetchTrainings();
    if (data) {
      setAllTrainings(data.filter((t) => !t.isArchived));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.fetchTrainingsError'),
      });
    }
    setIsLoading(false);
  }, [t]);

  const fetchQualifications = useCallback(async () => {
    const { data } = await apiFetchQualifications();
    if (data && Array.isArray(data)) {
      const byId = data.reduce(function (map, item) {
        map[item.qualificationId] = item;
        return map;
      }, {});
      setQualificationsById(byId);
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:trainings.errorMessages.fetchQualificationsError'),
      });
    }
  }, [t]);

  useEffect(() => {
    fetchQualifications();
    fetchTrainings();
  }, [fetchTrainings, fetchQualifications]);

  const triggerReload = fetchTrainings;

  const createTraining = () => {
    setNewTrainingOpen(true);
  };
  const closeNewTraining = () => {
    setNewTrainingOpen(false);
  };

  const [pastTrainings, plannedTrainings] = useMemo(() => {
    const now = new Date();
    return allTrainings.reduce(
      (result, training) => {
        result[
          isBefore(
            parseISO(
              training.trainingDays[training.trainingDays.length - 1].endTime
            ),
            now
          )
            ? 0
            : 1
        ].push(training);
        return result;
      },
      [[], []]
    );
  }, [allTrainings]);

  let component: React.ReactNode;
  const companiesById = useSelector(getCompaniesByIdState);
  switch (selectedList) {
    case 'trainings':
      component = (
        <HRTrainingsPage
          trainings={plannedTrainings}
          companiesById={companiesById}
          qualificationsById={qualificationsById}
          triggerReload={triggerReload}
          closeNewTraining={closeNewTraining}
          newTrainingOpen={newTrainingOpen}
          loading={isLoading}
        />
      );
      break;
    case 'archive':
      component = (
        <HRTrainingsPage
          trainings={pastTrainings}
          companiesById={companiesById}
          qualificationsById={qualificationsById}
          triggerReload={triggerReload}
          closeNewTraining={closeNewTraining}
          newTrainingOpen={newTrainingOpen}
          loading={isLoading}
        />
      );
      break;
    default:
      component = <div>404</div>;
      break;
  }
  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>
        <Button
          type="primary"
          onClick={createTraining}
          iconProp={['fal', 'plus']}
        >
          <span>{t('hr:navigationBar.createTraining')}</span>
        </Button>
      </NavigationBar>
      <Flex.Row flex={1} className={classes.height}>
        <HRTrainingsMenu
          selectedList={selectedList}
          urlPrefix={pathPrefix}
          className={classes.menu}
        />
        {component}
      </Flex.Row>
    </Flex.Column>
  );
};

export default HRTrainings;
