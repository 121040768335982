import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { IconName } from '@fortawesome/pro-light-svg-icons';

import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Classes } from 'jss';

const useStyles = makePrioStyles((theme) => ({
  collapse: {
    backgroundColor: 'transparent',
    '& > .ant-collapse-item': {
      borderBottom: 'none',
    },
    '& > .ant-collapse-item-active > .ant-collapse-header': {
      fontWeight: theme.old.typography.fontWeight.bold,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 16,
    },
    padding: `0 ${theme.old.spacing.unit(1)}px`,
  },
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  settingsIcon: {
    marginRight: theme.old.spacing.unit(1.5) + 18,
  },
  navItemText: {
    '&.ant-typography': {
      color: 'unset',
    },
  },
  menuCollapsed: {
    transition: 'padding 0.2s',
    '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item': {
      padding: `0 ${theme.old.spacing.unit(1.875)}px`,
      borderRadius: 2,
    },
  },
}));

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  theme: PrioTheme,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string,
  collapsed?: boolean
) => (
  <Menu.Item
    key={itemKey}
    title={
      <>
        {icon != null ? (
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', icon]}
            className={classes.icon}
          />
        ) : (
          <span className={classes.settingsIcon} />
        )}
        <Typography.Text className={classes.navItemText}>
          {t(`contacts:contactsNav.navigation.${itemKey}`)}
        </Typography.Text>
      </>
    }
  >
    <Link
      to={customUrl ?? `${urlPrefix ?? ''}${itemKey}`}
      style={icon == null ? { color: theme.old.palette.primaryColor } : {}}
    >
      {icon != null ? (
        <FontAwesomeIcon
          fixedWidth
          icon={['fal', icon]}
          className={classes.icon}
        />
      ) : (
        <span className={classes.settingsIcon} />
      )}
      {!collapsed && (
        <Typography.Text className={classes.navItemText}>
          {t(`contacts:contactsNav.navigation.${itemKey}`)}
        </Typography.Text>
      )}
    </Link>
  </Menu.Item>
);

interface AllMemberMenuProps {
  selectedList?: string;
  className?: string;
  urlPrefix?: string;
  collapsed?: boolean;
}

export const AllMemberMenu: React.FC<AllMemberMenuProps> = (props) => {
  const { className, selectedList, urlPrefix, collapsed } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  return (
    <Menu
      className={classNames(classes.menu, className, {
        [classes.menuCollapsed]: collapsed,
      })}
      selectedKeys={[selectedList]}
      mode="inline"
      id="prio-global-contacts-menu"
      inlineIndent={theme.old.components.menuInlineIndent}
    >
      {navItem(
        'all',
        'address-book',
        t,
        theme,
        classes,
        urlPrefix,
        undefined,
        collapsed
      )}
      {navItem(
        'allContacts',
        'user',
        t,
        theme,
        classes,
        urlPrefix,
        undefined,
        collapsed
      )}
      {navItem(
        'allCompanies',
        'building',
        t,
        theme,
        classes,
        urlPrefix,
        undefined,
        collapsed
      )}
      {navItem(
        'contactSearch',
        'user-magnifying-glass',
        t,
        theme,
        classes,
        urlPrefix,
        undefined,
        collapsed
      )}
    </Menu>
  );
};
