import { Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  CLOSE_GLOBAL_DOCUMENTS_SEARCH_MODAL,
  OPEN_GLOBAL_DOCUMENTS_SEARCH_MODAL,
  OpenGlobalSearchModalAction,
} from '../actions/globalDocumentsSearchModal';

export interface GlobalDocumentsSearchModalState {
  open: boolean;
}

type GlobalDocumentsSearchModalActions = OpenGlobalSearchModalAction;

const initialState: GlobalDocumentsSearchModalState = {
  open: false,
};

const reducer: Reducer<
  GlobalDocumentsSearchModalState,
  GlobalDocumentsSearchModalActions
> = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_GLOBAL_DOCUMENTS_SEARCH_MODAL: {
      const { predefinedState } = action;
      return {
        ...state,
        open: true,
        ...(predefinedState ?? {}),
      };
    }
    case CLOSE_GLOBAL_DOCUMENTS_SEARCH_MODAL: {
      return {
        ...state,
        open: false,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;

export const getOpen = (state: GlobalDocumentsSearchModalState) => state.open;
