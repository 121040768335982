import React, { useMemo } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { TimeAndLeaveManagementTimelineItem } from '../../../timeAndLeaveManagement/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../../components/Flex';
import { TimelineGroup } from '../../../../components/Timeline/types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    color: theme.old.typography.colors.muted,
    overflow: 'hidden',
  },
}));

interface TimeTrackingSuggestionTimelineItemProps {
  className?: string;
  item: TimeAndLeaveManagementTimelineItem;
  group: TimelineGroup;
}

export const TimeTrackingSuggestionTimelineItem: React.FC<
  TimeTrackingSuggestionTimelineItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, item, group } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const title = useMemo(() => {
    const _title = item.title.match(`^(${group.title})(.*)`);
    if (_title && _title[2]) {
      return _title[2].trim();
    } else {
      return item.title;
    }
  }, [group, item]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      className={classNames(classes.root, className)}
      alignItems="center"
      childrenGap={theme.old.spacing.baseSpacing}
    >
      <FontAwesomeIcon icon={['fal', 'calendar-circle-plus']} />
      <Flex.Item
        flex={1}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {title}
      </Flex.Item>
    </Flex.Row>
  );
};

export default TimeTrackingSuggestionTimelineItem;
