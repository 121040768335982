import React from 'react';
import classNames from 'classnames';
import {} from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import VirtualMenu from '../../../../components/VirtualMenu';
import { DriveItem } from '../../../../models/Drive';
import { DriveItemId } from '../../../../models/Types';
import { useSelector } from 'react-redux';
import {
  getCurrentFolderChildren,
  getCurrentFolderItem,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { useNavigate } from 'react-router-dom';
import { isDriveItemFolder } from '../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {},
}));

interface BreadcrumbDriveItemMenuProps {
  className?: string;
  driveItemId: DriveItemId;
  activeItemId: DriveItemId;
  pathPrefix?: string;
  onItemClick?: (driveItemId: DriveItemId) => void;
  disableLinkNavigation?: boolean;
}

export const BreadcrumbDriveItemMenu: React.FC<BreadcrumbDriveItemMenuProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    driveItemId,
    activeItemId,
    pathPrefix,
    disableLinkNavigation,
    onItemClick,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const driveItem = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, activeItemId)
  );
  const childFolders = useSelector<RootReducerState, DriveItem[]>(
    (state) =>
      getCurrentFolderChildren(state, driveItemId)?.filter((item) =>
        isDriveItemFolder(item)
      )
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <VirtualMenu<DriveItem>
      items={childFolders}
      activeItem={driveItem}
      itemRenderer={(driveItem) => driveItem.name}
      className={classNames(classes.root, className)}
      onActiveChanged={(driveItem) => {
        if (!disableLinkNavigation) {
          navigate(`${pathPrefix ?? ''}folder/${driveItem.id}`);
        }
        if (onItemClick) {
          onItemClick(driveItem.id);
        }
      }}
    />
  );
};

export default BreadcrumbDriveItemMenu;
