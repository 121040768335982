import { combineReducers } from 'redux';
import currentFolder, * as fromCurrentFolder from './currentFolder';
import meta, * as fromMeta from './meta';
import uploadLists, * as fromUploadLists from './uploadLists';
import documentTemplates, * as fromDocumentTemplates from './documentTemplates';
import documentTemplateManagement, * as fromDocumentTemplateManagement from './documentTemplateManagement';
import remoteFolders, * as fromRemoteFolders from './remoteFolders';
import previewModal, * as fromPreviewModal from './previewModal';
import driveFavorites, * as fromDriveFavorites from './driveFavorites';
import lastOpenDriveItemFolder, * as fromLastOpenDriveItemFolder from './lastOpenDriveItemFolder';
import columnWidths, * as fromColumnWidths from './columnWidths';
import isDocumentMetaDataSaving, * as fromIsDocumentMetaDataSaving from './documentMetaDataSaving';
import isGlobalDocumentsSearchModalOpen, * as fromGlobalDocumentsSearchModal from './globalDocumentsSearchModal';
import {
  DriveItemId,
  ProjectId,
  DriveUserRemoteItemId,
  GroupId,
  DriveFavoriteId,
} from '../../../models/Types';

export interface DocumentsReducerState {
  currentFolder: fromCurrentFolder.CurrentFolderState;
  meta: fromMeta.CurrentFolderMetaState;
  previewModal: fromPreviewModal.OpenCurrentPreviewModal;
  documentTemplates: fromDocumentTemplates.DocumentTemplatesState;
  documentTemplateManagement: fromDocumentTemplateManagement.DocumentTemplateManagementState;
  uploadLists: fromUploadLists.UploadListState;
  remoteFolders: fromRemoteFolders.RemoteFoldersState;
  driveFavorites: fromDriveFavorites.DriveFavoriteState;
  lastOpenDriveItemFolder: fromLastOpenDriveItemFolder.LastOpenDriveItemFolderState;
  columnWidths: fromColumnWidths.ColumnWidthsState;
  isDocumentMetaDataSaving: fromIsDocumentMetaDataSaving.DocumentMetaDataSavingState;
  isGlobalDocumentsSearchModalOpen: fromGlobalDocumentsSearchModal.GlobalDocumentsSearchModalState;
}

export default combineReducers<DocumentsReducerState>({
  currentFolder,
  meta,
  previewModal,
  documentTemplates,
  documentTemplateManagement,
  uploadLists,
  remoteFolders,
  driveFavorites,
  lastOpenDriveItemFolder,
  columnWidths,
  isDocumentMetaDataSaving,
  isGlobalDocumentsSearchModalOpen,
});

export const getCurrentFolderItem = (
  state: DocumentsReducerState,
  driveItemId: DriveItemId
) => fromCurrentFolder.getItem(state.currentFolder, driveItemId);
export const getCurrentFolderChildren = (
  state: DocumentsReducerState,
  driveItemId: DriveItemId
) => fromCurrentFolder.getChildren(state.currentFolder, driveItemId);

export const getRecursiveDriveItemIdPath = (
  state: DocumentsReducerState,
  driveItemId: DriveItemId,
  groupId: GroupId
) =>
  fromCurrentFolder.getRecursiveDriveItemIdPath(
    state.currentFolder,
    driveItemId,
    groupId
  );

export const getPartialCurrentFolderState = (
  state: DocumentsReducerState,
  driveItemIds: DriveItemId[]
) =>
  fromCurrentFolder.getPartialCurrentFolderState(
    state.currentFolder,
    driveItemIds
  );

export const getCurrentFolderNextLink = (
  state: DocumentsReducerState,
  driveItemId: DriveItemId
) => fromCurrentFolder.getNextLink(state.currentFolder, driveItemId);

export const getCurrentFolderMeta = (state: DocumentsReducerState) =>
  fromCurrentFolder.getMeta(state.currentFolder);

export const getDriveItemIsFetching = (
  state: DocumentsReducerState,
  driveItemId: string
) => fromCurrentFolder.getDriveItemIsFetching(state.currentFolder, driveItemId);

export const getCurrentDriveItem = (state: DocumentsReducerState) =>
  fromCurrentFolder.getCurrentDriveItem(state.currentFolder);

export const isDisabledDriveItem = (
  state: DocumentsReducerState,
  driveItemId: DriveItemId
) => fromCurrentFolder.isDisabledDriveItem(state.currentFolder, driveItemId);

export const getDisabledItems = (state: DocumentsReducerState) =>
  fromCurrentFolder.getDisabledItems(state.currentFolder);

export const getCurrentFolderRedirect = (
  state: DocumentsReducerState,
  driveItemId: DriveItemId
) => fromCurrentFolder.getRedirect(state.currentFolder, driveItemId);

export const getCurrentFolderMetaCeateNewDocumentOpen = (
  state: DocumentsReducerState
) => fromMeta.getCreateNewDocumentOpen(state.meta);
export const getMetaCopyDocumentsDrawerState = (state: DocumentsReducerState) =>
  fromMeta.getCopyDocumentsDrawerState(state.meta);
export const getMetaAreMultipleSelected = (state: DocumentsReducerState) =>
  fromMeta.getAreMultipleSelected(state.meta);
export const getMetaState = (state: DocumentsReducerState) =>
  fromMeta.getMeta(state.meta);

export const getErrorOfDriveItem = (
  state: DocumentsReducerState,
  driveItemId: DriveItemId
) => fromCurrentFolder.getErrorOfDriveItem(state.currentFolder, driveItemId);

export const documentTemplateManagementGetAction = (
  state: DocumentsReducerState
) => fromDocumentTemplateManagement.getAction(state.documentTemplateManagement);

export const documentTemplateManagementGetSelectedEntry = (
  state: DocumentsReducerState
) =>
  fromDocumentTemplateManagement.getSelectedEntry(
    state.documentTemplateManagement
  );

export const documentTemplatesGetTemplates = (state: DocumentsReducerState) =>
  fromDocumentTemplates.getTemplates(state.documentTemplates);

export const documentTemplatesGetIsFetching = (state: DocumentsReducerState) =>
  fromDocumentTemplates.getIsFetching(state.documentTemplates);

export const getUploadListState = (
  state: DocumentsReducerState,
  groupId: GroupId
) => fromUploadLists.getUploadListState(state.uploadLists, groupId);

export const getUploadDocumentById = (
  state: DocumentsReducerState,
  groupId: GroupId,
  fileId: string
) => fromUploadLists.getUploadDocumentById(state.uploadLists, groupId, fileId);

export const getUploadList = (state: DocumentsReducerState, groupId: GroupId) =>
  fromUploadLists.getUploadList(state.uploadLists, groupId);

export const getUploadProgressAllGroups = (state: DocumentsReducerState) =>
  fromUploadLists.getUploadProgressAllGroups(state.uploadLists);

export const getRemoteFoldersMeta = (state: DocumentsReducerState) =>
  fromRemoteFolders.getMeta(state.remoteFolders);

export const getRemoteFolderIsFetching = (state: DocumentsReducerState) =>
  fromRemoteFolders.getRemoteFolderIsFetching(state.remoteFolders);

export const getRemoteFoldersRedirect = (
  state: DocumentsReducerState,
  temporaryId: string
) => fromRemoteFolders.getRedirect(state.remoteFolders, temporaryId);
export const getRemoteFoldersProjectIds = (state: DocumentsReducerState) =>
  fromRemoteFolders.getProjectIds(state.remoteFolders);
export const getRemoteItemsByProjectId = (
  state: DocumentsReducerState,
  projectId: ProjectId
) =>
  fromRemoteFolders.getRemoteItemsByProjectId(state.remoteFolders, projectId);
export const getCurrentRemoteItemId = (state: DocumentsReducerState) =>
  fromRemoteFolders.getCurrentRemoteItemId(state.remoteFolders);
export const getCurrentRemoteItem = (state: DocumentsReducerState) =>
  fromRemoteFolders.getCurrentRemoteItem(state.remoteFolders);
export const getRemoteItem = (
  state: DocumentsReducerState,
  driveUserRemoteItemId: DriveUserRemoteItemId
) =>
  fromRemoteFolders.getRemoteItem(state.remoteFolders, driveUserRemoteItemId);
export const getOpenCurrentPreviewModal = (state: DocumentsReducerState) =>
  fromPreviewModal.getOpenCurrentPreviewModal(state.previewModal);

export const getDriveFavorites = (
  state: DocumentsReducerState,
  projectId?: ProjectId
) => fromDriveFavorites.getDriveFavorites(state.driveFavorites, projectId);

export const getDriveFavorite = (
  state: DocumentsReducerState,
  driveFavoriteId: DriveFavoriteId
) => fromDriveFavorites.getDriveFavorite(state.driveFavorites, driveFavoriteId);

export const getFavorisingProgresses = (state: DocumentsReducerState) =>
  fromDriveFavorites.getFavorisingProgresses(state.driveFavorites);

export const getLastOpenDriveItemFolder = (
  state: DocumentsReducerState,
  projectId: ProjectId
) =>
  fromLastOpenDriveItemFolder.getDriveItemPath(
    state.lastOpenDriveItemFolder,
    projectId
  );

export const getColumnWidths = (state: DocumentsReducerState) =>
  fromColumnWidths.getColumnWidths(state.columnWidths);

export const getIsDocumentMetaDataSaving = (state: DocumentsReducerState) =>
  fromIsDocumentMetaDataSaving.getIsDocumentMetaDataSaving(
    state.isDocumentMetaDataSaving
  );

export const getIsGlobalDocumentsSearchModalOpen = (
  state: DocumentsReducerState
) =>
  fromGlobalDocumentsSearchModal.getOpen(
    state.isGlobalDocumentsSearchModalOpen
  );
