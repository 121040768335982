import { combineReducers, Reducer } from 'redux';
import {
  ProjectId,
  RemoteDriveItemId,
  DriveUserRemoteItemId,
} from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  FETCH_REMOTE_FOLDERS_REQUEST,
  FETCH_REMOTE_FOLDERS_COMMIT,
  FETCH_REMOTE_FOLDERS_ROLLBACK,
  CREATE_REMOTE_FOLDER_COMMIT,
  DELETE_REMOTE_FOLDER_REQUEST,
  DELETE_REMOTE_FOLDER_ROLLBACK,
  CREATE_REMOTE_FOLDER_REQUEST,
  CREATE_REMOTE_FOLDER_ROLLBACK,
  SET_CURRENT_REMOTE_ITEM,
} from '../actions/remoteFolders';
import { DriveUserRemoteItem } from '../../../models/Document';
import { distinct } from '../../../util';

export interface RemoteFoldersState {
  byId: ByIdState;
  ids: IdsState;
  redirects: any;
  meta: RemoteFoldersMeta;
  currentRemoteItem: string;
}

interface ByIdState {
  [key: ProjectId]: DriveUserRemoteItem;
}

const byId: Reducer<ByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REMOTE_FOLDERS_COMMIT: {
      const { payload } = action;
      return (payload as Array<DriveUserRemoteItem>).reduce((map, item) => {
        map[item.driveUserRemoteItemId] = item;
        return map;
      }, {});
    }
    case CREATE_REMOTE_FOLDER_REQUEST: {
      const {
        payload,
        meta: { temporaryId, projectId },
      } = action;
      return {
        ...state,
        [temporaryId]: {
          name: payload.name,
          driveUserRemoteItemId: temporaryId,
          projectId: projectId,
          driveItemId: payload.driveItemId,
        },
      };
    }
    case CREATE_REMOTE_FOLDER_COMMIT: {
      const {
        payload,
        meta: { temporaryId },
      } = action;
      const { [temporaryId]: temporaryItem, ...rest } = state;
      if (temporaryItem) {
        return {
          ...rest,
          [(payload as DriveUserRemoteItem).driveUserRemoteItemId]: payload,
        };
      }
      return state;
    }
    case CREATE_REMOTE_FOLDER_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      const { [temporaryId]: temporaryItem, ...rest } = state;
      if (temporaryItem) {
        return rest;
      }
      return state;
    }
    case DELETE_REMOTE_FOLDER_REQUEST: {
      const {
        meta: { driveUserRemoteItemId },
      } = action;
      const { [driveUserRemoteItemId]: item, ...rest } = state;
      if (item) {
        return rest;
      }
      return state;
    }
    case DELETE_REMOTE_FOLDER_ROLLBACK: {
      const {
        meta: { driveUserRemoteItemId, rollbackRemoteItem },
      } = action;
      return {
        ...state,
        [driveUserRemoteItemId]: rollbackRemoteItem,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface IdsState {
  [key: ProjectId]: RemoteDriveItemId[];
}

const ids: Reducer<IdsState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_REMOTE_FOLDERS_COMMIT: {
      const { payload } = action;
      return (payload as Array<DriveUserRemoteItem>).reduce<IdsState>(
        (map, item) => {
          map[item.projectId] = distinct([
            ...(map[item.projectId] ?? []),
            item.driveUserRemoteItemId,
          ]);
          return map;
        },
        {}
      );
    }
    case CREATE_REMOTE_FOLDER_REQUEST: {
      const {
        meta: { temporaryId, projectId },
      } = action;
      return {
        ...state,
        [projectId]: distinct([...(state[projectId] ?? []), temporaryId]),
      };
    }
    case CREATE_REMOTE_FOLDER_COMMIT: {
      const {
        payload,
        meta: { temporaryId, projectId },
      } = action;
      return {
        ...state,
        [projectId]: distinct([
          ...(state[projectId] ?? []).filter((id) => id !== temporaryId),
          (payload as DriveUserRemoteItem).driveUserRemoteItemId,
        ]),
      };
    }
    case CREATE_REMOTE_FOLDER_ROLLBACK: {
      const {
        meta: { temporaryId, projectId },
      } = action;
      return {
        ...state,
        [projectId]: (state[projectId] ?? []).filter(
          (id) => id !== temporaryId
        ),
      };
    }
    case DELETE_REMOTE_FOLDER_REQUEST: {
      const {
        meta: { driveUserRemoteItemId, rollbackRemoteItem },
      } = action;
      const projectId = (rollbackRemoteItem as DriveUserRemoteItem)?.projectId;
      const filteredIdsAtProject = state[projectId]?.filter(
        (id) => id !== driveUserRemoteItemId
      );
      return {
        ...state,
        [projectId]:
          filteredIdsAtProject.length > 0 ? filteredIdsAtProject : undefined,
      };
    }
    case DELETE_REMOTE_FOLDER_ROLLBACK: {
      const {
        meta: { driveUserRemoteItemId, rollbackRemoteItem },
      } = action;
      const projectId = (rollbackRemoteItem as DriveUserRemoteItem)?.projectId;
      return {
        ...state,
        [projectId]: distinct([
          ...(state[projectId] ?? []),
          driveUserRemoteItemId,
        ]),
      };
    }
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

const redirects: Reducer<any, any> = (state = {}, action) => {
  switch (action.type) {
    case CREATE_REMOTE_FOLDER_COMMIT: {
      const {
        payload: { driveUserRemoteItemId },
        meta: { temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: driveUserRemoteItemId,
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

export interface RemoteFoldersMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const initialState: RemoteFoldersMeta = {
  isFetching: false,
  hasError: false,
};

const meta: Reducer<RemoteFoldersMeta, any> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_REMOTE_FOLDERS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_REMOTE_FOLDERS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_REMOTE_FOLDERS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'documents:errorMessages.fetchRemoteItemsError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

const currentRemoteItem: Reducer<string, any> = (state = null, action) => {
  switch (action.type) {
    case SET_CURRENT_REMOTE_ITEM: {
      return action.driveUserRemoteItemId ?? null;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

export default combineReducers<RemoteFoldersState>({
  byId,
  ids,
  redirects,
  meta,
  currentRemoteItem,
});

export const getMeta: (state: RemoteFoldersState) => RemoteFoldersMeta = (
  state
) => state.meta;

export const getRedirect: (
  state: RemoteFoldersState,
  temporaryId: string
) => string = (state, temporaryId) => state.redirects[temporaryId];

export const getProjectIds: (state: RemoteFoldersState) => ProjectId[] = (
  state
) => Object.keys(state.ids);

export const getRemoteItemsByProjectId: (
  state: RemoteFoldersState,
  projectId: ProjectId
) => DriveUserRemoteItem[] = (state, projectId) =>
  (state.ids[projectId] ?? [])
    .map((id) => state.byId[id])
    .filter((item) => item !== undefined)
    .sort((a, b) => a.name.localeCompare(b.name));

export const getCurrentRemoteItemId: (
  state: RemoteFoldersState
) => DriveUserRemoteItemId = (state) => state.currentRemoteItem;

export const getCurrentRemoteItem: (
  state: RemoteFoldersState
) => DriveUserRemoteItem = (state) => state.byId[state.currentRemoteItem];

export const getRemoteItem: (
  state: RemoteFoldersState,
  driveUserRemoteItemId: DriveUserRemoteItemId
) => DriveUserRemoteItem = (state, driveUserRemoteItemId) =>
  state.byId[driveUserRemoteItemId];

export const getRemoteFolderIsFetching = (state: RemoteFoldersState) =>
  state.meta.isFetching;
