import {
  CompanyId,
  DateTimeString,
  InvoiceId,
  OfficeId,
  ProjectId,
  InvoiceType,
  InvoiceStatus,
  InvoicePaymentId,
  InvoicePaymentType,
  InvoicePositionId,
} from './Types';

export type CurrencyCode = 'EUR' | 'USD' | 'GBP'; //to be completed or use external library...

export interface Money {
  value: number;
  isoCode: CurrencyCode;
}

export interface CalculateOverview {
  invoices: string[];
}

interface ByIdDictionary {
  [id: string]: MonthBreakDownDictionary;
}

interface MonthBreakDownDictionary {
  [month: string]: AccountingOverview;
}

export interface FullAccountingOverview {
  total: AccountingOverview;
  projectBreakDown: AccountingOverview[];
  companyBreakDown: AccountingOverview[];
  companyMonthBreakDown: ByIdDictionary;
  projectMonthBreakDown: ByIdDictionary;
}

export interface AccountingSearch {
  invoiceIds?: string[];
  numbers?: string[];
  titles?: string[];
  projectIds?: string[];
  invoiceDate?: string[];
  invoiceStatus?: string[];
  invoiceTypes?: string[];
}

export interface Invoice {
  invoiceId: InvoiceId;
  number: string;
  type: InvoiceType;
  status: InvoiceStatus;
  invoiceDate: DateTimeString; //absolut
  title: string;
  projectId: ProjectId;
  officeId: OfficeId;
  companyId: CompanyId;
  netSum: Money;
  grossSum: Money;
  recipientOfficeId: OfficeId;
  recipientCompanyId: CompanyId;
  invoicePayments: InvoicePayment[];
  invoicePositions: InvoicePosition[];
  billerCompanyId: CompanyId;
  rowVersion: string;
}

export interface CreateInvoice {
  number: string;
  type: InvoiceType;
  invoiceDate: DateTimeString; //absolut
  title: string;
  projectId: ProjectId;
  recipientCompanyId: CompanyId;
  invoicePositions: CreateInvoicePosition[];
  billerCompanyId: CompanyId;
  rowVersion?: string;
}

export interface InvoicePayment {
  invoicePaymentId?: InvoicePaymentId;
  invoiceId: InvoiceId;
  paymentType: InvoicePaymentType;
  date: DateTimeString; //absolut
  debitSum: Money;
  originalSum: Money;
  title: string;
}

export interface InvoicePosition {
  invoicePositionId?: InvoicePositionId;
  invoiceId?: InvoiceId;
  title: string;
  pricePerUnit: Money;
  vat: number;
  amount: number;
}

export interface CreateInvoicePosition {
  title: string;
  pricePerUnit: Money;
  vat: number;
  amount: number;
}

export interface AccountingOverview {
  invoices: Invoice[];
  outgoingInvoiceSummary: OutgoingInvoiceSummary;
  paymentSummary: PaymentSummary;
  refundSummary: RefundSummary;
  projectOrCompanyId?: string;
}

export interface OutgoingInvoiceSummary {
  netSum: Money;
  grossSum: Money;
  grossSumRemaining: Money;
  netSumRemaining: Money;
}

export const emptyAccountingOverview: AccountingOverview = {
  invoices: [],
  projectOrCompanyId: '',
  outgoingInvoiceSummary: {
    netSum: {
      value: 0.0,
      isoCode: 'EUR',
    },
    grossSum: {
      value: 0.0,
      isoCode: 'EUR',
    },
    grossSumRemaining: {
      value: 0.0,
      isoCode: 'EUR',
    },
    netSumRemaining: {
      value: 0.0,
      isoCode: 'EUR',
    },
  },
  paymentSummary: {
    grossSum: {
      value: 0.0,
      isoCode: 'EUR',
    },
  },
  refundSummary: {
    grossSum: {
      value: 0.0,
      isoCode: 'EUR',
    },
  },
};

export interface PaymentSummary {
  grossSum: Money;
}

export interface RefundSummary {
  grossSum: Money;
}

export interface AccountingFilter {
  companyIds?: CompanyId[];
  projectIds?: ProjectId[];
  status?: InvoiceStatus[];
  types?: InvoiceType[];
}

export interface AccountingFilterRequest {
  companyIds?: CompanyId[];
  projectIds?: ProjectId[];
  status?: InvoiceStatus[];
  types?: InvoiceType[];
  from?: DateTimeString; //absolut
  to?: DateTimeString; //absolut
}

export const allInvoiceTypes: InvoiceType[] = [
  'incomingInvoice',
  'outgoingInvoice',
];

export const allInvoiceStatus: InvoiceStatus[] = [
  'pending',
  'paid',
  'partlyPaid',
];
