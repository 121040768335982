import { ApiResult, apiUrl } from '../../../api';
import {
  Company,
  CompanyDuplicate,
  CreateExternalCompanyRequest,
} from '../../../models/Company';
import { getAccessToken } from '../../../store/authEffect';
import fetchWithRetry from '../../../util/fetchWithRetry';

export const apiCheckCompanyDuplicate: (
  values: CompanyDuplicate
) => Promise<ApiResult<Company[]>> = async (values) => {
  const keysArr = Object.keys(values);
  const valsArr = Object.values(values);
  const validationString = keysArr
    .map((key, idx) => `${key}=${valsArr[idx]}`)
    .join('&');
  const result = await fetchWithRetry(
    `${apiUrl}/contact/Company/validation?${validationString}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateExternalCompany: (
  value: CreateExternalCompanyRequest
) => Promise<ApiResult<Company>> = async (value) => {
  const result = await fetchWithRetry(
    `${apiUrl}/contact/Company/externalCompany`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ ...value }),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
