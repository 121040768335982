import React, { ReactNode } from 'react';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../theme/types';
import Flex from './Flex';

interface NavigationBarProps {
  className?: string;
  children?: ReactNode;
}

export const NavigationBar: React.FC<NavigationBarProps> = (props) => {
  const theme = useTheme<PrioTheme>();
  const { children } = props;

  return (
    <>
      <Flex.Row>
        <Flex.Row alignItems="center" childrenGap={theme.spacing.regular}>
          {children}
        </Flex.Row>
      </Flex.Row>
    </>
  );
};

export default NavigationBar;
