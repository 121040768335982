import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MailSettingsForm from './MailSettingsForm';
import { makePrioStyles } from '../../../theme/utils';
import { MailSettings } from '../../../models/UserSettings/MailSettings';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& > div': {
      '& > div': {
        '& > div': {
          '& > div': {
            display: 'flex',
            flexDirection: 'column',
          },
        },
      },
    },
  },
}));

interface MailSettingsDrawerProps {
  mailSettings: MailSettings;
  mailSettingsDrawerVisible: boolean;
  setMailSettingsDrawerVisible: (boolean) => void;
}

export const MailSettingsDrawer: React.FC<MailSettingsDrawerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    mailSettings,
    mailSettingsDrawerVisible,
    setMailSettingsDrawerVisible,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onClose = () => {
    setMailSettingsDrawerVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Drawer
      className={classes.root}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={mailSettingsDrawerVisible}
      width={theme.old.components.drawerWidth}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
    >
      <Typography.Title level={2}>
        {t('mail:settingsForm.title')}
      </Typography.Title>

      <MailSettingsForm initialValues={mailSettings} />
    </Drawer>
  );
};

export default MailSettingsDrawer;
