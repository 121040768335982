import { PRIO } from '../../../../constants';
import { apiUrl } from '../../api/index';
import { UserSettings } from '../../../../models/UserSettings/UserSettings';
import { SubMenuStateType } from '../../../../models/Types';

/** Update Sider Open State*/
export const UPDATE_THEME_SIDER_SETTING_REQUEST =
  PRIO + 'UPDATE_THEME_SIDER_SETTING_REQUEST';
export const UPDATE_THEME_SIDER_SETTING_COMMIT =
  PRIO + 'UPDATE_THEME_SIDER_SETTING_COMMIT';
export const UPDATE_THEME_SIDER_SETTING_ROLLBACK =
  PRIO + 'UPDATE_THEME_SIDER_SETTING_ROLLBACK';

export const setSiderSetting = (siderOpenSetting: {
  open?: boolean;
  subMenuState?: SubMenuStateType;
}) => {
  const json: UserSettings = {
    themeSettings: {
      siderOptions: {
        ...siderOpenSetting,
      },
    },
  };
  return {
    type: UPDATE_THEME_SIDER_SETTING_REQUEST,
    requiresAuth: true,
    payload: json,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
          url: `${apiUrl}/usersetting/usersetting/me`,
          method: 'PATCH',
          json: json,
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_THEME_SIDER_SETTING_COMMIT,
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: UPDATE_THEME_SIDER_SETTING_ROLLBACK,
        },
      },
      siderOpenSetting,
    },
  };
};
