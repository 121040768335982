import { NotificationId, CompanyId } from '../../../models/Types';
import { Reducer } from 'redux';
import {
  OpenNotificationsDrawerAction,
  OPEN_NOTIFICATIONS_DRAWER,
  CLOSE_NOTIFICATIONS_DRAWER,
  SET_NOTIFICATIONS_DRAWER_TAB,
  SetNotificationsDrawerTabAction,
  SET_NOTIFICATIONS_DRAWER_VIEW,
  NotificationDrawerView,
  NotificationTabs,
  SetNotificationsDrawerViewAction,
  SET_NOTIFICATIONS_DRAWER_STATE,
  SetNotificationsDrawerStateAction,
} from '../actions/drawer';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface NotificationsDrawerState {
  open: boolean;
  tab: NotificationTabs;
  view: NotificationDrawerView;
  selectedNotification?: NotificationId;
  selectedCompany?: CompanyId;
}

type NotificationsDrawerActions = OpenNotificationsDrawerAction &
  SetNotificationsDrawerTabAction &
  SetNotificationsDrawerViewAction &
  SetNotificationsDrawerStateAction;

const initialState: NotificationsDrawerState = {
  open: false,
  tab: 'all',
  view: 'list',
};

const reducer: Reducer<NotificationsDrawerState, NotificationsDrawerActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OPEN_NOTIFICATIONS_DRAWER: {
      const { predefinedState } = action;
      return {
        ...state,
        open: true,
        ...(predefinedState ?? {}),
      };
    }
    case CLOSE_NOTIFICATIONS_DRAWER: {
      return {
        ...state,
        open: false,
        selectedCompany: undefined,
        selectedNotification: undefined,
      };
    }
    case SET_NOTIFICATIONS_DRAWER_STATE: {
      const { partialState } = action;
      return {
        ...state,
        ...partialState,
      };
    }
    case SET_NOTIFICATIONS_DRAWER_TAB: {
      const { tab } = action;
      return {
        ...state,
        tab,
      };
    }
    case SET_NOTIFICATIONS_DRAWER_VIEW: {
      const { view } = action;
      return {
        ...state,
        view,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;

export const getOpen = (state: NotificationsDrawerState) => state.open;
