import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { makePrioStyles } from '../../../../theme/utils';
import { OfficeId } from '../../../../models/Types';
import HRCompensationPaymentsTable, {
  HRCompensationPaymentsPageTableRefProps,
} from './HRCompensationPaymentsTable';
import CreateCompensationPaymentsDrawer from './CreateCompensationPaymentsDrawer';
import { FilterBar } from '../../../../components/Filter/FilterBar';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import classNames from 'classnames';
import {
  CompensationPayment,
  CompensationPaymentsCalculatedData,
} from '../../../../models/TimeKeeping';
import { DefaultSearchParameterItem } from '../../../../components/Filter/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  table: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
}));

export interface HRCompensationPaymentsPageRefProps {
  openCreateCompensationPaymentDrawer: () => void;
}

interface HRCompensationPaymentsPageProps {
  className?: string;
  officeId?: OfficeId;
}

export const HRCompensationPaymentsPage = forwardRef<
  HRCompensationPaymentsPageRefProps,
  HRCompensationPaymentsPageProps
>((props, ref) => {
  //#region ------------------------------ Defaults
  const { className, officeId } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef = useRef<HRCompensationPaymentsPageTableRefProps>(null);

  const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

  const customDefaultSearchParameters: DefaultSearchParameterItem[] = [
    {
      parameterName: 'Calculated.OfficeId',
      defaultValue: officeId,
      defaultMethod: 'eq',
    },
  ];
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnCreateDrawerClose = () => {
    setShowCreateDrawer(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useImperativeHandle(ref, () => ({
    openCreateCompensationPaymentDrawer: () => {
      setShowCreateDrawer(true);
    },
  }));
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <FilterContextProvider<
        CompensationPayment,
        CompensationPaymentsCalculatedData
      >
        searchType="compensationPayments"
        equalityFunction={(a, b) =>
          a.data.compensationPaymentId === b.data.compensationPaymentId
        }
        customDefaultSearchParameters={customDefaultSearchParameters}
      >
        <FilterBar />
        <HRCompensationPaymentsTable className={classes.table} ref={tableRef} />
        <CreateCompensationPaymentsDrawer
          visible={showCreateDrawer}
          officeId={officeId}
          onClose={handleOnCreateDrawerClose}
        />
      </FilterContextProvider>
    </div>
  );
});

export default HRCompensationPaymentsPage;
