import { PRIO } from '../../../constants';
import moment from 'moment';
import {
  CreateTimeRecordRequest,
  TimeRecord,
} from '../../../models/TimeRecord';
import { apiUrl } from '../../../api';
import { TimeRecordId, DateString, ProjectId } from '../../../models/Types';
import { isoDateFormat, todayDateString } from '../../../util';
import { ReduxAction } from '../../../models/Redux';

export const DEBOUNCE_TIMERECORDS_ME = PRIO + 'DEBOUNCE_TIMERECORDS_ME';
export const debounceTimeRecordsMe: (
  startDate?: DateString,
  endDate?: DateString
) => ReduxAction<{ startDate: DateString; endDate: DateString }> = (
  startDate,
  endDate
) => ({
  type: DEBOUNCE_TIMERECORDS_ME,
  meta: {
    startDate,
    endDate,
  },
});

export const SYNC_TIMERECORDS_ME = PRIO + 'SYNC_TIMERECORDS_ME';
export const syncTimeRecordsMe: (
  startDate?: DateString,
  endDate?: DateString
) => ReduxAction<{ startDate: DateString; endDate: DateString }> = (
  startDate,
  endDate
) => ({
  type: SYNC_TIMERECORDS_ME,
  meta: {
    startDate,
    endDate,
  },
});

/** My TimeRecord fetching */
export const FETCH_MY_TIME_RECORDS_REQUEST =
  PRIO + 'FETCH_MY_TIME_RECORDS_REQUEST';
export const FETCH_MY_TIME_RECORDS_COMMIT =
  PRIO + 'FETCH_MY_TIME_RECORDS_COMMIT';
export const FETCH_MY_TIME_RECORDS_ROLLBACK =
  PRIO + 'FETCH_MY_TIME_RECORDS_ROLLBACK';

export const fetchMyTimeRecords = (
  startDate?: DateString,
  endDate?: DateString
) => ({
  type: FETCH_MY_TIME_RECORDS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/timetracking/TimeRecord/me${
          startDate && endDate
            ? `?startDate=${startDate}&endDate=${endDate}`
            : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_MY_TIME_RECORDS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MY_TIME_RECORDS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'timeRecords:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
  },
});

/** Project TimeRecord fetching */
export const FETCH_PROJECT_TIME_RECORDS_REQUEST =
  PRIO + 'FETCH_PROJECT_TIME_RECORDS_REQUEST';
export const FETCH_PROJECT_TIME_RECORDS_COMMIT =
  PRIO + 'FETCH_PROJECT_TIME_RECORDS_COMMIT';
export const FETCH_PROJECT_TIME_RECORDS_ROLLBACK =
  PRIO + 'FETCH_PROJECT_TIME_RECORDS_ROLLBACK';

export const fetchProjectTimeRecords = (
  projectId: ProjectId,
  startDate: DateString = moment().subtract(12, 'months').format(isoDateFormat),
  endDate: DateString = todayDateString
) => ({
  type: FETCH_PROJECT_TIME_RECORDS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/timetracking/TimeRecord?projectId=${projectId}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: { type: FETCH_PROJECT_TIME_RECORDS_COMMIT },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_PROJECT_TIME_RECORDS_ROLLBACK,
        snackbarErrorMessage: {
          label: 'timeRecords:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
  },
});

/** TimeRecords creation */
export const CREATE_TIME_RECORDS_REQUEST = PRIO + 'CREATE_TIME_RECORDS_REQUEST';
export const CREATE_TIME_RECORDS_COMMIT = PRIO + 'CREATE_TIME_RECORDS_COMMIT';
export const CREATE_TIME_RECORDS_ROLLBACK =
  PRIO + 'CREATE_TIME_RECORDS_ROLLBACK';

export const createTimeRecords = (timeRecords: CreateTimeRecordRequest[]) => ({
  type: CREATE_TIME_RECORDS_COMMIT,
  payload: timeRecords,
});

/** TimeRecord update */
export const UPDATE_TIME_RECORD_COMMIT = PRIO + 'UPDATE_TIME_RECORD_COMMIT';

export const updateTimeRecord = (
  timeRecord: TimeRecord,
  timeRecordId: TimeRecordId,
  originalTimeRecord: TimeRecord
) => ({
  type: UPDATE_TIME_RECORD_COMMIT,
  payload: timeRecord,
  meta: {
    timeRecordId,
    timeRecord,
    originalTimeRecord,
  },
});

/** TimeRecord deletion */
export const DELETE_TIME_RECORD = PRIO + 'DELETE_TIME_RECORD';

export const deleteMyTimeRecord = (
  timeRecordId: TimeRecordId,
  rollbackTimeRecord: TimeRecord,
  isMe?: boolean
) => ({
  type: DELETE_TIME_RECORD,
  meta: {
    timeRecordId,
    projectId: rollbackTimeRecord.projectId,
    contactId: rollbackTimeRecord.contactId,
  },
});
