import React, { ReactNode, useCallback, useMemo } from 'react';
import { OfficesContext } from '../context/OfficesContext';
import { useSelector } from 'react-redux';
import {
  getExternalOfficeIds,
  getExternalOfficesByIdState,
  getInternalOfficeIds,
  getInternalOfficesByIdState,
  getOfficeMeByIdState,
  getOfficeMeIds,
} from '../../../apps/main/rootReducer';
import { Office } from '../../../models/Office';
import { OfficeId } from '../../../models/Types';
import { distinct } from '../../../util';

export const sortOfficesHelper = (a: Office, b: Office) => {
  return (a?.name ?? '').localeCompare(b?.name ?? '');
};

interface OfficesProviderProps {
  children?: ReactNode;
}

export const OfficesProvider: React.FC<OfficesProviderProps> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const meById = useSelector(getOfficeMeByIdState);

  const myOfficeIds = useSelector(getOfficeMeIds);

  const myOffices: Office[] = useMemo(() => {
    return myOfficeIds
      .map((id) => meById[id])
      .filter((office) => !!office)
      .sort(sortOfficesHelper);
  }, [myOfficeIds, meById]);

  const internalById = useSelector(getInternalOfficesByIdState);

  const internalOfficeIds = useSelector(getInternalOfficeIds);

  const internalOffices: Office[] = useMemo(() => {
    return internalOfficeIds
      .map((id) => internalById[id])
      .filter((office) => !!office)
      .sort(sortOfficesHelper);
  }, [internalOfficeIds, internalById]);

  const externalById = useSelector(getExternalOfficesByIdState);

  const externalOfficeIds = useSelector(getExternalOfficeIds);

  const externalOffices: Office[] = useMemo(() => {
    return externalOfficeIds
      .map((id) => externalById[id])
      .filter((office) => !!office)
      .sort(sortOfficesHelper);
  }, [externalOfficeIds, externalById]);

  const allOffices: Office[] = useMemo(() => {
    const allIds = distinct([...internalOfficeIds, ...externalOfficeIds]);
    return allIds
      .map((id) => {
        if (!!internalById[id]) {
          return internalById[id];
        } else if (!!externalById[id]) {
          return externalById[id];
        }
        return null;
      })
      .filter((office) => !!office)
      .sort(sortOfficesHelper);
  }, [internalOfficeIds, externalOfficeIds, internalById, externalById]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getMyOfficeById = useCallback(
    (id: OfficeId) => {
      return meById[id];
    },
    [meById]
  );

  const getMyOfficeByProperty = useCallback(
    (property: keyof Office, value: any) => {
      return myOffices.find((project) => project[property] === value);
    },
    [myOffices]
  );

  const getInternalOfficeById = useCallback(
    (id: OfficeId) => {
      return internalById[id];
    },
    [internalById]
  );

  const getInternalOfficeByProperty = useCallback(
    (property: keyof Office, value: any) => {
      return internalOffices.find((project) => project[property] === value);
    },
    [internalOffices]
  );

  const getExternalOfficeById = useCallback(
    (id: OfficeId) => {
      return externalById[id];
    },
    [externalById]
  );

  const getExternalOfficeByProperty = useCallback(
    (property: keyof Office, value: any) => {
      return externalOffices.find((project) => project[property] === value);
    },
    [externalOffices]
  );

  const getOfficeById = useCallback(
    (id: OfficeId) => {
      const myOffice = getMyOfficeById(id);
      if (myOffice) {
        return myOffice;
      }
      const internalOffice = getInternalOfficeById(id);
      if (internalOffice) {
        return internalOffice;
      }
      const externalOffice = getExternalOfficeById(id);
      if (externalOffice) {
        return externalOffice;
      }
      return undefined;
    },
    [getMyOfficeById, getInternalOfficeById, getExternalOfficeById]
  );

  const getOfficeByProperty = useCallback(
    (property: keyof Office, value: any) => {
      const myOffice = getMyOfficeByProperty(property, value);
      if (myOffice) {
        return myOffice;
      }
      const internalOffice = getInternalOfficeByProperty(property, value);
      if (internalOffice) {
        return internalOffice;
      }
      const externalOffice = getExternalOfficeByProperty(property, value);
      if (externalOffice) {
        return externalOffice;
      }
      return undefined;
    },
    [
      getMyOfficeByProperty,
      getInternalOfficeByProperty,
      getExternalOfficeByProperty,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <OfficesContext.Provider
      value={{
        myOffices,
        internalOffices,
        externalOffices,
        allOffices,
        getMyOfficeById,
        getMyOfficeByProperty,
        getInternalOfficeById,
        getInternalOfficeByProperty,
        getExternalOfficeById,
        getExternalOfficeByProperty,
        getOfficeById,
        getOfficeByProperty,
      }}
    >
      {children}
    </OfficesContext.Provider>
  );
};

export default OfficesProvider;
