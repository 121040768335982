import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { DashboardItemId } from '../../../models/Types';
import { DashboardWidgetType } from '../../../models/Dashboard';
import DashboardAbsenceOverviewItem from './DashboardItems/DashboardAbsenceOverviewItem';
import DashboardAbsenceNotificationsItem from './DashboardItems/DashboardAbsenceNotificationsItem';
import DashboardInboxOverviewItem from './DashboardItems/DashboarInboxOvervieItem';
import DashboardProjectInfosItemItem from './DashboardItems/DashboardProjectInfosItem';
import { Project } from '../../../models/Project';
import DashboardUpcomingAbsencesItem from './DashboardItems/DashboardUpcomingAbsencesItem';
import DashboardReteachItem from './DashboardItems/DashboardReteachItem';
import DashboardPendingActionsItem from './DashboardItems/DashboardPendingActionsItem';
import DashboardUpcomingBirthdaysItem from './DashboardItems/DashboardUpcomingBirthdaysItem';
import DashboardHRTimekeepingItem from './DashboardItems/DashboardHRTimekeepingItem';
import DashboardOvertimeItem from './DashboardItems/DashboardOvertimeItem';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'visible',
    zIndex: 99,
  },
}));

interface DashboardBaseItemProps {
  className?: string;
  dashboardItemId: DashboardItemId;
  type?: DashboardWidgetType;
  data?: any;
}

export const DashboardBaseItem: React.FC<DashboardBaseItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, dashboardItemId, type, data } = props;
  const classes = useStyles(props);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  switch (type) {
    case 'absenceOverview': {
      return <DashboardAbsenceOverviewItem />;
    }
    case 'absenceNotifications': {
      return <DashboardAbsenceNotificationsItem />;
    }
    case 'upcomingAbsences': {
      return <DashboardUpcomingAbsencesItem />;
    }
    case 'inboxOverview': {
      return <DashboardInboxOverviewItem />;
    }
    case 'projectInfos': {
      return <DashboardProjectInfosItemItem project={data as Project} />;
    }
    case 'upcomingBirthdays': {
      return <DashboardUpcomingBirthdaysItem />;
    }
    case 'reteachOverview': {
      return <DashboardReteachItem />;
    }
    case 'pendingActions': {
      return <DashboardPendingActionsItem />;
    }
    case 'hrTimekeepingOverview': {
      return <DashboardHRTimekeepingItem />;
    }
    case 'overtimeOverview': {
      return <DashboardOvertimeItem />;
    }
    // TODO: Comment in when Timekeeping is available
    /*
    case 'timekeepingInfo': {
      return <DashboardTimekeepingInfoItem />;
    }
    */
    default: {
      return (
        <div className={classNames(classes.root, className)}>
          {dashboardItemId}
        </div>
      );
    }
  }
};

export default DashboardBaseItem;
