import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../theme/utils';
import { DndMailAddressDto } from '../types';
import { emailPattern } from '../../hooks/useEmailValidation';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../theme/types';
import TransBtn from 'rc-select/lib/TransBtn';
import { CloseOutlined } from '@ant-design/icons';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    borderRadius: 2,
    border: '1px solid #f0f0f0',
    background: '#f5f5f5',
    marginTop: 2,
    marginBottom: 2,
    marginInlineEnd: 3.2,
    paddingInlineStart: 8,
    paddingInlineEnd: 4,
    boxShadow:
      '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
    '& .ant-select-selection-item': {
      lineHeight: 28,
    },
    '& .ant-select-selection-item-remove': {
      fontSize: 10,
      textAlign: 'center',
      height: '100%',
    },
    '& p': {
      margin: 0,
    },
  },
  secondary: {
    color: theme.old.typography.colors.muted,
  },
}));

interface MailAddressItemPreviewProps {
  className?: string;
  item: DndMailAddressDto;
}

export const MailAddressItemPreview: React.FC<MailAddressItemPreviewProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, item } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <p
        style={
          item.label?.toString()?.match(emailPattern)
            ? {}
            : { color: theme.old.palette.chromaticPalette.red }
        }
      >
        {item.label}
      </p>
      {item.closable && (
        <TransBtn
          className={'ant-select-selection-item-remove'}
          customizeIcon={<CloseOutlined />}
        />
      )}
    </div>
  );
};

export default MailAddressItemPreview;
