import { BarChart } from '@prio365/prio365-react-library';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import { PrioTheme } from '../../../../theme/types';
import { useTheme } from 'react-jss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface AbsenceOverviewBarChartProps {
  absenceOverview: AbsenceOverview;
}

export const AbsenceOverviewBarChart: React.FC<AbsenceOverviewBarChartProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { absenceOverview } = props;

  const { t } = useTranslation();

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { bars, dividers } = useMemo(() => {
    const {
      takenVacationDays,
      requestedVacationDays,
      acceptedVacationDays,
      remainingVacationDays,
      vacationDay,
      remainingDaysPreviousYear,
    } = absenceOverview ?? {};
    const bars = [];

    if (remainingDaysPreviousYear < 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.usedDaysPreviousYear'),
        fraction: Math.abs(remainingDaysPreviousYear),
        color: theme.colors.base.blue[90],
        hideEndGap: takenVacationDays > 0,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.blue[90],
              }}
            >
              {Math.abs(remainingDaysPreviousYear)}{' '}
            </span>
            <span>
              {remainingVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (takenVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.takenVacationDays'),
        fraction: takenVacationDays,
        color: theme.colors.base.blue[80],
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.blue[90],
              }}
            >
              {takenVacationDays}{' '}
            </span>
            <span>
              {takenVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (acceptedVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.acceptedVacationDays'),
        fraction: acceptedVacationDays,
        color: theme.colors.base.green.default,
        hideEndGap: requestedVacationDays > 0,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.green.default,
              }}
            >
              {acceptedVacationDays}{' '}
            </span>
            <span>
              {acceptedVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (requestedVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.requestedVacationDays'),
        fraction: requestedVacationDays,
        color: theme.colors.base.yellow.default,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.yellow.default,
              }}
            >
              {requestedVacationDays}{' '}
            </span>
            <span>
              {requestedVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    if (remainingVacationDays > 0)
      bars.push({
        title: t('absences:absenceOverview.popOver.remainingVacationDays'),
        fraction: remainingVacationDays,
        color: theme.colors.base.primary.default,
        content: (
          <div>
            <span
              style={{
                fontWeight: theme.font.fontWeight.bold,
                color: theme.colors.base.primary.default,
              }}
            >
              {remainingVacationDays}{' '}
            </span>
            <span>
              {remainingVacationDays > 1
                ? t('absences:absenceOverview.popOver.days')
                : t('absences:absenceOverview.popOver.day')}
            </span>
          </div>
        ),
      });

    const allBarFractionsAdded = bars.reduce(
      (acc, bar) => acc + bar.fraction,
      0
    );

    const remainingDaysBarPositionPercentage = Math.round(
      Math.abs(
        (remainingDaysPreviousYear / Math.max(allBarFractionsAdded, 1)) * 100
      )
    );

    const totalVacationDays =
      remainingDaysPreviousYear < 0
        ? vacationDay
        : vacationDay + remainingDaysPreviousYear;
    const dividers = [];

    if (remainingDaysPreviousYear > 0)
      dividers.push({
        atFraction: remainingDaysPreviousYear,
        color: '#000000',
        // 20% of bar fractions
        textPosition:
          remainingDaysBarPositionPercentage >= 22 ? 'left' : 'right',
        text: `${t(
          'absences:absenceOverview.popOver.remainingDaysPreviousYear'
        )}: ${remainingDaysPreviousYear}`,
      });
    if (totalVacationDays > 0)
      dividers.push({
        atFraction: totalVacationDays,
        color: '#000000',
        text: `${t(
          'absences:absenceOverview.popOver.vacationDay'
        )}: ${totalVacationDays}`,
      });

    return { bars, dividers };
  }, [absenceOverview, theme, t]);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <BarChart
      bars={bars}
      dividers={dividers}
      miniLegend
      alignFractionNumbers="center"
    />
  );
};
