import { apiUrl } from '../../../../api';
import { PRIO } from '../../../../constants';
import { Message } from '../../../../models/Message';
import { DispatchAction, ReduxAction } from '../../../../models/Redux';
import { MessageId, ProjectId } from '../../../../models/Types';

export const SET_ACTIVE_DRAFT_MESSAGE_ID_PROJECT =
  PRIO + 'SET_ACTIVE_DRAFT_MESSAGE_ID_PROJECT';

export const setActiveDraftMessageIdProject: (
  projectId: ProjectId,
  messageId: MessageId
) => ReduxAction = (projectId, messageId) => ({
  type: SET_ACTIVE_DRAFT_MESSAGE_ID_PROJECT,
  projectId,
  messageId,
});

export const CREATE_NEW_MESSAGE_DRAFT_PROJECT_REQUEST =
  PRIO + 'CREATE_NEW_MESSAGE_DRAFT_PROJECT_REQUEST';
export const CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT =
  PRIO + 'CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT';
export const CREATE_NEW_MESSAGE_DRAFT_PROJECT_ROLLBACK =
  PRIO + 'CREATE_NEW_MESSAGE_DRAFT_PROJECT_ROLLBACK';

export const createNewDraftMessageProject: (
  projectId: ProjectId
) => DispatchAction<{ projectId: ProjectId }, Message> = (projectId) => ({
  type: CREATE_NEW_MESSAGE_DRAFT_PROJECT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/message`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: {
          body: {
            contentType: 1,
            content: '<p></p><p></p><p></p>',
          },
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_NEW_MESSAGE_DRAFT_PROJECT_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_NEW_MESSAGE_DRAFT_PROJECT_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.createDraftError',
          timeout: 6,
        },
        meta: { projectId },
      },
    },
    projectId,
  },
});

export const CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_REQUEST =
  PRIO + 'CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_REQUEST';
export const CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT =
  PRIO + 'CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT';
export const CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_ROLLBACK =
  PRIO + 'CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_ROLLBACK';

export const createMessageReplyAllForwardProject: (
  projectId: ProjectId,
  messageId: string,
  type: 'Reply' | 'ReplyAll' | 'Forward'
) => DispatchAction<{ projectId: ProjectId }, Message> = (
  projectId,
  messageId,
  type
) => ({
  type: CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/Email/${projectId}/message/${messageId}/create${type}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        json: {
          body: {
            contentType: 1,
            content: '<p></p><p></p><p></p>',
          },
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_COMMIT,
        meta: { projectId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_MESSAGE_REPLY_ALL_FORWARD_PROJECT_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.createDraftError',
          timeout: 6,
        },
        meta: { projectId },
      },
    },
    projectId,
  },
});

export const REMOVE_DRAFT_MESSAGE_PROJECT =
  PRIO + 'REMOVE_DRAFT_MESSAGE_PROJECT';

export const removeDraftMessageProject: (
  projectId: ProjectId,
  messageId: MessageId
) => ReduxAction = (projectId, messageId) => ({
  type: REMOVE_DRAFT_MESSAGE_PROJECT,
  projectId,
  messageId,
});

export const REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT =
  PRIO + 'REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT';

export const removeDraftMessageWithoutPipelineStopProject: (
  projectId: ProjectId,
  messageId: MessageId
) => ReduxAction = (projectId, messageId) => ({
  type: REMOVE_DRAFT_MESSAGE_WITHOUT_PIPELINE_STOP_PROJECT,
  projectId,
  messageId,
});

export const ADD_DRAFT_MESSAGE_PROJECT = PRIO + 'ADD_DRAFT_MESSAGE_PROJECT';

export const addDraftMessageProject: (
  projectId: ProjectId,
  message: Message,
  isInitial?: boolean
) => ReduxAction = (projectId, message, isInitial) => ({
  type: ADD_DRAFT_MESSAGE_PROJECT,
  projectId,
  message,
  isInitial,
});

export const ADD_SENDING_MESSAGE_ID_PROJECT =
  PRIO + 'ADD_SENDING_MESSAGE_ID_PROJECT';

export const addSendingMessageIdProject: (
  projectId: ProjectId,
  messageId: MessageId
) => ReduxAction = (projectId, messageId) => ({
  type: ADD_SENDING_MESSAGE_ID_PROJECT,
  projectId,
  messageId,
});

export const REMOVE_SENDING_MESSAGE_ID_PROJECT =
  PRIO + 'REMOVE_SENDING_MESSAGE_ID_PROJECT';

export const removeSendingMessageIdProject: (
  projectId: ProjectId,
  messageId: MessageId
) => ReduxAction = (projectId, messageId) => ({
  type: REMOVE_SENDING_MESSAGE_ID_PROJECT,
  projectId,
  messageId,
});
