import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BasisPicker,
  BasisPickerSelectOptions,
} from '../../../../components/BasisPicker';
import BasisDatePicker from '../../../../components/BasisDatePicker';
import { FormInstance, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { makePrioStyles } from '../../../../theme/utils';
import { TodoTaskFormModel } from '../../../../models/Todo';

const useStyles = makePrioStyles((theme) => ({
  root: { width: '100%' },
  quickOptionsPicker: {
    width: '100%',
  },
  extensionTextColor: {
    '&.ant-typography': {
      color: theme.old.palette.chromaticPalette.grey,
    },
  },
}));

interface DatePickerWithQuickActionProps {
  form?: FormInstance<TodoTaskFormModel>;
  type?: PickerType;
  disabled?: boolean;
}

type PickerType = 'reminder' | 'dueDate';
export const DatePickerWithQuickAction: React.FC<
  DatePickerWithQuickActionProps
> = (props) => {
  const { form, type, disabled } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const basisDatePickerRef = React.useRef(null);

  const laterTodayMoment = moment().add(2, 'hour').startOf('hour');
  const tomorrowMoment = moment()
    .add(1, 'day')
    .startOf('day')
    .add(9, 'hour')
    .startOf('hour');
  const nextWeekMoment = moment()
    .add(1, 'week')
    .startOf('week')
    .startOf('day')
    .add(9, 'hour')
    .startOf('hour');

  const selectOptions: BasisPickerSelectOptions[] = [
    {
      label: t(
        'tasks:todoForm.reminderDatePicker.defaultReminderOptions.laterToday'
      ),
      value: 'laterToday',
      icon: {
        name: 'calendar-day',
      },
      extension: (
        <Typography.Text className={classes.extensionTextColor} italic>
          {laterTodayMoment.format('HH:mm')}
        </Typography.Text>
      ),
    },
    {
      label: t(
        'tasks:todoForm.reminderDatePicker.defaultReminderOptions.tomorrow'
      ),
      value: 'tomorrow',
      icon: {
        name: 'angle-double-right',
      },
      extension: (
        <Typography.Text className={classes.extensionTextColor} italic>
          {tomorrowMoment.format('dddd HH:mm')}
        </Typography.Text>
      ),
    },
    {
      label: t(
        'tasks:todoForm.reminderDatePicker.defaultReminderOptions.nextWeek'
      ),
      value: 'nextWeek',
      icon: {
        name: 'calendar-week',
      },
      extension: (
        <Typography.Text className={classes.extensionTextColor} italic>
          {nextWeekMoment.format('dddd HH:mm')}
        </Typography.Text>
      ),
    },
  ];

  const extraFooter = () => (
    <BasisPicker
      onSelect={handleQuickOptionsSelect}
      allowClear
      defaultValue="laterToday"
      className={classes.quickOptionsPicker}
      items={selectOptions}
    />
  );
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [datePickerValue, setDatePickerValue] = useState<Moment>();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleQuickOptionsSelect = (option: string) => {
    switch (option) {
      case 'laterToday':
        setDatePickerValue(laterTodayMoment);
        break;
      case 'tomorrow':
        setDatePickerValue(tomorrowMoment);
        break;
      case 'nextWeek':
        setDatePickerValue(nextWeekMoment);
        break;

      default:
        setDatePickerValue(moment());
        break;
    }
  };

  const handleDateOk = (date: Moment) => {
    setDatePickerOpen(false);
    if (type === 'reminder') {
      form.setFieldsValue({
        reminderDate: date,
      });
    }

    if (type === 'dueDate') {
      form.setFieldsValue({
        dueDate: date,
      });
    }
  };

  const handleDateChange = (date: Moment) => {
    if (date === null) {
      setDatePickerValue(null);
    }
  };
  const handleBlur = (focusEvent: React.FocusEvent<HTMLInputElement>) => {
    // Only value that distincts weether selectbox is clicked or some other space outside of the datePicker
    //@ts-ignore
    if (focusEvent.nativeEvent.sourceCapabilities !== null) {
      setDatePickerOpen(false);
    } else {
      basisDatePickerRef.current.focus();
    }
  };
  const handleDatePickerFocus = () => {
    setDatePickerOpen(true);
  };
  //#endregion

  //#region ------------------------------ useEffects
  //#endregion

  return (
    <BasisDatePicker
      disabled={disabled}
      format="YYYY-MM-DD HH:mm"
      value={datePickerValue}
      suffixIconFontAwesome={['fal', 'alarm-clock']}
      showTime={{
        showSecond: false,
      }}
      showNow={false}
      className={classes.root}
      ref={basisDatePickerRef}
      open={datePickerOpen}
      onChange={handleDateChange}
      onOk={handleDateOk}
      onBlur={handleBlur}
      onFocus={handleDatePickerFocus}
      extraFooter={extraFooter()}
    />
  );
};

export default DatePickerWithQuickAction;
