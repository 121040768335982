import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select } from '@prio365/prio365-react-library';

import { Company } from '../../../models/Company';
import {
  getCompany,
  getCompanyRedirect,
  getContact,
  getUserMeContactId,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { isTemporaryId } from '../../../util';
import { CompanyId, CompanyType, ContactId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Contact } from '../../../models/Contact';
import classNames from 'classnames';
import useCompaniesContext from '../hooks/useCompaniesContext';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-select-selection-item': {
      minHeight: 50,
    },
    '&.ant-select-single > .ant-select-selector > .ant-select-selection-item': {
      display: 'flex',
      flexDirection: 'row',
    },
    '&.ant-select-single $shortNameText': {
      marginLeft: theme.old.spacing.baseSpacing,
    },
  },
  shortNameText: {
    fontSize: 14,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    color: theme.old.typography.colors.muted,
  },
  button: {
    borderTop: theme.old.borders.content,
  },
  disabledPicker: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
}));
interface CompanyPickerProps {
  value?: string | string[];
  label?: string;
  onChange?: (value: string | string[]) => void;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
  companyType?: CompanyType;
  fetch?: boolean;
  emptyLabel?: string;
  onCompanySelect?: (selectedCompany: Company) => void;
  buttonLabel?: string;
  iconProp?: IconProp;
  onButtonClick?: (
    event?: React.MouseEvent<HTMLElement, MouseEvent>,
    value?: string | string[],
    allCompanies?: Company[]
  ) => void;
  disableTemporaryIdFilter?: boolean;
  onlyCompanyIds?: CompanyId[];
}

export const CompanyPicker: React.FC<CompanyPickerProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const {
    className,
    value,
    label,
    onChange,
    onClick,
    disabled,
    companyType,
    multiple,
    buttonLabel,
    emptyLabel,
    iconProp,
    onCompanySelect,
    onButtonClick,
    disableTemporaryIdFilter,
    onlyCompanyIds,
  } = props;

  const { companies: allCompanies } = useCompaniesContext();

  const currentUserId = useSelector<RootReducerState, ContactId>(
    getUserMeContactId
  );
  const currentUser = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, currentUserId)
  );

  const currentUserCompany = useSelector<RootReducerState, Company>((state) =>
    getCompany(state, currentUser?.companyId)
  );
  const redirect = useSelector<RootReducerState, string>((state) =>
    typeof value === 'string' ? getCompanyRedirect(state, value) : null
  );

  const companiesOfType = useMemo(() => {
    if (companyType) {
      if (companyType === 'ContactCompany') {
        return allCompanies.filter(
          (company) => currentUserCompany?.companyId === company.companyId
        );
      } else {
        return allCompanies.filter(
          (company) => company.companyType === companyType
        );
      }
    } else {
      return allCompanies;
    }
  }, [companyType, allCompanies, currentUserCompany?.companyId]);

  const activeCompanies = useMemo(
    () =>
      disableTemporaryIdFilter
        ? companiesOfType
        : companiesOfType.filter((c) => !isTemporaryId(c.companyId)),
    [companiesOfType, disableTemporaryIdFilter]
  );

  const companies = useMemo(
    () =>
      onlyCompanyIds
        ? activeCompanies.filter((company) =>
            onlyCompanyIds.includes(company.companyId)
          )
        : activeCompanies,
    [activeCompanies, onlyCompanyIds]
  );
  //#endregion

  //#region -------------------------------- State declaration
  const [companyPickerIsOpen, setCompanyPickerIsOpen] = useState<boolean>();
  //#endregion

  //#region -------------------------------- Methods
  const closeCompanyPicker = () => {
    setCompanyPickerIsOpen(false);
  };

  const openCompanyPicker = () => {
    setCompanyPickerIsOpen(true);
  };
  //#endregion

  //#region -------------------------------- Handle methods
  const handleOnChange: (value: string) => void = onChange
    ? (value: string) => {
        onChange(value ?? '');
      }
    : null;

  const handleOnCompanySelect: (companyId: string) => void = useMemo(
    () =>
      onCompanySelect
        ? (companyId: string) => {
            var selectedCompany = companies.find(
              (c) => c.companyId === companyId
            );
            onCompanySelect(selectedCompany ?? null);
          }
        : null,
    [companies, onCompanySelect]
  );

  const handleOnButtonClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void = onButtonClick
    ? (event) => {
        onButtonClick(event, value, activeCompanies);
      }
    : null;
  //#endregion

  //#region -------------------------------- Hooks
  useEffect(() => {
    if (redirect && onChange) {
      onChange(redirect);
      handleOnCompanySelect(redirect);
    }
  }, [redirect, onChange, handleOnCompanySelect]);
  //#endregion

  return (
    <Select
      size="small"
      visible={companyPickerIsOpen}
      onVisibleChange={(open) => {
        if (open) {
          openCompanyPicker();
        }
        if (!open) {
          closeCompanyPicker();
        }
      }}
      className={classNames(classes.root, className, {
        [classes.disabledPicker]: disabled,
      })}
      showSearch
      mode={multiple ? 'multiple' : null}
      value={value}
      placeholder={label}
      onChange={handleOnChange}
      onClick={onClick}
      filterOption={(input, option) =>
        option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
      dropdownRender={
        buttonLabel &&
        ((menu) => (
          <Flex.Column>
            {menu}
            <Button
              type="link"
              onClick={(e) => {
                closeCompanyPicker();
                handleOnButtonClick(e);
              }}
              className={classes.button}
              iconProp={iconProp ?? ['fal', 'plus']}
            >
              {buttonLabel}
            </Button>
          </Flex.Column>
        ))
      }
      options={[
        ...(emptyLabel
          ? [
              {
                value: 'empty',
                label: emptyLabel,
                title: emptyLabel,
              },
            ]
          : []),
        ...companies.map((company: Company) => ({
          value: company.companyId,
          label: (
            <>
              {company.fullName}
              <span className={classes.shortNameText}>
                {company.shortName ?? company.fullName ?? company.rowVersion}
              </span>
            </>
          ),
          title: company.fullName + company.shortName,
        })),
      ]}
    />
  );
};

export default CompanyPicker;
