import React from 'react';
import { useSelector } from 'react-redux';
import {
  getAllCompanies,
  getCompaniesIsFetching,
} from '../../../apps/main/rootReducer';
import { CompanyCard } from './CompanyCard';
import { Company } from '../../../models/Company';
import CompanyListSkeleton from './CompanyListSkeleton';
import { syncCompanies } from '../actions';
import { useDispatch } from 'react-redux';
import Flex from '../../../components/Flex';

interface CompaniesListProps {}

export const CompaniesList: React.FC<CompaniesListProps> = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(syncCompanies());
  }, [dispatch]);

  const companies = useSelector(getAllCompanies);
  const isFetching = useSelector(getCompaniesIsFetching);

  const renderSkeleton = companies.length === 0 && isFetching;

  if (renderSkeleton) {
    return <CompanyListSkeleton />;
  }

  return (
    <Flex.Column>
      {companies.map((company: Company) => (
        <CompanyCard key={company.companyId} company={company} />
      ))}
    </Flex.Column>
  );
};

export default CompaniesList;
