import { Project } from '../../../models/Project';
import { ProjectExtensionType } from '../../../models/ProjectExtension';
import { GlobalRole, OfficeRole, ProjectRole } from '../../../models/Types';
import { User } from '../../../models/User';

export const sortProjects: (projects: Project[]) => Project[] = (projects) => {
  const parentProjects = projects.filter((project) => !project.parentProject);
  const subProjectsByParentProjectId: {
    [parenProjectId: string]: Project[];
  } = projects.reduce((map, subProject) => {
    const parentProjectId = subProject.parentProject;
    if (parentProjectId) {
      if (!map[parentProjectId]) {
        map[parentProjectId] = [];
      }
      map[parentProjectId].push(subProject);
    }
    return map;
  }, {});
  const subProjectsWithNullParent = projects.filter(
    (project) =>
      project.parentProject &&
      !parentProjects.some((p) => p.projectId === project.parentProject)
  );
  return parentProjects
    .concat(subProjectsWithNullParent)
    .sort((a: Project, b: Project) => {
      const numberCompare = a.number.localeCompare(b.number);
      if (numberCompare !== 0) return numberCompare;
      return a.name.localeCompare(b.name);
    })
    .reduce((acc, parentProject) => {
      const subProjects = subProjectsByParentProjectId[parentProject.projectId];
      acc.push(parentProject);
      if (subProjects) {
        acc.push(
          ...subProjects.sort((a: Project, b: Project) => {
            const numberCompare = a.number.localeCompare(b.number);
            if (numberCompare !== 0) return numberCompare;
            return a.name.localeCompare(b.name);
          })
        );
      }
      return acc;
    }, []);
};

interface ProjectAccess {
  summary: boolean;
  sharedMailboxUser: boolean;
  contacts: boolean;
  tasks: boolean;
  calendar: boolean;
  timeRecord: boolean;
  groupId: boolean;
  invoiceControlling: boolean;
  timeRecordControlling: boolean;
  settings: boolean;
}

const hasExtensionAccessOrLoading = (
  project: Project,
  type: ProjectExtensionType
) => {
  if (!project) {
    return false;
  }
  const extensionState = project.projectExtensionMeDtos?.find(
    (ext) => ext.projectExtensionType === type
  )?.extensionUserState;

  if (!extensionState) {
    return false;
  }
  return extensionState !== 'noAccess';
};

const chooseBoolOnActiveTab = (
  activeTab: string,
  allBool: boolean,
  otherBool: boolean
) => {
  if (activeTab === 'all') {
    return allBool;
  }
  return otherBool;
};

export const getProjectAccess: (
  project: Project,
  userMe: User,
  activeTab: string
) => ProjectAccess = (project, userMe, activeTab) => {
  const globalRoles: GlobalRole[] = userMe?.prioData?.globalRoles || [];

  const projectRoles: ProjectRole[] =
    userMe?.prioData?.projectRoles?.[project.projectId] || [];

  const companyRoles: OfficeRole[] =
    userMe?.prioData?.officeRoles?.[project?.internalOfficeId] || [];

  const showProjectSettings =
    globalRoles.includes('globalAdmin') ||
    projectRoles.includes('projectAdmin') ||
    projectRoles.includes('projectAssistance') ||
    companyRoles.includes('officeAdmin') ||
    companyRoles.includes('officeAssistance');
  const showProjectControlling =
    globalRoles.includes('globalAdmin') ||
    globalRoles.includes('globalController') ||
    projectRoles.includes('projectAdmin') ||
    projectRoles.includes('projectController') ||
    companyRoles.includes('officeController');

  const isProjectMember =
    projectRoles.includes('projectMember') ||
    Object.keys(userMe?.prioData?.projectRoles ?? []).includes(
      project.projectId
    );

  return {
    summary: true,
    sharedMailboxUser:
      isProjectMember &&
      chooseBoolOnActiveTab(
        activeTab,
        !!project.sharedMailboxUserId,
        hasExtensionAccessOrLoading(project, 'sharedMailbox')
      ),
    contacts: isProjectMember,
    tasks:
      isProjectMember &&
      chooseBoolOnActiveTab(
        activeTab,
        !!project.masterPlanId,
        hasExtensionAccessOrLoading(project, 'planner')
      ) &&
      !!project.eMailSuffix &&
      !!project.groupId,
    calendar: isProjectMember && !!project.eMailPrefix && !!project.eMailSuffix,
    timeRecord:
      isProjectMember && !showProjectSettings && !showProjectControlling,
    groupId:
      isProjectMember &&
      chooseBoolOnActiveTab(
        activeTab,
        !!project.groupId,
        hasExtensionAccessOrLoading(project, 'sharepoint')
      ),
    invoiceControlling: isProjectMember && showProjectControlling,
    timeRecordControlling: isProjectMember && showProjectControlling,
    settings: showProjectSettings,
  };
};
