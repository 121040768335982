import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { useDispatch, useSelector } from 'react-redux';
import { MailFolderId, MessageId, ProjectId } from '../../../models/Types';
import { Message } from '../../../models/Message';
import PrioSpinner from '../../../components/PrioSpinner';
import { apiDeleteMessage } from '../api';
import { deleteLocalMessage } from '../actions/actionControllers/messageActionController';
import EmailComposer from './EmailComposer/EmailComposer';
import { Link, useNavigate } from 'react-router-dom';
import { setMailListNavigationState } from '../actions/actionControllers/mailNavigationActionController';
import {
  getActiveMessageId,
  getDraftMessages,
  getMailSettings,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { setActiveDraftMessageId } from '../actions/actionControllers/draftsActionController';
import { v4 as uuid } from 'uuid';
import { createSelector } from 'reselect';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  rootCompressedView: {
    padding: '12px !important',
  },
  mailComposer: {
    overflow: 'hidden',
    marginTop: theme.old.spacing.baseSpacing,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    flex: 1,
  },
  mailComposerCompressedView: {
    marginTop: 0,
  },
}));

const firstDraftMessageIdSelector = (projectId: ProjectId) =>
  createSelector<
    [
      (state: RootReducerState) => MessageId,
      (state: RootReducerState) => Message[],
    ],
    MessageId
  >(
    (state) => getActiveMessageId(state, projectId),
    (state) => getDraftMessages(state, projectId),
    (activeMessageId, draftMessages) =>
      draftMessages?.filter(
        (draftMessages) => activeMessageId !== draftMessages?.id
      )?.[0]?.id
  );

const useFirstDraftMessageId = (
  projectId: ProjectId,
  isActiveMessage?: boolean
) => {
  const draftMessageId = useSelector(firstDraftMessageIdSelector(projectId));
  return useMemo(
    () => (!isActiveMessage ? null : draftMessageId),
    [draftMessageId, isActiveMessage]
  );
};

interface DraftMessagePageProps {
  className?: string;
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  message: Message;
  isActiveMessage?: boolean;
  nextDraftId?: MessageId;
  hideBackButton?: boolean;
}

export const DraftMessagePage: React.FC<DraftMessagePageProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    className,
    message,
    projectId,
    mailFolderId,
    isActiveMessage,
    nextDraftId,
    hideBackButton,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [draftMessage, setDraftMessage] = useState<Message>(message);

  const draftMessageId = useMemo(() => draftMessage?.id, [draftMessage?.id]);

  const { messageViewLayout } = useSelector(getMailSettings);
  const mailSettings = useSelector(getMailSettings);

  const firstDraftMessageId = useFirstDraftMessageId(
    projectId,
    isActiveMessage
  );

  const toolbarIndex = useMemo(() => uuid(), []);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (message.id !== draftMessageId) {
      setDraftMessage(message);
    }
  }, [message, draftMessageId]);
  //#endregion

  if (!draftMessage) {
    return (
      <div className="prio-flex-center-center prio-flex-column">
        <PrioSpinner size="large" />
      </div>
    );
  }

  //#region ------------------------------ Methods / Handlers
  const close = () => {
    if (isActiveMessage) {
      if (firstDraftMessageId) {
        dispatch(setActiveDraftMessageId(projectId, firstDraftMessageId));
      }
      navigate(
        `${messageViewLayout === 'hidden' ? '../mail/' : ''}${mailFolderId}`
      );
      dispatch(setMailListNavigationState(null, projectId));
    } else {
      dispatch(setActiveDraftMessageId(projectId, nextDraftId));
    }
  };

  const deleteAndClose = async () => {
    if (!draftMessageId) return;
    const { result } = await apiDeleteMessage(projectId, draftMessageId);
    if (result.status >= 200 && result.status < 300) {
      close();
      dispatch(deleteLocalMessage(projectId, mailFolderId, draftMessageId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.deleteDraftError'),
      });
    }
  };

  const handleSend = async () => {
    close();
  };
  //#endregion

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.rootCompressedView]: mailSettings.mailListSpacing === 'tight',
      })}
    >
      {!hideBackButton && messageViewLayout === 'hidden' && (
        <Link to={`../mail/${mailFolderId}`}>
          <Button
            type="link"
            onClick={() => {
              dispatch(setMailListNavigationState(null, projectId));
            }}
            iconProp={['fal', 'chevron-left']}
          >
            {t('common:back')}
          </Button>
        </Link>
      )}
      <EmailComposer
        className={classNames(classes.mailComposer, {
          [classes.mailComposerCompressedView]:
            mailSettings.mailListSpacing === 'tight',
        })}
        message={draftMessage}
        onSend={handleSend}
        onCancel={deleteAndClose}
        onBack={close}
        showSubject
        projectId={projectId}
        toolbarIndex={toolbarIndex}
        autofocus={!isActiveMessage}
        openInNewWindow
      />
    </div>
  );
};

export default DraftMessagePage;
