import React from 'react';
import { Routes as ReactRouterDomRoutes, Route, Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import CompanyList from './CompanyList';
import CompanyDetailsPage from './CompanyDetailsPage';
import Flex from '../../../components/Flex';
import { Button } from '@prio365/prio365-react-library';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

interface CompaniesProps {}

export const Companies: React.FC<CompaniesProps> = () => (
  <>
    <Flex.Row>
      <Link to="create">
        <Button type="default">
          <Trans i18nKey="companies:links.create">Firma anlegen </Trans>
        </Button>
      </Link>
      <Link to="search">
        <Button type="default">
          <Trans i18nKey="companies:links.search">Firma suchen </Trans>
        </Button>
      </Link>
    </Flex.Row>
    <Routes>
      <Route path="/" element={<CompanyList />} />
      <Route path="create" element={<div>TODO: migrate to ant design</div>} />
      <Route path=":companyId/details" element={<CompanyDetailsPage />} />
      <Route path="/companies/search">
        <h1>Kontaktsuche</h1>
      </Route>
    </Routes>
  </>
);

export default Companies;
