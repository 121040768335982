import React from 'react';
import classNames from 'classnames';
import Flex from '../../../components/Flex';
import { GenericSettingsMenu } from './GenericSettingsMenu';

import { makePrioStyles } from '../../../theme/utils';
import { useParams } from 'react-router-dom';
import ConfigurationPage from './ConfigurationPage';
import HourlyRateSuggestionsPage from './HourlyRateSuggestionsPage';
import KilometerRateSuggestionsPage from './KilometerRateSuggestionsPage';
import TitleSuggestionsPage from './TitleSuggestionsPage';
import InternalJobTitlesPage from './InternalJobTitlesPage';
import ExternalJobTitlesPage from './ExternalJobTitlesPage';
import GlobalProjectMessageSettingsPage from './GlobalProjectMessageSettingsPage';
import GlobalProjectDocumentSettingsPage from './GlobalProjectDocumentSettingsPage';
import GlobalProjectSettingsPage from './GlobalProjectSettingsPage';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  rowContent: {
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    width: '100%',
  },
}));

interface GenericSettingsProps {
  className?: string;
  pathPrefix?: string;
}

export const GenericSettings: React.FC<GenericSettingsProps> = (props) => {
  const classes = useStyles();
  const { className, pathPrefix } = props;
  const { selectedList } = useParams();

  let component: React.ReactNode;
  switch (selectedList) {
    case 'keyValue':
      component = <ConfigurationPage className={classNames(classes.content)} />;
      break;
    case 'hourlyRates':
      component = (
        <HourlyRateSuggestionsPage className={classNames(classes.content)} />
      );
      break;
    case 'kilometerRates':
      component = (
        <KilometerRateSuggestionsPage className={classNames(classes.content)} />
      );
      break;
    case 'titles':
      component = (
        <TitleSuggestionsPage className={classNames(classes.content)} />
      );
      break;
    case 'internalJobTitles':
      component = (
        <InternalJobTitlesPage className={classNames(classes.content)} />
      );
      break;
    case 'externalJobTitles':
      component = (
        <ExternalJobTitlesPage className={classNames(classes.content)} />
      );
      break;
    case 'mail':
      component = (
        <GlobalProjectMessageSettingsPage
          className={classNames(classes.content)}
        />
      );
      break;
    case 'documents':
      component = (
        <GlobalProjectDocumentSettingsPage
          className={classNames(classes.content)}
        />
      );
      break;
    case 'projectSetting':
      component = (
        <GlobalProjectSettingsPage className={classNames(classes.content)} />
      );
      break;
    default:
  }

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Flex.Row flex={1} className={classes.rowContent}>
        <GenericSettingsMenu
          selectedList={selectedList}
          urlPrefix={pathPrefix}
          className={classes.menu}
        />
        {component}
      </Flex.Row>
    </Flex.Column>
  );
};

export default GenericSettings;
