import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

import Flex from '../../../components/Flex';
import { SettingsMenu } from './SettingsMenu';
import useAccessRights from '../../users/hooks/useAccessRights';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getGlobalSiderOpen } from '../../../apps/main/rootReducer';
import { makePrioStyles } from '../../../theme/utils';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    transition: 'padding 0.2s',
    padding: `0 ${theme.old.spacing.unit(1.5)}px ${theme.old.spacing.unit(
      1.5
    )}px`,
  },
  rootOpen: {
    padding: `0 ${theme.old.spacing.defaultPadding}px ${theme.old.spacing.defaultPadding}px`,
  },
}));

interface SettingsModuleNavigationProps {
  className?: string;
}

export const SettingsModuleNavigation: React.FC<
  SettingsModuleNavigationProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const { selectedList } = useParams();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    showAllPrioSettings,
    showUserRoleSettings,
    showTemplateSettings,
    showUserCompanyData,
  } = useAccessRights([
    'showAllPrioSettings',
    'showUserRoleSettings',
    'showTemplateSettings',
    'showUserCompanyData',
  ]);
  const siderOpen = useSelector(getGlobalSiderOpen);
  //#endregion

  if (showAllPrioSettings && !selectedList) {
    return <Navigate to="generic" />;
  } else if (showUserCompanyData && !selectedList) {
    return <Navigate to="users" />;
  } else if (showUserRoleSettings && !selectedList) {
    return <Navigate to="user-roles" />;
  } else if (showTemplateSettings && !selectedList) {
    return <Navigate to="templates" />;
  }

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames(classes.root, className, {
        [classes.rootOpen]: siderOpen,
      })}
    >
      <Flex.Column childrenGap={theme.old.spacing.unit(1)}>
        <SettingsMenu
          selectedList={selectedList}
          urlPrefix="../"
          collapsed={!siderOpen}
        />
      </Flex.Column>
    </Flex.Column>
  );
};

export default SettingsModuleNavigation;
