import { RootReducerState } from '../../../apps/main/rootReducer';
import { createSelector } from 'reselect';
import { TextBlocksByIdState } from '../reducers/textBlocks';
import { TextBlock } from '../../../models/TextBlock';

export const textBlockSelector = createSelector<
  [
    (state: RootReducerState) => string[],
    (state: RootReducerState) => TextBlocksByIdState
  ],
  TextBlock[]
>(
  (state) => state.settings.textBlocks.ids,
  (state) => state.settings.textBlocks.byId,
  (ids, byId) => ids.filter((id) => !byId[id].isArchived).map((id) => byId[id])
);
