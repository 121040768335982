import { CSSProperties } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

export type OnMoveItemsIntoFolder = (
  driveItems: string[],
  targetDriveItem: string,
  destinationDriveItem: string,
  targetIsRoot?: boolean,
  destinationIsRoot?: boolean
) => void;

interface DraggableElementProps<DataType extends object> {
  id: React.Key;

  style?: CSSProperties;
  children?: React.ReactNode;
  dragType: string;
  collect?: (monitor: DragSourceMonitor<DataType, unknown>) => any;
  onAction?: DynamicActions;
  item: DataType;
  onDragEnd?: (
    item: DataType,
    monitor: DragSourceMonitor<DataType, unknown>
  ) => void;
}

interface DynamicActions {
  [key: string]: Function;
}

const DraggableElement = <DataType extends object>(
  props: DraggableElementProps<DataType>
) => {
  const { dragType, children, onAction, collect, onDragEnd, item, style } =
    props;
  const [, drag, preview] = useDrag({
    type: dragType,
    item: item,
    collect: collect
      ? collect
      : (monitor) => {
          return {
            isDragging: !!monitor.isDragging(),
          };
        },
    end: (_, monitor) => {
      onDragEnd(item, monitor);
    },
    ...onAction,
  });

  preview(getEmptyImage(), { captureDraggingState: true });

  return (
    <>
      <div ref={drag} style={style ?? null}>
        {children}
      </div>
    </>
  );
};

export default DraggableElement;
