import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { Button, Drawer, useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../../../theme/types';
import { useNavigate, useParams } from 'react-router-dom';
import ActionBar from '../../../components/ActionBar';
import ListMenuArea from '../../common/ListMenuArea';
import ProjectPhaseEdit from './ProjectPhaseEdit';
import { ProjectPhase } from '../../../models/ProjectPhase';
import { apiGetProjectPhases } from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectPhaseCreate from './ProjectPhaseCreate';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  item: {
    color: theme.colors.application.typography.default,
    fontWeight: theme.font.fontSize.regular,
  },
  subItem: {
    color: theme.colors.application.typography.muted,
    fontWeight: theme.font.fontSize.extraSmall,
    float: 'left',
  },
  listItem: {
    padding: `${theme.spacing.regular * 0.5}px ${theme.spacing.regular}px`,
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& h2': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: `${theme.spacing.regular * 0.5}px`,
    },
  },
  icon: {
    minWidth: theme.spacing.regular * 1.5,
    fontSize: theme.font.fontSize.extraSmall,
  },
}));

interface ProjectPhaseSettingsProps {
  className?: string;
}

export const ProjectPhaseSettings: React.FC<ProjectPhaseSettingsProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const navigate = useNavigate();
  const { projectId, id } = useParams();
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [projectPhases, setProjectPhases] = useState<ProjectPhase[]>([]);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [fetchPhases, setFetchPhases] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const projectPhaseChanged = (projectPhaseId: string) => {
    const fetchProjectPhase = async () => {
      const { data } = await apiGetProjectPhases(projectId);
      setProjectPhases(data);
      navigate(projectPhaseId);
    };
    fetchProjectPhase();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchProjectPhase = async () => {
      setFetchPhases(true);
      const { data } = await apiGetProjectPhases(projectId);
      setProjectPhases(data);
      setFetchPhases(false);
    };
    fetchProjectPhase();
  }, [projectId, setFetchPhases]);
  //#endregion

  const ProjectPhaseListItem: React.FC<{
    index: number;
    key: string;
    isScrolling: boolean;
    isVisible: boolean;
  }> = ({ index, key, isScrolling, isVisible }) => {
    return (
      <div className={classes.listItem}>
        <Flex.Row
          className={classes.item}
          childrenGap={theme.spacing.extraSmall}
        >
          <h2>{projectPhases[index].name}</h2>
        </Flex.Row>
        <Flex.Row
          className={classes.subItem}
          childrenGap={theme.spacing.extraSmall}
        >
          {projectPhases[index].isFavorite && (
            <span className={classes.icon}>
              <FontAwesomeIcon icon={['fal', 'star']} />
            </span>
          )}
          {!projectPhases[index].isActive && (
            <span className={classes.icon}>
              <FontAwesomeIcon icon={['fal', 'eye-slash']} />
            </span>
          )}
          {projectPhases[index].budget > 0 && (
            <span className={classes.icon}>
              <FontAwesomeIcon icon={['fal', 'money-bill']} />
            </span>
          )}
          {((projectPhases[index].from !== '' &&
            projectPhases[index].from !== '0001-01-01T00:00:00') ||
            (projectPhases[index].to !== '' &&
              projectPhases[index].to !== '0001-01-01T00:00:00')) && (
            <span className={classes.icon}>
              <FontAwesomeIcon icon={['fal', 'calendar']} />
            </span>
          )}
        </Flex.Row>
      </div>
    );
  };

  return (
    <>
      <Flex.Column flex={1} className={classes.root}>
        <Flex.Row childrenGap={theme.spacing.regular} marginBottom={16}>
          <ActionBar>
            <Button
              disabled={id === '0'}
              type="primary"
              onClick={() => {
                setOpenNew(true);
              }}
            >
              {t('projects:projectPhase.new')}
            </Button>
          </ActionBar>
        </Flex.Row>
        <Flex.Row className={classes.root}>
          <ListMenuArea
            // sort by name
            menuItems={projectPhases?.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            menuItemIdKey="projectPhaseId"
            menuItemEmptyString={t('projects:projectPhase.empty')}
            listItem={ProjectPhaseListItem}
            isLoading={fetchPhases}
            pageNode={
              <ProjectPhaseEdit projectPhaseChanged={projectPhaseChanged} />
            }
          />
        </Flex.Row>
      </Flex.Column>
      <Drawer
        title={t('projects:projectPhase.new')}
        visible={openNew}
        closable={true}
        onClose={() => {
          setOpenNew(false);
        }}
      >
        <ProjectPhaseCreate
          projectPhaseChanged={(phaseId) => {
            projectPhaseChanged(phaseId);
            setOpenNew(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default ProjectPhaseSettings;
