import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import React from 'react';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  box: {
    width: '100%',
    border: `1px solid ${theme.colors.application.border}`,
    borderRadius: theme.borderRadius.regular,
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },
  boxDivider: {
    borderLeft: `1px solid ${theme.colors.application.border}`,
    height: 76,
  },

  // StatBox
  statBox: {
    height: 76,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    padding: theme.spacing.small,
    paddingLeft: theme.spacing.regular,
    paddingRight: theme.spacing.regular,
    gap: theme.spacing.extraSmall,
  },
  statBoxTitle: {
    fontSize: theme.font.fontSize.extraSmall,
    fontWeight: theme.font.fontWeight.bold,
    whiteSpace: 'nowrap',
    opacity: 0.8,
  },
  statBoxValue: {
    fontSize: 24,
    fontWeight: theme.font.fontWeight.bold,
    whiteSpace: 'nowrap',
    width: '100%',
  },
  statBoxFooter: {
    fontSize: theme.font.fontSize.small,
    width: '100%',
  },
  infoFooter: {
    fontSize: theme.font.fontSize.regular,
    color: theme.colors.application.typography.default,
    fontWeight: theme.font.fontWeight.bold,
    marginTop: theme.spacing.extraSmall,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.small,
  },
  infoFooterText: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.default,
  },
}));

interface StatBoxProps {
  title: string | React.ReactNode;
  stat: string;
  footer?: string;
  elementOverwrite?: React.ReactNode;
  textSize?: 'small' | 'large'; // default is large
}

interface StatBoxesProps {
  stats: StatBoxProps[];
  className?: string;
  style?: React.CSSProperties;
  textSize?: 'small' | 'large'; // default is large
}

export const StatBoxes: React.FC<StatBoxesProps> = (props) => {
  const { stats, className, style, textSize = 'large' } = props;
  const classes = useStyles();
  return (
    <div style={style} className={classNames(classes.box, className)}>
      {stats.map((stat, index) => (
        <React.Fragment key={index}>
          {index > 0 && <div className={classNames(classes.boxDivider)} />}
          <StatBox key={index} {...stat} textSize={textSize} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default StatBoxes;

function StatBox(props: StatBoxProps) {
  const { title, stat, footer, elementOverwrite, textSize } = props;
  const classes = useStyles();

  const theme = useTheme<PrioTheme>();
  return (
    <div className={classNames(classes.statBox)}>
      {elementOverwrite !== undefined ? (
        elementOverwrite
      ) : (
        <>
          <div className={classNames(classes.statBoxTitle)}>{title}</div>
          <div
            className={classNames(classes.statBoxValue)}
            style={{
              fontSize: textSize === 'small' ? theme.font.fontSize.regular : 24,
            }}
          >
            {stat}
          </div>
          {footer && (
            <div className={classNames(classes.statBoxFooter)}>{footer}</div>
          )}
        </>
      )}
    </div>
  );
}
